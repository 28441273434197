import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Tabs } from "antd";

import PendingsComponent from "./pending";
import SettledComponent from "./settled";
import RequestsComponent from "./requests";

import { updateLocationHash, getHashValue } from "utils/common";
import { GAME_CATEGORY } from "constants/game.constants";

/** Betslips Page Retail Tab Component */
const RetailComponent = ({ gameCategory, showBetsBySeparate = false  }) => {
	const { hash } = useLocation();
	const { t } = useTranslation();

	const [activeKey, setActiveKey] = useState(null);

	/** Update active tab on location hash change */
	useEffect(() => {
		setActiveKey(getHashValue("tab") || "1");
	}, [hash]);

	return (
		<Fragment>
			<Tabs
				animated={false}
				activeKey={activeKey}
				onChange={(key) => updateLocationHash("tab=" + key, true)}
				destroyInactiveTabPane={true}
				items={[
					{
						key: "1",
						label: t("pages.dashboard.bets.tabs.pending_betslips"),
						children: <PendingsComponent gameCategory={gameCategory} showBetsBySeparate={showBetsBySeparate} />
					},
					{
						key: "2",
						label: t("pages.dashboard.bets.tabs.settled_betslips"),
						children: <SettledComponent gameCategory={gameCategory} showBetsBySeparate={showBetsBySeparate} />
					},
					{
						key: "3",
						label: t("pages.dashboard.bets.tabs.requests"),
						children: <RequestsComponent />
					}
				]}
			/>
		</Fragment>
	);
};

RetailComponent.propTypes = {
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY)),
	showBetsBySeparate: PropTypes.bool
};

export default RetailComponent;
