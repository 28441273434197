import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Button, Select, Input, Spin, Tooltip, Radio, Switch, Checkbox } from "antd";
const { Item: FormItem } = Form;

import { saveApiKeyGeneralInfo } from "store/actions/dashboard/online/apikeys/generalInfo.action";
import { getPartnerAvailableLanguages } from "store/actions/dashboard/partners/languages.action";

import { copyToClipboard } from "utils/common";
import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { URL_REGEX } from "constants/regex.constants";
import { LOGIN_CONFIGURATION_TYPE, INTEGRATION_TYPE } from "constants/apiKey.constants";

import apiKeyGeneralInfoType from "types/apiKey/generalInfo.type";
import { GAME_CATEGORY } from "constants/game.constants";

/** Api Key Edit Page General Info Tab Component */
const GeneralInfoComponent = ({ saveApiKeyGeneralInfo, isSaving, isLoading, generalInfo, onTabChange, isLanguagesLoading, languages, getPartnerAvailableLanguages }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [loginConfigurationType, setLoginConfigurationType] = useState(null);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.APIKEY, action: PERMISSION_ACTION.MODIFY });

	/** Load partner languages */
	useEffect(() => {
		getPartnerAvailableLanguages();
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			defaultLanguageCode: generalInfo.defaultLanguageCode,
			description: generalInfo.description,
			name: generalInfo.name,
			endPointURL: generalInfo.endPointURL,
			secret: generalInfo.secret,
			id: generalInfo.id,
			loginConfiguration: generalInfo.loginConfiguration,
			integrationType: generalInfo.integrationType,
			sendAdditionalData: generalInfo.sendAdditionalData,
			isHideHeaderInMobileFrame: generalInfo.isHideHeaderInMobileFrame,
			gameCategoryList: generalInfo.gameCategoryList
		});
		setLoginConfigurationType(generalInfo.loginConfiguration.type);
	}, [generalInfo]);

	/** Fires when form submitted
	 * @function
	 * @memberOf GeneralInfoComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				saveApiKeyGeneralInfo({ ...data });
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	/** Fires on login configuration type change
	 * @function
	 * @param {object} e - change event object
	 * @memberOf GeneralInfoComponent
	 */
	const handleLoginConfigurationTypeChange = (e) => {
		setLoginConfigurationType(e.target.value);
		setFieldsValue({
			loginConfiguration: {
				type: e.target.value
			}
		});
		setTimeout(() => {
			validateFields([["loginConfiguration", "data"]]);
		}, 50);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					name: generalInfo.name,
					defaultLanguageCode: generalInfo.defaultLanguageCode ? generalInfo.defaultLanguageCode.toLowerCase() : "",
					description: generalInfo.description,
					id: generalInfo.id,
					endPointURL: generalInfo.endPointURL,
					secret: generalInfo.secret,
					integrationType: generalInfo.integrationType,
					loginConfiguration: generalInfo.loginConfiguration,
					sendAdditionalData: generalInfo.sendAdditionalData,
					isHideHeaderInMobileFrame: generalInfo.isHideHeaderInMobileFrame,
					gameCategoryList: generalInfo.gameCategoryList
				}}
				onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, { ...generalInfo }))}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[40, 0]}>
							<Col xs={24} sm={24} xl={12}>
								<Row gutter={[16, 0]}>
									<Col span={24}>
										<h1>{t("pages.dashboard.apikeys.general_info")}</h1>
									</Col>
									<Col xs={24} sm={12}>
										<FormItem label={`${t("pages.dashboard.apikeys.name")} *`} name="name" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
											<Input maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.apikeys.name")}`} disabled={isDisabled} />
										</FormItem>
									</Col>
									<Col xs={24} sm={12}>
										<FormItem label={`${t("pages.dashboard.apikeys.default_language")} *`} name="defaultLanguageCode" rules={[{ required: true, message: t("validation.field_required") }]}>
											<Select placeholder={t("pages.dashboard.partners.language.select_language_placeholder")} loading={isLanguagesLoading} disabled={isDisabled} suffixIcon={<i className="icon-down" />}>
												{Object.keys(languages).map((lang) => (
													<Select.Option key={lang} value={lang.toUpperCase()}>
														{languages[lang]}
													</Select.Option>
												))}
											</Select>
										</FormItem>
									</Col>
									<Col xs={24} sm={24}>
										<FormItem label={`${t("pages.dashboard.apikeys.description")}`} name="description">
											<Input.TextArea maxLength={1000} rows={6} placeholder={`${t("common.enter")} ${t("pages.dashboard.apikeys.description")}`} disabled={isDisabled} />
										</FormItem>
									</Col>
								</Row>
							</Col>
							<Col xs={24} sm={24} xl={12}>
								<Row gutter={[16, 0]}>
									<Col span={24}>
										<h1>{t("pages.dashboard.apikeys.endpoint_details")}</h1>
									</Col>

									<Col xs={24} sm={24} xl={12}>
										<FormItem label={`${t("pages.dashboard.apikeys.api_key")} *`} name="id" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
											<Input disabled placeholder={`${t("common.enter")} ${t("pages.dashboard.apikeys.api_key")}`} className="form-input-with-copy" />
										</FormItem>
										<Tooltip title={t("common.copy") + " " + t("pages.dashboard.apikeys.api_key")} getPopupContainer={() => document.body}>
											<div className="form-copy-button" onClick={() => copyToClipboard(generalInfo.id)}>
												<i className="icon-copy"></i>
											</div>
										</Tooltip>
									</Col>
									<Col xs={24} sm={24} xl={12}>
										<FormItem name="integrationType" label={t("pages.dashboard.apikeys.integration_Type")}>
											<Select disabled suffixIcon={<i className="icon-down" />}>
												<Select.Option value={INTEGRATION_TYPE.IFRAME}>{t("pages.dashboard.apikeys.iframe")}</Select.Option>
												<Select.Option value={INTEGRATION_TYPE.FEED}>{t("pages.dashboard.apikeys.feed")}</Select.Option>
											</Select>
										</FormItem>
									</Col>
									{generalInfo.integrationType === INTEGRATION_TYPE.IFRAME && (
										<Col xs={24} sm={24} xl={12}>
											<FormItem
												label={`${t("pages.dashboard.apikeys.endpoint_url")} *`}
												name="endPointURL"
												rules={[
													{ required: true, whitespace: true, message: t("validation.field_required") },
													{ pattern: URL_REGEX, message: t("validation.url_format") }
												]}
											>
												<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.apikeys.endpoint_url")}`} disabled={isDisabled} />
											</FormItem>
										</Col>
									)}
									<Col xs={24} sm={24} xl={12}>
										<FormItem label={`${t("pages.dashboard.apikeys.secret_key")}`} name="secret">
											<Input disabled placeholder={`${t("common.enter")} ${t("pages.dashboard.apikeys.secret_key")}`} className="form-input-with-copy" />
										</FormItem>
										<Tooltip title={t("common.copy") + " " + t("pages.dashboard.apikeys.secret_key")} getPopupContainer={() => document.body}>
											<div className="form-copy-button" onClick={() => copyToClipboard(generalInfo.secret)}>
												<i className="icon-copy"></i>
											</div>
										</Tooltip>
									</Col>
									<Col span={24}>
										<FormItem
											name={"gameCategoryList"}
											// className="form-item-without-margin form-item-radio-group"
											label={t("pages.dashboard.apikeys.gameCategory")}
											rules={[{ required: true, message: t("validation.field_required") }]}
										>
											<Checkbox.Group
												size="large"
												// onChange={e => handleLoginConfigurationTypeChange(e)}
												disabled={isDisabled}
												// style={{ marginBottom: "8px" }}
												// value={[1]}
												options={Object.values(GAME_CATEGORY).map((gc) => {
													return {
														label: <span>{t(`pages.dashboard.apikeys.gameCategory_${gc}`)}</span>,
														value: gc
													};
												})}
											>
												{Object.values(GAME_CATEGORY).map((gc) => {
													return (
														<Checkbox key={gc} value={gc}>
															<span className="">{t(`pages.dashboard.apikeys.gameCategory_${gc}`)}</span>
														</Checkbox>
													);
												})}
											</Checkbox.Group>
										</FormItem>
									</Col>
									<Col xs={24} sm={24} xl={12}>
										<div className="inline-form-item form-switcher form-switcher-without-margin">
											<label htmlFor="sendAdditionalData" className="vs--cursor-pointer">
												{t("pages.dashboard.partners.retail_settings.retail_configs.send_additional_data")}
											</label>
											<FormItem id="sendAdditionalData" name="sendAdditionalData" valuePropName="checked" className="ant-row">
												<Switch disabled={isDisabled} />
											</FormItem>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row gutter={[40, 0]}>
							<Col xs={24} sm={24} xl={12}>
								<Row gutter={[16, 0]}>
									<Col span={24}>
										<h1 className="dashboard-section-name">{t("pages.dashboard.apikeys.login_parameters")}</h1>
									</Col>
									<Col span={24}>
										<FormItem name={["loginConfiguration", "type"]} className="form-item-without-margin form-item-radio-group" label={t("pages.dashboard.apikeys.desktop")}>
											<Radio.Group size="large" onChange={(e) => handleLoginConfigurationTypeChange(e)} disabled={isDisabled} style={{ marginBottom: "8px" }}>
												<Radio value={LOGIN_CONFIGURATION_TYPE.URL}>{t("pages.dashboard.apikeys.login_page_url")}</Radio>
												<Radio value={LOGIN_CONFIGURATION_TYPE.POPUP}>{t("pages.dashboard.apikeys.login_popup_script")}</Radio>
											</Radio.Group>
										</FormItem>
									</Col>
									<Col span={24}>
										<FormItem
											label=""
											name={["loginConfiguration", "data"]}
											rules={[
												({ getFieldValue }) => ({
													validator(rule, value) {
														if (getFieldValue(["loginConfiguration", "type"]) === LOGIN_CONFIGURATION_TYPE.URL && !URL_REGEX.test(value)) {
															return Promise.reject(t("validation.url_format"));
														} else if (!value) {
															return Promise.reject(t("validation.field_required"));
														}
														return Promise.resolve();
													}
												})
											]}
											className="form-item-without-label"
										>
											{loginConfigurationType === LOGIN_CONFIGURATION_TYPE.URL ? (
												<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.apikeys.login_page_url")}`} disabled={isDisabled} />
											) : (
												<Input.TextArea rows={6} placeholder={`${t("common.enter")} ${t("pages.dashboard.apikeys.login_popup_script")}`} disabled={isDisabled} />
											)}
										</FormItem>
									</Col>

									<Col span={24}>
										<FormItem name={["loginConfiguration", "mobileLoginURL"]} label={t("pages.dashboard.apikeys.mobile_login_url")} rules={[{ pattern: URL_REGEX, message: t("validation.url_format") }]}>
											<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.apikeys.mobile_login_url")}`} disabled={isDisabled} />
										</FormItem>
									</Col>
								</Row>
								<Row gutter={[16, 0]}>
									<Col xs={24} sm={24} xl={12}>
										<div className="inline-form-item form-switcher form-switcher-without-margin">
											<label htmlFor="isHideHeaderInMobileFrame" className="vs--cursor-pointer">
												{t("pages.dashboard.partners.retail_settings.retail_configs.isHideHeaderInMobileFrame")}
											</label>
											<FormItem id="isHideHeaderInMobileFrame" name="isHideHeaderInMobileFrame" valuePropName="checked" className="ant-row">
												<Switch disabled={isDisabled} />
											</FormItem>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
					{!isDisabled && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** GeneralInfoComponent propTypes
 * PropTypes
 */
GeneralInfoComponent.propTypes = {
	/** Redux action to save apiKey General info */
	saveApiKeyGeneralInfo: PropTypes.func,
	/** Redux state property, is true when general info is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when general info is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the general info of current editing apiKey  */
	generalInfo: apiKeyGeneralInfoType,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func,
	/** Redux state property, is true when loading partner languages */
	isLanguagesLoading: PropTypes.bool,
	/** Redux state property, represents the object of loaded partner languages */
	languages: PropTypes.object,
	/** Redux action to get all languages for partner */
	getPartnerAvailableLanguages: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	saveApiKeyGeneralInfo: (data) => {
		dispatch(saveApiKeyGeneralInfo(data));
	},

	getPartnerAvailableLanguages: () => {
		dispatch(getPartnerAvailableLanguages());
	}
});

const mapStateToProps = (state) => {
	return {
		generalInfo: state.apikeys.editingApikey.generalInfo,
		isSaving: state.apikeys.isSaving,
		isLoading: state.apikeys.isLoading,
		isLanguagesLoading: state.partner.language.isAvailableLoading,
		languages: state.partner.language.availableLanguages
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent);
