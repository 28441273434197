import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Tag } from "antd";

import { getBetshopSessions, setBetshopSessionsSorting, setBetshopSessionsFilters } from "store/actions/dashboard/retail/betshops/sessions.action";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import ApiUrls from "constants/api.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { hasPermission } from "utils/permissions";

import Table from "components/common/table";
import ExportButton from "components/common/exportButton";

import Filters from "./filters.component";
import SettlementReport from "./report.component";

import sessionType from "types/betshop/session.type";
import sortingType from "types/common/sorting.type";

/** Betshop Edit Page Sessions Tab Component */

const SessionsComponent = ({ isLoading, sessions, total, getBetshopSessions, sorting, filters, setBetshopSessionsSorting, setBetshopSessionsFilters }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	/** The current viewing session */
	const [viewingSession, setViewingSession] = useState(null);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.session.cashier"),
			dataIndex: "userName"
		},
		{
			title: t("pages.dashboard.session.session_start"),
			dataIndex: "createTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.session.session_end"),
			dataIndex: "endTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.session.session_duration"),
			dataIndex: "duration",
			render: (value, record) => moment.duration(moment(record.endTime).diff(moment(record.createTime)), "milliseconds").humanize(),
			sorter: false
		},
		{
			title: t("pages.dashboard.session.settlement_report"),
			dataIndex: "isSettlementPrinted",
			render: (value) => <Tag color={value ? "green" : "cyan"}>{value ? t("pages.dashboard.session.printed") : t("pages.dashboard.session.not_printed")}</Tag>
		}
	];

	return (
		<Fragment>
			<div className="dashboard-section-content">
				<div className="table-header">
					<div className="table-buttons-dropdowns">
						{hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_ACTIVITY, action: PERMISSION_ACTION.EXPORT }) ? (
							<ExportButton
								columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))}
								tableName={t("pages.dashboard.betshops.sessions")}
								url={`${ApiUrls.EXPORT_BETSHOPS_ALL_SESSIONS}?projectId=${routeParams.id}&projectType=${PROJECT_TYPE.RETAIL}`}
								filters={filters}
								sorting={sorting}
							/>
						) : null}
					</div>
					<Filters />
				</div>
				<Table
					loading={isLoading}
					columns={columns}
					data={sessions}
					loadFn={() => getBetshopSessions(routeParams.id)}
					sorting={sorting}
					filters={filters}
					setSortingFn={setBetshopSessionsSorting}
					setFiltersFn={setBetshopSessionsFilters}
					total={total}
					actions={{
						view: {
							handler: (record) => setViewingSession(record),
							hidden: (record) => !record.isSettlementPrinted,
							icon: <i className="icon-reports" />
						}
					}}
					isDisabled={() => false}
					enableReload={true}
				/>
			</div>

			{viewingSession ? <SettlementReport onClose={() => setViewingSession(null)} session={viewingSession} /> : null}
		</Fragment>
	);
};

/** SessionsComponent propTypes
 * PropTypes
 */
SessionsComponent.propTypes = {
	/** Redux action to get betshop sessions */
	getBetshopSessions: PropTypes.func,
	/** Redux state property, represents the array of loaded sessions of betshop */
	sessions: PropTypes.arrayOf(sessionType),
	/** Redux state property, betshop sessions total count */
	total: PropTypes.number,
	/** Redux state property, is true when loading betshop sessions */
	isLoading: PropTypes.bool,
	/** Redux action to set betshops sessions sorting details */
	setBetshopSessionsSorting: PropTypes.func,
	/** Redux action to set betshop sessions filters */
	setBetshopSessionsFilters: PropTypes.func,
	/** Redux state property, betshop sessions sorting details */
	sorting: sortingType,
	/** Redux state property, betshops sessions filters */
	filters: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	getBetshopSessions: (id) => {
		dispatch(getBetshopSessions(id));
	},
	setBetshopSessionsSorting: (sorting) => {
		dispatch(setBetshopSessionsSorting(sorting));
	},
	setBetshopSessionsFilters: (filters) => {
		dispatch(setBetshopSessionsFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.betshops.viewingBetshop.sessions.isLoading,
		sessions: state.betshops.viewingBetshop.sessions.sessions,
		total: state.betshops.viewingBetshop.sessions.total,
		sorting: state.betshops.viewingBetshop.sessions.sorting,
		filters: state.betshops.viewingBetshop.sessions.filters
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionsComponent);
