import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import CheckboxDropdown from "components/common/checkboxDropdown";

/** Table Columns chooser component */
const ColumnsButton = ({ columns, onApply, defaultSelectedColumns }) => {
	const { t } = useTranslation();

	/** Function , fires on apply button click
	 * @function
	 * @param {array} value - selected columns
	 * @memberOf Columns
	 */
	const applyColumns = (value) => {
		onApply(value);
	};

	return (
		<CheckboxDropdown
			columns={columns}
			buttons={[{ text: t("common.apply"), onClick: (value) => applyColumns(value) }]}
			title={t("common.manage_columns")}
			buttonText={t("common.columns")}
			buttonClassName="table-columns-button"
			allowEmpty={true}
			selectAllByDefault={false}
			defaultSelectedColumns={defaultSelectedColumns}
		/>
	);
};

/** ColumnsButton propTypes
 * PropTypes
 */
ColumnsButton.propTypes = {
	/** Array of columns of table which can be excluded */
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			key: PropTypes.string
		})
	),
	/** Function which will fire on apply button click */
	onApply: PropTypes.func,
	/** Default Selected columns */
	defaultSelectedColumns: PropTypes.arrayOf(PropTypes.string)
};

export default ColumnsButton;
