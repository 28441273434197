import moment from "moment";

export const WEEKDAYS = {
	MONDAY: "monday",
	TUESDAY: "tuesday",
	WEDNESDAY: "wednesday",
	THURSDAY: "thursday",
	FRIDAY: "friday",
	SATURDAY: "saturday",
	SUNDAY: "sunday"
};

export const MONTHS = {
	JANUARY: 1,
	FEBRUARY: 2,
	MARCH: 3,
	APRIL: 4,
	MAY: 5,
	JUNE: 6,
	JULY: 7,
	AUGUST: 8,
	SEPTEMBER: 9,
	OCTOBER: 10,
	NOVEMBER: 11,
	DECEMBER: 12
};

export const YEARS = Array(moment().year() - 2020 + 1)
	.fill()
	.map((_, idx) => 2020 + idx);

export const SHOP_LIMIT_VALUES = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];

export const PROJECT_LIMIT_VALUES = Array.from(Array(100).keys()).map((i) => i + 1);

export const REDEMPTION_DATE_VALUES = {
	NEVER: 1,
	SEVEN_DAYS: 2,
	FORTEEN_DAYS: 4,
	THIRTHY_DAYS: 8,
	FORTYFIVE_DAYS: 16,
	SIXTY_DAYS: 32,
	NINTY_DAYS: 64,
	HUNDREDTWENTY_DAYS: 128
};

export const ALL_OPTION_ID = "*";

export const ORDER_DIRECTION = {
	NONE: 0,
	ASC: 1,
	DESC: 2
};

export const EXPORT_TYPE = {
	CSV: 1,
	PDF: 2
};

export const PROJECT_TYPE = {
	BACKOFFICE: 0,
	ONLINE: 1,
	RETAIL: 2
};

export const POPUP_SIZE = {
	SMALL: 348,
	MIDDLE: 530,
	BIG: 668,
	BIGEST: 1268
};

export const DATA_DIFFERENCE_TYPE = {
	DIFFERENCE: 1,
	MISSING: 2,
	BET_DIFFERENCE: 3
};

export const REPORT_TYPE = {
	BY_ACTION_TIME: 1,
	BY_CALCULATION_TIME: 2,
	BY_BET_TIME: 4
};

export const MAX_DECIMALS_COUNT = 15;

export const TEXTAREA_MAX_LENGTH = 200;

export const CAPTCHA_KEY = "6LcAgikaAAAAAOTs9Oa8h6Qi_p0-L92W_yxnp0_9";
export const TINYMCE_KEY = "em12fwxyy5txn1giw22hj0wwrory021z44ht9bcp2tz8uiw5";

export const FILE_SIZES_MEASUREMENT = {
	B: 1,
	KB: 1024,
	MB: 1048576
}

export const MAX_INT_32_VALUE = 2147483647;