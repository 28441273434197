import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { RESPONSE_STATE } from "constants/response.constants";

import {
	TRANSLATION_GROUPS_BEFORE,
	TRANSLATION_GROUPS_FINISH,
	TRANSLATION_SET_GROUPS,
	TRANSLATIONS_BEFORE,
	TRANSLATIONS_FINISH,
	SET_TRANSLATIONS,
	TRANSLATION_SAVE_BEFORE,
	TRANSLATION_SAVE_FINISH,
	UPDATE_TRANSLATION,
	TRANSLATION_SET_SYSTEM_DEFAULT,
	TRANSLATIONS_PUBLISH_BEFORE,
	TRANSLATIONS_PUBLISH_FINISH,
	TRANSLATIONS_UNPUBLISH_BEFORE,
	TRANSLATIONS_UNPUBLISH_FINISH
} from "store/actionTypes";

const setTranslationGroupsBefore = () => ({
	type: TRANSLATION_GROUPS_BEFORE
});

const setTranslationGroupsFinished = () => ({
	type: TRANSLATION_GROUPS_FINISH
});

const setTranslationGroups = (groups) => ({
	type: TRANSLATION_SET_GROUPS,
	payload: { groups }
});

export const setTranslationsBefore = () => ({
	type: TRANSLATIONS_BEFORE
});

export const setTranslationsFinished = () => ({
	type: TRANSLATIONS_FINISH
});

const setTranslations = (translations) => ({
	type: SET_TRANSLATIONS,
	payload: { translations }
});

export const setTranslationSaveBefore = () => ({
	type: TRANSLATION_SAVE_BEFORE
});

export const setTranslationSaveFinished = () => ({
	type: TRANSLATION_SAVE_FINISH
});

const updateTranslation = (translation, key) => ({
	type: UPDATE_TRANSLATION,
	payload: { translation, key }
});

export const setTranslationsPublishBefore = () => ({
	type: TRANSLATIONS_PUBLISH_BEFORE
});

export const setTranslationsPublishFinished = () => ({
	type: TRANSLATIONS_PUBLISH_FINISH
});

const setTranslationsUnPublishBefore = () => ({
	type: TRANSLATIONS_UNPUBLISH_BEFORE
});

const setTranslationsUnPublishFinished = () => ({
	type: TRANSLATIONS_UNPUBLISH_FINISH
});

export const getTranslationGroups = () => {
	return (dispatch) => {
		dispatch(setTranslationGroupsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.TRANSLATION_GET_ALL_GROUPS}`,
			method: Methods.GET
		})
			.then(({ data: { value: groups } }) => {
				dispatch(setTranslationGroups(groups));
				dispatch(setTranslationGroupsFinished());
			})
			.catch(() => {
				dispatch(setTranslationGroupsFinished());
			});
	};
};

export const getTranslations = (groupId, subGroupId, languageCodes, isSystemDefault) => {
	return (dispatch) => {
		dispatch(setTranslationsBefore());
		const languageCodesQuery = languageCodes
			.slice(0, 5)
			.map((l) => "languageCodes=" + l)
			.join("&");
		const endpoint = isSystemDefault ? ApiUrls.TRANSLATION_GET_ALL_SYSTEM_DEFAULT : ApiUrls.TRANSLATION_GET_ALL;
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${endpoint}?${languageCodesQuery}`,
			method: Methods.GET,
			params: {
				baseLanguageCode: "en",
				groupId,
				subGroupId,
				isSystemDefault
			}
		})
			.then(({ data: { value: translations } }) => {
				dispatch(setTranslations(translations));
				dispatch(setTranslationsFinished());
			})
			.catch(() => {
				dispatch(setTranslationsFinished());
			});
	};
};

export const saveTranslation = (data) => {
	return (dispatch) => {
		dispatch(setTranslationSaveBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${data.isSystemDefault ? ApiUrls.TRANSLATION_SAVE_SYSTEM_DEFAULT : ApiUrls.TRANSLATION_SAVE}`,
			method: Methods.POST,
			data: data
		})
			.then(({ data: { message: msg, status, value: translation } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					dispatch(updateTranslation(translation, data.key));
				}

				dispatch(setTranslationSaveFinished());
			})
			.catch(() => {
				dispatch(setTranslationSaveFinished());
			});
	};
};

export const resetTranslation = (data) => {
	return (dispatch) => {
		dispatch(setTranslationSaveBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.TRANSLATION_RESET}`,
			method: Methods.DELETE,
			data: data
		})
			.then(({ data: { value: translation } }) => {
				dispatch(updateTranslation(translation, data.key));
				dispatch(setTranslationSaveFinished());
			})
			.catch(() => {
				dispatch(setTranslationSaveFinished());
			});
	};
};

export const createTranslation = (data, languageCodes) => {
	return (dispatch) => {
		dispatch(setTranslationSaveBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.TRANSLATION_CREATE}`,
			method: Methods.POST,
			data: data
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
				}
				dispatch(getTranslations(data.groupId, data.subGroupId, languageCodes, true));
				dispatch(setTranslationSaveFinished());
			})
			.catch(() => {
				dispatch(setTranslationSaveFinished());
			});
	};
};

export const publishTranslations = (isSystemDefault, languageCode) => {
	return (dispatch) => {
		dispatch(setTranslationsPublishBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${isSystemDefault ? ApiUrls.TRANSLATION_PUBLISH_SYSTEM_DEFAULT : ApiUrls.TRANSLATION_PUBLISH}`,
			method: Methods.POST,
			data: { isSystemDefault, languageCode }
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
				}
				dispatch(setTranslationsPublishFinished());
			})
			.catch(() => {
				dispatch(setTranslationsPublishFinished());
			});
	};
};

export const unpublishTranslations = (languageCode) => {
	return (dispatch) => {
		dispatch(setTranslationsUnPublishBefore());
		const data = {};
		if (languageCode) {
			data.languageCode = languageCode;
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.TRANSLATION_UNPUBLISH}`,
			method: Methods.POST,
			data: data
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
				}
				dispatch(setTranslationsUnPublishFinished());
			})
			.catch(() => {
				dispatch(setTranslationsUnPublishFinished());
			});
	};
};

export const setSystemDefault = (systemDefault) => ({
	type: TRANSLATION_SET_SYSTEM_DEFAULT,
	payload: { systemDefault }
});
