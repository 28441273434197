import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Button } from "antd";
const { Item: FormItem } = Form;

import Color from "./color";

import { TEMPLATES, TEMPLATE_RULES, COLORS_LIST } from "constants/color.constants";

import apiKeyUIType from "types/apiKey/ui.type";

import { saveApiKeyUI, setApiKeyPreviewUI } from "store/actions/dashboard/online/apikeys/customization.action";

import { lighten, darken, rgba } from "utils/color";
import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

/** Customization Tool Colors Component */
const Colors = ({ ui, isSaving, saveApiKeyUI, setApiKeyPreviewUI, previewUI, onClose }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const [isFormTouched, setIsFormTouched] = useState(false);
	const [initialValues, setInitialValues] = useState(undefined);
	const [isResetDisabled, setIsResetDisabled] = useState(false);

	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_CUSTOMIZATION, action: PERMISSION_ACTION.MODIFY });

	/** Set initial colors */
	useEffect(() => {
		if (ui.launchUrl) {
			//Check if brand color exist
			const isBrandColorExist = previewUI["--brand-color-1"];
			if (!isBrandColorExist) {
				loadDefaultTemplate();
			} else {
				setApiKeyPreviewUI({
					"--vs--template": "main"
				});
			}
		}
	}, [ui]);

	/** Set default template
	 * @function
	 * @memberOf Colors
	 */
	const loadDefaultTemplate = () => {
		setApiKeyPreviewUI({
			...TEMPLATES["main"].colors,
			"--vs--template": "main"
		});
	};

	/** Update color picker values from saved data
	 * @function
	 * @param{string} name - color name
	 * @param{string} value - color value
	 * @memberOf Colors
	 */
	const onChange = (name, value) => {
		setApiKeyPreviewUI({
			[name]: value
		});
	};

	/** Function to save colors
	 * @function
	 * @memberOf Colors
	 */
	const saveColors = () => {
		const parameters = ui.parameters || [];
		const allColors = [];
		COLORS_LIST.forEach((l) => {
			l.forEach((c) => {
				allColors.push(c);
			});
		});
		const data = parameters
			.filter((p) => !allColors.includes(p.key) && p.key !== "--vs--template")
			.concat(
				Object.keys(previewUI)
					.filter((p) => allColors.includes(p) || p === "--vs--template")
					.map((c) => ({ key: c, value: previewUI[c] }))
			);

		saveApiKeyUI(routeParams.id, data, "EN");
		setIsFormTouched(false);
		setInitialValues(previewUI);
		onClose();
	};

	/** Function which fires on template colorpicker change
	 * @function
	 * @param {number} i - color group index
	 * @param {string} value - color value
	 * @memberOf Colors
	 */
	const handleTemplateButtonChange = (i, value) => {
		const colors = COLORS_LIST[i];
		const rules = TEMPLATE_RULES[previewUI["--vs--template"]];

		const results = {};

		colors.forEach((color) => {
			let res = value;
			if (rules[color]) {
				rules[color].forEach((rule) => {
					res = applyColorFunction(rule.function, rule.percent, res);
				});
			}
			results[color] = res;
		});

		setApiKeyPreviewUI({
			...results
		});
	};

	/** Function to generate color depend on rule
	 * @function
	 * @param {string} functionName - function name to apply
	 * @param {number} percent - percent of modification
	 * @param {string} initial - initial color
	 * @returns {string}
	 * @memberOf Colors
	 */
	const applyColorFunction = (functionName, percent, initial) => {
		if (functionName === "lighten") {
			return lighten(initial, percent);
		} else if (functionName === "darken") {
			return darken(initial, percent);
		} else if (functionName === "rgba") {
			return rgba(initial, percent);
		}
	};

	/** Function to reset colors to selected template
	 * @function
	 * @memberOf Colors
	 */
	const resetToTemplate = () => {
		const templateColors = (TEMPLATES[previewUI["--vs--template"]])?.colors ?? {};
		setApiKeyPreviewUI({
			...templateColors
		});
	};

	/** Set initial values */
	useEffect(() => {
		const values = previewUI || {};
		if (initialValues === undefined && Object.keys(values).length) {
			setInitialValues(values);
		}
	}, [previewUI]);

	/** Activate save button, on any change */
	useEffect(() => {
		if (!initialValues) {
			setIsFormTouched(false);
			setIsResetDisabled(true);
			return;
		}
		const allColors = [];
		COLORS_LIST.forEach((l) => {
			l.forEach((c) => {
				allColors.push(c);
			});
		});
		const initial = {};
		const updated = {};
		Object.keys(previewUI || {}).forEach((k) => {
			if (allColors.includes(k)) {
				initial[k] = initialValues[k];
				updated[k] = previewUI[k];
			}
		});

		setIsFormTouched(isFormChanged(initial, updated));
		setIsResetDisabled(!isFormChanged(TEMPLATES[previewUI["--vs--template"]].colors, updated));
	}, [previewUI, initialValues]);

	return (
		<div className="dashboard-customization-tool-section">
			<h2>{t("pages.dashboard.apikeys.customization.colors")}</h2>
			<div className="dashboard-customization-tool-section-main">
				{Object.keys(previewUI).length
					? COLORS_LIST.map((l, i) => (
							<div className="color-picker-list" key={i}>
								<span className="color-picker-list-title">{i === 0 ? t("pages.dashboard.apikeys.customization.brand_colors") : i === 1 ? t("pages.dashboard.apikeys.customization.background_colors") : t("pages.dashboard.apikeys.customization.text_and_icon_colors")}</span>
								<div className="color-picker-list-items">
									{l.map((c) => (
										<Color color={previewUI[c]} key={c} onChange={(value) => onChange(c, value)} isDisabled={isDisabled} />
									))}
								</div>
								{(i === 1 || i === 2) && (
									<div className="color-picker-generate">
										<Color color={previewUI[COLORS_LIST[i][0]]} onChange={(value) => handleTemplateButtonChange(i, value)} generateAll={true} isDisabled={isDisabled} />
									</div>
								)}
							</div>
						))
					: null}
			</div>
			{!isDisabled && (
				<FormItem className="button-container">
					<Button className="button" onClick={resetToTemplate} disabled={isResetDisabled}>
						<span>{t("pages.dashboard.apikeys.customization.reset_to_default")}</span>
					</Button>
					<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={saveColors} disabled={!isFormTouched}>
						<span>{t("common.save")}</span>
					</Button>
				</FormItem>
			)}
		</div>
	);
};

/** Colors propTypes
 * PropTypes
 */
Colors.propTypes = {
	/** Redux state property, apikey ui data */
	ui: apiKeyUIType,
	/** Redux state property, is true when ui is saving */
	isSaving: PropTypes.bool,
	/** Redux action to save apiKey ui data */
	saveApiKeyUI: PropTypes.func,
	/** Redux action to set apiKey current preview ui data */
	setApiKeyPreviewUI: PropTypes.func,
	/** Redux state property, apikey ui data */
	previewUI: apiKeyUIType,
	/** Function to close tool */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	saveApiKeyUI: (id, parameters, languageCode) => {
		dispatch(saveApiKeyUI(id, parameters, languageCode));
	},
	setApiKeyPreviewUI: (data) => {
		dispatch(setApiKeyPreviewUI(data));
	}
});

const mapStateToProps = (state) => {
	return {
		ui: state.apikeys.editingApikey.customization.ui,
		previewUI: state.apikeys.editingApikey.customization.previewUI,
		isSaving: state.apikeys.isSaving
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Colors);
