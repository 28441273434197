import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Form, Col, Row, Modal, Input, Checkbox, Select } from "antd";
const { Item: FormItem } = Form;

import { createApikey, getApikeys } from "store/actions/dashboard/online/apikeys/apikeys.action";

import Paths from "constants/path.constants";
import { POPUP_SIZE } from "constants/common.constants";
import { INTEGRATION_TYPE } from "constants/apiKey.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import { hasPermission } from "utils/permissions";

import partnerType from "types/partner/partner.type";

/** Api key Creating Popup Component */
const ApiKeyAddComponent = ({ isSaving, createApikey, globalPartnerId, globalPartners, getApikeys, onClose }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;

	/** Fires when form submitted
	 * @function
	 * @memberOf ApiKeyAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ name, isTesting, integrationType }) => {
				createApikey({ name, isTesting, integrationType }, ({ id }) => {
					if (hasPermission({ resource: PERMISSION_RESOURCE.APIKEY, action: PERMISSION_ACTION.MODIFY })) {
						navigate(Paths.DASHBOARD_ONLINE_APIKEYS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, `${id}?PI=${globalPartnerId}`));
					} else {
						getApikeys();
						onClose();
					}
				});
			})
			.catch(Function.prototype);
	};

	const isCurrentPartnerTest = (globalPartners.find((p) => p.id === globalPartnerId))?.isTesting;

	return (
		<Modal open={true} title={t("pages.dashboard.apikeys.create_project")} cancelText={t("common.cancel")} okText={t("common.create")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.SMALL} maskClosable={false} closable={false} okButtonProps={{ loading: isSaving }} centered>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					name: "",
					isTesting: isCurrentPartnerTest,
					integrationType: INTEGRATION_TYPE.IFRAME
				}}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={`${t("pages.dashboard.apikeys.name")} *`} name="name" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
							<Input maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.apikeys.name")}`} autoFocus={true} autoComplete="off" />
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem name="integrationType" label={t("pages.dashboard.apikeys.integration_Type")}>
							<Select getPopupContainer={() => document.body} suffixIcon={<i className="icon-down" />}>
								<Select.Option value={INTEGRATION_TYPE.IFRAME}>{t("pages.dashboard.apikeys.iframe")}</Select.Option>
								<Select.Option value={INTEGRATION_TYPE.FEED}>{t("pages.dashboard.apikeys.feed")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col span={24}>
						<div className="inline-form-checkbox">
							<label>{t("pages.dashboard.apikeys.is_test_project")}</label>
							<FormItem className="inline-form-item-control" name="isTesting" valuePropName="checked">
								<Checkbox disabled={isCurrentPartnerTest} />
							</FormItem>
						</div>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** ApiKeyAddComponent propTypes
 * PropTypes
 */
ApiKeyAddComponent.propTypes = {
	/** Redux state property, is true when creating apiKey request is in process */
	isSaving: PropTypes.bool,
	/** Redux action to create apiKey */
	createApikey: PropTypes.func,
	/** Redux action to get api keys */
	getApikeys: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, represents the array of global partners  */
	globalPartners: PropTypes.arrayOf(partnerType),
	/** Fires on popup close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	createApikey: (apiKey, onSuccess) => {
		dispatch(createApikey(apiKey, onSuccess));
	},
	getApikeys: () => {
		dispatch(getApikeys(true));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.apikeys.isSaving,
		globalPartnerId: state.partner.globalPartnerId,
		globalPartners: state.partner.globalPartners
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeyAddComponent);
