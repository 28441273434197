import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Button, Select, Spin, Switch, Tooltip } from "antd";
const { Item: FormItem } = Form;

import { INTEGRATION_TYPE } from "constants/partner.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { getPartnerOnlineConfigs, savePartnerOnlineConfigs } from "store/actions/dashboard/partners/onlineSettings.action";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import onlineConfigsType from "types/partner/onlineConfigs.type";

import { PROJECT_LIMIT_VALUES } from "constants/common.constants";
import NumericInput from "components/common/numericInput";
import { COMBO_BOOST_STEP_FORM_NAMES, COMBO_BOOST_STEPS, COMBO_BOOST_STEPS_TRANSLATIONS } from "constants/bet.constants";
import { isNullish } from "utils/common";

const comboBoostStepsValues = Object.values(COMBO_BOOST_STEPS)

/** Partner Edit Page Online Tab Online Config subTab Component */
const OnlineConfigsComponent = ({ isSaving, isLoading, onlineConfigs, getPartnerOnlineConfigs, savePartnerOnlineConfigs, onTabChange }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_ONLINE_CONFIGURATION, action: PERMISSION_ACTION.MODIFY });

	/** Load partner online configs */
	useEffect(() => {
		getPartnerOnlineConfigs();
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			...onlineConfigs
		});
	}, [onlineConfigs]);

	/** Fires when form submitted
	 * @function
	 * @memberOf OnlineConfigsComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				const boostFactors = comboBoostStepsValues.reduce((acc, comboBoostStep) => {
					acc[COMBO_BOOST_STEP_FORM_NAMES[comboBoostStep]] = Number(data.comboBoostSettings.boostFactors[COMBO_BOOST_STEP_FORM_NAMES[comboBoostStep]]) || null;
					const resetCondition = (
						comboBoostStepsValues
							.filter(value => value < comboBoostStep)
							.some(value => 100 === (Number(data.comboBoostSettings.boostFactors[value]) || 0))
					)
					if (resetCondition) {
						acc[COMBO_BOOST_STEP_FORM_NAMES[comboBoostStep]] = null;
					}
					return acc
				}, {})
				savePartnerOnlineConfigs({
					...data,
					comboBoostSettings: {
						...data.comboBoostSettings,
						minOddFactor: Number(data.comboBoostSettings.minOddFactor) || 0,
						boostFactors: boostFactors
					}
				});
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					...onlineConfigs,
					comboBoostSettings: {
						...onlineConfigs.comboBoostSettings,
						boostFactors: {
							...onlineConfigs.comboBoostSettings.boostFactors
						}
					}
				}}
				onValuesChange={(changed, formValues) => {
					setIsFormTouched(
						isFormChanged(
							{
								...formValues,
								comboBoostSettings: {
									...formValues.comboBoostSettings,
									boostFactors: {
										...formValues.comboBoostSettings.boostFactors
									}
								}
							},
							{
								...onlineConfigs,
								comboBoostSettings: {
									...onlineConfigs.comboBoostSettings,
									boostFactors: {
										...onlineConfigs.comboBoostSettings.boostFactors
									}
								}
							}
						)
					);
				}}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.partners.online_settings.project_limit")} name="projectCountLimit">
									<Select disabled={isDisabled} suffixIcon={<i className="icon-down" />}>
										<Select.Option value={0}>{t("common.unlimited")}</Select.Option>
										{PROJECT_LIMIT_VALUES.map((el) => (
											<Select.Option value={el} key={el} disabled={el < onlineConfigs.projectCount}>
												{el}
											</Select.Option>
										))}
									</Select>
								</FormItem>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<div className="inline-form-item form-switcher form-item-without-margin">
									<label>{t("pages.dashboard.partners.online_settings.split_stake_feature")}</label>
									<FormItem name="isSplitStakeEnabled" valuePropName="checked" className="ant-row">
										<Switch disabled={isDisabled} />
									</FormItem>
								</div>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<div className="inline-form-item form-switcher form-item-without-margin">
									<label>{t("pages.dashboard.partners.online_settings.allow_bet_cancellation_by_player")}</label>
									<FormItem name="allowBetCancellation" valuePropName="checked" className="ant-row">
										<Switch disabled={isDisabled} />
									</FormItem>
								</div>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h1 className="dashboard-section-name vs--mt-32">{t("pages.dashboard.bets.combo_boost")}</h1>
							</Col>
							<Col span={24} className="vs--mb-18">
								<FormItem shouldUpdate noStyle>
									{({ getFieldValue }) => {
										const isComboBoostEnabled = getFieldValue(["comboBoostSettings", "isComboBoostEnabled"]);
										return (
											<div className="inline-form-item form-switcher form-item-without-margin">
												<label>{t(isComboBoostEnabled ? "common.enabled" : "common.disabled")}</label>
												<FormItem name={["comboBoostSettings", "isComboBoostEnabled"]} valuePropName="checked" className="ant-row">
													<Switch disabled={isDisabled} />
												</FormItem>
											</div>
										);
									}}
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={4}>
								<FormItem noStyle shouldUpdate>
									{({ getFieldValue }) => {
										const isComboBoostEnabled = getFieldValue(["comboBoostSettings", "isComboBoostEnabled"]);
										return (
											<FormItem
												label={`${t("pages.dashboard.bets.combo_boost_minOddFactor")} *`}
												name={["comboBoostSettings", "minOddFactor"]}
												rules={[
													{
														validator: (_, value) => {
															const numericValue = Number(value) || 0;
															if (!isComboBoostEnabled) {
																return Promise.resolve();
															}
															if (numericValue < 1.01 || numericValue > 1000) {
																return Promise.reject(t("validation.must_be_between").replace("%X%", 1.01).replace("%Y%", 1000));
															}

															return Promise.resolve();
														}
													}
												]}
											>
												<NumericInput decimalsCount={2} placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.combo_boost_minOddFactor")}`} disabled={isDisabled || !isComboBoostEnabled} />
											</FormItem>
										);
									}}
								</FormItem>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h1 className="dashboard-section-name vs--mt-32">
									{`${t("pages.dashboard.bets.boost_factor")} *`}
									<Tooltip
										title={t("pages.dashboard.bets.please_set_boost_factor")}
									>
										<i className="icon-info vs--font-bigest vs--cursor-pointer" />
									</Tooltip>
								</h1>
							</Col>
							<Col span={24}>
								<FormItem noStyle shouldUpdate>
									{({ getFieldValue }) => {
										const isComboBoostEnabled = getFieldValue(["comboBoostSettings", "isComboBoostEnabled"]);
										const anyStepHasValue = comboBoostStepsValues.some(value => {
											const fieldValue = getFieldValue(["comboBoostSettings", "boostFactors", COMBO_BOOST_STEP_FORM_NAMES[value]]);
											if (isNullish(fieldValue) || fieldValue === "") {
												return false
											}
											return fieldValue > 0
										})
										return (
											<Row gutter={[16, 0]}>
												{comboBoostStepsValues.map((comboBoostStep) => {
													const name = ["comboBoostSettings", "boostFactors", COMBO_BOOST_STEP_FORM_NAMES[comboBoostStep]];
													const previousComboBoostSteps = comboBoostStepsValues.filter(value => value < comboBoostStep)
													const nextComboBoostSteps = comboBoostStepsValues.filter(value => value > comboBoostStep)
													const disabledByValue = (
														previousComboBoostSteps
															.some(value => 100 === Number(getFieldValue(["comboBoostSettings", "boostFactors", COMBO_BOOST_STEP_FORM_NAMES[value]])) || 0)
													)
													const disabled = isDisabled || !isComboBoostEnabled || disabledByValue;
													const rules = (
														isComboBoostEnabled
															? (
																[
																	{
																		validator: (_, value) => {
																			const numericValue = Number(value) || 0;
																			if (!anyStepHasValue) {
																				return Promise.reject(t("validation.enter_this_or_other_field"));
																			}
																			if (isNullish(value) || value === "") {
																				return Promise.resolve();
																			}
																			let index = null;
																			const biggerExistInNextSteps = nextComboBoostSteps.some((value, i) => {
																				const nextValue = getFieldValue(["comboBoostSettings", "boostFactors", COMBO_BOOST_STEP_FORM_NAMES[value]]);
																				if (isNullish(nextValue) || nextValue === "") {
																					return false
																				}
																				if (numericValue >= Number(nextValue) || 0) {
																					index = i;
																					return true
																				}
																				return false
																			})
																			if (biggerExistInNextSteps) {
																				return Promise.reject(
																					t("validation.must_be_less_and_not_equal_than_other")
																						.replace("%X%", "Field")
																						.replace("%Y%", getFieldValue(["comboBoostSettings", "boostFactors", COMBO_BOOST_STEP_FORM_NAMES[nextComboBoostSteps[index]]]))
																				);
																			}
																			if (numericValue < 1 || numericValue > 100) {
																				return Promise.reject(t("validation.must_be_between").replace("%X%", 1).replace("%Y%", 100));
																			}
																			return Promise.resolve();
																		}
																	}
																]
															)
															: []
													)
													return (
														<Col key={comboBoostStep}>
															<FormItem
																name={name}
																label={t(COMBO_BOOST_STEPS_TRANSLATIONS[comboBoostStep])}
																rules={rules}
																dependencies={
																	comboBoostStepsValues
																		.filter(value => value !== comboBoostStep)
																		.map(value => ["comboBoostSettings", "boostFactors", COMBO_BOOST_STEP_FORM_NAMES[value]])
																}
															>
																<NumericInput isInteger={true} placeholder={`${t("common.enter")} 1 - 100%`} disabled={disabled} />
															</FormItem>
														</Col>
													);
												})}
											</Row>
										);
									}}
								</FormItem>
							</Col>
						</Row>
					</div>
					{!isDisabled && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** OnlineConfigsComponent propTypes
 * PropTypes
 */
OnlineConfigsComponent.propTypes = {
	/** Redux state property, is true when online configs is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when loading online configs data */
	isLoading: PropTypes.bool,
	/** Redux state, represents the online configs of current editing partner  */
	onlineConfigs: onlineConfigsType,
	/** Redux action to get partner online configs */
	getPartnerOnlineConfigs: PropTypes.func,
	/** Redux action to save partner online configs */
	savePartnerOnlineConfigs: PropTypes.func,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getPartnerOnlineConfigs: () => {
		dispatch(getPartnerOnlineConfigs());
	},

	savePartnerOnlineConfigs: (data) => {
		dispatch(savePartnerOnlineConfigs(data));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.partners.isSaving,
		isLoading: state.partner.isLoading,
		onlineConfigs: state.partner.onlineSettings.onlineConfigs
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OnlineConfigsComponent);
