import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Tabs } from "antd";

import { useTranslation } from "react-i18next";

import PasswordSettingsComponent from "./password-settings";
import TokenMultideviceComponent from "./token-multidevice";
import AuthenticationSettingsComponent from "./authentication-settings";

import { updateLocationHash, getHashValue } from "utils/common";

/** Security Tab Component */
const SecuritySettingsComponent = ({ onTabChange }) => {
	const { t } = useTranslation();

	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf SecuritySettingsComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	useEffect(() => {
		onTabChange(unsavedTabs.length > 0);
	}, [unsavedTabs]);

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf SecuritySettingsComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	return (
		<Tabs
			animated={false}
			className="sub-tabs"
			defaultActiveKey={getHashValue("subtab")}
			onChange={(key) => updateLocationHash("subtab=" + key, true)}
			items={[
				{
					key: "1",
					label: <span className={tabClassName("1")}>{t("pages.dashboard.partners.security_settings.tabs.password_settings")}</span>,
					children: <PasswordSettingsComponent onTabChange={(status) => changeTabSavedStatus(status, "1")} />
				},
				{
					key: "2",
					label: <span className={tabClassName("2")}>{t("pages.dashboard.partners.security_settings.tabs.token_multidevice")}</span>,
					children: <TokenMultideviceComponent onTabChange={(status) => changeTabSavedStatus(status, "2")} />
				},
				{
					key: "3",
					label: <span className={tabClassName("3")}>{t("pages.dashboard.partners.security_settings.tabs.authentication_settings")}</span>,
					children: <AuthenticationSettingsComponent onTabChange={(status) => changeTabSavedStatus(status, "3")} />
				}
			]}
		/>
	);
};

/** PropbabilitiesComponent propTypes
 * PropTypes
 */
SecuritySettingsComponent.propTypes = {
	/** Fires when for any form in tab, saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

export default SecuritySettingsComponent;
