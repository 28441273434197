import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getApiKeyGeneralInfo } from "store/actions/dashboard/online/apikeys/generalInfo.action";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Tabs } from "antd";
import Tools from "./tools";
import { updateLocationHash, getHashValue } from "utils/common";
import Paths from "constants/path.constants";
import { GAME_CATEGORY } from "constants/game.constants";

/** Analytical Tools Component */
const AnalyticalToolsComponent = ({ gameCategoryList, onTabChange }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Function to make subtab class name
	 * @function
	 * @description addes class "unsaved-subtab" if subtab has unsaved changes
	 * @param {string} key - subtab key
	 * @returns {string}
	 * @memberOf AnalyticalToolsComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-subtab" : "");

	/** Fires when subtab saved status changed
	 * @function
	 * @param {boolean} status - does subtab have unsaved change
	 * @param {string} key - subtab key
	 * @memberOf AnalyticalToolsComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	return (
		<Fragment>
			<Tabs
				className="sub-tabs"
				animated={false}
				defaultActiveKey={getHashValue("subtab") || (gameCategoryList.includes(GAME_CATEGORY.SCHEDULED) ? "1" : gameCategoryList.includes(GAME_CATEGORY.INSTANT) ? "2" : "0")}
				onChange={(key) => updateLocationHash("subtab=" + key)}
				items={[
					gameCategoryList.includes(GAME_CATEGORY.SCHEDULED)
						? {
								key: "1",
								label: <span className={tabClassName("1")}>{t("pages.dashboard.apikeys.tabs.games_subtab_1")}</span>,
								children: <Tools gameCategory={GAME_CATEGORY.SCHEDULED} onTabChange={onTabChange} />
							}
						: null,
					gameCategoryList.includes(GAME_CATEGORY.INSTANT)
						? {
								key: "2",
								label: <span className={tabClassName("2")}>{t("pages.dashboard.apikeys.tabs.games_subtab_2")}</span>,
								children: <Tools gameCategory={GAME_CATEGORY.INSTANT} onTabChange={onTabChange} />
							}
						: null
				]}
			/>
		</Fragment>
	);
};

/** AnalyticalToolsComponent propTypes
 * PropTypes
 */
AnalyticalToolsComponent.propTypes = {
	/** Redux state, game category list  */
	gameCategoryList: PropTypes.arrayOf(PropTypes.oneOf(Object.values(GAME_CATEGORY))),
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		gameCategoryList: state.apikeys.editingApikey.generalInfo.gameCategoryList
	};
};

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticalToolsComponent);
