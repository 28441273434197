import { Fragment } from "react";

import BetshopPerformanceComponent from "components/dashboard/reports/betshopPerformance/betshopPerformance.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const BetshopPerformanceRoute = () => {
	return (
		<Fragment>
			<BetshopPerformanceComponent />
		</Fragment>
	);
};

export default withPermission(BetshopPerformanceRoute, { resource: PERMISSION_RESOURCE.REPORTS_BETSHOP_PERFORMANCE, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
