import { Fragment } from "react";
import PropTypes from "prop-types";

import GamesComponent from "components/dashboard/virtuals/games/games.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { GAME_CATEGORY } from "constants/game.constants";

const GamesRouteComponent = ({ gameCategory }) => {
	return (
		<Fragment>
			<GamesComponent gameCategory={gameCategory} />
		</Fragment>
	);
};

GamesRouteComponent.propTypes = {
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const GamesRoute = withPermission(GamesRouteComponent, { resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);

export const ScheduledGameRoute = () => {
	return <GamesRoute gameCategory={GAME_CATEGORY.SCHEDULED} />;
};

export const InstantGameRoute = () => {
	return <GamesRoute gameCategory={GAME_CATEGORY.INSTANT} />;
};
