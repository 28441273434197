import { Fragment } from "react";

import PermissionsComponent from "components/dashboard/userManagement/permissions/permissions.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const PermissionsRoute = () => {
	return (
		<Fragment>
			<PermissionsComponent />
		</Fragment>
	);
};

export default withPermission(PermissionsRoute, { resource: PERMISSION_RESOURCE.PERMISSION, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
