import PropTypes from "prop-types";
import { connect } from "react-redux";
import TransactionsFilters from "../../../filters.component";
import { setTransactionsFilters, getTransactions } from "store/actions/dashboard/transactions/transactions.action";
import { PROJECT_TYPE } from "constants/common.constants";
import { TRANSACTION_TYPE } from "constants/transactions.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";

/** Retail Transactions Page Filters Component */
const Filters = ({ setTransactionsFilters, getTransactions, filters, globalPartnerId }) => {
	return <TransactionsFilters getTransactions={getTransactions} setTransactionsFilters={setTransactionsFilters} projectNamesAutosuggestionType={AUTOSUGGESTION_TYPE.BETSHOP_NAMES} globalPartnerId={globalPartnerId} filters={filters} projectType={PROJECT_TYPE.RETAIL} />;
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get retail transactions */
	getTransactions: PropTypes.func,
	/** Redux action to set retail transactions filters */
	setTransactionsFilters: PropTypes.func,
	/** Redux state property, retail transactions filters */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getTransactions: () => {
		dispatch(getTransactions(PROJECT_TYPE.RETAIL, TRANSACTION_TYPE.RESULT));
	},
	setTransactionsFilters: (filters) => {
		dispatch(setTransactionsFilters(filters, PROJECT_TYPE.RETAIL, TRANSACTION_TYPE.RESULT));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.transactions.results.retail.filters,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
