import moment from "moment";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { getErrors } from "../errors/errors.action";

import { MONITORING_ACTION_BEFORE, MONITORING_ACTION_FINISH, MONITORING_ACTION_SERVICE_BEFORE, MONITORING_ACTION_SERVICE_FINISH, MONITORING_SET_SERVICE, MONITORING_SET_SERVICES } from "store/actionTypes";

const setMonitoringActionBefore = () => ({
	type: MONITORING_ACTION_BEFORE
});

const setMonitoringActionFinished = () => ({
	type: MONITORING_ACTION_FINISH
});

const setMonitoringServiceActionBefore = () => ({
	type: MONITORING_ACTION_SERVICE_BEFORE
});

const setMonitoringServiceActionFinished = () => ({
	type: MONITORING_ACTION_SERVICE_FINISH
});

const setMonitoringService = (service) => ({
	type: MONITORING_SET_SERVICE,
	payload: { service }
});

const setMonitoringServices = (services) => ({
	type: MONITORING_SET_SERVICES,
	payload: { services }
});

export const getMonitoringServices = () => {
	return (dispatch) => {
		dispatch(setMonitoringActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_MONITORING_ALL}`,
			method: Methods.GET
		})
			.then(({ data: { value: services } }) => {
				dispatch(setMonitoringServices(services));
				dispatch(setMonitoringActionFinished());
			})
			.catch(() => {
				dispatch(setMonitoringActionFinished());
			});
	};
};

export const getMonitoringService = (id) => {
	return (dispatch) => {
		dispatch(setMonitoringServiceActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_MONITORING}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: service } }) => {
				dispatch(setMonitoringService(service));
				dispatch(setMonitoringServiceActionFinished());
			})
			.catch(() => {
				dispatch(setMonitoringServiceActionFinished());
			});
	};
};

export const getServiceErrors = (name) => {
	return (dispatch) => {
		dispatch(getErrors({ controller: name, year: moment().year(), month: moment().month() + 1 }));
	};
};
