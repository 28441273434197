import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import {
	BET_ANOMALIES_CONFIGURATIONS_ACTION_BEFORE,
	BET_ANOMALIES_CONFIGURATIONS_ACTION_FINISH,
	BET_ANOMALIES_CONFIGURATIONS_SAVE_ACTION_BEFORE,
	BET_ANOMALIES_CONFIGURATIONS_SAVE_ACTION_FINISH,
	BET_ANOMALIES_HISTORY_BEFORE,
	BET_ANOMALIES_HISTORY_FINISH,
	BET_ANOMALY_SAVE_BEFORE,
	BET_ANOMALY_SAVE_FINISH,
	BET_ANOMALY_UPDATE,
	SET_BET_ANOMALIES_CONFIGURATIONS,
	SET_BET_ANOMALIES_HISTORY,
	SET_BET_ANOMALIES_HISTORY_FILTERS,
	SET_BET_ANOMALIES_HISTORY_SORTING
} from "store/actionTypes";

export const setBetAnomaliesConfigurationsActionBefore = () => ({
	type: BET_ANOMALIES_CONFIGURATIONS_ACTION_BEFORE
});

export const setBetAnomaliesConfigurationsActionFinish = () => ({
	type: BET_ANOMALIES_CONFIGURATIONS_ACTION_FINISH
});

export const setBetAnomaliesConfigurationsSaveActionBefore = () => ({
	type: BET_ANOMALIES_CONFIGURATIONS_SAVE_ACTION_BEFORE
})

export const setBetAnomaliesConfigurationsSaveActionFinish = () => ({
	type: BET_ANOMALIES_CONFIGURATIONS_SAVE_ACTION_FINISH
})

export const setBetAnomaliesConfigurationsData = (data) => ({
	type: SET_BET_ANOMALIES_CONFIGURATIONS,
	payload: { data }
})

export const getBetAnomaliesConfigurationsData = () => {
	return (dispatch, getState) => {
		dispatch(setBetAnomaliesConfigurationsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_RISK_MANAGEMENT_BET_ANOMALIES_CONFIGURATIONS}`,
			method: Methods.GET,
		})
			.then(({ data: { value: data } }) => {
				dispatch(setBetAnomaliesConfigurationsData(data));
			})
			.finally(() => {
				dispatch(setBetAnomaliesConfigurationsActionFinish());
			});
	};
};

export const saveBetAnomaliesConfigurationsData = (data) => {
	return (dispatch, getState) => {
		dispatch(setBetAnomaliesConfigurationsSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_RISK_MANAGEMENT_BET_ANOMALIES_CONFIGURATIONS}`,
			method: Methods.POST,
			data: { ...data }
		})
			.then(({ data: { value, message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
				}
				dispatch(setBetAnomaliesConfigurationsData(value));
			})
			.finally(() => {
				dispatch(setBetAnomaliesConfigurationsSaveActionFinish());
			});
	};
};

export const setBetAnomaliesHistoryBefore = () => ({
	type: BET_ANOMALIES_HISTORY_BEFORE
});

export const setBetAnomaliesHistoryFinish = () => ({
	type: BET_ANOMALIES_HISTORY_FINISH
});


export const setBetAnomaliesHistoryFilters = (filters) => ({
	type: SET_BET_ANOMALIES_HISTORY_FILTERS,
	payload: { filters }
})

export const setBetAnomaliesHistorySorting = (sorting) => ({
	type: SET_BET_ANOMALIES_HISTORY_SORTING,
	payload: { sorting }
});

export const setBetAnomaliesHistory = (payload) => ({
	type: SET_BET_ANOMALIES_HISTORY,
	payload: payload
});

export const getBetAnomaliesHistory = (fromFirstPage) => {
	return (dispatch, getState) => {
		dispatch(setBetAnomaliesHistoryBefore());
		const historyState = getState().riskManagement.betAnomalies.history;
		const { partnerName, ...filters } = historyState.filters;

		const params = {
			...historyState.sorting,
			...filters,
			page: fromFirstPage ? 1 : historyState.sorting.page
		};
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_RISK_MANAGEMENT_BET_ANOMALIES_HISTORY}`,
			method: Methods.GET,
			params: params
		})
			.then(({ data: { value } }) => {
				dispatch(setBetAnomaliesHistory({ total: value.item1, data: value.item2 }));
				if (fromFirstPage) {
					dispatch(setBetAnomaliesHistorySorting({ ...getState().riskManagement.betAnomalies.history.sorting, page: 1 }));
				}
			})
			.finally(() => {
				dispatch(setBetAnomaliesHistoryFinish());
			});
	};
}

export const updateBetAnomaly = (data) => ({
	type: BET_ANOMALY_UPDATE,
	payload: { data }
})

export const saveBetAnomaly = (data) => {
	return (dispatch, getState) => {
		dispatch(saveBetAnomalySaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_RISK_MANAGEMENT_BET_ANOMALY}`,
			method: Methods.POST,
			data: { ...data }
		})
			.then(({ data: { value, message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
				}
				dispatch(updateBetAnomaly(value));
			})
			.finally(() => {
				dispatch(saveBetAnomalySaveActionFinish());
			});
	};
};

export const saveBetAnomalySaveActionBefore = () => ({
	type: BET_ANOMALY_SAVE_BEFORE
})

export const saveBetAnomalySaveActionFinish = () => ({
	type: BET_ANOMALY_SAVE_FINISH
})