import PropTypes from "prop-types";

export default PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	secretKey: PropTypes.string,
	endPointUrl: PropTypes.string,
	lastUpdatedDate: PropTypes.string,
	lastUpdatedBy: PropTypes.string
});
