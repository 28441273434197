import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Col, Row, Modal } from "antd";
const { Item: FormItem } = Form;

import SearchableSelect from "components/common/searchableSelect";

import { addApiKeyBlockedCountry } from "store/actions/dashboard/online/apikeys/blockedCountries.action";

import apiKeyBlockedCountryType from "types/apiKey/blockedCountry.type";

import { POPUP_SIZE } from "constants/common.constants";

import systemCountries from "systemData/countries";

/** Apikey Blocked Country Adding Popup Component */
const BlockedCountryAddComponent = ({ isSaving, addApiKeyBlockedCountry, onClose, countries }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;

	/** Fires when form submitted
	 * @function
	 * @memberOf BlockedCountryAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ countryCode }) => {
				addApiKeyBlockedCountry(routeParams.id, countryCode);
			})
			.catch(Function.prototype);
	};

	return (
		<Modal open={true} title={t("pages.dashboard.apikeys.add_country")} cancelText={t("common.cancel")} okText={t("common.save")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.SMALL} maskClosable={false} closable={false} okButtonProps={{ loading: isSaving }} centered>
			<Form className="dashboard-form" form={formInstance} colon={false} layout="vertical" requiredMark={false}>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={t("pages.dashboard.apikeys.black_list_country")} name="countryCode" rules={[{ required: true, message: t("validation.field_required") }]}>
							<SearchableSelect
								placeholder={t("pages.dashboard.apikeys.select_country")}
								items={Object.keys(systemCountries).sort((a, b) => (systemCountries[a] > systemCountries[b] ? 1 : -1))}
								valueProp={(item) => item}
								textProp={(item) => systemCountries[item]}
								renderText={(item) => systemCountries[item]}
								getPopupContainer={() => document.body}
								isOptionDisabled={(item) => countries.map((c) => c.countryCode.toLowerCase()).includes(item)}
							/>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** BlockedCountryAddComponent propTypes
 * PropTypes
 */
BlockedCountryAddComponent.propTypes = {
	/** Redux state property, is true when adding blocked country for api key */
	isSaving: PropTypes.bool,
	/** Redux action to add blocked country for api key*/
	addApiKeyBlockedCountry: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Redux state property, represents the array of blocked countries of api key */
	countries: PropTypes.arrayOf(apiKeyBlockedCountryType)
};

const mapDispatchToProps = (dispatch) => ({
	addApiKeyBlockedCountry: (id, countryCode) => {
		dispatch(addApiKeyBlockedCountry(id, countryCode));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.apikeys.isSaving,
		countries: state.apikeys.editingApikey.ipRestriction.blockedCountries.countries
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockedCountryAddComponent);
