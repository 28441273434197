import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { APIKEY_GAME_OTHER_CONFIGS_BEFORE, APIKEY_GAME_OTHER_CONFIGS_FINISH, SET_APIKEY_GAME_OTHER_CONFIGS } from "store/actionTypes";
import { setApikeySaveActionBefore, setApikeySaveActionFinished } from "./apikeys.action";
import { getApiKeyGames } from "./games.action";
import { GAME_CATEGORY } from "constants/game.constants";

const setApiKeyGameOtherConfigsBefore = () => ({
	type: APIKEY_GAME_OTHER_CONFIGS_BEFORE
});

const setApiKeyGameOtherConfigsFinished = () => ({
	type: APIKEY_GAME_OTHER_CONFIGS_FINISH
});

const setApikeyGameOtherConfigs = (data) => ({
	type: SET_APIKEY_GAME_OTHER_CONFIGS,
	payload: { data }
});

export const getApiKeyGameOtherConfigs = ({ id, gameCategory, gameId }) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.APIKEY_GET_SCHEDULED_GAME_OTHER_CONFIGS : ApiUrls.APIKEY_GET_INSTANT_GAME_OTHER_CONFIGS;
	return (dispatch) => {
		dispatch(setApiKeyGameOtherConfigsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.GET,
			params: { id, gameId }
		})
			.then(({ data: { value } }) => {
				dispatch(setApikeyGameOtherConfigs(value));
			})
			.finally(() => {
				dispatch(setApiKeyGameOtherConfigsFinished());
			});
	};
};

export const saveApiKeyGameOtherConfigs = ({ data, gameCategory, callback }) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.APIKEY_SAVE_SCHEDULED_GAME_OTHER_CONFIGS : ApiUrls.APIKEY_SAVE_INSTANT_GAME_OTHER_CONFIGS;
	return (dispatch) => {
		dispatch(setApikeySaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.POST,
			data
		})
			.then(({ data: { value } }) => {
				dispatch(setApikeyGameOtherConfigs(value));
				dispatch(getApiKeyGames(data.id, gameCategory));
			})
			.finally(() => {
				dispatch(setApikeySaveActionFinished());
				if (typeof callback === "function") {
					callback();
				}
			});
	};
};
