import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Button, Select, Row, Col } from "antd";
const { Item: FormItem } = Form;

import apiKeyUIType from "types/apiKey/ui.type";

import { saveApiKeyUI, setApiKeyPreviewUI } from "store/actions/dashboard/online/apikeys/customization.action";

import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const FONT_FAMILIES = ["Roboto, sans-serif", "Arial, Helvetica, sans-serif", "Impact, Charcoal, sans-serif", "Tahoma, Geneva, sans-serif", "Trebuchet MS, Helvetica, sans-serif", "Verdana, Geneva, sans-serif"];

/** Customization Tool Fonts Component */
const Fonts = ({ ui, isSaving, saveApiKeyUI, setApiKeyPreviewUI, previewUI, onClose }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [initialFontFamily, setInitialFontFamily] = useState(FONT_FAMILIES[0]);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_CUSTOMIZATION, action: PERMISSION_ACTION.MODIFY });

	/** Set initial font family */
	useEffect(() => {
		if (ui.launchUrl) {
			//Check if font family exist
			const fontFamily = previewUI["--font-family"];
			if (!fontFamily) {
				setApiKeyPreviewUI({
					"--font-family": FONT_FAMILIES[0]
				});
			} else {
				setInitialFontFamily(previewUI["--font-family"]);
			}
		}
	}, [ui]);

	/** Function to save font family
	 * @function
	 * @memberOf Fonts
	 */
	const saveFontFamily = () => {
		const parameters = ui.parameters || [];

		saveApiKeyUI(routeParams.id, parameters.filter((p) => p.key !== "--font-family").concat([{ key: "--font-family", value: previewUI["--font-family"] }]), "EN");
		setInitialFontFamily(previewUI["--font-family"]);
		onClose();
	};

	/** Function to reset font family to default template
	 * @function
	 * @memberOf Fonts
	 */
	const resetToTemplate = () => {
		setApiKeyPreviewUI({
			"--font-family": FONT_FAMILIES[0]
		});
	};

	return (
		<div className="dashboard-customization-tool-section">
			<h2>{t("pages.dashboard.apikeys.customization.fonts")}</h2>
			<div className="dashboard-customization-tool-section-main">
				<Form colon={false} requiredMark={false} layout="vertical">
					<Row gutter={[16, 0]}>
						<Col span={24}>
							<FormItem label={t("pages.dashboard.apikeys.customization.main_font")}>
								<Select
									value={previewUI["--font-family"]}
									onChange={(f) =>
										setApiKeyPreviewUI({
											"--font-family": f
										})
									}
									suffixIcon={<i className="icon-down" />}
									disabled={isDisabled}
								>
									{FONT_FAMILIES.map((font) => (
										<Select.Option value={font} key={font}>
											{font}
										</Select.Option>
									))}
								</Select>
							</FormItem>
						</Col>
					</Row>
				</Form>
			</div>

			{!isDisabled && (
				<FormItem className="button-container">
					<Button className="button" onClick={resetToTemplate} disabled={previewUI["--font-family"] === FONT_FAMILIES[0]}>
						<span>{t("pages.dashboard.apikeys.customization.reset_to_default")}</span>
					</Button>
					<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={saveFontFamily} disabled={initialFontFamily === previewUI["--font-family"]}>
						<span>{t("common.save")}</span>
					</Button>
				</FormItem>
			)}
		</div>
	);
};

/** Fonts propTypes
 * PropTypes
 */
Fonts.propTypes = {
	/** Redux state property, apikey ui data */
	ui: apiKeyUIType,
	/** Redux state property, is true when ui is saving */
	isSaving: PropTypes.bool,
	/** Redux action to save apiKey ui data */
	saveApiKeyUI: PropTypes.func,
	/** Redux action to set apiKey current preview ui data */
	setApiKeyPreviewUI: PropTypes.func,
	/** Redux state property, apikey ui data */
	previewUI: apiKeyUIType,
	/** Function to close tool */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	saveApiKeyUI: (id, parameters, languageCode) => {
		dispatch(saveApiKeyUI(id, parameters, languageCode));
	},
	setApiKeyPreviewUI: (data) => {
		dispatch(setApiKeyPreviewUI(data));
	}
});

const mapStateToProps = (state) => {
	return {
		ui: state.apikeys.editingApikey.customization.ui,
		previewUI: state.apikeys.editingApikey.customization.previewUI,
		isSaving: state.apikeys.isSaving
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Fonts);
