import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Table from "components/common/table";

import languageType from "types/language/language.type";

import { getPartnerLanguages } from "store/actions/dashboard/partners/languages.action";

/** Partner Edit Page Languages Tab Component */
const LanguageComponent = ({ languages, getPartnerLanguages, isLoading }) => {
	const { t } = useTranslation();

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.partners.language.name"),
			dataIndex: "name",
			sorter: false
		},
		{
			title: t("pages.dashboard.partners.language.code"),
			dataIndex: "code",
			sorter: false
		},
		{
			title: t("pages.dashboard.partners.language.published"),
			dataIndex: "isPublished",
			sorter: false,
			render: (value) => (value ? t("common.yes") : t("common.no"))
		}
	];

	return (
		<div className="dashboard-section-content">
			<Table loading={isLoading} columns={columns} data={languages} loadFn={getPartnerLanguages} total={languages.length} actions={{}} isDisabled={(record) => !record.enabled} noPagination={true} uniqueKey="code" />
		</div>
	);
};

/** LanguageComponent propTypes
 * PropTypes
 */
LanguageComponent.propTypes = {
	/** Redux action to get partner languages */
	getPartnerLanguages: PropTypes.func,
	/** Redux state property, represents the array of partner languages  */
	languages: PropTypes.arrayOf(languageType),
	/** Redux state property, is true when loading partner languages */
	isLoading: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	getPartnerLanguages: () => {
		dispatch(getPartnerLanguages());
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.partner.language.isLoading,
		languages: state.partner.language.languages
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageComponent);
