import { Fragment } from "react";
import PropTypes from "prop-types";

import EventsComponent from "components/dashboard/events/events.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { PROJECT_TYPE } from "constants/common.constants";

const EventsRouteComponent = ({ projectType }) => {
	return (
		<Fragment>
			<EventsComponent projectType={projectType} />
		</Fragment>
	);
};

EventsRouteComponent.propTypes = {
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE))
};

const EventsRoute = withPermission(EventsRouteComponent, { resource: PERMISSION_RESOURCE.EVENTS, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);

export const OnlineEventsRoute = () => {
	return <EventsRoute projectType={PROJECT_TYPE.ONLINE} />;
};

export const RetailEventsRoute = () => {
	return <EventsRoute projectType={PROJECT_TYPE.RETAIL} />;
};
