import { Provider } from "react-redux";

import { message } from "antd";

import "translations/config";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import Paths from "constants/path.constants";

import store from "store/configureStore";

import "utils/axios";

import { isAuthenticated, startWatchingRefreshTokenExpiration } from "utils/auth";

// Layouts

import RootLayout from "layouts/root.layout";
import AuthLayoutRoute from "layouts/auth.layout";
import DashboardLayoutRoute from "layouts/dashboard.layout";

// Routes
import LoginRoute from 'routes/auth/login.route';
import ActivateRoute from 'routes/auth/activate.route';
import ResetPasswordRoute from 'routes/auth/resetPassword.route';
import ForgotPasswordRoute from 'routes/auth/forgotPassword.route';
import DashboardRoute from 'routes/dashboard/dashboard.route';
import StatisticsRoute from 'routes/dashboard/statistics/statistics.route';
import ProfileRoute from 'routes/dashboard/profile/profile.route';
import UsersRoute from 'routes/dashboard/userManagement/users/users.route';
import UsersEditRoute from 'routes/dashboard/userManagement/users/users-edit.route';
import AccessManagerRoute from 'routes/dashboard/userManagement/accessManager/accessManager.route';
import AccessManagerEditRoute from 'routes/dashboard/userManagement/accessManager/accessManager-edit.route';
import PermissionsRequestsRoute from 'routes/dashboard/userManagement/permissionsRequests/permissionsRequests.route';
import PermissionsRoute from 'routes/dashboard/userManagement/permissions/permissions.route';
import PermissionGroupsRoute from 'routes/dashboard/userManagement/permissionGroups/permissionGroups.route';
import PermissionGroupsEditRoute from 'routes/dashboard/userManagement/permissionGroups/permissionGroups-edit.route';
import PartnersRoute from 'routes/dashboard/partners/partners.route';
import PartnersEditRoute from 'routes/dashboard/partners/partners-edit.route';
import PartnersViewRoute from 'routes/dashboard/partners/partners-view.route';
import ApikeysRoute from 'routes/dashboard/online/apikeys/apikeys.route';
import ApikeysEditRoute from 'routes/dashboard/online/apikeys/apikeys-edit.route';
import { OnlineBetslipsRoute, RetailBetslipsRoute } from 'routes/dashboard/betslips/betslips.route';
import { OnlineEventsRoute, RetailEventsRoute } from 'routes/dashboard/events/events.route';
import { OnlineEventViewRoute, RetailEventViewRoute } from 'routes/dashboard/events/events-view.route';
import PlayersRoute from 'routes/dashboard/online/players/players.route';
import PlayersViewRoute from 'routes/dashboard/online/players/players-view.route';
import ErrorsRoute from 'routes/dashboard/developer/errors/errors.route';
import RequestsRoute from 'routes/dashboard/developer/requests/requests.route';
import ErrorRoute from 'routes/dashboard/developer/errors/error.route';
import JobsRoute from 'routes/dashboard/developer/jobs/jobs.route';
import MonitoringRoute from 'routes/dashboard/developer/monitoring/monitoring.route';
import DeveloperSpaceStreamsRoute from 'routes/dashboard/developer/streams/streams.route';
import DeveloperSpaceServerStreamsRoute from 'routes/dashboard/developer/streamServers/streamServers.route';
import DeveloperPostDeploymentActionsRoute from 'routes/dashboard/developer/postDeploymentActions/postDeploymentActions.route';
import { ScheduledGameRoute, InstantGameRoute } from 'routes/dashboard/virtuals/games/games.route';
import { ScheduledGameEditRoute, InstantGameEditRoute } from 'routes/dashboard/virtuals/games/game-edit.route';
import BoxesRoute from 'routes/dashboard/retail/boxes/boxes.route';
import BoxEditRoute from 'routes/dashboard/retail/boxes/box-edit.route';
import BetshopsRoute from 'routes/dashboard/retail/betshops/betshops.route';
import BetshopEditRoute from 'routes/dashboard/retail/betshops/betshop-edit.route';
import BetshopViewRoute from 'routes/dashboard/retail/betshops/betshop-view.route';
import CashiersRoute from 'routes/dashboard/retail/cashierManagement/cashiers/cashiers.route';
import CashierEditRoute from 'routes/dashboard/retail/cashierManagement/cashiers/cashier-edit.route';
import BonusesStandardRoute from 'routes/dashboard/retail/bonuses/bonuses-standard.route';
import BonusStandardEditRoute from 'routes/dashboard/retail/bonuses/bonus-standard-edit.route';
import BonusesJackpotRoute from 'routes/dashboard/retail/bonuses/bonuses-jackpot.route';
import BonusJackpotEditRoute from 'routes/dashboard/retail/bonuses/bonus-jackpot-edit.route';
import TransactionsRoute from 'routes/dashboard/transactions/transactions.route';
import UserLogsRoute from 'routes/dashboard/userLogs/userLogs.route';
import SystemCurrenciesRoute from 'routes/dashboard/settings/systemCurrencies/systemCurrencies.route';
import SystemLanguagesRoute from 'routes/dashboard/settings/systemLanguages/systemLanguages.route';
import ProjectPerformanceReportRoute from 'routes/dashboard/reports/projectPerformance/projectPerformance.route';
import BetshopPerformanceReportRoute from 'routes/dashboard/reports/betshopPerformance/betshopPerformance.route';
import PartnerOnlineReportRoute from 'routes/dashboard/reports/partnerOnlineReport/partnerOnlineReport.route';
import TranslationsRoute from 'routes/dashboard/cms/translations/translations.route';
import LiveMonitorTranslationsRoute from 'routes/dashboard/cms/liveMonitorTranslations/liveMonitorTranslations.route';
import RulesRoute from 'routes/dashboard/cms/rules/rules.route';
import { ScheduledRuleEditRoute, InstantRuleEditRoute } from 'routes/dashboard/cms/rules/rule-edit.route';
import TicketsRoute from 'routes/dashboard/cms/tickets/tickets.route';
import HotkeysRoute from 'routes/dashboard/cms/hotkeys/hotkeys.route';
import MaintenanceModeRoute from 'routes/dashboard/settings/maintenanceMode/maintenanceMode.route';
import OperatorsRoute from 'routes/dashboard/developer/operators/operators.route';
import CSATRoute from 'routes/dashboard/usersFeedback/CSAT/CSAT.route';
import SurveyEditRoute from 'routes/dashboard/usersFeedback/CSAT/survey-edit.route';
import DashboardStreamsRoute from 'routes/dashboard/streams/streams.route';
import StreamsEditRoute from 'routes/dashboard/streams/streams-edit.route';
import BetCorrectionRoute from 'routes/dashboard/developer/betCorrection/betCorrection.route';
import BigMultipliersRoute from "routes/dashboard/riskManagement/bigMultipliers/bigMultipliers.route";
import BetAnomaliesRoute from "routes/dashboard/riskManagement/betAnomalies/betAnomalies.route";
import PlayerSuspiciousActivities from "routes/dashboard/riskManagement/playerSuspiciousActivities/playerSuspiciousActivities.route";


if (isAuthenticated()) {
	startWatchingRefreshTokenExpiration();
}

message.config({
	top: 64
});

const App = () => {
	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route path={Paths.DEFAULT} element={<RootLayout />} errorElement={<></>}>
				<Route path={Paths.LOGIN} element={<AuthLayoutRoute />}>
					<Route path="" element={<LoginRoute />} />
				</Route>
				<Route path={Paths.ACTIVATE} element={<AuthLayoutRoute />}>
					<Route path="" element={<ActivateRoute />} />
				</Route>
				<Route path={Paths.RESET_PASSWORD} element={<AuthLayoutRoute />}>
					<Route path="" element={<ResetPasswordRoute />} />
				</Route>
				<Route path={Paths.FORGOT_PASSWORD} element={<AuthLayoutRoute />}>
					<Route path="" element={<ForgotPasswordRoute />} />
				</Route>

				<Route path={Paths.DASHBOARD} element={<DashboardLayoutRoute />}>
					<Route path="" element={<DashboardRoute />} />
					<Route path={Paths.DASHBOARD_PROFILE} element={<ProfileRoute />} />
					<Route path={Paths.DASHBOARD_STATISTICS} element={<StatisticsRoute />} />
					<Route path={Paths.DASHBOARD_SCHEDULED_GAMES} element={<ScheduledGameRoute />} />
					<Route path={Paths.DASHBOARD_SCHEDULED_GAMES_EDIT} element={<ScheduledGameEditRoute />} />
					<Route path={Paths.DASHBOARD_INSTANT_GAMES} element={<InstantGameRoute />} />
					<Route path={Paths.DASHBOARD_INSTANT_GAMES_EDIT} element={<InstantGameEditRoute />} />
					<Route path={Paths.DASHBOARD_BOXES} element={<BoxesRoute />} />
					<Route path={Paths.DASHBOARD_BOXES_EDIT} element={<BoxEditRoute />} />
					<Route path={Paths.DASHBOARD_PARTNERS_EDIT} element={<PartnersEditRoute />} />
					<Route path={Paths.DASHBOARD_PARTNERS_VIEW} element={<PartnersViewRoute />} />
					<Route path={Paths.DASHBOARD_PARTNERS} element={<PartnersRoute />} />
					<Route path={Paths.DASHBOARD_RISK_MANAGEMENT_BIG_MULTIPLIERS} element={<BigMultipliersRoute />} />
					<Route path={Paths.DASHBOARD_RISK_MANAGEMENT_BET_ANOMALIES} element={<BetAnomaliesRoute />} />
					<Route path={Paths.DASHBOARD_RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES} element={<PlayerSuspiciousActivities />} />
					<Route path={Paths.DASHBOARD_USERMANAGEMENT_USERS} element={<UsersRoute />} />
					<Route path={Paths.DASHBOARD_USERMANAGEMENT_USERS_EDIT} element={<UsersEditRoute />} />
					<Route path={Paths.ACCESS_MANAGERS} element={<AccessManagerRoute />} />
					<Route path={Paths.ACCESS_MANAGERS_EDIT} element={<AccessManagerEditRoute />} />
					<Route path={Paths.PERMISSION_REQUESTS} element={<PermissionsRequestsRoute />} />
					<Route path={Paths.DASHBOARD_USERMANAGEMENT_PERMISSIONS} element={<PermissionsRoute />} />
					<Route path={Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS} element={<PermissionGroupsRoute />} />
					<Route path={Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS_EDIT} element={<PermissionGroupsEditRoute />} />
					<Route path={Paths.DASHBOARD_ONLINE_APIKEYS} element={<ApikeysRoute />} />
					<Route path={Paths.DASHBOARD_ONLINE_APIKEYS_EDIT} element={<ApikeysEditRoute />} />
					<Route path={Paths.DASHBOARD_BETSLIPS_ONLINE} element={<OnlineBetslipsRoute />} />
					<Route path={Paths.DASHBOARD_BETSLIPS_RETAIL} element={<RetailBetslipsRoute />} />
					<Route path={Paths.DASHBOARD_EVENTS_ONLINE} element={<OnlineEventsRoute />} />
					<Route path={Paths.DASHBOARD_EVENTS_ONLINE_VIEW} element={<OnlineEventViewRoute />} />
					<Route path={Paths.DASHBOARD_EVENTS_RETAIL} element={<RetailEventsRoute />} />
					<Route path={Paths.DASHBOARD_EVENTS_RETAIL_VIEW} element={<RetailEventViewRoute />} />
					<Route path={Paths.DASHBOARD_ONLINE_PLAYERS} element={<PlayersRoute />} />
					<Route path={Paths.DASHBOARD_ONLINE_PLAYERS_VIEW} element={<PlayersViewRoute />} />
					<Route path={Paths.DASHBOARD_RETAIL_BETSHOPS} element={<BetshopsRoute />} />
					<Route path={Paths.DASHBOARD_RETAIL_BETSHOPS_EDIT} element={<BetshopEditRoute />} />
					<Route path={Paths.DASHBOARD_RETAIL_BETSHOPS_VIEW} element={<BetshopViewRoute />} />
					<Route path={Paths.DASHBOARD_RETAIL_CASHIER_MANAGEMENT_CASHIERS} element={<CashiersRoute />} />
					<Route path={Paths.DASHBOARD_RETAIL_CASHIER_MANAGEMENT_CASHIERS_EDIT} element={<CashierEditRoute />} />
					<Route path={Paths.DASHBOARD_BONUSES_STANDARD} element={<BonusesStandardRoute />} />
					<Route path={Paths.DASHBOARD_BONUSES_STANDARD_EDIT} element={<BonusStandardEditRoute />} />
					<Route path={Paths.DASHBOARD_BONUSES_JACKPOT} element={<BonusesJackpotRoute />} />
					<Route path={Paths.DASHBOARD_BONUSES_JACKPOT_EDIT} element={<BonusJackpotEditRoute />} />
					<Route path={Paths.DASHBOARD_TRANSACTIONS} element={<TransactionsRoute />} />
					<Route path={Paths.DASHBOARD_USER_LOGS} element={<UserLogsRoute />} />
					<Route path={Paths.DASHBOARD_SETTINGS_CURRENCIES} element={<SystemCurrenciesRoute />} />
					<Route path={Paths.DASHBOARD_SETTINGS_LANGUAGES} element={<SystemLanguagesRoute />} />
					<Route path={Paths.DASHBOARD_REPORTS_PROJECTS_PERFORMANCE} element={<ProjectPerformanceReportRoute />} />
					<Route path={Paths.DASHBOARD_REPORTS_BETSHOPS_PERFORMANCE} element={<BetshopPerformanceReportRoute />} />
					<Route path={Paths.DASHBOARD_REPORTS_PARTNER_ONLINE} element={<PartnerOnlineReportRoute />} />
					<Route path={Paths.DASHBOARD_USERS_FEEDBACK_CSAT} element={<CSATRoute />} />
					<Route path={Paths.DASHBOARD_USERS_FEEDBACK_CSAT_EDIT} element={<SurveyEditRoute />} />
					<Route path={Paths.DASHBOARD_CMS_TRANSLATIONS} element={<TranslationsRoute />} />
					<Route path={Paths.DASHBOARD_CMS_LIVE_MONITOR_TRANSLATIONS} element={<LiveMonitorTranslationsRoute />} />
					<Route path={Paths.DASHBOARD_CMS_RULES} element={<RulesRoute />} />
					<Route path={Paths.DASHBOARD_CMS_SCHEDULED_RULES_EDIT} element={<ScheduledRuleEditRoute />} />
					<Route path={Paths.DASHBOARD_CMS_INSTANT_RULES_EDIT} element={<InstantRuleEditRoute />} />
					<Route path={Paths.DASHBOARD_CMS_TICKETS} element={<TicketsRoute />} />
					<Route path={Paths.DASHBOARD_CMS_HOTKEYS} element={<HotkeysRoute />} />
					<Route path={Paths.DASHBOARD_SETTINGS_MAINTENANCEMODE} element={<MaintenanceModeRoute />} />
					<Route path={Paths.DASHBOARD_DEVELOPER_OPERATORS} element={<OperatorsRoute />} />
					<Route path={Paths.DASHBOARD_DEVELOPER_REQUESTS} element={<RequestsRoute />} />
					<Route path={Paths.DASHBOARD_DEVELOPER_ERRORS} element={<ErrorsRoute />} />
					<Route path={Paths.DASHBOARD_DEVELOPER_ERROR_DETAILS} element={<ErrorRoute />} />
					<Route path={Paths.DASHBOARD_DEVELOPER_JOBS} element={<JobsRoute />} />
					<Route path={Paths.DASHBOARD_DEVELOPER_MONITORING} element={<MonitoringRoute />} />
					<Route path={Paths.DASHBOARD_DEVELOPER_STREAMS} element={<DeveloperSpaceStreamsRoute />} />
					{/* -- unused functionality -- */}
					{/* <Route path={Paths.DASHBOARD_DEVELOPER_STREAM_SERVERS} element={<DeveloperSpaceServerStreamsRoute />} /> */}
					{/* -- unused functionality -- */}
					<Route path={Paths.DASHBOARD_STREAMS} element={<DashboardStreamsRoute />} />
					<Route path={Paths.DASHBOARD_STREAMS_EDIT} element={<StreamsEditRoute />} />
					<Route path={Paths.DASHBOARD_DEVELOPER_POST_DEPLOYMENT_ACTIONS} element={<DeveloperPostDeploymentActionsRoute />} />
					<Route path={Paths.DASHBOARD_DEVELOPER_BETSLIP_CORRECTION} element={<BetCorrectionRoute />} />
				</Route>
			</Route>
		)
	);

	return (
		<Provider store={store}>
			<div className="App">
				<RouterProvider router={router} />
			</div>
		</Provider>
	);
};

export default App;
