import { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Form, Row, Col, Select, DatePicker, Divider } from "antd";
import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import SearchableSelect from "components/common/searchableSelect";
import { setAccessManagersFilters, getAccessManagers } from "store/actions/dashboard/userManagement/accessManagers/accessManagers.action";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import useAutosuggestion from "hooks/useAutosuggestion";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
import { FILTER_USER_STATE } from "constants/filter.constants";

import { getPermissionRequestHistory, setPermissionRequestHistoryFilters } from "store/actions/dashboard/userManagement/requests/history.action";
import { PERMISSION_REQUEST_OBJECT_TYPE, PERMISSION_REQUEST_STATUS, PERMISSION_REQUEST_TYPE } from "constants/permissionRequest.constants";

const { Item: FormItem } = Form;

/** Reuqest History Page Filters Component */
const Filters = ({ setPermissionRequestHistoryFilters, getPermissionRequestHistory, filters, globalPartnerId }) => {
	const { t } = useTranslation();

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [
				{ name: "date", keepTime: false },
				{ name: "lastUpdate", keepTime: false }
			]
		};
	}, []);

	/** Function to get filters list
	 * @function
	 * @returns {array} - filters list
	 * @memberOf Filters
	 */
	const filtersList = () => [
		{
			name: "status",
			title: t("common.status"),
			values: [
				{ title: t("common.all"), value: "" },
				{ title: t("pages.dashboard.permissions_requests.approve"), value: PERMISSION_REQUEST_STATUS.APPROVED },
				{ title: t("pages.dashboard.permissions_requests.reject"), value: PERMISSION_REQUEST_STATUS.REJECTED }
			]
		},
		{
			name: "actionTypes",
			title: t("pages.dashboard.permissions_requests.actionTypes.actionType"),
			values: [
				{ title: t("common.all"), value: "" },
				{ title: t("pages.dashboard.permissions_requests.actionTypes.permissionGroupModify"), value: PERMISSION_REQUEST_TYPE.GROUP_MODIFY },
				{ title: t("pages.dashboard.permissions_requests.actionTypes.addPartnerAccess"), value: PERMISSION_REQUEST_TYPE.ADD_PARTNER_ACCESS },
				{ title: t("pages.dashboard.permissions_requests.actionTypes.deletePartnerAccess"), value: PERMISSION_REQUEST_TYPE.DELETE_PARTNER_ACCESS },
				{ title: t("pages.dashboard.permissions_requests.actionTypes.addProjectAccess"), value: PERMISSION_REQUEST_TYPE.ADD_PROJECT_ACCESS },
				{ title: t("pages.dashboard.permissions_requests.actionTypes.deleteProjectAccess"), value: PERMISSION_REQUEST_TYPE.DELETE_PROJECT_ACCESS },
				{ title: t("pages.dashboard.permissions_requests.actionTypes.addBetshopAccess"), value: PERMISSION_REQUEST_TYPE.ADD_BETSHOP_ACCESS },
				{ title: t("pages.dashboard.permissions_requests.actionTypes.deleteBetshopAccess"), value: PERMISSION_REQUEST_TYPE.DELETE_BETSHOP_ACCESS },
				{ title: t("pages.dashboard.permissions_requests.actionTypes.addPermissionGroup"), value: PERMISSION_REQUEST_TYPE.ADD_PERMISSION_GROUP },
				{ title: t("pages.dashboard.permissions_requests.actionTypes.deletePermissionGroup"), value: PERMISSION_REQUEST_TYPE.DELETE_PERMISSION_GROUP }
			]
		},
		{
			name: "objectType",
			title: t("pages.dashboard.permissions_requests.objectTypes.objectType"),
			values: [
				{ title: t("common.all"), value: "" },
				{ title: t("pages.dashboard.permissions_requests.objectTypes.user"), value: PERMISSION_REQUEST_OBJECT_TYPE.USER },
				{ title: t("pages.dashboard.permissions_requests.objectTypes.permissionGroup"), value: PERMISSION_REQUEST_OBJECT_TYPE.PERMISSION_GROUP }
			]
		},
		{ name: "date", title: t("common.creation_date") },
		{ name: "lastUpdate", title: t("common.last_update_date") }
	];

	return (
		<FiltersWrapper filtersName="requestHistory" loadFn={getPermissionRequestHistory} setFiltersFn={setPermissionRequestHistoryFilters} filters={filters} formFieldsConfigs={formFieldsConfigs} filtersList={filtersList()}>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.status")} name="status">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={""}>{t("common.all")}</Select.Option>
								<Select.Option value={PERMISSION_REQUEST_STATUS.APPROVED}>{t("pages.dashboard.permissions_requests.approve")}</Select.Option>
								<Select.Option value={PERMISSION_REQUEST_STATUS.REJECTED}>{t("pages.dashboard.permissions_requests.reject")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.permissions_requests.actionTypes.actionType")} name="actionTypes">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={""}>{t("common.all")}</Select.Option>
								<Select.Option value={PERMISSION_REQUEST_TYPE.GROUP_MODIFY}>{t("pages.dashboard.permissions_requests.actionTypes.permissionGroupModify")}</Select.Option>
								<Select.Option value={PERMISSION_REQUEST_TYPE.ADD_PARTNER_ACCESS}>{t("pages.dashboard.permissions_requests.actionTypes.addPartnerAccess")}</Select.Option>
								<Select.Option value={PERMISSION_REQUEST_TYPE.DELETE_PARTNER_ACCESS}>{t("pages.dashboard.permissions_requests.actionTypes.deletePartnerAccess")}</Select.Option>
								<Select.Option value={PERMISSION_REQUEST_TYPE.ADD_PROJECT_ACCESS}>{t("pages.dashboard.permissions_requests.actionTypes.addProjectAccess")}</Select.Option>
								<Select.Option value={PERMISSION_REQUEST_TYPE.DELETE_PROJECT_ACCESS}>{t("pages.dashboard.permissions_requests.actionTypes.deleteProjectAccess")}</Select.Option>
								<Select.Option value={PERMISSION_REQUEST_TYPE.ADD_BETSHOP_ACCESS}>{t("pages.dashboard.permissions_requests.actionTypes.addBetshopAccess")}</Select.Option>
								<Select.Option value={PERMISSION_REQUEST_TYPE.DELETE_BETSHOP_ACCESS}>{t("pages.dashboard.permissions_requests.actionTypes.deleteBetshopAccess")}</Select.Option>
								<Select.Option value={PERMISSION_REQUEST_TYPE.ADD_PERMISSION_GROUP}>{t("pages.dashboard.permissions_requests.actionTypes.addPermissionGroup")}</Select.Option>
								<Select.Option value={PERMISSION_REQUEST_TYPE.DELETE_PERMISSION_GROUP}>{t("pages.dashboard.permissions_requests.actionTypes.deletePermissionGroup")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.permissions_requests.objectTypes.objectType")} name="objectType">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={""}>{t("common.all")}</Select.Option>
								<Select.Option value={PERMISSION_REQUEST_OBJECT_TYPE.USER}>{t("pages.dashboard.permissions_requests.objectTypes.user")}</Select.Option>
								<Select.Option value={PERMISSION_REQUEST_OBJECT_TYPE.PERMISSION_GROUP}>{t("pages.dashboard.permissions_requests.objectTypes.permissionGroup")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.creation_date")} name="date">
							<DatePicker.RangePicker placeholder={[t("common.from"), t("common.to")]} format={DATE_FORMAT} disabledDate={(d) => !d || d.isAfter(moment().endOf("day"))} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.last_update_date")} name="lastUpdate">
							<DatePicker.RangePicker placeholder={[t("common.from"), t("common.to")]} format={DATE_FORMAT} disabledDate={(d) => !d || d.isAfter(moment().endOf("day"))} />
						</FormItem>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get permission request history */
	getPermissionRequestHistory: PropTypes.func,
	/** Redux action to set permission request history filters */
	setPermissionRequestHistoryFilters: PropTypes.func,
	/** Redux state property, permission request history filters */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getPermissionRequestHistory: () => {
		dispatch(getPermissionRequestHistory());
	},
	setPermissionRequestHistoryFilters: (filters) => {
		dispatch(setPermissionRequestHistoryFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.permissionRequests.history.filters,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
