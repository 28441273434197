import { Fragment, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Row, Col } from "antd";
import Input from "antd/lib/input";
import NumericInput from "components/common/numericInput";
import { getBetCorrections, setBetslipCorrectionsFilters } from "store/actions/dashboard/developer/betCorrection/betCorrections.action";
import FiltersWrapper from "components/common/filters";
import SearchableSelect from "components/common/searchableSelect";
import useAutosuggestion from "hooks/useAutosuggestion";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";

const { Item: FormItem } = Form;

/** Games Page Filters Component */
const Filters = ({ filters, getBetCorrections, setBetslipCorrectionsFilters }) => {
	const [selectedPartnerId, setSelectedPartnerId] = useState(null);

	const { t } = useTranslation();

	const partnersNames = useAutosuggestion(AUTOSUGGESTION_TYPE.PARTNER_NAMES, []);

	const apiKeyNames = useAutosuggestion(
		AUTOSUGGESTION_TYPE.API_KEY_NAMES,
		[selectedPartnerId],
		{ actionArguments: [ { partnerId: selectedPartnerId } ] }
	);

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [
				{ name: "date", keepTime: false },
				{ name: "lastUpdate", keepTime: false }
			]
		};
	}, []);

	const filtersList = [
		{ name: "partnerId", title: t("pages.dashboard.developerBetCorrections.partnerID") },
		{ name: "projectId", title: t("pages.dashboard.apikeys.project_name_or_ID") },
		{
			name: "betSlipId",
			title: t("pages.dashboard.bets.betslip_id")
		},
		{ name: "betId", title: t("pages.dashboard.bets.bet_id") },
		{ name: "eventId", title: t("pages.dashboard.bets.event_id") },
		{ name: "playerId", title: t("pages.dashboard.players.player_id_or_username") },
		{ name: "playerExternalId", title: t("pages.dashboard.players.external_id") }
	];

	const getItemKey = (item) => item.key;
	const getItemValue = (item) => item.value;

	return (
		<FiltersWrapper loadFn={getBetCorrections} setFiltersFn={setBetslipCorrectionsFilters} filters={filters} formFieldsConfigs={formFieldsConfigs} filtersList={filtersList} filtersName="betCorrections">
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.developerBetCorrections.partnerID")} name="partnerId">
							<SearchableSelect
								placeholder={`${t("common.start_typing")} ${t("pages.dashboard.partners.partner_name_or_id")}`}
								items={partnersNames}
								valueProp={getItemKey}
								textProp={getItemValue}
								renderText={getItemValue}
								suffixIcon={null}
								onChange={(partnerId) => setSelectedPartnerId(partnerId)}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.apikeys.project_name_or_ID")} name="projectId">
							<SearchableSelect placeholder={`${t("common.start_typing")} ${t("pages.dashboard.apikeys.project_name_or_ID")}`} items={apiKeyNames} valueProp={getItemKey} textProp={getItemValue} renderText={getItemValue} suffixIcon={null} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.betslip_id")} name="betSlipId">
							<NumericInput placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.betslip_id")}`} isInteger={true} maxLength={12} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.bet_id")} name="betId">
							<NumericInput placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.bet_id")}`} isInteger={true} maxLength={12} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.event_id")} name="eventId">
							<NumericInput placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.event_id")}`} isInteger={true} maxLength={12} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.player_id_or_username")} name="userNameOrId">
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.player_id_or_username")}`} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.players.external_id")} name="playerExternalId">
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.players.external_id")}`} />
						</FormItem>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux state property, games filters */
	filters: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	setBetslipCorrectionsFilters: (filters) => {
		dispatch(setBetslipCorrectionsFilters(filters));
	},
	getBetCorrections: (fromFirstPage) => {
		dispatch(getBetCorrections(fromFirstPage));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.betCorrections.filters
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
