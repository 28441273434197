import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Button, Slider } from "antd";
const { Item: FormItem } = Form;

import NumericInput from "components/common/numericInput";

import apiKeyUIType from "types/apiKey/ui.type";

import { saveApiKeyUI, setApiKeyPreviewUI } from "store/actions/dashboard/online/apikeys/customization.action";

import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

/** Customization Tool BorderRadius Component */
const BorderRadius = ({ ui, isSaving, saveApiKeyUI, setApiKeyPreviewUI, previewUI, onClose }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [initialBorderRadius, setInitialBorderRadius] = useState("4px");
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_CUSTOMIZATION, action: PERMISSION_ACTION.MODIFY });

	/** Set initial border radius */
	useEffect(() => {
		if (ui.launchUrl) {
			//Check if border radius exist
			const borderRadius = previewUI["--border-radius"];
			if (!borderRadius) {
				setApiKeyPreviewUI({
					"--border-radius": "4px"
				});
			} else {
				setInitialBorderRadius(borderRadius);
			}
		}
	}, [ui]);

	/** Function to save border radius
	 * @function
	 * @memberOf BorderRadius
	 */
	const saveBorderRadius = () => {
		const parameters = ui.parameters || [];

		saveApiKeyUI(routeParams.id, parameters.filter((p) => p.key !== "--border-radius").concat([{ key: "--border-radius", value: previewUI["--border-radius"] }]), "EN");
		setInitialBorderRadius(previewUI["--border-radius"]);
		onClose();
	};

	/** Function to reset border radius to default template
	 * @function
	 * @memberOf BorderRadius
	 */
	const resetToTemplate = () => {
		setApiKeyPreviewUI({
			"--border-radius": "4px"
		});
	};

	return (
		<div className="dashboard-customization-tool-section">
			<h2>{t("pages.dashboard.apikeys.customization.borders_radius")}</h2>
			<div className="dashboard-customization-tool-section-main">
				<div className={"border-radius-preview" + (isDisabled ? " border-radius-disable" : "")}>
					<div className="border-radius-preview-cub" style={{ borderRadius: previewUI["--border-radius"] || "4px" }}>
						<div className="border-radius-preview-cub-mark"></div>
						<div className="border-radius-preview-cub-mark"></div>
						<div className="border-radius-preview-cub-mark"></div>
						<div className="border-radius-preview-cub-mark"></div>
					</div>
				</div>
				<div className="border-radius-slider">
					<Slider
						min={0}
						max={20}
						onChange={(v) =>
							setApiKeyPreviewUI({
								"--border-radius": v + "px"
							})
						}
						value={Number((previewUI["--border-radius"] || "4px").replace("px", ""))}
						disabled={isDisabled}
					/>
					<NumericInput
						isInteger={true}
						maxLength={2}
						onChange={(v) =>
							setApiKeyPreviewUI({
								"--border-radius": Math.min(20, Number(v)) + "px"
							})
						}
						value={Number((previewUI["--border-radius"] || "4px").replace("px", ""))}
						disabled={isDisabled}
					/>
				</div>
			</div>
			{!isDisabled && (
				<FormItem className="button-container">
					<Button className="button" onClick={resetToTemplate} disabled={previewUI["--border-radius"] === "4px"}>
						<span>{t("pages.dashboard.apikeys.customization.reset_to_default")}</span>
					</Button>
					<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={saveBorderRadius} disabled={initialBorderRadius === previewUI["--border-radius"]}>
						<span>{t("common.save")}</span>
					</Button>
				</FormItem>
			)}
		</div>
	);
};

/** BorderRadius propTypes
 * PropTypes
 */
BorderRadius.propTypes = {
	/** Redux state property, apikey ui data */
	ui: apiKeyUIType,
	/** Redux state property, is true when ui is saving */
	isSaving: PropTypes.bool,
	/** Redux action to save apiKey ui data */
	saveApiKeyUI: PropTypes.func,
	/** Redux action to set apiKey current preview ui data */
	setApiKeyPreviewUI: PropTypes.func,
	/** Redux state property, apikey ui data */
	previewUI: apiKeyUIType,
	/** Function to close tool */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	saveApiKeyUI: (id, parameters, languageCode) => {
		dispatch(saveApiKeyUI(id, parameters, languageCode));
	},
	setApiKeyPreviewUI: (data) => {
		dispatch(setApiKeyPreviewUI(data));
	}
});

const mapStateToProps = (state) => {
	return {
		ui: state.apikeys.editingApikey.customization.ui,
		isSaving: state.apikeys.isSaving,
		previewUI: state.apikeys.editingApikey.customization.previewUI
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BorderRadius);
