import PropTypes from "prop-types";

import { BETSLIP_MODES, BET_STATE, CANCEL_USER_TYPE } from "constants/bet.constants";
import { SCHEDULED_GAME_TYPE, INSTANT_GAME_TYPE } from "constants/game.constants";

export default PropTypes.shape({
	id: PropTypes.number,
	betSlipId: PropTypes.string,
	partnerId: PropTypes.string,
	apiKeyId: PropTypes.string,
	eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	status: PropTypes.oneOf(Object.values(BET_STATE)),
	eventStartDate: PropTypes.string,
	betTime: PropTypes.string,
	payoutTime: PropTypes.string,
	type: PropTypes.oneOf(Object.values(BETSLIP_MODES)),
	gameType: PropTypes.oneOf([...Object.values(SCHEDULED_GAME_TYPE), ...Object.values(INSTANT_GAME_TYPE)].map((v) => v.value)),
	gameName: PropTypes.string,
	userId: PropTypes.string,
	userName: PropTypes.string,
	currencyId: PropTypes.string,
	market: PropTypes.string,
	odd: PropTypes.string,
	oddId: PropTypes.string,
	factor: PropTypes.number,
	period: PropTypes.number,
	argument: PropTypes.number,
	amount: PropTypes.number,
	winning: PropTypes.number,
	possibleWin: PropTypes.number,
	ipAddress: PropTypes.string,
	allowCancel: PropTypes.bool,
	cancelUserType: PropTypes.oneOf(Object.values(CANCEL_USER_TYPE)),
	projectName: PropTypes.string
});
