import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { BOXES_BEFORE, BOXES_FINISH, BOX_BEFORE, BOX_FINISH, SET_BOXES, BOXES_ACTION_SET_BOXES_SORTING } from "store/actionTypes";

export const setBoxesActionBefore = () => ({
	type: BOXES_BEFORE
});

export const setBoxesActionFinished = () => ({
	type: BOXES_FINISH
});

export const setBoxSaveActionBefore = () => ({
	type: BOX_BEFORE
});

export const setBoxSaveActionFinished = () => ({
	type: BOX_FINISH
});

export const setBoxes = (boxes) => ({
	type: SET_BOXES,
	payload: { boxes }
});

export const setBoxesSorting = (sorting) => ({
	type: BOXES_ACTION_SET_BOXES_SORTING,
	payload: { sorting }
});

export const getBoxes = (fromFirstPage) => {
	return (dispatch, getState) => {
		dispatch(setBoxesActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GETALL_BOXES}`,
			method: Methods.GET,
			params: {
				...getState().boxes.sorting,
				...getState().boxes.filters,
				page: fromFirstPage ? 1 : getState().boxes.sorting.page
			}
		})
			.then(({ data: { value: boxes } }) => {
				dispatch(setBoxes(boxes));
				if (fromFirstPage) dispatch(setBoxesSorting({ ...getState().boxes.sorting, page: 1 }));
				dispatch(setBoxesActionFinished());
			})
			.catch((ex) => {
				dispatch(setBoxesActionFinished());
			});
	};
};

export const createBox = (box, onSuccess) => {
	return (dispatch) => {
		dispatch(setBoxSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CREATE_BOX}`,
			method: Methods.POST,
			data: box
		})
			.then(({ data: { value: box } }) => {
				dispatch(setBoxSaveActionFinished());
				onSuccess && onSuccess(box);
			})
			.catch((ex) => {
				dispatch(setBoxSaveActionFinished());
			});
	};
};
