import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { RESPONSE_STATE } from "constants/response.constants";

import { SET_SELECTED_GAME_TYPE, SET_RULES, RULES_SET_SORTING, SET_RULES_HISTORY, SET_RULE_DETAILS, RULES_HISTORY_SORTING, UPDATE_RULE, RULE_TRANSLATION_SET_SYSTEM_DEFAULT, ADD_RULE_LANGUAGE_BEFORE, ADD_RULE_LANGUAGE_FINISH } from "store/actionTypes";

import { setTranslationsBefore, setTranslationsFinished, setTranslationSaveBefore, setTranslationSaveFinished, setTranslationsPublishBefore, setTranslationsPublishFinished } from "./translation.action";
import { GAME_CATEGORY } from "constants/game.constants";
import { REDUX_RULES_STORE_FIELD } from "constants/rules.constants";

export const setSelectedGameType = (gameType, gameCategory) => ({
	type: SET_SELECTED_GAME_TYPE,
	payload: { gameType, gameCategory }
});

const setRules = (data, gameCategory) => ({
	type: SET_RULES,
	payload: { data, gameCategory }
});

export const setRulesSorting = (sorting, gameCategory) => ({
	type: RULES_SET_SORTING,
	payload: { sorting, gameCategory }
});

const setRulesHistory = (data, gameCategory) => ({
	type: SET_RULES_HISTORY,
	payload: { data, gameCategory }
});

export const setHistorySorting = (sorting, gameCategory) => ({
	type: RULES_HISTORY_SORTING,
	payload: { sorting, gameCategory }
});

export const setRuleDetails = (data, gameCategory) => ({
	type: SET_RULE_DETAILS,
	payload: { data, gameCategory }
});

const updateRule = (data, gameCategory) => ({
	type: UPDATE_RULE,
	payload: { data, gameCategory }
});

const addRuleLanguagesBefore = () => ({
	type: ADD_RULE_LANGUAGE_BEFORE
});

const addRuleLanguagesFinished = () => ({
	type: ADD_RULE_LANGUAGE_FINISH
});

export const setRulesSystemDefault = (systemDefault, gameCategory) => ({
	type: RULE_TRANSLATION_SET_SYSTEM_DEFAULT,
	payload: { systemDefault, gameCategory }
});

export const getRules = ({ fromFirstPage = false, gameType, isSystemDefault = false, gameCategory }) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? (isSystemDefault ? ApiUrls.SCHEDULED_RULE_SYSTEM_ALL : ApiUrls.SCHEDULED_RULE_GET_ALL) : isSystemDefault ? ApiUrls.INSTANT_RULE_SYSTEM_ALL : ApiUrls.INSTANT_RULE_GET_ALL;
	return (dispatch, getState) => {
		const state = getState();
		dispatch(setTranslationsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.GET,
			params: {
				...state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].main.sorting,
				page: fromFirstPage ? 1 : state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].main.sorting.page,
				gameType
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setRules(data, gameCategory));
				if (fromFirstPage) {
					dispatch(setRulesSorting({ ...state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].main.sorting, page: 1 }, gameCategory));
				}
				dispatch(setTranslationsFinished());
			})
			.catch(() => {
				dispatch(setTranslationsFinished());
			});
	};
};

export const getRulesHistory = ({ fromFirstPage = false, gameType, languageCode, isSystemDefault = true, gameCategory }) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? (isSystemDefault ? ApiUrls.SCHEDULED_RULE_SYSTEM_GET_ALL_HISTORY : ApiUrls.SCHEDULED_RULE_GET_ALL_HISTORY) : isSystemDefault ? ApiUrls.INSTANT_RULE_SYSTEM_GET_ALL_HISTORY : ApiUrls.INSTANT_RULE_GET_ALL_HISTORY;
	return (dispatch, getState) => {
		const state = getState();
		dispatch(setTranslationsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.GET,
			params: {
				...state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].history.sorting,
				page: fromFirstPage ? 1 : state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].history.sorting.page,
				gameType,
				languageCode
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setRulesHistory(data, gameCategory));
				if (fromFirstPage) {
					dispatch(setHistorySorting({ ...state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].history.sorting, page: 1 }, gameCategory));
				}
				dispatch(setTranslationsFinished());
			})
			.catch(() => {
				dispatch(setTranslationsFinished());
			});
	};
};

export const getRuleDetails = ({ id, fromHistory = false, systemDefault = false, gameCategory }) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? (systemDefault ? ApiUrls.SCHEDULED_RULE_GET_SYSTEM_DETAILS : ApiUrls.SCHEDULED_RULE_GET_DETAILS) : systemDefault ? ApiUrls.INSTANT_RULE_GET_SYSTEM_DETAILS : ApiUrls.INSTANT_RULE_GET_DETAILS;
	return (dispatch) => {
		dispatch(setTranslationsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.GET,
			params: {
				id,
				fromHistory
			}
		})
			.then(({ data: { value: rule } }) => {
				dispatch(setRuleDetails(rule, gameCategory));
				dispatch(setTranslationsFinished());
			})
			.catch(() => {
				dispatch(setTranslationsFinished());
			});
	};
};

export const saveRule = ({ systemDefault, ...params }) => {
	const saveFn = systemDefault ? saveRuleSystem : saveRulePartner;
	return saveFn(params);
};

export const saveRulePartner = ({ gameType, title, content, languageCode, Id, gameCategory }) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.SCHEDULED_RULE_SAVE : ApiUrls.INSTANT_RULE_SAVE;
	return (dispatch) => {
		dispatch(setTranslationSaveBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.POST,
			data: {
				gameType,
				title,
				content,
				languageCode,
				Id
			}
		})
			.then(({ data: { message: msg, status, value: rule } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(updateRule(rule, gameCategory));
				}
				dispatch(setTranslationSaveFinished());
			})
			.catch(() => {
				dispatch(setTranslationSaveFinished());
			});
	};
};

export const saveRuleSystem = ({ gameType, title, content, languageCode, Id, gameCategory }) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.SCHEDULED_RULE_SYSTEM_SAVE : ApiUrls.INSTANT_RULE_SYSTEM_SAVE;
	return (dispatch) => {
		dispatch(setTranslationSaveBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.POST,
			data: {
				gameType,
				title,
				content,
				languageCode,
				Id
			}
		})
			.then(({ data: { message: msg, status, value: rule } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(updateRule(rule, gameCategory));
				}
				dispatch(setTranslationSaveFinished());
			})
			.catch(() => {
				dispatch(setTranslationSaveFinished());
			});
	};
};

export const publishRules = ({ gameType, languageCode = null, onSuccess = Function.prototype, systemDefault, gameCategory }) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? (systemDefault ? ApiUrls.SCHEDULED_SYSTEM_RULE_PUBLISH : ApiUrls.SCHEDULED_RULE_PUBLISH) : systemDefault ? ApiUrls.INSTANT_SYSTEM_RULE_PUBLISH : ApiUrls.INSTANT_RULE_PUBLISH;
	return (dispatch) => {
		dispatch(setTranslationsPublishBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.POST,
			data: { gameType, languageCode }
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					onSuccess();
				}
				dispatch(setTranslationsPublishFinished());
			})
			.catch(() => {
				dispatch(setTranslationsPublishFinished());
			});
	};
};

export const addRuleLanguages = ({ gameType, languageCodes = [], onSuccess = Function.prototype, gameCategory }) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.SCHEDULED_RULE_ADD : ApiUrls.INSTANT_RULE_ADD;
	return (dispatch) => {
		dispatch(addRuleLanguagesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.POST,
			data: { gameType, languageCodes }
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					onSuccess();
				}
				dispatch(addRuleLanguagesFinished());
			})
			.catch(() => {
				dispatch(addRuleLanguagesFinished());
			});
	};
};
