import { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Col, Row, Modal, Input } from "antd";
const { Item: FormItem } = Form;

import SearchableSelect from "components/common/searchableSelect";

import { addUserPermissionGroup, getUserAvailablePermissionGroups } from "store/actions/dashboard/userManagement/users/permissionGroups.action";

import { POPUP_SIZE } from "constants/common.constants";
import { doesUserHaveRoleOf } from "utils/auth";
import { USER_ROLE } from "constants/user.constants";
import userPermissionGroupType from "types/user/permissionGroup.type";

/** User Permission Group Adding Popup Component */
const PermissionGroupAddComponent = ({ isSaving, addUserPermissionGroup, getUserAvailablePermissionGroups, onClose, availableGroups, groups, isAvailableGroupsLoading }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;
	const isAccessManager = doesUserHaveRoleOf(USER_ROLE.ACCESS_MANAGER);
	const accessManagerNoteActions = isAccessManager
		? {
				title: t("pages.dashboard.permissions_requests.notes"),
				isPrompt: true,
				isPromptRequired: true,
				promptLabel: t("pages.dashboard.permissions_requests.notes")
			}
		: {};

	/**Load user available permission groups */
	useEffect(() => {
		getUserAvailablePermissionGroups(routeParams.id);
	}, []);

	/** Fires when form submitted
	 * @function
	 * @memberOf PermissionGroupAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ groupId, note }) => {
				addUserPermissionGroup(routeParams.id, groupId, note, isAccessManager);
				onClose();
			})
			.catch(Function.prototype);
	};

	return (
		<Modal open={true} title={t("pages.dashboard.users.add_new_group")} cancelText={t("common.cancel")} okText={t("common.add")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.SMALL} maskClosable={false} closable={false} okButtonProps={{ loading: isSaving }} centered>
			<Form className="dashboard-form" colon={false} layout="vertical" requiredMark={false} form={formInstance}>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={t("pages.dashboard.users.select_permission_groups_placeholder")} name="groupId" rules={[{ required: true, message: t("validation.field_required") }]}>
							<SearchableSelect
								placeholder={t("pages.dashboard.users.select_permission_groups_placeholder")}
								items={Object.keys(availableGroups).filter((k) => !groups.map((g) => g.id).includes(k))}
								valueProp={(item) => item}
								textProp={(item) => availableGroups[item]}
								renderText={(item) => availableGroups[item]}
								getPopupContainer={() => document.body}
								loading={isAvailableGroupsLoading}
							/>
						</FormItem>
						{isAccessManager ? (
							<FormItem label={t("pages.dashboard.permissions_requests.notes")} name="note" rules={[{ required: true, message: t("validation.field_required") }]}>
								<Input.TextArea rows={4} placeholder={t("pages.dashboard.permissions_requests.notes")} />
							</FormItem>
						) : null}
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** PermissionGroupAddComponent propTypes
 * PropTypes
 */
PermissionGroupAddComponent.propTypes = {
	/** Redux state property, is true when user permission group is adding */
	isSaving: PropTypes.bool,
	/** Redux action to add users permission group */
	addUserPermissionGroup: PropTypes.func,
	/** Redux action to get user available permission Groups */
	getUserAvailablePermissionGroups: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Redux state property, represents  available permission groups  */
	availableGroups: PropTypes.object,
	/** Redux state property, represents the user permission groups  */
	groups: PropTypes.arrayOf(userPermissionGroupType),
	/** Redux state property, is true when user available permission groups are adding */
	isAvailableGroupsLoading: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	addUserPermissionGroup: (id, groupId, note, isAccessManager) => {
		dispatch(addUserPermissionGroup(id, groupId, note, isAccessManager));
	},
	getUserAvailablePermissionGroups: (userId) => {
		dispatch(getUserAvailablePermissionGroups(userId));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.users.isSaving,
		availableGroups: state.users.editingUser.permissionGroups.availableGroups,
		groups: state.users.editingUser.permissionGroups.groups,
		isAvailableGroupsLoading: state.users.editingUser.permissionGroups.isAvailableGroupsLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionGroupAddComponent);
