import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Form, Row, Col, Input, Select, DatePicker } from 'antd';
import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import NumericInput from "components/common/numericInput";

import {
	getJackpotHistory,
	setJackpotHistoryFilters,
} from 'store/actions/dashboard/bonuses/jackpotHistory.action';

import useAutosuggestion from 'hooks/useAutosuggestion';
import { AUTOSUGGESTION_TYPE } from 'constants/autoSuggestion.constants';
import { FILTER_JACKPOT_BONUS_LEVEL } from "constants/filter.constants";
import { JACKPOT_TABLE_FILTERS, JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS, JACKPOT_TRANSACTION_STATUS, JACKPOT_BONUS_TRANSACTION_STATUS_TRANSLATION_TEXTS } from "constants/bonus.constants";
import { DATE_TIME_FORMAT, TIME_FORMAT } from 'constants/date.constants';
import SearchableSelect from 'components/common/searchableSelect';

const { Item: FormItem } = Form;

const getGlobalPartnerId = (state) => state.partner.globalPartnerId;
const getJackpotHistoryFilters = (state) => state.bonuses.jackpots.editingJackpot.history.filters;


/** Jackpot Bonus History Page Filters Component */
const Filters = ({ jackpotId }) => {
	const { t } = useTranslation();

	const globalPartnerId = useSelector(getGlobalPartnerId);
	const filters = useSelector(getJackpotHistoryFilters);

	const dispatch = useDispatch();

	const getHistory = useCallback((fromFirstPage) => dispatch(getJackpotHistory(fromFirstPage, jackpotId)), [dispatch, jackpotId]);
	const setFilters = useCallback((params) => dispatch(setJackpotHistoryFilters(params)), [dispatch]);

	const availableBetshops = useAutosuggestion(
		AUTOSUGGESTION_TYPE.BETSHOP_NAMES,
		[globalPartnerId, jackpotId],
		{ disableAction: () => !globalPartnerId }
	)

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [
				{ name: "date", keepTime: true }
			]
		};
	}, []);

	/** Function to get filters list
		* @function
		* @returns {array} - filters list
		* @memberOf Filters
  */
	const filtersList = () => [
		{
			name: JACKPOT_TABLE_FILTERS.WON_LEVEL,
			title: t('pages.dashboard.bonuses.jackpot_level'),
			values: [
				{ title: t('common.all'), value: FILTER_JACKPOT_BONUS_LEVEL.ALL },
				{ title: t(JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS[FILTER_JACKPOT_BONUS_LEVEL.BRONZE]), value: FILTER_JACKPOT_BONUS_LEVEL.BRONZE },
				{ title: t(JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS[FILTER_JACKPOT_BONUS_LEVEL.SILVER]), value: FILTER_JACKPOT_BONUS_LEVEL.SILVER },
				{ title: t(JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS[FILTER_JACKPOT_BONUS_LEVEL.GOLD]), value: FILTER_JACKPOT_BONUS_LEVEL.GOLD }
			]
		},
		{
			name: JACKPOT_TABLE_FILTERS.STATUS,
			title: t('pages.dashboard.bonuses.status'),
			values: [
				{ title: t('common.all'), value: JACKPOT_TRANSACTION_STATUS.ALL },
				{ title: t(JACKPOT_BONUS_TRANSACTION_STATUS_TRANSLATION_TEXTS[JACKPOT_TRANSACTION_STATUS.WON]), value: JACKPOT_TRANSACTION_STATUS.WON },
				{ title: t(JACKPOT_BONUS_TRANSACTION_STATUS_TRANSLATION_TEXTS[JACKPOT_TRANSACTION_STATUS.NOT_WON]), value: JACKPOT_TRANSACTION_STATUS.NOT_WON }
			]
		},
		{ name: JACKPOT_TABLE_FILTERS.BETSLIP, title: t('pages.dashboard.bonuses.betslipId') },
		{
			name: JACKPOT_TABLE_FILTERS.PROJECT,
			title: t('pages.dashboard.bonuses.betshopIds'),
			values: availableBetshops.map(g => ({ title: g.value, value: g.key }))
		},
		{ name: JACKPOT_TABLE_FILTERS.END_TIME, title: t('common.timeAndDate') }
	];


	const disabledDate = (current) => {
		// Can not select future dates
		return current && current > Date.now();
	};

	const disabledDateTime = () => ({
		disabledHours: () => {
			const hours = [];
			const currentHour = new Date().getHours();
			for (let i = currentHour + 1; i < 24; i++) {
				hours.push(i);
			}
			return hours;
		},
		disabledMinutes: (selectedHour) => {
			const minutes = [];
			const currentMinute = new Date().getMinutes();
			if (selectedHour === new Date().getHours()) {
				for (let i = currentMinute + 1; i < 60; i++) {
					minutes.push(i);
				}
			}
			return minutes;
		},
		disabledSeconds: (selectedHour, selectedMinute) => {
			const seconds = [];
			const currentSecond = new Date().getSeconds();
			if (selectedHour === new Date().getHours() && selectedMinute === new Date().getMinutes()) {
				for (let i = currentSecond + 1; i < 60; i++) {
					seconds.push(i);
				}
			}
			return seconds;
		},
	});

	return (
		<FiltersWrapper
			filtersName="jackpotBonusHistory"
			loadFn={getHistory}
			setFiltersFn={setFilters}
			filters={filters}
			formFieldsConfigs={formFieldsConfigs}
			filtersList={filtersList()}
		>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem
							label={t('pages.dashboard.bonuses.jackpot_won_level')}
							name={JACKPOT_TABLE_FILTERS.WON_LEVEL}
						>
							<Select
								suffixIcon={(<i className="icon-down" />)}
							>
								<Select.Option value={FILTER_JACKPOT_BONUS_LEVEL.ALL}>{t('common.all')}</Select.Option>
								<Select.Option value={FILTER_JACKPOT_BONUS_LEVEL.BRONZE}>
									{t(JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS[FILTER_JACKPOT_BONUS_LEVEL.BRONZE])}
								</Select.Option>
								<Select.Option value={FILTER_JACKPOT_BONUS_LEVEL.SILVER}>
									{t(JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS[FILTER_JACKPOT_BONUS_LEVEL.SILVER])}
								</Select.Option>
								<Select.Option value={FILTER_JACKPOT_BONUS_LEVEL.GOLD}>
									{t(JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS[FILTER_JACKPOT_BONUS_LEVEL.GOLD])}
								</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem
							label={t('pages.dashboard.bonuses.status')}
							name={JACKPOT_TABLE_FILTERS.STATUS}
						>
							<Select
								suffixIcon={(<i className="icon-down" />)}
							>
								<Select.Option value={JACKPOT_TRANSACTION_STATUS.ALL}>{t('common.all')}</Select.Option>
								<Select.Option value={JACKPOT_TRANSACTION_STATUS.WON}>
									{t(JACKPOT_BONUS_TRANSACTION_STATUS_TRANSLATION_TEXTS[JACKPOT_TRANSACTION_STATUS.WON])}
								</Select.Option>
								<Select.Option value={JACKPOT_TRANSACTION_STATUS.NOT_WON}>
								{t(JACKPOT_BONUS_TRANSACTION_STATUS_TRANSLATION_TEXTS[JACKPOT_TRANSACTION_STATUS.NOT_WON])}
								</Select.Option>

							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem
							label={t('pages.dashboard.bonuses.betslipId')}
							name={JACKPOT_TABLE_FILTERS.BETSLIP}
						>
							<NumericInput
								placeholder={`${t('common.enter')} ${t('pages.dashboard.bonuses.betslipId')}`}
								isInteger={true}
								maxLength={8}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem shouldUpdate={true}>
							{
								({ setFieldsValue }) => {
									return (
										<FormItem
											label={t('pages.dashboard.bonuses.betshopIds')}
											name={JACKPOT_TABLE_FILTERS.PROJECT}
											style={{ marginTop: -8 }}
										>
											<SearchableSelect
												mode="multiple"
												placeholder={t("pages.dashboard.bonuses.betshopIds")}
												items={availableBetshops.length > 1 ? [{ value: t("common.all"), key: FILTER_JACKPOT_BONUS_LEVEL.ALL }, ...availableBetshops.map(g => ({ value: g.value, key: g.key }))] : availableBetshops.map(g => ({ value: g.value, key: g.key }))}
												valueProp={(availableBetShop) => availableBetShop.key}
												textProp={(availableBetShop) => availableBetShop.value}
												renderText={(availableBetShop) => availableBetShop.value}
												defaultValue={FILTER_JACKPOT_BONUS_LEVEL.ALL}
												onChange={(projectIds) => {
													if (!projectIds.length) {
														setFieldsValue({ projectIds: [FILTER_JACKPOT_BONUS_LEVEL.ALL] });
													} else if (projectIds.slice(-1)[0] === FILTER_JACKPOT_BONUS_LEVEL.ALL) {
														setFieldsValue({ projectIds: [FILTER_JACKPOT_BONUS_LEVEL.ALL] });
													} else if (projectIds.length > 1 && projectIds.find((projectId) => projectId === FILTER_JACKPOT_BONUS_LEVEL.ALL) !== undefined) {
														setFieldsValue({ projectIds: projectIds.filter((projectId) => projectId !== FILTER_JACKPOT_BONUS_LEVEL.ALL) });
													} else {
														setFieldsValue({ projectIds });
													}
												}}
											/>

										</FormItem>
									)
								}
							}
						</FormItem>

					</Col>
					<Col xs={24} sm={12} lg={8} xl={6}>
						<FormItem
							label={t('common.timeAndDate')}
							name={JACKPOT_TABLE_FILTERS.END_TIME}
						>
							<DatePicker.RangePicker
								placeholder={[t('common.from'), t('common.to')]}
								format={DATE_TIME_FORMAT}
								showTime={{ format: TIME_FORMAT }}
								disabledDate={disabledDate}
								disabledTime={disabledDateTime}
							/>
						</FormItem>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	)
}

export default Filters;