import { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { Tabs } from "antd";

import Breadcrumbs from "components/common/breadcrumbs";
import GeneralComponent from "components/dashboard/usersFeedback/CSAT/edit/sections/general";
import PartnersComponent from "components/dashboard/usersFeedback/CSAT/edit/sections/partners";

import { getSurveyGeneralInfo } from "store/actions/dashboard/usersFeedback/CSAT/generalInfo.action";
import { resetEditingSurvey } from "store/actions/dashboard/usersFeedback/CSAT/surveys.action";

import Paths from "constants/path.constants";

import { updateLocationHash, getHashValue } from "utils/common";

const SurveyEdit = ({ name, getSurveyGeneralInfo }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const [activeKey, setActiveKey] = useState("1");
	const [unsavedTabs, setUnsavedTabs] = useState([]);

	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((tab) => tab !== key));
		}
	};

	/** Reset Partner Access on unmounth */
	useEffect(() => {
		return () => {
			resetEditingSurvey();
		};
	}, []);

	/** Load survey general info*/
	useEffect(() => {
		if (routeParams.id) {
			getSurveyGeneralInfo(routeParams.id);
		}

		if (getHashValue("tab")) {
			setActiveKey(getHashValue("tab"));
		}
	}, []);

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.csat"), path: Paths.DASHBOARD_USERS_FEEDBACK_CSAT }, { title: `${t("common.edit")} ${name}` }]} />
				<Tabs
					animated={false}
					activeKey={activeKey}
					onChange={(key) => {
						updateLocationHash("tab=" + key);
						setActiveKey(key);
					}}
					items={[
						{
							key: "1",
							label: <span className={tabClassName("1")}>{t("pages.dashboard.usersFeedback.tabs.general")}</span>,
							children: <GeneralComponent onTabChange={(status) => changeTabSavedStatus(status, "1")} />
						},
						{
							key: "2",
							label: <span className={tabClassName("2")}>{t("pages.dashboard.usersFeedback.tabs.partners")}</span>,
							children: <PartnersComponent onTabChange={(status) => changeTabSavedStatus(status, "2")} />
						}
					]}
				/>
			</div>
		</Fragment>
	);
};

SurveyEdit.propTypes = {
	/** Redux state, represents the name of current editing survey  */
	name: PropTypes.string,
	/** Redux action to get survey General info */
	getSurveyGeneralInfo: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		name: state.surveys.editingSurvey.generalInfo.name
	};
};

const mapDispatchToProps = (dispatch) => ({
	getSurveyGeneralInfo: (surveyId) => {
		dispatch(getSurveyGeneralInfo(surveyId));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyEdit);
