import { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import { Tabs } from "antd";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import FootballSceneProbabilitiesComponent from "./football/sceneProbabilities";
import FootballHandicapProbabilityComponent from "./football/handicapProbability";
import GoalAndMissScenes from "./football/goalAndMissScenes";
import FootballMatchAndTeams from "./football/matchAndTeams";
import CompetitionsAndRacesComponent from "./racing/competitionsAndRaces";
import StrengthsComponent from "./racing/strengths";
import TeamsAndRatings from "./football/teamsAndRatings";

import { GAME_CATEGORY, SCHEDULED_GAME_TYPE, GAME_TYPES } from "constants/game.constants";

import gameGeneralInfoType from "types/game/generalInfo.type";

import { updateLocationHash, getHashValue, isSeasonGame, isRacingGame } from "utils/common";

/** Propbabilities Tab Component */
const PropbabilitiesComponent = ({ generalInfo, onTabChange, gameCategory }) => {
	const { t } = useTranslation();

	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf PropbabilitiesComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	useEffect(() => {
		onTabChange(unsavedTabs.length > 0);
	}, [unsavedTabs]);

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf PropbabilitiesComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	return generalInfo.type ? (
		<Tabs
			animated={false}
			className="sub-tabs"
			defaultActiveKey={getHashValue("subtab")}
			onChange={(key) => updateLocationHash("subtab=" + key, true)}
			items={[
				generalInfo.type === GAME_TYPES[gameCategory].FOOTBALL_SINGLE_MATCH.value || (gameCategory === GAME_CATEGORY.SCHEDULED && isSeasonGame(generalInfo.type))
					? {
							key: "1",
							label: <span className={tabClassName("1")}>{t("pages.dashboard.games.probabilities.g_ng_scenes")}</span>,
							children: <FootballSceneProbabilitiesComponent gameCategory={gameCategory} onTabChange={(status) => changeTabSavedStatus(status, "1")} />
						}
					: null,
				gameCategory === GAME_CATEGORY.SCHEDULED && generalInfo.type === SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value
					? {
							key: "4",
							label: <span className={tabClassName("4")}>{t("pages.dashboard.games.probabilities.g_m_probabality")}</span>,
							children: <GoalAndMissScenes onTabChange={(status) => changeTabSavedStatus(status, "4")} />
						}
					: null,
				gameCategory === GAME_CATEGORY.SCHEDULED && (generalInfo.type === SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value || isSeasonGame(generalInfo.type))
					? {
							key: "2",
							label: <span className={tabClassName("2")}>{t("pages.dashboard.games.probabilities.handicap_probability")}</span>,
							children: <FootballHandicapProbabilityComponent gameCategory={gameCategory} onTabChange={(status) => changeTabSavedStatus(status, "2")} />
						}
					: null,
				gameCategory === GAME_CATEGORY.SCHEDULED && generalInfo.type === SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value
					? {
							key: "6",
							label: <span className={tabClassName("6")}>{t("pages.dashboard.games.probabilities.teams_and_ratings")}</span>,
							children: <TeamsAndRatings onTabChange={(status) => changeTabSavedStatus(status, "6")} />
						}
					: null,
				generalInfo.type === GAME_TYPES[gameCategory].FOOTBALL_SINGLE_MATCH.value || (gameCategory === GAME_CATEGORY.SCHEDULED && isSeasonGame(generalInfo.type))
					? {
							key: "3",
							label: <span className={tabClassName("3")}>{t("pages.dashboard.games.probabilities.match_and_teams")}</span>,
							children: <FootballMatchAndTeams gameCategory={gameCategory} />
						}
					: null,
				gameCategory === GAME_CATEGORY.SCHEDULED && isRacingGame(generalInfo.type)
					? {
							key: "1",
							label: <span className={tabClassName("1")}>{t("pages.dashboard.games.probabilities.strengths")}</span>,
							children: <StrengthsComponent onTabChange={(status) => changeTabSavedStatus(status, "1")} />
						}
					: null,
				gameCategory === GAME_CATEGORY.SCHEDULED && isRacingGame(generalInfo.type)
					? {
							key: "2",
							label: <span className={tabClassName("2")}>{t("pages.dashboard.games.probabilities.competitions_and_races")}</span>,
							children: <CompetitionsAndRacesComponent onTabChange={(status) => changeTabSavedStatus(status, "2")} />
						}
					: null
			]}
		/>
	) : (
		<Fragment />
	);
};

/** PropbabilitiesComponent propTypes
 * PropTypes
 */
PropbabilitiesComponent.propTypes = {
	/** Fires when for any form in tab, saved/unsaved state is changed */
	onTabChange: PropTypes.func,
	/** Redux state, represents the general info of current editing game  */
	generalInfo: gameGeneralInfoType,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapStateToProps = (state) => {
	return {
		generalInfo: state.games.editingGame.generalInfo
	};
};

export default connect(mapStateToProps, null)(PropbabilitiesComponent);
