import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Button, Tag } from "antd";

import Table from "components/common/table";
import Question from "components/common/question";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { getBetslipsDBCacheCompare, syncronizeDBCache } from "store/actions/dashboard/betslips/dbCacheCompare.action";

import dbCacheCompareType from "types/bet/dbCacheCompare.type";

import { PROJECT_TYPE, DATA_DIFFERENCE_TYPE } from "constants/common.constants";
import { BETSLIP_MODES, BET_STATE, BET_STATE_COLOR } from "constants/bet.constants";
import { PERMISSION_ACTION } from "constants/permissions.constants";

import { hasPermission } from "utils/permissions";

/** Bet DB Cache Compare Component */
const BetDBCacheCompare = ({ id, projectType, permission, getBetslipsDBCacheCompare, syncronizeDBCache, isLoading, isSaving, dbCacheCompareData }) => {
	const { t } = useTranslation();

	const [showSyncronizePopup, setShowSyncronizePopup] = useState(false);

	const getColumns = () => {
		const columns = [
			{
				title: t("pages.dashboard.bets.id"),
				dataIndex: "id",
				sorter: false
			},
			{
				title: t("pages.dashboard.bets.date"),
				dataIndex: "betTime",
				render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT),
				sorter: false
			},
			{
				title: t("pages.dashboard.bets.bet_type"),
				dataIndex: "type",
				render: (value) => t(`pages.dashboard.bets.${value === BETSLIP_MODES.SINGLE ? "single" : value === BETSLIP_MODES.MULTI ? "multi" : ""}`),
				sorter: false
			},
			{
				title: `${t("pages.dashboard.bets.cache_compare.db")} ${t("pages.dashboard.bets.status")}`,
				dataIndex: "status",
				className: "ant-table-tag-cell",
				render: (value) => <Tag color={BET_STATE_COLOR[value]}>{t("pages.dashboard.bets.bet_status_" + value)}</Tag>,
				sorter: false
			},
			{
				title: `${t("pages.dashboard.bets.cache_compare.cache")} ${t("pages.dashboard.bets.status")}`,
				dataIndex: "cacheStatus",
				className: "ant-table-tag-cell",
				render: (value, record) => (record.differenceType !== DATA_DIFFERENCE_TYPE.MISSING ? <Tag color={BET_STATE_COLOR[value]}>{t("pages.dashboard.bets.bet_status_" + value)}</Tag> : " - "),
				sorter: false
			}
		];

		return columns;
	};

	/** Function to get table details data
	 * @function
	 * @param {object} record - row item
	 * @returns {object} - table details data
	 * @memberOf BetDBCacheCompare
	 */
	const getDetailsData = (record) => {
		const betStatus = record?.difference?.status ?? {};

		return record.bets
			? {
					columns: [
						{
							title: t("pages.dashboard.bets.bet_id"),
							dataIndex: "id"
						},
						{
							title: `${t("pages.dashboard.bets.cache_compare.db")} ${t("pages.dashboard.bets.status")}`,
							dataIndex: "status_old",
							className: "ant-table-tag-cell",
							render: (value, record) =>
								record.differenceType !== DATA_DIFFERENCE_TYPE.BET_DIFFERENCE ? (
									<Tag color={BET_STATE_COLOR[betStatus.item1]}>{t("pages.dashboard.bets.bet_status_" + (betStatus.item1 ?? ""))}</Tag>
								) : (
									<Tag color={BET_STATE_COLOR[record?.status]}>{t("pages.dashboard.bets.bet_status_" + (record?.status ?? ""))}</Tag>
								),
							sorter: false
						},
						{
							title: `${t("pages.dashboard.bets.cache_compare.cache")} ${t("pages.dashboard.bets.status")}`,
							dataIndex: "status_new",
							className: "ant-table-tag-cell",
							render: (value, record) =>
								record.differenceType === DATA_DIFFERENCE_TYPE.MISSING ? (
									" - "
								) : record.differenceType === DATA_DIFFERENCE_TYPE.BET_DIFFERENCE ? (
									<Tag color={BET_STATE_COLOR[record?.status]}>{t("pages.dashboard.bets.bet_status_" + (record?.status ?? ""))}</Tag>
								) : (
									<Tag color={BET_STATE_COLOR[betStatus.item2]}>{t("pages.dashboard.bets.bet_status_" + (betStatus.item2 ?? ""))}</Tag>
								),
							sorter: false
						}
					],
					data: record.bets,
					uniqueKey: "id"
				}
			: null
	};

	/** After syncronzie close the question popup */
	useEffect(() => {
		if (!isSaving) {
			setShowSyncronizePopup(false);
		}
	}, [isSaving]);

	return (
		<div className="dashboard-section-content">
			<div className="table-header">
				<div className="table-buttons-dropdowns">
					{hasPermission({ resource: permission, action: PERMISSION_ACTION.MODIFY }) && (
						<Button onClick={() => setShowSyncronizePopup(true)} type="primary" disabled={dbCacheCompareData.length === 0}>
							{t("pages.dashboard.bets.cache_compare.synchronize")}
						</Button>
					)}
				</div>
			</div>
			<Table
				loading={isLoading}
				columns={getColumns()}
				loadFn={() => getBetslipsDBCacheCompare(id, projectType)}
				data={dbCacheCompareData}
				total={dbCacheCompareData.length}
				isDisabled={(record) => false}
				noPagination={true}
				uniqueKey="id"
				nonFixed={true}
				detailsType="table"
				details={getDetailsData}
				isNonExpendable={(record) => Boolean(record?.bets?.length)}
			/>
			<Question type="confirm" onOk={() => syncronizeDBCache(id, projectType)} onCancel={() => setShowSyncronizePopup(false)} isVisible={showSyncronizePopup} message={t("pages.dashboard.bets.cache_compare.synchronize_message")} isLoading={isSaving} />
		</div>
	);
};

/** BetDBCacheCompare propTypes
 * PropTypes
 */
BetDBCacheCompare.propTypes = {
	/** The item(player/betshop) id */
	id: PropTypes.string,
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	/** The resource permission */
	permission: PropTypes.string,
	/** Redux action, to get betslip db cache compare data */
	getBetslipsDBCacheCompare: PropTypes.func,
	/** Redux action, to syncronize betslip db cache compare data */
	syncronizeDBCache: PropTypes.func,
	/** Redux state property, represents the array of db cache compare data */
	dbCacheCompareData: PropTypes.arrayOf(dbCacheCompareType),
	/** Redux state property is true when loading db cache compare data */
	isLoading: PropTypes.bool,
	/** Redux state property is true when syncronizing db cache compare data */
	isSaving: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	getBetslipsDBCacheCompare: (id, projectType) => {
		dispatch(getBetslipsDBCacheCompare(id, projectType));
	},
	syncronizeDBCache: (id, projectType) => {
		dispatch(syncronizeDBCache(id, projectType));
	}
});

const mapStateToProps = (state) => {
	return {
		dbCacheCompareData: state.betslips.dbCacheCompare.data,
		isLoading: state.betslips.isLoading,
		isSaving: state.betslips.isSaving
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BetDBCacheCompare);
