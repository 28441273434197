import { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Form from "antd/lib/form";
import Select from "antd/lib/select";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import LoadedTopMarketsChart from "./loadedChart";
import NoChartData from "../noChartData";
import { connect } from "react-redux";
import { setDashboardFilters } from "store/actions/dashboard/dashboard/dashboard.action";
import { makeGroupTitle } from "utils/markets";
import { PROJECT_TYPE } from "constants/common.constants";
import { GAME_CATEGORY, GAME_TYPES, GAME_TYPE_MAPPER, INSTANT_GAME_TYPE, SCHEDULED_GAME_TYPE, SCHEDULED_GAME_TYPE_MAPPER } from "constants/game.constants";
import {
	chartInitialOptions,
	containerProps,
	formInitialValues,
} from "./initialConfigs";
import topMarketType from "types/dashboard/topMarket.type";

/** Dashboard Top Markets Chart Component */
const TopMarketsChart = ({
	projectType,
	topMarkets,
	gameType,
	gameCategory,
	setDashboardFilters,
	isTopMarketsLoading
}) => {
	const { t } = useTranslation();
	const [chartOptions, setChartOptions] = useState(chartInitialOptions);
	const [formInstance] = Form.useForm();
	const { setFieldsValue } = formInstance;

	const gameTypesList = (
		gameCategory === GAME_CATEGORY.SCHEDULED
			? { [GAME_CATEGORY.SCHEDULED]: SCHEDULED_GAME_TYPE }
			: gameCategory === GAME_CATEGORY.INSTANT
				? { [GAME_CATEGORY.INSTANT]: INSTANT_GAME_TYPE }
				: { ...GAME_TYPES }
	);

	useEffect(() => setFieldsValue({ gameType }), [gameType, setFieldsValue]);
	useEffect(() => {
		setChartOptions((prev) => {
			const series = topMarkets.map((topMarket) => {
				const marketGroupTitle = makeGroupTitle(
					0,
					topMarket.market,
					{},
					topMarket.argument,
					null,
					topMarket.gameType
				);
				const gameName =
					gameType !== ""
						? ""
						: ` (${t(`common.${GAME_TYPE_MAPPER[topMarket.gameCategory][topMarket.gameType]}`)})`;
				return {
					name: `${marketGroupTitle}${gameName}`,
					data: [topMarket.betCount]
				};
			});
			return { ...prev, series };
		});
	}, [topMarkets, gameType, t]);

	return (
		<div className="dashboard-chart dashboard-chart-horizontal-amounts dashboard-chart-top-markets">
			<div className="dashboard-chart-header">
				<span className="dashboard-chart-header-title">{t("pages.dashboard.dashboard.top_markets")}</span>
			</div>
			<Form colon={false} requiredMark={false} layout="vertical" form={formInstance} initialValues={formInitialValues}>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xxl={4}>
						<Form.Item label={t("pages.dashboard.dashboard.games")} name="gameType">
							<Select disabled={isTopMarketsLoading} suffixIcon={<i className="icon-down" />} onChange={(value) => setDashboardFilters({ gameType: value }, projectType)} getPopupContainer={(trigger) => trigger.parentNode}>
								<Select.Option value="">{t("common.all")}</Select.Option>
								{
									Object.entries(gameTypesList)
										.flatMap(([key, gameTypes]) => {
											return Object.values(gameTypes).map(gt => {
												return {
													gameCategory: Number(key),
													gameType: gt.value,
													value: `${key}-${gt.value}`
												}
											})
										}).map((d) => (
											<Select.Option key={d.value} value={d.value}>
												{t(`common.${GAME_TYPE_MAPPER[d.gameCategory][d.gameType]}`)}
											</Select.Option>
										))}
							</Select>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<div className="dashboard-chart-content">
				{isTopMarketsLoading ? (
					<Fragment>
						<LoadedTopMarketsChart />
						<div className="top-markets-legend-skeleton">
							{Array.from(Array(3).keys()).map((key) => (
								<div key={key}>
									<div className="legend-item">
										<div></div>
										<div data-width="222"></div>
									</div>
									<div className="legend-item">
										<div></div>
										<div data-width="195"></div>
									</div>
									<div className="legend-item">
										<div></div>
										<div data-width="215"></div>
									</div>
								</div>
							))}
						</div>
					</Fragment>
				) : !isTopMarketsLoading && !topMarkets.length ? (
					<NoChartData />
				) : (
					<HighchartsReact highcharts={Highcharts} containerProps={containerProps} options={chartOptions} />
				)}
			</div>
		</div>
	);
};

/** TopMarketsChart propTypes
 * PropTypes
 */
TopMarketsChart.propTypes = {
	/** Redux state property, the count of online or retail top markets */
	topMarkets: PropTypes.arrayOf(topMarketType),
	/** Project type(Online / Retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	/** Redux state property, dashboard filters*/
	gameType: PropTypes.oneOf([
		"",
		...Object.values(SCHEDULED_GAME_TYPE).map((gt) => `${GAME_CATEGORY.SCHEDULED}-${gt.value}`),
		...Object.values(INSTANT_GAME_TYPE).map((gt) => `${GAME_CATEGORY.INSTANT}-${gt.value}`),
	]),
	gameCategory: PropTypes.oneOf(["", ...Object.values(GAME_CATEGORY)]),
	/** Redux action to set filters */
	setDashboardFilters: PropTypes.func,
	// Redux state controls topMarketsCharts data loading indicator
	isTopMarketsLoading: PropTypes.bool,
	// Redux state controls gamePerformanceChart data loading indicator
	isGamesPerformanceLoading: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
	let topMarkets = [];
	let gameType = "";
	let gameCategory = "";
	switch (ownProps.projectType) {
		case PROJECT_TYPE.ONLINE:
			topMarkets = state.dashboard.online.topMarkets;
			gameType = state.dashboard.online.filters.gameType;
			gameCategory = state.dashboard.online.filters.gameCategory;
			break;
		case PROJECT_TYPE.RETAIL:
			topMarkets = state.dashboard.retail.topMarkets;
			gameType = state.dashboard.retail.filters.gameType;
			gameCategory = state.dashboard.retail.filters.gameCategory;
			break;
		default:
			break;
	}

	const isTopMarketsLoading = state.dashboard.isTopMarketsLoading;

	return { topMarkets, gameType, gameCategory, isTopMarketsLoading };
};

const mapDispatchToProps = (dispatch) => ({
	setDashboardFilters: (filters, projectType) => {
		dispatch(setDashboardFilters(filters, projectType));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(TopMarketsChart);
