import { useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ChartLoader from "components/dashboard/statistics/charts/chart-loader";
import NoChartData from "../noChartData";

import { DASHBOARD_PERIOD } from "constants/dashboard.constants";
import { PROJECT_TYPE } from "constants/common.constants";

import dashboardActivityType from "types/dashboard/dashboardActivity.type";

/** Dashboard Page Betslips Chart Component */
const BetslipsChartComponent = ({ onlineBetslipActivities, retailBetslipActivities, period, projectType, isBetslipActivitiesLoading }) => {
	const { t } = useTranslation();

	/** function to make data for BarChart
	 * @function
	 * @param {string} type - categories/series
	 * @memberOf BetslipsChartComponent
	 */
	const getActivitiesChartData = (type) => {
		const result = {
			categories: [],
			series: []
		};

		const activities = projectType === PROJECT_TYPE.ONLINE ? onlineBetslipActivities : retailBetslipActivities;

		if (period === DASHBOARD_PERIOD.TODAY || period === DASHBOARD_PERIOD.YESTERDAY) {
			activities.forEach((a) => {
				result.categories.push(moment(a.time).format("HH:mm"));
			});
		} else if (period === DASHBOARD_PERIOD.LAST_MONTH || period === DASHBOARD_PERIOD.LAST_3_MONTH) {
			activities.forEach((a) => {
				result.categories.push(moment(a.time).format("MM/DD"));
			});
		}

		result.series.push({
			name: t("pages.dashboard.dashboard.bets"),
			data: activities.map((a) => a.count),
			color: "#926ECB"
		});

		return result[type];
	};

	const isNoData = useMemo(() => {
		const series = getActivitiesChartData("series");
		const seriesDataSum = series[0].data.reduce((ac, cur) => (ac += cur), 0);
		return seriesDataSum === 0;
	}, [getActivitiesChartData]);

	return (
		<div className="dashboard-chart">
			<div className="dashboard-chart-header">
				<span className="dashboard-chart-header-title">{t("pages.dashboard.dashboard.total_bets")}</span>
			</div>
			<div className="dashboard-chart-content">
				{isBetslipActivitiesLoading ? <ChartLoader /> : null}
				{isNoData ? (
					<div className="top-players-no-chart-data-wrapper">
						<NoChartData />
					</div>
				) : (
					<HighchartsReact
						highcharts={Highcharts}
						options={{
							accessibility: {
								enabled: false
							},
							chart: {
								type: "areaspline"
							},
							xAxis: {
								crosshair: true,
								labels: {
									formatter: function () {
										return this.value;
									}
								},
								categories: getActivitiesChartData("categories")
							},
							yAxis: {
								title: {
									text: null
								},
								allowDecimals: false
							},
							series: getActivitiesChartData("series"),
							title: {
								text: null
							},
							plotOptions: {
								series: {
									fillOpacity: 0.2
								},
								area: {
									marker: {
										enabled: false,
										symbol: "circle",
										radius: 2,
										states: {
											hover: {
												enabled: true
											}
										}
									}
								}
							},
							tooltip: {
								backgroundColor: "#4D4D4D",
								style: {
									color: "#FFFFFF"
								}
							}
						}}
					/>
				)}
			</div>
		</div>
	);
};

/** BetslipsChartComponent propTypes
 * PropTypes
 */
BetslipsChartComponent.propTypes = {
	/** Redux state property, represents the array of online betslip activities */
	onlineBetslipActivities: PropTypes.arrayOf(dashboardActivityType),
	/** Redux state property, represents the array of retail betslip activities */
	retailBetslipActivities: PropTypes.arrayOf(dashboardActivityType),
	/** The selected period f chart */
	period: PropTypes.oneOf(Object.values(DASHBOARD_PERIOD)),
	/** Project type(Online/ Retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	// Redux state controls betslips activities loading
	isBetslipActivitiesLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		onlineBetslipActivities: state.dashboard.online.betslipActivities,
		retailBetslipActivities: state.dashboard.retail.betslipActivities,
		isBetslipActivitiesLoading: state.dashboard.isBetslipActivitiesLoading
	};
};

export default connect(mapStateToProps, null)(BetslipsChartComponent);
