import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { Tag, Tooltip } from "antd";

import HistoryFilters from "./historyFilters";
import { useTranslation } from "react-i18next";
import { getBetAnomaliesHistory, setBetAnomaliesHistorySorting } from "store/actions/dashboard/riskManagment/betAnomalies/betAnomalies.actions";

import Table from "components/common/table";
import ExportButtonImminently from "components/common/exportButton/exportButtonImminently";

import { binaryToFlags, getGamesFromConfig } from "utils/common";
import { hasPermission } from "utils/permissions";
import { GAME_TYPE_MAPPER } from "constants/game.constants";
import { EXPORT_TYPE } from "constants/common.constants";
import { DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import ApiUrls from "constants/api.constants";
import { RISK_CASE_STATUS_COLOR, RISK_CASE_STATUS_TRANSLATIONS } from "constants/riskManagement.constants";
import ChangeRiskCaseStatusPopup from "./changeRiskCaseStatusPopup";
import ChangeNotePopup from "./changeNotePopup";

const dashedString = "- - -";

const BetAnomaliesHistory = ({
	isLoading = false,
	betAnomaliesHistory = [],
	total = 0,
	filters = {},
	sorting = {},
	getBetAnomaliesHistory = Function.prototype,
	setBetAnomaliesHistorySorting = Function.prototype,
}) => {

	const { t } = useTranslation();
	const [showRiskCaseStatusChangePopup, setShowRiskCaseStatusChangePopup] = useState(null);
	const [showNoteChangePopup, setShowNoteChangePopup] = useState(null);

	const columns = [
		{
			title: t("pages.dashboard.bets.player_external_id"),
			dataIndex: "playerExternalId"
		},
		{
			title: t("pages.dashboard.bets.player_id"),
			dataIndex: "playerId"
		},
		{
			title: t("common.partner"),
			dataIndex: "partnerName"
		},
		{
			title: t("pages.dashboard.bets.project"),
			dataIndex: "projectName"
		},
		{
			title: t("common.creation_date"),
			dataIndex: "calculateTime",
			render: (value) => {
				return (
					<Fragment>
						<div>{moment.utc(value).local().format(DATE_FORMAT)}</div>
						<div>{moment.utc(value).local().format(TIME_FORMAT)}</div>
					</Fragment>
				);
			}
		},
		{
			title: t("pages.dashboard.risk_management.bet_anomalies_history_filter_winCount"),
			dataIndex: "winCount",
			render: (_, record) => {
				return record?.winCount ?? dashedString;
			}
		},
		{
			title: t("pages.dashboard.risk_management.bet_anomalies_history_filter_winPercent"),
			dataIndex: "winPercent",
			render: (value, record) => {
				return value ?? dashedString;
			}
		},
		{
			title: `${t("pages.dashboard.risk_management.bet_anomalies_history_filter_defaultTotalWinAmount")} (EUR)`,
			dataIndex: "defaultTotalWinAmount",
			render: (value, record) => {
				return value ?? dashedString;
			}
		},
		{
			title: t("pages.dashboard.risk_management.bet_anomalies_history_filter_riskCaseStatus"),
			dataIndex: "riskCaseStatus",
			render: (value, record) => {
				return (
					<Tag color={RISK_CASE_STATUS_COLOR[value]}>
						<Fragment>
							{t(RISK_CASE_STATUS_TRANSLATIONS[value])}
							{
								hasPermission({ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BET_ANOMALIES_HISTORY, action: PERMISSION_ACTION.MODIFY })
									? (
										<i
											className="icon-edit"
											onClick={() => {
												return handleRiskCaseStatusChangeClick(record);
											}}
										/>
									) : null
								}
						</Fragment>
					</Tag>
				)
			}
		},
		{
			title: t("pages.dashboard.risk_management.bet_anomalies_history_note"),
			dataIndex: "note",
			render: (value, record) => {
				return (
					<Tag style={{ background: "transparent" }} className="vs--flex">
						<Fragment>
							<span style={{ maxWidth: 140 }} className="inline-block vs--ellipsis vs--pr-8">
								{
									value
										? (
											<Tooltip
												title={value}
											>
												{value}
											</Tooltip>
										)
										: t("pages.dashboard.risk_management.bet_anomalies_history_addNote")
								}
							</span>
							{
								hasPermission({ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BET_ANOMALIES_HISTORY, action: PERMISSION_ACTION.MODIFY })
									? (
										<i
											className="icon-edit"
											onClick={() => {
												return handleNoteChangeClick(record);
											}}
										/>
									) : null
								}
						</Fragment>
					</Tag>
				)
			},
			sorter: false,
			width: 228
		},
	];
	const { partnerName, ...exportFilters } = filters;

	const handleRiskCaseStatusChangeClick = (record) => {
		setShowRiskCaseStatusChangePopup(record)
	}
	
	const handleNoteChangeClick = (record) => {
		setShowNoteChangePopup(record)
	}

	return (
		<div className="dashboard-section table-section">
			<div className="dashboard-section-content">
				<div className="table-header">
					<div className="table-buttons-dropdowns">
						{hasPermission({ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BET_ANOMALIES_HISTORY, action: PERMISSION_ACTION.EXPORT }) && (
							<ExportButtonImminently
								url={ApiUrls.EXPORT_RISK_MANAGEMENT_BET_ANOMALIES_HISTORY}
								exportType={EXPORT_TYPE.CSV}
								filters={exportFilters}
								sorting={sorting}
							/>
						)}
					</div>
					<HistoryFilters />
				</div>
				<Table
					loading={isLoading}
					columns={columns}
					data={betAnomaliesHistory}
					loadFn={getBetAnomaliesHistory}
					sorting={sorting}
					setSortingFn={setBetAnomaliesHistorySorting}
					total={total}
					detailsType="table"
					uniqueKey="id"
				/>
				{showRiskCaseStatusChangePopup ? <ChangeRiskCaseStatusPopup item={showRiskCaseStatusChangePopup} onClose={() => setShowRiskCaseStatusChangePopup(null)} /> : null}
				{showNoteChangePopup ? <ChangeNotePopup item={showNoteChangePopup} onClose={() => setShowNoteChangePopup(null)} /> : null}
			</div>
		</div>
	);
};

/**
 * BetAnomaliesHistory PropTypes
 */
BetAnomaliesHistory.propTypes = {
	filters: PropTypes.object,
	sorting: PropTypes.object,
	isLoading: PropTypes.bool,
	betAnomaliesHistory: PropTypes.arrayOf(PropTypes.object),
	total: PropTypes.number,
	getBetAnomaliesHistory: PropTypes.func,
	setBetAnomaliesHistorySorting: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getBetAnomaliesHistory: (fromFirstPage) => {
		dispatch(getBetAnomaliesHistory(fromFirstPage));
	},
	setBetAnomaliesHistorySorting: (sorting) => {
		dispatch(setBetAnomaliesHistorySorting(sorting));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.riskManagement.betAnomalies.history.filters,
		sorting: state.riskManagement.betAnomalies.history.sorting,
		isLoading: state.riskManagement.betAnomalies.history.isLoading,
		betAnomaliesHistory: state.riskManagement.betAnomalies.history.data,
		total: state.riskManagement.betAnomalies.history.total
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BetAnomaliesHistory);
