export const PERMISSION_REQUEST_TYPE = {
	GROUP_MODIFY: 1 << 0,
	ADD_PARTNER_ACCESS: 1 << 1,
	DELETE_PARTNER_ACCESS: 1 << 2,
	ADD_PROJECT_ACCESS: 1 << 3,
	DELETE_PROJECT_ACCESS: 1 << 4,
	ADD_PERMISSION_GROUP: 1 << 5,
	DELETE_PERMISSION_GROUP: 1 << 6,
	ADD_BETSHOP_ACCESS: 1 << 7,
	DELETE_BETSHOP_ACCESS: 1 << 8
};

export const PERMISSION_REQUEST_OBJECT_TYPE = {
	PERMISSION_GROUP: 1 << 0,
	USER: 1 << 1
};

export const PERMISSION_REQUEST_STATUS = {
	APPROVED: 1,
	REJECTED: 2
};

export const PERMISSION_REQUESTS_MENU_KEYS = {
	PENDING_REQUESTS: "1",
	HISTORY: "2"
};
