import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { PARTNER_ACTIVITY_BEFORE, PARTNER_ACTIVITY_FINISH, PARTNER_SET_PARTNER_ACTIVITY, PARTNER_SET_ACTIVITY_SORTING, PARTNER_SET_ACTIVITY_FILTERS } from "../../../actionTypes";

const setPartnerActivityBefore = () => ({
	type: PARTNER_ACTIVITY_BEFORE
});

const setPartnerActivityFinished = () => ({
	type: PARTNER_ACTIVITY_FINISH
});

const setPartnerActivity = (data) => ({
	type: PARTNER_SET_PARTNER_ACTIVITY,
	payload: { data }
});

export const setPartnerActivityFilters = (filters) => ({
	type: PARTNER_SET_ACTIVITY_FILTERS,
	payload: { filters }
});

export const setPartnerActivitySorting = (sorting) => ({
	type: PARTNER_SET_ACTIVITY_SORTING,
	payload: { sorting }
});

export const getPartnerActivity = () => {
	return (dispatch, getState) => {
		dispatch(setPartnerActivityBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_WALLET_HISTORY}`,
			method: Methods.GET,
			params: {
				...getState().partners.viewingPartner.activity.sorting,
				...getState().partners.viewingPartner.activity.filters
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setPartnerActivity(data));
				dispatch(setPartnerActivityFinished());
			})
			.catch(() => {
				dispatch(setPartnerActivityFinished());
			});
	};
};
