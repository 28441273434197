import { message } from "antd";

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";
import { SET_POST_DEPLOYMENT_ACTIONS_ACTION_BEFORE, SET_POST_DEPLOYMENT_ACTIONS_ACTION_FINISH, SET_POST_DEPLOYMENT_TRANSLATION_LANGUAGE_CODES } from "store/actionTypes";
import { downloadURI } from "utils/common";

const setPostDeploymentActionsActionBefore = () => ({
	type: SET_POST_DEPLOYMENT_ACTIONS_ACTION_BEFORE
});

const setPostDeploymentActionsActionFinished = () => ({
	type: SET_POST_DEPLOYMENT_ACTIONS_ACTION_FINISH
});

const setPostDeploymentTranslationLanguageCodes = (languageCodes) => ({
	type: SET_POST_DEPLOYMENT_TRANSLATION_LANGUAGE_CODES,
	payload: {
		languageCodes: languageCodes
	}
});

export const updateTranslations = ({ file, type, languageCodes }) => {
	const formData = new FormData();
	formData.append("file", file);
	formData.append("type", type);
	languageCodes.forEach((languageCode) => formData.append("languageCodes", languageCode));
	const cancelTokenSource = axios.CancelToken.source();
	return (dispatch) => {
		dispatch(setPostDeploymentActionsActionBefore());
		const requestParams = {
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.UPDATE_POST_DEPLOYMENT_ACTIONS_TRANSLATIONS}`,
			method: Methods.POST,
			headers: {
				"content-type": "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s"
			},
			cancelToken: cancelTokenSource.token,
			data: formData
		};

		return axios(requestParams)
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
				}
			})
			.finally((err) => {
				dispatch(setPostDeploymentActionsActionFinished());
			});
	};
};

export const flushCache = ({ db }) => {
	return (dispatch) => {
		dispatch(setPostDeploymentActionsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.POST_DEPLOYMENT_ACTIONS_FLUSH_CACHE}`,
			method: Methods.POST,
			data: { db: db }
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
				}
			})
			.finally(() => {
				dispatch(setPostDeploymentActionsActionFinished());
			});
	};
};

export const getPostDeploymentTranslationLanguageCodes = () => {
	return (dispatch) => {
		dispatch(setPostDeploymentActionsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_POST_DEPLOYMENT_TRANSLATION_ACTIONS_LANGUAGES}`,
			method: Methods.GET,
			params: {}
		})
			.then(({ data: { value } }) => {
				dispatch(setPostDeploymentTranslationLanguageCodes(value));
			})
			.finally(() => {
				dispatch(setPostDeploymentActionsActionFinished());
			});
	};
};

export const exportPostDeploymentActionTranslations = ({ type, languageCodes }) => {
	return (dispatch) => {
		dispatch(setPostDeploymentActionsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.EXPORT_POST_DEPLOYMENT_ACTIONS_TRANSLATIONS}`,
			method: Methods.POST,
			data: { type, languageCodes }
		})
			.then(({ data }) => {
				if (data.status !== RESPONSE_STATE.SUCCESS) {
					return;
				}
				const { value } = data;
				const fileUrl = `${import.meta.env.SYSTEM_CDN_URL}/${value?.toLowerCase()}`;
				const fileName = value.split("/").at(-1);
				return { fileUrl, fileName };
			})
			.then((options) => {
				if (!options) {
					return;
				}
				const { fileUrl, fileName } = options;
				downloadURI(fileUrl, fileName);
			})
			.finally(() => {
				dispatch(setPostDeploymentActionsActionFinished());
			});
	};
};
