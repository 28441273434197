import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Col, Row, Modal, Select, Input } from "antd";
const { Item: FormItem } = Form;

// import { changeSettledBet, changeSettledBetslip } from "store/actions/dashboard/betslips/settled.action";

import { POPUP_SIZE } from "constants/common.constants";
import { RISK_CASE_STATUS_TRANSLATIONS, RISK_CASE_STATUSES } from "constants/riskManagement.constants";
import { saveBetAnomaly } from "store/actions/dashboard/riskManagment/betAnomalies/betAnomalies.actions";

/** Betslip Status changing Popup Component */
const ChangeNotePopup = ({ item, isSaving, saveBetAnomaly, onClose }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;

	const [currentNote, setCurrentNote] = useState(item.note);

	/** Fires when form submitted
	 * @function
	 * @memberOf ChangeNotePopup
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				saveBetAnomaly({ ...item, ...data })
			})
			.then(() => onClose())
			.catch(Function.prototype);
	};

	return (
		<Modal
			open={true}
			title={t("pages.dashboard.risk_management.bet_anomalies_history_note")}
			cancelText={t("common.cancel")}
			okText={t("common.save")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.SMALL}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving, disabled: currentNote === item.note }}
			centered
		>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={item}
			>
				<Row gutter={[16, 0]}>
				<Col span={24}>
						<FormItem
							label={t("pages.dashboard.risk_management.bet_anomalies_history_note")}
							name="note"
						>
							<Input.TextArea
								rows={3}
								maxLength={150}
								placeholder={`${t("common.enter")} ${t("pages.dashboard.risk_management.bet_anomalies_history_note")}`}
								onChange={e => setCurrentNote(e.target.value)}
							/>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** ChangeNotePopup propTypes
 * PropTypes
 */
ChangeNotePopup.propTypes = {
	isSaving: PropTypes.bool,
	saveBetAnomaly: PropTypes.func,
	item: PropTypes.shape({
		id: PropTypes.number,
		playerId: PropTypes.string,
		playerExternalId: PropTypes.string,
		partnerId: PropTypes.string,
		partnerName: PropTypes.string,
		projectId: PropTypes.string,
		projectName: PropTypes.string,
		winCount: PropTypes.number,
		winPercent: PropTypes.number,
		originalTotalWinAmount: PropTypes.number,
		defaultTotalWinAmount: PropTypes.number,
		calculateTime: PropTypes.string,
		riskCaseStatus: PropTypes.oneOf(Object.values(RISK_CASE_STATUSES)),
		note: PropTypes.string,
	}),
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	saveBetAnomaly: (record) => {
		dispatch(saveBetAnomaly(record));
	},
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.riskManagement.betAnomalies.history.isSaving
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeNotePopup);
