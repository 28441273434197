import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumbs from "components/common/breadcrumbs";
import GeneralComponent from "../edit/sections/general";
import { getBonusGeneralInfo } from "store/actions/dashboard/bonuses/generalInfo.action";
import Paths from "constants/path.constants";
import { updateLocationHash, getHashValue } from "utils/common";

/** Bonus Edit Page Component */
const BonusEditComponent = ({ bonusId, getBonusGeneralInfo }) => {
	const routeParams = useParams();
	const { hash } = useLocation();
	const { t } = useTranslation();

	const [activeKey, setActiveKey] = useState(getHashValue("tab") || "1");
	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf BonusEditComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf BonusEditComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	const handleTabChange = (tabKey) => {
		updateLocationHash("tab=" + tabKey);
	};

	/** Load bonus general info */
	useEffect(() => {
		if (!routeParams.id) {
			return;
		}

		getBonusGeneralInfo(routeParams.id);
	}, []);

	/** Update active tab on location hash change */
	useEffect(() => {
		setActiveKey(getHashValue("tab") || "1");
	}, [hash]);

	return (
		<Fragment>
			<div className="dashboard-section">
				{bonusId && (
					<Breadcrumbs
						items={
							[
								{ title: t('pages.dashboard.menu.bonuses'), path: Paths.DASHBOARD_BONUSES_STANDARD },
								{ title: bonusId, path: "" }
							]
						}
					/>
				)}
				<Tabs
					animated={false}
					onChange={handleTabChange}
					activeKey={activeKey}
					items={[
						{
							key: "1",
							label: <span className={tabClassName("1")}>{t("pages.dashboard.bonuses.tabs.general")}</span>,
							children: <GeneralComponent onTabChange={(status) => changeTabSavedStatus(status, "1")} activeTabKey={activeKey} />
						}
					]}
				/>
			</div>
		</Fragment>
	);
};

/** BonusEditComponent propTypes
 * PropTypes
 */
BonusEditComponent.propTypes = {
	/** Redux state property, represents the name of current editing bonus  */
	bonusId: PropTypes.string,
	/** Redux action to get bonus General info */
	getBonusGeneralInfo: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		bonusId: state.bonuses.standard.editingBonus.generalInfo.id,
	}
}

const mapDispatchToProps = (dispatch) => ({
	getBonusGeneralInfo: (id) => {
		dispatch(getBonusGeneralInfo(id));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BonusEditComponent);
