import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import { Form, Spin, Row, Col, Switch, Input, Tooltip, Select, DatePicker, Checkbox, Button } from "antd";
const { Item: FormItem } = Form;
import { saveSurveyGeneralInfo } from "store/actions/dashboard/usersFeedback/CSAT/generalInfo.action";
import locale from "antd/es/date-picker/locale/en_US";
import { isFormChanged } from "utils/form";
import { copyToClipboard } from "utils/common";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { SURVEY_DURATION_TYPE } from "constants/survey.constants";
import { SURVEY_STATE } from "constants/survey.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import surveyGeneralInfoType from "types/survey/generalInfo.type";

/** Survey Edit Page General Info Tab Component */
const SurveyGeneralInfoComponent = ({ saveSurveyGeneralInfo, isSaving, isLoading, generalInfo, onTabChange }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue, getFieldsValue } = formInstance;

	const [isFormTouched, setIsFormTouched] = useState(false);
	const [selectedDurationType, setSelectedDurationType] = useState(null);

	const { durationTime, projectType } = getFieldsValue(["durationTime", "projectType"]);
	const isFormDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.SURVEYS, action: PERMISSION_ACTION.MODIFY }) || generalInfo.state === SURVEY_STATE.NONE || generalInfo.state === SURVEY_STATE.FINISHED || (generalInfo.endDate && moment.utc(generalInfo.endDate) <= moment.utc(Date.now()));

	const handleForm = () => {
		validateFields()
			.then((data) => {
				const { name, enabled, durationTime, suggestMessage } = data;
				const isDurationTimeArray = Array.isArray(durationTime);

				saveSurveyGeneralInfo({
					id: generalInfo.id,
					name,
					enabled,
					suggestMessage,
					startDate: isDurationTimeArray ? moment(durationTime[0]).toDate() : moment(durationTime).toDate(),
					endDate: isDurationTimeArray ? moment(durationTime[1]).toDate() : null
				});
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		const isTemporary = generalInfo.startDate && generalInfo.endDate !== null ? true : false;
		const isPermanent = generalInfo.startDate !== null && generalInfo.startDate !== "";
		const durationType = isTemporary ? SURVEY_DURATION_TYPE.TEMPORARY : isPermanent ? SURVEY_DURATION_TYPE.PERMANENT : null;
		const durationTime = isTemporary ? [moment.utc(generalInfo.startDate).local(), moment.utc(generalInfo.endDate).local()] : isPermanent ? moment.utc(generalInfo.startDate).local() : null;

		setSelectedDurationType(durationType);
		setFieldsValue({
			id: generalInfo.id,
			name: generalInfo.name,
			projectType: t(`pages.dashboard.usersFeedback.${generalInfo.projectType === PROJECT_TYPE.BACKOFFICE ? "backoffice_users" : generalInfo.projectType === PROJECT_TYPE.ONLINE ? "site_players" : "retail_users"}`),
			durationType,
			durationTime,
			state: generalInfo.state,
			suggestMessage: generalInfo.suggestedMessage,
			isTesting: generalInfo.isTesting,
			enabled: generalInfo.enabled
		});
	}, [generalInfo]);

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					id: generalInfo.id,
					name: generalInfo.name,
					projectType: t(`pages.dashboard.usersFeedback.${generalInfo.projectType === PROJECT_TYPE.BACKOFFICE ? "backoffice_users" : generalInfo.projectType === PROJECT_TYPE.ONLINE ? "site_players" : "retail_users"}`),
					durationType: selectedDurationType,
					durationTime: null,
					state: generalInfo.state,
					suggestMessage: generalInfo.suggestedMessage,
					isTesting: generalInfo.isTesting,
					enabled: generalInfo.enabled
				}}
				onValuesChange={(changed, formValues) =>
					setIsFormTouched(
						isFormChanged(
							{ ...formValues },
							{
								id: generalInfo.id,
								name: generalInfo.name,
								projectType,
								durationType: selectedDurationType,
								durationTime: selectedDurationType === SURVEY_DURATION_TYPE.TEMPORARY ? [moment.utc(generalInfo.startDate).local(), moment.utc(generalInfo.endDate).local()] : moment.utc(generalInfo.startDate).local(),
								state: generalInfo.state,
								suggestMessage: generalInfo.suggestedMessage,
								isTesting: generalInfo.isTesting,
								enabled: generalInfo.enabled
							}
						)
					)
				}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h1>{t("pages.dashboard.usersFeedback.general_info")}</h1>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={24} xl={12} xxl={6}>
								<div className="inline-form-item">
									<label>{t("pages.dashboard.menu.csat")}</label>
									<FormItem className="inline-form-item-control" name="enabled" valuePropName="checked">
										<Switch disabled={isFormDisabled} />
									</FormItem>
								</div>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={24} xl={12} xxl={6}>
								<FormItem
									label={`${t("pages.dashboard.usersFeedback.csat_name")} *`}
									name="name"
									rules={[
										{ required: true, whitespace: true, message: t("validation.field_required") },
										{ max: 30, message: t("validation.field_invalid") },
										{ min: 2, message: t("validation.field_invalid") }
									]}
								>
									<Input minLength={2} maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.usersFeedback.csat_name")}`} disabled={isFormDisabled} />
								</FormItem>
							</Col>
							<Col xs={24} sm={24} xl={12} xxl={6}>
								<FormItem label={t("pages.dashboard.usersFeedback.space")} name="projectType">
									<Input disabled={true} />
								</FormItem>
							</Col>
							<Col xs={24} sm={24} xl={12} xxl={6}>
								<FormItem label={t("pages.dashboard.usersFeedback.csat_id")} name="id">
									<Input disabled={true} />
								</FormItem>
								<Tooltip title={t("common.copy") + " " + t("pages.dashboard.usersFeedback.csat_id")} getPopupContainer={() => document.body}>
									<div className="form-copy-button" onClick={() => copyToClipboard(generalInfo.id)}>
										<i className="icon-copy"></i>
									</div>
								</Tooltip>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={`${t("pages.dashboard.usersFeedback.duration_type")} *`} name="durationType">
									<Select suffixIcon={<i className="icon-down" />} placeholder={`${t("common.select")}`} disabled getPopupContainer={() => document.body} autoFocus={true}>
										<Select.Option value={SURVEY_DURATION_TYPE.TEMPORARY}>{t("pages.dashboard.maintenance_mode.temporary")}</Select.Option>
										<Select.Option value={SURVEY_DURATION_TYPE.PERMANENT}>{t("pages.dashboard.maintenance_mode.permanent")}</Select.Option>
									</Select>
								</FormItem>
							</Col>
							{selectedDurationType !== null && (
								<Col xs={24} sm={12} xl={6}>
									<FormItem
										label={`${t(`pages.dashboard.usersFeedback.${selectedDurationType === SURVEY_DURATION_TYPE.TEMPORARY ? "duration_time" : "start_date"}`)} *`}
										name="durationTime"
										rules={[
											() => ({
												validator(rule, durationTime) {
													if (selectedDurationType === SURVEY_DURATION_TYPE.TEMPORARY && !durationTime[1]) {
														return Promise.reject(t("validation.field_required"));
													} else {
														return Promise.resolve();
													}
												}
											})
										]}
									>
										{selectedDurationType === SURVEY_DURATION_TYPE.TEMPORARY ? (
											<DatePicker.RangePicker
												placeholder={[t("common.from"), t("common.to")]}
												format={`${DATE_FORMAT} ${TIME_FORMAT}`}
												showTime={{ format: TIME_FORMAT }}
												disabled={isFormDisabled && moment(durationTime[0]).isBefore(moment()) ? [true, true] : moment(durationTime[0]).isBefore(moment()) ? [true, false] : isFormDisabled ? [false, true] : [false, false]}
												disabledDate={(currentDate) => !currentDate || currentDate.isBefore(moment())}
												allowClear={false}
												locale={{
													...locale,
													lang: {
														...locale.lang,
														ok: t("common.apply")
													}
												}}
												getPopupContainer={() => document.body}
											/>
										) : (
											<DatePicker
												placeholder={t("common.select_time")}
												format={`${DATE_FORMAT} ${TIME_FORMAT}`}
												showTime={{ format: TIME_FORMAT }}
												disabled={isFormDisabled || moment(durationTime).isBefore(moment())}
												disabledDate={(currentDate) => !currentDate || currentDate.isBefore(moment())}
												allowClear={false}
												getPopupContainer={() => document.body}
												locale={{
													...locale,
													lang: {
														...locale.lang,
														ok: t("common.apply")
													}
												}}
											/>
										)}
									</FormItem>
								</Col>
							)}
						</Row>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<div className="inline-form-checkbox">
									<label>{t("pages.dashboard.usersFeedback.suggested_message_box")}</label>
									<FormItem className="inline-form-item-control" name="suggestMessage" valuePropName="checked">
										<Checkbox disabled={isFormDisabled} />
									</FormItem>
								</div>
							</Col>
						</Row>
					</div>
					{!isFormDisabled && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={() => handleForm()} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

SurveyGeneralInfoComponent.propTypes = {
	/** Redux action to save survey General info */
	saveSurveyGeneralInfo: PropTypes.func,
	/** Redux state property, is true when general info is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when general info is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the general info of current editing survey  */
	generalInfo: surveyGeneralInfoType,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	saveSurveyGeneralInfo: (data) => {
		dispatch(saveSurveyGeneralInfo(data));
	}
});

const mapStateToProps = (state) => {
	return {
		generalInfo: state.surveys.editingSurvey.generalInfo,
		isSaving: state.surveys.isSaving,
		isLoading: state.surveys.isLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyGeneralInfoComponent);
