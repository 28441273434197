import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { buildPathToStaticFolderOfCDN } from "utils/common";

const LoadedTopMarketsChart = () => {
	const options = {
		accessibility: {
			enabled: false
		},
		chart: {
			type: "column",
			style: { fontFamily: "Roboto" },
			plotBackgroundImage: [buildPathToStaticFolderOfCDN("admin-images/dashboard/chart-bg.svg")],
			height: 350
		},
		title: {
			text: null
		},
		series: [
			{
				title: {
					enabled: false
				},
				enableMouseTracking: false,
				animation: false,
				data: [1000, 920, 850, 750, 650, 550, 450, 350, 250, 150],
				dataLabels: {
					useHTML: true,
					formatter: function () {
						return `<span style="width: 40px; height: 20px; background: transparent linear-gradient(90deg, #F2F2F2 0%, #E6E6E6 100%) 0% 0% no-repeat padding-box;
				color: transparent; border-radius: 4px"></span>`;
					},
					enabled: false
				},
				pointWidth: 75,
				color: {
					linearGradient: { x1: "0%", y1: "100%" },
					stops: [
						[0, "#F2F2F2"],
						[1, "#E6E6E6 "]
					]
				}
			}
		],
		yAxis: {
			title: {
				enabled: false
			},
			min: 100,
			max: 1000,
			tickInterval: 100,
			labels: {
				x: -10,
				useHTML: true,
				formatter: function () {
					return `<div style="width:62px; height: 16px; background: transparent linear-gradient(90deg, #F2F2F2 0%, #E6E6E6 100%) 0% 0% no-repeat padding-box;
			 color: transparent; border-radius: 4px;">-</div>`;
				}
			}
		},
		xAxis: {
			visible: false,
			labels: {
				enabled: false
			}
		},
		plotOptions: {
			column: {
				pointPlacement: -0.3
			}
		},
		legend: {
			enabled: false
		}
	};

	return <HighchartsReact highcharts={Highcharts} options={options} containerProps={{ style: { width: "100%", height: "100%" } }} />;
};

export default LoadedTopMarketsChart;
