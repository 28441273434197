import { Fragment } from "react";

import LiveMonitorTranslationsComponent from "components/dashboard/cms/liveMonitorTranslations/liveMonitorTranslations.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const LiveMonitorTranslationsRoute = () => {
	return (
		<Fragment>
			<LiveMonitorTranslationsComponent />
		</Fragment>
	);
};

export default withPermission(LiveMonitorTranslationsRoute, { resource: PERMISSION_RESOURCE.TRANSLATIONS_LIVE_MONITOR, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
