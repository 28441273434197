import PropTypes from "prop-types";

import { USER_STATE } from "constants/user.constants";
import { CASHIER_TYPE } from "constants/cashier.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	userName: PropTypes.string,
	externalId: PropTypes.string,
	email: PropTypes.string,
	state: PropTypes.oneOf(Object.values(USER_STATE)),
	type: PropTypes.oneOf(Object.values(CASHIER_TYPE)),
	created: PropTypes.string,
	lastUpdate: PropTypes.string,
	lastUpdateBy: PropTypes.string,
	lastLogin: PropTypes.string,
	betShop: PropTypes.string,
	isOnline: PropTypes.bool
});
