import PropTypes from "prop-types";

import { Slider } from "antd";

import { useTranslation } from "react-i18next";

import { isMobile } from "utils/common";

/** Video Player Controls */
const Controls = ({ onMuteUnmute, onEnlargeShrink, onMinimize, isMuted, extended, showMinimize, showFullscreen, showExtend, volume, onVolumeChange, onFullscreen }) => {
	const { t } = useTranslation();

	return (
		<div className="vs--video-controls">
			<div className="vs--video-controls-inner vs--flex vs--flex-row vs--align-center vs--justify-end vs--pl-8 vs--pr-8">
				<div className="vs--flex vs--flex-row vs--align-center">
					<div className="vs--video-control" onClick={onMuteUnmute} data-action="volume">
						<i className={!isMuted ? "icon-volume" : "icon-mute"} title={isMuted ? t("common.unmute") : t("common.mute")}></i>
					</div>
					{!isMobile() && <Slider max={10} min={0} onChange={(e) => onVolumeChange(e / 10)} value={volume * 10} />}
					{showExtend && (
						<div className="vs--video-control" onClick={onEnlargeShrink} data-action="enlarge">
							<i className={extended ? "ic_video-compress" : "ic_video-enlarge"} title={extended ? t("common.defaultView") : t("common.largeView")}></i>
						</div>
					)}

					{isMobile() && showMinimize && (
						<div className="vs--video-control vs--flex vs--flex-row vs--align-center" onClick={onMinimize} data-action="minimize" title={t("common.miniplayer")}>
							<i className="ic_minimize"></i>
						</div>
					)}
					{showFullscreen && (
						<div className="vs--video-control" onClick={onFullscreen} data-action="fullscreen">
							<i className="icon-fullscreen" title={t("common.fullscreen")}></i>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

/** Controls propTypes
 * PropTypes
 */
Controls.propTypes = {
	/** Function to call on mute/unmute button click */
	onMuteUnmute: PropTypes.func,
	/** Function to call on enlarge/shrink button click */
	onEnlargeShrink: PropTypes.func,
	/** Function to call on minimize/maximize button click */
	onMinimize: PropTypes.func,
	/** Is true when player is muted */
	isMuted: PropTypes.bool,
	/** Is true when player is enlarged */
	extended: PropTypes.bool,
	/** If true video minimize button will be shown on mobile */
	showMinimize: PropTypes.bool,
	/** If true video extend button will be shown */
	showExtend: PropTypes.bool,
	/** Video volume */
	volume: PropTypes.number,
	/** Function to call to change video volume */
	onVolumeChange: PropTypes.func,
	/** If true video fullscreen button will be shown */
	showFullscreen: PropTypes.bool,
	/** Player instance in case of nanoplayer */
	playerInstance: PropTypes.object,
	/** Function to call on fullscreen button click */
	onFullscreen: PropTypes.func
};

export default Controls;
