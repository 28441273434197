import { useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Col, Row, Modal, Input, Radio } from "antd";
const { Item: FormItem } = Form;

import { addApiKeyBlockedIP } from "store/actions/dashboard/online/apikeys/blockedIPs.action";

import { ip6addrCompare } from "utils/ipAddress";

import { IP_V4_ADDRESS_REGEX, IP_V6_ADDRESS_REGEX } from "constants/regex.constants";
import { POPUP_SIZE } from "constants/common.constants";

const IPADDRESS_TYPE = {
	IP: "1",
	RANGE: "2"
};

/** Apikey blocked IP Adding Popup Component */
const BlockedIPAddComponent = ({ isSaving, addApiKeyBlockedIP, onClose }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, getFieldValue } = formInstance;

	const [selectedType, setSelectedType] = useState(IPADDRESS_TYPE.IP);

	/** Fires when form submitted
	 * @function
	 * @memberOf BlockedCountryAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ from, to, ipAddress }) => {
				const d = {
					id: routeParams.id
				};
				if (selectedType === IPADDRESS_TYPE.IP) {
					d.from = ipAddress;
				} else {
					d.from = from;
					d.to = to;
				}
				addApiKeyBlockedIP(d);
			})
			.catch(Function.prototype);
	};

	return (
		<Modal open={true} title={t("pages.dashboard.apikeys.add_ip_address")} cancelText={t("common.cancel")} okText={t("common.save")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.MIDDLE} maskClosable={false} closable={false} okButtonProps={{ loading: isSaving }} centered>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					ip_address: "",
					from: "",
					to: ""
				}}
			>
				<Radio.Group
					options={[
						{
							label: (
								<Row gutter={[16, 0]}>
									<Col xs={24} sm={24}>
										<FormItem
											label={`${t("pages.dashboard.apikeys.ip_address")} *`}
											name="ipAddress"
											rules={
												selectedType === IPADDRESS_TYPE.IP
													? [
															{ required: true, whitespace: true, message: t("validation.field_required") },
															() => ({
																validator(rule, value) {
																	if (value) {
																		if (!IP_V4_ADDRESS_REGEX.test(value) && !IP_V6_ADDRESS_REGEX.test(value)) {
																			return Promise.reject(t("validation.field_invalid"));
																		}
																	}
																	return Promise.resolve();
																}
															})
														]
													: []
											}
										>
											<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.apikeys.ip_address")}`} disabled={selectedType === IPADDRESS_TYPE.RANGE} autoFocus={true} autoComplete="off" />
										</FormItem>
									</Col>
								</Row>
							),
							value: IPADDRESS_TYPE.IP
						},
						{
							label: (
								<Row gutter={[16, 0]}>
									<Col xs={24} sm={12}>
										<FormItem
											label={`${t("pages.dashboard.apikeys.ip_address_from")} *`}
											className="form-item-without-margin"
											name="from"
											rules={
												selectedType === IPADDRESS_TYPE.RANGE
													? [
															{ required: true, whitespace: true, message: t("validation.field_required") },
															() => ({
																validator(rule, value) {
																	if (value) {
																		if (!IP_V4_ADDRESS_REGEX.test(value) && !IP_V6_ADDRESS_REGEX.test(value)) {
																			return Promise.reject(t("validation.field_invalid"));
																		}
																	}
																	return Promise.resolve();
																}
															}),
															({ getFieldValue }) => ({
																validator(rule, value) {
																	const toValue = getFieldValue("to");
																	if (value && toValue && (IP_V4_ADDRESS_REGEX.test(toValue) || IP_V6_ADDRESS_REGEX.test(toValue))) {
																		if (ip6addrCompare(value, toValue) !== -1) {
																			return Promise.reject(t("validation.must_be_less_and_not_equal_than_other").replace("%X%", t("pages.dashboard.apikeys.ip_address_from")).replace("%Y%", t("pages.dashboard.apikeys.ip_address_to")));
																		}
																	}
																	return Promise.resolve();
																}
															})
														]
													: []
											}
											validateFirst
										>
											<Input
												placeholder={`${t("common.enter")} ${t("pages.dashboard.apikeys.ip_address_from")}`}
												disabled={selectedType === IPADDRESS_TYPE.IP}
												onChange={() => {
													setTimeout(() => {
														if (getFieldValue("to") !== "") validateFields(["to"]);
													}, 0);
												}}
											/>
										</FormItem>
									</Col>
									<Col xs={24} sm={12}>
										<FormItem
											label={`${t("pages.dashboard.apikeys.ip_address_to")} *`}
											className="form-item-without-margin"
											name="to"
											rules={
												selectedType === IPADDRESS_TYPE.RANGE
													? [
															{ required: true, whitespace: true, message: t("validation.field_required") },
															() => ({
																validator(rule, value) {
																	if (value) {
																		if (!IP_V4_ADDRESS_REGEX.test(value) && !IP_V6_ADDRESS_REGEX.test(value)) {
																			return Promise.reject(t("validation.field_invalid"));
																		}
																	}
																	return Promise.resolve();
																}
															}),
															({ getFieldValue }) => ({
																validator(rule, value) {
																	const fromValue = getFieldValue("from");
																	if (value && fromValue && (IP_V4_ADDRESS_REGEX.test(fromValue) || IP_V6_ADDRESS_REGEX.test(fromValue))) {
																		if (ip6addrCompare(value, fromValue) !== 1) {
																			return Promise.reject(t("validation.must_be_more_and_not_equal_than_other").replace("%X%", t("pages.dashboard.apikeys.ip_address_to")).replace("%Y%", t("pages.dashboard.apikeys.ip_address_from")));
																		}
																	}
																	return Promise.resolve();
																}
															})
														]
													: []
											}
											validateFirst
										>
											<Input
												placeholder={`${t("common.enter")} ${t("pages.dashboard.apikeys.ip_address_to")}`}
												disabled={selectedType === IPADDRESS_TYPE.IP}
												onChange={() => {
													setTimeout(() => {
														if (getFieldValue("from") !== "") validateFields(["from"]);
													}, 0);
												}}
											/>
										</FormItem>
									</Col>
								</Row>
							),
							value: IPADDRESS_TYPE.RANGE
						}
					]}
					onChange={(e) => setSelectedType(e.target.value)}
					value={selectedType}
					className="radio-fields-wrapper"
				></Radio.Group>
			</Form>
		</Modal>
	);
};

/** BlockedIPAddComponent propTypes
 * PropTypes
 */
BlockedIPAddComponent.propTypes = {
	/** Redux state property, is true when adding blocked ip for api key */
	isSaving: PropTypes.bool,
	/** Redux action to add blocked ip for api key*/
	addApiKeyBlockedIP: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	addApiKeyBlockedIP: (ip) => {
		dispatch(addApiKeyBlockedIP(ip));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.apikeys.isSaving
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockedIPAddComponent);
