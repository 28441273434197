import PropTypes from "prop-types";

import { USER_STATE } from "constants/user.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	userName: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	email: PropTypes.string,
	isTesting: PropTypes.bool,
	created: PropTypes.string,
	lastUpdate: PropTypes.string,
	lastLogin: PropTypes.string,
	lastPasswordChange: PropTypes.string,
	lastBlocked: PropTypes.string,
	lastBlockedBy: PropTypes.string,
	avatarId: PropTypes.number,
	state: PropTypes.oneOf(Object.values(USER_STATE)),
	mainPartner: PropTypes.string,
	partners: PropTypes.arrayOf(PropTypes.string),
	apiKeys: PropTypes.arrayOf(PropTypes.string),
	betShops: PropTypes.arrayOf(PropTypes.string),
	betShopsGroups: PropTypes.arrayOf(PropTypes.string),
	permissionGroups: PropTypes.arrayOf(PropTypes.string),
	permissionCount: PropTypes.number
});
