import { Fragment } from "react";

import PermissionRequestsComponent from "components/dashboard/userManagement/permissionsRequests/permissionsRequests.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const PermissionsRequests = () => {
	return <PermissionRequestsComponent />;
};

export default withPermission(PermissionsRequests, { resource: PERMISSION_RESOURCE.PERMISSION_REQUESTS, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
