import PropTypes from "prop-types";

import { USER_STATE } from "constants/user.constants";

export default PropTypes.shape({
	personalInfo: PropTypes.shape({
		id: PropTypes.string,
		userName: PropTypes.string,
		externalId: PropTypes.string,
		created: PropTypes.string,
		lastActiveAt: PropTypes.string,
		partnerName: PropTypes.string,
		currencyCode: PropTypes.string,
		state: PropTypes.oneOf(Object.values(USER_STATE)),
		projectName: PropTypes.string
	}),
	total: PropTypes.shape({
		ggr: PropTypes.number,
		ngr: PropTypes.number,
		ggrPercent: PropTypes.number,
		turnover: PropTypes.number,
		won: PropTypes.number,
		currentBalance: PropTypes.number,
		betSlipsTotal: PropTypes.number
	}),
	topMarkets: PropTypes.shape({
		markets: PropTypes.arrayOf(
			PropTypes.shape({
				argument: PropTypes.number,
				betCount: PropTypes.number,
				gameType: PropTypes.number,
				market: PropTypes.string
			})
		)
	})
});
