import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Form, Col, Row, Spin } from "antd";
import Flag from "components/common/flag";
import { getFootballTeams } from "store/actions/dashboard/virtuals/games/probabilities.action";
import { SCHEDULED_GAME_TYPE, GAME_CATEGORY, GAME_TYPES, INSTANT_GAME_TYPE } from "constants/game.constants";
import { buildPathToStaticFolderOfCDN, isCupGame } from "utils/common";
import footballTeamsType from "types/game/footballTeams.type";
import gameGeneralInfoType from "types/game/generalInfo.type";
import systemFootballTeams from "systemData/footballTeams";

/** Game Edit Page Probabilities Tab football match and teams subTab Component */
const MatchAndTeamsComponent = ({ isLoading, getFootballTeams, footballTeams, generalInfo, gameCategory }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();

	/** Load game handicap Ppobability */
	useEffect(() => {
		if (routeParams.id) {
			getFootballTeams(routeParams.id, gameCategory);
		}
	}, [routeParams.id, gameCategory]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form colon={false} form={formInstance} requiredMark={false} layout="vertical">
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={24}>
								<h1>{generalInfo.type === GAME_TYPES[gameCategory].FOOTBALL_SINGLE_MATCH.value ? t("pages.dashboard.games.probabilities.stadium_name") : t("common.logo")}</h1>
							</Col>
							<Col xs={24} sm={24}>
								{(() => {
									if (gameCategory === GAME_CATEGORY.SCHEDULED) {
										switch (generalInfo.type) {
											case SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value:
												return (
													<div className="dashboard-card-item">
														<span>San Nuñez Stadium</span>
													</div>
												);
											case SCHEDULED_GAME_TYPE.CHAMPIONS_LEAGUE.value:
												return <img src={buildPathToStaticFolderOfCDN("admin-images/champions-league.svg")} alt={t("common.champions_league")} />;
											case SCHEDULED_GAME_TYPE.COPA_LIBERTADORES.value:
												return <img src={buildPathToStaticFolderOfCDN("admin-images/copa-libertadores.svg")} alt={t("common.copa_libertadores")} />;
											default:
												return <img src={buildPathToStaticFolderOfCDN("admin-images/english-league.svg")} alt={t("common.english_league")} />;
										}
									}
									if (gameCategory === GAME_CATEGORY.INSTANT) {
										switch (generalInfo.type) {
											case INSTANT_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value:
												return (
													<div className="dashboard-card-item">
														<span>San Nuñez Stadium</span>
													</div>
												);
											default:
												return null;
										}
									}
								})()}
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={24}>
								<h1 style={{ marginTop: "24px" }}>{t("pages.dashboard.games.probabilities.teams_playing")}</h1>
								{footballTeams.teams.map((team) => {
									return (
										<div className="dashboard-card-item" key={team}>
											<div>
												<Flag code={team} gameType={generalInfo.type} />
												<span>
													{(generalInfo.type === GAME_TYPES[gameCategory].FOOTBALL_SINGLE_MATCH.value || (gameCategory === GAME_CATEGORY.SCHEDULED && isCupGame(generalInfo.type))) && systemFootballTeams[generalInfo.type]
														? systemFootballTeams[generalInfo.type][team.toLowerCase()]
														: team}
												</span>
											</div>
										</div>
									);
								})}
							</Col>
						</Row>
					</div>
				</div>
			</Form>
		</Spin>
	);
};

/** MatchAndTeamsComponent propTypes
 * PropTypes
 */
MatchAndTeamsComponent.propTypes = {
	/** Redux state property, is true when rtps are loading */
	isLoading: PropTypes.bool,
	/** Redux action to get football teams for game */
	getFootballTeams: PropTypes.func,
	/** Redux state, represents the football teams data of current editing game  */
	footballTeams: footballTeamsType,
	/** Redux state, represents the general info of current editing game  */
	generalInfo: gameGeneralInfoType,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	getFootballTeams: (id, gameCategory) => {
		dispatch(getFootballTeams(id, gameCategory));
	}
});

const mapStateToProps = (state) => {
	return {
		generalInfo: state.games.editingGame.generalInfo,
		footballTeams: state.games.editingGame.probabilities.footballTeams,
		isSaving: state.games.isSaving,
		isLoading: state.games.isLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchAndTeamsComponent);
