import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getApiKeyUI, resetApiKeyUI } from "store/actions/dashboard/online/apikeys/customization.action";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import CustomizationTool from "./tool";
import { sendDataToIframe } from "utils/common";
import apiKeyUIType from "types/apiKey/ui.type";

const VIEW_TYPE = {
	DESKTOP: 1,
	MOBILE: 2
};

/** Api Key Edit Page Customization Tab Component */
const CustomizationComponent = ({ getApiKeyUI, isLoading, ui, previewUI, resetApiKeyUI }) => {
	const routeParams = useParams();
	const [view, setView] = useState(VIEW_TYPE.MOBILE);
	const previewRef = useRef({});

	/** Load api key ui data */
	useEffect(() => {
		if (routeParams.id) {
			getApiKeyUI(routeParams.id, "EN", !ui.launchUrl ? true : false);
		}
	}, [view]);

	/** Load api key ui data */
	useEffect(() => {
		return () => {
			previewRef.current = {};
			resetApiKeyUI();
		};
	}, []);

	/** Send event to iframe on any change of colors */
	useEffect(() => {
		const onReady = (e) => {
			const d = e.data;
			if (d) {
				if (d.eventName === "vs--customization-ready") {
					sendCurrentDataToIframe();
				}
			}
		};
		window.addEventListener("message", onReady, false);

		return () => {
			window.removeEventListener("message", onReady);
		};
	}, []);

	/** Send event to iframe on any change of colors */
	useEffect(() => {
		previewRef.current = previewUI;
		sendCurrentDataToIframe();
	}, [previewUI]);

	/** Send event to iframe on any change of colors */
	useEffect(() => {
		sendDataToIframe("dashboard-customization-iframe", ui.logoId, "vs--customization-mobile-logo");
	}, [ui.logoId]);

	/** Send currecnt ui data to preview iframe
	 * @function
	 * @memberOf CustomizationComponent
	 */
	const sendCurrentDataToIframe = () => {
		const data = Object.keys(previewRef.current).map((p) => ({ key: p, value: previewRef.current[p] }));
		sendDataToIframe("dashboard-customization-iframe", data, "vs--customization");
	};

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<div className="dashboard-section-content">
				<div className="dashboard-customization">
					<div className="dashboard-customization-devices">
						<div className={"dashboard-customization-devices-button" + (view === VIEW_TYPE.MOBILE ? " dashboard-customization-devices-button-active" : "")} onClick={() => setView(VIEW_TYPE.MOBILE)}>
							<i className="icon-mobile" />
						</div>
						<div className={"dashboard-customization-devices-button" + (view === VIEW_TYPE.DESKTOP ? " dashboard-customization-devices-button-active" : "")} onClick={() => setView(VIEW_TYPE.DESKTOP)}>
							<i className="icon-desktop" />
						</div>
					</div>
					{ui.launchUrl && (
						<div className="dashboard-customization-preview">
							{isLoading ? null : view === VIEW_TYPE.DESKTOP ? (
								<div className="dashboard-customization-preview-desktop">
									<iframe id="dashboard-customization-iframe" src={ui.launchUrl} title="Virtual Sport" width="100%" frameBorder="0" allowFullScreen={true} allow="autoplay" />
								</div>
							) : (
								<div className="dashboard-customization-preview-mobile">
									<iframe id="dashboard-customization-iframe" src={`${ui.launchUrl}&isMobile=true`} title="Virtual Sport" width="100%" frameBorder="0" allowFullScreen={true} allow="autoplay" />
								</div>
							)}
							<CustomizationTool currentView={view} />
						</div>
					)}
				</div>
			</div>
		</Spin>
	);
};

/** CustomizationComponent propTypes
 * PropTypes
 */
CustomizationComponent.propTypes = {
	/** Redux action to get apiKey ui data */
	getApiKeyUI: PropTypes.func,
	/** Redux state property, is true when loading ui data */
	isLoading: PropTypes.bool,
	/** Redux state property, apikey ui data */
	ui: apiKeyUIType,
	/** Redux state property, apikey ui data */
	previewUI: apiKeyUIType,
	/** Redux action to reset apiKey ui data */
	resetApiKeyUI: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getApiKeyUI: (id, languageCode, resetData) => {
		dispatch(getApiKeyUI(id, languageCode, resetData));
	},
	resetApiKeyUI: () => {
		dispatch(resetApiKeyUI());
	}
});

const mapStateToProps = (state) => {
	return {
		ui: state.apikeys.editingApikey.customization.ui,
		previewUI: state.apikeys.editingApikey.customization.previewUI,
		isLoading: state.apikeys.isLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizationComponent);
