import {
	PLAYERS_BEFORE,
	PLAYERS_FINISH,
	PLAYERS_SAVE_BEFORE,
	PLAYERS_SAVE_FINISH,
	SET_PLAYERS,
	SET_PLAYERS_SORTING,
	SET_PLAYERS_FILTERS,
	CHANGE_PLAYER_STATE,
	PLAYERS_QUICK_INFO_BEFORE,
	PLAYERS_QUICK_INFO_FINISH,
	SET_PLAYER_QUICK_INFO,
	SET_PLAYER_GENERAL_INFO,
	PLAYERS_SET_PLAYER_SESSIONS,
	PLAYERS_SET_SESSIONS_SORTING,
	PLAYERS_SET_SESSIONS_FILTERS,
	SET_PLAYER_PENDING_BETSLIPS,
	SET_PLAYER_PENDING_BETSLIPS_SORTING,
	SET_PLAYER_PENDING_BETSLIPS_FILTERS,
	SET_PLAYER_SETTLED_BETSLIPS,
	SET_PLAYER_SETTLED_BETSLIPS_SORTING,
	SET_PLAYER_SETTLED_BETSLIPS_FILTERS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case PLAYERS_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case PLAYERS_FINISH:
			return {
				...state,
				isLoading: false
			};
		case PLAYERS_SAVE_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case PLAYERS_SAVE_FINISH:
			return {
				...state,
				isSaving: false
			};
		case SET_PLAYERS:
			return {
				...state,
				players: payload.data.item2,
				total: payload.data.item1
			};
		case SET_PLAYERS_SORTING:
			return {
				...state,
				sorting: payload.sorting
			};
		case SET_PLAYERS_FILTERS:
			return {
				...state,
				filters: payload.filters,
				sorting: {
					...state.sorting,
					page: 1
				}
			};
		case CHANGE_PLAYER_STATE:
			return {
				...state,
				players: state.players.map((p) => (p.id !== payload.data.id ? { ...p } : { ...p, ...payload.data }))
			};
		case PLAYERS_QUICK_INFO_BEFORE:
			return {
				...state,
				quickInfo: {
					...state.quickInfo,
					isLoading: true
				}
			};
		case PLAYERS_QUICK_INFO_FINISH:
			return {
				...state,
				quickInfo: {
					...state.quickInfo,
					isLoading: false
				}
			};
		case SET_PLAYER_QUICK_INFO:
			return {
				...state,
				quickInfo: {
					...state.quickInfo,
					quickInfo: payload.data
				}
			};
		case SET_PLAYER_GENERAL_INFO:
			return {
				...state,
				viewingPlayer: {
					...state.viewingPlayer,
					generalInfo: payload.info
				}
			};
		case PLAYERS_SET_PLAYER_SESSIONS:
			return {
				...state,
				viewingPlayer: {
					...state.viewingPlayer,
					sessions: {
						...state.viewingPlayer.sessions,
						sessions: payload.data.item2,
						total: payload.data.item1
					}
				}
			};
		case PLAYERS_SET_SESSIONS_SORTING:
			return {
				...state,
				viewingPlayer: {
					...state.viewingPlayer,
					sessions: {
						...state.viewingPlayer.sessions,
						sorting: payload.sorting
					}
				}
			};
		case PLAYERS_SET_SESSIONS_FILTERS:
			return {
				...state,
				viewingPlayer: {
					...state.viewingPlayer,
					sessions: {
						...state.viewingPlayer.sessions,
						filters: payload.filters,
						sorting: {
							...state.viewingPlayer.sessions.sorting,
							page: 1
						}
					}
				}
			};
		case SET_PLAYER_PENDING_BETSLIPS:
			return {
				...state,
				viewingPlayer: {
					...state.viewingPlayer,
					betHistory: {
						...state.viewingPlayer.betHistory,
						pendings: {
							...state.viewingPlayer.betHistory.pendings,
							bets: payload.data.item2,
							total: payload.data.item1
						}
					}
				}
			};
		case SET_PLAYER_PENDING_BETSLIPS_SORTING:
			return {
				...state,
				viewingPlayer: {
					...state.viewingPlayer,
					betHistory: {
						...state.viewingPlayer.betHistory,
						pendings: {
							...state.viewingPlayer.betHistory.pendings,
							sorting: payload.sorting
						}
					}
				}
			};
		case SET_PLAYER_PENDING_BETSLIPS_FILTERS:
			return {
				...state,
				viewingPlayer: {
					...state.viewingPlayer,
					betHistory: {
						...state.viewingPlayer.betHistory,
						pendings: {
							...state.viewingPlayer.betHistory.pendings,
							filters: payload.filters,
							sorting: {
								...state.viewingPlayer.betHistory.pendings.sorting,
								page: 1
							}
						}
					}
				}
			};
		case SET_PLAYER_SETTLED_BETSLIPS:
			return {
				...state,
				viewingPlayer: {
					...state.viewingPlayer,
					betHistory: {
						...state.viewingPlayer.betHistory,
						settled: {
							...state.viewingPlayer.betHistory.settled,
							bets: payload.data.item2,
							total: payload.data.item1
						}
					}
				}
			};
		case SET_PLAYER_SETTLED_BETSLIPS_SORTING:
			return {
				...state,
				viewingPlayer: {
					...state.viewingPlayer,
					betHistory: {
						...state.viewingPlayer.betHistory,
						settled: {
							...state.viewingPlayer.betHistory.settled,
							sorting: payload.sorting
						}
					}
				}
			};
		case SET_PLAYER_SETTLED_BETSLIPS_FILTERS:
			return {
				...state,
				viewingPlayer: {
					...state.viewingPlayer,
					betHistory: {
						...state.viewingPlayer.betHistory,
						settled: {
							...state.viewingPlayer.betHistory.settled,
							filters: payload.filters,
							sorting: {
								...state.viewingPlayer.betHistory.settled.sorting,
								page: 1
							}
						}
					}
				}
			};
		default:
			return state;
	}
};
