import PropTypes from "prop-types";

import { NOTIFICATION_STATE, NOTIFICATION_TYPE } from "constants/notification.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	message: PropTypes.string,
	state: PropTypes.oneOf(Object.values(NOTIFICATION_STATE)),
	type: PropTypes.oneOf(Object.values(NOTIFICATION_TYPE)),
	time: PropTypes.string,
	reprintRequestData: PropTypes.object
});
