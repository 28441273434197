import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { PROJECT_TYPE } from "constants/common.constants";

import { EVENTS_BEFORE, EVENTS_FINISH, SET_EVENTS, SET_EVENTS_SORTING, SET_EVENTS_FILTERS, EVENT_BEFORE, EVENT_FINISH, SET_EVENT } from "../../../actionTypes";
import { GAME_CATEGORY } from "constants/game.constants";

const setEventsActionBefore = () => ({
	type: EVENTS_BEFORE
});

const setEventsActionFinished = () => ({
	type: EVENTS_FINISH
});

const setEventActionBefore = () => ({
	type: EVENT_BEFORE
});

const setEventActionFinished = () => ({
	type: EVENT_FINISH
});

const setEvents = (data, projectType, gameCategory) => ({
	type: SET_EVENTS,
	payload: { data, projectType, gameCategory }
});

const setEvent = (data, gameCategory) => ({
	type: SET_EVENT,
	payload: { data, gameCategory }
});

export const setEventsSorting = (sorting, projectType, gameCategory) => ({
	type: SET_EVENTS_SORTING,
	payload: { sorting, projectType, gameCategory }
});

export const setEventsFilters = (filters, projectType, gameCategory) => ({
	type: SET_EVENTS_FILTERS,
	payload: { filters, projectType, gameCategory }
});

export const getEvents = (projectType, fromFirstPage, gameCategory) => {
	return (dispatch, getState) => {
		dispatch(setEventsActionBefore());
		const type = projectType === PROJECT_TYPE.RETAIL ? "retail" : "online";
		const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.SCHEDULED_EVENTS_GET_ALL : ApiUrls.INSTANT_EVENTS_GET_ALL;
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.GET,
			params: {
				...getState().events[type][gameCategory].sorting,
				...getState().events[type][gameCategory].filters,
				page: fromFirstPage ? 1 : getState().events[type][gameCategory].sorting.page,
				projectType: projectType,
				type: 1
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setEvents(data, projectType, gameCategory));
				if (fromFirstPage) dispatch(setEventsSorting({ ...getState().events[type][gameCategory].sorting, page: 1 }, projectType, gameCategory));
				dispatch(setEventsActionFinished());
			})
			.catch(() => {
				dispatch(setEventsActionFinished());
			});
	};
};

export const getEvent = (id, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.SCHEDULED_EVENTS_GET_EVENT : ApiUrls.INSTANT_EVENTS_GET_EVENT;
	return (dispatch) => {
		dispatch(setEventActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setEvent(data, gameCategory));
				dispatch(setEventActionFinished());
			})
			.catch(() => {
				dispatch(setEventActionFinished());
			});
	};
};

export const recalculate = (id, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.SCHEDULED_EVENTS_RECALCULATE : ApiUrls.INSTANT_EVENTS_RECALCULATE;
	return (dispatch) => {
		dispatch(setEventActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.POST,
			data: { id }
		})
			.then(({ data: { message: msg, status, value: data } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setEvent(data, gameCategory));
				}
				dispatch(setEventActionFinished());
			})
			.catch(() => {
				dispatch(setEventActionFinished());
			});
	};
};
