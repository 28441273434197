import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Form from "antd/es/form";
import Select from "antd/es/select";
import SearchableSelect from "components/common/searchableSelect";
import Button from "antd/es/button";
import { exportPostDeploymentActionTranslations } from "store/actions/dashboard/developer/postDeploymentActions/postDeploymentActions.action";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { UPDATING_TRANSLATION_TYPES, UPDATING_TRANSLATION_TYPE_TRANSLATIONS_KEYS, LANGUAGE_CODES_MAX_COUNT } from "constants/developerSpacePostDeploymentActions.constants";

const TranslationsExportActions = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [formInstance] = Form.useForm();
	const languageCodes = useSelector((state) => state.postDeploymentActions.languageCodes);
	const [allowExport, setAllowExport] = useState(true);
	const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.MODIFY });

	return (
		<Col>
			<h2>{t("pages.dashboard.developerSpacePostDeploymentActions.translationsExport")}</h2>
			<Row>
				<Form
					colon={false}
					form={formInstance}
					requiredMark={false}
					layout="vertical"
					initialValues={{
						type: UPDATING_TRANSLATION_TYPES.ALL,
						languageCodes: ["EN"]
					}}
					onValuesChange={(changedValue) => {
						if (!Object.hasOwn(changedValue, "languageCodes")) {
							return;
						}
						const selectedCount = changedValue.languageCodes.length;
						return setAllowExport(selectedCount > 0 && selectedCount <= LANGUAGE_CODES_MAX_COUNT);
					}}
					onFinish={(formData) => {
						dispatch(exportPostDeploymentActionTranslations(formData));
					}}
				>
					<Row gutter={16}>
						<Col>
							<Form.Item label={t("pages.dashboard.developerSpacePostDeploymentActions.translationType")} name="type" layout="inline">
								<Select suffixIcon={<i className="icon-down" />} disabled={!hasModifyPermission}>
									{Object.values(UPDATING_TRANSLATION_TYPES).map((value) => {
										const translationKey = UPDATING_TRANSLATION_TYPE_TRANSLATIONS_KEYS[value];
										return (
											<Select.Option key={value} value={value}>
												{t(`pages.dashboard.developerSpacePostDeploymentActions.${translationKey}`)}
											</Select.Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col>
							<Form.Item
								label={t("pages.dashboard.developerSpacePostDeploymentActions.translationLanguages")}
								name="languageCodes"
								layout="inline"
								rules={[
									{ required: true, message: t("validation.field_required") },
									{
										validator: (rule, value) => {
											if (value) {
												if (value && value.length > LANGUAGE_CODES_MAX_COUNT) {
													return Promise.reject(
														<div style={{ position: "relative" }}>
															<div style={{ position: "absolute", whiteSpace: "nowrap" }}>{t("validation.must_be_less_than_other").replace("%X%", t("pages.dashboard.developerSpacePostDeploymentActions.translationType")).replace("%Y%", LANGUAGE_CODES_MAX_COUNT)}</div>
														</div>
													);
												}
												return Promise.resolve();
											}
											return;
										}
									}
								]}
							>
								<SearchableSelect mode="multiple" getPopupContainer={(trigger) => trigger.parentNode} items={languageCodes} valueProp={(languageCode) => languageCode.key} textProp={(languageCode) => languageCode.value} renderText={(languageCode) => languageCode.value} />
							</Form.Item>
						</Col>
						<Col>
							<Form.Item style={{ marginTop: 20, marginBottom: 0 }} layout="inline">
								<Button type="primary" htmlType="submit" disabled={!(allowExport && hasModifyPermission)}>
									{t("common.export")}
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Row>
		</Col>
	);
};
export default TranslationsExportActions;
