import { Fragment, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Form, Input, Button, Result } from "antd";
const { Item: FormItem } = Form;

import { forgotPassword } from "store/actions/auth/forgotPassword.action";

import Paths from "constants/path.constants";
import { EMAIL_REGEX } from "constants/regex.constants";

/** Forgot Password Page Component */

const ForgotPasswordComponent = ({ forgotPassword, isSaving }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;

	const [emailValue, setEmailValue] = useState("");
	const [showSuccess, setShowSuccess] = useState(false);

	/** Fires when form submitted
	 * @function
	 * @memberOf ForgotPasswordComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ email }) => {
				forgotPassword(email, () => {
					setShowSuccess(true);
				});
			})
			.catch(Function.prototype);
	};

	/** Fires on resend button click
	 * @function
	 * @memberOf ForgotPasswordComponent
	 */
	const handleResend = () => {
		forgotPassword(emailValue);
	};

	return (
		<div className="vs--main-login">
			<Form
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				colon={false}
				initialValues={{
					email: ""
				}}
			>
				{!showSuccess ? (
					<Fragment>
						<FormItem
							label={`${t("pages.auth.email")} *`}
							name="email"
							rules={[
								{ required: true, whitespace: true, message: t("validation.field_required") },
								{ pattern: EMAIL_REGEX, message: t("validation.email_format") },
								{ max: 50, message: t("validation.field_invalid") },
								{ min: 6, message: t("validation.field_invalid") }
							]}
						>
							<Input minLength={6} maxLength={50} placeholder={`${t("common.enter")} ${t("pages.auth.email")}`} onChange={(e) => setEmailValue(e.target.value)} />
						</FormItem>
						<FormItem>
							<label>{t("pages.auth.forgot_password_send_info")}</label>
						</FormItem>
						<FormItem>
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={isSaving}>
								<span>{t("pages.auth.send")}</span>
							</Button>
						</FormItem>
					</Fragment>
				) : (
					<Fragment>
						<Result status="success" subTitle={t("pages.auth.forgot_password_send_success")} />
						<FormItem>
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleResend} disabled={isSaving}>
								<span>{t("pages.auth.not_received")}</span>
							</Button>
						</FormItem>
					</Fragment>
				)}
				<div className="form-footer-links">
					<span onClick={() => navigate(Paths.LOGIN, { replace: true })}>{t("pages.auth.goBackToTheSignInPage")}</span>
				</div>
			</Form>
		</div>
	);
};

/** ForgotPasswordComponent propTypes
 * PropTypes
 */
ForgotPasswordComponent.propTypes = {
	/** Redux state property, is true when sending email for forgot password request is in process */
	isSaving: PropTypes.bool,
	/** Redux action to send email for forgot password */
	forgotPassword: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	forgotPassword: (email, onSuccess) => {
		dispatch(forgotPassword(email, onSuccess));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.auth.forgotPassword.isSaving
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordComponent);
