import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { buildPathToStaticFolderOfCDN } from "utils/common";

const LoadedGamePerformanceChart = () => {
	const options = {
		accessibility: {
			enabled: false
		},
		chart: {
			type: "bar",
			animation: false,
			plotBackgroundImage: [buildPathToStaticFolderOfCDN("admin-images/dashboard/chart-bg.svg")]
		},
		series: [
			{
				enableMouseTracking: false,
				animation: false,
				data: [176, 400, 20, 300, 1000, 340, 50, 150, 600, 850],
				dataLabels: {
					useHTML: true,
					formatter: function () {
						return `<span style="width: 40px; height: 20px; background: transparent linear-gradient(90deg, #F2F2F2 0%, #E6E6E6 100%) 0% 0% no-repeat padding-box;
				color: transparent; border-radius: 4px">TEST</span>`;
					},
					enabled: true
				},

				color: {
					linearGradient: { x1: "0%", y1: "100%" },
					stops: [
						[0, "#F2F2F2"],
						[1, "#E6E6E6 "]
					]
				}
			}
		],
		tooltip: {
			enabled: false
		},
		xAxis: {
			categories: ["65", "74", "120", "20", "10", "60", "80", "20", "40", "40", "20", "30"],
			labels: {
				x: -10,
				useHTML: true,
				formatter: function () {
					return `<div style="width:${this.value}px; background: transparent linear-gradient(90deg, #F2F2F2 0%, #E6E6E6 100%) 0% 0% no-repeat padding-box;
			 color: transparent">-</div>`;
				}
			}
		},
		yAxis: {
			labels: {
				useHTML: true,
				formatter: function () {
					return `<div style="width:24px; height: 15px; background: transparent linear-gradient(90deg, #F2F2F2 0%, #E6E6E6 100%) 0% 0% no-repeat padding-box;
			color: transparent">-</div>`;
				}
			},
			tickInterval: 47,
			min: 0,
			max: 1600,
			title: {
				text: null
			}
		},
		legend: {
			enabled: false
		},
		title: {
			text: null
		}
	};

	return <HighchartsReact highcharts={Highcharts} options={options} containerProps={{ style: { width: "100%", height: "100%" } }} />;
};

export default LoadedGamePerformanceChart;
