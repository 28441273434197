import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Tabs } from "antd";
import OnlineComponent from "./sections/online";
import RetailComponent from "./sections/retail";
import Breadcrumbs from "components/common/breadcrumbs";
import { binaryToFlags } from "utils/common";
import { updateLocationHash, getHashValue, clearLocationHash } from "utils/common";
import { PARTNER_TYPE } from "constants/partner.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import Paths from "constants/path.constants";
import partnerType from "types/partner/partner.type";

/** Events Page Component */
const EventsComponent = ({ projectType }) => {
	return <Fragment>{projectType === PROJECT_TYPE.ONLINE ? <OnlineComponent /> : projectType === PROJECT_TYPE.RETAIL ? <RetailComponent /> : null}</Fragment>;
};

/** EventsComponent propTypes
 * PropTypes
 */
EventsComponent.propTypes = {
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE))
};

export default EventsComponent;
