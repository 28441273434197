import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button } from "antd";

import { SURVEY_STATE } from "constants/survey.constants";

import Table from "components/common/table";
import AddPartnerPopup from "components/dashboard/usersFeedback/CSAT/edit/sections/partners/partner-add.component";

import AvailablePartnersType from "types/survey/availablePartners.type";
import SurveyQuickInfoType from "types/survey/generalInfo.type";
import SortingType from "types/common/sorting.type";

import { getSurveyPartners, getSurveyAvailablePartners, setSurveyPartnersSorting } from "store/actions/dashboard/usersFeedback/CSAT/partners.action";
import { getSurveyQuickInfo } from "store/actions/dashboard/usersFeedback/CSAT/quickInfo.action";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

/** Survey Quick Info Partners Tab Component */
const CsatQuickInfoPartnersTab = ({ isLoading, total, sorting, partners, availablePartners, getSurveyPartners, getSurveyAvailablePartners, getSurveyQuickInfo, setSurveyPartnersSorting, quickInfo: { state, id } }) => {
	const { t } = useTranslation();

	/** State to show/hide partner add popup */
	const [showAddPopup, setShowAddPopup] = useState(false);

	const isAddPartnerAvailable = hasPermission({ resource: PERMISSION_RESOURCE.SURVEYS, action: PERMISSION_ACTION.MODIFY });

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.usersFeedback.partner"),
			dataIndex: "name",
			sorter: false
		},
		{
			title: t("pages.dashboard.usersFeedback.total_participants"),
			dataIndex: "playersCount",
			sorter: false
		},
		{
			title: `${t("pages.dashboard.usersFeedback.response_rate")} %`,
			dataIndex: "responseRate",
			sorter: false
		},
		{
			title: `${t("pages.dashboard.usersFeedback.csat_score")} %`,
			dataIndex: "responseScore",
			sorter: false
		}
	];

	/** Load survey available partners */
	useEffect(() => {
		if (isAddPartnerAvailable && id) {
			getSurveyAvailablePartners(id);
		}
	}, [isAddPartnerAvailable, id]);

	/** Close partner add popup, after save */
	useEffect(() => {
		setShowAddPopup(false);
	}, [partners]);

	return (
		<div className="dashboard-section-content">
			{isAddPartnerAvailable && (
				<div className="dashboard-section-buttons vs--pt-16 vs--pb-16 vs--pr-24 vs--text-right">
					<Button
						type="primary"
						disabled={availablePartners.length === 0 || state === SURVEY_STATE.NONE || state === SURVEY_STATE.FINISHED}
						htmlType="button"
						onClick={() => {
							setShowAddPopup(true);
						}}
					>
						<span>{t("pages.dashboard.usersFeedback.add_partner")}</span>
					</Button>
				</div>
			)}
			<Table loading={isLoading} columns={columns} data={partners} loadFn={(fromFirstpage) => getSurveyPartners(id, fromFirstpage)} total={total} sorting={sorting} setSortingFn={setSurveyPartnersSorting} />
			{showAddPopup && <AddPartnerPopup surveyId={id} onClose={() => setShowAddPopup(false)} onAddClick={() => getSurveyQuickInfo(id)} />}
		</div>
	);
};

/** CsatQuickInfoPartnersTab propTypes
 * PropTypes
 */
CsatQuickInfoPartnersTab.propTypes = {
	/** Redux state property, is true when loading survey partners */
	isLoading: PropTypes.bool,
	/** Redux state property, survey partners total count */
	total: PropTypes.number,
	/** Redux state property, survey partners sorting details */
	sorting: SortingType,
	/** Redux state property, represents the partners info of survey */
	partners: PropTypes.array,
	/** Redux state property, represents the available partners info of survey  */
	availablePartners: AvailablePartnersType,
	/** Redux action to get survey partners */
	getSurveyPartners: PropTypes.func,
	/** Redux action to get survey available partners */
	getSurveyAvailablePartners: PropTypes.func,
	/** Redux action to get survey quick info */
	getSurveyQuickInfo: PropTypes.func,
	/** Redux state, represents the quick info of current survey  */
	quickInfo: SurveyQuickInfoType,
	/** Redux action to set survey partners sorting details */
	setSurveyPartnersSorting: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getSurveyPartners: (surveyId, fromFirstpage) => {
		dispatch(getSurveyPartners(surveyId, fromFirstpage));
	},
	getSurveyAvailablePartners: (surveyId) => {
		dispatch(getSurveyAvailablePartners(surveyId));
	},
	getSurveyQuickInfo: (surveyId) => {
		dispatch(getSurveyQuickInfo(surveyId));
	},
	setSurveyPartnersSorting: (sorting) => {
		dispatch(setSurveyPartnersSorting(sorting));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.surveys.editingSurvey.isLoading,
		total: state.surveys.editingSurvey.partners.total,
		sorting: state.surveys.editingSurvey.partners.sorting,
		partners: state.surveys.editingSurvey.partners.partners,
		availablePartners: state.surveys.editingSurvey.availablePartners,
		quickInfo: state.surveys.quickInfo.quickInfo
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CsatQuickInfoPartnersTab);
