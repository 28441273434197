import { useState, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Tag, Tooltip } from "antd";

import { hasPermission } from "utils/permissions";

import { getPlayers, setPlayersSorting } from "store/actions/dashboard/online/players/players.action";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import Filters from "./filters.component";
import QuickInfo from "./quickInfo.component";
import ExportButton from "components/common/exportButton";
import PlayerStatusChangeComponent from "./player-status-change.component";

import playerType from "types/player/player.type";
import sortingType from "types/common/sorting.type";
import currencyType from "types/common/currency.type";

import Paths from "constants/path.constants";
import ApiUrls from "constants/api.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { USER_STATE } from "constants/user.constants";
import { FILTER_SHOW_IN_CURRENCY } from "constants/filter.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import { numberWithSpaces } from "utils/common";
import { convertCurrencies, getPartnerCurrency, getPartnerCurrencyDecimalCount } from "utils/currency";

import countries from "systemData/countries";

/** Players Page Component */
const PlayersComponent = ({ getPlayers, players, isLoading, total, setPlayersSorting, sorting, filters, globalPartnerId, currency, currencies }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	/** The id of the player which quick info is showing */
	const [quickInfo, setQuickInfo] = useState(null);

	/** State to show/hide player status change popup */
	const [showStatusChangePopup, setShowStatusChangePopup] = useState(false);

	/** Fires on state tag click
	 * @function
	 * @param {object} e - click eveent object
	 * @param {object} record - the record
	 * @memberOf PlayersComponent
	 */
	const handleStateTagClick = (e, record) => {
		e.stopPropagation();
		setShowStatusChangePopup(record);
	};

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.players.id"),
			dataIndex: "id",
			render: (value) => (
				<span className="table-col-link" onClick={() => setQuickInfo(value)}>
					{value}
				</span>
			)
		},
		{
			title: t("pages.dashboard.players.external_id"),
			dataIndex: "externalId"
		},
		{
			title: t("pages.dashboard.players.userName"),
			dataIndex: "userName"
		},
		{
			title: t("pages.dashboard.players.status"),
			dataIndex: "state",
			className: "ant-table-tag-cell",
			render: (value, record) =>
				record.info && value === USER_STATE.BLOCKED ? (
					<Tooltip
						title={
							<Fragment>
								<p>
									{t("pages.dashboard.players.blocked_by_provider")}
									<br />
								</p>
								{record.info && record.info.from && (
									<span>
										<b>{t("common.from")} : </b>
										{moment.utc(record.info.from).local().format(DATE_TIME_FORMAT)}
										<br />
									</span>
								)}
								{record.info && record.info.to && (
									<span>
										<b>{t("common.to")} : </b>
										{moment.utc(record.info.to).local().format(DATE_TIME_FORMAT)}
										<br />
									</span>
								)}
								<span>
									<b>{t("pages.dashboard.players.reason")} : </b>
									{record.info.reason}
								</span>
							</Fragment>
						}
					>
						<Tag color={value === USER_STATE.ACTIVE ? "green" : value === USER_STATE.IN_PROGRESS ? "orange" : "cyan"}>
							<Fragment>
								{t("pages.dashboard.users.status_" + value)}
								{hasPermission({ resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.MODIFY }) && <i className="icon-edit" onClick={(e) => handleStateTagClick(e, record)} />}
							</Fragment>
						</Tag>
					</Tooltip>
				) : (
					<Tag color={value === USER_STATE.ACTIVE ? "green" : value === USER_STATE.IN_PROGRESS ? "orange" : "cyan"}>
						<Fragment>
							{t("pages.dashboard.users.status_" + value)}
							{hasPermission({ resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.MODIFY }) && <i className="icon-edit" onClick={(e) => handleStateTagClick(e, record)} />}
						</Fragment>
					</Tag>
				)
		},
		{
			title: t("pages.dashboard.players.project"),
			dataIndex: "projectName",
			sorter: false
		},
		{
			title: t("pages.dashboard.players.type"),
			dataIndex: "isTesting",
			render: (value) => (value ? t("pages.dashboard.players.test") : t("pages.dashboard.players.real"))
		},
		{
			title: t("pages.dashboard.players.currency"),
			dataIndex: "currencyCode",
			render: (value, record) => getPartnerCurrency(filters.showIn === FILTER_SHOW_IN_CURRENCY.ORIGINAL ? value : currency.code, currencies, globalPartnerId)
		},
		{
			title: t("pages.dashboard.players.current_balance"),
			dataIndex: "balance",
			render: (value, record) =>
				filters.showIn === FILTER_SHOW_IN_CURRENCY.ORIGINAL ? numberWithSpaces(Number(value).toFixed(getPartnerCurrencyDecimalCount(record.currencyCode, currencies, globalPartnerId))) : numberWithSpaces(convertCurrencies(value, record.currencyCode, currency.code, currencies, globalPartnerId))
		},
		{
			title: t("pages.dashboard.players.country"),
			dataIndex: "countryCode",
			render: (value) => (value ? countries[value.toLowerCase()] : ""),
			sorter: false
		},
		{
			title: t("pages.dashboard.players.created_at"),
			dataIndex: "created",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.players.last_active_at"),
			dataIndex: "lastActiveAt",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.players.last_blocked_at"),
			dataIndex: "lastBlockedAt",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "")
		},
		{
			title: t("pages.dashboard.players.last_blocked_by"),
			dataIndex: "lastBlockedBy"
		}
	];

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.players") }]} />
				<div className="dashboard-section-content">
					<div className="table-header">
						<div className="table-buttons-dropdowns">
							{hasPermission({ resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.EXPORT }) ? (
								<ExportButton columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t("pages.dashboard.menu.players")} url={ApiUrls.EXPORT_PLAYERS} filters={filters} sorting={sorting} />
							) : null}
						</div>
						<Filters />
					</div>
					<Table
						loading={isLoading}
						columns={columns}
						data={players}
						loadFn={getPlayers}
						sorting={sorting}
						setSortingFn={setPlayersSorting}
						total={total}
						updateProps={[globalPartnerId]}
						isDisabled={() => false}
						actions={{
							view: {
								handler: (record) => {
									navigate(Paths.DASHBOARD_ONLINE_PLAYERS_VIEW.replace(DYNAMIC_PATH_ID_REGEX, record.id));
								}
							}
						}}
					/>
				</div>

				<QuickInfo id={quickInfo} onClose={() => setQuickInfo(null)} />
			</div>

			{showStatusChangePopup ? <PlayerStatusChangeComponent onClose={() => setShowStatusChangePopup(null)} player={showStatusChangePopup} /> : null}
		</Fragment>
	);
};

/** PlayersComponent propTypes
 * PropTypes
 */
PlayersComponent.propTypes = {
	/** Redux action to get players */
	getPlayers: PropTypes.func,
	/** Redux state property, represents the array of loaded players */
	players: PropTypes.arrayOf(playerType),
	/** Redux state property, players total count */
	total: PropTypes.number,
	/** Redux action to set players sorting details */
	setPlayersSorting: PropTypes.func,
	/** Redux state property, players sorting details */
	sorting: sortingType,
	/** Redux state property, players filters */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, is true when loading players */
	isLoading: PropTypes.bool,
	/** Redux state property, the current user currency */
	currency: currencyType,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType)
};

const mapDispatchToProps = (dispatch) => ({
	getPlayers: (fromFirstPage) => {
		dispatch(getPlayers(fromFirstPage));
	},
	setPlayersSorting: (sorting) => {
		dispatch(setPlayersSorting(sorting));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.players.isLoading,
		players: state.players.players,
		total: state.players.total,
		sorting: state.players.sorting,
		filters: state.players.filters,
		globalPartnerId: state.partner.globalPartnerId,
		currencies: state.profile.userInfo.currencies,
		currency: state.profile.userInfo.currency
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayersComponent);
