import { mergeClassNames } from "utils/common";
import BannerPreviewSection from "./BannerPreviewSection";

const PreviewSteeplechasingBanners = ({ gameType, gameId, partnerId, banners, bannersGroup, bannerList }) => {

	const buildSrc = (bannerName, bannerImg, index) => {
		const partner = partnerId ? partnerId : "system";
		const size = ["mp4", "webm"].includes(bannerImg.format) ? "large" : "small";
		const path = `/games/${gameId}/${bannerName}_${index}_${bannerImg.id}_${size}.${bannerImg.format}`.toLowerCase();
		return `${import.meta.env.SYSTEM_CDN_URL}/${partner}${path}`;
	}

	const bannerImageSources = bannerList.map((bannerImg, i) => {
		return { src: buildSrc(bannersGroup, bannerImg, i), file: bannerImg }
	})

	return (
		<div
			className="vs--banner-adjustment-preview"
			data-game-type={gameType}
		>
			{
				bannerImageSources.map((_, i) => (
					<div
						key={i}
						className={mergeClassNames(
							"vs--banner-adjustment-preview-scene",
							`vs--banner-adjustment-preview-group-${bannersGroup}`,
							`vs--banner-adjustment-preview-group-${bannersGroup}-${(i + 1)}`
						)}
					/>
				))
			}
			{
				bannerImageSources.map((imgSrc, i) => (
					<div
						key={i}
						className={
							mergeClassNames(
								"vs--banner-adjustment-preview-img",
								`vs--banner-adjustment-preview-${bannersGroup}`,
								`vs--banner-adjustment-preview-${bannersGroup}-${(i + 1)}`
							)
						}
					>
						<BannerPreviewSection srcObj={imgSrc} objectFit="fill" />
					</div>
				))
			}
		</div>
	)
}
export default PreviewSteeplechasingBanners