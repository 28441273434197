import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import moment from "moment";

import { DatePicker, Button, Tag } from "antd";

import OutsideAlerter from "components/common/outsideAlerter";

/** Public Holidays Component */
const PublicHolidays = ({ id, onChange, value, disabled, readOnly }) => {
	const { t } = useTranslation();
	const [tags, setTags] = useState(value);
	const [picker, setPicker] = useState(false);
	const [selectedDate, setSelectedDate] = useState(null);

	/** update picker value, when form item value is changed  */
	useEffect(() => {
		setTags(value);
	}, [value]);

	/** Fires on OK button click on picker
	 * @function
	 * @memberOf PublicHolidays
	 */
	const setTag = () => {
		selectedDate && tags.filter((t) => t.month === selectedDate.month && t.day === selectedDate.day).length === 0 && updateTags([...tags, selectedDate]);
		setSelectedDate(null);
		setPicker(false);
	};

	/** Fires on remove button click on tag
	 * @function
	 * @param {string} tag
	 * @memberOf PublicHolidays
	 */
	const removeTag = (tag) => {
		updateTags(tags.filter((t) => t !== tag));
	};

	/** Calls form item onchange event
	 * @function
	 * @param {array} tags - form item value
	 * @memberOf PublicHolidays
	 */
	const updateTags = (tags) => {
		setTags(tags);
		onChange && onChange(tags);
	};

	return (
		<div className={"tags-list" + (disabled ? " tags-list-disabled" : "")}>
			<div className="tags-list-icon" onClick={() => !disabled && !readOnly && setPicker(true)}>
				<i className="icon-calendar"></i>
			</div>
			<OutsideAlerter callback={() => setPicker(false)}>
				<div className="tags-list-picker" id={`tags-list-picker-${id}`}>
					{picker && (
						<DatePicker
							open={true}
							getPopupContainer={() => document.getElementById(`tags-list-picker-${id}`)}
							format="MMMM D"
							onChange={(d, v) => {
								setSelectedDate({
									month: Number(moment(v, "MMMM D").format("M")),
									day: Number(moment(v, "MMMM D").format("D"))
								});
							}}
							renderExtraFooter={() => (
								<Button disabled={selectedDate === null} type="primary" className="button" onClick={setTag} size="small">
									<span>{t("common.ok")}</span>
								</Button>
							)}
						/>
					)}
				</div>
			</OutsideAlerter>

			<div className="tags-list-content">
				{tags.length > 0 ? (
					tags.map((t) => (
						<Tag key={t.month + " " + t.day} closable={!disabled && !readOnly} onClose={() => removeTag(t)}>
							{moment(t.month, "M").format("MMMM") + " " + moment(t.day, "D").format("D")}
						</Tag>
					))
				) : (
					<div className="tags-list-content-placeholder">
						<p>{t("pages.dashboard.partners.retail_settings.retail_configs.holidays")}</p>
					</div>
				)}
			</div>
		</div>
	);
};

/** PublicHolidays propTypes
 * PropTypes
 */
PublicHolidays.propTypes = {
	/** Handler of picker value change */
	onChange: PropTypes.func,
	/** Current value of picker */
	value: PropTypes.array,
	/** DOM id attribute of the element */
	id: PropTypes.string,
	/** Should picker be disabled */
	disabled: PropTypes.bool,
	/** Should picker be readonly */
	readOnly: PropTypes.bool
};

export default PublicHolidays;
