import { buildPathToStaticFolderOfCDN } from "utils/common";

const NoChartData = () => {
	return (
		<div className="vs--ui-no-chart-data">
			<img src={buildPathToStaticFolderOfCDN("admin-images/dashboard/no-chart-data-icon.svg")} alt="No chart data" />
		</div>
	);
};

export default NoChartData;
