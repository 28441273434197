import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { setPlayersActionBefore, setPlayersActionFinished } from "./players.action";

import { SET_PLAYER_GENERAL_INFO } from "store/actionTypes";

export const setPlayerGeneralInfo = (info) => ({
	type: SET_PLAYER_GENERAL_INFO,
	payload: { info }
});

export const getPlayerGeneralInfo = (id) => {
	return (dispatch) => {
		dispatch(setPlayersActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PLAYER_QUICK_VIEW}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: info } }) => {
				dispatch(setPlayerGeneralInfo(info));
				dispatch(setPlayersActionFinished());
			})
			.catch((ex) => {
				dispatch(setPlayersActionFinished());
			});
	};
};
