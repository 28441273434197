import PropTypes from "prop-types";

export default PropTypes.shape({
	playerId: PropTypes.string,
	projectName: PropTypes.string,
	betsCount: PropTypes.number,
	currencyCode: PropTypes.string,
	turnover: PropTypes.number,
	ggrPercent: PropTypes.number
});
