import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Button, Input, Spin, Switch } from "antd";
const { Item: FormItem } = Form;

import SearchableSelect from "components/common/searchableSelect";

import { getCashierGeneralInfo, saveCashierGeneralInfo } from "store/actions/dashboard/retail/cashierManagement/cashiers/generalInfo.action";
import { getAvailableBetshops } from "store/actions/dashboard/retail/cashierManagement/cashiers/cashiers.action";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import cashierGeneralInfoType from "types/cashier/generalInfo.type";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { INTEGRATION_TYPE } from "constants/betshop.constants";
import { CASHIER_TYPE } from "constants/cashier.constants";

/** Cashier Edit Page General Info Tab Component */
const GeneralInfoComponent = ({ getCashierGeneralInfo, saveCashierGeneralInfo, getAvailableBetshops, isSaving, isLoading, generalInfo, availableBetshops, isAvailableBetshopsLoading, onTabChange }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.CASHIER, action: PERMISSION_ACTION.MODIFY });

	/** Load cashier general info, available betshops */
	useEffect(() => {
		if (routeParams.id) getCashierGeneralInfo(routeParams.id);
		getAvailableBetshops();
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			firstName: generalInfo.firstName,
			lastName: generalInfo.lastName,
			betShopId: generalInfo.betShop,
			applyExpiration: generalInfo.applyExpiration
		});
	}, [generalInfo]);

	/** Fires when form submitted
	 * @function
	 * @memberOf GeneralInfoComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				saveCashierGeneralInfo({
					...data,
					betShopId: generalInfo.type === CASHIER_TYPE.EXTERNAL ? undefined : data.betShopId,
					id: routeParams.id
				});
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					firstName: generalInfo.firstName,
					lastName: generalInfo.lastName,
					betShopId: generalInfo.betShop,
					applyExpiration: generalInfo.applyExpiration
				}}
				onValuesChange={(changed, formValues) =>
					setIsFormTouched(
						isFormChanged(
							{ ...formValues },
							{
								firstName: generalInfo.firstName,
								lastName: generalInfo.lastName,
								betShopId: generalInfo.betShop,
								applyExpiration: generalInfo.applyExpiration
							}
						)
					)
				}
			>
				<div className="dashboard-section-content">
					<Row gutter={[16, 0]}>
						<Col xs={24} sm={12} md={8} xl={6}>
							<FormItem label={t("pages.dashboard.cashiers.id")}>
								<Input value={generalInfo.id} disabled={true} />
							</FormItem>
						</Col>
						<Col xs={24} sm={12} md={8} xl={6}>
							<FormItem
								label={`${t("pages.dashboard.cashiers.first_name")} *`}
								name="firstName"
								rules={[
									{ required: true, whitespace: true, message: t("validation.field_required") },
									{ max: 50, message: t("validation.field_invalid") }
								]}
							>
								<Input maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.cashiers.first_name")}`} disabled={isDisabled || generalInfo.type === CASHIER_TYPE.EXTERNAL} />
							</FormItem>
						</Col>
						<Col xs={24} sm={12} md={8} xl={6}>
							<FormItem
								label={`${t("pages.dashboard.cashiers.last_name")} *`}
								name="lastName"
								rules={[
									{ required: true, whitespace: true, message: t("validation.field_required") },
									{ max: 50, message: t("validation.field_invalid") }
								]}
							>
								<Input maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.cashiers.last_name")}`} disabled={isDisabled || generalInfo.type === CASHIER_TYPE.EXTERNAL} />
							</FormItem>
						</Col>
						<Col xs={24} sm={12} md={8} xl={6}>
							<FormItem label={t("pages.dashboard.cashiers.email")}>
								<Input value={generalInfo.email} disabled={true} />
							</FormItem>
						</Col>
						<Col xs={24} sm={12} md={8} xl={6}>
							<FormItem label={t("pages.dashboard.cashiers.username")}>
								<Input value={generalInfo.userName} disabled={true} />
							</FormItem>
						</Col>
						<Col xs={24} sm={12} md={8} xl={6}>
							<FormItem label={t("pages.dashboard.cashiers.betshop")} name="betShopId" rules={[{ required: true, message: t("validation.field_required") }]}>
								{generalInfo.type === CASHIER_TYPE.INTERNAL ? (
									<SearchableSelect
										placeholder={t("pages.dashboard.cashiers.select_betshop_placeholder")}
										loading={isAvailableBetshopsLoading}
										items={Object.keys(availableBetshops)}
										valueProp={(item) => item}
										textProp={(item) => availableBetshops[item]}
										renderText={(item) => availableBetshops[item]}
										disabled={isDisabled}
									/>
								) : (
									<Input disabled={isDisabled || generalInfo.type === CASHIER_TYPE.EXTERNAL} />
								)}
							</FormItem>
						</Col>
						<Col xs={24} sm={12} md={8} xl={6}>
							<div className="inline-form-item inline-form-switcher">
								<label>{t("pages.dashboard.users.apply_password_expiration")}</label>
								<FormItem className="inline-form-item-control" name="applyExpiration" valuePropName="checked">
									<Switch disabled={isDisabled || generalInfo.type === CASHIER_TYPE.EXTERNAL} />
								</FormItem>
							</div>
						</Col>
					</Row>

					{!isDisabled && generalInfo.type === CASHIER_TYPE.INTERNAL && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={() => handleForm()} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** GeneralInfoComponent propTypes
 * PropTypes
 */
GeneralInfoComponent.propTypes = {
	/** Redux action to get cashier General info */
	getCashierGeneralInfo: PropTypes.func,
	/** Redux action to save cashier General info */
	saveCashierGeneralInfo: PropTypes.func,
	/** Redux action to get available betshops */
	getAvailableBetshops: PropTypes.func,
	/** Redux state property, is true when general info is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when general info is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the general info of current editing cashier  */
	generalInfo: cashierGeneralInfoType,
	/** Redux state, represents available betshops list  */
	availableBetshops: PropTypes.object,
	/** Redux state property, is true when available betshops are loading */
	isAvailableBetshopsLoading: PropTypes.bool,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getCashierGeneralInfo: (id) => {
		dispatch(getCashierGeneralInfo(id));
	},

	saveCashierGeneralInfo: (data) => {
		dispatch(saveCashierGeneralInfo(data));
	},

	getAvailableBetshops: () => {
		dispatch(getAvailableBetshops({ integrationType: INTEGRATION_TYPE.STANDALONE }));
	}
});

const mapStateToProps = (state) => {
	return {
		generalInfo: state.cashiers.editingCashier.generalInfo,
		isSaving: state.cashiers.isSaving,
		isLoading: state.cashiers.isLoading,
		availableBetshops: state.cashiers.availableBetshops,
		isAvailableBetshopsLoading: state.cashiers.isAvailableBetshopsLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent);
