import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";
import { Row, Col, Select, Form, Input, Spin, Button } from "antd";
const { Item: FormItem } = Form;

import Breadcrumbs from "components/common/breadcrumbs";
import Editor from "components/common/editor";

import ruleType from "types/translation/rule.type";
import { getRuleDetails, saveRule } from "store/actions/dashboard/cms/translation/rules.action";
import { getSystemAvailableLanguages } from "store/actions/dashboard/settings/systemLanguages/systemLanguages.action";
import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { GAME_CATEGORY, GAME_TYPE_MAPPER } from "constants/game.constants";
import { hasPermission } from "utils/permissions";
import { REDUX_RULES_STORE_FIELD } from "constants/rules.constants";

/** Rule Edit Component */
const EditRule = ({ isLoading, isSaving, getRuleDetails, saveRule, rule, getSystemAvailableLanguages, systemLanguages, isSystemAvailableLoading, systemDefault, gameCategory }) => {
	const routeParams = useParams();
	const { hash } = useLocation();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();

	const { setFieldsValue, validateFields } = formInstance;

	const [editorValue, setEditorValue] = useState("");
	const [titleValue, setTitleValue] = useState("");

	/** Load system languages */
	useEffect(() => {
		if (Object.keys(systemLanguages).length === 0) {
			getSystemAvailableLanguages();
		}
	}, []);

	/** Load Rule Details */
	useEffect(() => {
		if (routeParams.id) {
			getRuleDetails({
				id: routeParams.id,
				fromHistory: false,
				systemDefault: systemDefault,
				gameCategory: gameCategory
			});
		}
	}, [routeParams.id]);

	/** Fires when form submitted
	 * @function
	 * @memberOf EditRule
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				if (rule.title !== data.title || rule.content !== editorValue) {
					saveRule({ systemDefault, gameType: rule.gameType, languageCode: rule.languageCode, title: data.title, content: editorValue, Id: rule.id, gameCategory });
				}
			})
			.catch(Function.prototype);
	};

	/** Fires on TynyMCE editor change
	 * @function
	 * @memberOf EditRule
	 */
	const handleEditorChange = (e) => {
		setEditorValue(e);
	};

	useEffect(() => {
		setFieldsValue({ title: rule.title || "" });
		setTitleValue(rule.title || "");

		setEditorValue(rule.content || "");
	}, [rule]);

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs
					items={[
						{ title: t("pages.dashboard.menu.rules"), path: Paths.DASHBOARD_CMS_RULES + hash },
						{ title: `${t("common.edit")} ${routeParams.id}` }
					]}
				/>
				<Spin spinning={isLoading} wrapperClassName="form-spin">
					<div className="dashboard-section-content">
						<Form colon={false} form={formInstance} requiredMark={false} layout="vertical">
							<div>
								<Row gutter={[16, 0]}>
									<Col xs={8} md={6}>
										<FormItem label={t("pages.dashboard.translations.game_type")}>
											<Select disabled={true} value={rule?.gameType ?? ""} suffixIcon={<i className="icon-down" />}>
												{
													<Select.Option key={rule?.gameType ?? ""} value={rule?.gameType ?? ""}>
														{t(`common.${GAME_TYPE_MAPPER[gameCategory][rule?.gameType ?? ""]}`)}
													</Select.Option>
												}
											</Select>
										</FormItem>
									</Col>
									<Col xs={8} md={6}>
										<FormItem label={t("pages.dashboard.translations.language")}>
											<Select disabled={true} value={rule?.languageCode ?? ""} loading={isSystemAvailableLoading} suffixIcon={<i className="icon-down" />}>
												<Select.Option key={rule?.languageCode ?? ""} value={rule?.languageCode ?? ""}>
													{systemLanguages?.[(rule?.languageCode ?? "").toLowerCase()] ?? ""}
												</Select.Option>
											</Select>
										</FormItem>
									</Col>
									<Col xs={8} md={6}>
										<FormItem label={t("pages.dashboard.translations.title")} name="title">
											<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.translations.title")}`} onChange={(e) => setTitleValue(e.target.value)} disabled={!hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.MODIFY })} />
										</FormItem>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<FormItem label={t("pages.dashboard.translations.body")}>
											<Editor
												disabled={!hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.MODIFY })}
												value={editorValue}
												onChange={handleEditorChange}
											/>
										</FormItem>
									</Col>
								</Row>
							</div>
							{hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.MODIFY }) ? (
								<FormItem className="button-container">
									<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={rule.title === titleValue && rule.content.replace(/&nbsp;/g, " ") === editorValue.replace(/&nbsp;/g, " ")}>
										<span>{t("common.save")}</span>
									</Button>
								</FormItem>
							) : null}
						</Form>
					</div>
				</Spin>
			</div>
		</Fragment>
	);
};

/** EditRule propTypes
 * PropTypes
 */
EditRule.propTypes = {
	/** Redux action to get Rule Details */
	getRuleDetails: PropTypes.func,
	/** Redux action to save rules */
	saveRule: PropTypes.func,
	/** Redux state property, is true when loading rule details */
	isLoading: PropTypes.bool,
	/** Redux state property, is true when saving rules */
	isSaving: PropTypes.bool,
	/** Redux state property, translations */
	rules: ruleType,
	/** Redux action to get system available languages */
	getSystemAvailableLanguages: PropTypes.func,
	/** Redux state property, represents the object of loaded system languages */
	systemLanguages: PropTypes.object,
	/** Redux state property, is true when loading system languages */
	isSystemAvailableLoading: PropTypes.bool,
	/** Redux state property, is true when system default translations is enabled */
	systemDefault: PropTypes.bool,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	getRuleDetails: ({ id, fromHistory, systemDefault, gameCategory }) => {
		dispatch(getRuleDetails({ id, fromHistory, systemDefault, gameCategory }));
	},
	saveRule: (params) => {
		dispatch(saveRule(params));
	},
	getSystemAvailableLanguages: () => {
		dispatch(getSystemAvailableLanguages());
	}
});

const mapStateToProps = (state, props) => {
	const { gameCategory } = props;
	return {
		isLoading: state.translations.isLoading,
		isSaving: state.translations.isSaving,
		rule: state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].editingRule,
		systemLanguages: state.systemLanguages.systemAvailableLanguages,
		isSystemAvailableLoading: state.systemLanguages.isAvailableLoading,
		systemDefault: state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].main.systemDefault
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditRule);
