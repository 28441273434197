import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Row, Col, Tooltip } from "antd";

import BannerUpload from "components/dashboard/common/bannerUpload";

import ApiUrls from "constants/api.constants";

import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { deleteMobileLogo, setMobileLogoId } from "store/actions/dashboard/online/apikeys/customization.action";

import apiKeyUIType from "types/apiKey/ui.type";
import { FILE_SIZES_MEASUREMENT } from "constants/common.constants";

/** Customization Tool Mobile Logo Component */
const MobileLogo = ({ deleteMobileLogo, setMobileLogoId, ui, globalPartnerId }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	return (
		<div className="dashboard-customization-tool-section">
			<h2>{t("pages.dashboard.apikeys.customization.mobile_logo")}</h2>
			<div className="dashboard-customization-tool-section-main">
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<div className="dashboard-section-info">
							<h1>{t("pages.dashboard.apikeys.logo_for_mobile")}</h1>
							<Tooltip title={<span dangerouslySetInnerHTML={{ __html: t("pages.dashboard.apikeys.logo_for_mobile_tooltip") }}></span>}>
								<i className="icon-info" />
							</Tooltip>
						</div>
					</Col>
					<Col span={24}>
						<BannerUpload
							uploadUrl={`${import.meta.env.SYSTEM_API_URL}${ApiUrls.APIKEY_UPLOAD_LOGO}`}
							defaultFile={
								ui.logoId
									? {
											url: `${import.meta.env.SYSTEM_CDN_URL}/${globalPartnerId}/projects/${routeParams.id}/ui/${ui.logoId}_mobile_logo.png`,
											status: "done",
											percent: 100
										}
									: null
							}
							remove={
								hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_CUSTOMIZATION, action: PERMISSION_ACTION.MODIFY })
									? {
											handler: () => deleteMobileLogo(routeParams.id),
											message: t("pages.dashboard.apikeys.remove_logo_confirmation_message")
										}
									: null
							}
							data={{
								id: routeParams.id
							}}
							fileBuilder={(value) => ({
								url: `${import.meta.env.SYSTEM_CDN_URL}/${globalPartnerId}/projects/${routeParams.id}/ui/${value}_mobile_logo.png`
							})}
							extensions={["image/png", "image/jpeg", "image/jpg"]}
							size={FILE_SIZES_MEASUREMENT.MB}
							disabled={!hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_CUSTOMIZATION, action: PERMISSION_ACTION.MODIFY })}
							disablePreview={true}
							onSuccess={(id) => {
								setMobileLogoId(id);
							}}
						/>
					</Col>
				</Row>
			</div>
		</div>
	);
};

/** MobileLogo propTypes
 * PropTypes
 */
MobileLogo.propTypes = {
	/** Redux action to delete mobile logo */
	deleteMobileLogo: PropTypes.func,
	/** Redux action to set mobile logo */
	setMobileLogoId: PropTypes.func,
	/** Redux state property, apikey ui data */
	ui: apiKeyUIType,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	deleteMobileLogo: (apiKeyId) => {
		dispatch(deleteMobileLogo(apiKeyId));
	},
	setMobileLogoId: (logoId) => {
		dispatch(setMobileLogoId(logoId));
	}
});

const mapStateToProps = (state) => {
	return {
		ui: state.apikeys.editingApikey.customization.ui,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileLogo);
