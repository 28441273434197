import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Form from "antd/lib/form";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Switch from "antd/lib/switch";
import Select from "antd/lib/select";
const { Item: FormItem } = Form;
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import RuleDetailsPopup from "components/dashboard/cms/rules/sections/versionHistory/ruleDetailsPopup";
import Table from "components/common/table";

import ruleType from "types/translation/rule.type";
import sortingType from "types/common/sorting.type";
import { getRulesHistory, getRuleDetails, setHistorySorting, setRulesSystemDefault } from "store/actions/dashboard/cms/translation/rules.action";
import { GAME_CATEGORY, SCHEDULED_GAME_TYPE, SCHEDULED_GAME_TYPE_MAPPER } from "constants/game.constants";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { REDUX_RULES_STORE_FIELD } from "constants/rules.constants";

const VersionHistoryTab = ({ isSystemAvailableLoading, isLoading, mainTabGameType, systemLanguages, history, total, sorting, getRulesHistory, setHistorySorting, getRuleDetails, systemDefault, setRulesSystemDefault, globalPartnerId, gameCategory }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();

	const [selectedGameType, setSelectedGameType] = useState(mainTabGameType);
	const [selectedLangCode, setSelectedLangCode] = useState("en");
	const [showRuleDetailsPopup, setShowRuleDetailsPopup] = useState(null);

	const columns = [
		{
			title: t("pages.dashboard.translations.title"),
			dataIndex: "title",
			sorter: false
		},
		{
			title: t("pages.dashboard.translations.version"),
			dataIndex: "version"
		},
		{
			title: t("pages.dashboard.translations.published_by"),
			dataIndex: "userName",
			sorter: false
		},
		{
			title: t("pages.dashboard.translations.published_at"),
			dataIndex: "date",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"),
			sorter: false
		}
	];

	useEffect(() => {
		getRulesHistory({
			fromFirstPage: true,
			gameType: selectedGameType,
			languageCode: selectedLangCode,
			isSystemDefault: systemDefault,
			gameCategory: gameCategory
		});
	}, [systemDefault, selectedGameType, selectedLangCode, globalPartnerId, gameCategory]);

	/** Load selected Rule Details */
	useEffect(() => {
		if (showRuleDetailsPopup !== null) {
			getRuleDetails({ id: showRuleDetailsPopup, fromHistory: true, systemDefault: systemDefault, gameCategory: gameCategory });
		}
	}, [showRuleDetailsPopup, gameCategory]);

	return (
		<Fragment>
			<div className="dashboard-section-content table-section">
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<div className="inline-form-item" style={{ justifyContent: "flex-end" }}>
							<label style={{ opacity: systemDefault ? "1" : "0.5" }}>{t("pages.dashboard.translations.system_default_rules")}</label>
							<FormItem className="inline-form-item-control" style={{ flex: 0 }}>
								<Switch
									onChange={(val) => {
										setRulesSystemDefault(val, gameCategory);
									}}
									checked={systemDefault}
									disabled={!hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW })}
								/>
							</FormItem>
						</div>
					</Col>
				</Row>
				<Form colon={false} requiredMark={false} form={formInstance} layout="vertical">
					<Row gutter={[16, 0]}>
						<Col xs={12} md={4}>
							<FormItem label={t("pages.dashboard.translations.game_type")}>
								<Select value={selectedGameType} onChange={(selectedGameType) => setSelectedGameType(selectedGameType)} suffixIcon={<i className="icon-down" />} getPopupContainer={(trigger) => trigger.parentNode}>
									{Object.values(SCHEDULED_GAME_TYPE).map((gtObj) => (
										<Select.Option key={gtObj.value} value={gtObj.value}>
											{t(`common.${SCHEDULED_GAME_TYPE_MAPPER[gtObj.value]}`)}
										</Select.Option>
									))}
								</Select>
							</FormItem>
						</Col>
						<Col xs={12} md={4}>
							<FormItem label={t("pages.dashboard.translations.language")}>
								<Select loading={isSystemAvailableLoading} value={selectedLangCode} onChange={(e) => setSelectedLangCode(e)} suffixIcon={<i className="icon-down" />} getPopupContainer={(trigger) => trigger.parentNode}>
									{Object.keys(systemLanguages).map((lang) => (
										<Select.Option key={lang} value={lang}>
											{systemLanguages[lang]}
										</Select.Option>
									))}
								</Select>
							</FormItem>
						</Col>
					</Row>

					<Table
						loading={isLoading}
						columns={columns}
						data={history}
						loadFn={(fromFirstPage) =>
							!fromFirstPage &&
							getRulesHistory({
								fromFirstPage: fromFirstPage,
								gameType: selectedGameType,
								languageCode: selectedLangCode,
								isSystemDefault: systemDefault,
								gameCategory: gameCategory
							})
						}
						updateProps={[selectedGameType, systemLanguages, selectedLangCode]}
						total={total}
						sorting={sorting}
						setSortingFn={(sortingObj) => setHistorySorting(sortingObj, gameCategory)}
						actions={{
							view: {
								handler: (rule) => {
									setShowRuleDetailsPopup(rule.id);
								}
							}
						}}
					/>
				</Form>
			</div>

			{showRuleDetailsPopup !== null && <RuleDetailsPopup gameCategory={gameCategory} onClose={() => setShowRuleDetailsPopup(null)} />}
		</Fragment>
	);
};

VersionHistoryTab.propTypes = {
	/** Redux state property, selected game in rules main tab */
	mainTabGameType: PropTypes.number,
	/** Redux action to get rules */
	getRulesHistory: PropTypes.func,
	/** Redux action to get Rule Details */
	getRuleDetails: PropTypes.func,
	/** Redux action to set rules history sorting details */
	setHistorySorting: PropTypes.func,
	/** Redux state property, is true when loading rules version history */
	isLoading: PropTypes.bool,
	/** Redux state property, is true when publishing rules */
	isPublishing: PropTypes.bool,
	/** Redux state property, is true when saving rules */
	isSaving: PropTypes.bool,
	/** Redux state property, rules version history */
	history: PropTypes.arrayOf(ruleType),
	/** Redux state property, rules history sorting details */
	sorting: sortingType,
	/** Redux state property, represents the object of loaded system languages */
	systemLanguages: PropTypes.object,
	/** Redux state property, is true when loading system languages */
	isSystemAvailableLoading: PropTypes.bool,
	/** Redux state property, is true when system default rules is enabled */
	systemDefault: PropTypes.bool,
	/** Redux action to set system default translation */
	setRulesSystemDefault: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	getRulesHistory: ({ fromFirstPage, gameType, languageCode, isSystemDefault, gameCategory }) => {
		dispatch(getRulesHistory({ fromFirstPage, gameType, languageCode, isSystemDefault, gameCategory }));
	},
	getRuleDetails: ({ id, fromHistory, systemDefault, gameCategory }) => {
		dispatch(getRuleDetails({ id, fromHistory, systemDefault, gameCategory }));
	},
	setHistorySorting: (sorting, gameCategory) => {
		dispatch(setHistorySorting(sorting, gameCategory));
	},
	setRulesSystemDefault: (systemDefault, gameCategory) => {
		dispatch(setRulesSystemDefault(systemDefault, gameCategory));
	}
});

const mapStateToProps = (state, props) => {
	const { gameCategory } = props;
	return {
		isLoading: state.translations.isLoading,
		isSaving: state.translations.isSaving,
		mainTabGameType: state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].main.selectedGameType,
		history: state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].history.data,
		total: state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].history.total,
		sorting: state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].history.sorting,
		systemLanguages: state.systemLanguages.systemAvailableLanguages,
		isSystemAvailableLoading: state.systemLanguages.isAvailableLoading,
		isPublishing: state.translations.isPublishing,
		systemDefault: state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].main.systemDefault,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(VersionHistoryTab);
