import { Fragment } from "react";

import RulesComponent from "components/dashboard/cms/rules/rules.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const RulesRoute = () => {
	return (
		<Fragment>
			<RulesComponent />
		</Fragment>
	);
};

export default withPermission(
	RulesRoute,
	[
		{ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW },
		{ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.VIEW }
	],
	Paths.DASHBOARD
);
