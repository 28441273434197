import moment from "moment";

import { binaryToFlags, isLeagueGame, flagsToBinary } from "utils/common";
import { ALL_OPTION_ID } from "constants/common.constants";
import { FIELD_NAMES, JACKPOT_LEVEL } from 'constants/bonus.constants';
import { BETSLIP_MODES } from "constants/bet.constants";

export const disablePreviousDays = (currentDay) => {
	return currentDay.isBefore(moment());
};

export const getSelectedValues = (selectedValues) => {
	const firstElem = selectedValues[0];
	const lastElem = selectedValues[selectedValues.length - 1];

	if (lastElem === ALL_OPTION_ID) {
		return [ALL_OPTION_ID];
	}

	if (firstElem === ALL_OPTION_ID && selectedValues.length > 1) {
		return selectedValues.filter((item) => item !== ALL_OPTION_ID);
	}

	return selectedValues;
};

export const maxFieldsValidation = ({ value, rangeArr, translationFunc, fieldNameStr, dependencyFieldValue, dependencyFieldName }) => {
	if (value === "" || value === null) {
		return Promise.resolve();
	}

	const numberValue = Number(value);

	if (Number.isNaN(numberValue)) {
		return Promise.reject(translationFunc("validation.must_be_number").replace("%X%", translationFunc(`pages.dashboard.bonuses.${fieldNameStr}`)));
	}

	if (numberValue < rangeArr[0] || numberValue > rangeArr[1]) {
		return Promise.reject(translationFunc("validation.must_be_between").replace("%X%", rangeArr[0]).replace("%Y%", rangeArr[1]));
	}

	if (dependencyFieldValue !== "" && dependencyFieldValue !== null && numberValue < Number(dependencyFieldValue)) {
		return Promise.reject(translationFunc("validation.must_be_more").replace("%X%", translationFunc(`pages.dashboard.bonuses.${dependencyFieldName}`)));
	}

	return Promise.resolve();
};

export const minFieldsValidation = ({ value, rangeArr, translationFunc, fieldNameStr, dependencyFieldValue, dependencyFieldName }) => {
	if (value === "" || value === null) {
		return Promise.resolve();
	}

	const numberValue = Number(value);

	if (Number.isNaN(numberValue)) {
		return Promise.reject(translationFunc("validation.must_be_number").replace("%X%", translationFunc(`pages.dashboard.bonuses.${fieldNameStr}`)));
	}

	if (numberValue < rangeArr[0] || numberValue > rangeArr[1]) {
		return Promise.reject(translationFunc("validation.must_be_between").replace("%X%", rangeArr[0]).replace("%Y%", rangeArr[1]));
	}

	if (dependencyFieldValue !== "" && dependencyFieldValue !== null && numberValue > Number(dependencyFieldValue)) {
		return Promise.reject(translationFunc("validation.must_be_less").replace("%X%", translationFunc(`pages.dashboard.bonuses.${dependencyFieldName}`)));
	}

	return Promise.resolve();
};

export const fieldsValidation = ({ value, rangeArr, translationFunc, fieldNameStr }) => {
	if (value === "" || value === null) {
		return Promise.resolve();
	}

	const numberValue = Number(value);

	if (Number.isNaN(numberValue)) {
		return Promise.reject(translationFunc("validation.must_be_number").replace("%X%", translationFunc(`pages.dashboard.bonuses.${fieldNameStr}`)));
	}

	if (numberValue < rangeArr[0] || numberValue > rangeArr[1]) {
		return Promise.reject(translationFunc("validation.must_be_between").replace("%X%", rangeArr[0]).replace("%Y%", rangeArr[1]));
	}

	return Promise.resolve();
};

export const betProbabilityFieldValidation = ({ value, rangeArr, translationFunc }) => {
	if (value === "") {
		return Promise.resolve();
	}

	const numberValue = Number(value);

	if (Number.isNaN(numberValue)) {
		return Promise.reject(translationFunc("validation.must_be_number").replace("%X%", translationFunc("pages.dashboard.bonuses.betProbability")));
	}

	if (numberValue < rangeArr[0] || numberValue > rangeArr[1]) {
		return Promise.reject(translationFunc("validation.must_be_between").replace("%X%", rangeArr[0]).replace("%Y%", rangeArr[1]));
	}

	if (!numberValue.toString().match(/^(\d)*(\.)?([0-9]{1})?$/gm)) {
		return Promise.reject(translationFunc("validation.decimal_count_permitted").replace("%X%", "1"));
	}

	return Promise.resolve();
};

export const betTypeFieldValidation = ({ value, selectedGameTypes, translationFunc }) => {
	const selectedBetTypes = binaryToFlags([BETSLIP_MODES.SINGLE, BETSLIP_MODES.MULTI], value);

	if (selectedBetTypes.length === 1 && selectedBetTypes[0] === BETSLIP_MODES.MULTI) {
		if (selectedGameTypes.length === 1 && !(selectedGameTypes[0] === ALL_OPTION_ID || isLeagueGame(selectedGameTypes[0]))) {
			return Promise.reject(translationFunc("validation.invalid_multi_bonus_for_game_type"));
		}
	}

	return Promise.resolve();
};

export const createRequestBodyForBonuses = ({ fieldValuesObj, betshops, games }) => {
	const requestBody = {};

	for (const fieldName in fieldValuesObj) {
		switch (fieldName) {
			case FIELD_NAMES.CURRENCY:
				requestBody[fieldName] = fieldValuesObj[fieldName].toUpperCase();
				break;

			case FIELD_NAMES.BETSHOP_IDS:
				requestBody[fieldName] = fieldValuesObj[fieldName][0] === ALL_OPTION_ID ? betshops.map((betshop) => betshop.key) : fieldValuesObj[fieldName];
				break;

			case FIELD_NAMES.GAME_TYPE:
				requestBody[fieldName] = fieldValuesObj[fieldName][0] === ALL_OPTION_ID ? flagsToBinary(games) : flagsToBinary(fieldValuesObj[fieldName]);
				break;

			case FIELD_NAMES.END_DATE:
				requestBody[fieldName] = fieldValuesObj[fieldName].toDate();
				break;

			case FIELD_NAMES.DATE:
				requestBody.startDate = fieldValuesObj[fieldName][0].toDate();
				requestBody.endDate = fieldValuesObj[fieldName][1].toDate();
				break;

			default:
				requestBody[fieldName] = fieldValuesObj[fieldName] ? Number(fieldValuesObj[fieldName]) : null;
				break;
		}
	}

	return requestBody;
};

export const required = (translationFunc) => {
	return {
		required: true,
		message: translationFunc("validation.field_required")
	};
};

export const addMinutesToCurrentDate = ({ minute, second, millisecond }) => {
	return moment().add(minute, "m").second(second).millisecond(millisecond);
};

export const disableTimeUntilNow = (date) => {
	const currentDate = moment();

	// At the beginning when date is not selected date is equal to null
	if (date === null || date.date() === currentDate.date()) {
		return {
			disabledHours: () => Array.from(Array(currentDate.hours()).keys()),
			disabledMinutes: () => Array.from(Array(currentDate.minutes() + 1).keys())
		};
	}
};

export const dateFieldValidation = ({ selectedStartDate, selectedEndDate, initialStartDate, config, translationFunc }) => {
	if (selectedStartDate.isBefore(initialStartDate)) {
		return Promise.reject(translationFunc("validation.must_be_more_than_other").replace("%X%", translationFunc("pages.dashboard.bonuses.startDate")).replace("%Y%", initialStartDate.format(config)));
	}
	if (selectedEndDate <= selectedStartDate) {
		return Promise.reject(translationFunc("validation.must_be_more_and_not_equal_than_other").replace("%X%", translationFunc("pages.dashboard.bonuses.endDate")).replace("%Y%", translationFunc("pages.dashboard.bonuses.startDate")));
	} else {
		return Promise.resolve();
	}
};

export const disableTimeForDateField = ({ date, type, initialStartDate, selectedStartDate }) => {
	if (type === "start" && date.date() === initialStartDate.date()) {
		return {
			disabledHours: () => Array.from(Array(initialStartDate.hours()).keys()),
			disabledMinutes: () => Array.from(Array(initialStartDate.minutes()).keys())
		};
	}

	if (type === "end") {
		if (date === null || date.date() === selectedStartDate.date()) {
			return {
				disabledHours: () => Array.from(Array(selectedStartDate.hours()).keys()),
				disabledMinutes: () => Array.from(Array(selectedStartDate.minutes() + 1).keys())
			};
		}
	}
}

export const createJackpotLevelObject = (index) => {
	const levelType = [JACKPOT_LEVEL.BRONZE, JACKPOT_LEVEL.SILVER, JACKPOT_LEVEL.GOLD][index];
	const betSlipType = flagsToBinary([BETSLIP_MODES.SINGLE, BETSLIP_MODES.MULTI]);
	return (
		{
			poolName: "",
			betSlipType: betSlipType,
			seedAmount: null,
			minHitAmount: null,
			maxHitAmount: null,
			contribution: null,
			nextSeedContribution: null,
			levelType: levelType,
			minBetAmount: null
		}
	)
}

export const mapJackpotGeneralInfo = (info) => {
	const mappedInfo = { ...info }
	const levelsFromResponse = Array.isArray(mappedInfo.levels) ? mappedInfo.levels : [];

	const generatedLevels = Array.from({ length: 3 }, (_, i) => ({
		levelType: [JACKPOT_LEVEL.BRONZE, JACKPOT_LEVEL.SILVER, JACKPOT_LEVEL.GOLD][i]
	}));

	if (!mappedInfo.hitCount) {
		mappedInfo.hitCount = 3;
		mappedInfo.levels = Array.from({ length: generatedLevels.length }, (_, i) => createJackpotLevelObject(i));
	}
	mappedInfo.levels = generatedLevels.map((pseudoLevel, i) => {
		const found = levelsFromResponse.find(levelFromResponse => levelFromResponse.levelType === pseudoLevel.levelType);
		return (found ?? createJackpotLevelObject(i))
	})
	if (!mappedInfo.projectIds) {
		mappedInfo.projectIds = []
	}

	return mappedInfo
}
