import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Checkbox, Input, Button, Select, Divider, notification, Spin } from "antd";
const { Item: FormItem } = Form;

import { PARTNER_TYPE } from "constants/partner.constants";
import { EMAIL_REGEX, TEL_REGEX } from "constants/regex.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { savePartnerGeneralInfo } from "store/actions/dashboard/partners/generalInfo.action";

import { flagsToBinary, binaryToFlags } from "utils/common";
import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import countries from "systemData/countries";

import partnerGeneralInfoType from "types/partner/generalInfo.type";

/** Partner Edit Page General Info Tab Component */
const GeneralInfoComponent = ({ savePartnerGeneralInfo, isSaving, isLoading, generalInfo, onTabChange }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [initialPartnerType, setInitialPartnerType] = useState(generalInfo.type);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.PARTNER, action: PERMISSION_ACTION.MODIFY });

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		// for placeholder visibility we need to set undefined
		const countryCode = generalInfo.countryCode !== null ? generalInfo.countryCode : undefined;

		setFieldsValue({
			...generalInfo,
			countryCode,
			partnerId: routeParams.id,
			type: binaryToFlags(Object.values(PARTNER_TYPE), generalInfo.type)
		});
		setInitialPartnerType(generalInfo.type);
	}, [generalInfo]);

	/** Check is form changed
	 * @function
	 * @param {object} formValues - form current values
	 * @returns {boolean}
	 * @memberOf GeneralInfoComponent
	 */
	const formChanged = (formValues) => {
		if (formValues.type !== undefined) formValues.type = flagsToBinary(formValues.type);
		return isFormChanged(formValues, { ...generalInfo, partnerId: routeParams.id });
	};

	/** Fires when form submitted
	 * @function
	 * @memberOf GeneralInfoComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				savePartnerGeneralInfo({
					...data,
					type: flagsToBinary(data.type)
				});
				setIsFormTouched(false);

				/** Show message popup if type is changed */
				if (initialPartnerType !== flagsToBinary(data.type)) {
					const initial = binaryToFlags(Object.values(PARTNER_TYPE), initialPartnerType);

					if ((!initial.includes(PARTNER_TYPE.ONLINE) && data.type.includes(PARTNER_TYPE.ONLINE)) || (!initial.includes(PARTNER_TYPE.RETAIL) && data.type.includes(PARTNER_TYPE.RETAIL))) {
						notification.info({
							message: t("pages.dashboard.partners.general_info.type_added_message")
						});
					}
				}
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					type: binaryToFlags(Object.values(PARTNER_TYPE), generalInfo.type)
				}}
				onValuesChange={(changed, formValues) => setIsFormTouched(formChanged({ ...formValues }))}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[0, 0]}>
							<Col>
								<FormItem label={`${t("pages.dashboard.partners.add_partner_form.partner_type")} *`} name="type" rules={[{ required: true, message: t("validation.field_required") }]}>
									<Checkbox.Group
										options={[
											{
												label: (
													<div className="partners-checkbox">
														<i className="icon-online"></i>
														<span>{t("common.online")}</span>
													</div>
												),
												value: PARTNER_TYPE.ONLINE
											},
											{
												label: (
													<div className="partners-checkbox">
														<i className="icon-retail"></i>
														<span>{t("common.retail")}</span>
													</div>
												),
												value: PARTNER_TYPE.RETAIL
											}
										]}
										disabled={isDisabled}
									/>
								</FormItem>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.partners.general_info.id")} name="partnerId" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
									<Input disabled={true} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.partners.general_info.contact_name")} name="contactName" rules={[{ max: 50, message: t("validation.field_invalid") }]}>
									<Input maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.general_info.contact_name")}`} disabled={isDisabled} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem
									label={t("pages.dashboard.partners.general_info.email_address")}
									name="email"
									rules={[
										{ pattern: EMAIL_REGEX, message: t("validation.email_format") },
										{ max: 50, message: t("validation.field_invalid") }
									]}
								>
									<Input maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.general_info.email_address")}`} disabled={isDisabled} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.partners.general_info.fax")} name="fax">
									<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.general_info.fax")}`} disabled={isDisabled} />
								</FormItem>
							</Col>
						</Row>

						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<FormItem
									label={`${t("pages.dashboard.partners.general_info.partner_name")} *`}
									name="name"
									rules={[
										{ required: true, whitespace: true, message: t("validation.field_required") },
										{ max: 30, message: t("validation.field_invalid") }
									]}
								>
									<Input maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.general_info.partner_name")}`} disabled={isDisabled} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem
									label={t("pages.dashboard.partners.general_info.tel")}
									name="tel"
									rules={[
										{ pattern: TEL_REGEX, message: t("validation.tel_format") },
										{ max: 30, message: t("validation.field_invalid") }
									]}
								>
									<Input maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.general_info.tel")}`} disabled={isDisabled} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem
									label={t("pages.dashboard.partners.general_info.emergancy_tel")}
									name="emergencyTel"
									rules={[
										{ pattern: TEL_REGEX, message: t("validation.tel_format") },
										{ max: 50, message: t("validation.field_invalid") }
									]}
								>
									<Input maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.general_info.emergancy_tel")}`} disabled={isDisabled} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={`${t("pages.dashboard.partners.general_info.country")} *`} name="countryCode" rules={[{ required: true, message: t("validation.field_required") }]}>
									<Select placeholder={`${t("common.select")} ${t("pages.dashboard.partners.general_info.country")}`} showSearch optionFilterProp="children" disabled={isDisabled} suffixIcon={<i className="icon-down" />}>
										{Object.keys(countries)
											.sort()
											.map((c) => (
												<Select.Option key={c} value={c.toUpperCase()}>
													{countries[c]}
												</Select.Option>
											))}
									</Select>
								</FormItem>
							</Col>
						</Row>

						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<FormItem
									label={`${t("pages.dashboard.partners.general_info.city")} *`}
									name="city"
									rules={[
										{ required: true, whitespace: true, message: t("validation.field_required") },
										{ max: 50, message: t("validation.field_invalid") }
									]}
								>
									<Input maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.general_info.city")}`} disabled={isDisabled} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem
									label={`${t("pages.dashboard.partners.general_info.address")} *`}
									name="address"
									rules={[
										{ required: true, whitespace: true, message: t("validation.field_required") },
										{ max: 100, message: t("validation.field_invalid") }
									]}
								>
									<Input maxLength={100} placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.general_info.address")}`} disabled={isDisabled} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.partners.general_info.postal_code")} name="postalCode" rules={[{ max: 20, message: t("validation.field_invalid") }]}>
									<Input maxLength={20} placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.general_info.postal_code")}`} disabled={isDisabled} />
								</FormItem>
							</Col>
						</Row>
					</div>
					{!isDisabled && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** GeneralInfoComponent propTypes
 * PropTypes
 */
GeneralInfoComponent.propTypes = {
	/** Redux action to save partner General info */
	savePartnerGeneralInfo: PropTypes.func,
	/** Redux state property, is true when general info is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when general info is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the general info of current editing partner  */
	generalInfo: partnerGeneralInfoType,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	savePartnerGeneralInfo: (data) => {
		dispatch(savePartnerGeneralInfo(data));
	}
});

const mapStateToProps = (state) => {
	return {
		generalInfo: state.partner.generalInfo,
		isSaving: state.partners.isSaving,
		isLoading: state.partner.isLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent);
