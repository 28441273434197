import { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { getDashboardOnlinePlayersCount } from "store/actions/dashboard/dashboard/dashboard.action";
import WidgetSkeleton from "components/dashboard/statistics/widgets/skeleton";

/** Dashboard Page Online Players Widget Component */
const OnlinePlayersWidgetComponent = ({ globalPartnerId, onlinePlayersCount, getDashboardOnlinePlayersCount, isOnlinePlayersCountLoading }) => {
	const { t } = useTranslation();

	useEffect(() => {
		getDashboardOnlinePlayersCount();
		const interval = setInterval(() => {
			getDashboardOnlinePlayersCount();
		}, 120000);

		return () => {
			clearInterval(interval);
		};
	}, [globalPartnerId]);

	return (
		<div className="dashboard-widget">
			<div className="dashboard-widget-icon" data-type="players">
				<i className="icon-rocket" />
			</div>
			<div className="dashboard-widget-info">
				<span className="dashboard-widget-info-title">{t("pages.dashboard.dashboard.active_players")}</span>

				{isOnlinePlayersCountLoading ? <WidgetSkeleton /> : <span className="dashboard-widget-info-value">{onlinePlayersCount}</span>}
			</div>
		</div>
	);
};

/** OnlinePlayersWidgetComponent propTypes
 * PropTypes
 */
OnlinePlayersWidgetComponent.propTypes = {
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, online players count */
	onlinePlayersCount: PropTypes.number,
	/** Redux action to get online players count */
	getDashboardOnlinePlayersCount: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		onlinePlayersCount: state.dashboard.online.onlinePlayersCount,
		globalPartnerId: state.partner.globalPartnerId,
		isOnlinePlayersCountLoading: state.dashboard.online.isOnlinePlayersCountLoading
	};
};

const mapDispatchToProps = (dispatch) => ({
	getDashboardOnlinePlayersCount: () => {
		dispatch(getDashboardOnlinePlayersCount());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(OnlinePlayersWidgetComponent);
