import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { PROJECT_TYPE, REPORT_TYPE } from "constants/common.constants";

import { BETSHOP_PERFORMANCE_REPORT_BEFORE, BETSHOP_PERFORMANCE_REPORT_FINISH, SET_BETSHOP_PERFORMANCE_REPORT, SET_BETSHOP_PERFORMANCE_REPORT_FILTERS } from "store/actionTypes";

export const setBetshopPerformanceReportBefore = () => ({
	type: BETSHOP_PERFORMANCE_REPORT_BEFORE
});

export const setBetshopPerformanceReportFinished = () => ({
	type: BETSHOP_PERFORMANCE_REPORT_FINISH
});

const setBetshopPerformanceReport = (betshopPerformance) => ({
	type: SET_BETSHOP_PERFORMANCE_REPORT,
	payload: { betshopPerformance }
});

export const setBetshopPerformanceReportFilters = (filters) => ({
	type: SET_BETSHOP_PERFORMANCE_REPORT_FILTERS,
	payload: { filters }
});

export const getBetshopPerformanceReport = () => {
	return (dispatch, getState) => {
		dispatch(setBetshopPerformanceReportBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_BETSHOP_PERFORMANCE_REPORT}`,
			method: Methods.GET,
			params: {
				...getState().reports.betshopPerformance.filters,
				projectType: PROJECT_TYPE.RETAIL,
				reportType: REPORT_TYPE.BY_ACTION_TIME
			}
		})
			.then(({ data: { value: betshopPerformance } }) => {
				dispatch(setBetshopPerformanceReport(betshopPerformance));
				dispatch(setBetshopPerformanceReportFinished());
			})
			.catch((ex) => {
				dispatch(setBetshopPerformanceReportFinished());
			});
	};
};
