import { Fragment } from "react";
import PropTypes from "prop-types";

import GameEditComponent from "components/dashboard/virtuals/games/edit/game-edit.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { GAME_CATEGORY } from "constants/game.constants";

const GameEditRouteComponent = ({ gameCategory }) => {
	return (
		<Fragment>
			<GameEditComponent gameCategory={gameCategory} />
		</Fragment>
	);
};

GameEditRouteComponent.propTypes = {
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const GameEditRoute = withPermission(GameEditRouteComponent, { resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);

export const ScheduledGameEditRoute = () => {
	return <GameEditRoute gameCategory={GAME_CATEGORY.SCHEDULED} />;
};

export const InstantGameEditRoute = () => {
	return <GameEditRoute gameCategory={GAME_CATEGORY.INSTANT} />;
};
