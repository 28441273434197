import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Input, Button, Divider, Spin } from "antd";
import moment from "moment";
const { Item: FormItem } = Form;
import ListItem from "./listItem";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { getStreamAvailableGames, saveStreamsGeneralInfo } from "store/actions/dashboard/streams/generalInfo.action";
import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

/** Partner Edit Page General Info Tab Component */
const GeneralInfoComponent = ({ isLoading, isSaving, generalInfo, onTabChange, saveStreamsGeneralInfo, getStreamAvailableGames, availableGames }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue, getFieldValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.STREAMS, action: PERMISSION_ACTION.MODIFY });

	/** Get Available Games */
	useEffect(() => getStreamAvailableGames(), []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		const newState = { ...generalInfo };
		newState.games = newState.games.map((game) => {
			const newGame = { ...game };
			newGame.date = [moment.utc(`${newGame.from.hour}:${newGame.from.minute}`, "HH:mm").local(), moment.utc(`${newGame.to.hour}:${newGame.to.minute}`, "HH:mm").local()];
			return newGame;
		});
		setFieldsValue(newState);
	}, [generalInfo]);

	/** Fires when form submitted
	 * @function
	 * @memberOf GeneralInfoComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				const dataForSave = { ...data };
				dataForSave.id = generalInfo.id;
				dataForSave.games = dataForSave.games.map((game) => {
					const newGameObj = { ...game };
					const [fromObj, toObj] = newGameObj.date;
					newGameObj.from = { hour: fromObj.utc().hour(), minute: fromObj.utc().minute() };
					newGameObj.to = { hour: toObj.utc().hour(), minute: toObj.utc().minute() };
					newGameObj.name = (availableGames.find((ag) => ag.id === game.id))?.name ?? null;
					delete newGameObj.date;
					return newGameObj;
				});
				saveStreamsGeneralInfo(dataForSave);
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					id: null,
					name: null,
					games: [],
					streamUrl: null
				}}
				onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, { ...generalInfo }))}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[40, 0]}>
							<Col xs={24} sm={24} xl={12}>
								<Row gutter={[16, 0]}>
									<Col span={24}>
										<h1>{t("pages.dashboard.streams.generalInfo")}</h1>
									</Col>
									<Col xs={24} sm={12}>
										<FormItem label={`${t("pages.dashboard.streams.name")} *`} name="name" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
											<Input maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.streams.name")}`} disabled={isDisabled} />
										</FormItem>
									</Col>
									<Col xs={24} sm={12}>
										<FormItem label={`${t("pages.dashboard.streams.streamUrl")} *`} name="streamUrl" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
											<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.streams.streamUrl")}`} disabled={isDisabled} />
										</FormItem>
									</Col>
								</Row>
							</Col>
						</Row>
						<Divider style={{ marginBottom: "24px", marginTop: "8px" }} />
						<Row gutter={[16, 0]}>
							<Col>
								<Row gutter={[18, 0]}>
									<Form.List name="games">
										{(fields, { add, remove }) => {
											return (
												<Col xs={24} sm={24} xl={12}>
													{fields.map((field, index) => (
														<ListItem key={field.fieldKey} field={field} fields={fields} index={index} remove={remove} getFieldValue={getFieldValue} availableGames={availableGames} disabled={isDisabled} />
													))}
													{!isDisabled ? (
														<Form.Item>
															<Button type="primary" onClick={() => add()}>
																{t("pages.dashboard.streams.addGame")}
															</Button>
														</Form.Item>
													) : null}
												</Col>
											);
										}}
									</Form.List>
								</Row>
							</Col>
						</Row>
					</div>
					{hasPermission({ resource: PERMISSION_RESOURCE.STREAMS, action: PERMISSION_ACTION.MODIFY }) && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** GeneralInfoComponent propTypes
 * PropTypes
 */
GeneralInfoComponent.propTypes = {
	/** Redux action to save stream General info */
	saveStreamsGeneralInfo: PropTypes.func,
	/** Redux state property, is true when general info is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when general info is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the general info of current editing partner  */
	generalInfo: PropTypes.object,
	// /** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func,
	/** Redux action to get Available Games */
	getStreamAvailableGames: PropTypes.func,
	/** Redux state, available games of current editing stream  */
	availableGames: PropTypes.arrayOf(PropTypes.object)
};

const mapDispatchToProps = (dispatch) => ({
	saveStreamsGeneralInfo: (data) => {
		dispatch(saveStreamsGeneralInfo(data));
	},
	getStreamAvailableGames: () => {
		dispatch(getStreamAvailableGames());
	}
});

const mapStateToProps = (state) => {
	return {
		generalInfo: state.streams.generalInfo,
		isSaving: state.partners.isSaving,
		isLoading: state.streams.isLoading,
		availableGames: state.streams.availableGames
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent);
