import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getApiKeyGameRTPs, saveApiKeyGameRTPs, resetApiKeyGameRTPs } from "store/actions/dashboard/online/apikeys/rtp.action";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Input, Space, Switch } from "antd";
const { Item: FormItem } = Form;
import Table from "components/common/table";
import FooterButtons from "./footerButtons";
import NumericInput from "components/common/numericInput";
import { countDecimals, numberTransform } from "utils/common";
import { rtpPeriodChecking } from "utils/markets";
import { hasPermission } from "utils/permissions";
import { showError } from "utils/message";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import rtpType from "types/rtp/rtp.type";
import useRTPs from "hooks/useRTPs";
import { PERIOD } from "constants/market.constants";
import { GAME_CATEGORY, INSTANT_GAME_TYPE, SCHEDULED_GAME_TYPE } from "constants/game.constants";
import useForceUpdate from "hooks/useForceUpdate";

const saveButtonPropsStateDefault = { loading: false, disabled: true, style: { display: "none" } };

/** Apikey Game RTP managment Popup Component */
const RTPComponent = ({ isSaving, isLoading, getApiKeyGameRTPs, saveApiKeyGameRTPs, resetApiKeyGameRTPs, onClose, rtp, gameId, gameType, gameCategory }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [orders, setOrders] = useState({});
	const [initialized, setInitialized] = useState(false);
	const [isFormTouched, setIsFormTouched] = useState(false);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_RTP, action: PERMISSION_ACTION.MODIFY });
	const [saveButtonPropsState, setSaveButtonPropsState] = useState(saveButtonPropsStateDefault);
	const [forceUpdate] = useForceUpdate();

	useEffect(() => {
		setSaveButtonPropsState({
			loading: isSaving,
			disabled: isDisabled || !isFormTouched,
			style: isDisabled || !rtp.allowOverride ? { display: "none" } : {}
		});
	}, [isSaving, isDisabled, isFormTouched, rtp.allowOverride]);

	/** Columns of table */
	const columns = useMemo(() => ([
		{
			title: "#",
			dataIndex: "index",
			sorter: false,
			render: (index, record) => (rtpPeriodChecking(record, PERIOD.MATCH, PERIOD.NONE, PERIOD.PENALTY_SHOOTOUT) ? index + 1 : " "),
			width: "6%"
		},
		{
			title: t("pages.dashboard.apikeys.rtp.market"),
			dataIndex: "name",
			render: (value, record) => {

				if (gameType === SCHEDULED_GAME_TYPE.KENO.value) {
					return record.name;
				}

				if (record.period === PERIOD.FIRSTHALF) {
					return `${t(`markets.${gameType}.FirstHalf`)} ${t(`markets.${gameType}.${value}`)}`;
				}

				if (record.period === PERIOD.SECONDHALF) {
					return `${t(`markets.${gameType}.SecondHalf`)} ${t(`markets.${gameType}.${value}`)}`;
				}

				if (rtpPeriodChecking(record, PERIOD.MATCH, PERIOD.NONE, PERIOD.PENALTY_SHOOTOUT)) {
					if (record.period === PERIOD.MATCH && record.name === "Winner") {
						return t(`markets.${gameType}.LeagueChamp`);
					}
					return t(`markets.${gameType}.${value}`);
				}

				return "";
			},
			sorter: false,
			className: "drag-visible",
			width: "27%",
			ellipsis: true
		},
		{
			title: t("pages.dashboard.apikeys.rtp.margin"),
			dataIndex: "margin",
			sorter: false,
			className: "ant-table-input-cell",
			render: (value, record) => (
				<FormItem
					className="inline-form-item-control table-form-control"
					name={["rtPs", record.name + "_" + record.period, "margin"]}
					rules={[
						{ required: true, message: t("validation.field_required") },
						{
							type: "number",
							min: rtp.minPossibleValue || 1,
							message: t("validation.must_be_more").replace("%X%", rtp.minPossibleValue || 1),
							transform: numberTransform
						},
						{
							type: "number",
							max: rtp.maxPossibleValue || 50,
							message: t("validation.must_be_less").replace("%X%", rtp.maxPossibleValue || 50),
							transform: numberTransform
						}
					]}
					style={{ marginLeft: 0 }}
				>
					<NumericInput
						style={{ maxWidth: "200px" }}
						decimalsCount={2}
						disabled={isDisabled}
						onChange={(val) => {
							const numVal = Number(val);
							const value = (100 - (!isNaN(numVal) ? numVal : 0)).toFixed(countDecimals(numVal));
							setFieldsValue({
								rtPs: {
									[record.name + "_" + record.period]: {
										rtp: value
									}
								}
							});
						}}
					/>
				</FormItem>
			),
			width: "28%"
		},
		{
			title: t("pages.dashboard.apikeys.rtp.rtp"),
			dataIndex: "rtp",
			className: "ant-table-input-cell",
			render: (value, record) => (
				<FormItem className="inline-form-item-control table-form-control" style={{ marginLeft: 0 }} name={["rtPs", record.name + "_" + record.period, "rtp"]}>
					<Input style={{ maxWidth: "200px" }} disabled={true} />
				</FormItem>
			),
			sorter: false,
			width: "28%"
		},
		{
			title: "",
			dataIndex: "enabled",
			className: "ant-table-input-cell",
			render: (value, record) => {
				let isDisabledByParent = false;
				
				if (gameType !== SCHEDULED_GAME_TYPE.KENO.value && [PERIOD.FIRSTHALF, PERIOD.SECONDHALF].includes(record.period)) {
					const parentRtp = formInstance.getFieldValue(["rtPs", `${record.name}_${PERIOD.MATCH}`])
					isDisabledByParent = !parentRtp.enabled
				}
				return (
					<FormItem className="inline-form-item-control table-form-control" style={{ marginLeft: 0 }} name={["rtPs", record.name + "_" + record.period, "enabled"]} valuePropName="checked">
						<Switch disabled={isDisabled || isDisabledByParent} />
					</FormItem>
				)
			},
			sorter: false,
			width: "10%"
		}
	]
	), [gameType, t, setFieldsValue, isDisabled]);


	const { mutatedData, getDetailsData } = useRTPs(rtp, orders, columns);

	/** Function to sort rtps data by order Number
	 * @function
	 * @param {array} data - array of rtps
	 * @returns {string} data - sorted array
	 * @memberOf RTPComponent
	 */
	const sortByOrderNumber = (data) => {
		data.sort((item1, item2) => (orders[item1.name] < orders[item2.name] ? -1 : 1));
		return data;
	};

	const modifiedMarkets = useMemo(() => {
		const marketsNames = rtp.rtPs.filter((d) => d.period === 0 || d.period === 3).map((d) => d.name);
		const firstHalfMarketNames = rtp.rtPs.filter((d) => d.period === 1).map((d) => d.name);

		const filtered = rtp.rtPs.filter((d) => d.period === 0 || d.period === 3 || (d.period === 1 && !marketsNames.includes(d.name)) || (d.period === 2 && !marketsNames.includes(d.name) && !firstHalfMarketNames.includes(d.name)));

		const dataSource = sortByOrderNumber(filtered).map((el, index) => ({
			...el,
			key: el.name + el.period,
			index: index,
			isMain: true
		}));

		return dataSource;
	}, [rtp.rtPs, orders]);

	/** Fires when form submitted
	 * @function
	 * @memberOf RTPComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((values) => {
				try {
					const { rtPs } = rtp.rtPs.reduce((acc, d) => {
						if (acc.hasSameOrder) {
							throw new Error(t('errors.message.rtpsHaveSameOrder'));
						}
						else if (acc.groupedRtps[d.orderNumber] && acc.groupedRtps[d.orderNumber] !== d.name) {
							acc.hasSameOrder = true;
						}
						else {
							acc.groupedRtps[d.orderNumber] = d.name;

							if (!values.rtPs[d.name + "_" + d.period]) {
								return acc;
							}

							acc.rtPs.push({
								margin: values.rtPs[d.name + "_" + d.period].margin,
								enabled: values.rtPs[d.name + "_" + d.period].enabled,
								name: d.name,
								period: d.period,
								orderNumber: orders[d.name],
							});
						}

						return acc;
					}, { rtPs: [], groupedRtps: {}, hasSameOrder: false });

					const d = {
						id: routeParams.id,
						gameId,
						rtPs
					};

					saveApiKeyGameRTPs({ data: d, onSuccess: onClose, gameCategory });
				} catch (err) {
					if (err.message === t("errors.message.rtpsHaveSameOrder")) {
						showError(err.message);
					} else {
						throw new Error(err.message);
					}
				}
			})
			.catch(Function.prototype);
	};

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		const orders = {};
		rtp.rtPs.forEach((r) => {
			orders[r.name] = r.orderNumber;
		});
		setOrders(orders);

		if (initialized) {
			setFieldsValue({
				rtPs: Object.fromEntries(
					rtp.rtPs.map((r, i) => [
						r.name + "_" + r.period,
						{
							margin: r.margin,
							rtp: r.rtp,
							enabled: r.enabled
						}
					])
				)
			});
		}
	}, [rtp.rtPs]);

	/** reset Rtp data when opening popup */
	useEffect(() => {
		resetApiKeyGameRTPs();
		setInitialized(true);
	}, []);

	/** Activate save button only when there is a change */
	useEffect(() => {
		setIsFormTouched(true);
	}, [rtp.rtPs]);

	return (
		<Space direction="vertical">
			<Form
				className="dashboard-form"
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				onValuesChange={(_, values) => {
					if (gameType !== SCHEDULED_GAME_TYPE.KENO.value) {
						const rtPsEntries = Object.entries(values.rtPs)
						const filtered = rtPsEntries.filter(([key, value]) => {
							const periodStr = key.at(-1);
							return (PERIOD.MATCH.toString() === periodStr) && !value.enabled;
						})
						if (filtered.length > 0) {
							const newValues = { ...values, rtPs: { ...values.rtPs } }
							filtered.forEach((entry) => {
								const [groupName] = entry.at(0).split("_");
								[
									`${groupName}_${PERIOD.FIRSTHALF}`,
									`${groupName}_${PERIOD.SECONDHALF}`
								].forEach(subGroup => {
									if (newValues.rtPs[subGroup]) {
										newValues.rtPs[subGroup] = {
											...newValues.rtPs[subGroup],
											enabled: false
										}
										formInstance.setFieldValue(["rtPs", subGroup, "enabled"], false)
									}
								})
							})
						}
						// Need to re-render table for re-work column render function
						forceUpdate()
					}
				}}
			>
				<div className="ant-modal-table">
					<Table
						loading={isLoading}
						columns={columns}
						// data={modifiedMarkets}
						data={mutatedData}
						loadFn={() => getApiKeyGameRTPs({ id: routeParams.id, gameId, gameCategory })}
						total={rtp.rtPs.length}
						isDisabled={(record) => !record.period}
						noPagination={true}
						uniqueKey="name"
						nonFixed={true}
						detailsType="table"
						details={getDetailsData}
						isAlwaysExpended={true}
						tableLayout="fixed"
						draggable={
							isDisabled
								? null
								: {
									onDragEnd: (oldIndex, newIndex) => {
										const oldOrderNumber = oldIndex + 1;
										const newOrderNumber = newIndex + 1;

										const newOrders = {};

										Object.keys(orders).forEach((k) => {
											if (oldOrderNumber < newOrderNumber) {
												if (orders[k] === oldOrderNumber) {
													newOrders[k] = newOrderNumber;
												} else if (orders[k] > oldOrderNumber && orders[k] <= newOrderNumber) {
													newOrders[k] = orders[k] - 1;
												} else {
													newOrders[k] = orders[k];
												}
											} else {
												if (orders[k] === oldOrderNumber) {
													newOrders[k] = newOrderNumber;
												} else if (orders[k] < oldOrderNumber && orders[k] >= newOrderNumber) {
													newOrders[k] = orders[k] + 1;
												} else {
													newOrders[k] = orders[k];
												}
											}
										});

										setOrders(newOrders);
										setTimeout(() => {
											setIsFormTouched(true);
										}, 100);
									}
								}
						}
						isDraggable={() => true}
						id="apikeys_games_rtps_modal"
					/>
				</div>
			</Form>
			<FooterButtons onClose={onClose} onSave={!(isDisabled || !rtp.allowOverride) ? handleForm : null} saveButtonProps={saveButtonPropsState} />
		</Space>
	);
};

RTPComponent.propTypes = {
	/** Redux state property, is true when rtps of api key game is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when rtps of api key game is loading */
	isLoading: PropTypes.bool,
	/** Redux action to get game rtps of api key game */
	getApiKeyGameRTPs: PropTypes.func,
	/** Redux action to save game rtps of api key game */
	saveApiKeyGameRTPs: PropTypes.func,
	/** Redux action to clear game rtps of api key game */
	resetApiKeyGameRTPs: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Redux state, represents the rtp data of current editing api key game  */
	rtp: rtpType,
	/** Api keys Current editing game id */
	gameId: PropTypes.string,
	/** Current game type */
	gameType: PropTypes.oneOf([...Object.values(SCHEDULED_GAME_TYPE), ...Object.values(INSTANT_GAME_TYPE)].map((v) => v.value)),
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	getApiKeyGameRTPs: (obj) => {
		dispatch(getApiKeyGameRTPs(obj));
	},
	saveApiKeyGameRTPs: (obj) => {
		dispatch(saveApiKeyGameRTPs(obj));
	},
	resetApiKeyGameRTPs: () => {
		dispatch(resetApiKeyGameRTPs());
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.apikeys.isSaving,
		isLoading: state.apikeys.editingApikey.rtp.isLoading,
		rtp: state.apikeys.editingApikey.rtp.data
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RTPComponent);
