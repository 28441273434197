import PropTypes from "prop-types";

import { connect } from "react-redux";

import { PROJECT_TYPE } from "constants/common.constants";
import { TRANSACTION_TYPE } from "constants/transactions.constants";

import { getTransactions, getTransactionHistory, setTransactionsSorting, setTransactionsFilters, resendTransaction } from "store/actions/dashboard/transactions/transactions.action";

import TransactionsTableComponent from "../../../table.component";

import transactionType from "types/transaction/transaction.type";
import sortingType from "types/common/sorting.type";

/** Transactions Page Rollback Tab Retail SubTabe Page Component */
const RetailTransactionsComponent = ({ getTransactions, getTransactionHistory, setTransactionsSorting, setTransactionsFilters, resendTransaction, transactions, isLoading, sorting, filters, total }) => {
	return (
		<TransactionsTableComponent
			getTransactions={getTransactions}
			getTransactionHistory={getTransactionHistory}
			setTransactionsSorting={setTransactionsSorting}
			setTransactionsFilters={setTransactionsFilters}
			resendTransaction={resendTransaction}
			transactions={transactions}
			isLoading={isLoading}
			sorting={sorting}
			filters={filters}
			total={total}
			projectType={PROJECT_TYPE.RETAIL}
			transactionType={TRANSACTION_TYPE.ROLLBACK}
		/>
	);
};

/** RetailTransactionsComponent propTypes
 * PropTypes
 */
RetailTransactionsComponent.propTypes = {
	/** Redux action to get retail transactions */
	getTransactions: PropTypes.func,
	/** Redux action to get transaction history */
	getTransactionHistory: PropTypes.func,
	/** Redux action to process transaction */
	resendTransaction: PropTypes.func,
	/** Redux state property, represents the array of retail transactions */
	transactions: PropTypes.arrayOf(transactionType),
	/** Redux state property, is true when loading retail transactions */
	isLoading: PropTypes.bool,
	/** Redux action to set retail transactions sorting details */
	setTransactionsSorting: PropTypes.func,
	/** Redux action to set retail transactions filters */
	setTransactionsFilters: PropTypes.func,
	/** Redux state property, retail transactions sorting details */
	sorting: sortingType,
	/** Redux state property, retail transactions filters */
	filters: PropTypes.object,
	/** Redux state property, retail transactions total count */
	total: PropTypes.number
};

const mapDispatchToProps = (dispatch) => ({
	getTransactions: (fromFirstPage) => {
		dispatch(getTransactions(PROJECT_TYPE.RETAIL, TRANSACTION_TYPE.ROLLBACK, fromFirstPage));
	},
	getTransactionHistory: (id) => {
		dispatch(getTransactionHistory(id, PROJECT_TYPE.RETAIL, TRANSACTION_TYPE.ROLLBACK));
	},
	setTransactionsSorting: (sorting) => {
		dispatch(setTransactionsSorting(sorting, PROJECT_TYPE.RETAIL, TRANSACTION_TYPE.ROLLBACK));
	},
	setTransactionsFilters: (filters) => {
		dispatch(setTransactionsFilters(filters, PROJECT_TYPE.RETAIL, TRANSACTION_TYPE.ROLLBACK));
	},
	resendTransaction: (id) => {
		dispatch(resendTransaction(id, PROJECT_TYPE.RETAIL, TRANSACTION_TYPE.ROLLBACK));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.transactions.isLoading,
		transactions: state.transactions.rollbacks.retail.transactions,
		total: state.transactions.rollbacks.retail.total,
		sorting: state.transactions.rollbacks.retail.sorting,
		filters: state.transactions.rollbacks.retail.filters
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RetailTransactionsComponent);
