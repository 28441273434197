import { useMemo } from "react";
import PropTypes from "prop-types";
import { sortBy } from "utils/common";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ColorGenerator } from "utils/color";
import { toUpperCaseFirstLetter } from "utils/common";
import CsatRatesInfo from "components/dashboard/usersFeedback/CSAT/csatRatesInfo";

/** Dashboard Page Surveys Chart Component */
const PieChartComponent = ({ chartData, text, csatScore }) => {
	const { orderedRates, ratesAmount } = useMemo(() => {
		const orderMap = {
			great: 1,
			good: 2,
			okay: 3,
			bad: 4,
			terrible: 5,
			skip: 6
		};
		const rateKeys = Object.keys(chartData);
		const colorGen = new ColorGenerator(rateKeys.length, ["#7DC681", "#DBE670", "#FFE76B", "#FF1A41", "#D10F00", "#8B8B8B"]);

		const { rates, ratesAmount } = rateKeys.reduce(
			(acc, key) => {
				acc.ratesAmount += chartData[key];
				acc.rates.push({
					name: key,
					order: orderMap[key],
					y: chartData[key]
				});

				return acc;
			},
			{ ratesAmount: 0, rates: [] }
		);

		return {
			ratesAmount,
			orderedRates: sortBy(
				rates,
				(item) => item?.order
			).map((rate) => ({ ...rate, name: toUpperCaseFirstLetter(rate.name), color: colorGen.next().value }))
		};
	}, [chartData]);

	const isPieEmpty = ratesAmount === 0;

	return (
		<div className="bonus-chart">
			<div className="bonus-chart-content pie-chart">
				<div className="pie-chart-content">
					<HighchartsReact
						highcharts={Highcharts}
						options={{
							accessibility: {
								enabled: false
							},
							chart: {
								width: "280",
								type: "pie",
								marginTop: -80
							},
							legend: {
								align: "center",
								layout: "vertical",
								verticalAlign: "center",
								x: 0,
								y: 0
							},
							plotOptions: {
								pie: {
									size: "250",
									showInLegend: false,
									innerSize: isPieEmpty ? "0%" : "70%",
									dataLabels: {
										enabled: false
									}
								}
							},
							title: {
								text: ""
							},
							tooltip: {
								backgroundColor: "#4D4D4D",
								style: {
									color: "#FFFFFF"
								},
								formatter: function () {
									return this.key + " :  " + Math.round(this.percentage) + "%<br/>Count :  " + this.y;
								}
							},
							series: [
								{
									name: "Count",
									colorByPoint: true,
									data: isPieEmpty ? [] : orderedRates
								}
							]
						}}
					/>
					{text && (
						<div className="bonus-chart-content-empty">
							<span>{text}</span>
							<br />
							<span>{`${csatScore} %`}</span>
						</div>
					)}
				</div>
				<div className="pie-chart-legend">
					<CsatRatesInfo rates={orderedRates} />
				</div>
			</div>
		</div>
	);
};

/** PieChartComponent propTypes
 * PropTypes
 */
PieChartComponent.propTypes = {
	/** Redux state property, represents the data of surveys activities */
	chartData: PropTypes.object,
	/** Text for chart */
	text: PropTypes.string,
	/** Csat Score Value */
	csatScore: PropTypes.number
};

export default PieChartComponent;
