import { useState, Fragment, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "antd/lib/button";

import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import Filters from "./filters.component";
import Question from "components/common/question";

import { getBetCorrections, setBetslipsCorrectionsSorting, settleBetCorrections } from "store/actions/dashboard/developer/betCorrection/betCorrections.action";

import sortingType from "types/common/sorting.type";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import { BONUS_TYPE_TRANSLATION_TEXTS } from "constants/bonus.constants";

import { hasPermission } from "utils/permissions";
import { isBetslipCancelable, isEventIsNotFinishedYet } from "utils/common";
import { cancelPendingBetslip } from "store/actions/dashboard/betslips/pendings.action";
import { DATE_TIME_FORMAT } from "constants/date.constants";

/** Games Page Component */
const BetCorrectionComponent = ({ isLoading, total, sorting, getBetCorrections, cancelPendingBetslip, setBetslipsCorrectionsSorting, betCorrections, settleBetCorrections }) => {
	const { t } = useTranslation();

	const [showQuestion, setShowQuestion] = useState(false);

	const dateRef = useRef(moment().local().format());

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.reports.partner"),
			dataIndex: "partnerName"
		},
		{
			title: t("pages.dashboard.reports.project"),
			dataIndex: "projectName"
		},
		{
			title: t("pages.dashboard.reports.player"),
			dataIndex: "userName"
		},
		{
			title: t("pages.dashboard.players.external_id"),
			dataIndex: "externalId"
		},
		{
			title: "IP",
			dataIndex: "ipAddress"
		},
		{
			title: t("pages.dashboard.games.game"),
			dataIndex: "gameName"
		},
		{
			title: t("pages.dashboard.bets.bonus_id"),
			dataIndex: "bonusId",
			render: (value, record) => {
				if (record.bonusId === null) {
					return " - ";
				}
				return <span title={record.bonusId}>{record.bonusId}</span>;
			}
		},
		{
			title: t("pages.dashboard.bets.bonus_type"),
			render: (value, record) => {
				if (!record.bonusId) {
					return " - ";
				}

				const translatedBonusType = t(BONUS_TYPE_TRANSLATION_TEXTS[record.bonusType]);

				return <span title={translatedBonusType}>{translatedBonusType}</span>;
			}
		}
	];

	const getDetailsData = (record) => {
		return {
			columns: [
				{
					title: t("pages.dashboard.bets.bet_id"),
					dataIndex: "id"
				},
				{
					title: t("pages.dashboard.bets.event_id"),
					dataIndex: "eventId"
				},
				{
					title: t(t("pages.dashboard.bets.starts_at")),
					dataIndex: "eventStartDate",
					render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT) || "-"
				},
				{
					title: t("pages.dashboard.bets.ends_at"),
					dataIndex: "eventFinishTime",
					render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT) || "-"
				},
				{
					title: t("pages.dashboard.bets.market_id"),
					dataIndex: "market"
				},
				{
					title: t("pages.dashboard.bets.betting_period_start"),
					dataIndex: "bettingPeriodStartTime",
					render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT) || "-"
				},
				{
					title: t("pages.dashboard.bets.betting_period_end"),
					dataIndex: "bettingPeriodEndTime",
					render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-")
				}
			],
			data: record.bets,
			uniqueKey: "id"
		};
	};

	const handleSettleAll = () => {
		settleBetCorrections(betCorrections.map((b) => b.id));
		setShowQuestion(false);
	};
	const handleOkQuestion = () => handleSettleAll();
	const handleCancelQuestion = () => setShowQuestion(false);

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.games.virtual_scheduled_games") }]} />
				<div className="dashboard-section-content">
					<div className="table-header">
						<div className="table-buttons-dropdowns">
							{hasPermission({ resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.MODIFY }) && betCorrections?.length ? (
								<div className="table-buttons-dropdowns-button">
									<Button onClick={() => setShowQuestion(true)} type="primary">
										{t("pages.dashboard.bets.settle_all")}
									</Button>
								</div>
							) : null}
						</div>
						<Filters />
					</div>
					<Table
						loading={isLoading}
						columns={columns}
						data={betCorrections}
						loadFn={getBetCorrections}
						sorting={sorting}
						setSortingFn={setBetslipsCorrectionsSorting}
						total={total}
						actions={
							hasPermission({ resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.MODIFY })
								? {
										cancelBet: {
											messageKey: "bet_cancel",
											handler: (record, reason) => cancelPendingBetslip({ id: record.id, reason }),
											title: t("common.cancel"),
											hidden: (record) => !isBetslipCancelable(record),
											icon: <i className="icon-cancel" />,
											type: "delete",
											promptLabel: t("pages.dashboard.bets.cancel_reason"),
											isPromptRequired: true,
											isPrompt: true
										},
										settleBetslips: {
											title: t("pages.dashboard.bets.settle_betslips"),
											messageKey: "settle_betslisp",
											handler: (record) => {
												settleBetCorrections([record.id]);
											},
											hidden: (record) => isEventIsNotFinishedYet(record, dateRef.current, (_date) => moment.utc(_date).local().format()),
											icon: <i className="icon-settle-betslip" />,
											type: "delete"
										}
									}
								: {}
						}
						details={getDetailsData}
						detailsType="table"
					/>
				</div>
			</div>

			<Question type="confirm" onOk={handleOkQuestion} onCancel={handleCancelQuestion} isVisible={showQuestion} message={t("alerts.settle_betslisp.delete_all")} title={t("pages.dashboard.bets.settle_betslips")} isPromptRequired />
		</Fragment>
	);
};

/** BetCorrectionComponent propTypes
 * PropTypes
 */
BetCorrectionComponent.propTypes = {
	/** Redux state property, is true when loading games */
	isLoading: PropTypes.bool,
	/** Redux state property, bet corrections total count */
	total: PropTypes.number,
	/** Redux action to set betslip corrections sorting details */
	setBetslipsCorrectionsSorting: PropTypes.func,
	/** Redux state property, bet corrections sorting details */
	sorting: sortingType
};

const mapDispatchToProps = (dispatch) => ({
	setBetslipsCorrectionsSorting: (sorting) => {
		dispatch(setBetslipsCorrectionsSorting(sorting));
	},
	getBetCorrections: (fromFirstPage) => {
		dispatch(getBetCorrections(fromFirstPage));
	},
	cancelPendingBetslip: ({ id, reason }) => {
		dispatch(cancelPendingBetslip({ id, reason, projectType: PROJECT_TYPE.ONLINE }));
	},
	settleBetCorrections: (id) => {
		dispatch(settleBetCorrections([...id]));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.betCorrections.isLoading,
		total: state.betCorrections.total,
		sorting: state.betCorrections.sorting,
		betCorrections: state.betCorrections.data
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BetCorrectionComponent);
