import PropTypes from "prop-types";

import { connect } from "react-redux";

import Dropdown from "antd/lib/dropdown";

import { changeUserCurrency } from "store/actions/dashboard/profile/userInfo.action";

import { ditinctArrayOfObjects } from "utils/common";

import currencyType from "types/common/currency.type";

/** Global currency dropdown component on Header */

const GlobalCurrencyDropdown = ({ currency, currencies, changeUserCurrency }) => {
	/** Function, fires on drodown item click
	 * @function
	 * @param {string} code
	 * @memberOf GlobalCurrencyDropdown
	 */
	const handleDropdownChange = (code) => {
		if (code) {
			const curr = ditinctArrayOfObjects(currencies, "code").find((c) => c.code === code);
			changeUserCurrency(curr);
		}
	};

	return (
		<div className="global-dropdown">
			<Dropdown
				menu={{
					onClick: currencies ? (e) => handleDropdownChange(e ? e.key : null) : Function.prototype,
					items: currencies
						? ditinctArrayOfObjects(currencies, "code").map((c, i) => {
								return {
									key: c.code || i,
									label: `${c.name} - ${c.code.toUpperCase()}`,
									// TODO: this className doesn't work, need to handle other way
									className: currency && c.code === currency.code ? "global-dropdown-active" : ""
								};
							})
						: []
				}}
				trigger={["click"]}
				placement="bottomRight"
				overlayClassName="global-dropdown-menu"
			>
				<div className="global-dropdown-content">
					<i className="icon-currency-header" />
					<span>{currency ? currency.code : ""}</span>
					<i className="icon-down" />
				</div>
			</Dropdown>
		</div>
	);
};

/** GlobalCurrencyDropdown propTypes
 * PropTypes
 */
GlobalCurrencyDropdown.propTypes = {
	/** Redux state property, the current user currency */
	currency: currencyType,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType),
	/** Redux action to change user currency */
	changeUserCurrency: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	changeUserCurrency: (currency) => {
		dispatch(changeUserCurrency(currency));
	}
});

const mapStateToProps = (state) => {
	return {
		currency: state.profile.userInfo.currency,
		currencies: state.profile.userInfo.currencies
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalCurrencyDropdown);
