import { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { Row, Col, Spin } from "antd";

import ApiUrls from "constants/api.constants";

import BannerUpload from "components/dashboard/common/bannerUpload";

import { getBanners, deleteBanner, resetBanners } from "store/actions/dashboard/virtuals/games/banners.action";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { hasPermission } from "utils/permissions";
import { FILE_SIZES_MEASUREMENT } from "constants/common.constants";

/** Game Edit Page Track Banners Tab Component */
const TrackBannersComponent = ({ getBanners, deleteBanner, resetBanners, banners, isLoading, partnerId }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	/** Load game general info */
	useEffect(() => {
		if (routeParams.id) getBanners(routeParams.id);

		return () => resetBanners();
	}, []);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<div className="dashboard-section-content">
				{Object.keys(banners).map((banner) => (
					<Row gutter={[16, 0]} key={banner}>
						<Col xs={24} sm={24}>
							<h3 className="dashboard-section-title">{t(`pages.dashboard.games.banners.${banner}_text`)}</h3>
							<p className="dashboard-section-subtitle">{t(`pages.dashboard.games.banners.${banner}_desc`)}</p>
						</Col>
						{banners[banner].map((b, index) => (
							<Col xs={24} sm={12} md={6} key={index}>
								<BannerUpload
									uploadUrl={`${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_UPLOAD_BANNER}`}
									defaultFile={
										b && b.id
											? {
													name: banner + b.format,
													dimensions: b.dimension,
													previewUrl: `${import.meta.env.SYSTEM_CDN_URL}/${partnerId ? partnerId : "system"}/games/${routeParams.id}/${banner?.toLowerCase()}_${index}_${b.id}_large.${b.format}`,
													url: b.format === "mp4" ? `${import.meta.env.SYSTEM_CDN_URL}/system/images/banner_thumbnail.png` : `${import.meta.env.SYSTEM_CDN_URL}/${partnerId ? partnerId : "system"}/games/${routeParams.id}/${banner?.toLowerCase()}_${index}_${b.id}_small.${b.format}`,
													status: "done",
													percent: 100,
													loaded: b.size,
													total: b.size,
													format: b.format
												}
											: null
									}
									remove={
										hasPermission({ resource: PERMISSION_RESOURCE.GAME_TRACK_BRANDING, action: PERMISSION_ACTION.DELETE })
											? {
													handler: () => deleteBanner(routeParams.id, index + 1, banner),
													message: t("pages.dashboard.games.banners.remove_confirmation_message")
												}
											: null
									}
									data={{
										gameId: routeParams.id,
										bannerNumber: index + 1,
										type: banner
									}}
									fileBuilder={(value) => ({
										url: value.format === "mp4" ? `${import.meta.env.SYSTEM_CDN_URL}/system/images/banner_thumbnail.png` : `${import.meta.env.SYSTEM_CDN_URL}/${partnerId ? partnerId : "system"}/games/${routeParams.id}/${banner?.toLowerCase()}_${index}_${value.id}_small.${value.format}`,
										previewUrl: `${import.meta.env.SYSTEM_CDN_URL}/${partnerId ? partnerId : "system"}/games/${routeParams.id}/${banner?.toLowerCase()}_${index}_${value.id}_large.${value.format}`,
										dimensions: value.dimension,
										format: value.format
									})}
									extensions={["image/png", "image/jpeg", "image/jpg", "image/bmp", "image/gif"].concat(["bigMonitor", "billboard", "billboardBanner", "smallMonitor"].includes(banner) ? ["video/mp4"] : [])}
									size={2 * FILE_SIZES_MEASUREMENT.MB}
									disabled={!hasPermission({ resource: PERMISSION_RESOURCE.GAME_TRACK_BRANDING, action: PERMISSION_ACTION.MODIFY })}
								/>
							</Col>
						))}
					</Row>
				))}
			</div>
		</Spin>
	);
};

/** TrackBannersComponent propTypes
 * PropTypes
 */
TrackBannersComponent.propTypes = {
	/** Redux action to get game banners */
	getBanners: PropTypes.func,
	/** Redux action to delete game banners */
	deleteBanner: PropTypes.func,
	/** Redux action to reset banners */
	resetBanners: PropTypes.func,
	/** Redux state property, is true when banners is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the array of banners of current editing game  */
	banners: PropTypes.object,
	/** Redux state property, represents game partner id */
	partnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getBanners: (id) => {
		dispatch(getBanners(id));
	},
	deleteBanner: (id, bannerNumber, type) => {
		dispatch(deleteBanner(id, bannerNumber, type));
	},
	resetBanners: () => {
		dispatch(resetBanners());
	}
});

const mapStateToProps = (state) => {
	return {
		banners: state.games.editingGame.banners,
		isLoading: state.games.isLoading,
		partnerId: state.games.editingGame.generalInfo.partnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackBannersComponent);
