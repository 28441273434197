import { Fragment } from "react";
import PropTypes from "prop-types";

import RuleEditComponent from "components/dashboard/cms/rules/sections/rules/edit/rule-edit.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { GAME_CATEGORY } from "constants/game.constants";

const RuleEditRouteComponent = ({ gameCategory }) => {
	return (
		<Fragment>
			<RuleEditComponent gameCategory={gameCategory} />
		</Fragment>
	);
};

RuleEditRouteComponent.propTypes = {
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const RuleEditRoute = withPermission(RuleEditRouteComponent, { resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.MODIFY }, Paths.DASHBOARD);

export const ScheduledRuleEditRoute = () => {
	return <RuleEditRoute gameCategory={GAME_CATEGORY.SCHEDULED} />;
};

export const InstantRuleEditRoute = () => {
	return <RuleEditRoute gameCategory={GAME_CATEGORY.INSTANT} />;
};
