import PropTypes from "prop-types";

export default PropTypes.shape({
	id: PropTypes.string,
	errorTime: PropTypes.string,
	controller: PropTypes.string,
	action: PropTypes.string,
	message: PropTypes.string,
	stackTrace: PropTypes.string,
	requestId: PropTypes.string,
	ip: PropTypes.string
});
