import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Button, Checkbox, Dropdown, Menu } from "antd";

/** Checkboxes Dropdown Component */
const CheckboxDropdown = ({ columns, buttons, title, buttonText, buttonIcon, buttonClassName = "", allowEmpty, selectAllByDefault, defaultSelectedColumns }) => {
	const { t } = useTranslation();

	const [opened, setOpened] = useState(false);
	const [selectAll, setSelectAll] = useState(() => {
		if (!selectAllByDefault) {
			return selectAllByDefault;
		}
		if (!defaultSelectedColumns) {
			return defaultSelectedColumns;
		}
		return defaultSelectedColumns.length === columns.length;
	});
	const [selectedColumns, setSelectedColumns] = useState(() => {
		if (selectAllByDefault) {
			return columns.map((c) => c.key);
		}
		if (defaultSelectedColumns) {
			return defaultSelectedColumns;
		}
		return [];
	});

	/** check/uncheck checkboxes on select/deselect all */
	useEffect(() => {
		if (selectAll) {
			return setSelectedColumns(columns.map((c) => c.key));
		}
		setSelectedColumns([]);
	}, [selectAll]);

	/** set default values */
	useEffect(() => {
		if (selectAllByDefault) {
			return setSelectedColumns(columns.map((c) => c.key));
		}
		setSelectedColumns(() => {
			if (defaultSelectedColumns) {
				return defaultSelectedColumns;
			}
			return [];
		});
	}, [selectAllByDefault]);

	/** Function , hide popup
	 * @function
	 * @memberOf ExportButton
	 */
	const onCancel = () => setOpened(false);

	/** Function , returns modal content JSX
	 * @function
	 * @returns {JSX}
	 * @memberOf ExportButton
	 */
	const renderDropdownContent = () => {
		return {
			items: columns.map((column, i) => ({
				key: column.key || i,
				label: <Checkbox value={column.key}>{column.title}</Checkbox>
			}))
		};
	};

	return (
		<div className={"global-dropdown global-dropdown-without-border " + buttonClassName}>
			<Dropdown
				open={opened}
				menu={renderDropdownContent()}
				dropdownRender={(menu) => {
					const disabled = !allowEmpty && selectedColumns.length === 0;
					return (
						<div className="table-checkboxes-modal-content">
							<div className="table-checkboxes-modal-content-row table-checkboxes-modal-content-row-header">
								<span>{title}</span>
							</div>
							<div className="table-checkboxes-modal-content-inner">
								<div className="table-checkboxes-modal-content-row" style={{ marginBottom: "0" }}>
									<Checkbox checked={selectAll} onChange={(e) => setSelectAll(e.target.checked)} indeterminate={selectAll} className="vs--flex-equal ant-dropdown-menu-item ant-dropdown-menu-item-only-child">
										{selectAll ? t("common.deselect_all") : t("common.select_all")}
									</Checkbox>
								</div>
								<Checkbox.Group value={selectedColumns} onChange={(value) => setSelectedColumns(value)} style={{ lineHeight: 1 }}>
									{menu}
								</Checkbox.Group>
							</div>
							<div className="table-checkboxes-modal-footer">
								{buttons.map((b, index) => (
									<Button
										key={index}
										htmlType="button"
										type="secondary"
										disabled={disabled}
										onClick={() => {
											b.onClick(selectedColumns);
											onCancel();
										}}
									>
										{b.text}
									</Button>
								))}
							</div>
						</div>
					);
				}}
				placement="bottomRight"
				trigger={["click"]}
				getPopupContainer={(trigger) => trigger.parentNode}
				overlayClassName="table-checkboxes-modal global-dropdown-menu"
				overlayStyle={{ width: "230px", maxWidth: "230px", minWidth: "0" }}
				onOpenChange={(e) => setOpened(e)}
			>
				<div className="global-dropdown-content">
					<span>{buttonText}</span>
					<i className="icon-down" />
				</div>
			</Dropdown>
		</div>
	);
};

/** CheckboxDropdown propTypes
 * PropTypes
 */
CheckboxDropdown.propTypes = {
	/** Array od columns of table which can be exported */
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			key: PropTypes.string
		})
	),
	/** Buttons array */
	buttons: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			onClick: PropTypes.func
		})
	),
	/** Dropdown popup title */
	title: PropTypes.string,
	/** Dropdown toggle button text */
	buttonText: PropTypes.string,
	/** Dropdown toggle button icon */
	buttonIcon: PropTypes.node,
	/** Dropdown toggle button className */
	buttonClassName: PropTypes.node,
	/** Allow to uncheck all checkboxes */
	allowEmpty: PropTypes.bool,
	/** Select all checkboxes by default */
	selectAllByDefault: PropTypes.bool,
	/** Default Selected columns */
	defaultSelectedColumns: PropTypes.arrayOf(PropTypes.string)
};

export default CheckboxDropdown;
