import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getApiKeyGeneralInfo } from "store/actions/dashboard/online/apikeys/generalInfo.action";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Tabs } from "antd";
import Breadcrumbs from "components/common/breadcrumbs";
import GeneralComponent from "../edit/sections/general";
import GamesComponent from "./sections/games";
import CurrenciesComponent from "../edit/sections/currencies";
import AnalyticalToolsComponent from "../edit/sections/analyticalTools";
import PlatformsComponent from "../edit/sections/platforms";
import IPRestrictionsComponent from "../edit/sections/ipRestriction";
import CustomizationComponent from "../edit/sections/customization";
import { updateLocationHash, getHashValue } from "utils/common";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import Paths from "constants/path.constants";
import AddOns from "./sections/addOns";

/** ApiKey Edit Component */
const ApiKeyEditComponent = ({ apiKeyName, getApiKeyGeneralInfo }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf ApiKeyEditComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf ApiKeyEditComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	/** Load api key general info*/
	useEffect(() => {
		if (routeParams.id) {
			getApiKeyGeneralInfo(routeParams.id);
		}
	}, [routeParams.id]);

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.projects"), path: Paths.DASHBOARD_ONLINE_APIKEYS }, { title: `${t("common.edit")} ${apiKeyName}` }]} />
				<Tabs
					animated={false}
					defaultActiveKey={getHashValue("tab")}
					onChange={(key) => updateLocationHash("tab=" + key)}
					items={[
						{
							key: "1",
							label: <span className={tabClassName("1")}>{t("pages.dashboard.apikeys.tabs.general")}</span>,
							children: <GeneralComponent onTabChange={(status) => changeTabSavedStatus(status, "1")} />
						},
						hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_GAMES, action: PERMISSION_ACTION.VIEW })
							? {
								key: "2",
								label: <span className={tabClassName("2")}>{t("pages.dashboard.apikeys.tabs.games")}</span>,
								children: <GamesComponent />
							}
							: null,
						hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_ADD_ONS, action: PERMISSION_ACTION.VIEW })
							? {
								key: "3",
								label: <span className={tabClassName("3")}>{t("pages.dashboard.apikeys.tabs.addOns")}</span>,
								children: <AddOns id={routeParams.id} onTabChange={(status) => changeTabSavedStatus(status, "3")} />
							}
							: null,
						hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_CURRENCIES, action: PERMISSION_ACTION.VIEW })
							? {
								key: "4",
								label: <span className={tabClassName("4")}>{t("pages.dashboard.apikeys.tabs.currencies_and_limits")}</span>,
								children: <CurrenciesComponent />
							}
							: null,
						hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_ANALYTICAL_TOOLS, action: PERMISSION_ACTION.VIEW })
							? {
								key: "5",
								label: <span className={tabClassName("5")}>{t("pages.dashboard.apikeys.tabs.analytical_tools")}</span>,
								children: <AnalyticalToolsComponent onTabChange={(status) => changeTabSavedStatus(status, "5")} />
							}
							: null,
						hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_PLATFORMS, action: PERMISSION_ACTION.VIEW })
							? {
								key: "6",
								label: <span className={tabClassName("6")}>{t("pages.dashboard.apikeys.tabs.platforms")}</span>,
								children: <PlatformsComponent onTabChange={(status) => changeTabSavedStatus(status, "6")} />
							}
							: null,
						hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_IPRESTRICTIONS, action: PERMISSION_ACTION.VIEW })
							? {
								key: "7",
								label: <span className={tabClassName("7")}>{t("pages.dashboard.apikeys.tabs.ip_restrictions")}</span>,
								children: <IPRestrictionsComponent />
							}
							: null,
						hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_CUSTOMIZATION, action: PERMISSION_ACTION.VIEW })
							? {
								key: "8",
								label: <span className={tabClassName("8")}>{t("pages.dashboard.apikeys.tabs.customization")}</span>,
								children: <CustomizationComponent />
							}
							: null
					]}
				/>
			</div>
		</Fragment>
	);
};

/** ApiKeyEditComponent propTypes
 * PropTypes
 */
ApiKeyEditComponent.propTypes = {
	/** Redux state, represents the name of current editing apiKey  */
	apiKeyName: PropTypes.string,
	/** Redux action to get apiKey General info */
	getApiKeyGeneralInfo: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		apiKeyName: state.apikeys.editingApikey.generalInfo.name
	};
};

const mapDispatchToProps = (dispatch) => ({
	getApiKeyGeneralInfo: (id) => {
		dispatch(getApiKeyGeneralInfo(id));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeyEditComponent);
