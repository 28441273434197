const STREAM_LANGUAGES_CODES = {
	KO: "KOR",
	FR: "FRE",
	PT: "POR",
	RO: "RUM",
	ES: "SPA",
	DE: "GER",
	AR: "ARA",
	TR: "TUR",
	FA: "PER",
	RU: "RUS",
	HY: "ARM",
	EN: "ENG",
	BE: "BEL",
	SR: "SRB",
	EL: "GRC"
};

export { STREAM_LANGUAGES_CODES };
