import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import {
	ACCESS_MANAGERS_ACTION_BEFORE,
	ACCESS_MANAGERS_ACTION_FINISH,
	ACCESS_MANAGERS_ACTION_SET_ACCESS_MANAGERS,
	ACCESS_MANAGERS_SAVE_ACTION_BEFORE,
	ACCESS_MANAGERS_SAVE_ACTION_FINISH,
	ACCESS_MANAGERS_SET_SORTING,
	ACCESS_MANAGERS_SET_FILTERS,
	SET_ACCESS_MANAGER_GENERAL_INFO,
	RESET_EDITING_ACCESS_MANAGER,
	CHANGE_ACCESS_MANAGER_STATE
} from "store/actionTypes";

const setAccessManagersActionBefore = () => ({
	type: ACCESS_MANAGERS_ACTION_BEFORE
});

const setAccessManagersActionFinished = () => ({
	type: ACCESS_MANAGERS_ACTION_FINISH
});

const setAccessManagersSaveActionBefore = () => ({
	type: ACCESS_MANAGERS_SAVE_ACTION_BEFORE
});

const setAccessManagersSaveActionFinished = () => ({
	type: ACCESS_MANAGERS_SAVE_ACTION_FINISH
});

const setAccessManagers = (data) => ({
	type: ACCESS_MANAGERS_ACTION_SET_ACCESS_MANAGERS,
	payload: { data }
});

const setAccessManagerGeneralInfo = (generalInfo) => ({
	type: SET_ACCESS_MANAGER_GENERAL_INFO,
	payload: { generalInfo }
});

export const setAccessManagersSorting = (sorting) => ({
	type: ACCESS_MANAGERS_SET_SORTING,
	payload: { sorting }
});

export const setAccessManagersFilters = (filters) => ({
	type: ACCESS_MANAGERS_SET_FILTERS,
	payload: { filters }
});

const setChangeAccessManagerState = (data) => ({
	type: CHANGE_ACCESS_MANAGER_STATE,
	payload: { data }
});

export const getAccessManagers = (fromFirstPage) => {
	return (dispatch, getState) => {
		dispatch(setAccessManagersActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_ACCESS_MANAGERS}`,
			method: Methods.GET,
			params: {
				...getState().accessManagers.sorting,
				...getState().accessManagers.filters,
				page: fromFirstPage ? 1 : getState().accessManagers.sorting.page
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setAccessManagers(data));
				if (fromFirstPage) {
					dispatch(setAccessManagersSorting({ ...getState().accessManagers.sorting, page: 1 }));
				}
				dispatch(setAccessManagersActionFinished());
			})
			.catch(() => {
				dispatch(setAccessManagersActionFinished());
			});
	};
};

export const resetEditingAccessManager = () => ({
	type: RESET_EDITING_ACCESS_MANAGER
});

export const createAccessManager = (accessManager, onSuccess) => {
	return (dispatch) => {
		dispatch(setAccessManagersSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CREATE_ACCESS_MANAGER}`,
			method: Methods.POST,
			data: accessManager
		})
			.then(({ data: { value } }) => {
				if (typeof onSuccess === "function") {
					onSuccess(value);
				}
			})
			.finally(() => {
				dispatch(setAccessManagersSaveActionFinished());
			});
	};
};

export const getAccessManagerGeneralInfo = (id) => {
	return (dispatch) => {
		dispatch(setAccessManagersActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_ACCESS_MANAGER_GENERAL_INFO}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: info } }) => {
				dispatch(setAccessManagerGeneralInfo(info));
			})
			.finally((ex) => {
				dispatch(setAccessManagersActionFinished());
			});
	};
};

export const saveAccessManagerGeneralInfo = (generalInfo) => {
	return (dispatch) => {
		dispatch(setAccessManagersSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_ACCESS_MANAGER_GENERAL_INFO}`,
			method: Methods.POST,
			data: generalInfo
		})
			.then(({ data: { message: msg, status, value } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setAccessManagerGeneralInfo(value));
				}
			})
			.finally((ex) => {
				dispatch(setAccessManagersSaveActionFinished());
			});
	};
};

export const changeAccessManagerState = (data) => {
	return (dispatch) => {
		dispatch(setAccessManagersActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CHANGE_ACCESS_MANAGER_STATE}`,
			method: Methods.POST,
			data: data
		})
			.then(({ data: { value: data } }) => {
				setTimeout(() => {
					dispatch(setChangeAccessManagerState(data));
				}, 200);
			})
			.finally((ex) => {
				dispatch(setAccessManagersActionFinished());
			});
	};
};
