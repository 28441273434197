import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Tabs } from "antd";

import Breadcrumbs from "components/common/breadcrumbs";
import GeneralInfoComponent from "../edit/sections/general";
import OnlineSettingsComponent from "../edit/sections/online";
import RetailSettingsComponent from "../edit/sections/retail";
import SecuritySettingsComponent from "../edit/sections/security";
import CurrencyComponent from "../edit/sections/currency";
import LanguageComponent from "../edit/sections/language";

import Paths from "constants/path.constants";

import { setGlobalPartnerId, resetPartner } from "store/actions/dashboard/partners/partner.action";
import { getPartnerGeneralInfo } from "store/actions/dashboard/partners/generalInfo.action";

import { PARTNER_TYPE } from "constants/partner.constants";

import { binaryToFlags, updateLocationHash, getHashValue } from "utils/common";
import { hasPermission, hasOneOfPermissions } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

/** Partner Edit Page Component */
const PartnerEditComponent = ({ setGlobalPartnerId, resetPartner, partnerType, partnerName, getPartnerGeneralInfo, globalPartnerId }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const [unsavedTabs, setUnsavedTabs] = useState([]);
	const [activeKey, setActiveKey] = useState("1");

	/** Set current editing partner id as global partner id, reset partner when leaving partner edit page */
	useEffect(() => {
		setGlobalPartnerId(routeParams.id);
		return () => resetPartner();
	}, []);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf PartnerEditComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf PartnerEditComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	/** Is retail type partner
	 * @function
	 * @returns {boolean}
	 * @memberOf PartnerEditComponent
	 */
	const isRetail = () => binaryToFlags(Object.values(PARTNER_TYPE), partnerType).includes(PARTNER_TYPE.RETAIL);

	/** Is online type partner
	 * @function
	 * @returns {boolean}
	 * @memberOf PartnerEditComponent
	 */
	const isOnline = () => binaryToFlags(Object.values(PARTNER_TYPE), partnerType).includes(PARTNER_TYPE.ONLINE);

	/** Load partner general info */
	useEffect(() => {
		if (globalPartnerId === routeParams.id) {
			getPartnerGeneralInfo();
		}
	}, [globalPartnerId, routeParams.id]);

	/** Set default tab */
	useEffect(() => {
		getHashValue("tab") && setActiveKey(getHashValue("tab"));
	}, [partnerType]);

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.partners"), path: Paths.DASHBOARD_PARTNERS }, { title: `${t("common.edit")} ${partnerName}` }]} />
				<Tabs
					animated={false}
					activeKey={activeKey}
					onChange={(key) => {
						setActiveKey(key);
						updateLocationHash("tab=" + key);
					}}
					items={[
						{
							key: "1",
							label: <span className={tabClassName("1")}>{t("pages.dashboard.partners.tabs.general_info")}</span>,
							children: <GeneralInfoComponent onTabChange={(status) => changeTabSavedStatus(status, "1")} />
						},
						isOnline() &&
						hasOneOfPermissions([
							{ resource: PERMISSION_RESOURCE.PARTNER_ONLINE_CONFIGURATION, action: PERMISSION_ACTION.VIEW },
							{ resource: PERMISSION_RESOURCE.PARTNER_ONLINE_RTP, action: PERMISSION_ACTION.VIEW }
						])
							? {
									key: "2",
									label: <span className={tabClassName("2")}>{t("pages.dashboard.partners.tabs.main_online_settings")}</span>,
									children: <OnlineSettingsComponent onTabChange={(status) => changeTabSavedStatus(status, "2")} />
								}
							: null,
						isRetail() && hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_RETAIL_CONFIGURATION, action: PERMISSION_ACTION.VIEW })
							? {
									key: "3",
									label: <span className={tabClassName("3")}>{t("pages.dashboard.partners.tabs.main_retail_settings")}</span>,
									children: <RetailSettingsComponent onTabChange={(status) => changeTabSavedStatus(status, "3")} />
								}
							: null,
						hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_SECURITY_SETTINGS, action: PERMISSION_ACTION.VIEW })
							? {
									key: "4",
									label: <span className={tabClassName("4")}>{t("pages.dashboard.partners.tabs.security_settings")}</span>,
									children: <SecuritySettingsComponent onTabChange={(status) => changeTabSavedStatus(status, "4")} />
								}
							: null,
						hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_CURRENCIES, action: PERMISSION_ACTION.VIEW })
							? {
									key: "5",
									label: <span className={tabClassName("5")}>{t("pages.dashboard.partners.tabs.currency")}</span>,
									children: <CurrencyComponent />
								}
							: null,
						hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_LANGUAGES, action: PERMISSION_ACTION.VIEW })
							? {
									key: "6",
									label: <span className={tabClassName("6")}>{t("pages.dashboard.partners.tabs.language")}</span>,
									children: <LanguageComponent />
								}
							: null
					]}
				/>
			</div>
		</Fragment>
	);
};

/** PartnerEditComponent propTypes
 * PropTypes
 */
PartnerEditComponent.propTypes = {
	/** Redux action to set global partner id */
	setGlobalPartnerId: PropTypes.func,
	/** Redux action to reset partner */
	resetPartner: PropTypes.func,
	/** Redux state, represents the type of current editing partner  */
	partnerType: PropTypes.number,
	/** Redux state, represents the name of current editing partner  */
	partnerName: PropTypes.string,
	/** Redux action to get partner General info */
	getPartnerGeneralInfo: PropTypes.func,
	globalPartnerId: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		partnerType: state.partner.generalInfo.type,
		partnerName: state.partner.generalInfo.name,
		globalPartnerId: state.partner.globalPartnerId
	};
};

const mapDispatchToProps = (dispatch) => ({
	setGlobalPartnerId: (partnerId) => {
		dispatch(setGlobalPartnerId(partnerId));
	},
	resetPartner: () => {
		dispatch(resetPartner());
	},
	getPartnerGeneralInfo: () => {
		dispatch(getPartnerGeneralInfo());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerEditComponent);
