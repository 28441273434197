import { useState, Fragment, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Progress, Upload, message } from 'antd';

const { Dragger } = Upload;

import { DownloadOutlined } from "@ant-design/icons";

import Preview from './preview';
import Question from 'components/common/question';

import { doUploadRequest } from "utils/upload";
import { humanFileSize, downloadURI } from 'utils/common';

/** Banner upload Component */
const BannerAdjustmentUploadComponent = ({
	uploadUrl,
	defaultFile,
	remove,
	data,
	fileBuilder,
	extensions,
	size,
	disabled = false,
	disablePreview,
	onSuccess,
	updateProps,
	allowedDeviationPercent,
	aspectRatio,
	...props
}) => {

	const { t } = useTranslation();

	const [file, setFile] = useState(defaultFile || null);
	const [showPreview, setShowPreview] = useState(false);
	const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);

	/** Update data */
	useEffect(() => {
		setTimeout(() => {
			updateDefaultFile();
		}, 20)
	}, updateProps || [])

	const uploadCancelation = useRef(null);

	/** Fires on preview button click
		  * @function
		  * @param {object} e - event object
		  * @memberOf BannerAdjustmentUploadComponent
	 */
	const handlePreview = e => {
		e.stopPropagation();
		setShowPreview(true)
	}

	/** Fires on remove button click
		  * @function
		  * @param {object} e - event object
		  * @memberOf BannerAdjustmentUploadComponent
	 */
	const handleRemove = e => {
		e.stopPropagation();
		setShowRemoveConfirmation(true);
	}

	/** Fires on download button click
		  * @function
		  * @param {object} e - event object
		  * @memberOf BannerAdjustmentUploadComponent
	 */
	const handleDownload = e => {
		e.stopPropagation();
		const fileURL = file.previewUrl || file.url;
		const fileName = fileURL.split("/")[fileURL.split("/").length - 1];
		downloadURI(fileURL, fileName)
	}

	/** Function to remove the file
		  * @function
		  * @memberOf BannerAdjustmentUploadComponent
	 */
	const removeFile = () => {
		setShowRemoveConfirmation(false);
		remove.handler && remove.handler();
		setFile(null);
	}

	/** Function to update default file
		  * @function
		  * @memberOf BannerAdjustmentUploadComponent
	 */
	const updateDefaultFile = () => {
		setFile(defaultFile);
	}

	/** Function to abort upload
		  * @function
		  * @param {object} e - event object
		  * @memberOf BannerAdjustmentUploadComponent
	 */
	const abortUpload = e => {
		e.stopPropagation();
		if (file.status === "error") {
			setFile(null);
		} else {
			uploadCancelation.current.cancel();
		}
	}

	/** Function to reTry upload
		  * @function
		  * @param {object} e - event object
		  * @memberOf BannerAdjustmentUploadComponent
	 */
	const retryUpload = e => {
		e.stopPropagation();
		uploadCancelation.current = doUploadRequest({
			action: uploadUrl,
			onSuccess: (data, file) => {
				const f = {};
				f.name = file.name;
				f.status = data.status;
				f.fileObject = file.originFileObj;
				f.total = file.size;
				f.percent = 100;
				f.loaded = file.size
				f.url = fileBuilder(data.value).url;
				f.previewUrl = fileBuilder(data.value).previewUrl;
				f.dimensions = fileBuilder(data.value).dimensions;
				f.format = fileBuilder(data.value).format;
				setFile(f);
			},
			onError: () => {
				setFile({
					...file,
					status: "error"
				});
			},
			onProgress: event => {
				const f = {};
				f.name = file.name;
				f.status = "uploading";
				f.fileObject = file.originFileObj;
				f.total = event.total;
				f.loaded = event.loaded;
				f.percent = (f.loaded * 100) / f.total;
				setFile(f);
			},
			file: file.fileObject,
			data
		})
	}

	/** Function which fires on upload start
		  * @function
		  * @param {object} file
		  * @memberOf BannerAdjustmentUploadComponent
	 */
	const onBeforeUpload = file => {
		if (extensions && !extensions.includes(file.type)) {
			message.info(t('errors.message.InvalidFile'));
			setFile(null);
			return false;
		} else if (size && size < file.size) {
			message.info(t('errors.message.InvalidFileSize'));
			setFile(null);
			return false;
		}

		const infoType = file.type.split("/").at(0);
		const fileSrc = window.URL.createObjectURL(file);
		const checkDimensions = (width, height, resolveCallback, rejectCallback) => {
			const currentAspectRatio = (width / height);
			const diff = Math.abs(aspectRatio - currentAspectRatio)
			if (diff > (aspectRatio * allowedDeviationPercent / 100)) {
				const errorObj = new Error(t('errors.message.InvalidFileDimension'))
				message.info(errorObj.message);
				setFile(null)
				rejectCallback(errorObj);
			} else {
				setFile({
					name: file.name,
					total: file.size,
					percent: 0,
					loaded: 0,
					status: "uploading",
					fileObject: file
				})
				resolveCallback(file);
			}

			window.URL.revokeObjectURL(fileSrc)
		}

		return new Promise((resolve, reject) => {
			switch (infoType) {
				case "video":
					const video = document.createElement('video');
					video.addEventListener("loadedmetadata", () => {
						checkDimensions(video.videoWidth, video.videoHeight, resolve, reject)
					}, false);
					video.addEventListener("error", () => {
						message.error( `Error ${video.error.code}; details: ${video.error.message}`)
						window.URL.revokeObjectURL(fileSrc)
						rejectCallback(video.error);
					}, false);
					video.src = fileSrc;
					break;
				case "image":
					const img = new Image();
					img.addEventListener("load", () => {
						checkDimensions(img.width, img.height, resolve, reject)
					}, false);
					img.src = fileSrc;
					break;
				default:
					const errorObj = new Error(t('errors.message.InvalidFile'))
					message.info(errorObj.message);
					setFile(null);
					reject(errorObj)
					window.URL.revokeObjectURL(fileSrc)
					break;
			}

		});

	}

	/** Function which fires during upload
		  * @function
		  * @param {object} info
		  * @memberOf BannerAdjustmentUploadComponent
	 */
	const onChange = info => {
		const f = {};
		f.name = info?.file?.name ?? "";
		f.status = info?.file?.status ?? "";
		f.fileObject = info?.file?.originFileObj ?? {};

		if (f.status === "uploading") {
			f.total = info?.event?.total ?? 0;
			f.loaded = info?.event?.loaded ?? 0;
			f.percent = (f.loaded * 100) / f.total;
		} else if (f.status === "done") {
			const fileResponse = info?.file?.response?.value ?? "";

			f.total = info?.file?.size ?? 0;
			f.percent = 100;
			f.loaded = info?.file?.size ?? 0;
			f.url = fileBuilder(fileResponse).url;
			f.previewUrl = fileBuilder(fileResponse).previewUrl;
			f.dimensions = fileBuilder(fileResponse).dimensions;
			f.format = fileBuilder(fileResponse).format;

			onSuccess && onSuccess(fileResponse);
		} else if (f.status === "error") {
			f.total = file.total;
			f.percent = file.percent;
			f.loaded = file.loaded;
		}
		if (f.status) {
			setFile(f);
		}
	}

	return (
		<Fragment>
			<div {...props} className={"banner-adjustment-upload" + (["uploading", "error"].includes(file?.status) ? " banner-upload-uploading" : "")}>
				<Dragger
					action={uploadUrl}
					multiple={false}
					onChange={onChange}
					beforeUpload={onBeforeUpload}
					disabled={disabled}
					showUploadList={{
						showPreviewIcon: false,
						showDownloadIcon: false,
						showRemoveIcon: false
					}}
					customRequest={({ onSuccess, onError, onProgress, file }) => {
						uploadCancelation.current = doUploadRequest({ action: uploadUrl, onSuccess, onError, onProgress, file, data })
					}}
					extensions={extensions}
				>
					{
						file ?
							file.status === "done" ? (
								<div className="banner-upload-preview">
									<img src={file.url} alt="banner" />
									<div className="banner-upload-preview-actions">
										<div className="banner-upload-preview-actions-button" onClick={handleDownload}>
											<DownloadOutlined />
										</div>
										{
											!disablePreview && (
												<div className="banner-upload-preview-actions-button" onClick={handlePreview}>
													<i className="icon-eye" />
												</div>
											)
										}
										{
											remove && (
												<div className="banner-upload-preview-actions-button" onClick={handleRemove}>
													<i className="icon-remove" />
												</div>
											)
										}

									</div>
								</div>) : file.status === "uploading" || file.status === "error" ? (
									<div className="banner-upload-progress">
										<div className="banner-upload-progress-info">
											<div className="banner-upload-progress-info-text">
												<b>{file.name}</b>
												<span>{`${humanFileSize(file.loaded)} / ${humanFileSize(file.total)}`}</span>
											</div>
											<div className="banner-upload-progress-info-actions">
												{
													file.status === "error" && (
														<div className="banner-upload-progress-info-actions-item" data-action="retry" onClick={retryUpload}>
															<i className="icon-reset" />
														</div>
													)
												}

												<div className="banner-upload-progress-info-actions-item" data-action="abort" onClick={abortUpload}>
													<i className="icon-close" />
												</div>
											</div>
										</div>
										<Progress
											percent={file.percent}
											size="small"
											showInfo={false}
											strokeWidth={3}
											status={file.status === "error" ? "exception" : "normal"}
										/>
									</div>
								) : null
							: <span className="banner-upload-drag-text">{t('common.choose_or_drag_here')}</span>

					}
				</Dragger>
				{
					disabled && !file
						? (
							<span
								className='banner-upload-with-comfirm-trigger'
								style={{ cursor: 'not-allowed' }}
								onDragOver={(e) => e.preventDefault()}
								onDragLeave={(e) => e.preventDefault()}
								onDrop={(e) => e.preventDefault()}
							/>
						)
						: null
				}
			</div>
			{
				showPreview && <Preview file={file} onClose={() => setShowPreview(false)} />
			}

			{
				showRemoveConfirmation && (
					<Question
						type="confirm"
						onOk={removeFile}
						onCancel={() => setShowRemoveConfirmation(false)}
						isVisible={true}
						message={remove.message}
					/>)
			}
		</Fragment>
	)
}

/** BannerAdjustmentUploadComponent propTypes
	 * PropTypes
*/
BannerAdjustmentUploadComponent.propTypes = {
	/** Upload URL */
	uploadUrl: PropTypes.string,
	/** Default file */
	defaultFile: PropTypes.shape({
		/** File url */
		url: PropTypes.string,
		/** File dimensions */
		dimensions: PropTypes.string,
		/** File name */
		name: PropTypes.string,
		/** File status */
		status: PropTypes.oneOf(["uploading", "done", "error"]),
		/** File uploaded percent */
		percent: PropTypes.number,
		/** File loaded part size */
		loaded: PropTypes.number,
		/** File total size */
		total: PropTypes.number,
		/** File file object */
		fileObject: PropTypes.object,
		/** File format */
		format: PropTypes.string,
		/** File preview url */
		previewUrl: PropTypes.string,
	}),
	/** Function which will fire on remove button click */
	remove: PropTypes.shape({
		/** Remove Confirmation popup message */
		message: PropTypes.string,
		/** Remove handler */
		handler: PropTypes.func
	}),
	/** Files data of upload */
	data: PropTypes.object,
	/** Function which will build file url from response */
	fileBuilder: PropTypes.func,
	/** Files extension allowed for upload */
	extensions: PropTypes.arrayOf(PropTypes.string),
	/** Max file size allowed for upload */
	size: PropTypes.number,
	/** Should upload be disabled */
	disabled: PropTypes.bool,
	/** If true, the preview will not be available */
	disablePreview: PropTypes.bool,
	/** Fires on upload success */
	onSuccess: PropTypes.func,
	/** Array of props, which update will triger data load */
	updateProps: PropTypes.array,
	/** Allowed Deviation Percent for aspect ratio */
	allowedDeviationPercent: PropTypes.number,
	/** Allowed aspect ratio */
	aspectRatio: PropTypes.number
}


export default BannerAdjustmentUploadComponent;