import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Paths from "constants/path.constants";
import { PROJECT_TYPE } from "constants/common.constants";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { FILTER_REPORT_BONUS_TYPE } from "constants/filter.constants";

import { hasPermission, hasOneOfPermissions } from "utils/permissions";

import WidgetSkeleton from "components/dashboard/statistics/widgets/skeleton";

/** Dashboard Page Total Betslips Widget Component */
const TotalBonusBetsWidgetComponent = ({ onlineBonusBetsCount, retailBonusBetsCount, projectType, filters: { projectId, period }, isGamesPerformanceLoading }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	/** Generate redirection path hashs
	 * @function
	 * @memberOf TotalBonusBetsWidgetComponent
	 */
	const generateRedirectionPathHash = () => {
		let hash = projectType === PROJECT_TYPE.RETAIL ? "#tab=2&subtab=2" : "";
		if (!hasPermission({ resource: PERMISSION_RESOURCE.PENDING_BETSLIP, action: PERMISSION_ACTION.VIEW })) {
			if (hash === "") {
				hash = "#subtab=2";
			}
		}

		return hash;
	};

	const generatePath = () => {
		return projectType === PROJECT_TYPE.ONLINE ? Paths.DASHBOARD_BETSLIPS_ONLINE : Paths.DASHBOARD_BETSLIPS_RETAIL;
	};

	const generateQueryParams = () => (
		projectType === PROJECT_TYPE.RETAIL ?
			`?projectType=${projectType}&period=${period}&projectId=${projectId ? projectId : "all"}&betType=${FILTER_REPORT_BONUS_TYPE.ONLY_BONUS}` : ""
	);

	const bonusBetsCount = (
		projectType === PROJECT_TYPE.ONLINE
			? onlineBonusBetsCount
			: retailBonusBetsCount
	) ?? 0

	return (
		<div className="dashboard-area" data-type="totalBonusBetsCount">
			<div className="dashboard-area-header">
				<i className="icon-retailBonus" />
				<span className="dashboard-area-header-title">{t("pages.dashboard.dashboard.total_bonuses")}</span>
			</div>
			<div className="dashboard-area-content">
				{isGamesPerformanceLoading ? (
					<WidgetSkeleton variation="total" />
				) : (
					<span title={bonusBetsCount} className="dashboard-area-content-value">
						{bonusBetsCount}
					</span>
				)}
				{hasOneOfPermissions([
					{ resource: PERMISSION_RESOURCE.PENDING_BETSLIP, action: PERMISSION_ACTION.VIEW },
					{ resource: PERMISSION_RESOURCE.SETTLED_BETSLIP, action: PERMISSION_ACTION.VIEW }
				]) && <i className="icon-right" onClick={() => navigate(`${generatePath()}${generateQueryParams()}${generateRedirectionPathHash()}`)} />}
			</div>
		</div>
	);
};

/** TotalBonusBetsWidgetComponent propTypes
 * PropTypes
 */
TotalBonusBetsWidgetComponent.propTypes = {
	/** Redux state property, the count of online bonuses */
	onlineBonusBetsCount: PropTypes.number,
	/** Redux state property, the count of retail bonuses */
	retailBonusBetsCount: PropTypes.number,
	/** Project type(Online/ Retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	/** Redux state property, dashboard filters*/
	filters: PropTypes.object,
	// Redux state controls games performance loading indicator
	isGamesPerformanceLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		onlineBonusBetsCount: state.dashboard.online.totalBonusBetsCount,
		retailBonusBetsCount: state.dashboard.retail.totalBonusBetsCount,
		filters: state.dashboard.retail.filters,
		isGamesPerformanceLoading: state.dashboard.isGamesPerformanceLoading
	};
};

export default connect(mapStateToProps, null)(TotalBonusBetsWidgetComponent);
