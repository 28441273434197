import { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GAME_CATEGORY, SCHEDULED_GAME_TYPE } from 'constants/game.constants';
import PenaltyBannersAdjustment from './penaltyBannersAdjustment';
import SteeplechasingBannersAdjustment from './steeplechasingBannersAdjustment';

/** Game Edit Page Banners Tab Component */
const BannersAdjustmentComponent = ({ gameType, gameCategory }) => {

	switch (gameType) {
		case SCHEDULED_GAME_TYPE.STEEPLECHASING.value:
			return <SteeplechasingBannersAdjustment  gameType={gameType} gameCategory={gameCategory} />;
		case SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value:
			return <PenaltyBannersAdjustment  gameType={gameType} gameCategory={gameCategory} />;
		default:
			break;
	}

	return null
}

/** BannersAdjustmentComponent propTypes
	 * PropTypes
*/
BannersAdjustmentComponent.propTypes = {
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY)),
	/** Redux state property, represents game type */
	gameType: PropTypes.oneOf(Object.values(SCHEDULED_GAME_TYPE).map(sgt => sgt.value))
}

export default BannersAdjustmentComponent
