import {
	GAMES_BEFORE,
	GAMES_FINISH,
	GAME_BEFORE,
	GAME_FINISH,
	SET_GAMES,
	CHANGE_GAME_STATE,
	DELETE_GAME,
	SET_GAMES_SORTING,
	SET_GAMES_FILTERS,
	SET_GAME_DETAILS,
	SET_GAME_GENERAL_INFO,
	RESET_GAME_GENERAL_INFO,
	SET_GAME_LIVE_MONITOR_LOGO_ID,
	SET_GAME_STREAM_URL,
	SET_GAME_FOOTBALL_SCENE_PROBABILITIES,
	SET_GAME_FOOTBALL_HANDICAP_PROBABILITY,
	SET_GAME_STRENGTHS,
	SET_GAME_COMPETITIONS_AND_RACES,
	SET_GAME_FOOTBALL_TEAMS,
	SET_GAME_RTPS,
	SET_GAME_BANNERS,
	SET_GAME_STREAM_CONFIGURATION,
	SET_PENALTY_GOAL_MISS_PROBABILITIES,
	SET_GAME_PENALTY_TEAMS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case GAMES_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case GAMES_FINISH:
			return {
				...state,
				isLoading: false
			};
		case GAME_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case GAME_FINISH:
			return {
				...state,
				isSaving: false
			};
		case SET_GAMES:
			return {
				...state,
				games: payload.games.item2,
				total: payload.games.item1
			};

		case SET_GAME_DETAILS:
			return {
				...state,
				games: state.games.map((g) => (g.id === payload.id ? { ...g, details: payload.details } : g))
			};
		case DELETE_GAME:
			return {
				...state,
				games: state.games.filter((g) => g.id !== payload.id)
			};
		case CHANGE_GAME_STATE:
			return {
				...state,
				games: state.games.map((g) => (g.id !== payload.data.id ? { ...g } : { ...g, ...payload.data }))
			};
		case SET_GAMES_SORTING:
			return {
				...state,
				sorting: payload.sorting
			};
		case SET_GAMES_FILTERS:
			return {
				...state,
				filters: {
					...state.filters,
					[payload.gameCategory]: payload.filters
				},
				sorting: {
					...state.sorting,
					page: 1
				}
			};
		case SET_GAME_GENERAL_INFO:
			return {
				...state,
				editingGame: {
					...state.editingGame,
					generalInfo: {
						...payload.info
					}
				}
			};
		case RESET_GAME_GENERAL_INFO:
			return {
				...state,
				editingGame: {
					...state.editingGame,
					generalInfo: {
						partnerName: "",
						sceneCount: 6,
						activeGameCount: 10,
						closeBetSeconds: 5,
						participants: [],
						name: ""
					}
				}
			};
		case SET_GAME_LIVE_MONITOR_LOGO_ID:
			return {
				...state,
				editingGame: {
					...state.editingGame,
					generalInfo: {
						...state.editingGame.generalInfo,
						liveMonitorLogoId: payload.logoId
					}
				}
			};
		case SET_GAME_FOOTBALL_SCENE_PROBABILITIES:
			return {
				...state,
				editingGame: {
					...state.editingGame,
					probabilities: {
						...state.editingGame.probabilities,
						footballSceneProbabilities: payload.probabilities
					}
				}
			};
		case SET_PENALTY_GOAL_MISS_PROBABILITIES:
			return {
				...state,
				editingGame: {
					...state.editingGame,
					probabilities: {
						...state.editingGame.probabilities,
						penaltyGoalMissProbabilities: payload.probabilities
					}
				}
			};
		case SET_GAME_FOOTBALL_HANDICAP_PROBABILITY:
			return {
				...state,
				editingGame: {
					...state.editingGame,
					probabilities: {
						...state.editingGame.probabilities,
						footballHandicapProbability: payload.probability
					}
				}
			};

		case SET_GAME_FOOTBALL_TEAMS:
			return {
				...state,
				editingGame: {
					...state.editingGame,
					probabilities: {
						...state.editingGame.probabilities,
						footballTeams: payload.data
					}
				}
			};
		case SET_GAME_PENALTY_TEAMS:
			return {
				...state,
				editingGame: {
					...state.editingGame,
					probabilities: {
						...state.editingGame.probabilities,
						penaltyTeams: payload.data
					}
				}
			};
		case SET_GAME_STRENGTHS:
			return {
				...state,
				editingGame: {
					...state.editingGame,
					probabilities: {
						...state.editingGame.probabilities,
						strengths: payload.strengths
					}
				}
			};
		case SET_GAME_COMPETITIONS_AND_RACES:
			return {
				...state,
				editingGame: {
					...state.editingGame,
					probabilities: {
						...state.editingGame.probabilities,
						competitionsAndRaces: payload.data
					}
				}
			};
		case SET_GAME_RTPS:
			return {
				...state,
				editingGame: {
					...state.editingGame,
					rtp: payload.rtp
				}
			};
		case SET_GAME_BANNERS:
			return {
				...state,
				editingGame: {
					...state.editingGame,
					banners: payload.banners
				}
			};
		case SET_GAME_STREAM_CONFIGURATION:
			return {
				...state,
				editingGame: {
					...state.editingGame,
					streamConfiguration: payload.configuration
				}
			};
		default:
			return state;
	}
};
