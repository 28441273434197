import { Fragment, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";

import { Row, Col, Spin, Alert, Divider } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import Breadcrumbs from "components/common/breadcrumbs";
import ErrorsTableComponent from "./errors-table";

import { getMonitoringService, getServiceErrors } from "store/actions/dashboard/developer/monitoring/monitoring.action";

import monitoringServiceType from "types/monitoring/monitoringService.type";
import errorLogType from "types/log/error.type";

import { toUpperCaseFirstLetter } from "utils/common";

const getPieChartsDefaultOptions = (seriestext, titleText, data) => {
	const defOption = {
		accessibility: {
			enabled: false
		},
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: "pie"
		},
		series: [
			{
				name: seriestext,
				colorByPoint: true,
				data,
				fillColor: "#F0F0F0"
			}
		],
		title: {
			text: titleText
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: "pointer",
				size: 250,
				dataLabels: {
					enabled: false
				}
			}
		}
	};
	if (data.some((elem) => elem.y > 0)) {
		defOption.plotOptions.pie.dataLabels = {
			enabled: true,
			format: "<b>{point.name}</b>: {point.y}"
		};
	}
	return defOption;
};

/**Monitoring Page Component */
const MonitoringPage = ({ services, service, isLoading, errors, getMonitoringService, getServiceErrors }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [barChartData, setBarChartData] = useState({});
	const [lastHourPieChartData, setLastHourPieChartData] = useState([]);
	const [lastDayPieChartData, setLastDayPieChartData] = useState([]);
	const [chartDataLoaded, setChartDataLoaded] = useState(false);

	/** Load monitoring data */
	useEffect(() => {
		if (services.length > 0) {
			const s = services.find((s) => s.name === routeParams.name);
			if (s && s.id) {
				getMonitoringService(s.id);
			}
		}
	}, [services, routeParams.name]);

	useEffect(() => {
		if (Object.keys(service).length) {
			handleBarChartData();
			handlePieChartData("last_hour");
			handlePieChartData("last_day");
			getServiceErrors(service.name);
			setTimeout(() => {
				setChartDataLoaded(true);
			}, 500);
		}
	}, [service]);

	/** function to make data for BarChart
	 * @function
	 * @memberOf MonitoringPageComponent
	 */
	const handleBarChartData = () => {
		const colors = ["#722ed1", "#1890ff", "#eb2f96", "#fa8c16", "#fadb14", "#2f54eb", "#faad14"];

		const result = {
			categories: [],
			series: []
		};
		if (service.metrics) {
			Object.keys(service.metrics).forEach((d) => {
				result.categories.push(moment.utc(d, "DD.MM HH").local().format("HH:mm"));
				Object.keys(service.metrics[d]).forEach((m) => {
					if (!result.series.find((s) => s.name.toLowerCase() === m.toLowerCase())) {
						let col = null;
						if (m.toLowerCase() === "success") {
							col = "#90ed7d";
						} else if (m.toLowerCase() === "failed") {
							col = "#FF0000";
						} else {
							if (colors.length > 0) {
								col = colors[0];
								colors.shift();
							}
						}
						result.series.push({
							name: toUpperCaseFirstLetter(m),
							data: [],
							color: col
						});
					}

					result.series.find((s) => s.name.toLowerCase() === m.toLowerCase()).data.push(service.metrics[d][m]);
				});
			});
		}
		setBarChartData(result);
	};

	/** function to make data for PieChart
	 * @function
	 * @param {string } type - last_hour/last_day
	 * @memberOf MonitoringPageComponent
	 */
	const handlePieChartData = (type) => {
		const colors = ["#722ed1", "#1890ff", "#eb2f96", "#fa8c16", "#fadb14", "#2f54eb", "#faad14"];
		const result = [];
		if (service.metrics) {
			if (type === "last_hour") {
				const keys = Object.keys(service.metrics);
				if (keys.length) {
					const lastHourMetricKey = keys[keys.length - 1];
					const lastHourMetric = service.metrics[lastHourMetricKey];
					Object.keys(lastHourMetric).forEach((metric) => {
						let col = null;
						if (metric.toLowerCase() === "success") {
							col = "#90ed7d";
						} else if (metric.toLowerCase() === "failed") {
							col = "#FF0000";
						} else {
							if (colors.length > 0) {
								col = colors[0];
								colors.shift();
							}
						}
						result.push({
							name: toUpperCaseFirstLetter(metric),
							y: lastHourMetric[metric],
							color: col
						});
					});
				}
			} else if (type === "last_day") {
				const keys = Object.keys(service.metrics);
				if (keys.length) {
					const lastHourMetricKey = keys[keys.length - 1];
					const lastHourMetric = service.metrics[lastHourMetricKey];
					Object.keys(lastHourMetric).forEach((metric) => {
						let col = null;
						if (metric.toLowerCase() === "success") {
							col = "#90ed7d";
						} else if (metric.toLowerCase() === "failed") {
							col = "#FF0000";
						} else {
							if (colors.length > 0) {
								col = colors[0];
								colors.shift();
							}
						}
						result.push({
							name: toUpperCaseFirstLetter(metric),
							y: Object.values(service.metrics)
								.map((el) => el[metric])
								.reduce((a, b) => a + b, 0),
							color: col
						});
					});
				}
			}
		}
		if (type === "last_hour") {
			setLastHourPieChartData(result);
		} else if (type === "last_day") {
			setLastDayPieChartData(result);
		}
	};

	const lastHourPieChartDataOptions = useMemo(() => {
		return getPieChartsDefaultOptions(t("pages.dashboard.monitoring.count"), t("pages.dashboard.monitoring.last_hour"), lastHourPieChartData);
	}, [lastHourPieChartData]);

	const lastDayPieChartDataOptions = useMemo(() => {
		return getPieChartsDefaultOptions(t("pages.dashboard.monitoring.count"), t("pages.dashboard.monitoring.last_24_hour"), lastDayPieChartData);
	}, [lastDayPieChartData]);

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: (service?.name ?? "").replace("Service", " Service") }]} />
				<div className="dashboard-section-content">
					<Spin spinning={isLoading && !chartDataLoaded}>
						{service.name && service.totalFailed > 10 ? (
							<Alert message={t("pages.dashboard.monitoring.warning")} description={`${(service?.name ?? "").replace("Service", " Service")} ${t("pages.dashboard.monitoring.warning_msg").replace("%COUNT%", service.totalFailed)}`} type="warning" showIcon style={{ marginBottom: "20px" }} />
						) : null}
						{chartDataLoaded && (
							<Fragment>
								<HighchartsReact
									highcharts={Highcharts}
									options={{
										accessibility: {
											enabled: false
										},
										chart: {
											type: "column"
										},
										xAxis: {
											crosshair: true,
											categories: barChartData.categories
										},
										yAxis: {
											min: 0
										},
										series: barChartData.series,
										title: {
											text: (service?.name ?? "").replace("Service", " Service")
										}
									}}
								/>
								<Divider />
								<Row>
									<Col xs={24} sm={24} md={24} lg={12}>
										<HighchartsReact highcharts={Highcharts} options={lastHourPieChartDataOptions} />
									</Col>
									<Col xs={24} sm={24} md={24} lg={12}>
										<HighchartsReact highcharts={Highcharts} options={lastDayPieChartDataOptions} />
									</Col>
								</Row>
							</Fragment>
						)}
					</Spin>
				</div>
				{errors.length ? <ErrorsTableComponent errors={errors} /> : null}
			</div>
		</Fragment>
	);
};

/** MonitoringPage propTypes
 * PropTypes
 */
MonitoringPage.propTypes = {
	/** Redux action to get data of monitoring service */
	getMonitoringService: PropTypes.func,
	/** Redux state property, data of current viewing monitoring service */
	service: monitoringServiceType,
	/** Redux state property, array of all monitoring services in system */
	services: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string
		})
	),
	/** Redux state property, is true when loading monitoring service data */
	isLoading: PropTypes.bool,
	/** Redux action to get error logs for service */
	getServiceErrors: PropTypes.func,
	/** Redux state property, represents the array of error logs of service */
	errors: PropTypes.arrayOf(errorLogType)
};

const mapDispatchToProps = (dispatch) => ({
	getMonitoringService: (id) => {
		dispatch(getMonitoringService(id));
	},
	getServiceErrors: (name) => {
		dispatch(getServiceErrors(name));
	}
});

const mapStateToProps = (state) => {
	return {
		service: state.monitoring.service,
		services: state.monitoring.services,
		isLoading: state.monitoring.isLoading,
		errors: state.errors.errors
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringPage);
