import i18n from "translations/config";

import { PARTNER_SECURITY_PASSWORD_PATTERN, PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE, PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS, PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_REGEXP } from "constants/partner.constants";

const lengthChecking = (value, passwordSettings) => value.length < passwordSettings.passwordMinLimit || value.length > passwordSettings.passwordMaxLimit;

/** Validate function for password
 * @function
 * @param {string} value - value of password field
 * @param {object} passwordSettings - password validation settings
 */
export const validatePasword = (value, passwordSettings) => {
	if (lengthChecking(value, passwordSettings)) {
		return Promise.reject(i18n.t("pages.dashboard.profile.password_rules.must_be_between").replace("%X%", passwordSettings.passwordMinLimit).replace("%Y%", passwordSettings.passwordMaxLimit));
	}

	let result = false;
	const pattern = passwordSettings?.pattern ?? {};
	const patternArray = pattern.contain ?? [];
	const alsoIncludeCharacters = pattern?.alsoIncludeCharacters ?? "";
	const reg = new RegExp(pattern?.regularExpression ?? "", "g");

	switch (pattern?.type) {
		case PARTNER_SECURITY_PASSWORD_PATTERN.CONTAINS:
			for (let i = 0; i < patternArray.length; i++) {
				result = PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_REGEXP[patternArray[i].type].test(value);
				if (result && patternArray[i].value === PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE.NONE) {
					return Promise.reject(i18n.t("pages.dashboard.profile.password_rules.must_not_contain").replace("%X%", i18n.t(`pages.dashboard.partners.security_settings.password_settings.contain_type_${patternArray[i].type}`)));
				} else if (!result && patternArray[i].value === PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE.MUST) {
					return Promise.reject(i18n.t("pages.dashboard.profile.password_rules.must_contain").replace("%X%", i18n.t(`pages.dashboard.partners.security_settings.password_settings.contain_type_${patternArray[i].type}`)));
				}
			}
			if (!alsoIncludeCharacters.split("").every((char) => value.includes(char))) {
				return Promise.reject(i18n.t("pages.dashboard.profile.password_rules.must_include").replace("%X%", alsoIncludeCharacters));
			}
			break;
		case PARTNER_SECURITY_PASSWORD_PATTERN.PATTERN:
			if (!reg.test(value)) {
				return Promise.reject(i18n.t("pages.dashboard.profile.password_rules.must_match_regular_expression").replace("%X%", pattern?.regularExpression ?? ""));
			}
			break;
		default:
			break;
	}

	return Promise.resolve();
};

//-----------------------2FA PASSWORD INPUTS-------------------------//

export const getFirstEmptyInputIndex = (passwordArr) => {
	return passwordArr.findIndex((inputValue) => !inputValue);
};

export const isPasswordInputsFilled = (passwordArr) => {
	for (const character of passwordArr) {
		if (!character) {
			return false;
		}
	}

	return true;
};

export const isPasswordEmpty = (passwordArr) => {
	return !passwordArr.some((item) => Boolean(item));
};

//-------------------------------------------------------------------//
