import { Tabs } from "antd";
import Breadcrumbs from "components/common/breadcrumbs";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { getHashValue } from "utils/common";
import Paths from "constants/path.constants";
import BigMultipliersHistory from "./history";
import BigMultipliersConfigurations from "./configurations";
import { hasPermission } from "utils/permissions";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

const TABS = {
	HISTORY: "1",
	CONFIGURATIONS: "2"
}
const BigMultipliers = () => {

	const navigate = useNavigate();
	const { hash } = useLocation();
	const { t } = useTranslation();
	const [activeKey, setActiveKey] = useState(() => {
		return getHashValue("tab") || (
			hasPermission({ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BIG_MULTIPLIERS_HISTORY, action: PERMISSION_ACTION.VIEW })
				? TABS.HISTORY
				: hasPermission({ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BIG_MULTIPLIERS_CONFIGURATIONS, action: PERMISSION_ACTION.VIEW })
					? TABS.CONFIGURATIONS
					: null
		)
	});
	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Update active tab on location hash change */
	useEffect(() => {
		setActiveKey(getHashValue("tab") || (
			hasPermission({ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BIG_MULTIPLIERS_HISTORY, action: PERMISSION_ACTION.VIEW })
				? TABS.HISTORY
				: hasPermission({ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BIG_MULTIPLIERS_CONFIGURATIONS, action: PERMISSION_ACTION.VIEW })
					? TABS.CONFIGURATIONS
					: null
		));
	}, [hash]);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf RetailSettingsComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf RetailSettingsComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.risk_management"), path: "" }, { title: t("pages.dashboard.menu.big_multipliers"), path: "" }]} />
				<Tabs
					activeKey={activeKey}
					onChange={(key) => {
						navigate(`${Paths.DASHBOARD_RISK_MANAGEMENT_BIG_MULTIPLIERS}#tab=${key}`);
					}}
					items={[
						(
							hasPermission({ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BIG_MULTIPLIERS_HISTORY , action: PERMISSION_ACTION.VIEW })
								? (
									{
										key: TABS.HISTORY,
										label: <span className={tabClassName(TABS.HISTORY)}>{t(`pages.dashboard.risk_management.big_multipliers_history`)}</span>,
										children: <BigMultipliersHistory onTabChange={(status) => changeTabSavedStatus(status, TABS.HISTORY)} />
									}
								)
								: null
						),
						(
							hasPermission({ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BIG_MULTIPLIERS_CONFIGURATIONS , action: PERMISSION_ACTION.VIEW })
								? (
									{
										key: TABS.CONFIGURATIONS,
										label: <span className={tabClassName(TABS.CONFIGURATIONS)}>{t(`pages.dashboard.risk_management.big_multipliers_configurations`)}</span>,
										children: <BigMultipliersConfigurations onTabChange={(status) => changeTabSavedStatus(status, TABS.CONFIGURATIONS)} />
									}
								)
								: null
						)
					]}
				/>
			</div>
		</Fragment>
	);
};

export default BigMultipliers;
