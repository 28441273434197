import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Button, Spin } from "antd";
const { Item: FormItem } = Form;

import TicketConfigComponent from "../ticket-config";

import { getBetTicket, saveBetTicket, setCurrentBetTicket } from "store/actions/dashboard/cms/tickets/ticket.action";

import { flagsToBinary, binaryToFlags } from "utils/common";
import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { TICKET_GENERAL_DETAILS, TICKET_MARKET_DETAILS } from "constants/partner.constants";
import { TICKET_TYPE } from "constants/ticket.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import ticketType from "types/partner/ticket.type";
import betTicketType from "types/partner/betTicket.type";

/** Partner Edit Page Retail Tab Bet Ticket subTab Component */
const BetTicketComponent = ({ isSaving, isLoading, currentTicket, ticket, updateCurrentBetTicket, globalPartnerId, getBetTicket, saveBetTicket, onTabChange }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);

	/** Load partner bet ticket */
	useEffect(() => {
		globalPartnerId && getBetTicket();
	}, [globalPartnerId]);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			...ticket,
			generalDetails: binaryToFlags(Object.values(TICKET_GENERAL_DETAILS), ticket.generalDetails),
			marketSelection: binaryToFlags(Object.values(TICKET_MARKET_DETAILS), ticket.marketSelection)
		});
	}, [ticket]);

	/** Fires when form submitted
	 * @function
	 * @memberOf BetTicketComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				saveBetTicket({
					...data,
					generalDetails: flagsToBinary(data.generalDetails),
					marketSelection: flagsToBinary(data.marketSelection)
				});
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	/** Check is form changed
	 * @function
	 * @param {object} formValues - form current values
	 * @returns {boolean}
	 * @memberOf BetTicketComponent
	 */
	const formChanged = (formValues) => {
		if (formValues.generalDetails) formValues.generalDetails = flagsToBinary(formValues.generalDetails);
		if (formValues.marketSelection) formValues.marketSelection = flagsToBinary(formValues.marketSelection);
		return isFormChanged(formValues, { ...ticket });
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	/** Fires when any field value is changed in TicketConfigComponent component
	 * @function
	 * @param {string} field - field name
	 * @param {string} value - field value
	 * @memberOf BetTicketComponent
	 */
	const onTicketChange = (field, val) => {
		updateCurrentBetTicket({
			...currentTicket,
			[field]: field === "generalDetails" || field === "marketSelection" ? flagsToBinary(val) : val
		});
	};

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					enableBarcodePrinting: ticket.enableBarcodePrinting,
					enableQRPrinting: ticket.enableQRPrinting,
					generalDetails: binaryToFlags(Object.values(TICKET_GENERAL_DETAILS), ticket.generalDetails),
					marketSelection: binaryToFlags(Object.values(TICKET_MARKET_DETAILS), ticket.marketSelection),
					customText: ticket.customText
				}}
				onValuesChange={(changed, formValues) => setIsFormTouched(formChanged({ ...formValues }))}
			>
				<div className="dashboard-section-content">
					<TicketConfigComponent currentTicket={currentTicket} onTicketChange={onTicketChange} type={TICKET_TYPE.BET} ticket={ticket} />

					{hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_TICKETS, action: PERMISSION_ACTION.MODIFY }) && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** BetTicketComponent propTypes
 * PropTypes
 */
BetTicketComponent.propTypes = {
	/** Redux state property, is true when bet ticket is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when loading bet ticket data */
	isLoading: PropTypes.bool,
	/** Redux state, represents the current editing ticket(ticket in preview)  */
	currentTicket: ticketType,
	/** Redux state, represents the current loaded ticket(ticket in server)  */
	ticket: betTicketType,
	/** Redux action to update current ticket(ticket in preview) */
	updateCurrentBetTicket: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux action to save partner bet ticket */
	saveBetTicket: PropTypes.func,
	/** Redux action to get partner bet ticket */
	getBetTicket: PropTypes.func,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isSaving: state.tickets.isSaving,
		isLoading: state.tickets.isLoading,
		currentTicket: state.tickets.currentBetTicket,
		ticket: state.tickets.betTicket,
		globalPartnerId: state.partner.globalPartnerId
	};
};

const mapDispatchToProps = (dispatch) => ({
	updateCurrentBetTicket: (ticket) => {
		dispatch(setCurrentBetTicket(ticket));
	},
	getBetTicket: () => {
		dispatch(getBetTicket());
	},
	saveBetTicket: (data) => {
		dispatch(saveBetTicket(data));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BetTicketComponent);
