import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Tabs } from "antd";

import { useTranslation } from "react-i18next";

import PermissionsComponent from "./permissions";
import PermissionGroupsComponent from "./permissionGroups";

import { updateLocationHash, getHashValue } from "utils/common";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

/** User Edit Page Permission And Groups Settings Tab Component */
const PermissionsAndGroupsComponent = ({ onTabChange }) => {
	const { t } = useTranslation();

	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf PermissionsAndGroupsComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	useEffect(() => {
		onTabChange(unsavedTabs.length > 0);
	}, [unsavedTabs]);

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf PermissionsAndGroupsComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	return (
		<Tabs
			animated={false}
			className="sub-tabs"
			defaultActiveKey={getHashValue("subtab")}
			onChange={(key) => updateLocationHash("subtab=" + key, true)}
			items={[
				hasPermission({ resource: PERMISSION_RESOURCE.USER_PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW })
					? {
							key: "1",
							label: <span className={tabClassName("1")}>{t("pages.dashboard.users.tabs.groups")}</span>,
							children: <PermissionGroupsComponent onTabChange={(status) => changeTabSavedStatus(status, "1")} />
						}
					: null,
				hasPermission({ resource: PERMISSION_RESOURCE.USER_PERMISSION, action: PERMISSION_ACTION.VIEW })
					? {
							key: "2",
							label: <span className={tabClassName("2")}>{t("pages.dashboard.users.tabs.permissions")}</span>,
							children: <PermissionsComponent onTabChange={(status) => changeTabSavedStatus(status, "2")} />
						}
					: null
			]}
		/>
	);
};

/** PermissionsAndGroupsComponent propTypes
 * PropTypes
 */
PermissionsAndGroupsComponent.propTypes = {
	/** Fires when for any form in tab, saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

export default PermissionsAndGroupsComponent;
