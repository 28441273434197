import {
	DASHBOARD_SET_APIKEY_NAMES,
	DASHBOARD_SET_APIKEY_NAMES_BEFORE,
	DASHBOARD_SET_APIKEY_NAMES_FINISH,
	DASHBOARD_SET_BETSHOP_NAMES,
	DASHBOARD_SET_BETSHOP_NAMES_BEFORE,
	DASHBOARD_SET_BETSHOP_NAMES_FINISH,
	DASHBOARD_PLAYERS_ACTIVITIES_BEFORE,
	DASHBOARD_PLAYERS_ACTIVITIES_FINISH,
	DASHBOARD_SET_PLAYERS_ACTIVITIES,
	DASHBOARD_SET_ONLINE_PLAYERS_COUNT_BEFORE,
	DASHBOARD_SET_ONLINE_PLAYERS_COUNT,
	DASHBOARD_SET_PENDING_BETSLIPS_COUNT_BEFORE,
	DASHBOARD_SET_PENDING_BETSLIPS_COUNT,
	DASHBOARD_BETSLIPS_ACTIVITIES_BEFORE,
	DASHBOARD_BETSLIPS_ACTIVITIES_FINISH,
	DASHBOARD_SET_BETSLIPS_ACTIVITIES,
	DASHBOARD_TOP_MARKETS_BEFORE,
	DASHBOARD_TOP_MARKETS_FINISH,
	DASHBOARD_SET_TOP_MARKETS,
	DASHBOARD_GAMES_PERFORMANCE_BEFORE,
	DASHBOARD_GAMES_PERFORMANCE_FINISH,
	DASHBOARD_SET_GAMES_PERFORMANCE,
	DASHBOARD_TOP_PLAYERS_BEFORE,
	DASHBOARD_TOP_PLAYERS_FINISH,
	DASHBOARD_SET_TOP_PLAYERS_SORTING,
	DASHBOARD_SET_TOP_PLAYERS,
	DASHBOARD_SET_FILTERS,
	DASHBOARD_PARTNERS_REVENUE_BEFORE,
	DASHBOARD_PARTNERS_REVENUE_FINISH,
	DASHBOARD_SET_PARTNERS_REVENUE,
	DASHBOARD_SET_PARTNERS_REVENUE_FILTER,
	DASHBOARD_CALCULATE_GAMES_PERFORMANCE
} from "../../actionTypes";

import { PROJECT_TYPE } from "constants/common.constants";

export default (state = {}, { type, payload }) => {
	let pt = payload ? (payload.projectType === PROJECT_TYPE.ONLINE ? "online" : "retail") : null;

	switch (type) {
		case DASHBOARD_SET_APIKEY_NAMES:
			return {
				...state,
				online: {
					...state.online,
					apiKeyNames: payload.data
				}
			};
		case DASHBOARD_SET_APIKEY_NAMES_BEFORE:
			return {
				...state,
				online: {
					...state.online,
					isApiKeyNamesLoading: true
				}
			};
		case DASHBOARD_SET_APIKEY_NAMES_FINISH:
			return {
				...state,
				online: {
					...state.online,
					isApiKeyNamesLoading: false
				}
			};
		case DASHBOARD_SET_BETSHOP_NAMES:
			return {
				...state,
				retail: {
					...state.retail,
					betshopNames: payload.data
				}
			};
		case DASHBOARD_SET_BETSHOP_NAMES_BEFORE:
			return {
				...state,
				retail: {
					...state.retail,
					isBetshopNamesLoading: true
				}
			};
		case DASHBOARD_SET_BETSHOP_NAMES_FINISH:
			return {
				...state,
				retail: {
					...state.retail,
					isBetshopNamesLoading: false
				}
			};
		case DASHBOARD_PLAYERS_ACTIVITIES_BEFORE:
			return {
				...state,
				online: {
					...state.online,
					isPlayerActivitiesLoading: true
				}
			};
		case DASHBOARD_PLAYERS_ACTIVITIES_FINISH:
			return {
				...state,
				online: {
					...state.online,
					isPlayerActivitiesLoading: false
				}
			};
		case DASHBOARD_SET_PLAYERS_ACTIVITIES:
			return {
				...state,
				online: {
					...state.online,
					playerActivities: payload.data.item2,
					playersTotal: payload.data.item1
				}
			};
		case DASHBOARD_SET_ONLINE_PLAYERS_COUNT_BEFORE:
			return {
				...state,
				online: {
					...state.online,
					isOnlinePlayersCountLoading: true
				}
			};
		case DASHBOARD_SET_ONLINE_PLAYERS_COUNT:
			return {
				...state,
				online: {
					...state.online,
					onlinePlayersCount: payload.count,
					isOnlinePlayersCountLoading: false
				}
			};
		case DASHBOARD_SET_PENDING_BETSLIPS_COUNT_BEFORE:
			return {
				...state,
				isPendingBetslipscountLoading: true
			};
		case DASHBOARD_SET_PENDING_BETSLIPS_COUNT:
			return {
				...state,
				isPendingBetslipscountLoading: false,
				[pt]: {
					...state[pt],
					pendingBetslipsCount: payload.count
				}
			};
		case DASHBOARD_BETSLIPS_ACTIVITIES_BEFORE:
			return {
				...state,
				isBetslipActivitiesLoading: true
			};
		case DASHBOARD_BETSLIPS_ACTIVITIES_FINISH:
			return {
				...state,
				isBetslipActivitiesLoading: false
			};
		case DASHBOARD_SET_BETSLIPS_ACTIVITIES:
			return {
				...state,
				[pt]: {
					...state[pt],
					betslipActivities: payload.data.item2,
					betslipsTotal: payload.data.item1
				}
			};
		case DASHBOARD_TOP_MARKETS_BEFORE:
			return {
				...state,
				isTopMarketsLoading: true
			};
		case DASHBOARD_TOP_MARKETS_FINISH:
			return {
				...state,
				isTopMarketsLoading: false
			};
		case DASHBOARD_SET_TOP_MARKETS:
			return {
				...state,
				[pt]: {
					...state[pt],
					topMarkets: payload.data
				}
			};
		case DASHBOARD_GAMES_PERFORMANCE_BEFORE:
			return {
				...state,
				isGamesPerformanceLoading: true
			};
		case DASHBOARD_GAMES_PERFORMANCE_FINISH:
			return {
				...state,
				isGamesPerformanceLoading: false
			};
		case DASHBOARD_SET_GAMES_PERFORMANCE:
			return {
				...state,
				[pt]: {
					...state[pt],
					gamesPerformance: {
						...state[pt].gamesPerformance,
						...payload.data.gamesPerformance
					},
					totalBonusBetsCount: payload.data.totalBonusBetsCount
				}
			};
		case DASHBOARD_CALCULATE_GAMES_PERFORMANCE:
			return {
				...state,
				[pt]: {
					...state[pt],
					gamesPerformance: {
						...state[pt].gamesPerformance,
						...payload.data
					}
				}
			};
		case DASHBOARD_TOP_PLAYERS_BEFORE:
			return {
				...state,
				online: {
					...state.online,
					topPlayers: {
						...state.online.topPlayers,
						isLoading: true
					}
				}
			};
		case DASHBOARD_TOP_PLAYERS_FINISH:
			return {
				...state,
				online: {
					...state.online,
					topPlayers: {
						...state.online.topPlayers,
						isLoading: false
					}
				}
			};
		case DASHBOARD_SET_TOP_PLAYERS_SORTING:
			return {
				...state,
				online: {
					...state.online,
					topPlayers: {
						...state.online.topPlayers,
						sorting: payload.sorting
					}
				}
			};
		case DASHBOARD_SET_TOP_PLAYERS:
			return {
				...state,
				online: {
					...state.online,
					topPlayers: {
						...state.online.topPlayers,
						data: payload.data.item2,
						total: payload.data.item1
					}
				}
			};
		case DASHBOARD_SET_FILTERS:
			return {
				...state,
				[pt]: {
					...state[pt],
					filters: {
						...state[pt].filters,
						...payload.filters
					}
				}
			};
		case DASHBOARD_PARTNERS_REVENUE_BEFORE:
			return {
				...state,
				[pt]: {
					...state[pt],
					partnersRevenue: {
						...state[pt].partnersRevenue,
						isLoading: true
					}
				}
			};
		case DASHBOARD_PARTNERS_REVENUE_FINISH:
			return {
				...state,
				[pt]: {
					...state[pt],
					partnersRevenue: {
						...state[pt].partnersRevenue,
						isLoading: false
					}
				}
			};
		case DASHBOARD_SET_PARTNERS_REVENUE:
			return {
				...state,
				[pt]: {
					...state[pt],
					partnersRevenue: {
						...state[pt].partnersRevenue,
						data: payload.data
					}
				}
			};
		case DASHBOARD_SET_PARTNERS_REVENUE_FILTER:
			return {
				...state,
				[pt]: {
					...state[pt],
					partnersRevenue: {
						...state[pt].partnersRevenue,
						filters: payload.filters
					}
				}
			};
		default:
			return state;
	}
};
