import PropTypes from "prop-types";
import { connect } from "react-redux";
import SettledBetslipsFilters from "../../common/settled/filters.component";
import { PROJECT_TYPE } from "constants/common.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
import { GAME_CATEGORY } from "constants/game.constants";

/** Retail Settled Betlsips Page Filters Component */
const Filters = ({ filters, gameCategory, showBetsBySeparate = false }) => {
	return (
		<SettledBetslipsFilters
			projectNamesAutosuggestionType={AUTOSUGGESTION_TYPE.BETSHOP_NAMES}
			filters={filters}
			projectType={PROJECT_TYPE.RETAIL}
			gameCategory={gameCategory}
			showBetsBySeparate={showBetsBySeparate}
		/>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux state property, retail settled betslips filters */
	filters: PropTypes.object,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY)),
	showBetsBySeparate: PropTypes.bool
};

const mapDispatchToProps = null;

const mapStateToProps = (state) => {
	return {
		filters: state.betslips.settled.retail.filters
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
