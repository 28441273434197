import { useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Col, Row, Modal, Input } from "antd";
const { Item: FormItem } = Form;

import SearchableSelect from "components/common/searchableSelect";

import { PROJECT_TYPE, POPUP_SIZE } from "constants/common.constants";

import userProjectAccessType from "types/user/projectAccess.type";
import userBetShopAccessType from "types/user/betShopAccess.type";
import { doesUserHaveRoleOf } from "utils/auth";
import { USER_ROLE } from "constants/user.constants";

/** User Project Adding Popup Component */
const DataAddComponent = ({ isSaving, addData, onClose, dataAccess, details }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;
	const [value, setValue] = useState(null);
	const isAccessManager = doesUserHaveRoleOf(USER_ROLE.ACCESS_MANAGER);

	/** Fires when form submitted
	 * @function
	 * @memberOf DataAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ projectId, note }) => {
				addData(routeParams.id, details.partnerId, projectId, details.projectType, note);
				if (typeof onClose === "function") {
					onClose();
				}
			})
			.catch(Function.prototype);
	};

	/** Get available projects
	 * @function
	 * @returns {array}
	 * @memberOf DataAddComponent
	 */
	const getAvailableItems = () => {
		let selected = dataAccess.partners.find((p) => p.id === details.partnerId);
		selected = selected || { apiKeys: [], availableApiKeys: [], betShops: [], availableBetShops: [] };
		let available = details.projectType === PROJECT_TYPE.ONLINE ? selected.availableApiKeys : selected.availableBetShops;
		available = available.filter((a) => !(details.projectType === PROJECT_TYPE.ONLINE ? selected.apiKeys : selected.betShops).map((i) => i.id).includes(a.id));
		available.sort((a, b) => (a === "*" ? 1 : -1));
		return available;
	};

	return (
		<Modal
			open={true}
			title={details.projectType === PROJECT_TYPE.ONLINE ? t("pages.dashboard.users.add_new_project") : t("pages.dashboard.users.add_new_betshop")}
			cancelText={t("common.cancel")}
			okText={t("common.add")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.SMALL}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving }}
			centered
		>
			<Form className="dashboard-form" colon={false} layout="vertical" requiredMark={false} form={formInstance}>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={details.projectType === PROJECT_TYPE.ONLINE ? t("pages.dashboard.users.select_project_placeholder") : t("pages.dashboard.users.select_betshop_placeholder")} name="projectId" rules={[{ required: true, message: t("validation.field_required") }]}>
							<SearchableSelect
								placeholder={details.projectType === PROJECT_TYPE.ONLINE ? t("pages.dashboard.users.select_project_placeholder") : t("pages.dashboard.users.select_betshop_placeholder")}
								items={getAvailableItems()}
								valueProp={(item) => item.id}
								textProp={(item) => (item.name === "*" ? t("common.all") : item.name)}
								renderText={(item) => (item.name === "*" ? <b>{t("common.all")}</b> : item.name)}
								getPopupContainer={() => document.body}
								value={value}
								onChange={(e) => setValue(e)}
							/>
						</FormItem>
						{isAccessManager ? (
							<FormItem label={`${t("pages.dashboard.permissions_requests.notes")} *`} name="note" rules={[{ required: true, message: t("validation.field_required") }]}>
								<Input.TextArea rows={4} placeholder={`${t("pages.dashboard.permissions_requests.notes")} *`} />
							</FormItem>
						) : null}
						{value === "*" ? (
							<small>
								<i>{details.projectType === PROJECT_TYPE.ONLINE ? t("pages.dashboard.users.all_future_projects") : t("pages.dashboard.users.all_future_betshops")}</i>
							</small>
						) : null}
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** DataAddComponent propTypes
 * PropTypes
 */
DataAddComponent.propTypes = {
	/** Redux state property, is true when user project access is adding */
	isSaving: PropTypes.bool,
	/** Redux action to add users project access */
	addData: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Redux state property, represents the projects/betShops access info of user  */
	dataAccess: PropTypes.oneOfType([userProjectAccessType, userBetShopAccessType]),
	/** Details */
	details: PropTypes.shape({
		/** Editing Partner Id */
		partnerId: PropTypes.string,
		/** Adding project type */
		projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE))
	})
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.users.isSaving
	};
};

export default connect(mapStateToProps, null)(DataAddComponent);
