import PropTypes from "prop-types";

import { USER_STATE } from "constants/user.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	userName: PropTypes.string,
	email: PropTypes.string,
	state: PropTypes.oneOf(Object.values(USER_STATE)),
	projectId: PropTypes.string,
	projectName: PropTypes.string,
	currencyCode: PropTypes.string,
	balance: PropTypes.number,
	countryCode: PropTypes.string,
	created: PropTypes.string,
	lastActiveAt: PropTypes.string
});
