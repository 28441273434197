import PropTypes from "prop-types";

import { INTEGRATION_TYPE } from "constants/partner.constants";

export default PropTypes.shape({
	projectCountLimit: PropTypes.number,
	projectCount: PropTypes.number,
	isSplitStakeEnabled: PropTypes.bool,
	allowBetCancellation: PropTypes.bool
});
