import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { pipe } from "utils/common";

const executeLowerCaseTextOrDefault = (func, mainArgument) => {
	return pipe(
		(arg) => func(arg),
		(arg) => (arg?.toLowerCase ? arg.toLowerCase() : "")
	)(mainArgument);
};

const executeForFiltering = (func, mainArgument, additionalArgument) => {
	return pipe(
		(arg) => executeLowerCaseTextOrDefault(func, arg),
		(arg) => (arg ? arg.includes(additionalArgument.toLowerCase()) : "")
	)(mainArgument);
};

const optionPropsDefaultGenerator = (props) => props

/** SearchableSelect Component, the select component with searchbar */
const SearchableSelect = ({
	mode,
	maxTagCount = 1,
	maxTagTextLength = 12,
	items,
	valueProp,
	textProp,
	renderText,
	addNone,
	noneText,
	isOptionDisabled,
	optionPropsGenerator = optionPropsDefaultGenerator,
	...rest
}) => {
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState("");

	/** Filter and sort
	 * @function
	 * @param {array} items - items to filter and sort
	 * @description filters items by search value, and sort them according to search value position on string
	 * @memberOf SearchableSelect
	 */
	const filterAndSort = (items) => {
		const filtered = items.filter((item) => executeForFiltering(textProp, item, searchValue) || executeForFiltering(valueProp, item, searchValue));
		filtered.sort((a, b) => {
			let aIndex = 1000000; // too big number
			let bIndex = 1000000;

			const aText = executeLowerCaseTextOrDefault(textProp, a);
			const bText = executeLowerCaseTextOrDefault(textProp, a);

			if (aText.includes(searchValue.toLowerCase())) {
				aIndex = aText.indexOf(searchValue.toLowerCase());
			}

			if (bText.includes(searchValue.toLowerCase())) {
				bIndex = bText.indexOf(searchValue.toLowerCase());
			}

			return aIndex >= bIndex ? 1 : -1;
		});
		return filtered;
	};

	const renderAddNoneSelectOption = () => {
		if (!addNone) return null;
		const text = noneText || t("common.none");
		return (
			<Select.Option key="none" {...optionPropsGenerator({ value: "", text })}>
				{text}
			</Select.Option>
		);
	};

	const checkisDisable = (item) => {
		let condition = typeof isOptionDisabled === "function";
		if (condition) {
			condition = isOptionDisabled(item);
		}
		if (!condition) {
			condition = rest.open === false;
		}
		return condition;
	};

	return (
		<Select
			mode={mode}
			maxTagTextLength={maxTagTextLength}
			maxTagCount={maxTagCount}
			showArrow={true}
			showSearch={true}
			filterOption={() => true}
			onSearch={(inputValue) => setSearchValue(inputValue)}
			onSelect={() => setSearchValue("")}
			onDropdownVisibleChange={(opened) => !opened && setSearchValue("")}
			suffixIcon={<i className="icon-down" />}
			{...rest}
		>
			{renderAddNoneSelectOption()}
			{filterAndSort(items).map((item) => {
				const value = valueProp(item)
				return (
					<Select.Option
						key={value}
						{...optionPropsGenerator({ value, text: textProp(item), disabled: checkisDisable(item) })}
					>
						{renderText(item)}
					</Select.Option>
				)
			})}
		</Select>
	);
};

/** SearchableSelect propTypes
 * PropTypes
 */
SearchableSelect.propTypes = {
	/** Mode of select */
	mode: PropTypes.oneOf(["multiple", "tags"]),
	/** Max tag count to show */
	maxTagCount: PropTypes.number,
	/** Max tag text length to show */
	maxTagTextLength: PropTypes.number,
	/** Items of select */
	items: PropTypes.array,
	/** The property of item to use for value of select */
	valueProp: PropTypes.func,
	/** The property of item to use for text of select */
	textProp: PropTypes.func,
	/** Function for rendering text of select */
	renderText: PropTypes.func,
	/** Add "none" option to select */
	addNone: PropTypes.bool,
	/** None option text */
	noneText: PropTypes.string,
	/** Function, which gets item as argument, and returns true if option should be disabled */
	isOptionDisabled: PropTypes.func
};

export default SearchableSelect;
