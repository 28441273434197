import { Fragment, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useParams } from "react-router-dom";

import { Form, Row, Col, DatePicker, Radio } from "antd";

const { Item: FormItem } = Form;
import locale from "antd/es/date-picker/locale/en_US";

import FiltersWrapper from "components/common/filters";

import { setPlayerSessionsFilters, getPlayerSessions } from "store/actions/dashboard/online/players/sessions.action";

import { monthAgo } from "utils/dateTime";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

/** Player Sessions Page Filters Component */

const Filters = ({ setPlayerSessionsFilters, getPlayerSessions, filters }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [{ name: "date", keepTime: true, roundTime: false }]
		};
	}, []);

	return (
		<FiltersWrapper filtersName="player_sessions" loadFn={() => getPlayerSessions(routeParams.id)} setFiltersFn={setPlayerSessionsFilters} filters={filters} formFieldsConfigs={formFieldsConfigs} filtersList={[]}>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={24} lg={12} xl={8}>
						<FormItem label={t("pages.dashboard.session.date")} name="date">
							<DatePicker.RangePicker
								placeholder={[t("common.from"), t("common.to")]}
								format={`${DATE_FORMAT} ${TIME_FORMAT}`}
								showTime={{ format: TIME_FORMAT }}
								disabledDate={(d) => !d || d.isBefore(monthAgo(3)) || d.isAfter(moment().endOf("day"))}
								allowClear={false}
								locale={{
									...locale,
									lang: {
										...locale.lang,
										ok: t("common.apply")
									}
								}}
							/>
						</FormItem>
					</Col>

					<Col xs={24} sm={18} lg={18} xl={12}>
						<div className="table-filters-quick-filters">
							<FormItem label={t("common.quick_filters")} name="quickFilters" className="table-filters-quick-filters-buttons">
								<Radio.Group>
									<Radio.Button value="last_24_h" style={{ marginBottom: "8px" }}>
										{t("common.last_24_h")}
									</Radio.Button>
									<Radio.Button value="last_week" style={{ marginBottom: "8px" }}>
										{t("common.this_week")}
									</Radio.Button>
									<Radio.Button value="last_month" style={{ marginBottom: "8px" }}>
										{t("common.this_month")}
									</Radio.Button>
									<Radio.Button value="last_3_month" style={{ marginBottom: "8px" }}>
										{t("common.last_3_months")}
									</Radio.Button>
								</Radio.Group>
							</FormItem>
						</div>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get player sessions */
	getPlayerSessions: PropTypes.func,
	/** Redux action to set player sessions filters */
	setPlayerSessionsFilters: PropTypes.func,
	/** Redux state property, player sessions filters */
	filters: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	getPlayerSessions: (id) => {
		dispatch(getPlayerSessions(id));
	},
	setPlayerSessionsFilters: (filters) => {
		dispatch(setPlayerSessionsFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.players.viewingPlayer.sessions.filters
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
