import { Fragment, useState, useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { Tabs } from "antd";

import BetTicketComponent from "./sections/betTicket";
import CancelTicketComponent from "./sections/cancelTicket";
import PayoutTicketComponent from "./sections/payoutTicket";

import { hasPermission } from "utils/permissions";
import { updateLocationHash, getHashValue } from "utils/common";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { setLogo } from "store/actions/dashboard/cms/tickets/ticket.action";

/** CMS Betshop Ticket Page Component */
const TicketsComponent = ({ setLogo }) => {
	const { t } = useTranslation();

	const [unsavedTabs, setUnsavedTabs] = useState([]);

	useEffect(() => () => setLogo(null), []);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf TicketsComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf TicketsComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	return (
		<Fragment>
			<div className="dashboard-section">
				<Tabs
					animated={false}
					defaultActiveKey={getHashValue("tab")}
					onChange={(key) => updateLocationHash("tab=" + key)}
					items={[
						{
							key: "1",
							label: <span className={tabClassName("1")}>{t("pages.dashboard.partners.retail_settings.tabs.bet_ticket")}</span>,
							children: <BetTicketComponent onTabChange={(status) => changeTabSavedStatus(status, "1")} />
						},
						{
							key: "2",
							label: <span className={tabClassName("2")}>{t("pages.dashboard.partners.retail_settings.tabs.cancellation_ticket")}</span>,
							children: <CancelTicketComponent onTabChange={(status) => changeTabSavedStatus(status, "2")} />
						},
						{
							key: "3",
							label: <span className={tabClassName("3")}>{t("pages.dashboard.partners.retail_settings.tabs.payout_ticket")}</span>,
							children: <PayoutTicketComponent onTabChange={(status) => changeTabSavedStatus(status, "3")} />
						}
					]}
				/>
			</div>
		</Fragment>
	);
};

/** TicketsComponent propTypes
 * PropTypes
 */
TicketsComponent.propTypes = {
	/** Redux action to set the partner ticket logo */
	setLogo: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	setLogo: (logo) => {
		dispatch(setLogo(logo));
	}
});

export default connect(null, mapDispatchToProps)(TicketsComponent);
