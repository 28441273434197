import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { PERMISSIONS_ACTION_BEFORE, PERMISSIONS_ACTION_FINISH, PERMISSIONS_ACTION_SET_PERMISSIONS, PERMISSION_RESOURCES_ACTION_BEFORE, PERMISSION_RESOURCES_ACTION_FINISH, PERMISSIONS_ACTION_SET_RESOURCES } from "store/actionTypes";

const setPermissionsActionBefore = () => ({
	type: PERMISSIONS_ACTION_BEFORE
});

const setPermissionsActionFinished = () => ({
	type: PERMISSIONS_ACTION_FINISH
});

const setPermissionResourcesActionBefore = () => ({
	type: PERMISSION_RESOURCES_ACTION_BEFORE
});

const setPermissionResourcesActionFinished = () => ({
	type: PERMISSION_RESOURCES_ACTION_FINISH
});

const setPermissions = (permissions) => ({
	type: PERMISSIONS_ACTION_SET_PERMISSIONS,
	payload: { permissions }
});

const setResources = (resources) => ({
	type: PERMISSIONS_ACTION_SET_RESOURCES,
	payload: { resources }
});

export const getPermissions = (resource) => {
	return (dispatch) => {
		dispatch(setPermissionsActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SYSTEM_PERMISSIONS}`,
			method: Methods.GET,
			params: { resource }
		})
			.then(({ data: { value: permissions } }) => {
				dispatch(setPermissions(permissions));
				dispatch(setPermissionsActionFinished());
			})
			.catch(() => {
				dispatch(setPermissionsActionFinished());
			});
	};
};

export const getPermissionResources = () => {
	return (dispatch) => {
		dispatch(setPermissionResourcesActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SYSTEM_PERMISSION_RESOURCES}`,
			method: Methods.GET
		})
			.then(({ data: { value: resources } }) => {
				dispatch(setResources(resources));
				dispatch(setPermissionResourcesActionFinished());
			})
			.catch(() => {
				dispatch(setPermissionResourcesActionFinished());
			});
	};
};
