import PropTypes from "prop-types";

import { SCHEDULED_GAME_TYPE, INSTANT_GAME_TYPE } from "constants/game.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	partnerId: PropTypes.string,
	type: PropTypes.oneOf([...Object.values(SCHEDULED_GAME_TYPE), ...Object.values(INSTANT_GAME_TYPE)].map((v) => v.value)),
	cycleMinutes: PropTypes.number,
	sceneCount: PropTypes.number,
	activeGameCount: PropTypes.number,
	closeBetSeconds: PropTypes.number,
	created: PropTypes.string,
	lastModified: PropTypes.string,
	isTesting: PropTypes.bool,
	disableInfo: PropTypes.shape({
		disableReason: PropTypes.string,
		disableTo: PropTypes.string
	}),
	details: PropTypes.shape({
		groupCount: PropTypes.number,
		partnerCount: PropTypes.number,
		participantCount: PropTypes.number
	})
});
