import { Component } from "react";

import { Navigate } from "react-router-dom";

import { isAuthenticated } from "utils/auth";

import PATHS from "constants/path.constants";

/** Hocs which allows access to page only unAuthorized users
 * @function
 * @param {Component} WrappedComponent - Component to add functionality
 */
const WithAnanymous = (WrappedComponent) => {
	class WithAnanymous extends Component {
		render() {
			const returnURL = new URLSearchParams(location.search).get("returnURL");

			if (isAuthenticated()) {
				return <Navigate to={returnURL ? `${PATHS.DASHBOARD}?returnURL=${encodeURIComponent(returnURL)}` : PATHS.DASHBOARD} replace />;
			}

			return <WrappedComponent {...this.props} />;
		}
	}

	WithAnanymous.displayName = `WithAnanymous(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`;
	return WithAnanymous;
};

export default WithAnanymous;
