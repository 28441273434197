import axios from "axios";
import moment from "moment";
import Methods from "constants/methods.constants";
import { EXPORT_TYPE } from "constants/common.constants";
import { DATE_TIME_FORMAT } from "constants/date.constants";

const getApplicationType = (type) => (type === EXPORT_TYPE.CSV ? "text/csv" : "application/pdf");
const getExt = (type) => (type === EXPORT_TYPE.CSV ? ".csv" : ".pdf");

export const exportData = (url, exportType, exportColumns, tableName, filters, sorting) => {
	return () => {
		const tz = new Date().getTimezoneOffset();
		let columnsStr = "tz=" + tz + "&";
		exportColumns.forEach((c) => {
			columnsStr += "ExportColumns=" + c + "&";
		});
		const urlStr = url + (url.indexOf("?") > -1 ? "&" + columnsStr : "?" + columnsStr);

		let arrParams = {};
		if (filters) {
			Object.keys(filters).forEach((f) => {
				if (!Array.isArray(filters[f])) return;
				if (filters[f].length === 0) return;
				arrParams[f] = filters[f];
			});
		}

		let qsArrParams = Object.keys(arrParams).map((p) => arrParams[p].map((i) => `${p}=${i}`).join("&"));
		let qsArrParamsStr = "";
		if (qsArrParams.length) {
			qsArrParamsStr = "&" + qsArrParams.join("&");
		}

		const f = { ...filters };

		Object.keys(arrParams).forEach((p) => {
			delete f[p];
		});

		const s = {};
		if (sorting && sorting.orderBy) {
			s.orderBy = sorting.orderBy;
			s.orderDirection = sorting.orderDirection;
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${urlStr}ExportType=${exportType}${qsArrParamsStr}`,
			method: Methods.GET,
			params: {
				...f,
				...s
			},
			responseType: "blob"
		})
			.then(({ data }) => {
				try {
					const applicationType = getApplicationType(exportType);
					const blob = new Blob([data], { type: applicationType });
					const link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.target = "_blank";
					const ext = getExt(exportType);
					const fileName = `${tableName} - ${moment().format(DATE_TIME_FORMAT)}`;
					link.download = fileName + ext;
					link.click();
				} catch (ex) {
					console.log(ex);
				}
			})
			.catch(Function.prototype);
	};
};
