import { REDUX_EDITING_API_KEY_ANALITICAL_TOOLS_FIELD, REDUX_EDITING_API_KEY_GAME_FIELD, REDUX_EDITING_API_KEY_PLATFORMS_FIELD } from "constants/game.constants";
import {
	APIKEYS_ACTION_BEFORE,
	APIKEYS_ACTION_FINISH,
	APIKEYS_ACTION_SET_APIKEYS,
	APIKEY_SAVE_ACTION_BEFORE,
	APIKEY_SAVE_ACTION_FINISH,
	APIKEYS_SET_SORTING,
	APIKEYS_SET_FILTERS,
	SET_APIKEY_GENERAL_INFO,
	SET_APIKEY_GAMES,
	SET_APIKEY_ADD_GAME,
	SET_APIKEY_UPDATE_GAME,
	SET_APIKEY_DELETE_GAME,
	APIKEY_GAMES_BEFORE,
	APIKEY_GAMES_FINISH,
	SET_APIKEY_GAME_RTP,
	APIKEY_GAMES_RTP_BEFORE,
	APIKEY_GAMES_RTP_FINISH,
	APIKEY_CURRENCIES_BEFORE,
	APIKEY_CURRENCIES_FINISH,
	SET_APIKEY_CURRENCIES,
	SET_APIKEY_UPDATE_CURRENCY,
	SET_APIKEY_ANALYTICAL_TOOLS,
	SET_APIKEY_PLATFORMS,
	APIKEY_BLOCKED_COUNTRIES_BEFORE,
	APIKEY_BLOCKED_COUNTRIES_FINISH,
	SET_APIKEY_BLOCKED_COUNTRIES,
	SET_APIKEY_ADD_BLOCKED_COUNTRY,
	SET_APIKEY_DELETE_BLOCKED_COUNTRY,
	APIKEY_BLOCKED_IPS_BEFORE,
	APIKEY_BLOCKED_IPS_FINISH,
	SET_APIKEY_BLOCKED_IPS,
	SET_APIKEY_ADD_BLOCKED_IP,
	SET_APIKEY_DELETE_BLOCKED_IP,
	SET_APIKEY_UI,
	SET_APIKEY_PREVIEW_UI,
	RESET_APIKEY_UI,
	SET_APIKEY_MOBILE_LOGO_ID,
	APIKEY_GAME_OTHER_CONFIGS_BEFORE,
	APIKEY_GAME_OTHER_CONFIGS_FINISH,
	SET_APIKEY_GAME_OTHER_CONFIGS,
	APIKEYS_ADD_ONS_ACTION_BEFORE,
	SET_APIKEYS_ADD_ONS,
	APIKEYS_ADD_ONS_ACTION_FINISH,
	APIKEYS_ADD_ONS_SAVE_BEFORE,
	APIKEYS_ADD_ONS_SAVE_FINISH
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case APIKEYS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case APIKEYS_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case APIKEYS_ACTION_SET_APIKEYS:
			return {
				...state,
				apiKeys: payload.data.item2,
				total: payload.data.item1
			};
		case APIKEY_SAVE_ACTION_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case APIKEY_SAVE_ACTION_FINISH:
			return {
				...state,
				isSaving: false
			};
		case SET_APIKEY_GENERAL_INFO:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					generalInfo: {
						...payload.info
					}
				}
			};
		case APIKEY_GAMES_BEFORE:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					games: {
						...state.editingApikey.games,
						isLoading: true
					}
				}
			};
		case APIKEY_GAMES_FINISH:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					games: {
						...state.editingApikey.games,
						isLoading: false
					}
				}
			};
		case SET_APIKEY_GAMES:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					games: {
						...state.editingApikey.games,
						[REDUX_EDITING_API_KEY_GAME_FIELD[payload.gameCategory]]: payload.games
					}
				}
			};
		case APIKEYS_SET_SORTING:
			return {
				...state,
				sorting: payload.sorting
			};
		case APIKEYS_SET_FILTERS:
			return {
				...state,
				filters: payload.filters,
				sorting: {
					...state.sorting,
					page: 1
				}
			};
		case SET_APIKEY_ADD_GAME:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					games: {
						...state.editingApikey.games,
						[REDUX_EDITING_API_KEY_GAME_FIELD[payload.gameCategory]]: [...state.editingApikey.games[REDUX_EDITING_API_KEY_GAME_FIELD[payload.gameCategory]], payload.game]
					}
				}
			};
		case SET_APIKEY_UPDATE_GAME:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					games: {
						...state.editingApikey.games,
						[REDUX_EDITING_API_KEY_GAME_FIELD[payload.gameCategory]]: state.editingApikey.games[REDUX_EDITING_API_KEY_GAME_FIELD[payload.gameCategory]].map((g) => (g.id === payload.game.id ? payload.game : g))
					}
				}
			};
		case SET_APIKEY_DELETE_GAME:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					games: {
						...state.editingApikey.games,
						[REDUX_EDITING_API_KEY_GAME_FIELD[payload.gameCategory]]: state.editingApikey.games[REDUX_EDITING_API_KEY_GAME_FIELD[payload.gameCategory]].filter((g) => g.id !== payload.id)
					}
				}
			};
		case APIKEY_GAMES_RTP_BEFORE:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					rtp: {
						...state.editingApikey.rtp,
						isLoading: true
					}
				}
			};
		case APIKEY_GAMES_RTP_FINISH:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					rtp: {
						...state.editingApikey.rtp,
						isLoading: false
					}
				}
			};
		case SET_APIKEY_GAME_RTP:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					rtp: {
						...state.editingApikey.rtp,
						data: payload.rtp
					}
				}
			};
		case APIKEY_GAME_OTHER_CONFIGS_BEFORE:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					otherConfigs: {
						...state.editingApikey.otherConfigs,
						isLoading: true
					}
				}
			};
		case APIKEY_GAME_OTHER_CONFIGS_FINISH:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					otherConfigs: {
						...state.editingApikey.otherConfigs,
						isLoading: false
					}
				}
			};
		case SET_APIKEY_GAME_OTHER_CONFIGS:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					otherConfigs: {
						...state.editingApikey.otherConfigs,
						data: payload.data
					}
				}
			};
		case APIKEY_CURRENCIES_BEFORE:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					currencies: {
						...state.editingApikey.currencies,
						isLoading: true
					}
				}
			};
		case APIKEY_CURRENCIES_FINISH:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					currencies: {
						...state.editingApikey.currencies,
						isLoading: false
					}
				}
			};
		case SET_APIKEY_CURRENCIES:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					currencies: {
						...state.editingApikey.currencies,
						currencies: payload.currencies
					}
				}
			};
		case SET_APIKEY_UPDATE_CURRENCY:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					currencies: {
						...state.editingApikey.currencies,
						currencies: state.editingApikey.currencies.currencies.map((c) =>
							c.code === payload.currency.code
								? payload.currency
								: {
										...c,
										isDefault: payload.currency.isDefault ? false : c.isDefault
								  }
						)
					}
				}
			};
		case SET_APIKEY_ANALYTICAL_TOOLS:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					[REDUX_EDITING_API_KEY_ANALITICAL_TOOLS_FIELD[payload.gameCategory]]: payload.data
				}
			};
		case SET_APIKEY_PLATFORMS:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					[REDUX_EDITING_API_KEY_PLATFORMS_FIELD[payload.gameCategory]]: payload.data
				}
			};
		case APIKEY_BLOCKED_COUNTRIES_BEFORE:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					ipRestriction: {
						...state.editingApikey.ipRestriction,
						blockedCountries: {
							...state.editingApikey.ipRestriction.blockedCountries,
							isLoading: true
						}
					}
				}
			};
		case APIKEY_BLOCKED_COUNTRIES_FINISH:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					ipRestriction: {
						...state.editingApikey.ipRestriction,
						blockedCountries: {
							...state.editingApikey.ipRestriction.blockedCountries,
							isLoading: false
						}
					}
				}
			};
		case SET_APIKEY_BLOCKED_COUNTRIES:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					ipRestriction: {
						...state.editingApikey.ipRestriction,
						blockedCountries: {
							...state.editingApikey.ipRestriction.blockedCountries,
							countries: payload.countries
						}
					}
				}
			};
		case SET_APIKEY_ADD_BLOCKED_COUNTRY:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					ipRestriction: {
						...state.editingApikey.ipRestriction,
						blockedCountries: {
							...state.editingApikey.ipRestriction.blockedCountries,
							countries: [...state.editingApikey.ipRestriction.blockedCountries.countries, payload.country]
						}
					}
				}
			};
		case SET_APIKEY_DELETE_BLOCKED_COUNTRY:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					ipRestriction: {
						...state.editingApikey.ipRestriction,
						blockedCountries: {
							...state.editingApikey.ipRestriction.blockedCountries,
							countries: state.editingApikey.ipRestriction.blockedCountries.countries.filter((c) => c.countryCode !== payload.countryCode)
						}
					}
				}
			};
		case APIKEY_BLOCKED_IPS_BEFORE:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					ipRestriction: {
						...state.editingApikey.ipRestriction,
						blockedIPs: {
							...state.editingApikey.ipRestriction.blockedIPs,
							isLoading: true
						}
					}
				}
			};
		case APIKEY_BLOCKED_IPS_FINISH:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					ipRestriction: {
						...state.editingApikey.ipRestriction,
						blockedIPs: {
							...state.editingApikey.ipRestriction.blockedIPs,
							isLoading: false
						}
					}
				}
			};
		case SET_APIKEY_BLOCKED_IPS:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					ipRestriction: {
						...state.editingApikey.ipRestriction,
						blockedIPs: {
							...state.editingApikey.ipRestriction.blockedIPs,
							ips: payload.ips
						}
					}
				}
			};
		case SET_APIKEY_ADD_BLOCKED_IP:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					ipRestriction: {
						...state.editingApikey.ipRestriction,
						blockedIPs: {
							...state.editingApikey.ipRestriction.blockedIPs,
							ips: [...state.editingApikey.ipRestriction.blockedIPs.ips, payload.ip]
						}
					}
				}
			};
		case SET_APIKEY_DELETE_BLOCKED_IP:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					ipRestriction: {
						...state.editingApikey.ipRestriction,
						blockedIPs: {
							...state.editingApikey.ipRestriction.blockedIPs,
							ips: state.editingApikey.ipRestriction.blockedIPs.ips.filter((ip) => ip.id !== payload.ipId)
						}
					}
				}
			};
		case SET_APIKEY_UI: {
			let previewUIValues = {};
			if (payload.updatePreview) {
				const parameters = payload.data.parameters || [];
				parameters.forEach((p) => {
					previewUIValues[p.key] = p.value;
				});
			} else {
				previewUIValues = { ...state.editingApikey.customization.previewUI };
			}

			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					customization: {
						...state.editingApikey.customization,
						ui: payload.data,
						previewUI: previewUIValues
					}
				}
			};
		}
		case SET_APIKEY_PREVIEW_UI:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					customization: {
						...state.editingApikey.customization,
						previewUI: {
							...state.editingApikey.customization.previewUI,
							...payload.data
						}
					}
				}
			};
		case RESET_APIKEY_UI:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					customization: {
						ui: {},
						previewUI: {}
					}
				}
			};
		case SET_APIKEY_MOBILE_LOGO_ID:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					customization: {
						...state.editingApikey.customization,
						ui: {
							...state.editingApikey.customization.ui,
							logoId: payload.logoId
						}
					}
				}
			};
		case APIKEYS_ADD_ONS_ACTION_BEFORE:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					addOns: {
						...state.editingApikey.addOns,
						isLoading: true
					}
				}
			};
		case APIKEYS_ADD_ONS_ACTION_FINISH:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					addOns: {
						...state.editingApikey.addOns,
						isLoading: false
					}
				}
			};
		case APIKEYS_ADD_ONS_SAVE_BEFORE:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					addOns: {
						...state.editingApikey.addOns,
						isSaving: true
					}
				}
			};
		case APIKEYS_ADD_ONS_SAVE_FINISH:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					addOns: {
						...state.editingApikey.addOns,
						isSaving: false
					}
				}
			};
		case SET_APIKEYS_ADD_ONS:
			return {
				...state,
				editingApikey: {
					...state.editingApikey,
					addOns: {
						...state.editingApikey.addOns,
						data: {
							...state.editingApikey.addOns.data,
							...payload.data,
							boostFactors: {
								...state.editingApikey.addOns.data.boostFactors,
								...payload.data.boostFactors,
							}
						}
					}
				}
			};
		default:
			return state;
	}
};
