import PropTypes from "prop-types";

import { BETSLIP_MODES, BET_STATE, CANCEL_USER_TYPE } from "constants/bet.constants";
import { DATA_DIFFERENCE_TYPE } from "constants/common.constants";

const dbCacheCompareType = PropTypes.shape({
	id: PropTypes.number,
	betTime: PropTypes.string,
	type: PropTypes.oneOf(Object.values(BETSLIP_MODES)),
	status: PropTypes.oneOf(Object.values(BET_STATE)),
	differenceType: PropTypes.oneOf(Object.values(DATA_DIFFERENCE_TYPE)),
	difference: PropTypes.object
});
dbCacheCompareType.bets = PropTypes.arrayOf(dbCacheCompareType);

export default dbCacheCompareType;
