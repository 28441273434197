import { useState, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Tag, Tooltip, Button } from "antd";

import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import GameAddComponent from "./game-add.component";
import GameStatusChangeComponent from "./game-status-change.component";
import Filters from "./filters.component";
import ExportButton from "components/common/exportButton";

import { getGames, setGamesSorting } from "store/actions/dashboard/virtuals/games/games.action";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import gameType from "types/game/game.type";
import sortingType from "types/common/sorting.type";

import ApiUrls from "constants/api.constants";
import { GAME_STATE, GAME_CYCLE_MINUTES, GAME_CATEGORY, GAME_TYPES } from "constants/game.constants";
import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import { hasPermission } from "utils/permissions";

/** Games Page Component */
const GamesComponent = ({ getGames, games, isLoading, total, setGamesSorting, sorting, filters, globalPartnerId, gameCategory }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const gameTypes = GAME_TYPES[gameCategory];

	/** State to show/hide game creation popup */
	const [showCreatePopup, setShowCreatePopup] = useState(false);
	/** State to show/hide game status change popup */
	const [showStatusChangePopup, setShowStatusChangePopup] = useState(false);

	/** Fires on state tag click
	 * @function
	 * @param {object} e - click eveent object
	 * @param {object} record - the record
	 * @memberOf GamesComponent
	 */
	const handleStateTagClick = (e, record) => {
		e.stopPropagation();
		setShowStatusChangePopup(record);
	};

	const gameText = gameCategory === GAME_CATEGORY.SCHEDULED ? "virtual_scheduled_games" : gameCategory === GAME_CATEGORY.INSTANT ? "virtual_instant_games" : "";

	const exportUrl = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.EXPORT_SCHEDULED_GAMES : gameCategory === GAME_CATEGORY.INSTANT ? ApiUrls.EXPORT_INSTANT_GAMES : "";

	const gameEditUrl = gameCategory === GAME_CATEGORY.SCHEDULED ? Paths.DASHBOARD_SCHEDULED_GAMES_EDIT : gameCategory === GAME_CATEGORY.INSTANT ? Paths.DASHBOARD_INSTANT_GAMES_EDIT : "";

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.games.game_id"),
			dataIndex: "id"
		},
		{
			title: t("pages.dashboard.games.name"),
			dataIndex: "name",
			render: (value, record) => (record.isTesting ? value + " (Test)" : value)
		},
		{
			title: t("pages.dashboard.games.status"),
			dataIndex: "state",
			className: "ant-table-tag-cell",
			render: (value, record) =>
				record.info && value !== GAME_STATE.ACTIVE ? (
					<Tooltip
						title={
							<Fragment>
								<p>
									{t("pages.dashboard.games.game_state_changed_by_provider")}
									<br />
								</p>
								{record.info && record.info.from && (
									<span>
										<b>{t("common.from")} : </b>
										{moment.utc(record.info.from).local().format(DATE_TIME_FORMAT)}
										<br />
									</span>
								)}
								{record.info && record.info.to && (
									<span>
										<b>{t("common.to")} : </b>
										{moment.utc(record.info.to).local().format(DATE_TIME_FORMAT)}
										<br />
									</span>
								)}
								<span>
									<b>{t("pages.dashboard.games.reason")} : </b>
									{record.info.reason}
								</span>
							</Fragment>
						}
					>
						<Tag color={value === GAME_STATE.ACTIVE ? "green" : value === GAME_STATE.INACTIVE ? "cyan" : value === GAME_STATE.CLOSE_FOR_BETTING ? "orange" : ""}>
							<Fragment>
								{value === GAME_STATE.ACTIVE ? t("common.active") : value === GAME_STATE.INACTIVE ? t("common.inactive") : value === GAME_STATE.CLOSE_FOR_BETTING ? t("pages.dashboard.games.betting_inactive") : ""}
								{hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.MODIFY }) && <i className="icon-edit" onClick={(e) => handleStateTagClick(e, record)} />}
							</Fragment>
						</Tag>
					</Tooltip>
				) : (
					<Tag color={value === GAME_STATE.ACTIVE ? "green" : value === GAME_STATE.INACTIVE ? "red" : value === GAME_STATE.CLOSE_FOR_BETTING ? "orange" : ""}>
						<Fragment>
							{value === GAME_STATE.ACTIVE ? t("common.active") : value === GAME_STATE.INACTIVE ? t("common.inactive") : value === GAME_STATE.CLOSE_FOR_BETTING ? t("pages.dashboard.games.betting_inactive") : ""}
							{hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.MODIFY }) && <i className="icon-edit" onClick={(e) => handleStateTagClick(e, record)} />}
						</Fragment>
					</Tag>
				)
		},
		...(gameCategory === GAME_CATEGORY.SCHEDULED
			? [
					{
						title: t("pages.dashboard.games.specified_partner"),
						dataIndex: "partnerName",
						render: (value, record) => (
							<div className="row-with-icon">
								<i className={value ? "icon-partner-specific" : "icon-generic"}></i>
								<span>{value ? (record.partnerIsTesting ? value + " (Test)" : value) : t("pages.dashboard.games.generic")}</span>
							</div>
						),
						sorter: false
					}
				]
			: []),
		{
			title: t("pages.dashboard.games.game"),
			dataIndex: "type",
			render: (value) => {
				const gameValues = Object.values(gameTypes);
				if (!gameValues.length) {
					return "-";
				}
				const [game] = gameValues.filter((v) => v.value === value).map((v) => v.type);

				return game ? t(`common.${game}`) : "-";
			}
		},
		...(gameCategory === GAME_CATEGORY.SCHEDULED
			? [
					{
						title: t("pages.dashboard.games.draw"),
						dataIndex: "cycleMinutes",
						render: (value) => (Object.keys(GAME_CYCLE_MINUTES).find((k) => GAME_CYCLE_MINUTES[k] === value) || "").replace("CYCLE", "").replace("MIN", "") + " " + t("common.minutes")
					},
					{
						title: t("pages.dashboard.games.active_game_count"),
						dataIndex: "activeGameCount",
						sorter: false
					},
					{
						title: t("pages.dashboard.games.bets_close_before_start"),
						dataIndex: "closeBetSeconds",
						render: (value) => value + " " + t("common.seconds"),
						sorter: false
					}
				]
			: []),
		{
			title: t("pages.dashboard.games.created_at"),
			dataIndex: "created",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.games.last_update_at"),
			dataIndex: "lastModified",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		}
	];

	const generateAction = () => {
		let key = null;

		if (hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.VIEW })) {
			key = "view";
		}

		if (hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.MODIFY })) {
			key = "edit";
		}

		if (!key) {
			return {};
		}

		return {
			[key]: {
				handler: (record) => {
					navigate(gameEditUrl.replace(DYNAMIC_PATH_ID_REGEX, record.id + (record.partnerId ? "?PI=" + record.partnerId : "")));
				}
			}
		};
	};

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t(`pages.dashboard.games.${gameText}`) }]} />
				<div className="dashboard-section-content">
					<div className="table-header">
						<div className="table-buttons-dropdowns">
							{hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.EXPORT }) ? (
								<ExportButton columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t(`pages.dashboard.games.${gameText}`)} url={exportUrl} filters={filters} sorting={sorting} />
							) : null}

							{hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.CREATE }) ? (
								<div className="table-buttons-dropdowns-button">
									<Button onClick={() => setShowCreatePopup(true)} type="primary">
										{t("pages.dashboard.games.create_game")}
									</Button>
								</div>
							) : null}
						</div>
						<Filters gameCategory={gameCategory} />
					</div>
					<Table loading={isLoading} columns={columns} data={games} loadFn={(fromFirstPage) => getGames(fromFirstPage, gameCategory)} sorting={sorting} setSortingFn={setGamesSorting} total={total} updateProps={[globalPartnerId]} actions={generateAction()} isDisabled={() => false} />
				</div>
			</div>

			{showCreatePopup ? <GameAddComponent gameCategory={gameCategory} onClose={() => setShowCreatePopup(false)} /> : null}
			{showStatusChangePopup ? <GameStatusChangeComponent gameCategory={gameCategory} onClose={() => setShowStatusChangePopup(null)} game={showStatusChangePopup} /> : null}
		</Fragment>
	);
};

/** GamesComponent propTypes
 * PropTypes
 */
GamesComponent.propTypes = {
	/** Redux action to get games */
	getGames: PropTypes.func,
	/** Redux state property, represents the array of games  */
	games: PropTypes.arrayOf(gameType),
	/** Redux state property, is true when loading games */
	isLoading: PropTypes.bool,
	/** Redux state property, games total count */
	total: PropTypes.number,
	/** Redux action to set games sorting details */
	setGamesSorting: PropTypes.func,
	/** Redux state property, games sorting details */
	sorting: sortingType,
	/** Redux state property, games filters */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	getGames: (fromFirstPage, gameCategory) => {
		dispatch(getGames(fromFirstPage, false, gameCategory));
	},
	setGamesSorting: (sorting) => {
		dispatch(setGamesSorting(sorting));
	}
});

const mapStateToProps = (state, props) => {
	const { gameCategory } = props;
	return {
		isLoading: state.games.isLoading,
		games: state.games.games,
		total: state.games.total,
		sorting: state.games.sorting,
		filters: state.games.filters[gameCategory],
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GamesComponent);
