import { useState, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, Tooltip, message } from "antd";

import { hasPermission } from "utils/permissions";

import { getApikeys, setApikeysSorting } from "store/actions/dashboard/online/apikeys/apikeys.action";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import ApiKeyAddComponent from "./apikeys-add.component";
import Filters from "./filters.component";
import ExportButton from "components/common/exportButton";

import apiKeyType from "types/apiKey/apiKey.type";
import sortingType from "types/common/sorting.type";
import userInfoType from "types/profile/userInfo.type";

import Paths from "constants/path.constants";
import ApiUrls from "constants/api.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { INTEGRATION_TYPE } from "constants/apiKey.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

/** ApiKeys Page Component */

const ApiKeysComponent = ({ getApikeys, apiKeys, isLoading, total, setApikeysSorting, sorting, filters, globalPartnerId, userInfo }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	/** State to show/hide apikey creation popup */
	const [showCreatePopup, setShowCreatePopup] = useState(false);

	const renderGames = (value) =>
		value ? (
			<div style={{ maxWidth: 350 }}>
				<Tooltip
					title={value.map((v) => (
						<div>{v}</div>
					))}
					overlayInnerStyle={{ width: "max-content" }}
					getPopupContainer={(target) => target.parentNode}
				>
					<div className="vs--ellipsis">{value.join(", ")}</div>
				</Tooltip>
			</div>
		) : (
			" - "
		);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.apikeys.name"),
			dataIndex: "name",
			render: (value, record) => (record.isTesting ? value + " (Test)" : value)
		},
		{
			title: t("pages.dashboard.apikeys.endpoint_url"),
			dataIndex: "endPointURL",
			render: (value) => (value ? value : " - "),
			sorter: false
		},
		{
			title: t("pages.dashboard.apikeys.integration_Type"),
			dataIndex: "integrationType",
			render: (value) => (value === INTEGRATION_TYPE.IFRAME ? t("pages.dashboard.apikeys.iframe") : value === INTEGRATION_TYPE.FEED ? t("pages.dashboard.apikeys.feed") : " - "),
			sorter: false
		},
		{
			title: t("pages.dashboard.apikeys.scheduledGames"),
			dataIndex: "games",
			render: renderGames,
			sorter: false
		},
		{
			title: t("pages.dashboard.apikeys.instantGames"),
			dataIndex: "instantGames",
			render: renderGames,
			sorter: false
		},
		{
			title: t("pages.dashboard.apikeys.created_at"),
			dataIndex: "created",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.apikeys.last_modified_at"),
			dataIndex: "lastUpdate",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		}
	];

	/** Fires on create button click
	 * @function
	 * @memberOf ApiKeysComponent
	 */
	const handleCreateButtonClick = () => {
		const projectCountLimit = (userInfo.partners || []).find((p) => p.id === globalPartnerId).projectCountLimit;
		if (projectCountLimit === 0) {
			setShowCreatePopup(true);
		} else if (projectCountLimit > total) {
			setShowCreatePopup(true);
		} else {
			message.info(t("pages.dashboard.apikeys.limit_reached_message"));
		}
	};

	const generateAction = () => {
		let key = null;

		if (hasPermission({ resource: PERMISSION_RESOURCE.APIKEY, action: PERMISSION_ACTION.VIEW })) {
			key = "view";
		}

		if (hasPermission({ resource: PERMISSION_RESOURCE.APIKEY, action: PERMISSION_ACTION.MODIFY })) {
			key = "edit";
		}

		if (!key) {
			return {};
		}

		return {
			[key]: {
				handler: (record) => {
					navigate(Paths.DASHBOARD_ONLINE_APIKEYS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, `${record.id}?PI=${record.partnerId}`));
				}
			}
		};
	};

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.projects") }]} />
				<div className="dashboard-section-content">
					<div className="table-header">
						<div className="table-buttons-dropdowns">
							{hasPermission({ resource: PERMISSION_RESOURCE.APIKEY, action: PERMISSION_ACTION.EXPORT }) ? (
								<ExportButton columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t("pages.dashboard.menu.projects")} url={ApiUrls.EXPORT_APIKEY} filters={filters} sorting={sorting} />
							) : null}
							{hasPermission({ resource: PERMISSION_RESOURCE.APIKEY, action: PERMISSION_ACTION.CREATE }) ? (
								<div className="table-buttons-dropdowns-button">
									<Button onClick={handleCreateButtonClick} type="primary">
										{t("pages.dashboard.apikeys.create_project")}
									</Button>
								</div>
							) : null}
						</div>
						<Filters />
					</div>
					<Table loading={isLoading} columns={columns} data={apiKeys} loadFn={getApikeys} sorting={sorting} setSortingFn={setApikeysSorting} total={total} updateProps={[globalPartnerId]} actions={generateAction()} isDisabled={() => false} />
				</div>
			</div>
			{showCreatePopup ? <ApiKeyAddComponent onClose={() => setShowCreatePopup(false)} /> : null}
		</Fragment>
	);
};

/** ApiKeysComponent propTypes
 * PropTypes
 */
ApiKeysComponent.propTypes = {
	/** Redux action to get api keys */
	getApikeys: PropTypes.func,
	/** Redux state property, represents the array of loaded api keys */
	apiKeys: PropTypes.arrayOf(apiKeyType),
	/** Redux state property, apiKeys total count */
	total: PropTypes.number,
	/** Redux action to set apiKeys sorting details */
	setApikeysSorting: PropTypes.func,
	/** Redux state property, apiKeys sorting details */
	sorting: sortingType,
	/** Redux state property, apiKeys filters */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, current user info */
	userInfo: userInfoType,
	/** Redux state property, is true when loading api keys */
	isLoading: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	getApikeys: (fromFirstPage) => {
		dispatch(getApikeys(fromFirstPage));
	},
	setApikeysSorting: (sorting) => {
		dispatch(setApikeysSorting(sorting));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.apikeys.isLoading,
		apiKeys: state.apikeys.apiKeys,
		total: state.apikeys.total,
		sorting: state.apikeys.sorting,
		filters: state.apikeys.filters,
		globalPartnerId: state.partner.globalPartnerId,
		userInfo: state.profile.userInfo
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeysComponent);
