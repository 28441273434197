import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { refreshToken, getUser } from "utils/auth";

import { USERINFO_ACTION_BEFORE, USERINFO_ACTION_FINISH, SET_USERINFO, UPDATE_USER_AVATAR, SET_USER_CURRENCY, SET_USER_MAINTENANCE_MODE } from "../../../actionTypes";

const setUserInfoActionBefore = () => ({
	type: USERINFO_ACTION_BEFORE
});

const setUserInfoActionFinished = () => ({
	type: USERINFO_ACTION_FINISH
});

const setUserInfo = (userInfo) => ({
	type: SET_USERINFO,
	payload: { userInfo }
});

const setUserCurrency = (currency) => ({
	type: SET_USER_CURRENCY,
	payload: { currency }
});

export const setUserInfoMaintenanceMode = (mode) => ({
	type: SET_USER_MAINTENANCE_MODE,
	payload: { mode }
});

export const updateUserAvatar = (avatarId) => ({
	type: UPDATE_USER_AVATAR,
	payload: { avatarId }
});

export const getUserInfo = (loadInBackground) => {
	return (dispatch) => {
		if (!loadInBackground) {
			dispatch(setUserInfoActionBefore());
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_USERINFO}`,
			method: Methods.GET
		})
			.then(({ data: { value: userInfo } }) => {
				dispatch(setUserInfo(userInfo));
				dispatch(setUserInfoActionFinished());
			})
			.catch(() => {
				dispatch(setUserInfoActionFinished());
			});
	};
};

export const changeUserCurrency = (currency) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CHANGE_USER_CURRENCY}`,
			method: Methods.POST,
			data: { code: currency.code }
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					const user = getUser();
					const refresh_token = user?.refreshToken ?? null;
					const userName = user?.userName;
					refreshToken(refresh_token, userName).then(() => {
						dispatch(setUserCurrency(currency));
					});
				}
			})
			.catch(Function.prototype);
	};
};
