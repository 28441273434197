import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Button } from "antd";
import Table from "components/common/table";
import PermissionGroupAddComponent from "./permissionGroup-add.component";
import userPermissionGroupType from "types/user/permissionGroup.type";

import { getUserPermissionGroups, deleteUserPermissionGroup } from "store/actions/dashboard/userManagement/users/permissionGroups.action";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { hasPermission } from "utils/permissions";
import PendingRequestsNote from "components/common/pendingRequestsNote";
import { PERMISSION_REQUEST_OBJECT_TYPE, PERMISSION_REQUEST_TYPE } from "constants/permissionRequest.constants";
import { doesUserHaveRoleOf } from "utils/auth";
import { USER_ROLE } from "constants/user.constants";

/** User Edit Page Permissions and Groups Tab Groups Sub Tab Component */
const PermissionGroupsComponent = ({ groups, getUserPermissionGroups, isLoading, userId, deleteUserPermissionGroup }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [disableDueToNumberOfRequests, setDisableDueToNumberOfRequests] = useState(false);
	const isAccessManager = doesUserHaveRoleOf(USER_ROLE.ACCESS_MANAGER);
	const accessManagerNoteActions = isAccessManager
		? {
				title: t("pages.dashboard.permissions_requests.notes"),
				isPrompt: true,
				isPromptRequired: true,
				promptLabel: t("pages.dashboard.permissions_requests.notes")
			}
		: {};

	/** State to show/hide partner add popup */
	const [showAddPopup, setShowAddPopup] = useState(false);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.permissionGroups.name"),
			dataIndex: "name",
			sorter: false
		},
		{
			title: t("pages.dashboard.permissionGroups.description"),
			dataIndex: "description",
			render: (value) => (
				<span title={value} className="table-col-shorten-text">
					{value}
				</span>
			),
			sorter: false
		}
	];

	/** Close permission group add popup, after save */
	useEffect(() => {
		setShowAddPopup(false);
	}, [groups]);

	return (
		<div className="dashboard-section-content">
			<div className="vs--flex">
				<PendingRequestsNote
					objectId={routeParams.id}
					actionTypes={[PERMISSION_REQUEST_TYPE.ADD_PERMISSION_GROUP, PERMISSION_REQUEST_TYPE.DELETE_PERMISSION_GROUP]}
					objectType={PERMISSION_REQUEST_OBJECT_TYPE.USER}
					dependencies={[groups]}
					onCountChange={(count) => {
						setDisableDueToNumberOfRequests(count > 0);
					}}
					style={{
						/* for handle scss/less style loading priority mistake by webpack */
						marginBottom: 24,
						marginRight: "auto"
					}}
				/>
				{!disableDueToNumberOfRequests && hasPermission({ resource: PERMISSION_RESOURCE.USER_PERMISSION_GROUP, action: PERMISSION_ACTION.MODIFY }) && routeParams.id !== userId ? (
					<div className="dashboard-section-buttons tb--ml-auto">
						<Button
							type="primary"
							htmlType="button"
							className="button"
							onClick={() => {
								setShowAddPopup(true);
							}}
						>
							<span>{t("pages.dashboard.users.add_new_group")}</span>
						</Button>
					</div>
				) : null}
			</div>
			<Table
				loading={isLoading}
				columns={columns}
				data={groups}
				loadFn={() => getUserPermissionGroups(routeParams.id)}
				total={groups.length}
				actions={
					hasPermission({ resource: PERMISSION_RESOURCE.USER_PERMISSION_GROUP, action: PERMISSION_ACTION.DELETE }) && routeParams.id !== userId
						? {
								delete: {
									...accessManagerNoteActions,
									messageKey: "permissionGroup",
									handler: (record, note) => {
										deleteUserPermissionGroup(routeParams.id, record.id, note, isAccessManager);
									},
									disabled: () => disableDueToNumberOfRequests
								}
							}
						: {}
				}
				isDisabled={() => false}
				noPagination={true}
			/>
			{showAddPopup ? <PermissionGroupAddComponent onClose={() => setShowAddPopup(false)} /> : null}
		</div>
	);
};

/** PermissionGroupsComponent propTypes
 * PropTypes
 */
PermissionGroupsComponent.propTypes = {
	/** Redux action to get user permission Groups */
	getUserPermissionGroups: PropTypes.func,
	/** Redux state property, represents the user permission groups  */
	groups: PropTypes.arrayOf(userPermissionGroupType),
	/** Redux state property, is true when loading user permission Groups */
	isLoading: PropTypes.bool,
	/** Redux state property, current user id */
	userId: PropTypes.string,
	/** Redux action to delete users permission group*/
	deleteUserPermissionGroup: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getUserPermissionGroups: (id) => {
		dispatch(getUserPermissionGroups(id));
	},
	deleteUserPermissionGroup: (id, groupId, note, isAccessManager) => {
		dispatch(deleteUserPermissionGroup(id, groupId, note, isAccessManager));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.users.isLoading,
		groups: state.users.editingUser.permissionGroups.groups,
		userId: state.profile.userInfo.id
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionGroupsComponent);
