import PropTypes from "prop-types";

export default PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	description: PropTypes.string,
	defaultLanguageCode: PropTypes.string,
	defaultCurrencyCode: PropTypes.string,
	secret: PropTypes.string,
	endPointURL: PropTypes.string,
	gameGroupId: PropTypes.string,
	isTesting: PropTypes.bool,
	betCount: PropTypes.number,
	totalTurnover: PropTypes.number,
	totalWin: PropTypes.number,
	totalPayout: PropTypes.number,
	totalCanceled: PropTypes.number,
	ggr: PropTypes.number,
	ngr: PropTypes.number,
	created: PropTypes.string,
	lastUpdate: PropTypes.string,
	enabled: PropTypes.bool,
	partnerId: PropTypes.string
});
