import { Fragment, useMemo } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import SideShadowBox from "components/ui/sideShadowBox";

import moment from "moment";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

const momentifyAndGetTime = (value) => moment(value).toDate().getTime();
const utcMomentifyAndFormt = (value, format) => moment.utc(value).local().format(format);

const isThereDateFilterName = (filterNames, filters, initialFilters) => {
	return filterNames.some((name) => {
		const isExistsInFilters = filters[name] && !initialFilters[name];
		if (isExistsInFilters) return isExistsInFilters;

		const isExistsInInitialFilters = !filters[name] && initialFilters[name];
		if (isExistsInInitialFilters) return isExistsInInitialFilters;

		const isExistsInBoth = filters[name] && initialFilters[name];
		if (!isExistsInBoth) return isExistsInBoth;

		return momentifyAndGetTime(filters["from"]) !== momentifyAndGetTime(initialFilters["from"]);
	});
};

/** Table Filters Tags Component */
const FilterTags = ({ filters, filtersList, initialFilters, datePickersFilterKeys, clearAll, removeFilter }) => {
	const { t } = useTranslation();

	const tagsList = useMemo(() => {
		return (filtersList || []).reduce((acc, f) => {
			if(typeof f?.hidden === "function" && f.hidden()) {
				return acc;
			}
			if (datePickersFilterKeys?.[f.name] && isThereDateFilterName(datePickersFilterKeys[f.name], filters, initialFilters)) {
				acc.push(f);
			} else if (!Array.isArray(initialFilters[f.name]) || !Array.isArray(filters[f.name])) {
				if (filters[f.name] !== initialFilters[f.name]) {
					acc.push(f);
				}
			} else if (Array.isArray(initialFilters[f.name]) && Array.isArray(filters[f.name])) {
				if (filters[f.name].sort().toString() !== initialFilters[f.name].sort().toString()) {
					acc.push(f);
				}
			}

			return acc;
		}, []);
	}, [filtersList, initialFilters, isThereDateFilterName]);

	/** Function , to get filter text
	 * @function
	 * @returns {string} - text
	 * @memberOf FilterTags
	 */
	const makeTagsText = (filter) => {
		let title = filter.title;
		let value = "";
		const createRetVal = () => `${title}: ${value}`;
		const format = filter.time ? DATE_TIME_FORMAT : DATE_FORMAT;

		if (datePickersFilterKeys?.[filter.name]) {
			value = datePickersFilterKeys[filter.name].map((key) => utcMomentifyAndFormt(filters[key], format)).join(" - ");
			return createRetVal();
		}

		if (Array.isArray(filters[filter.name])) {
			if (filters[filter.name].length > 1) {
				value = filters[filter.name].length;
			} else if (!filter["values"]) {
				value = filters[filter.name][0];
			} else {
				const foundVal = filter["values"].find((v) => v.value === filters[filter.name][0]);
				value = foundVal?.title !== undefined ? foundVal.title : filters[filter.name];
			}
			return createRetVal();
		}

		if (filter["values"]) {
			const foundVal = filter["values"].find((v) => v.value == filters[filter.name]);
			value = foundVal?.title !== undefined ? foundVal.title :  filters[filter.name];

			return createRetVal();
		}

		value = filters[filter.name];

		return createRetVal();
	};
	
	const tagsListFiltered =  tagsList.filter(tag => Object.hasOwn(filters, tag.name) && Boolean(filters[tag.name]))

	return tagsListFiltered.length > 0 ? (
		<div className="table-filters-tags">
			<div className="table-filters-tags-clear">
				<span onClick={clearAll}>{t("common.clear_all")}</span>
			</div>
			<SideShadowBox className={"table-filters-tags-list"} dependencies={filters}>
				{tagsListFiltered.map((tag) => (
					<div className="table-filters-tags-list-item" key={tag.name}>
						<span>{makeTagsText(tag)}</span>
						<i className="icon-close" onClick={() => removeFilter(tag.name)} />
					</div>
				))}
			</SideShadowBox>
		</div>
	) : (
		<Fragment />
	);
};

/** FilterTags propTypes
 * PropTypes
 */
FilterTags.propTypes = {
	/** Currenct filters */
	filters: PropTypes.object.isRequired,
	/** Initiail filters */
	initialFilters: PropTypes.object.isRequired,
	/** The array of all possible tags */
	filtersList: PropTypes.arrayOf(
		PropTypes.shape({
			/** Filter name */
			name: PropTypes.string,
			/** Filter title */
			title: PropTypes.string
		})
	),
	/**  */
	datePickersFilterKeys: PropTypes.object,
	/** Function to clear all filters */
	clearAll: PropTypes.func,
	/** Function to remove single filter */
	removeFilter: PropTypes.func
};

export default FilterTags;
