import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

const TwoFactorQRView = ({ QRsrc }) => {
	const { t } = useTranslation();

	return (
		<div className="auth-qr-code">
			<h4>{t("pages.auth.two_factor.two_factor_verification_qr")}</h4>
			{QRsrc && <img src={QRsrc} alt="QR code" />}
		</div>
	);
};

/** TwoFactorQRView propTypes
 * PropTypes
 */
TwoFactorQRView.propTypes = {
	/** QR code src */
	QRsrc: PropTypes.string
};

export default TwoFactorQRView;
