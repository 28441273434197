import { Fragment } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { refreshToken, getUser } from "utils/auth";
import { logout } from "store/actions/auth/auth.action";

/** Logout confirmation modal component, which appears when session is close to end */

const LogoutModal = ({ expires, logout }) => {
	const { t } = useTranslation();

	const textParts = t("common.token_expire_text").split("%X%");
	const text = (
		<Fragment>
			{textParts[0]}
			<b>{expires}</b>
			{textParts[1]}
		</Fragment>
	);

	/** Fires on clicking refresh button
	 * @function
	 * @memberOf LogoutModal
	 */
	const onRefresh = () => {
		const user = getUser();

		const refresh_token = user?.refreshToken ?? null;
		const userName = user?.userName ?? null;
		refreshToken(refresh_token, userName);
	};

	/** Fires on clicking logout button
	 * @function
	 * @memberOf LogoutModal
	 */
	const onLogout = () => {
		logout();
	};

	return 0 <= expires && expires <= 10 ? (
		<Modal title={t("common.token_expiration")} open={true} onOk={onRefresh} onCancel={onLogout} okText={t("common.refresh")} cancelText={t("common.logout")} centered closable={false}>
			<p>{text}</p>
		</Modal>
	) : null;
};

LogoutModal.propTypes = {
	/** Redux state property, represents seconds to access token expiration */
	expires: PropTypes.number,
	/** Redux action to logout */
	logout: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		expires: state.auth.expires
	};
};

const mapDispatchToProps = (dispatch) => ({
	logout: () => {
		dispatch(logout());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoutModal);
