import PropTypes from "prop-types";

export default PropTypes.shape({
	name: PropTypes.string,
	metrics: PropTypes.object,
	total: PropTypes.number,
	totalSuccess: PropTypes.number,
	totalFailed: PropTypes.number,
	lastTotal: PropTypes.number,
	lastTotalSuccess: PropTypes.number,
	lastTotalFailed: PropTypes.number
});
