import {
	CASHIERS_ACTION_BEFORE,
	CASHIERS_ACTION_FINISH,
	CASHIERS_ACTION_SET_CASHIERS,
	CASHIERS_SAVE_ACTION_BEFORE,
	CASHIERS_SAVE_ACTION_FINISH,
	CASHIERS_SET_SORTING,
	CASHIERS_SET_FILTERS,
	SET_CASHIER_GENERAL_INFO,
	CHANGE_CASHIER_STATE,
	CASHIERS_AVAILABLE_BETSHOPS_BEFORE,
	CASHIERS_AVAILABLE_BETSHOPS_FINISH,
	CASHIERS_SET_AVAILABLE_BETSHOPS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case CASHIERS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case CASHIERS_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case CASHIERS_ACTION_SET_CASHIERS:
			return {
				...state,
				cashiers: payload.data.item2,
				total: payload.data.item1
			};
		case CASHIERS_SAVE_ACTION_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case CASHIERS_SAVE_ACTION_FINISH:
			return {
				...state,
				isSaving: false
			};
		case CASHIERS_SET_SORTING:
			return {
				...state,
				sorting: payload.sorting
			};
		case CASHIERS_SET_FILTERS:
			return {
				...state,
				filters: payload.filters,
				sorting: {
					...state.sorting,
					page: 1
				}
			};
		case SET_CASHIER_GENERAL_INFO:
			return {
				...state,
				editingCashier: {
					...state.editingCashier,
					generalInfo: {
						...payload.info
					}
				}
			};
		case CHANGE_CASHIER_STATE:
			return {
				...state,
				cashiers: state.cashiers.map((c) => (c.id !== payload.data.id ? { ...c } : { ...payload.data }))
			};
		case CASHIERS_AVAILABLE_BETSHOPS_BEFORE:
			return {
				...state,
				isAvailableBetshopsLoading: true
			};
		case CASHIERS_AVAILABLE_BETSHOPS_FINISH:
			return {
				...state,
				isAvailableBetshopsLoading: false
			};
		case CASHIERS_SET_AVAILABLE_BETSHOPS:
			return {
				...state,
				availableBetshops: payload.betshops
			};
		default:
			return state;
	}
};
