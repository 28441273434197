import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { numberWithSpaces } from "utils/common";
import { PROJECT_TYPE } from "constants/common.constants";

import WidgetSkeleton from "components/dashboard/statistics/widgets/skeleton";

/** Dashboard Page Total GGR Percent Widget Component */
const GGRPercentageWidgetComponent = ({ totalGGRPercent, isGamesPerformanceLoading }) => {
	const { t } = useTranslation();
	return (
		<div className="dashboard-area" data-type="totalGGRPercent">
			<div className="dashboard-area-header">
				<i className="icon-dashboard-total-ggr-percent" />
				<span className="dashboard-area-header-title">{t("pages.dashboard.dashboard.ggrPercent")}</span>
			</div>
			<div className="dashboard-area-content">
				{isGamesPerformanceLoading ? (
					<WidgetSkeleton />
				) : (
					<span title={totalGGRPercent} className="dashboard-area-content-value">
						{totalGGRPercent}
					</span>
				)}
			</div>
		</div>
	);
};

/** GGRPercentageWidgetComponent propTypes
 * PropTypes
 */
GGRPercentageWidgetComponent.propTypes = {
	/** Redux state property, dashboard total ggr percent (Online / Retail) */
	totalGGRPercent: PropTypes.string,
	// Redux state controls games performance loading indicator
	isGamesPerformanceLoading: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
	let totalGGRPercent = 0;
	switch (ownProps.projectType) {
		case PROJECT_TYPE.ONLINE:
			totalGGRPercent = state.dashboard.online.gamesPerformance.totalGGRPercent || 0;
			break;
		case PROJECT_TYPE.RETAIL:
			totalGGRPercent = state.dashboard.retail.gamesPerformance.totalGGRPercent || 0;
			break;
		default:
			break;
	}
	const { minorUnit } = state.profile.userInfo.currency;
	totalGGRPercent = numberWithSpaces(totalGGRPercent.toFixed(minorUnit)) + " %";
	const isGamesPerformanceLoading = state.dashboard.isGamesPerformanceLoading;
	return { totalGGRPercent, isGamesPerformanceLoading };
};

export default connect(mapStateToProps, null)(GGRPercentageWidgetComponent);
