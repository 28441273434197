import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Tabs } from "antd";

import Breadcrumbs from "components/common/breadcrumbs";
import GeneralComponent from "../edit/sections/general";
import BoxComponent from "../edit/sections/box";
import CurrencyComponent from "../edit/sections/currency";
import OperatingHoursComponent from "../edit/sections/operatingHours";
import CustomizationComponent from "../edit/sections/customization";
import LimitSettingsComponent from "../edit/sections/limitSettings";
import BonusConfigsComponent from "../edit/sections/bonusConfigs";

import { getBetshopGeneralInfo } from "store/actions/dashboard/retail/betshops/generalInfo.action";

import { hasPermission } from "utils/permissions";
import { updateLocationHash, getHashValue } from "utils/common";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { INTEGRATION_TYPE } from "constants/betshop.constants";
import Paths from "constants/path.constants";

/** Betshop Edit Component */
const BetshopEditComponent = ({ betshopName, integrationType, getBetshopGeneralInfo }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf BetshopEditComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf BetshopEditComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	/** Load betshop general info*/
	useEffect(() => {
		if (routeParams.id) getBetshopGeneralInfo(routeParams.id);
	}, []);

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.betshops"), path: Paths.DASHBOARD_RETAIL_BETSHOPS }, { title: `${t("common.edit")} ${betshopName}` }]} />
				<Tabs
					animated={false}
					defaultActiveKey={getHashValue("tab")}
					onChange={(key) => updateLocationHash("tab=" + key)}
					items={[
						{
							key: "1",
							label: <span className={tabClassName("1")}>{t("pages.dashboard.betshops.tabs.general")}</span>,
							children: <GeneralComponent onTabChange={(status) => changeTabSavedStatus(status, "1")} />
						},
						hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_BOX, action: PERMISSION_ACTION.VIEW })
							? {
									key: "2",
									label: <span className={tabClassName("2")}>{t("pages.dashboard.betshops.tabs.box")}</span>,
									children: <BoxComponent onTabChange={(status) => changeTabSavedStatus(status, "2")} />
								}
							: null,
						hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.VIEW })
							? {
									key: "3",
									label: <span className={tabClassName("3")}>{integrationType === INTEGRATION_TYPE.STANDALONE ? t("pages.dashboard.betshops.tabs.currency_and_limits") : t("pages.dashboard.betshops.tabs.currencies")}</span>,
									children: <CurrencyComponent onTabChange={(status) => changeTabSavedStatus(status, "3")} />
								}
							: null,
						integrationType === INTEGRATION_TYPE.STANDALONE && hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_OPERATING_SETTINGS, action: PERMISSION_ACTION.VIEW })
							? {
									key: "4",
									label: <span className={tabClassName("4")}>{t("pages.dashboard.betshops.tabs.operating_hours")}</span>,
									children: <OperatingHoursComponent onTabChange={(status) => changeTabSavedStatus(status, "4")} />
								}
							: null,
						hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CUSTOMIZATION, action: PERMISSION_ACTION.VIEW })
							? {
									key: "5",
									label: <span className={tabClassName("5")}>{t("pages.dashboard.betshops.tabs.customization")}</span>,
									children: <CustomizationComponent onTabChange={(status) => changeTabSavedStatus(status, "5")} />
								}
							: null,
						integrationType === INTEGRATION_TYPE.STANDALONE && hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_LIMIT_SETTINGS, action: PERMISSION_ACTION.VIEW })
							? {
									key: "6",
									label: <span className={tabClassName("6")}>{t("pages.dashboard.betshops.tabs.limit_settings")}</span>,
									children: <LimitSettingsComponent onTabChange={(status) => changeTabSavedStatus(status, "6")} />
								}
							: null,
						hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_BONUS_CONFIGS, action: PERMISSION_ACTION.VIEW })
							? {
									key: "7",
									label: <span className={tabClassName("7")}>{t("pages.dashboard.betshops.tabs.bonus_configs")}</span>,
									children: <BonusConfigsComponent onTabChange={(status) => changeTabSavedStatus(status, "7")} />
								}
							: null
					]}
				/>
			</div>
		</Fragment>
	);
};

/** BetshopEditComponent propTypes
 * PropTypes
 */
BetshopEditComponent.propTypes = {
	/** Redux state, represents the name of current editing betshop  */
	betshopName: PropTypes.string,
	/** Redux state, represents the integration type of current editing betshop  */
	integrationType: PropTypes.oneOf(Object.values(INTEGRATION_TYPE)),
	/** Redux action to get betshop General info */
	getBetshopGeneralInfo: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		betshopName: state.betshops.editingBetshop.generalInfo.name,
		integrationType: state.betshops.editingBetshop.generalInfo.integrationType
	};
};

const mapDispatchToProps = (dispatch) => ({
	getBetshopGeneralInfo: (id) => {
		dispatch(getBetshopGeneralInfo(id));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BetshopEditComponent);
