import { Fragment } from "react";

import CashiersComponent from "components/dashboard/retail/cashierManagement/cashiers/cashiers.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const CashiersRoute = () => {
	return (
		<Fragment>
			<CashiersComponent />
		</Fragment>
	);
};

export default withPermission(CashiersRoute, { resource: PERMISSION_RESOURCE.CASHIER, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
