import PropTypes from "prop-types";

export default PropTypes.shape({
	id: PropTypes.string,
	code: PropTypes.string,
	name: PropTypes.string,
	created: PropTypes.string,
	lastUpdate: PropTypes.string,
	lastUpdatedBy: PropTypes.string,
	enabled: PropTypes.bool
});
