import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { PARTNER_ACTION_SET_GENERAL_INFO } from "../../../actionTypes";

import { setPartnerActionBefore, setPartnerActionFinished, setPartnerSaveActionBefore, setPartnerSaveActionFinished } from "./partner.action";

export const setPartnerActionSetGeneralInfo = (info) => ({
	type: PARTNER_ACTION_SET_GENERAL_INFO,
	payload: { info }
});

export const getPartnerGeneralInfo = () => {
	return (dispatch) => {
		dispatch(setPartnerActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_GENERAL_INFO}`,
			method: Methods.GET
		})
			.then(({ data: { value: info } }) => {
				dispatch(setPartnerActionSetGeneralInfo(info));
				dispatch(setPartnerActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerActionFinished());
			});
	};
};

export const savePartnerGeneralInfo = (info) => {
	return (dispatch) => {
		dispatch(setPartnerSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SET_PARTNER_GENERAL_INFO}`,
			method: Methods.POST,
			data: {
				...info
			}
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					dispatch(setPartnerActionSetGeneralInfo({ ...data.value, partnerId: info.partnerId }));
				}
				dispatch(setPartnerSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerSaveActionFinished());
			});
	};
};
