import { Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Col, Row, Modal, Tooltip, Input } from "antd";
const { Item: FormItem } = Form;

import SearchableSelect from "components/common/searchableSelect";

import { addUserPartnerAccess } from "store/actions/dashboard/userManagement/users/partners.action";

import userPartnerAccessType from "types/user/partnerAccess.type";

import { POPUP_SIZE } from "constants/common.constants";
import { doesUserHaveRoleOf } from "utils/auth";
import { USER_ROLE } from "constants/user.constants";

/** User Partner Adding Popup Component */
const PartnerAddComponent = ({ isSaving, addUserPartnerAccess, onClose, partnerAccess }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;
	const isAccessManager = doesUserHaveRoleOf(USER_ROLE.ACCESS_MANAGER);

	/** Fires when form submitted
	 * @function
	 * @memberOf PartnerAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ partnerId, note }) => {
				addUserPartnerAccess(routeParams.id, partnerId, note);
			})
			.catch(Function.prototype);
	};

	/** Fires when form submitted
	 * @function
	 * @returns Array
	 * @memberOf PartnerAddComponent
	 */
	const getAvailablePartners = () => {
		const partnerAccessPartners = partnerAccess.partners.map((p) => p.id);
		const filteredPartners = partnerAccess.availablePartners.filter((partner) => partner.id !== partnerAccess.mainPartner.id && !partnerAccessPartners.includes(partner.id));
		return filteredPartners;
	};

	return (
		<Modal open={true} title={t("pages.dashboard.users.add_new_partner")} cancelText={t("common.cancel")} okText={t("common.add")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.SMALL} maskClosable={false} closable={false} okButtonProps={{ loading: isSaving }} centered>
			<Form className="dashboard-form" colon={false} layout="vertical" requiredMark={false} form={formInstance}>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem
							label={
								<Fragment>
									<span>{t("pages.dashboard.users.select_partner_placeholder")}</span>
									<Tooltip title={t("pages.dashboard.users.partners_info")} getPopupContainer={() => document.body}>
										<i className="icon-info table-filters-info" />
									</Tooltip>
								</Fragment>
							}
							name="partnerId"
							rules={[{ required: true, message: t("validation.field_required") }]}
						>
							<SearchableSelect
								placeholder={t("pages.dashboard.users.select_partner_placeholder")}
								items={getAvailablePartners()}
								valueProp={(item) => item.id}
								textProp={(item) => (item.name === "*" ? t("common.all") : item.name)}
								renderText={(item) => (item.name === "*" ? <b>{t("common.all")}</b> : item.name)}
								getPopupContainer={() => document.body}
							/>
						</FormItem>
						{isAccessManager ? (
							<FormItem
								label={t("pages.dashboard.permissions_requests.notes")}
								name="note"
								rules={[
									{ required: true, message: t("validation.field_required") },
									{
										min: 5,
										max: 200,
										message: t("validation.field_invalid")
									}
								]}
							>
								<Input.TextArea maxLength={200} rows={4} placeholder={t("pages.dashboard.permissions_requests.notes")} />
							</FormItem>
						) : null}
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** PartnerAddComponent propTypes
 * PropTypes
 */
PartnerAddComponent.propTypes = {
	/** Redux state property, is true when user partner access is adding */
	isSaving: PropTypes.bool,
	/** Redux action to add users partner access */
	addUserPartnerAccess: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Redux state property, represents the partner access info of user  */
	partnerAccess: userPartnerAccessType
};

const mapDispatchToProps = (dispatch) => ({
	addUserPartnerAccess: (id, partnerId, note) => {
		dispatch(addUserPartnerAccess(id, partnerId, note));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.users.isSaving,
		partnerAccess: state.users.editingUser.partnerAccess
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerAddComponent);
