import { useEffect } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getPermissionRequestsCount } from "store/actions/dashboard/userManagement/requests/requests.action";

import { flagsToBinary } from "utils/common";
import { hasPermission } from "utils/permissions";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { PERMISSION_REQUESTS_MENU_KEYS, PERMISSION_REQUEST_TYPE } from "constants/permissionRequest.constants";
import Tag from "antd/es/tag";
import { Alert } from "antd";

/** Pending Requests Note Component */
const PendingRequestsNote = ({ objectId, actionTypes = [], objectType, dependencies = [], getPermissionRequestsCount, count, className = null, onCountChange = Function.prototype, style = null, ...otherProps }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const flag = flagsToBinary(actionTypes);
	const requestsCount = count[flag] ?? 0;

	const hasPermissionRequestViewPermission = hasPermission({
		resource: PERMISSION_RESOURCE.PERMISSION_REQUESTS,
		action: PERMISSION_ACTION.VIEW
	});

	const mergeDependencies = Array.isArray(dependencies) ? dependencies.concat(hasPermissionRequestViewPermission) : dependencies;

	const generateNoteMessage = () => {
		const thereIsOne = requestsCount === 1;
		const txt = t(`pages.dashboard.permissions_requests.${thereIsOne ? "pendingRequestsInfoMessage_one" : "pendingRequestsInfoMessage_many"}`);
		if (thereIsOne) {
			return txt;
		}
		return txt.replace("%%count%%", requestsCount);
	};

	useEffect(() => {
		hasPermissionRequestViewPermission && getPermissionRequestsCount(objectId, actionTypes, objectType);
	}, mergeDependencies);

	useEffect(() => onCountChange(requestsCount), [requestsCount]);

	return requestsCount > 0 ? (
		<Alert
			type="warning"
			showIcon={true}
			className={"vs--width-max-content" + (hasPermissionRequestViewPermission ? " vs--cursor-pointer" : "") + (className ? " " + className : "")}
			onClick={() => {
				if (hasPermissionRequestViewPermission) {
					navigate({
						pathname: Paths.PERMISSION_REQUESTS,
						search: `objectId=${objectId}&actionTypes=${flag}&objectType=${objectType}`,
						hash: `tab=${PERMISSION_REQUESTS_MENU_KEYS.PENDING_REQUESTS}`
					});
				}
			}}
			message={<span className="vs--worning-color">{generateNoteMessage()}</span>}
			style={style}
			{...otherProps}
		/>
	) : null;
};

/** PendingRequestsNote propTypes
 * PropTypes
 */
PendingRequestsNote.propTypes = {
	/** Object Id */
	objectId: PropTypes.string,
	/** Action Type */
	actionTypes: PropTypes.arrayOf(PropTypes.number),
	/** Object Type */
	objectType: PropTypes.number,
	/** Dependencies */
	dependencies: PropTypes.array,
	/** Redux action, to get permission requests */
	getPermissionRequestsCount: PropTypes.func,
	/** Redux state property, permission requests counts */
	count: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	getPermissionRequestsCount: (objectId, actionType, objectType) => {
		dispatch(getPermissionRequestsCount(objectId, actionType, objectType));
	}
});

const mapStateToProps = (state) => {
	return {
		count: state.permissionRequests.count
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingRequestsNote);
