const STATUSCODES = {
	400: "Bad Request",
	401: "Unauthorized",
	403: "Forbidden",
	404: "Not Found",
	200: "OK",
	500: "Internal Server Error"
};

export default STATUSCODES;
