import PropTypes from "prop-types";

export default PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	age: PropTypes.number,
	jockeyName: PropTypes.string,
	trainer: PropTypes.string,
	rank: PropTypes.number
});
