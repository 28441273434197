import { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Form, Col, Row, Modal, Input, Checkbox, Select } from "antd";
const { Item: FormItem } = Form;

import SearchableSelect from "components/common/searchableSelect";

import { createBetshop, getBetshops } from "store/actions/dashboard/retail/betshops/betshops.action";
import { getSystemTimeZones } from "store/actions/system/system.action";
import { getPartnerAvailableCurrencies } from "store/actions/dashboard/partners/currencies.action";

import Paths from "constants/path.constants";

import partnerType from "types/partner/partner.type";

import { POPUP_SIZE } from "constants/common.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { INTEGRATION_TYPE } from "constants/betshop.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import { hasPermission } from "utils/permissions";

/** Betshop Creating Popup Component */
const BetshopAddComponent = ({ isSaving, createBetshop, getBetshops, getSystemTimeZones, globalPartnerId, globalPartners, timezones, getPartnerAvailableCurrencies, availableCurrencies, isAvailableLoading, onClose }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;

	/** Load system timezones */
	useEffect(() => {
		if (timezones.length === 0) {
			getSystemTimeZones();
		}
	}, []);

	/** Load partner available currencies */
	useEffect(() => {
		getPartnerAvailableCurrencies();
	}, []);

	/** Fires when form submitted
	 * @function
	 * @memberOf BetshopAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ name, isTesting, address, timeZoneId, defaultCurrency, integrationType }) => {
				createBetshop({ name, isTesting, address, defaultCurrency, timeZoneId, integrationType }, ({ id }) => {
					if (hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.MODIFY })) {
						navigate(Paths.DASHBOARD_RETAIL_BETSHOPS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, `${id}?PI=${globalPartnerId}`));
					} else {
						getBetshops();
						onClose();
					}
				});
			})
			.catch(Function.prototype);
	};
	/** Detect if current partner is test partner
	 * @function
	 * @returns {boolean}
	 * @memberOf BetshopAddComponent
	 */
	const isCurrentPartnerTest = (globalPartners.find((p) => p.id === globalPartnerId))?.isTesting;

	return (
		<Modal open={true} title={t("pages.dashboard.betshops.create_betshop")} cancelText={t("common.cancel")} okText={t("common.create")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.SMALL} maskClosable={false} closable={false} okButtonProps={{ loading: isSaving }} centered>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					name: "",
					isTesting: isCurrentPartnerTest,
					integrationType: INTEGRATION_TYPE.STANDALONE
				}}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={`${t("pages.dashboard.betshops.integrationType")} *`} name="integrationType">
							<Select suffixIcon={<i className="icon-down" />} placeholder={`${t("common.select")} ${t("pages.dashboard.betshops.integrationType")}`} getPopupContainer={() => document.body} autoFocus={true} value={INTEGRATION_TYPE.STANDALONE} disabled>
								<Select.Option value={INTEGRATION_TYPE.STANDALONE}>{t("pages.dashboard.betshops.standalone")}</Select.Option>
								<Select.Option value={INTEGRATION_TYPE.IFRAME}>{t("pages.dashboard.betshops.iframe")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem label={`${t("pages.dashboard.betshops.name")} *`} name="name" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
							<Input maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.betshops.name")}`} autoComplete="off" />
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem
							label={`${t("pages.dashboard.betshops.address")} *`}
							name="address"
							rules={[
								{ required: true, whitespace: true, message: t("validation.field_required") },
								{ max: 100, message: t("validation.field_invalid") }
							]}
						>
							<Input maxLength={100} placeholder={`${t("common.enter")} ${t("pages.dashboard.betshops.address")}`} />
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem label={t("pages.dashboard.betshops.default_currency")} name="defaultCurrency" rules={[{ required: true, message: t("validation.field_required") }]}>
							<SearchableSelect
								loading={isAvailableLoading}
								items={Object.keys(availableCurrencies)}
								valueProp={(item) => item.toUpperCase()}
								textProp={(item) => availableCurrencies[item]}
								renderText={(item) => item.toUpperCase() + " - " + availableCurrencies[item]}
								placeholder={`${t("common.select")} ${t("pages.dashboard.betshops.default_currency")}`}
								getPopupContainer={() => document.body}
							/>
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem label={`${t("pages.dashboard.betshops.timezone")} *`} name="timeZoneId" rules={[{ required: true, message: t("validation.field_required") }]}>
							<SearchableSelect items={timezones} valueProp={(item) => item.id} textProp={(item) => item.description} renderText={(item) => item.description} placeholder={t("pages.dashboard.betshops.select_timezone")} getPopupContainer={() => document.body} />
						</FormItem>
					</Col>
					<Col span={24}>
						<div className="inline-form-checkbox">
							<label>{t("pages.dashboard.betshops.is_test_betshop")}</label>
							<FormItem className="inline-form-item-control" name="isTesting" valuePropName="checked">
								<Checkbox disabled={isCurrentPartnerTest} />
							</FormItem>
						</div>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** BetshopAddComponent propTypes
 * PropTypes
 */
BetshopAddComponent.propTypes = {
	/** Redux state property, is true when creating betshop request is in process */
	isSaving: PropTypes.bool,
	/** Redux action to create betshop */
	createBetshop: PropTypes.func,
	/** Redux action to get betshops */
	getBetshops: PropTypes.func,
	/** Redux action to get system timezones */
	getSystemTimeZones: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, represents the array of global partners  */
	globalPartners: PropTypes.arrayOf(partnerType),
	/** Redux state property, represents the array of system timezones  */
	timezones: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			description: PropTypes.string
		})
	),
	/** Redux action to get available currencies */
	getPartnerAvailableCurrencies: PropTypes.func,
	/** Redux state property, represents the object of available currencies  */
	availableCurrencies: PropTypes.object,
	/** Redux state property, is true when loading available currencies  */
	isAvailableLoading: PropTypes.bool,
	/** Fires on popup close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	createBetshop: (betshop, onSuccess) => {
		dispatch(createBetshop(betshop, onSuccess));
	},
	getBetshops: () => {
		dispatch(getBetshops(true));
	},
	getSystemTimeZones: () => {
		dispatch(getSystemTimeZones());
	},
	getPartnerAvailableCurrencies: () => {
		dispatch(getPartnerAvailableCurrencies());
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.betshops.isSaving,
		globalPartnerId: state.partner.globalPartnerId,
		globalPartners: state.partner.globalPartners,
		timezones: state.system.timezones,
		availableCurrencies: state.partner.currency.availableCurrencies,
		isAvailableLoading: state.partner.currency.isAvailableLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BetshopAddComponent);
