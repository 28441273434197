import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import CheckboxDropdown from "../checkboxDropdown";

import { exportData } from "store/actions/dashboard/export/export.action";

import { toUpperCaseFirstLetter } from "utils/common";
import { EXPORT_TYPE } from "constants/common.constants";

import sortingType from "types/common/sorting.type";

/** Table Export Button Component */
const ExportButton = ({ columns, tableName, url, exportData, filters, sorting }) => {
	const { t } = useTranslation();

	/** Function , do export
	 * @function
	 * @param {array} value - selected columns
	 * @param {string} exportType - pdf/csv
	 * @memberOf ExportButton
	 */
	const doExport = (value, exportType) => {
		const exportColumns = value.map((c) => toUpperCaseFirstLetter(c));
		exportData(url, exportType, exportColumns, tableName, filters, sorting);
	};

	return (
		<CheckboxDropdown
			columns={columns}
			buttons={[
				{ text: t("common.pdf"), onClick: (value) => doExport(value, EXPORT_TYPE.PDF) },
				{ text: t("common.csv"), onClick: (value) => doExport(value, EXPORT_TYPE.CSV) }
			]}
			title={t("common.select_rows_for_export")}
			buttonText={t("common.export")}
			buttonIcon={<i className="icon-export" />}
			buttonClassName="table-export-button"
			allowEmpty={false}
			selectAllByDefault={true}
		/>
	);
};

/** ExportButton propTypes
 * PropTypes
 */
ExportButton.propTypes = {
	/** Array od columns of table which can be exported */
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			key: PropTypes.string
		})
	),
	/** Table Name */
	tableName: PropTypes.string,
	/** Export api url */
	url: PropTypes.string,
	/** Redux action, export data */
	exportData: PropTypes.func,
	/** Table filters */
	filters: PropTypes.object,
	/** Table sorting */
	sorting: sortingType
};

const mapDispatchToProps = (dispatch) => ({
	exportData: (url, exportType, exportColumns, tableName, filters, sorting) => {
		dispatch(exportData(url, exportType, exportColumns, tableName, filters, sorting));
	}
});

export default connect(null, mapDispatchToProps)(ExportButton);
