import { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { Tag } from "antd";

import { getRequests, getRequest, setRequestsSorting } from "store/actions/dashboard/developer/requests/requests.action";

import STATUSCODES from "constants/statusCode.constants";

import Filters from "./filters.component";
import RequestDetailsComponent from "./request-details.component";
import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";

import requestType from "types/log/request.type";
import sortingType from "types/common/sorting.type";

/** Requests Page Component */
const RequestsComponent = ({ getRequests, getRequest, requests, isLoading, globalPartnerId, setRequestsSorting, sorting, total }) => {
	const { t } = useTranslation();

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.logs.controller") + "/" + t("pages.dashboard.logs.action"),
			dataIndex: "controller.action",
			render: (value, record) => <span key={record.key}>{(record?.controller ?? "-") + "/" + (record?.action ?? "-")} </span>,
			sorter: false
		},
		{
			title: "IP",
			dataIndex: "ip",
			sorter: false
		},
		{
			title: t("pages.dashboard.logs.requestTime"),
			dataIndex: "requestTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT),
			sorter: false
		},
		{
			title: t("pages.dashboard.logs.status"),
			dataIndex: "httpStatusCode",
			render: (value) => <span>{<Tag color={value === 200 ? "green" : "red"}>{value + " " + STATUSCODES[value]}</Tag>}</span>,
			sorter: false
		}
	];

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.logs_requests") }]} />
				<div className="dashboard-logs-section-content dashboard-section-content">
					<div className="table-header">
						<div className="table-buttons-dropdowns"></div>
						<Filters />
					</div>
					<Table
						loading={isLoading}
						columns={columns}
						data={requests}
						loadFn={getRequests}
						total={total}
						sorting={sorting}
						setSortingFn={setRequestsSorting}
						updateProps={[globalPartnerId]}
						isDisabled={() => false}
						details={(record) => (record.details ? Array(1) : [])}
						detailsLoadFn={(record) => (!record.details ? getRequest(record.id) : false)}
						detailsOptions={{ layout: "horizontal", column: 1, colon: ":", bordered: true }}
						detailsRender={(record) => <RequestDetailsComponent request={record} />}
					/>
				</div>
			</div>
		</Fragment>
	);
};

/** RequestsComponent propTypes
 * PropTypes
 */
RequestsComponent.propTypes = {
	/** Redux action to get requests */
	getRequests: PropTypes.func,
	/** Redux action to get request by id */
	getRequest: PropTypes.func,
	/** Redux state property, represents the array of requests */
	requests: PropTypes.arrayOf(requestType),
	/** Redux state property, is true when loading requests */
	isLoading: PropTypes.bool,
	/** Redux state property, requests total count */
	total: PropTypes.number,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux action to set requests sorting details */
	setRequestsSorting: PropTypes.func,
	/** Redux state property, requests sorting details */
	sorting: sortingType
};

const mapDispatchToProps = (dispatch) => ({
	getRequests: () => {
		dispatch(getRequests());
	},
	getRequest: (id) => {
		dispatch(getRequest(id));
	},
	setRequestsSorting: (sorting) => {
		dispatch(setRequestsSorting(sorting));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.requests.isLoading,
		requests: state.requests.requests,
		globalPartnerId: state.partner.globalPartnerId,
		sorting: state.requests.sorting,
		total: state.requests.total
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestsComponent);
