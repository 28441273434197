import { useCallback, useRef } from "react";
import PropTypes from "prop-types";

/* React hook to generate unchangeable referance function */
const useEvent = (callback) => {
	/* Create constant referance */
	const callbackRef = useRef();
	/* Direct const referance to callback */
	callbackRef.current = callback;
	/* Wrap callback with unchangeable reference function */
	return useCallback((...args) => callbackRef.current(...args), []);
};

useEvent.PropTypes = {
	/* Function with changeable reference what will wrapping unchangeable reference function  */
	callback: PropTypes.func
};

export default useEvent;
