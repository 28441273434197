import PropTypes from "prop-types";

import { PARTNER_ACTIVITY_TYPE } from "constants/partner.constants";

export default PropTypes.shape({
	id: PropTypes.number,
	changeAmount: PropTypes.number,
	type: PropTypes.oneOf(Object.values(PARTNER_ACTIVITY_TYPE)),
	total: PropTypes.number,
	changeTime: PropTypes.string,
	userName: PropTypes.string,
	userId: PropTypes.string,
	viewChangeAmount: PropTypes.number,
	viewTotal: PropTypes.number,
	balanceBefore: PropTypes.number,
	viewBalanceBefore: PropTypes.number,
	reason: PropTypes.string
});
