import PropTypes from "prop-types";
import { buildPathToStaticFolderOfCDN } from "utils/common";

/** Notification sound Component */
const NotificationSound = ({ onEnd }) => {
	return <audio autoPlay src={buildPathToStaticFolderOfCDN("media/admin/notification.mp3")} onEnded={onEnd} />;
};

/** NotificationSound propTypes
 * PropTypes
 */
NotificationSound.propTypes = {
	/** Function, which will fire on audio end  */
	onEnd: PropTypes.func
};

export default NotificationSound;
