import PropTypes from "prop-types";

import { AutoComplete } from "antd";

const validTypes = ["string", "number"];

/** Auto Suggestion Input Component, allows to select one of autocomplete options or write characters based on input type */
const AutoSuggestionInput = ({ type = "string", value: inputValue = "", onChange = Function.prototype, options, ...rest }) => {
	const onChangeHandler = (value) => {
		if (type === "number") {
			const isEqualToOneOfOptions = options.findIndex((option) => option.value === inputValue) !== -1;

			if (isEqualToOneOfOptions) {
				const valueLastChar = value.charAt(value.length - 1);
				const isSelectedValueDeleted = inputValue.startsWith(value);

				if (isSelectedValueDeleted) {
					onChange("");
				} else if (!isSelectedValueDeleted && !isNaN(valueLastChar)) {
					onChange(valueLastChar);
				}
			}
		} else if (type === "string") {
			onChange(value);
		}
	};

	const onSearchHandler = (value) => {
		const reg = /^-?[0-9]*(\.[0-9]*)?$/;

		if (validTypes.includes(type) && !isNaN(value) && reg.test(value) && !value.startsWith("-")) {
			onChange(value);
		}
	};

	const onSelectHandler = (value) => {
		onChange(value);
	};

	return <AutoComplete {...rest} value={inputValue} options={options} onChange={onChangeHandler} onSearch={onSearchHandler} onSelect={onSelectHandler}></AutoComplete>;
};

AutoSuggestionInput.propTypes = {
	/** Input value type, using for input value validation */
	type: PropTypes.oneOf(validTypes),
	/** Antd Form Item value */
	value: PropTypes.string,
	/** AutoComplete options */
	options: PropTypes.array,
	/** Antd Form Item Callback which called on input change */
	onChange: PropTypes.func
};

export default AutoSuggestionInput;
