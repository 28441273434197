import axios from "axios";
import moment from "moment";
import Methods from "constants/methods.constants";
import ApiUrls, { SPECIFIC_ENDPOINTS } from "constants/api.constants";

import { PROJECT_TYPE } from "constants/common.constants";
import { PAYMENT_TYPE } from "constants/partner.constants";
import { monthAgo } from "utils/dateTime";

import { SET_PENDING_BETSLIPS, SET_PENDING_BETSLIPS_SORTING, SET_PENDING_BETSLIPS_FILTERS } from "store/actionTypes";

import { setBetslipsActionBefore, setBetslipsActionFinished } from "./betslips.action";
import { message } from "antd";
import { RESPONSE_STATE } from "constants/response.constants";
import { DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import { assignIdsForBets } from "utils/betslips";

const setPendingBets = (data, projectType) => ({
	type: SET_PENDING_BETSLIPS,
	payload: { data, projectType }
});

export const setPendingBetsSorting = (sorting, projectType) => ({
	type: SET_PENDING_BETSLIPS_SORTING,
	payload: { sorting, projectType }
});

export const setPendingBetsFilters = (filters, projectType) => ({
	type: SET_PENDING_BETSLIPS_FILTERS,
	payload: { filters, projectType }
});

export const getPendingBets = (projectType, fromFirstPage, gameCategory = "", showBetsBySeparate = false) => {
	return (dispatch, getState) => {
		dispatch(setBetslipsActionBefore());

		const projectT = projectType === PROJECT_TYPE.ONLINE ? "online" : "retail";

		const projectIds = getState().betslips.pendings[projectT].filters.projectIds;
		const qsProjectIds = projectIds ? projectIds.map((id) => `projectIds=${id}`).join("&") : "";

		const filters = { ...getState().betslips.pendings[projectT].filters };
		delete filters["projectIds"];

		if (projectType === PROJECT_TYPE.RETAIL && filters["paymentType"] === PAYMENT_TYPE.BOTH) {
			delete filters["paymentType"];
		}

		const { GET_BETS, GET_BETS_NEW } = SPECIFIC_ENDPOINTS[projectType];

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${showBetsBySeparate ? GET_BETS_NEW : GET_BETS}?${qsProjectIds}`,
			method: Methods.GET,
			params: {
				...getState().betslips.pendings[projectT].sorting,
				...filters,
				from: moment(monthAgo(1), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				page: fromFirstPage ? 1 : getState().betslips.pendings[projectT].sorting.page,
				isPending: true,
				gameCategory
			}
		})
			.then(({ data: { value: data } }) => {
				const mappedData = assignIdsForBets(data, showBetsBySeparate)
				dispatch(setPendingBets(mappedData, projectType));
				if (fromFirstPage) dispatch(setPendingBetsSorting({ ...getState().betslips.pendings[projectT].sorting, page: 1 }));
				dispatch(setBetslipsActionFinished());
			})
			.catch(() => {
				dispatch(setBetslipsActionFinished());
			});
	};
};

export const cancelPendingBet = ({ id, betId, reason, projectType, gameCategory = "", showBetsBySeparate = false }) => {
	return (dispatch) => {
		dispatch(setBetslipsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BETS_CANCEL_BET}`,
			method: Methods.POST,
			data: { id, betId, reason }
		})
			.then(({ data: { message: msg, status } }) => {
				dispatch(getPendingBets(projectType, false, gameCategory, showBetsBySeparate));
				dispatch(setBetslipsActionFinished());
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
				} else {
					message.error(msg);
				}
			})
			.catch(() => {
				dispatch(setBetslipsActionFinished());
			});
	};
};

export const cancelPendingBetslip = ({ id, reason, projectType, gameCategory, showBetsBySeparate = false }) => {
	return (dispatch) => {
		dispatch(setBetslipsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BETS_CANCEL_BETSLIP}`,
			method: Methods.POST,
			data: { id, reason: reason }
		})
			.then(({ data: { message: msg, status } }) => {
				dispatch(getPendingBets(projectType, false, gameCategory, showBetsBySeparate));
				dispatch(setBetslipsActionFinished());
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
				} else {
					message.error(msg);
				}
			})
			.catch(() => {
				dispatch(setBetslipsActionFinished());
			});
	};
};

export const settleBetslips = ({ ids, projectType, gameCategory, showBetsBySeparate = false }) => {
	return (dispatch, getState) => {
		dispatch(setBetslipsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CORRECT_BETSLIP_STATUS}`,
			method: Methods.POST,
			data: ids
		})
			.then(({ data: { message: msg, status, value } }) => {
				dispatch(getPendingBets(projectType, false, gameCategory, showBetsBySeparate));
				if (status !== RESPONSE_STATE.SUCCESS) {
					message.error(msg);
					return;
				}
				message.success(msg);
				const projectT = projectType === PROJECT_TYPE.ONLINE ? "online" : "retail";
				const pendings = getState().betslips.pendings[projectT];
				const bets = pendings.bets;
				const updatedBetslipIds = Object.keys(value).map(Number);
				const filteredData = bets.filter((bet) => !updatedBetslipIds.includes(bet.betslipId));
				dispatch(setPendingBets({ item1: pendings.total, item2: filteredData }, projectType));
			})
			.finally(() => {
				dispatch(setBetslipsActionFinished());
			});
	};
};
