import { Fragment } from "react";

import UsersComponent from "components/dashboard/userManagement/users/users.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const UsersRoute = () => {
	return (
		<Fragment>
			<UsersComponent />
		</Fragment>
	);
};

export default withPermission(UsersRoute, { resource: PERMISSION_RESOURCE.USER, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
