import { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, message } from "antd";

import { hasPermission } from "utils/permissions";

import { getBetshops, enableDisableBetshop, setBetshopSorting, setBetshopFilters } from "store/actions/dashboard/retail/betshops/betshops.action";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import ApiUrls from "constants/api.constants";

import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import ExportButton from "components/common/exportButton";

import BetshopAddComponent from "./betshops-add.component";
import BetshopLimitComponent from "./betshops-limit.component";
import Filters from "./filters.component";

import betshopType from "types/betshop/betshop.type";
import sortingType from "types/common/sorting.type";
import currencyType from "types/common/currency.type";
import userInfoType from "types/profile/userInfo.type";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { INTEGRATION_TYPE } from "constants/betshop.constants";
import Paths from "constants/path.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import { numberWithSpaces } from "utils/common";
import { convertCurrencies, getPartnerCurrency } from "utils/currency";

/** Betshops Page Component */

const BetshopsComponent = ({ isLoading, betshops, total, getBetshops, enableDisableBetshop, sorting, filters, setBetshopSorting, setBetshopFilters, currency, globalPartnerId, currencies, userInfo }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	/** State to show/hide betshop creation popup */
	const [showCreatePopup, setShowCreatePopup] = useState(false);
	/** State to show/hide betshop balance popup */
	const [balanceBetshop, setBalanceBetshop] = useState(null);

	/** Close balance managing popup */
	useEffect(() => {
		setBalanceBetshop(null);
	}, [betshops]);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.betshops.id"),
			dataIndex: "id"
		},
		{
			title: t("pages.dashboard.betshops.name"),
			dataIndex: "name",
			render: (value, record) => (record.isTesting ? value + " (Test)" : value)
		},
		{
			title: t("pages.dashboard.betshops.code"),
			dataIndex: "code",
			sorter: false
		},
		{
			title: t("pages.dashboard.betshops.currency"),
			dataIndex: "currency",
			render: (value, record) => getPartnerCurrency(currency.code, currencies, globalPartnerId),
			sorter: false,
			disableExport: true
		},
		...(hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_BALANCE, action: PERMISSION_ACTION.VIEW })
			? [
					{
						title: t("pages.dashboard.betshops.current_limit"),
						dataIndex: "balance",
						render: (value, record) => (record.integrationType === INTEGRATION_TYPE.IFRAME ? " - " : numberWithSpaces(convertCurrencies(value, record.currencyId, currency.code, currencies, globalPartnerId)))
					}
				]
			: []),
		{
			title: t("pages.dashboard.betshops.address"),
			dataIndex: "address",
			sorter: false
		},
		{
			title: t("pages.dashboard.betshops.integrationType"),
			dataIndex: "integrationType",
			render: (value, record) => {
				let txt = "";
				switch (value) {
					case INTEGRATION_TYPE.STANDALONE:
						txt = t("pages.dashboard.betshops.standalone");
						break;
					case INTEGRATION_TYPE.IFRAME:
						txt = t("pages.dashboard.betshops.iframe");
						break;
					default:
						break;
				}
				return txt;
			}
		},
		{
			title: t("pages.dashboard.betshops.created_at"),
			dataIndex: "created",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.betshops.last_modified_at"),
			dataIndex: "lastUpdate",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		}
	];

	/** Fires on create button click
	 * @function
	 * @memberOf BetshopsComponent
	 */
	const handleCreateButtonClick = () => {
		const betShopCountLimit = (userInfo.partners || []).find((p) => p.id === globalPartnerId).betShopCountLimit;
		if (betShopCountLimit === 0) {
			setShowCreatePopup(true);
		} else if (betShopCountLimit > total) {
			setShowCreatePopup(true);
		} else {
			message.info(t("pages.dashboard.betshops.limit_reached_message"));
		}
	};

	/* Specific case, view action busy for activiti functional */
	const generateEditAction = () => {
		if (!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.VIEW })) {
			return null;
		}
		const obj = {
			handler: (record) => {
				navigate(Paths.DASHBOARD_RETAIL_BETSHOPS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, `${record.id}?PI=${record.partnerId}`));
			},
			icon: <i className="icon-eye" />
		};
		if (hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.MODIFY })) {
			delete obj["icon"];
		}
		return obj;
	};

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.betshops") }]} />
				<div className="dashboard-section-content">
					<div className="table-header">
						<div className="table-buttons-dropdowns">
							{hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.EXPORT }) ? (
								<ExportButton columns={columns.filter((c) => !c.disableExport).map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t("pages.dashboard.menu.betshops")} url={ApiUrls.EXPORT_BETSHOPS} filters={filters} sorting={sorting} />
							) : null}
							<div className="table-buttons-dropdowns-button">
								{hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.CREATE }) && (
									<Button onClick={handleCreateButtonClick} type="primary">
										{t("pages.dashboard.betshops.create_betshop")}
									</Button>
								)}
							</div>
						</div>
						<Filters />
					</div>
					<Table
						loading={isLoading}
						columns={columns}
						data={betshops}
						loadFn={getBetshops}
						sorting={sorting}
						filters={filters}
						setSortingFn={setBetshopSorting}
						setFiltersFn={setBetshopFilters}
						total={total}
						updateProps={[globalPartnerId]}
						actions={{
							activate: hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.MODIFY })
								? {
										isChecked: (record) => record.enabled,
										messageKey: "betshop",
										handler: (isChecked, record) =>
											enableDisableBetshop({
												enabled: isChecked,
												groupId: record.groupId,
												name: record.name,
												address: record.address,
												id: record.id,
												tel: record.tel,
												email: record.email,
												ticketLanguageCode: record.ticketLanguageCode,
												liveMonitorLanguageCode: record.liveMonitorLanguageCode || "EN",
												languageCode: record.languageCode,
												dst: record.dst,
												hotKeyEnabled: record.hotKeyEnabled,
												lastBetReprintEnabled: record.lastBetReprintEnabled
											})
									}
								: null,

							edit: generateEditAction(),

							view: hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_ACTIVITY, action: PERMISSION_ACTION.VIEW })
								? {
										handler: (record) => {
											navigate(Paths.DASHBOARD_RETAIL_BETSHOPS_VIEW.replace(DYNAMIC_PATH_ID_REGEX, `${record.id}?PI=${record.partnerId}`));
										},
										title: t("common.see_activity"),
										icon: <i className="icon-activity" />
									}
								: null,

							balance: hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_BALANCE, action: PERMISSION_ACTION.MODIFY })
								? {
										disabled: (record) => record.integrationType === INTEGRATION_TYPE.IFRAME,
										handler: (record) =>
											setBalanceBetshop({
												id: record.id,
												name: record.name,
												currencyId: record.currencyId
											}),
										title: t("pages.dashboard.betshops.change_limit"),
										icon: <i className="icon-balance" />
									}
								: null
						}}
						isDisabled={() => false}
						enableReload={true}
					/>
				</div>
			</div>

			{showCreatePopup ? <BetshopAddComponent onClose={() => setShowCreatePopup(false)} /> : null}

			{balanceBetshop ? <BetshopLimitComponent onClose={() => setBalanceBetshop(null)} betshop={balanceBetshop} /> : null}
		</Fragment>
	);
};

/** BetshopsComponent propTypes
 * PropTypes
 */
BetshopsComponent.propTypes = {
	/** Redux action to get betshops */
	getBetshops: PropTypes.func,
	/** Redux state property, represents the array of loaded betshops */
	betshops: PropTypes.arrayOf(betshopType),
	/** Redux state property, betshops total count */
	total: PropTypes.number,
	/** Redux state property, is true when loading betshops */
	isLoading: PropTypes.bool,
	/** Redux action to enable/disable betshop */
	enableDisableBetshop: PropTypes.func,
	/** Redux action to set betshops sorting details */
	setBetshopSorting: PropTypes.func,
	/** Redux action to set betshops filters */
	setBetshopFilters: PropTypes.func,
	/** Redux state property, betshops sorting details */
	sorting: sortingType,
	/** Redux state property, betshops filters */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, current user currency */
	currency: currencyType,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType),
	/** Redux state property, current user info */
	userInfo: userInfoType
};

const mapDispatchToProps = (dispatch) => ({
	getBetshops: (fromFirstPage) => {
		dispatch(getBetshops(fromFirstPage));
	},
	enableDisableBetshop: (betshop) => {
		dispatch(enableDisableBetshop(betshop));
	},
	setBetshopSorting: (sorting) => {
		dispatch(setBetshopSorting(sorting));
	},
	setBetshopFilters: (filters) => {
		dispatch(setBetshopFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.betshops.isLoading,
		betshops: state.betshops.betshops,
		total: state.betshops.total,
		sorting: state.betshops.sorting,
		filters: state.betshops.filters,
		currency: state.profile.userInfo.currency,
		globalPartnerId: state.partner.globalPartnerId,
		currencies: state.profile.userInfo.currencies,
		userInfo: state.profile.userInfo
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BetshopsComponent);
