import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { PARTNER_SECURITY_PASSWORD_PATTERN, PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE } from "constants/partner.constants";

import passwordSettingsType from "types/partner/passwordSettings.type";

/** Password Rules Component */
const PasswordRules = ({ passwordSettings }) => {
	const { t } = useTranslation();

	const pattern = passwordSettings.pattern ?? {};

	/** Get 'must contain' rules from password settings
	 *@returns {Array}
	 * @memberOf PasswordRules
	 */
	const getMustRules = () => (pattern.contain ?? []).filter((rule) => rule.value === PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE.MUST);

	/** Get 'must not contain' rules from password settings
	 *@returns {Array}
	 * @memberOf PasswordRules
	 */
	const getNoneRules = () => (pattern.contain ?? []).filter((rule) => rule.value === PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE.NONE);

	return (
		<label className="password-rules">
			<span style={{ fontStyle: "normal" }}>
				{t("pages.dashboard.profile.password_rules.rules_for_password")}
				<br />
			</span>
			<ul>
				<li>
					{t("pages.dashboard.profile.password_rules.must_be_between").replace("%X%", passwordSettings.passwordMinLimit).replace("%Y%", passwordSettings.passwordMaxLimit)}
					<br />
				</li>
				{pattern.type === PARTNER_SECURITY_PASSWORD_PATTERN.CONTAINS ? (
					<Fragment>
						{getMustRules().length > 0 ? (
							<li>
								{t("pages.dashboard.profile.password_rules.must_contain").replace(
									"%X%",
									getMustRules()
										.map((rule) => t(`pages.dashboard.partners.security_settings.password_settings.contain_type_${rule.type}`))
										.join(", ")
								)}{" "}
								<br />
							</li>
						) : null}
						{getNoneRules().length > 0 ? (
							<li>
								{t("pages.dashboard.profile.password_rules.must_not_contain").replace(
									"%X%",
									getNoneRules()
										.map((rule) => t(`pages.dashboard.partners.security_settings.password_settings.contain_type_${rule.type}`))
										.join(", ")
								)}{" "}
								<br />
							</li>
						) : null}
						{pattern.alsoIncludeCharacters ? (
							<li>
								{t("pages.dashboard.profile.password_rules.must_include").replace("%X%", pattern.alsoIncludeCharacters)}
								<br />
							</li>
						) : null}
					</Fragment>
				) : pattern.type === PARTNER_SECURITY_PASSWORD_PATTERN.PATTERN ? (
					<li>
						{t("pages.dashboard.profile.password_rules.must_match_regular_expression").replace("%X%", pattern.regularExpression ?? "")}
						<br />
					</li>
				) : null}
			</ul>
		</label>
	);
};

/** PasswordRules propTypes
 * PropTypes
 */
PasswordRules.propTypes = {
	/** The password settings */
	passwordSettings: passwordSettingsType
};

export default PasswordRules;
