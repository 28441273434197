import { Fragment, useEffect } from "react";
import "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col, Spin } from "antd";
import Breadcrumbs from "components/common/breadcrumbs";
import TranslationsExportActions from "./translationsExportActions";
import TranslationsUpdateActions from "./translationsUpdateActions";
import CacheActions from "./cacheActions";
import { getPostDeploymentTranslationLanguageCodes } from "store/actions/dashboard/developer/postDeploymentActions/postDeploymentActions.action";

/** PostDeploymentActions Page Component */
const PostDeploymentActions = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isLoading = useSelector((state) => state.postDeploymentActions.isLoading);

	useEffect(() => {
		dispatch(getPostDeploymentTranslationLanguageCodes());
	}, []);

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.postDeploymentActions") }]} />
				<Spin spinning={isLoading} wrapperClassName="form-spin">
					<div className="dashboard-section-content">
						<Row gutter={[40, 40]}>
							<TranslationsExportActions />
						</Row>
						<Row gutter={[40, 40]} className="vs--mt-28">
							<TranslationsUpdateActions />
						</Row>
						<Row gutter={[40, 40]} className="vs--mt-28">
							<CacheActions />
						</Row>
					</div>
				</Spin>
			</div>
		</Fragment>
	);
};

export default PostDeploymentActions;
