import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Button } from "antd";
const { Item: FormItem } = Form;

import PermissionsList from "components/dashboard/common/permissions";

import { getUserPermissions, saveUserPermissions } from "store/actions/dashboard/userManagement/users/permissions.action";

import permissionType from "types/permission/permission.type";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

/** User Edit Page Permissions and Groups Tab Permissions Sub Tab Component */

const PermissionsComponent = ({ getUserPermissions, saveUserPermissions, permissions, isLoading, isSaving, userId, onTabChange }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);

	const [initialValues, setInitialValues] = useState({});

	/** Load permissions */
	useEffect(() => {
		if (routeParams.id) {
			getUserPermissions(routeParams.id);
		}
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		const values = {};
		permissions.forEach((perm) => {
			let data = {};
			data["parent"] = {};
			perm.actions.forEach((a) => {
				data["parent"][a.action] = a.checked;
			});
			perm.subPermissions.forEach((sub) => {
				data[sub.resource] = {};
				sub.actions.forEach((a) => {
					data[sub.resource][a.action] = a.checked;
				});
			});
			values[perm.resource] = data;
		});
		setFieldsValue(values);
		setInitialValues(values);
	}, [permissions]);

	/** Fires when form submitted
	 * @function
	 * @memberOf PermissionsComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				const perms = Object.keys(data).map((resource) => ({
					resource: resource,
					actions: data[resource].parent ? Object.keys(data[resource].parent).filter((k) => data[resource]["parent"][k] === true) : [],
					subPermissions: Object.keys(data[resource])
						.filter((r) => r !== "parent")
						.map((r) => ({
							resource: r,
							actions: data[resource][r] ? Object.keys(data[resource][r]).filter((k) => data[resource][r][k] === true) : []
						}))
				}));

				saveUserPermissions({
					id: routeParams.id,
					permissions: perms
				});

				setIsFormTouched(false);
			})
			.catch((ex) => {
				console.log(ex);
			});
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<div className="dashboard-section-content">
			<Form colon={false} requiredMark={false} layout="vertical" form={formInstance} onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, initialValues))}>
				<PermissionsList
					permissions={permissions}
					isLoading={isLoading}
					formInstance={formInstance}
					initialFormValues={initialValues}
					editable={true}
					onCheckAll={() => setIsFormTouched(true)}
					disabled={!(hasPermission({ resource: PERMISSION_RESOURCE.USER_PERMISSION, action: PERMISSION_ACTION.MODIFY }) && routeParams.id !== userId)}
				/>
				{hasPermission({ resource: PERMISSION_RESOURCE.USER_PERMISSION, action: PERMISSION_ACTION.MODIFY }) && routeParams.id !== userId && (
					<FormItem className="button-container">
						<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={() => handleForm()} disabled={!isFormTouched}>
							<span>{t("common.save")}</span>
						</Button>
					</FormItem>
				)}
			</Form>
		</div>
	);
};

/** PermissionsComponent propTypes
 * PropTypes
 */
PermissionsComponent.propTypes = {
	/** Redux action to get user permissions */
	getUserPermissions: PropTypes.func,
	/** Redux action to save user permissions */
	saveUserPermissions: PropTypes.func,
	/** Redux state property, represents the array of user permissions */
	permissions: PropTypes.arrayOf(permissionType),
	/** Redux state property, is true when loading user permissions */
	isLoading: PropTypes.bool,
	/** Redux state property, is true when saving user permissions */
	isSaving: PropTypes.bool,
	/** Redux state property, current user id */
	userId: PropTypes.string,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getUserPermissions: (id) => {
		dispatch(getUserPermissions(id));
	},
	saveUserPermissions: (permissions) => {
		dispatch(saveUserPermissions(permissions));
	}
});

const mapStateToProps = (state) => {
	return {
		permissions: state.users.editingUser.permissions,
		isSaving: state.users.isSaving,
		isLoading: state.users.isLoading,
		userId: state.profile.userInfo.id
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsComponent);
