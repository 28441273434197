import { Fragment } from "react";

import StreamServersComponent from "components/dashboard/developer/streamServers/streamServers.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

const StreamServersRoute = () => {
	return (
		<Fragment>
			<StreamServersComponent />
		</Fragment>
	);
};

export default withPermission(StreamServersRoute, { resource: PERMISSION_RESOURCE.DEVELOPMENT_STREAM_SERVERS, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
