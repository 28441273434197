import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Table from "components/common/table";
import Flag from "components/common/flag";
import NumericInput from "components/common/numericInput";
import { Form, Button } from "antd";
import { getPenaltyShootTeamsAndRatings, savePenaltyShootTeamsAndRatings } from "store/actions/dashboard/virtuals/games/probabilities.action";
import { hasPermission } from "utils/permissions";
import { isFormChanged } from "utils/form";
import systemFootballTeams from "systemData/footballTeams";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { PENALTY_SHOOTOUT_TEAMS_AND_RATINGS_VALIDATION_COEFFICIENT } from "constants/game.constants";
import gameGeneralInfoType from "types/game/generalInfo.type";

const { Item: FormItem } = Form;
const [MIN_RAITING, MAX_RAITING] = PENALTY_SHOOTOUT_TEAMS_AND_RATINGS_VALIDATION_COEFFICIENT;

const TeamsAndRatings = ({ getPenaltyShootTeamsAndRatings, savePenaltyShootTeamsAndRatings, isSaving, generalInfo, penaltyTeams }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { setFieldsValue, validateFields } = formInstance;
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.GAME_PROBABILITIES, action: PERMISSION_ACTION.MODIFY });
	const [isFormTouched, setIsFormTouched] = useState(false);

	const toLineKey = (record) => `${record.countryCode}_${record.totalPoints}`;

	const mapDataForForm = (penaltyTeamsArg) => {
		return penaltyTeamsArg.reduce((acc, team) => {
			acc[toLineKey(team)] = `${team.totalPoints}`;
			return acc;
		}, {});
	};

	/** Fires when form submitted
	 * @function
	 * @memberOf TeamsAndRatings
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				savePenaltyShootTeamsAndRatings(
					generalInfo.id,
					penaltyTeams.map((penaltyTeam) => {
						return {
							...penaltyTeam,
							totalPoints: Number(data[toLineKey(penaltyTeam)])
						};
					})
				);
				setIsFormTouched(false);
			})
			.catch((ex) => {
				console.log(ex);
			});
	};

	/** Check is form changed
	 * @function
	 * @param {object} formValues - form current values
	 * @returns {boolean}
	 * @memberOf function
	 */
	const formChanged = (formValues) => {
		return isFormChanged(formValues, mapDataForForm([...penaltyTeams]));
	};

	const columns = [
		{
			title: t("pages.dashboard.games.probabilities.team"),
			dataIndex: "countryCode",
			render: (value) => {
				return (
					<div className="vs--flex">
						<Flag code={value} gameType={generalInfo.type} />
						<span>{systemFootballTeams[generalInfo.type][value.toLowerCase()]}</span>
					</div>
				);
			},
			sorter: false
		},
		{
			title: t("pages.dashboard.games.probabilities.team_ratings"),
			dataIndex: "totalPoints",
			render: (_, record) => {
				const name = toLineKey(record);
				return (
					<FormItem
						className="inline-form-item-control team_ratings_probablities"
						name={name}
						rules={[
							{ required: true, whitespace: true, message: t("validation.field_required") },
							() => ({
								message: t("validation.must_be_between").replace("%X%", MIN_RAITING).replace("%Y%", MAX_RAITING),
								validator(rule, value) {
									const numericValue = Number(value);
									if (numericValue < MIN_RAITING || MAX_RAITING < numericValue) {
										return Promise.reject();
									}
									return Promise.resolve();
								}
							})
						]}
						validateFirst
					>
						<NumericInput disabled={isDisabled} />
					</FormItem>
				);
			},
			sorter: false
		}
	];

	useEffect(() => {
		getPenaltyShootTeamsAndRatings(routeParams.id);
	}, []);

	useEffect(() => {
		if (!penaltyTeams || !penaltyTeams.length) {
			return;
		}
		setFieldsValue(mapDataForForm([...penaltyTeams]));
	}, [penaltyTeams]);

	return (
		<div className="dashboard-section table-section">
			<Form initialValues={undefined} form={formInstance} layout="vertical" colon={false} disabled={isDisabled} onValuesChange={(changed, formValues) => setIsFormTouched(formChanged({ ...formValues }))}>
				<Table loading={false} columns={columns} data={penaltyTeams} pagination={false} />
				{
					<FormItem className="button-container">
						<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={isDisabled || !isFormTouched}>
							<span>{t("common.save")}</span>
						</Button>
					</FormItem>
				}
			</Form>
		</div>
	);
};

TeamsAndRatings.propTypes = {
	/** Redux action to get Penalty shoot teams and Ratings */
	getPenaltyShootTeamsAndRatings: PropTypes.func,
	/** Redux state, represents the general info of current editing game */
	generalInfo: gameGeneralInfoType,
	/** Redux state property, is true when Penalty shoot teams and Ratings is loading */
	isLoading: PropTypes.bool,
	/** Redux state property, is true when Penalty shoot teams and Ratings is saving */
	isSaving: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	getPenaltyShootTeamsAndRatings: (id) => {
		dispatch(getPenaltyShootTeamsAndRatings(id));
	},
	savePenaltyShootTeamsAndRatings: (id, teams) => {
		dispatch(savePenaltyShootTeamsAndRatings(id, teams));
	}
});

const mapStateToProps = (state) => {
	return {
		generalInfo: state.games.editingGame.generalInfo,
		isLoading: state.games.isLoading,
		isSaving: state.games.isSaving,
		penaltyTeams: state.games.editingGame.probabilities.penaltyTeams.teams
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsAndRatings);
