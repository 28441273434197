import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { SYSTEM_BETSHOPS_BEFORE, SYSTEM_BETSHOPS_FINISH, SYSTEM_SET_BETSHOPS } from "../../actionTypes";

export const getSystemCountries = () => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SYSTEM_COUNTRIES}`,
			method: Methods.GET
		})
			.then(({ data: countries }) => {
				console.log(countries);
			})
			.catch((err) => console.log(err));
	};
};

export const getSystemFootballTeams = () => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SYSTEM_FOOTBALL_TEAMS}`,
			method: Methods.GET
		})
			.then(({ data: teams }) => {
				console.log(JSON.stringify(teams));
			})
			.catch((err) => console.log(err));
	};
};

export const getSystemMarkets = () => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SYSTEM_MARKETS}`,
			method: Methods.GET
		})
			.then(({ data: markets }) => {
				console.log(JSON.stringify(Object.keys(markets)));
			})
			.catch((err) => console.log(err));
	};
};

/** Timezones */

const setSystemTimeZonesBefore = () => ({
	type: SYSTEM_BETSHOPS_BEFORE
});

const setSystemTimeZonesFinished = () => ({
	type: SYSTEM_BETSHOPS_FINISH
});

const setSystemTimeZones = (timezones) => ({
	type: SYSTEM_SET_BETSHOPS,
	payload: { timezones }
});

export const getSystemTimeZones = () => {
	return (dispatch) => {
		dispatch(setSystemTimeZonesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SYSTEM_TIMEZONES}`,
			method: Methods.GET
		})
			.then(({ data: timezones }) => {
				dispatch(setSystemTimeZones(timezones));
				dispatch(setSystemTimeZonesFinished());
			})
			.catch((err) => {
				dispatch(setSystemTimeZonesFinished());
			});
	};
};
