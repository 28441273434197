import PropTypes from "prop-types";

import { MAINTENANCE_MODE_DURATION_TYPE, MAINTENANCE_MODE_CHANGE_REASON } from "constants/settings.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	maintenanceMode: PropTypes.bool,
	durationType: PropTypes.oneOf(Object.values(MAINTENANCE_MODE_DURATION_TYPE)),
	reason: PropTypes.oneOf(Object.values(MAINTENANCE_MODE_CHANGE_REASON)),
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	lastUpdatedDate: PropTypes.string,
	lastUpdatedBy: PropTypes.string
});
