import { Fragment } from "react";

import ForgotPasswordComponent from "components/auth/forgotPassword/forgotPassword.component";

const ForgotPasswordRoute = () => {
	return (
		<Fragment>
			<ForgotPasswordComponent />
		</Fragment>
	);
};

export default ForgotPasswordRoute;
