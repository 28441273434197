import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form, Row, Col } from "antd";
const { Item: FormItem } = Form;
import Table from "components/common/table";
import NumericInput from "components/common/numericInput";
import { numberTransform, combinations } from "utils/common";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import rtpType from "types/rtp/rtp.type";

/** Game Edit Page Default RTP Tab Common RTP Component */
const KenoRTPComponent = ({ isLoading, rtp, getRTPs, setIsFormTouched, formInstance, currentValues }) => {
	const { t } = useTranslation();

	const { setFieldsValue, getFieldValue } = formInstance;
	const [data, setData] = useState([]);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.GAME_RTP, action: PERMISSION_ACTION.MODIFY });

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		let d = Array.from(Array(11).keys()).map((i) => ({}));

		rtp.rtPs.forEach((r) => {
			const name = r.name;
			const index = r.name.split("-")[0];
			const col = r.name.split("-")[1];
			d[index]["col_" + col] = { ...r };
		});

		d.forEach((i, index) => {
			d[index]["col_0"] = index;
		});
		setData(d);
	}, [rtp.rtPs]);

	/** Calculate margin
	 * @function
	 * @param {Object} value
	 * @returns {number}
	 * @memberOf KenoRTPComponent
	 */
	const calculateMargin = (value) => {
		if (!value || !value.name) return 0;
		const valueX = Number(value.name.split("-")[0]);
		const valueY = Number(value.name.split("-")[1]);

		const v = Number(currentValues?.rtPs?.[`${value.name}_${value.period}`]?.margin ?? 1);
		const res = (combinations(80 - valueY, 20 - valueX) / combinations(80, 20)) * v * combinations(valueY, valueX);
		return res.toFixed(4);
	};

	/** Columns of table */
	const columns = Array.from(Array(11).keys()).map((column) => ({
		title: column === 0 ? "" : column,
		dataIndex: "col_" + column,
		sorter: false,
		render: (value, record) =>
			column === 0 ? (
				value
			) : !value ? (
				""
			) : !value.enabled ? (
				""
			) : (
				<FormItem
					className="inline-form-item-control table-form-control ant-table-small-input"
					style={{ marginLeft: 0 }}
					name={["rtPs", value.name + "_" + value.period, "margin"]}
					rules={[
						{ required: true, message: t("validation.field_required") },
						{
							type: "number",
							min: 1,
							message: t("validation.must_be_more").replace("%X%", 1),
							transform: numberTransform
						},
						{
							type: "number",
							max: 10000,
							message: t("validation.must_be_less").replace("%X%", 10000),
							transform: numberTransform
						}
					]}
				>
					<NumericInput style={{ maxWidth: "128px" }} addonAfter={calculateMargin(value)} disabled={isDisabled} />
				</FormItem>
			),
		width: column !== 0 ? "152px" : "48px",
		align: "center"
	}));

	return (
		<Row gutter={[16, 0]}>
			<Col span={24}>
				<Table loading={isLoading} columns={columns} data={data} loadFn={() => getRTPs()} noPagination={true} uniqueKey="col_0" nonFixed={true} className="ant-table-small" tableLayout="fixed" isDisabled={() => isDisabled} />
			</Col>
		</Row>
	);
};

/** KenoRTPComponent propTypes
 * PropTypes
 */
KenoRTPComponent.propTypes = {
	/** Is true when rtps are loading */
	isLoading: PropTypes.bool,
	/** Function to get rtps */
	getRTPs: PropTypes.func,
	/** Redux state, represents the rtp data   */
	rtp: rtpType,
	/** Function to update form touched/untouched state */
	setIsFormTouched: PropTypes.func,
	/** The form object */
	formInstance: PropTypes.object,
	/** Current Values of the form */
	currentValues: PropTypes.object
};

export default KenoRTPComponent;
