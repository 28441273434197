import { DEFAULT_PARTNER_ID, UPDATING_TRANSLATION_TYPE_GROUP_IDS } from "constants/developerSpacePostDeploymentActions.constants";

/** Function to validate translations array
 * @function
 * @param {array.<object>} translations of translation objects
 * @returns {Object}
 */
export const validateTranslations = (translations, type, validLanguages) => {
	const groupIds = UPDATING_TRANSLATION_TYPE_GROUP_IDS[type];
	if (!groupIds) {
		return "Invalid Translation Type.";
	}
	for (let i = 0; i < translations.length; i++) {
		const current = translations[i];

		if (current.createdAt) {
			return "Found Invalid property: CreatedAt";
		}
		if (current.modifiedAt) {
			return "Found Invalid property: ModifiedAt";
		}

		if (!validLanguages.includes(current.languageCode)) {
			return `Invalid LanguageCode: ${current.languageCode}`;
		}

		if (DEFAULT_PARTNER_ID !== current.partnerId) {
			return `Invalid PartnerId: ${current.partnerId}`;
		}

		const isValidGroups = groupIds.some((group) => {
			return current.groupId === group.id;
		});

		if (!isValidGroups) {
			return "Invalid Group Ids and Sub Groups Ids.";
		}
	}
	return null;
};
