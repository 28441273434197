import PropTypes from "prop-types";

export default PropTypes.shape({
	id: PropTypes.number,
	betTime: PropTypes.string,
	projectId: PropTypes.string,
	lastStatus: PropTypes.number,
	lastActionTime: PropTypes.string,
	lastActionBy: PropTypes.string,
	history: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			betTime: PropTypes.string,
			projectId: PropTypes.string,
			lastStatus: PropTypes.number,
			lastActionTime: PropTypes.string,
			lastActionBy: PropTypes.string
		})
	)
});
