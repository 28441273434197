import PropTypes from "prop-types";

import { FOOTBALL_PROBABILITIES } from "constants/game.constants";

export default PropTypes.shape({
	goalScenes: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.oneOf(Object.values(FOOTBALL_PROBABILITIES).map((p) => p.value)),
			percent: PropTypes.number
		})
	),
	noGoalScenes: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.oneOf(Object.values(FOOTBALL_PROBABILITIES).map((p) => p.value)),
			percent: PropTypes.number
		})
	)
});
