import { MONITORING_ACTION_BEFORE, MONITORING_ACTION_FINISH, MONITORING_ACTION_SERVICE_BEFORE, MONITORING_ACTION_SERVICE_FINISH, MONITORING_SET_SERVICE, MONITORING_SET_SERVICES } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case MONITORING_ACTION_BEFORE:
			return {
				...state,
				isServicesLoading: true
			};
		case MONITORING_ACTION_FINISH:
			return {
				...state,
				isServicesLoading: false
			};
		case MONITORING_ACTION_SERVICE_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case MONITORING_ACTION_SERVICE_FINISH:
			return {
				...state,
				isLoading: false
			};
		case MONITORING_SET_SERVICE:
			return {
				...state,
				service: payload.service
			};
		case MONITORING_SET_SERVICES:
			return {
				...state,
				services: payload.services
			};
		default:
			return state;
	}
};
