const PATHS = {
	DEFAULT: "/",
	LOGIN: "/login",
	ACTIVATE: "/activate",
	RESET_PASSWORD: "/resetPassword",
	FORGOT_PASSWORD: "/forgotPassword",
	DASHBOARD: "/",
	DASHBOARD_STATISTICS: "/statistics",
	DASHBOARD_PERMISSIONS: "/users/permissions",
	DASHBOARD_PROFILE: "/profile",
	DASHBOARD_PARTNERS: "/partners",
	DASHBOARD_PARTNERS_EDIT: "/partners/edit/:id",
	DASHBOARD_PARTNERS_VIEW: "/partners/view/:id",
	DASHBOARD_USERMANAGEMENT_USERS: "/userManagement/users",
	DASHBOARD_USERMANAGEMENT_USERS_EDIT: "/userManagement/users/edit/:id",
	DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS: "/userManagement/permissionGroups",
	DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS_EDIT: "/userManagement/permissionGroups/edit/:id",
	DASHBOARD_USERMANAGEMENT_PERMISSIONS: "/userManagement/permissions",
	ACCESS_MANAGERS: "/userManagement/accessManagers",
	ACCESS_MANAGERS_EDIT: "/userManagement/accessManagers/edit/:id",
	PERMISSION_REQUESTS: "/userManagement/permissionRequests",

	DASHBOARD_RISK_MANAGEMENT_BIG_MULTIPLIERS: "/risk-management/big-multipliers",
	DASHBOARD_RISK_MANAGEMENT_BET_ANOMALIES: "/risk-management/bet-anomalies",
	DASHBOARD_RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES: "/risk-management/player-suspicious-activities",

	DASHBOARD_ONLINE_APIKEYS: "/online/apikeys",
	DASHBOARD_ONLINE_APIKEYS_EDIT: "/online/apikeys/edit/:id",
	DASHBOARD_EVENTS_ONLINE: "/eventsOnline",
	DASHBOARD_EVENTS_ONLINE_VIEW: "/eventsOnline/view/:gameCategory/:id",
	DASHBOARD_EVENTS_RETAIL: "/eventsRetail",
	DASHBOARD_EVENTS_RETAIL_VIEW: "/eventsRetail/view/:gameCategory/:id",
	DASHBOARD_ONLINE_PLAYERS: "/online/players",
	DASHBOARD_ONLINE_PLAYERS_VIEW: "/online/players/view/:id",
	DASHBOARD_BETSLIPS_ONLINE: "/betslipsOnline",
	DASHBOARD_BETSLIPS_RETAIL: "/betslipsRetail",
	DASHBOARD_DEVELOPER_OPERATORS: "/developer/operators",
	DASHBOARD_DEVELOPER_ERRORS: "/developer/errors",
	DASHBOARD_DEVELOPER_ERROR_DETAILS: "/developer/errors/:id",
	DASHBOARD_DEVELOPER_REQUESTS: "/developer/requests",
	DASHBOARD_DEVELOPER_JOBS: "/developer/jobs",
	DASHBOARD_DEVELOPER_STREAMS: "/developer/streams",
	DASHBOARD_DEVELOPER_POST_DEPLOYMENT_ACTIONS: "/developer/postDeploymentActions",
	//#region unused functionality
	// DASHBOARD_DEVELOPER_STREAM_SERVERS: "/developer/streamServers",
	//#endregion
	DASHBOARD_DEVELOPER_BETSLIP_CORRECTION: "/developer/betCorrections",

	DASHBOARD_DEVELOPER_MONITORING: "/developer/monitoring/:name",
	DASHBOARD_SCHEDULED_GAMES: "/scheduledGames",
	DASHBOARD_SCHEDULED_GAMES_EDIT: "/scheduledGames/edit/:id",
	DASHBOARD_INSTANT_GAMES: "/instantGames",
	DASHBOARD_INSTANT_GAMES_EDIT: "/instantGames/edit/:id",
	DASHBOARD_BOXES: "/retail/boxes",
	DASHBOARD_BOXES_EDIT: "/retail/boxes/edit/:id",
	DASHBOARD_BONUSES_STANDARD: "/bonuses-standard",
	DASHBOARD_BONUSES_STANDARD_EDIT: "/bonuses-standard/edit/:id",
	DASHBOARD_BONUSES_JACKPOT: "/bonuses-jackpot",
	DASHBOARD_BONUSES_JACKPOT_EDIT: "/bonuses-jackpot/edit/:id",
	DASHBOARD_RETAIL_BETSHOPS: "/retail/betshops",
	DASHBOARD_RETAIL_BETSHOPS_EDIT: "/retail/betshops/edit/:id",
	DASHBOARD_RETAIL_BETSHOPS_VIEW: "/retail/betshops/view/:id",
	DASHBOARD_RETAIL_CASHIER_MANAGEMENT_CASHIERS: "/retail/cashierManagement/cashiers",
	DASHBOARD_RETAIL_CASHIER_MANAGEMENT_CASHIERS_EDIT: "/retail/cashierManagement/cashiers/edit/:id",
	DASHBOARD_TRANSACTIONS: "/transactions",
	DASHBOARD_USER_LOGS: "/userLogs",
	DASHBOARD_SETTINGS_CURRENCIES: "/settings/currencies",
	DASHBOARD_SETTINGS_LANGUAGES: "/settings/languages",
	DASHBOARD_REPORTS_PROJECTS_PERFORMANCE: "/reports/projectPerformance",
	DASHBOARD_REPORTS_BETSHOPS_PERFORMANCE: "/reports/betshopPerformance",
	DASHBOARD_REPORTS_PARTNER_ONLINE: "/reports/partnerOnlineReport",
	DASHBOARD_CMS_TRANSLATIONS: "/cms/translations",
	DASHBOARD_CMS_LIVE_MONITOR_TRANSLATIONS: "/cms/liveMonitorTranslations",
	DASHBOARD_CMS_RULES: "/cms/rules",
	DASHBOARD_CMS_SCHEDULED_RULES_EDIT: "/cms/rules/scheduled/edit/:id",
	DASHBOARD_CMS_INSTANT_RULES_EDIT: "/cms/rules/instant/edit/:id",
	DASHBOARD_CMS_TICKETS: "/cms/tickets",
	DASHBOARD_CMS_HOTKEYS: "/cms/hotkeys",
	DASHBOARD_SETTINGS_MAINTENANCEMODE: "/settings/maintenanceMode",
	DASHBOARD_USERS_FEEDBACK_CSAT: "/usersFeedback/CSAT",
	DASHBOARD_USERS_FEEDBACK_CSAT_EDIT: "/usersFeedback/CSAT/edit/:id",
	DASHBOARD_STREAMS: "/streams",
	DASHBOARD_STREAMS_EDIT: "/streams/edit/:id"
};

export default PATHS;
