import PropTypes from "prop-types";

import { LOGIN_CONFIGURATION_TYPE } from "constants/apiKey.constants";

export default PropTypes.shape({
	parameters: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			value: PropTypes.string
		})
	),
	launchUrl: PropTypes.string
});
