import { STREAMS_BEFORE, STREAMS_FINISH, STREAMS_SET_SORTING, SET_STREAMS, SET_STREAMS_GENERAL_INFO, ADD_STREAM, SET_AVAILABLE_GAMES } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case STREAMS_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case STREAMS_FINISH:
			return {
				...state,
				isLoading: false
			};
		case STREAMS_SET_SORTING:
			return {
				...state,
				sorting: payload.sorting
			};
		case SET_STREAMS:
			return {
				...state,
				total: payload.streams.item1,
				streams: payload.streams.item2
			};
		case ADD_STREAM:
			return {
				...state,
				total: state.total + 1,
				streams: state.streams.concat(payload.stream)
			};
		case SET_STREAMS_GENERAL_INFO:
			return {
				...state,
				generalInfo: payload.generalInfo
			};
		case SET_AVAILABLE_GAMES:
			return {
				...state,
				availableGames: payload.availableGames
			};
		default:
			return state;
	}
};
