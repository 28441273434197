import { useParams } from "react-router-dom";

import BetDBCacheCompare from "components/dashboard/common/betDBCacheCompare";

import { PROJECT_TYPE } from "constants/common.constants";
import { PERMISSION_RESOURCE } from "constants/permissions.constants";

/** Game Edit Page General Info Tab Component */
const DBCacheCompareComponent = () => {
	const routeParams = useParams();

	return <BetDBCacheCompare id={routeParams.id} projectType={PROJECT_TYPE.ONLINE} permission={PERMISSION_RESOURCE.PLAYER_DBCACHECOMPARE} />;
};

export default DBCacheCompareComponent;
