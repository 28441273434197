import { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Select, Input } from "antd";
import { getRequests, setRequestFilters } from "store/actions/dashboard/developer/requests/requests.action";
import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import useAutosuggestion from "hooks/useAutosuggestion";
import { FILTER_REQUEST_API_TYPE } from "constants/filter.constants";
import STATUSCODES from "constants/statusCode.constants";
import { MONTHS, YEARS } from "constants/common.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
const { Item: FormItem } = Form;

/** Error/Request Logs Page Filters Component */
const Filters = ({ setRequestFilters, getRequests, filters, globalPartnerId }) => {
	const { t } = useTranslation();
	const userNames = useAutosuggestion(AUTOSUGGESTION_TYPE.USER_NAMES, [globalPartnerId], { disableAction: () => !globalPartnerId });
	const controllers = useAutosuggestion(AUTOSUGGESTION_TYPE.CONTROLLERS, [globalPartnerId], { disableAction: () => !globalPartnerId });

	const filtersList = [
		{ name: "userId", title: t("pages.dashboard.users.user_name_or_ID") },
		{ name: "controller", title: t("pages.dashboard.logs.controller") },
		{ name: "action", title: t("pages.dashboard.logs.action") },
		{
			name: "aPIType",
			title: t("pages.dashboard.logs.type"),
			values: [
				{ title: t("pages.dashboard.logs.filter_admin"), value: FILTER_REQUEST_API_TYPE.ADMIN },
				{ title: t("pages.dashboard.logs.filter_site"), value: FILTER_REQUEST_API_TYPE.SITE },
				{ title: t("pages.dashboard.logs.filter_cashier"), value: FILTER_REQUEST_API_TYPE.CASHIER }
			]
		},
		{
			name: "month",
			title: t("common.month"),
			values: Object.values(MONTHS).map((m) => ({ title: t("months.month_" + m), value: m }))
		},
		{
			name: "year",
			title: t("common.year"),
			values: YEARS.map((y) => ({ title: y, value: y }))
		},
		{
			name: "statusCode",
			title: t("pages.dashboard.logs.status"),
			values: [{ title: t("common.all"), value: "" }].concat(Object.keys(STATUSCODES).map((c) => ({ title: c + " " + STATUSCODES[c], value: c })))
		}
	];

	return (
		<FiltersWrapper filtersName="requests" loadFn={getRequests} setFiltersFn={setRequestFilters} filters={filters} filtersList={filtersList}>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.users.user_name_or_ID")} name="userId">
							<AutoComplete placeholder={`${t("common.start_typing")} ${t("pages.dashboard.logs.user_name_or_ID")}`} items={userNames.map((k) => k.value)} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.logs.controller")} name="controller">
							<AutoComplete placeholder={`${t("common.start_typing")} ${t("pages.dashboard.logs.controller")}`} items={controllers.map((k) => k.value)} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.logs.action")} name="action">
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.logs.action")}`} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.logs.type")} name="aPIType">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_REQUEST_API_TYPE.ADMIN}>{t("pages.dashboard.logs.filter_admin")}</Select.Option>
								<Select.Option value={FILTER_REQUEST_API_TYPE.SITE}>{t("pages.dashboard.logs.filter_site")}</Select.Option>
								<Select.Option value={FILTER_REQUEST_API_TYPE.CASHIER}>{t("pages.dashboard.logs.filter_cashier")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.month")} name="month">
							<Select suffixIcon={<i className="icon-down" />}>
								{Object.values(MONTHS).map((m) => (
									<Select.Option value={m} key={m}>
										{t("months.month_" + m)}
									</Select.Option>
								))}
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.year")} name="year">
							<Select suffixIcon={<i className="icon-down" />}>
								{YEARS.map((y) => (
									<Select.Option value={y} key={y}>
										{y}
									</Select.Option>
								))}
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.logs.status")} name="statusCode">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value="">{t("common.all")}</Select.Option>
								{Object.keys(STATUSCODES).map((k) => (
									<Select.Option value={k} key={k}>
										{k + " " + STATUSCODES[k]}
									</Select.Option>
								))}
							</Select>
						</FormItem>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get requests */
	getRequests: PropTypes.func,
	/** Redux action to set requests filters */
	setRequestFilters: PropTypes.func,
	/** Redux state property, requests filters */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getRequests: () => {
		dispatch(getRequests());
	},
	setRequestFilters: (filters) => {
		dispatch(setRequestFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.requests.filters,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
