import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Radio } from "antd";

import Table from "components/common/table";
import Question from "components/common/question";
import currencyType from "types/currency/currency.type";

/** Currecies Tab Component */
const CurrenciesComponent = ({ currencies, getCurrencies, isLoading, updateCurrency, deleteCurrency, CurrencyEditComponent, entity, editable }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	/** State to show/hide currecny edit popup */
	const [showEditPopup, setShowEditPopup] = useState(false);
	const [editingCurrency, setEditingCurrency] = useState(null);
	const [defaultCurrency, setDefaultCurrency] = useState(null);
	const [handlingCurrency, setHandlingCurrency] = useState(null);

	/** Columns of table */
	const columns = [
		{
			title: "",
			dataIndex: "isDefault",
			render: (value, record) => <Radio value={record.code} disabled={!record.enabled} style={{ margin: 0 }} defaultChecked={value}></Radio>,
			sorter: false,
			className: "ant-table-radio-cell"
		},
		{
			title: t("pages.dashboard.currency.name"),
			dataIndex: "name",
			sorter: false
		},
		{
			title: t("pages.dashboard.currency.code"),
			dataIndex: "code",
			sorter: false
		},
		{
			title: t("pages.dashboard.currency.fav_bets"),
			dataIndex: "favoriteBets",
			render: (value) => value.join(", "),
			sorter: false
		},
		{
			title: t("pages.dashboard.currency.min_single"),
			dataIndex: "singleMin",
			render: (value, record) => record.betLimits.singleMin,
			sorter: false
		},
		{
			title: t("pages.dashboard.currency.max_single"),
			dataIndex: "singleMax",
			render: (value, record) => record.betLimits.singleMax,
			sorter: false
		},
		{
			title: t("pages.dashboard.currency.max_possible_win_single"),
			dataIndex: "singlePossibleWinMax",
			render: (value, record) => record.betLimits.singlePossibleWinMax,
			sorter: false
		},
		{
			title: t("pages.dashboard.currency.min_multi"),
			dataIndex: "multiMin",
			render: (value, record) => record.betLimits.multiMin,
			sorter: false
		},
		{
			title: t("pages.dashboard.currency.max_multi"),
			dataIndex: "multiMax",
			render: (value, record) => record.betLimits.multiMax,
			sorter: false
		},
		{
			title: t("pages.dashboard.currency.max_possible_win_multi"),
			dataIndex: "multiPossibleWinMax",
			render: (value, record) => record.betLimits.multiPossibleWinMax,
			sorter: false
		},
		{
			title: t("pages.dashboard.currency.overriden_state"),
			dataIndex: "isOverride",
			render: (value) => (value ? t("pages.dashboard.currency.overriden") : t("pages.dashboard.currency.not_overriden")),
			sorter: false
		}
	];

	/** Close currecny add/edit popup, after save */
	useEffect(() => {
		setShowEditPopup(false);
		setEditingCurrency(null);
		const defaultCur = currencies.find((c) => c.isDefault);
		defaultCur && setDefaultCurrency(defaultCur.code);
	}, [currencies]);

	/** Fires when default currency is changed
	 * @function
	 * @memberOf CurrenciesComponent
	 */
	const handleDefaultCurrencyChange = (code) => {
		const selectedCurrency = currencies.find((c) => c.code === code);
		updateCurrency({
			betLimits: selectedCurrency.betLimits,
			favoriteBets: selectedCurrency.favoriteBets,
			code: selectedCurrency.code,
			id: routeParams.id,
			isDefault: true
		});
		setHandlingCurrency(null);
	};

	return (
		<div className="dashboard-section-content">
			<Radio.Group style={{ width: "100%" }} value={defaultCurrency} onChange={(e) => setHandlingCurrency(e.target.value)} disabled={!editable}>
				<Table
					loading={isLoading}
					columns={columns}
					data={currencies}
					loadFn={() => getCurrencies(routeParams.id)}
					total={currencies.length}
					actions={
						editable
							? {
									edit: {
										handler: (record) => {
											setEditingCurrency(record.code);
											setShowEditPopup(true);
										}
									},
									delete: {
										messageKey: "currency_reset",
										handler: (record) => {
											deleteCurrency(routeParams.id, record.code);
										},
										icon: <i className="icon-sync" />,
										title: t("common.reset"),
										disabled: (record) => !record.isOverride
									}
								}
							: {}
					}
					isDisabled={(record) => !record.enabled}
					noPagination={true}
					uniqueKey="code"
				/>
			</Radio.Group>
			<Question type="confirm" onOk={() => handleDefaultCurrencyChange(handlingCurrency)} onCancel={() => setHandlingCurrency(null)} isVisible={handlingCurrency !== null} message={t("pages.dashboard.currency.default_" + entity + "_currency_text")} />
			{showEditPopup ? (
				<CurrencyEditComponent
					onClose={() => {
						setShowEditPopup(false);
						setEditingCurrency(null);
					}}
					editingCurrency={editingCurrency}
				/>
			) : null}
		</div>
	);
};

/** CurrenciesComponent propTypes
 * PropTypes
 */
CurrenciesComponent.propTypes = {
	/** Function to call to get currencies */
	getCurrencies: PropTypes.func,
	/** The array of currencies  */
	currencies: PropTypes.arrayOf(currencyType),
	/** Is true when loading currencies */
	isLoading: PropTypes.bool,
	/** Is currency editable */
	editable: PropTypes.bool,
	/** Function to call to update currency */
	updateCurrency: PropTypes.func,
	/** Function to call to reset currency to partner currency */
	deleteCurrency: PropTypes.func,
	/** Currency Edit Modal Component */
	CurrencyEditComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	/** The entity of currencies */
	entity: PropTypes.oneOf(["project", "betshop"])
};

export default CurrenciesComponent;
