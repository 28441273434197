import { Fragment, useRef, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useParams } from "react-router-dom";

import { Form, Row, Col, Select, Input, DatePicker, Radio, Tooltip } from "antd";

const { Item: FormItem } = Form;
import locale from "antd/es/date-picker/locale/en_US";

import FiltersWrapper from "components/common/filters";
import NumericInput from "components/common/numericInput";

import { setPlayerSettledBetsFilters, getPlayerSettledBets } from "store/actions/dashboard/online/players/betslips.action";

import { FILTER_BETSLIP_MODES, FILTER_BET_STATE, FILTER_BETSLIP_MODE_TYPE, FILTER_REPORT_BONUS_TYPE } from "constants/filter.constants";
import { GAME_CATEGORY } from "constants/game.constants";

import { monthAgo } from "utils/dateTime";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

/** Player View Page BetHistory tab Settled betslips SubTab Page filters Component */
const Filters = ({ setPlayerSettledBetsFilters, getPlayerSettledBets, filters }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const formInstanceRef = useRef(null);

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [{ name: "date", keepTime: true, roundTime: false }]
		};
	}, []);

	/** Function to get filters list
	 * @function
	 * @returns {array} - filters list
	 * @memberOf Filters
	 */
	const filtersList = () => [
		{ name: "betSlipId", title: t("pages.dashboard.bets.betslip_id") },
		{ name: "betId", title: t("pages.dashboard.bets.bet_id") },
		{ name: "eventId", title: t("pages.dashboard.bets.event_id") },
		{
			name: "type",
			title: t("pages.dashboard.bets.bet_type"),
			values: [
				{ title: t("common.all"), value: FILTER_BETSLIP_MODES.ALL },
				{
					title: t("pages.dashboard.bets.single"),
					value: FILTER_BETSLIP_MODES.SINGLE
				},
				{
					title: t("pages.dashboard.bets.multi"),
					value: FILTER_BETSLIP_MODES.MULTI
				}
			]
		},
		{
			name: "status",
			title: t("pages.dashboard.bets.status"),
			values: Object.keys(FILTER_BET_STATE).map((st) => ({
				title: FILTER_BET_STATE[st] !== "" ? t(`pages.dashboard.bets.bet_status_${FILTER_BET_STATE[st]}`) : t("common.all"),
				value: FILTER_BET_STATE[st]
			}))
		},
		{
			name: "demoState",
			title: t("pages.dashboard.bets.mode_type"),
			values: [
				{
					title: t("pages.dashboard.bets.real"),
					value: FILTER_BETSLIP_MODE_TYPE.REAL
				},
				{
					title: t("pages.dashboard.bets.demo"),
					value: FILTER_BETSLIP_MODE_TYPE.DEMO
				}
			]
		},
		{ name: "bonusId", title: t("pages.dashboard.bets.bonus_id") },
		{
			name: "betType",
			title: t("pages.dashboard.bets.betslips"),
			values: [
				{ title: t("common.all"), value: FILTER_REPORT_BONUS_TYPE.ALL },
				{
					title: t("pages.dashboard.bets.only_bonus"),
					value: FILTER_REPORT_BONUS_TYPE.ONLY_BONUS
				},
				{
					title: t("pages.dashboard.bets.without_bonus"),
					value: FILTER_REPORT_BONUS_TYPE.WITHOUT_BONUS
				}
			]
		},
		{
			name: "gameCategory",
			title: t("pages.dashboard.games.gameCategory"),
			values: [
				{ title: t("common.all"), value: "" },
				{
					title: t(`pages.dashboard.games.gameCategory_${GAME_CATEGORY.SCHEDULED}`),
					value: GAME_CATEGORY.SCHEDULED
				},
				{
					title: t(`pages.dashboard.games.gameCategory_${GAME_CATEGORY.INSTANT}`),
					value: GAME_CATEGORY.INSTANT
				}
			]
		}
	];

	return (
		<FiltersWrapper filtersName="betHistory_settled" loadFn={() => getPlayerSettledBets(routeParams.id)} setFiltersFn={(filters) => setPlayerSettledBetsFilters(filters)} filters={filters} formFieldsConfigs={formFieldsConfigs} filtersList={filtersList()} formInstanceRef={formInstanceRef}>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.betslip_id")} name="betSlipId">
							<NumericInput placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.betslip_id")}`} isInteger={true} maxLength={12} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.bet_id")} name="betId">
							<NumericInput placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.bet_id")}`} isInteger={true} maxLength={12} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.event_id")} name="eventId">
							<NumericInput placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.event_id")}`} isInteger={true} maxLength={12} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.bet_type")} name="type">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_BETSLIP_MODES.ALL}>{t("common.all")}</Select.Option>
								<Select.Option value={FILTER_BETSLIP_MODES.SINGLE}>{t("pages.dashboard.bets.single")}</Select.Option>
								<Select.Option value={FILTER_BETSLIP_MODES.MULTI}>{t("pages.dashboard.bets.multi")}</Select.Option>
							</Select>
						</FormItem>
					</Col>

					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.status")} name="status">
							<Select
								mode="multiple"
								showSearch={false}
								suffixIcon={<i className="icon-down" />}
								maxTagTextLength={12}
								maxTagCount={1}
								showArrow={true}
								getPopupContainer={(trigger) => trigger.parentNode}
								onChange={(selectedStatuses) => {
									switch (true) {
										case Boolean(formInstanceRef?.current?.setFieldsValue) === false:
											break;
										case selectedStatuses.at(0) === FILTER_BET_STATE.ALL && selectedStatuses.length > 1:
											formInstanceRef.current.setFieldsValue({ status: selectedStatuses.slice(1) });
											break;
										case selectedStatuses.at(-1) === FILTER_BET_STATE.ALL && selectedStatuses.length > 0:
										case selectedStatuses.length ===
											((filtersList().find((filter) => filter.name === "status"))?.values?.length ?? 0) - 1:
										case selectedStatuses.length === 0:
											formInstanceRef.current.setFieldsValue({ status: [FILTER_BET_STATE.ALL] });
											break;
										default:
											formInstanceRef.current.setFieldsValue({ status: [...selectedStatuses] })
											break;
									}
								}}
							>
								{Object.keys(FILTER_BET_STATE).map((k) => (
									<Select.Option key={k} value={FILTER_BET_STATE[k]}>
										<span title={FILTER_BET_STATE[k] !== "" ? t(`pages.dashboard.bets.bet_status_${FILTER_BET_STATE[k]}`) : t("common.all")}>{FILTER_BET_STATE[k] !== "" ? t(`pages.dashboard.bets.bet_status_${FILTER_BET_STATE[k]}`) : t("common.all")}</span>
									</Select.Option>
								))}
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.mode_type")} name="demoState">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_BETSLIP_MODE_TYPE.REAL}>{t("pages.dashboard.bets.real")}</Select.Option>
								<Select.Option value={FILTER_BETSLIP_MODE_TYPE.DEMO}>{t("pages.dashboard.bets.demo")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.bonus_id")} name="bonusId">
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.bonus_id")}`} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.betslips")} name="betType">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_REPORT_BONUS_TYPE.ALL}>{t("common.all")}</Select.Option>
								<Select.Option value={FILTER_REPORT_BONUS_TYPE.ONLY_BONUS}>{t("pages.dashboard.bets.only_bonus")}</Select.Option>
								<Select.Option value={FILTER_REPORT_BONUS_TYPE.WITHOUT_BONUS}>{t("pages.dashboard.bets.without_bonus")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.games.gameCategory")} name="gameCategory">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={""}>{t("common.all")}</Select.Option>
								<Select.Option value={GAME_CATEGORY.SCHEDULED}>{t(`pages.dashboard.games.gameCategory_${GAME_CATEGORY.SCHEDULED}`)}</Select.Option>
								<Select.Option value={GAME_CATEGORY.INSTANT}>{t(`pages.dashboard.games.gameCategory_${GAME_CATEGORY.INSTANT}`)}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={24} lg={12} xl={8}>
						<FormItem
							label={
								<Fragment>
									<span>{t("pages.dashboard.bets.date_and_time")}</span>
									<Tooltip title={t("common.for_more_data")} getPopupContainer={() => document.body}>
										<i className="icon-info table-filters-info" />
									</Tooltip>
								</Fragment>
							}
							name="date"
						>
							<DatePicker.RangePicker
								placeholder={[t("common.from"), t("common.to")]}
								format={`${DATE_FORMAT} ${TIME_FORMAT}`}
								showTime={{ format: TIME_FORMAT }}
								disabledDate={(d) => !d || d.isBefore(monthAgo(3)) || d.isAfter(moment().endOf("day"))}
								allowClear={false}
								locale={{
									...locale,
									lang: {
										...locale.lang,
										ok: t("common.apply")
									}
								}}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={18} lg={18} xl={12}>
						<div className="table-filters-quick-filters">
							<FormItem label={t("common.quick_filters")} name="quickFilters" className="table-filters-quick-filters-buttons">
								<Radio.Group>
									<Radio.Button value="last_24_h" style={{ marginBottom: "8px" }}>
										{t("common.last_24_h")}
									</Radio.Button>
									<Radio.Button value="last_week" style={{ marginBottom: "8px" }}>
										{t("common.this_week")}
									</Radio.Button>
									<Radio.Button value="last_month" style={{ marginBottom: "8px" }}>
										{t("common.this_month")}
									</Radio.Button>
									<Radio.Button value="last_3_month" style={{ marginBottom: "8px" }}>
										{t("common.last_3_months")}
									</Radio.Button>
								</Radio.Group>
							</FormItem>
						</div>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get player settled bets */
	getPlayerSettledBets: PropTypes.func,
	/** Redux action to set playersettled bets filters */
	setPlayerSettledBetsFilters: PropTypes.func,
	/** Redux state property, player settled bets filters */
	filters: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		filters: state.players.viewingPlayer.betHistory.settled.filters
	};
};

const mapDispatchToProps = (dispatch) => ({
	getPlayerSettledBets: (id) => {
		dispatch(getPlayerSettledBets(id));
	},
	setPlayerSettledBetsFilters: (filters) => {
		dispatch(setPlayerSettledBetsFilters(filters));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
