import PropTypes from "prop-types";

import { SCHEDULED_GAME_TYPE, INSTANT_GAME_TYPE } from "constants/game.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.oneOf([...Object.values(SCHEDULED_GAME_TYPE), ...Object.values(INSTANT_GAME_TYPE)].map((v) => v.value)),
	enabled: PropTypes.bool
});
