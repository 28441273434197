import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { SET_BETSLIPS_REQUESTS, SET_BETSLIPS_REQUESTS_SORTING, SET_BETSLIPS_REQUESTS_FILTERS } from "../../../actionTypes";

import { setBetslipsActionBefore, setBetslipsActionFinished } from "./betslips.action";

const setRequests = (data) => ({
	type: SET_BETSLIPS_REQUESTS,
	payload: { data }
});

export const setRequestsSorting = (sorting) => ({
	type: SET_BETSLIPS_REQUESTS_SORTING,
	payload: { sorting }
});

export const setRequestsFilters = (filters) => ({
	type: SET_BETSLIPS_REQUESTS_FILTERS,
	payload: { filters }
});

export const getRequests = (fromFirstPage, betSlipId, type) => {
	return (dispatch, getState) => {
		dispatch(setBetslipsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_REPRINT_REQUESTS}`,
			method: Methods.GET,
			params: {
				...getState().betslips.requests.filters,
				...getState().betslips.requests.sorting,
				page: fromFirstPage ? 1 : getState().betslips.requests.sorting.page,
				betSlipId: betSlipId || getState().betslips.requests.filters.betSlipId,
				ticketType: type || getState().betslips.requests.filters.ticketType
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setRequests(data));
				if (fromFirstPage) dispatch(setRequestsSorting({ ...getState().betslips.requests.sorting, page: 1 }));
				dispatch(setBetslipsActionFinished());
			})
			.catch(() => {
				dispatch(setBetslipsActionFinished());
			});
	};
};

export const declineRequest = (id) => {
	return (dispatch) => {
		dispatch(setBetslipsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.DECLINE_REPRINT_REQUEST}`,
			method: Methods.POST,
			data: { id }
		})
			.then(() => {
				dispatch(getRequests());
				dispatch(setBetslipsActionFinished());
			})
			.catch(() => {
				dispatch(setBetslipsActionFinished());
			});
	};
};

export const approveRequest = (id) => {
	return (dispatch) => {
		dispatch(setBetslipsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APPROVE_REPRINT_REQUEST}`,
			method: Methods.POST,
			data: { id }
		})
			.then(() => {
				dispatch(getRequests());
				dispatch(setBetslipsActionFinished());
			})
			.catch(() => {
				dispatch(setBetslipsActionFinished());
			});
	};
};
