import { useTranslation } from 'react-i18next';
import { Row, Col } from "antd";

import { isSeasonGame } from 'utils/common';
import { SCHEDULED_GAME_TYPE } from 'constants/game.constants';
import { PERIOD } from 'constants/market.constants';

const Scenes = ({ event }) => {
	const { t } = useTranslation();

	const data = event?.gameData?.scenes ?? [];

	return (
		<div className="dashboard-result-view-scenes">
			{
				(event.gameType === SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value || isSeasonGame(event.gameType)) && data.length
					? (
						<Row gutter={[16, 0]}>
							<>
								<Col xs={24} sm={24} lg={12}>
									<span className="dashboard-result-view-scenes-period">{t('pages.dashboard.events.1_half')}</span>
									{
										event.gameData.scenes.filter(sc => sc.period === PERIOD.FIRSTHALF).map((scene, index) => (
											<div className="dashboard-result-view-scene" key={`${scene.name}_${index}`}>
												<span className="dashboard-result-view-scene-index">{index + 1}.</span>
												<span className="dashboard-result-view-scene-title" title={scene.name}>{scene.name}</span>
											</div>
										))
									}
								</Col>
								<Col xs={24} sm={24} lg={12}>
									<span className="dashboard-result-view-scenes-period">{t('pages.dashboard.events.2_half')}</span>
									{
										event.gameData.scenes.filter(sc => sc.period === PERIOD.SECONDHALF).map((scene, index) => (
											<div className="dashboard-result-view-scene" key={`${scene.name}_${index}`}>
												<span className="dashboard-result-view-scene-index">{index + 1}.</span>
												<span className="dashboard-result-view-scene-title" title={scene.name}>{scene.name}</span>
											</div>
										))
									}
								</Col>
							</>
						</Row>
					) : event.gameType === SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value && data.length ? (
						<>
							{
								<Row gutter={[16, 0]}>
									{event.gameData.scenes.filter(sc => sc.period === PERIOD.PENALTY_SHOOTOUT).map((scene, index) => (
										<Col xs={24} sm={24} lg={12} key={`${scene.name}_${index}`}>
											<div className="dashboard-result-view-scene">
												<span className="dashboard-result-view-scene-index">{index + 1}.</span>
												<span className="dashboard-result-view-scene-title" title={scene.name}>{scene.name}</span>
											</div>
										</Col>
									))}
								</Row>
							}
						</>
					)
						: event.gameType === SCHEDULED_GAME_TYPE.KENO.value ? (
							<div className="dashboard-result-view-scenes-keno">
								{
									data.map(sc => (
										<div className="dashboard-result-view-scenes-keno-ball" key={sc.number}>
											<div className="dashboard-result-view-scenes-keno-ball-inner">
												<span>{sc.number}</span>
											</div>
										</div>
									))
								}
							</div>
						) : ""
			}
		</div>
	)
};

export default Scenes;