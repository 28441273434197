import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import useRTPs from "hooks/useRTPs";

import { Form, Input, Switch } from "antd";
const { Item: FormItem } = Form;

import Table from "components/common/table";
import NumericInput from "components/common/numericInput";

import { countDecimals, numberTransform } from "utils/common";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { PERIOD } from "constants/market.constants";

import rtpType from "types/rtp/rtp.type";
import { rtpPeriodChecking } from "utils/markets";
import { INSTANT_GAME_TYPE, SCHEDULED_GAME_TYPE } from "constants/game.constants";
import useEvent from "hooks/useEvent";

/** Game Edit Page Default RTP Tab Common RTP Component */
const CommonRTPComponent = ({ isLoading, rtp, getRTPs, setIsFormTouched, formInstance, orders, setOrders, containerRef, gameType }) => {
	const { t } = useTranslation();

	const { setFieldsValue } = formInstance;

	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.GAME_RTP, action: PERMISSION_ACTION.MODIFY });
	const uniqueKey = "name";

	/** Columns of table */
	const columns = useMemo(
		() => [
			{
				title: "#",
				dataIndex: "index",
				sorter: false,
				render: (index, record) => (rtpPeriodChecking(record, PERIOD.MATCH, PERIOD.NONE, PERIOD.PENALTY_SHOOTOUT) ? index + 1 : " "),
				width: "6%"
			},
			{
				title: t("pages.dashboard.apikeys.rtp.market"),
				dataIndex: "name",
				render: (value, record) => {
					if (record.period === PERIOD.FIRSTHALF) {
						return `${t(`markets.${gameType}.FirstHalf`)} ${t(`markets.${gameType}.${value}`)}`;
					}

					if (record.period === PERIOD.SECONDHALF) {
						return `${t(`markets.${gameType}.SecondHalf`)} ${t(`markets.${gameType}.${value}`)}`;
					}

					if (rtpPeriodChecking(record, PERIOD.MATCH, PERIOD.NONE, PERIOD.PENALTY_SHOOTOUT)) {
						if (record.period === PERIOD.MATCH && record.name === "Winner") {
							return t(`markets.${gameType}.LeagueChamp`);
						}
						return t(`markets.${gameType}.${value}`);
					}

					return "";
				},
				sorter: false,
				className: "drag-visible",
				width: `${isDisabled ? 30 : 29}%`,
				ellipsis: true
			},
			{
				title: t("pages.dashboard.apikeys.rtp.margin"),
				dataIndex: "margin",
				sorter: false,
				className: "ant-table-input-cell",
				render: (value, record) => (
					<FormItem
						className="inline-form-item-control table-form-control"
						name={["rtPs", record.name + "_" + record.period, "margin"]}
						rules={[
							{ required: true, message: t("validation.field_required") },
							{
								type: "number",
								min: rtp.minPossibleValue || 1,
								message: t("validation.must_be_more").replace("%X%", rtp.minPossibleValue || 1),
								transform: numberTransform
							},
							{
								type: "number",
								max: rtp.maxPossibleValue || 50,
								message: t("validation.must_be_less").replace("%X%", rtp.maxPossibleValue || 50),
								transform: numberTransform
							}
						]}
						style={{ marginLeft: 0 }}
					>
						<NumericInput
							style={{ maxWidth: "200px" }}
							decimalsCount={2}
							disabled={isDisabled}
							onChange={(val) => {
								const numVal = Number(val);
								const value = (100 - (!isNaN(numVal) ? numVal : 0)).toFixed(countDecimals(numVal));
								setFieldsValue({
									rtPs: {
										[record.name + "_" + record.period]: {
											rtp: value
										}
									}
								});
							}}
						/>
					</FormItem>
				),
				width: `${isDisabled ? 30 : 29}%`
			},
			{
				title: t("pages.dashboard.apikeys.rtp.rtp"),
				dataIndex: "rtp",
				className: "ant-table-input-cell",
				render: (value, record) => (
					<FormItem className="inline-form-item-control table-form-control" style={{ marginLeft: 0 }} name={["rtPs", record.name + "_" + record.period, "rtp"]}>
						<Input style={{ maxWidth: "200px" }} disabled={true} />
					</FormItem>
				),
				sorter: false,
				width: `${isDisabled ? 30 : 29}%`
			},
			{
				title: "",
				dataIndex: "enabled",
				className: "ant-table-input-cell",
				render: (value, record) => {
					let isDisabledByParent = false;
					if (gameType !== SCHEDULED_GAME_TYPE.KENO.value && [PERIOD.FIRSTHALF, PERIOD.SECONDHALF].includes(record.period)) {
						const parentRtp = formInstance.getFieldValue(["rtPs", `${record.name}_${PERIOD.MATCH}`])
						isDisabledByParent = !parentRtp.enabled
					}
					return (
						<FormItem className="inline-form-item-control table-form-control" style={{ marginLeft: 0 }} name={["rtPs", record.name + "_" + record.period, "enabled"]} valuePropName="checked">
							<Switch disabled={isDisabled || isDisabledByParent} />
						</FormItem>
					)
				},
				sorter: false,
				width: "6%"
			}
		],
		[t, setFieldsValue, isDisabled]
	);

	const { mutatedData, getDetailsData } = useRTPs(rtp, orders, columns);

	return (
		<Table
			loading={isLoading}
			columns={columns}
			data={mutatedData}
			loadFn={() => getRTPs()}
			isDisabled={(record) => isDisabled || !record.period}
			noPagination={true}
			uniqueKey={uniqueKey}
			nonFixed={true}
			detailsType="table"
			details={getDetailsData}
			isAlwaysExpended={true}
			tableLayout="fixed"
			draggableColumns={[uniqueKey]}
			draggable={
				isDisabled
					? null
					: {
							/* Promisified, because hard syncron operations */
							onDragEnd: (oldIndex, newIndex) => {
								/* Closuring of arguments */
								Promise.resolve([oldIndex + 1, newIndex + 1])
									/* Define new state for sorting */
									.then((indexInfo) => {
										const keysArr = Object.keys(orders);
										const keysArrLength = keysArr.length;
										/* Checking, maybe need to interupt function */
										if (!keysArrLength) {
											throw null;
										}

										/* Get closured arguments */
										const [oldOrderNumber, newOrderNumber] = indexInfo;
										const newOrders = {};
										let key = "";

										const isOldLessThenNew = oldOrderNumber < newOrderNumber;

										const checkisItDrugDown = (value) => {
											return isOldLessThenNew && value > oldOrderNumber && value <= newOrderNumber;
										};

										const checkisItDrugUp = (value) => {
											return !isOldLessThenNew && value < oldOrderNumber && value >= newOrderNumber;
										};

										for (let i = 0; i < keysArrLength; i++) {
											key = keysArr[i];

											if (orders[key] === oldOrderNumber) {
												newOrders[key] = newOrderNumber;
												continue;
											}

											if (checkisItDrugDown(orders[key])) {
												newOrders[key] = orders[key] - 1;
												continue;
											}

											if (checkisItDrugUp(orders[key])) {
												newOrders[key] = orders[key] + 1;
												continue;
											}

											newOrders[key] = orders[key];
										}
										return newOrders;
									})
									.then(
										/* Update function */
										(newOrders) => {
											setOrders(newOrders);
											setTimeout(() => {
												setIsFormTouched(true);
											}, 100);
										},
										/* Stub function */
										Function.prototype
									);
							}
						}
			}
			isDraggable={() => true}
		/>
	);
};

/** CommonRTPComponent propTypes
 * PropTypes
 */
CommonRTPComponent.propTypes = {
	/** Is true when rtps are loading */
	isLoading: PropTypes.bool,
	/** Function to get rtps */
	getRTPs: PropTypes.func,
	/** Redux state, represents the rtp data   */
	rtp: rtpType,
	/** Function to update form touched/untouched state */
	setIsFormTouched: PropTypes.func,
	/** The form object */
	formInstance: PropTypes.object,
	/** RTP Orders object */
	orders: PropTypes.object,
	/** Function to set RTP Orders object */
	setOrders: PropTypes.func,
	/** React ref with cointainer referance */
	containerRef: PropTypes.shape({ current: PropTypes.any }),
	/** Current game type */
	gameType: PropTypes.oneOf([...Object.values(SCHEDULED_GAME_TYPE), ...Object.values(INSTANT_GAME_TYPE)].map((v) => v.value))
};

export default CommonRTPComponent;
