import PropTypes from "prop-types";
import { Input } from "antd";

import { countDecimals } from "utils/common";

/** Numeric Input Component, allows only numeric characters in input */
const NumericInput = ({
	onChange,
	value,
	onBlur,
	placeholder,
	style,
	isInteger,
	disabled,
	decimalsCount,
	...rest
}) => {
	/** Fires on input change
		* @function
		* @description checks to allow only numeric characters
		* @memberOf NumericInput
  */
	const onChangeHandler = e => {
		const { value } = e.target;
		const reg = isInteger ? /^-?[0-9]*([0-9]*)?$/ : /^-?[0-9]*(\.[0-9]*)?$/;
		if ((!isNaN(value) && reg.test(value) && !value.startsWith("-") && (!decimalsCount || countDecimals(value) <= decimalsCount)) || value === '') {
			onChange(value);
		}
	};

	/** Fires on input blur
		* @function
		* @description remove "." from value, if it's the last character
		* @memberOf NumericInput
  */
	const onBlurHandler = () => {
		const v = value === undefined || value === null ? "" : value;
		let valueTemp = v + "";
		if (!isInteger && valueTemp.charAt(v.length - 1) === '.') {
			valueTemp = valueTemp.slice(0, -1);
		}
		if (valueTemp !== valueTemp.replace(/0*(\d+)/, '$1')) {
			onChange(valueTemp.replace(/0*(\d+)/, '$1'));
		}

		if (onBlur) {
			onBlur();
		}
	}

	return (
		<Input
			type="number"
			{...rest}

			style={style}
			onChange={onChangeHandler}
			onBlur={onBlurHandler}
			placeholder={placeholder}
			value={value}
			disabled={disabled}
			autoComplete="off"
		/>
	)
}

/** NumericInput propTypes
	 * PropTypes
*/
NumericInput.propTypes = {
	/** Callback which called on input change */
	onChange: PropTypes.func,
	/** Input value*/
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/** Callback which called on input blur */
	onBlur: PropTypes.func,
	/** Input placeholder */
	placeholder: PropTypes.string,
	/** Input styles */
	style: PropTypes.object,
	/** Should input value be integer */
	isInteger: PropTypes.bool,
	/**Is input disabled */
	disabled: PropTypes.bool,
	/** Limitation for decimal numbers */
	decimalsCount: PropTypes.number
}

export default NumericInput;
