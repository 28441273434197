import { flagsToBinary } from "utils/common";
import { addMinutesToCurrentDate } from "utils/bonuses";

import { BETSLIP_MODES } from "constants/bet.constants";

export const BONUS_TYPE = {
	FREEBET: 1,
	FREEAMOUNT: 2,
	DOUBLEDOOBLE: 3
};

export const JACKPOTS_TYPES = {
	ONLINE: 1,
	RETAIL: 2
};

export const JACKPOT_LEVEL = {
	BRONZE: 1,
	SILVER: 2,
	GOLD: 4
}

export const JACKPOT_EDIT_HASHES = {
	TAB: "tab"
}

export const JACKPOT_HISTORY_TABS = {
	GENERAL: "1",
	HISTORY: "2"
}

export const JACKPOT_TABLE_FILTERS = {
	LEVEL: "levelType",
	BETSLIP: "betSlipId",
	PROJECT: "projectIds",
	END_TIME: "date",
	WON_LEVEL: "wonLevelType",
	STATUS: "status",
}

export const BONUS_STATE = {
	ACTIVE: 1,
	FINISHED: 2,
	INACTIVE: 4
};

export const JACKPOT_STATE = {
	INACTIVE: 1,
	ACTIVE: 2,
	PAUSED: 4,
	FINISHED: 8,
};

export const JACKPOT_TRANSACTION_STATUS = {
	ALL: "",
	NOT_WON: 1,
	WON: 2
};

export const BONUS_WIN_TYPE = {
	TOTAL: 1,
	PURE: 2
};

export const BONUS_TYPE_TRANSLATION_TEXTS = {
	[BONUS_TYPE.FREEBET]: "pages.dashboard.bets.free_bet_text",
	[BONUS_TYPE.FREEAMOUNT]: "pages.dashboard.bets.free_amount_text",
	[BONUS_TYPE.DOUBLEDOOBLE]: "pages.dashboard.bets.double_dooble"
};

export const JACKPOT_BONUS_TYPE_TRANSLATION_TEXTS = {
	[JACKPOTS_TYPES.ONLINE]: "pages.dashboard.bonuses.jackpot_type_online",
	[JACKPOTS_TYPES.RETAIL]: "pages.dashboard.bonuses.jackpot_type_retail",
}

export const JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS = {
	[JACKPOT_LEVEL.BRONZE]: "pages.dashboard.bonuses.jackpot_level_bronze",
	[JACKPOT_LEVEL.SILVER]: "pages.dashboard.bonuses.jackpot_level_silver",
	[JACKPOT_LEVEL.GOLD]: "pages.dashboard.bonuses.jackpot_level_gold"
}

export const JACKPOT_BONUS_LEVEL_COLORS = {
	[JACKPOT_LEVEL.BRONZE]: "#C78B6126",
	[JACKPOT_LEVEL.SILVER]: "#F2F2F2",
	[JACKPOT_LEVEL.GOLD]: "#E9BD4026"
}

export const JACKPOT_BONUS_STATES_TRANSLATION_TEXTS = {
	[JACKPOT_STATE.INACTIVE]: "pages.dashboard.bonuses.jackpot_status_not_active",
	[JACKPOT_STATE.ACTIVE]: "pages.dashboard.bonuses.jackpot_status_active",
	[JACKPOT_STATE.PAUSED]: "pages.dashboard.bonuses.jackpot_status_paused",
	[JACKPOT_STATE.FINISHED]: "pages.dashboard.bonuses.jackpot_status_finished"
}

export const JACKPOT_BONUS_TRANSACTION_STATUS_TRANSLATION_TEXTS = {
	[JACKPOT_TRANSACTION_STATUS.NOT_WON]: "pages.dashboard.bonuses.jackpot_status_not_won",
	[JACKPOT_TRANSACTION_STATUS.WON]: "pages.dashboard.bonuses.jackpot_status_won"
}


export const BONUS_FIELDS_RANGE = {
	AMOUNT: [1, 1000000],
	MIN_AMOUNT_SINGLE: [1, 1000000],
	MAX_AMOUNT_SINGLE: [1, 1000000],
	MIN_AMOUNT_MULTI: [1, 1000000],
	MAX_AMOUNT_MULTI: [1, 1000000],
	MIN_ODDS_SINGLE: [1.01, 10000],
	MAX_ODDS_SINGLE: [1.01, 10000],
	MIN_ODDS_MULTI: [1.02, 10000],
	MAX_ODDS_MULTI: [1.02, 10000],
	QUANTITY: [1, 1000],
	MIN_POSSIBLE_WIN: [1, 10000000],
	MAX_POSSIBLE_WIN: [1, 10000000],
	BET_PROBABILITY: [1, 10]
};

export const FIELD_NAMES = {
	BONUSE_TYPE: "bonusType",
	CURRENCY: "currency",
	BETSHOP_IDS: "betshopIds",
	GAME_TYPE: "gameType",
	QUANTITY: "quantity",
	AMOUNT: "amount",
	BET_TYPE: "betType",
	WIN_TYPE: "winType",
	MIN_ODDS_SINGLE: "minOddsSingle",
	MAX_ODDS_SINGLE: "maxOddsSingle",
	MIN_ODDS_MULTI: "minOddsMulti",
	MAX_ODDS_MULTI: "maxOddsMulti",
	END_DATE: "endDate",
	BET_PROBABILITY: "betProbability",
	MIN_AMOUNT_SINGLE: "minAmountSingle",
	MAX_AMOUNT_SINGLE: "maxAmountSingle",
	MIN_AMOUNT_MULTI: "minAmountMulti",
	MAX_AMOUNT_MULTI: "maxAmountMulti",
	MIN_POSSIBLE_WIN_AMOUNT: "minPossibleWinAmount",
	MAX_POSSIBLE_WIN_AMOUNT: "maxPossibleWinAmount",
	DATE: "date"
};

export const DOUBLE_DOOBLE_INITIAL_DELAY = {
	MINUTE: 30,
	SECOND: 0,
	MILLISECOND: 0
}

// Initial values include all field values that exist in each view
export const ANTD_FORM_INITIAL_VALUES = {
	[FIELD_NAMES.BONUSE_TYPE]: [],
	[FIELD_NAMES.CURRENCY]: [],
	[FIELD_NAMES.BETSHOP_IDS]: [],
	[FIELD_NAMES.GAME_TYPE]: [],
	[FIELD_NAMES.QUANTITY]: "",
	[FIELD_NAMES.AMOUNT]: "",
	[FIELD_NAMES.MIN_POSSIBLE_WIN_AMOUNT]: "",
	[FIELD_NAMES.MAX_POSSIBLE_WIN_AMOUNT]: "",
	[FIELD_NAMES.BET_TYPE]: flagsToBinary([BETSLIP_MODES.SINGLE, BETSLIP_MODES.MULTI]),
	[FIELD_NAMES.WIN_TYPE]: BONUS_WIN_TYPE.PURE,
	[FIELD_NAMES.MIN_ODDS_SINGLE]: "",
	[FIELD_NAMES.MAX_ODDS_SINGLE]: "",
	[FIELD_NAMES.MIN_ODDS_MULTI]: "",
	[FIELD_NAMES.MAX_ODDS_MULTI]: "",
	[FIELD_NAMES.END_DATE]: "",
	[FIELD_NAMES.BET_PROBABILITY]: "",
	[FIELD_NAMES.MIN_AMOUNT_SINGLE]: "",
	[FIELD_NAMES.MAX_AMOUNT_SINGLE]: "",
	[FIELD_NAMES.MIN_AMOUNT_MULTI]: "",
	[FIELD_NAMES.MAX_AMOUNT_MULTI]: "",
	[FIELD_NAMES.DATE]: [
		addMinutesToCurrentDate({
			minute: DOUBLE_DOOBLE_INITIAL_DELAY.MINUTE,
			second: DOUBLE_DOOBLE_INITIAL_DELAY.SECOND,
			millisecond: DOUBLE_DOOBLE_INITIAL_DELAY.MILLISECOND
		}),
		null
	]
};
