import axios from "axios";
import { message } from "antd";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { SET_BETSLIP_CORRECTION_ACTION_BEFORE, SET_BETSLIP_CORRECTION_ACTION_FINISH, SET_BETSLIP_CORRECTIONS, SET_BETSLIP_CORRECTIONS_FILTERS, SET_BETSLIP_CORRECTIONS_SORTING } from "store/actionTypes";
import { RESPONSE_STATE } from "constants/response.constants";

const setBetslipCorrectionBefore = () => ({
	type: SET_BETSLIP_CORRECTION_ACTION_BEFORE
});

const setBetslipCorrectionFinish = () => ({
	type: SET_BETSLIP_CORRECTION_ACTION_FINISH
});

export const setBetslipCorrectionsData = (betCorrections) => ({
	type: SET_BETSLIP_CORRECTIONS,
	payload: betCorrections
});

export const setBetslipCorrectionsFilters = (filters) => ({
	type: SET_BETSLIP_CORRECTIONS_FILTERS,
	payload: { filters }
});

export const setBetslipsCorrectionsSorting = (sorting) => ({
	type: SET_BETSLIP_CORRECTIONS_SORTING,
	payload: sorting
});

export const getBetCorrections = (fromFirstPage) => {
	return (dispatch, getState) => {
		dispatch(setBetslipCorrectionBefore());
		const betCorrectionsState = getState().betCorrections;
		const params = {
			...betCorrectionsState.sorting,
			...betCorrectionsState.filters,
			page: fromFirstPage ? 1 : betCorrectionsState.sorting.page
		};
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_BET_CORRECTIONS}`,
			method: Methods.GET,
			params: params
		})
			.then(({ data: { value: betCorrections } }) => {
				dispatch(setBetslipCorrectionsData(betCorrections));
				if (fromFirstPage) dispatch(setBetslipsCorrectionsSorting({ ...getState().betCorrections.sorting, page: 1 }));
				dispatch(setBetslipCorrectionFinish());
			})
			.catch(() => {
				dispatch(setBetslipCorrectionFinish());
			});
	};
};

export const settleBetCorrections = (ids) => {
	return (dispatch, getState) => {
		dispatch(setBetslipCorrectionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CORRECT_BETSLIP_STATUS}`,
			method: Methods.POST,
			data: ids
		})
			.then(({ data: { message: msg, status, value } }) => {
				if (status !== RESPONSE_STATE.SUCCESS) {
					message.error(msg);
					return;
				}
				message.success(msg);
				const updatedBetslipIds = Object.keys(value).map(Number);
				const betCorrections = getState().betCorrections.data;
				const filteredBetCorrections = betCorrections.filter((bet) => !updatedBetslipIds.includes(bet.id));
				dispatch(setBetslipCorrectionsData(filteredBetCorrections));
			})
			.finally(() => {
				dispatch(setBetslipCorrectionFinish());
			});
	};
};
