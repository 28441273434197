import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Table from 'components/common/table';

import { SCHEDULED_GAME_TYPE } from 'constants/game.constants';

const RacingParticipants = ({ event }) => {
	const { t } = useTranslation();

	const participants = event?.gameData?.participants ?? [];
	const sortedParticipants = useMemo(() => participants.sort((a, b) => a.place > b.place ? 1 : -1), [participants]);

	const racingSceneId = useMemo(() => {
		let str = "";

		if (!event.gameData || !event.gameData.participants) return "";

		const leadingParticipants = event.gameData.participants.reduce((acc, participant) => {
			if(participant.place === 1 || participant.place === 2 || participant.place === 3) {
				acc += `_${participant.number}`;
			}

			return acc;
		}, "");

		str += event.gameType === SCHEDULED_GAME_TYPE.HORSES_RACE.value ? "Horse" : event.gameType === SCHEDULED_GAME_TYPE.GREYHOUNDS_RACE.value ? "Greyhound" : "";
		str += event.gameData.participants.length + leadingParticipants;
		str += event.gameType === SCHEDULED_GAME_TYPE.STEEPLECHASING.value ? event.gameData.participants.reduce((acc, p) => {
			if (p.failed) {
				acc += `_${p.number}`;
			}

			return acc;
		}, "") : "";

		return str;
	}, [event]);

	return (
		<div className="dashboard-result-view-section dashboard-result-view-participants">
			<div className="dashboard-result-view-section-header">
				<span>{t('pages.dashboard.events.participants')}</span>
				<span>,&nbsp;{`${t('pages.dashboard.events.scene')} ${racingSceneId}`}</span>
			</div>
			<div className="dashboard-result-view-markets">
				<div className="dashboard-result-view-markets-list">
					<Table
						loading={false}
						columns={[
							{
								title: event.gameType === SCHEDULED_GAME_TYPE.HORSES_RACE.value ? t('pages.dashboard.events.horse_number') : event.gameType === SCHEDULED_GAME_TYPE.GREYHOUNDS_RACE.value ? t('pages.dashboard.events.greyhound_number') : "",
								dataIndex: 'number',
								sorter: false
							},
							{
								title: event.gameType === SCHEDULED_GAME_TYPE.HORSES_RACE.value ? t('pages.dashboard.events.horse_name') : event.gameType === SCHEDULED_GAME_TYPE.GREYHOUNDS_RACE.value ? t('pages.dashboard.events.greyhound_name') : "",
								dataIndex: 'name',
								sorter: false
							},
							{
								title: t('pages.dashboard.events.place'),
								dataIndex: 'place',
								render: value => [1, 2, 3].includes(value) ? value : t('pages.dashboard.events.no_show'),
								sorter: false
							},
							{
								title: t('pages.dashboard.events.steeplechase_failed'),
								dataIndex: 'failed',
								hidden: event.gameType !== SCHEDULED_GAME_TYPE.STEEPLECHASING.value,
								render: value => value ? t('common.yes') : t('common.no'),
								sorter: false
							}
						]}
						data={sortedParticipants}
						total={sortedParticipants.length}
						noPagination={true}
						uniqueKey="name"
					/>
				</div>
			</div>
		</div>
	);
};

export default RacingParticipants;