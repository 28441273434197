import { Fragment, useState, useEffect } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Tabs } from "antd";

import RollbacksComponent from "./sections/rollbacks";
import ResultsComponent from "./sections/results";

import { setTransactionsActiveTab } from "store/actions/dashboard/transactions/transactions.action";

import { PROJECT_TYPE } from "constants/common.constants";
import { TRANSACTION_TYPE } from "constants/transactions.constants";

import { updateLocationHash, getHashValue } from "utils/common";

/** Failed Transactions Page Component */
const TransactionsComponent = ({ rollbacksPageActiveTab, resultsPageActiveTab, setTransactionsActiveTab }) => {
	const { t } = useTranslation();

	const [activeTab, setActiveTab] = useState("1");

	useEffect(() => {
		setTransactionsActiveTab(activeTab);
	}, [activeTab]);

	return (
		<Fragment>
			<div className="dashboard-section">
				<Tabs
					animated={false}
					onTabClick={(key) => setActiveTab(key)}
					defaultActiveKey={getHashValue("tab")}
					onChange={(key) => updateLocationHash("tab=" + key)}
					items={[
						{
							key: "1",
							label: t("pages.dashboard.transactions.tabs.rollbacks"),
							children: <RollbacksComponent />
						},
						{
							key: "2",
							label: t("pages.dashboard.transactions.tabs.results"),
							children: <ResultsComponent />
						}
					]}
				/>
			</div>
		</Fragment>
	);
};

/** TransactionsComponent propTypes
 * PropTypes
 */
TransactionsComponent.propTypes = {
	/** Redux state property, the key of active sub tab in rollbacks tab page */
	rollbacksPageActiveTab: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	/** Redux state property, the key of active sub tab in results tab page */
	resultsPageActiveTab: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	/** Redux action to set transactions page active tab */
	setTransactionsActiveTab: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		rollbacksPageActiveTab: state.transactions.rollbacks.rollbacksPageActiveTab,
		resultsPageActiveTab: state.transactions.results.resultsPageActiveTab
	};
};

const mapDispatchToProps = (dispatch) => ({
	setTransactionsActiveTab: (tab) => {
		dispatch(setTransactionsActiveTab(tab));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsComponent);
