import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import SingleEventComponent from './sections/common/single';
import Breadcrumbs from 'components/common/breadcrumbs';
import { binaryToFlags } from 'utils/common';
import { updateLocationHash, getHashValue, clearLocationHash } from 'utils/common';
import { PARTNER_TYPE } from 'constants/partner.constants';
import { PROJECT_TYPE } from "constants/common.constants";
import { GAME_CATEGORY } from "constants/game.constants";
import Paths from 'constants/path.constants';
import partnerType from "types/partner/partner.type";

/** Event View Page Component */
const EventViewComponent = ({ projectType }) => {
	const params = useParams()
	const eventId = Number(params.id);
	const gameCategory = Number(params.gameCategory);
	const { hash } = useLocation();
	const { t } = useTranslation();
	const path = (
		projectType === PROJECT_TYPE.ONLINE
			? Paths.DASHBOARD_EVENTS_ONLINE
			: projectType === PROJECT_TYPE.RETAIL
				? Paths.DASHBOARD_EVENTS_RETAIL
				: null
		
	)
	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs
					items={[
						{ title: t("pages.dashboard.bets.event"), path: path ? path + hash : null },
						{ title: eventId }
					]}
				/>
				<div className="dashboard-section-content">
					<SingleEventComponent
						projectType={projectType}
						eventId={Number(eventId)}
						gameCategory={gameCategory}
					/>
				</div>
			</div>
		</Fragment>
	);
}

/** EventViewComponent propTypes
	 * PropTypes
*/
EventViewComponent.propTypes = {
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
}

export default EventViewComponent;
