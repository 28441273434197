import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { TimePicker, Button } from "antd";

import OutsideAlerter from "components/common/outsideAlerter";

/** Range time Picket Component */
const RangeTimePicker = ({ id, onSelect, defaultValue, onClose, onSelectChange = () => {} }) => {
	const { t } = useTranslation();

	const [start, setStart] = useState(defaultValue[0] || null);
	const [end, setEnd] = useState(defaultValue[0] || null);

	/** Fires on OK button click
	 * @function
	 * @memberOf RangeTimePicker
	 */
	const onOk = () => {
		onSelect([start, end]);
	};

	const handleSelect = (cb) => (time) => cb(time.format(TIME_FORMAT));

	useEffect(() => {
		onSelectChange([start, end]);
	}, [start, end]);

	return (
		<OutsideAlerter callback={onClose}>
			<div id={id} className="range-time-picker">
				<TimePicker format={TIME_FORMAT} value={start ? moment(start, TIME_FORMAT) : null} open={true} getPopupContainer={() => document.getElementById(id)} onSelect={handleSelect(setStart)} defaultValue={start} />
				<TimePicker format={TIME_FORMAT} value={end ? moment(end, TIME_FORMAT) : null} open={true} getPopupContainer={() => document.getElementById(id)} onSelect={handleSelect(setEnd)} defaultValue={end} />
				<div className="range-time-picker-addon">
					<Button disabled={start === null || end === null} className="button" size="small" type="primary" onClick={onOk}>
						{t("common.ok")}
					</Button>
				</div>
			</div>
		</OutsideAlerter>
	);
};

/** RangeTimePicker propTypes
 * PropTypes
 */
RangeTimePicker.propTypes = {
	/** DOM id attribute of the element */
	id: PropTypes.string,
	/** Handler of picker value change */
	onSelect: PropTypes.func,
	/** Default value of picker */
	defaultValue: PropTypes.array,
	/** Function to close picker */
	onClose: PropTypes.func,
	// Function prop to change custom select value
	onSelectChange: PropTypes.func
};

export default RangeTimePicker;
