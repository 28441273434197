import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Modal } from "antd";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import { getBetshopSettlementReport } from "store/actions/dashboard/retail/betshops/sessions.action";
import { getPartnerCurrencyDecimalCount } from "utils/currency";
import { numberWithSpaces, printElement } from "utils/common";
import Paths from "constants/path.constants";
import { POPUP_SIZE } from "constants/common.constants";
import { GAME_NAME_MAPPER } from "constants/game.constants";
import settlementReportType from "types/betshop/settlementReport.type";
import currencyType from "types/currency/currency.type";
import betshopGeneralInfoType from "types/betshop/generalInfo.type";
import sessionType from "types/betshop/session.type";

/** Betshop Session Settlement Report Component */
const SettlementReportComponent = ({ isloading, getBetshopSettlementReport, settlementReport, session, currencies, globalPartnerId, generalInfo, ticketLogoId, onClose }) => {
	const { t } = useTranslation();

	/** Load system timezones */
	useEffect(() => {
		if (session.id) {
			getBetshopSettlementReport(session.id);
		}
	}, []);

	/** Fires on print button click
	 * @function
	 * @memberOf SettlementReportComponent
	 */
	const handlePrint = () => {
		printElement("vs--print-wrapper-normal");
		if (hasBonusReport()) {
			printElement("vs--print-wrapper-bonus");
		}
		onClose();
	};

	/** Function to get respective value for respective game type and field from turnover report
	 * @function
	 * @param {string} gameType - game type or total
	 * @param {string} reportField - the field name
	 * @returns {number}
	 * @memberOf SettlementReportComponent
	 */
	const getColValue = (gameType, reportField) => {
		if (gameType !== "total") {
			const res = settlementReport?.report?.[gameType]?.[reportField] ?? "";
			if (res !== "") {
				if (["betSlipCount", "playedCount", "wonCount", "bonusBetSlipsCount", "bonusWonCount"].includes(reportField)) {
					return res;
				} else {
					return numberWithSpaces(res.toFixed(getPartnerCurrencyDecimalCount(generalInfo.currencyId, currencies, globalPartnerId)));
				}
			} else {
				return "";
			}
		} else {
			const res = settlementReport[reportField] !== undefined ? settlementReport[reportField] : "";

			if (["betSlipCount", "playedCount", "wonCount", "bonusBetSlipsCount", "bonusWonCount"].includes(reportField)) {
				return res;
			} else {
				return numberWithSpaces(res.toFixed(getPartnerCurrencyDecimalCount(generalInfo.currencyId, currencies, globalPartnerId)));
			}
		}
	};

	/** Table Cols data */
	const tableCols = [
		[
			{
				key: "turnover",
				title: t("pages.dashboard.session.report.turnover")
			},
			{
				key: "canceled",
				title: t("pages.dashboard.session.report.cancel")
			},
			{
				key: "netTurnover",
				title: t("pages.dashboard.session.report.net")
			}
		],
		[
			{
				key: "won",
				title: t("pages.dashboard.session.report.won")
			},
			{
				key: "payout",
				title: t("pages.dashboard.session.report.payout")
			},
			{
				key: "wonUnpaid",
				title: t("pages.dashboard.session.report.notPaid")
			}
		],
		[
			{
				key: "betSlipCount",
				title: t("pages.dashboard.session.report.betslips")
			},
			{
				key: "playedCount",
				title: t("pages.dashboard.session.report.played")
			},
			{
				key: "wonCount",
				title: t("pages.dashboard.session.report.won")
			}
		]
	];

	const bonusTableCols = [
		[
			{
				key: "bonusTurnover",
				title: t("pages.dashboard.session.report.turnover")
			},
			{
				key: "bonusGGR",
				title: t("pages.dashboard.session.report.ggr")
			}
		],
		[
			{
				key: "bonusWon",
				title: t("pages.dashboard.session.report.won")
			},
			{
				key: "bonusPayout",
				title: t("pages.dashboard.session.report.payout")
			},
			{
				key: "bonusWonUnpaid",
				title: t("pages.dashboard.session.report.notPaid")
			}
		],
		[
			{
				key: "bonusBetSlipsCount",
				title: t("pages.dashboard.session.report.betslips")
			},
			{
				key: "bonusWonCount",
				title: t("pages.dashboard.session.report.won")
			}
		]
	];

	const renderReport = (type) => {
		const isBonus = type === "bonus";
		const columns = type === "bonus" ? bonusTableCols : tableCols;

		return (
			<div id={"vs--print-wrapper-" + type}>
				<div className="vs--print">
					{ticketLogoId && (
						<div className="vs--print-header">
							<img src={`${import.meta.env.SYSTEM_API_URL}${Paths.PARTNER_GET_TICKET_LOGO}/${globalPartnerId}/${ticketLogoId}`} alt="logo" />
						</div>
					)}
					<div className="vs--print-header-sub vs--print-section">
						<div className="vs--print-header-sub-title">
							<i className="icon-virtual-games" />
							<h1>{t("common.virtualSports")}</h1>
						</div>
						<h2>{isBonus ? t("pages.dashboard.session.bonus_report") : t("pages.dashboard.session.settlement_report")}</h2>
					</div>

					<div className="vs--print-info vs--print-section">
						<div className="vs--print-info-row">
							<span>{t("pages.dashboard.session.report.betshop")}</span>
							<span>{generalInfo.name}</span>
						</div>
						<div className="vs--print-info-row">
							<span>{t("pages.dashboard.session.report.address")}</span>
							<span>{generalInfo.address}</span>
						</div>
						<div className="vs--print-info-row">
							<span>{t("pages.dashboard.session.report.printed")}</span>
							<span>{moment.utc().local().format(DATE_TIME_FORMAT)}</span>
						</div>
						<div className="vs--print-info-row">
							<span>{t("pages.dashboard.session.report.cashier")}</span>
							<span>{session.userName}</span>
						</div>
						<div className="vs--print-info-row">
							<span>{t("pages.dashboard.session.report.currency")}</span>
							<span>{generalInfo.currencyId}</span>
						</div>
					</div>

					{columns.map((group) => (
						<div className="vs--print-info vs--print-section" key={group[0].key}>
							<div className="vs--print-table">
								<div className="vs--print-table-row vs--print-table-row-head">
									<div className={"vs--print-table-cell" + (group.length === 2 ? " vs--print-table-cell-big" : "")}></div>
									{group.map((item) => (
										<div className={"vs--print-table-cell" + (group.length === 2 ? " vs--print-table-cell-big" : "")} key={item.key}>
											<span>{item.title}</span>
										</div>
									))}
								</div>
								{Object.keys(settlementReport.report)
									.concat(["total"])
									.map((game) => (
										<div className="vs--print-table-row" key={game}>
											<div className={"vs--print-table-cell" + (group.length === 2 ? " vs--print-table-cell-big" : "")}>
												<span>{GAME_NAME_MAPPER[game] ? t(`common.${GAME_NAME_MAPPER[game]}`) : ""}</span>
											</div>
											{group.map((item) => (
												<div className={"vs--print-table-cell" + (group.length === 2 ? " vs--print-table-cell-big" : "")} key={item.key}>
													<span>{getColValue(game, item.key)}</span>
												</div>
											))}
										</div>
									))}
							</div>
						</div>
					))}

					<div className="vs--print-info vs--print-section">
						<div className="vs--print-info-row">
							<span>{t("pages.dashboard.session.report.ggr")}</span>
							<span>{getColValue("total", "ggr")}</span>
						</div>
					</div>
					<div className="vs--print-info vs--print-section">
						<div className="vs--print-info-row">
							<span>{t("pages.dashboard.session.report.finalBalance")}</span>
							<span>{getColValue("total", "balance")}</span>
						</div>
					</div>

					<div className="vs--print-signature">
						<div>
							<span>{t("pages.dashboard.session.report.cashier")}:</span>
						</div>
						<div>
							<span>{t("pages.dashboard.session.report.manager")}:</span>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const hasBonusReport = () => {
		return (
			settlementReport &&
			(settlementReport.bonusTurnover !== 0 || settlementReport.bonusGGR !== 0 || settlementReport.bonusWon !== 0 || settlementReport.bonusPayout !== 0 || settlementReport.bonusWonUnpaid !== 0 || settlementReport.bonusBetSlipsCount !== 0 || settlementReport.bonusWonCount !== 0)
		);
	};

	return (
		<Modal
			open={true}
			title={t("pages.dashboard.session.settlement_report")}
			cancelText={t("common.cancel")}
			okText={t("common.print")}
			onOk={handlePrint}
			onCancel={onClose}
			width={hasBonusReport() ? POPUP_SIZE.MIDDLE : POPUP_SIZE.SMALL}
			maskClosable={false}
			closable={false}
			className="vs--print-modal"
			centered
		>
			<div className="vs--print-report">
				{renderReport("normal")}
				{hasBonusReport() ? renderReport("bonus") : null}
			</div>
		</Modal>
	);
};

/** SettlementReportComponent propTypes
 * PropTypes
 */
SettlementReportComponent.propTypes = {
	/** Redux state property, is true when loading settlement report */
	isloading: PropTypes.bool,
	/** Redux action to get settlement report */
	getBetshopSettlementReport: PropTypes.func,
	/** Redux state property, represents the settlement report of session  */
	settlementReport: settlementReportType,
	/** Current viewing session  */
	session: sessionType,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType),
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state, represents the general info of current editing betshop  */
	generalInfo: betshopGeneralInfoType,
	/** Current ticket logo id of editing partner */
	ticketLogoId: PropTypes.number,
	/** Fires on popup close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getBetshopSettlementReport: (id) => {
		dispatch(getBetshopSettlementReport(id));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.betshops.viewingBetshop.sessions.currentSession.isLoading,
		settlementReport: state.betshops.viewingBetshop.sessions.currentSession.settlementReport,
		currencies: state.profile.userInfo.currencies,
		globalPartnerId: state.partner.globalPartnerId,
		generalInfo: state.betshops.editingBetshop.generalInfo,
		ticketLogoId: state.tickets.ticketLogoId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SettlementReportComponent);
