import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ORDER_DIRECTION } from "constants/common.constants";
import state0 from "./state0.svg";
import state1 from "./state1.svg";
import state2 from "./state2.svg";

const IMAGES = {
	[ORDER_DIRECTION.NONE]: state0,
	[ORDER_DIRECTION.ASC]: state1,
	[ORDER_DIRECTION.DESC]: state2
};

const SortingSwitcher = ({ className = null, value = ORDER_DIRECTION.NONE, onChange = Function.prototype, imgClassName = null, imgProps = {}, ...otherProps }) => {
	const [state, setState] = useState(value);
	/*
		This Component must be like switcher,
		so onChange is handler of state,
		but for implementation used onClick,
		because span hasn't onChange
	*/
	const _onClick = useCallback(() => {
		setState((prevState) => {
			let newState = ORDER_DIRECTION.NONE;
			switch (prevState) {
				case ORDER_DIRECTION.ASC:
					newState = ORDER_DIRECTION.DESC;
					break;
				case ORDER_DIRECTION.DESC:
					newState = ORDER_DIRECTION.NONE;
					break;
				default:
					newState = ORDER_DIRECTION.ASC;
					break;
			}
			onChange(newState);
			return newState;
		});
	}, [setState, onChange]);

	useEffect(() => setState(value), [value, setState]);

	return (
		<span {...otherProps} className={"vs--sorting-switcher" + (typeof className === "string" ? " " + className : "")} onClick={_onClick}>
			<img className={"vs--sorting-switcher-image" + (typeof imgClassName === "string" ? " " + imgClassName : "")} {...imgProps} src={IMAGES[state]} />
		</span>
	);
};

SortingSwitcher.propTypes = {
	className: PropTypes.string,
	value: PropTypes.oneOf(Object.values(ORDER_DIRECTION)),
	onChange: PropTypes.func,
	imgClassName: PropTypes.string,
	imgProps: PropTypes.object
};

export default SortingSwitcher;
