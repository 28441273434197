import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { setTemporaryLockData, authenticate } from "store/actions/auth/auth.action";

import { useTranslation } from "react-i18next";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { Form, Input, Button, Alert, Statistic } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import Paths from "constants/path.constants";

import { checkIsLessThanMinute } from "utils/auth";

const MainLoginComponent = ({ temporaryLockMessageVisible, unlockDate, authenticate, setTemporaryLockData }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { executeRecaptcha } = useGoogleReCaptcha();
	const [formInstance] = Form.useForm();

	const handlePasswordExpired = (token) => {
		navigate(Paths.RESET_PASSWORD + "?t=" + token + "&type=forceChange", { replace: true });
	};

	const handleFormSubmit = () => {
		formInstance
			.validateFields()
			.then(({ username, password }) => {
				if (import.meta.env.MODE === "development") {
					authenticate(username, password, "34f530b1728d4b28a5d1e39bb92be946", handlePasswordExpired);
				} else {
					executeRecaptcha().then((token) => {
						authenticate(username, password, token, handlePasswordExpired);
					});
				}
			})
			.catch(Function.prototype);
	};

	return (
		<div className={`vs--main-login ${temporaryLockMessageVisible ? "vs--main-login-with-alerter" : ""}`}>
			<div className="vs--main-login-box">
				<h1 className="title">{t("pages.auth.login")}</h1>
				{temporaryLockMessageVisible && (
					<div className="vs--main-login-box-alerter">
						<Alert
							showIcon
							icon={
								/* fragment need for displaying icon correctly */
								<>
									<i className="icon-info-filled" />
								</>
							}
							message={
								<Statistic.Countdown
									prefix={<span className="vs--message">{t("pages.auth.incorrect_attempts_message")}</span>}
									suffix={<span className="vs--message">{checkIsLessThanMinute(unlockDate) ? t("pages.auth.seconds") : t("pages.auth.minutes")}</span>}
									format="mm:ss"
									value={unlockDate ? unlockDate.getTime() : null}
									onFinish={() => setTemporaryLockData({ isVisible: false, unlockDate: null })}
								/>
							}
							type="info"
						/>
					</div>
				)}
				<Form form={formInstance} requiredMark={false} layout="vertical">
					<Form.Item label={t("pages.auth.username")} name="username" rules={[{ required: true, whitespace: true, message: t("validation.username_required") }]}>
						<Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />} placeholder={t("pages.auth.username")} />
					</Form.Item>
					<Form.Item label={t("pages.auth.password")} name="password" rules={[{ required: true, whitespace: true, message: t("validation.password_required") }]}>
						<Input.Password prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />} type="password" placeholder={t("pages.auth.password")} />
					</Form.Item>
					<div className="form-footer-links">
						<span onClick={() => navigate(Paths.FORGOT_PASSWORD, { replace: true })}>{t("pages.auth.forgot_password")}</span>
					</div>
					<Form.Item>
						<Button type="primary" htmlType="submit" className="button" onClick={handleFormSubmit} disabled={unlockDate !== null}>
							<span>{t("pages.auth.login")}</span>
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
};

/** MainLoginComponent propTypes
 * PropTypes
 */
MainLoginComponent.propTypes = {
	/** Redux state property, user unlock date in shape of string */
	unlockDate: PropTypes.object,
	/** Redux state property, indicates temporary lock message visible or not */
	temporaryLockMessageVisible: PropTypes.bool,
	/** Redux action to authenticate */
	authenticate: PropTypes.func,
	/** Redux action to set temporary lock data */
	setTemporaryLockData: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		temporaryLockMessageVisible: state.auth.mainLogin.temporaryLockMessageVisible,
		unlockDate: state.auth.mainLogin.unlockDate
	};
};

const mapDispatchToProps = (dispatch) => ({
	setTemporaryLockData: ({ isVisible, unlockDate }) => {
		dispatch(setTemporaryLockData({ isVisible, unlockDate }));
	},
	authenticate: (username, password, captchaToken, onSuccessCB, onErrorCB) => {
		dispatch(authenticate(username, password, captchaToken, onSuccessCB, onErrorCB));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(MainLoginComponent);
