import axios from "axios";

import { loginUser, logout as logoutUser } from "utils/auth";
import sessionStorageUtils from "utils/sessionStorage";
import { TOKEN_TYPE, LOGIN_VIEW_COMPONENTS, USER_STATUS } from "constants/auth.constants";

import { AUTHENTICATE_ACTION_BEFORE, AUTHENTICATE_ACTION_FINISH, AUTHENTICATE_ACTION_SET_EXPIRE, AUTHENTICATE_ACTION_SET_QRBASE64, SET_ACTIVE_COMPONENT_NAME, SET_TEMPORARY_LOCK_DATA, SET_TWO_FACTOR_DATA } from "../../actionTypes";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";
import { message } from "antd";

const setAuthenticateActionBefore = () => ({
	type: AUTHENTICATE_ACTION_BEFORE
});

const setAuthenticateActionFinished = () => ({
	type: AUTHENTICATE_ACTION_FINISH
});

const setAuthenticateActionSetExpire = (expires) => ({
	type: AUTHENTICATE_ACTION_SET_EXPIRE,
	payload: { expires }
});

const setQRBase64 = (base64) => ({
	type: AUTHENTICATE_ACTION_SET_QRBASE64,
	payload: { base64 }
});

export const setActiveComponentNameAction = (componentName) => ({
	type: SET_ACTIVE_COMPONENT_NAME,
	payload: componentName
});

export const setTemporaryLockData = ({ isVisible, unlockDate }) => ({
	type: SET_TEMPORARY_LOCK_DATA,
	payload: { isVisible, unlockDate }
});

const setTowFactorData = (data) => ({
	type: SET_TWO_FACTOR_DATA,
	payload: data
});

export const authenticate = (userName, password, token, onPasswordExpired) => {
	return (dispatch) => {
		dispatch(setAuthenticateActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTHENTICATE}`,
			method: Methods.POST,
			data: { userName, password, token }
		})
			.then(({ status, data }) => {
				dispatch(setAuthenticateActionFinished());
				if (status < 400) {
					if (data?.value?.tokenType === TOKEN_TYPE.NONE) {
						loginUser({ ...(data?.value), userName: userName });
						setTimeout(() => {
							location.reload();
						}, 0);
					} else {
						const dataBE = { ...(data?.value), userName: userName };

						if (dataBE.tokenType === TOKEN_TYPE.QR || dataBE.tokenType === TOKEN_TYPE.TOKEN) {
							dispatch(
								setTowFactorData({
									tokenType: dataBE.tokenType,
									token: dataBE.token,
									userName: dataBE.userName
								})
							);
							dispatch(setActiveComponentNameAction(LOGIN_VIEW_COMPONENTS.TWO_FACTOR_COMPONENT));
						} else if (dataBE.tokenType === TOKEN_TYPE.PASSWORD_EXPIRED) {
							onPasswordExpired(dataBE.token);
						}
					}
				}
			})
			.catch(({ response }) => {
				if (!response) {
					dispatch(setAuthenticateActionFinished());
					message.error("Something went wrong");
					return;
				}

				const data = response.data;

				if (data.status === USER_STATUS.TEMPORARY_LOCKED) {
					dispatch(setTemporaryLockData({ isVisible: true, unlockDate: new Date(`${data.message} UTC`) }));
					dispatch(setActiveComponentNameAction(LOGIN_VIEW_COMPONENTS.MAIN_LOGIN_COMPONENT));
				}

				if (data.status === USER_STATUS.PERMANENT_LOCKED) {
					dispatch(setTemporaryLockData({ isVisible: false, unlockDate: null }));
					dispatch(setActiveComponentNameAction(LOGIN_VIEW_COMPONENTS.USER_BLOCKED_COMPONENT));
				}

				dispatch(setAuthenticateActionFinished());
			});
	};
};

export const logout = () => {
	return () => {
		const authorizationData = sessionStorageUtils.get("authorizationData");
		const refreshToken = authorizationData?.refreshToken ?? null;
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.LOGOUT}`,
			method: Methods.POST,
			data: { refreshToken }
		}).finally(() => {
			logoutUser();
		});
	};
};

export const setTokenExpiration = (expires) => {
	return (dispatch) => {
		dispatch(setAuthenticateActionSetExpire(expires));
	};
};

export const getTwoFactorQR = (token) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTHENTICATE_QR}`,
			method: Methods.GET,
			headers: {
				Authorization: "Bearer " + token
			}
		})
			.then(({ status, data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					dispatch(setQRBase64(data.value));
				}
			})
			.catch(Function.prototype);
	};
};

export const verifyQRCode = (token, code, userName, onVerifyFailCB) => {
	return (dispatch) => {
		dispatch(setAuthenticateActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTHENTICATE_TOKEN}`,
			method: Methods.POST,
			headers: {
				Authorization: "Bearer " + token
			},
			data: { token: code }
		})
			.then(({ status, data }) => {
				dispatch(setAuthenticateActionFinished());
				if (status < 400) {
					if (data?.value?.tokenType === TOKEN_TYPE.NONE) {
						loginUser({ ...(data?.value), userName });
						setTimeout(() => {
							location.reload();
						}, 0);
					}
				}
			})
			.catch(({ response: { data } }) => {
				dispatch(setAuthenticateActionFinished());

				if (data.status === USER_STATUS.TEMPORARY_LOCKED) {
					dispatch(setTemporaryLockData({ isVisible: true, unlockDate: new Date(`${data.message} UTC`) }));
					dispatch(setActiveComponentNameAction(LOGIN_VIEW_COMPONENTS.MAIN_LOGIN_COMPONENT));
					return;
				}

				if (data.status === USER_STATUS.PERMANENT_LOCKED) {
					dispatch(setTemporaryLockData({ isVisible: false, unlockDate: null }));
					dispatch(setActiveComponentNameAction(LOGIN_VIEW_COMPONENTS.USER_BLOCKED_COMPONENT));
					return;
				}

				if (typeof onVerifyFailCB === "function") {
					onVerifyFailCB();
				}
			});
	};
};
