import { STREAMS_ACTION_BEFORE, STREAMS_ACTION_FINISH, SET_DEVELOPER_SPACE_STREAMS } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case STREAMS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case STREAMS_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case SET_DEVELOPER_SPACE_STREAMS:
			return {
				...state,
				streams: payload.streams
			};
		default:
			return state;
	}
};
