import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { numberWithSpaces } from "utils/common";
import { PROJECT_TYPE } from "constants/common.constants";

import WidgetSkeleton from "components/dashboard/statistics/widgets/skeleton";

/** Dashboard Page Total Turnover Widget Component */
const TotalTurnoverWidgetComponent = ({ totalTurnover, isGamesPerformanceLoading }) => {
	const { t } = useTranslation();
	return (
		<div className="dashboard-area" data-type="totalTurnover">
			<div className="dashboard-area-header">
				<i className="icon-dashboard-total-turnover" />
				<span className="dashboard-area-header-title">{t("pages.dashboard.dashboard.turnover")}</span>
			</div>
			<div className="dashboard-area-content">
				{isGamesPerformanceLoading ? (
					<WidgetSkeleton variation="total" />
				) : (
					<span title={totalTurnover} className="dashboard-area-content-value">
						{totalTurnover}
					</span>
				)}
			</div>
		</div>
	);
};

/** TotalTurnoverWidgetComponent propTypes
 * PropTypes
 */
TotalTurnoverWidgetComponent.propTypes = {
	/** Redux state property, dashboard total turnover amount (Online / Retail) */
	totalTurnover: PropTypes.string,
	// Redux state controls games performance loading indicator
	isGamesPerformanceLoading: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
	let totalTurnover = 0;
	switch (ownProps.projectType) {
		case PROJECT_TYPE.ONLINE:
			totalTurnover = state.dashboard.online.gamesPerformance.totalTurnover || 0;
			break;
		case PROJECT_TYPE.RETAIL:
			totalTurnover = state.dashboard.retail.gamesPerformance.totalTurnover || 0;
			break;
		default:
			break;
	}
	const { minorUnit, symbol, code } = state.profile.userInfo.currency;
	totalTurnover = numberWithSpaces(totalTurnover.toFixed(minorUnit)) + " " + (symbol || code);
	const isGamesPerformanceLoading = state.dashboard.isGamesPerformanceLoading;
	return { totalTurnover, isGamesPerformanceLoading };
};

export default connect(mapStateToProps, null)(TotalTurnoverWidgetComponent);
