export const BETSLIP_MODES = {
	SINGLE: 1,
	MULTI: 2
};

export const BET_STATE = {
	PENDING: 1,
	RETURN: 2,
	WON: 4,
	SEMIWON: 8,
	SEMILOST: 16,
	LOST: 32,
	CANCELLED: 64,
	REJECTED_BY_OPERATOR: 128
};

export const BET_STATE_COLOR = {
	[BET_STATE.CANCELLED]: "geekblue",
	[BET_STATE.REJECTED_BY_OPERATOR]: "cyan",
	[BET_STATE.WON]: "green",
	[BET_STATE.LOST]: "red",
	[BET_STATE.SEMILOST]: "red",
	[BET_STATE.RETURN]: "green",
	[BET_STATE.SEMIWON]: "green"
};

export const BET_STATE_TRANSLATION_KEYS = Object.values(BET_STATE).reduce((acc, betState) => ((acc[betState] = `pages.dashboard.bets.bet_status_${betState}`), acc), {});

export const ODD_STATE = {
	PENDING: 0,
	WON: 1,
	LOST: 2,
	SEMIWON: 3,
	SEMILOST: 4,
	RETURN: 5
};

export const CANCEL_USER_TYPE = {
	NONE: 0,
	SYSTEM: 1,
	USER: 2,
	PLAYER: 4,
	CASHIER: 8
};

export const PRINT_REQUEST_STATE = {
	NONE: 0,
	PENDING: 1,
	APPROVED: 2,
	DECLINED: 3
};

export const PRINT_TYPE = {
	BET: 1,
	CANCEL: 2,
	PAYOUT: 3
};

export const FAVORITE_BET_SUM = "Sum";

export const FAVORITE_BETS = ["x2", "x3", "x4", "x5", "x7", "x10", "x20", "x50", "Min", "Max", FAVORITE_BET_SUM];

export const KEYS_OF_LOCAL_STORAGE = {
	INCLUDED_COLUMNS_SAVED_KEY: "selected_columns",
	PENDING_BETSLIPS_SAVED_KEY: "pending_betslips",
	SETTLED_BETSLIPS_SAVED_KEY: "settled_betslips"
};


export const COMBO_BOOST_STEPS = {
	FOUR_BETS: 4,
	FIVE_BETS: 5,
	SIX_BETS: 6,
	SEVEN_BETS: 7,
	EIGHT_BETS: 8,
	NINE_BETS: 9,
	TEN_BETS: 10
}

export const COMBO_BOOST_STEP_FORM_NAMES = {
	[COMBO_BOOST_STEPS.FOUR_BETS]: "fourBets",
	[COMBO_BOOST_STEPS.FIVE_BETS]: "fiveBets",
	[COMBO_BOOST_STEPS.SIX_BETS]: "sixBets",
	[COMBO_BOOST_STEPS.SEVEN_BETS]: "sevenBets",
	[COMBO_BOOST_STEPS.EIGHT_BETS]: "eightBets",
	[COMBO_BOOST_STEPS.NINE_BETS]: "nineBets",
	[COMBO_BOOST_STEPS.TEN_BETS]: "tenBets",
}

export const COMBO_BOOST_STEPS_TRANSLATIONS = {
	[COMBO_BOOST_STEPS.FOUR_BETS]: "pages.dashboard.bets.combo_boost_step_4",
	[COMBO_BOOST_STEPS.FIVE_BETS]: "pages.dashboard.bets.combo_boost_step_5",
	[COMBO_BOOST_STEPS.SIX_BETS]: "pages.dashboard.bets.combo_boost_step_6",
	[COMBO_BOOST_STEPS.SEVEN_BETS]: "pages.dashboard.bets.combo_boost_step_7",
	[COMBO_BOOST_STEPS.EIGHT_BETS]: "pages.dashboard.bets.combo_boost_step_8",
	[COMBO_BOOST_STEPS.NINE_BETS]: "pages.dashboard.bets.combo_boost_step_9",
	[COMBO_BOOST_STEPS.TEN_BETS]: "pages.dashboard.bets.combo_boost_step_10",
}