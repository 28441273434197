import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { flagsToBinary } from "utils/common";

import { SET_PERMISSION_REQUESTS_ACTION_BEFORE, SET_PERMISSION_REQUESTS_ACTION_FINISH, SET_PERMISSION_REQUESTS_SAVE_ACTION_BEFORE, SET_PERMISSION_REQUESTS_SAVE_ACTION_FINISH, SET_PERMISSION_REQUESTS_COUNT } from "store/actionTypes";

export const setPermissionRequestsActionBefore = () => ({
	type: SET_PERMISSION_REQUESTS_ACTION_BEFORE
});

export const setPermissionRequestsActionFinished = () => ({
	type: SET_PERMISSION_REQUESTS_ACTION_FINISH
});

export const setPermissionRequestsSaveActionBefore = () => ({
	type: SET_PERMISSION_REQUESTS_SAVE_ACTION_BEFORE
});

export const setPermissionRequestsSaveActionFinished = () => ({
	type: SET_PERMISSION_REQUESTS_SAVE_ACTION_FINISH
});

const setPermissionRequestsCount = (count, actionType) => ({
	type: SET_PERMISSION_REQUESTS_COUNT,
	payload: { count, actionType }
});

export const getPermissionRequestsCount = (objectId, actionTypes, objectType) => {
	return (dispatch) => {
		let actionTypesStr = "";
		if (actionTypes?.length > 0) {
			actionTypesStr = "actionTypes=" + actionTypes.join("&actionTypes=");
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PERMISSION_REQUESTS}?${actionTypesStr}`,
			method: Methods.GET,
			params: {
				objectId,
				objectType
			}
		})
			.then(({ data: { value: requests } }) => {
				dispatch(setPermissionRequestsCount(requests.length, flagsToBinary(actionTypes)));
			})
			.catch((ex) => {
				console.log(ex);
			});
	};
};
