import { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Modal from "antd/lib/modal";
import Form from "antd/lib/form";
import Select from "antd/lib/select";
import Input from "antd/lib/input";

import { POPUP_SIZE } from "constants/common.constants";

import { createStreamServerGame } from "store/actions/dashboard/streamServers";

const { Item: FormItem } = Form;

const AddStreamServerGame = ({ handleCloseModal = () => {}, isModalOpen, serverId = "", createStreamServerGame, streamServers, handleSetLoading }) => {
	const [isDuplicatedErrorText, setIsDuplicatedErrorText] = useState({});

	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue, resetFields, getFieldValue } = formInstance;

	const handleFormChange =
		(field) =>
		(e = {}) => {
			const target = e.target;

			let targetValue = e;

			if (target) {
				targetValue = target.value;
			}

			setFieldsValue({
				[field]: targetValue
			});

			validateFields(["name", "languageCode"]);
		};

	const handleForm = () => {
		validateFields()
			.then((game) => {
				const sendData = {
					id: serverId,
					game
				};

				handleSetLoading(true);

				createStreamServerGame(sendData, () => {
					resetFields(["name", "languageCode", "nanocosmosStreamName", "flussonicStreamName"]);

					handleSetLoading(false);
				});
			})
			.catch(Function.prototype);
	};

	const gameNamesOptions = useMemo(() => {
		return streamServers.availableGames.allGames
			? streamServers.availableGames.allGames.map((game) => ({
					value: game,
					label: game
				}))
			: [];
	}, [streamServers]);

	const streamLanguageOptions = useMemo(() => {
		return streamServers.availableGames.languageCodes
			? streamServers.availableGames.languageCodes.map((lng) => ({
					value: lng,
					label: t(`languages.${lng}`)
				}))
			: [];
	}, [streamServers]);

	useEffect(() => {
		if (!isModalOpen) {
			setIsDuplicatedErrorText({});

			resetFields(["name", "languageCode", "nanocosmosStreamName", "flussonicStreamName"]);
		}
	}, [isModalOpen]);

	return (
		<Modal open={isModalOpen} onCancel={handleCloseModal} onOk={handleForm} okText={t("common.add")} closable={false} maskClosable={false} centered width={POPUP_SIZE.SMALL}>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					name: undefined,
					languageCode: undefined,
					nanocosmosStreamName: undefined,
					flussonicStreamName: undefined
				}}
			>
				<Row>
					<Col span={24}>
						<FormItem
							label={`${t("pages.dashboard.developerSpaceServerStreams.selectGameLabel")} *`}
							name="name"
							rules={[
								{ required: true, message: t("validation.field_required") },
								() => ({
									validator(_, value) {
										const formData = {
											name: value,
											languageCode: getFieldValue("languageCode")
										};

										const existedStreamServerGames = streamServers.data.reduce((acc, server) => [...acc, ...server.games], []);

										const isDuplicated = existedStreamServerGames.some((game) => game.name === formData.name && !!formData.languageCode && game.languageCode === formData.languageCode);

										if (!isDuplicated) {
											return Promise.resolve();
										}

										return Promise.reject(new Error(t("pages.dashboard.developerSpaceServerStreams.nameInputFieldErrorText")));
									}
								})
							]}
							validateStatus={!!isDuplicatedErrorText.name && "error"}
							help={isDuplicatedErrorText.name}
						>
							<Select getPopupContainer={() => document.body} options={gameNamesOptions} suffixIcon={<i className="icon-down" />} onChange={handleFormChange("name")} placeholder={t("pages.dashboard.developerSpaceServerStreams.selectPlaceholder")} />
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem
							label={`${t("pages.dashboard.developerSpaceServerStreams.selectStreamLanguageLabel")} *`}
							name="languageCode"
							rules={[
								{
									required: true,
									message: t("validation.field_required")
								},
								() => ({
									validator(_, value) {
										const formData = {
											name: getFieldValue("name"),
											languageCode: value
										};

										const existedStreamServerGames = streamServers.data.reduce((acc, server) => [...acc, ...server.games], []);

										const isDuplicated = existedStreamServerGames.some((game) => game.name === formData.name && !!formData.languageCode && game.languageCode === formData.languageCode);

										if (!isDuplicated) {
											return Promise.resolve();
										}

										return Promise.reject(new Error(t("pages.dashboard.developerSpaceServerStreams.languageCodeInputFieldErrorText")));
									}
								})
							]}
							validateStatus={!!isDuplicatedErrorText.languageCode && "error"}
							help={isDuplicatedErrorText.languageCode}
						>
							<Select getPopupContainer={() => document.body} options={streamLanguageOptions} suffixIcon={<i className="icon-down" />} onChange={handleFormChange("languageCode")} placeholder={t("pages.dashboard.developerSpaceServerStreams.selectPlaceholder")} />
						</FormItem>
					</Col>
					<Col xs={24}>
						<FormItem label={t("pages.dashboard.developerSpaceServerStreams.inputFlussonicNameLabel")} name="flussonicStreamName">
							<Input maxLength={50} onChange={handleFormChange("flussonicStreamName")} placeholder={t("pages.dashboard.developerSpaceServerStreams.inputPlaceholder")} />
						</FormItem>
					</Col>
					<Col xs={24}>
						<FormItem label={t("pages.dashboard.developerSpaceServerStreams.inputNanocosmicNameLabel")} name="nanocosmosStreamName">
							<Input maxLength={50} onChange={handleFormChange("nanocosmosStreamName")} placeholder={t("pages.dashboard.developerSpaceServerStreams.inputPlaceholder")} />
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

AddStreamServerGame.propTypes = {
	// Closing handler prop of modal
	handleCloseModal: PropTypes.func,
	// The current id of stream server
	serverId: PropTypes.string,
	// Redux async action to create stream server game
	createStreamServerGame: PropTypes.func,
	// Redux state of stream servers data
	streamServers: PropTypes.object,
	// Handler prop to set server item loading
	handleSetLoading: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		streamServers: state.streamServers
	};
};

const mapDispatchToProps = (dispatch) => ({
	createStreamServerGame: (data, cb) => {
		dispatch(createStreamServerGame(data, cb));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(AddStreamServerGame);
