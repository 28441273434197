import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { ERRORS_ACTION_BEFORE, ERRORS_ACTION_FINISH, ERROR_ACTION_BEFORE, ERROR_ACTION_FINISH, SET_ERRORS, SET_ERRORS_SORTING, SET_ERRORS_FILTERS, SET_ERROR } from "store/actionTypes";

const setErrorsActionBefore = () => ({
	type: ERRORS_ACTION_BEFORE
});

const setErrorsActionFinished = () => ({
	type: ERRORS_ACTION_FINISH
});

const setErrorActionBefore = () => ({
	type: ERROR_ACTION_BEFORE
});

const setErrorActionFinished = () => ({
	type: ERROR_ACTION_FINISH
});

const setErrors = (errors) => ({
	type: SET_ERRORS,
	payload: { errors }
});

const setError = (error) => ({
	type: SET_ERROR,
	payload: { error }
});

export const setErrorsSorting = (sorting) => ({
	type: SET_ERRORS_SORTING,
	payload: { sorting }
});

export const setErrorFilters = (filters) => ({
	type: SET_ERRORS_FILTERS,
	payload: { filters }
});

export const getErrors = (params) => {
	return (dispatch, getState) => {
		dispatch(setErrorsActionBefore());
		let queryParams = {};
		let strParams = "";
		if (params) {
			queryParams = params;
		} else {
			queryParams = {
				...getState().errors.sorting,
				...getState().errors.filters
			};
		}
		const { excludeController, ...otherParams } = queryParams;
		if (excludeController && Array.isArray(excludeController) && excludeController.length) {
			strParams = "excludeControllers=" + excludeController.join("&excludeControllers=");
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_ERROR_LOGS}` + (strParams ? "?" + strParams : ""),
			method: Methods.GET,
			params: otherParams
		})
			.then(({ data: { value: errors } }) => {
				dispatch(setErrors(errors));
				dispatch(setErrorsActionFinished());
			})
			.catch((ex) => {
				dispatch(setErrorsActionFinished());
			});
	};
};

export const getError = (id) => {
	return (dispatch, getState) => {
		dispatch(setErrorActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_ERROR_LOG}`,
			method: Methods.GET,
			params: { id, year: getState().errors.filters.year, month: getState().errors.filters.month }
		})
			.then(({ data: { value: error } }) => {
				dispatch(setError(error));
				dispatch(setErrorActionFinished());
			})
			.catch(() => {
				dispatch(setErrorActionFinished());
			});
	};
};
