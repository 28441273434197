import { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Col, Row, Modal, Input, Select } from "antd";
const { Item: FormItem } = Form;

import { createTranslation } from "store/actions/dashboard/cms/translation/translation.action";

import translationGroupType from "types/translation/group.type";

import { POPUP_SIZE } from "constants/common.constants";

/** Translation Creating Popup Component */
const TranslationAddComponent = ({ isSaving, createTranslation, groups, groupId, subGroupId, languageCodes, onClose }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;

	/** Fires when form submitted
	 * @function
	 * @memberOf TranslationAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ key, text }) => {
				const translation = {
					key: key,
					groupId: groupId,
					subGroupId: subGroupId,
					text: text
				};
				createTranslation(translation, languageCodes);
			})
			.catch(Function.prototype);
	};

	return (
		<Modal open={true} title={t("pages.dashboard.translations.add_new_translation")} cancelText={t("common.cancel")} okText={t("common.create")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.SMALL} maskClosable={false} closable={false} okButtonProps={{ loading: isSaving }} centered>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					key: "",
					group: subGroupId || groupId
				}}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={t("pages.dashboard.translations.resource")} name="group">
							<Select disabled={true} suffixIcon={<i className="icon-down" />}>
								{groups
									.filter((g) => g.name.toLowerCase() !== "rules")
									.map((group) =>
										group.subGroups.length > 0 ? (
											<Select.OptGroup value={group.id} key={group.id} label={group.name}>
												{group.subGroups.map((sub) => (
													<Select.Option value={sub.id} key={sub.id}>
														{sub.name}
													</Select.Option>
												))}
											</Select.OptGroup>
										) : (
											<Select.Option value={group.id} key={group.id}>
												{group.name}
											</Select.Option>
										)
									)}
							</Select>
						</FormItem>
					</Col>
				</Row>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={`${t("pages.dashboard.translations.translation_key")} *`} name="key" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.translations.translation_key")}`} autoFocus={true} autoComplete="off" />
						</FormItem>
					</Col>
				</Row>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={`${t("pages.dashboard.translations.translation")} *`} name="text" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.translations.translation")}`} />
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** TranslationAddComponent propTypes
 * PropTypes
 */
TranslationAddComponent.propTypes = {
	/** Redux state property, is true when creating translation request is in process */
	isSaving: PropTypes.bool,
	/** Redux action to create translation */
	createTranslation: PropTypes.func,
	/** Redux state property, translation groups */
	groups: PropTypes.arrayOf(translationGroupType),
	/** Group id of new translation */
	groupId: PropTypes.string,
	/** Sub group id of new translation */
	subGroupId: PropTypes.string,
	/** The list of arrays of current showing languages  */
	languageCodes: PropTypes.arrayOf(PropTypes.string),
	/** Fires on popup close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	createTranslation: (data, languageCodes) => {
		dispatch(createTranslation(data, languageCodes));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.translations.isSaving,
		groups: state.translations.groups
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TranslationAddComponent);
