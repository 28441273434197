import { SET_BETSLIP_CORRECTION_ACTION_BEFORE, SET_BETSLIP_CORRECTION_ACTION_FINISH, SET_BETSLIP_CORRECTIONS, SET_BETSLIP_CORRECTIONS_FILTERS, SET_BETSLIP_CORRECTIONS_SORTING } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_BETSLIP_CORRECTION_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case SET_BETSLIP_CORRECTION_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case SET_BETSLIP_CORRECTIONS:
			return {
				...state,
				total: payload.item1,
				data: payload.item2
			};
		case SET_BETSLIP_CORRECTIONS_FILTERS:
			return {
				...state,
				filters: payload.filters
			};
		case SET_BETSLIP_CORRECTIONS_SORTING:
			return {
				...state,
				sorting: payload
			};
		default:
			return state;
	}
};
