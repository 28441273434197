import { EVENTS_BEFORE, EVENTS_FINISH, SET_EVENTS, SET_EVENTS_SORTING, SET_EVENTS_FILTERS, EVENT_BEFORE, EVENT_FINISH, SET_EVENT } from "../../actionTypes";

import { PROJECT_TYPE } from "constants/common.constants";

export default (state = {}, { type, payload }) => {
	const projectType = payload && payload.projectType === PROJECT_TYPE.RETAIL ? "retail" : "online";
	const gameCategory = payload ? payload.gameCategory : null;
	switch (type) {
		case EVENTS_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case EVENTS_FINISH:
			return {
				...state,
				isLoading: false
			};
		case SET_EVENTS:
			return {
				...state,
				[projectType]: {
					...state[projectType],
					[gameCategory]: {
						...state[projectType][gameCategory],
						events: payload.data.item2,
						total: payload.data.item1
					}
				}
			};
		case SET_EVENTS_SORTING:
			return {
				...state,
				[projectType]: {
					...state[projectType],
					[gameCategory]: {
						...state[projectType][gameCategory],
						sorting: payload.sorting
					}
				}
			};
		case SET_EVENTS_FILTERS:
			return {
				...state,
				[projectType]: {
					...state[projectType],
					[gameCategory]: {
						...state[projectType][gameCategory],
						filters: payload.filters,
						sorting: {
							...state[projectType].sorting,
							page: 1
						}
					}
				}
			};
		case EVENT_BEFORE:
			return {
				...state,
				isEventLoading: true
			};
		case EVENT_FINISH:
			return {
				...state,
				isEventLoading: false
			};
		case SET_EVENT:
			return {
				...state,
				event: payload.data
			};
		default:
			return state;
	}
};
