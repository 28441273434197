export default {
	/** Set in localstorage
	 * @function
	 * @param {string} key - key
	 * @param {Object} value - value
	 */
	set: (key, value) => {
		localStorage.setItem(key, JSON.stringify(value));
	},

	/** Clear localstorage
	 * @function
	 */
	clear: () => {
		localStorage.clear();
	},

	/** Remove from localstorage
	 * @function
	 * @param {string} key - key
	 */
	remove: (key) => {
		localStorage.removeItem(key);
	},

	/** Get from localstorage
	 * @function
	 * @param {string} key - key
	 * @returns {Object}
	 */
	get: (key) => {
		var data = localStorage.getItem(key);
		if (data === "undefined") return undefined;
		return data ? JSON.parse(data) : data;
	}
};
