import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { USER_LOGS_BEFORE, USER_LOGS_FINISH, SET_USER_LOGS, SET_USER_LOGS_SORTING, SET_USER_LOGS_FILTERS, SET_USER_LOG_DETAILS, SET_USER_LOG_RESOURCES } from "../../../actionTypes";

const setUserLogsBefore = () => ({
	type: USER_LOGS_BEFORE
});

const setUserLogsFinished = () => ({
	type: USER_LOGS_FINISH
});

const setUserLogs = (userLogs) => ({
	type: SET_USER_LOGS,
	payload: { userLogs }
});

const setUserLogDetails = (id, details) => ({
	type: SET_USER_LOG_DETAILS,
	payload: { id, details }
});

const setUserLogResources = (resources) => ({
	type: SET_USER_LOG_RESOURCES,
	payload: { resources }
});

export const setUserLogsSorting = (sorting) => ({
	type: SET_USER_LOGS_SORTING,
	payload: { sorting }
});

export const setUserLogsFilters = (filters) => ({
	type: SET_USER_LOGS_FILTERS,
	payload: { filters }
});

export const getUserLogs = (fromFirstPage) => {
	return (dispatch, getState) => {
		dispatch(setUserLogsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_LOGS_GETALL}`,
			method: Methods.GET,
			params: {
				...getState().userLogs.sorting,
				...getState().userLogs.filters,
				page: fromFirstPage ? 1 : getState().userLogs.sorting.page
			}
		})
			.then(({ data: { value: userLogs } }) => {
				dispatch(setUserLogs(userLogs));
				if (fromFirstPage) dispatch(setUserLogsSorting({ ...getState().userLogs.sorting, page: 1 }));
				dispatch(setUserLogsFinished());
			})
			.catch(() => {
				dispatch(setUserLogsFinished());
			});
	};
};

export const getUserLogDetails = (id) => {
	return (dispatch, getState) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_LOGS_GET_DETAILS}`,
			method: Methods.GET,
			params: { id, year: getState().userLogs.filters.year, month: getState().userLogs.filters.month }
		}).then(({ data: { value: data } }) => {
			dispatch(setUserLogDetails(id, data));
		});
	};
};

export const getUserLogResources = () => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_LOGS_GET_RESOURCES}`,
			method: Methods.GET
		}).then(({ data: { value: resources } }) => {
			dispatch(setUserLogResources(resources));
		});
	};
};
