import {
	BETSHOP_PERFORMANCE_REPORT_BEFORE,
	BETSHOP_PERFORMANCE_REPORT_FINISH,
	SET_BETSHOP_PERFORMANCE_REPORT,
	SET_BETSHOP_PERFORMANCE_REPORT_FILTERS,
	PROJECT_PERFORMANCE_REPORT_BEFORE,
	PROJECT_PERFORMANCE_REPORT_FINISH,
	SET_PROJECT_PERFORMANCE_REPORT,
	SET_PROJECT_PERFORMANCE_REPORT_FILTERS,
	PARTNER_ONLINE_REPORT_BEFORE,
	PARTNER_ONLINE_REPORT_FINISH,
	SET_PARTNER_ONLINE_REPORT,
	SET_PARTNER_ONLINE_REPORT_FILTERS,
	SET_PARTNER_ONLINE_REPORT_SORTING
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case BETSHOP_PERFORMANCE_REPORT_BEFORE:
			return {
				...state,
				betshopPerformance: {
					...state.betshopPerformance,
					isLoading: true
				}
			};
		case BETSHOP_PERFORMANCE_REPORT_FINISH:
			return {
				...state,
				betshopPerformance: {
					...state.betshopPerformance,
					isLoading: false
				}
			};
		case SET_BETSHOP_PERFORMANCE_REPORT:
			return {
				...state,
				betshopPerformance: {
					...state.betshopPerformance,
					betshopPerformanceReport: payload.betshopPerformance
				}
			};
		case SET_BETSHOP_PERFORMANCE_REPORT_FILTERS:
			return {
				...state,
				betshopPerformance: {
					...state.betshopPerformance,
					filters: payload.filters
				}
			};
		case PROJECT_PERFORMANCE_REPORT_BEFORE:
			return {
				...state,
				projectPerformance: {
					...state.projectPerformance,
					isLoading: true
				}
			};
		case PROJECT_PERFORMANCE_REPORT_FINISH:
			return {
				...state,
				projectPerformance: {
					...state.projectPerformance,
					isLoading: false
				}
			};
		case SET_PROJECT_PERFORMANCE_REPORT:
			return {
				...state,
				projectPerformance: {
					...state.projectPerformance,
					projectPerformanceReport: payload.projectPerformance
				}
			};
		case SET_PROJECT_PERFORMANCE_REPORT_FILTERS:
			return {
				...state,
				projectPerformance: {
					...state.projectPerformance,
					filters: payload.filters
				}
			};
		case PARTNER_ONLINE_REPORT_BEFORE:
			return {
				...state,
				partnerOnlineReport: {
					...state.partnerOnlineReport,
					isLoading: true
				}
			};
		case PARTNER_ONLINE_REPORT_FINISH:
			return {
				...state,
				partnerOnlineReport: {
					...state.partnerOnlineReport,
					isLoading: false
				}
			};
		case SET_PARTNER_ONLINE_REPORT:
			return {
				...state,
				partnerOnlineReport: {
					...state.partnerOnlineReport,
					partnerOnlineReport: payload.partnerOnlineReport.item2,
					partnerOnlineReportTotal: payload.partnerOnlineReport.item1
				}
			};
		case SET_PARTNER_ONLINE_REPORT_FILTERS:
			return {
				...state,
				partnerOnlineReport: {
					...state.partnerOnlineReport,
					filters: payload.filters
				}
			};
		case SET_PARTNER_ONLINE_REPORT_SORTING:
			return {
				...state,
				partnerOnlineReport: {
					...state.partnerOnlineReport,
					sorting: payload.sorting
				}
			};
		default:
			return state;
	}
};
