import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Row, Col, Input, Form, Button, Divider } from "antd";
const { Item: FormItem } = Form;

import PermissionsList from "components/dashboard/common/permissions";

import { getPermissionGroupGeneralInfo, savePermissionGroupGeneralInfo } from "store/actions/dashboard/userManagement/permissionGroups/generalInfo.action";

import generalInfoType from "types/permissionGroup/generalInfo.type";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import Question from 'components/common/question';
import { doesUserHaveRoleOf, getUser } from 'utils/auth';
import { USER_ROLE } from 'constants/user.constants';
import { PERMISSION_REQUEST_OBJECT_TYPE, PERMISSION_REQUEST_TYPE } from 'constants/permissionRequest.constants';
import PendingRequestsNote from 'components/common/pendingRequestsNote';

/** Permission Group Edit Page General Info Tab Component */

const GeneralInfoComponent = ({ getPermissionGroupGeneralInfo, savePermissionGroupGeneralInfo, generalInfo, isLoading, isSaving, onTabChange }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [initialValues, setInitialValues] = useState({ permissions: {} });
	const [confirmationData, setConfirmationData] = useState(null);
	const [disableDueToNumberOfRequests, setDisableDueToNumberOfRequests] = useState(false);
	const isAccessManager = doesUserHaveRoleOf(USER_ROLE.ACCESS_MANAGER);
	const isDisabled = (isAccessManager && disableDueToNumberOfRequests) || !hasPermission({ resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.MODIFY });

	/** Load general info */
	useEffect(() => {
		if (routeParams.id) {
			getPermissionGroupGeneralInfo(routeParams.id);
		}
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		const permissions = {};
		generalInfo.permissions.forEach((perm) => {
			let data = {};
			data["parent"] = {};
			perm.actions.forEach((a) => {
				data["parent"][a.action] = a.checked;
			});
			perm.subPermissions.forEach((sub) => {
				data[sub.resource] = {};
				sub.actions.forEach((a) => {
					data[sub.resource][a.action] = a.checked;
				});
			});
			permissions[perm.resource] = data;
		});

		const values = {
			name: generalInfo.name,
			description: generalInfo.description,
			permissions: permissions
		};
		setFieldsValue(values);
		setInitialValues(values);
	}, [generalInfo]);

	/** Fires when form submitted
	 * @function
	 * @memberOf GeneralInfoComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				const perms = Object.keys(data.permissions).map((resource) => ({
					resource: resource,
					actions: data.permissions[resource].parent ? Object.keys(data.permissions[resource].parent).filter((k) => data.permissions[resource]["parent"][k] === true) : [],
					subPermissions: Object.keys(data.permissions[resource])
						.filter((r) => r !== "parent")
						.map((r) => ({
							resource: r,
							actions: data.permissions[resource][r] ? Object.keys(data.permissions[resource][r]).filter((k) => data.permissions[resource][r][k] === true) : []
						}))
				}));

				const objToSend = {
					id: routeParams.id,
					permissions: perms,
					name: data.name,
					description: data.description
				};

				if (isAccessManager) {
					setConfirmationData({
						onOk: (note) => {
							savePermissionGroupGeneralInfo({ ...objToSend, note });
							setIsFormTouched(false);
							setConfirmationData(null);
						},
						onCancel: () => {
							setConfirmationData(null);
						}
					});
					return;
				}

				savePermissionGroupGeneralInfo(objToSend)
				setIsFormTouched(false);

			}).catch(Function.prototype)
	}

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Form colon={false} requiredMark={false} layout="vertical" form={formInstance} onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, initialValues))}>
			<div className="dashboard-section-content">
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} md={8}>
						<PendingRequestsNote
							objectId={routeParams.id}
							actionTypes={[PERMISSION_REQUEST_TYPE.GROUP_MODIFY]}
							objectType={PERMISSION_REQUEST_OBJECT_TYPE.PERMISSION_GROUP}
							dependencies={[generalInfo]}
							onCountChange={(count) => {
								setDisableDueToNumberOfRequests(count > 0);
							}}
							style={{
								/* for handle scss/less style loading priority mistake by webpack */
								marginBottom: 16
							}}
						/>
						<FormItem label={`${t("pages.dashboard.permissionGroups.name")} *`} name="name" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
							<Input maxLength={100} placeholder={`${t("common.enter")} ${t("pages.dashboard.permissionGroups.name")}`} disabled={isAccessManager || isDisabled} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} md={16}>
						<FormItem label={`${t("pages.dashboard.permissionGroups.description")}`} name="description" rules={[{ max: 10000, message: t("validation.field_invalid") }]}>
							<Input.TextArea maxLength={10000} rows={4} placeholder={t("pages.dashboard.permissionGroups.description")} disabled={isAccessManager || isDisabled} />
						</FormItem>
					</Col>
				</Row>
				<Divider />
				<PermissionsList permissions={generalInfo.permissions} isLoading={isLoading} formInstance={formInstance} initialFormValues={initialValues.permissions} editable={true} onCheckAll={() => setIsFormTouched(true)} disabled={isDisabled} fieldName="permissions" />
				{isDisabled ? null : (
					<Fragment>
						{confirmationData ? <Question title={t("pages.dashboard.permissions_requests.notes")} type="prompt" isVisible={true} isPromptRequired={true} promptLabel={t("pages.dashboard.permissions_requests.notes")} onOk={confirmationData.onOk} onCancel={confirmationData.onCancel} /> : null}
						<FormItem className="button-container">
							<Button disabled={disableDueToNumberOfRequests || !isFormTouched} loading={isSaving} type="primary" htmlType="submit" className="button" onClick={() => handleForm()}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					</Fragment>
				)}
			</div>
		</Form>
	);
};

/** GeneralInfoComponent propTypes
 * PropTypes
 */
GeneralInfoComponent.propTypes = {
	/** Redux action to get permission group general info */
	getPermissionGroupGeneralInfo: PropTypes.func,
	/** Redux action to save permission group general info */
	savePermissionGroupGeneralInfo: PropTypes.func,
	/** Redux state property, represents the general info of current editing permission group */
	generalInfo: generalInfoType,
	/** Redux state property, is true when loading permission group general info */
	isLoading: PropTypes.bool,
	/** Redux state property, is true when saving permission group general info */
	isSaving: PropTypes.bool,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getPermissionGroupGeneralInfo: (id) => {
		dispatch(getPermissionGroupGeneralInfo(id));
	},
	savePermissionGroupGeneralInfo: (generalInfo) => {
		dispatch(savePermissionGroupGeneralInfo(generalInfo));
	}
});

const mapStateToProps = (state) => {
	return {
		generalInfo: state.permissionGroups.editingPermissionGroup.generalInfo,
		isSaving: state.permissionGroups.isSaving,
		isLoading: state.permissionGroups.isLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent);
