import PropTypes from "prop-types";

import { Form, Col, Select } from "antd";

import { BONUS_TYPE, FIELD_NAMES } from "constants/bonus.constants";

const DefaultView = ({ handleBonusTypeFieldChange, t }) => {
	return (
		<>
			<Col xs={24} sm={12}>
				<Form.Item name={FIELD_NAMES.BONUSE_TYPE} label={t("pages.dashboard.bonuses.type")}>
					<Select placeholder={t("pages.dashboard.bonuses.type")} suffixIcon={<i className="icon-down" />} onChange={handleBonusTypeFieldChange}>
						<Select.Option value={BONUS_TYPE.FREEBET}>{t("pages.dashboard.bonuses.freebet")}</Select.Option>
						<Select.Option value={BONUS_TYPE.DOUBLEDOOBLE}>{t("pages.dashboard.bonuses.doubleDooble")}</Select.Option>
					</Select>
				</Form.Item>
			</Col>
			<Col xs={24} sm={12}>
				<Form.Item name={FIELD_NAMES.CURRENCY} label={`${t("pages.dashboard.bonuses.currency")} *`}>
					<Select placeholder={t("pages.dashboard.bonuses.currency")} suffixIcon={<i className="icon-down" />} disabled={true} />
				</Form.Item>
			</Col>
			<Col xs={24} sm={12}>
				<Form.Item name={FIELD_NAMES.BETSHOP_IDS} label={`${t("pages.dashboard.bonuses.betshopIds")} *`}>
					<Select placeholder={t("pages.dashboard.bonuses.betshopIds")} suffixIcon={<i className="icon-down" />} disabled={true} />
				</Form.Item>
			</Col>
			<Col xs={24} sm={12}>
				<Form.Item name={FIELD_NAMES.GAME_TYPE} label={`${t("pages.dashboard.bonuses.gameType")} *`}>
					<Select placeholder={`${t("common.select")} ${t("pages.dashboard.bonuses.gameType")}`} suffixIcon={<i className="icon-down" />} disabled={true} />
				</Form.Item>
			</Col>
		</>
	);
};

/** DefaultView propTypes
 * PropTypes
 */
DefaultView.propTypes = {
	/** Fires on bonus type field change */
	handleBonusTypeFieldChange: PropTypes.func,
	/**  Translates texts */
	t: PropTypes.func
};

export default DefaultView;
