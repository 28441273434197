import i18n from "translations/config";
import moment from "moment";

import localStorageUtils from "utils/localStorage";
import { convertCurrencies, getPartnerCurrencyDecimalCount } from "utils/currency";
import { numberWithSpaces } from "utils/common";
import { PROJECT_TYPE } from "constants/common.constants";
import { BETSLIP_MODES, KEYS_OF_LOCAL_STORAGE } from "constants/bet.constants";
import { DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import { FILTER_SHOW_IN_CURRENCY } from "constants/filter.constants";
import { PAYMENT_TYPE_TEXT } from "constants/partner.constants";
import { createElement } from "react";

export const columnsTitlesByProjectType = (projectType, fieldName, fallback = "- - -") => {
	// switch (projectType) {
	// 	case PROJECT_TYPE.ONLINE:
	// 		return [
	// 			{
	// 				title: i18n.t("pages.dashboard.bets.player_external_id"),
	// 				[fieldName]: "externalId",
	// 				render: (value, record) => {
	// 					let renderValue = value
	// 					if (!Array.isArray(record.bets) && record.type === BETSLIP_MODES.MULTI) {
	// 						renderValue = fallback;
	// 					}
	// 					return (
	// 						createElement("div", { style: { width: 250 } }, renderValue)
	// 					)
	// 				},
	// 				sorter: false,
	// 				copy: true,
	// 				copyCondition: (record) => record.type === BETSLIP_MODES.SINGLE || Array.isArray(record.bets)
	// 			},
	// 			{
	// 				title: i18n.t("pages.dashboard.bets.player_id"),
	// 				[fieldName]: "userId",
	// 				render: (value, record) => {
	// 					let renderValue = value
	// 					if (!Array.isArray(record.bets) && record.type === BETSLIP_MODES.MULTI) {
	// 						renderValue = fallback;
	// 					}
	// 					return (
	// 						createElement("div", { style: { width: 400 } }, renderValue)
	// 					)
	// 				},
	// 				sorter: false,
	// 				copy: true,
	// 				copyCondition: (record) => record.type === BETSLIP_MODES.SINGLE || Array.isArray(record.bets)
	// 			},
	// 		];
	// 	case PROJECT_TYPE.RETAIL:
	// 		return [
	// 			{
	// 				title: i18n.t("pages.dashboard.partners.retail_settings.retail_configs.payment_type"),
	// 				[fieldName]: "paymentType",
	// 				render: (value, record) => {
	// 					let renderValue = fallback
	// 					const condition = record.type === BETSLIP_MODES.SINGLE || Array.isArray(record.bets)
	// 					if (condition) {
	// 						renderValue = i18n.t(PAYMENT_TYPE_TEXT[value])
	// 					}
	// 					return (
	// 						createElement("div", { style: { width: 150 } }, renderValue)
	// 					)
	// 				}
	// 			}
	// 		];
	// 	default:
	// 		break;
	// }
	// return [];
	switch (projectType) {
		case PROJECT_TYPE.ONLINE:
			return [
				{ title: i18n.t("pages.dashboard.bets.player_id"), [fieldName]: "userId" },
				{ title: i18n.t("pages.dashboard.bets.player_external_id"), [fieldName]: "externalId" }
			];
		case PROJECT_TYPE.RETAIL:
			return [
				{
					title: i18n.t("pages.dashboard.partners.retail_settings.retail_configs.payment_type"),
					[fieldName]: "paymentType",
					render: (value, record) => {
						const isMultiBetInfo = record.type === BETSLIP_MODES.MULTI && !Array.isArray(record.bets);
						return isMultiBetInfo ? fallback : i18n.t(PAYMENT_TYPE_TEXT[value])
					}
				}
			];
		default:
			break;
	}
	return [];
};

/*
	localStorageUtils.get("selected_columns") || {})["settled_betslips"] || []
*/

export const getIncludedColumnsFromLocalStorage = (key) => {
	const selectedColumns = localStorageUtils.get(KEYS_OF_LOCAL_STORAGE.INCLUDED_COLUMNS_SAVED_KEY);
	switch (true) {
		case !selectedColumns:
			return [];
		case !selectedColumns[key]:
			return [];
		default:
			break;
	}
	return selectedColumns[key];
};

export const setIncludedColumnsFromLocalStorage = (key, value) => {
	const columns = localStorageUtils.get(KEYS_OF_LOCAL_STORAGE.INCLUDED_COLUMNS_SAVED_KEY) || {};
	columns[key] = value;
	localStorageUtils.set(KEYS_OF_LOCAL_STORAGE.INCLUDED_COLUMNS_SAVED_KEY, columns);
};

export const getFilteredColumns = (arrForFilter, arrForFind, arrForIncludeChecking) => {
	return arrForFilter.filter((filteringElement) => {
		const filteringElementData = arrForFind.find((findingElement) => findingElement.key === filteringElement.dataIndex);

		if (!filteringElementData) {
			return true;
		}

		return arrForIncludeChecking.includes(filteringElement.dataIndex);
	});
};

export const convertAmt = (value, record, currencies, currency, globalPartnerId, filters) => {
	if (filters.showIn === FILTER_SHOW_IN_CURRENCY.ORIGINAL) {
		const currencyDecimalCount = getPartnerCurrencyDecimalCount(record.currencyId, currencies, globalPartnerId, record.betTime);

		return numberWithSpaces(Number(value).toFixed(currencyDecimalCount));
	}

	return numberWithSpaces(convertCurrencies(value, record.currencyId, currency.code, currencies, globalPartnerId, record.betTime));
};

export const assignIdsForBets = (responseModel, showBetsBySeparate) => {
	if (!showBetsBySeparate) {
		return responseModel
	}

	const newResponseModel = { ...responseModel }

	newResponseModel.item2 = newResponseModel.item2.map(betData => {
		const newBetData = { ...betData }
		newBetData.id = `${betData.betSlipId}-${betData.betId ?? "null"}`
		return newBetData
	})

	return newResponseModel;
}
