import moment from "moment";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import i18n from "translations/config";
import Tooltip from "antd/es/tooltip";
import Tag from "antd/es/tag";
import { PERMISSION_REQUEST_OBJECT_TYPE, PERMISSION_REQUEST_STATUS, PERMISSION_REQUEST_TYPE } from "constants/permissionRequest.constants";

export const getTableColumns = (isHistory = false) => {
	return [
		{
			title: i18n.t("pages.dashboard.users.username"),
			dataIndex: "userName",
			sorting: isHistory
		},
		{
			title: i18n.t("pages.dashboard.permissions_requests.actionTypes.actionType"),
			dataIndex: "actionType",
			render: (value) => {
				switch (value) {
					case PERMISSION_REQUEST_TYPE.GROUP_MODIFY:
						return i18n.t("pages.dashboard.permissions_requests.actionTypes.permissionGroupModify");
					case PERMISSION_REQUEST_TYPE.ADD_PARTNER_ACCESS:
						return i18n.t("pages.dashboard.permissions_requests.actionTypes.addPartnerAccess");
					case PERMISSION_REQUEST_TYPE.DELETE_PARTNER_ACCESS:
						return i18n.t("pages.dashboard.permissions_requests.actionTypes.deletePartnerAccess");
					case PERMISSION_REQUEST_TYPE.ADD_PROJECT_ACCESS:
						return i18n.t("pages.dashboard.permissions_requests.actionTypes.addProjectAccess");
					case PERMISSION_REQUEST_TYPE.DELETE_PROJECT_ACCESS:
						return i18n.t("pages.dashboard.permissions_requests.actionTypes.deleteProjectAccess");
					case PERMISSION_REQUEST_TYPE.ADD_BETSHOP_ACCESS:
						return i18n.t("pages.dashboard.permissions_requests.actionTypes.addBetshopAccess");
					case PERMISSION_REQUEST_TYPE.DELETE_BETSHOP_ACCESS:
						return i18n.t("pages.dashboard.permissions_requests.actionTypes.deleteBetshopAccess");
					case PERMISSION_REQUEST_TYPE.ADD_PERMISSION_GROUP:
						return i18n.t("pages.dashboard.permissions_requests.actionTypes.addPermissionGroup");
					case PERMISSION_REQUEST_TYPE.DELETE_PERMISSION_GROUP:
						return i18n.t("pages.dashboard.permissions_requests.actionTypes.deletePermissionGroup");
					default:
						return "";
				}
			},
			sorting: isHistory
		},
		{
			title: i18n.t("pages.dashboard.permissions_requests.objectTypes.objectType"),
			dataIndex: "objectType",
			render: (value) => (value === PERMISSION_REQUEST_OBJECT_TYPE.PERMISSION_GROUP ? i18n.t("pages.dashboard.permissions_requests.objectTypes.permissionGroup") : value === PERMISSION_REQUEST_OBJECT_TYPE.USER ? i18n.t("pages.dashboard.permissions_requests.objectTypes.user") : ""),
			sorting: isHistory
		},
		...(isHistory
			? [
					{
						title: i18n.t("pages.dashboard.users.status"),
						dataIndex: "status",
						render: (value) => {
							const color = value === PERMISSION_REQUEST_STATUS.APPROVED ? "green" : value === PERMISSION_REQUEST_STATUS.REJECTED ? "red" : "cyan";
							const txt = value === PERMISSION_REQUEST_STATUS.APPROVED ? i18n.t("pages.dashboard.permissions_requests.approve") : value === PERMISSION_REQUEST_STATUS.REJECTED ? i18n.t("pages.dashboard.permissions_requests.reject") : null;
							return <Tag color={color}>{txt}</Tag>;
						},
						sorting: isHistory
					}
				]
			: []),
		{
			title: i18n.t("pages.dashboard.permissions_requests.notes"),
			dataIndex: isHistory ? "note" : "requestNote",
			short: true
		},
		...(isHistory
			? [
					{
						title: i18n.t("pages.dashboard.permissions_requests.rejectNotes"),
						dataIndex: "rejectNote",
						short: true
					},
					{
						title: i18n.t("pages.dashboard.permissionGroups.last_modified_at"),
						dataIndex: "modifiedAt",
						sorting: isHistory,
						render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "")
					},
					{
						title: i18n.t("pages.dashboard.permissionGroups.last_modified_by"),
						dataIndex: "modifiedBy",
						sorting: isHistory
					}
				]
			: []),
		{
			title: i18n.t("pages.dashboard.permissionGroups.created_at"),
			dataIndex: "createdAt",
			sorting: isHistory,
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "")
		}
	];
};

export const expandColumns = [
	{
		title: "#",
		dataIndex: "index",
		width: 15
	},
	{
		title: i18n.t("pages.dashboard.userLogs.change"),
		dataIndex: "change"
	}
];
