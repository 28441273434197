import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import WidgetSkeleton from "components/dashboard/statistics/widgets/skeleton";

/** Dashboard Page Retail Betshops Count Widget Component */
const BetshopsCountWidgetComponent = ({ betshopsCount, isBetshopNamesLoading }) => {
	const { t } = useTranslation();
	return (
		<div className="dashboard-widget">
			<div className="dashboard-widget-icon" data-type="betshopsCount">
				<i className="icon-dashboard-betshops-count" />
			</div>
			<div className="dashboard-widget-info">
				<span className="dashboard-widget-info-title">{t("pages.dashboard.dashboard.betshopsCount")}</span>
				{isBetshopNamesLoading ? <WidgetSkeleton /> : <span className="dashboard-widget-info-value">{betshopsCount}</span>}
			</div>
		</div>
	);
};

/** BetshopsCountWidgetComponent propTypes
 * PropTypes
 */
BetshopsCountWidgetComponent.propTypes = {
	/** Redux state property, retail betshops count */
	betshopsCount: PropTypes.number,
	// Redux state controls betshop count loading indicator
	isBetshopNamesLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		betshopsCount: state.dashboard.retail.betshopNames.length,
		isBetshopNamesLoading: state.dashboard.retail.isBetshopNamesLoading
	};
};

const mapDispatchToProps = null; // dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(BetshopsCountWidgetComponent);
