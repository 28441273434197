import { Fragment } from 'react';

import JackpotComponent from 'components/dashboard/bonuses/jackpot/jackpots.component';
import withPermission from 'hocs/withPermission';

import Paths from 'constants/path.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

const BonusesJackpotRoute = () => {
	return (
		<Fragment>
			<JackpotComponent />
		</Fragment>
	)
}

export default withPermission(
	BonusesJackpotRoute,
	{ resource: PERMISSION_RESOURCE.BONUS_JACKPOT, action: PERMISSION_ACTION.VIEW },
	Paths.DASHBOARD
)