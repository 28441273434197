import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Button, Input, Spin } from "antd";
const { Item: FormItem } = Form;

import { saveBoxGeneralInfo } from "store/actions/dashboard/retail/boxes/generalInfo.action";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import boxGeneralInfoType from "types/box/generalInfo.type";

/** Box Edit Page General Info Tab Component */
const GeneralInfoComponent = ({ saveBoxGeneralInfo, isSaving, isLoading, generalInfo, onTabChange }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);

	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.BOX, action: PERMISSION_ACTION.MODIFY });

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			name: generalInfo.name,
			connectionInstruction: generalInfo.connectionInstruction
		});
	}, [generalInfo]);

	/** Fires when form submitted
	 * @function
	 * @memberOf GeneralInfoComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				saveBoxGeneralInfo({
					...data,
					id: routeParams.id
				});
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					name: generalInfo.name,
					connectionInstruction: generalInfo.connectionInstruction
				}}
				onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, { ...generalInfo }))}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={24} md={8} xl={6}>
								<FormItem label={`${t("pages.dashboard.boxes.name")} *`} name="name" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
									<Input maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.boxes.name")}`} disabled={isDisabled} />
								</FormItem>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={24} md={8} xl={6}>
								<FormItem label={`${t("pages.dashboard.boxes.connection_instruction")}`} name="connectionInstruction" rules={[{ max: 10000, message: t("validation.field_invalid") }]}>
									<Input.TextArea maxLength={10000} placeholder={`${t("common.enter")} ${t("pages.dashboard.boxes.connection_instruction")}`} disabled={isDisabled} />
								</FormItem>
							</Col>
						</Row>
					</div>
					{!isDisabled && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** GeneralInfoComponent propTypes
 * PropTypes
 */
GeneralInfoComponent.propTypes = {
	/** Redux action to save box General info */
	saveBoxGeneralInfo: PropTypes.func,
	/** Redux state property, is true when general info is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when general info is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the general info of current editing box  */
	generalInfo: boxGeneralInfoType,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	saveBoxGeneralInfo: (data) => {
		dispatch(saveBoxGeneralInfo(data));
	}
});

const mapStateToProps = (state) => {
	return {
		generalInfo: state.boxes.editingBox.generalInfo,
		isSaving: state.boxes.isSaving,
		isLoading: state.boxes.isLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent);
