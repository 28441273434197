import { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getPartnerActivity, setPartnerActivitySorting } from "store/actions/dashboard/partners/activity.action";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import Table from "components/common/table";
import ExportButton from "components/common/exportButton";
import Filters from "./filters.component";
import { numberWithSpaces } from "utils/common";
import { convertCurrency, getPartnerCurrency } from "utils/currency";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import ApiUrls from "constants/api.constants";
import activityType from "types/partner/activity.type";
import sortingType from "types/common/sorting.type";
import currencyType from "types/common/currency.type";

/** Partner View Page Activity Tab Component */

const ActivityComponent = ({ isLoading, activities, total, getPartnerActivity, sorting, filters, setPartnerActivitySorting, currency, currencies }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.wallet.transaction_id"),
			dataIndex: "id"
		},
		{
			title: t("pages.dashboard.wallet.date"),
			dataIndex: "changeTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.wallet.type"),
			dataIndex: "type",
			render: (value) => t("pages.dashboard.wallet.activityType_" + value)
		},
		{
			title: t("pages.dashboard.wallet.currency"),
			dataIndex: "currency",
			render: (value, record) => getPartnerCurrency(currency.code, currencies, routeParams.id, record.changeTime),
			sorter: false
		},
		{
			title: t("pages.dashboard.wallet.amount"),
			dataIndex: "changeAmount",
			render: (value, record) => numberWithSpaces(convertCurrency(value, currency.code, currencies, routeParams.id, record.changeTime))
		},
		{
			title: t("pages.dashboard.wallet.balance_before"),
			dataIndex: "balanceBefore",
			render: (value, record) => numberWithSpaces(convertCurrency(value, currency.code, currencies, routeParams.id, record.changeTime))
		},
		{
			title: t("pages.dashboard.wallet.balance_after"),
			dataIndex: "total",
			render: (value, record) => numberWithSpaces(convertCurrency(value, currency.code, currencies, routeParams.id, record.changeTime))
		},
		{
			title: t("pages.dashboard.wallet.username"),
			dataIndex: "userName"
		},
		{
			title: t("pages.dashboard.wallet.details"),
			dataIndex: "reason",
			render: (value) => (
				<span title={value} className="table-col-shorten-text">
					{value}
				</span>
			),
			sorting: false
		}
	];

	return (
		<Fragment>
			<div className="dashboard-section-content">
				<div className="table-header">
					<div className="table-buttons-dropdowns">
						{hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_TRANSACTIONS_HISTORY, action: PERMISSION_ACTION.EXPORT }) ? (
							<ExportButton columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t("pages.dashboard.partners.wallet_activity")} url={`${ApiUrls.EXPORT_PARTNERS_WALLET_HISTORY}?id=${routeParams.id}`} filters={filters} sorting={sorting} />
						) : null}
					</div>
					<Filters />
				</div>

				<Table loading={isLoading} columns={columns} data={activities} loadFn={() => getPartnerActivity(routeParams.id)} sorting={sorting} setSortingFn={setPartnerActivitySorting} total={total} updateProps={[currency]} actions={{}} isDisabled={() => false} />
			</div>
		</Fragment>
	);
};

/** ActivityComponent propTypes
 * PropTypes
 */
ActivityComponent.propTypes = {
	/** Redux action to get partner activity */
	getPartnerActivity: PropTypes.func,
	/** Redux state property, represents the array of loaded activities of partner */
	activities: PropTypes.arrayOf(activityType),
	/** Redux state property, partner activity total count */
	total: PropTypes.number,
	/** Redux state property, is true when loading partner activity */
	isLoading: PropTypes.bool,
	/** Redux action to set partner activity sorting details */
	setPartnerActivitySorting: PropTypes.func,
	/** Redux state property, partner activity sorting details */
	sorting: sortingType,
	/** Redux state property, partner activity filters */
	filters: PropTypes.object,
	/** Redux state property, current user currency */
	currency: currencyType,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType)
};

const mapDispatchToProps = (dispatch) => ({
	getPartnerActivity: () => {
		dispatch(getPartnerActivity());
	},
	setPartnerActivitySorting: (sorting) => {
		dispatch(setPartnerActivitySorting(sorting));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.partners.viewingPartner.activity.isLoading,
		activities: state.partners.viewingPartner.activity.activities,
		total: state.partners.viewingPartner.activity.total,
		sorting: state.partners.viewingPartner.activity.sorting,
		filters: state.partners.viewingPartner.activity.filters,
		currency: state.profile.userInfo.currency,
		currencies: state.profile.userInfo.currencies
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityComponent);
