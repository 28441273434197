import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Col, Row, Modal, Input } from "antd";
import SearchableSelect from "components/common/searchableSelect";
import NumericInput from "components/common/numericInput";
import { addSystemCurrency, updateSystemCurrency, getAvailableCurrencies, getSystemAvailableCurrencies } from "store/actions/dashboard/settings/systemCurrencies/systemCurrencies.action";
import { numberTransform } from "utils/common";
import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { POPUP_SIZE } from "constants/common.constants";
import systemCurrencyType from "types/systemCurrencies/systemCurrency.type";
import currencyType from "types/currency/currency.type";
const { Item: FormItem } = Form;

/** System Currency Adding/Editing Popup Component */
const SystemCurrencyAddComponent = ({
	isSaving,
	addSystemCurrency,
	updateSystemCurrency,
	getAvailableCurrencies,
	getSystemAvailableCurrencies,
	onClose,
	editingCurrency,
	mode,
	systemCurrencies,
	availableCurrencies,
	systemAvailableCurrencies,
	isAvailableLoading,
	onCreateCallback = Function.prototype,
	onUpdateCallback = Function.prototype
}) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;
	const [selectedCur, setSelectedCur] = useState(editingCurrency ? editingCurrency.toUpperCase() : "");
	const [isFormTouched, setIsFormTouched] = useState(false);

	/** Load available currencies */
	useEffect(() => {
		if (Object.keys(availableCurrencies).length === 0) {
			getAvailableCurrencies();
		}
		if (mode === "add" && hasPermission({ resource: PERMISSION_RESOURCE.SETTINGS_CURRENCIES, action: PERMISSION_ACTION.CREATE })) {
			getSystemAvailableCurrencies();
		}
	}, [mode]);

	/** Get initial values for form
	 * @function
	 * @returns {object} - initial values
	 * @memberOf SystemCurrencyAddComponent
	 */
	const getDefaultValues = () => {
		const cur = systemCurrencies.find((c) => c.code === editingCurrency);
		const initial = {
			code: cur ? cur.code : undefined,
			symbol: cur ? cur.symbol : "",
			rate: cur ? cur.rate : ""
		};
		return initial;
	};

	/** Fires when form submitted
	 * @function
	 * @memberOf SystemCurrencyAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				const d = {
					code: data.code.toUpperCase(),
					symbol: data.symbol,
					rate: data.rate
				};
				switch (mode) {
					case "add":
						addSystemCurrency(d, onCreateCallback);
						break;
					case "edit":
						updateSystemCurrency(d, onUpdateCallback);
						break;
					default:
						break;
				}
				setIsFormTouched(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Modal
			open={true}
			title={mode === "add" ? t("pages.dashboard.currency.create_currency") : t("pages.dashboard.currency.edit_new_currency")}
			cancelText={t("common.cancel")}
			okText={t("common.save")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.SMALL}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving, disabled: !isFormTouched }}
			centered
		>
			<Form className="dashboard-form" form={formInstance} colon={false} layout="vertical" requiredMark={false} initialValues={getDefaultValues()} onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, { ...getDefaultValues() }))}>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={`${t("pages.dashboard.currency.select_currency")} *`} name="code" rules={[{ required: true, message: t("validation.field_required") }]}>
							<SearchableSelect
								loading={isAvailableLoading}
								disabled={mode === "edit"}
								placeholder={t("pages.dashboard.currency.select_currency_placeholder")}
								items={Object.keys(availableCurrencies)}
								valueProp={(item) => item.toUpperCase()}
								textProp={(item) => availableCurrencies[item]}
								renderText={(item) => item.toUpperCase() + " - " + availableCurrencies[item]}
								isOptionDisabled={(item) => systemAvailableCurrencies.map((c) => c.code.toLowerCase()).includes(item.toLowerCase())}
								onChange={(e) => setSelectedCur(e.toUpperCase())}
								getPopupContainer={() => document.body}
							/>
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem label={t("pages.dashboard.currency.code")}>
							<Input value={selectedCur} disabled={true} />
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem
							label={`${t("pages.dashboard.currency.symbol")} *`}
							name="symbol"
							rules={[
								{ required: true, whitespace: true, message: t("validation.field_required") },
								{ max: 4, message: t("validation.field_invalid") }
							]}
						>
							<Input maxLength={4} placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.symbol")}`} />
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem
							label={`${t("pages.dashboard.currency.rate")} *`}
							name="rate"
							rules={[
								{ required: true, message: t("validation.field_required") },
								{ type: "number", max: 100000, message: t("validation.must_be_less").replace("%X%", 100000), transform: numberTransform },
								{ type: "number", min: 0.000001, message: t("validation.must_be_more").replace("%X%", 0.000001), transform: numberTransform }
							]}
						>
							<NumericInput placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.rate")}`} disabled={selectedCur === "USD"} />
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** SystemCurrencyAddComponent propTypes
 * PropTypes
 */
SystemCurrencyAddComponent.propTypes = {
	/** Redux state property, is true when currency is adding */
	isSaving: PropTypes.bool,
	/** Redux action to add system currency */
	addSystemCurrency: PropTypes.func,
	/** Redux action to update system currency */
	updateSystemCurrency: PropTypes.func,
	/** Redux action to get available currencies in system */
	getAvailableCurrencies: PropTypes.func,
	/** Redux action to get system available currencies in system */
	getSystemAvailableCurrencies: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Current editing currecy */
	editingCurrency: PropTypes.string,
	/** Is Popup opened to edit currecny, or add */
	mode: PropTypes.oneOf(["add", "edit"]),
	/** Redux state property, represents the array of system currencies  */
	systemCurrencies: PropTypes.arrayOf(systemCurrencyType),
	/** Redux state property, represents available currencies  */
	availableCurrencies: PropTypes.object,
	/** Redux state property, represents system available currencies  */
	systemAvailableCurrencies: PropTypes.arrayOf(currencyType),
	/** Redux state property, is true when available currencies are loading */
	isAvailableLoading: PropTypes.bool,
	/** React property function, for calling after create new currency */
	onCreateCallback: PropTypes.func,
	/** React property function, for calling after update new currency */
	onUpdateCallback: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	addSystemCurrency: (currency, callback) => {
		dispatch(addSystemCurrency(currency, callback));
	},
	updateSystemCurrency: (currency, callback) => {
		dispatch(updateSystemCurrency(currency, callback));
	},
	getAvailableCurrencies: () => {
		dispatch(getAvailableCurrencies());
	},
	getSystemAvailableCurrencies: () => {
		dispatch(getSystemAvailableCurrencies());
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.systemCurrencies.isSaving,
		isAvailableLoading: state.systemCurrencies.isAvailableLoading,
		systemCurrencies: state.systemCurrencies.systemCurrencies,
		availableCurrencies: state.systemCurrencies.availableCurrencies,
		systemAvailableCurrencies: state.systemCurrencies.systemAvailableCurrencies
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemCurrencyAddComponent);
