import { USER_LOGS_BEFORE, USER_LOGS_FINISH, SET_USER_LOGS, SET_USER_LOGS_SORTING, SET_USER_LOGS_FILTERS, SET_USER_LOG_DETAILS, SET_USER_LOG_RESOURCES } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case USER_LOGS_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case USER_LOGS_FINISH:
			return {
				...state,
				isLoading: false
			};
		case SET_USER_LOGS:
			return {
				...state,
				userLogs: payload.userLogs.item2,
				total: payload.userLogs.item1
			};
		case SET_USER_LOGS_SORTING:
			return {
				...state,
				sorting: payload.sorting
			};
		case SET_USER_LOGS_FILTERS:
			return {
				...state,
				filters: payload.filters
			};
		case SET_USER_LOG_DETAILS:
			return {
				...state,
				userLogs: state.userLogs.map((l) => (l.id === payload.id ? { ...l, details: payload.details } : l))
			};
		case SET_USER_LOG_RESOURCES:
			return {
				...state,
				resources: payload.resources
			};
		default:
			return state;
	}
};
