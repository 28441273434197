import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Tabs } from "antd";
import BetslipsComponent from "./betslips";
import { binaryToFlags, flagsToBinary } from "utils/common";
import { updateLocationHash, getHashValue, clearLocationHash } from "utils/common";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { PARTNER_TYPE } from "constants/partner.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import { GAME_CATEGORY } from "constants/game.constants";
import partnerType from "types/partner/partner.type";

/** Betslips Page Component */
const RetailBetslipsComponent = ({
	gameCategory = flagsToBinary(Object.values(GAME_CATEGORY)),
	showBetsBySeparate = false
}) => {
	const { t } = useTranslation();

	return (
		<Fragment>
			<div className="dashboard-section">
				<BetslipsComponent activeTab={PROJECT_TYPE.ONLINE.toString()} gameCategory={GAME_CATEGORY.SCHEDULED} showBetsBySeparate={showBetsBySeparate} />
			</div>
		</Fragment>
	);
};

/** RetailBetslipsComponent propTypes
 * PropTypes
 */
RetailBetslipsComponent.propTypes = {
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	showBetsBySeparate: PropTypes.bool
};

export default RetailBetslipsComponent;
