import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { setBetshopsActionBefore, setBetshopsActionFinished, setBetshopSaveActionBefore, setBetshopSaveActionFinished } from "./betshops.action";

import { SET_BETSHOP_UI, RESET_BETSHOP_UI, SET_BETSHOP_LOGO_ID } from "store/actionTypes";

const setBetshopUI = (data) => ({
	type: SET_BETSHOP_UI,
	payload: { data }
});

export const getBetshopUI = (id, languageCode) => {
	return (dispatch) => {
		dispatch(setBetshopsActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BETSHOP_GET_UI}`,
			method: Methods.GET,
			params: { id, languageCode }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setBetshopUI(data));
				dispatch(setBetshopsActionFinished());
			})
			.catch((ex) => {
				dispatch(setBetshopsActionFinished());
			});
	};
};

export const saveBetshopUI = (id, templateType, languageCode) => {
	return (dispatch) => {
		dispatch(setBetshopSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BETSHOP_SAVE_UI}`,
			method: Methods.POST,
			data: {
				id,
				templateType,
				languageCode
			}
		})
			.then(({ data: { message: msg, status, value: data } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
				}
				dispatch(setBetshopSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setBetshopSaveActionFinished());
			});
	};
};

export const resetBetshopUI = () => ({
	type: RESET_BETSHOP_UI
});

export const deleteLogo = (betShopId) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BETSHOP_DELETE_LOGO}`,
			method: Methods.DELETE,
			data: { betShopId }
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setLogoId(null));
				}
			})
			.catch((ex) => {
				console.log(ex);
			});
	};
};

export const setLogoId = (logoId) => ({
	type: SET_BETSHOP_LOGO_ID,
	payload: { logoId }
});
