import { useMemo } from "react";
import PropTypes from "prop-types";

const LinearGauge = ({ percent, itemsCount = "auto" }) => {
	const [gaugeItems, eachItemsPercent] = useMemo(() => {
		const targetItemsCount = isNaN(itemsCount) ? 89 : itemsCount % 2 > 0 ? itemsCount : itemsCount - 1;
		const items = Object.keys([...Array(targetItemsCount)]);

		const eachItemsPercent = 100 / targetItemsCount;

		return [items, eachItemsPercent];
	}, [itemsCount, percent]);

	return (
		<div className="vs-ui-linear-gauge-container">
			{gaugeItems.map((_, index) => {
				return <div key={_} className={`vs-ui-linear-gauge-item ${!!(index % 2) && index !== gaugeItems.length - 1 ? "white-gauge-item" : eachItemsPercent * index <= (percent <= 55 ? percent : percent - 1) && percent > 0 ? "in-progress-gauge-item" : "out-of-progress-gauge-item"}`}></div>;
			})}
		</div>
	);
};

LinearGauge.propTypes = {
	// Percent prop of widget
	percent: PropTypes.number,
	// Items count prop of widget
	itemsCount: PropTypes.oneOf(["auto", PropTypes.number])
};

export default LinearGauge;
