import { message } from "antd";
import axios from "axios";
import i18n from "translations/config";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import {
	BONUSES_BEFORE,
	BONUSES_FINISH,
	JACKPOT_BONUSES_BEFORE,
	JACKPOT_BONUSES_FINISH,
	BONUS_BEFORE,
	BONUS_FINISH,
	JACKPOT_BONUS_BEFORE,
	JACKPOT_BONUS_FINISH,
	SET_STANDARD_BONUSES,
	SET_BONUSES_FILTERS,
	SET_BONUSES_SORTING,
	CHANGE_STANDARD_BONUS_STATE,
	SET_BONUS_AVAILABLE_BETSHOPS,
	SET_BONUS_AVAILABLE_GAMES,
	SET_BONUS_QUICK_INFO,
	SET_BONUS_PRINT_VOUCHERS,
	SET_BONUS_PRINT_VOUCHERS_TRANSLATIONS,
	SET_JACKPOT_BONUSES,
	SET_BONUS_JACKPOT_AVAILABLE_GAMES,
	SET_BONUS_JACKPOT_AVAILABLE_BETSHOPS,
	SET_JACKPOT_BONUSES_FILTERS,
	SET_JACKPOT_BONUSES_SORTING,
	CHANGE_JACKPOT_BONUS_STATE
} from '../../../actionTypes';

export const setBonusesActionBefore = () => ({
	type: BONUSES_BEFORE
});

export const setBonusesActionFinished = () => ({
	type: BONUSES_FINISH
});

export const setBonusSaveActionBefore = () => ({
	type: BONUS_BEFORE
});

export const setBonusSaveActionFinished = () => ({
	type: BONUS_FINISH
});

export const setJackpotSaveActionBefore = () => ({
	type: JACKPOT_BONUS_BEFORE
})

export const setJackpotSaveActionFinished = () => ({
	type: JACKPOT_BONUS_FINISH
})

export const setStandardBonuses = bonuses => ({
	type: SET_STANDARD_BONUSES,
	payload: { bonuses }
});

export const setJackpotBonusesActionBefore = () => ({
	type: JACKPOT_BONUSES_BEFORE
})

export const setJackpotBonusesActionFinished = () => ({
	type: JACKPOT_BONUSES_FINISH
})

export const setJackpotBonuses = data => ({
	type: SET_JACKPOT_BONUSES,
	payload: { data }
})

export const setJackpotBonusesFilters = filters => ({
	type: SET_JACKPOT_BONUSES_FILTERS,
	payload: { filters }
})

export const setJackpotBonusesSorting = sorting => ({
	type: SET_JACKPOT_BONUSES_SORTING,
	payload: { sorting }
})

export const setBonusesFilters = filters => ({
	type: SET_BONUSES_FILTERS,
	payload: { filters }
});

export const setBonusesSorting = (sorting) => ({
	type: SET_BONUSES_SORTING,
	payload: { sorting }
});

const setChangeBonusState = data => ({
	type: CHANGE_STANDARD_BONUS_STATE,
	payload: { data }
})

const setChangeJackpotState = data => ({
	type: CHANGE_JACKPOT_BONUS_STATE,
	payload: { data }
});

export const getBonuses = (fromFirstPage) => {
	return (dispatch, getState) => {
		dispatch(setBonusesActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BONUSES_GETALL}`,
			method: Methods.GET,
			params: {
				...getState().bonuses.standard.sorting,
				...getState().bonuses.standard.filters,
				page: fromFirstPage ? 1 : getState().bonuses.standard.sorting.page
			}
		})
			.then(({ data: { value: bonuses } }) => {
				dispatch(setStandardBonuses(bonuses));
				if (fromFirstPage) dispatch(setBonusesSorting({ ...getState().bonuses.standard.sorting, page: 1 }))
				dispatch(setBonusesActionFinished());
			})
			.catch((ex) => {
				dispatch(setBonusesActionFinished());
			});
	};
};

export const getJackpots = (fromFirstPage) => {
	return (dispatch, getState) => {
		dispatch(setJackpotBonusesActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.JACKPOTS_GETALL}`,
			method: Methods.GET,
			params: {
				...getState().bonuses.jackpots.sorting,
				...getState().bonuses.jackpots.filters,
				page: fromFirstPage ? 1 : getState().bonuses.jackpots.sorting.page
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setJackpotBonuses(data));
				if (fromFirstPage) {
					dispatch(setJackpotBonusesSorting({ ...getState().bonuses.jackpots.sorting, page: 1 }))
				}
				dispatch(setJackpotBonusesActionFinished());
			})
			.catch((ex) => {
				dispatch(setJackpotBonusesActionFinished());
			})
	}
}

export const changeJackpotState = data => {
	return dispatch => {
		dispatch(setJackpotSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.JACKPOT_CHANGE_STATE}`,
			method: Methods.POST,
			data
		})
			.then(({ data: { status, value, ...other } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(other.message);
					dispatch(setChangeJackpotState({ id: data.id, state: value }));
				}
				dispatch(setJackpotSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setJackpotSaveActionFinished());
			})
	}
}

export const setAvailableBetshops = data => ({
	type: SET_BONUS_AVAILABLE_BETSHOPS,
	payload: { data }
});

export const getAvailableBetshops = (currencyCode) => {
	return (dispatch, getState) => {
		dispatch(setBonusSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BONUS_AVAILABLE_BETSHOPS}`,
			method: Methods.GET,
			params: { currencyCode }
		})
			.then(({ data: { value } }) => {
				dispatch(setAvailableBetshops(value));
			})
			.finally((ex) => {
				dispatch(setBonusSaveActionFinished());
			});
	};
};

export const setAvailableGames = (data) => ({
	type: SET_BONUS_AVAILABLE_GAMES,
	payload: { data }
});

export const getAvailableGames = (betshopIds) => {
	return (dispatch, getState) => {
		if (!Array.isArray(betshopIds) || !betshopIds.length) {
			setAvailableGames([]);
		}
		const joinedArrayParams = betshopIds.map((i) => `betshopIds=${i}`).join("&");
		const additionalQueryParams = joinedArrayParams.length ? "?" + joinedArrayParams : "";
		dispatch(setBonusSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BONUS_AVAILABLE_GAMES}${additionalQueryParams}`,
			method: Methods.GET,
			params: {}
		})
			.then(({ data: { value } }) => {
				dispatch(setAvailableGames(value));
			})
			.finally((ex) => {
				dispatch(setBonusSaveActionFinished());
			});
	};
};

export const createBonus = (bonus, onSuccess) => {
	return (dispatch) => {
		dispatch(setBonusSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BONUS_CREATE}`,
			method: Methods.POST,
			data: bonus
		})
			.then(({ data: { value: bonus } }) => {
				dispatch(setBonusSaveActionFinished());
				onSuccess && onSuccess(bonus);
			})
			.catch((ex) => {
				dispatch(setBonusSaveActionFinished());
			});
	};
};

export const createJackpot = (jackpot, onSuccess) => {
	return dispatch => {
		dispatch(setJackpotSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.JACKPOT_CREATE}`,
			method: Methods.POST,
			data: jackpot
		})
			.then(({ data: { value } }) => {
				dispatch(setJackpotSaveActionFinished());
				onSuccess && onSuccess(value);
			})
			.catch((ex) => {
				dispatch(setJackpotSaveActionFinished());
			})
	}
}

export const changeBonusState = data => {
	return dispatch => {
		dispatch(setBonusSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BONUS_CHANGE_STATE}`,
			method: Methods.POST,
			data: { id: data.id }
		})
			.then(({ data: { status, value, ...other } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(other.message);
					dispatch(setChangeBonusState({ id: data.id, bonusState: value }));
				}
				dispatch(setBonusSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setBonusSaveActionFinished());
			});
	};
};

export const setBonuseQuickInfo = (data) => ({
	type: SET_BONUS_QUICK_INFO,
	payload: { data }
});

export const getBonuseQuickInfo = (id) => {
	return (dispatch) => {
		dispatch(setBonusSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BONUS_GET_QUICK_VIEW}`,
			method: Methods.GET,
			params: {
				id
			}
		})
			.then(({ data: { value } }) => {
				dispatch(setBonuseQuickInfo(value));
			})
			.finally((ex) => {
				dispatch(setBonusSaveActionFinished());
			});
	};
};

export const setBonusPrintVouchers = (data) => ({
	type: SET_BONUS_PRINT_VOUCHERS,
	payload: { data }
});

const setBonusPrintVouchersTranslations = (translations) => ({
	type: SET_BONUS_PRINT_VOUCHERS_TRANSLATIONS,
	payload: { translations }
});

const getTranslationsFromBonusesVaucher = (data) => ({
	freebetId: data.cashier.voucherFreebetId || i18n.t("pages.dashboard.bonuses.vaucher.freebetId"),
	betOn: data.cashier.voucherBetOn || i18n.t("pages.dashboard.bonuses.vaucher.betOn"),
	betType: data.cashier.voucherBetType || i18n.t("pages.dashboard.bonuses.vaucher.betType"),
	validTill: data.cashier.voucherValidTill || i18n.t("pages.dashboard.bonuses.vaucher.validTill"),
	minOddsSingle: data.cashier.voucherMinOddsSingle || i18n.t("pages.dashboard.bonuses.vaucher.minOddsSingle"),
	minOddsMulti: data.cashier.voucherMinOddsMulti || i18n.t("pages.dashboard.bonuses.vaucher.minOddsMulti"),
	maxPossibleWin: data.cashier.voucherMaxPossibleWin || i18n.t("pages.dashboard.bonuses.vaucher.maxPossibleWin"),
	betshops: data.cashier.voucherBetshops || i18n.t("pages.dashboard.bonuses.vaucher.betshops"),
	freebetText: data.cashier.voucherFreebetText || i18n.t("pages.dashboard.bonuses.vaucher.freebetText"),
	usageRules: data.cashier.voucherUsageRules || i18n.t("pages.dashboard.bonuses.vaucher.usageRules"),
	voucherFooterText: data.cashier.voucherFooterText || i18n.t("pages.dashboard.bonuses.vaucher.voucherFooterText")
});

const getTranslationsFromBets = (data) => ({
	multi: data.bet.multi || i18n.t("pages.dashboard.bets.multi"),
	single: data.bet.single || i18n.t("pages.dashboard.bets.single")
});

const getTranslationsFromCommon = (data) => ({
	football_single_match: data.common.virtualFootball || i18n.t("common.football_single_match"),
	english_league: data.common.englishLeague || i18n.t("common.english_league"),
	horse_racing_flat: data.common.horseRacing || i18n.t("common.horse_racing_flat"),
	greyhounds: data.common.greyhounds || i18n.t("common.greyhounds"),
	african_cup: data.common.africanCup || i18n.t("common.african_cup"),
	world_cup: data.common.footballWorldCup || i18n.t("common.world_cup"),
	european_cup: data.common.footballEuropeanCup || i18n.t("common.european_cup"),
	champions_league: data.common.footballChampionsLeague || i18n.t("common.champions_league"),
	copa_libertadores: data.common.footballCopaLibertadores || i18n.t("common.copa_libertadores"),
	keno: data.common.keno || i18n.t("common.keno")
});

const getBonusPrintVouchersTranslations = (path, vouchers) => {
	return (dispatch) => {
		return fetch(`${import.meta.env.SYSTEM_CDN_URL}/${path?.toLowerCase()}`)
			.then((response) => response.json())
			.then((data) => {
				const translations = {
					...getTranslationsFromBonusesVaucher(data),
					...getTranslationsFromBets(data),
					...getTranslationsFromCommon(data)
				};
				dispatch(setBonusPrintVouchersTranslations(translations));
				dispatch(setBonusesActionFinished());
				setTimeout(() => {
					dispatch(setBonusPrintVouchers(vouchers));
				}, 0);
			})
			.catch((ex) => {
				dispatch(setBonusPrintVouchersTranslations({}));
				dispatch(setBonusPrintVouchers(vouchers));
				dispatch(setBonusesActionFinished());
			});
	};
};

export const getBonusPrintVouchers = (id, languageCode) => {
	return (dispatch) => {
		dispatch(setBonusesActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BONUS_GET_PRINT_VOUCHERS}`,
			method: Methods.POST,
			data: { id, languageCode }
		})
			.then(
				({
					data: {
						value: { item1, item2 }
					}
				}) => {
					dispatch(getBonusPrintVouchersTranslations(item1, item2));
				}
			)
			.catch((ex) => {
				dispatch(setBonusesActionFinished());
			})
	}
}

export const setAvailableJackpotBetshops = data => ({
	type: SET_BONUS_JACKPOT_AVAILABLE_BETSHOPS,
	payload: { data }
})

export const getAvailableJackpotBetshops = (currencyCode, jackpotId) => {
	return (dispatch, getState) => {
		dispatch(setBonusSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.JACKPOT_AVAILABLE_BETSHOPS}`,
			method: Methods.GET,
			params: { currencyCode, jackpotId }
		})
			.then(({ data: { value } }) => {
				dispatch(setAvailableJackpotBetshops(value))
			})
			.finally((ex) => {
				dispatch(setBonusSaveActionFinished());
			})
	}
}

export const setAvailableJackpotGames = data => ({
	type: SET_BONUS_JACKPOT_AVAILABLE_GAMES,
	payload: { data }
})

export const getAvailableJackpotGames = betshopIds => {
	return (dispatch, getState) => {
		if (!Array.isArray(betshopIds) || !betshopIds.length) { setAvailableGames([]) }
		const joinedArrayParams = betshopIds.map(i => `betshopIds=${i}`).join("&")
		const additionalQueryParams = joinedArrayParams.length ? "?" + joinedArrayParams : ""
		dispatch(setBonusSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.JACKPOT_AVAILABLE_GAMES}${additionalQueryParams}`,
			method: Methods.GET,
			params: {}
		})
			.then(({ data: { value } }) => {
				dispatch(setAvailableJackpotGames(value))
			})
			.finally((ex) => {
				dispatch(setBonusSaveActionFinished());
			})
	}
}
