import PropTypes from "prop-types";

import { connect } from "react-redux";

import ApiUrls from "constants/api.constants";
import { PROJECT_TYPE } from "constants/common.constants";

import SettledBetsTableComponent from "../../common/settled/tableByBetSlip.component";
import NewSettledBetsTableComponent from "../../common/settled/tableByBet.component";

import { getSettledBets, setSettledBetsSorting, setSettledBetsFilters } from "store/actions/dashboard/betslips/settled.action";

import betType from "types/bet/bet.type";
import sortingType from "types/common/sorting.type";

import { PERMISSION_RESOURCE } from "constants/permissions.constants";
import { GAME_CATEGORY } from "constants/game.constants";

/** Betslips Page Settled betslips Tab Retail SubTabe Page Component */
const RetailSettledBetsComponent = ({
	bets,
	sorting,
	filters,
	total,
	isLoading,
	getSettledBets,
	setSettledBetsSorting,
	setSettledBetsFilters,
	gameCategory,
	showBetsBySeparate = false
}) => {
	const Component = showBetsBySeparate ? NewSettledBetsTableComponent : SettledBetsTableComponent;
	const exportURL = showBetsBySeparate ? ApiUrls.EXPORT_RETAIL_BETS : ApiUrls.EXPORT_RETAIL_BETSLIPS;
	return (
		<Component
			bets={bets}
			sorting={sorting}
			filters={filters}
			total={total}
			isLoading={isLoading}
			projectType={PROJECT_TYPE.RETAIL}
			getSettledBets={getSettledBets}
			setSettledBetsSorting={setSettledBetsSorting}
			setSettledBetsFilters={setSettledBetsFilters}
			exportURL={exportURL + "?isPending=" + false}
			permission={PERMISSION_RESOURCE.BETSLIPS_RETAIL}
			gameCategory={gameCategory}
			showBetsBySeparate={showBetsBySeparate}
		/>
	);
};

/** RetailSettledBetsComponent propTypes
 * PropTypes
 */
RetailSettledBetsComponent.propTypes = {
	/** Redux state property, represents the array of retail settled betslips */
	bets: PropTypes.arrayOf(betType),
	/** Redux state property, retail settled betslips sorting details */
	sorting: sortingType,
	/** Redux state property, retail settled betslips filters */
	filters: PropTypes.object,
	/** Redux state property, retail settled betslips total count */
	total: PropTypes.number,
	/** Redux state property, is true when loading settled bets */
	isLoading: PropTypes.bool,
	/** Redux action to get settled bets */
	getSettledBets: PropTypes.func,
	/** Redux action to set settled bets sorting details */
	setSettledBetsSorting: PropTypes.func,
	/** Redux action to set settled bets filters */
	setSettledBetsFilters: PropTypes.func,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY)),
	showBetsBySeparate: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		bets: state.betslips.settled.retail.bets,
		total: state.betslips.settled.retail.total,
		sorting: state.betslips.settled.retail.sorting,
		filters: state.betslips.settled.retail.filters,
		isLoading: state.betslips.isLoading
	};
};

const mapDispatchToProps = (dispatch, { showBetsBySeparate = false }) => ({
	getSettledBets: (fromFirstPage, gameCategory) => {
		dispatch(getSettledBets(PROJECT_TYPE.RETAIL, fromFirstPage, gameCategory, showBetsBySeparate));
	},
	setSettledBetsSorting: (sorting) => {
		dispatch(setSettledBetsSorting(sorting, PROJECT_TYPE.RETAIL));
	},
	setSettledBetsFilters: (filters) => {
		dispatch(setSettledBetsFilters(filters, PROJECT_TYPE.RETAIL));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(RetailSettledBetsComponent);
