import { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Select } from "antd";
import { getRequests, setRequestsFilters } from "store/actions/dashboard/betslips/requests.action";
import FiltersWrapper from "components/common/filters";
import SearchableSelect from "components/common/searchableSelect";
import NumericInput from "components/common/numericInput";
import useAutosuggestion from "hooks/useAutosuggestion";
import { FILTER_PRINT_TYPE } from "constants/filter.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
const { Item: FormItem } = Form;

/** Requests Page Filters Component */
const Filters = ({ setRequestsFilters, getRequests, filters, activeReset, globalPartnerId }) => {
	const { t } = useTranslation();
	const betshopNames = useAutosuggestion(AUTOSUGGESTION_TYPE.BETSHOP_NAMES, [globalPartnerId], { disableAction: () => !globalPartnerId });

	const filtersList = [
		{
			name: "ticketType",
			title: t("pages.dashboard.bets.ticket_type"),
			values: [
				{ title: t("common.all"), value: FILTER_PRINT_TYPE.ALL },
				{ title: t("pages.dashboard.bets.ticket_type_1"), value: FILTER_PRINT_TYPE.BET },
				{ title: t("pages.dashboard.bets.ticket_type_2"), value: FILTER_PRINT_TYPE.CANCEL },
				{ title: t("pages.dashboard.bets.ticket_type_3"), value: FILTER_PRINT_TYPE.PAYOUT }
			]
		},
		{ name: "betSlipId", title: t("pages.dashboard.bets.ticket_id") },
		{
			name: "betShopId",
			title: t("pages.dashboard.bets.betshop"),
			values: betshopNames.map((b) => ({ title: b.value, value: b.key }))
		}
	];

	return (
		<FiltersWrapper filtersName="betHistory_betRequests" loadFn={getRequests} setFiltersFn={setRequestsFilters} filters={filters} activeReset={activeReset} filtersList={filtersList}>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.ticket_type")} name="ticketType">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_PRINT_TYPE.ALL}>{t("common.all")}</Select.Option>
								<Select.Option value={FILTER_PRINT_TYPE.BET}>{t("pages.dashboard.bets.ticket_type_1")}</Select.Option>
								<Select.Option value={FILTER_PRINT_TYPE.CANCEL}>{t("pages.dashboard.bets.ticket_type_2")}</Select.Option>
								<Select.Option value={FILTER_PRINT_TYPE.PAYOUT}>{t("pages.dashboard.bets.ticket_type_3")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.ticket_id")} name="betSlipId">
							<NumericInput placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.ticket_id")}`} isInteger={true} maxLength={12} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.betshop")} name="betShopId">
							<SearchableSelect items={betshopNames} valueProp={(item) => item.key} textProp={(item) => item.value} renderText={(item) => item.value} placeholder={`${t("common.select")} ${t("pages.dashboard.bets.betshop")}`} addNone={true} noneText={t("common.all")} />
						</FormItem>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get requests */
	getRequests: PropTypes.func,
	/** Redux action to requests filters */
	setRequestsFilters: PropTypes.func,
	/** Redux state property, retail pending betslips filters */
	filters: PropTypes.object,
	/** If true, reset button will be active by default */
	activeReset: PropTypes.bool,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		filters: state.betslips.requests.filters,
		globalPartnerId: state.partner.globalPartnerId
	};
};

const mapDispatchToProps = (dispatch) => ({
	getRequests: () => {
		dispatch(getRequests());
	},
	setRequestsFilters: (filters) => {
		dispatch(setRequestsFilters(filters));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
