import { message } from "antd";
import i18n from "i18next";
import { BETSLIP_MODES, BET_STATE } from "constants/bet.constants";
import { GAME_CATEGORY, INSTANT_GAME_TYPE, SCHEDULED_GAME_TYPE } from "constants/game.constants";
import { ORIGIN_FOR_IFRAME_POST_MESSAGE } from "constants/apiKey.constants";
import { FILE_SIZES_MEASUREMENT, MAX_DECIMALS_COUNT, ORDER_DIRECTION } from "constants/common.constants";
import { FILTER_GAME_SPECIFICATION_TYPE, FILTER_STATUS, FILTER_TEST_STATE, FILTER_EVENT_STATE } from "constants/filter.constants";
import moment from "moment";
import { yesterday } from "utils/dateTime";
import { DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

/**
	* Function to create full path for loading it from CDN
	* @param {string} path file location
	* @returns {string} full path of file from CDN
*/
export const buildPathToStaticFolderOfCDN = (path) => {
	return `${import.meta.env.SYSTEM_CDN_URL}/static/${path?.toLowerCase()}`;
}

/** Function using for merge not empty string arguments with spaces
	* @function
	* @param {string} path - location path
	* @returns {object} - query params
*/
export const mergeClassNames = (...classes) => {
	return (
		(new Array)
			.concat(classes)
			.filter(str => typeof str === "string" && str !== "")
			.join(" ")
	) || null
};

/** Function using for checking is value null or undefined
	* @function
	* @param {string} checkArgument - value for checking
	* @returns {boolean} - true if value is null or undefined, otherway false
*/
export const isNullish = (checkArgument) => {
	return (
		checkArgument === null || checkArgument === undefined
	)
};

/** Make first letter of string to lower case 
	 * @function
	 * @param {string} str - string to convert
	 * @returns {string}
 */
export const toLowerCaseFirstLetter = (str) => str.charAt(0).toLowerCase() + str.slice(1);

/** Make first letter of string to upper case
 * @function
 * @param {string} str - string to convert
 * @returns {string}
 */
export const toUpperCaseFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const sortBy = (array, iteratee) => {
	const copiedArray = [...array];

	copiedArray.sort((a, b) => {
		const item1 = iteratee(a);
		const item2 = iteratee(b);

		if (isNullish(item1) && isNullish(item2)) {
			return 0;
		}
		if (isNullish(item1) === undefined) {
			return 1;
		}
		if (isNullish(item2) === undefined) {
			return -1;
		}

		if (item1 < item2) {
			return -1;
		}
		if (item1 > item2) {
			return 1;
		}

		return 0;
	});

	return copiedArray;
}

/** Copy text to clipboard
 * @function
 * @param {string} text - text to copy
 * @returns {string}
 */

export const copyToClipboard = (text) => {
	const input = document.createElement("input");
	input.setAttribute("value", text);
	document.body.appendChild(input);
	input.select();
	const result = document.execCommand("copy");
	document.body.removeChild(input);
	message.success(i18n.t("common.copied"));
	return result;
};

/** function which print json pretty
 * @function
 * @param {string} str - string to pretify
 * @returns {string}
 */
export const prettyJson = (str) => {
	let result = "";
	try {
		const json = JSON.parse(str);
		result = JSON.stringify(json, null, 2);
	} catch (ex) {
		result = str;
	}
	return result;
};

/** function which make a binary integer, from array of enum values, so server can detect selected enum values
 * @function
 * @param {array} arr - array of enum values(1,2,4,8,16,...)
 * @returns {number}
 */
export const flagsToBinary = (arr) => arr.reduce((a, b) => a ^ b, 0);

/** function which filter enum values, to values which are true with given binary number
 * @function
 * @param {array} flags - array of all enum values(1,2,4,8,16,...)
 * @param {binaryNum} number - binary integer, which represents the array of selected flags
 * @returns {array}
 */
export const binaryToFlags = (flags, binaryNum) => flags.filter((f) => f & binaryNum);

/** function which prints DOM elemnt content
 * @function
 * @param {string} id - The DOM elemnt id to print
 */
export const printElement = (id) => {
	const element = document.getElementById(id);
	const styleTags = Array.prototype.slice.call(document.getElementsByTagName("style"));
	const linkTags = Array.prototype.slice.call(document.getElementsByTagName("link")).filter((l) => l.rel === "stylesheet");
	const elementsToCopy = [...styleTags, ...linkTags];
	let pri;
	const iframe = document.getElementById(id + "-iframe") ? document.getElementById(id + "-iframe") : document.createElement("iframe");
	iframe.setAttribute("id", id + "-iframe");
	iframe.setAttribute("style", "height: 0px; width: 0px; position: absolute;");
	iframe.setAttribute("height", "560px");
	iframe.setAttribute("width", "420px");
	document.body.appendChild(iframe);
	pri = iframe.contentWindow;
	pri.document.open();
	pri.document.write("<div class='vs--print-view'>" + element.innerHTML + "</div>");
	elementsToCopy.forEach((el) => {
		const node = el.cloneNode(true);
		node.setAttribute("media", "print");
		pri.document.body.appendChild(el.cloneNode(true));
	});
	pri.document.close();
	pri.focus();

	pri.addEventListener(
		"load",
		function () {
			pri.print();
		},
		false
	);
};

/** function which prints DOM elemnts content but called inside multiPrintElement
 * @function
 * @param {string} ids - The array of DOM elemnts id for print
 * @param {number} num - number of iteration of
 * @param {array} elementsToCopy - The array of DOM elemnts for copy to iframe
 * @param {function} callback - callback for calling after print iframe load end
 * @return {undefined}
 */
const runPrint = (id, num, elementsToCopy = [], callback) => {
	const element = document.getElementById(id);
	if (!element) {
		return;
	}
	const iframe = document.getElementById(id + "-iframe") || document.getElementById(id + "-iframe-" + num) || document.createElement("iframe");
	iframe.setAttribute("id", id + "-iframe-" + num);
	iframe.setAttribute("style", "height: 0px; width: 0px; position: absolute;");
	iframe.setAttribute("height", "560px");
	iframe.setAttribute("width", "420px");
	document.body.appendChild(iframe);
	const pri = iframe.contentWindow;
	pri.document.open();
	pri.document.write("<div class='vs--print-view'>" + element.innerHTML + "</div>");
	elementsToCopy.forEach((el) => {
		const node = el.cloneNode(true);
		node.setAttribute("media", "print");
		pri.document.body.appendChild(el.cloneNode(true));
	});
	const styleTag = document.createElement("style");
	styleTag.innerHTML = "html, body{min-width: 0!important; width: 100%!important; background-color: #fff!important; will-change: auto!important;transform: none!important; margin: auto}";
	pri.document.body.appendChild(styleTag);
	pri.document.close();
	pri.focus();
	pri.addEventListener(
		"load",
		function () {
			pri.print();
			typeof callback === "function" && callback(id, num);
		},
		false
	);
};

/** function which prints DOM elemnts content
 * @function
 * @param {string} ids - The array of DOM elemnts id for print
 * @param {function} callback - callback for calling after each print
 */

export const multiPrintElement = (ids, callback) => {
	if (!Array.isArray(ids) || !ids.length) {
		return;
	}
	const styleTags = Array.prototype.slice.call(document.getElementsByTagName("style"));
	const linkTags = Array.prototype.slice.call(document.getElementsByTagName("link")).filter((l) => l.rel === "stylesheet");
	const elementsToCopy = [...styleTags, ...linkTags];
	for (let i = 0; i < ids.length; i++) {
		runPrint(ids[i], i, elementsToCopy, callback);
	}
};

/** function which counts the character counts of floating number after "."
 * @function
 * @param {number} num
 * @returns {number}
 */
export const countDecimals = (num) => {
	if (Math.floor(num) === num || isNaN(num)) return 0;
	return num.toString().split(".")[1] ? num.toString().split(".")[1].length : 0;
};

/** function which transform string to Number if possible
 * @function
 * @description used for transform rule of NumericInput component
 * @param {string} v  - string to convert
 * @returns {number}
 */
export const numberTransform = (v) => (!isNaN(v) && v !== "" ? Number(v) : v);

/** function to move an array item to a different position
 * @function
 * @param {array} array
 * @param {number} from - Index of item to move
 * @param {number} to - Index of where to move the item.
 * @returns {array}
 */
export const arrayMove = (array, from, to) => {
	array = array.slice();
	const startIndex = to < 0 ? array.length + to : to;
	const item = array.splice(from, 1)[0];
	array.splice(startIndex, 0, item);
	return array;
};

/** Check if device is mobile
 * @function
 * @returns {boolean}
 */
export const isMobile = () => {
	const isMobileBrowser = {
		Android: () => navigator.userAgent.match(/Android/i) !== null,
		BlackBerry: () => navigator.userAgent.match(/BlackBerry/i) !== null,
		iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null,
		Opera: () => navigator.userAgent.match(/Opera Mini/i) !== null,
		Windows: () => navigator.userAgent.match(/IEMobile/i) !== null || navigator.userAgent.match(/WPDesktop/i) !== null,
		any: () => isMobileBrowser.Android() || isMobileBrowser.BlackBerry() || isMobileBrowser.iOS() || isMobileBrowser.Opera() || isMobileBrowser.Windows()
	};

	return isMobileBrowser.any();
};

/** Check if device is ios
 * @function
 * @returns {boolean}
 */
export const isIOS = () => navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null;

/** Beutify number by adding spaces
 * @function
 * @param {number} num
 * @returns {string}
 */
export const numberWithSpaces = (arg) => {
	if (arg === null || arg === undefined) return "";
	const strNum = typeof arg === "string" ? arg : arg.toString();
	const parts = strNum.split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	return parts.join(".");
};

/** Checks if two objects are equal
 * @function
 * @param {object} x
 * @param {object} y
 * @returns {boolean}
 */
export const isObjectEquals = (x, y) => {
	if (x === null || x === undefined || y === null || y === undefined) {
		return x === y;
	}
	// after this just checking type of one would be enough
	if (x.constructor !== y.constructor) {
		return false;
	}
	// if they are functions, they should exactly refer to same one (because of closures)
	if (x instanceof Function) {
		return x === y;
	}
	// if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
	if (x instanceof RegExp) {
		return x === y;
	}
	if (x === y || x.valueOf() === y.valueOf()) {
		return true;
	}
	if (Array.isArray(x) && x.length !== y.length) {
		return false;
	}

	// if they are dates, they must had equal valueOf
	if (x instanceof Date) {
		return false;
	}

	// if they are strictly equal, they both need to be object at least
	if (!(x instanceof Object)) {
		return false;
	}
	if (!(y instanceof Object)) {
		return false;
	}

	// recursive object equality check
	var p = Object.keys(x);
	return Object.keys(y).every((i) => p.indexOf(i) !== -1) && p.every((i) => isObjectEquals(x[i], y[i]));
};

/** Format bytes as human-readable text.
 * @function
 * @param {number} bytes - Number of bytes
 * @returns {string} - Formatted string.
 */
export const humanFileSize = (bytes) => {
	const thresh = FILE_SIZES_MEASUREMENT.KB;
	const dp = 2;

	if (Math.abs(bytes) < thresh) {
		return bytes + " B";
	}

	const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	let u = -1;
	const r = 10 ** dp;

	do {
		bytes /= thresh;
		++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

	return bytes.toFixed(dp) + " " + units[u];
};

/** Update location hash
 * @function
 * @param {string} hash - New hash
 * @param {boolean} add - If true , will add new param to hash, otherwise will replace
 */
export const updateLocationHash = (hash, add) => {
	const scrollmem = document.body.scrollTop;
	let h = window.location.hash.replace("#", "");
	const params = h.split("&").filter((p) => p !== "" && p.split("=")[0] !== hash.split("=")[0]);
	window.location.hash = add && params.length > 0 ? params.join("&") + "&" + hash : hash;
	document.body.scrollTop = scrollmem;
};

/** Get hash param value from hash
 * @function
 * @param {string} param - Param name
 * @returns {string}
 */
export const getHashValue = (param, optionalHash = window.location.hash) => {
	const urlParams = new URLSearchParams(optionalHash.replace("#", "?"));
	return urlParams.get(param) || "";
};

/** Clear location hash
 * @function
 */
export const clearLocationHash = () => {
	window.location.hash = "";
};

/** Send Data To Iframe
 * @param {string} data - ifrmae element id
 * @param {object} data - the data to send
 * @param {string} name - message name
 * @function
 */
export const sendDataToIframe = (iframeId, data, eventName) => {
	const iframeEl = document.getElementById(iframeId);
	if (!iframeEl) {
		return;
	}
	iframeEl.contentWindow.postMessage({ eventName, data }, ORIGIN_FOR_IFRAME_POST_MESSAGE);
};

/** Distinct array of objects by property
 * @param {array} arr - array to distinct
 * @param {string} propName - the property name to distinct by
 * @returns {array} - distincted array
 * @function
 */
export const ditinctArrayOfObjects = (arr, propName) => {
	const result = [];
	const map = new Map();
	for (const item of arr) {
		if (!map.has(item[propName])) {
			map.set(item[propName], true);
			result.push({
				...item
			});
		}
	}
	return result;
};

/** Distinct array of objects by property
 * @param {array} arr - array to distinct
 * @param {string} propName - the property name to distinct by
 * @returns {array} - distincted array
 * @function
 */
export const downloadURI = async (uri, name) => {
	try {
		const response = await fetch(uri, { method: "GET", cache: "no-cache" });
		const blob = await response.blob()
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.download = name;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
	} catch (error) {
		console.log(error);
		message.error(`${i18n.t("common.cantDownload")}: ${error.message}`);
		return;
	}
};

/** Checks if the betslip can be canceled
 * @function
 * @param {object} betslip
 * @returns {boolean}
 */
export const isBetslipCancelable = (betslip, fromBetslips = false) => {
	// const checkingFn = (b) => (b.allowBetCancel && b.betStatus === BET_STATE.PENDING)
	// if (betslip.type === BETSLIP_MODES.SINGLE) {
	// 	return checkingFn(betslip)
	// }

	// return (betslip?.bets ?? [])?.every(checkingFn) && betslip.betSlipStatus === BET_STATE.PENDING;

	if (betslip.status !== BET_STATE.PENDING) {
		return false;
	}

	if (!fromBetslips) {
		return (betslip?.bets ?? []).every((b) => b.allowCancel || (b.status === BET_STATE.CANCELLED && betslip.type === BETSLIP_MODES.SINGLE));
	}

	if (betslip.type === BETSLIP_MODES.SINGLE) {
		return betslip.allowBetCancel;
	}

	return (betslip?.bets ?? []).every((b) => b.allowBetCancel);
};

/** Checks if the betslip can be canceled
 * @function
 * @param {object} betslip
 * @param {string} currentDateStr
 * @returns {boolean}
 */
export const isEventIsNotFinishedYet = (betslip, currentDateStr, formattFn, fromBetslips = false) => {
	// if (!betslip) {
	// 	return true;
	// }
	// const currentDate = new Date(currentDateStr);

	// if (betslip.type === BETSLIP_MODES.SINGLE) {
	// 	const diff = new Date(formattFn(betslip.eventEndTime)) - currentDate;
	// 	return diff > 0;
	// }

	// if (!Array.isArray(betslip.bets)) {
	// 	return true;
	// }

	// return betslip.bets.some((bet) => {
	// 	const diff = new Date(formattFn(bet.eventEndTime)) - currentDate;
	// 	return diff > 0;
	// });
	if (!fromBetslips) {
		if (!betslip || !Array.isArray(betslip.bets)) {
			return true;
		}
	}
	const currentDate = new Date(currentDateStr);

	if (fromBetslips && betslip.type === BETSLIP_MODES.SINGLE) {
		const diff = new Date(formattFn(betslip.eventFinishTime)) - currentDate;
		return diff > 0;
	}

	return betslip.bets.some((bet) => {
		const diff = new Date(formattFn(bet.eventFinishTime)) - currentDate;
		return diff > 0;
	});
};

/** Lock screen orientation for mobile
 * @function
 * @param {string} mode - Orientation mode
 */
export const lockOrientation = (mode) => (screen.orientation && screen.orientation.lock && screen.orientation.lock(mode).catch((err) => console.log(err))) || (screen.mozLockOrientation && screen.mozLockOrientation(mode)) || (screen.msLockOrientation && screen.msLockOrientation(mode));

/** UnLock screen orientation for mobile
 * @function
 */
export const unLockOrientation = () => (screen.orientation && screen.orientation.unlock && screen.orientation.unlock()) || (screen.mozUnlockOrientation && screen.mozUnlockOrientation()) || (screen.msUnockOrientation && screen.msUnockOrientation());

/** Calculate the combination of n and r */
export const combinations = (n, r) => {
	const range = (a, b) => {
		let prd = a,
			i = a;

		while (i++ < b) {
			prd *= i;
		}
		return prd;
	};

	if (n == r || r == 0) {
		return 1;
	} else {
		r = r < n - r ? n - r : r;
		return range(r + 1, n) / range(1, n - r);
	}
};

/** Create Date Time string from object
 * @function
 * @param {object} dateTimeObj
 * @returns {string} formatte hour:minute
 */
export const createDateTimeString = (dateTimeObj) => {
	let hour = dateTimeObj?.hour ?? "";
	if (hour !== "") {
		hour = hour.toString().padStart(2, 0);
	}
	let minute = dateTimeObj?.minute ?? "";
	if (minute !== "") {
		minute = minute.toString().padStart(2, 0);
	}
	return `${hour}:${minute}`;
};

/** Checks is the game is racing
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */
export const isRacingGame = (type) => [SCHEDULED_GAME_TYPE.HORSES_RACE.value, SCHEDULED_GAME_TYPE.GREYHOUNDS_RACE.value, SCHEDULED_GAME_TYPE.STEEPLECHASING.value].includes(type);

/** Checks is the game is league
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */
export const isLeagueGame = type => [SCHEDULED_GAME_TYPE.ENGLISH_LEAGUE.value, SCHEDULED_GAME_TYPE.TURKISH_LEAGUE.value].includes(type);

/** Checks is the game is simple cup
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */
export const isSimpleCupGame = (type) => [SCHEDULED_GAME_TYPE.AFRICAN_CUP.value, SCHEDULED_GAME_TYPE.WORLD_CUP.value, SCHEDULED_GAME_TYPE.EUROPEAN_CUP.value].includes(type);

/** Checks is the game is champions game
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */
export const isChampionsCupGame = (type) => [SCHEDULED_GAME_TYPE.CHAMPIONS_LEAGUE.value, SCHEDULED_GAME_TYPE.COPA_LIBERTADORES.value].includes(type);

/** Checks is the game is cup
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */
export const isCupGame = (type) => isSimpleCupGame(type) || isChampionsCupGame(type);

/** Checks is the game is season
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */
export const isSeasonGame = (type) => isLeagueGame(type) || isCupGame(type);

/** Collect functions and get new function for execute with own argument, where every function get result of previous function as argument
 * @function
 * @param {array} functionsArray - functions array what will be executed
 * @param {any} initialArgument - An argument what will be passed to first function of functionArray as argument
 * @returns {any} - result of last function of functionArray
 */
export const pipe =
	(...functionsArray) =>
		(initialArgument) =>
			functionsArray.reduce((acc, func) => func(acc), initialArgument);

/** Get Round translated name of simple Cup Game
 * @function
 * @param {number} orderNumber - functions array what will be executed
 * @returns {string} - empty string or translated value
 */
export const getRoundNameOfSimpleCupGame = (orderNumber) => {
	switch (orderNumber) {
		case 1:
		case 2:
			return `${i18n.t("common.round")} 16 ${i18n.t("common.day")} ${orderNumber}`;
		case 3:
			return `${i18n.t("common.quarterFinals")}`;
		case 4:
			return `${i18n.t("common.semiFinals")}`;
		case 5:
			return `${i18n.t("common.final")}`;
		default:
			break;
	}
	return "";
};

/** Get Round translated name of champions Cup Game
 * @function
 * @param {number} orderNumber - functions array what will be executed
 * @returns {string} - empty string or translated value
 */
export const getRoundNameOfChampionsCupGame = (orderNumber) => {
	switch (orderNumber) {
		case 1:
		case 2:
			return `${i18n.t("common.round")} 16 ${i18n.t("common.leg")} ${orderNumber}`;
		case 3:
		case 4:
			return `${i18n.t("common.quarterFinals")} ${i18n.t("common.leg")} ${orderNumber}`;
		case 5:
		case 6:
			return `${i18n.t("common.semiFinals")} ${i18n.t("common.leg")} ${orderNumber}`;
		case 7:
			return `${i18n.t("common.final")}`;
		default:
			break;
	}
	return "";
};

/** Get Round translated name of Cup Game
 * @function
 * @param {number} orderNumber - functions array what will be executed
 * @returns {string} - empty string or translated value
 */
export const getRoundNameOfCupGame = (orderNumber, type) => {
	switch (true) {
		case isSimpleCupGame(type):
			return getRoundNameOfSimpleCupGame(orderNumber);
		case isChampionsCupGame(type):
			return getRoundNameOfChampionsCupGame(orderNumber);
		default:
			break;
	}
	return "";
};

/** Function to set cookie
 * @function
 * @param {string} cname - cookie name
 * @param {string} cvalue - cookie value
 * @param {number} exdays - cookie expiration days
 */
export const setCookie = (cname, cvalue, exdays) => {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	let expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

/** Function to get cookie
 * @function
 * @param {string} cname - cookie name
 * @returns {string}
 */
export const getCookie = (cname) => {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
};

export const constructArrayForGivenRange = ({ from, to }) => {
	const constructedArray = [];

	for (let i = from; i <= to; i++) {
		constructedArray.push(i);
	}

	return constructedArray;
};

export const toFixedWithoutRound = (num, count = 2) => {
	const regExp = new RegExp("^-?\\d+(?:.\\d{0," + (count || -1) + "})?");

	const toFixedNumber = num.toString().match(regExp)[0];

	return +toFixedNumber;
};

export const renderWithPercentage = (val) => (!isNaN(val) ? `${val}%` : null);

export const isValidDecimal = (num, count = 1) => {
	num = `${num}`;

	if (isNaN(num) || count > MAX_DECIMALS_COUNT) return false;

	const decimalIndex = num.indexOf(".");

	const decimalsCount = decimalIndex >= 0 ? num.length - decimalIndex - 1 : 0;

	return decimalsCount <= count;
};

export const countTotal = (data = []) => {
	if (!data.length) return 100;

	return data.reduce((acc, cur) => {
		const parsedCurrentItem = parseFloat(cur === "" ? 0 : cur);

		return !isNaN(parsedCurrentItem) ? toFixedWithoutRound(acc + parsedCurrentItem, 10) : acc;
	}, 0);
};

/** Function group array by chuncks
 * @function
 * @param {array} arr - input array for group by chunck
 * @param {number} chunkSize - max size of each chunck array
 * @returns {array[]}
 */
export const arrayToChunck = (arr = [], chunkSize = 0) => {
	if (!Array.isArray(arr)) {
		throw new TypeError('Invalid argument "arr". "arr" must be array');
	}
	if (typeof chunkSize !== "number") {
		throw new TypeError('Invalid argument "chunkSize". "chunkSize" must be number');
	}
	const retVal = [];
	for (let i = 0; i < arr.length; i += chunkSize) {
		retVal.push(arr.slice(i, i + chunkSize));
	}
	return retVal;
};

/**
 * Get Default filters object of games initial state of redux
 * @returns object
 */
export const getGameDefaultFilters = () => {
	return {
		nameOrId: "",
		specificationType: FILTER_GAME_SPECIFICATION_TYPE.ALL,
		gameType: "",
		itemState: FILTER_STATUS.ALL,
		testState: FILTER_TEST_STATE.ALL,
		to: "",
		from: "",
		lastUpdateFrom: "",
		lastUpdateTo: ""
	};
};

export const getGamesFromConfig = (gameCategory) => {
	let scheduledGames = [];
	let instantGames = [];

	switch (import.meta.env.SYSTEM_ENV) {
		case "loc":
		case "dev":
		case "tst":
		case "stg":
		case "prd":
			scheduledGames = [
				SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH,
				SCHEDULED_GAME_TYPE.ENGLISH_LEAGUE,
				SCHEDULED_GAME_TYPE.HORSES_RACE,
				SCHEDULED_GAME_TYPE.GREYHOUNDS_RACE,
				SCHEDULED_GAME_TYPE.AFRICAN_CUP,
				SCHEDULED_GAME_TYPE.KENO,
				SCHEDULED_GAME_TYPE.WORLD_CUP,
				SCHEDULED_GAME_TYPE.EUROPEAN_CUP,
				SCHEDULED_GAME_TYPE.CHAMPIONS_LEAGUE,
				SCHEDULED_GAME_TYPE.COPA_LIBERTADORES,
				SCHEDULED_GAME_TYPE.STEEPLECHASING,
				SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT,
				SCHEDULED_GAME_TYPE.TURKISH_LEAGUE,
			];
			instantGames = [INSTANT_GAME_TYPE.FOOTBALL_SINGLE_MATCH];
			break;
		default:
			break;
	}

	switch (gameCategory) {
		case GAME_CATEGORY.SCHEDULED:
			return scheduledGames;
		case GAME_CATEGORY.INSTANT:
			return instantGames;
		default:
			return [];
	}
};

/**
 * Get Default filters object of events online section, initial state of redux
 * @returns object
 */
export const getOnlineEventsDefaultState = () => {
	return {
		[GAME_CATEGORY.SCHEDULED]: {
			isLoading: false,
			events: [],
			total: 0,
			filters: {
				projectId: "",
				id: "",
				status: FILTER_EVENT_STATE.ALL,
				gameType: "",
				isResultCalculated: "",
				from: moment(yesterday(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				quickFilters: "last_24_h"
			},
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "StartTime",
				orderDirection: ORDER_DIRECTION.DESC
			}
		},
		[GAME_CATEGORY.INSTANT]: {
			isLoading: false,
			events: [],
			total: 0,
			filters: {
				projectId: "",
				id: "",
				status: FILTER_EVENT_STATE.ALL,
				gameType: "",
				isResultCalculated: "",
				from: moment(yesterday(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				quickFilters: "last_24_h"
			},
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "StartTime",
				orderDirection: ORDER_DIRECTION.DESC
			}
		}
	};
};

/**
 * Get Default filters object of events retail section, initial state of redux
 * @returns object
 */
export const getRetailEventsDefaultState = () => {
	return {
		[GAME_CATEGORY.SCHEDULED]: {
			isLoading: false,
			events: [],
			total: 0,
			filters: {
				projectId: "",
				id: "",
				status: FILTER_EVENT_STATE.ALL,
				gameType: "",
				isResultCalculated: "",
				from: moment(yesterday(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				quickFilters: "last_24_h"
			},
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "StartTime",
				orderDirection: ORDER_DIRECTION.DESC
			}
		}
	};
};


export const delay = (ms) => new Promise(res => setTimeout(res, ms))