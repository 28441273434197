import PropTypes from "prop-types";

const projectType = PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string
});

export default PropTypes.shape({
	partners: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
			type: PropTypes.number,
			apiKeys: PropTypes.arrayOf(projectType),
			availableApiKeys: PropTypes.arrayOf(projectType),
			betShops: PropTypes.arrayOf(projectType),
			availableBetShops: PropTypes.arrayOf(projectType)
		})
	)
});
