import PropTypes from "prop-types";

export default PropTypes.shape({
	rtPS: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			margin: PropTypes.number,
			enabled: PropTypes.bool
		})
	),
	allowOverride: PropTypes.bool,
	minPossibleValue: PropTypes.number,
	maxPossibleValue: PropTypes.number
});
