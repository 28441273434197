export const COLORS_LIST = [
	["--brand-color-1"],
	["--vs--bg-color", "--vs--bg-color-base", "--vs--bg-color-heading", "--vs--bg-color-element", "--vs--bg-color-element-default", "--vs--bg-color-element-hover", "--vs--bg-color-expanded"],
	["--vs--text-color-1", "--vs--text-color-2", "--vs--text-color-3", "--vs--icon-color", "--vs--icon-color-default", "--vs--icon-color-hover"]
];

export const TEMPLATES = {
	main: {
		name: "Main",
		colors: {
			"--brand-color-1": "#FFB700",
			"--vs--bg-color": "#111111",
			"--vs--bg-color-base": "#1B1B1B",
			"--vs--bg-color-heading": "#292929",
			"--vs--bg-color-element": "#292929",
			"--vs--bg-color-element-default": "rgba(41,41,41,.5)",
			"--vs--bg-color-element-hover": "#383838",
			"--vs--bg-color-expanded": "#303030",
			"--vs--text-color-1": "#FFFFFF",
			"--vs--text-color-2": "rgba(255,255,255,.8)",
			"--vs--text-color-3": "rgba(255,255,255,.5)",
			"--vs--icon-color": "#E6E6E6",
			"--vs--icon-color-default": "rgba(230,230,230,.5)",
			"--vs--icon-color-hover": "rgba(230,230,230,.7)"
		}
	}
};

export const TEMPLATE_RULES = {
	main: {
		"--brand-color-1": null,
		"--vs--bg-color": null,
		"--vs--bg-color-base": [
			{
				function: "lighten",
				percent: 4
			}
		],
		"--vs--bg-color-heading": [
			{
				function: "lighten",
				percent: 9
			}
		],
		"--vs--bg-color-element": [
			{
				function: "lighten",
				percent: 9
			}
		],
		"--vs--bg-color-element-default": [
			{
				function: "lighten",
				percent: 9
			},
			{
				function: "rgba",
				percent: 50
			}
		],
		"--vs--bg-color-element-hover": [
			{
				function: "lighten",
				percent: 15
			}
		],
		"--vs--bg-color-expanded": [
			{
				function: "lighten",
				percent: 12
			}
		],
		"--vs--text-color-1": null,
		"--vs--text-color-2": [
			{
				function: "rgba",
				percent: 80
			}
		],
		"--vs--text-color-3": [
			{
				function: "rgba",
				percent: 50
			}
		],
		"--vs--icon-color": [
			{
				function: "darken",
				percent: 10
			}
		],
		"--vs--icon-color-default": [
			{
				function: "darken",
				percent: 10
			},
			{
				function: "rgba",
				percent: 50
			}
		],
		"--vs--icon-color-hover": [
			{
				function: "darken",
				percent: 10
			},
			{
				function: "rgba",
				percent: 70
			}
		]
	}
};
