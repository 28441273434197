import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { Tabs } from "antd";

import Breadcrumbs from "components/common/breadcrumbs";
import GeneralComponent from "./sections/general";

import { getAccessManagerGeneralInfo } from "store/actions/dashboard/userManagement/accessManagers/accessManagers.action";
import { resetEditingAccessManager } from "store/actions/dashboard/userManagement/accessManagers/accessManagers.action";

import Paths from "constants/path.constants";

import { PARTNER_TYPE } from "constants/partner.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { updateLocationHash, getHashValue } from "utils/common";

import { hasOneOfPermissions, hasPermission } from "utils/permissions";

const MENU_KEYS = {
	GENERAL_INFO: "1"
};

/** Access Manager Edit Component */
const AccessManagerEditComponent = ({ userName, getAccessManagerGeneralInfo, resetEditingAccessManager }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const [activeKey, setActiveKey] = useState(MENU_KEYS.GENERAL_INFO);

	/** Load user partners info*/
	useEffect(() => {
		const hashValue = getHashValue("tab");
		if (hashValue && Object.values(hashValue).includes(hashValue)) {
			setActiveKey(hashValue);
		}
	}, []);

	/** Reset General Info on unmounth */
	useEffect(() => () => resetEditingAccessManager(), []);

	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf AccessManagerEditComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf AccessManagerEditComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	/** Load user general info*/
	useEffect(() => {
		if (routeParams.id) {
			getAccessManagerGeneralInfo(routeParams.id);
		}
	}, []);

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.access_managers"), path: Paths.ACCESS_MANAGERS }, { title: `${t("common.edit")} ${userName}` }]} />
				<Tabs
					animated={false}
					activeKey={activeKey}
					onChange={(key) => {
						updateLocationHash("tab=" + key);
						setActiveKey(key);
					}}
					items={[
						{
							key: MENU_KEYS.GENERAL_INFO,
							label: <span className={tabClassName(MENU_KEYS.GENERAL_INFO)}>{t("pages.dashboard.access_managers.tabs.general_info")}</span>,
							children: <GeneralComponent onTabChange={(status) => changeTabSavedStatus(status, MENU_KEYS.GENERAL_INFO)} />
						}
					]}
				/>
			</div>
		</Fragment>
	);
};

/** AccessManagerEditComponent propTypes
 * PropTypes
 */
AccessManagerEditComponent.propTypes = {
	/** Redux state, represents the userName of current editing user  */
	userName: PropTypes.string,
	/** Redux action to get user General info */
	getAccessManagerGeneralInfo: PropTypes.func,
	/** Redux action to reset editing user */
	resetEditingAccessManager: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		userName: state.accessManagers.editingAccessManager.generalInfo.userName
	};
};

const mapDispatchToProps = (dispatch) => ({
	resetEditingAccessManager: () => {
		dispatch(resetEditingAccessManager());
	},
	getAccessManagerGeneralInfo: (id) => {
		dispatch(getAccessManagerGeneralInfo(id));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessManagerEditComponent);
