import { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Button, Input, Spin, Tag, Divider } from "antd";
const { Item: FormItem } = Form;

import AvatarUpload from "components/dashboard/common/avatarUpload";
import Breadcrumbs from "components/common/breadcrumbs";

import { getProfile, saveProfile } from "store/actions/dashboard/profile/profile.action";
import { updateUserAvatar } from "store/actions/dashboard/profile/userInfo.action";

import { isFormChanged } from "utils/form";

import profileType from "types/profile/profile.type";

import { USER_ROLE } from "constants/user.constants";
import { TEL_REGEX } from "constants/regex.constants";

/** User Profile page Component */
const ProfileComponent = ({ getProfile, saveProfile, isSaving, isLoading, profile, userId, updateUserAvatar }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);

	/** Load user profile*/
	useEffect(() => {
		getProfile();
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			firstName: profile.firstName,
			lastName: profile.lastName,
			tel: profile.tel,
			address: profile.address
		});
	}, [profile]);

	/** Fires when form submitted
	 * @function
	 * @memberOf ProfileComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				saveProfile(data);
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.profile.my_profile") }]} />
				<Form
					style={{ height: "100%" }}
					colon={false}
					form={formInstance}
					requiredMark={false}
					layout="vertical"
					initialValues={{
						firstName: profile.firstName,
						lastName: profile.lastName,
						tel: profile.tel,
						address: profile.address
					}}
					onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, { ...profile }))}
				>
					<div className="dashboard-section-content">
						<div className="avatar-upload-wrapper">
							<AvatarUpload
								userId={userId}
								role={profile.role}
								name={`${profile.firstName ?? ""} ${profile.lastName ?? ""}`}
								onSuccess={(avatarId) => updateUserAvatar(avatarId)}
								avatarId={profile.avatarId}
								canEdit={profile.allowEdit}
								isProfile={true}
							/>
							<div className="avatar-upload-form">
								<Spin spinning={isLoading} wrapperClassName="form-spin">
									<div className="dashboard-section-content">
										<div>
											<Row gutter={[16, 0]}>
												<Col span={24}>
													<h1>{t("pages.dashboard.profile.personal_information")}</h1>
												</Col>
												<Col xs={24} sm={24} lg={8}>
													<FormItem label={t("pages.dashboard.users.id")}>
														<Input value={profile.id} disabled />
													</FormItem>
												</Col>
												<Col xs={24} sm={24} lg={8}>
													<FormItem label={t("pages.dashboard.users.username")}>
														<Input value={profile.userName} disabled />
													</FormItem>
												</Col>
												<Col xs={24} sm={24} lg={8}>
													<FormItem label={t("pages.dashboard.users.email")}>
														<Input value={profile.email} disabled />
													</FormItem>
												</Col>
											</Row>
											<Row gutter={[16, 0]}>
												<Col xs={24} sm={24} lg={8}>
													<FormItem
														label={`${t("pages.dashboard.users.first_name")} *`}
														name="firstName"
														rules={[
															{ required: true, whitespace: true, message: t("validation.field_required") },
															{ max: 30, message: t("validation.field_invalid") },
															{ min: 2, message: t("validation.field_invalid") }
														]}
													>
														<Input minLength={2} maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.users.first_name")}`} readOnly={!profile.allowEdit} />
													</FormItem>
												</Col>
												<Col xs={24} sm={24} lg={8}>
													<FormItem
														label={`${t("pages.dashboard.users.last_name")} *`}
														name="lastName"
														rules={[
															{ required: true, whitespace: true, message: t("validation.field_required") },
															{ max: 30, message: t("validation.field_invalid") },
															{ min: 2, message: t("validation.field_invalid") }
														]}
													>
														<Input minLength={2} maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.users.last_name")}`} readOnly={!profile.allowEdit} />
													</FormItem>
												</Col>
												<Col xs={24} sm={24} lg={8}>
													<FormItem
														label={t("pages.dashboard.users.tel")}
														name="tel"
														rules={[
															{ pattern: TEL_REGEX, message: t("validation.tel_format") },
															{ max: 30, message: t("validation.field_invalid") }
														]}
													>
														<Input maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.users.tel")}`} readOnly={!profile.allowEdit} />
													</FormItem>
												</Col>
											</Row>
											<Row gutter={[16, 0]}>
												<Col xs={24} sm={24} lg={8}>
													<FormItem label={t("pages.dashboard.users.address")} name="address" rules={[{ max: 100, message: t("validation.field_invalid") }]}>
														<Input maxLength={100} placeholder={`${t("common.enter")} ${t("pages.dashboard.users.address")}`} readOnly={!profile.allowEdit} />
													</FormItem>
												</Col>
											</Row>
											{
												profile.role === USER_ROLE.USER &&
												(
													<Row gutter={[16, 0]}>
														<Col span={24}>
															<h1 className="dashboard-section-name">{t('pages.dashboard.profile.partners')}</h1>
															{
																profile.partners && profile.partners.length > 0 ?
																	<div style={{ marginTop: "16px" }}>
																		{
																			profile.partners.map(p => <Tag className="ant-tag-card vs--profile-card" key={p}>
																				{p}</Tag>)
																		}
																	</div> : <span>{t('pages.dashboard.profile.no_partner')}</span>
															}
														</Col>
													</Row>
												)
											}
										</div>
										<FormItem className="button-container">
											{profile.allowEdit && (
												<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched || !profile.allowEdit}>
													<span>{t("common.save")}</span>
												</Button>
											)}
										</FormItem>
									</div>
								</Spin>
							</div>
						</div>
					</div>
				</Form>
			</div>
		</Fragment>
	);
};

/** ProfileComponent propTypes
 * PropTypes
 */
ProfileComponent.propTypes = {
	/** Redux action to get user profile */
	getProfile: PropTypes.func,
	/** Redux action to save user profile */
	saveProfile: PropTypes.func,
	/** Redux action to update user avatar */
	updateUserAvatar: PropTypes.func,
	/** Redux state property, is true when profile is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when profile is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the user profile  */
	profile: profileType,
	/** Redux state, represents the user id  */
	userId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getProfile: () => {
		dispatch(getProfile());
	},

	saveProfile: (data) => {
		dispatch(saveProfile(data));
	},

	updateUserAvatar: (avatarId) => {
		dispatch(updateUserAvatar(avatarId));
	}
});

const mapStateToProps = (state) => {
	return {
		profile: state.profile.profile,
		isSaving: state.profile.isSaving,
		isLoading: state.profile.isLoading,
		userId: state.profile.userInfo.id
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);
