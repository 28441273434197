import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import {
	SURVEY_AVAILABLE_PARTNERS_ACTION_BEFORE,
	SURVEY_AVAILABLE_PARTNERS_ACTION_FINISH,
	SET_SURVEY_AVAILABLE_PARTNERS,
	SET_SURVEY_PARTNERS_BEFORE,
	SET_SURVEY_PARTNERS_FINISH,
	SET_SURVEY_PARTNERS,
	SET_SURVEY_PARTNERS_SORTING,
	SURVEY_ADD_PARTNER_BEFORE,
	SURVEY_ADD_PARTNER_FINISH,
	SURVEY_SAVE_PARTNERS_BEFORE,
	SURVEY_SAVE_PARTNERS_FINISH,
	SURVEY_SAVE_PARTNERS,
	SET_SURVEY_PARTNER_FEEDBACKS_BEFORE,
	SET_SURVEY_PARTNER_FEEDBACKS_FINISH,
	SET_SURVEY_PARTNER_FEEDBACKS,
	SET_SURVEY_PARTNER_FEEDBACKS_SORTING
} from "store/actionTypes";

const surveyAddPartnerBefore = () => ({
	type: SURVEY_ADD_PARTNER_BEFORE
});

const surveyAddPartnerFinished = () => ({
	type: SURVEY_ADD_PARTNER_FINISH
});

const surveySavePartnersBefore = () => ({
	type: SURVEY_SAVE_PARTNERS_BEFORE
});

const surveySavePartnersFinished = () => ({
	type: SURVEY_SAVE_PARTNERS_FINISH
});

const setSurveyAvailablePartnersActionBefore = () => ({
	type: SURVEY_AVAILABLE_PARTNERS_ACTION_BEFORE
});

const setSurveyAvailablePartnersActionFinished = () => ({
	type: SURVEY_AVAILABLE_PARTNERS_ACTION_FINISH
});

const setSurveyPartnerFeedbacksBefore = () => ({
	type: SET_SURVEY_PARTNER_FEEDBACKS_BEFORE
});

const setSurveyPartnerFeedbacksFinished = () => ({
	type: SET_SURVEY_PARTNER_FEEDBACKS_FINISH
});

const setSurveyPartnersActionBefore = () => ({
	type: SET_SURVEY_PARTNERS_BEFORE
});

const setSurveyPartnersActionFinished = () => ({
	type: SET_SURVEY_PARTNERS_FINISH
});

const setSurveyAvailablePartners = (data) => ({
	type: SET_SURVEY_AVAILABLE_PARTNERS,
	payload: { data }
});

const setSurveyPartners = (info) => ({
	type: SET_SURVEY_PARTNERS,
	payload: { info }
});

const setSurveySavePartners = (data) => ({
	type: SURVEY_SAVE_PARTNERS,
	payload: { data }
});

export const setSurveyPartnersSorting = (sorting) => ({
	type: SET_SURVEY_PARTNERS_SORTING,
	payload: { sorting }
});

const setSurveyPartnerFeedbacks = (data) => ({
	type: SET_SURVEY_PARTNER_FEEDBACKS,
	payload: { data }
});

export const setSurveyPartnerFeedbacksSorting = (sorting) => ({
	type: SET_SURVEY_PARTNER_FEEDBACKS_SORTING,
	payload: { sorting }
});

export const getSurveyPartners = (surveyId, fromFirstPage = false) => {
	return (dispatch, getState) => {
		dispatch(setSurveyPartnersActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SURVEY_PARTNERS}`,
			method: Methods.GET,
			params: {
				id: surveyId,
				...getState().surveys.editingSurvey.partners.sorting,
				page: fromFirstPage ? 1 : getState().surveys.editingSurvey.partners.sorting.page
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setSurveyPartners(data));
				if (fromFirstPage) {
					dispatch(setSurveyPartnersSorting({ ...getState().surveys.editingSurvey.partners.sorting, page: 1 }));
				}
				dispatch(setSurveyPartnersActionFinished());
			})
			.catch(() => {
				dispatch(setSurveyPartnersActionFinished());
			});
	};
};

export const getSurveyPartnerFeedbacks = ({ fromFirstPage = false, surveyId, partnerId }) => {
	return (dispatch, getState) => {
		dispatch(setSurveyPartnerFeedbacksBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SURVEY_PARTNER_FEEDBACKS}`,
			method: Methods.GET,
			params: {
				surveyId,
				partnerId,
				...getState().surveys.editingSurvey.partnerFeedbacks.sorting,
				page: fromFirstPage ? 1 : getState().surveys.editingSurvey.partnerFeedbacks.sorting.page
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setSurveyPartnerFeedbacks(data));
				if (fromFirstPage) {
					dispatch(setSurveyPartnerFeedbacksSorting({ ...getState().surveys.editingSurvey.partnerFeedbacks.sorting, page: 1 }));
				}
				dispatch(setSurveyPartnerFeedbacksFinished());
			})
			.catch(() => {
				dispatch(setSurveyPartnerFeedbacksFinished());
			});
	};
};

export const getSurveyAvailablePartners = (id) => {
	return (dispatch) => {
		dispatch(setSurveyAvailablePartnersActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SURVEY_AVAILABLE_PARTNERS}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setSurveyAvailablePartners(data));
				dispatch(setSurveyAvailablePartnersActionFinished());
			})
			.catch(() => {
				dispatch(setSurveyAvailablePartnersActionFinished());
			});
	};
};

export const addSurveyPartners = ({ id, partnerIds, onAddClick = null }) => {
	return (dispatch) => {
		dispatch(surveyAddPartnerBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.ADD_PARTNERS_SURVEY}`,
			method: Methods.POST,
			data: { id, partnerIds }
		})
			.then(({ data: { status, ...other } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(other.message);
					dispatch(getSurveyPartners(id));

					if (onAddClick !== null) {
						onAddClick();
					}
				}
				dispatch(surveyAddPartnerFinished());
			})
			.catch(() => {
				dispatch(surveyAddPartnerFinished());
			});
	};
};

export const saveSurveyPartners = ({ id, partners }) => {
	return (dispatch) => {
		dispatch(surveySavePartnersBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_SURVEY_PARTNERS}`,
			method: Methods.POST,
			data: { id, partners }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setSurveySavePartners(data));
				dispatch(surveySavePartnersFinished());
			})
			.catch(() => {
				dispatch(surveySavePartnersFinished());
			});
	};
};
