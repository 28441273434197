import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Button, message } from "antd";
const { Item: FormItem } = Form;

import NumericInput from "components/common/numericInput";
import Table from "components/common/table";

import { FOOTBALL_PROBABILITIES, GAME_CATEGORY } from "constants/game.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { getFootballSceneProbabilities, saveFootballSceneProbabilities } from "store/actions/dashboard/virtuals/games/probabilities.action";

import { isFormChanged } from "utils/form";
import { numberTransform } from "utils/common";
import { hasPermission } from "utils/permissions";

import sceneProbabilitiesType from "types/game/sceneProbabilities.type";

/** Game Edit Page Probabilities Tab SceneProbabilities subTab Component */
const SceneProbabilitiesComponent = ({ isSaving, isLoading, footballSceneProbabilities, getFootballSceneProbabilities, saveFootballSceneProbabilities, onTabChange, gameCategory }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [formCurrentValues, setFormCurrentValues] = useState({});

	const goalScenes = footballSceneProbabilities?.goalScenes ?? [];
	const noGoalScenes = footballSceneProbabilities?.noGoalScenes ?? [];

	/** Load game scene probabilities */
	useEffect(() => {
		if (routeParams.id) {
			getFootballSceneProbabilities(routeParams.id, gameCategory);
		}
	}, [gameCategory]);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		if (goalScenes.length > 0 && noGoalScenes.length > 0) {
			const goalScenesObj = {};
			const noGoalScenesObj = {};
			goalScenes.forEach((s) => (goalScenesObj["type_" + s.type] = s.percent.toString()));
			noGoalScenes.forEach((s) => (noGoalScenesObj["type_" + s.type] = s.percent.toString()));
			setFieldsValue({
				goalScenes: goalScenesObj,
				noGoalScenes: noGoalScenesObj
			});
			setFormCurrentValues({
				goalScenes: goalScenesObj,
				noGoalScenes: noGoalScenesObj
			});
		}
	}, [goalScenes, noGoalScenes]);

	/** Fires when form submitted
	 * @function
	 * @memberOf SceneProbabilitiesComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				const goalScenes = data.goalScenes;
				const noGoalScenes = data.noGoalScenes;
				if (countTotal("goalScenes") === 100 && countTotal("noGoalScenes") === 100) {
					saveFootballSceneProbabilities(
						{
							id: routeParams.id,
							goalScenes: Object.keys(goalScenes).map((g) => ({
								type: g.replace("type_", ""),
								percent: goalScenes[g]
							})),
							noGoalScenes: Object.keys(noGoalScenes).map((g) => ({
								type: g.replace("type_", ""),
								percent: noGoalScenes[g]
							}))
						},
						gameCategory
					);
					setIsFormTouched(false);
				} else {
					message.error(t("pages.dashboard.games.probabilities.total_must_be"));
				}
			})
			.catch((ex) => {
				console.log(ex);
			});
	};

	/** Check is form changed
	 * @function
	 * @param {string} table - table to count (goalScenes, noGoalScenes)
	 * @returns {number} total sum of table fields values
	 * @memberOf SceneProbabilitiesComponent
	 */
	const countTotal = (table) => {
		if (!isFormTouched || !formCurrentValues[table]) return 100;
		return Object.values(formCurrentValues[table]).reduce((a, b) => (!isNaN(Number(b)) ? parseFloat((Number(a) + Number(b)).toFixed(10), 8) : Number(a)), 0);
	};

	/** Check is form changed
	 * @function
	 * @param {object} formValues - form current values
	 * @returns {boolean}
	 * @memberOf SceneProbabilitiesComponent
	 */
	const formChanged = (formValues) => {
		if (Object.keys(formValues).length === 0) return false;

		const initialValues = { ...footballSceneProbabilities };

		const initialGoalScenes = {};
		const initialNoGoalScenes = {};
		const formGoalScenes = {};
		const formNoGoalScenes = {};

		initialValues.goalScenes.forEach((scene) => {
			initialGoalScenes["type_" + scene.type] = scene.percent;
		});

		initialValues.noGoalScenes.forEach((scene) => {
			initialNoGoalScenes["type_" + scene.type] = scene.percent;
		});

		Object.keys(formValues.goalScenes).forEach((type) => {
			formGoalScenes[type] = Number(formValues.goalScenes[type]);
		});

		Object.keys(formValues.noGoalScenes).forEach((type) => {
			formNoGoalScenes[type] = Number(formValues.noGoalScenes[type]);
		});

		return isFormChanged({ goalScenes: formGoalScenes, noGoalScenes: formNoGoalScenes }, { goalScenes: initialGoalScenes, noGoalScenes: initialNoGoalScenes });
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	/** Make table columns
	 * @function
	 * @param {string} table - table  (goalScenes, noGoalScenes)
	 * @returns {array}
	 * @memberOf SceneProbabilitiesComponent
	 */
	const makeColumns = (table) => [
		{
			title: table === "goalScenes" ? t("pages.dashboard.games.probabilities.goal_scenes") : t("pages.dashboard.games.probabilities.no_goal_scenes"),
			dataIndex: "type",
			render: (value) => t(`pages.dashboard.games.probabilities.${Object.values(FOOTBALL_PROBABILITIES).filter((p) => p.value === value)[0].key}`),
			sorter: false,
			width: "50%"
		},
		{
			title: t("pages.dashboard.games.probabilities.probability"),
			dataIndex: "percent",
			width: "50%",
			sorter: false,
			render: (value, record) => (
				<FormItem
					className="inline-form-item-control"
					style={{ marginLeft: 0, maxWidth: "200px" }}
					name={[table, `type_${record.type}`]}
					rules={[
						{ required: true, whitespace: true, message: t("validation.field_required") },
						{ type: "number", max: 100, message: t("validation.must_be_less_than_other").replace("%X%", t("pages.dashboard.games.probabilities.probability")).replace("%Y%", "100"), transform: numberTransform },
						{ type: "number", min: 0, message: t("validation.must_be_more_than_other").replace("%X%", t("pages.dashboard.games.probabilities.probability")).replace("%Y%", "0"), transform: numberTransform }
					]}
				>
					<NumericInput disabled={!hasPermission({ resource: PERMISSION_RESOURCE.GAME_PROBABILITIES, action: PERMISSION_ACTION.MODIFY })} />
				</FormItem>
			)
		}
	];

	return (
		<Form
			colon={false}
			form={formInstance}
			requiredMark={false}
			layout="vertical"
			onValuesChange={(changed, formValues) => {
				setFormCurrentValues(JSON.parse(JSON.stringify(formValues)));
				setIsFormTouched(formChanged({ ...formValues }));
			}}
		>
			<div className="dashboard-section-content">
				<div>
					<div className="dashboard-probabilities">
						<Row gutter={[56, 0]}>
							<Col xs={24} sm={24} lg={12}>
								<Table
									loading={isLoading}
									columns={makeColumns("goalScenes")}
									data={goalScenes.map((d) => ({ ...d, key: d.type }))}
									total={goalScenes.length}
									isDisabled={() => false}
									noPagination={true}
									uniqueKey="type"
								/>
							</Col>
							<Col xs={24} sm={24} lg={12}>
								<Table
									loading={isLoading}
									columns={makeColumns("noGoalScenes")}
									data={noGoalScenes.map((d) => ({ ...d, key: d.type }))}
									total={noGoalScenes.length}
									isDisabled={() => false}
									noPagination={true}
									uniqueKey="type"
								/>
							</Col>
						</Row>
						<div className="dashboard-probabilities-footer">
							<Row gutter={[56, 0]}>
								<Col xs={24} sm={24} lg={12}>
									<div className="table-footer">
										<div className="table-footer-tr">
											<div className="table-footer-td">
												<b>{t("common.total")}</b>
											</div>
											<div className="table-footer-td">
												<b className={"info-text" + (countTotal("goalScenes") !== 100 ? " error-text" : "")}>{countTotal("goalScenes")}</b>
											</div>
										</div>
									</div>
								</Col>
								<Col xs={24} sm={24} lg={12}>
									<div className="table-footer">
										<div className="table-footer-tr">
											<div className="table-footer-td">
												<b>{t("common.total")}</b>
											</div>
											<div className="table-footer-td">
												<b className={"info-text" + (countTotal("noGoalScenes") !== 100 ? " error-text" : "")}>{countTotal("noGoalScenes")}</b>
											</div>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</div>
				{hasPermission({ resource: PERMISSION_RESOURCE.GAME_PROBABILITIES, action: PERMISSION_ACTION.MODIFY }) && (
					<FormItem className="button-container">
						<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
							<span>{t("common.save")}</span>
						</Button>
					</FormItem>
				)}
			</div>
		</Form>
	);
};

/** SceneProbabilitiesComponent propTypes
 * PropTypes
 */
SceneProbabilitiesComponent.propTypes = {
	/** Redux state property, is true when scene Probabilities is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when scene Probabilities is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the football scene Probabilities of current editing game  */
	footballSceneProbabilities: sceneProbabilitiesType,
	/** Redux action to save game football scene Probabilities */
	saveFootballSceneProbabilities: PropTypes.func,
	/** Redux action to get football scene Probabilities */
	getFootballSceneProbabilities: PropTypes.func,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	getFootballSceneProbabilities: (id, gameCategory) => {
		dispatch(getFootballSceneProbabilities(id, gameCategory));
	},

	saveFootballSceneProbabilities: (data, gameCategory) => {
		dispatch(saveFootballSceneProbabilities(data, gameCategory));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.games.isSaving,
		isLoading: state.games.isLoading,
		footballSceneProbabilities: state.games.editingGame.probabilities.footballSceneProbabilities
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SceneProbabilitiesComponent);
