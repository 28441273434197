import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Row, Col, Select, Form, Button, Dropdown, Menu, Tooltip } from "antd";
const { Item: FormItem } = Form;

import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import LiveMonitorTranslationField from "./liveMonitorTranslationField.component";

import translationType from "types/translation/translation.type";

import { getLiveMonitorTranslations, publishLiveMonitorTranslations } from "store/actions/dashboard/cms/translation/liveMonitorTranslation.action";
import { getSystemAvailableLanguages } from "store/actions/dashboard/settings/systemLanguages/systemLanguages.action";

import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { LIVE_MONITOR_TRANSLATION_SUB_GROUPS_IDS } from "constants/translation.constants";
import { SCHEDULED_GAME_TYPE, SCHEDULED_GAME_TYPE_MAPPER } from "constants/game.constants";

/** Live Monitor Translations Page Component */
const LiveMonitorTranslationsComponent = ({ isLoading, getLiveMonitorTranslations, publishLiveMonitorTranslations, liveMonitorTranslations, getSystemAvailableLanguages, systemLanguages, isSystemAvailableLoading, isPublishing }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();

	const { setFieldsValue } = formInstance;

	const [selectedLanguages, setSelectedLanguages] = useState([]);

	const [selectedGameType, setSelectedGameType] = useState(SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value);

	/** Load system available languages */
	useEffect(() => {
		getSystemAvailableLanguages();
	}, []);

	/** Set field values */
	useEffect(() => {
		const data = {};
		liveMonitorTranslations.forEach((t) => {
			data[t.key] = data[t.key] || {};
			t.translations.forEach((tr) => {
				data[t.key][tr.languageCode.toLowerCase()] = tr.text;
			});
		});
		setFieldsValue({
			translations: data
		});
	}, [liveMonitorTranslations]);

	useEffect(() => {
		if (Object.keys(systemLanguages).length > 0) {
			setSelectedLanguages(Object.keys(systemLanguages).splice(0, 3));
		}
	}, [systemLanguages]);

	/** Load translation on game type change */
	useEffect(() => {
		if (selectedGameType && selectedLanguages.length > 0) {
			handleGameTypeChange();
		}
	}, [selectedGameType, selectedLanguages]);

	/** Set column dropdown values */
	useEffect(() => {
		setFieldsValue({
			languages: selectedLanguages
		});
	}, [selectedLanguages]);

	/** Fires on groups dropdown change
	 * @function
	 * @memberOf LiveMonitorTranslationsComponent
	 */
	const handleGameTypeChange = () => {
		getLiveMonitorTranslations(selectedGameType, selectedLanguages);
	};

	/** Make columns array depend on languages list
	 * @function
	 * @param {string} value - selected value
	 * @returns {array}
	 * @memberOf LiveMonitorTranslationsComponent
	 */
	const makeColumns = () => {
		const columns = [
			{
				title: t("pages.dashboard.translations.keys"),
				dataIndex: "key",
				sorter: false,
				fixed: "left"
			},
			{
				title: t("pages.dashboard.translations.system_default"),
				dataIndex: "text",
				render: (value) => (
					<span title={value} className="table-col-shorten-text">
						{value}
					</span>
				),
				sorter: false
			}
		];

		selectedLanguages.forEach((lang, ind) => {
			columns.push({
				title: renderTranslationColumnTitle(lang, ind),
				dataIndex: lang,
				render: (value, record) => renderTranslationColumn(lang, record),
				sorter: false,
				className: "ant-table-input-cell"
			});
		});

		return columns;
	};

	/** Render column for translation
	 * @function
	 * @param {string} lang - language for column
	 * @param {object} record - row item
	 * @returns {JSX}
	 * @memberOf LiveMonitorTranslationsComponent
	 */
	const renderTranslationColumn = (lang, record) => {
		const translation = record.translations.find((t) => t.languageCode.toLowerCase() === lang.toLowerCase());

		return <LiveMonitorTranslationField lang={lang} translationKey={record.key} source={translation?.source} formInstance={formInstance} gameType={selectedGameType} />;
	};

	/** Render table column header
	 * @function
	 * @param {string} lang - language for column
	 * @param {number} index - column index
	 * @returns {JSX}
	 * @memberOf LiveMonitorTranslationsComponent
	 */
	const renderTranslationColumnTitle = (lang, index) => {
		let languages = Object.keys(systemLanguages).filter((l) => !selectedLanguages.includes(l) || l === lang);

		return (
			<div className="global-dropdown translation-field-dropdown">
				<Dropdown
					menu={{
						onClick: (e) => {
							const update = selectedLanguages.map((l, i) => (i === index ? e.key : l));
							setSelectedLanguages(update);
						},
						items: languages.map((l) => ({
							key: l,
							className: l === selectedLanguages[index] ? "global-dropdown-active" : "",
							label: systemLanguages[l]
						}))
					}}
					trigger={["click"]}
					placement="bottomLeft"
					overlayClassName="global-dropdown-menu"
				>
					<div className="global-dropdown-content">
						<span>{systemLanguages[selectedLanguages[index]]}</span>
						<i className="icon-down" />
					</div>
				</Dropdown>
				{hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_LIVE_MONITOR, action: PERMISSION_ACTION.MODIFY }) ? (
					<div className="translation-field-dropdown-publish live-publish-speaker" onClick={() => publishLiveMonitorTranslations(selectedLanguages[index])}>
						<Tooltip title={t("pages.dashboard.translations.publish")}>
							<i className="icon-speaker" />
						</Tooltip>
					</div>
				) : null}
			</div>
		);
	};

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.live_monitor_texts") }]} />
				<Form colon={false} requiredMark={false} form={formInstance} layout="vertical" initialValues={{ gameType: SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value }}>
					<div className="dashboard-section-content">
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} md={6}>
								<FormItem label={t("pages.dashboard.translations.game_type")} name="gameType">
									<Select onChange={(e) => setSelectedGameType(e)} suffixIcon={<i className="icon-down" />}>
										{Object.keys(LIVE_MONITOR_TRANSLATION_SUB_GROUPS_IDS).map((subGroupKey) => {
											const key = Number(subGroupKey);
											return (
												<Select.Option key={key} value={key}>
													{t(`common.${SCHEDULED_GAME_TYPE_MAPPER[key]}`)}
												</Select.Option>
											);
										})}
									</Select>
								</FormItem>
							</Col>
							<Col xs={24} sm={12} md={18}>
								<FormItem label=" " className="translation-buttons">
									{hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_LIVE_MONITOR, action: PERMISSION_ACTION.MODIFY }) ? (
										<Button onClick={() => publishLiveMonitorTranslations()} type="primary" icon={<i className="icon-speaker" />} className="button-with-icon">
											{t("pages.dashboard.translations.publish")}
										</Button>
									) : null}
								</FormItem>
							</Col>
						</Row>
						<Table loading={isLoading || isSystemAvailableLoading || isPublishing} columns={makeColumns()} data={liveMonitorTranslations} total={liveMonitorTranslations.length} actions={{}} isDisabled={() => false} noPagination={true} uniqueKey="key" />
					</div>
				</Form>
			</div>
		</Fragment>
	);
};

/** LiveMonitorTranslationsComponent propTypes
 * PropTypes
 */
LiveMonitorTranslationsComponent.propTypes = {
	/** Redux action to get live monitor translations */
	getLiveMonitorTranslations: PropTypes.func,
	/** Redux state property, is true when loading live monitor translations */
	isLoading: PropTypes.bool,
	/** Redux state property, live monitor translations */
	liveMonitorTranslations: PropTypes.arrayOf(translationType),
	/** Redux action to get system available languages */
	getSystemAvailableLanguages: PropTypes.func,
	/** Redux state property, represents the object of loaded system languages */
	systemLanguages: PropTypes.object,
	/** Redux state property, is true when loading system languages */
	isSystemAvailableLoading: PropTypes.bool,
	/** Redux action to publish live monitor translations */
	publishLiveMonitorTranslations: PropTypes.func,
	/** Redux state property, is true when publishing live monitor translations */
	isPublishing: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	getLiveMonitorTranslations: (gameType, languageCodes) => {
		dispatch(getLiveMonitorTranslations(gameType, languageCodes));
	},
	getSystemAvailableLanguages: () => {
		dispatch(getSystemAvailableLanguages());
	},
	publishLiveMonitorTranslations: (language) => {
		dispatch(publishLiveMonitorTranslations(language));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.translations.isLoading,
		liveMonitorTranslations: state.translations.liveMonitorTranslations,
		systemLanguages: state.systemLanguages.systemAvailableLanguages,
		isSystemAvailableLoading: state.systemLanguages.isAvailableLoading,
		isPublishing: state.translations.isPublishing
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveMonitorTranslationsComponent);
