import { Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Input } from "antd";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { getErrors, getError, setErrorsSorting } from "store/actions/dashboard/developer/errors/errors.action";

import Filters from "./filters.component";
import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";

import errorType from "types/log/error.type";
import sortingType from "types/common/sorting.type";

/** Error Logs Page Component */
const ErrorsComponent = ({ getErrors, getError, errors, isLoading, globalPartnerId, total, sorting, setErrorsSorting }) => {
	const { t } = useTranslation();

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.logs.controller") + "/" + t("pages.dashboard.logs.action"),
			dataIndex: "controller.action",
			render: (value, record) => <span key={record.key}>{(record?.controller ?? "-") + "/" + (record?.action ?? "-")} </span>,
			sorter: false
		},
		{
			title: "IP",
			dataIndex: "ip",
			sorter: false
		},
		{
			title: t("pages.dashboard.logs.message"),
			dataIndex: "message",
			render: (value) => (
				<span title={value} className="table-col-shorten-text">
					{value}
				</span>
			),
			sorter: false
		},
		{
			title: t("pages.dashboard.logs.time"),
			dataIndex: "errorTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT),
			sorter: false
		}
	];

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.logs_errors") }]} />
				<div className="dashboard-logs-section-content dashboard-section-content">
					<div className="table-header">
						<div className="table-buttons-dropdowns"></div>
						<Filters />
					</div>
					<Table
						loading={isLoading}
						columns={columns}
						data={errors}
						loadFn={getErrors}
						total={total}
						sorting={sorting}
						setSortingFn={setErrorsSorting}
						updateProps={[globalPartnerId]}
						isDisabled={() => false}
						details={(record) =>
							record.details
								? [
										{
											title: t("common.id"),
											value: record.details.id
										},
										{
											title: t("pages.dashboard.logs.controller"),
											value: record.details.controller
										},
										{
											title: t("pages.dashboard.logs.action"),
											value: record.details.action
										},
										{
											title: t("pages.dashboard.logs.message"),
											value: record.details.message
										},
										{
											title: t("pages.dashboard.logs.time"),
											value: moment.utc(record.details.errorTime).local().format(DATE_TIME_FORMAT)
										},
										{
											title: t("pages.dashboard.logs.stackTrace"),
											value: <Input.TextArea className="dashboard-logs-exception" autoSize={true} disabled={true} value={record.details.stackTrace} />
										}
									]
								: null
						}
						detailsLoadFn={(record) => (!record.details ? getError(record.id) : false)}
						detailsOptions={{ layout: "horizontal", column: 1, colon: ":", bordered: true }}
					/>
				</div>
			</div>
		</Fragment>
	);
};

/** ErrorsComponent propTypes
 * PropTypes
 */
ErrorsComponent.propTypes = {
	/** Redux action to get errors */
	getErrors: PropTypes.func,
	/** Redux action to get error */
	getError: PropTypes.func,
	/** Redux state property, represents the array of errors */
	errors: PropTypes.arrayOf(errorType),
	/** Redux state property, is true when loading errors */
	isLoading: PropTypes.bool,
	/** Redux state property, errors total count */
	total: PropTypes.number,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux action to set errors sorting details */
	setErrorsSorting: PropTypes.func,
	/** Redux state property, errors sorting details */
	sorting: sortingType
};

const mapDispatchToProps = (dispatch) => ({
	getErrors: () => {
		dispatch(getErrors());
	},
	getError: (id) => {
		dispatch(getError(id));
	},
	setErrorsSorting: (sorting) => {
		dispatch(setErrorsSorting(sorting));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.errors.isLoading,
		errors: state.errors.errors,
		globalPartnerId: state.partner.globalPartnerId,
		sorting: state.errors.sorting,
		total: state.errors.total
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorsComponent);
