import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { SET_BETSHOP_OPERATING_HOURS } from "store/actionTypes";

import { setBetshopsActionBefore, setBetshopsActionFinished, setBetshopSaveActionBefore, setBetshopSaveActionFinished } from "./betshops.action";

const setBetshopOperatingHours = (data) => ({
	type: SET_BETSHOP_OPERATING_HOURS,
	payload: { data }
});

export const getBetshopOperatingHours = (id) => {
	return (dispatch) => {
		dispatch(setBetshopsActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BETSHOP_GET_OPERATING_SETTINGS}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: operatingHours } }) => {
				dispatch(setBetshopOperatingHours(operatingHours));
				dispatch(setBetshopsActionFinished());
			})
			.catch((ex) => {
				dispatch(setBetshopsActionFinished());
			});
	};
};

export const saveBetshopOperatingHours = (data) => {
	return (dispatch) => {
		dispatch(setBetshopSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BETSHOP_SAVE_OPERATING_SETTINGS}`,
			method: Methods.POST,
			data: data
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					dispatch(setBetshopOperatingHours(data.value));
				}
				dispatch(setBetshopSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setBetshopSaveActionFinished());
			});
	};
};
