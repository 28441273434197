import axios from "axios";

import {
	JOBS_ACTION_BEFORE,
	JOBS_ACTION_FINISH,
	JOBS_ACTION_SET_JOBS,
	JOBS_ACTION_UPDATE_JOB,
	JOBS_ACTION_SET_SETTINGS,
	JOBS_ACTION_SAVE_BEFORE,
	JOBS_ACTION_SAVE_FINISHED
} from "store/actionTypes";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

const setJobsActionBefore = () => ({
	type: JOBS_ACTION_BEFORE
});

const setJobsActionFinished = () => ({
	type: JOBS_ACTION_FINISH
});

const setJobsActionSetJobs = (jobs) => ({
	type: JOBS_ACTION_SET_JOBS,
	payload: { jobs }
});

const setJobsActionUpdateJob = (job) => {
	return {
		type: JOBS_ACTION_UPDATE_JOB,
		payload: { job }
	};
};

const setJobSaveActionBefore = () => ({
	type: JOBS_ACTION_SAVE_BEFORE
});

const setJobSaveActionFinished = () => ({
	type: JOBS_ACTION_SAVE_FINISHED
});

const setJobActionSetJobSettings = (settings) => ({
	type: JOBS_ACTION_SET_SETTINGS,
	payload: { settings }
});

export const getJobs = () => {
	return (dispatch) => {
		dispatch(setJobsActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_JOBS}`,
			method: Methods.GETs
		})
			.then(({ data: { value: jobs } }) => {
				dispatch(setJobsActionSetJobs(jobs));
				dispatch(setJobsActionFinished());
			})
			.catch(() => {
				dispatch(setJobsActionFinished());
			});
	};
};

export const setJobs = (jobs) => {
	return (dispatch) => {
		dispatch(setJobsActionSetJobs(jobs));
	};
};

export const updateJob = (job) => {
	return (dispatch) => {
		dispatch(setJobsActionUpdateJob(job));
	};
};

export const unlockJob = (id) => {
	return (dispatch) => {
		dispatch(setJobsActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.UNLOCK_JOBS}`,
			method: Methods.POST,
			data: { id }
		})
			.then(({ data: { value: job } }) => {
				dispatch(updateJob(job));
				dispatch(setJobsActionFinished());
			})
			.catch(() => {
				dispatch(setJobsActionFinished());
			});
	};
};

export const getJobSettings = (id) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_JOBS_SETTINGS}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: settings } }) => {
				dispatch(setJobActionSetJobSettings(settings));
			})
			.catch(Function.prototype);
	};
};

export const saveJobSettings = (settings) => {
	return (dispatch) => {
		dispatch(setJobSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_JOBS_SETTINGS}`,
			method: Methods.POST,
			data: {
				...settings
			}
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					dispatch(updateJob({ ...data.value, id: settings.id }));
					dispatch(setJobActionSetJobSettings(data.value));
				}
				dispatch(setJobSaveActionFinished());
			})
			.catch(() => {
				dispatch(setJobSaveActionFinished());
			})
	}
}