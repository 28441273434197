import { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { hasPermission } from "utils/permissions";
import ApiUrls from "constants/api.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { getSystemCurrencies, setSystemCurrenciesSorting, setSystemCurrenciesFilters } from "store/actions/dashboard/settings/systemCurrencies/systemCurrencies.action";
import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import ExportButton from "components/common/exportButton";
import SystemCurrencyAddComponent from "./systemCurrencies-add.component";
import SearchableSelect from "components/common/searchableSelect";
import { Button } from "antd";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import useAutosuggestion from "hooks/useAutosuggestion";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
import systemCurrencyType from "types/systemCurrencies/systemCurrency.type";
import sortingType from "types/common/sorting.type";

/** System Currencies Page Component */
const SystemCurrenciesComponent = ({ getSystemCurrencies, systemCurrencies, isLoading, total, setSystemCurrenciesSorting, sorting, filters, setSystemCurrenciesFilters }) => {
	const { t } = useTranslation();

	/** State to show/hide game creation popup */
	const [showCreatePopup, setShowCreatePopup] = useState(false);
	const [editingCurrency, setEditingCurrency] = useState(null);
	const [currenciesFilter, setCurrenciesFilter] = useState(null);

	/** Load existing currencies */
	const currencyNames = useAutosuggestion(AUTOSUGGESTION_TYPE.CURRENCY_NAMES, [systemCurrencies.length]);

	/** Set Redux currencies filters state */
	useEffect(() => {
		setSystemCurrenciesFilters({ ...filters, code: currenciesFilter });
	}, [currenciesFilter]);

	// Update currencies data by filters change
	useEffect(() => {
		getSystemCurrencies();
	}, [filters]);

	/** Close currecny add/edit popup, after save */
	useEffect(() => {
		setShowCreatePopup(false);
		setEditingCurrency(null);
	}, [systemCurrencies]);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.currency.name"),
			dataIndex: "name"
		},
		{
			title: t("pages.dashboard.currency.code"),
			dataIndex: "code"
		},
		{
			title: t("pages.dashboard.currency.symbol"),
			dataIndex: "symbol",
			sorter: false
		},
		{
			title: t("pages.dashboard.currency.rate"),
			dataIndex: "rate",
			sorter: false
		},
		{
			title: t("pages.dashboard.currency.created_at"),
			dataIndex: "created",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.currency.last_update_at"),
			dataIndex: "lastUpdate",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.currency.last_update_by"),
			dataIndex: "lastUpdatedBy"
		}
	];

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.currencies") }]} />
				<div className="dashboard-section-content">
					<div className="table-buttons-dropdowns">
						<div className="table-search-section">
							<SearchableSelect
								placeholder={t("pages.dashboard.currency.search_currency")}
								items={currencyNames}
								valueProp={(item) => item.key}
								textProp={(item) => item.value}
								renderText={(item) => item.key + " - " + item.value}
								onChange={(e) => setCurrenciesFilter(e)}
								suffixIcon={<Fragment />}
								allowClear={true}
							/>
						</div>
						{hasPermission({
							resource: PERMISSION_RESOURCE.SETTINGS_CURRENCIES,
							action: PERMISSION_ACTION.EXPORT
						}) ? (
							<ExportButton columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t("pages.dashboard.currency.search_currency")} url={ApiUrls.EXPORT_SYSTEM_CURRENCIES} sorting={sorting} filters={filters} />
						) : null}
						{hasPermission({
							resource: PERMISSION_RESOURCE.SETTINGS_CURRENCIES,
							action: PERMISSION_ACTION.CREATE
						}) && (
							<>
								<div className="table-header-text">
									<span>{`${t("pages.dashboard.currency.system_default_currency")}: USD`}</span>
								</div>
								<div className="table-buttons-dropdowns-button">
									<Button onClick={() => setShowCreatePopup("add")} type="primary">
										{t("pages.dashboard.currency.create_currency")}
									</Button>
								</div>
							</>
						)}
					</div>
					<Table
						loading={isLoading}
						columns={columns}
						data={systemCurrencies}
						loadFn={getSystemCurrencies}
						sorting={sorting}
						setSortingFn={setSystemCurrenciesSorting}
						total={total}
						actions={
							hasPermission({
								resource: PERMISSION_RESOURCE.SETTINGS_CURRENCIES,
								action: PERMISSION_ACTION.MODIFY
							})
								? {
										edit: {
											handler: (record) => {
												setEditingCurrency(record.code);
												setShowCreatePopup("edit");
											},
											disabled: (record) => record.code === "USD"
										}
									}
								: {}
						}
						isDisabled={() => false}
						uniqueKey="code"
					/>
				</div>
			</div>

			{showCreatePopup ? (
				<SystemCurrencyAddComponent
					onClose={() => {
						setShowCreatePopup(false);
						setEditingCurrency(null);
					}}
					onCreateCallback={getSystemCurrencies}
					onUpdateCallback={getSystemCurrencies}
					mode={showCreatePopup}
					editingCurrency={editingCurrency}
				/>
			) : null}
		</Fragment>
	);
};

/** SystemCurrenciesComponent propTypes
 * PropTypes
 */
SystemCurrenciesComponent.propTypes = {
	/** Redux action to get system currencies */
	getSystemCurrencies: PropTypes.func,
	/** Redux state property, represents the array of loaded system currencies */
	systemCurrencies: PropTypes.arrayOf(systemCurrencyType),
	/** Redux state property, system currencies total count */
	total: PropTypes.number,
	/** Redux action to set system currencies sorting details */
	setSystemCurrenciesSorting: PropTypes.func,
	/** Redux state property, system currencies sorting details */
	sorting: sortingType,
	/** Redux state property, is true when loading system currencies */
	isLoading: PropTypes.bool,
	/** Redux state property, filter for existing currencies  */
	filters: PropTypes.object,
	/** Redux action to set system currencies filters */
	setSystemCurrenciesFilters: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getSystemCurrencies: () => {
		dispatch(getSystemCurrencies());
	},
	setSystemCurrenciesSorting: (sorting) => {
		dispatch(setSystemCurrenciesSorting(sorting));
	},
	setSystemCurrenciesFilters: (filters) => {
		dispatch(setSystemCurrenciesFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.systemCurrencies.isLoading,
		systemCurrencies: state.systemCurrencies.systemCurrencies,
		total: state.systemCurrencies.total,
		sorting: state.systemCurrencies.sorting,
		filters: state.systemCurrencies.filters
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemCurrenciesComponent);
