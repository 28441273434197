import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import ruleType from "types/translation/rule.type";
import { POPUP_SIZE } from "constants/common.constants";
import { GAME_CATEGORY } from "constants/game.constants";
import { REDUX_RULES_STORE_FIELD } from "constants/rules.constants";

const RuleDetailsPopup = ({ isLoading, rule, onClose, gameCategory }) => {
	const { t } = useTranslation();

	if (Object.keys(rule).length === 0) {
		return null;
	}

	return (
		<Modal open={true} title={t("pages.dashboard.translations.rule_body")} okText={t("common.close")} onOk={onClose} cancelButtonProps={{ style: { display: "none" } }} width={POPUP_SIZE.MIDDLE} bodyStyle={{ maxHeight: "570px" }} maskClosable={false} closable={false} centered>
			{isLoading ? (
				<div className="vs--flex vs--justify-center">
					<LoadingOutlined style={{ fontSize: 48 }} spin />
				</div>
			) : (
				<span dangerouslySetInnerHTML={{ __html: rule.content }}></span>
			)}
		</Modal>
	);
};

RuleDetailsPopup.propTypes = {
	/** Redux state property, is true when loading rule details */
	isLoading: PropTypes.bool,
	/** Redux state property, rule details */
	rule: ruleType,
	/** React property, callback will be called on close click */
	onClose: PropTypes.func,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapStateToProps = (state, props) => {
	const { gameCategory } = props;
	return {
		isLoading: state.translations.isLoading,
		rule: state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].editingRule
	};
};

export default connect(mapStateToProps, null)(RuleDetailsPopup);
