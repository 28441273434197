import {
	JOBS_ACTION_BEFORE,
	JOBS_ACTION_FINISH,
	JOBS_ACTION_SET_JOBS,
	JOBS_ACTION_UPDATE_JOB,
	JOBS_ACTION_SET_SETTINGS,
	JOBS_ACTION_SAVE_BEFORE,
	JOBS_ACTION_SAVE_FINISHED
} from '../../actionTypes';

export default (state = {}, { type, payload }) => {

	switch (type) {

		case JOBS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			}
		case JOBS_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			}
		case JOBS_ACTION_SET_JOBS:
			return {
				...state,
				jobs: payload.jobs
			}
		case JOBS_ACTION_UPDATE_JOB:
			return {
				...state,
				jobs: state.jobs.map(j => j.id === payload.job.id ? { ...j, ...payload.job } : j)
			}
		case JOBS_ACTION_SAVE_BEFORE:
			return {
				...state,
				isSaving: true
			}
		case JOBS_ACTION_SAVE_FINISHED:
			return {
				...state,
				isSaving: false
			}
		case JOBS_ACTION_SET_SETTINGS:
			return {
				...state,
				jobSettings: payload.settings
			}
		default:
			return state;
	}
}
