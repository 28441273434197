import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const particlesInit = async (engine) => await loadFull(engine);
const particlesLoaded = Function.prototype;

const options = {
	interactivity: {
		events: { onClick: { enable: true, mode: "push" }, onHover: { enable: true, mode: "grab" }, resize: true },
		modes: { push: { quantity: 4 }, repulse: { distance: 200, duration: 0.4 }, grab: { distance: 400, line_linked: { opacity: 0.3 } } }
	},
	particles: {
		color: { value: "#ffffff" },
		links: { color: "#ffffff", distance: 150, enable: true, opacity: 0.1, width: 1 },
		move: {
			enable: true,
			speed: 1.5,
			direction: "none",
			random: false,
			straight: false
		},
		number: { density: { enable: true, area: 800 }, value: 120 },
		opacity: { value: 0.5 },
		shape: { type: "circle" },
		size: { value: { min: 1, max: 5 } }
	}
};

/** Login Page Particle Canvas Component */
const ParticleCanvas = () => {
	return <Particles className="particle-canvas" init={particlesInit} loaded={particlesLoaded} options={options} />;
};

export default ParticleCanvas;
