import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useCurrentPath from "hooks/useCurrentPath";

import { Dropdown, Avatar } from "antd";

import { logout } from "store/actions/auth/auth.action";

import Paths from "constants/path.constants";

import userInfoType from "types/profile/userInfo.type";

/** Profile dropdown component on Header */

const ProfileDropdown = ({ userInfo, logout }) => {
	const navigate = useNavigate();
	const path = useCurrentPath();
	const { t } = useTranslation();

	/** Fires on dropdown change
	 * @function
	 * @param {string} value - selected item value
	 * @memberOf ProfileDropdown
	 */
	const handleChange = (value) => {
		if (value === "profile") {
			if (path !== Paths.DASHBOARD_PROFILE) {
				navigate(Paths.DASHBOARD_PROFILE);
			}
		} else if (value === "logout") {
			logout();
		}
	};

	return (
		<div className="global-dropdown global-dropdown-avatar">
			<Dropdown
				menu={{
					onClick: (e) => e.key && handleChange(e.key),
					items: [
						{
							key: "profile",
							label: t("common.profile")
						},
						{
							key: "logout",
							label: t("common.logout")
						}
					]
				}}
				trigger={["click"]}
				placement="bottomLeft"
			>
				<div className="global-dropdown-content">
					<div className="dashboard-header-avatar">
						<Avatar size="25" src={userInfo?.avatar}></Avatar>
						<span className="dashboard-header-avatar-text">{`${userInfo?.firstName ?? ""} ${userInfo?.lastName ?? ""}`}</span>
					</div>
				</div>
			</Dropdown>
		</div>
	);
};

/** ProfileDropdown propTypes
 * PropTypes
 */
ProfileDropdown.propTypes = {
	/** Redux state, represents the user info  */
	userInfo: userInfoType,
	/** Redux action to logout */
	logout: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		userInfo: state.profile.userInfo
	};
};

const mapDispatchToProps = (dispatch) => ({
	logout: () => {
		dispatch(logout());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown);
