import { buildPathToStaticFolderOfCDN } from "utils/common";

export const containerProps = { style: { width: "100%", height: "100%" } };

export const formInitialValues = { gameType: "" };

export const chartInitialOptions = {
	accessibility: {
		enabled: false
	},
	colors: ["#86BCDB", "#7E9EDA", "#7780D9", "#8879D9", "#A47AD9", "#C17CD9", "#D27DCE", "#D27DB0", "#D27C94", "#D27E79"],
	chart: {
		plotBackgroundImage: [buildPathToStaticFolderOfCDN("admin-images/dashboard/chart-bg.svg")],
		type: "column",
		style: { fontFamily: "Roboto" }
	},
	credits: { enabled: false },
	plotOptions: {
		column: {
			groupPadding: 0,
			pointPadding: 0.17,
			dataLabels: {
				enabled: true,
				style: {
					color: "#4D4D4D",
					fontSize: "14px",
					fontWeight: "600"
				}
			}
		},
		series: {
			type: "column",
			maxPointWidth: 140,
			states: {
				hover: {
					enabled: true
				}
			}
		}
	},
	legend: {
		symbolRadius: 50,
		margin: 26,
		align: "center",
		itemMarginBottom: 8,
		itemStyle: {
			color: "#4D4D4D",
			fontSize: "14px"
		}
	},
	xAxis: {
		labels: {
			enabled: false
		},
		tickLength: 0
	},
	yAxis: {
		gridLineColor: "transparent",
		labels: {
			style: {
				color: "#4D4D4D",
				fontSize: "14px",
				fontWeight: "600"
			}
		},
		title: {
			text: ""
		}
	},
	title: {
		align: "left",
		style: {
			fontSize: "14px",
			color: "#4D4D4D"
		},
		text: ""
	},
	tooltip: {
		enabled: true,
		headerFormat: null
	},
	series: []
};
