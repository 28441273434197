import { useState } from "react";
import PropTypes from "prop-types";

import { AutoComplete as AntAutoComplete } from "antd";

/** AutoComplete Component */
const AutoComplete = ({ placeholder, items, value, onChange, ...otherProps }) => {
	const [search, setSearch] = useState("");

	/** Get Filtered Options
	 * @function
	 * @returns {array}
	 * @description filters items by search value, and sort them according to search value position on string
	 * @memberOf AutoComplete
	 */
	const getOptions = () => {
		const filtered = search ? items.filter((item) => item.toLowerCase().includes(search.toLowerCase())) : [];
		filtered.sort((a, b) => {
			let aIndex = 1000000; // too big number
			let bIndex = 1000000;
			if (a.toLowerCase().includes(search.toLowerCase())) {
				aIndex = a.toLowerCase().indexOf(search.toLowerCase());
			}
			if (b.toLowerCase().includes(search.toLowerCase())) {
				bIndex = b.toLowerCase().indexOf(search.toLowerCase());
			}
			return aIndex >= bIndex ? 1 : -1;
		});

		return filtered.map((f) => ({ value: f }));
	};

	return <AntAutoComplete placeholder={placeholder} options={getOptions()} onSearch={setSearch} value={value} onChange={onChange} {...otherProps} />;
};

/** AutoComplete propTypes
 * PropTypes
 */
AutoComplete.propTypes = {
	/** Autocomplete data */
	items: PropTypes.arrayOf(PropTypes.string),
	/** Placeholder of input */
	placeholder: PropTypes.string,
	/** Selected option */
	value: PropTypes.string,
	/** Called when select an option or input value change, or value of input is changed */
	onChange: PropTypes.func,
	/** All other props */
	otherProps: PropTypes.object
};

export default AutoComplete;
