import PropTypes from 'prop-types';

import { JACKPOTS_TYPES, JACKPOT_STATE } from "constants/bonus.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	partnerId: PropTypes.string,
	partnerName: PropTypes.string,
	projectIds: PropTypes.array,
	type: PropTypes.oneOf(Object.values(JACKPOTS_TYPES)),
	name: PropTypes.string,
	players: PropTypes.number,
	gameType: PropTypes.number,
	gameCategory: PropTypes.number,
	state: PropTypes.oneOf(Object.values(JACKPOT_STATE)),
	hitCount: PropTypes.number,
	isDraft: PropTypes.bool,
	currency: PropTypes.string,
	levels: PropTypes.array,
	createTime: PropTypes.string,
	startTime: PropTypes.string,
	finishTime: PropTypes.string
})