import { SCHEDULED_GAME_TYPE } from "constants/game.constants";

// TODO: Need to handle game category
export default {
	[SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value]: {
		afg: "Afghanistan",
		aia: "Anguilla",
		alb: "Albania",
		alg: "Algeria",
		and: "Andorra",
		ang: "Angola",
		arg: "Argentina",
		arm: "Armenia",
		aru: "Aruba",
		asa: "American Samoa",
		atg: "Antigua and Barbuda",
		aus: "Australia",
		aze: "Azerbaijan",
		bah: "Bahamas",
		ban: "Bangladesh",
		bdi: "Burundi",
		ben: "Benin",
		bel: "Belgium",
		ber: "Bermuda",
		bfa: "Burkina Faso",
		bhr: "Bahrain",
		bhu: "Bhutan",
		bih: "Bosnia and Herzegovina",
		blr: "Belarus",
		blz: "Belize",
		bol: "Bolivia",
		bot: "Botswana",
		brb: "Barbados",
		bra: "Brazil",
		bru: "Brunei Darussalam",
		bul: "Bulgaria",
		cam: "Cambodia",
		can: "Canada",
		cay: "Cayman Islands",
		cgo: "Congo",
		cha: "Chad",
		chi: "Chile",
		chn: "China PR",
		cod: "Congo DR",
		col: "Colombia",
		com: "Comoros",
		cpv: "Cabo Verde",
		crc: "Costa Rica",
		cta: "Central African Republic",
		cub: "Cuba",
		cuw: "Curaçao",
		cyp: "Cyprus",
		cze: "Czech Republic",
		dji: "Djibouti",
		dma: "Dominica",
		dom: "Dominican Republic",
		ecu: "Ecuador",
		eng: "England",
		eqg: "Equatorial Guinea",
		eri: "Eritrea",
		esp: "Spain",
		est: "Estonia",
		fij: "Fiji",
		fin: "Finland",
		fra: "France",
		fro: "Faroe Islands",
		gab: "Gabon",
		geo: "Georgia",
		ger: "Germany",
		gha: "Ghana",
		gib: "Gibraltar",
		gnb: "Guinea-Bissau",
		gre: "Greece",
		grn: "Grenada",
		gua: "Guatemala",
		gum: "Guam",
		guy: "Guyana",
		hai: "Haiti",
		hkg: "Hong Kong",
		hon: "Honduras",
		hun: "Hungary",
		idn: "Indonesia",
		ind: "India",
		irl: "Republic of Ireland",
		irn: "Iran",
		irq: "Iraq",
		isl: "Iceland",
		isr: "Israel",
		ita: "Italy",
		jam: "Jamaica",
		jor: "Jordan",
		jpn: "Japan",
		kaz: "Kazakhstan",
		ken: "Kenya",
		kgz: "Kyrgyz Republic",
		kor: "Korea Republic",
		ksa: "Saudi Arabia",
		kuw: "Kuwait",
		kvx: "Kosovo",
		lao: "Laos",
		lbn: "Lebanon",
		lbr: "Liberia",
		lby: "Libya",
		lca: "St. Lucia",
		les: "Lesotho",
		lie: "Liechtenstein",
		ltu: "Lithuania",
		lux: "Luxembourg",
		lva: "Latvia",
		mac: "Macau",
		mad: "Madagascar",
		mas: "Malaysia",
		mda: "Moldova",
		mdv: "Maldives",
		mex: "Mexico",
		mkd: "North Macedonia",
		mlt: "Malta",
		mne: "Montenegro",
		mng: "Mongolia",
		moz: "Mozambique",
		mri: "Mauritius",
		msr: "Montserrat",
		mwi: "Malawi",
		mya: "Myanmar",
		nam: "Namibia",
		nca: "Nicaragua",
		ncl: "New Caledonia",
		nep: "Nepal",
		nga: "Nigeria",
		nig: "Niger",
		nir: "Northern Ireland",
		nor: "Norway",
		nzl: "New Zealand",
		oma: "Oman",
		pak: "Pakistan",
		pan: "Panama",
		par: "Paraguay",
		per: "Peru",
		phi: "Philippines",
		ple: "Palestine",
		png: "Papua New Guinea",
		pol: "Poland",
		por: "Portugal",
		prk: "Korea DPR",
		pur: "Puerto Rico",
		qat: "Qatar",
		rou: "Romania",
		rsa: "South Africa",
		rwa: "Rwanda",
		sam: "Samoa",
		sco: "Scotland",
		sen: "Senegal",
		sey: "Seychelles",
		sin: "Singapore",
		skn: "St. Kitts and Nevis",
		sle: "Sierra Leone",
		slv: "El Salvador",
		smr: "San Marino",
		sol: "Solomon Islands",
		som: "Somalia",
		srb: "Serbia",
		sri: "Sri Lanka",
		ssd: "South Sudan",
		stp: "São Tomé and Príncipe",
		sur: "Suriname",
		svk: "Slovakia",
		svn: "Slovenia",
		swz: "Eswatini",
		syr: "Syria",
		tah: "Tahiti",
		tan: "Tanzania",
		tca: "Turks and Caicos Islands",
		tga: "Tonga",
		tha: "Thailand",
		tjk: "Tajikistan",
		tkm: "Turkmenistan",
		tls: "Timor-Leste",
		tog: "Togo",
		tpe: "Chinese Taipei",
		tri: "Trinidad and Tobago",
		tur: "Turkey",
		uae: "United Arab Emirates",
		uga: "Uganda",
		ukr: "Ukraine",
		uru: "Uruguay",
		usa: "USA",
		uzb: "Uzbekistan",
		van: "Vanuatu",
		ven: "Venezuela",
		vgb: "British Virgin Islands",
		vie: "Vietnam",
		vin: "St. Vincent and the Grenadines",
		vir: "US Virgin Islands",
		yem: "Yemen",
		zam: "Zambia"
	},
	[SCHEDULED_GAME_TYPE.ENGLISH_LEAGUE.value]: {
		ars: "ARS",
		che: "CHE",
		mnu: "MNU",
		liv: "LIV",
		mnc: "MNC",
		whu: "WHU",
		new: "NEW",
		lei: "LEI",
		ldu: "LDU",
		eve: "EVE",
		sou: "SOU",
		tot: "TOT",
		cry: "CRY",
		bur: "BUR",
		wol: "WOL",
		ast: "AST",
		bha: "BHA",
		bre: "BRE",
		nor: "NOR",
		wat: "WAT"
	},
	[SCHEDULED_GAME_TYPE.AFRICAN_CUP.value]: {
		cmr: "Cameroon",
		sen: "Senegal",
		alg: "Algeria",
		mli: "Mali",
		tun: "Tunisia",
		gui: "Guinea",
		egy: "Egypt",
		gha: "Ghana",
		zim: "Zimbabwe",
		civ: "Cote d'Ivoire",
		mar: "Morocco",
		nga: "Nigeria",
		sdn: "Sudan",
		eth: "Ethiopia",
		mtn: "Mauritania",
		gam: "Gambia"
	},
	[SCHEDULED_GAME_TYPE.WORLD_CUP.value]: {
		bel: "Belgium",
		fra: "France",
		eng: "England",
		ita: "Italy",
		esp: "Spain",
		por: "Portugal",
		ger: "Germany",
		uru: "Uruguay",
		arg: "Argentina",
		bra: "Brazil",
		nga: "Nigeria",
		sen: "Senegal",
		cmr: "Cameroon",
		irn: "Iran",
		tur: "Turkey",
		rus: "Russia"
	},
	[SCHEDULED_GAME_TYPE.EUROPEAN_CUP.value]: {
		bel: "Belgium",
		fra: "France",
		eng: "England",
		ita: "Italy",
		esp: "Spain",
		por: "Portugal",
		den: "Denmark",
		ned: "Netherlands",
		ger: "Germany",
		sui: "Switzerland",
		cro: "Croatia",
		swe: "Sweden",
		wal: "Wales",
		aut: "Austria",
		tur: "Turkey",
		rus: "Russia"
	},
	[SCHEDULED_GAME_TYPE.CHAMPIONS_LEAGUE.value]: {
		bar: "BAR",
		rma: "RMA",
		atm: "ATM",
		bay: "BAY",
		bvb: "BVB",
		bnf: "BNF",
		che: "CHE",
		liv: "LIV",
		mnu: "MNU",
		tot: "TOT",
		int: "INT",
		mnc: "MNC",
		juv: "JUV",
		psg: "PSG",
		ajx: "AJX",
		por: "POR"
	},
	[SCHEDULED_GAME_TYPE.COPA_LIBERTADORES.value]: {
		aso: "ASO",
		boc: "BOC",
		cam: "CAM",
		ccp: "CCP",
		cru: "CRU",
		eme: "EME",
		fla: "FLA",
		gre: "GRE",
		ldu: "LDU",
		lib: "LIB",
		nac: "NAC",
		oli: "OLI",
		pal: "PAL",
		rac: "RAC",
		riv: "RIV",
		sfc: "SFC"
	},
	[SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value]: {
		"arg": "Argentina",
		"bel": "Belgium",
		"bra": "Brazil",
		"col": "Colombia",
		"cro": "Croatia",
		"eng": "England",
		"esp": "Spain",
		"fra": "France",
		"ger": "Germany",
		"irn": "Iran",
		"ita": "Italy",
		"mar": "Morocco",
		"mex": "Mexico",
		"ned": "Netherlands",
		"nga": "Nigeria",
		"por": "Portugal",
		"sen": "Senegal",
		"tun": "Tunisia",
		"tur": "Turkey",
		"uru": "Uruguay",
		"den": "Denmark",
		"cmr": "Cameroon"
	},
	[SCHEDULED_GAME_TYPE.TURKISH_LEAGUE.value]: {
		"ads": "Adana Demirspor",
		"aly": "Alanyaspor",
		"ank": "Ankaragucu",
		"ant": "Antalyaspor",
		"bfk": "Istanbul Basaksehir",
		"bjk": "Besiktas",
		"crs": "Caykur Rizespor",
		"fb": "Fenerbahce",
		"fkg": "Fatih Karagümrük",
		"gfk": "Gaziantep FK",
		"gs": "Galatasaray",
		"hty": "Hatayspor",
		"ist": "Istanbulspor",
		"kay": "Kayserispor",
		"kny": "Konyaspor",
		"ksp": "Kasimpasa",
		"pen": "Pendikspor",
		"sam": "Samsunspor",
		"svs": "Sivasspor",
		"ts": "Trabzonspor"
	},
}
