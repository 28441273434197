import { Fragment, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Tabs } from "antd";

import Breadcrumbs from "components/common/breadcrumbs";
import GeneralComponent from "../edit/sections/general";

import Paths from "constants/path.constants";

/** Cashier Edit Component */
const CashierEditComponent = ({ userName }) => {
	const { t } = useTranslation();

	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf CashierEditComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf CashierEditComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.cashiers.cashiers"), path: Paths.DASHBOARD_RETAIL_CASHIER_MANAGEMENT_CASHIERS }, { title: `${t("common.edit")} ${userName}` }]} />
				<Tabs
					animated={false}
					items={[
						{
							key: "1",
							label: <span className={tabClassName("1")}>{t("pages.dashboard.cashiers.tabs.general_info")}</span>,
							children: <GeneralComponent onTabChange={(status) => changeTabSavedStatus(status, "1")} />
						}
					]}
				/>
			</div>
		</Fragment>
	);
};

/** CashierEditComponent propTypes
 * PropTypes
 */
CashierEditComponent.propTypes = {
	/** Redux state, represents the username of current editing cashier  */
	userName: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		userName: state.cashiers.editingCashier.generalInfo.userName
	};
};

export default connect(mapStateToProps, null)(CashierEditComponent);
