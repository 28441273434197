import { Fragment, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { Form, Row, Col, Select, Input, DatePicker } from "antd";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import { getPlayers, setPlayersFilters } from "store/actions/dashboard/online/players/players.action";
import FiltersWrapper from "components/common/filters";
import SearchableSelect from "components/common/searchableSelect";
import useAutosuggestion from "hooks/useAutosuggestion";
import { yesterday, getDateRange } from "utils/dateTime";
import localStorageUtils from "utils/localStorage";
import { PROJECT_TYPE } from "constants/common.constants";
import { FILTER_SHOW_IN_CURRENCY, FILTER_USER_STATE, FILTER_TEST_STATE } from "constants/filter.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
const { Item: FormItem } = Form;

/** Players Page Filters Component */
const Filters = ({ setPlayersFilters, getPlayers, filters, globalPartnerId }) => {
	const { t } = useTranslation();
	const isMountedRef = useRef(null);
	const [searchParams] = useSearchParams();
	const apiKeyNames = useAutosuggestion(AUTOSUGGESTION_TYPE.API_KEY_NAMES, [globalPartnerId], { disableAction: () => !globalPartnerId });

	const filtersList = [
		{
			name: "projectIds",
			title: t("pages.dashboard.players.project"),
			values: apiKeyNames.map((p) => ({ title: p.value, value: p.key }))
		},
		{ name: "userNameOrId", title: t("pages.dashboard.players.player_id_or_username") },
		{ name: "externalId", title: t("pages.dashboard.players.external_id") },
		{
			name: "testState",
			title: t("pages.dashboard.players.type"),
			values: [
				{ title: t("common.all"), value: FILTER_TEST_STATE.ALL },
				{ title: t("pages.dashboard.players.test"), value: FILTER_TEST_STATE.TESTING },
				{ title: t("pages.dashboard.players.real"), value: FILTER_TEST_STATE.NOTTESTING }
			]
		},
		{
			name: "showIn",
			title: t("pages.dashboard.bets.show_in"),
			values: [
				{ title: t("pages.dashboard.bets.original_currency"), value: FILTER_SHOW_IN_CURRENCY.ORIGINAL },
				{ title: t("pages.dashboard.bets.converted_currency"), value: FILTER_SHOW_IN_CURRENCY.CONVERTED }
			]
		},
		{ name: "lastUpdate", title: t("pages.dashboard.players.last_active_at") }
	];

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [
				{ name: "date", keepTime: false },
				{ name: "lastUpdate", keepTime: false }
			]
		};
	}, []);

	const modifiedFilters = useMemo(() => {
		if (!isMountedRef.current) {
			isMountedRef.current = true;
			const period = searchParams.get("period");
			const projectType = searchParams.get("projectType");

			if (period && Number(projectType) === PROJECT_TYPE.ONLINE) {
				const cachedFilters = localStorageUtils.get("filters") || {};
				const cachedPlayersFilters = cachedFilters["players"] || {};
				const projectId = searchParams.get("projectId");
				const { from, to } = getDateRange(Number(period));

				const necessaryKeys = {
					lastUpdateFrom: from,
					lastUpdateTo: to,
					projectIds: projectId === "all" ? [] : [projectId]
				};

				cachedFilters["players"] = { ...cachedPlayersFilters, ...necessaryKeys };
				localStorageUtils.set("filters", cachedFilters);

				return { ...filters, from: moment().subtract(1, "years").toDate(), to: new Date() };
			}

			return {
				...filters,
				from: moment(yesterday(), `${DATE_FORMAT}`).set("hour", 0).set("minute", 0).set("second", 0).toDate(),
				to: moment(new Date(), `${DATE_FORMAT}`).set("hour", 23).set("minute", 59).set("second", 59).toDate()
			};
		} else {
			return filters;
		}
	}, [filters]);

	return (
		<FiltersWrapper filtersName="players" loadFn={getPlayers} setFiltersFn={setPlayersFilters} formFieldsConfigs={formFieldsConfigs} filters={modifiedFilters} filtersList={filtersList}>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.players.project")} name="projectIds">
							<SearchableSelect items={apiKeyNames} valueProp={(item) => item.key} textProp={(item) => item.value} renderText={(item) => item.value} mode="multiple" placeholder={`${t("common.select")} ${t("pages.dashboard.players.project")}`} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.players.player_id_or_username")} name="userNameOrId">
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.players.player_id_or_username")}`} />
						</FormItem>
					</Col>

					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.players.external_id")} name="externalId">
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.players.external_id")}`} />
						</FormItem>
					</Col>

					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.players.status")} name="state">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_USER_STATE.ALL}>{t("common.all")}</Select.Option>
								<Select.Option value={FILTER_USER_STATE.ACTIVE}>{t("pages.dashboard.users.status_2")}</Select.Option>
								<Select.Option value={FILTER_USER_STATE.BLOCKED}>{t("pages.dashboard.users.status_4")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.players.type")} name="testState">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_TEST_STATE.ALL}>{t("common.all")}</Select.Option>
								<Select.Option value={FILTER_TEST_STATE.TESTING}>{t("pages.dashboard.players.test")}</Select.Option>
								<Select.Option value={FILTER_TEST_STATE.NOTTESTING}>{t("pages.dashboard.players.real")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.show_in")} name="showIn">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_SHOW_IN_CURRENCY.ORIGINAL}>{t("pages.dashboard.bets.original_currency")}</Select.Option>
								<Select.Option value={FILTER_SHOW_IN_CURRENCY.CONVERTED}>{t("pages.dashboard.bets.converted_currency")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={6} lg={6} xl={4}>
						<FormItem label={t("common.creation_date")} name="date">
							<DatePicker.RangePicker placeholder={[t("common.from"), t("common.to")]} format={DATE_FORMAT} disabledDate={(d) => !d || d.isAfter(moment().endOf("day"))} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.players.last_active_at")} name="lastUpdate">
							<DatePicker.RangePicker placeholder={[t("common.from"), t("common.to")]} format={DATE_FORMAT} disabledDate={(d) => !d || d.isAfter(moment().endOf("day"))} />
						</FormItem>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get players */
	getPlayers: PropTypes.func,
	/** Redux action to set players filters */
	setPlayersFilters: PropTypes.func,
	/** Redux state property, players filters */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		filters: state.players.filters,
		globalPartnerId: state.partner.globalPartnerId
	};
};

const mapDispatchToProps = (dispatch) => ({
	getPlayers: () => {
		dispatch(getPlayers());
	},
	setPlayersFilters: (filters) => {
		dispatch(setPlayersFilters(filters));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
