import { Fragment, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Tabs, Drawer, Tag, Button } from "antd";

import { hasPermission } from "utils/permissions";

import SurveyQuickInfoType from "types/survey/generalInfo.type";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { SURVEY_STATE } from "constants/survey.constants";
import Paths from "constants/path.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import CsatQuickInfoGeneralTab from "components/dashboard/usersFeedback/CSAT/quickInfo/sections/general";
import CsatQuickInfoPartnersTab from "components/dashboard/usersFeedback/CSAT/quickInfo/sections/partners";
import SurveyStateChangeComponent from "components/dashboard/usersFeedback/CSAT/survey-state-change.component";

import { getSurveyQuickInfo } from "store/actions/dashboard/usersFeedback/CSAT/quickInfo.action";

const UserSurveyQuickInfo = ({ quickInfo: { state, id }, getSurveyQuickInfo, onClose }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [activeKey, setActiveKey] = useState("general");

	/** State to show/hide survey state change popup */
	const [showStateChangePopup, setShowStateChangePopup] = useState(false);

	const getStateColor = (state) => {
		const mapping = {
			[SURVEY_STATE.ACTIVE]: "green",
			[SURVEY_STATE.FINISHED]: "blue",
			[SURVEY_STATE.INACTIVE]: "geekblue"
		};
		return mapping[state];
	};

	const handleStateTagClick = (e) => {
		e.stopPropagation();
		setShowStateChangePopup(true);
	};

	return (
		<Fragment>
			<Drawer
				className={"dashboard-quick-info"}
				title={
					<div className="vs--flex">
						<div style={{ marginRight: "auto" }}>{t("pages.dashboard.usersFeedback.csat_quick_info")}</div>
						<div className="dashboard-quick-info-csat vs--flex vs--justify-center vs--quick-info-actions">
							{state !== SURVEY_STATE.NONE && (
								<div className="vs--quick-info-state">
									<Tag color={getStateColor(state)}>
										{t("pages.dashboard.usersFeedback.status_" + (state || SURVEY_STATE.FINISHED))}
										{hasPermission({ resource: PERMISSION_RESOURCE.SURVEYS, action: PERMISSION_ACTION.MODIFY }) && state === SURVEY_STATE.ACTIVE && <i className="icon-edit" onClick={(e) => handleStateTagClick(e)} />}
									</Tag>
								</div>
							)}
						</div>
						<Button
							disabled={id === "" || state === SURVEY_STATE.NONE || state === SURVEY_STATE.FINISHED}
							onClick={() => {
								navigate(Paths.DASHBOARD_USERS_FEEDBACK_CSAT_EDIT.replace(DYNAMIC_PATH_ID_REGEX, id));
								onClose();
							}}
							className="dashboard-quick-info-edit-btn"
							type="primary"
						>
							{hasPermission({ resource: PERMISSION_RESOURCE.SURVEYS, action: PERMISSION_ACTION.MODIFY }) ? t("common.edit") : t("common.view")}
						</Button>
					</div>
				}
				closable={false}
				open={id !== ""}
				onClose={onClose}
				width="50%"
				maskStyle={{ backgroundColor: "transparent" }}
			>
				<Tabs
					animated={false}
					activeKey={activeKey}
					onChange={(key) => {
						setActiveKey(key);
					}}
					items={[
						{
							key: "general",
							label: t("pages.dashboard.usersFeedback.tabs.general"),
							children: <CsatQuickInfoGeneralTab />
						},
						{
							key: "partners",
							label: t("pages.dashboard.usersFeedback.tabs.partners"),
							children: <CsatQuickInfoPartnersTab />
						}
					]}
				/>
			</Drawer>

			{showStateChangePopup && <SurveyStateChangeComponent onClose={() => setShowStateChangePopup(false)} onSaveClick={() => getSurveyQuickInfo(id)} id={id} />}
		</Fragment>
	);
};

UserSurveyQuickInfo.propTypes = {
	/** Redux state, represents the quick info of current survey  */
	quickInfo: SurveyQuickInfoType,
	/** Redux action to get survey quick info */
	getSurveyQuickInfo: PropTypes.func,
	/** Function to call on quick info close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getSurveyQuickInfo: (surveyId) => {
		dispatch(getSurveyQuickInfo(surveyId));
	}
});

const mapStateToProps = (state) => {
	return {
		quickInfo: state.surveys.quickInfo.quickInfo
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSurveyQuickInfo);
