import PropTypes from "prop-types";

const securityLevelColors = {
	1: "#b8d5a1",
	2: "#85b876",
	3: "#71a992",
	4: "#54a7ad",
	5: "#5c95aa",
	6: "#3d6a85",
	7: "#164a69",
	200: "#00A1AF"
};

const defaultStyles = {
	marginLeft: "auto"
};

const IconSecurityLevel = ({ level, ...props }) => {
	switch (level) {
		case 1:
		case 2:
		case 3:
		case 4:
		case 5:
		case 6:
		case 7:
			return (
				<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style={defaultStyles} {...props}>
					<g id="Icon" transform="translate(-52 -51)">
						<rect id="Path" width="24" height="24" transform="translate(52 51)" fill="none" />
						<path
							id="Vector"
							transform="translate(55 52.313)"
							fill={securityLevelColors[level]}
							d="M17.527,2.886,9.219.036a.678.678,0,0,0-.438,0L.473,2.886A.712.712,0,0,0,0,3.563v7.125c0,7.83,8.711,10.63,8.8,10.658a.684.684,0,0,0,.4,0c.089-.028,8.8-2.827,8.8-10.658V3.563A.711.711,0,0,0,17.527,2.886Z"
						/>
						<text id="_1" data-name="1" transform="translate(64 67.3)" fill="#fff" fontSize="14" fontFamily="Roboto-Bold, Roboto" fontWeight="700">
							<tspan x="-4" y="0">
								{level}
							</tspan>
						</text>
					</g>
				</svg>
			);
		default:
			return (
				<svg width="43" height="19" viewBox="0 0 43 19" xmlns="http://www.w3.org/2000/svg" style={defaultStyles} {...props}>
					<g id="Icon" transform="translate(-52 -51)">
						<text id="_1" data-name="1" transform="translate(64 67.3)" fill={securityLevelColors[level]} fontSize="14" fontFamily="Roboto-Bold, Roboto" fontWeight="700">
							<tspan x="-12" y="0">
								{level} LV
							</tspan>
						</text>
					</g>
				</svg>
			);
	}
};

IconSecurityLevel.propTypes = {
	level: PropTypes.number
};

export default IconSecurityLevel;
