import { Fragment, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Form, Row, Col, DatePicker, Radio, Tooltip } from "antd";

const { Item: FormItem } = Form;
import locale from "antd/es/date-picker/locale/en_US";

import FiltersWrapper from "components/common/filters";

import { getPartnerOnlineReport, setPartnerOnlineReportFilters } from "store/actions/dashboard/reports/partnerOnlineReport/partnerOnlineReport.action";

import { monthAgo } from "utils/dateTime";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

/** Partner Online Report Page Filters Component */

const Filters = ({ setPartnerOnlineReportFilters, getPartnerOnlineReport, filters }) => {
	const { t } = useTranslation();

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [{ name: "date", keepTime: true, roundTime: true }]
		};
	}, []);

	return (
		<FiltersWrapper filtersName="partnerOnlineReport" loadFn={() => getPartnerOnlineReport()} setFiltersFn={(filters) => setPartnerOnlineReportFilters(filters)} filters={filters} formFieldsConfigs={formFieldsConfigs} filtersList={[]} defaultOpened={true}>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={24} lg={12} xl={8}>
						<FormItem
							label={
								<Fragment>
									<span>{t("pages.dashboard.events.date_and_time")}</span>
									<Tooltip title={t("common.for_more_data")} getPopupContainer={() => document.body}>
										<i className="icon-info table-filters-info" />
									</Tooltip>
								</Fragment>
							}
							name="date"
						>
							<DatePicker.RangePicker
								placeholder={[t("common.from"), t("common.to")]}
								format={`${DATE_FORMAT} HH:00`}
								showTime={{ format: "HH" }}
								disabledDate={(d) => !d || d.isBefore(monthAgo(3)) || d.isAfter(moment().endOf("day"))}
								allowClear={false}
								locale={{
									...locale,
									lang: {
										...locale.lang,
										ok: t("common.apply")
									}
								}}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={18} lg={18} xl={12}>
						<div className="table-filters-quick-filters">
							<FormItem label={t("common.quick_filters")} name="quickFilters" className="table-filters-quick-filters-buttons">
								<Radio.Group>
									<Radio.Button value="last_24_h" style={{ marginBottom: "8px" }}>
										{t("common.last_24_h")}
									</Radio.Button>
									<Radio.Button value="last_week" style={{ marginBottom: "8px" }}>
										{t("common.this_week")}
									</Radio.Button>
									<Radio.Button value="last_month" style={{ marginBottom: "8px" }}>
										{t("common.this_month")}
									</Radio.Button>
									<Radio.Button value="last_3_month" style={{ marginBottom: "8px" }}>
										{t("common.last_3_months")}
									</Radio.Button>
								</Radio.Group>
							</FormItem>
						</div>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get partner online report */
	getPartnerOnlineReport: PropTypes.func,
	/** Redux action to set partner online report filters */
	setPartnerOnlineReportFilters: PropTypes.func,
	/** Redux state property, partner online report filters */
	filters: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	getPartnerOnlineReport: () => {
		dispatch(getPartnerOnlineReport());
	},

	setPartnerOnlineReportFilters: (filters) => {
		dispatch(setPartnerOnlineReportFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.reports.partnerOnlineReport.filters
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
