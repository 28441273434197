/** Lighten color by amount
 * @function
 * @param {string} color - color
 * @param {number} amount - amount
 * @returns {string}
 */
export const lighten = (color, amount) => {
	const addLight = function (color, amount) {
		let cc = parseInt(color, 16) + amount;
		let c = cc > 255 ? 255 : cc;
		c = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`;
		return c;
	};

	color = color.indexOf("#") >= 0 ? color.substring(1, color.length) : color;
	amount = parseInt((255 * amount) / 100);
	return (color = `#${addLight(color.substring(0, 2), amount)}${addLight(color.substring(2, 4), amount)}${addLight(color.substring(4, 6), amount)}`);
};

/** Darken color by amount
 * @function
 * @param {string} color - color
 * @param {number} amount - amount
 * @returns {string}
 */
export const darken = (color, amount) => {
	const subtractLight = function (color, amount) {
		let cc = parseInt(color, 16) - amount;
		let c = cc < 0 ? 0 : cc;
		c = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`;
		return c;
	};

	color = color.indexOf("#") >= 0 ? color.substring(1, color.length) : color;
	amount = parseInt((255 * amount) / 100);
	return (color = `#${subtractLight(color.substring(0, 2), amount)}${subtractLight(color.substring(2, 4), amount)}${subtractLight(color.substring(4, 6), amount)}`);
};

/** Hex to rgba
 * @function
 * @param {string} color - color
 * @param {number} percent - percent
 * @returns {string}
 */
export const rgba = (color, percent) => {
	let c;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
		c = color.substring(1).split("");
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = "0x" + c.join("");
		return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",." + percent + ")";
	}
};

/** Class for generate unic colors with iterator pattern
 * @class
 * @param {number} limit - colors max count what should be generate
 * @param {array} defaultExistingColors - array whit default colors
 * @returns {instance}
 */
export class ColorGenerator {
	constructor(limit = 100, defaultExistingColors = null) {
		this.limit = limit;
		this.index = 0;
		this.existingColors = defaultExistingColors ? defaultExistingColors.map((color) => color.toLowerCase()) : this.getDefaultExistingColors() || [];
		this.startRandomFrom = this.existingColors.length;
	}

	[Symbol.iterator]() {
		return this;
	}

	next() {
		if (this.index === this.limit) {
			return { value: undefined, done: true };
		}
		if (this.index < this.startRandomFrom) {
			return { done: false, value: this.existingColors[this.index++] };
		}
		let isUnic = false;
		let randColor = null;
		while (!isUnic) {
			randColor = this.getRandomColor();
			isUnic = !this.existingColors.includes(randColor);
		}
		this.index++;
		this.existingColors.push(randColor);
		return { done: false, value: randColor };
	}

	getDefaultExistingColors() {
		return [
			"#ca8a04",
			"#2dd4c0",
			"#0891b3",
			"#e879f9",
			"#831844",
			"#ec8610",
			"#10b982",
			"#165e76",
			"#9a7ad2",
			"#fb7286",
			"#92400e",
			"#176535",
			"#4438ca",
			"#701a75",
			"#0c4a6f",
			"#f572b6",
			"#d87706",
			"#4d7d0f",
			"#a755f7",
			"#be195e",
			"#ea580b",
			"#1d7771",
			"#6366f1",
			"#a21caf",
			"#be113c",
			"#4d1d95",
			"#793510",
			"#064d3b",
			"#7c0000",
			"#312d81"
		];
	}
	getRandomColor() {
		return "#" + Math.floor(Math.random() * 0xffffff).toString(16);
	}
}
