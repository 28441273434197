import { Button, Col, Form, Row, Select, Spin } from "antd";
import PropTypes from "prop-types";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";
import { connect } from "react-redux";
import { RISK_MANAGEMENT_ACTION_TYPE_TRANSLATIONS, RISK_MANAGEMENT_ACTION_TYPES, RISK_MANAGEMENT_AVERAGE_UPDATE_FREQUENCY, RISK_MANAGEMENT_AVERAGE_UPDATE_FREQUENCY_TRANSLATIONS } from "constants/riskManagement.constants";
import { getPlayerSuspiciousActivitiesConfigurations } from "store/actions/dashboard/riskManagment/playerSuspiciousActivities/playerSuspiciousActivities.actions";

import NumericInput from "components/common/numericInput";
import SearchableSelect from "components/common/searchableSelect";

import useUpdateEffect from "hooks/useUpdateEffect";
import { EMAIL_REGEX } from "constants/regex.constants";
import Table from "components/common/table";
import CreateConfiguration from "./createConfiguration"

const { Item: FormItem } = Form;

const PlayerSuspiciousActivitiesConfigurations = ({
	isSaving = false,
	isLoading = false,
	configurations = [],
	total = 0,
	getPlayerSuspiciousActivitiesConfigurations = Function.prototype
}) => {

	const { t } = useTranslation();
	const [showCreatePopup, setShowCreatePopup] = useState(false)

	const columns = [
		{
			title: t("pages.dashboard.bets.currency"),
			dataIndex: "currency",
			render: (value) => {
				return value?.toUpperCase() ?? " - ";
			},
			sorter: false
		},
		{
			title: t("pages.dashboard.risk_management.player_suspicious_activities_configurations_betAmount"),
			dataIndex: "minBetAmount",
			sorter: false
		},
		{
			title: t("pages.dashboard.risk_management.player_suspicious_activities_configurations_deviation"),
			dataIndex: "minDeviation",
			sorter: false
		},
		{
			title: t("pages.dashboard.risk_management.player_suspicious_activities_configurations_averageUpdateFrequency"),
			dataIndex: "averageUpdateFrequency",
			render: (value) => {
				return t(RISK_MANAGEMENT_AVERAGE_UPDATE_FREQUENCY_TRANSLATIONS[value]);
			},
			sorter: false
		},
		{
			title: t("pages.dashboard.risk_management.player_suspicious_activities_configurations_actionType"),
			dataIndex: "actionType",
			render: (value) => {
				return t(RISK_MANAGEMENT_ACTION_TYPE_TRANSLATIONS[value]);
			},
			sorter: false
		},
		{
			title: t("pages.dashboard.risk_management.player_suspicious_activities_configurations_emails"),
			dataIndex: "emails",
			sorter: false
		}
	];

	return (
		<div className="dashboard-section table-section">
			<div className="dashboard-section-content">
				<div className="table-header">
					<div className="table-buttons-dropdowns">
						{hasPermission({ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS, action: PERMISSION_ACTION.CREATE }) ? (
							<div className="table-buttons-dropdowns-button">
								<Button onClick={() => setShowCreatePopup(true)} type="primary">
									{t("pages.dashboard.risk_management.player_suspicious_activities_configurations_addConfigurations")}
								</Button>
							</div>
						) : null}
					</div>
				</div>
				<Table
					loading={isLoading}
					columns={columns}
					data={configurations}
					loadFn={getPlayerSuspiciousActivitiesConfigurations}
					total={total}
					detailsType="table"
					uniqueKey="id"
					noPagination={true}
				/>
				{
					showCreatePopup ? <CreateConfiguration onClose={() => setShowCreatePopup(false)} /> : null
				}
			</div>
		</div>
	);
};

PlayerSuspiciousActivitiesConfigurations.propTypes = {
	isSaving: PropTypes.bool,
	isLoading: PropTypes.bool,
	total: PropTypes.number,
	configurations: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			currency: PropTypes.string,
			averageUpdateFrequency: PropTypes.oneOf(Object.values(RISK_MANAGEMENT_AVERAGE_UPDATE_FREQUENCY)),
			minBetAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			minDeviation: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			actionType: PropTypes.oneOf(Object.values(RISK_MANAGEMENT_ACTION_TYPES)),
			emails: PropTypes.arrayOf(PropTypes.string)
		})
	),
	getPlayerSuspiciousActivitiesConfigurations: PropTypes.func
};

const mapStateToProps = (state) => ({
	isSaving: state.riskManagement.playerSuspiciousActivities.configurations.isSaving,
	isLoading: state.riskManagement.playerSuspiciousActivities.configurations.isLoading,
	configurations: state.riskManagement.playerSuspiciousActivities.configurations.data,
	total: state.riskManagement.playerSuspiciousActivities.configurations.total
});

const mapDispatchToProps = (dispatch) => ({
	getPlayerSuspiciousActivitiesConfigurations: () => {
		dispatch(getPlayerSuspiciousActivitiesConfigurations());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerSuspiciousActivitiesConfigurations);
