import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { setApikeysActionBefore, setApikeysActionFinished, setApikeySaveActionBefore, setApikeySaveActionFinished } from "./apikeys.action";

import { SET_APIKEY_GENERAL_INFO } from "store/actionTypes";
import { binaryToFlags, flagsToBinary } from "utils/common";
import { GAME_CATEGORY } from "constants/game.constants";

const setApikeyGeneralInfo = (info) => ({
	type: SET_APIKEY_GENERAL_INFO,
	payload: { info }
});

export const getApiKeyGeneralInfo = (id) => {
	return (dispatch) => {
		dispatch(setApikeysActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APIKEY_GET_GENERAL_INFO}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: info } }) => {
				dispatch(
					setApikeyGeneralInfo({
						...info,
						gameCategoryList: binaryToFlags(Object.values(GAME_CATEGORY), info.gameCategory)
					})
				);
				dispatch(setApikeysActionFinished());
			})
			.catch((ex) => {
				dispatch(setApikeysActionFinished());
			});
	};
};

export const saveApiKeyGeneralInfo = (sendInfo) => {
	return (dispatch) => {
		dispatch(setApikeySaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APIKEY_SAVE_GENERAL_INFO}`,
			method: Methods.POST,
			data: {
				...sendInfo,
				gameCategory: flagsToBinary(sendInfo.gameCategoryList)
			}
		})
			.then(({ data: { message: msg, status, value: responseInfo } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(
						setApikeyGeneralInfo({
							...responseInfo,
							gameCategoryList: binaryToFlags(Object.values(GAME_CATEGORY), responseInfo.gameCategory)
						})
					);
				}
				dispatch(setApikeySaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setApikeySaveActionFinished());
			});
	};
};
