import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import {
	USERS_ACTION_BEFORE,
	USERS_ACTION_FINISH,
	USERS_ACTION_SET_USERS,
	USERS_ACTION_SET_INACTIVE_USERS,
	USERS_INACTIVE_USERS_ACTION_BEFORE,
	USERS_INACTIVE_USERS_ACTION_FINISH,
	USER_SAVE_ACTION_BEFORE,
	USERS_SAVE_ACTION_FINISH,
	USERS_SET_SORTING,
	USERS_SET_FILTERS,
	USERS_AVAILABLE_PARTNERS_ACTION_BEFORE,
	USERS_AVAILABLE_PARTNERS_ACTION_FINISH,
	USERS_ACTION_SET_AVAILABLE_PARTNERS,
	CHANGE_USER_STATE,
	RESET_EDITING_USER
} from "store/actionTypes";

export const setUsersActionBefore = () => ({
	type: USERS_ACTION_BEFORE
});

export const setUsersActionFinished = () => ({
	type: USERS_ACTION_FINISH
});

export const setUserSaveActionBefore = () => ({
	type: USER_SAVE_ACTION_BEFORE
});

export const setUserSaveActionFinished = () => ({
	type: USERS_SAVE_ACTION_FINISH
});

const setUserAvailablePartnersActionBefore = () => ({
	type: USERS_AVAILABLE_PARTNERS_ACTION_BEFORE
});

const setUserAvailablePartnersActionFinished = () => ({
	type: USERS_AVAILABLE_PARTNERS_ACTION_FINISH
});

const setUsers = (data) => ({
	type: USERS_ACTION_SET_USERS,
	payload: { data }
});

export const setInactiveUsers = (data) => ({
	type: USERS_ACTION_SET_INACTIVE_USERS,
	payload: { data }
});

const setInactiveUsersBefore = () => ({
	type: USERS_INACTIVE_USERS_ACTION_BEFORE
});

const setInactiveUsersFinished = () => ({
	type: USERS_INACTIVE_USERS_ACTION_FINISH
});

const setUserAvailablePartners = (data) => ({
	type: USERS_ACTION_SET_AVAILABLE_PARTNERS,
	payload: { data }
});

const setChangeUserState = (data) => ({
	type: CHANGE_USER_STATE,
	payload: { data }
});

export const setUsersSorting = (sorting) => ({
	type: USERS_SET_SORTING,
	payload: { sorting }
});

export const setUsersFilters = (filters) => ({
	type: USERS_SET_FILTERS,
	payload: { filters }
});

export const resetEditingUser = () => ({
	type: RESET_EDITING_USER
});

export const getUsers = (fromFirstPage) => {
	return (dispatch, getState) => {
		dispatch(setUsersActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_USERS}`,
			method: Methods.GET,
			params: {
				...getState().users.sorting,
				...getState().users.filters,
				page: fromFirstPage ? 1 : getState().users.sorting.page
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setUsers(data));
				if (fromFirstPage) {
					dispatch(setUsersSorting({ ...getState().users.sorting, page: 1 }));
				}
				dispatch(setUsersActionFinished());
			})
			.catch(() => {
				dispatch(setUsersActionFinished());
			});
	};
};

export const getInactiveUsers = () => {
	return (dispatch) => {
		dispatch(setInactiveUsersBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_GET_INACTIVE}`,
			method: Methods.GET
		})
			.then(({ data: { value: users } }) => {
				dispatch(setInactiveUsers(users));
				dispatch(setInactiveUsersFinished());
			})
			.catch(() => {
				dispatch(setInactiveUsersFinished());
			});
	};
};

export const createUser = (user, onSuccess) => {
	return (dispatch) => {
		dispatch(setUserSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CREATE_USER}`,
			method: Methods.POST,
			data: user
		})
			.then(({ data: { value: user } }) => {
				dispatch(setUserSaveActionFinished());
				if (typeof onSuccess === "function") {
					onSuccess(user);
				}
			})
			.catch(() => {
				dispatch(setUserSaveActionFinished());
			});
	};
};

export const getUserAvailablePartners = () => {
	return (dispatch) => {
		dispatch(setUserAvailablePartnersActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_GET_AVAILABLE_PARTNERS}`,
			method: Methods.GET
		})
			.then(({ data: { value: data } }) => {
				dispatch(setUserAvailablePartners(data));
				dispatch(setUserAvailablePartnersActionFinished());
			})
			.catch(() => {
				dispatch(setUserAvailablePartnersActionFinished());
			});
	};
};

export const changeUserState = (data) => {
	return (dispatch) => {
		dispatch(setUserSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CHANGE_USER_STATE}`,
			method: Methods.POST,
			data: data
		})
			.then(({ data: { value: data } }) => {
				setTimeout(() => {
					dispatch(setChangeUserState(data));
				}, 200);
				dispatch(setUserSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setUserSaveActionFinished());
			});
	};
};

export const deleteUser = (id) => {
	return (dispatch) => {
		dispatch(setUsersActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.DELETE_USER}`,
			method: Methods.DELETE,
			data: { id }
		})
			.then(({ data: { status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					dispatch(getUsers());
				}
				dispatch(setUsersActionFinished());
			})
			.catch(() => {
				dispatch(setUsersActionFinished());
			});
	};
};

export const forceLogout = () => {
	return (dispatch, getState) => {
		dispatch(setUserSaveActionBefore());

		const filters = { ...getState().users.filters };
		Object.keys(filters).forEach((k) => {
			if (filters[k] === "" || filters[k] === undefined) {
				delete filters[k];
			}
		});

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_FORCE_LOGOUT}`,
			method: Methods.POST,
			data: {
				...getState().users.sorting,
				...filters,
				page: getState().users.sorting.page
			}
		}).finally(() => {
			dispatch(setUserSaveActionFinished());
		});
	};
};

export const resendRegistrationEmail = (ids, onSuccess) => {
	return (dispatch) => {
		dispatch(setUserSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_RESEND_REGISTRATION_EMAIL}`,
			method: Methods.POST,
			data: { ids }
		})
			.then(({ data: { status, message: msg } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
				}
				dispatch(setUserSaveActionFinished());

				if (typeof onSuccess === "function") {
					onSuccess();
				}
			})
			.catch(() => {
				dispatch(setUserSaveActionFinished());
			});
	};
};

export const unlockUser = (id) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_UNLOCK}`,
			method: Methods.POST,
			data: { id }
		}).then(({ data: { status, message: msg } }) => {
			if (status === RESPONSE_STATE.SUCCESS) {
				message.success(msg);
				dispatch(getUsers());
			}
		});
	};
};
