import {
	BETSHOPS_ACTION_BEFORE,
	BETSHOPS_ACTION_FINISH,
	BETSHOPS_ACTION_SET_BETSHOPS,
	BETSHOP_SAVE_ACTION_BEFORE,
	BETSHOP_SAVE_ACTION_FINISH,
	BETSHOPS_ACTION_SET_BETSHOP_ENABLED,
	BETSHOPS_SET_FILTERS,
	BETSHOPS_SET_SORTING,
	SET_BETSHOP_GENERAL_INFO,
	SET_BETSHOP_LOGO_ID,
	SET_BETSHOP_CURRENCY,
	SET_BETSHOP_BOXES,
	ADD_BETSHOP_BOX,
	UPDATE_BETSHOP_BOX,
	DELETE_BETSHOP_BOX,
	BETSHOP_BOXES_BEFORE,
	BETSHOP_BOXES_FINISH,
	SET_BETSHOP_AVAILABLE_BOXES,
	BETSHOP_AVAILABLE_BOXES_BEFORE,
	BETSHOP_AVAILABLE_BOXES_FINISH,
	SET_BETSHOP_OPERATING_HOURS,
	SET_BETSHOP_LIMIT_SETTINGS,
	SET_BETSHOP_BONUS_CONFIGS,
	BETSHOP_SESSIONS_BEFORE,
	BETSHOP_SESSIONS_FINISH,
	BETSHOP_SET_BETSHOP_SESSIONS,
	BETSHOP_SET_SESSIONS_SORTING,
	BETSHOP_SET_SESSIONS_FILTERS,
	BETSHOP_SETTLEMENT_REPORT_BEFORE,
	BETSHOP_SETTLEMENT_REPORT_FINISH,
	BETSHOP_SET_SETTLEMENT_REPORT,
	BETSHOP_ACTIVITY_BEFORE,
	BETSHOP_ACTIVITY_FINISH,
	BETSHOP_SET_BETSHOP_ACTIVITY,
	BETSHOP_SET_ACTIVITY_SORTING,
	BETSHOP_SET_ACTIVITY_FILTERS,
	BETSHOP_WALLET_BEFORE,
	BETSHOP_WALLET_FINISH,
	BETSHOP_WALLET_SAVE_BEFORE,
	BETSHOP_WALLET_SAVE_FINISH,
	BETSHOP_SET_WALLET,
	BETSHOP_UPDATE_WALLET,
	SET_BETSHOP_UI,
	RESET_BETSHOP_UI
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case BETSHOPS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case BETSHOPS_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case BETSHOPS_ACTION_SET_BETSHOPS:
			return {
				...state,
				betshops: payload.data.item2,
				total: payload.data.item1
			};
		case BETSHOP_SAVE_ACTION_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case BETSHOP_SAVE_ACTION_FINISH:
			return {
				...state,
				isSaving: false
			};
		case BETSHOPS_ACTION_SET_BETSHOP_ENABLED:
			return {
				...state,
				betshops: state.betshops.map((b) => (b.id === payload.id ? { ...b, enabled: payload.enabled } : b))
			};
		case BETSHOPS_SET_FILTERS:
			return {
				...state,
				filters: payload.filters,
				sorting: {
					...state.sorting,
					page: 1
				}
			};
		case BETSHOPS_SET_SORTING:
			return {
				...state,
				sorting: payload.sorting
			};
		case SET_BETSHOP_GENERAL_INFO:
			return {
				...state,
				editingBetshop: {
					...state.editingBetshop,
					generalInfo: {
						...payload.info
					}
				}
			};
		case SET_BETSHOP_CURRENCY:
			return {
				...state,
				editingBetshop: {
					...state.editingBetshop,
					currency: payload.currency
				}
			};
		case BETSHOP_BOXES_BEFORE:
			return {
				...state,
				editingBetshop: {
					...state.editingBetshop,
					boxes: {
						...state.editingBetshop.boxes,
						isLoading: true
					}
				}
			};
		case BETSHOP_BOXES_FINISH:
			return {
				...state,
				editingBetshop: {
					...state.editingBetshop,
					boxes: {
						...state.editingBetshop.boxes,
						isLoading: false
					}
				}
			};
		case SET_BETSHOP_BOXES:
			return {
				...state,
				editingBetshop: {
					...state.editingBetshop,
					boxes: {
						...state.editingBetshop.boxes,
						boxes: payload.boxes
					}
				}
			};
		case ADD_BETSHOP_BOX:
			return {
				...state,
				editingBetshop: {
					...state.editingBetshop,
					boxes: {
						...state.editingBetshop.boxes,
						boxes: [...state.editingBetshop.boxes.boxes, payload.box]
					}
				}
			};
		case UPDATE_BETSHOP_BOX:
			return {
				...state,
				editingBetshop: {
					...state.editingBetshop,
					boxes: {
						...state.editingBetshop.boxes,
						boxes: state.editingBetshop.boxes.boxes.map((b) => (b.id === payload.box.id ? payload.box : { ...b }))
					}
				}
			};
		case DELETE_BETSHOP_BOX:
			return {
				...state,
				editingBetshop: {
					...state.editingBetshop,
					boxes: {
						...state.editingBetshop.boxes,
						boxes: state.editingBetshop.boxes.boxes.filter((b) => b.id !== payload.id)
					}
				}
			};
		case BETSHOP_AVAILABLE_BOXES_BEFORE:
			return {
				...state,
				editingBetshop: {
					...state.editingBetshop,
					boxes: {
						...state.editingBetshop.boxes,
						isAvailableBoxesLoading: true
					}
				}
			};
		case BETSHOP_AVAILABLE_BOXES_FINISH:
			return {
				...state,
				editingBetshop: {
					...state.editingBetshop,
					boxes: {
						...state.editingBetshop.boxes,
						isAvailableBoxesLoading: false
					}
				}
			};
		case SET_BETSHOP_AVAILABLE_BOXES:
			return {
				...state,
				editingBetshop: {
					...state.editingBetshop,
					boxes: {
						...state.editingBetshop.boxes,
						availableBoxes: payload.boxes
					}
				}
			};
		case SET_BETSHOP_OPERATING_HOURS:
			return {
				...state,
				editingBetshop: {
					...state.editingBetshop,
					operatingHours: payload.data
				}
			};
		case SET_BETSHOP_LIMIT_SETTINGS:
			return {
				...state,
				editingBetshop: {
					...state.editingBetshop,
					limitSettings: payload.data
				}
			};
		case SET_BETSHOP_BONUS_CONFIGS:
			return {
				...state,
				editingBetshop: {
					...state.editingBetshop,
					bonusConfigs: payload.data
				}
			};
		case SET_BETSHOP_UI: {
			return {
				...state,
				editingBetshop: {
					...state.editingBetshop,
					customization: {
						...state.editingBetshop.customization,
						ui: payload.data
					}
				}
			};
		}
		case RESET_BETSHOP_UI:
			return {
				...state,
				editingBetshop: {
					...state.editingBetshop,
					customization: {
						ui: {}
					}
				}
			};
		case SET_BETSHOP_LOGO_ID:
			return {
				...state,
				editingBetshop: {
					...state.editingBetshop,
					customization: {
						...state.editingBetshop.customization,
						ui: {
							...state.editingBetshop.customization.ui,
							logoId: payload.logoId
						}
					}
				}
			};
		case BETSHOP_ACTIVITY_BEFORE:
			return {
				...state,
				viewingBetshop: {
					...state.viewingBetshop,
					activity: {
						...state.viewingBetshop.activity,
						isLoading: true
					}
				}
			};
		case BETSHOP_ACTIVITY_FINISH:
			return {
				...state,
				viewingBetshop: {
					...state.viewingBetshop,
					activity: {
						...state.viewingBetshop.activity,
						isLoading: false
					}
				}
			};
		case BETSHOP_SET_BETSHOP_ACTIVITY:
			return {
				...state,
				viewingBetshop: {
					...state.viewingBetshop,
					activity: {
						...state.viewingBetshop.activity,
						activities: payload.data.item2,
						total: payload.data.item1
					}
				}
			};
		case BETSHOP_SET_ACTIVITY_SORTING:
			return {
				...state,
				viewingBetshop: {
					...state.viewingBetshop,
					activity: {
						...state.viewingBetshop.activity,
						sorting: payload.sorting
					}
				}
			};
		case BETSHOP_SET_ACTIVITY_FILTERS:
			return {
				...state,
				viewingBetshop: {
					...state.viewingBetshop,
					activity: {
						...state.viewingBetshop.activity,
						filters: payload.filters,
						sorting: {
							...state.viewingBetshop.activity.sorting,
							page: 1
						}
					}
				}
			};
		case BETSHOP_SESSIONS_BEFORE:
			return {
				...state,
				viewingBetshop: {
					...state.viewingBetshop,
					sessions: {
						...state.viewingBetshop.sessions,
						isLoading: true
					}
				}
			};
		case BETSHOP_SESSIONS_FINISH:
			return {
				...state,
				viewingBetshop: {
					...state.viewingBetshop,
					sessions: {
						...state.viewingBetshop.sessions,
						isLoading: false
					}
				}
			};
		case BETSHOP_SET_BETSHOP_SESSIONS:
			return {
				...state,
				viewingBetshop: {
					...state.viewingBetshop,
					sessions: {
						...state.viewingBetshop.sessions,
						sessions: payload.data.item2,
						total: payload.data.item1
					}
				}
			};
		case BETSHOP_SET_SESSIONS_SORTING:
			return {
				...state,
				viewingBetshop: {
					...state.viewingBetshop,
					sessions: {
						...state.viewingBetshop.sessions,
						sorting: payload.sorting
					}
				}
			};
		case BETSHOP_SET_SESSIONS_FILTERS:
			return {
				...state,
				viewingBetshop: {
					...state.viewingBetshop,
					sessions: {
						...state.viewingBetshop.sessions,
						filters: payload.filters,
						sorting: {
							...state.viewingBetshop.sessions.sorting,
							page: 1
						}
					}
				}
			};
		case BETSHOP_SETTLEMENT_REPORT_BEFORE:
			return {
				...state,
				viewingBetshop: {
					...state.viewingBetshop,
					sessions: {
						...state.viewingBetshop.sessions,
						currentSession: {
							...state.viewingBetshop.sessions.currentSession,
							isLoading: true
						}
					}
				}
			};
		case BETSHOP_SETTLEMENT_REPORT_FINISH:
			return {
				...state,
				viewingBetshop: {
					...state.viewingBetshop,
					sessions: {
						...state.viewingBetshop.sessions,
						currentSession: {
							...state.viewingBetshop.sessions.currentSession,
							isLoading: false
						}
					}
				}
			};
		case BETSHOP_SET_SETTLEMENT_REPORT:
			return {
				...state,
				viewingBetshop: {
					...state.viewingBetshop,
					sessions: {
						...state.viewingBetshop.sessions,
						currentSession: {
							...state.viewingBetshop.sessions.currentSession,
							settlementReport: payload.data
						}
					}
				}
			};
		case BETSHOP_WALLET_BEFORE:
			return {
				...state,
				wallet: {
					...state.wallet,
					isLoading: true
				}
			};
		case BETSHOP_WALLET_FINISH:
			return {
				...state,
				wallet: {
					...state.wallet,
					isLoading: false
				}
			};
		case BETSHOP_WALLET_SAVE_BEFORE:
			return {
				...state,
				wallet: {
					...state.wallet,
					isSaving: true
				}
			};
		case BETSHOP_WALLET_SAVE_FINISH:
			return {
				...state,
				wallet: {
					...state.wallet,
					isSaving: false
				}
			};
		case BETSHOP_SET_WALLET:
			return {
				...state,
				wallet: {
					...state.wallet,
					wallet: payload.wallet
				}
			};
		case BETSHOP_UPDATE_WALLET:
			return {
				...state,
				betshops: state.betshops.map((b) => (b.id === payload.id ? { ...b, balance: payload.balance } : b))
			};
		default:
			return state;
	}
};
