import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import SignalRUtils from "utils/signalR";

import Row from "antd/lib/row";
import Spin from "antd/lib/spin";
import Breadcrumbs from "components/common/breadcrumbs";
import StreamServersItem from "./streamServersItem";

import {
	getAllStreamServers,
	getAvailableStreamServerGames,
	setAllStreamServers,
} from "store/actions/dashboard/streamServers";

import { hasPermission } from "utils/permissions";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

const StreamServersComponent = ({
	streamServers,
	getAllStreamServers,
	getAvailableStreamServerGames,
	setAllStreamServers,
}) => {
	const [updatedStreamServer, setUpdatedStreamServer] = useState(null);

	const { t } = useTranslation();

	useEffect(() => {
		getAllStreamServers();

		const isHasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.DEVELOPMENT_STREAM_SERVERS, action: PERMISSION_ACTION.MODIFY });

		if (isHasModifyPermission) {
			getAvailableStreamServerGames();
		}
	}, []);

	/** Subscribe to singalR events */
	useEffect(() => {
		SignalRUtils.getConnections().forEach((connection, index) => {
			if ((index === 0) || (index === 2)) {
				connection.addHandler(() => {
					connection.getConnection().invoke("Subscribe", "StreamServer");
					connection.getConnection().off("StreamServer");
					connection.getConnection().on("StreamServer", (data) => {
						const streamServer = JSON.parse(data);

						setUpdatedStreamServer(streamServer);
					});
				});
			}
		});

		return () => {
			SignalRUtils.getConnections().forEach((connection) => {
				connection.getConnection().invoke("Unsubscribe", "StreamServer");
				connection.getConnection().off("StreamServer");
			});
		};
	}, []);

	useEffect(() => {
		if (updatedStreamServer) {
			const allStreamServers = [...streamServers.data];

			const updatedData = allStreamServers.map(server => server.id === updatedStreamServer.id ? ({ ...updatedStreamServer }) : server);

			setAllStreamServers({
				data: updatedData
			});
		}
	}, [updatedStreamServer])

	return (
		<div className="dashboard-section table-section">
			<Breadcrumbs
				items={[
					{
						title: t(
							"pages.dashboard.developerSpaceServerStreams.serverStreams"
						),
					},
				]}
			/>
			<Spin spinning={streamServers.isAllLoading} wrapperClassName="form-spin">
				<div className="dashboard-section-content">
					<Row gutter={[10, 20]} className="stream-servers-list-row">
						{streamServers.data.map((item, index) => {
							return (
								<StreamServersItem
									key={item.id}
									index={index}
									name={item.name}
									id={item.id}
									games={item.games}
									cpu={item.lastCounters.cpu}
									ram={item.lastCounters.ram}
									streamHealths={item.lastCounters.streamHealths}
									state={item.state}
								/>
							);
						})}
					</Row>
				</div>
			</Spin>
		</div>
	);
};

StreamServersComponent.propTypes = {
	//Redux state of stream servers data
	streamServers: PropTypes.object,
	// Redux state action for getting available games
	getAvailableStreamServerGames: PropTypes.func,
	// Redux state action to set stream servers data
};

const mapDispatchToProps = (dispatch) => ({
	getAllStreamServers: () => {
		dispatch(getAllStreamServers());
	},
	getAvailableStreamServerGames: () => {
		dispatch(getAvailableStreamServerGames());
	},
	setAllStreamServers: (data) => {
		dispatch(setAllStreamServers(data));
	},
});

const mapStateToProps = (state) => {
	return {
		streamServers: state.streamServers,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StreamServersComponent);