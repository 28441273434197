import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getProjectPerformanceReport, setProjectPerformanceReportFilters } from "store/actions/dashboard/reports/projectPerformance/projectPerformance.action";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import Table from "components/common/table";
import Breadcrumbs from "components/common/breadcrumbs";
import Filters from "./filters.component";
import ExportButton from "components/common/exportButton";
import { convertCurrencies, getPartnerCurrency, getPartnerCurrencyDecimalCount, canConvertCurrencies } from "utils/currency";
import { numberWithSpaces } from "utils/common";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import Paths from "constants/path.constants";
import ApiUrls from "constants/api.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import projectPerformanceReportType from "types/reports/projectPerformanceReport.type";
import partnerType from "types/partner/partner.type";
import currencyType from "types/common/currency.type";

/** Project Performance Report Page Component */

const ProjectPerformanceReportComponent = ({ getProjectPerformanceReport, projectPerformanceReport, setProjectPerformanceReportFilters, isLoading, filters, globalPartnerId, globalPartners, currency, currencies }) => {
	const { t } = useTranslation();

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.reports.partner"),
			dataIndex: "totalCol",
			render: (value, record) =>
				value === "total"
					? t("pages.dashboard.reports.project_total")
					: (globalPartners.find((p) => p.id === globalPartnerId))?.name,
			sorter: false
		},
		{
			title: t("pages.dashboard.reports.project"),
			dataIndex: "name",
			sorter: false
		},
		{
			title: t("pages.dashboard.reports.from"),
			dataIndex: "from",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : ""),
			sorter: false
		},
		{
			title: t("pages.dashboard.reports.to"),
			dataIndex: "to",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : ""),
			sorter: false
		},
		{
			title: t("pages.dashboard.reports.currency"),
			dataIndex: "currencyCode",
			sorter: false
		},

		{
			title: t("pages.dashboard.reports.ggr") + "%",
			dataIndex: "ggrPercent",
			sorter: false
		},
		{
			title: t("pages.dashboard.reports.ggr"),
			dataIndex: "ggr",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.turnover"),
			dataIndex: "turnoverAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.win_amount"),
			dataIndex: "wonAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.cancelled_amount"),
			dataIndex: "canceledAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.betslips"),
			dataIndex: "betCount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.betslips_won"),
			dataIndex: "wonCount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.betslips_cancelled"),
			dataIndex: "canceledCount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.bonus_bet_amount"),
			dataIndex: "bonusTurnoverAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.bonus_win"),
			dataIndex: "bonusWonAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.bonus_ggr"),
			dataIndex: "bonusGGR",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.bonus_bet_count"),
			dataIndex: "bonusBetCount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.bonus_redeem_amount"),
			dataIndex: "bonusRedeemAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		}
	];

	/** Get total values of each column in table
	 * @function
	 * @memberOf ProjectPerformanceReportComponent
	 * @returns {object} - total values
	 */
	const getTotalData = () => {
		if (projectPerformanceReport.length < 2) return null;

		const totalData = {};
		totalData["totalCol"] = "total";
		totalData["from"] = "";
		totalData["to"] = "";
		totalData["name"] = "";
		totalData["currencyCode"] = getPartnerCurrency(currency.code, currencies, globalPartnerId);

		totalData["uniqueKey"] = `total | ${currency.code}`;

		let canCountTotal = true;
		projectPerformanceReport.forEach((p) => {
			if (!canConvertCurrencies(p.currencyCode, currency.code, currencies, globalPartnerId)) {
				canCountTotal = false;
			}
		});

		if (canCountTotal) {
			totalData["ggr"] = projectPerformanceReport
				.map((p) => Number(convertCurrencies(p.ggr, p.currencyCode, currency.code, currencies, globalPartnerId)))
				.reduce((a, b) => a + b, 0)
				.toFixed(getPartnerCurrencyDecimalCount(currency.code, currencies, globalPartnerId));
			totalData["turnoverAmount"] = projectPerformanceReport
				.map((p) => Number(convertCurrencies(p.turnoverAmount, p.currencyCode, currency.code, currencies, globalPartnerId)))
				.reduce((a, b) => a + b, 0)
				.toFixed(getPartnerCurrencyDecimalCount(currency.code, currencies, globalPartnerId));
			totalData["wonAmount"] = projectPerformanceReport
				.map((p) => Number(convertCurrencies(p.wonAmount, p.currencyCode, currency.code, currencies, globalPartnerId)))
				.reduce((a, b) => a + b, 0)
				.toFixed(getPartnerCurrencyDecimalCount(currency.code, currencies, globalPartnerId));
			totalData["canceledAmount"] = projectPerformanceReport
				.map((p) => Number(convertCurrencies(p.canceledAmount, p.currencyCode, currency.code, currencies, globalPartnerId)))
				.reduce((a, b) => a + b, 0)
				.toFixed(getPartnerCurrencyDecimalCount(currency.code, currencies, globalPartnerId));

			totalData["bonusTurnoverAmount"] = projectPerformanceReport
				.map((p) => Number(convertCurrencies(p.bonusTurnoverAmount, p.currencyCode, currency.code, currencies, globalPartnerId)))
				.reduce((a, b) => a + b, 0)
				.toFixed(getPartnerCurrencyDecimalCount(currency.code, currencies, globalPartnerId));

			totalData["bonusWonAmount"] = projectPerformanceReport
				.map((p) => Number(convertCurrencies(p.bonusWonAmount, p.currencyCode, currency.code, currencies, globalPartnerId)))
				.reduce((a, b) => a + b, 0)
				.toFixed(getPartnerCurrencyDecimalCount(currency.code, currencies, globalPartnerId));

			totalData["bonusRedeemAmount"] = projectPerformanceReport
				.map((p) => Number(convertCurrencies(p.bonusRedeemAmount, p.currencyCode, currency.code, currencies, globalPartnerId)))
				.reduce((a, b) => a + b, 0)
				.toFixed(getPartnerCurrencyDecimalCount(currency.code, currencies, globalPartnerId));

			totalData["bonusGGR"] = projectPerformanceReport
				.map((p) => Number(convertCurrencies(p.bonusGGR, p.currencyCode, currency.code, currencies, globalPartnerId)))
				.reduce((a, b) => a + b, 0)
				.toFixed(getPartnerCurrencyDecimalCount(currency.code, currencies, globalPartnerId));
			totalData["ggrPercent"] = "";
			//totalData["ggrPercent"] = totalData["ggr"]/(totalData["turnoverAmount"] - totalData["canceledAmount"]) * 100
		} else {
			totalData["ggr"] = totalData["turnoverAmount"] = totalData["wonAmount"] = totalData["canceledAmount"] = totalData["ggrPercent"] = "";
		}

		totalData["betCount"] = projectPerformanceReport.map((p) => p.betCount).reduce((a, b) => Number(a) + Number(b), 0);
		totalData["wonCount"] = projectPerformanceReport.map((p) => p.wonCount).reduce((a, b) => Number(a) + Number(b), 0);
		totalData["canceledCount"] = projectPerformanceReport.map((p) => p.canceledCount).reduce((a, b) => Number(a) + Number(b), 0);
		totalData["bonusBetCount"] = projectPerformanceReport.map((p) => p.bonusBetCount).reduce((a, b) => Number(a) + Number(b), 0);
		return totalData;
	};

	return (
		<div className="dashboard-section">
			<Breadcrumbs items={[{ title: t("pages.dashboard.menu.projects_performance") }]} />
			<div className="dashboard-section-content">
				<div className="table-header">
					<div className="table-buttons-dropdowns">
						{hasPermission({ resource: PERMISSION_RESOURCE.REPORTS_PROJECT_PERFORMANCE, action: PERMISSION_ACTION.EXPORT }) ? (
							<ExportButton
								columns={columns.map((c) => ({
									title: c.title,
									key: c.dataIndex
								}))}
								tableName={t("pages.dashboard.menu.projects_performance")}
								url={`${ApiUrls.EXPORT_REPORT_PROJECT_PERFORMANCE}?projectType=${PROJECT_TYPE.ONLINE}`}
								filters={filters}
							/>
						) : null}
					</div>
					<Filters />
				</div>
				<div className="table-info">
					<i className="icon-info" />
					<span>Currencies are converted based on the latest rate of the month, so please consider fluctuation in case other rates are applied for Requested Reports by Support.</span>
				</div>
				<Table
					loading={isLoading}
					columns={columns}
					data={projectPerformanceReport.concat(getTotalData() ? [getTotalData()] : [])}
					loadFn={getProjectPerformanceReport}
					filters={filters}
					setFiltersFn={setProjectPerformanceReportFilters}
					noPagination={true}
					updateProps={[globalPartnerId]}
					actions={{}}
					isDisabled={() => false}
					uniqueKey="uniqueKey"
					enableReload={true}
				/>
			</div>
		</div>
	);
};

/** ProjectPerformanceReportComponent propTypes
 * PropTypes
 */
ProjectPerformanceReportComponent.propTypes = {
	/** Redux action to get project performance report */
	getProjectPerformanceReport: PropTypes.func,
	/** Redux state property, represents the project performance report */
	projectPerformanceReport: PropTypes.arrayOf(projectPerformanceReportType),
	/** Redux action to set betshop performance report filters */
	setProjectPerformanceReportFilters: PropTypes.func,
	/** Redux state property, project performance report filters */
	filters: PropTypes.object,
	/** Redux state property, is true when loading project performance report */
	isLoading: PropTypes.bool,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, represents the array of global partners  */
	globalPartners: PropTypes.arrayOf(partnerType),
	/** Redux state property, the current user currency */
	currency: currencyType,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType)
};

const mapDispatchToProps = (dispatch) => ({
	getProjectPerformanceReport: () => {
		dispatch(getProjectPerformanceReport());
	},

	setProjectPerformanceReportFilters: (filters) => {
		dispatch(setProjectPerformanceReportFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.reports.projectPerformance.isLoading,
		projectPerformanceReport: state.reports.projectPerformance.projectPerformanceReport,
		filters: state.reports.projectPerformance.filters,
		globalPartnerId: state.partner.globalPartnerId,
		globalPartners: state.partner.globalPartners,
		currencies: state.profile.userInfo.currencies,
		currency: state.profile.userInfo.currency
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPerformanceReportComponent);
