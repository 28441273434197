import { REQUESTS_ACTION_BEFORE, REQUESTS_ACTION_FINISH, REQUEST_ACTION_BEFORE, REQUEST_ACTION_FINISH, SET_REQUESTS, SET_REQUESTS_SORTING, SET_REQUESTS_FILTERS, SET_REQUEST } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case REQUESTS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case REQUESTS_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case REQUEST_ACTION_BEFORE:
			return {
				...state,
				isRequestLoading: true
			};
		case REQUEST_ACTION_FINISH:
			return {
				...state,
				isRequestLoading: false
			};
		case SET_REQUESTS:
			return {
				...state,
				requests: payload.requests.item2,
				total: payload.requests.item1
			};
		case SET_REQUESTS_SORTING:
			return {
				...state,
				sorting: payload.sorting
			};
		case SET_REQUESTS_FILTERS:
			return {
				...state,
				filters: payload.filters,
				sorting: {
					...state.sorting,
					page: 1
				}
			};
		case SET_REQUEST:
			return {
				...state,
				requests: state.requests.map((r) => (r.id === payload.request.id ? { ...r, details: payload.request } : r))
			};
		default:
			return state;
	}
};
