import { PERMISSIONS_ACTION_BEFORE, PERMISSIONS_ACTION_FINISH, PERMISSIONS_ACTION_SET_PERMISSIONS, PERMISSION_RESOURCES_ACTION_BEFORE, PERMISSION_RESOURCES_ACTION_FINISH, PERMISSIONS_ACTION_SET_RESOURCES } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case PERMISSIONS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case PERMISSIONS_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case PERMISSIONS_ACTION_SET_PERMISSIONS:
			return {
				...state,
				permissions: payload.permissions
			};
		case PERMISSION_RESOURCES_ACTION_BEFORE:
			return {
				...state,
				isResourcesLoading: true
			};
		case PERMISSION_RESOURCES_ACTION_FINISH:
			return {
				...state,
				isResourcesLoading: false
			};
		case PERMISSIONS_ACTION_SET_RESOURCES:
			return {
				...state,
				resources: payload.resources
			};
		default:
			return state;
	}
};
