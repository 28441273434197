import { Button, Col, Form, Row, Select, Spin } from "antd";
import PropTypes from "prop-types";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";
import { connect } from "react-redux";
import { RISK_MANAGEMENT_ACTION_TYPE_TRANSLATIONS, RISK_MANAGEMENT_ACTION_TYPES } from "constants/riskManagement.constants";
import { getBigMultipliersConfigurationsData, saveBigMultipliersConfigurationsData } from "store/actions/dashboard/riskManagment/bigMultipliers/bigMultipliers.actions";

import NumericInput from "components/common/numericInput";
import SearchableSelect from "components/common/searchableSelect";

import useUpdateEffect from "hooks/useUpdateEffect";
import { EMAIL_REGEX } from "constants/regex.constants";

const { Item: FormItem } = Form;

const BigMultipliersConfigurations = ({
	isSaving = false,
	isLoading = false,
	configurations,
	onTabChange = Function.prototype,
	getBigMultipliersConfigurationsData = Function.prototype,
	saveBigMultipliersConfigurationsData = Function.prototype
}) => {

	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);

	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BIG_MULTIPLIERS_CONFIGURATIONS, action: PERMISSION_ACTION.MODIFY });

	const reset = () => {
		setFieldsValue({
			...configurations,
			emails: configurations.emails ?? []
		});
		setIsFormTouched(false);
	};

	const handleForm = () => {
		validateFields()
			.then((data) => {
				const emails = (
					data.actionType === RISK_MANAGEMENT_ACTION_TYPES.NONE
						? (data.emails?.filter((email) => EMAIL_REGEX.test(email)) ?? [])
						: data.emails
				);

				saveBigMultipliersConfigurationsData({
					...data,
					minBetAmount: Number(data.minBetAmount) || 0,
					minMultiplier: Number(data.minMultiplier) || 0,
					emails: emails?.length > 0 ? emails : null
				});
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	/** Load partner retail configs */
	useEffect(() => {
		getBigMultipliersConfigurationsData();
	}, []);

	useUpdateEffect(() => {
		reset();
	}, [configurations]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					minBetAmount: "",
					minMultiplier: "",
					actionType: "",
					emails: []
				}}
				onValuesChange={(changed, formValues) => {
					if ("actionType" in changed) {
						validateFields(["emails"]);
					}
					setIsFormTouched(isFormChanged({ ...formValues }, { ...configurations }));
				}}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h4>{t("pages.dashboard.risk_management.big_multipliers_configurations_configuration_details")}</h4>
								<Row gutter={[16, 0]}>
									<Col xs={24} sm={24} md={8} lg={8} xl={6}>
										<FormItem
											name="minBetAmount"
											label={t("pages.dashboard.risk_management.big_multipliers_configurations_configuration_detail_minBetAmount")}
											rules={[
												{
													validator: (_, values) => {
														const num = Number(values) ?? 0;
														if (num <= 0) {
															return Promise.reject(t("validation.must_be_more_and_not_equal_than_other").replace("%X%", t("pages.dashboard.risk_management.big_multipliers_configurations_configuration_detail_minBetAmount")).replace("%Y%", 0));
														}
														return Promise.resolve();
													}
												}
											]}
										>
											<NumericInput disabled={isDisabled} placeholder={`${t("common.enter")} ${t("pages.dashboard.risk_management.big_multipliers_configurations_configuration_detail_minBetAmount")}`} />
										</FormItem>
									</Col>
									<Col xs={24} sm={24} md={8} lg={8} xl={6}>
										<FormItem
											name="minMultiplier"
											label={t("pages.dashboard.risk_management.big_multipliers_configurations_configuration_detail_minMultiplier")}
											rules={[
												{
													validator: (_, values) => {
														const num = Number(values) ?? 0;
														if (num < 1.01 || num > 10000) {
															return Promise.reject(t("validation.must_be_between").replace("%X%", 1.01).replace("%Y%", 10000));
														}
														return Promise.resolve();
													}
												}
											]}
										>
											<NumericInput disabled={isDisabled} placeholder={`${t("common.enter")} ${t("pages.dashboard.risk_management.big_multipliers_configurations_configuration_detail_minMultiplier")}`} />
										</FormItem>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h4>{t("pages.dashboard.risk_management.big_multipliers_configurations_actions")}</h4>
								<Row gutter={[16, 0]}>
									<Col xs={24} sm={24} md={8} lg={8} xl={6}>
										<FormItem name="actionType" label={t("pages.dashboard.risk_management.big_multipliers_configurations_configuration_actions_actionType")}>
											<Select
												suffixIcon={<i className="icon-down" />}
												options={Object.values(RISK_MANAGEMENT_ACTION_TYPES).map((actionType) => ({
													value: actionType,
													label: t(RISK_MANAGEMENT_ACTION_TYPE_TRANSLATIONS[actionType])
												}))}
												disabled={isDisabled}
											/>
										</FormItem>
									</Col>
									<FormItem dependencies={["actionType"]} noStyle>
										{({ getFieldValue }) => {
											const actionType = getFieldValue("actionType");
											return (
												<Col xs={24} sm={24} md={8} lg={8} xl={6}>
													<FormItem
														name="emails"
														label={t("pages.dashboard.risk_management.big_multipliers_configurations_configuration_actions_emails")}
														rules={[
															{
																validator: (_, values) => {
																	if (actionType === RISK_MANAGEMENT_ACTION_TYPES.NONE) {
																		return Promise.resolve();
																	}
																	if (!Array.isArray(values) || values.length === 0) {
																		return Promise.reject(t("validation.field_cannot_be_blank").replace("%X%", t("pages.dashboard.risk_management.big_multipliers_configurations_configuration_actions_emails")));
																	}
																	return Promise.resolve();
																}
															},
															{
																validator: (_, values) => {
																	if (Array.isArray(values) && values.length > 10) {
																		return Promise.reject(t("validation.must_be_less").replace("%X%", 10));
																	}
																	return Promise.resolve();
																}
															},
															{
																validator: (_, values) => {
																	if (actionType === RISK_MANAGEMENT_ACTION_TYPES.NONE) {
																		return Promise.resolve();
																	}
																	let invalidValue = null;
																	const invalidValueExists = values.some((value) => {
																		if (!EMAIL_REGEX.test(value)) {
																			invalidValue = value;
																			return true;
																		}
																		return false;
																	});
																	if (invalidValueExists) {
																		return Promise.reject(`${invalidValue} ${t("validation.email_format")}`);
																	}
																	return Promise.resolve();
																}
															}
														]}
													>
														<SearchableSelect
															disabled={isDisabled || actionType === RISK_MANAGEMENT_ACTION_TYPES.NONE}
															mode="tags"
															placeholder={t("pages.dashboard.risk_management.big_multipliers_configurations_configuration_actions_emails")}
															items={configurations.emails ?? []}
															valueProp={(item) => item}
															textProp={(item) => item}
															renderText={(item) => item}
															getPopupContainer={() => document.body}
														/>
													</FormItem>
												</Col>
											);
										}}
									</FormItem>
								</Row>
							</Col>
						</Row>
					</div>
					<Row justify="end">
						{!isDisabled && (
							<FormItem className="button-container">
								<Button loading={isSaving} type="default" htmlType="submit" className="button" onClick={reset} disabled={!isFormTouched}>
									<span>{t("common.cancel")}</span>
								</Button>
							</FormItem>
						)}
						{!isDisabled && (
							<FormItem className="button-container">
								<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
									<span>{t("common.save")}</span>
								</Button>
							</FormItem>
						)}
					</Row>
				</div>
			</Form>
		</Spin>
	);
};

BigMultipliersConfigurations.propTypes = {
	isSaving: PropTypes.bool,
	isLoading: PropTypes.bool,
	configurations: PropTypes.shape({
		minBetAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		minMultiplier: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		actionType: PropTypes.oneOf(Object.values(RISK_MANAGEMENT_ACTION_TYPES)),
		emails: PropTypes.arrayOf(PropTypes.string)
	}),
	onTabChange: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isSaving: state.riskManagement.bigMultipliers.configurations.isSaving,
		isLoading: state.riskManagement.bigMultipliers.configurations.isLoading,
		configurations: state.riskManagement.bigMultipliers.configurations.data
	};
};

const mapDispatchToProps = (dispatch) => ({
	getBigMultipliersConfigurationsData: () => {
		dispatch(getBigMultipliersConfigurationsData());
	},
	saveBigMultipliersConfigurationsData: (data) => {
		dispatch(saveBigMultipliersConfigurationsData(data));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BigMultipliersConfigurations);
