import PropTypes from "prop-types";

export default PropTypes.shape({
	id: PropTypes.string,
	userName: PropTypes.string,
	createTime: PropTypes.string,
	endTime: PropTypes.string,
	ipAddress: PropTypes.string,
	countryCode: PropTypes.string,
	deviceType: PropTypes.string
});
