import { Fragment, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Form, Row, Col, Select, DatePicker } from 'antd';
import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";

import { getJackpots, setJackpotBonusesFilters } from 'store/actions/dashboard/bonuses/bonuses.action';
import useAutosuggestion from 'hooks/useAutosuggestion';
import { AUTOSUGGESTION_TYPE } from 'constants/autoSuggestion.constants';
import { FILTER_GAME_SPECIFICATION_TYPE, FILTER_JACKPOT_BONUS_STATE, FILTER_JACKPOT_BONUS_TYPE } from "constants/filter.constants";
import { JACKPOT_BONUS_STATES_TRANSLATION_TEXTS, JACKPOTS_TYPES, JACKPOT_BONUS_TYPE_TRANSLATION_TEXTS } from "constants/bonus.constants";
import { DATE_FORMAT } from 'constants/date.constants';
const { Item: FormItem } = Form;

const getGlobalPartnerId = (state) => state.partner.globalPartnerId;
const getJackpotBonusesFilters = (state) => state.bonuses.jackpots.filters;

const FORM_FIELDS = {
	NAME_OR_ID: "nameOrId",
	NETWORK: "specificationType",
	JACKPOT_TYPE: "type",
	STATE: "state",
	CREATION_DATE: "date"
}

/** Jackpots Page Filters Component */
const Filters = () => {
	const { t } = useTranslation();

	const globalPartnerId = useSelector(getGlobalPartnerId);
	const filters = useSelector(getJackpotBonusesFilters);

	const dispatch = useDispatch();

	const getJackpotBonuses = useCallback((fromFirstPage) => dispatch(getJackpots(fromFirstPage)), [dispatch]);
	const setFilters = useCallback((params) => dispatch(setJackpotBonusesFilters(params)), [dispatch]);

	const jackpotNames = useAutosuggestion(
		AUTOSUGGESTION_TYPE.JACKPOT_NAMES,
		[globalPartnerId],
		{ disableAction: () => !globalPartnerId }
	)

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [
				{ name: "date", keepTime: false }
			]
		};
	}, []);

	/** Function to get filters list
		* @function
		* @returns {array} - filters list
		* @memberOf Filters
  */
	const filtersList = () => [
		{
			name: FORM_FIELDS.NAME_OR_ID,
			title: t('pages.dashboard.bonuses.nameOrId')
		},
		{
			name: FORM_FIELDS.NETWORK,
			title: t('pages.dashboard.bonuses.network_type'),
			values: [
				{ title: t('common.all'), value: FILTER_GAME_SPECIFICATION_TYPE.ALL },
				{ title: t('pages.dashboard.bonuses.partner_specific'), value: FILTER_GAME_SPECIFICATION_TYPE.PARTNER_SPECIFIC },
				{ title: t('pages.dashboard.bonuses.generic'), value: FILTER_GAME_SPECIFICATION_TYPE.GENERIC }
			]
		},
		{
			name: FORM_FIELDS.JACKPOT_TYPE,
			title: t('pages.dashboard.bonuses.jackpot_type'),
			values: [
				{ title: t('common.all'), value: FILTER_JACKPOT_BONUS_TYPE.ALL },
				{ title: t(JACKPOT_BONUS_TYPE_TRANSLATION_TEXTS[JACKPOTS_TYPES.ONLINE]), value: FILTER_JACKPOT_BONUS_TYPE.ONLINE },
				{ title: t(JACKPOT_BONUS_TYPE_TRANSLATION_TEXTS[JACKPOTS_TYPES.RETAIL]), value: FILTER_JACKPOT_BONUS_TYPE.RETAIL }
			]
		},
		{ name: FORM_FIELDS.CREATION_DATE, title: t('pages.dashboard.bonuses.creationDate') }
	];

	return (
		<FiltersWrapper
			filtersName="jackpotBonusHistory"
			loadFn={getJackpotBonuses}
			setFiltersFn={setFilters}
			filters={filters}
			formFieldsConfigs={formFieldsConfigs}
			filtersList={filtersList()}
		>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem
							label={t('pages.dashboard.bonuses.nameOrId')}
							name={FORM_FIELDS.NAME_OR_ID}
						>
							<AutoComplete
								placeholder={`${t('common.start_typing')} ${t('pages.dashboard.bonuses.nameOrId')}`}
								items={jackpotNames.map(k => k.value)}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem
							label={t('pages.dashboard.bonuses.network_type')}
							name={FORM_FIELDS.NETWORK}
						>
							<Select
								suffixIcon={(<i className="icon-down" />)}
							>
								<Select.Option value={FILTER_GAME_SPECIFICATION_TYPE.ALL}>{t('common.all')}</Select.Option>
								<Select.Option value={FILTER_GAME_SPECIFICATION_TYPE.PARTNER_SPECIFIC}>
									{t('pages.dashboard.bonuses.partner_specific')}
								</Select.Option>
								<Select.Option value={FILTER_GAME_SPECIFICATION_TYPE.GENERIC}>
									{t('pages.dashboard.bonuses.generic')}
								</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem
							label={t('pages.dashboard.bonuses.jackpot_type')}
							name={FORM_FIELDS.JACKPOT_TYPE}
						>
							<Select
								suffixIcon={(<i className="icon-down" />)}
							>
								<Select.Option value={FILTER_JACKPOT_BONUS_TYPE.ALL}>{t('common.all')}</Select.Option>
								<Select.Option value={FILTER_JACKPOT_BONUS_TYPE.ONLINE}>
									{t(JACKPOT_BONUS_TYPE_TRANSLATION_TEXTS[JACKPOTS_TYPES.ONLINE])}
								</Select.Option>
								<Select.Option value={FILTER_JACKPOT_BONUS_TYPE.RETAIL}>
									{t(JACKPOT_BONUS_TYPE_TRANSLATION_TEXTS[JACKPOTS_TYPES.RETAIL])}
								</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem
							label={t('pages.dashboard.bonuses.status')}
							name={FORM_FIELDS.STATE}
						>
							<Select
								suffixIcon={(<i className="icon-down" />)}
							>
								<Select.Option value={FILTER_JACKPOT_BONUS_STATE.ALL}>{t('common.all')}</Select.Option>
								<Select.Option value={FILTER_JACKPOT_BONUS_STATE.INACTIVE}>
									{t(JACKPOT_BONUS_STATES_TRANSLATION_TEXTS[FILTER_JACKPOT_BONUS_STATE.INACTIVE])}
								</Select.Option>
								<Select.Option value={FILTER_JACKPOT_BONUS_STATE.ACTIVE}>
									{t(JACKPOT_BONUS_STATES_TRANSLATION_TEXTS[FILTER_JACKPOT_BONUS_STATE.ACTIVE])}
								</Select.Option>
								<Select.Option value={FILTER_JACKPOT_BONUS_STATE.PAUSED}>
									{t(JACKPOT_BONUS_STATES_TRANSLATION_TEXTS[FILTER_JACKPOT_BONUS_STATE.PAUSED])}
								</Select.Option>
								<Select.Option value={FILTER_JACKPOT_BONUS_STATE.FINISHED}>
									{t(JACKPOT_BONUS_STATES_TRANSLATION_TEXTS[FILTER_JACKPOT_BONUS_STATE.FINISHED])}
								</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem
							label={t('pages.dashboard.bonuses.creationDate')}
							name={FORM_FIELDS.CREATION_DATE}
						>
							<DatePicker.RangePicker
								placeholder={[t('common.from'), t('common.to')]}
								format={DATE_FORMAT}
								disabledDate={d => !d || d.isAfter(moment().endOf('day'))}
							/>
						</FormItem>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	)
}

export default Filters;