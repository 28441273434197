import { useEffect, useState, Fragment } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Form, Row, Col, Button, Switch, Spin, Select, Tooltip, TimePicker } from "antd";
const { Item: FormItem } = Form;

import SearchableSelect from "components/common/searchableSelect";
import NumericInput from "components/common/numericInput";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { getBetshopLimitSettings, saveBetshopLimitSettings } from "store/actions/dashboard/retail/betshops/limitSettings.action";
import { getSystemTimeZones } from "store/actions/system/system.action";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import limitSettingsType from "types/betshop/limitSettings.type";
import betshopGeneralInfoType from "types/betshop/generalInfo.type";

import { BETSHOP_LIMIT_FREQUENCY } from 'constants/betshop.constants';
import { numberTransform } from 'utils/common';

/** Betshop Edit Page Operating Hours Tab Component */
const LimitSettingsComponent = ({ isSaving, isLoading, limitSettings, getBetshopLimitSettings, saveBetshopLimitSettings, getSystemTimeZones, timezones, generalInfo, onTabChange }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue, isFieldTouched } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [isLimitAutoAdjustmentEnabled, setIsLimitAutoAdjustmentEnabled] = useState(false);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_LIMIT_SETTINGS, action: PERMISSION_ACTION.MODIFY });

	/** Load system timezones */
	useEffect(() => {
		if (timezones.length === 0) {
			getSystemTimeZones();
		}
	}, []);

	/** Load betshop limit settings */
	useEffect(() => {
		getBetshopLimitSettings(routeParams.id);
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			...limitSettings,
			frequencySettings: {
				...limitSettings.frequencySettings,
				at: moment(`${limitSettings.frequencySettings.at.hour}:${limitSettings.frequencySettings.at.minute}`, "HH:mm")
			}
		});

		setIsLimitAutoAdjustmentEnabled(limitSettings.enabled);
	}, [limitSettings]);

	/** Fires when form submitted
	 * @function
	 * @memberOf LimitSettingsComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				saveBetshopLimitSettings({
					id: routeParams.id,
					enabled: data.enabled,
					fixedAmount: Number(data.fixedAmount),
					frequencySettings: {
						every: data.frequencySettings.every,
						at: {
							hour: data.frequencySettings.at.hour(),
							minute: data.frequencySettings.at.minutes()
						}
					}
				});
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					enabled: limitSettings.enabled,
					fixedAmount: Number(limitSettings.fixedAmount),
					frequencySettings: {
						...limitSettings.frequencySettings,
						at: moment(`${limitSettings.frequencySettings.at.hour}:${limitSettings.frequencySettings.at.minute}`, "HH:mm")
					}
				}}
				onValuesChange={(changed, formValues) =>
					setIsFormTouched(
						isFormChanged(
							{
								...formValues,
								frequencySettings: {
									...formValues.frequencySettings,
									at: {
										hour: formValues.frequencySettings.at.hour(),
										minute: formValues.frequencySettings.at.minutes()
									}
								}
							},
							{ ...limitSettings }
						)
					)
				}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<div className="inline-form-item">
									<label>{t("pages.dashboard.betshops.limit_auto_adjustment")}</label>
									<FormItem className="inline-form-item-control" name="enabled" valuePropName="checked">
										<Switch
											disabled={isDisabled}
											onChange={(e) => {
												setIsLimitAutoAdjustmentEnabled(e);
												setTimeout(() => {
													if (isFormTouched) validateFields(["fixedAmount"]);
												}, 0);
											}}
										/>
									</FormItem>
								</div>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<FormItem
									className="inline-form-item-with-margin"
									label={
										<Fragment>
											<span>{t("pages.dashboard.betshops.fixed_limit_amount")}</span>
											<Tooltip title={<span dangerouslySetInnerHTML={{ __html: t("pages.dashboard.betshops.fixed_limit_amount_tooltip") }}></span>}>
												<i className="icon-info" style={{ fontSize: "24px" }} />
											</Tooltip>
										</Fragment>
									}
									name="fixedAmount"
									rules={[
										{ type: "number", message: t('validation.field_invalid'), transform: numberTransform },
										({ getFieldValue }) => ({
											validator(rule, value) {
												if (!value && value !== 0 && getFieldValue("enabled")) {
													return Promise.reject(t("validation.field_required"));
												}

												if (Number(value) < 1 && getFieldValue("enabled")) {
													return Promise.reject(t("validation.must_be_more").replace("%X%", 1));
												}

												return Promise.resolve();
											}
										})
									]}
									validateFirst
								>
									<NumericInput placeholder={`${t("common.enter")} ${t("pages.dashboard.betshops.fixed_limit_amount")}`} disabled={isDisabled || !isLimitAutoAdjustmentEnabled} suffix={generalInfo.currencyId} />
								</FormItem>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h1 className="dashboard-section-name">{t("pages.dashboard.betshops.frequency")}</h1>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.betshops.every")} name={["frequencySettings", "every"]}>
									<Select disabled={isDisabled || !isLimitAutoAdjustmentEnabled} suffixIcon={<i className="icon-down" />}>
										<Select.Option value={BETSHOP_LIMIT_FREQUENCY.DAY}>{t("common.day")}</Select.Option>
										<Select.Option value={BETSHOP_LIMIT_FREQUENCY.MONDAY}>{t("weekDays.monday")}</Select.Option>
										<Select.Option value={BETSHOP_LIMIT_FREQUENCY.TUESDAY}>{t("weekDays.tuesday")}</Select.Option>
										<Select.Option value={BETSHOP_LIMIT_FREQUENCY.WEDNESDAY}>{t("weekDays.wednesday")}</Select.Option>
										<Select.Option value={BETSHOP_LIMIT_FREQUENCY.THURSDAY}>{t("weekDays.thursday")}</Select.Option>
										<Select.Option value={BETSHOP_LIMIT_FREQUENCY.FRIDAY}>{t("weekDays.friday")}</Select.Option>
										<Select.Option value={BETSHOP_LIMIT_FREQUENCY.SATURDAY}>{t("weekDays.saturday")}</Select.Option>
										<Select.Option value={BETSHOP_LIMIT_FREQUENCY.SUNDAY}>{t("weekDays.sunday")}</Select.Option>
									</Select>
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem className="form-timepicker" label={t("pages.dashboard.betshops.at")} name={["frequencySettings", "at"]}>
									<TimePicker format="HH:mm" allowClear={false} getPopupContainer={(trigger) => trigger.parentNode} disabled={isDisabled || !isLimitAutoAdjustmentEnabled} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.betshops.timezone")} name={["frequencySettings", "timeZoneId"]}>
									<SearchableSelect items={timezones} valueProp={(item) => item.id} textProp={(item) => item.description} renderText={(item) => item.description} placeholder={t("pages.dashboard.betshops.select_timezone")} getPopupContainer={() => document.body} disabled={true} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.betshops.daylight_saving_time")} name={["frequencySettings", "dst"]}>
									<Select disabled={true} suffixIcon={<i className="icon-down" />}>
										<Select.Option value="-1">-1</Select.Option>
										<Select.Option value="0">0</Select.Option>
										<Select.Option value="1">1</Select.Option>
									</Select>
								</FormItem>
							</Col>
						</Row>
					</div>
					{!isDisabled && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** LimitSettingsComponent propTypes
 * PropTypes
 */
LimitSettingsComponent.propTypes = {
	/** Redux state property, is true when limit settings is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when limit settings is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the limit settings of current editing betshop  */
	limitSettings: limitSettingsType,
	/** Redux action to save betshop limit settings */
	saveBetshopLimitSettings: PropTypes.func,
	/** Redux action to get betshop limit settings */
	getBetshopLimitSettings: PropTypes.func,
	/** Redux action to get system timezones */
	getSystemTimeZones: PropTypes.func,
	/** Redux state property, represents the array of system timezones  */
	timezones: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			description: PropTypes.string
		})
	),
	/** Redux state, represents the general info of current editing betshop  */
	generalInfo: betshopGeneralInfoType,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getBetshopLimitSettings: (id) => {
		dispatch(getBetshopLimitSettings(id));
	},

	saveBetshopLimitSettings: (data) => {
		dispatch(saveBetshopLimitSettings(data));
	},

	getSystemTimeZones: () => {
		dispatch(getSystemTimeZones());
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.betshops.isSaving,
		isLoading: state.betshops.isLoading,
		limitSettings: state.betshops.editingBetshop.limitSettings,
		timezones: state.system.timezones,
		generalInfo: state.betshops.editingBetshop.generalInfo
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LimitSettingsComponent);
