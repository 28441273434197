import { Fragment } from "react";

import PartnersEditComponent from "components/dashboard/partners/edit/partner-edit.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const PartnersEditRoute = () => {
	return (
		<Fragment>
			<PartnersEditComponent />
		</Fragment>
	);
};

export default withPermission(PartnersEditRoute, { resource: PERMISSION_RESOURCE.PARTNER, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD_PARTNERS);
