import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Col, Row, Modal } from 'antd';
const { Item: FormItem } = Form;
import SearchableSelect from "components/common/searchableSelect";

import useAutosuggestion from "hooks/useAutosuggestion";

import { getJackpotCopyData } from 'store/actions/dashboard/bonuses/generalInfo.action';
import { POPUP_SIZE } from 'constants/common.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autoSuggestion.constants';
import { JACKPOTS_TYPES } from 'constants/bonus.constants';

const getGlobalPartnerId = (state) => state.partner.globalPartnerId;
const getIsGeneralInfoLoading = (state) => state.bonuses.standard.isLoading;


const CopyDataPopup = ({ jackpotCopyId, copyFields, onClose, onCopy }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;

	const [jackpot, setJackpot] = useState();

	const isLoading = useSelector(getIsGeneralInfoLoading);
	const globalPartnerId = useSelector(getGlobalPartnerId);

	const jackpotNames = useAutosuggestion(
		AUTOSUGGESTION_TYPE.JACKPOT_NAMES,
		[globalPartnerId],
		{
			disableAction: () => !globalPartnerId,
			actionArguments: [{ params: { type: JACKPOTS_TYPES.RETAIL, excludeIds: [jackpotCopyId] } }]
		}
	);

	const dispatch = useDispatch();

	const handleForm = () => {
		validateFields()
			.then(() => {
				dispatch(getJackpotCopyData(jackpot, copyFields, onCopy));
			})
			.finally(() => onClose())
	};

	return (
		<Modal
			open={true}
			title={t('pages.dashboard.bonuses.copy_data')}
			cancelText={t('common.cancel')}
			okText={t('common.copy')}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.SMALL}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isLoading, disabled: !jackpot }}
			centered
		>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
			>
				<Row gutter={[16, 0]}>
					<Fragment>
						<Col span={24}>
							<FormItem
								label={`${t('pages.dashboard.bonuses.jackpot')} *`}
								rules={[{ required: true, message: t('validation.field_required') }]}
							>
								<SearchableSelect
									getPopupContainer={() => document.body}
									value={jackpot}
									onChange={(v) => setJackpot(v)}
									items={jackpotNames}
									valueProp={(item) => item.key}
									textProp={(item) => item.value}
									renderText={(item) => item.value}
									placeholder={`${t("common.select")} ${t("pages.dashboard.bonuses.jackpot")}`}
								/>
							</FormItem>
						</Col>
					</Fragment>
				</Row>
			</Form>
		</Modal>
	)
};

CopyDataPopup.propTypes = {
	/** React prop, fields to copy from another jackpot */
	copyFields: PropTypes.array,
	/** React prop, callback function to close popup */
	onClose: PropTypes.func,
	/** React prop, callback function to be called on copy button click */
	onCopy: PropTypes.func
}

export default CopyDataPopup;