import { buildPathToStaticFolderOfCDN } from "utils/common";

// Dark templates images
const TEMPLATE1 = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/template-1.png");
const TEMPLATE8 = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/template-8.png");
const TEMPLATE9 = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/template-9.png");
const TEMPLATE10 = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/template-10.png");
const TEMPLATE11 = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/template-11.png");
const TEMPLATE12 = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/template-12.png");
const TEMPLATE13 = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/template-13.png");

// Light templates images
const PURPLE_TEMPLATE_IMAGE = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/light-template-purple.png");
const BONDI_BLUE_TEMPLATE_IMAGE = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/light-template-bondi-blue.png");
const BRITISH_GREEN_TEMPLATE_IMAGE = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/light-template-british-green.png");
const ORANGE_TEMPLATE_IMAGE = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/light-template-orange.png");
const BLUE_TEMPLATE_IMAGE = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/light-template-blue.png");
const GREEN_TEMPLATE_IMAGE = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/light-template-green.png");
const RED_TEMPLATE_IMAGE = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/light-template-red.png");
const MARDI_GRAS_TEMPLATE_IMAGE = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/light-template-mardi-gras.png");

export const BETSHOP_ACTIVITY_TYPE = {
	LIMIT_ADJUSTMENT: 2,
	BET: 3,
	CANCEL: 4,
	PAYOUT: 5
};

export const INTEGRATION_TYPE = {
	STANDALONE: 1,
	IFRAME: 2
};

export const WALLET_TYPE = {
	BASIC: 1,
	SEAMLESS: 2
};

export const BETSHOP_LIMIT_FREQUENCY = {
	DAY: 1,
	MONDAY: 2,
	TUESDAY: 4,
	WEDNESDAY: 8,
	THURSDAY: 16,
	FRIDAY: 32,
	SATURDAY: 64,
	SUNDAY: 128
};

export const BETSHOP_BONUS_CONFIG_TYPE = {
	EQUAL: 1,
	GREATER_OR_EQUAL: 2
};

export const BETSHOP_TEMPLATES = [
	{
		key: 1,
		name: "Yellow Jasper",
		thumbnail: TEMPLATE1,
		params: {
			"--brand-color-1": "red",
			"--brand-color-2": "red",
			"--vs--bg-color": "red",
			"--vs--bg-color-header": "#212121",
			"--vs--bg-color-section": "#2C2C2C",
			"--vs--bg-color-active": "#4D4D4D",
			"--vs--bg-color-element": "#333333",
			"--vs--bg-color-factor": "#1A1A1A",
			"--vs--bg-color-heading": "#414141",
			"--vs--bg-color-market": "#262626",
			"--vs--bg-color-field": "#212121",
			"--vs--bg-color-bet-factor": "#707070",
			"--vs--text-color-1": "#FFFFFF",
			"--vs--text-color-2": "#CCCCCC",
			"--vs--text-color-3": "#999999",
			"--vs--text-header-color-1": "#FFFFFF",
			"--vs--text-header-color-2": "#999999",
			"--vs--button-text-color": "#FFFFFF"
		}
	},
	{
		key: 2,
		name: "Purple",
		thumbnail: PURPLE_TEMPLATE_IMAGE,
		params: {
			"--brand-color-1": "#FFB700",
			"--brand-color-2": "#E87400",
			"--vs--bg-color": "#000000",
			"--vs--bg-color-header": "#111111",
			"--vs--bg-color-section": "#111111",
			"--vs--bg-color-active": "#333333",
			"--vs--bg-color-element": "#333333",
			"--vs--bg-color-factor": "#000000",
			"--vs--bg-color-heading": "#414141",
			"--vs--bg-color-market": "#212121",
			"--vs--bg-color-field": "#212121",
			"--vs--bg-color-bet-factor": "#484848",
			"--vs--text-color-1": "#FFFFFF",
			"--vs--text-color-2": "#CCCCCC",
			"--vs--text-color-3": "#CCCCCC",
			"--vs--text-header-color-1": "#FFFFFF",
			"--vs--text-header-color-2": "#999999",
			"--vs--button-text-color": "#FFFFFF"
		}
	},
	{
		key: 4,
		name: "Bondi Blue",
		thumbnail: BONDI_BLUE_TEMPLATE_IMAGE,
		params: {
			"--brand-color-1": "#15DCC1",
			"--brand-color-2": "#FFD500",
			"--vs--bg-color": "#000000",
			"--vs--bg-color-header": "#111111",
			"--vs--bg-color-section": "#111111",
			"--vs--bg-color-active": "#212121",
			"--vs--bg-color-element": "#0E0E0E",
			"--vs--bg-color-factor": "#1E1E1E",
			"--vs--bg-color-heading": "#090909",
			"--vs--bg-color-market": "#000000",
			"--vs--bg-color-field": "#000000",
			"--vs--bg-color-bet-factor": "#3D3D3D",
			"--vs--text-color-1": "#FFFFFF",
			"--vs--text-color-2": "#CCCCCC",
			"--vs--text-color-3": "#CCCCCC",
			"--vs--text-header-color-1": "#FFFFFF",
			"--vs--text-header-color-2": "#999999",
			"--vs--button-text-color": "#000000"
		}
	},
	{
		key: 8,
		name: "British Green",
		thumbnail: BRITISH_GREEN_TEMPLATE_IMAGE,
		params: {
			"--brand-color-1": "#267CDF",
			"--brand-color-2": "#0A5C9A",
			"--vs--bg-color": "#ECEEF1",
			"--vs--bg-color-header": "#FFFFFF",
			"--vs--bg-color-section": "#F8F8F9",
			"--vs--bg-color-active": "#FFFFFF",
			"--vs--bg-color-element": "#F7FAFF",
			"--vs--bg-color-factor": "#EBF1FC",
			"--vs--bg-color-heading": "#FFFFFF",
			"--vs--bg-color-market": "#ECEEF1",
			"--vs--bg-color-field": "#FFFFFF",
			"--vs--bg-color-bet-factor": "#C2C2C2",
			"--vs--text-color-1": "#000000",
			"--vs--text-color-2": "#999999",
			"--vs--text-color-3": "#333333",
			"--vs--text-header-color-1": "#262626",
			"--vs--text-header-color-2": "#666666",
			"--vs--button-text-color": "#FFFFFF"
		}
	},
	{
		key: 16,
		name: "Orange",
		thumbnail: ORANGE_TEMPLATE_IMAGE,
		params: {
			"--brand-color-1": "#7E9A0D",
			"--brand-color-2": "#FFD500",
			"--vs--bg-color": "#0E1011",
			"--vs--bg-color-header": "#1A1F24",
			"--vs--bg-color-section": "#15191C",
			"--vs--bg-color-active": "#354048",
			"--vs--bg-color-element": "#2C343A",
			"--vs--bg-color-factor": "#1D2329",
			"--vs--bg-color-heading": "#262D33",
			"--vs--bg-color-market": "#1A1F24",
			"--vs--bg-color-field": "#22282D",
			"--vs--bg-color-bet-factor": "#494F54",
			"--vs--text-color-1": "#FFFFFF",
			"--vs--text-color-2": "#999999",
			"--vs--text-color-3": "#CCCCCC",
			"--vs--text-header-color-1": "#FFFFFF",
			"--vs--text-header-color-2": "#999999",
			"--vs--button-text-color": "#000000"
		}
	},
	{
		key: 32,
		name: "Blue",
		thumbnail: BLUE_TEMPLATE_IMAGE,
		params: {
			"--brand-color-1": "#7E9A0D",
			"--brand-color-2": "#0A5C9A",
			"--vs--bg-color": "#FFFFFF",
			"--vs--bg-color-header": "#E6E6E6",
			"--vs--bg-color-section": "#EFEFEF",
			"--vs--bg-color-active": "#E5E5E5",
			"--vs--bg-color-element": "#F6F6F6",
			"--vs--bg-color-factor": "#E5E5E5",
			"--vs--bg-color-heading": "#DCDCDC",
			"--vs--bg-color-market": "#FFFFFF",
			"--vs--bg-color-field": "#FFFFFF",
			"--vs--bg-color-bet-factor": "#BDBDBD",
			"--vs--text-color-1": "#000000",
			"--vs--text-color-2": "#999999",
			"--vs--text-color-3": "#707070",
			"--vs--text-header-color-1": "#262626",
			"--vs--text-header-color-2": "#999999",
			"--vs--button-text-color": "#FFFFFF"
		}
	},
	{
		key: 64,
		name: "Green",
		thumbnail: GREEN_TEMPLATE_IMAGE,
		params: {
			"--brand-color-1": "#E87400",
			"--brand-color-2": "#005BF9",
			"--vs--bg-color": "#DFDFE0",
			"--vs--bg-color-header": "#FFFFFF",
			"--vs--bg-color-section": "#F1F1F1",
			"--vs--bg-color-active": "#FFFFFF",
			"--vs--bg-color-element": "#CCCCCC",
			"--vs--bg-color-factor": "#FFFFFF",
			"--vs--bg-color-heading": "#E6E6E6",
			"--vs--bg-color-market": "#F9F9F9",
			"--vs--bg-color-field": "#E6E6E6",
			"--vs--bg-color-bet-factor": "#C2C2C2",
			"--vs--text-color-1": "#000000",
			"--vs--text-color-2": "#999999",
			"--vs--text-color-3": "#333333",
			"--vs--text-header-color-1": "#000000",
			"--vs--text-header-color-2": "#999999",
			"--vs--button-text-color": "#FFFFFF"
		}
	},
	{
		key: 8192,
		name: "Red",
		thumbnail: RED_TEMPLATE_IMAGE
	},
	{
		key: 16384,
		name: "Mardi Gras",
		thumbnail: MARDI_GRAS_TEMPLATE_IMAGE
	},
	{
		key: 128,
		name: "Sapphire",
		thumbnail: TEMPLATE8,
		params: {
			"--brand-color-1": "#E87400",
			"--brand-color-2": "#FFB700",
			"--vs--bg-color": "#E6E6E6",
			"--vs--bg-color-header": "#666666",
			"--vs--bg-color-section": "#F5F5F5",
			"--vs--bg-color-active": "#FFFFFF",
			"--vs--bg-color-element": "#FFFFFF",
			"--vs--bg-color-factor": "#D6D6D6",
			"--vs--bg-color-heading": "#E5E5E5",
			"--vs--bg-color-market": "#EBEBEB",
			"--vs--bg-color-field": "#E6E6E6",
			"--vs--bg-color-bet-factor": "#ADADAD",
			"--vs--text-color-1": "#000000",
			"--vs--text-color-2": "#999999",
			"--vs--text-color-3": "#707070",
			"--vs--text-header-color-1": "#000000",
			"--vs--text-header-color-2": "#999999",
			"--vs--button-text-color": "#000000"
		}
	},
	{
		key: 256,
		name: "Cats Eye (Tanzanite)",
		thumbnail: TEMPLATE9,
		params: {
			"--brand-color-1": "#E87400",
			"--brand-color-2": "#267CDF",
			"--vs--bg-color": "#333333",
			"--vs--bg-color-header": "#333333",
			"--vs--bg-color-section": "#262626",
			"--vs--bg-color-active": "#4C4C4C",
			"--vs--bg-color-element": "#494949",
			"--vs--bg-color-factor": "#666666",
			"--vs--bg-color-heading": "#414141",
			"--vs--bg-color-market": "#363636",
			"--vs--bg-color-field": "#302A2A",
			"--vs--bg-color-bet-factor": "#666666",
			"--vs--text-color-1": "#FFFFFF",
			"--vs--text-color-2": "#CCCCCC",
			"--vs--text-color-3": "#CCCCCC",
			"--vs--text-header-color-1": "#FFFFFF",
			"--vs--text-header-color-2": "#999999",
			"--vs--button-text-color": "#FFFFFF"
		}
	},
	{
		key: 512,
		name: "Aquamarine",
		thumbnail: TEMPLATE10,
		params: {
			"--brand-color-1": "#C81202",
			"--brand-color-2": "#00912C",
			"--vs--bg-color": "#E6E6E6",
			"--vs--bg-color-header": "#B3B3B3",
			"--vs--bg-color-section": "#EEEEEE",
			"--vs--bg-color-active": "#CCCCCC",
			"--vs--bg-color-element": "#D2D2D2",
			"--vs--bg-color-factor": "#FBFBFB",
			"--vs--bg-color-heading": "#B3B3B3",
			"--vs--bg-color-market": "#E5E5E5",
			"--vs--bg-color-field": "#CCCCCC",
			"--vs--bg-color-bet-factor": "#C2C2C2",
			"--vs--text-color-1": "#000000",
			"--vs--text-color-2": "#999999",
			"--vs--text-color-3": "#333333",
			"--vs--text-header-color-1": "#FFFFFF",
			"--vs--text-header-color-2": "#666666",
			"--vs--button-text-color": "#FFFFFF"
		}
	},
	{
		key: 1024,
		name: "Carnelian",
		thumbnail: TEMPLATE11,
		params: {
			"--brand-color-1": "#C81202",
			"--brand-color-2": "#00912C",
			"--vs--bg-color": "#E6E6E6",
			"--vs--bg-color-header": "#B3B3B3",
			"--vs--bg-color-section": "#EEEEEE",
			"--vs--bg-color-active": "#CCCCCC",
			"--vs--bg-color-element": "#D2D2D2",
			"--vs--bg-color-factor": "#FBFBFB",
			"--vs--bg-color-heading": "#B3B3B3",
			"--vs--bg-color-market": "#E5E5E5",
			"--vs--bg-color-field": "#CCCCCC",
			"--vs--bg-color-bet-factor": "#C2C2C2",
			"--vs--text-color-1": "#000000",
			"--vs--text-color-2": "#999999",
			"--vs--text-color-3": "#333333",
			"--vs--text-header-color-1": "#FFFFFF",
			"--vs--text-header-color-2": "#666666",
			"--vs--button-text-color": "#FFFFFF"
		}
	},
	{
		key: 2048,
		name: "Turquoise",
		thumbnail: TEMPLATE12,
		params: {
			"--brand-color-1": "#C81202",
			"--brand-color-2": "#00912C",
			"--vs--bg-color": "#E6E6E6",
			"--vs--bg-color-header": "#B3B3B3",
			"--vs--bg-color-section": "#EEEEEE",
			"--vs--bg-color-active": "#CCCCCC",
			"--vs--bg-color-element": "#D2D2D2",
			"--vs--bg-color-factor": "#FBFBFB",
			"--vs--bg-color-heading": "#B3B3B3",
			"--vs--bg-color-market": "#E5E5E5",
			"--vs--bg-color-field": "#CCCCCC",
			"--vs--bg-color-bet-factor": "#C2C2C2",
			"--vs--text-color-1": "#000000",
			"--vs--text-color-2": "#999999",
			"--vs--text-color-3": "#333333",
			"--vs--text-header-color-1": "#FFFFFF",
			"--vs--text-header-color-2": "#666666",
			"--vs--button-text-color": "#FFFFFF"
		}
	},
	{
		key: 4096,
		name: "Lilac",
		thumbnail: TEMPLATE13,
		params: {
			"--brand-color-1": "#C81202",
			"--brand-color-2": "#00912C",
			"--vs--bg-color": "#E6E6E6",
			"--vs--bg-color-header": "#B3B3B3",
			"--vs--bg-color-section": "#EEEEEE",
			"--vs--bg-color-active": "#CCCCCC",
			"--vs--bg-color-element": "#D2D2D2",
			"--vs--bg-color-factor": "#FBFBFB",
			"--vs--bg-color-heading": "#B3B3B3",
			"--vs--bg-color-market": "#E5E5E5",
			"--vs--bg-color-field": "#CCCCCC",
			"--vs--bg-color-bet-factor": "#C2C2C2",
			"--vs--text-color-1": "#000000",
			"--vs--text-color-2": "#999999",
			"--vs--text-color-3": "#333333",
			"--vs--text-header-color-1": "#FFFFFF",
			"--vs--text-header-color-2": "#666666",
			"--vs--button-text-color": "#FFFFFF"
		}
	}
];
