import "prop-types";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import TransactionsHistoryComponent from "./transactions";
import DBCacheCompareComponent from "./dbCacheCompare";
import { updateLocationHash, getHashValue } from "utils/common";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

/** Betshop Edit Page Activity Tab Component */
const ActivityComponent = () => {
	const { t } = useTranslation();

	return (
		<Tabs
			animated={false}
			className="sub-tabs"
			defaultActiveKey={getHashValue("subtab")}
			onChange={(key) => updateLocationHash("subtab=" + key, true)}
			destroyInactiveTabPane={true}
			items={[
				{
					key: "1",
					label: t("pages.dashboard.betshops.viewTabs.transactions"),
					children: <TransactionsHistoryComponent />
				},
				hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_DBCACHECOMPARE, action: PERMISSION_ACTION.VIEW })
					? {
							key: "2",
							label: t("pages.dashboard.bets.cache_compare.db_cache_compare"),
							children: <DBCacheCompareComponent />
						}
					: null
			]}
		/>
	);
};

export default ActivityComponent;
