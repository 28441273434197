import { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Spin, Tooltip, Button } from "antd";
const { Item: FormItem } = Form;

import BannerUpload from "components/dashboard/common/bannerUpload";

import { getBetshopUI, resetBetshopUI, saveBetshopUI, deleteLogo, setLogoId } from "store/actions/dashboard/retail/betshops/customization.action";

import apiKeyUIType from "types/apiKey/ui.type";

import { sendDataToIframe } from "utils/common";
import { hasPermission } from "utils/permissions";

import ApiUrls from "constants/api.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { BETSHOP_TEMPLATES } from "constants/betshop.constants";
import { FILE_SIZES_MEASUREMENT } from "constants/common.constants";

/** Betshop Edit Page Customization Tab Component */
const CustomizationComponent = ({ getBetshopUI, saveBetshopUI, isLoading, isSaving, ui, previewUI, resetBetshopUI, deleteLogo, setLogoId, globalPartnerId, onTabChange }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [initialTemplate, setInitialTemplate] = useState(null);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CUSTOMIZATION, action: PERMISSION_ACTION.MODIFY });

	/** Load betshop ui data */
	useEffect(() => {
		if (routeParams.id) {
			getBetshopUI(routeParams.id, "EN");
		}
	}, []);

	/** Set initial template */
	useEffect(() => {
		if (ui.launchUrl) {
			//Check if template exist
			const template = ui.templateType;
			if (!template) {
				setSelectedTemplate(BETSHOP_TEMPLATES[0].key);
				setInitialTemplate(BETSHOP_TEMPLATES[0].key);
			} else {
				setSelectedTemplate(template);
				setInitialTemplate(template);
			}
		}
	}, [ui]);

	/** Load api key ui data */
	useEffect(() => {
		return () => {
			resetBetshopUI();
		};
	}, []);

	/** Send event to iframe on any change of colors */
	useEffect(() => {
		const onReady = (e) => {
			const d = e.data;
			if (d) {
				if (d.eventName === "vs--customization-ready") {
					sendCurrentDataToIframe();
				}
			}
		};
		window.addEventListener("message", onReady, false);

		return () => {
			window.removeEventListener("message", onReady);
		};
	}, []);

	/** Send event to iframe on any change of colors */
	useEffect(() => {
		setTimeout(() => {
			sendCurrentDataToIframe();
		}, 100);
	}, [selectedTemplate]);

	/** Send event to iframe on any change of colors */
	useEffect(() => {
		sendDataToIframe("dashboard-customization-iframe", ui.logoId, "vs--customization-logo");
	}, [ui.logoId]);

	/** Send currecnt ui data to preview iframe
	 * @function
	 * @memberOf CustomizationComponent
	 */
	const sendCurrentDataToIframe = () => {
		if (selectedTemplate) {
			sendDataToIframe("dashboard-customization-iframe", selectedTemplate, "vs--customization");
		}
	};

	/** Fires when form submitted
	 * @function
	 * @memberOf GeneralInfoComponent
	 */
	const handleForm = () => {
		saveBetshopUI(routeParams.id, selectedTemplate, "EN");
		setInitialTemplate(selectedTemplate);
	};

	/** Fires on template click
	 * @function
	 * @param {number} template
	 * @memberOf GeneralInfoComponent
	 */
	const handleTemplateClick = (template) => {
		setSelectedTemplate(template);
	};

	useEffect(() => {
		onTabChange(initialTemplate !== selectedTemplate);
	}, [initialTemplate, selectedTemplate]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<div className="dashboard-section-content">
				<Form colon={false} requiredMark={false} layout="vertical">
					<Row gutter={[16, 0]}>
						<Col span={24}>
							<h1>{t("pages.dashboard.betshops.choose_template")}</h1>
						</Col>
						<Col xs={24} sm={24} md={18} xl={20}>
							<div className="dashboard-customization-templates-wrapper">
								<div className="dashboard-customization-templates">
									{[...BETSHOP_TEMPLATES]
										.sort((a, b) => (a.key !== initialTemplate ? 1 : -1))
										.map((template) => (
											<div key={template.key} className={"dashboard-customization-templates-item" + (isDisabled ? " dashboard-customization-templates-item-disable" : "")}>
												<span title={initialTemplate !== template.key ? template.name : `${t("pages.dashboard.betshops.current_version")}/${template.name}`}>{initialTemplate !== template.key ? template.name : `${t("pages.dashboard.betshops.current_version")}/${template.name}`}</span>
												<div className={"dashboard-customization-templates-item-thumbnail" + (selectedTemplate === template.key ? " dashboard-customization-templates-item-thumbnail-selected" : "")} onClick={() => !isDisabled && handleTemplateClick(template.key)}>
													<img src={template.thumbnail} alt={template.name} />
												</div>
											</div>
										))}
								</div>
								<div className="dashboard-customization-templates-divider" />
							</div>
						</Col>
						<Col xs={24} sm={24} md={6} xl={4}>
							{ui.logoId !== undefined && (
								<FormItem
									label={
										<Fragment>
											<span>{t("pages.dashboard.betshops.betshop_logo")}</span>
											<Tooltip title={<span dangerouslySetInnerHTML={{ __html: t("pages.dashboard.betshops.betshop_logo_tooltip") }}></span>} getPopupContainer={() => document.body}>
												<i className="icon-info" style={{ fontSize: "24px" }} />
											</Tooltip>
										</Fragment>
									}
									className="dashboard-customization-upload"
								>
									<BannerUpload
										uploadUrl={`${import.meta.env.SYSTEM_API_URL}${ApiUrls.BETSHOP_UPLOAD_LOGO}`}
										defaultFile={
											ui.logoId
												? {
														url: `${import.meta.env.SYSTEM_CDN_URL}/${routeParams.id}/images/${ui.logoId}_betshoplogo.png`,
														status: "done",
														percent: 100
													}
												: null
										}
										remove={
											isDisabled
												? null
												: {
														handler: () => deleteLogo(routeParams.id),
														message: t("pages.dashboard.betshops.remove_logo_confirmation_message")
													}
										}
										data={{ id: routeParams.id }}
										fileBuilder={(value) => ({
											url: `${import.meta.env.SYSTEM_CDN_URL}/${routeParams.id}/images/${value}_betshoplogo.png`
										})}
										extensions={["image/png"]}
										size={FILE_SIZES_MEASUREMENT.MB}
										disabled={isDisabled}
										disablePreview={true}
										onSuccess={(logoId) => {
											setLogoId(logoId);
										}}
									/>
								</FormItem>
							)}
						</Col>
					</Row>
					<Row gutter={[16, 0]}>
						<Col span={24}>
							<h1 style={{ marginTop: "24px" }}>{t("pages.dashboard.betshops.currently_chosen_version")}</h1>
						</Col>
						<Col span={24}>
							<div className="dashboard-customization">
								{ui.launchUrl && (
									<div className="dashboard-customization-preview">
										{!isLoading ? (
											<div className="dashboard-customization-preview-betshop">
												<iframe id="dashboard-customization-iframe" src={ui.launchUrl} title="Virtual Sport" width="100%" frameBorder="0" allowFullScreen={true} allow="autoplay" />
											</div>
										) : null}
									</div>
								)}
							</div>
						</Col>
					</Row>
				</Form>
				{!isDisabled && (
					<FormItem className="button-container">
						<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={initialTemplate === selectedTemplate}>
							<span>{t("common.save")}</span>
						</Button>
					</FormItem>
				)}
			</div>
		</Spin>
	);
};

/** CustomizationComponent propTypes
 * PropTypes
 */
CustomizationComponent.propTypes = {
	/** Redux action to get betshop ui data */
	getBetshopUI: PropTypes.func,
	/** Redux action to save betshop ui data */
	saveBetshopUI: PropTypes.func,
	/** Redux state property, is true when loading ui data */
	isLoading: PropTypes.bool,
	/** Redux state property, is true when saving ui data */
	isSaving: PropTypes.bool,
	/** Redux state property, betshop ui data */
	ui: apiKeyUIType,
	/** Redux state property, betshop ui data */
	previewUI: apiKeyUIType,
	/** Redux action to reset betshop ui data */
	resetBetshopUI: PropTypes.func,
	/** Redux action to delete logo */
	deleteLogo: PropTypes.func,
	/** Redux action to set logo id */
	setLogoId: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getBetshopUI: (id, languageCode, resetData) => {
		dispatch(getBetshopUI(id, languageCode, resetData));
	},
	saveBetshopUI: (id, templateType, languageCode) => {
		dispatch(saveBetshopUI(id, templateType, languageCode));
	},
	resetBetshopUI: () => {
		dispatch(resetBetshopUI());
	},
	deleteLogo: (betshopId) => {
		dispatch(deleteLogo(betshopId));
	},
	setLogoId: (logoId) => {
		dispatch(setLogoId(logoId));
	}
});

const mapStateToProps = (state) => {
	return {
		ui: state.betshops.editingBetshop.customization.ui,
		previewUI: state.betshops.editingBetshop.customization.previewUI,
		isLoading: state.betshops.isLoading,
		isSaving: state.betshops.isSaving,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizationComponent);
