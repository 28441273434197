import { useRef, useState, memo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Input, Tooltip } from "antd";
const { Item: FormItem } = Form;

import { saveLiveMonitorTranslation } from "store/actions/dashboard/cms/translation/liveMonitorTranslation.action";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { hasPermission } from "utils/permissions";

import { INSTANT_GAME_TYPE, SCHEDULED_GAME_TYPE } from "constants/game.constants";

/** Live Monitor Translation Field Component in Live Monitor Translations Table*/
const LiveMonitorTranslationField = ({ gameType, lang, translationKey, saveLiveMonitorTranslation, formInstance }) => {
	const { t } = useTranslation();

	const [initialValue, setInitialValue] = useState("");
	const [inputValue, setInputValue] = useState("");
	const inputRef = useRef(null);

	const { setFieldsValue, getFieldValue } = formInstance;

	/** Fires on input focus
	 * @function
	 * @memberOf LiveMonitorTranslationField
	 */
	const handleFieldFocus = () => {
		setInitialValue(getFieldValue(["translations", translationKey, lang.toLowerCase()]));
		setInputValue(getFieldValue(["translations", translationKey, lang.toLowerCase()]));
	};

	/** Fires on input blur
	 * @function
	 * @memberOf LiveMonitorTranslationField
	 */
	const handleFieldBlur = () => {
		if (initialValue !== inputValue) {
			let text = inputValue;
			if (text) {
				text = text.trim();
			}
			if (text) {
				saveLiveMonitorTranslation(gameType, translationKey, text, lang.toLowerCase());
			}
		}
	};

	/** Fires on filed press enter
	 * @function
	 * @memberOf LiveMonitorTranslationField
	 */
	const handleFieldPressEnter = () => {
		inputRef.current && inputRef.current.blur();
	};

	return (
		<div className="translation-field">
			<FormItem className="inline-form-item-control table-form-control" style={{ marginLeft: "0" }} name={["translations", translationKey, lang.toLowerCase()]} rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
				<Input
					ref={inputRef}
					onPressEnter={handleFieldPressEnter}
					autoComplete="off"
					onChange={(e) => setInputValue(e.target.value)}
					readOnly={!hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_LIVE_MONITOR, action: PERMISSION_ACTION.MODIFY })}
					onFocus={handleFieldFocus}
					onBlur={handleFieldBlur}
				/>
			</FormItem>
		</div>
	);
};

/** LiveMonitorTranslationField propTypes
 * PropTypes
 */
LiveMonitorTranslationField.propTypes = {
	/** Redux state property, represents the type of current selected game  */
	gameType: PropTypes.oneOf([...Object.values(SCHEDULED_GAME_TYPE), ...Object.values(INSTANT_GAME_TYPE)].map((v) => v.value)),
	/** Translation language */
	lang: PropTypes.string,
	/** Translation key */
	translationKey: PropTypes.string,
	/** Redux action to save live monitor translation */
	saveLiveMonitorTranslation: PropTypes.func,
	/**Form objcet */
	formInstance: PropTypes.object
};
const mapDispatchToProps = (dispatch) => ({
	saveLiveMonitorTranslation: (gameType, key, text, languageCode) => {
		dispatch(saveLiveMonitorTranslation(gameType, key, text, languageCode));
	}
});

export default connect(null, mapDispatchToProps)(memo(LiveMonitorTranslationField));
