import { Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Row, Col, Select, Input } from "antd";
const { Item: FormItem } = Form;

import FiltersWrapper from "components/common/filters";
import NumericInput from "components/common/numericInput";

import { setPlayerPendingBetsFilters, getPlayerPendingBets } from "store/actions/dashboard/online/players/betslips.action";

import { FILTER_BETSLIP_MODES, FILTER_BETSLIP_MODE_TYPE, FILTER_REPORT_BONUS_TYPE } from "constants/filter.constants";
import { GAME_CATEGORY } from "constants/game.constants";

/** Player View Page BetHistory tab Pending betslips SubTab Page filters Component */

const Filters = ({ setPlayerPendingBetsFilters, getPlayerPendingBets, filters }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	/** Function to get filters list
	 * @function
	 * @returns {array} - filters list
	 * @memberOf Filters
	 */
	const filtersList = () => [
		{ name: "betSlipId", title: t("pages.dashboard.bets.betslip_id") },
		{ name: "betId", title: t("pages.dashboard.bets.bet_id") },
		{ name: "eventId", title: t("pages.dashboard.bets.event_id") },
		{
			name: "type",
			title: t("pages.dashboard.bets.bet_type"),
			values: [
				{ title: t("common.all"), value: FILTER_BETSLIP_MODES.ALL },
				{
					title: t("pages.dashboard.bets.single"),
					value: FILTER_BETSLIP_MODES.SINGLE
				},
				{
					title: t("pages.dashboard.bets.multi"),
					value: FILTER_BETSLIP_MODES.MULTI
				}
			]
		},
		{
			name: "demoState",
			title: t("pages.dashboard.bets.mode_type"),
			values: [
				{
					title: t("pages.dashboard.bets.real"),
					value: FILTER_BETSLIP_MODE_TYPE.REAL
				},
				{
					title: t("pages.dashboard.bets.demo"),
					value: FILTER_BETSLIP_MODE_TYPE.DEMO
				}
			]
		},
		{ name: "bonusId", title: t("pages.dashboard.bets.bonus_id") },
		{
			name: "betType",
			title: t("pages.dashboard.bets.betslips"),
			values: [
				{ title: t("common.all"), value: FILTER_REPORT_BONUS_TYPE.ALL },
				{
					title: t("pages.dashboard.bets.only_bonus"),
					value: FILTER_REPORT_BONUS_TYPE.ONLY_BONUS
				},
				{
					title: t("pages.dashboard.bets.without_bonus"),
					value: FILTER_REPORT_BONUS_TYPE.WITHOUT_BONUS
				}
			]
		},
		{
			name: "gameCategory",
			title: t("pages.dashboard.games.gameCategory"),
			values: [
				{ title: t("common.all"), value: "" },
				{
					title: t(`pages.dashboard.games.gameCategory_${GAME_CATEGORY.SCHEDULED}`),
					value: GAME_CATEGORY.SCHEDULED
				},
				{
					title: t(`pages.dashboard.games.gameCategory_${GAME_CATEGORY.INSTANT}`),
					value: GAME_CATEGORY.INSTANT
				}
			]
		}
	];

	return (
		<FiltersWrapper filtersName="betHistory_pendings" loadFn={() => getPlayerPendingBets(routeParams.id)} setFiltersFn={(filters) => setPlayerPendingBetsFilters(filters)} filters={filters} filtersList={filtersList()}>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.betslip_id")} name="betSlipId">
							<NumericInput placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.betslip_id")}`} isInteger={true} maxLength={12} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.bet_id")} name="betId">
							<NumericInput placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.bet_id")}`} isInteger={true} maxLength={12} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.event_id")} name="eventId">
							<NumericInput placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.event_id")}`} isInteger={true} maxLength={12} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.bet_type")} name="type">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_BETSLIP_MODES.ALL}>{t("common.all")}</Select.Option>
								<Select.Option value={FILTER_BETSLIP_MODES.SINGLE}>{t("pages.dashboard.bets.single")}</Select.Option>
								<Select.Option value={FILTER_BETSLIP_MODES.MULTI}>{t("pages.dashboard.bets.multi")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.mode_type")} name="demoState">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_BETSLIP_MODE_TYPE.REAL}>{t("pages.dashboard.bets.real")}</Select.Option>
								<Select.Option value={FILTER_BETSLIP_MODE_TYPE.DEMO}>{t("pages.dashboard.bets.demo")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.bonus_id")} name="bonusId">
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.bonus_id")}`} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.betslips")} name="betType">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_REPORT_BONUS_TYPE.ALL}>{t("common.all")}</Select.Option>
								<Select.Option value={FILTER_REPORT_BONUS_TYPE.ONLY_BONUS}>{t("pages.dashboard.bets.only_bonus")}</Select.Option>
								<Select.Option value={FILTER_REPORT_BONUS_TYPE.WITHOUT_BONUS}>{t("pages.dashboard.bets.without_bonus")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.games.gameCategory")} name="gameCategory">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={""}>{t("common.all")}</Select.Option>
								<Select.Option value={GAME_CATEGORY.SCHEDULED}>{t(`pages.dashboard.games.gameCategory_${GAME_CATEGORY.SCHEDULED}`)}</Select.Option>
								<Select.Option value={GAME_CATEGORY.INSTANT}>{t(`pages.dashboard.games.gameCategory_${GAME_CATEGORY.INSTANT}`)}</Select.Option>
							</Select>
						</FormItem>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get player pending bets */
	getPlayerPendingBets: PropTypes.func,
	/** Redux action to set player pending bets filters */
	setPlayerPendingBetsFilters: PropTypes.func,
	/** Redux state property, player pending bets filters */
	filters: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		filters: state.players.viewingPlayer.betHistory.pendings.filters
	};
};

const mapDispatchToProps = (dispatch) => ({
	getPlayerPendingBets: (id) => {
		dispatch(getPlayerPendingBets(id));
	},
	setPlayerPendingBetsFilters: (filters) => {
		dispatch(setPlayerPendingBetsFilters(filters));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
