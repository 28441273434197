import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Tag, Button } from "antd";

import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { USER_STATE } from "constants/user.constants";

import { getCashiers, setCashiersSorting, setCashiersFilters, changeCashierState, forceLogout, forceLogoutSingleCashier, resendRegistrationEmail } from "store/actions/dashboard/retail/cashierManagement/cashiers/cashiers.action";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import Table from "components/common/table";
import Breadcrumbs from "components/common/breadcrumbs";
import Filters from "./filters.component";
import ColumnsButton from "components/common/columnsButton";
import ExportButton from "components/common/exportButton";
import Question from "components/common/question";
import CashierAddComponent from "./cashier-add.component";

import { CASHIER_TYPE } from "constants/cashier.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import cashierType from "types/cashier/cashier.type";
import sortingType from "types/common/sorting.type";

import Paths from "constants/path.constants";
import ApiUrls from "constants/api.constants";

import localStorageUtils from "utils/localStorage";

/** Cashiers Page Internal Tab Component */

const InternalCashiersComponent = ({ getCashiers, forceLogout, forceLogoutSingleCashier, cashiers, isLoading, isSaving, total, setCashiersSorting, setCashiersFilters, sorting, filters, changeCashierState, resendRegistrationEmail, globalPartnerId }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	/** State to show/hide cashier creation popup */
	const [showCreatePopup, setShowCreatePopup] = useState(false);

	/** State to show/hide force logout popup */
	const [showForceLogoutPopup, setShowForceLogoutPopup] = useState(false);

	/** State to show/hide resend activation email popup */
	const [resendingUser, setResendingUser] = useState(null);

	/** Columns list, which can be included/excluded */
	const columnsToInclude = [
		{ title: t("pages.dashboard.cashiers.id"), key: "id" },
		{ title: t("pages.dashboard.cashiers.externalId"), key: "externalId" },
		{ title: t("pages.dashboard.cashiers.created_at"), key: "created" },
		{ title: t("pages.dashboard.cashiers.last_update_at"), key: "lastUpdate" },
		{ title: t("pages.dashboard.cashiers.last_update_by"), key: "lastUpdateBy" }
	];

	const [includedColumns, setIncludedColumns] = useState((localStorageUtils.get("selected_columns") || {})["cashiers"] || []);

	/** Function which will fire on columns popup apply button click
	 * @function
	 * @param {array} selectedColumns - selected columns
	 * @memberOf InternalCashiersComponent
	 */
	const handleColumnsApply = (selectedColumns) => {
		setIncludedColumns(selectedColumns);
	};

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.cashiers.first_name"),
			dataIndex: "firstName",
			render: (value, record) => (
				<div className="table-col-marker-wrapper">
					<i title={record.isOnline ? t("pages.dashboard.cashiers.online") : t("pages.dashboard.cashiers.offline")} className={"table-col-marker table-col-marker-" + (record.isOnline ? "active" : "passive")} />
					<span>{value}</span>
				</div>
			)
		},
		{
			title: t("pages.dashboard.cashiers.last_name"),
			dataIndex: "lastName"
		},
		{
			title: t("pages.dashboard.cashiers.username"),
			dataIndex: "userName"
		},
		{
			title: t("pages.dashboard.cashiers.betshop"),
			dataIndex: "betShop"
		},
		{
			title: t("pages.dashboard.cashiers.type"),
			dataIndex: "type",
			render: (value) => (value === CASHIER_TYPE.INTERNAL ? t("pages.dashboard.cashiers.internal") : t("pages.dashboard.cashiers.external"))
		},
		{
			title: t("pages.dashboard.cashiers.status"),
			dataIndex: "state",
			render: (value) => <Tag color={value === USER_STATE.IN_PROGRESS ? "orange" : value === USER_STATE.ACTIVE ? "green" : "cyan"}>{t("pages.dashboard.users.status_" + value)}</Tag>
		},
		{
			title: t("pages.dashboard.cashiers.id"),
			dataIndex: "id"
		},
		{
			title: t("pages.dashboard.cashiers.externalId"),
			dataIndex: "externalId",
			render: (value) => value || ""
		},
		{
			title: t("pages.dashboard.cashiers.created_at"),
			dataIndex: "created",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.cashiers.last_update_at"),
			dataIndex: "lastUpdate",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.cashiers.last_update_by"),
			dataIndex: "lastUpdateBy"
		},
		{
			title: t("pages.dashboard.cashiers.last_login_at"),
			dataIndex: "lastLogin",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "")
		}
	];

	/** Keep included columns in local storage */
	useEffect(() => {
		const columns = localStorageUtils.get("selected_columns") || {};
		columns["cashiers"] = includedColumns;
		localStorageUtils.set("selected_columns", columns);
	}, [includedColumns]);

	/** Close force logout popup after save */
	useEffect(() => {
		if (isSaving) {
			setShowForceLogoutPopup(false);
			setResendingUser(null);
		}
	}, [isSaving]);

	/* Specific case, view action busy for activiti functional */
	const generateEditAction = () => {
		if (!hasPermission({ resource: PERMISSION_RESOURCE.CASHIER, action: PERMISSION_ACTION.VIEW })) {
			return null;
		}
		const obj = {
			handler: (record) => {
				navigate(Paths.DASHBOARD_RETAIL_CASHIER_MANAGEMENT_CASHIERS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, `${record.id}?PI=${globalPartnerId}`));
			},
			icon: <i className="icon-eye" />
		};
		if (hasPermission({ resource: PERMISSION_RESOURCE.CASHIER, action: PERMISSION_ACTION.MODIFY })) {
			delete obj["icon"];
		}
		return obj;
	};

	return (
		<div className="dashboard-section">
			<Breadcrumbs items={[{ title: t("pages.dashboard.cashiers.cashiers") }]} />
			<div className="dashboard-section-content">
				<div className="table-header">
					<div className="table-buttons-dropdowns">
						{hasPermission({ resource: PERMISSION_RESOURCE.CASHIER, action: PERMISSION_ACTION.EXPORT }) ? (
							<ExportButton columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t("pages.dashboard.menu.cashiers")} url={`${ApiUrls.EXPORT_CASHIERS}`} filters={filters} sorting={sorting} />
						) : null}
						<ColumnsButton columns={columnsToInclude} onApply={handleColumnsApply} defaultSelectedColumns={includedColumns} />
						{hasPermission({ resource: PERMISSION_RESOURCE.CASHIER, action: PERMISSION_ACTION.CREATE }) && (
							<div className="table-buttons-dropdowns-button">
								<Button onClick={() => setShowCreatePopup(true)} type="primary">
									{t("pages.dashboard.cashiers.create_cashier")}
								</Button>
							</div>
						)}
						{hasPermission({ resource: PERMISSION_RESOURCE.CASHIER_FORCE_LOGOUT, action: PERMISSION_ACTION.MODIFY }) && (
							<div className="table-buttons-dropdowns-button">
								<Button onClick={() => setShowForceLogoutPopup(true)} type="secondary">
									{t("pages.dashboard.cashiers.force_logout")}
								</Button>
							</div>
						)}
					</div>
					<Filters />
				</div>
				<Table
					loading={isLoading}
					columns={columns.filter((c) => !columnsToInclude.find((col) => col.key === c.dataIndex) || includedColumns.includes(c.dataIndex))}
					data={cashiers}
					loadFn={getCashiers}
					sorting={sorting}
					filters={filters}
					setSortingFn={setCashiersSorting}
					setFiltersFn={setCashiersFilters}
					total={total}
					updateProps={[globalPartnerId]}
					enableReload={true}
					actions={{
						...(hasPermission({ resource: PERMISSION_RESOURCE.CASHIER, action: PERMISSION_ACTION.MODIFY })
							? {
									activate: {
										isChecked: (record) => record.state !== USER_STATE.BLOCKED,
										messageKey: "cashier",
										handler: (isChecked, record) => {
											const d = {
												enabled: isChecked,
												id: record.id
											};
											changeCashierState(d);
										}
									},
									resend: {
										handler: (record) => setResendingUser({ id: record.id, name: record.userName }),
										title: t("pages.dashboard.users.resend_activation_email"),
										hidden: (record) => record.state !== USER_STATE.IN_PROGRESS,
										icon: <i className="icon-resend" />
									}
								}
							: {}),
						edit: generateEditAction(),
						logout: hasPermission({ resource: PERMISSION_RESOURCE.CASHIER_FORCE_LOGOUT, action: PERMISSION_ACTION.MODIFY })
							? {
									icon: <i className="icon-logout" />,
									type: "delete",
									disabled: (record) => !record.isOnline,
									handler: (record) => forceLogoutSingleCashier(record.id),
									title: t("common.logout"),
									message: (record) => t("pages.dashboard.cashiers.single_cashier_force_logout_message").replace("%CASHIER_NAME%", record.userName)
								}
							: null
					}}
					isDisabled={() => false}
				/>
				{showCreatePopup ? <CashierAddComponent onClose={() => setShowCreatePopup(false)} /> : null}
				<Question type="confirm" onOk={forceLogout} onCancel={() => setShowForceLogoutPopup(false)} isVisible={showForceLogoutPopup} message={t("pages.dashboard.cashiers.force_logout_message")} />
				<Question
					type="confirm"
					onOk={() => resendRegistrationEmail([resendingUser.id])}
					onCancel={() => setResendingUser(null)}
					isVisible={resendingUser !== null}
					message={t("pages.dashboard.users.resend_activation_email_msg").replace("%%USERNAME%%", resendingUser ? resendingUser.name : "")}
					title={t("pages.dashboard.users.resend_activation_email")}
				/>
			</div>
		</div>
	);
};

/** InternalCashiersComponent propTypes
 * PropTypes
 */
InternalCashiersComponent.propTypes = {
	/** Redux action to get cashiers */
	getCashiers: PropTypes.func,
	/** Redux action to force logout */
	forceLogout: PropTypes.func,
	/** Redux action to force logout single cashier */
	forceLogoutSingleCashier: PropTypes.func,
	/** Redux state property, represents the array of cashiers */
	cashiers: PropTypes.arrayOf(cashierType),
	/** Redux state property, cashiers total count */
	total: PropTypes.number,
	/** Redux action to set cashiers sorting details */
	setCashiersSorting: PropTypes.func,
	/** Redux action to set cashiers filters */
	setCashiersFilters: PropTypes.func,
	/** Redux state property, cashiers sorting details */
	sorting: sortingType,
	/** Redux state property, cashiers filters */
	filters: PropTypes.object,
	/** Redux state property, is true when loading cashiers */
	isLoading: PropTypes.bool,
	/** Redux state property, is true when saving cashier */
	isSaving: PropTypes.bool,
	/** Redux action to block/unblock cashiers */
	changeCashierState: PropTypes.func,
	/** Redux action to resend registration email */
	resendRegistrationEmail: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getCashiers: (fromFirstPage) => {
		dispatch(getCashiers(fromFirstPage));
	},
	setCashiersSorting: (sorting) => {
		dispatch(setCashiersSorting(sorting));
	},
	setCashiersFilters: (filters) => {
		dispatch(setCashiersFilters(filters));
	},
	changeCashierState: (data) => {
		dispatch(changeCashierState(data));
	},
	forceLogout: () => {
		dispatch(forceLogout());
	},
	forceLogoutSingleCashier: (id) => {
		dispatch(forceLogoutSingleCashier(id));
	},
	resendRegistrationEmail: (id) => {
		dispatch(resendRegistrationEmail(id));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.cashiers.isLoading,
		isSaving: state.cashiers.isSaving,
		cashiers: state.cashiers.cashiers,
		total: state.cashiers.total,
		sorting: state.cashiers.sorting,
		filters: state.cashiers.filters,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InternalCashiersComponent);
