import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Button, Checkbox, Spin, Select, Divider } from "antd";
const { Item: FormItem } = Form;

import SearchableSelect from "components/common/searchableSelect";
import HoursDropdown from "components/dashboard/common/hoursDropdown";
import PublicHolidays from "components/dashboard/common/publicHolidays";

import { WEEKDAYS } from "constants/common.constants";
import { WORKING_HOURS_TYPE } from "constants/partner.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { getBetshopOperatingHours, saveBetshopOperatingHours } from "store/actions/dashboard/retail/betshops/operatingHours.action";
import { getSystemTimeZones } from "store/actions/system/system.action";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import operatingHoursType from "types/betshop/operatingHours.type";

/** Betshop Edit Page Operating Hours Tab Component */
const OperatingHoursComponent = ({ isSaving, isLoading, operatingHours, getBetshopOperatingHours, saveBetshopOperatingHours, getSystemTimeZones, timezones, onTabChange }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_OPERATING_SETTINGS, action: PERMISSION_ACTION.MODIFY });

	/** Load betshop operating hours */
	useEffect(() => {
		getBetshopOperatingHours(routeParams.id);
	}, []);

	/** Load system timezones */
	useEffect(() => {
		if (timezones.length === 0) {
			getSystemTimeZones();
		}
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			...operatingHours
		});
	}, [operatingHours]);

	/** Fires when form submitted
	 * @function
	 * @memberOf OperatingHoursComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				saveBetshopOperatingHours({
					publicHolidays: data.publicHolidays,
					workingHours: data.workingHours,
					id: routeParams.id
				});
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					publicHolidays: {
						days: operatingHours.publicHolidays.days,
						disableCashierSoftware: operatingHours.publicHolidays.disableCashierSoftware
					},
					workingHours: {
						weekDays: Object.fromEntries(Object.entries(WEEKDAYS).map(([k, v], i) => [v, operatingHours.workingHours.weekDays[v]]))
					},
					timeZoneId: operatingHours.timeZoneId
				}}
				onValuesChange={(changed, formValues) =>
					setIsFormTouched(
						isFormChanged(
							{
								...formValues,
								workingHours: {
									...formValues.workingHours,
									weekDays: Object.fromEntries(Object.entries(WEEKDAYS).map(([k, v], i) => [v, formValues.workingHours.weekDays[v].type === WORKING_HOURS_TYPE.CUSTOM ? formValues.workingHours.weekDays[v] : { type: formValues.workingHours.weekDays[v].type }]))
								}
							},
							{
								...operatingHours,
								workingHours: {
									...operatingHours.workingHours,
									weekDays: Object.fromEntries(Object.entries(WEEKDAYS).map(([k, v], i) => [v, operatingHours.workingHours.weekDays[v].type === WORKING_HOURS_TYPE.CUSTOM ? operatingHours.workingHours.weekDays[v] : { type: operatingHours.workingHours.weekDays[v].type }]))
								}
							}
						)
					)
				}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[40, 0]}>
							<Col xs={24} sm={24} xxl={12}>
								<h4>{t("pages.dashboard.partners.retail_settings.retail_configs.working_hours")}</h4>
								<Row gutter={[16, 0]} className="hours-dropdown-wrapper">
									<Col xs={24} sm={12} md={12}>
										{Object.keys(WEEKDAYS).map((day, i) => {
											return i < 4 ? (
												<Row key={day}>
													<FormItem name={["workingHours", "weekDays", WEEKDAYS[day]]} style={{ width: "100%" }} label={t(`weekDays.${WEEKDAYS[day]}`)}>
														<HoursDropdown day={WEEKDAYS[day]} disabled={isDisabled || operatingHours.allowOverride !== true} />
													</FormItem>
												</Row>
											) : null;
										})}
									</Col>
									<Col xs={24} sm={12} md={12}>
										{Object.keys(WEEKDAYS).map((day, i) => {
											return i >= 4 ? (
												<Row key={i}>
													<FormItem name={["workingHours", "weekDays", WEEKDAYS[day]]} style={{ width: "100%" }} label={t(`weekDays.${WEEKDAYS[day]}`)}>
														<HoursDropdown day={WEEKDAYS[day]} disabled={isDisabled || operatingHours.allowOverride !== true} />
													</FormItem>
												</Row>
											) : null;
										})}
									</Col>
								</Row>
							</Col>

							<Col xs={24} sm={24} xxl={12}>
								<h4>{t("pages.dashboard.partners.retail_settings.retail_configs.public_holidays")}</h4>
								<Row gutter={[16, 0]}>
									<Col xs={24} sm={18} md={12}>
										<FormItem name={["publicHolidays", "days"]} label=" ">
											<PublicHolidays id="retail-configs-public-holidays" disabled={isDisabled || operatingHours.allowOverride !== true} />
										</FormItem>
									</Col>
								</Row>
							</Col>
						</Row>
						<Divider style={{ marginTop: "24px" }} />
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={`${t("pages.dashboard.betshops.timezone")} *`} name="timeZoneId" rules={[{ required: true, message: t("validation.field_required") }]}>
									<SearchableSelect items={timezones} valueProp={(item) => item.id} textProp={(item) => item.description} renderText={(item) => item.description} placeholder={t("pages.dashboard.betshops.select_timezone")} getPopupContainer={() => document.body} disabled={true} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.betshops.daylight_saving_time")} name="dst" rules={[{ required: true, message: t("validation.field_required") }]}>
									<Select disabled={true} suffixIcon={<i className="icon-down" />}>
										<Select.Option value="-1">-1</Select.Option>
										<Select.Option value="0">0</Select.Option>
										<Select.Option value="1">1</Select.Option>
									</Select>
								</FormItem>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<div className="inline-form-checkbox">
									<label>{t("pages.dashboard.partners.retail_settings.retail_configs.disable_cashier_when_closed")}</label>
									<FormItem className="inline-form-item-control" name={["publicHolidays", "disableCashierSoftware"]} valuePropName="checked">
										<Checkbox disabled={isDisabled || operatingHours.allowOverride !== true} />
									</FormItem>
								</div>
							</Col>
						</Row>
					</div>
					{!isDisabled && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** OperatingHoursComponent propTypes
 * PropTypes
 */
OperatingHoursComponent.propTypes = {
	/** Redux state property, is true when operating hours is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when operating hours is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the operating hours of current editing betshop  */
	operatingHours: operatingHoursType,
	/** Redux action to save betshop operating hours */
	saveBetshopOperatingHours: PropTypes.func,
	/** Redux action to get betshop operating hours */
	getBetshopOperatingHours: PropTypes.func,
	/** Redux action to get system timezones */
	getSystemTimeZones: PropTypes.func,
	/** Redux state property, represents the array of system timezones  */
	timezones: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			description: PropTypes.string
		})
	),
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getBetshopOperatingHours: (id) => {
		dispatch(getBetshopOperatingHours(id));
	},

	saveBetshopOperatingHours: (data) => {
		dispatch(saveBetshopOperatingHours(data));
	},

	getSystemTimeZones: () => {
		dispatch(getSystemTimeZones());
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.betshops.isSaving,
		isLoading: state.betshops.isLoading,
		operatingHours: state.betshops.editingBetshop.operatingHours,
		timezones: state.system.timezones
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OperatingHoursComponent);
