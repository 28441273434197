import { useMemo } from "react";
import PropTypes from "prop-types";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ColorGenerator } from "utils/color";
import BetshopSlider from "../BetshopSlider";

/** Dashboard Page Betslips Chart Component */
const PieChartComponent = ({ chartData, text, usedCount }) => {
	const { betsSum, data } = useMemo(() => {
		const colorGen = new ColorGenerator(chartData.length);
		return chartData.reduce(
			(acc, { item1: betshopName, item2: betsCount }) => {
				acc.betsSum += betsCount;
				acc.data.push({
					name: betshopName,
					color: colorGen.next().value,
					y: betsCount
				});
				return acc;
			},
			{ betsSum: 0, data: [] }
		);
	}, [chartData]);

	const isPieEmpty = betsSum === 0;

	return (
		<div className="bonus-chart">
			<div className="bonus-chart-content pie-chart">
				<div className="pie-chart-content">
					<HighchartsReact
						highcharts={Highcharts}
						options={{
							accessibility: {
								enabled: false
							},
							chart: {
								width: "280",
								type: "pie",
								marginTop: -80
							},
							legend: {
								align: "center",
								layout: "vertical",
								verticalAlign: "center",
								x: 0,
								y: 0
							},
							plotOptions: {
								pie: {
									size: "250",
									showInLegend: false,
									innerSize: isPieEmpty ? "0%" : "70%",
									dataLabels: {
										enabled: false
									}
								}
							},
							title: {
								text: ""
							},
							tooltip: {
								backgroundColor: "#4D4D4D",
								style: {
									color: "#FFFFFF"
								},
								formatter: function () {
									return this.key + " :  " + Math.round(this.percentage) + "%<br/>Count :  " + this.y;
								}
							},
							series: [
								{
									name: "Count",
									colorByPoint: true,
									data: isPieEmpty ? [] : data
								}
							]
						}}
					/>
					{text && (
						<div className="bonus-chart-content-empty">
							<span>{text}</span>
							<br />
							<span>{usedCount}</span>
						</div>
					)}
				</div>
				<div className="pie-chart-legend">
					<BetshopSlider list={data} totalAmt={betsSum} />
				</div>
			</div>
		</div>
	);
};

/** PieChartComponent propTypes
 * PropTypes
 */
PieChartComponent.propTypes = {
	/** Redux state property, represents the data of betslip activities */
	chartData: PropTypes.array,
	/** Text for chart */
	text: PropTypes.string,
	/** Text for empty chart */
	usedCount: PropTypes.number
};

export default PieChartComponent;
