import { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { message, Popover, Button, Tooltip } from "antd";

import ChangePasswordComponent from "../../profile/changePassword.component";

import Paths from "constants/api.constants";
import { USER_ROLE } from "constants/user.constants";

import Upload from "components/common/upload";

import { deleteUserAvatar } from "store/actions/dashboard/userManagement/users/avatar.action";
import { FILE_SIZES_MEASUREMENT } from "constants/common.constants";

/** User Edit Page General Info TabAvatar upload Component */
const AvatarUploadComponent = ({ userId, name, role, avatarId, deleteUserAvatar, onSuccess, canEdit, isProfile }) => {
	const { t } = useTranslation();

	/** State to show/hide change password popup */
	const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false);
	const [isPopoverVisible, setIsPopoverVisible] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [avatar, setAvatar] = useState(avatarId ? `${import.meta.env.SYSTEM_CDN_URL}/system/users/${userId}/${avatarId}_large.png` : null);

	useEffect(() => {
		setAvatar(avatarId ? `${import.meta.env.SYSTEM_CDN_URL}/system/users/${userId}/${avatarId}_large.png` : null);
	}, [avatarId]);

	/** Fires when logo uploaded
	 * @function
	 * @param {object} logo - the uploaded logo
	 * @memberOf AvatarUploadComponent
	 */
	const onUpload = (logo) => {
		const msg = logo?.response?.message;
		msg && message.success(msg);
		setAvatar(`${import.meta.env.SYSTEM_CDN_URL}/system/users/${userId}/${logo?.response?.value}_large.png`);
		setIsUploading(false);
		onSuccess && onSuccess(logo?.response?.value);
	};

	/** Fires on remove button click
	 * @function
	 * @param {object} e - event object
	 * @memberOf AvatarUploadComponent
	 */
	const handleAvatarDelete = (e) => {
		e.stopPropagation();
		deleteUserAvatar(
			userId,
			() => {
				setAvatar(null);
				onSuccess && onSuccess();
			},
			isProfile
		);
	};

	return (
		<div className="avatar-upload-container">
			<div className="avatar-upload">
				{canEdit && (
					<Popover
						content={
							<div className="avatar-upload-popover-content">
								<Tooltip title={<span dangerouslySetInnerHTML={{ __html: t("pages.dashboard.users.avatar_upload_tooltip") }}></span>}>
									<i className="icon-info avatar-upload-popover-info" />
								</Tooltip>
								<Upload
									action={`${import.meta.env.SYSTEM_API_URL}${isProfile ? Paths.USER_UPLOAD_PROFILE_AVATAR : Paths.USER_UPLOAD_AVATAR}`}
									onChange={onUpload}
									onBeforeUpload={() => {
										setIsUploading(true);
										setIsPopoverVisible(false);
									}}
									data={{ id: userId }}
									extensions={["image/png", "image/jpeg", "image/jpg", "image/bmp", "image/gif"]}
									size={FILE_SIZES_MEASUREMENT.MB}
									showUploadList={
										isUploading
											? {
													showDownloadIcon: false,
													showRemoveIcon: false
												}
											: false
									}
									disabled={!canEdit}
								>
									<Button type="primary" className="button">
										<span>{avatar ? t("pages.dashboard.users.change_photo") : t("pages.dashboard.users.upload_photo")}</span>
									</Button>
								</Upload>
								{avatar && (
									<Button
										className="button avatar-upload-popover-delete"
										onClick={(e) => {
											setIsPopoverVisible(false);
											handleAvatarDelete(e);
										}}
									>
										<span>{t("pages.dashboard.users.delete_photo")}</span>
									</Button>
								)}
							</div>
						}
						title="Title"
						trigger="click"
						placement="bottomLeft"
						overlayClassName="avatar-upload-popover"
						open={isPopoverVisible}
						onOpenChange={(visible) => setIsPopoverVisible(visible)}
					>
						<div className="avatar-upload-button" onClick={() => setIsPopoverVisible(true)}>
							<i className="icon-edit" />
						</div>
					</Popover>
				)}
				{avatar ? <img src={avatar} alt="avatar" /> : <i className="icon-avatar avatar-upload-icon" />}
			</div>
			{name && <h4 className="avatar-upload-name">{name}</h4>}
			{role && <span className="avatar-upload-role">{role === USER_ROLE.SUPER_ADMIN ? t("pages.dashboard.users.super_admin") : role === USER_ROLE.USER ? t("pages.dashboard.users.user") : role === USER_ROLE.DEVELOPER ? t("pages.dashboard.users.developer") : ""}</span>}
			{isProfile && (
				<a className="avatar-upload-change-password" onClick={() => setShowChangePasswordPopup(true)}>
					<span>{t("pages.dashboard.profile.change_password")}</span>
				</a>
			)}

			{showChangePasswordPopup ? <ChangePasswordComponent onClose={() => setShowChangePasswordPopup(false)} /> : null}
		</div>
	);
};

/** AvatarUploadComponent propTypes
 * PropTypes
 */
AvatarUploadComponent.propTypes = {
	/** User id */
	userId: PropTypes.string,
	/** User name */
	name: PropTypes.string,
	/** User role */
	role: PropTypes.oneOf(Object.values(USER_ROLE)),
	/** User avatar id */
	avatarId: PropTypes.number,
	/** Redux action to delete user avatar */
	deleteUserAvatar: PropTypes.func,
	/** Can user avatar */
	canEdit: PropTypes.bool,
	/** Function to fire on upload success */
	onSuccess: PropTypes.func,
	/** Is profile avatar */
	isProfile: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	deleteUserAvatar: (id, onSuccess, isProfile) => {
		dispatch(deleteUserAvatar(id, onSuccess, isProfile));
	}
});

export default connect(null, mapDispatchToProps)(AvatarUploadComponent);
