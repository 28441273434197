import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
	setBetshopsActionBefore,
	setBetshopsActionFinished,
	setBetshopSaveActionBefore,
	setBetshopSaveActionFinished
} from './betshops.action';

import {
	SET_BETSHOP_CURRENCY
} from 'store/actionTypes';
import { RESPONSE_STATE } from 'constants/response.constants';
import { message } from 'antd';

const setBetshopCurrency = currency => ({
	type: SET_BETSHOP_CURRENCY,
	payload: { currency }
})

export const getBetshopCurrency = (id, code) => {
	return dispatch => {
		dispatch(setBetshopsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BETSHOP_GET_CURRENCY}`,
			method: Methods.GET,
			params: { id, code }
		})
			.then(({ data: { value: currency } }) => {
				dispatch(setBetshopCurrency(currency))
				dispatch(setBetshopsActionFinished());
			})
			.catch((ex) => {
				dispatch(setBetshopsActionFinished());
			})
	}
}

export const saveBetshopCurrency = currency => {
	return dispatch => {
		dispatch(setBetshopSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BETSHOP_SAVE_CURRENCY}`,
			method: Methods.POST,
			data: currency
		})
			.then(({ data: { value: currency, status, message: msg } }) => {
				if(status === RESPONSE_STATE.SUCCESS){
					message.success(msg);
				}
				dispatch(setBetshopCurrency(currency))
				dispatch(setBetshopSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setBetshopSaveActionFinished());
			})
	}
}

export const deleteBetshopCurrency = (id, code) => {
	return dispatch => {
		dispatch(setBetshopSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BETSHOP_DELETE_CURRENCY}`,
			method: Methods.DELETE,
			data: { id, code }
		})
			.then(({ data: { value: currency } }) => {
				dispatch(getBetshopCurrency(id));
				dispatch(setBetshopSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setBetshopSaveActionFinished());
			})
	}
}


