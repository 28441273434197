import { useState, Fragment, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Row from "antd/lib/row";
import Col from "antd/lib/col";
import ChartLoader from "components/dashboard/statistics/charts/chart-loader";
import StreamServerItemHeader from "./streamServerItemHeader";
import AddStreamServerGame from "./streamServerItemHeader/addStreamServerGame";
import GaugeWidget from "./gaugeWidget";
import StreamServerGame from "./streamServerGame";
import StreamServerItemActionModal from "./streamServerItemActionModal";

import { restartStreamServer } from "store/actions/dashboard/streamServers";

const StreamServersItem = ({ name, id, games, cpu, ram, streamHealths = {}, restartStreamServer, state = 0 }) => {
	const [showAddGameModal, setShowAddGameModal] = useState(false);
	const [showRefreshServerModal, setShowRefreshServerModal] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const { t } = useTranslation();

	const serverItemSteamHealths = useMemo(() => streamHealths || {}, [streamHealths]);

	const handleOpenModal = (setState) => () => {
		setState(true);
	};

	const handleCloseModal = () => {
		setShowAddGameModal(false);
		setShowRefreshServerModal(false);
	};

	const handleSetLoading = useCallback((bool) => setIsLoading(bool), []);

	const getStreamHealthsKey = useCallback(
		(game = {}) => {
			const streamHealthsKeys = Object.keys(serverItemSteamHealths);

			let output = {
				nanocosmosBitrate: 0,
				flussonicInputBitrate: 0,
				flussonicOutputBitrate: 0
			};

			if (!streamHealthsKeys.length || !state) {
				return output;
			}

			for (let i = 0; i < streamHealthsKeys.length; i++) {
				const streamHealthKeyItem = `${streamHealthsKeys[i].toLowerCase()}`;

				const targetStreamHealthKey = `${game.name.toLowerCase()}${game.languageCode.toLowerCase()}`;

				if (streamHealthKeyItem === targetStreamHealthKey) {
					output = serverItemSteamHealths[`${streamHealthsKeys[i]}`];
					break;
				}
			}

			return output;
		},
		[serverItemSteamHealths]
	);

	useEffect(() => {
		if (isLoading) {
			setShowAddGameModal(false);
			setShowRefreshServerModal(false);
		}
	}, [isLoading]);

	return (
		<Col xl={8} lg={12} md={12} sm={24} xs={24} key={id}>
			<div className="stream-servers-item">
				<StreamServerItemHeader title={name} handleOpenAddGameModal={handleOpenModal(setShowAddGameModal)} handleOpenRefreshServerModal={handleOpenModal(setShowRefreshServerModal)} isAddGameDisabled={games.length >= 5} />
				<Row className="stream-servers-item-content">
					{isLoading ? <ChartLoader /> : null}
					<GaugeWidget title="CPU" percent={state ? cpu : 0} />
					<GaugeWidget title="RAM" percent={state ? ram : 0} />
					<Col span={24}>
						{games.map((game) => {
							return (
								<Fragment key={`${game.name}-${game.languageCode}`}>
									<StreamServerGame name={game.name} languageCode={game.languageCode} streamHealths={getStreamHealthsKey(game)} flussonicStreamName={game.flussonicStreamName} nanocosmosStreamName={game.nanocosmosStreamName} serverId={id} />
								</Fragment>
							);
						})}
					</Col>
				</Row>
			</div>

			<AddStreamServerGame handleCloseModal={handleCloseModal} isModalOpen={showAddGameModal} serverId={id} handleSetLoading={handleSetLoading} />

			{/* Stream server restart modal */}
			<StreamServerItemActionModal
				isModalOpen={showRefreshServerModal}
				title={t("pages.dashboard.developerSpaceServerStreams.restartModalTitle")}
				contentText={t("pages.dashboard.developerSpaceServerStreams.restartModalContentText", { serverName: name })}
				okText={t("pages.dashboard.developerSpaceServerStreams.restartModalOkText")}
				onClose={handleCloseModal}
				onOk={() => {
					handleSetLoading(true);
					restartStreamServer({ id }, () => {
						handleSetLoading(false);
					});
				}}
			/>
		</Col>
	);
};

StreamServersItem.propTypes = {
	// The name of each stream server item
	name: PropTypes.string,
	// The id of each stream server item
	id: PropTypes.string,
	// Steam healths of each stream server item
	streamHealths: PropTypes.object,
	// Redux state action of restarting stream server
	restartStreamServer: PropTypes.func,
	// Stream server item state prop indicator
	state: PropTypes.number
};

const mapDispatchToProps = (dispatch) => ({
	restartStreamServer: ({ id }, cb) => dispatch(restartStreamServer({ id }, cb))
});

export default connect(null, mapDispatchToProps)(StreamServersItem);
