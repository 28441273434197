import axios from "axios";
import localStorageUtils from "./localStorage";
import sessionStorageUtils from "./sessionStorage";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import Paths from "constants/path.constants";
import { USER_ROLE, USER_ROLE_NAME } from "constants/user.constants";
import store from "store/configureStore";
import { setTokenExpiration } from "store/actions/auth/auth.action";
import { setGlobalPartnerId } from "store/actions/dashboard/partners/partner.action";
import { TOKEN_EXPIRATION } from "constants/date.constants";

const pendingRequests = [];
let isRefreshingToken = false;

/** check if user is Authenticated
 * @function
 * @returns {boolean}
 */
export const isAuthenticated = () => {
	const authData = sessionStorageUtils.get("authorizationData");
	return !!authData;
};

/** get current user
 * @function
 * @returns {Object}
 */
export const getUser = () => {
	const authData = sessionStorageUtils.get("authorizationData");
	return authData
		? {
				isLoggedIn: true,
				token: authData.token,
				wsToken: authData.wsToken,
				refreshToken: authData.refreshToken,
				userName: authData.userName,
				role: authData.role,
				currencyCode: authData.currencyCode
			}
		: null;
};

/** User role enums.

*/

/**
 * Check user role which saved in session storage
 * @function doesUserHaveRoleOf
 * @param {RoleType} role Can be one of values USER_ROLE, USER_ROLE_NAME
 * @returns {boolean}
 * @typedef {(number | string)} RoleType one of enums USER_ROLE or USER_ROLE_NAME
 */

export const doesUserHaveRoleOf = (role) => {
	const type = typeof role;
	if (!["number", "string"].includes(type)) {
		throw new Error("Invalid role type. It must be number or string. Follow");
	}

	const mappedRole = type === "number" ? USER_ROLE_NAME[role] : role;

	return getUser()?.role === mappedRole;
};

/** login user
 * @function
 * @param {Object} user - user data got from user authenticate request
 */
export const loginUser = (user) => {
	sessionStorageUtils.set("authorizationData", user);
	startWatchingRefreshTokenExpiration();
};

/** logout user
 * @function
 * @param {string} message - if passed will show this as notification
 */
export const logout = (message) => {
	stopWatchingRefreshTokenExpiration();
	sessionStorageUtils.remove("authorizationData");
	sessionStorageUtils.remove("selected_partner_id");
	localStorageUtils.remove("filters");
	window.location.href = Paths.LOGIN;
};

/** Sends request to server to refresh token
 * @function
 * @param {string} refresh_token - refresh token
 * @param {string} userId - user id
 * @param {string} userName - user name
 * @param {Object} requestConfig - the request configuration which will be sends, when new tokens got from server
 * @returns {Promise}
 */
export const refreshToken = (refresh_token, userName, requestConfig) => {
	if (!isRefreshingToken) {
		isRefreshingToken = true;
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.REFRESH_TOKEN}`,
			method: Methods.POST,
			data: { refreshToken: refresh_token },
			headers: { grant_type: "refresh_token" }
		})
			.then(({ status, data: { value: authData } }) => {
				if (status === 200) {
					loginUser({ ...authData, userName });
					isRefreshingToken = false;
					pendingRequests.forEach((req) => {
						req.request &&
							axios
								.request(req.request)
								.then((d) => req.resolve(d))
								.catch((err) => req.reject(err));
					});
					pendingRequests.splice(0, pendingRequests.length);
					return requestConfig && axios.request(requestConfig);
				} else {
					logout();
				}
			})
			.catch(() => {
				isRefreshingToken = false;
			});
	} else {
		const obj = {
			request: requestConfig
		};
		const promise = new Promise((resolve, reject) => {
			obj.resolve = resolve;
			obj.reject = reject;
			pendingRequests.push(obj);
		});
		obj.promise = promise;
		return promise;
	}
};

let interval = null;
let timer = 0;

/** start interval, to check if token is near to be expired
 * @function
 */

export const startWatchingRefreshTokenExpiration = () => {
	stopWatchingRefreshTokenExpiration();
	interval = setInterval(() => {
		timer--;
		if (timer < 0) {
			logout();
		}
		store.dispatch(setTokenExpiration(timer));
	}, 1000);
};

/** stop interval, to check if token is near to be expired
 * @function
 */
const stopWatchingRefreshTokenExpiration = () => {
	clearInterval(interval);
	timer = TOKEN_EXPIRATION;
	store.dispatch(setTokenExpiration(timer));
};

export const checkIsLessThanMinute = (date) => {
	const now = Date.now();

	const unlockDate = isNaN(date) ? now : +date;

	return unlockDate - now < 60000;
};
