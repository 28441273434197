import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Tabs } from "antd";

import Breadcrumbs from "components/common/breadcrumbs";

import ActivityComponent from "../view/sections/activity";

import { setGlobalPartnerId } from "store/actions/dashboard/partners/partner.action";
import { getPartnerGeneralInfo } from "store/actions/dashboard/partners/generalInfo.action";

import Paths from "constants/path.constants";

/** Partner View Component */
const PartnerViewComponent = ({ setGlobalPartnerId, getPartnerGeneralInfo, partnerName }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const [activeTab, setActiveTab] = useState("1");

	/** Set current editing partner id as global partner id*/
	useEffect(() => {
		setGlobalPartnerId(routeParams.id);
	}, []);

	/** Load partner general info */
	useEffect(() => {
		getPartnerGeneralInfo();
	}, []);

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.partners"), path: Paths.DASHBOARD_PARTNERS }, { title: `${partnerName} ${t("pages.dashboard.partners.wallet_activity")}` }]} />
				<Tabs
					animated={false}
					onTabClick={(key) => setActiveTab(key)}
					items={[
						{
							key: "1",
							label: t("pages.dashboard.partners.viewTabs.transactions_history"),
							children: <ActivityComponent />
						}
					]}
				/>
			</div>
		</Fragment>
	);
};

/** PartnerEditComponent propTypes
 * PropTypes
 */
PartnerViewComponent.propTypes = {
	/** Redux action to set global partner id */
	setGlobalPartnerId: PropTypes.func,
	/** Redux state, represents the name of current editing partner  */
	partnerName: PropTypes.string,
	/** Redux action to get partner General info */
	getPartnerGeneralInfo: PropTypes.func,
	/** Object from router, with info about current route*/
	match: PropTypes.shape({
		/** query params of current route */
		params: PropTypes.object
	})
};

const mapDispatchToProps = (dispatch) => ({
	setGlobalPartnerId: (partnerId) => {
		dispatch(setGlobalPartnerId(partnerId));
	},
	getPartnerGeneralInfo: () => {
		dispatch(getPartnerGeneralInfo());
	}
});

const mapStateToProps = (state) => {
	return {
		partnerName: state.partner.generalInfo.name
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerViewComponent);
