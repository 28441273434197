import { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Tag, Button } from "antd";

import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { USER_ROLE, USER_STATE } from "constants/user.constants";

import { getUsers, setUsersSorting, setUsersFilters, changeUserState, deleteUser, forceLogout, resendRegistrationEmail, unlockUser } from "store/actions/dashboard/userManagement/users/users.action";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import UserAddComponent from "./users-add.component";
import UsersResendingEmails from "./users-resending.component";
import Filters from "./filters.component";
import ColumnsButton from "components/common/columnsButton";
import ExportButton from "components/common/exportButton";
import Question from "components/common/question";

import userType from "types/user/user.type";
import sortingType from "types/common/sorting.type";

import Paths from "constants/path.constants";
import ApiUrls from "constants/api.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import localStorageUtils from "utils/localStorage";
import { doesUserHaveRoleOf } from "utils/auth";

const getTagColorByUserStatus = (statusId) => {
	switch (statusId) {
		case USER_STATE.LOCKED:
			return "red";
		case USER_STATE.IN_PROGRESS:
			return "orange";
		case USER_STATE.ACTIVE:
			return "green";
		default:
			return "cyan";
	}
};

/** Users Page Component */
const UsersComponent = ({ getUsers, users, isLoading, isSaving, total, setUsersSorting, setUsersFilters, sorting, filters, changeUserState, deleteUser, forceLogout, resendRegistrationEmail, globalPartnerId, userId, unlockUser }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const isAccessManager = doesUserHaveRoleOf(USER_ROLE.ACCESS_MANAGER);

	/** State to show/hide user creation popup */
	const [showCreatePopup, setShowCreatePopup] = useState(false);

	/** State to show/hide force logout popup */
	const [showForceLogoutPopup, setShowForceLogoutPopup] = useState(false);

	/** User id to unlock and show/hide confirmation popup */
	const [userIdToUnlock, setUserIdToUnlock] = useState(null);

	/** State to show/hide resend activation email popup */
	const [resendingUser, setResendingUser] = useState(null);

	/** State to show/hide resend activation emails popup */
	const [resendingUsers, setResendingUsers] = useState(null);

	/** Columns list, which can be included/excluded */
	const columnsToInclude = [
		{ title: t("pages.dashboard.users.email"), key: "email" },
		{ title: t("pages.dashboard.users.id"), key: "id" },
		{ title: t("pages.dashboard.users.partners"), key: "partners" },
		{ title: t("pages.dashboard.users.permissions"), key: "permissionCount" },
		{ title: t("pages.dashboard.users.last_update_at"), key: "lastUpdate" },
		{ title: t("pages.dashboard.users.last_login_at"), key: "lastLogin" },
		{ title: t("pages.dashboard.users.last_password_change_at"), key: "lastPasswordChange" },
		{ title: t("pages.dashboard.users.last_blocked_at"), key: "lastBlocked" },
		{ title: t("pages.dashboard.users.last_blocked_by"), key: "lastBlockedBy" }
	];

	const [includedColumns, setIncludedColumns] = useState((localStorageUtils.get("selected_columns") || {})["users"] || []);

	/** Function which will fire on columns popup apply button click
	 * @function
	 * @param {array} selectedColumns - selected columns
	 * @memberOf UsersComponent
	 */
	const handleColumnsApply = (selectedColumns) => {
		setIncludedColumns(selectedColumns);
	};

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.users.first_name"),
			dataIndex: "firstName"
		},
		{
			title: t("pages.dashboard.users.last_name"),
			dataIndex: "lastName"
		},
		{
			title: t("pages.dashboard.users.username"),
			dataIndex: "userName"
		},
		{
			title: t("pages.dashboard.users.status"),
			dataIndex: "state",
			render: (status, record) => {
				const color = getTagColorByUserStatus(status);
				return (
					<Tag color={color}>
						{t("pages.dashboard.users.status_" + status)}
						{!isAccessManager && status === USER_STATE.LOCKED ? <i className="icon-edit" onClick={() => setUserIdToUnlock(record.id)} /> : null}
					</Tag>
				);
			}
		},
		{
			title: t("pages.dashboard.users.email"),
			dataIndex: "email"
		},
		{
			title: t("pages.dashboard.users.id"),
			dataIndex: "id"
		},
		{
			title: t("pages.dashboard.users.main_partner"),
			dataIndex: "mainPartner"
		},
		{
			title: t("pages.dashboard.users.partners"),
			dataIndex: "partners",
			multi: true,
			multiMapper: (value) => value,
			sorter: false
		},
		{
			title: t("pages.dashboard.users.projects"),
			dataIndex: "apiKeys",
			multi: true,
			multiMapper: (value) => value,
			sorter: false,
			center: true,
			align: "center"
		},
		{
			title: t("pages.dashboard.users.betshops"),
			dataIndex: "betShops",
			multi: true,
			multiMapper: (value) => value,
			sorter: false,
			align: "center"
		},
		{
			title: t("pages.dashboard.users.permissionGroups"),
			dataIndex: "permissionGroups",
			multi: true,
			multiMapper: (value) => value,
			sorter: false,
			align: "center"
		},
		{
			title: t("pages.dashboard.users.permissions"),
			dataIndex: "permissionCount",
			sorter: false
		},
		{
			title: t("pages.dashboard.users.created_at"),
			dataIndex: "created",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.users.last_update_at"),
			dataIndex: "lastUpdate",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.users.last_login_at"),
			dataIndex: "lastLogin",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "")
		},
		{
			title: t("pages.dashboard.users.last_password_change_at"),
			dataIndex: "lastPasswordChange",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "")
		},
		{
			title: t("pages.dashboard.users.last_blocked_at"),
			dataIndex: "lastBlocked",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "")
		},
		{
			title: t("pages.dashboard.users.last_blocked_by"),
			dataIndex: "lastBlockedBy"
		}
	];

	/** Keep included columns in local storage */
	useEffect(() => {
		const columns = localStorageUtils.get("selected_columns") || {};
		columns["users"] = includedColumns;
		localStorageUtils.set("selected_columns", columns);
	}, [includedColumns]);

	/** Close force logout popup after save */
	useEffect(() => {
		if (isSaving) {
			setShowForceLogoutPopup(false);
			setResendingUser(null);
		}
	}, [isSaving]);

	/* Specific case, view action busy for activiti functional */
	const generateAction = () => {
		if (!isAccessManager && hasPermission({ resource: PERMISSION_RESOURCE.USER, action: PERMISSION_ACTION.MODIFY })) {
			return {
				activate: {
					isChecked: (record) => record.state !== USER_STATE.BLOCKED,
					messageKey: "user",
					handler: (isChecked, record) => {
						const d = {
							enabled: isChecked,
							id: record.id
						};
						changeUserState(d);
					},
					disabled: (record) => record.id === userId
				},
				edit: {
					handler: (record) => {
						navigate(Paths.DASHBOARD_USERMANAGEMENT_USERS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, `${record.id}?PI=${globalPartnerId}`));
					}
				},
				resend: {
					handler: (record) => setResendingUser({ id: record.id, name: record.userName }),
					title: t("pages.dashboard.users.resend_activation_email"),
					hidden: (record) => record.isTesting || record.state !== USER_STATE.IN_PROGRESS,
					icon: <i className="icon-resend" />
				}
			};
		}
		if (isAccessManager) {
			return {
				activate: {
					isChecked: (record) => record.state !== USER_STATE.BLOCKED,
					messageKey: "user",
					handler: (isChecked, record) => {
						const d = {
							enabled: isChecked,
							id: record.id
						};
						changeUserState(d);
					},
					disabled: (record) => record.id === userId
				},
				edit: {
					handler: (record) => {
						navigate(Paths.DASHBOARD_USERMANAGEMENT_USERS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, `${record.id}?PI=${globalPartnerId}`));
					}
				},
				resend: {
					handler: (record) => setResendingUser({ id: record.id, name: record.userName }),
					title: t("pages.dashboard.users.resend_activation_email"),
					hidden: (record) => record.isTesting || record.state !== USER_STATE.IN_PROGRESS,
					icon: <i className="icon-resend" />
				}
			};
		}
		return {
			view: {
				handler: (record) => {
					navigate(Paths.DASHBOARD_USERMANAGEMENT_USERS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, `${record.id}?PI=${globalPartnerId}`));
				}
			}
		};
	};

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.users") }]} />
				<div className="dashboard-section-content">
					<div className="table-header">
						<div className="table-buttons-dropdowns">
							{hasPermission({ resource: PERMISSION_RESOURCE.USER, action: PERMISSION_ACTION.EXPORT }) && (
								<ExportButton columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t("pages.dashboard.menu.users")} url={ApiUrls.EXPORT_USERS} filters={filters} sorting={sorting} />
							)}
							<ColumnsButton columns={columnsToInclude} onApply={handleColumnsApply} defaultSelectedColumns={includedColumns} />
							{hasPermission({ resource: PERMISSION_RESOURCE.USER, action: PERMISSION_ACTION.MODIFY }) && (
								<div className="table-buttons-dropdowns-button table-buttons-dropdowns-button-resend">
									<Button onClick={() => setResendingUsers(true)} type="secondary">
										<i className="icon-resend" />
										{t("pages.dashboard.users.resend_activation_email")}
									</Button>
								</div>
							)}
							{hasPermission({ resource: PERMISSION_RESOURCE.USER, action: PERMISSION_ACTION.CREATE }) && (
								<div className="table-buttons-dropdowns-button">
									<Button onClick={() => setShowCreatePopup(true)} type="primary">
										{t("pages.dashboard.users.create_user")}
									</Button>
								</div>
							)}
							{hasPermission({ resource: PERMISSION_RESOURCE.USER_FORCE_LOGOUT, action: PERMISSION_ACTION.MODIFY }) && (
								<div className="table-buttons-dropdowns-button">
									<Button onClick={() => setShowForceLogoutPopup(true)} type="secondary">
										{t("pages.dashboard.users.force_logout")}
									</Button>
								</div>
							)}
						</div>
						<Filters />
					</div>

					<Table
						loading={isLoading}
						columns={columns.filter((c) => !columnsToInclude.find((col) => col.key === c.dataIndex) || includedColumns.includes(c.dataIndex))}
						data={users}
						loadFn={getUsers}
						sorting={sorting}
						filters={filters}
						setSortingFn={setUsersSorting}
						setFiltersFn={setUsersFilters}
						total={total}
						updateProps={[globalPartnerId]}
						enableReload={true}
						actions={generateAction()}
						isDisabled={() => false}
					/>
				</div>
			</div>

			{showCreatePopup ? <UserAddComponent onClose={() => setShowCreatePopup(false)} /> : null}
			{resendingUsers ? <UsersResendingEmails onClose={() => setResendingUsers(false)} /> : null}

			<Question type="confirm" onOk={forceLogout} onCancel={() => setShowForceLogoutPopup(false)} isVisible={showForceLogoutPopup} message={t("pages.dashboard.users.force_logout_message")} title={t("pages.dashboard.users.force_logout")} />

			<Question
				type="confirm"
				onOk={() => resendRegistrationEmail([resendingUser.id])}
				onCancel={() => setResendingUser(null)}
				isVisible={resendingUser !== null}
				message={t("pages.dashboard.users.resend_activation_email_msg").replace("%%USERNAME%%", resendingUser ? resendingUser.name : "")}
				title={t("pages.dashboard.users.resend_activation_email")}
			/>

			<Question
				type="confirm"
				onOk={() => {
					unlockUser(userIdToUnlock);
					setUserIdToUnlock(null);
				}}
				onCancel={() => setUserIdToUnlock(null)}
				isVisible={Boolean(userIdToUnlock)}
				title={t("pages.dashboard.users.user_unlock_confirmation_popup_title")}
			/>
		</Fragment>
	);
};

/** UsersComponent propTypes
 * PropTypes
 */
UsersComponent.propTypes = {
	/** Redux action to get users */
	getUsers: PropTypes.func,
	/** Redux state property, represents the array of users */
	users: PropTypes.arrayOf(userType),
	/** Redux state property, users total count */
	total: PropTypes.number,
	/** Redux action to set users sorting details */
	setUsersSorting: PropTypes.func,
	/** Redux action to set users filters */
	setUsersFilters: PropTypes.func,
	/** Redux state property, users sorting details */
	sorting: sortingType,
	/** Redux state property, users filters */
	filters: PropTypes.object,
	/** Redux state property, is true when loading users */
	isLoading: PropTypes.bool,
	/** Redux state property, is true when saving user */
	isSaving: PropTypes.bool,
	/** Redux action to block/unblock user */
	changeUserState: PropTypes.func,
	/** Redux action to delete user */
	deleteUser: PropTypes.func,
	/** Redux action to resend registration email */
	resendRegistrationEmail: PropTypes.func,
	/** Redux action to force logout */
	forceLogout: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, current user id */
	userId: PropTypes.string,
	/** Redux action to unlock user */
	unlockUser: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getUsers: (fromFirstPage) => {
		dispatch(getUsers(fromFirstPage));
	},
	setUsersSorting: (sorting) => {
		dispatch(setUsersSorting(sorting));
	},
	setUsersFilters: (filters) => {
		dispatch(setUsersFilters(filters));
	},
	changeUserState: (data) => {
		dispatch(changeUserState(data));
	},
	deleteUser: (id) => {
		dispatch(deleteUser(id));
	},
	forceLogout: () => {
		dispatch(forceLogout());
	},
	resendRegistrationEmail: (id) => {
		dispatch(resendRegistrationEmail(id));
	},
	unlockUser: (id) => {
		dispatch(unlockUser(id));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.users.isLoading,
		isSaving: state.users.isSaving,
		users: state.users.users,
		total: state.users.total,
		sorting: state.users.sorting,
		filters: state.users.filters,
		globalPartnerId: state.partner.globalPartnerId,
		userId: state.profile.userInfo.id
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersComponent);
