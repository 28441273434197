import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { PROJECT_TYPE, REPORT_TYPE } from "constants/common.constants";

import { PROJECT_PERFORMANCE_REPORT_BEFORE, PROJECT_PERFORMANCE_REPORT_FINISH, SET_PROJECT_PERFORMANCE_REPORT, SET_PROJECT_PERFORMANCE_REPORT_FILTERS } from "store/actionTypes";

export const setProjectPerformanceReportBefore = () => ({
	type: PROJECT_PERFORMANCE_REPORT_BEFORE
});

export const setProjectPerformanceReportFinished = () => ({
	type: PROJECT_PERFORMANCE_REPORT_FINISH
});

const setProjectPerformanceReport = (projectPerformance) => ({
	type: SET_PROJECT_PERFORMANCE_REPORT,
	payload: { projectPerformance }
});

export const setProjectPerformanceReportFilters = (filters) => ({
	type: SET_PROJECT_PERFORMANCE_REPORT_FILTERS,
	payload: { filters }
});

export const getProjectPerformanceReport = () => {
	return (dispatch, getState) => {
		dispatch(setProjectPerformanceReportBefore());
		const filters = getState().reports.projectPerformance.filters;
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PROJECT_PERFORMANCE_REPORT}`,
			method: Methods.GET,
			params: {
				...filters,
				gameCategory: filters.gameCategory === "*" ? "" : filters.gameCategory,
				projectType: PROJECT_TYPE.ONLINE,
				reportType: REPORT_TYPE.BY_ACTION_TIME
			}
		})
			.then(({ data: { value: projectPerformance } }) => {
				dispatch(
					setProjectPerformanceReport(
						projectPerformance.map((reportObj) => ({
							...reportObj,
							uniqueKey: `${reportObj.id} | ${reportObj.currencyCode}`
						}))
					)
				);
				dispatch(setProjectPerformanceReportFinished());
			})
			.catch((ex) => {
				dispatch(setProjectPerformanceReportFinished());
			});
	};
};
