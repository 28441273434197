import {
	BONUSES_BEFORE,
	BONUSES_FINISH,
	JACKPOT_BONUSES_BEFORE,
	JACKPOT_BONUSES_FINISH,
	BONUS_BEFORE,
	BONUS_FINISH,
	JACKPOT_BONUS_BEFORE,
	JACKPOT_BONUS_FINISH,
	SET_STANDARD_BONUSES,
	SET_JACKPOT_BONUSES,
	SET_JACKPOT_BONUSES_FILTERS,
	SET_JACKPOT_BONUSES_SORTING,
	SET_BONUSES_FILTERS,
	SET_BONUSES_SORTING,
	SET_BONUS_GENERAL_INFO,
	SET_JACKPOT_GENERAL_INFO,
	SET_JACKPOT_HISTORY_BEFORE,
	SET_JACKPOT_HISTORY_FINISH,
	SET_JACKPOT_HISTORY,
	SET_JACKPOT_HISTORY_SORTING,
	SET_JACKPOT_HISTORY_FILTERS,
	CHANGE_STANDARD_BONUS_STATE,
	SET_BONUS_AVAILABLE_BETSHOPS,
	SET_BONUS_JACKPOT_AVAILABLE_BETSHOPS,
	SET_BONUS_AVAILABLE_GAMES,
	SET_BONUS_JACKPOT_AVAILABLE_GAMES,
	SET_BONUS_QUICK_INFO,
	SET_JACKPOT_QUICK_INFO,
	SET_BONUS_PRINT_VOUCHERS,
	SET_BONUS_PRINT_VOUCHERS_TRANSLATIONS,
	CHANGE_JACKPOT_BONUS_STATE
} from '../../actionTypes';


export default (state = {}, { type, payload }) => {
	switch (type) {
		case BONUSES_BEFORE:
			return {
				...state,
				standard: {
					...state.standard,
					isLoading: true
				}
			}
		case BONUSES_FINISH:
			return {
				...state,
				standard: {
					...state.standard,
					isLoading: false
				}
			}
		case JACKPOT_BONUSES_BEFORE:
			return {
				...state,
				jackpots: {
					...state.jackpots,
					isLoading: true
				}
			}
		case JACKPOT_BONUSES_FINISH:
			return {
				...state,
				jackpots: {
					...state.jackpots,
					isLoading: false
				}
			}
		case BONUS_BEFORE:
			return {
				...state,
				standard: {
					...state.standard,
					isSaving: true
				}
			}
		case BONUS_FINISH:
			return {
				...state,
				standard: {
					...state.standard,
					isSaving: false
				}
			}
		case SET_STANDARD_BONUSES:
			return {
				...state,
				standard: {
					...state.standard,
					data: payload.bonuses.item2,
					total: payload.bonuses.item1
				}
			}
		case JACKPOT_BONUS_BEFORE:
			return {
				...state,
				jackpots: {
					...state.jackpots,
					isSaving: true
				}
			}
		case JACKPOT_BONUS_FINISH:
			return {
				...state,
				jackpots: {
					...state.jackpots,
					isSaving: false
				}
			}
		case SET_JACKPOT_BONUSES:
			return {
				...state,
				jackpots: {
					...state.jackpots,
					data: payload.data.item2,
					total: payload.data.item1
				}
			}
		case SET_JACKPOT_BONUSES_FILTERS:
			return {
				...state,
				jackpots: {
					...state.jackpots,
					filters: payload.filters
				}
			}
		case SET_JACKPOT_BONUSES_SORTING:
			return {
				...state,
				jackpots: {
					...state.jackpots,
					sorting: payload.sorting
				}
			}
		case SET_JACKPOT_QUICK_INFO:
			return {
				...state,
				jackpots: {
					...state.jackpots,
					quickInfo: payload.data
				}
			}
		case SET_BONUSES_SORTING:
			return {
				...state,
				standard: {
					...state.standard,
					sorting: payload.sorting
				}
			}
		case SET_BONUSES_FILTERS:
			return {
				...state,
				standard: {
					...state.standard,
					filters: payload.filters
				}
			}
		case CHANGE_STANDARD_BONUS_STATE:
			return {
				...state,
				standard: {
					...state.standard,
					data: state.standard.data.map(b => b.id !== payload.data.id ? { ...b } : { ...b, ...payload.data })
				}
			}
		case CHANGE_JACKPOT_BONUS_STATE:
			return {
				...state,
				jackpots: {
					...state.jackpots,
					data: state.jackpots.data.map(b => b.id !== payload.data.id ? { ...b } : { ...b, ...payload.data })
				}
			}
		case SET_BONUS_GENERAL_INFO:
			return {
				...state,
				standard: {
					...state.standard,
					editingBonus: {
						...state.standard.editingBonus,
						generalInfo: {
							...payload.info
						}
					}
				}
			}
		case SET_JACKPOT_GENERAL_INFO:
			return {
				...state,
				jackpots: {
					...state.jackpots,
					editingJackpot: {
						...state.jackpots.editingJackpot,
						generalInfo: {
							...payload.info
						}
					}
				}
			}
		case SET_JACKPOT_HISTORY_BEFORE:
			return {
				...state,
				jackpots: {
					...state.jackpots,
					editingJackpot: {
						...state.jackpots.editingJackpot,
						history: {
							...state.jackpots.editingJackpot.history,
							isLoading: true
						}
					}
				}
			}
		case SET_JACKPOT_HISTORY_FINISH:
			return {
				...state,
				jackpots: {
					...state.jackpots,
					editingJackpot: {
						...state.jackpots.editingJackpot,
						history: {
							...state.jackpots.editingJackpot.history,
							isLoading: false
						}
					}
				}
			}
		case SET_JACKPOT_HISTORY:
			return {
				...state,
				jackpots: {
					...state.jackpots,
					editingJackpot: {
						...state.jackpots.editingJackpot,
						history: {
							...state.jackpots.editingJackpot.history,
							data: payload.data.item2,
							total: payload.data.item1
						}
					}
				}
			}
		case SET_JACKPOT_HISTORY_SORTING:
			return {
				...state,
				jackpots: {
					...state.jackpots,
					editingJackpot: {
						...state.jackpots.editingJackpot,
						history: {
							...state.jackpots.editingJackpot.history,
							sorting: payload.sorting
						}
					}
				}
			}
		case SET_JACKPOT_HISTORY_FILTERS:
			return {
				...state,
				jackpots: {
					...state.jackpots,
					editingJackpot: {
						...state.jackpots.editingJackpot,
						history: {
							...state.jackpots.editingJackpot.history,
							filters: payload.filters
						}
					}
				}
			}
		case SET_BONUS_AVAILABLE_BETSHOPS:
			return {
				...state,
				standard: {
					...state.standard,
					betshops: payload.data
				}
			}
		case SET_BONUS_JACKPOT_AVAILABLE_BETSHOPS:
			return {
				...state,
				jackpots: {
					...state.jackpots,
					betshops: payload.data
				}
			}
		case SET_BONUS_AVAILABLE_GAMES:
			return {
				...state,
				standard: {
					...state.standard,
					games: payload.data
				}
			}
		case SET_BONUS_JACKPOT_AVAILABLE_GAMES:
			return {
				...state,
				jackpots: {
					...state.jackpots,
					games: payload.data
				}
			}
		case SET_BONUS_QUICK_INFO:
			return {
				...state,
				standard: {
					...state.standard,
					quickInfo: payload.data
				}
			}
		case SET_BONUS_PRINT_VOUCHERS:
			return {
				...state,
				standard: {
					...state.standard,
					printVouchers: payload.data
				}
			}
		case SET_BONUS_PRINT_VOUCHERS_TRANSLATIONS:
			return {
				...state,
				standard: {
					...state.standard,
					printVouchersTranslations: payload.translations
				}
			}
		default:
			return state;
	}
}
