import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { PLAYERS_SET_PLAYER_SESSIONS, PLAYERS_SET_SESSIONS_SORTING, PLAYERS_SET_SESSIONS_FILTERS } from "store/actionTypes";

import { PROJECT_TYPE } from "constants/common.constants";

import { setPlayersActionBefore, setPlayersActionFinished } from "./players.action";

const setPlayerSessions = (data) => ({
	type: PLAYERS_SET_PLAYER_SESSIONS,
	payload: { data }
});

export const setPlayerSessionsFilters = (filters) => ({
	type: PLAYERS_SET_SESSIONS_FILTERS,
	payload: { filters }
});

export const setPlayerSessionsSorting = (sorting) => ({
	type: PLAYERS_SET_SESSIONS_SORTING,
	payload: { sorting }
});

export const getPlayerSessions = (id) => {
	return (dispatch, getState) => {
		dispatch(setPlayersActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PLAYER_SESSIONS}`,
			method: Methods.GET,
			params: {
				...getState().players.viewingPlayer.sessions.sorting,
				...getState().players.viewingPlayer.sessions.filters,
				userNameOrId: id,
				projectType: PROJECT_TYPE.ONLINE
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setPlayerSessions(data));
				dispatch(setPlayersActionFinished());
			})
			.catch(() => {
				dispatch(setPlayersActionFinished());
			});
	};
};
