import { GET_ALL_STREAM_SERVERS, SET_ALL_STREAM_SERVERS, GET_ALL_STREAM_SERVERS_FINISH, SET_AVAILABLE_STREAM_SERVER_GAMES } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case GET_ALL_STREAM_SERVERS:
			return {
				...state,
				isAllLoading: true
			};
		case SET_ALL_STREAM_SERVERS:
			return {
				...state,
				data: payload.data
			};
		case GET_ALL_STREAM_SERVERS_FINISH:
			return {
				...state,
				isAllLoading: false
			};
		case SET_AVAILABLE_STREAM_SERVER_GAMES:
			return {
				...state,
				availableGames: payload
			};
		default:
			return state;
	}
};
