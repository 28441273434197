import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Button, Select, Divider, Switch, Spin, Input, Tooltip, Radio } from "antd";
const { Item: FormItem } = Form;
import HoursDropdown from "components/dashboard/common/hoursDropdown";
import PublicHolidays from "components/dashboard/common/publicHolidays";
import { WEEKDAYS, SHOP_LIMIT_VALUES, REDEMPTION_DATE_VALUES, FILE_SIZES_MEASUREMENT } from "constants/common.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { WORKING_HOURS_TYPE, PAYMENT_TYPE } from "constants/partner.constants";
import { URL_REGEX } from "constants/regex.constants";
import { getPartnerRetailConfigs, savePartnerRetailConfigs, deleteLogo, setLogoId } from "store/actions/dashboard/partners/retailSettings.action";
import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";
import { copyToClipboard } from "utils/common";
import retailConfigsType from "types/partner/retailConfigs.type";
import BannerUploadWithComfirm from "components/dashboard/common/bannerUploadWithComfirm";
import ApiUrls from "constants/api.constants";

/** Partner Edit Page Retail Tab Retail Config subTab Component */
const RetailConfigsComponent = ({ isSaving, isLoading, retailConfigs, getPartnerRetailConfigs, savePartnerRetailConfigs, onTabChange, deleteLogo, setLogoId }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [paymentType, setPaymentType] = useState(null);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_RETAIL_CONFIGURATION, action: PERMISSION_ACTION.MODIFY });

	/** Load partner retail configs */
	useEffect(() => {
		getPartnerRetailConfigs();
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			...retailConfigs
		});

		setPaymentType(retailConfigs.paymentType);
	}, [retailConfigs]);

	/** Fires when form submitted
	 * @function
	 * @memberOf RetailConfigsComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				savePartnerRetailConfigs({
					...data,
					showBetRejectPopup: data.paymentType === PAYMENT_TYPE.CASH ? retailConfigs.showBetRejectPopup : data.showBetRejectPopup
				});
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					betShopCountLimit: retailConfigs.betShopCountLimit,
					ticketRedemptionPeriod: retailConfigs.ticketRedemptionPeriod,
					allowCancellationByCashier: retailConfigs.allowCancellationByCashier,
					secret: retailConfigs.secret,
					endPointURL: retailConfigs.endPointURL,
					hotKeyEnabled: retailConfigs.hotKeyEnabled,
					paymentType: retailConfigs.paymentType,
					showBetRejectPopup: retailConfigs.showBetRejectPopup,
					lastBetReprintEnabled: retailConfigs.lastBetReprintEnabled,
					sendAdditionalData: retailConfigs.sendAdditionalData,
					publicHolidays: {
						days: retailConfigs.publicHolidays.days,
						disableCashierSoftware: retailConfigs.publicHolidays.disableCashierSoftware
					},
					workingHours: {
						weekDays: Object.fromEntries(Object.entries(WEEKDAYS).map(([k, v], i) => [v, retailConfigs.workingHours.weekDays[v]])),
						allowOverride: retailConfigs.workingHours.allowOverride
					},
					logoId: retailConfigs.logoId
				}}
				onValuesChange={(changed, formValues) =>
					setIsFormTouched(
						isFormChanged(
							{
								...formValues,
								workingHours: {
									...formValues.workingHours,
									weekDays: Object.fromEntries(Object.entries(WEEKDAYS).map(([k, v], i) => [v, formValues.workingHours.weekDays[v].type === WORKING_HOURS_TYPE.CUSTOM ? formValues.workingHours.weekDays[v] : { type: formValues.workingHours.weekDays[v].type }]))
								}
							},
							{
								...retailConfigs,
								workingHours: {
									...retailConfigs.workingHours,
									weekDays: Object.fromEntries(Object.entries(WEEKDAYS).map(([k, v], i) => [v, retailConfigs.workingHours.weekDays[v].type === WORKING_HOURS_TYPE.CUSTOM ? retailConfigs.workingHours.weekDays[v] : { type: retailConfigs.workingHours.weekDays[v].type }]))
								}
							}
						)
					)
				}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={24} md={8} lg={8} xl={6}>
								<FormItem label={t("pages.dashboard.partners.retail_settings.retail_configs.endpoint_url")} name="endPointURL" rules={[{ pattern: URL_REGEX, message: t("validation.url_format") }]}>
									<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.retail_settings.retail_configs.endpoint_url")}`} disabled={isDisabled} />
								</FormItem>
							</Col>
							<Col xs={24} sm={24} md={8} lg={8} xl={6}>
								<FormItem label={`${t("pages.dashboard.partners.retail_settings.retail_configs.secret_key")}`} name="secret">
									<Input disabled placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.retail_settings.retail_configs.secret_key")}`} className="form-input-with-copy" />
								</FormItem>
								<Tooltip title={t("common.copy") + " " + t("pages.dashboard.partners.retail_settings.retail_configs.secret_key")} getPopupContainer={() => document.body}>
									<div className="form-copy-button" onClick={() => copyToClipboard(retailConfigs.secret)}>
										<i className="icon-copy"></i>
									</div>
								</Tooltip>
							</Col>
							<Col xs={24} sm={24} md={8} lg={8} xl={6}>
								<FormItem label={t("pages.dashboard.partners.retail_settings.retail_configs.shop_limit")} name="betShopCountLimit">
									<Select suffixIcon={<i className="icon-down" />} disabled={isDisabled}>
										<Select.Option value={0}>{t("common.unlimited")}</Select.Option>
										{SHOP_LIMIT_VALUES.map((el) => (
											<Select.Option value={el} key={el} disabled={el < retailConfigs.betShopCount}>
												{el}
											</Select.Option>
										))}
									</Select>
								</FormItem>
							</Col>
							<Col xs={24} sm={24} md={8} lg={8} xl={6}>
								<FormItem label={t("pages.dashboard.partners.retail_settings.retail_configs.ticket_redemption_date")} name="ticketRedemptionPeriod">
									<Select suffixIcon={<i className="icon-down" />} disabled={isDisabled}>
										<Select.Option value={REDEMPTION_DATE_VALUES.NEVER}>{t("common.never")}</Select.Option>
										<Select.Option value={REDEMPTION_DATE_VALUES.SEVEN_DAYS}>{`7 ${t("common.days")}`}</Select.Option>
										<Select.Option value={REDEMPTION_DATE_VALUES.FORTEEN_DAYS}>{`14 ${t("common.days")}`}</Select.Option>
										<Select.Option value={REDEMPTION_DATE_VALUES.THIRTHY_DAYS}>{`30 ${t("common.days")}`}</Select.Option>
										<Select.Option value={REDEMPTION_DATE_VALUES.FORTYFIVE_DAYS}>{`45 ${t("common.days")}`}</Select.Option>
										<Select.Option value={REDEMPTION_DATE_VALUES.SIXTY_DAYS}>{`60 ${t("common.days")}`}</Select.Option>
										<Select.Option value={REDEMPTION_DATE_VALUES.NINTY_DAYS}>{`90 ${t("common.days")}`}</Select.Option>
										<Select.Option value={REDEMPTION_DATE_VALUES.HUNDREDTWENTY_DAYS}>{`120 ${t("common.days")}`}</Select.Option>
									</Select>
								</FormItem>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<div className="inline-form-item form-switcher form-item-without-margin">
									<label>{t("pages.dashboard.partners.retail_settings.retail_configs.allow_bet_cancellation_by_cashier")}</label>
									<FormItem name="allowCancellationByCashier" valuePropName="checked" className="ant-row">
										<Switch disabled={isDisabled} />
									</FormItem>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="inline-form-item form-switcher form-item-without-margin">
									<label>{t("pages.dashboard.partners.retail_settings.retail_configs.hotkey")}</label>
									<FormItem name="hotKeyEnabled" valuePropName="checked" className="ant-row">
										<Switch disabled={isDisabled} />
									</FormItem>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="inline-form-item form-switcher">
									<label>{t("pages.dashboard.partners.retail_settings.retail_configs.enable_reprint")}</label>
									<FormItem name="lastBetReprintEnabled" valuePropName="checked" className="ant-row">
										<Switch disabled={isDisabled} />
									</FormItem>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="inline-form-item form-switcher form-item-without-margin">
									<label>{t("pages.dashboard.partners.retail_settings.retail_configs.send_additional_data")}</label>
									<FormItem name="sendAdditionalData" valuePropName="checked" className="ant-row">
										<Switch disabled={isDisabled} />
									</FormItem>
								</div>
							</Col>
						</Row>
						<Row gutter={[40, 0]}>
							<Col xs={24} sm={24} xxl={12}>
								<Row gutter={[16, 0]} className="hours-dropdown-wrapper">
									<Col span={24}>
										<div className="dashboard-section-info" style={{ marginBottom: 0 }}>
											<h4>{t("pages.dashboard.partners.retail_settings.retail_configs.logo")}</h4>
											<Tooltip title={<span dangerouslySetInnerHTML={{ __html: t("pages.dashboard.betshops.betshop_logo_tooltip") }}></span>}>
												<i className="icon-info" />
											</Tooltip>
										</div>
									</Col>
									<Col xs={24} sm={12} md={12}>
										{retailConfigs.logoId !== undefined && (
											<FormItem label={null} className="dashboard-customization-upload">
												<BannerUploadWithComfirm
													uploadUrl={`${import.meta.env.SYSTEM_API_URL}${ApiUrls.PARTNER_UPLOAD_LOGO}`}
													defaultFile={
														retailConfigs.logoId
															? {
																	url: `${import.meta.env.SYSTEM_CDN_URL}/${routeParams.id}/images/${retailConfigs.logoId}_betshoplogo.png`,
																	status: "done",
																	percent: 100
																}
															: null
													}
													remove={
														!isDisabled
															? {
																	handler: (data) => deleteLogo(data),
																	message: t("pages.dashboard.betshops.remove_logo_confirmation_message")
																}
															: null
													}
													data={{ id: routeParams.id }}
													fileBuilder={(value) => ({ url: `${import.meta.env.SYSTEM_CDN_URL}/${routeParams.id}/images/${value}_betshoplogo.png` })}
													extensions={["image/png"]}
													size={FILE_SIZES_MEASUREMENT.MB}
													disabled={isDisabled}
													disablePreview={true}
													onSuccess={(logoId) => setLogoId(logoId)}
													isLogoExists={!!retailConfigs.logoId}
												/>
											</FormItem>
										)}
									</Col>
								</Row>
							</Col>
						</Row>
						<Divider style={{ marginBottom: "8px", marginTop: "24px" }} />
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h4>{t("pages.dashboard.partners.retail_settings.retail_configs.payment_settings")}</h4>
								<Row gutter={[16, 0]}>
									<Col xs={24} sm={24} md={8} lg={8} xl={6}>
										<FormItem label={`${t("pages.dashboard.partners.retail_settings.retail_configs.payment_type")} *`} name="paymentType">
											<Select suffixIcon={<i className="icon-down" />} disabled={isDisabled} onChange={(e) => setPaymentType(e)}>
												<Select.Option value={PAYMENT_TYPE.BOTH}>{t("pages.dashboard.partners.retail_settings.retail_configs.both")}</Select.Option>
												<Select.Option value={PAYMENT_TYPE.CASH}>{t("pages.dashboard.partners.retail_settings.retail_configs.cash")}</Select.Option>
												<Select.Option value={PAYMENT_TYPE.CREDIT_CARD}>{t("pages.dashboard.partners.retail_settings.retail_configs.credit_card")}</Select.Option>
											</Select>
										</FormItem>
									</Col>
								</Row>
								{(paymentType === PAYMENT_TYPE.CREDIT_CARD || paymentType === PAYMENT_TYPE.BOTH) && (
									<Row gutter={[16, 0]}>
										<Col xs={24} sm={24}>
											<FormItem label={t("pages.dashboard.partners.retail_settings.retail_configs.credit_card_payment_confirmation_type")} name="showBetRejectPopup">
												<Radio.Group
													options={[
														{ label: <span>{t("pages.dashboard.partners.retail_settings.retail_configs.manual")}</span>, value: true },
														{ label: <span>{t("pages.dashboard.partners.retail_settings.retail_configs.automatic")}</span>, value: false }
													]}
													disabled={isDisabled}
												></Radio.Group>
											</FormItem>
										</Col>
									</Row>
								)}
							</Col>
						</Row>
						<Divider style={{ marginBottom: "8px", marginTop: "8px" }} />
						<Row gutter={[40, 0]}>
							<Col xs={24} sm={24} xxl={12}>
								<h4>{t("pages.dashboard.partners.retail_settings.retail_configs.working_hours")}</h4>
								<Row gutter={[16, 0]} className="hours-dropdown-wrapper">
									<Col xs={24} sm={12} md={12}>
										{Object.keys(WEEKDAYS).map((day, i) => {
											return i < 4 ? (
												<Row key={day}>
													<Col span={24}>
														<FormItem name={["workingHours", "weekDays", WEEKDAYS[day]]} style={{ width: "100%" }} label={t(`weekDays.${WEEKDAYS[day]}`)}>
															<HoursDropdown day={WEEKDAYS[day]} disabled={isDisabled} />
														</FormItem>
													</Col>
												</Row>
											) : null;
										})}
									</Col>
									<Col xs={24} sm={12} md={12}>
										{Object.keys(WEEKDAYS).map((day, i) => {
											return i >= 4 ? (
												<Row key={i}>
													<Col span={24}>
														<FormItem name={["workingHours", "weekDays", WEEKDAYS[day]]} style={{ width: "100%" }} label={t(`weekDays.${WEEKDAYS[day]}`)}>
															<HoursDropdown day={WEEKDAYS[day]} disabled={isDisabled} />
														</FormItem>
													</Col>
												</Row>
											) : null;
										})}
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<div className="inline-form-item">
											<label>{t("common.allow_override")}</label>
											<FormItem className="inline-form-item-control" name={["workingHours", "allowOverride"]} valuePropName="checked">
												<Switch disabled={isDisabled} />
											</FormItem>
										</div>
									</Col>
								</Row>
							</Col>
							<Col xs={24} sm={24} xxl={12}>
								<h4>{t("pages.dashboard.partners.retail_settings.retail_configs.public_holidays")}</h4>
								<Row gutter={[16, 0]}>
									<Col xs={24} sm={18} md={12}>
										<FormItem name={["publicHolidays", "days"]} label={t("pages.dashboard.partners.retail_settings.retail_configs.holidays")}>
											<PublicHolidays id="retail-configs-public-holidays" disabled={isDisabled} />
										</FormItem>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<div className="inline-form-item vs--mb-16">
											<label>{t("pages.dashboard.partners.retail_settings.retail_configs.disable_cashier_when_closed")}</label>
											<FormItem className="inline-form-item-control" name={["publicHolidays", "disableCashierSoftware"]} valuePropName="checked">
												<Switch disabled={isDisabled} />
											</FormItem>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
					{!isDisabled && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** RetailConfigsComponent propTypes
 * PropTypes
 */
RetailConfigsComponent.propTypes = {
	/** Redux state property, is true when retail config is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when loading retail config data */
	isLoading: PropTypes.bool,
	/** Redux state, represents the retail configs of current editing partner  */
	retailConfigs: retailConfigsType,
	/** Redux action to save partner retail configs */
	savePartnerRetailConfigs: PropTypes.func,
	/** Redux action to get partner retail configs */
	getPartnerRetailConfigs: PropTypes.func,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func,
	/** Redux action to delete logo */
	deleteLogo: PropTypes.func,
	/** Redux action to set logo id */
	setLogoId: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getPartnerRetailConfigs: () => {
		dispatch(getPartnerRetailConfigs());
	},
	savePartnerRetailConfigs: (data) => {
		dispatch(savePartnerRetailConfigs(data));
	},
	deleteLogo: (data) => {
		dispatch(deleteLogo(data));
	},
	setLogoId: (logoId) => {
		dispatch(setLogoId(logoId));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.partners.isSaving,
		isLoading: state.partner.isLoading,
		retailConfigs: state.partner.retailSettings.retailConfigs
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RetailConfigsComponent);
