import PropTypes from "prop-types";
import sortingType from "types/common/sorting.type";
import Table from "components/common/table";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import moment from "moment";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { getPermissionGroupQuickViewUsers, setPermissionGroupQuickViewUsersSorting, deletePermissionGroupQuickViewUser } from "store/actions/dashboard/userManagement/permissionGroups/generalInfo.action";
import { doesUserHaveRoleOf } from "utils/auth";
import { USER_ROLE } from "constants/user.constants";
import Question from "components/common/question";

/** Players Group Users Table Component */
const UserQuickInfoTable = ({ id, usersQuickView, globalPartnerId, getPermissionGroupQuickViewUsers, setPermissionGroupQuickViewUsersSorting, deletePermissionGroupQuickViewUser, isLoading }) => {
	const { t } = useTranslation();
	const { total, users, sorting } = usersQuickView;
	const isAccessManager = doesUserHaveRoleOf(USER_ROLE.ACCESS_MANAGER);
	const accessManagerNoteActions = isAccessManager
		? {
				title: t("pages.dashboard.permissions_requests.notes"),
				isPrompt: true,
				isPromptRequired: true,
				promptLabel: t("pages.dashboard.permissions_requests.notes")
			}
		: {};

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.users.user_id"),
			dataIndex: "id",
			sorter: false
		},
		{
			title: t("pages.dashboard.users.username"),
			dataIndex: "userName",
			sorter: false
		},
		{
			title: t("pages.dashboard.users.added_at"),
			dataIndex: "addedAt",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "")
		},
		{
			title: t("pages.dashboard.users.added_by"),
			dataIndex: "addedBy"
		}
	];

	// function to specify case: delete last item in last page
	const getSorting = () =>
		total - (sorting.page - 1) * sorting.limit === 1
			? {
					...sorting,
					page: sorting.page - 1
				}
			: sorting;

	return (
		<Table
			loadFn={getPermissionGroupQuickViewUsers(id)}
			loading={isLoading}
			columns={columns}
			data={users}
			sorting={sorting}
			setSortingFn={setPermissionGroupQuickViewUsersSorting}
			total={total}
			updateProps={[globalPartnerId]}
			actions={
				hasPermission({ resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.MODIFY })
					? {
							delete: {
								...accessManagerNoteActions,
								messageKey: "permissionGroup",
								handler: (record, note) => {
									if (!record.id) {
										return;
									}
									const objToSend = {
										id: record.id,
										groupId: id,
										sorting: getSorting()
									};
									if (isAccessManager) {
										objToSend.note = note;
									}
									deletePermissionGroupQuickViewUser(objToSend);
								},
								hidden: (record) => record.users && record.users.length > 0
							}
						}
					: {}
			}
			isDisabled={() => false}
		/>
	);
};

/** UserQuickInfoTable propTypes
 * PropTypes
 */
UserQuickInfoTable.propTypes = {
	/** The id of the group which quick info is showing */
	id: PropTypes.string,
	/** The users which quick info is showing */
	usersQuickView: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux action to get user permission group */
	getPermissionGroupQuickViewUsers: PropTypes.func,
	/** Redux action to delete user permission group */
	deletePermissionGroupQuickViewUser: PropTypes.func,
	/** Redux state property, users sorting details */
	sorting: sortingType,
	/** Function to set sorting details */
	setPermissionGroupQuickViewUsersSorting: PropTypes.func,
	/** Redux state property, is true when loading users */
	isLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		globalPartnerId: state.partner.globalPartnerId,
		sorting: state.permissionGroups.editingPermissionGroup.usersQuickView,
		usersQuickView: state.permissionGroups.editingPermissionGroup.usersQuickView,
		isLoading: state.permissionGroups.isLoading
	};
};

const mapDispatchToProps = (dispatch) => ({
	setPermissionGroupQuickViewUsersSorting: (users) => {
		dispatch(setPermissionGroupQuickViewUsersSorting(users));
	},
	getPermissionGroupQuickViewUsers: (id) => (fromFirstPage) => {
		dispatch(getPermissionGroupQuickViewUsers(id, fromFirstPage));
	},
	deletePermissionGroupQuickViewUser: (data) => {
		dispatch(deletePermissionGroupQuickViewUser(data));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(UserQuickInfoTable);
