import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Button, Switch, Spin, Select } from "antd";
const { Item: FormItem } = Form;

import NumericInput from "components/common/numericInput";

import { BETSHOP_BONUS_CONFIG_TYPE } from "constants/betshop.constants";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { getBetshopBonusConfigs, saveBetshopBonusConfigs } from "store/actions/dashboard/retail/betshops/bonusConfigs.action";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import bonusConfigsType from "types/betshop/bonusConfigs.type";
import betshopGeneralInfoType from "types/betshop/generalInfo.type";

/** Betshop Edit Page Bonus Configs Tab Component */
const BonusConfigsComponent = ({ isSaving, isLoading, bonusConfigs, getBetshopBonusConfigs, saveBetshopBonusConfigs, generalInfo, onTabChange }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue, isFieldTouched, getFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_BONUS_CONFIGS, action: PERMISSION_ACTION.MODIFY });

	/** Load betshop bonus configs */
	useEffect(() => {
		getBetshopBonusConfigs(routeParams.id);
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			...bonusConfigs
		});
	}, [bonusConfigs]);

	/** Fires when form submitted
	 * @function
	 * @memberOf BonusConfigsComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				saveBetshopBonusConfigs({
					id: routeParams.id,
					...data
				});
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					enabled: bonusConfigs.enabled,
					type: bonusConfigs.type,
					amount: bonusConfigs.amount
				}}
				onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, { ...bonusConfigs }))}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h1>{t("pages.dashboard.betshops.lottery_drum_configs")}</h1>
							</Col>
							<Col span={24}>
								<div className="inline-form-item ">
									<label>{t("pages.dashboard.betshops.print_double_bet_coupons")}</label>
									<FormItem className="inline-form-item-control" name="enabled" valuePropName="checked">
										<Switch
											disabled={isDisabled}
											onChange={() => {
												setTimeout(() => {
													validateFields(["amount"]);
												}, 0);
											}}
										/>
									</FormItem>
								</div>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} lg={6} xl={5}>
								<FormItem
									style={{ marginTop: "8px" }}
									label={`${t("pages.dashboard.betshops.stake_amount")} (${generalInfo.currencyId})`}
									name="amount"
									rules={[
										({ getFieldValue }) => ({
											validator(rule, value) {
												if ((value === "" || value === null) && getFieldValue(["enabled"])) {
													return Promise.reject(t("validation.field_required"));
												}
												return Promise.resolve();
											}
										})
									]}
								>
									<NumericInput
										placeholder={`${t("common.enter")} ${t("pages.dashboard.betshops.stake_amount")}`}
										disabled={isDisabled}
										addonBefore={
											<FormItem className="form-item-without-margin form-item-without-label" name="type">
												<Select suffixIcon={<i className="icon-down" />} disabled={isDisabled}>
													<Select.Option value={BETSHOP_BONUS_CONFIG_TYPE.EQUAL}>{"="}</Select.Option>
													<Select.Option value={BETSHOP_BONUS_CONFIG_TYPE.GREATER_OR_EQUAL}>{">="}</Select.Option>
												</Select>
											</FormItem>
										}
									/>
								</FormItem>
							</Col>
						</Row>
					</div>
					{!isDisabled && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** BonusConfigsComponent propTypes
 * PropTypes
 */
BonusConfigsComponent.propTypes = {
	/** Redux state property, is true when bonus configs is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when bonus configs is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the bonus configs of current editing betshop  */
	bonusConfigs: bonusConfigsType,
	/** Redux action to save betshop bonus configs */
	saveBetshopBonusConfigs: PropTypes.func,
	/** Redux action to get betshop bonus configs */
	getBetshopBonusConfigs: PropTypes.func,
	/** Redux state, represents the general info of current editing betshop  */
	generalInfo: betshopGeneralInfoType,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getBetshopBonusConfigs: (id) => {
		dispatch(getBetshopBonusConfigs(id));
	},

	saveBetshopBonusConfigs: (data) => {
		dispatch(saveBetshopBonusConfigs(data));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.betshops.isSaving,
		isLoading: state.betshops.isLoading,
		bonusConfigs: state.betshops.editingBetshop.bonusConfigs,
		generalInfo: state.betshops.editingBetshop.generalInfo
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BonusConfigsComponent);
