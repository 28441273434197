import { Fragment } from "react";

import PlayersViewComponent from "components/dashboard/online/players/view/player-view.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const PlayersViewRoute = () => {
	return (
		<Fragment>
			<PlayersViewComponent />
		</Fragment>
	);
};

export default withPermission(PlayersViewRoute, { resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
