import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "antd";

/** Dashboard Breadcrumbs Component */
const Breadcrumbs = ({ items }) => {
	const navigate = useNavigate();

	return (
		<Breadcrumb className="dashboard-breadcrumbs">
			{items.map((item) => (
				<Breadcrumb.Item key={item.title}>{<span onClick={() => item.path && navigate(item.path)}>{item.title}</span>}</Breadcrumb.Item>
			))}
		</Breadcrumb>
	);
};

/** Breadcrumbs propTypes
 * PropTypes
 */
Breadcrumbs.propTypes = {
	/** Breadcrumbs items */
	items: PropTypes.arrayOf(
		PropTypes.shape({
			/** Breadcrumb path */
			path: PropTypes.string,
			/** Breadcrumb title */
			title: PropTypes.string
		})
	)
};

export default Breadcrumbs;
