import { createElement, useState } from "react";
import PropTypes from "prop-types";
import { mergeClassNames } from "utils/common";

const BannerPreviewSection = ({ srcObj, objectFit = "cover" }) => {
	const [isVisible, setIsVisible] = useState(false);
	const isVideo = ["mp4", "webm"].includes(srcObj.file.format);

	return createElement(
		isVideo ? "video" : "img",
		{
			className: (
				mergeClassNames(
					"vs--banner-preview-section-image",
					"vs--banner-preview-section-image-object-fit-" + objectFit,
					!isVisible && "hidden"
				)
			),
			src: srcObj.src,
			onError: () => setIsVisible(false),
			[isVideo ? "onLoadedData" : "onLoad"]: () => setIsVisible(true),
			alt: isVideo ? null : "banner-section",
			muted: isVideo ? true : null,
			autoPlay: isVideo ? true : null,
			controls: isVideo ? false : null,
		}
	);
}

BannerPreviewSection.propTypes = {
	srcObj: PropTypes.shape({
		src: PropTypes.string,
		file: PropTypes.object
	}),
	objectFit: PropTypes.oneOf(["contain", "cover", "fill", "none", "scale-down"])
}

export default BannerPreviewSection;