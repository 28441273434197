const domReference = typeof window !== "undefined" && typeof window.document !== "undefined" ? window.document : {};
const UA = window.navigator.userAgent.toLowerCase();
const IS_IOS = /ipad|iphone|ipod/i.test(UA) && !window.MSStream;
const IS_CHROME = /chrome/i.test(UA);
const IS_SAFARI = /safari/i.test(UA) && !IS_CHROME;

/* Array of browser specific variables of document without prefixs and without */
/* without */
const withoutPrefixGroupOfVariables = ["requestFullscreen", "exitFullscreen", "fullscreenElement", "fullscreenEnabled", "fullscreenchange", "fullscreenerror"];
/* webkit (chrome, chromium, edge, safari...) */
const newWebKitPrefixGroupOfVariables = ["webkitEnterFullscreen", "webkitExitFullscreen", "webkitFullscreenElement", "webkitFullscreenEnabled", "webkitfullscreenchange", "webkitfullscreenerror"];
/* webkit (chrome, chromium, edge, safari...) */
const oldWebKitPrefixGroupOfVariables = ["webkitRequestFullScreen", "webkitCancelFullScreen", "webkitCurrentFullScreenElement", "webkitCancelFullScreen", "webkitfullscreenchange", "webkitfullscreenerror"];
/* new prefixs for Mozilla */
const mozPrefixGroupOfVariables = ["mozRequestFullScreen", "mozCancelFullScreen", "mozFullScreenElement", "mozFullScreenEnabled", "mozfullscreenchange", "mozfullscreenerror"];
/* ie, edge */
const msPrefixGroupOfVariables = ["msRequestFullscreen", "msExitFullscreen", "msFullscreenElement", "msFullscreenEnabled", "MSFullscreenChange", "MSFullscreenError"];
/* Array of browser specific variables groups */
const allGroupOfVariables = [withoutPrefixGroupOfVariables, newWebKitPrefixGroupOfVariables, oldWebKitPrefixGroupOfVariables, mozPrefixGroupOfVariables, msPrefixGroupOfVariables];

/**
 * Chcking function for continue effect in reduce loop
 * @param {object} acc reduce accumulator
 * @param {}
 */
const checkForContinueLoop = (acc, browserSpecificGroup, i) => {
	if (acc.isFound) {
		return true;
	}
	/* second element of eventsNameGroup the function name what we need */
	if (browserSpecificGroup[1] in domReference || ((IS_IOS || IS_SAFARI) && i === 1)) {
		return false;
	}
	return true;
};

const { correctGroupOfVariables } = allGroupOfVariables.reduce(
	(acc, browserSpecificGroup, i) => {
		if (checkForContinueLoop(acc, browserSpecificGroup, i)) {
			return acc;
		}
		acc.isFound = true;
		withoutPrefixGroupOfVariables.forEach((eventName, j) => {
			acc.correctGroupOfVariables[eventName] = browserSpecificGroup[j];
		});
		return acc;
	},
	{
		correctGroupOfVariables: {},
		isFound: false
	}
);

const eventNameMap = {
	change: correctGroupOfVariables.fullscreenchange,
	error: correctGroupOfVariables.fullscreenerror
};

const fullScreenAPI = {
	request: function (element) {
		return new Promise(
			function (resolve, reject) {
				const onFullScreenEntered = function () {
					this.off("change", onFullScreenEntered);
					resolve();
				}.bind(this);
				this.on("change", onFullScreenEntered);
				element = element || domReference.documentElement;
				if (IS_IOS || IS_SAFARI) {
					element = element.getElementsByTagName("video")[0];
				}
				const returnPromise = element[correctGroupOfVariables.requestFullscreen]();
				if (returnPromise instanceof Promise) {
					returnPromise.then(onFullScreenEntered).catch(reject);
				}
			}.bind(this)
		);
	},
	exit: function () {
		return new Promise(
			function (resolve, reject) {
				if (!this.isFullscreen) {
					resolve();
					return;
				}
				const onFullScreenExit = function () {
					this.off("change", onFullScreenExit);
					resolve();
				}.bind(this);
				this.on("change", onFullScreenExit);
				var returnPromise = domReference[correctGroupOfVariables.exitFullscreen]();
				if (returnPromise instanceof Promise) {
					returnPromise.then(onFullScreenExit).catch(reject);
				}
			}.bind(this)
		);
	},
	toggle: function (element) {
		return this.isFullscreen ? this.exit() : this.request(element);
	},
	onchange: function (callback) {
		this.on("change", callback);
	},
	onerror: function (callback) {
		this.on("error", callback);
	},
	on: function (event, callback) {
		var eventName = eventNameMap[event];
		var el = IS_IOS || IS_SAFARI ? domReference.getElementsByTagName("video")[0] : domReference;
		if (eventName) {
			el.addEventListener(eventName, callback, false);
		}
	},
	off: function (event, callback) {
		var eventName = eventNameMap[event];
		var el = IS_IOS || IS_SAFARI ? domReference.getElementsByTagName("video")[0] : domReference;

		if (eventName) {
			el.removeEventListener(eventName, callback, false);
		}
	}
};

Object.defineProperties(fullScreenAPI, {
	isFullscreen: {
		get: function () {
			return Boolean(domReference[correctGroupOfVariables.fullscreenElement]);
		}
	},
	element: {
		enumerable: true,
		get: function () {
			return domReference[correctGroupOfVariables.fullscreenElement];
		}
	},
	isEnabled: {
		enumerable: true,
		get: function () {
			// Coerce to boolean in case of old WebKit
			return Boolean(domReference[correctGroupOfVariables.fullscreenEnabled]);
		}
	}
});

export default fullScreenAPI;
