import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Input } from "antd";

import { getError } from "store/actions/dashboard/developer/errors/errors.action";

import Table from "components/common/table";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import errorLogType from "types/log/error.type";

/** Monitoring Page Errors table Component */
const ErrorsTableComponent = ({ getError, errors }) => {
	const { t } = useTranslation();

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.logs.controller") + "/" + t("pages.dashboard.logs.action"),
			dataIndex: "controller.action",
			render: (value, record) => <span key={record.key}>{(record?.controller ?? "-") + "/" + (record?.action ?? "-")} </span>,
			sorter: false
		},
		{
			title: t("pages.dashboard.logs.message"),
			dataIndex: "message",
			sorter: false
		},
		{
			title: t("pages.dashboard.logs.time"),
			dataIndex: "errorTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT),
			sorter: false
		}
	];

	return (
		<div className="dashboard-logs-section-content dashboard-section-content">
			<Table
				loading={false}
				columns={columns}
				data={errors}
				loadFn={Function.prototype}
				total={errors.length}
				isDisabled={() => false}
				details={(record) =>
					record.details
						? [
								{
									title: t("common.id"),
									value: record.details.id
								},
								{
									title: t("pages.dashboard.logs.controller"),
									value: record.details.controller
								},
								{
									title: t("pages.dashboard.logs.action"),
									value: record.details.action
								},
								{
									title: t("pages.dashboard.logs.message"),
									value: record.details.message
								},
								{
									title: t("pages.dashboard.logs.time"),
									value: moment.utc(record.details.errorTime).local().format(DATE_TIME_FORMAT)
								},
								{
									title: t("pages.dashboard.logs.stackTrace"),
									value: <Input.TextArea className="dashboard-logs-exception" autoSize={true} disabled={true} value={record.details.stackTrace} />
								}
							]
						: []
				}
				detailsLoadFn={(record) => (!record.details ? getError(record.id) : false)}
				detailsOptions={{ layout: "horizontal", column: 1, colon: ":", bordered: true }}
				noPagination={true}
			/>
		</div>
	);
};

/** ErrorsTableComponent propTypes
 * PropTypes
 */
ErrorsTableComponent.propTypes = {
	/** Redux action to get error details */
	getError: PropTypes.func,
	/** Array of error logs of service*/
	errors: PropTypes.arrayOf(errorLogType)
};

const mapDispatchToProps = (dispatch) => ({
	getError: (id) => {
		dispatch(getError(id));
	}
});

const mapStateToProps = (state) => {
	return {
		errors: state.errors.errors
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorsTableComponent);
