import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ACTIONS from "store/actions/dashboard/autoSuggestion/autoSuggestion.action";
import { AUTOSUGGESTION_MAPPING, AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";

/**
 * Default function for option.disableAction
 * @function defaultDisableAction
 * @memberof option
 * @returns {boolean}
 */
const defaultDisableAction = () => false;

/**
 * Custom hook for handling auto-suggestions.
 *
 * @param {AUTOSUGGESTION_TYPE} type - The type of auto-suggestion, must be AUTOSUGGESTION_TYPE enum.
 * @param {(array | undefined)} dependencies - Standart react hook dependencies that trigger the auto-suggestion.
 * @param {object} [option = {}] - Additional options
 * @param {() => boolean} [option.disableAction = defaultDisableAction] - Function to disable auto-suggestion action call.
 * @param {array} [option.actionArguments = []] - Array of arguments for the auto-suggestion action.
 *
 * @returns {array.<{ key: string, value: string }>} redux auto-suggestion state value
 */
const useAutosuggestion = (type, dependencies, option = {}) => {
	if (!Object.values(AUTOSUGGESTION_TYPE).includes(type)) {
		throw new Error(`Invalid 'type' parameter. 'type' must be one of the valid AUTOSUGGESTION_TYPE enum values.\nExample: ${Object.values(AUTOSUGGESTION_TYPE)}`);
	}
	const disableAction = typeof option?.disableAction === "function" ? option.disableAction : defaultDisableAction;
	const actionArguments = Array.isArray(option?.actionArguments) ? option.actionArguments : [];
	const { actionName, stateName } = AUTOSUGGESTION_MAPPING[type];
	const dispatch = useDispatch();
	useEffect(() => {
		if (disableAction()) {
			return;
		}
		dispatch(ACTIONS[actionName](...actionArguments));
	}, dependencies);
	return useSelector((state) => state.autoSuggestion[stateName]);
};

export default useAutosuggestion;
