import PropTypes from "prop-types";

export default PropTypes.shape({
	name: PropTypes.string,
	type: PropTypes.number,
	contactName: PropTypes.string,
	email: PropTypes.string,
	emergencyEmail: PropTypes.string,
	tel: PropTypes.string,
	emergencyTel: PropTypes.string,
	fax: PropTypes.string,
	countryCode: PropTypes.string,
	city: PropTypes.string,
	address: PropTypes.string,
	postalCode: PropTypes.string,
	isTesting: PropTypes.bool
});
