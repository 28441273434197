import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Form, Col, Input, Row, Modal, Checkbox } from "antd";
const { Item: FormItem } = Form;

import { createPartner, getPartners } from "store/actions/dashboard/partners/partners.action";
import { setPartnerActionSetGeneralInfo } from "store/actions/dashboard/partners/generalInfo.action";

import { PARTNER_TYPE } from "constants/partner.constants";
import { flagsToBinary } from "utils/common";
import Paths from "constants/path.constants";
import { POPUP_SIZE } from "constants/common.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import { hasPermission } from "utils/permissions";

/** Partner Creating Popup Component */
const PartnerAddComponent = ({ isSaving, createPartner, getPartners, setPartnerGeneralInfo, onClose }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;

	/** Fires when form submitted
	 * @function
	 * @memberOf PartnerAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ name, type, isTesting }) => {
				createPartner({ name, type: flagsToBinary(type), isTesting }, ({ id, name }) => {
					if (hasPermission({ resource: PERMISSION_RESOURCE.PARTNER, action: PERMISSION_ACTION.MODIFY })) {
						navigate(Paths.DASHBOARD_PARTNERS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, id));
						setPartnerGeneralInfo({
							partnerId: id,
							name: name,
							type: flagsToBinary(type)
						});
					} else {
						getPartners();
						onClose();
					}
				});
			})
			.catch(Function.prototype);
	};

	return (
		<Modal open={true} title={t("pages.dashboard.partners.create_partner")} cancelText={t("common.cancel")} okText={t("common.create")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.SMALL} maskClosable={false} closable={false} okButtonProps={{ loading: isSaving }} centered>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					type: [PARTNER_TYPE.ONLINE],
					isTesting: true
				}}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem
							label={`${t("pages.dashboard.partners.add_partner_form.company_name")} *`}
							name="name"
							rules={[
								{ required: true, whitespace: true, message: t("validation.field_required") },
								{ max: 30, message: t("validation.field_invalid") }
							]}
						>
							<Input maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.add_partner_form.company_name")}`} autoFocus={true} autoComplete="off" />
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem label={`${t("pages.dashboard.partners.add_partner_form.partner_type")} *`} name="type" rules={[{ required: true, message: t("validation.field_required") }]}>
							<Checkbox.Group
								options={[
									{
										label: (
											<div className="partners-checkbox">
												<i className="icon-online" />
												<span>{t("common.online")}</span>
											</div>
										),
										value: PARTNER_TYPE.ONLINE
									},
									{
										label: (
											<div className="partners-checkbox">
												<i className="icon-retail" />
												<span>{t("common.retail")}</span>
											</div>
										),
										value: PARTNER_TYPE.RETAIL
									}
								]}
							/>
						</FormItem>
					</Col>
					<Col span={24}>
						<div className="inline-form-checkbox">
							<label>{t("pages.dashboard.partners.general_info.is_test_partner")}</label>
							<FormItem className="inline-form-item-control" name="isTesting" valuePropName="checked">
								<Checkbox />
							</FormItem>
						</div>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** PartnerAddComponent propTypes
 * PropTypes
 */
PartnerAddComponent.propTypes = {
	/** Redux state property, is true when creating partner request is in process */
	isSaving: PropTypes.bool,
	/** Redux action to create partner */
	createPartner: PropTypes.func,
	/** Redux action to get partners */
	getPartners: PropTypes.func,
	/** Redux action to set partner general info */
	setPartnerGeneralInfo: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	createPartner: (partner, onSuccess) => {
		dispatch(createPartner(partner, onSuccess));
	},
	setPartnerGeneralInfo: (info) => {
		dispatch(setPartnerActionSetGeneralInfo(info));
	},
	getPartners: () => {
		dispatch(getPartners(true));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.partners.isSaving
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerAddComponent);
