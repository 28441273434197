import { useState } from "react";
import PropTypes from "prop-types";
import { Pagination } from "antd";

const BetshopSlider = ({ list = [], totalAmt = 0, perPageLimit = 24, defaultCurrent = 1 }) => {
	const [page, setPage] = useState(1);
	const getRange = () => [(page - 1) * perPageLimit, page * perPageLimit];
	return (
		<div className="pie-chart-labels">
			<div className="pie-chart-labels-container">
				{list.slice(...getRange()).map(({ name, color, y }, i) => (
					<div key={name} className="pie-chart-labels-item">
						<div className="pie-chart-labels-item-group">
							<div style={{ backgroundColor: color }} className="pie-chart-labels-item-group-badge" />
							<span className="pie-chart-labels-item-group-text">{name}</span>
						</div>
						{totalAmt && y ? Math.round((y / totalAmt) * 100) : 0} %
					</div>
				))}
			</div>
			{list.length > perPageLimit ? (
				<div className="pie-chart-labels-pagination">
					<Pagination
						total={list.length}
						defaultCurrent={defaultCurrent}
						current={page}
						defaultPageSize={perPageLimit}
						onChange={(pageNumber) => setPage(pageNumber)}
						itemRender={(pageNum, btnName, defaultComponent) => {
							if (btnName !== "page") {
								return defaultComponent;
							}
							return <div className="pie-chart-labels-item-group-badge" data-page={pageNum} />;
						}}
						showSizeChanger={false}
						showQuickJumper={false}
					/>
				</div>
			) : null}
		</div>
	);
};

BetshopSlider.propTypes = {
	list: PropTypes.array,
	totalAmt: PropTypes.number,
	perPageLimit: PropTypes.number,
	defaultCurrent: PropTypes.number
};

export default BetshopSlider;
