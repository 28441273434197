import {
	SYSTEM_SET_LANGUAGES,
	SYSTEM_SET_AVAILABLE_LANGUAGES,
	SYSTEM_SET_SYSTEM_AVAILABLE_LANGUAGES,
	SYSTEM_ADD_LANGUAGE,
	SYSTEM_DELETE_LANGUAGE,
	SYSTEM_LANGUAGES_BEFORE,
	SYSTEM_LANGUAGES_FINISH,
	SYSTEM_LANGUAGE_BEFORE,
	SYSTEM_LANGUAGE_FINISH,
	SYSTEM_AVAILABLE_LANGUAGES_BEFORE,
	SYSTEM_AVAILABLE_LANGUAGES_FINISH,
	SYSTEM_LANGUAGES_SET_SORTING
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SYSTEM_LANGUAGES_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case SYSTEM_LANGUAGES_FINISH:
			return {
				...state,
				isLoading: false
			};
		case SYSTEM_LANGUAGE_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case SYSTEM_LANGUAGE_FINISH:
			return {
				...state,
				isSaving: false
			};
		case SYSTEM_AVAILABLE_LANGUAGES_BEFORE:
			return {
				...state,
				isAvailableLoading: true
			};
		case SYSTEM_AVAILABLE_LANGUAGES_FINISH:
			return {
				...state,
				isAvailableLoading: false
			};
		case SYSTEM_SET_LANGUAGES:
			return {
				...state,
				systemLanguages: payload.languages.item2,
				total: payload.languages.item1
			};
		case SYSTEM_SET_AVAILABLE_LANGUAGES:
			return {
				...state,
				availableLanguages: payload.languages
			};
		case SYSTEM_SET_SYSTEM_AVAILABLE_LANGUAGES:
			return {
				...state,
				systemAvailableLanguages: payload.languages
			};
		case SYSTEM_ADD_LANGUAGE:
			return {
				...state,
				systemLanguages: [...state.systemLanguages, payload.language],
				total: state.systemLanguages.total + 1
			};
		case SYSTEM_DELETE_LANGUAGE:
			return {
				...state,
				systemLanguages: state.systemLanguages.filter((l) => l.code !== payload.code)
			};
		case SYSTEM_LANGUAGES_SET_SORTING:
			return {
				...state,
				sorting: payload.sorting
			};
		default:
			return state;
	}
};
