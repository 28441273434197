import { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import Table from 'components/common/table';
import Filters from './filters.component';
import ExportButton from 'components/common/exportButton';
import SingleEventComponent from './single';
import { getEvents, setEventsSorting, setEventsFilters } from 'store/actions/dashboard/events/events.action';
import { hasPermission } from 'utils/permissions';
import { getGamesFromConfig, isCupGame, isLeagueGame, isRacingGame } from 'utils/common';
import ApiUrls from 'constants/api.constants';
import Paths from 'constants/path.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { GAME_STATUSES, GAME_CATEGORY, GAME_TYPES } from 'constants/game.constants';
import { PROJECT_TYPE } from "constants/common.constants";
import eventType from 'types/event/event.type';
import sortingType from 'types/common/sorting.type';
import { DYNAMIC_PATH_GAME_CATEGORY_REGEX, DYNAMIC_PATH_ID_REGEX } from 'constants/regex.constants';
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

/** Events Page Component */
const EventsComponent = ({ getEvents, setEventsSorting, setEventsFilters, isLoading, events, sorting, filters, total, globalPartnerId, projectType, activeTab, gameCategory }) => {
	const navigate = useNavigate();
	const { hash } = useLocation();
	const { t } = useTranslation();

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.events.event_id"),
			dataIndex: "id",
			sorter: false
		},
		{
			title: t("pages.dashboard.events.start_date"),
			dataIndex: "startTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.events.end_date"),
			dataIndex: "finishTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.events.game_type"),
			dataIndex: "gameType",
			render: (value) =>
				t(
					`common.${Object.values(GAME_TYPES[gameCategory])
						.filter((v) => v.value === value)
						.map((v) => v.type)}`
				)
		},
		{
			title: t("pages.dashboard.events.game_name"),
			dataIndex: "gameName",
			sorter: false
		},
		{
			title: t("pages.dashboard.events.result"),
			dataIndex: "gameData",
			sorter: false,
			render: (value, record) => {
				let str = "";
				const team1 = value?.team1 ?? {};
				const team2 = value?.team2 ?? {};

				if (record.gameType === GAME_TYPES[gameCategory]?.FOOTBALL_SINGLE_MATCH?.value || record.gameType === GAME_TYPES[gameCategory]?.PENALTY_SHOOTOUT?.value || isCupGame(record.gameType)) {
					str = `${team1.countryName ?? ""} ${team1.goalCount ?? ""} - ${team2.goalCount ?? ""} ${team2.countryName ?? ""}`;
				} else if (isLeagueGame(record.gameType)) {
					str = `${team1.countryCode ?? ""} ${team1.goalCount ?? ""} - ${team2.goalCount ?? ""} ${team2.countryCode ?? ""}`;
				} else if (isRacingGame(record.gameType)) {
					const participants = value?.participants ?? [];
					const { first, second, third } = participants.reduce((acc, p) => {
						if(p.place === 1) {
							acc.first = p;
						} else if(p.place === 2) {
							acc.second = p;
						} else if(p.place === 3) {
							acc.third = p;
						}

						return acc;
					}, { first: null, second: null, third: null });
					
					const addParticipantText = (p) => {
						if(!p?.number || !p?.name) {
							return "";
						}

						return `${p.place !== 1 ? ", " : ""}${p.place}.(#${p.number}) ${p.name}`;
					};

					str += [first, second, third].reduce((acc, p) => {
						acc += addParticipantText(p);

						return acc;
					}, "");
				} else if (record.gameType === GAME_TYPES[gameCategory]?.KENO?.value) {
					str = (value?.scenes ?? [])
						.filter((sc) => sc.isCompleted)
						.map((sc) => sc.number)
						.join(", ");
				}

				return str;
			}
		},
		{
			title: t("pages.dashboard.events.status"),
			dataIndex: "status",
			render: (value) => (value === GAME_STATUSES.FINISHED ? t("pages.dashboard.events.completed") : value === GAME_STATUSES.CANCELED ? t("pages.dashboard.events.cancelled") : "")
		},
		{
			title: t("pages.dashboard.events.betslips"),
			dataIndex: "betSlipsCount",
			sorter: false
		},
		{
			title: t("pages.dashboard.events.calculation_status"),
			dataIndex: "isResultCalculated",
			render: (value) => (value === true ? <span className="green-color">{t("pages.dashboard.events.calculation_status_success")}</span> : value === false ? <span className="red-color">{t("pages.dashboard.events.calculation_status_fail")}</span> : "")
		}
	];

	return (
		<div className="dashboard-section-content">
			<Fragment>
				<div className="table-header">
					<div className="table-buttons-dropdowns">
						{
							hasPermission({ resource: PERMISSION_RESOURCE.EVENTS, action: PERMISSION_ACTION.EXPORT })
								? (
									<ExportButton
										columns={columns.map(c => ({ title: c.title, key: c.dataIndex }))}
										tableName={projectType === PROJECT_TYPE.ONLINE ? t("pages.dashboard.events.tabs.online_events") : t("pages.dashboard.events.tabs.retail_events")}
										url={`${ApiUrls.SCHEDULED_EXPORT_EVENTS}?status=24&projectType=${projectType}`}
										filters={filters}
										sorting={sorting}
									/>
								)
								: null
						}
					</div>
					<Filters
						projectType={projectType}
						filters={filters}
						gameCategory={gameCategory}
					/>
				</div>
				<Table
					loading={isLoading}
					columns={columns}
					data={events}
					loadFn={() => getEvents(projectType, false, gameCategory)}
					sorting={sorting}
					filters={filters}
					setSortingFn={sorting => setEventsSorting(sorting, projectType, gameCategory)}
					setFiltersFn={filters => setEventsFilters(filters, projectType, gameCategory)}
					total={total}
					updateProps={[globalPartnerId, projectType, gameCategory]}
					actions={{
						view: {
							handler: record => {
								const path = (
									projectType === PROJECT_TYPE.ONLINE
										? Paths.DASHBOARD_EVENTS_ONLINE_VIEW
										: projectType === PROJECT_TYPE.RETAIL
											? Paths.DASHBOARD_EVENTS_RETAIL_VIEW
											: null
								);
								if (path) {
									const urlPath = path.replace(DYNAMIC_PATH_GAME_CATEGORY_REGEX, gameCategory).replace(DYNAMIC_PATH_ID_REGEX, record.id)
									navigate(`${urlPath}${hash}`)
								}
							}
						}
					}}
					isDisabled={() => false}
					enableReload={true}
				/>
			</Fragment>
		</div>
	);
};

/** EventsComponent propTypes
 * PropTypes
 */
EventsComponent.propTypes = {
	/** Redux action to get events */
	getEvents: PropTypes.func,
	/** Redux action to set events sorting details */
	setEventsSorting: PropTypes.func,
	/** Redux action to set events filters */
	setEventsFilters: PropTypes.func,
	/** Redux state property, is true when loading events */
	isLoading: PropTypes.bool,
	/** The array of events */
	events: PropTypes.arrayOf(eventType),
	/** Event sorting details */
	sorting: sortingType,
	/** Events filters */
	filters: PropTypes.object,
	/** Events total count */
	total: PropTypes.number,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	/* Active tab */
	activeTab: PropTypes.string,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	getEvents: (projectType, fromFirstPage, gameCategory) => {
		dispatch(getEvents(projectType, fromFirstPage, gameCategory));
	},
	setEventsSorting: (sorting, projectType, gameCategory) => {
		dispatch(setEventsSorting(sorting, projectType, gameCategory));
	},
	setEventsFilters: (filters, projectType, gameCategory) => {
		dispatch(setEventsFilters(filters, projectType, gameCategory));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.events.isLoading,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsComponent);
