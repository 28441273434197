import {
	ACCESS_MANAGERS_ACTION_BEFORE,
	ACCESS_MANAGERS_ACTION_FINISH,
	ACCESS_MANAGERS_ACTION_SET_ACCESS_MANAGERS,
	ACCESS_MANAGERS_SAVE_ACTION_BEFORE,
	ACCESS_MANAGERS_SAVE_ACTION_FINISH,
	ACCESS_MANAGERS_SET_SORTING,
	ACCESS_MANAGERS_SET_FILTERS,
	SET_ACCESS_MANAGER_GENERAL_INFO,
	RESET_EDITING_ACCESS_MANAGER,
	CHANGE_ACCESS_MANAGER_STATE
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case ACCESS_MANAGERS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case ACCESS_MANAGERS_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case ACCESS_MANAGERS_ACTION_SET_ACCESS_MANAGERS:
			return {
				...state,
				data: payload.data.item2,
				total: payload.data.item1
			};
		case ACCESS_MANAGERS_SAVE_ACTION_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case ACCESS_MANAGERS_SAVE_ACTION_FINISH:
			return {
				...state,
				isSaving: false
			};
		case ACCESS_MANAGERS_SET_SORTING:
			return {
				...state,
				sorting: payload.sorting
			};
		case ACCESS_MANAGERS_SET_FILTERS:
			return {
				...state,
				filters: payload.filters,
				sorting: {
					...state.sorting,
					page: 1
				}
			};
		case SET_ACCESS_MANAGER_GENERAL_INFO:
			return {
				...state,
				editingAccessManager: {
					...state.editingAccessManager,
					generalInfo: {
						...payload.generalInfo
					}
				}
			};
		case RESET_EDITING_ACCESS_MANAGER:
			return {
				...state,
				editingAccessManager: {
					...state.editingAccessManager,
					generalInfo: {}
				}
			};
		case CHANGE_ACCESS_MANAGER_STATE:
			return {
				...state,
				data: state.data.map((u) => (u.id !== payload.data.id ? { ...u } : { ...u, state: payload.data.state, lastBlocked: payload.data.lastBlocked, lastBlockedBy: payload.data.lastBlockedBy, lastUpdate: payload.data.lastUpdate }))
			};
		default:
			return state;
	}
};
