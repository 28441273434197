import PropTypes from "prop-types";

import Modal from "antd/lib/modal";
import FooterButtons from "components/dashboard/developer/streamServers/streamServersItem/footerButtons";

import { POPUP_SIZE } from "constants/common.constants";

const StreamServerItemActionModal = ({ isModalOpen = false, onOk = () => {}, onClose = () => {}, okText = "", title = "", contentText = "", saveButtonProps = {} }) => {
	return (
		<Modal open={isModalOpen} closable={false} maskClosable={false} centered width={POPUP_SIZE.SMALL} footer={null}>
			<div className="stream-servers-restart-modal-content">
				<p className="stream-servers-restart-modal-content-title">{title}</p>
				<p className="stream-servers-restart-modal-content-text">{contentText}</p>
				<FooterButtons onSave={onOk} onClose={onClose} okText={okText} saveButtonProps={saveButtonProps} />
			</div>
		</Modal>
	);
};

StreamServerItemActionModal.propTypes = {
	// Show indicator of the modal
	isModalOpen: PropTypes.bool,
	//   Submit handler prop of the modal
	onOk: PropTypes.func,
	// Cancel handler prop of the modal
	onCancel: PropTypes.func,
	// Ok button text prop of the modal
	okText: PropTypes.string,
	// Title prop of the modal
	title: PropTypes.string,
	// Main text content prop of the modal
	contentText: PropTypes.string,
	// Props for saving action button
	saveButtonProps: PropTypes.object
};

export default StreamServerItemActionModal;
