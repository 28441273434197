import { constructArrayForGivenRange } from "utils/common";

export const PARTNER_TYPE = {
	ONLINE: 1,
	RETAIL: 2
};

export const PARTNER_STATE = {
	ENABLED: 1,
	DISABLED: 0
};

export const INTEGRATION_TYPE = {
	IFRAME: 1
};

export const WORKING_HOURS_TYPE = {
	ALWAYS_OPEN: 1,
	CLOSED: 2,
	CUSTOM: 4
};

export const TICKET_GENERAL_DETAILS = {
	BETSHOP_NAME: 1,
	BETSHOP_ADDRESS: 2,
	CASHIER_ID: 4,
	TICKET_ID: 8,
	PRINTED_DATETIME: 16,
	VALIDATION_DATE: 32
};

export const TICKET_MARKET_DETAILS = {
	TEAM_NAMES: 1,
	MARKETS: 2,
	BET_TYPE: 4,
	STAKE: 8,
	MAX_POSSIBLE_WIN: 16,
	MAX_WIN_PER_TICKET: 32,
	GAME_TYPE: 64,
	DRAW_ID: 128,
	START_TIME: 256,
	POSSIBLE_WIN: 512,
	STATE: 1024
};

export const PARTNER_TICKET_LOGO_NAME = "ticket_logo.png";

export const PARTNER_SECURITY_PASSWORD_PATTERN = {
	CONTAINS: 1,
	PATTERN: 2
};

export const PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS = {
	UPPERCASE: 1,
	LOWERCASE: 2,
	DIGITS: 4,
	DASH: 8,
	UNDERSCORE: 16,
	SPACE: 32,
	SPECIAL: 64,
	BRACKETS: 128
};

export const PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE = {
	MAY: 1,
	MUST: 2,
	NONE: 4
};

export const PARTNER_WALLET_TYPE = {
	REGULAR: 1,
	UNLIMITED: 2
};

export const PARTNER_WALLET_ACTION_TYPE = {
	ADD: 1,
	REDUCE: 2
};

export const PARTNER_ACTIVITY_TYPE = {
	WALLET_ADJUSTMENT: 1,
	LIMIT_ADJUSTMENT: 2
};

export const PARTNER_PASSWORD_FORCE_CHANGE_TYPE = {
	NEVER: 1,
	EVERY_1_MONTH: 2,
	EVERY_2_MONTH: 4,
	EVERY_3_MONTH: 8,
	EVERY_4_MONTH: 16,
	EVERY_5_MONTH: 32,
	EVERY_6_MONTH: 64,
	EVERY_7_MONTH: 128,
	EVERY_8_MONTH: 256,
	EVERY_9_MONTH: 512,
	EVERY_10_MONTH: 1024,
	EVERY_11_MONTH: 2048
};

export const PAYMENT_TYPE = {
	CASH: 1,
	CREDIT_CARD: 2,
	BOTH: 3
};

export const PAYMENT_TYPE_TEXT = {
	[PAYMENT_TYPE.BOTH]: "common.all",
	[PAYMENT_TYPE.CASH]: "pages.dashboard.partners.retail_settings.retail_configs.cash",
	[PAYMENT_TYPE.CREDIT_CARD]: "pages.dashboard.partners.retail_settings.retail_configs.credit_card"
};

export const PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_REGEXP = {
	[PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.UPPERCASE]: /[A-Z]/,
	[PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.LOWERCASE]: /[a-z]/,
	[PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.DIGITS]: /[0-9]/,
	[PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.DASH]: /-/,
	[PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.UNDERSCORE]: /_/,
	[PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.SPACE]: /\s/,
	[PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.SPECIAL]: /[!#$%&*+,./:;=?@\\^`|~'"]/,
	[PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.BRACKETS]: /[{([<>\])}]/
};

//--------------------------AUTHENTICATION SETTINGS-------------------------//

export const FIELDS_NAME = {
	FAILED_LOGIN_ATTEMPTS: "failedLoginAttempts",
	AUTO_UNLOCK_TIME: "autoUnlockTime",
	AUTO_UNLOCK_COUNT: "autoUnlockCount",
	TWO_FA_TOGGLE: "twoFAToggle",
	TWO_FA_TYPE: "twoFAType",
	TWO_FA_FAILED_LOGIN_ATTEMPTS: "twoFAFailedLoginsCount",
	TWO_FA_AUTO_UNLOCK_TIME: "twoFAAutoUnlockTime",
	TWO_FA_AUTO_UNLOCK_COUNT: "twoFAAutoUnlockCount"
};

export const FORM_DEFAULT_VALUES = {
	[FIELDS_NAME.FAILED_LOGIN_ATTEMPTS]: 3,
	[FIELDS_NAME.AUTO_UNLOCK_TIME]: 1,
	[FIELDS_NAME.AUTO_UNLOCK_COUNT]: 3,
	[FIELDS_NAME.TWO_FA_TOGGLE]: false,
	[FIELDS_NAME.TWO_FA_FAILED_LOGIN_ATTEMPTS]: 3,
	[FIELDS_NAME.TWO_FA_AUTO_UNLOCK_TIME]: 1,
	[FIELDS_NAME.TWO_FA_AUTO_UNLOCK_COUNT]: 3
};

export const FAILED_LOGIN_ATTEMPTS_OPTIONS = constructArrayForGivenRange({
	from: 1,
	to: 10
});
export const AUTO_UNLOCK_TIME_OPTIONS = constructArrayForGivenRange({
	from: 1,
	to: 30
});
export const AUTO_UNLOCK_COUNT_OPTIONS = constructArrayForGivenRange({
	from: 1,
	to: 10
});
