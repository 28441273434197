export const USER_STATE = {
	IN_PROGRESS: 1,
	ACTIVE: 2,
	BLOCKED: 4,
	EXPIRED: 8,
	LOCKED: 16
};

export const USER_GENDER = {
	NONE: 1,
	MALE: 2,
	FEMALE: 4
};

export const USER_ROLE = {
	SUPER_ADMIN: 1,
	USER: 2,
	DEVELOPER: 4,
	ACCESS_MANAGER: 8
};

export const USER_ROLE_NAME = {
	[USER_ROLE.SUPER_ADMIN]: "SuperAdmin",
	[USER_ROLE.USER]: "User",
	[USER_ROLE.DEVELOPER]: "Developer",
	[USER_ROLE.ACCESS_MANAGER]: "AccessManager"
};
