import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { ACTIVATION_PASSWORD_SETTINGS_ACTION_BEFORE, ACTIVATION_PASSWORD_SETTINGS_ACTION_FINISH, SET_ACTIVATION_PASSWORD_SETTINGS } from "../../actionTypes";

const setPasswordSettingsBefore = () => ({
	type: ACTIVATION_PASSWORD_SETTINGS_ACTION_BEFORE
});

const setPasswordSettingsFinished = () => ({
	type: ACTIVATION_PASSWORD_SETTINGS_ACTION_FINISH
});

const setPasswordSettings = (settings) => ({
	type: SET_ACTIVATION_PASSWORD_SETTINGS,
	payload: { settings }
});

export const getPasswordSettings = (token, type) => {
	return (dispatch) => {
		dispatch(setPasswordSettingsBefore());

		const headers =
			type === "forceChange"
				? {
						Authorization: "Bearer " + token
					}
				: undefined;

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${type === "forceChange" ? ApiUrls.GET_PASSWORD_SETTINGS : ApiUrls.GET_ACTIVATION_PASSWORD_SETTINGS}`,
			method: Methods.GET,
			params: type !== "forceChange" ? { token } : {},
			headers: headers
		})
			.then(({ data: { value: settings } }) => {
				dispatch(setPasswordSettings(settings));
				dispatch(setPasswordSettingsFinished());
			})
			.catch(() => {
				dispatch(setPasswordSettingsFinished());
			});
	};
};
