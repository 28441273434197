import { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import WidgetSkeleton from "components/dashboard/statistics/widgets/skeleton";

import { getDashboardPendingBetslipsCount } from "store/actions/dashboard/dashboard/dashboard.action";

import { PROJECT_TYPE } from "constants/common.constants";

/** Dashboard Page Pending Betslips Widget Component */
const PendingBetslipsWidgetComponent = ({ globalPartnerId, onlinePendingBetslipsCount, retailPendingBetslipsCount, getDashboardPendingBetslipsCount, projectType, isPendingBetslipscountLoading }) => {
	const { t } = useTranslation();

	useEffect(() => {
		getDashboardPendingBetslipsCount(projectType);
		const interval = setInterval(() => {
			getDashboardPendingBetslipsCount(projectType);
		}, 120000);

		return () => {
			clearInterval(interval);
		};
	}, [globalPartnerId]);

	return (
		<div className="dashboard-widget">
			<div className="dashboard-widget-icon" data-type="betslips">
				<i className="icon-pendings" />
			</div>
			<div className="dashboard-widget-info">
				<span className="dashboard-widget-info-title">{t("pages.dashboard.dashboard.pending_bets")}</span>
				{isPendingBetslipscountLoading ? <WidgetSkeleton /> : <span className="dashboard-widget-info-value">{projectType === PROJECT_TYPE.ONLINE ? onlinePendingBetslipsCount : retailPendingBetslipsCount}</span>}
			</div>
		</div>
	);
};

/** PendingBetslipsWidgetComponent propTypes
 * PropTypes
 */
PendingBetslipsWidgetComponent.propTypes = {
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, online pending betslips count */
	onlinePendingBetslipsCount: PropTypes.number,
	/** Redux state property, retail pending betslips count */
	retailPendingBetslipsCount: PropTypes.number,
	/** Redux action to get pending betslips count */
	getDashboardPendingBetslipsCount: PropTypes.func,
	/** Project type(Online/ Retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	// Redux state controls pendingbetslips count loading indicator
	isPendingBetslipscountLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		onlinePendingBetslipsCount: state.dashboard.online.pendingBetslipsCount,
		retailPendingBetslipsCount: state.dashboard.retail.pendingBetslipsCount,
		globalPartnerId: state.partner.globalPartnerId,
		isPendingBetslipscountLoading: state.dashboard.isPendingBetslipscountLoading
	};
};

const mapDispatchToProps = (dispatch) => ({
	getDashboardPendingBetslipsCount: (projectType) => {
		dispatch(getDashboardPendingBetslipsCount(projectType));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingBetslipsWidgetComponent);
