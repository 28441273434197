import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import {
	PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_ACTION_BEFORE,
	PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_ACTION_FINISH,
	SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_FILTERS,
	SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_SORTING,
	SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY,
	PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS_ACTION_BEFORE,
	PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS_ACTION_FINISH,
	SET_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS,
	PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATION_SAVE_ACTION_BEFORE,
	PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATION_SAVE_ACTION_FINISH,
	ADD_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS
} from "store/actionTypes";


export const setPlayerSuspiciousActivitiesHistoryBefore = () => ({
	type: PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_ACTION_BEFORE
});

export const setPlayerSuspiciousActivitiesHistoryFinish = () => ({
	type: PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_ACTION_FINISH
});

export const setPlayerSuspiciousActivitiesHistoryFilters = (filters) => ({
	type: SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_FILTERS,
	payload: { filters }
})

export const setPlayerSuspiciousActivitiesHistorySorting = (sorting) => ({
	type: SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_SORTING,
	payload: { sorting }
});

export const setPlayerSuspiciousActivitiesHistoryData = (payload) => ({
	type: SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY,
	payload: payload
})


export const setPlayerSuspiciousActivitiesConfigurationsBefore = () => ({
	type: PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS_ACTION_BEFORE
});

export const setPlayerSuspiciousActivitiesConfigurationsFinish = () => ({
	type: PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS_ACTION_FINISH
});

export const setPlayerSuspiciousActivitiesConfigurationsData = (payload) => ({
	type: SET_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS,
	payload: payload
})

export const addPlayerSuspiciousActivitiesConfigurationsData = (data) => ({
	type: ADD_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS,
	payload: { data }
})

export const setPlayerSuspiciousActivitiesConfigurationSaveBefore = () => ({
	type: PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATION_SAVE_ACTION_BEFORE
});

export const setPlayerSuspiciousActivitiesConfigurationSaveFinish = () => ({
	type: PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATION_SAVE_ACTION_FINISH
});

export const getPlayerSuspiciousActivitiesHistory = (fromFirstPage) => {
	return (dispatch, getState) => {

		dispatch(setPlayerSuspiciousActivitiesHistoryBefore());
		const historyState = getState().riskManagement.playerSuspiciousActivities.history;
		const { partnerName, projectName, ...filters } = historyState.filters;

		const params = {
			...historyState.sorting,
			...filters,
			page: fromFirstPage ? 1 : historyState.sorting.page
		};
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY}`,
			method: Methods.GET,
			params: params
		})
			.then(({ data: { value } }) => {
				dispatch(setPlayerSuspiciousActivitiesHistoryData({ total: value.item1, data: value.item2 }));
				if (fromFirstPage) {
					dispatch(setPlayerSuspiciousActivitiesHistorySorting({ ...getState().riskManagement.playerSuspiciousActivities.history.sorting, page: 1 }));
				}
			})
			.finally(() => {
				dispatch(setPlayerSuspiciousActivitiesHistoryFinish());
			});
	};
}

export const getPlayerSuspiciousActivitiesConfigurations = () => {
	return (dispatch) => {
		dispatch(setPlayerSuspiciousActivitiesConfigurationsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS}`,
			method: Methods.GET
		})
			.then(({ data: { value } }) => {
				dispatch(setPlayerSuspiciousActivitiesConfigurationsData({ total: value.item1, data: value.item2 }));
			})
			.finally(() => {
				dispatch(setPlayerSuspiciousActivitiesConfigurationsFinish());
			});
	};
}

export const createPlayerSuspiciousActivitiesConfiguration = (data, onSuccess) => {
	return (dispatch, getState) => {
		dispatch(setPlayerSuspiciousActivitiesConfigurationSaveBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.ADD_RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATION}`,
			method: Methods.POST,
			data: data
		})
			.then(({ data: { value } }) => {
				dispatch(addPlayerSuspiciousActivitiesConfigurationsData(value));
				if (typeof onSuccess === "function") {
					onSuccess()	
				}
			})
			.finally(() => {
				dispatch(setPlayerSuspiciousActivitiesConfigurationSaveFinish());
			});
	};
}