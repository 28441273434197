import { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ChartGenerator from "./ChartGenerator";

const BetCount = ({ tooltipSymbol, categories, betCounts }) => {
	const { t } = useTranslation();
	const series = useMemo(
		() => [
			{
				name: t("pages.dashboard.dashboard.partners_revenue_bet_count"),
				data: betCounts
			}
		],
		[betCounts]
	);
	const Chart = useMemo(() => ChartGenerator({ tooltipSymbol, categories, series }), [tooltipSymbol, categories, series]);
	return <Chart />;
};

BetCount.propTypes = {
	/** React property, symbol of tooltip of chart */
	tooltipSymbol: PropTypes.string,
	/** React property, Highchart rows names */
	categories: PropTypes.arrayOf(PropTypes.string),
	/** React property, bet counts of all partners */
	betCounts: PropTypes.arrayOf(PropTypes.object)
};

export default BetCount;
