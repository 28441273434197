import {
	AUTHENTICATE_ACTION_BEFORE,
	AUTHENTICATE_ACTION_FINISH,
	AUTHENTICATE_ACTION_SET_EXPIRE,
	AUTHENTICATE_ACTION_SET_QRBASE64,
	ACTIVATE_ACTION_BEFORE,
	ACTIVATE_ACTION_FINISH,
	ACTIVATION_PASSWORD_SETTINGS_ACTION_BEFORE,
	ACTIVATION_PASSWORD_SETTINGS_ACTION_FINISH,
	SET_ACTIVATION_PASSWORD_SETTINGS,
	RESET_PASSWORD_ACTION_BEFORE,
	RESET_PASSWORD_ACTION_FINISH,
	FORGOT_PASSWORD_ACTION_BEFORE,
	FORGOT_PASSWORD_ACTION_FINISH,
	SET_ACTIVE_COMPONENT_NAME,
	SET_TEMPORARY_LOCK_DATA,
	SET_TWO_FACTOR_DATA
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case AUTHENTICATE_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case AUTHENTICATE_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case AUTHENTICATE_ACTION_SET_EXPIRE:
			return {
				...state,
				expires: payload.expires
			};
		case AUTHENTICATE_ACTION_SET_QRBASE64:
			return {
				...state,
				QRBase64: payload.base64
			};
		case ACTIVATION_PASSWORD_SETTINGS_ACTION_BEFORE:
			return {
				...state,
				passwordSettings: {
					...state.passwordSettings,
					isLoading: true
				}
			};
		case ACTIVATION_PASSWORD_SETTINGS_ACTION_FINISH:
			return {
				...state,
				passwordSettings: {
					...state.passwordSettings,
					isLoading: false
				}
			};
		case ACTIVATE_ACTION_BEFORE:
			return {
				...state,
				activation: {
					...state.activation,
					isActivating: true
				}
			};
		case ACTIVATE_ACTION_FINISH:
			return {
				...state,
				activation: {
					...state.activation,
					isActivating: false
				}
			};
		case SET_ACTIVATION_PASSWORD_SETTINGS:
			return {
				...state,
				passwordSettings: {
					...state.passwordSettings,
					passwordSettings: payload.settings
				}
			};
		case RESET_PASSWORD_ACTION_BEFORE:
			return {
				...state,
				resetPassword: {
					...state.resetPassword,
					isReseting: true
				}
			};
		case RESET_PASSWORD_ACTION_FINISH:
			return {
				...state,
				resetPassword: {
					...state.resetPassword,
					isReseting: false
				}
			};
		case FORGOT_PASSWORD_ACTION_BEFORE:
			return {
				...state,
				forgotPassword: {
					...state.forgotPassword,
					isSaving: true
				}
			};
		case FORGOT_PASSWORD_ACTION_FINISH:
			return {
				...state,
				forgotPassword: {
					...state.forgotPassword,
					isSaving: false
				}
			};
		case SET_ACTIVE_COMPONENT_NAME:
			return {
				...state,
				activeComponentName: payload
			};
		case SET_TEMPORARY_LOCK_DATA:
			return {
				...state,
				mainLogin: {
					...state.mainLogin,
					temporaryLockMessageVisible: payload.isVisible,
					unlockDate: payload.unlockDate
				}
			};
		case SET_TWO_FACTOR_DATA:
			return {
				...state,
				twoFactorData: {
					...state.twoFactorData,
					...payload
				}
			};
		default:
			return state;
	}
};
