import { SYSTEM_BETSHOPS_BEFORE, SYSTEM_BETSHOPS_FINISH, SYSTEM_SET_BETSHOPS } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SYSTEM_BETSHOPS_BEFORE:
			return {
				...state,
				isTimezonesLoading: true
			};
		case SYSTEM_BETSHOPS_FINISH:
			return {
				...state,
				isTimezonesLoading: false
			};
		case SYSTEM_SET_BETSHOPS:
			return {
				...state,
				timezones: payload.timezones
			};

		default:
			return state;
	}
};
