import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { getPermissionRequests, approvePermissionRequest, rejectPermissionRequest } from "store/actions/dashboard/userManagement/requests/pending.action";
import RequestsCommonTable from "./requestsCommonTable";
import { expandColumns, getTableColumns } from "./columns";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import Paths from "constants/path.constants";
import permissionRequestType from "types/permissionRequest/request.type";
import notificationType from "types/notification/notification.type";
import { PERMISSION_REQUESTS_MENU_KEYS, PERMISSION_REQUEST_STATUS, PERMISSION_REQUEST_TYPE } from "constants/permissionRequest.constants";
import Question from "components/common/question";
import { binaryToFlags } from "utils/common";
import { useSearchParams } from "react-router-dom";

const PendingRequests = ({ getPermissionRequests, requests, isLoading, globalPartnerId, approvePermissionRequest, rejectPermissionRequest, notifications, hash, search }) => {
	const { t } = useTranslation();
	const [confirmation, setConfirmation] = useState(null);
	const [searchParams] = useSearchParams(search);
	const navigate = useNavigate();
	const objectId = searchParams.get("objectId") || null;
	const objectType = Number(searchParams.get("objectType")) || null;
	const actionTypes = binaryToFlags(
		Object.values(PERMISSION_REQUEST_TYPE),
		Number(searchParams.get("actionTypes")) || 0
	);
	const thereIsAnyFilter = Boolean(search) && Boolean(objectId || objectType || (actionTypes && (actionTypes.length > 0)))

	const generateAction = () => {
		if (hasPermission({ resource: PERMISSION_RESOURCE.PERMISSION_REQUESTS, action: PERMISSION_ACTION.MODIFY })) {
			return {
				rejectedAction: {
					handler: (record) => {
						setConfirmation({
							id: record.id,
							type: PERMISSION_REQUEST_STATUS.REJECTED
						});
					},
					title: t("pages.dashboard.permissions_requests.reject"),
					icon: <i className="icon-cancel vs--font-bigest" />
				},
				approvedAction: {
					handler: (record) => {
						setConfirmation({
							id: record.id,
							type: PERMISSION_REQUEST_STATUS.APPROVED
						});
					},
					title: t("pages.dashboard.permissions_requests.approve"),
					icon: <i className="icon-check vs--font-bigest" />
				}
			};
		}
		return null;
	};

	const closeConfirmation = () => setConfirmation(null);
	const handleConfirmation = (note) => {
		switch (confirmation.type) {
			case PERMISSION_REQUEST_STATUS.APPROVED:
				approvePermissionRequest(confirmation.id);
				break;
			case PERMISSION_REQUEST_STATUS.REJECTED:
				rejectPermissionRequest(confirmation.id, note);
				break;
			default:
				break;
		}
		if (thereIsAnyFilter) {
			navigate(
				{
					pathname: Paths.PERMISSION_REQUESTS,
					hash: `tab=${PERMISSION_REQUESTS_MENU_KEYS.PENDING_REQUESTS}`
				},
				{
					replace: true
				}
			);
		}
		closeConfirmation();
	};

	return (
		<div className="dashboard-section-content">
			<RequestsCommonTable
				isLoading={isLoading}
				columns={getTableColumns(false)}
				loadFn={() =>
					getPermissionRequests(objectId, actionTypes, objectType, (responseData) => {
						if (thereIsAnyFilter && Array.isArray(responseData) && responseData.length === 0) {
							navigate(
								{
									pathname: Paths.PERMISSION_REQUESTS,
									hash: `tab=${PERMISSION_REQUESTS_MENU_KEYS.PENDING_REQUESTS}`
								},
								{
									replace: true
								}
							);
						}
					})
				}
				data={requests}
				total={requests ? requests.length : 0}
				updateProps={[globalPartnerId, notifications.length, hash, search, thereIsAnyFilter]}
				actions={generateAction()}
				isDisabledFn={() => false}
				uniqueKey="key"
				noPagination={true}
				detailsFn={(record) => {
					return {
						columns: expandColumns,
						data: record.changes.map((change, index) => ({ index: index + 1, change })),
						uniqueKey: "index"
					};
				}}
			/>
			{confirmation?.type === PERMISSION_REQUEST_STATUS.APPROVED && <Question title={t("pages.dashboard.permissions_requests.approve")} message={t("pages.dashboard.permissions_requests.approveConfirmationMessage")} onOk={handleConfirmation} onCancel={closeConfirmation} isVisible={true} />}

			{confirmation?.type === PERMISSION_REQUEST_STATUS.REJECTED && (
				<Question
					title={t("pages.dashboard.permissions_requests.reject")}
					message={t("pages.dashboard.permissions_requests.rejectConfirmationMessage")}
					onOk={handleConfirmation}
					onCancel={closeConfirmation}
					isVisible={true}
					isPromptRequired={true}
					type="prompt"
					promptLabel={t("pages.dashboard.permissions_requests.notes")}
					maxLength={201}
					promptValidationRules={[
						{
							min: 5,
							message: t("validation.must_be_more_than_other").replace("%X%", t("pages.dashboard.permissions_requests.rejectNotes")).replace("%Y%", 5)
						},
						{
							max: 200,
							message: t("validation.must_be_less_than_other").replace("%X%", t("pages.dashboard.permissions_requests.rejectNotes")).replace("%Y%", 200)
						}
					]}
				/>
			)}
		</div>
	);
};

PendingRequests.propTypes = {
	/** Redux action to get permission requests */
	getPermissionRequests: PropTypes.func,
	/** Redux state property, represents the array of requests  */
	requests: PropTypes.arrayOf(permissionRequestType),
	/** Redux state property, is true when loading requests */
	isLoading: PropTypes.bool,
	/** Redux state property, represents global parner id */
	globalPartnerId: PropTypes.string,
	/** Redux action to approve permission request */
	approvePermissionRequest: PropTypes.func,
	/** Redux action to reject permission request */
	rejectPermissionRequest: PropTypes.func,
	/** Redux state, represents the array of notifications  */
	notifications: PropTypes.arrayOf(notificationType),
	/** React property, url hash value  */
	hash: PropTypes.string,
	/** React property, url search query params  */
	search: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		globalPartnerId: state.partner.globalPartnerId,
		isLoading: state.permissionRequests.isLoading,
		requests: state.permissionRequests.pending.requests,
		notifications: state.notifications.notifications
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getPermissionRequests: (userId, actionType, objectType, callback) => {
			dispatch(getPermissionRequests(userId, actionType, objectType, callback));
		},

		approvePermissionRequest: (id, callback) => {
			dispatch(approvePermissionRequest(id, callback));
		},

		rejectPermissionRequest: (id, note, callback) => {
			dispatch(rejectPermissionRequest(id, note, callback));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingRequests);
