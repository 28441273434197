import { Fragment, useEffect, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Button, Tooltip, AutoComplete, Divider, Select, Tag } from "antd";
const { Item: FormItem } = Form;

import SearchableSelect from "components/common/searchableSelect";
import NumericInput from "components/common/numericInput";
import Question from "components/common/question";

import { getBetshopCurrency, saveBetshopCurrency, deleteBetshopCurrency } from "store/actions/dashboard/retail/betshops/currency.action";
import { getPartnerAvailableCurrencies } from "store/actions/dashboard/partners/currencies.action";

import { isFormChanged } from "utils/form";
import { numberTransform } from "utils/common";
import { favoriteBetsRepeatingIndexes, validateMax, validateMin, validatePosMax } from "utils/currency";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { FAVORITE_BETS, FAVORITE_BET_SUM } from "constants/bet.constants";
import { INTEGRATION_TYPE } from "constants/betshop.constants";

import currencyType from "types/currency/currency.type";

/** Betshop Edit Page Currecy Tab Component */
const CurrencyComponent = ({ currency, getBetshopCurrency, isSaving, saveBetshopCurrency, deleteBetshopCurrency, getPartnerAvailableCurrencies, availableCurrencies, isAvailableLoading, integrationType, onTabChange }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, getFieldValue, setFieldsValue, getFieldError, getFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [resetConfirmPopup, setResetConfirmPopup] = useState(false);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.MODIFY });

	/** Load betshop currency, load partner currencies */
	useEffect(() => {
		getBetshopCurrency(routeParams.id);
		getPartnerAvailableCurrencies();
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			retailFavoriteBets: currency.retailFavoriteBets,
			betLimits: currency.betLimits,
			code: currency.code
		});
		validateFields();
	}, [currency]);

	/** Fires when form submitted
	 * @function
	 * @memberOf CurrencyComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				saveBetshopCurrency({
					id: routeParams.id,
					isDefault: true,
					...data,
					favoriteBets: data.retailFavoriteBets // TODO: Need improvement
				});
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	/** Fires on "Apply to all" button click
	 * @function
	 * @memberOf CurrencyComponent
	 */
	const applyToAll = () => {
		let min, max, posWin;
		let minIsValid = true,
			maxIsValid = true,
			posWinIsValid = true;
		min = getFieldValue(["betLimits", "singleMin"]);
		max = getFieldValue(["betLimits", "singleMax"]);
		posWin = getFieldValue(["betLimits", "singlePossibleWinMax"]);
		if (getFieldError(["betLimits", "singleMin"]).length !== 0) minIsValid = false;
		if (getFieldError(["betLimits", "singleMax"]).length !== 0) maxIsValid = false;
		if (getFieldError(["betLimits", "singlePossibleWinMax"]).length !== 0) posWinIsValid = false;

		const fields = {};
		if (minIsValid) {
			fields.singleMin = min;
			fields.multiMin = min;
		}
		if (maxIsValid) {
			fields.singleMax = max;
			fields.multiMax = max;
		}

		if (posWinIsValid) {
			fields.singlePossibleWinMax = posWin;
			fields.multiPossibleWinMax = posWin;
		}

		const result = {};
		result.betLimits = { ...fields };
		setFieldsValue(result);
		setIsFormTouched(isFormChanged({ ...getFieldsValue() }, { retailFavoriteBets: currency.retailFavoriteBets, betLimits: currency.betLimits, code: currency.code }));
	};

	/** Fires on "Reset to Partner's values" button click
	 * @function
	 * @memberOf CurrencyComponent
	 */
	const handleReset = () => {
		deleteBetshopCurrency(routeParams.id, currency.code);
		setIsFormTouched(false);
		setResetConfirmPopup(false);
	};

	return (
		<Form colon={false} form={formInstance} requiredMark={false} layout="vertical" onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, { retailFavoriteBets: currency.retailFavoriteBets, betLimits: currency.betLimits, code: currency.code }))}>
			<div className="dashboard-section-content">
				<div>
					<Row gutter={[16, 0]}>
						<Col xs={24} sm={12} xl={6}>
							<FormItem label={t("pages.dashboard.betshops.default_currency")} name="code">
								<SearchableSelect
									loading={isAvailableLoading}
									items={Object.keys(availableCurrencies)}
									valueProp={(item) => item.toUpperCase()}
									textProp={(item) => availableCurrencies[item]}
									renderText={(item) => item.toUpperCase() + " - " + availableCurrencies[item]}
									onChange={(code) => getBetshopCurrency(routeParams.id, code)}
									disabled={true}
								/>
							</FormItem>
						</Col>
						<Col xs={24} sm={12} xl={6}>
							<FormItem label={t("pages.dashboard.currency.decimal_count")}>
								<Select value={currency.decimalCount} suffixIcon={<i className="icon-down" />} disabled={true}>
									<Select.Option value={0}>0</Select.Option>
									<Select.Option value={1}>0,1</Select.Option>
									<Select.Option value={2}>0,12</Select.Option>
									<Select.Option value={3}>0,123</Select.Option>
									<Select.Option value={4}>0,1234</Select.Option>
									<Select.Option value={5}>0,12345</Select.Option>
									<Select.Option value={6}>0,123456</Select.Option>
									<Select.Option value={7}>0,1234567</Select.Option>
									<Select.Option value={8}>0,12345678</Select.Option>
								</Select>
							</FormItem>
						</Col>
					</Row>
					{integrationType === INTEGRATION_TYPE.STANDALONE && (
						<Fragment>
							<Divider style={{ marginBottom: "0" }} />
							<Row>
								<Col xs={24} sm={24} xl={12}>
									<Row gutter={[16, 0]}>
										<Col span={24}>
											<FormItem style={{ marginBottom: "8px", marginTop: "8px" }}>{currency.isOverride ? <Tag color="green">{t("pages.dashboard.currency.overriden")}</Tag> : <Tag color="red">{t("pages.dashboard.currency.not_overriden")}</Tag>}</FormItem>
										</Col>
									</Row>
									<Row gutter={[16, 0]}>
										{[0, 1, 2, 3, 4, 5, 6, 7].map((i) => (
											<Col xs={24} sm={6} key={i}>
												<FormItem
													label={i === 0 ? t("pages.dashboard.currency.retail_favorite_bets") : " "}
													name={["retailFavoriteBets", i]}
													className="form-item-without-margin"
													rules={[
														{ required: true, message: t("validation.field_required") },
														({ getFieldValue }) => ({
															validator(rule, value) {
																if ((value && value.length > 20) || (value !== "" && (isNaN(value) || Number(value) < 0.00001) && !FAVORITE_BETS.includes(value))) {
																	return Promise.reject(t("validation.field_invalid"));
																} else if (favoriteBetsRepeatingIndexes(getFieldValue("retailFavoriteBets")).includes(i)) {
																	return Promise.reject(t("validation.favorite_bets_no_duplicate"));
																} else if (!FAVORITE_BETS.includes(value) && value !== "") {
																	let isMinValid = false,
																		isMaxValid = false;
																	if (getFieldValue("betLimits").multiMax === "" || getFieldValue("betLimits").singleMax === "" || Number(value) <= Number(getFieldValue("betLimits").multiMax) || Number(value) <= Number(getFieldValue("betLimits").singleMax)) isMaxValid = true;
																	if (getFieldValue("betLimits").multiMin === "" || getFieldValue("betLimits").singleMin === "" || Number(value) >= Number(getFieldValue("betLimits").multiMin) || Number(value) >= Number(getFieldValue("betLimits").singleMin)) isMinValid = true;

																	if (!isMaxValid) {
																		return Promise.reject(t("validation.favorite_bets_max"));
																	} else if (!isMinValid) {
																		return Promise.reject(t("validation.favorite_bets_min"));
																	}
																}
																return Promise.resolve();
															}
														})
													]}
												>
													<AutoComplete
														filterOption={() => true}
														options={FAVORITE_BETS.map((value) => ({ value }))}
														onChange={(value) => {
															if (value === FAVORITE_BET_SUM) {
																const values = formInstance.getFieldsValue();
																const updated = { ...values };
																updated["retailFavoriteBets"] = updated["retailFavoriteBets"].map((v) => {
																	if (v === FAVORITE_BET_SUM) {
																		const betLimits = values["betLimits"];
																		const sumValue = Math.min(betLimits.singleMin, betLimits.multiMin);
																		return `+${sumValue}`;
																	}
																	return v;
																});
																formInstance.setFieldsValue(updated);
															}
															setTimeout(() => {
																const nonEmpty = getFieldValue("retailFavoriteBets")
																	.map((f, i) => (f !== "" ? i : f))
																	.filter((f) => f !== "");
																validateFields(nonEmpty.map((f) => ["retailFavoriteBets", f]));
															}, 0);
														}}
														disabled={isDisabled}
														showArrow={true}
														suffixIcon={<i className="icon-down" />}
													></AutoComplete>
												</FormItem>
											</Col>
										))}
									</Row>

									<Row>
										<Col xs={24} sm={12}>
											<div className="ant-modal-section-title">
												<h4>{t(`pages.dashboard.currency.bet_limits`)}</h4>
												{!isDisabled && (
													<Tooltip title={t("pages.dashboard.currency.apply_to_all")}>
														<i className="icon-reset" style={{ cursor: "pointer" }} onClick={applyToAll} />
													</Tooltip>
												)}
											</div>
										</Col>
									</Row>
									<Row gutter={[16, 0]}>
										<Col xs={24} sm={12}>
											<FormItem
												label={t("pages.dashboard.currency.min_single")}
												name={["betLimits", "singleMin"]}
												rules={[
													{ required: true, message: t("validation.field_required") },
													{ type: "number", min: 0.01, message: t("validation.field_invalid"), transform: numberTransform },
													({ getFieldValue }) => ({
														validator(rule, value) {
															return validateMin(value, getFieldValue("betLimits").singleMax);
														}
													})
												]}
											>
												<NumericInput
													placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.min_single")}`}
													onChange={() => {
														setTimeout(() => {
															if (getFieldValue(["betLimits", "singleMax"]) !== "") {
																validateFields([["betLimits", "singleMax"]]);
															}
															const nonEmpty = getFieldValue("retailFavoriteBets")
																.map((f, i) => (f !== "" ? i : f))
																.filter((f) => f !== "");
															validateFields(nonEmpty.map((f) => ["retailFavoriteBets", f]));
														}, 0);
													}}
													disabled={isDisabled}
												/>
											</FormItem>
										</Col>
										<Col xs={24} sm={12}>
											<FormItem
												label={t("pages.dashboard.currency.min_multi")}
												name={["betLimits", "multiMin"]}
												rules={[
													{ required: true, message: t("validation.field_required") },
													{ type: "number", min: 0.01, message: t("validation.field_invalid"), transform: numberTransform },
													({ getFieldValue }) => ({
														validator(rule, value) {
															return validateMin(value, getFieldValue("betLimits").multiMax);
														}
													})
												]}
											>
												<NumericInput
													placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.min_multi")}`}
													onChange={() => {
														setTimeout(() => {
															if (getFieldValue(["betLimits", "multiMax"]) !== "") validateFields([["betLimits", "multiMax"]]);
															const nonEmpty = getFieldValue("retailFavoriteBets")
																.map((f, i) => (f !== "" ? i : f))
																.filter((f) => f !== "");
															validateFields(nonEmpty.map((f) => ["retailFavoriteBets", f]));
														}, 0);
													}}
													disabled={isDisabled}
												/>
											</FormItem>
										</Col>
									</Row>
									<Row gutter={[16, 0]}>
										<Col xs={24} sm={12}>
											<FormItem
												label={t("pages.dashboard.currency.max_single")}
												name={["betLimits", "singleMax"]}
												rules={[
													{ required: true, message: t("validation.field_required") },
													{ type: "number", min: 0.01, message: t("validation.field_invalid"), transform: numberTransform },
													({ getFieldValue }) => ({
														validator(rule, value) {
															return validateMax(getFieldValue("betLimits").singleMin, value, getFieldValue("betLimits").singlePossibleWinMax);
														}
													})
												]}
											>
												<NumericInput
													placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.max_single")}`}
													onChange={() => {
														setTimeout(() => {
															if (getFieldValue(["betLimits", "singleMin"]) !== "") validateFields([["betLimits", "singleMin"]]);
															if (getFieldValue(["betLimits", "singlePossibleWinMax"]) !== "") validateFields([["betLimits", "singlePossibleWinMax"]]);
															const nonEmpty = getFieldValue("retailFavoriteBets")
																.map((f, i) => (f !== "" ? i : f))
																.filter((f) => f !== "");
															validateFields(nonEmpty.map((f) => ["retailFavoriteBets", f]));
														}, 0);
													}}
													disabled={isDisabled}
												/>
											</FormItem>
										</Col>
										<Col xs={24} sm={12}>
											<FormItem
												label={t("pages.dashboard.currency.max_multi")}
												name={["betLimits", "multiMax"]}
												rules={[
													{ required: true, message: t("validation.field_required") },
													{ type: "number", min: 0.01, message: t("validation.field_invalid"), transform: numberTransform },
													({ getFieldValue }) => ({
														validator(rule, value) {
															return validateMax(getFieldValue("betLimits").multiMin, value, getFieldValue("betLimits").multiPossibleWinMax);
														}
													})
												]}
											>
												<NumericInput
													placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.max_multi")}`}
													onChange={() => {
														setTimeout(() => {
															if (getFieldValue(["betLimits", "multiMin"]) !== "") validateFields([["betLimits", "multiMin"]]);
															if (getFieldValue(["betLimits", "multiPossibleWinMax"]) !== "") validateFields([["betLimits", "multiPossibleWinMax"]]);
															const nonEmpty = getFieldValue("retailFavoriteBets")
																.map((f, i) => (f !== "" ? i : f))
																.filter((f) => f !== "");
															validateFields(nonEmpty.map((f) => ["retailFavoriteBets", f]));
														}, 0);
													}}
													disabled={isDisabled}
												/>
											</FormItem>
										</Col>
									</Row>
									<Row gutter={[16, 0]}>
										<Col xs={24} sm={12}>
											<FormItem
												label={t("pages.dashboard.currency.max_possible_win_single")}
												name={["betLimits", "singlePossibleWinMax"]}
												rules={[
													{ required: true, message: t("validation.field_required") },
													{ type: "number", min: 0.01, message: t("validation.field_invalid"), transform: numberTransform },
													({ getFieldValue }) => ({
														validator(rule, value) {
															return validatePosMax(getFieldValue("betLimits").singleMax, value);
														}
													})
												]}
											>
												<NumericInput
													placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.max_possible_win_single")}`}
													onChange={() => {
														setTimeout(() => {
															if (getFieldValue(["betLimits", "singleMax"]) !== "") validateFields([["betLimits", "singleMax"]]);
														}, 0);
													}}
													disabled={isDisabled}
												/>
											</FormItem>
										</Col>
										<Col xs={24} sm={12}>
											<FormItem
												label={t("pages.dashboard.currency.max_possible_win_multi")}
												name={["betLimits", "multiPossibleWinMax"]}
												rules={[
													{ required: true, message: t("validation.field_required") },
													{ type: "number", min: 0.01, message: t("validation.field_invalid"), transform: numberTransform },
													({ getFieldValue }) => ({
														validator(rule, value) {
															return validatePosMax(getFieldValue("betLimits").multiMax, value);
														}
													})
												]}
											>
												<NumericInput
													placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.max_possible_win_multi")}`}
													onChange={() => {
														setTimeout(() => {
															if (getFieldValue(["betLimits", "multiMax"]) !== "") validateFields([["betLimits", "multiMax"]]);
														}, 0);
													}}
													disabled={isDisabled}
												/>
											</FormItem>
										</Col>
									</Row>
								</Col>
								<Col xs={24} sm={24} xl={12}>
									{!isDisabled && (
										<FormItem className="betshops-currency-reset" style={{ marginTop: "8px" }}>
											<Button type="secondary" htmlType="button" onClick={() => setResetConfirmPopup(true)} disabled={!currency.isOverride && !isFormTouched}>
												{t("pages.dashboard.betshops.reset_to_default_values")}
											</Button>
										</FormItem>
									)}
								</Col>
							</Row>
						</Fragment>
					)}
				</div>
				{integrationType === INTEGRATION_TYPE.STANDALONE && !isDisabled && (
					<FormItem className="button-container">
						<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
							<span>{t("common.save")}</span>
						</Button>
					</FormItem>
				)}
			</div>
			<Question type="confirm" onOk={handleReset} onCancel={() => setResetConfirmPopup(false)} isVisible={resetConfirmPopup} message={t("pages.dashboard.betshops.reset_to_default_values_question")} />
		</Form>
	);
};

/** CurrencyComponent propTypes
 * PropTypes
 */
CurrencyComponent.propTypes = {
	/** Redux action to get betshop currency */
	getBetshopCurrency: PropTypes.func,
	/** Redux state property, represents the betshop currencies  */
	currency: currencyType,
	/** Redux state property, is true when saving betshop currency */
	isSaving: PropTypes.bool,
	/** Redux action to save betshop currency */
	saveBetshopCurrency: PropTypes.func,
	/** Redux action to reset betshop currency to partner currency */
	deleteBetshopCurrency: PropTypes.func,
	/** Redux action to get available currencies */
	getPartnerAvailableCurrencies: PropTypes.func,
	/** Redux state property, represents the object of available currencies  */
	availableCurrencies: PropTypes.object,
	/** Redux state property, is true when loading available currencies  */
	isAvailableLoading: PropTypes.bool,
	/** Redux state, represents the integration type of current editing betshop  */
	integrationType: PropTypes.oneOf(Object.values(INTEGRATION_TYPE)),
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getBetshopCurrency: (id, code) => {
		dispatch(getBetshopCurrency(id, code));
	},
	saveBetshopCurrency: (currency) => {
		dispatch(saveBetshopCurrency(currency));
	},
	deleteBetshopCurrency: (id, code) => {
		dispatch(deleteBetshopCurrency(id, code));
	},
	getPartnerAvailableCurrencies: () => {
		dispatch(getPartnerAvailableCurrencies());
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.betshops.isSaving,
		currency: state.betshops.editingBetshop.currency,
		availableCurrencies: state.partner.currency.availableCurrencies,
		isAvailableLoading: state.partner.currency.isAvailableLoading,
		integrationType: state.betshops.editingBetshop.generalInfo.integrationType
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyComponent);
