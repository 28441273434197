import { Button } from "antd";
import { EXPORT_TYPE } from "constants/common.constants";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { exportData } from "store/actions/dashboard/export/export.action";

const ExportImminently = ({ 
	url, 
	filters, 
	sorting, 
	exportType = EXPORT_TYPE.CSV, 
	exportData, 
	tableName: argTableName = null 
}) => {
	const { t } = useTranslation()
	const tableName = argTableName ?? t("common.export")
	const exportHandler = () => {
		exportData(url, exportType, [], tableName, filters, sorting);
	}
	return (
		<Button onClick={exportHandler} type="secondary">
			{tableName}
		</Button>
	)
}

/** ExportButton propTypes
 * PropTypes
 */
ExportImminently.propTypes = {
	exportType: PropTypes.oneOf([EXPORT_TYPE.CSV, EXPORT_TYPE.PDF]),
	/** Export api url */
	url: PropTypes.string,
	/** Redux action, export data */
	exportData: PropTypes.func,
	tableName: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	exportData: (url, exportType, exportColumns, tableName, filters, sorting) => {
		dispatch(exportData(url, exportType, exportColumns, tableName, filters, sorting));
	}
});

export default connect(null, mapDispatchToProps)(ExportImminently)