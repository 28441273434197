import { MAINTENANCE_MODE_BEFORE, MAINTENANCE_MODE_FINISH, SET_MAINTENANCE_MODE } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case MAINTENANCE_MODE_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case MAINTENANCE_MODE_FINISH:
			return {
				...state,
				isLoading: false
			};
		case SET_MAINTENANCE_MODE:
			return {
				...state,
				maintenanceMode: payload.data
			};
		default:
			return state;
	}
};
