import PropTypes from "prop-types";

import { USER_STATE, USER_ROLE } from "constants/user.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	userName: PropTypes.string,
	email: PropTypes.string,
	role: PropTypes.oneOf(Object.values(USER_ROLE)),
	state: PropTypes.oneOf(Object.values(USER_STATE)),
	tel: PropTypes.string,
	address: PropTypes.string,
	allowEdit: PropTypes.bool,
	mainPartner: PropTypes.string,
	partners: PropTypes.arrayOf(PropTypes.string),
	avatarId: PropTypes.number
});
