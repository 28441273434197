import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { STREAMS_BEFORE, STREAMS_FINISH, STREAMS_SET_SORTING, SET_STREAMS, ADD_STREAM } from "../../../actionTypes";

export const setStreamsActionBefore = () => ({
	type: STREAMS_BEFORE
});

export const setStreamsActionFinished = () => ({
	type: STREAMS_FINISH
});

const setStreams = (streams) => ({
	type: SET_STREAMS,
	payload: { streams }
});

const addStream = (stream) => ({
	type: ADD_STREAM,
	payload: { stream }
});

export const setStreamsSorting = (sorting) => ({
	type: STREAMS_SET_SORTING,
	payload: { sorting }
});

export const getStreams = (fromFirstPage) => {
	return (dispatch, getState) => {
		dispatch(setStreamsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.STREAMS_GETALL}`,
			method: Methods.GET,
			params: {
				...getState().streams.sorting,
				page: fromFirstPage ? 1 : getState().streams.sorting.page
			}
		})
			.then(({ data: { value: streams } }) => {
				dispatch(setStreams(streams));
				if (fromFirstPage) {
					dispatch(setStreamsSorting({ ...getState().streams.sorting, page: 1 }));
				}
			})
			.finally((ex) => {
				dispatch(setStreamsActionFinished());
			});
	};
};

export const createStream = (streamData, onSuccess) => {
	return (dispatch) => {
		dispatch(setStreamsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.STREAMS_CREATE}`,
			method: Methods.POST,
			data: streamData
		})
			.then(({ data: { value: stream } }) => {
				dispatch(addStream(stream));
				onSuccess && onSuccess(stream);
			})
			.finally((ex) => {
				dispatch(setStreamsActionFinished());
			});
	};
};
