import { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { Tooltip } from "antd";

import HistoryFilters from "./historyFilters";
import { useTranslation } from "react-i18next";
import { getBigMultipliersHistory, setBigMultipliersHistorySorting } from "store/actions/dashboard/riskManagment/bigMultipliers/bigMultipliers.actions";

import Table from "components/common/table";
import ExportButtonImminently from "components/common/exportButton/exportButtonImminently";

import { binaryToFlags, getGamesFromConfig } from "utils/common";
import { hasPermission } from "utils/permissions";
import { GAME_TYPE_MAPPER } from "constants/game.constants";
import { EXPORT_TYPE } from "constants/common.constants";
import { DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import ApiUrls from "constants/api.constants";

const dashedString = "- - -";

const BigMultipliersHistory = ({
	isLoading = false,
	bigMultipliersHistory = [],
	total = 0,
	filters = {},
	sorting = {},
	getBigMultipliersHistory = Function.prototype,
	setBigMultipliersHistorySorting = Function.prototype,
}) => {

	const { t } = useTranslation();
	const columns = [
		{
			title: t("pages.dashboard.bets.betslip_id"),
			dataIndex: "betSlipId",
			render: (value) => {
				return value ?? dashedString
			}
		},
		{
			title: t("pages.dashboard.bets.bet_id"),
			dataIndex: "betId",
			render: (value) => {
				return value ?? dashedString
			}
		},
		{
			title: t("pages.dashboard.bets.date"),
			dataIndex: "calculateTime",
			render: (value) => {
				return (
					<Fragment>
						<div>{moment.utc(value).local().format(DATE_FORMAT)}</div>
						<div>{moment.utc(value).local().format(TIME_FORMAT)}</div>
					</Fragment>
				);
			}
		},
		{
			title: t("pages.dashboard.bets.player_external_id"),
			dataIndex: "playerExternalId"
		},
		{
			title: t("pages.dashboard.bets.player_id"),
			dataIndex: "playerId"
		},
		{
			title: t("common.partner"),
			dataIndex: "partnerName",
			sorter: false
		},
		{
			title: t("pages.dashboard.bets.project"),
			dataIndex: "projectName",
			sorter: false
		},
		{
			title: `${t("pages.dashboard.risk_management.big_multipliers_history_filter_betAmount")} (EUR)`,
			dataIndex: "defaultBetAmount",
			render: (_, record) => {
				return record?.betAmount ?? dashedString;
			}
		},
		{
			title: `${t("pages.dashboard.risk_management.big_multipliers_history_filter_winAmount")} (EUR)`,
			dataIndex: "defaultWinAmount",
			render: (_, record) => {
				return record?.winAmount ?? dashedString;
			}
		},
		{
			title: t("pages.dashboard.risk_management.big_multipliers_history_filter_multiplier"),
			dataIndex: "multiplier"
		},
		{
			title: t("pages.dashboard.games.gameCategory"),
			dataIndex: "gameCategory",
			render: (value) => {
				return (
					t(`pages.dashboard.games.gameCategory_${value}`)
				)
			}
		},
		{
			title: t("pages.dashboard.bets.game_type"),
			dataIndex: "gameType",
			render: (value, record) => {
				const gameNames = GAME_TYPE_MAPPER[record?.gameCategory];
				const allGames = getGamesFromConfig(record?.gameCategory).map(game => game.value)
				const gameTypes = binaryToFlags(allGames, value)
				const betCount = record?.betCount ?? 0;
				const betSlipId = record?.betSlipId;

				if (gameTypes.length === 0) {
					return dashedString
				}

				if (!betSlipId) {
					return t(`common.${gameNames[gameTypes[0]]}`)
				}

				return (
					<Tooltip
						title={
							gameTypes.map((gameType) => <div key={gameType}>{t(`common.${gameNames[gameType]}`)}</div>)
						}
					>
						{`${t("pages.dashboard.bets.multi")} (${record?.betCount ?? 0})`}
					</Tooltip>
				)
			},
			sorter: false
		}
	];
	const { partnerName, ...exportFilters } = filters;

	return (
		<div className="dashboard-section table-section">
			<div className="dashboard-section-content">
				<div className="table-header">
					<div className="table-buttons-dropdowns">
						{hasPermission({ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BIG_MULTIPLIERS_HISTORY, action: PERMISSION_ACTION.EXPORT }) && (
							<ExportButtonImminently
								url={ApiUrls.EXPORT_RISK_MANAGEMENT_BIG_MULTIPLIERS_HISTORY}
								exportType={EXPORT_TYPE.CSV}
								filters={exportFilters}
								sorting={sorting}
							/>
						)}
					</div>
					<HistoryFilters />
				</div>
				<Table
					loading={isLoading}
					columns={columns}
					data={bigMultipliersHistory}
					loadFn={getBigMultipliersHistory}
					sorting={sorting}
					setSortingFn={setBigMultipliersHistorySorting}
					total={total}
					detailsType="table"
					uniqueKey="id"
				/>
			</div>
		</div>
	);
};

/**
 * BigMultipliersHistory PropTypes
 */
BigMultipliersHistory.propTypes = {
	filters: PropTypes.object,
	sorting: PropTypes.object,
	isLoading: PropTypes.bool,
	bigMultipliersHistory: PropTypes.arrayOf(PropTypes.object),
	total: PropTypes.number,
	getBigMultipliersHistory: PropTypes.func,
	setBigMultipliersHistorySorting: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getBigMultipliersHistory: (fromFirstPage) => {
		dispatch(getBigMultipliersHistory(fromFirstPage));
	},
	setBigMultipliersHistorySorting: (sorting) => {
		dispatch(setBigMultipliersHistorySorting(sorting));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.riskManagement.bigMultipliers.history.filters,
		sorting: state.riskManagement.bigMultipliers.history.sorting,
		isLoading: state.riskManagement.bigMultipliers.history.isLoading,
		bigMultipliersHistory: state.riskManagement.bigMultipliers.history.data,
		total: state.riskManagement.bigMultipliers.history.total
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BigMultipliersHistory);
