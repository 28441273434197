import { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPlayerGeneralInfo } from "store/actions/dashboard/online/players/general.action";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tabs } from "antd";
import Breadcrumbs from "components/common/breadcrumbs";
import GeneralInfoComponent from "./sections/general";
import SessionsComponent from "./sections/sessions";
import BetHistoryComponent from "./sections/betHistory";
import { updateLocationHash, getHashValue } from "utils/common";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import Paths from "constants/path.constants";

/** Player View Component */
const PlayerViewComponent = ({ getPlayerGeneralInfo, userName }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	/** Load player general info*/
	useEffect(() => {
		if (routeParams.id) getPlayerGeneralInfo(routeParams.id);
	}, []);

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.players"), path: Paths.DASHBOARD_ONLINE_PLAYERS }, { title: userName || " " }]} />
				<Tabs
					animated={false}
					defaultActiveKey={getHashValue("tab")}
					onChange={(key) => updateLocationHash("tab=" + key)}
					items={[
						{
							key: "1",
							label: t("pages.dashboard.players.tabs.general"),
							children: <GeneralInfoComponent />
						},
						hasPermission({ resource: PERMISSION_RESOURCE.PLAYER_SESSIONS_HISTORY, action: PERMISSION_ACTION.VIEW })
							? {
									key: "2",
									label: t("pages.dashboard.players.tabs.sessions_history"),
									children: <SessionsComponent />
								}
							: null,
						hasPermission({ resource: PERMISSION_RESOURCE.PLAYER_BET_HISTORY, action: PERMISSION_ACTION.VIEW })
							? {
									key: "3",
									label: t("pages.dashboard.players.tabs.bet_history"),
									children: <BetHistoryComponent />
								}
							: null
					]}
				/>
			</div>
		</Fragment>
	);
};

/** PlayerViewComponent propTypes
 * PropTypes
 */
PlayerViewComponent.propTypes = {
	/** Redux state, represents the userName of current viewing player  */
	userName: PropTypes.string,
	/** Redux action to get player General info */
	getPlayerGeneralInfo: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getPlayerGeneralInfo: (id) => {
		dispatch(getPlayerGeneralInfo(id));
	}
});

const mapStateToProps = (state) => {
	return {
		userName: state.players.viewingPlayer.generalInfo.personalInfo.userName
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerViewComponent);
