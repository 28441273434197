import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { message } from "antd";
import { RESPONSE_STATE } from "constants/response.constants";

import { BETSHOP_WALLET_BEFORE, BETSHOP_WALLET_FINISH, BETSHOP_WALLET_SAVE_BEFORE, BETSHOP_WALLET_SAVE_FINISH, BETSHOP_SET_WALLET, BETSHOP_UPDATE_WALLET } from "store/actionTypes";

const setBetshopWalletBefore = () => ({
	type: BETSHOP_WALLET_BEFORE
});

const setBetshopWalletFinished = () => ({
	type: BETSHOP_WALLET_FINISH
});

const setBetshopSaveWalletBefore = () => ({
	type: BETSHOP_WALLET_SAVE_BEFORE
});

const setBetshopSaveWalletFinished = () => ({
	type: BETSHOP_WALLET_SAVE_FINISH
});

const setBetshopWallet = (wallet) => ({
	type: BETSHOP_SET_WALLET,
	payload: { wallet }
});

const updateBetshopWallet = (id, balance) => ({
	type: BETSHOP_UPDATE_WALLET,
	payload: { id, balance }
});

export const getBetshopWallet = (id) => {
	return (dispatch) => {
		dispatch(setBetshopWalletBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_BETSHOP_WALLET}`,
			method: Methods.GET,
			params: {
				id
			}
		})
			.then(({ data: { value: wallet } }) => {
				dispatch(setBetshopWallet(wallet));
				dispatch(setBetshopWalletFinished());
			})
			.catch(() => {
				dispatch(setBetshopWalletFinished());
			});
	};
};

export const saveBetshopWallet = (wallet) => {
	return (dispatch) => {
		dispatch(setBetshopSaveWalletBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_BETSHOP_WALLET}`,
			method: Methods.POST,
			data: {
				...wallet
			}
		})
			.then(({ data: { message: msg, status, value: data } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(updateBetshopWallet(wallet.id, data.balance));
				}
				dispatch(setBetshopSaveWalletFinished());
			})
			.catch((ex) => {
				dispatch(setBetshopSaveWalletFinished());
			});
	};
};
