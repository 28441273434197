import PropTypes from "prop-types";

export default PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	description: PropTypes.string,
	created: PropTypes.string,
	lastUpdate: PropTypes.string,
	lastUpdateBy: PropTypes.string,
	users: PropTypes.arrayOf(PropTypes.string)
});
