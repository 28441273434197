import PropTypes from "prop-types";

import { BONUS_WIN_TYPE, BONUS_TYPE } from "constants/bonus.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	projectId: PropTypes.string,
	projectName: PropTypes.string,
	bonusType: PropTypes.oneOf(Object.values(BONUS_TYPE)),
	name: PropTypes.string,
	gameType: PropTypes.number,
	activationPeriodStart: PropTypes.string,
	activationPeriodEnd: PropTypes.string,
	activePeriod: PropTypes.number,
	currency: PropTypes.string,
	betSlipType: PropTypes.number,
	totalOdds: PropTypes.number,
	minOdds: PropTypes.number,
	minMultiSelectionsCount: PropTypes.number,
	winType: PropTypes.oneOf(Object.values(BONUS_WIN_TYPE))
});
