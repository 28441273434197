import { message } from "antd";

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { SET_PERMISSION_REQUESTS } from "store/actionTypes";

import { setPermissionRequestsActionBefore, setPermissionRequestsActionFinished, setPermissionRequestsSaveActionBefore, setPermissionRequestsSaveActionFinished } from "./requests.action";
import { RESPONSE_STATE } from "constants/response.constants";

const setPermissionRequests = (requests) => ({
	type: SET_PERMISSION_REQUESTS,
	payload: { requests }
});

export const getPermissionRequests = (objectId, actionTypes, objectType, callback) => {
	return (dispatch) => {
		dispatch(setPermissionRequestsActionBefore());
		const actionTypeFilterExists = Array.isArray(actionTypes) && actionTypes.length > 0;
		const collectedActionTypes = actionTypeFilterExists ? "actionTypes=" + actionTypes.join("&actionTypes=") : null;
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PERMISSION_REQUESTS}?${actionTypeFilterExists ? collectedActionTypes : ""}`,
			method: Methods.GET,
			params: {
				objectId,
				objectType
			}
		})
			.then(({ data: { value: requests } }) => {
				dispatch(setPermissionRequests(requests));
				if (typeof callback === "function") {
					callback(requests);
				}
			})
			.finally((ex) => {
				dispatch(setPermissionRequestsActionFinished());
			});
	};
};

export const approvePermissionRequest = (id) => {
	return (dispatch) => {
		dispatch(setPermissionRequestsSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APPROVE_PERMISSION_REQUEST}`,
			method: Methods.POST,
			data: {
				id
			}
		})
			.then(({ data: { value, status, message: msg } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setPermissionRequests(value));
				}
			})
			.finally((ex) => {
				dispatch(setPermissionRequestsSaveActionFinished());
			});
	};
};

export const rejectPermissionRequest = (id, note) => {
	return (dispatch) => {
		dispatch(setPermissionRequestsSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.REJECT_PERMISSION_REQUEST}`,
			method: Methods.POST,
			data: {
				id,
				note
			}
		})
			.then(({ data: { value, status, message: msg } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setPermissionRequests(value));
				}
			})
			.finally((ex) => {
				dispatch(setPermissionRequestsSaveActionFinished());
			});
	};
};
