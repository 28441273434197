export const RISK_MANAGEMENT_ACTION_TYPES = {
	NONE: 1,
	SEND_EMAIL: 2
}

export const RISK_MANAGEMENT_ACTION_TYPE_TRANSLATIONS = {
	[RISK_MANAGEMENT_ACTION_TYPES.NONE]: "pages.dashboard.risk_management.big_multipliers_configurations_configuration_actions_actionType_none",
	[RISK_MANAGEMENT_ACTION_TYPES.SEND_EMAIL]: "pages.dashboard.risk_management.big_multipliers_configurations_configuration_actions_actionType_sendEMail"
}

export const RISK_CASE_STATUSES = {
	OPEN: 1,
	IN_PROGRESS: 2,
	COMPLETED: 4
}

export const RISK_CASE_STATUS_TRANSLATIONS = {
	[RISK_CASE_STATUSES.OPEN]: "pages.dashboard.risk_management.bet_anomalies_risk_case_status_open",
	[RISK_CASE_STATUSES.IN_PROGRESS]: "pages.dashboard.risk_management.bet_anomalies_risk_case_status_inProgress",
	[RISK_CASE_STATUSES.COMPLETED]: "pages.dashboard.risk_management.bet_anomalies_risk_case_status_completed"
}

export const RISK_CASE_STATUS_COLOR = {
	[RISK_CASE_STATUSES.IN_PROGRESS]: "blue",
	[RISK_CASE_STATUSES.OPEN]: "red",
	[RISK_CASE_STATUSES.COMPLETED]: "green"
};

export const RISK_MANAGEMENT_BET_ANOMALIES_TIME_PERIODS = {
	NONE: 0,
	LAST_HOUR: 1,
	LAST_3_HOUR: 3,
	LAST_24_HOUR: 24
}

export const RISK_MANAGEMENT_BET_ANOMALIES_TIME_PERIODS_TRANSLATIONS = {
	[RISK_MANAGEMENT_BET_ANOMALIES_TIME_PERIODS.NONE]: "pages.dashboard.risk_management.bet_anomalies_configurations_configuration_detail_timePeriod_none",
	[RISK_MANAGEMENT_BET_ANOMALIES_TIME_PERIODS.LAST_HOUR]: "pages.dashboard.risk_management.bet_anomalies_configurations_configuration_detail_timePeriod_lastHour",
	[RISK_MANAGEMENT_BET_ANOMALIES_TIME_PERIODS.LAST_3_HOUR]: "pages.dashboard.risk_management.bet_anomalies_configurations_configuration_detail_timePeriod_last3Hour",
	[RISK_MANAGEMENT_BET_ANOMALIES_TIME_PERIODS.LAST_24_HOUR]: "pages.dashboard.risk_management.bet_anomalies_configurations_configuration_detail_timePeriod_last24Hour"
}

export const RISK_MANAGEMENT_AVERAGE_UPDATE_FREQUENCY = {
	ONE_WEEK: 1,
	ONE_MONTH: 2,
	THREE_MONTH: 4
}

export const RISK_MANAGEMENT_AVERAGE_UPDATE_FREQUENCY_TRANSLATIONS = {
	[RISK_MANAGEMENT_AVERAGE_UPDATE_FREQUENCY.ONE_WEEK]: "pages.dashboard.risk_management.player_suspicious_activities_configurations_averageUpdateFrequency_oneWeek",
	[RISK_MANAGEMENT_AVERAGE_UPDATE_FREQUENCY.ONE_MONTH]: "pages.dashboard.risk_management.player_suspicious_activities_configurations_averageUpdateFrequency_oneMonth",
	[RISK_MANAGEMENT_AVERAGE_UPDATE_FREQUENCY.THREE_MONTH]: "pages.dashboard.risk_management.player_suspicious_activities_configurations_averageUpdateFrequency_threeMonth"
}