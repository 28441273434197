import { useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Form, Row, Col, Select, DatePicker, Radio } from "antd";
import locale from "antd/es/date-picker/locale/en_US";
import { setBetshopSessionsFilters, getBetshopSessions } from "store/actions/dashboard/retail/betshops/sessions.action";
import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import useAutosuggestion from "hooks/useAutosuggestion";
import { monthAgo } from "utils/dateTime";
import { FILTER_SETTLEMENT_PRINTED } from "constants/filter.constants";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
const { Item: FormItem } = Form;

/** Betshop Sessions Page Filters Component */
const Filters = ({ setBetshopSessionsFilters, getBetshopSessions, filters, globalPartnerId }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const cashierNames = useAutosuggestion(AUTOSUGGESTION_TYPE.CASHIER_NAMES, [globalPartnerId], { disableAction: () => !globalPartnerId });

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [{ name: "date", keepTime: true, roundTime: false }]
		};
	}, []);

	const filtersList = [
		{ name: "userNameOrId", title: t("pages.dashboard.session.cashier") },
		{
			name: "settlementPrinted",
			title: t("pages.dashboard.session.settlements"),
			values: [
				{ title: t("common.all"), value: FILTER_SETTLEMENT_PRINTED.ALL },
				{ title: t("pages.dashboard.session.printed"), value: FILTER_SETTLEMENT_PRINTED.PRINTED },
				{ title: t("pages.dashboard.session.not_printed"), value: FILTER_SETTLEMENT_PRINTED.NOT_PRINTED }
			]
		}
	];

	return (
		<FiltersWrapper filtersName="betshop_sessions" loadFn={() => getBetshopSessions(routeParams.id)} setFiltersFn={setBetshopSessionsFilters} filters={filters} formFieldsConfigs={formFieldsConfigs} filtersList={filtersList}>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.session.username_or_id")} name="userNameOrId">
							<AutoComplete placeholder={`${t("common.start_typing")} ${t("pages.dashboard.session.username_or_id")}`} items={cashierNames.map((k) => k.value)} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.session.settlements")} name="settlementPrinted">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_SETTLEMENT_PRINTED.ALL}>
									<span title={t("common.all")}>{t("common.all")}</span>
								</Select.Option>
								<Select.Option value={FILTER_SETTLEMENT_PRINTED.PRINTED}>
									<span>{t("pages.dashboard.session.printed")}</span>
								</Select.Option>
								<Select.Option value={FILTER_SETTLEMENT_PRINTED.NOT_PRINTED}>
									<span>{t("pages.dashboard.session.not_printed")}</span>
								</Select.Option>
							</Select>
						</FormItem>
					</Col>

					<Col xs={24} sm={24} lg={12} xl={8}>
						<FormItem label={t("pages.dashboard.session.date")} name="date">
							<DatePicker.RangePicker
								placeholder={[t("common.from"), t("common.to")]}
								format={`${DATE_FORMAT} ${TIME_FORMAT}`}
								showTime={{ format: TIME_FORMAT }}
								disabledDate={(d) => !d || d.isBefore(monthAgo(3)) || d.isAfter(moment().endOf("day"))}
								allowClear={false}
								locale={{
									...locale,
									lang: {
										...locale.lang,
										ok: t("common.apply")
									}
								}}
							/>
						</FormItem>
					</Col>

					<Col xs={24} sm={18} lg={18} xl={12}>
						<div className="table-filters-quick-filters">
							<FormItem label={t("common.quick_filters")} name="quickFilters" className="table-filters-quick-filters-buttons">
								<Radio.Group>
									<Radio.Button value="last_24_h" style={{ marginBottom: "8px" }}>
										{t("common.last_24_h")}
									</Radio.Button>
									<Radio.Button value="last_week" style={{ marginBottom: "8px" }}>
										{t("common.this_week")}
									</Radio.Button>
									<Radio.Button value="last_month" style={{ marginBottom: "8px" }}>
										{t("common.this_month")}
									</Radio.Button>
									<Radio.Button value="last_3_month" style={{ marginBottom: "8px" }}>
										{t("common.last_3_months")}
									</Radio.Button>
								</Radio.Group>
							</FormItem>
						</div>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get betshop sessions */
	getBetshopSessions: PropTypes.func,
	/** Redux action to set betshop sessions filters */
	setBetshopSessionsFilters: PropTypes.func,
	/** Redux state property, betshop sessions filters */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getBetshopSessions: (id) => {
		dispatch(getBetshopSessions(id));
	},
	setBetshopSessionsFilters: (filters) => {
		dispatch(setBetshopSessionsFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.betshops.viewingBetshop.sessions.filters,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
