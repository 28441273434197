import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { SET_USER_PARTNER_ACCESS } from "store/actionTypes";

import { setUsersActionBefore, setUsersActionFinished, setUserSaveActionBefore, setUserSaveActionFinished } from "./users.action";

const setUserPartnerAccess = (partnerAccess) => ({
	type: SET_USER_PARTNER_ACCESS,
	payload: { partnerAccess }
});
export const getUserPartnerAccess = (id) => {
	return (dispatch) => {
		dispatch(setUsersActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_GET_PARTNER_ACCESS}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: partnerAccess } }) => {
				dispatch(setUserPartnerAccess(partnerAccess));
				dispatch(setUsersActionFinished());
			})
			.catch((ex) => {
				dispatch(setUsersActionFinished());
			});
	};
};

export const addUserPartnerAccess = (id, partnerId, note) => {
	return (dispatch) => {
		dispatch(setUserSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_ADD_PARTNER_ACCESS}`,
			method: Methods.POST,
			data: { id, partnerId, note }
		})
			.then(({ data: { value: partnerAccess } }) => {
				dispatch(setUserPartnerAccess(partnerAccess));
				dispatch(setUserSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setUserSaveActionFinished());
			});
	};
};

export const deleteUserPartnerAccess = (id, partnerId, note) => {
	return (dispatch) => {
		dispatch(setUserSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_DELETE_PARTNER_ACCESS}`,
			method: Methods.DELETE,
			data: { id, partnerId, note }
		})
			.then(({ data: { value: partnerAccess } }) => {
				dispatch(setUserPartnerAccess(partnerAccess));
				dispatch(setUserSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setUserSaveActionFinished());
			});
	};
};
