import { useState, useEffect, useMemo, useImperativeHandle, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Collapse, Form, Button } from "antd";

import FilterTags from "./filterTags";

import useUpdateEffect from "hooks/useUpdateEffect";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { isFormChanged } from "utils/form";
import { yesterday, weekago, monthAgo, getNow } from "utils/dateTime";
import localStorageUtils from "utils/localStorage";

const getFromStorageWithDefaults = (name, defaultvalue) => {
	return localStorageUtils.get(name) || defaultvalue;
};

const getNecessaryFilter = (filtersName) => {
	const filtersFromLocalStorage = getFromStorageWithDefaults("filters", {});
	const value = filtersFromLocalStorage[filtersName];
	const necessaryFilter = value || {};
	return necessaryFilter;
};

const momentifyOrDefault = (dateValueForMomentify, defaultvalue, ...argsForMoment) => {
	if (!dateValueForMomentify) return defaultvalue;

	return moment(dateValueForMomentify, ...argsForMoment);
};

const setMomentDate = (momentDate, hour = 0, minute = 0, second = 0) => {
	return moment(momentDate).set("hour", hour).set("minute", minute).set("second", second);
};

/** Table Filters Component */
const Filters = ({ filtersName, loadFn, setFiltersFn, filters, formFieldsConfigs, children, globalPartnerId, activeReset, filtersList, defaultOpened, dependencies, formInstanceRef }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { setFieldsValue, getFieldsValue } = formInstance;

	const [isFiltersTouched, setIsFiltersTouched] = useState(activeReset ? true : false);
	const [canApply, setCanApply] = useState(false);
	const [active, setActive] = useState(false);
	const [initial, setInitial] = useState(filters);

	const datePickersFilterKeys = useMemo(() => {
		return (
			formFieldsConfigs?.datePicker.reduce((acc, field) => {
				if (field.name === "date") {
					acc[field.name] = ["from", "to"];
				} else {
					acc[field.name] = [`${field.name}From`, `${field.name}To`];
				}

				return acc;
			}, {}) || {}
		);
	}, [formFieldsConfigs]);

	const updateFormValues = (filters, ...momentArgs) => {
		const formDatePickerFields = Object.keys(datePickersFilterKeys).reduce((acc, key) => {
			acc[key] = datePickersFilterKeys[key].map((filterKey) => momentifyOrDefault(filters[filterKey], ...momentArgs));

			return acc;
		}, {});

		return {
			...filters,
			...formDatePickerFields
		};
	};

	const formInitialValues = useMemo(() => updateFormValues(initial, "", DATE_FORMAT), [initial, momentifyOrDefault]);

	/** Reset Filters
	 * @function
	 * @memberOf Filters
	 */
	const resetFilters = () => setFiltersFn(initial);

	/** Funtion, handle form values, and make them according to filters
	 * @function
	 * @memberOf Filters
	 */
	const getDataFromFormValues = () => {
		const data = { ...getFieldsValue() };

		formFieldsConfigs?.datePicker.forEach((field) => {
			const [fieldNameFrom, fieldNameTo] = datePickersFilterKeys[field.name];

			if (data[field.name]) {
				const fromValue = data[field.name][0] ? data[field.name][0].clone() : "";
				const toValue = data[field.name][1] ? data[field.name][1].clone() : "";
				data[fieldNameFrom] = "";
				data[fieldNameTo] = "";

				const setValue = (key, value, momentArgs = []) => {
					const retVal = field.keepTime ? moment(value) : setMomentDate(value, ...momentArgs);
					data[key] = retVal.toDate();
				};

				if (fromValue) {
					setValue(fieldNameFrom, fromValue);
				}
				if (toValue) {
					setValue(fieldNameTo, toValue, [23, 59, 59]);
				}

				delete data[field.name];
			}
		});

		return data;
	};

	/** Funtion, fires on submit button click
	 * @function
	 * @memberOf Filters
	 */
	const doFilter = () => {
		setFiltersFn(getDataFromFormValues());
		setActive(false);
		setCanApply(false);
		setTimeout(() => loadFn(true), 0);
	};

	/** Funtion, fires on reset button click
	 * @function
	 * @param {boolean} loadData - if should load new data after reset
	 * @memberOf Filters
	 */
	const doReset = (loadData) => {
		resetFilters();
		setTimeout(() => {
			setFieldsValue(formInitialValues);
			setIsFiltersTouched(isFiltersChanged());
			if (loadData) {
				setCanApply(false);
				loadFn();
			}
		}, 0);
	};

	/** Check is filters changed
	 * @function
	 * @returns {boolean}
	 * @memberOf Filters
	 */
	const isFiltersChanged = () => {
		const updated = { ...getDataFromFormValues() };
		return isFormChanged(initial, updated);
	};

	/** Function , fires on form value change
	 * @function
	 * @returns {object} changed - name-value mapping for changed field
	 * @memberOf Filters
	 */
	const handleFormValuesChange = (changed) => {
		if (changed.quickFilters) {
			handleQuickFiltersChange(changed.quickFilters);
		}
		setIsFiltersTouched(isFiltersChanged());
		setCanApply(true);
		Array.prototype.forEach.call(dependencies ? dependencies : [], (d) => {
			if (changed[d.field]) {
				if (typeof d.checkingFn === "function" && !d.checkingFn(changed)) {
					return;
				}
				setFieldsValue({ [d.resetField]: d.resetValue });
			}
		});
	};

	/** Function , fires on quick filter click
	 * @function
	 * @returns {string} value - quick filter value
	 * @memberOf Filters
	 */
	const handleQuickFiltersChange = (value) => {
		const FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
		const TIME = moment(getNow(true), TIME_FORMAT);
		const quickFilterDateConfig = formFieldsConfigs?.datePicker.find((filter) => Object.hasOwn(filter, "roundTime"));
		let from_d, to_d;
		to_d = moment(getNow(true), FORMAT);

		switch (value) {
			case "last_24_h":
				from_d = yesterday(quickFilterDateConfig?.roundTime);
				break;
			case "last_week":
				from_d = weekago(1, quickFilterDateConfig?.roundTime);
				break;
			case "last_month":
				from_d = monthAgo(1, quickFilterDateConfig?.roundTime);
				break;
			case "last_3_month":
				from_d = monthAgo(3, quickFilterDateConfig?.roundTime);
				break;
		}

		if (from_d) from_d.set({ hour: TIME.get("hour"), minute: TIME.get("minute") });
		if (to_d) to_d.set({ hour: TIME.get("hour"), minute: TIME.get("minute") });

		setFieldsValue({ date: [from_d, to_d] });
	};

	/** Function , to remove single filter
	 * @function
	 * @returns {string} name - filter name
	 * @memberOf Filters
	 */
	const removeFilter = (name) => {
		const fltrs = { ...filters };

		if (datePickersFilterKeys && datePickersFilterKeys[name]) {
			datePickersFilterKeys[name].forEach((key) => {
				fltrs[key] = initial?.[key] ?? "";
			});
		} else {
			fltrs[name] = initial[name];
		}

		Array.prototype.forEach.call(dependencies ? dependencies : [], (d) => {
			if (name === d.field) {
				fltrs[d.resetField] = d.resetValue;
			}
		});

		setFiltersFn(fltrs);
		setCanApply(false);
		setTimeout(loadFn, 0);
	};

	useImperativeHandle(formInstanceRef, () => formInstance);

	useEffect(() => {
		const initialFilters = { ...initial };

		if (initial.quickFilters) {
			const quickFilterDateConfig = formFieldsConfigs.datePicker.find((filter) => Object.hasOwn(filter, "roundTime"));
			const [from, to] = datePickersFilterKeys[quickFilterDateConfig?.name] || datePickersFilterKeys["date"];
			const formatDate = (cb) => moment(cb(quickFilterDateConfig?.roundTime), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate();

			initialFilters[from] = formatDate(yesterday);
			initialFilters[to] = formatDate(getNow);
			setInitial(initialFilters);
		}

		setFiltersFn({
			...initialFilters,
			...getNecessaryFilter(filtersName)
		});
	}, []);

	useEffect(() => {
		const cached = getFromStorageWithDefaults("filters", {});
		const formFieldsKeys = filtersList.map((f) => f.name);
		formFieldsKeys.forEach((key) => {
			if (datePickersFilterKeys && datePickersFilterKeys[key]) {
				formFieldsKeys.push(...datePickersFilterKeys[key]);
			}
		});
		const newFilters = {};
		Object.keys(filters).forEach((f) => {
			if (formFieldsKeys.includes(f)) {
				newFilters[f] = filters[f];
			}
		});

		cached[filtersName] = newFilters;
		localStorageUtils.set("filters", cached);
	}, [filters]);

	/** Reset filters when global partner changes */
	useUpdateEffect(() => {
		doReset(false);
	}, [globalPartnerId]);

	/** Update field values when filters updated from outside */
	useEffect(() => {
		if (active) {
			setFieldsValue(updateFormValues(filters, ""));

			setTimeout(() => {
				setIsFiltersTouched(isFiltersChanged());
			}, 0);
		}
	}, [filters, active]);

	/** Activate reset button on activeReset property change */
	useEffect(() => {
		activeReset && setIsFiltersTouched(true);
	}, [activeReset]);

	/** Reset to initial on component unmount */
	useEffect(() => () => resetFilters(), []);

	useEffect(() => {
		if (defaultOpened) {
			setActive(true);
		}
	}, [defaultOpened]);

	return (
		<Fragment>
			<div className="table-filters table-filters-head">
				<div className="table-filters-header">
					<Button className={active ? "table-filters-button-active" : ""} type="secondary" onClick={() => setActive(!active)}>
						<i className="icon-filter" />
						<span className="table-filters-header-title">{t("common.filter")}</span>
					</Button>
					<FilterTags filters={filters} filtersList={filtersList} initialFilters={initial} datePickersFilterKeys={datePickersFilterKeys} clearAll={() => doReset(true)} removeFilter={removeFilter} />
				</div>
			</div>
			<div className="table-filters table-filters-content">
				<Collapse bordered={false} activeKey={active ? ["panel"] : []}>
					<Collapse.Panel forceRender={false} showArrow={false} key="panel">
						<Form colon={false} form={formInstance} requiredMark={false} layout="vertical" initialValues={formInitialValues} onValuesChange={handleFormValuesChange}>
							{children}
							<div className="button-container">
								<Button htmlType="button" className="button" onClick={() => doReset(true)} style={{ marginRight: "16px" }} disabled={!isFiltersTouched}>
									<span>{t("common.reset")}</span>
								</Button>
								<Button type="primary" htmlType="button" className="button" onClick={doFilter} disabled={!canApply}>
									<span>{t("common.apply")}</span>
								</Button>
							</div>
						</Form>
					</Collapse.Panel>
				</Collapse>
			</div>
		</Fragment>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** The name of filters, will be used for caching */
	filtersName: PropTypes.string,
	/** Function to call to laod table data */
	loadFn: PropTypes.func.isRequired,
	/** Function to call to set filters */
	setFiltersFn: PropTypes.func.isRequired,
	/** Currenct filters */
	filters: PropTypes.object.isRequired,
	/** Form fields configs */
	formFieldsConfigs: PropTypes.shape({
		datePicker: PropTypes.array
	}),
	/** The JSX content of filters */
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** If true, reset button will be active by default */
	activeReset: PropTypes.bool,
	/** Filters list, which will be kept in localStorage */
	filtersList: PropTypes.arrayOf(
		PropTypes.shape({
			/** Filter name */
			name: PropTypes.string,
			/** Filter title */
			title: PropTypes.string
		})
	),
	/** If true, filters will be opened by default */
	defaultOpened: PropTypes.bool,
	/** The list of rules to update fields depond on each other */
	dependencies: PropTypes.array,
	/** React Property, in which we give formInstance methods for the Parent component */
	formInstanceRef: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, null)(Filters);
