import { BETSLIPS_BEFORE, BETSLIPS_FINISH, BETSLIPS_SAVE_BEFORE, BETSLIPS_SAVE_FINISH } from "../../../actionTypes";

export const setBetslipsActionBefore = () => ({
	type: BETSLIPS_BEFORE
});

export const setBetslipsActionFinished = () => ({
	type: BETSLIPS_FINISH
});

export const setBetslipsSaveActionBefore = () => ({
	type: BETSLIPS_SAVE_BEFORE
});

export const setBetslipsSaveActionFinished = () => ({
	type: BETSLIPS_SAVE_FINISH
});
