import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Button, message } from "antd";
import NumericInput from "components/common/numericInput";
import Table from "components/common/table";
import { PENALTY_PROBABILITIES } from "constants/game.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { getPenaltyGoalMissProbabilities, savePenaltyGoalMissProbabilities } from "store/actions/dashboard/virtuals/games/probabilities.action";
import { isFormChanged } from "utils/form";
import { arrayToChunck, numberTransform } from "utils/common";
import { hasPermission } from "utils/permissions";
import penaltyGoalMissProbabilitiesType from "types/game/penaltyGoalMissProbabilities.type";
const { Item: FormItem } = Form;

/** Game Edit Page Probabilities Tab SceneProbabilities subTab Component */
const SceneProbabilitiesComponent = ({ isSaving, isLoading, penaltyGoalMissProbabilities, getPenaltyGoalMissProbabilities, savePenaltyGoalMissProbabilities, onTabChange }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue, getFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [formCurrentValues, setFormCurrentValues] = useState({});

	const goalScenes = penaltyGoalMissProbabilities?.goalScenes ?? [];
	const noGoalScenes = penaltyGoalMissProbabilities?.noGoalScenes ?? [];

	const convertProbabilityListToObject = useCallback((probabilityList, makeString = false) => {
		return probabilityList.reduce((acc, obj) => {
			acc["type_" + obj.type] = obj.percent;
			if (makeString) {
				acc["type_" + obj.type] = acc["type_" + obj.type].toString();
			}
			return acc;
		}, {});
	}, []);

	const convertProbabilityListToTableData = useCallback((probabilityList) => {
		return arrayToChunck(probabilityList, 3).map((chunck, key) => {
			const convertedObj = chunck.reduce((acc, elem, i) => {
				acc["type_" + i] = elem.percent.toString();
				return acc;
			}, {});
			convertedObj["key"] = key;
			return convertedObj;
		});
	}, []);

	const convertTableDataToProbabilityList = useCallback((tableData) => {
		return tableData.reduce((acc, data, i) => {
			Object.entries(data).forEach(([key, value]) => {
				if (key === "key") {
					return acc;
				}
				acc.push({
					type: 1 << acc.length,
					percent: Number(value)
				});
			});
			return acc;
		}, []);
	}, []);

	/** Load game scene probabilities */
	useEffect(() => {
		if (routeParams.id) {
			getPenaltyGoalMissProbabilities(routeParams.id);
		}
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		if (goalScenes.length > 0 && noGoalScenes.length > 0) {
			const goalScenesObj = convertProbabilityListToTableData(goalScenes);
			const noGoalScenesObj = noGoalScenes.reduce((acc, s) => {
				acc["type_" + s.type] = s.percent.toString();
				return acc;
			}, {});

			setFieldsValue({
				goalScenes: goalScenesObj,
				noGoalScenes: noGoalScenesObj
			});
			setFormCurrentValues({
				goalScenes: goalScenesObj,
				noGoalScenes: noGoalScenesObj
			});
		}
	}, [goalScenes, noGoalScenes]);

	/** Fires when form submitted
	 * @function
	 * @memberOf SceneProbabilitiesComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				const goalScenes = data.goalScenes;
				const noGoalScenes = data.noGoalScenes;
				if (isTotalsValid()) {
					savePenaltyGoalMissProbabilities({
						id: routeParams.id,
						goalScenes: convertTableDataToProbabilityList(goalScenes),
						noGoalScenes: Object.keys(noGoalScenes).map((g) => ({
							type: Number(g.replace("type_", "")),
							percent: Number(noGoalScenes[g])
						}))
					});
					setIsFormTouched(false);
				} else {
					message.error(t("pages.dashboard.games.probabilities.total_must_be"));
				}
			})
			.catch((ex) => {
				console.log(ex);
			});
	};

	/** Check is form changed
	 * @function
	 * @param {string} table - table to count (goalScenes, noGoalScenes)
	 * @returns {number} total sum of table fields values
	 * @memberOf SceneProbabilitiesComponent
	 */
	const countTotal = (table) => {
		if (!isFormTouched || !formCurrentValues[table]) return 100;
		return Object.values(table === "goalScenes" ? convertProbabilityListToObject(convertTableDataToProbabilityList(formCurrentValues[table])) : formCurrentValues[table]).reduce((a, b) => {
			return !isNaN(Number(b)) ? parseFloat((Number(a) + Number(b)).toFixed(10), 8) : Number(a);
		}, 0);
	};

	const isTotalsValid = () => {
		return countTotal("goalScenes") === 100 && countTotal("noGoalScenes") === 100;
	};

	/** Check is form changed
	 * @function
	 * @param {object} formValues - form current values
	 * @returns {boolean}
	 * @memberOf SceneProbabilitiesComponent
	 */
	const formChanged = (formValues) => {
		if (Object.keys(formValues).length === 0) return false;

		const initialValues = { ...penaltyGoalMissProbabilities };

		const initialGoalScenes = {};
		const initialNoGoalScenes = {};
		const formGoalScenes = {};
		const formNoGoalScenes = {};

		initialValues.goalScenes.forEach((scene) => {
			initialGoalScenes["type_" + scene.type] = scene.percent;
		});

		initialValues.goalScenes = goalScenes.map((o) => ({ ...o }));

		initialValues.noGoalScenes.forEach((scene) => {
			initialNoGoalScenes["type_" + scene.type] = scene.percent;
		});

		convertTableDataToProbabilityList(formValues.goalScenes).forEach((obj) => {
			formGoalScenes["type_" + obj.type] = obj.percent;
		});

		Object.keys(formValues.noGoalScenes).forEach((type) => {
			formNoGoalScenes[type] = Number(formValues.noGoalScenes[type]);
		});

		return isFormChanged({ goalScenes: formGoalScenes, noGoalScenes: formNoGoalScenes }, { goalScenes: initialGoalScenes, noGoalScenes: initialNoGoalScenes });
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	/** Make table columns
	 * @function
	 * @param {string} table - table  (goalScenes, noGoalScenes)
	 * @returns {array}
	 * @memberOf SceneProbabilitiesComponent
	 */
	const makeColumns = (table) => {
		switch (table) {
			case "goalScenes":
				return Array.from({ length: 3 }, (_, i) => {
					const key = "type_" + i;
					return {
						title: i === 0 ? t("pages.dashboard.games.probabilities.g_s_probabilities") : null,
						dataIndex: key,
						width: "33%",
						sorter: false,
						render: (value, record, index) => (
							<FormItem
								className="inline-form-item-control"
								style={{ marginLeft: 0, maxWidth: "200px" }}
								name={[table, index, key]}
								rules={[
									{ required: true, whitespace: true, message: t("validation.field_required") },
									{ type: "number", max: 100, message: t("validation.must_be_less_than_other").replace("%X%", t("pages.dashboard.games.probabilities.probability")).replace("%Y%", "100"), transform: numberTransform },
									{ type: "number", min: 0, message: t("validation.must_be_more_than_other").replace("%X%", t("pages.dashboard.games.probabilities.probability")).replace("%Y%", "0"), transform: numberTransform }
								]}
							>
								<NumericInput decimalsCount={2} disabled={!hasPermission({ resource: PERMISSION_RESOURCE.GAME_PROBABILITIES, action: PERMISSION_ACTION.MODIFY })} />
							</FormItem>
						)
					};
				});
			case "noGoalScenes":
				return [
					{
						title: table === "goalScenes" ? t("pages.dashboard.games.probabilities.goal_scenes") : t("pages.dashboard.games.probabilities.no_goal_scenes"),
						dataIndex: "type",
						render: (value) => t(`pages.dashboard.games.probabilities.${Object.values(PENALTY_PROBABILITIES).filter((p) => p.value === value)[0].key}`),
						sorter: false,
						width: "50%"
					},
					{
						title: t("pages.dashboard.games.probabilities.probability"),
						dataIndex: "percent",
						width: "50%",
						sorter: false,
						render: (value, record) => (
							<FormItem
								className="inline-form-item-control"
								style={{ marginLeft: 0, maxWidth: "200px" }}
								name={[table, `type_${record.type}`]}
								rules={[
									{ required: true, whitespace: true, message: t("validation.field_required") },
									{ type: "number", max: 100, message: t("validation.must_be_less_than_other").replace("%X%", t("pages.dashboard.games.probabilities.probability")).replace("%Y%", "100"), transform: numberTransform },
									{ type: "number", min: 0, message: t("validation.must_be_more_than_other").replace("%X%", t("pages.dashboard.games.probabilities.probability")).replace("%Y%", "0"), transform: numberTransform }
								]}
							>
								<NumericInput disabled={!hasPermission({ resource: PERMISSION_RESOURCE.GAME_PROBABILITIES, action: PERMISSION_ACTION.MODIFY })} />
							</FormItem>
						)
					}
				];
			default:
				return [];
		}
	};

	return (
		<Form
			colon={false}
			form={formInstance}
			requiredMark={false}
			layout="vertical"
			onValuesChange={(changed, formValues) => {
				setFormCurrentValues(JSON.parse(JSON.stringify(formValues)));
				setIsFormTouched(formChanged({ ...formValues }));
			}}
		>
			<div className="dashboard-section-content">
				<div>
					<div className="dashboard-probabilities">
						<Row gutter={[56, 0]}>
							<Col xs={24} sm={24} lg={12} className="goal_probabilities">
								<Table
									loading={isLoading}
									columns={makeColumns("goalScenes")}
									data={convertProbabilityListToTableData(goalScenes)}
									total={goalScenes.length}
									isDisabled={() => false}
									striped={false}
									noPagination={true}
									uniqueKey="key"
								/>
							</Col>
							<Col xs={24} sm={24} lg={12}>
								<Table
									loading={isLoading}
									columns={makeColumns("noGoalScenes")}
									data={noGoalScenes.map((d) => ({ ...d, key: d.type }))}
									total={noGoalScenes.length}
									isDisabled={() => false}
									noPagination={true}
									uniqueKey="type"
								/>
							</Col>
						</Row>
						<div className="dashboard-probabilities-footer">
							<Row gutter={[56, 0]}>
								<Col xs={24} sm={24} lg={12}>
									<div className="table-footer">
										<div className="table-footer-tr">
											<div className="table-footer-td">
												<b>{t("common.total")}</b>
											</div>
											<div className="table-footer-td">
												<b className={"info-text" + (countTotal("goalScenes") !== 100 ? " error-text" : "")}>{countTotal("goalScenes")}</b>
											</div>
										</div>
									</div>
								</Col>
								<Col xs={24} sm={24} lg={12}>
									<div className="table-footer">
										<div className="table-footer-tr">
											<div className="table-footer-td">
												<b>{t("common.total")}</b>
											</div>
											<div className="table-footer-td">
												<b className={"info-text" + (countTotal("noGoalScenes") !== 100 ? " error-text" : "")}>{countTotal("noGoalScenes")}</b>
											</div>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</div>
				{hasPermission({ resource: PERMISSION_RESOURCE.GAME_PROBABILITIES, action: PERMISSION_ACTION.MODIFY }) && (
					<FormItem className="button-container">
						<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
							<span>{t("common.save")}</span>
						</Button>
					</FormItem>
				)}
			</div>
		</Form>
	);
};

/** SceneProbabilitiesComponent propTypes
 * PropTypes
 */
SceneProbabilitiesComponent.propTypes = {
	/** Redux state property, is true when scene Probabilities is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when scene Probabilities is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the football scene Probabilities of current editing game  */
	penaltyGoalMissProbabilities: penaltyGoalMissProbabilitiesType,
	/** Redux action to save game football scene Probabilities */
	savePenaltyGoalMissProbabilities: PropTypes.func,
	/** Redux action to get football scene Probabilities */
	getPenaltyGoalMissProbabilities: PropTypes.func,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getPenaltyGoalMissProbabilities: (id) => {
		dispatch(getPenaltyGoalMissProbabilities(id));
	},

	savePenaltyGoalMissProbabilities: (data) => {
		dispatch(savePenaltyGoalMissProbabilities(data));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.games.isSaving,
		isLoading: state.games.isLoading,
		penaltyGoalMissProbabilities: state.games.editingGame.probabilities.penaltyGoalMissProbabilities
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SceneProbabilitiesComponent);
