import { useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Spin, Descriptions, Input } from "antd";

import { getError } from "store/actions/dashboard/developer/errors/errors.action";

import { formatDateTime } from "utils/dateTime";

import Breadcrumbs from "components/common/breadcrumbs";

import Paths from "constants/path.constants";

import errorType from "types/log/error.type";

/** Single Error Page Component */
const ErrorComponent = ({ getError, error, isLoading }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	/** Load error */
	useEffect(() => {
		const errorId = routeParams.id;
		getError(errorId);
	}, []);

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.logs_errors"), path: Paths.DASHBOARD_DEVELOPER_ERRORS }, { title: t("pages.dashboard.logs.error_details") }]} />
				<Spin spinning={isLoading}>
					{error ? (
						<Descriptions title={""} colon={true} column={1} bordered>
							<Descriptions.Item label={t("common.id")}>{error.id}</Descriptions.Item>
							<Descriptions.Item label={t("pages.dashboard.logs.controller")}>{error.controller}</Descriptions.Item>
							<Descriptions.Item label={t("pages.dashboard.logs.action")}>{error.action}</Descriptions.Item>
							<Descriptions.Item label={t("pages.dashboard.logs.message")}>{error.message}</Descriptions.Item>
							<Descriptions.Item label={t("pages.dashboard.logs.time")}>{formatDateTime(error.errorTime)}</Descriptions.Item>
							<Descriptions.Item label={t("pages.dashboard.logs.stackTrace")}>
								<Input.TextArea className="dashboard-logs-exception" autoSize={true} disabled={true} value={error.stackTrace} />
							</Descriptions.Item>
						</Descriptions>
					) : null}
				</Spin>
			</div>
		</Fragment>
	);
};
/** ErrorComponent propTypes
 * PropTypes
 */
ErrorComponent.propTypes = {
	/** Redux action to get single error */
	getError: PropTypes.func,
	/** Redux state property, loaded error */
	error: errorType,
	/** Redux state property, is true when loading single error */
	isLoading: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	getError: (id) => {
		dispatch(getError(id));
	}
});

const mapStateToProps = (state) => {
	return {
		error: state.errors.error,
		isLoading: state.errors.isErrorLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorComponent);
