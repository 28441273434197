import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { setApikeySaveActionBefore, setApikeySaveActionFinished } from "./apikeys.action";

import { APIKEY_BLOCKED_COUNTRIES_BEFORE, APIKEY_BLOCKED_COUNTRIES_FINISH, SET_APIKEY_BLOCKED_COUNTRIES, SET_APIKEY_ADD_BLOCKED_COUNTRY, SET_APIKEY_DELETE_BLOCKED_COUNTRY } from "store/actionTypes";

const setApiKeyBlockedCountriesBefore = () => ({
	type: APIKEY_BLOCKED_COUNTRIES_BEFORE
});

const setApiKeyBlockedCountriesFinished = () => ({
	type: APIKEY_BLOCKED_COUNTRIES_FINISH
});

const setApikeyBlockedCountries = (countries) => ({
	type: SET_APIKEY_BLOCKED_COUNTRIES,
	payload: { countries }
});

export const setApiKeyAddBlockedCountry = (country) => ({
	type: SET_APIKEY_ADD_BLOCKED_COUNTRY,
	payload: { country }
});

export const setApiKeyDeleteBlockedCountry = (countryCode) => ({
	type: SET_APIKEY_DELETE_BLOCKED_COUNTRY,
	payload: { countryCode }
});

export const getApiKeyBlockedCountries = (id) => {
	return (dispatch) => {
		dispatch(setApiKeyBlockedCountriesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APIKEY_GET_BLOCKED_COUNTRIES}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: countries } }) => {
				dispatch(setApikeyBlockedCountries(countries));
				dispatch(setApiKeyBlockedCountriesFinished());
			})
			.catch((ex) => {
				dispatch(setApiKeyBlockedCountriesFinished());
			});
	};
};

export const addApiKeyBlockedCountry = (id, countryCode) => {
	return (dispatch) => {
		dispatch(setApikeySaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APIKEY_ADD_BLOCKED_COUNTRY}`,
			method: Methods.POST,
			data: { id, countryCode }
		})
			.then(({ data: { value: country, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					dispatch(setApiKeyAddBlockedCountry(country));
				}
				dispatch(setApikeySaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setApikeySaveActionFinished());
			});
	};
};

export const deleteApiKeyBlockedCountry = (id, countryCode) => {
	return (dispatch) => {
		dispatch(setApikeySaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APIKEY_DELETE_BLOCKED_COUNTRY}`,
			method: Methods.DELETE,
			data: { id, countryCode }
		})
			.then(({ data: { status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					dispatch(setApiKeyDeleteBlockedCountry(countryCode));
				}
				dispatch(setApikeySaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setApikeySaveActionFinished());
			});
	};
};
