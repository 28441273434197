import { useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Form, Col, Row, Modal, Input, Checkbox, Tooltip } from "antd";
const { Item: FormItem } = Form;

import SearchableSelect from "components/common/searchableSelect";

import { createUser, getUserAvailablePartners, getUsers } from "store/actions/dashboard/userManagement/users/users.action";
import Paths from "constants/path.constants";
import { EMAIL_REGEX } from "constants/regex.constants";
import { POPUP_SIZE } from "constants/common.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import { hasPermission } from "utils/permissions";

import partnerType from "types/partner/partner.type";
import { doesUserHaveRoleOf } from "utils/auth";
import { USER_ROLE } from "constants/user.constants";

/** User Creating Popup Component */
const UserAddComponent = ({ isSaving, createUser, getUsers, globalPartnerId, globalPartners, isAvailablePartnersLoading, availablePartners, getUserAvailablePartners, onClose }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const isAccessManager = doesUserHaveRoleOf(USER_ROLE.ACCESS_MANAGER);

	/** Load user available partners */
	useEffect(() => {
		!isAccessManager && getUserAvailablePartners();
	}, [isAccessManager]);

	/** Fires when form submitted
	 * @function
	 * @memberOf UserAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				const requestData = { ...data };
				requestData.partnerId = globalPartnerId;
				if (Array.isArray(requestData.partnerIds) && requestData.partnerIds.includes("*")) {
					requestData.partnerIds = Object.keys(availablePartners).filter((p) => p !== globalPartnerId);
				}
				createUser(requestData, ({ id }) => {
					if (hasPermission({ resource: PERMISSION_RESOURCE.USER, action: PERMISSION_ACTION.MODIFY })) {
						navigate(Paths.DASHBOARD_USERMANAGEMENT_USERS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, `${id}?PI=${globalPartnerId}`));
					} else {
						getUsers();
						onClose();
					}
				});
			})
			.catch(Function.prototype);
	};

	/** Detect if current partner is test partner
	 * @function
	 * @returns {boolean}
	 * @memberOf UserAddComponent
	 */
	const isCurrentPartnerTest = (globalPartners.find((p) => p.id === globalPartnerId))?.isTesting;

	/** Generate items list for partnerIds select options
	 * @function
	 * @returns {array}
	 * @memberOf UserAddComponent
	 */
	const generateItemListForPartnerIds = () => {
		const partnersList = [];

		if (availablePartners["*"]) {
			partnersList.push("*");
		}

		const filteredPartners = Object.keys(availablePartners).filter((p) => p !== globalPartnerId && p.trim() !== "*");
		partnersList.push(...filteredPartners);
		return partnersList;
	};

	return (
		<Modal open={true} title={t("pages.dashboard.users.create_user")} cancelText={t("common.cancel")} okText={t("common.create")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.MIDDLE} maskClosable={false} closable={false} okButtonProps={{ loading: isSaving }} centered>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					firstName: "",
					lastName: "",
					userName: "",
					email: "",
					partnerIds: [],
					isTesting: isCurrentPartnerTest
				}}
			>
				<Row gutter={[16, 0]}>
					{isAccessManager ? null : (
						<Fragment>
							<Col xs={24} sm={12}>
								<FormItem label={`${t("pages.dashboard.users.main_partner")} *`}>
									<Input value={availablePartners[globalPartnerId]} disabled={true} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12}>
								<FormItem
									label={
										<Fragment>
											<span>{t("pages.dashboard.users.partners")}</span>
											<Tooltip title={t("pages.dashboard.users.partners_info")} getPopupContainer={() => document.body}>
												<i className="icon-info table-filters-info" />
											</Tooltip>
										</Fragment>
									}
									name="partnerIds"
								>
									<SearchableSelect
										mode="multiple"
										items={generateItemListForPartnerIds()}
										valueProp={(item) => item}
										textProp={(item) => (item === "*" ? t("common.all") : availablePartners[item])}
										renderText={(item) => (item === "*" ? t("common.all") : availablePartners[item])}
										placeholder={t("pages.dashboard.users.select_partner_placeholder")}
										loading={isAvailablePartnersLoading}
										getPopupContainer={() => document.body}
										onChange={(value) => {
											if (value.at(-1) === "*") {
												setFieldsValue({ partnerIds: ["*"] });
												return;
											}
											if (value.length > 1 && value.includes("*")) {
												setFieldsValue({ partnerIds: value.filter((v) => v !== "*") });
												return;
											}
											setFieldsValue({ partnerIds: value });
										}}
									/>
								</FormItem>
							</Col>
						</Fragment>
					)}
					<Col xs={24} sm={12}>
						<FormItem
							label={`${t("pages.dashboard.users.first_name")} *`}
							name="firstName"
							rules={[
								{ required: true, whitespace: true, message: t("validation.field_required") },
								{ max: 30, message: t("validation.field_invalid") },
								{ min: 2, message: t("validation.field_invalid") }
							]}
						>
							<Input minLength={2} maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.users.first_name")}`} autoFocus={true} autoComplete="off" />
						</FormItem>
					</Col>
					<Col xs={24} sm={12}>
						<FormItem
							label={`${t("pages.dashboard.users.last_name")} *`}
							name="lastName"
							rules={[
								{ required: true, whitespace: true, message: t("validation.field_required") },
								{ max: 30, message: t("validation.field_invalid") },
								{ min: 2, message: t("validation.field_invalid") }
							]}
						>
							<Input minLength={2} maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.users.last_name")}`} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12}>
						<FormItem
							label={`${t("pages.dashboard.users.email")} *`}
							name="email"
							rules={[
								{ pattern: EMAIL_REGEX, message: t("validation.email_format") },
								{ max: 50, message: t("validation.field_invalid") },
								{ min: 6, message: t("validation.field_invalid") }
							]}
						>
							<Input minLength={6} maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.users.email")}`} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12}>
						<FormItem
							label={`${t("pages.dashboard.users.username")} *`}
							name="userName"
							rules={[
								{ required: true, whitespace: true, message: t("validation.field_required") },
								{ max: 30, message: t("validation.field_invalid") },
								{ min: 5, message: t("validation.field_invalid") }
							]}
						>
							<Input minLength={6} maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.users.username")}`} />
						</FormItem>
					</Col>
					<Col span={24}>
						<div className="inline-form-checkbox">
							<label>{t("pages.dashboard.users.is_test_user")}</label>
							<FormItem className="inline-form-item-control" name="isTesting" valuePropName="checked">
								<Checkbox disabled={isCurrentPartnerTest} />
							</FormItem>
						</div>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** UserAddComponent propTypes
 * PropTypes
 */
UserAddComponent.propTypes = {
	/** Redux state property, is true when creating user request is in process */
	isSaving: PropTypes.bool,
	/** Redux action to create user */
	createUser: PropTypes.func,
	/** Redux action to get users */
	getUsers: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, represents the array of global partners  */
	globalPartners: PropTypes.arrayOf(partnerType),
	/** Redux state property, is true when loading user available partners */
	isAvailablePartnersLoading: PropTypes.bool,
	/** Redux state property, represents available partners of user  */
	availablePartners: PropTypes.object,
	/** Redux action to load user available partners */
	getUserAvailablePartners: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	createUser: (user, onSuccess) => {
		dispatch(createUser(user, onSuccess));
	},
	getUserAvailablePartners: () => {
		dispatch(getUserAvailablePartners());
	},
	getUsers: () => {
		dispatch(getUsers(true));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.users.isSaving,
		isAvailablePartnersLoading: state.users.isAvailablePartnersLoading,
		availablePartners: state.users.availablePartners,
		globalPartnerId: state.partner.globalPartnerId,
		globalPartners: state.partner.globalPartners
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAddComponent);
