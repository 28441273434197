import { Fragment } from "react";
import PropTypes from "prop-types";

import BetslipsComponent from "components/dashboard/betslips/betslips.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { PROJECT_TYPE } from "constants/common.constants";

const BetslipsRouteComponent = ({ projectType, showBetsBySeparate }) => {
	return (
		<Fragment>
			<BetslipsComponent projectType={projectType} showBetsBySeparate={showBetsBySeparate} />
		</Fragment>
	);
};

BetslipsRouteComponent.propTypes = {
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	showBetsBySeparate: PropTypes.bool
};

const BetslipsRoute = withPermission(
	BetslipsRouteComponent,
	[
		{ resource: PERMISSION_RESOURCE.BETSLIPS_ONLINE, action: PERMISSION_ACTION.VIEW },
		{ resource: PERMISSION_RESOURCE.BETSLIPS_RETAIL, action: PERMISSION_ACTION.VIEW }
	],
	Paths.DASHBOARD
);

export const OnlineBetslipsRoute = ({ showBetsBySeparate = true }) => {
	return <BetslipsRoute projectType={PROJECT_TYPE.ONLINE} showBetsBySeparate={showBetsBySeparate} />;
};

export const RetailBetslipsRoute = ({ showBetsBySeparate = false }) => {
	return <BetslipsRoute projectType={PROJECT_TYPE.RETAIL} showBetsBySeparate={showBetsBySeparate} />;
};

OnlineBetslipsRoute.propTypes = {
	showBetsBySeparate: PropTypes.bool
};

RetailBetslipsRoute.propTypes = {
	showBetsBySeparate: PropTypes.bool
};