import { NOTIFICATIONS_ACTION_BEFORE, NOTIFICATIONS_ACTION_FINISH, SET_NOTIFICATIONS, ADD_NOTIFICATION, SET_NOTIFICATION_SOUND, DELETE_NOTIFICATION } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case NOTIFICATIONS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case NOTIFICATIONS_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case SET_NOTIFICATIONS:
			return {
				...state,
				notifications: payload.notifications
			};
		case ADD_NOTIFICATION:
			return {
				...state,
				notifications: [payload.notification, ...state.notifications],
				sound: true
			};
		case SET_NOTIFICATION_SOUND:
			return {
				...state,
				sound: payload.show
			};
		case DELETE_NOTIFICATION:
			return {
				...state,
				notifications: state.notifications.filter((n) => n.id !== payload.id)
			};
		default:
			return state;
	}
};
