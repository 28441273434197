import { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "antd";

import UserQuickInfo from "./usersQuickInfo/quickInfo.component";
import Table from "components/common/table";
import PermissionGroupAddComponent from "../../permissionGroup-add.component";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import { hasPermission } from "utils/permissions";

import { getPermissionGroups, setPermissionGroupsSorting, deletePermissionGroup } from "store/actions/dashboard/userManagement/permissionGroups/myGroups.action";

import permissionGroupType from "types/permissionGroup/permissionGroup.type";
import sortingType from "types/common/sorting.type";
import ExportButtonImminently from "components/common/exportButton/exportButtonImminently";
import { EXPORT_TYPE } from "constants/common.constants";
import ApiUrls from "constants/api.constants";

/** Permission Groups Page My Groups Tab Component */
const MyGroupsComponent = ({ getPermissionGroups, setPermissionGroupsSorting, deletePermissionGroup, permissionGroups, isLoading, sorting, total, globalPartnerId }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	/** State to show/hide permission group creation popup */
	const [showCreatePopup, setShowCreatePopup] = useState(false);

	/** The group id and users for quick info */
	const [quickInfo, setQuickInfo] = useState(null);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.permissionGroups.name"),
			dataIndex: "name"
		},
		{
			title: t("pages.dashboard.permissionGroups.description"),
			dataIndex: "description",
			render: (value) => (
				<span title={value} className="table-col-shorten-text">
					{value}
				</span>
			),
			sorter: false
		},
		{
			title: t("pages.dashboard.users.user_count"),
			dataIndex: "users",
			sorter: false,
			render: (users, record) => (
				<span className="table-col-link" onClick={() => setQuickInfo(record)}>
					{users ? users.length : 0}
				</span>
			)
		},
		{
			title: t("pages.dashboard.permissionGroups.created_at"),
			dataIndex: "created",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.permissionGroups.last_modified_at"),
			dataIndex: "lastUpdate",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.permissionGroups.last_modified_by"),
			dataIndex: "lastUpdateBy",
			sorter: false
		}
	];

	const generateAction = () => {
		let key = null;
		if (hasPermission({ resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW })) {
			key = "view";
		}
		if (hasPermission({ resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.MODIFY })) {
			key = "edit";
		}
		return {
			[key]: {
				handler: (record) => {
					navigate(Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, `${record.id}?PI=${globalPartnerId}`));
				}
			},
			delete: hasPermission({ resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.DELETE })
				? {
					messageKey: "permissionGroup",
					handler: (record) => deletePermissionGroup(record.id),
					hidden: (record) => record.users && record.users.length > 0
				}
				: null
		};
	};

	const permissionGroupIds = permissionGroups.map(permissionGroup => permissionGroup.id)

	return (
		<div className="dashboard-section-content">
			<div className="table-buttons-dropdowns">
				{
					hasPermission({ resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.EXPORT })
						? (
							<ExportButtonImminently
								url={ApiUrls.EXPORT_PERMISSION_GROUPS}
								exportType={EXPORT_TYPE.CSV}
								filters={{ ids: permissionGroupIds }}
							/>
						)
						: null
				}
				{hasPermission({ resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.CREATE }) ? (
					<div className="table-buttons-dropdowns-button">
						<Button onClick={() => setShowCreatePopup(true)} type="primary">
							{t("pages.dashboard.permissionGroups.create_group")}
						</Button>
					</div>
				) : null}
			</div>
			<Table loading={isLoading} columns={columns} data={permissionGroups} loadFn={getPermissionGroups} sorting={sorting} setSortingFn={setPermissionGroupsSorting} total={total} updateProps={[globalPartnerId]} actions={generateAction()} isDisabled={() => false} />
			{showCreatePopup ? <PermissionGroupAddComponent onClose={() => setShowCreatePopup(false)} /> : null}
			<UserQuickInfo id={quickInfo?.id ?? null} onClose={() => setQuickInfo(null)} />
		</div>
	);
};

/** MyGroupsComponent propTypes
 * PropTypes
 */
MyGroupsComponent.propTypes = {
	/** Redux action to get permission groups */
	getPermissionGroups: PropTypes.func,
	/** Redux state property, represents the array of permission groups */
	permissionGroups: PropTypes.arrayOf(permissionGroupType),
	/** Redux state property, is true when loading permission groups */
	isLoading: PropTypes.bool,
	/** Redux action to set permission groups sorting details */
	setPermissionGroupsSorting: PropTypes.func,
	/** Redux action to delete permission group */
	deletePermissionGroup: PropTypes.func,
	/** Redux state property, permission groups sorting details */
	sorting: sortingType,
	/** Redux state property, permission groups total count */
	total: PropTypes.number,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getPermissionGroups: (fromFirstPage) => {
		dispatch(getPermissionGroups(fromFirstPage));
	},
	setPermissionGroupsSorting: (sorting) => {
		dispatch(setPermissionGroupsSorting(sorting));
	},
	deletePermissionGroup: (id) => {
		dispatch(deletePermissionGroup(id));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.permissionGroups.isLoading,
		permissionGroups: state.permissionGroups.myGroups.groups,
		total: state.permissionGroups.myGroups.total,
		sorting: state.permissionGroups.myGroups.sorting,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyGroupsComponent);
