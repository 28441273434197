import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import {
	SYSTEM_SET_LANGUAGES,
	SYSTEM_SET_AVAILABLE_LANGUAGES,
	SYSTEM_SET_SYSTEM_AVAILABLE_LANGUAGES,
	SYSTEM_ADD_LANGUAGE,
	SYSTEM_DELETE_LANGUAGE,
	SYSTEM_LANGUAGES_BEFORE,
	SYSTEM_LANGUAGES_FINISH,
	SYSTEM_LANGUAGE_BEFORE,
	SYSTEM_LANGUAGE_FINISH,
	SYSTEM_AVAILABLE_LANGUAGES_BEFORE,
	SYSTEM_AVAILABLE_LANGUAGES_FINISH,
	SYSTEM_LANGUAGES_SET_SORTING
} from "store/actionTypes";

const setSystemLanguages = (languages) => ({
	type: SYSTEM_SET_LANGUAGES,
	payload: { languages }
});

const setAvailableLanguages = (languages) => ({
	type: SYSTEM_SET_AVAILABLE_LANGUAGES,
	payload: { languages }
});

const setSystemAvailableLanguages = (languages) => ({
	type: SYSTEM_SET_SYSTEM_AVAILABLE_LANGUAGES,
	payload: { languages }
});

const setAddSystemLanguage = (language) => ({
	type: SYSTEM_ADD_LANGUAGE,
	payload: { language }
});

const setDeleteSystemLanguage = (code) => ({
	type: SYSTEM_DELETE_LANGUAGE,
	payload: { code }
});

const setSystemLanguagesBefore = () => ({
	type: SYSTEM_LANGUAGES_BEFORE
});

const setSystemLanguagesFinished = () => ({
	type: SYSTEM_LANGUAGES_FINISH
});

const setSystemLanguageBefore = () => ({
	type: SYSTEM_LANGUAGE_BEFORE
});

const setSystemLanguageFinished = () => ({
	type: SYSTEM_LANGUAGE_FINISH
});

const setSystemAvailableLanguagesBefore = () => ({
	type: SYSTEM_AVAILABLE_LANGUAGES_BEFORE
});

const setSystemAvailableLanguagesFinished = () => ({
	type: SYSTEM_AVAILABLE_LANGUAGES_FINISH
});

export const setSystemLanguagesSorting = (sorting) => ({
	type: SYSTEM_LANGUAGES_SET_SORTING,
	payload: { sorting }
});

export const getSystemLanguages = () => {
	return (dispatch, getState) => {
		dispatch(setSystemLanguagesBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SYSTEM_LANGUAGES_ALL}`,
			method: Methods.GET,
			params: {
				...getState().systemLanguages.sorting
			}
		})
			.then(({ data: { value: languages } }) => {
				dispatch(setSystemLanguages(languages));
				dispatch(setSystemLanguagesFinished());
			})
			.catch((ex) => {
				dispatch(setSystemLanguagesFinished());
			});
	};
};

export const addSystemLanguage = (code, callback) => {
	return (dispatch) => {
		dispatch(setSystemLanguageBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SYSTEM_LANGUAGES_CREATE}`,
			method: Methods.POST,
			data: { code }
		})
			.then(({ data: { value: language, status, message: msg } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setAddSystemLanguage(language));
					return true;
				}
				return false;
			})
			.then(() => {
				if (typeof callback !== "function") {
					return;
				}
				callback();
			})
			.finally(() => {
				dispatch(setSystemLanguageFinished());
			});
	};
};

export const deleteSystemLanguage = (code) => {
	return (dispatch) => {
		dispatch(setSystemLanguageBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SYSTEM_LANGUAGES_DELETE}`,
			method: Methods.DELETE,
			data: { code }
		})
			.then(({ data: { status, message: msg } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setDeleteSystemLanguage(code));
				}
				dispatch(setSystemLanguageFinished());
			})
			.catch((ex) => {
				dispatch(setSystemLanguageFinished());
			});
	};
};

export const getAvailableLanguages = () => {
	return (dispatch) => {
		dispatch(setSystemAvailableLanguagesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SYSTEM_LANGUAGES_AVAILABLE}`,
			method: Methods.GET
		})
			.then(({ data: { value: languages } }) => {
				dispatch(setAvailableLanguages(languages));
				dispatch(setSystemAvailableLanguagesFinished());
			})
			.catch((ex) => {
				dispatch(setSystemAvailableLanguagesFinished());
			});
	};
};

export const getSystemAvailableLanguages = () => {
	return (dispatch) => {
		dispatch(setSystemAvailableLanguagesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SYSTEM_LANGUAGES_SYSTEM_AVAILABLE}`,
			method: Methods.GET
		})
			.then(({ data: { value: languages } }) => {
				dispatch(setSystemAvailableLanguages(languages));
				dispatch(setSystemAvailableLanguagesFinished());
			})
			.catch((ex) => {
				dispatch(setSystemAvailableLanguagesFinished());
			});
	};
};

export const getSystemAvailablePublishedLanguages = () => {
	return (dispatch) => {
		dispatch(setSystemAvailableLanguagesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SYSTEM_LANGUAGES_SYSTEM_AVAILABLE_PUBLISHED}`,
			method: Methods.GET
		})
			.then(({ data: { value: languages } }) => {
				dispatch(setSystemAvailableLanguages(languages));
				dispatch(setSystemAvailableLanguagesFinished());
			})
			.catch((ex) => {
				dispatch(setSystemAvailableLanguagesFinished());
			});
	};
};
