import PropTypes from "prop-types";

const partnerType = PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.number
});

export default PropTypes.shape({
	partners: PropTypes.arrayOf(partnerType),
	availablePartners: PropTypes.arrayOf(partnerType),
	mainPartner: partnerType
});
