import { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Tag, Button } from "antd";

import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { USER_ROLE, USER_STATE } from "constants/user.constants";

import { getAccessManagers, setAccessManagersSorting, setAccessManagersFilters, changeAccessManagerState } from "store/actions/dashboard/userManagement/accessManagers/accessManagers.action";

import { resendRegistrationEmail } from "store/actions/dashboard/userManagement/users/users.action";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import AccessManagersAddComponent from "./accessManagers-add.component";
import Filters from "./filters.component";
import ColumnsButton from "components/common/columnsButton";
import ExportButton from "components/common/exportButton";
import Question from "components/common/question";

import userType from "types/user/user.type";
import sortingType from "types/common/sorting.type";

import Paths from "constants/path.constants";
import ApiUrls from "constants/api.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import localStorageUtils from "utils/localStorage";
import { doesUserHaveRoleOf } from "utils/auth";

const getTagColorByUserStatus = (statusId) => {
	switch (statusId) {
		case USER_STATE.LOCKED:
			return "red";
		case USER_STATE.IN_PROGRESS:
			return "orange";
		case USER_STATE.ACTIVE:
			return "green";
		default:
			return "cyan";
	}
};

/** Access Managers Page Component */
const AccessManagersComponent = ({ userId, getAccessManagers, accessManagers, isLoading, isSaving, total, setAccessManagersSorting, setAccessManagersFilters, changeAccessManagerState, resendRegistrationEmail, sorting, filters, globalPartnerId, mainPartnerId, isSavingResend }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const partnerId = globalPartnerId || mainPartnerId;

	/** State to show/hide user creation popup */
	const [showCreatePopup, setShowCreatePopup] = useState(false);
	/** State to show/hide resend activation email popup */
	const [resendingUser, setResendingUser] = useState(null);

	const isAccessManager = doesUserHaveRoleOf(USER_ROLE.ACCESS_MANAGER);

	const closeResendQuestion = () => setResendingUser(null);

	/** Columns list, which can be included/excluded */
	const columnsToInclude = [
		{ title: t("pages.dashboard.users.email"), key: "email" },
		{ title: t("pages.dashboard.users.id"), key: "id" },
		{ title: t("pages.dashboard.users.last_update_at"), key: "lastUpdate" },
		{ title: t("pages.dashboard.users.last_login_at"), key: "lastLogin" },
		{ title: t("pages.dashboard.users.last_password_change_at"), key: "lastPasswordChange" },
		{ title: t("pages.dashboard.users.last_blocked_at"), key: "lastBlocked" },
		{ title: t("pages.dashboard.users.last_blocked_by"), key: "lastBlockedBy" }
	];

	const [includedColumns, setIncludedColumns] = useState((localStorageUtils.get("selected_columns") || {})["accessManagers"] || []);

	/** Function which will fire on columns popup apply button click
	 * @function
	 * @param {array} selectedColumns - selected columns
	 * @memberOf UsersComponent
	 */
	const handleColumnsApply = (selectedColumns) => {
		setIncludedColumns(selectedColumns);
	};

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.users.first_name"),
			dataIndex: "firstName"
		},
		{
			title: t("pages.dashboard.users.last_name"),
			dataIndex: "lastName"
		},
		{
			title: t("pages.dashboard.users.username"),
			dataIndex: "userName"
		},
		{
			title: t("pages.dashboard.users.status"),
			dataIndex: "state",
			render: (status, record) => {
				return <Tag color={getTagColorByUserStatus(status)}>{t("pages.dashboard.users.status_" + status)}</Tag>;
			}
		},
		{
			title: t("pages.dashboard.users.email"),
			dataIndex: "email"
		},
		{
			title: t("pages.dashboard.users.id"),
			dataIndex: "id"
		},
		{
			title: t("pages.dashboard.users.created_at"),
			dataIndex: "created",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.users.last_update_at"),
			dataIndex: "lastUpdate",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.users.last_login_at"),
			dataIndex: "lastLogin",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "")
		},
		{
			title: t("pages.dashboard.users.last_password_change_at"),
			dataIndex: "lastPasswordChange",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-")
		},
		{
			title: t("pages.dashboard.users.last_blocked_at"),
			dataIndex: "lastBlocked",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "")
		},
		{
			title: t("pages.dashboard.users.last_blocked_by"),
			dataIndex: "lastBlockedBy"
		}
	];

	/** Keep included columns in local storage */
	useEffect(() => {
		const columns = localStorageUtils.get("selected_columns") || {};
		columns["accessManagers"] = includedColumns;
		localStorageUtils.set("selected_columns", columns);
	}, [includedColumns]);

	/* Specific case, view action busy for activiti functional */
	const generateAction = () => {
		const actionObj = {};
		const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.ACCESS_MANAGER, action: PERMISSION_ACTION.MODIFY });
		const allowModify = isAccessManager || hasModifyPermission;

		actionObj[allowModify ? "edit" : "view"] = {
			handler: (record) => {
				navigate({
					pathname: Paths.ACCESS_MANAGERS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, record.id),
					search: "?PI=" + partnerId
				});
			}
		};

		if (allowModify) {
			actionObj["resend"] = {
				handler: (record) => setResendingUser({ id: record.id, name: record.userName }),
				title: t("pages.dashboard.users.resend_activation_email"),
				hidden: (record) => record.state !== USER_STATE.IN_PROGRESS,
				icon: <i className="icon-resend" />
			};

			actionObj["activate"] = {
				isChecked: (record) => record.state !== USER_STATE.BLOCKED,
				messageKey: "access_managers",
				handler: (enabled, { id }) => {
					changeAccessManagerState({ id, enabled });
				},
				disabled: (record) => record.id === userId
			};
		}

		return actionObj;
	};

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.access_managers") }]} />
				<div className="dashboard-section-content">
					<div className="table-header">
						<div className="table-buttons-dropdowns">
							{hasPermission({ resource: PERMISSION_RESOURCE.ACCESS_MANAGER, action: PERMISSION_ACTION.EXPORT }) && (
								<ExportButton columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t("pages.dashboard.menu.access_managers")} url={ApiUrls.EXPORT_ACCESS_MANAGERS} filters={filters} sorting={sorting} />
							)}
							<ColumnsButton columns={columnsToInclude} onApply={handleColumnsApply} defaultSelectedColumns={includedColumns} />
							{hasPermission({ resource: PERMISSION_RESOURCE.ACCESS_MANAGER, action: PERMISSION_ACTION.CREATE }) && (
								<div className="table-buttons-dropdowns-button">
									<Button onClick={() => setShowCreatePopup(true)} type="primary">
										{t("pages.dashboard.access_managers.create_access_manager")}
									</Button>
								</div>
							)}
						</div>
						<Filters />
					</div>

					<Table
						loading={isLoading}
						columns={columns.filter((c) => !columnsToInclude.find((col) => col.key === c.dataIndex) || includedColumns.includes(c.dataIndex))}
						data={accessManagers}
						loadFn={getAccessManagers}
						sorting={sorting}
						filters={filters}
						setSortingFn={setAccessManagersSorting}
						setFiltersFn={setAccessManagersFilters}
						total={total}
						updateProps={[partnerId]}
						enableReload={true}
						actions={generateAction()}
						isDisabled={() => false}
					/>
				</div>
			</div>
			<Question
				type="confirm"
				onOk={() => resendRegistrationEmail([resendingUser.id], closeResendQuestion)}
				onCancel={closeResendQuestion}
				isVisible={resendingUser !== null}
				isLoading={isSavingResend}
				message={t("pages.dashboard.users.resend_activation_email_msg_access_manager").replace("%%USERNAME%%", resendingUser ? resendingUser.name : "")}
				title={t("pages.dashboard.users.resend_activation_email")}
			/>
			{showCreatePopup ? <AccessManagersAddComponent onClose={() => setShowCreatePopup(false)} /> : null}
		</Fragment>
	);
};

/** AccessManagersComponent propTypes
 * PropTypes
 */
AccessManagersComponent.propTypes = {
	/** Redux state property, current user id */
	userId: PropTypes.string,
	/** Redux action to get access managers */
	getAccessManagers: PropTypes.func,
	/** Redux state property, represents the array of access managers */
	accessManagers: PropTypes.arrayOf(userType),
	/** Redux state property, access managers total count */
	total: PropTypes.number,
	/** Redux action to set access managers sorting details */
	setAccessManagersSorting: PropTypes.func,
	/** Redux action to set access managers filters */
	setAccessManagersFilters: PropTypes.func,
	/** Redux action to resend registration email */
	resendRegistrationEmail: PropTypes.func,
	/** Redux action to block/unblock access manager */
	changeAccessManagerState: PropTypes.func,
	/** Redux state property, access managers sorting details */
	sorting: sortingType,
	/** Redux state property, access managers filters */
	filters: PropTypes.object,
	/** Redux state property, is true when loading access managers */
	isLoading: PropTypes.bool,
	/** Redux state property, is true when saving user */
	isSaving: PropTypes.bool,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, represents main partner id of user */
	mainPartnerId: PropTypes.string,
	/** Redux state property, is true when resend request is pending */
	isSavingResend: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	getAccessManagers: (fromFirstPage) => {
		dispatch(getAccessManagers(fromFirstPage));
	},
	setAccessManagersSorting: (sorting) => {
		dispatch(setAccessManagersSorting(sorting));
	},
	setAccessManagersFilters: (filters) => {
		dispatch(setAccessManagersFilters(filters));
	},
	resendRegistrationEmail: (id, onSuccess) => {
		dispatch(resendRegistrationEmail(id, onSuccess));
	},
	changeAccessManagerState: (data) => {
		dispatch(changeAccessManagerState(data));
	}
});

const mapStateToProps = (state) => {
	return {
		userId: state.profile.userInfo.id,
		isLoading: state.accessManagers.isLoading,
		isSaving: state.accessManagers.isSaving,
		accessManagers: state.accessManagers.data,
		total: state.accessManagers.total,
		sorting: state.accessManagers.sorting,
		filters: state.accessManagers.filters,
		globalPartnerId: state.partner.globalPartnerId,
		mainPartnerId: state.profile.userInfo.mainPartnerId,
		isSavingResend: state.users.isSaving
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessManagersComponent);
