import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { setApikeysActionBefore, setApikeysActionFinished, setApikeySaveActionBefore, setApikeySaveActionFinished } from "./apikeys.action";

import { SET_APIKEY_UI, SET_APIKEY_PREVIEW_UI, RESET_APIKEY_UI, SET_APIKEY_MOBILE_LOGO_ID } from "store/actionTypes";

const setApikeyUI = (data, updatePreview) => ({
	type: SET_APIKEY_UI,
	payload: { data, updatePreview }
});

export const getApiKeyUI = (id, languageCode, resetData) => {
	return (dispatch) => {
		dispatch(setApikeysActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APIKEY_GET_UI}`,
			method: Methods.GET,
			params: { id, languageCode }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setApikeyUI(data, resetData));
				dispatch(setApikeysActionFinished());
			})
			.catch((ex) => {
				dispatch(setApikeysActionFinished());
			});
	};
};

export const saveApiKeyUI = (id, parameters, languageCode) => {
	return (dispatch) => {
		dispatch(setApikeySaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APIKEY_SAVE_UI}`,
			method: Methods.POST,
			data: {
				id,
				parameters,
				languageCode
			}
		})
			.then(({ data: { message: msg, status, value: data } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setApikeyUI(data, false));
				}
				dispatch(setApikeySaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setApikeySaveActionFinished());
			});
	};
};

export const setApiKeyPreviewUI = (data) => ({
	type: SET_APIKEY_PREVIEW_UI,
	payload: { data }
});

export const resetApiKeyUI = () => ({
	type: RESET_APIKEY_UI
});

export const deleteMobileLogo = (apiKeyId) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APIKEY_DELETE_LOGO}`,
			method: Methods.DELETE,
			data: { apiKeyId }
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setMobileLogoId(null));
				}
			})
			.catch((ex) => {
				console.log(ex);
			});
	};
};

export const setMobileLogoId = (logoId) => ({
	type: SET_APIKEY_MOBILE_LOGO_ID,
	payload: { logoId }
});
