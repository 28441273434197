export const FILTER_STATUS = {
	ALL: "0",
	ACTIVE: "1",
	INACTIVE: "2"
};

export const FILTER_TEST_STATE = {
	ALL: "0",
	TESTING: "1",
	NOTTESTING: "2"
};

export const FILTER_GAME_SPECIFICATION_TYPE = {
	ALL: "0",
	PARTNER_SPECIFIC: "1",
	GENERIC: "2"
};

export const FILTER_BETSLIP_MODES = {
	ALL: "",
	SINGLE: "1",
	MULTI: "2"
};

export const FILTER_BETSLIP_MODE_TYPE = {
	DEMO: "1",
	REAL: "2"
};

export const FILTER_BET_STATE = {
	ALL: "",
	WON: "4",
	LOST: "32",
	CANCELLED: "64",
	REJECTED_BY_OPERATOR: "128"
};

export const FILTER_EVENT_STATE = {
	ALL: 24,
	FINISHED: 8,
	CANCELED: 16
};

export const FILTER_ACTIVITY_TYPE = {
	ALL: "",
	WALLET_ADJUSTMENT: 1,
	LIMIT_ADJUSTMENT: 2,
	BET: 3,
	CANCEL: 4,
	PAYOUT: 5
};

export const FILTER_TRANSACTION_STATUS = {
	ALL: "",
	SUCCESS: "false",
	FAILED: "true"
};

export const FILTER_USER_STATE = {
	ALL: "",
	IN_PROGRESS: "1",
	ACTIVE: "2",
	BLOCKED: "4",
	EXPIRED: "8"
};

export const FILTER_USER_ONLINE_STATE = {
	ALL: "",
	ONLINE: "1",
	OFFLINE: "2"
};

export const FILTER_REQUEST_API_TYPE = {
	ADMIN: 1,
	SITE: 2,
	CASHIER: 3
};

export const FILTER_SHOW_IN_CURRENCY = {
	ORIGINAL: 1,
	CONVERTED: 2
};

export const FILTER_PRINT_TYPE = {
	ALL: "",
	BET: "1",
	CANCEL: "2",
	PAYOUT: "3"
};

export const FILTER_SETTLEMENT_PRINTED = {
	ALL: "",
	PRINTED: "true",
	NOT_PRINTED: "false"
};

export const FILTER_BONUS_TYPE = {
	ALL: "",
	FREEBET: 1
};

export const FILTER_BONUS_STATE = {
	ALL: "",
	NEW: 1,
	ACTIVE: 2,
	FINISHED: 4,
	CANCELED: 8
};

export const FILTER_JACKPOT_BONUS_LEVEL = {
	ALL: "",
	BRONZE: 1,
	SILVER: 2,
	GOLD: 4
}

export const FILTER_JACKPOT_BONUS_STATE = {
	ALL: "",
	INACTIVE: 1,
	ACTIVE: 2,
	PAUSED: 4,
	FINISHED: 8
}

export const FILTER_JACKPOT_BONUS_TYPE = {
	ALL: "",
	ONLINE: 1,
	RETAIL: 2
}

export const FILTER_REPORT_BONUS_TYPE = {
	ALL: 1,
	ONLY_BONUS: 2,
	WITHOUT_BONUS: 4
};
