import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import ApiUrls from "constants/api.constants";
import { PROJECT_TYPE } from "constants/common.constants";

import SettledBetsTableComponent from "components/dashboard/betslips/sections/common/settled/tableByBetSlip.component";
import Filters from "./filters.component";

import { getPlayerSettledBets, setPlayerSettledBetsSorting, setPlayerSettledBetsFilters } from "store/actions/dashboard/online/players/betslips.action";

import betType from "types/bet/bet.type";
import sortingType from "types/common/sorting.type";

import { FILTER_SHOW_IN_CURRENCY } from "constants/filter.constants";
import { PERMISSION_RESOURCE } from "constants/permissions.constants";
import { GAME_CATEGORY } from "constants/game.constants";

/** Player View Page BetHistory tab Settled betslips SubTab Page Component */
const PlayerSettledBetsComponent = ({ bets, sorting, filters, total, isLoading, getPlayerSettledBets, setPlayerSettledBetsSorting, setPlayerSettledBetsFilters }) => {
	const routeParams = useParams();

	return (
		<SettledBetsTableComponent
			bets={bets}
			sorting={sorting}
			filters={{ ...filters, showIn: FILTER_SHOW_IN_CURRENCY.ORIGINAL }}
			total={total}
			isLoading={isLoading}
			projectType={PROJECT_TYPE.ONLINE}
			getSettledBets={() => getPlayerSettledBets(routeParams.id)}
			setSettledBetsSorting={setPlayerSettledBetsSorting}
			setSettledBetsFilters={setPlayerSettledBetsFilters}
			exportURL={ApiUrls.EXPORT_PLAYERS_BET_HISTORY + "?projectType=" + PROJECT_TYPE.ONLINE + "&userNameOrId=" + routeParams.id + "&isPending=" + false}
			filtersComponent={<Filters />}
			permission={PERMISSION_RESOURCE.PLAYER_BET_HISTORY}
			gameCategory={GAME_CATEGORY.SCHEDULED}
		/>
	);
};

/** PlayerSettledBetsComponent propTypes
 * PropTypes
 */
PlayerSettledBetsComponent.propTypes = {
	/** Redux state property, represents the array of player settled betslips */
	bets: PropTypes.arrayOf(betType),
	/** Redux state property, player settled betslips sorting details */
	sorting: sortingType,
	/** Redux state property, player settled betslips filters */
	filters: PropTypes.object,
	/** Redux state property, player settled betslips total count */
	total: PropTypes.number,
	/** Redux state property, is true when loading player settled bets */
	isLoading: PropTypes.bool,
	/** Redux action to get player settled bets */
	getPlayerSettledBets: PropTypes.func,
	/** Redux action to set player settled bets sorting details */
	setPlayerSettledBetsSorting: PropTypes.func,
	/** Redux action to set player settled bets filters */
	setPlayerSettledBetsFilters: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		bets: state.players.viewingPlayer.betHistory.settled.bets,
		total: state.players.viewingPlayer.betHistory.settled.total,
		sorting: state.players.viewingPlayer.betHistory.settled.sorting,
		filters: state.players.viewingPlayer.betHistory.settled.filters,
		isLoading: state.players.isLoading
	};
};

const mapDispatchToProps = (dispatch) => ({
	getPlayerSettledBets: (id) => {
		dispatch(getPlayerSettledBets(id));
	},
	setPlayerSettledBetsSorting: (sorting) => {
		dispatch(setPlayerSettledBetsSorting(sorting));
	},
	setPlayerSettledBetsFilters: (filters) => {
		dispatch(setPlayerSettledBetsFilters(filters));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerSettledBetsComponent);
