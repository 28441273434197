export const SURVEY_STATE = {
	NONE: 0,
	ACTIVE: 1,
	INACTIVE: 2,
	FINISHED: 4
};

export const SURVEY_TYPE = {
	CSAT: 1
};

export const SURVEY_DURATION_TYPE = {
	TEMPORARY: 1,
	PERMANENT: 2
};
