import PropTypes from "prop-types";

import Spin from "antd/lib/spin";

import { useTranslation } from "react-i18next";

const ChartLoader = ({ size = "large", title = "" }) => {
	const { t } = useTranslation();

	return (
		<div className="vs--ui-loader-container">
			<div className="vs--ui-loader-content-block">
				<Spin size={size} />
				<p className={`vs--ui-loader-title ${size === "large" ? "vs--ui-loader-title-large" : ""}`}>{title || t("common.loading")}</p>
			</div>
		</div>
	);
};

ChartLoader.propTypes = {
	// The size of antd spinner
	size: PropTypes.string,
	// Title text of the Component
	title: PropTypes.string
};

export default ChartLoader;
