import {
	PERMISSION_GROUPS_BEFORE,
	PERMISSION_GROUPS_FINISH,
	PERMISSION_GROUPS_SAVE_BEFORE,
	PERMISSION_GROUPS_SAVE_FINISH,
	PERMISSION_GROUPS_SET_MY_GROUPS,
	PERMISSION_GROUPS_SET_MY_GROUPS_SORTING,
	PERMISSION_GROUPS_SET_GENERAL_INFO,
	PERMISSION_GROUPS_SET_QUICK_VIEW_USERS,
	PERMISSION_GROUPS_SET_QUICK_VIEW_USERS_SORTING
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case PERMISSION_GROUPS_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case PERMISSION_GROUPS_FINISH:
			return {
				...state,
				isLoading: false
			};
		case PERMISSION_GROUPS_SAVE_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case PERMISSION_GROUPS_SAVE_FINISH:
			return {
				...state,
				isSaving: false
			};
		case PERMISSION_GROUPS_SET_MY_GROUPS:
			return {
				...state,
				myGroups: {
					...state.myGroups,
					groups: payload.data.item2,
					total: payload.data.item1
				}
			};
		case PERMISSION_GROUPS_SET_MY_GROUPS_SORTING:
			return {
				...state,
				myGroups: {
					...state.myGroups,
					sorting: payload.sorting
				}
			};
		case PERMISSION_GROUPS_SET_GENERAL_INFO:
			return {
				...state,
				editingPermissionGroup: {
					...state.editingPermissionGroup,
					generalInfo: {
						...payload.info
					}
				}
			};
		case PERMISSION_GROUPS_SET_QUICK_VIEW_USERS:
			return {
				...state,
				editingPermissionGroup: {
					...state.editingPermissionGroup,
					usersQuickView: {
						...state.editingPermissionGroup.usersQuickView,
						total: payload.data.item1,
						users: payload.data.item2
					}
				}
			};
		case PERMISSION_GROUPS_SET_QUICK_VIEW_USERS_SORTING:
			return {
				...state,
				editingPermissionGroup: {
					...state.editingPermissionGroup,
					usersQuickView: {
						...state.editingPermissionGroup.usersQuickView,
						sorting: payload.sorting
					}
				}
			};
		default:
			return state;
	}
};
