import PropTypes from "prop-types";

import { ORDER_DIRECTION } from "constants/common.constants";

export default PropTypes.shape({
	page: PropTypes.number,
	limit: PropTypes.number,
	orderBy: PropTypes.string,
	orderDirection: PropTypes.oneOf([ORDER_DIRECTION.DESC, ORDER_DIRECTION.ASC])
});
