import { Descriptions, Row, Col, Card, Button, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { copyToClipboard, prettyJson } from "utils/common";

import requestType from "types/log/request.type";

/**Requests Details Component */
const RequestsDetailsComponent = ({ request }) => {
	const { t } = useTranslation();

	return (
		<Descriptions colon={true} column={1} className="request-descriptions">
			<Descriptions.Item label={t("common.id")}>{request.id}</Descriptions.Item>
			<Descriptions.Item label={t("pages.dashboard.logs.milliseconds")}>{request.totalMilliseconds.toFixed(4)}</Descriptions.Item>
			<Descriptions.Item label={""}>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={24} md={12}>
						<Card title={t("pages.dashboard.logs.request")}>
							{request.details ? (
								<div className="dashboard-logs-request-description">
									<Descriptions colon={true} column={1}>
										<Descriptions.Item label={t("pages.dashboard.logs.requestTime")}>{moment.utc(request.details.requestTime).local().format(DATE_TIME_FORMAT)}</Descriptions.Item>
										<Descriptions.Item>
											{request.details.requestRaw && (
												<div className="dashboard-logs-request-description">
													<pre style={{ whiteSpace: "pre" }}>{prettyJson(request.details.requestRaw)}</pre>
													<Button onClick={() => copyToClipboard(request.details.requestRaw)}>{t("common.copy")}</Button>
												</div>
											)}
										</Descriptions.Item>
									</Descriptions>
								</div>
							) : (
								<Skeleton />
							)}
						</Card>
					</Col>
					<Col xs={24} sm={24} md={12}>
						<Card title={t("pages.dashboard.logs.response")}>
							{request.details ? (
								<div>
									<Descriptions colon={true} column={1}>
										<Descriptions.Item label={t("pages.dashboard.logs.responseTime")}>{moment.utc(request.details.responseTime).local().format(DATE_TIME_FORMAT)}</Descriptions.Item>
										<Descriptions.Item>
											{request.details.responseRaw && (
												<div className="dashboard-logs-request-description">
													<pre style={{ whiteSpace: "pre" }}>{prettyJson(request.details.responseRaw)}</pre>
													<Button onClick={() => copyToClipboard(request.details.responseRaw)}>{t("common.copy")}</Button>
												</div>
											)}
										</Descriptions.Item>
									</Descriptions>
								</div>
							) : (
								<Skeleton />
							)}
						</Card>
					</Col>
				</Row>
			</Descriptions.Item>
		</Descriptions>
	);
};

RequestsDetailsComponent.propTypes = {
	/** Request to show */
	request: requestType
};

export default RequestsDetailsComponent;
