import PropTypes from "prop-types";

import { BONUS_TYPE, BONUS_STATE } from "constants/bonus.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	projectId: PropTypes.string,
	projectName: PropTypes.string,
	bonusType: PropTypes.oneOf(Object.values(BONUS_TYPE)),
	name: PropTypes.string,
	players: PropTypes.number,
	gameType: PropTypes.number,
	bonusState: PropTypes.oneOf(Object.values(BONUS_STATE)),
	activationPeriodStart: PropTypes.string,
	activationPeriodEnd: PropTypes.string,
	activePeriod: PropTypes.number,
	currency: PropTypes.string,
	betSlipType: PropTypes.number,
	createdAt: PropTypes.string,
	createdBy: PropTypes.string,
	lastModifiedAt: PropTypes.string,
	lastModifiedBy: PropTypes.string
});
