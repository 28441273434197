import { message } from 'antd'
import axios from 'axios';
import Methods from 'constants/methods.constants';
import ApiUrls from 'constants/api.constants';
import { RESPONSE_STATE } from 'constants/response.constants';

import {
	PROFILE_ACTION_BEFORE,
	PROFILE_ACTION_FINISH,
	SET_PROFILE,
	PROFILE_SAVE_ACTION_BEFORE,
	PROFILE_SAVE_ACTION_FINISH
} from '../../../actionTypes';


const setProfileActionBefore = () => ({
	type: PROFILE_ACTION_BEFORE
})

const setProfileActionFinished = () => ({
	type: PROFILE_ACTION_FINISH
})

const setProfileSaveActionBefore = () => ({
	type: PROFILE_SAVE_ACTION_BEFORE
})

const setProfileSaveActionFinished = () => ({
	type: PROFILE_SAVE_ACTION_FINISH
})

const setProfile = profile => ({
	type: SET_PROFILE,
	payload: { profile }
})

export const getProfile = () => {
	return dispatch => {
		dispatch(setProfileActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PROFILE}`,
			method: Methods.GET
		})
			.then(({ data: { value: profile } }) => {
				dispatch(setProfile(profile));
				dispatch(setProfileActionFinished());
			})
			.catch(() => {
				dispatch(setProfileActionFinished());
			});
	};
};
export const saveProfile = profile => {
	return dispatch => {
		dispatch(setProfileSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_PROFILE}`,
			method: Methods.POST,
			data: {
				...profile
			}
		})
			.then(({ data: { message: msg, status, value: profile } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setProfile(profile));
				}
				dispatch(setProfileSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setProfileSaveActionFinished());
			});
	}
}