import axios from "axios";

import { PARTNERS_ACTION_BEFORE, PARTNERS_ACTION_FINISH, PARTNERS_ACTION_SET_PARTNERS, PARTNER_SAVE_ACTION_BEFORE, PARTNER_SAVE_ACTION_FINISH, PARTNER_SAVE_ACTION_SUCCESS, PARTNERS_SET_SORTING, PARTNERS_SET_FILTERS } from "../../../actionTypes";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { refreshToken, getUser } from "utils/auth";

import { getUserInfo } from "store/actions/dashboard/profile/userInfo.action";
import { setGlobalPartnerId } from "./partner.action";

const setPartnersActionBefore = () => ({
	type: PARTNERS_ACTION_BEFORE
});

const setPartnersActionFinished = () => ({
	type: PARTNERS_ACTION_FINISH
});

const setPartnersActionSetPartners = (partners) => ({
	type: PARTNERS_ACTION_SET_PARTNERS,
	payload: { partners }
});

const setPartnerSaveActionBefore = () => ({
	type: PARTNER_SAVE_ACTION_BEFORE
});

const setPartnerSaveActionFinished = () => ({
	type: PARTNER_SAVE_ACTION_FINISH
});

const setPartnerSaveActionSuccess = (data) => ({
	type: PARTNER_SAVE_ACTION_SUCCESS,
	payload: { data }
});

export const setPartnersSorting = (sorting) => ({
	type: PARTNERS_SET_SORTING,
	payload: { sorting }
});

export const setPartnersFilters = (filters) => ({
	type: PARTNERS_SET_FILTERS,
	payload: { filters }
});

export const getPartners = (fromFirstPage, loadAll) => {
	return (dispatch, getState) => {
		dispatch(setPartnersActionBefore());
		let params = null;
		if (loadAll) {
			params = { page: 1, limit: 500 };
		} else {
			params = {
				...getState().partners.sorting,
				...getState().partners.filters,
				page: fromFirstPage ? 1 : getState().partners.sorting.page
			};
		}
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNERS}`,
			method: Methods.GET,
			params: params
		})
			.then(({ data: { value: partners } }) => {
				dispatch(setPartnersActionSetPartners(partners));
				if (fromFirstPage) dispatch(setPartnersSorting({ ...getState().partners.sorting, page: 1 }));
				dispatch(setPartnersActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnersActionFinished());
			});
	};
};

export const createPartner = (partner, onSuccess) => {
	return (dispatch) => {
		dispatch(setPartnerSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CREATE_PARTNER}`,
			method: Methods.POST,
			data: {
				...partner
			}
		})
			.then(({ data }) => {
				dispatch(setPartnerSaveActionFinished());
				if (data.status === RESPONSE_STATE.SUCCESS) {
					const p = { ...partner, ...data.value };
					dispatch(setPartnerSaveActionSuccess(p));
					const user = getUser();
					const refresh_token = user?.refreshToken ?? null;
					const userName = user?.userName;
					refreshToken(refresh_token, userName)
						.then(() => {
							return dispatch(getUserInfo(true));
						})
						.then(() => {
							dispatch(setGlobalPartnerId(p.id));
							onSuccess && onSuccess(p);
						});
				}
			})
			.catch(() => {
				dispatch(setPartnerSaveActionFinished());
			});
	};
};
