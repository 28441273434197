import { useState, Fragment, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Progress, Upload, message } from "antd";
const { Dragger } = Upload;
import { DownloadOutlined } from "@ant-design/icons";
import Preview from "./preview";
import Question from "components/common/question";
import { doUploadRequest } from "utils/upload";
import { humanFileSize, downloadURI } from "utils/common";

/** Banner upload Component */
const BannerUploadWithComfirm = ({ uploadUrl, defaultFile, remove, data, fileBuilder, extensions, size, disabled = false, disablePreview, onSuccess, updateProps, isLogoExists }) => {
	const { t } = useTranslation();

	const [file, setFile] = useState(defaultFile || null);
	const [showPreview, setShowPreview] = useState(false);
	const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
	const [showActionsModal, setShowActionsModal] = useState(null);
	const overrideRef = useRef({ override: false });
	const antdDraggerContainerRef = useRef(null);
	const uploadFunctionGenerator = (boolFlag) => () => {
		overrideRef.current.override = boolFlag;
		typeof showActionsModal === "function" && showActionsModal();
		setShowActionsModal(null);
	};

	/** Update data */
	useEffect(() => {
		setTimeout(() => {
			updateDefaultFile();
		}, 20);
	}, updateProps || []);

	const uploadCancelation = useRef(null);

	/** Fires on preview button click
	 * @function
	 * @param {object} e - event object
	 * @memberOf BannerUploadWithComfirm
	 */
	const handlePreview = (e) => {
		e.stopPropagation();
		setShowPreview(true);
	};

	/** Fires on remove button click
	 * @function
	 * @param {object} e - event object
	 * @memberOf BannerUploadWithComfirm
	 */
	const handleRemove = (e) => {
		e.stopPropagation();
		setShowRemoveConfirmation(true);
	};

	/** Fires on download button click
	 * @function
	 * @param {object} e - event object
	 * @memberOf BannerUploadWithComfirm
	 */
	const handleDownload = (e) => {
		e.stopPropagation();
		const fileURL = file.previewUrl || file.url;
		const fileName = fileURL.split("/")[fileURL.split("/").length - 1];
		downloadURI(fileURL, fileName);
	};

	/** Function to remove the file
	 * @function
	 * @memberOf BannerUploadWithComfirm
	 */
	const removeFile = () => {
		setShowRemoveConfirmation(false);
		setShowActionsModal(() => () => {
			remove.handler && remove.handler({ ...overrideRef.current });
			setFile(null);
		});
	};

	/** Function to update default file
	 * @function
	 * @memberOf BannerUploadWithComfirm
	 */
	const updateDefaultFile = () => {
		setFile(defaultFile);
	};

	/** Function to abort upload
	 * @function
	 * @param {object} e - event object
	 * @memberOf BannerUploadWithComfirm
	 */
	const abortUpload = (e) => {
		e.stopPropagation();
		if (file.status === "error") {
			setFile(null);
		} else {
			uploadCancelation.current.cancel();
		}
	};

	/** Function to reTry upload
	 * @function
	 * @param {object} e - event object
	 * @param {boolean} boolValueForDragEventCall - flag that the event handler is being simulated
	 * @param {object} localazeState - state of file, using with boolValueForDragEventCall when event is being simulated
	 * @memberOf BannerUploadWithComfirm
	 */
	const retryUpload = (e, boolValueForDragEventCall = false, localazeState = {}) => {
		e.stopPropagation();
		uploadCancelation.current = doUploadRequest({
			action: uploadUrl,
			onSuccess: (data, file) => {
				const f = {};
				f.name = file.name;
				f.status = data.status;
				f.fileObject = !boolValueForDragEventCall ? file.originFileObj : localazeState;
				f.total = file.size;
				f.percent = 100;
				f.loaded = file.size;
				f.url = fileBuilder(data.value).url;
				f.previewUrl = fileBuilder(data.value).previewUrl;
				f.dimensions = fileBuilder(data.value).dimensions;
				f.format = fileBuilder(data.value).format;
				/* When event simulated status doesn't equal done, its equal to Success fo need convert it */
				if (boolValueForDragEventCall && data.status === "Success") {
					f.status = "done";
				}
				setFile(f);
			},
			onError: () => {
				setFile({
					...(!boolValueForDragEventCall ? file : localazeState),
					status: "error"
				});
			},
			onProgress: (event) => {
				const f = {};
				f.name = (!boolValueForDragEventCall ? file : localazeState).name;
				f.status = "uploading";
				f.fileObject = !boolValueForDragEventCall ? file.originFileObj : localazeState.fileObject;
				f.total = event.total;
				f.loaded = event.loaded;
				f.percent = (f.loaded * 100) / f.total;
				setFile(f);
			},
			file: (!boolValueForDragEventCall ? file : localazeState).fileObject,
			data: { ...data, ...overrideRef.current }
		});
	};

	/** Function which fires on upload start
	 * @function
	 * @param {object} file
	 * @memberOf BannerUploadWithComfirm
	 */
	const onBeforeUpload = (file) => {
		if (extensions && !extensions.includes(file.type)) {
			message.info(t("errors.message.InvalidFile"));
			setFile(null);
			return false;
		} else if (size && size < file.size) {
			message.info(t("errors.message.InvalidFileSize"));
			setFile(null);
			return false;
		}

		setFile({
			name: file.name,
			total: file.size,
			percent: 0,
			loaded: 0,
			status: "uploading",
			fileObject: file
		});
		return true;
	};

	/** Function which fires during upload
	 * @function
	 * @param {object} info
	 * @memberOf BannerUploadWithComfirm
	 */
	const onChange = (info) => {
		const f = {};
		f.name = info?.file?.name ?? "";
		f.status = info?.file?.status ?? "";
		f.fileObject = info?.file?.originFileObj ?? {};

		if (f.status === "uploading") {
			f.total = info?.event?.total ?? 0;
			f.loaded = info?.event?.loaded ?? 0;
			f.percent = (f.loaded * 100) / f.total;
		} else if (f.status === "done") {
			const fileResponse = info?.file?.response?.value ?? "";

			f.total = info?.file?.size ?? 0;
			f.percent = 100;
			f.loaded = info?.file?.size ?? 0;
			f.url = fileBuilder(fileResponse).url;
			f.previewUrl = fileBuilder(fileResponse).previewUrl;
			f.dimensions = fileBuilder(fileResponse).dimensions;
			f.format = fileBuilder(fileResponse).format;

			onSuccess && onSuccess(fileResponse);
		} else if (f.status === "error") {
			f.total = file.total;
			f.percent = file.percent;
			f.loaded = file.loaded;
		}
		if (f.status) {
			setFile(f);
		}
	};

	const predicateAntdDraggerHandler = (fnName) => (event) => {
		if (disabled) {
			return;
		}

		event && event.preventDefault();
		const antdDraggerElement = antdDraggerContainerRef.current && antdDraggerContainerRef.current.querySelector(".ant-upload.ant-upload-drag");

		if (antdDraggerElement && fnName && typeof antdDraggerElement.classList[fnName] === "function") {
			antdDraggerElement.classList[fnName]("ant-upload-drag-hover");
		}
	};

	return (
		<Fragment>
			<div className={"banner-upload banner-upload-with-comfirm" + (["uploading", "error"].includes(file?.status) ? " banner-upload-uploading" : "")}>
				<div className={`banner-upload-with-comfirm-wrapper${disabled ? " banner-upload-with-comfirm-wrapper-disabled" : ""}`} ref={antdDraggerContainerRef}>
					<Dragger
						action={uploadUrl}
						multiple={false}
						onChange={onChange}
						beforeUpload={onBeforeUpload}
						disabled={disabled}
						showUploadList={{
							showPreviewIcon: false,
							showDownloadIcon: false,
							showRemoveIcon: false
						}}
						customRequest={({ onSuccess, onError, onProgress, file }) => {
							uploadCancelation.current = doUploadRequest({ action: uploadUrl, onSuccess, onError, onProgress, file, data: { ...data, ...overrideRef.current } });
						}}
						extensions={extensions}
					>
						{file ? (
							file.status === "done" ? (
								<div
									className="banner-upload-preview"
									onClick={(e) => {
										if (disabled) {
											return;
										}

										e.stopPropagation();
										e.preventDefault();
										setShowActionsModal(() => () => {
											const uploadButton = document.getElementsByClassName("ant-upload-btn")[0];
											uploadButton && uploadButton.click();
										});
									}}
								>
									<img src={file.url} alt="banner" />
									<div className="banner-upload-preview-actions">
										<div className="banner-upload-preview-actions-button" onClick={handleDownload}>
											<DownloadOutlined />
										</div>
										{!disablePreview && (
											<div className="banner-upload-preview-actions-button" onClick={handlePreview}>
												<i className="icon-eye" />
											</div>
										)}
										{remove && (
											<div className="banner-upload-preview-actions-button" onClick={handleRemove}>
												<i className="icon-remove" />
											</div>
										)}
									</div>
								</div>
							) : file.status === "uploading" || file.status === "error" ? (
								<div
									className="banner-upload-progress"
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
										setShowActionsModal(() => () => {
											const uploadButton = document.getElementsByClassName("ant-upload-btn")[0];
											uploadButton && uploadButton.click();
										});
									}}
								>
									<div className="banner-upload-progress-info">
										<div className="banner-upload-progress-info-text">
											<b>{file.name}</b>
											<span>{`${humanFileSize(file.loaded)} / ${humanFileSize(file.total)}`}</span>
										</div>
										<div className="banner-upload-progress-info-actions">
											{file.status === "error" && (
												<div className="banner-upload-progress-info-actions-item" data-action="retry" onClick={retryUpload}>
													<i className="icon-reset" />
												</div>
											)}

											<div className="banner-upload-progress-info-actions-item" data-action="abort" onClick={abortUpload}>
												<i className="icon-close" />
											</div>
										</div>
									</div>
									<Progress percent={file.percent} size="small" showInfo={false} strokeWidth={3} status={file.status === "error" ? "exception" : "normal"} />
								</div>
							) : null
						) : (
							<span className="banner-upload-drag-text">{t("common.choose_or_drag_here")}</span>
						)}
					</Dragger>
					{file === null ? (
						<span
							className={`banner-upload-with-comfirm-trigger ${disabled ? "banner-upload-with-comfirm-trigger-disabled" : ""}`}
							onClick={(e) => {
								if (disabled) {
									return;
								}

								e.stopPropagation();
								e.preventDefault();
								setShowActionsModal(() => () => {
									const uploadButton = document.getElementsByClassName("ant-upload-btn")[0];
									uploadButton && uploadButton.click();
								});
							}}
							onDragOver={predicateAntdDraggerHandler("add")}
							onDragLeave={predicateAntdDraggerHandler("remove")}
							onDrop={(e) => {
								e.stopPropagation();
								predicateAntdDraggerHandler("remove")(e);
								/* Get native dom event from react syntetic event */
								const nativeEvent = e.nativeEvent;
								/* Destructure transfered data of onDrop event and asign to variable for don't lose referance of files */
								const files = [...nativeEvent.dataTransfer.files];
								if (files.length) {
									/* Create new onDrop event, becouse old event already dispachet by browser and can't be used again */
									const newNativeEvent = new nativeEvent.constructor(nativeEvent.type, nativeEvent);
									/* Callect all files in new data transfer object */
									const dataTransfer = new DataTransfer();
									dataTransfer.items.add(files[0]);
									/*
											Get initial descriptors of dataTransfer
											property of nativeEvent for assign other event
										*/
									const dataTransferDescriptors = Object.getOwnPropertyDescriptor(nativeEvent, "dataTransfer");
									/*
											Asign new data transfer object to new event,
											native event dataTransfer propery doesn't have setter,
											so forks only this way
										*/
									Object.defineProperty(newNativeEvent, "dataTransfer", { ...dataTransferDescriptors, value: dataTransfer });
									/* Simulation of onBeforeUpload for validation */
									if (onBeforeUpload(files[0])) {
										setShowActionsModal(() => () => {
											setTimeout(() => {
												/*
														Need set state again, becouse in the process
														state can be changed to null, this functionality works
														over the antd upload functionality
													*/
												const newState = {
													name: files[0].name,
													total: files[0].size,
													percent: 0,
													loaded: 0,
													status: "uploading",
													fileObject: files[0]
												};
												setFile(newState);
												/* Simulation of retryUpload to upload image */
												retryUpload(newNativeEvent, true, newState);
											}, 0);
										});
									}
								}
							}}
						/>
					) : null}
				</div>
			</div>
			{showPreview && <Preview file={file} onClose={() => setShowPreview(false)} />}
			{showRemoveConfirmation && <Question type="confirm" onOk={removeFile} onCancel={() => setShowRemoveConfirmation(false)} isVisible={true} message={remove.message} />}
			{showActionsModal !== null && <Question type="confirm" onOk={uploadFunctionGenerator(true)} onCancel={uploadFunctionGenerator(false)} isVisible={showActionsModal !== null} message={t("pages.dashboard.partners.retail_settings.retail_configs.override_all_confirm_message")} />}
		</Fragment>
	);
};

/** BannerUploadWithComfirm propTypes
 * PropTypes
 */
BannerUploadWithComfirm.propTypes = {
	/** Upload URL */
	uploadUrl: PropTypes.string,
	/** Default file */
	defaultFile: PropTypes.shape({
		/** File url */
		url: PropTypes.string,
		/** File dimensions */
		dimensions: PropTypes.string,
		/** File name */
		name: PropTypes.string,
		/** File status */
		status: PropTypes.oneOf(["uploading", "done", "error"]),
		/** File uploaded percent */
		percent: PropTypes.number,
		/** File loaded part size */
		loaded: PropTypes.number,
		/** File total size */
		total: PropTypes.number,
		/** File file object */
		fileObject: PropTypes.object,
		/** File format */
		format: PropTypes.string,
		/** File preview url */
		previewUrl: PropTypes.string
	}),
	/** Function which will fire on remove button click */
	remove: PropTypes.shape({
		/** Remove Confirmation popup message */
		message: PropTypes.string,
		/** Remove handler */
		handler: PropTypes.func
	}),
	/** Files data of upload */
	data: PropTypes.object,
	/** Function which will build file url from response */
	fileBuilder: PropTypes.func,
	/** Files extension allowed for upload */
	extensions: PropTypes.arrayOf(PropTypes.string),
	/** Max file size allowed for upload */
	size: PropTypes.number,
	/** Should upload be disabled */
	disabled: PropTypes.bool,
	/** If true, the preview will not be available */
	disablePreview: PropTypes.bool,
	/** Fires on upload success */
	onSuccess: PropTypes.func,
	/** Array of props, which update will triger data load */
	updateProps: PropTypes.array,
	/** Is file uploaded */
	isLogoExists: PropTypes.bool
};

export default BannerUploadWithComfirm;
