import { Fragment } from "react";

import BetshopsComponent from "components/dashboard/retail/betshops/betshops.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const BetshopsRoute = () => {
	return (
		<Fragment>
			<BetshopsComponent />
		</Fragment>
	);
};

export default withPermission(BetshopsRoute, { resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
