import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Tabs } from "antd";

import { useTranslation } from "react-i18next";

import OnlineConfigsComponent from "./online-configs";
import OnlineRTPComponent from "./online-rtp";

import { hasPermission } from "utils/permissions";
import { updateLocationHash, getHashValue } from "utils/common";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

/** Online Tab Component */
const OnlineSettingsComponent = ({ onTabChange }) => {
	const { t } = useTranslation();

	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf OnlineSettingsComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	useEffect(() => {
		onTabChange(unsavedTabs.length > 0);
	}, [unsavedTabs]);

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf OnlineSettingsComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	return (
		<Tabs
			animated={false}
			className="sub-tabs"
			defaultActiveKey={getHashValue("subtab")}
			onChange={(key) => updateLocationHash("subtab=" + key, true)}
			items={[
				hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_ONLINE_CONFIGURATION, action: PERMISSION_ACTION.VIEW })
					? {
							key: "1",
							label: <span className={tabClassName("1")}>{t("pages.dashboard.partners.online_settings.tabs.online_configs")}</span>,
							children: <OnlineConfigsComponent onTabChange={(status) => changeTabSavedStatus(status, "1")} />
						}
					: null,
				hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_ONLINE_RTP, action: PERMISSION_ACTION.VIEW })
					? {
							key: "2",
							label: <span className={tabClassName("2")}>{t("pages.dashboard.partners.online_settings.tabs.rtp")}</span>,
							children: <OnlineRTPComponent onTabChange={(status) => changeTabSavedStatus(status, "2")} />
						}
					: null
			]}
		/>
	);
};

/** RetailSettingsComponent propTypes
 * PropTypes
 */
OnlineSettingsComponent.propTypes = {
	/** Fires when for any form in tab, saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

export default OnlineSettingsComponent;
