import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { Form, Row, Col, Button, Select, Input, Spin, Switch, DatePicker, Tag } from "antd";
const { Item: FormItem } = Form;

import AvatarUpload from "components/dashboard/common/avatarUpload";
import Question from "components/common/question";

import { saveAccessManagerGeneralInfo } from "store/actions/dashboard/userManagement/accessManagers/accessManagers.action";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import userGeneralInfoType from "types/user/generalInfo.type";

import { USER_GENDER } from "constants/user.constants";
import { TEL_REGEX } from "constants/regex.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

/** User Edit Page General Info Tab Component */
const GeneralInfoComponent = ({ saveAccessManagerGeneralInfo, isSaving, isLoading, generalInfo, userId, onTabChange }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [expirationEnabled, setExpirationEnabled] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			firstName: generalInfo.firstName,
			lastName: generalInfo.lastName,
			gender: generalInfo.gender,
			tel: generalInfo.tel,
			address: generalInfo.address,
			email: generalInfo.email,
			userName: generalInfo.userName,
			applyExpiration: generalInfo.applyExpiration,
			experationEnabled: generalInfo.experationEnabled,
			experationDate: generalInfo.experationDate ? moment.utc(generalInfo.experationDate).local() : "",
			allowEdit: generalInfo.allowEdit
		});
		setExpirationEnabled(generalInfo.experationEnabled);
	}, [generalInfo]);

	/** Fires when form submitted
	 * @function
	 * @param {boolean} forceSave - if true then data will be saved, without asking for confirmation
	 * @memberOf GeneralInfoComponent
	 */
	const handleForm = (forceSave) => {
		validateFields()
			.then((data) => {
				if (!generalInfo.experationEnabled || data.experationEnabled || forceSave) {
					saveAccessManagerGeneralInfo({
						...data,
						experationDate: data.experationDate ? moment(data.experationDate).set({ second: 0, millisecond: 0 }).toDate() : null,
						id: routeParams.id
					});
					setIsFormTouched(false);
					if (forceSave) {
						setShowConfirm(false);
					}
				} else {
					setShowConfirm(true);
				}
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	/** Function, returns if fields are editable
	 * @function
	 * @returns {boolean}
	 * @memberOf GeneralInfoComponent
	 */
	const canEdit = routeParams.id !== userId && hasPermission({ resource: PERMISSION_RESOURCE.ACCESS_MANAGER, action: PERMISSION_ACTION.MODIFY });

	return (
		<Form
			colon={false}
			form={formInstance}
			requiredMark={false}
			layout="vertical"
			initialValues={{
				firstName: generalInfo.firstName,
				lastName: generalInfo.lastName,
				gender: generalInfo.gender,
				tel: generalInfo.tel,
				address: generalInfo.address,
				email: generalInfo.email,
				userName: generalInfo.userName,
				applyExpiration: generalInfo.applyExpiration,
				experationEnabled: generalInfo.experationEnabled,
				experationDate: generalInfo.experationDate ? moment.utc(generalInfo.experationDate).local() : "",
				allowEdit: generalInfo.allowEdit
			}}
			onValuesChange={(changed, formValues) =>
				setIsFormTouched(
					isFormChanged(
						{ ...formValues },
						{
							firstName: generalInfo.firstName,
							lastName: generalInfo.lastName,
							gender: generalInfo.gender,
							tel: generalInfo.tel,
							address: generalInfo.address,
							applyExpiration: generalInfo.applyExpiration,
							experationEnabled: generalInfo.experationEnabled,
							experationDate: generalInfo.experationDate ? moment.utc(generalInfo.experationDate).local() : "",
							allowEdit: generalInfo.allowEdit
						}
					)
				)
			}
		>
			<div className="dashboard-section-content">
				<div className="avatar-upload-wrapper">
					<AvatarUpload
						userId={routeParams.id}
						name={`${generalInfo.firstName ?? ""} ${generalInfo.lastName ?? ""}`}
						avatarId={generalInfo.avatarId}
						role={generalInfo.role}
						canEdit={canEdit}
						isProfile={false}
					/>
					<div className="avatar-upload-form">
						<Spin spinning={isLoading} wrapperClassName="form-spin">
							<div className="dashboard-section-content">
								<div>
									<Row gutter={[16, 0]}>
										<Col xs={24} sm={24} xl={12} xxl={6}>
											<FormItem
												label={`${t("pages.dashboard.users.first_name")} *`}
												name="firstName"
												rules={[
													{ required: true, whitespace: true, message: t("validation.field_required") },
													{ max: 30, message: t("validation.field_invalid") },
													{ min: 2, message: t("validation.field_invalid") }
												]}
											>
												<Input minLength={2} maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.users.first_name")}`} disabled={!canEdit} />
											</FormItem>
										</Col>
										<Col xs={24} sm={24} xl={12} xxl={6}>
											<FormItem
												label={`${t("pages.dashboard.users.last_name")} *`}
												name="lastName"
												rules={[
													{ required: true, whitespace: true, message: t("validation.field_required") },
													{ max: 30, message: t("validation.field_invalid") },
													{ min: 2, message: t("validation.field_invalid") }
												]}
											>
												<Input minLength={2} maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.users.last_name")}`} disabled={!canEdit} />
											</FormItem>
										</Col>
										<Col xs={24} sm={24} xl={12} xxl={6}>
											<FormItem label={t("pages.dashboard.users.email")} name="email">
												<Input disabled={true} />
											</FormItem>
										</Col>
										<Col xs={24} sm={24} xl={12} xxl={6}>
											<FormItem label={t("pages.dashboard.users.username")} name="userName">
												<Input disabled={true} />
											</FormItem>
										</Col>
										<Col xs={24} sm={24} xl={12} xxl={6}>
											<FormItem label={t("pages.dashboard.users.gender")} name="gender">
												<Select disabled={!canEdit} suffixIcon={<i className="icon-down" />}>
													<Select.Option value={USER_GENDER.MALE}>{t("pages.dashboard.users.male")}</Select.Option>
													<Select.Option value={USER_GENDER.FEMALE}>{t("pages.dashboard.users.female")}</Select.Option>
													<Select.Option value={USER_GENDER.NONE}>{t("pages.dashboard.users.other")}</Select.Option>
												</Select>
											</FormItem>
										</Col>
										<Col xs={24} sm={24} xl={12} xxl={6}>
											<FormItem
												label={t("pages.dashboard.users.tel")}
												name="tel"
												rules={[
													{ pattern: TEL_REGEX, message: t("validation.tel_format") },
													{ max: 30, message: t("validation.field_invalid") }
												]}
											>
												<Input maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.users.tel")}`} disabled={!canEdit} />
											</FormItem>
										</Col>
										<Col xs={24} sm={24} xl={12} xxl={6}>
											<FormItem label={t("pages.dashboard.users.address")} name="address" rules={[{ max: 100, message: t("validation.field_invalid") }]}>
												<Input maxLength={100} placeholder={`${t("common.enter")} ${t("pages.dashboard.users.address")}`} disabled={!canEdit} />
											</FormItem>
										</Col>
										<Col xs={24} sm={24} xl={12} xxl={6} />
										<Col xs={24} sm={24} xl={12} xxl={6}>
											<div className="inline-form-item">
												<label>{t("pages.dashboard.users.apply_password_expiration")}</label>
												<FormItem className="inline-form-item-control" name="applyExpiration" valuePropName="checked">
													<Switch disabled={!canEdit} />
												</FormItem>
											</div>
										</Col>
										<Col xs={24} sm={24} xl={12} xxl={6}>
											<div className="inline-form-item">
												<label>{t("pages.dashboard.users.allow_edit")}</label>
												<FormItem className="inline-form-item-control" name="allowEdit" valuePropName="checked">
													<Switch disabled={!canEdit} />
												</FormItem>
											</div>
										</Col>
										<Col xs={24} sm={24} xl={12} xxl={6}>
											<div className="inline-form-item">
												<label>{t("pages.dashboard.users.expiration")}</label>
												<FormItem className="inline-form-item-control" name="experationEnabled" valuePropName="checked">
													<Switch onChange={(e) => setExpirationEnabled(e)} disabled={!canEdit} />
												</FormItem>
											</div>
										</Col>
										<Col xs={24} sm={24} xl={12} xxl={6} style={{ display: expirationEnabled ? "block" : "none" }}>
											<FormItem
												label={t("pages.dashboard.users.expiration_date")}
												name="experationDate"
												rules={[
													() => ({
														validator(rule, value) {
															if (!value && expirationEnabled) {
																return Promise.reject(t("validation.field_required"));
															}
															if (moment.utc(value) < moment.utc() && expirationEnabled) {
																return Promise.reject(t("validation.field_invalid"));
															}
															return Promise.resolve();
														}
													})
												]}
											>
												{!generalInfo.experationEnabled ? (
													<DatePicker
														format={`${DATE_FORMAT} ${TIME_FORMAT}`}
														showTime={{ format: TIME_FORMAT }}
														disabledDate={(d) => !d || d.isBefore(moment().set({ hour: 0, minute: 0 }))}
														showToday={false}
														allowClear={false}
														placeholder={t("common.select_date")}
														getPopupContainer={() => document.body}
														disabled={!canEdit}
													/>
												) : (
													<Tag className="form-control-tag">{generalInfo.experationDate ? moment.utc(generalInfo.experationDate).local().format(DATE_TIME_FORMAT) : null}</Tag>
												)}
											</FormItem>
										</Col>
									</Row>
								</div>
								{canEdit && (
									<FormItem className="button-container">
										<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={() => handleForm()} disabled={!isFormTouched}>
											<span>{t("common.save")}</span>
										</Button>
									</FormItem>
								)}
							</div>
						</Spin>
					</div>
				</div>
			</div>
			<Question type="confirm" onOk={() => handleForm(true)} onCancel={() => setShowConfirm(false)} isVisible={showConfirm} message={t("pages.dashboard.users.expiration_confirm_message")} />
		</Form>
	);
};

/** GeneralInfoComponent propTypes
 * PropTypes
 */
GeneralInfoComponent.propTypes = {
	/** Redux action to save access manager General info */
	saveAccessManagerGeneralInfo: PropTypes.func,
	/** Redux state property, is true when general info is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when general info is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the general info of current editing access manager */
	generalInfo: userGeneralInfoType,
	/** Redux state property, current user id */
	userId: PropTypes.string,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	saveAccessManagerGeneralInfo: (data) => {
		dispatch(saveAccessManagerGeneralInfo(data));
	}
});

const mapStateToProps = (state) => {
	return {
		generalInfo: state.accessManagers.editingAccessManager.generalInfo,
		isSaving: state.accessManagers.isSaving,
		isLoading: state.accessManagers.isLoading,
		userId: state.profile.userInfo.id
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent);
