import { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Tabs } from "antd";

import RulesTab from "components/dashboard/cms/rules/sections/rules";
import VersionHistoryTab from "components/dashboard/cms/rules/sections/versionHistory";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { hasOneOfPermissions } from "utils/permissions";
import { updateLocationHash, getHashValue } from "utils/common";
import { GAME_CATEGORY } from "constants/game.constants";

const getDefaultActiveKey = () => getHashValue("subtab") || "1";

/** Rules Page Component */
const Rules = ({ gameCategory }) => {
	const { t } = useTranslation();
	const { hash } = useLocation();

	const [activeKey, setActiveKey] = useState(getDefaultActiveKey);

	/** Update active subtab on location hash change */
	useEffect(() => {
		setActiveKey(getDefaultActiveKey());
	}, [hash]);

	return (
		<Fragment>
			<Tabs
				className="sub-tabs"
				animated={false}
				activeKey={activeKey}
				onChange={(key) => updateLocationHash("subtab=" + key, true)}
				destroyInactiveTabPane={true}
				items={[
					hasOneOfPermissions([
						{ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW },
						{ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.VIEW }
					])
						? {
								key: "1",
								label: t("pages.dashboard.translations.rules"),
								children: <RulesTab gameCategory={gameCategory} />
							}
						: null,
					hasOneOfPermissions([
						{ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW },
						{ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.VIEW }
					])
						? {
								key: "2",
								label: t("pages.dashboard.translations.version_history"),
								children: <VersionHistoryTab gameCategory={gameCategory} />
							}
						: null
				]}
			/>
		</Fragment>
	);
};

Rules.propTypes = {
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = null;

const mapStateToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(Rules);
