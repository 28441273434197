import PropTypes from "prop-types";

import { connect } from "react-redux";

import ApiUrls from "constants/api.constants";
import { PROJECT_TYPE } from "constants/common.constants";

import PendingBetsTableComponent from "../../common/pending/tableByBetSlip.component";
import NewPendingBetsTableComponent from "../../common/pending/tableByBet.component";

import { setPendingBetsSorting, setPendingBetsFilters, getPendingBets, cancelPendingBet, cancelPendingBetslip } from "store/actions/dashboard/betslips/pendings.action";

import betType from "types/bet/bet.type";
import sortingType from "types/common/sorting.type";

import { PERMISSION_RESOURCE } from "constants/permissions.constants";
import { GAME_CATEGORY } from "constants/game.constants";

/** Betslips Page Pending betslips Tab Retail SubTabe Page Component */
const RetailPendingBetsComponent = ({
	bets,
	sorting,
	filters,
	total,
	isLoading,
	getPendingBets,
	cancelPendingBet,
	cancelPendingBetslip,
	setPendingBetsSorting,
	setPendingBetsFilters,
	gameCategory,
	showBetsBySeparate
}) => {
	const Component = showBetsBySeparate ? NewPendingBetsTableComponent : PendingBetsTableComponent;
	const exportURL = showBetsBySeparate ? ApiUrls.EXPORT_RETAIL_BETS : ApiUrls.EXPORT_RETAIL_BETSLIPS;
	return (
		<Component
			bets={bets}
			sorting={sorting}
			filters={filters}
			total={total}
			isLoading={isLoading}
			projectType={PROJECT_TYPE.RETAIL}
			getPendingBets={getPendingBets}
			cancelPendingBet={cancelPendingBet}
			cancelPendingBetslip={cancelPendingBetslip}
			setPendingBetsSorting={setPendingBetsSorting}
			setPendingBetsFilters={setPendingBetsFilters}
			exportURL={exportURL + "?isPending=" + true}
			permission={PERMISSION_RESOURCE.BETSLIPS_RETAIL}
			gameCategory={gameCategory}
			showBetsBySeparate={showBetsBySeparate}
		/>
	);
};

/** RetailPendingBetsComponent propTypes
 * PropTypes
 */
RetailPendingBetsComponent.propTypes = {
	/** Redux state property, represents the array of retail pending betslips */
	bets: PropTypes.arrayOf(betType),
	/** Redux state property, retail pending betslips sorting details */
	sorting: sortingType,
	/** Redux state property, retail pending betslips filters */
	filters: PropTypes.object,
	/** Redux state property, retail pending betslips total count */
	total: PropTypes.number,
	/** Redux state property, is true when loading pending bets */
	isLoading: PropTypes.bool,
	/** Redux action to get pending bets */
	getPendingBets: PropTypes.func,
	/** Redux action to cancel pending bet */
	cancelPendingBet: PropTypes.func,
	/** Redux action to cancel pending bet slip */
	cancelPendingBetslip: PropTypes.func,
	/** Redux action to set pending bets sorting details */
	setPendingBetsSorting: PropTypes.func,
	/** Redux action to set pending bets filters */
	setPendingBetsFilters: PropTypes.func,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY)),
	showBetsBySeparate: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		bets: state.betslips.pendings.retail.bets,
		total: state.betslips.pendings.retail.total,
		sorting: state.betslips.pendings.retail.sorting,
		filters: state.betslips.pendings.retail.filters,
		isLoading: state.betslips.isLoading
	};
};

const mapDispatchToProps = (dispatch, { showBetsBySeparate = false }) => ({
	getPendingBets: (fromFirstPage, gameCategory) => {
		dispatch(getPendingBets(PROJECT_TYPE.RETAIL, fromFirstPage, gameCategory, showBetsBySeparate));
	},
	cancelPendingBet: ({ id, betId, reason, gameCategory }) => {
		dispatch(cancelPendingBet({ id, betId, reason, projectType: PROJECT_TYPE.RETAIL, gameCategory }));
	},
	cancelPendingBetslip: ({ id, reason, gameCategory, showBetsBySeparate }) => {
		dispatch(cancelPendingBetslip({ id, reason, projectType: PROJECT_TYPE.RETAIL, gameCategory, showBetsBySeparate }));
	},
	setPendingBetsSorting: (sorting) => {
		dispatch(setPendingBetsSorting(sorting, PROJECT_TYPE.RETAIL));
	},
	setPendingBetsFilters: (filters) => {
		dispatch(setPendingBetsFilters(filters, PROJECT_TYPE.RETAIL));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(RetailPendingBetsComponent);
