import { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Tabs, Switch } from "antd";
import PartnersRevenue from "./charts/partnersRevenue";

import OnlineDashboardComponent from "./sections/online";
import RetailDashboardComponent from "./sections/retail";
import Breadcrumbs from "components/common/breadcrumbs";

import { binaryToFlags } from "utils/common";
import { getHashValue, clearLocationHash, updateLocationHash } from "utils/common";
import localStorageUtils from "utils/localStorage";

import { PARTNER_TYPE } from "constants/partner.constants";
import { PROJECT_TYPE } from "constants/common.constants";

import partnerType from "types/partner/partner.type";
import { hasPermission } from "utils/permissions";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

const ALL_PARTNERS = "ALL_PARTNERS";

/** Statistics Page Component */
const StatisticsComponent = ({ globalPartnerId, globalPartners }) => {
	const { hash } = useLocation();
	const { t } = useTranslation();

	const globalPartner = globalPartners.find((p) => p.id === globalPartnerId);

	/** Is retail type partner
	 * @function
	 * @returns {boolean}
	 * @memberOf EventsComponent
	 */
	const isRetail = () =>
		binaryToFlags(
			Object.values(PARTNER_TYPE),
			globalPartner?.type
		).includes(PARTNER_TYPE.RETAIL);

	/** Is online type partner
	 * @function
	 * @returns {boolean}
	 * @memberOf EventsComponent
	 */
	const isOnline = () =>
		binaryToFlags(
			Object.values(PARTNER_TYPE),
			globalPartner?.type
		).includes(PARTNER_TYPE.ONLINE);

	const [activeKey, setActiveKey] = useState(
		getHashValue("tab") ||
			(isOnline() && hasPermission({ resource: PERMISSION_RESOURCE.DASHBOARD_ONLINE, action: PERMISSION_ACTION.VIEW })
				? PROJECT_TYPE.ONLINE.toString()
				: isRetail() && hasPermission({ resource: PERMISSION_RESOURCE.DASHBOARD_RETAIL, action: PERMISSION_ACTION.VIEW })
					? PROJECT_TYPE.RETAIL.toString()
					: null)
	);
	const [appPartners, setAllPartners] = useState(false);

	useEffect(() => {
		if (localStorageUtils.get(ALL_PARTNERS)) {
			setAllPartners(true);
		}
	}, []);

	/** Update active tab on location hash change */
	useEffect(() => {
		if (globalPartners.length > 0) {
			setActiveKey(
				getHashValue("tab") ||
					(isOnline() && hasPermission({ resource: PERMISSION_RESOURCE.DASHBOARD_ONLINE, action: PERMISSION_ACTION.VIEW })
						? PROJECT_TYPE.ONLINE.toString()
						: isRetail() && hasPermission({ resource: PERMISSION_RESOURCE.DASHBOARD_RETAIL, action: PERMISSION_ACTION.VIEW })
							? PROJECT_TYPE.RETAIL.toString()
							: null)
			);
		}
	}, [hash, globalPartners]);

	useEffect(() => {
		if (globalPartners.length > 0) {
			if (activeKey === PROJECT_TYPE.ONLINE.toString()) {
				if (!isOnline()) {
					setActiveKey(PROJECT_TYPE.RETAIL.toString());
					clearLocationHash();
				}
			} else if (activeKey === PROJECT_TYPE.RETAIL.toString()) {
				if (!isRetail()) {
					setActiveKey(PROJECT_TYPE.ONLINE.toString());
					clearLocationHash();
				}
			}
		}
	}, [globalPartnerId, globalPartners]);

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.dashboard"), path: null }]} />
				<Tabs
					animated={false}
					activeKey={activeKey}
					onChange={(key) => {
						updateLocationHash("tab=" + key);
						setActiveKey(key);
					}}
					tabBarExtraContent={
						<label style={{ marginRight: "1rem", cursor: "pointer" }}>
							<span style={{ marginRight: "0.75rem" }}>{t("pages.dashboard.dashboard.tabs.partners_revenue")}</span>
							<Switch
								checked={appPartners}
								onChange={(value) => {
									setAllPartners(value);
									localStorageUtils.set(ALL_PARTNERS, value);
								}}
							/>
						</label>
					}
					items={[
						hasPermission({ resource: PERMISSION_RESOURCE.DASHBOARD_ONLINE, action: PERMISSION_ACTION.VIEW }) && isOnline()
							? {
									key: PROJECT_TYPE.ONLINE.toString(),
									label: t("pages.dashboard.dashboard.tabs.online"),
									children: !appPartners ? <OnlineDashboardComponent activeKey={activeKey} /> : <PartnersRevenue projectType={PROJECT_TYPE.ONLINE} />
								}
							: null,
						hasPermission({ resource: PERMISSION_RESOURCE.DASHBOARD_RETAIL, action: PERMISSION_ACTION.VIEW }) && isRetail()
							? {
									key: PROJECT_TYPE.RETAIL.toString(),
									label: t("pages.dashboard.dashboard.tabs.retail"),
									children: !appPartners ? <RetailDashboardComponent activeKey={activeKey} /> : <PartnersRevenue projectType={PROJECT_TYPE.RETAIL} />
								}
							: null
					]}
				/>
			</div>
		</Fragment>
	);
};

/** StatisticsComponent propTypes
 * PropTypes
 */
StatisticsComponent.propTypes = {
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, represents the array of global partners  */
	globalPartners: PropTypes.arrayOf(partnerType)
};

const mapStateToProps = (state) => {
	return {
		globalPartnerId: state.partner.globalPartnerId,
		globalPartners: state.partner.globalPartners
	};
};

export default connect(mapStateToProps, null)(StatisticsComponent);
