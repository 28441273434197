import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getBetshopPerformanceReport, setBetshopPerformanceReportFilters } from "store/actions/dashboard/reports/betshopPerformance/betshopPerformance.action";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import Table from "components/common/table";
import Breadcrumbs from "components/common/breadcrumbs";
import Filters from "./filters.component";
import ExportButton from "components/common/exportButton";
import { convertCurrencies, getPartnerCurrency, getPartnerCurrencyDecimalCount, canConvertCurrencies } from "utils/currency";
import { numberWithSpaces } from "utils/common";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import Paths from "constants/path.constants";
import ApiUrls from "constants/api.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import projectPerformanceReportType from "types/reports/projectPerformanceReport.type";
import partnerType from "types/partner/partner.type";
import currencyType from "types/common/currency.type";

/** Betshop Performance Report Page Component */

const BetshopPerformanceReportComponent = ({ getBetshopPerformanceReport, betshopPerformanceReport, setBetshopPerformanceReportFilters, isLoading, filters, globalPartnerId, globalPartners, currency, currencies }) => {
	const { t } = useTranslation();

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.reports.partner"),
			dataIndex: "totalCol",
			render: (value, record) =>
				value === "total"
					? t("pages.dashboard.reports.betshop_total")
					: (globalPartners.find((p) => p.id === globalPartnerId))?.name,
			sorter: false
		},
		{
			title: t("pages.dashboard.reports.betshop"),
			dataIndex: "name",
			sorter: false
		},
		{
			title: t("pages.dashboard.reports.from"),
			dataIndex: "from",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : ""),
			sorter: false
		},
		{
			title: t("pages.dashboard.reports.to"),
			dataIndex: "to",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : ""),
			sorter: false
		},
		{
			title: t("pages.dashboard.reports.currency"),
			dataIndex: "currencyCode",
			sorter: false
		},
		{
			title: t("pages.dashboard.reports.ggr") + "%",
			dataIndex: "ggrPercent",
			sorter: false
		},
		{
			title: t("pages.dashboard.reports.ggr"),
			dataIndex: "ggr",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.turnover"),
			dataIndex: "turnoverAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.win_amount"),
			dataIndex: "wonAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.cancelled_amount"),
			dataIndex: "canceledAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.betslips"),
			dataIndex: "betCount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.betslips_won"),
			dataIndex: "wonCount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.betslips_cancelled"),
			dataIndex: "canceledCount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.bonus_bet_amount"),
			dataIndex: "bonusTurnoverAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.bonus_bet_count"),
			dataIndex: "bonusBetCount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.bonus_ggr"),
			dataIndex: "bonusGGR",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.bonus_ggr") + "%",
			dataIndex: "bonusGGRPercent",
			sorter: false
		},
		{
			title: t("pages.dashboard.reports.bonus_win"),
			dataIndex: "bonusWonAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.bonus_redeem_amount"),
			dataIndex: "bonusRedeemAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},

		{
			title: t("pages.dashboard.reports.totalJackpotWonCount"),
			dataIndex: "totalJackpotWonCount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.totalJackpotWonAmount"),
			dataIndex: "totalJackpotWonAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.totalJackpotPayoutAmount"),
			dataIndex: "totalJackpotPayoutAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		}
	];

	const calculateColumnTotalAndConvertCurrency = (fieldName) => {
		return (
			betshopPerformanceReport
				.map((p) => Number(convertCurrencies(p[fieldName], p.currencyCode, currency.code, currencies, globalPartnerId)))
				.reduce((a, b) => a + b, 0)
				.toFixed(getPartnerCurrencyDecimalCount(currency.code, currencies, globalPartnerId))
		)
	}

	const calculateColumnTotal = (fieldName) => {
		return (
			betshopPerformanceReport.map((p) => Number(p[fieldName])).reduce((a, b) => a + b, 0)
		)
	}

	/** Get total values of each column in table
	 * @function
	 * @memberOf BetshopPerformanceReportComponent
	 * @returns {object} - total values
	 */
	const getTotalData = () => {
		if (betshopPerformanceReport.length < 2) return null;

		const totalData = {};
		["from", "to", "name", "ggrPercent", "bonusGGRPercent"].forEach(key => {
			totalData[key] = ""
		})
		totalData["totalCol"] = "total";
		totalData["currencyCode"] = getPartnerCurrency(currency.code, currencies, globalPartnerId);

		const canCountTotal = betshopPerformanceReport.every((p) => {
			return canConvertCurrencies(p.currencyCode, currency.code, currencies, globalPartnerId)
		});

		const fieldForCalculateColumnTotalAndConvertCurrency = [
			"ggr", "turnoverAmount", "canceledAmount", "wonAmount",
			"bonusWonAmount", "bonusGGR", "bonusRedeemAmount", "bonusTurnoverAmount",
			"totalJackpotWonCount", "totalJackpotWonAmount", "totalJackpotPayoutAmount"
		];

		const fieldForCalculateColumnTotal = [
			"betCount", "wonCount", "canceledCount", "bonusBetCount"
		];

		if (canCountTotal) {

			fieldForCalculateColumnTotalAndConvertCurrency.forEach(key => {
				totalData[key] = calculateColumnTotalAndConvertCurrency(key);
			})

		} else {
			fieldForCalculateColumnTotalAndConvertCurrency.forEach(key => {
				totalData[key] = "";
			})
		}

		fieldForCalculateColumnTotal.forEach(key => {
			totalData[key] = calculateColumnTotal(key);
		})

		return totalData;
	};

	return (
		<div className="dashboard-section">
			<Breadcrumbs items={[{ title: t("pages.dashboard.menu.betshops_performance") }]} />
			<div className="dashboard-section-content">
				<div className="table-header">
					<div className="table-buttons-dropdowns">
						{hasPermission({ resource: PERMISSION_RESOURCE.REPORTS_BETSHOP_PERFORMANCE, action: PERMISSION_ACTION.EXPORT }) ? (
							<ExportButton
								columns={columns.map((c) => ({
									title: c.title,
									key: c.dataIndex
								}))}
								tableName={t("pages.dashboard.menu.betshops_performance")}
								url={`${ApiUrls.EXPORT_REPORT_BETSHOP_PERFORMANCE}?projectType=${PROJECT_TYPE.RETAIL}`}
								filters={filters}
							/>
						) : null}
					</div>
					<Filters />
				</div>
				<div className="table-info">
					<i className="icon-info" />
					<span>Currencies are converted based on the latest rate of the month, so please consider fluctuation in case other rates are applied for Requested Reports by Support.</span>
				</div>
				<Table
					loading={isLoading}
					columns={columns}
					data={betshopPerformanceReport.concat(getTotalData() ? [getTotalData()] : [])}
					loadFn={getBetshopPerformanceReport}
					filters={filters}
					setFiltersFn={setBetshopPerformanceReportFilters}
					noPagination={true}
					updateProps={[globalPartnerId]}
					actions={{}}
					isDisabled={() => false}
					uniqueKey="currencyCode"
					enableReload={true}
				/>
			</div>
		</div>
	);
};

/** BetshopPerformanceReportComponent propTypes
 * PropTypes
 */
BetshopPerformanceReportComponent.propTypes = {
	/** Redux action to get betshop performance report */
	getBetshopPerformanceReport: PropTypes.func,
	/** Redux state property, represents the betshop performance report */
	betshopPerformanceReport: PropTypes.arrayOf(projectPerformanceReportType),
	/** Redux action to set betshop performance report filters */
	setBetshopPerformanceReportFilters: PropTypes.func,
	/** Redux state property, betshop performance report filters */
	filters: PropTypes.object,
	/** Redux state property, is true when loading betshop performance report */
	isLoading: PropTypes.bool,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, represents the array of global partners  */
	globalPartners: PropTypes.arrayOf(partnerType),
	/** Redux state property, the current user currency */
	currency: currencyType,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType)
};

const mapDispatchToProps = (dispatch) => ({
	getBetshopPerformanceReport: () => {
		dispatch(getBetshopPerformanceReport());
	},

	setBetshopPerformanceReportFilters: (filters) => {
		dispatch(setBetshopPerformanceReportFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.reports.betshopPerformance.isLoading,
		betshopPerformanceReport: state.reports.betshopPerformance.betshopPerformanceReport,
		filters: state.reports.betshopPerformance.filters,
		globalPartnerId: state.partner.globalPartnerId,
		globalPartners: state.partner.globalPartners,
		currencies: state.profile.userInfo.currencies,
		currency: state.profile.userInfo.currency
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BetshopPerformanceReportComponent);
