import { Fragment } from "react";

import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import BigMultipliers from "components/dashboard/riskManagement/bigMultipliers";

const BigMultipliersRouteComponent = () => {
	return (
		<Fragment>
			<BigMultipliers />
		</Fragment>
	);
};


export default withPermission(
	BigMultipliersRouteComponent,
	[
		{ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BIG_MULTIPLIERS_CONFIGURATIONS, action: PERMISSION_ACTION.VIEW },
		{ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BIG_MULTIPLIERS_HISTORY, action: PERMISSION_ACTION.VIEW }
	],
	Paths.DASHBOARD
)
