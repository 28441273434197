import { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Row, Col, Spin, Popover } from 'antd';

import ApiUrls from 'constants/api.constants';

import BannerAdjustmentUploadComponent from 'components/dashboard/common/bannerUpload/bannerAdjustmentUploadComponent';

import { getBanners, deleteBanner, resetBanners, updateBanner } from 'store/actions/dashboard/virtuals/games/banners.action';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { hasPermission } from 'utils/permissions';
import PreviewSteeplechasingBanners from './previewSteeplechasingBanners';
import { SCHEDULED_GAME_TYPE } from 'constants/game.constants';
import { FILE_SIZES_MEASUREMENT } from 'constants/common.constants';

const allowedDeviationPercent = 0.2005;

/** Game Edit Page Track Banners Tab Component */
const SteeplechasingBannersAdjustment = ({
	getBanners,
	deleteBanner,
	resetBanners,
	banners,
	isLoading,
	partnerId,
	updateBanner
}) => {

	const routeParams = useParams();
	const { t } = useTranslation();

	/** Load game general info */
	useEffect(() => {
		if (routeParams.id) {
			getBanners(routeParams.id);
		}
		return () => resetBanners();
	}, []);


	const validations = {
		trackBanner: {
			extensions: ["image/png", "image/jpg", "image/jpeg", "video/mp4"],
			aspectRatio: 1200 / 200,
			allowedDeviationPercent
		},
		fanceBanner: {
			extensions: ["image/png", "image/jpg", "image/jpeg"],
			aspectRatio: 3000 / 100,
			allowedDeviationPercent
		},
		billboardBigLedBanner: {
			extensions: ["image/png", "image/jpg", "image/jpeg", "video/mp4"],
			aspectRatio: 1100 / 500,
			allowedDeviationPercent
		},
		truckScreenBanner: {
			extensions: ["image/png", "image/jpg", "image/jpeg", "video/mp4"],
			aspectRatio: 980 / 640,
			allowedDeviationPercent
		},
		truckContainerBanner: {
			extensions: ["image/png", "image/jpg", "image/jpeg", "video/mp4"],
			aspectRatio: 960 / 240,
			allowedDeviationPercent
		},
		onGroundBanner: {
			extensions: ["image/png", "image/jpg", "image/jpeg"],
			aspectRatio: 800 / 100,
			allowedDeviationPercent
		},
		billboardBigBanner: {
			extensions: ["image/png", "image/jpg", "image/jpeg"],
			aspectRatio: 1800 / 600,
			allowedDeviationPercent
		}
	}

	const makeBannerUrl = (file, banner, index) => {
		if (["mp4", "webm"].includes(file.format)) {
			return `${import.meta.env.SYSTEM_CDN_URL}/system/images/banner_thumbnail.png`
		}

		const partner = partnerId ? partnerId : "system";
		const path = `/games/${routeParams.id}/${banner}_${index}_${file.id}_small.${file.format}`.toLowerCase();
		return `${import.meta.env.SYSTEM_CDN_URL}/${partner}${path}`
	}

	const makePreviewUrl = (file, banner, index) => {
		const partner = partnerId ? partnerId : "system";
		const path = `/games/${routeParams.id}/${banner}_${index}_${file.id}_large.${file.format}`.toLowerCase();
		return `${import.meta.env.SYSTEM_CDN_URL}/${partner}${path}`;
	}

	const drawMap = [
		["trackBanner", "fanceBanner"],
		["billboardBigLedBanner", "truckScreenBanner"],
		["truckContainerBanner", "onGroundBanner"],
		["billboardBigBanner"]
	];

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<div className="dashboard-section-content vs--banner-adjustment">
				<div>
					{t("pages.dashboard.games.banners.selectedImagesAreDisplayedInARandomOrderDuringRaces")}
				</div>
				{
					drawMap.map((drawRow, i) => {
						return (
							<Row
								key={drawRow.join("-") + i}
								wrap={false}
								gutter={[16, 0]}
								style={{ columnGap: 16 }}
							>
								{
									drawRow.map((banner, j) => {
										if (!banners[banner]) {
											return <Fragment key={banner + j} />
										}
										return (
											<Row
												key={banner + j}
												className='vs--banner-adjustment-element'
											>
												<Col
													xs={24}
													sm={24}
													className='vs--banner-adjustment-element-header'
												>
													<div className='vs--flex vs--flex-row'>
														<div className='vs--flex-grow'>
															<h3 className="dashboard-section-title">{t(`pages.dashboard.games.banners.${banner}_text`)}</h3>
															<p className="dashboard-section-subtitle">{t(`pages.dashboard.games.banners.${banner}_desc`)}</p>
														</div>
														<div className="vs--pt-6">
															<Popover
																title={
																	<span className='vs--font-smallest vs--font-medium'>
																		{t("common.imagePreview")}
																	</span>
																}
																content={
																	<PreviewSteeplechasingBanners
																		gameType={SCHEDULED_GAME_TYPE.STEEPLECHASING.value}
																		gameId={routeParams.id}
																		partnerId={partnerId}
																		bannersGroup={banner}
																		banners={banners}
																		bannerList={banners[banner]}
																	/>
																}
																placement='bottomRight'
																showArrow={false}
															>
																<span className='vs--banner-adjustment-preview-icon vs--font-smallest vs--font-medium vs--flex-inline vs--justify-start vs--align-center'>
																	<i className='icon-eye vs--font-bigest' />
																	&nbsp;
																	&nbsp;
																	{t("common.preview")}
																</span>
															</Popover>
														</div>
													</div>
												</Col>
												<Row
													gutter={[16, 0]}
													className='vs--banner-adjustment-element-body'
												>
													{
														banners[banner].map((b, index, arr) => {
															return (
																<Col
																	key={index}
																	xs={24}
																	sm={12}
																	md={6}
																	className='vs--banner-adjustment-upload-container'
																	ref={(node) => {
																		node?.style.setProperty("max-width", (100 / arr.length).toFixed(2) + "%", "important")
																	}}
																>
																	<BannerAdjustmentUploadComponent
																		onSuccess={value => {
																			updateBanner(banner, value, index)
																		}}
																		uploadUrl={`${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_UPLOAD_BANNER}`}
																		defaultFile={
																			(b && b.id)
																				? {
																					name: banner + b.format,
																					dimensions: b.dimension,
																					previewUrl: makePreviewUrl(b, banner, index),
																					url: makeBannerUrl(b, banner, index),
																					status: "done",
																					percent: 100,
																					loaded: b.size,
																					total: b.size,
																					format: b.format
																				}
																				: null
																		}
																		remove={
																			hasPermission({ resource: PERMISSION_RESOURCE.GAME_TRACK_BRANDING, action: PERMISSION_ACTION.DELETE })
																				? {
																					handler: () => deleteBanner(routeParams.id, index + 1, banner),
																					message: t("pages.dashboard.games.banners.remove_confirmation_message")
																				} : null
																		}
																		data={{
																			gameId: routeParams.id,
																			bannerNumber: index + 1,
																			type: banner
																		}}
																		fileBuilder={value => ({
																			url: makeBannerUrl(value, banner, index),
																			previewUrl: makePreviewUrl(value, banner, index),
																			dimensions: value.dimension,
																			format: value.format
																		})}
																		extensions={validations[banner]?.extensions ?? []}
																		size={2 * FILE_SIZES_MEASUREMENT.MB}
																		allowedDeviationPercent={validations[banner]?.allowedDeviationPercent ?? 0}
																		aspectRatio={validations[banner]?.aspectRatio ?? 16 / 9}
																		disabled={!hasPermission({ resource: PERMISSION_RESOURCE.GAME_TRACK_BRANDING, action: PERMISSION_ACTION.MODIFY })}
																	/>
																</Col>
															)
														})
													}
												</Row>
											</Row>
										)
									})
								}
							</Row>
						)
					})
				}
			</div>
		</Spin>
	)
}

/** SteeplechasingBannersAdjustment propTypes
	 * PropTypes
*/
SteeplechasingBannersAdjustment.propTypes = {
	/** Redux action to get game banners */
	getBanners: PropTypes.func,
	/** Redux action to delete game banners */
	deleteBanner: PropTypes.func,
	/** Redux action to reset banners */
	resetBanners: PropTypes.func,
	/** Redux state property, is true when banners is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the array of banners of current editing game  */
	banners: PropTypes.object,
	/** Redux state property, represents game partner id */
	partnerId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
	{
		getBanners: id => {
			dispatch(getBanners(id));
		},
		deleteBanner: (id, bannerNumber, type) => {
			dispatch(deleteBanner(id, bannerNumber, type));
		},
		resetBanners: () => {
			dispatch(resetBanners());
		},
		updateBanner: (name, banner, index) => {
			dispatch(updateBanner(name, banner, index))
		}
	}
)

const mapStateToProps = state => {
	return {
		banners: state.games.editingGame.banners,
		isLoading: state.games.isLoading,
		partnerId: state.games.editingGame.generalInfo.partnerId
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(SteeplechasingBannersAdjustment)
