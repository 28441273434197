import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { setSurveySaveBefore, setSurveySaveFinished, setSurveysActionBefore, setSurveysActionFinished } from "store/actions/dashboard/usersFeedback/CSAT/surveys.action";
import { SET_SURVEY_GENERAL_INFO } from "store/actionTypes";

const setSurveyGeneralInfo = (info) => ({
	type: SET_SURVEY_GENERAL_INFO,
	payload: { info }
});

export const getSurveyGeneralInfo = (surveyId) => {
	return (dispatch) => {
		dispatch(setSurveysActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SURVEY_GENERAL_INFO}`,
			method: Methods.GET,
			params: { id: surveyId }
		})
			.then(({ data: { value: info } }) => {
				dispatch(setSurveyGeneralInfo(info));
				dispatch(setSurveysActionFinished());
			})
			.catch((ex) => {
				dispatch(setSurveysActionFinished());
			});
	};
};

export const saveSurveyGeneralInfo = (info) => {
	return (dispatch) => {
		dispatch(setSurveySaveBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_SURVEY_GENERAL_INFO}`,
			method: Methods.POST,
			data: {
				...info
			}
		})
			.then(({ data: { message: msg, status, value: info } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setSurveyGeneralInfo(info));
				}
				dispatch(setSurveySaveFinished());
			})
			.catch((ex) => {
				dispatch(setSurveySaveFinished());
			});
	};
};
