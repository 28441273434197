import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { setUsersActionBefore, setUsersActionFinished, setUserSaveActionBefore, setUserSaveActionFinished } from "./users.action";

import { SET_USER_GENERAL_INFO } from "store/actionTypes";

const setUserGeneralInfo = (info) => ({
	type: SET_USER_GENERAL_INFO,
	payload: { info }
});

export const getUserGeneralInfo = (id) => {
	return (dispatch) => {
		dispatch(setUsersActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_GET_GENERAL_INFO}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: info } }) => {
				dispatch(setUserGeneralInfo(info));
				dispatch(setUsersActionFinished());
			})
			.catch((ex) => {
				dispatch(setUsersActionFinished());
			});
	};
};

export const saveUserGeneralInfo = (info) => {
	return (dispatch) => {
		dispatch(setUserSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_SAVE_GENERAL_INFO}`,
			method: Methods.POST,
			data: {
				...info
			}
		})
			.then(({ data: { message: msg, status, value: info } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setUserGeneralInfo(info));
				}
				dispatch(setUserSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setUserSaveActionFinished());
			});
	};
};
