import PropTypes from "prop-types";
import { PENALTY_PROBABILITIES } from "constants/game.constants";

const TYPES = { GOAL: [], NOGOAL: [] };
Object.entries(PENALTY_PROBABILITIES).forEach(([key, innerObjec]) => {
	const sortKey = key.includes("NOGOAL") ? "NOGOAL" : "GOAL";
	TYPES[sortKey].push(innerObjec.value);
});

export default PropTypes.shape({
	goalScenes: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.oneOf(TYPES.GOAL),
			percent: PropTypes.number
		})
	),
	noGoalScenes: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.oneOf(TYPES.NOGOAL),
			percent: PropTypes.number
		})
	)
});
