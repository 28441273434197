import PropTypes from "prop-types";
import topPlayerType from "types/player/topPlayer.type";
import sortingType from "types/common/sorting.type";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import Table from "components/common/table";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import { hasPermission } from "utils/permissions";

import { getDashboardTopPlayers, setDashboardTopPlayersSorting } from "store/actions/dashboard/dashboard/dashboard.action";

import { numberWithSpaces } from "utils/common";

/** Events Page Component */
const EventsComponent = ({ getDashboardTopPlayers, isLoading, data, sorting, filters, total, globalPartnerId }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.bets.username"),
			dataIndex: "userName",
			sorter: false
		},
		{
			title: t("pages.dashboard.bets.project"),
			dataIndex: "projectName",
			sorter: false
		},
		{
			title: t("pages.dashboard.bets.bets"),
			dataIndex: "betsCount",
			sorter: false
		},
		{
			title: t("pages.dashboard.bets.currency"),
			dataIndex: "currencyCode",
			sorter: false
		},
		{
			title: t("pages.dashboard.players.turnover"),
			dataIndex: "turnover",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.session.report.ggr") + " %",
			dataIndex: "ggrPercent",
			sorter: false,
			render: (v) => v + " %"
		}
	];

	return (
		<Table
			loading={isLoading}
			columns={columns}
			data={data}
			loadFn={() => getDashboardTopPlayers(filters.projectId, filters.period, PROJECT_TYPE.ONLINE)}
			sorting={sorting}
			setSortingFn={setDashboardTopPlayersSorting}
			total={total}
			updateProps={[globalPartnerId]}
			actions={
				hasPermission({
					resource: PERMISSION_RESOURCE.DASHBOARD_ONLINE,
					action: PERMISSION_ACTION.VIEW
				})
					? {
							view: {
								handler: (record) => navigate(Paths.DASHBOARD_ONLINE_PLAYERS_VIEW.replace(DYNAMIC_PATH_ID_REGEX, record.playerId))
							}
						}
					: {}
			}
			isDisabled={() => false}
			uniqueKey="userName"
		/>
	);
};

/** EventsComponent propTypes
 * PropTypes
 */
EventsComponent.propTypes = {
	/** Redux action to get events */
	getDashboardTopPlayers: PropTypes.func,
	/** Redux action to set sorting */
	setDashboardTopPlayersSorting: PropTypes.func,
	/** Redux state property, is true when loading Players */
	isLoading: PropTypes.bool,
	/** The array of data */
	data: PropTypes.arrayOf(topPlayerType),
	/** Event sorting details */
	sorting: sortingType,
	/** Redux state property, dashboard filters*/
	filters: PropTypes.object,
	/** Players total count */
	total: PropTypes.number,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getDashboardTopPlayers: (projectId, period, projectType) => {
		dispatch(getDashboardTopPlayers(projectId, period, projectType));
	},
	setDashboardTopPlayersSorting: (sorting) => {
		dispatch(setDashboardTopPlayersSorting(sorting));
	}
});

const mapStateToProps = (state) => {
	return {
		data: state.dashboard.online.topPlayers.data,
		total: state.dashboard.online.topPlayers.total,
		sorting: state.dashboard.online.topPlayers.sorting,
		filters: state.dashboard.online.filters,
		isLoading: state.dashboard.online.topPlayers.isLoading,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsComponent);
