import { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Form, Input, Button, Result } from "antd";
const { Item: FormItem } = Form;

import Paths from "constants/path.constants";

import PasswordRules from "components/common/passwordRules";

import { getPasswordSettings } from "store/actions/auth/passwordSettings.action";
import { resetPassword, setPassword } from "store/actions/auth/resetPassword.action";

import { validatePasword } from "utils/password";

import passwordSettingsType from "types/partner/passwordSettings.type";

/** Reset Password Page Component */
const ResetPasswordComponent = ({ resetPassword, setPassword, getPasswordSettings, passwordSettings, isLoading, isReseting }) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, getFieldValue } = formInstance;

	const token = searchParams.get("t");
	const type = searchParams.get("type") || "forgotPassword";
	const arePasswordRenderingRulesNeeded = !isLoading && Object.keys(passwordSettings).length > 0;

	/** Load password settings */
	useEffect(() => {
		if (token) {
			getPasswordSettings(token, type);
		}
	}, [token, type]);

	/** Fires when form submitted
	 * @function
	 * @memberOf ResetPasswordComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ newPassword, confirmPassword }) => {
				if (type === "forgotPassword") {
					return resetPassword(token, newPassword, confirmPassword, () => navigate(Paths.LOGIN, { replace: true }));
				}
				setPassword(token, newPassword, confirmPassword, () => navigate(Paths.LOGIN, { replace: true }));
			})
			.catch(Function.prototype);
	};

	if (!token) {
		return <Result status="warning" title={t("pages.auth.no_token_found")} />;
	}

	return (
		<div className="vs--main-login">
			<Form
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				colon={false}
				initialValues={{
					newPassword: "",
					confirmPassword: ""
				}}
			>
				<h1 className="title">{t("pages.auth.reset_password")}</h1>
				{arePasswordRenderingRulesNeeded ? (
					<FormItem>
						<PasswordRules passwordSettings={passwordSettings} />
					</FormItem>
				) : null}
				<FormItem
					label={`${t("pages.auth.password")} *`}
					name="newPassword"
					validateFirst
					rules={[
						{ required: true, whitespace: true, message: t("validation.field_required") },
						() => ({
							validator(rule, value) {
								return validatePasword(value, passwordSettings);
							}
						})
					]}
				>
					<Input.Password
						placeholder={`${t("common.enter")} ${t("pages.auth.password")}`}
						onChange={() => {
							setTimeout(() => {
								if (getFieldValue("confirmPassword") !== "") {
									validateFields(["confirmPassword"]);
								}
							}, 0);
						}}
					/>
				</FormItem>
				<FormItem
					label={`${t("pages.auth.confirm_password")} *`}
					name="confirmPassword"
					validateFirst
					rules={[
						{ required: true, whitespace: true, message: t("validation.field_required") },
						({ getFieldValue }) => ({
							validator(rule, value) {
								if (value !== getFieldValue("newPassword")) {
									return Promise.reject(t("validation.passwords_do_not_match"));
								}
								return Promise.resolve();
							}
						})
					]}
				>
					<Input.Password placeholder={`${t("pages.auth.confirm_password")}`} onPaste={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()} />
				</FormItem>
				<FormItem>
					<Button loading={isReseting} type="primary" htmlType="submit" className="button" onClick={handleForm}>
						<span>{t("pages.auth.reset")}</span>
					</Button>
				</FormItem>
			</Form>
		</div>
	);
};

/** ResetPasswordComponent propTypes
 * PropTypes
 */
ResetPasswordComponent.propTypes = {
	/** Redux state property, is true when reset password request is in process */
	isReseting: PropTypes.bool,
	/** Redux state property, is true when loading password settings */
	isLoading: PropTypes.bool,
	/** Redux action to get password settings */
	getPasswordSettings: PropTypes.func,
	/** Redux action to reset password */
	resetPassword: PropTypes.func,
	/** Redux action to set password */
	setPassword: PropTypes.func,
	/** Redux state property, the password settings */
	passwordSettings: passwordSettingsType
};

const mapDispatchToProps = (dispatch) => ({
	getPasswordSettings: (token, type) => {
		dispatch(getPasswordSettings(token, type));
	},
	resetPassword: (token, newPassword, confirmPassword, onSuccess) => {
		dispatch(resetPassword(token, newPassword, confirmPassword, onSuccess));
	},
	setPassword: (token, newPassword, confirmPassword, onSuccess) => {
		dispatch(setPassword(token, newPassword, confirmPassword, onSuccess));
	}
});

const mapStateToProps = (state) => {
	return {
		isReseting: state.auth.resetPassword.isReseting,
		isLoading: state.auth.passwordSettings.isLoading,
		passwordSettings: state.auth.passwordSettings.passwordSettings
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordComponent);
