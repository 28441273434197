import { Fragment } from "react";

import BonusesEditComponent from 'components/dashboard/bonuses/standard/edit/bonus-edit.component';
import withPermission from 'hocs/withPermission';

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const BonusJackpotEditRoute = () => {
	return (
		<Fragment>
			<BonusesEditComponent />
		</Fragment>
	);
};


export default withPermission(
	BonusJackpotEditRoute,
	{ resource: PERMISSION_RESOURCE.BONUS_STANDARD, action: PERMISSION_ACTION.VIEW },
	Paths.DASHBOARD
);
