import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Col, Row, Modal, Select } from "antd";
const { Item: FormItem } = Form;

import SearchableSelect from "components/common/searchableSelect";

import { addBoxGame } from "store/actions/dashboard/retail/boxes/games.action";
import { getGames } from "store/actions/dashboard/virtuals/games/games.action";

import { getGamesFromConfig } from "utils/common";

import { GAME_CATEGORY } from "constants/game.constants";
import { POPUP_SIZE } from "constants/common.constants";

import boxGameType from "types/box/boxGame.type";
import gameType from "types/game/game.type";


/** Box Game Adding Popup Component */
const GameAddComponent = ({ isSaving, addBoxGame, getGames, onClose, games, boxGames }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;

	/** Get the game types which are available for boxes
	 * @function
	 * @returns {array} - avalable types
	 * @memberOf GameAddComponent
	 */
	const getAvailableTypes = () => {
		const possible = getGamesFromConfig(GAME_CATEGORY.SCHEDULED).map((t) => t.value);
		const available = [];
		const types = boxGames.map((g) => g.type);
		possible.forEach((p) => {
			if (!types.includes(p)) {
				available.push(p);
			}
		});
		return available;
	};

	const [gameType, setGameType] = useState(getAvailableTypes()[0]);

	/** Get all games */
	useEffect(() => {
		getGames(GAME_CATEGORY.SCHEDULED);
	}, []);

	/** Fires when form submitted
	 * @function
	 * @memberOf GameAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ gameId }) => {
				const d = {
					id: routeParams.id,
					gameId: gameId
				};
				addBoxGame(d);
			})
			.catch(Function.prototype);
	};

	/** Fires on game type dorpdown change
	 * @function
	 * @param {string} value - dropdown value
	 * @memberOf GameAddComponent
	 */
	const onGameTypeChange = (value) => {
		setGameType(value);
		setFieldsValue({
			gameId: ""
		});
	};

	return (
		<Modal open={true} title={t("pages.dashboard.boxes.add_new_game")} cancelText={t("common.cancel")} okText={t("common.save")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.SMALL} maskClosable={false} closable={false} okButtonProps={{ loading: isSaving }} centered>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					type: gameType,
					gameId: ""
				}}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={`${t("pages.dashboard.boxes.game_type")}`} name="type" rules={[{ required: true, message: t("validation.field_required") }]}>
							<Select onChange={onGameTypeChange} getPopupContainer={() => document.body} suffixIcon={<i className="icon-down" />}>
								{getGamesFromConfig(GAME_CATEGORY.SCHEDULED).map((g) => (
									<Select.Option key={g.value} value={g.value} disabled={!getAvailableTypes().includes(g.value)}>
										{t(`common.${g.type}`)}
									</Select.Option>
								))}
							</Select>
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem label={t("pages.dashboard.boxes.select_game")} name="gameId" rules={[{ required: true, message: t("validation.field_required") }]}>
							<SearchableSelect placeholder={t("pages.dashboard.boxes.select_game_placeholder")} items={games.filter((g) => g.type === gameType)} valueProp={(item) => item.id} textProp={(item) => item.name} renderText={(item) => item.name} getPopupContainer={() => document.body} />
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** GameAddComponent propTypes
 * PropTypes
 */
GameAddComponent.propTypes = {
	/** Redux state property, is true when adding game for box */
	isSaving: PropTypes.bool,
	/** Redux action to add game to box*/
	addBoxGame: PropTypes.func,
	/** Redux action to get games */
	getGames: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Redux state property, represents the array of games  */
	games: PropTypes.arrayOf(gameType),
	/** Redux state property, represents the array of games of box */
	boxGames: PropTypes.arrayOf(boxGameType)
};

const mapDispatchToProps = (dispatch) => ({
	addBoxGame: (game) => {
		dispatch(addBoxGame(game));
	},
	getGames: (gameCategory) => {
		dispatch(getGames(false, true, gameCategory));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.boxes.isSaving,
		boxGames: state.boxes.editingBox.games.games,
		games: state.games.games
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GameAddComponent);
