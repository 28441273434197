import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import WidgetSkeleton from "components/dashboard/statistics/widgets/skeleton";

import Paths from "constants/path.constants";
import { PROJECT_TYPE } from "constants/common.constants";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { hasOneOfPermissions } from "utils/permissions";

/** Dashboard Page Total Betslips Widget Component */
const TotalBetslipsWidgetComponent = ({ onlineBetslipsTotal, retailBetslipsTotal, projectType, dashboardFilters, retailFilters, isBetslipActivitiesLoading, isPlayerActivitiesLoading, isGamesPerformanceLoading }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const filters = projectType === PROJECT_TYPE.RETAIL ? retailFilters : dashboardFilters;

	/** Generate redirection path hashs
	 * @function
	 * @memberOf TotalBetslipsWidgetComponent
	 */
	const generateRedirectionPathHash = () => (projectType === PROJECT_TYPE.RETAIL ? "#tab=2" : "#tab=1&subtab=2");

	const generatePath = () => {
		return projectType === PROJECT_TYPE.ONLINE ? Paths.DASHBOARD_BETSLIPS_ONLINE : Paths.DASHBOARD_BETSLIPS_RETAIL;
	};

	const generateQueryParams = () => `?period=${filters.period}&projectId=${filters.projectId ? filters.projectId : "all"}`;

	return (
		<div className="dashboard-area" data-type="betslips">
			<div className="dashboard-area-header">
				<i className="icon-betslips" />
				<span className="dashboard-area-header-title">{t("pages.dashboard.dashboard.total_bets")}</span>
			</div>
			<div className="dashboard-area-content">
				{isBetslipActivitiesLoading || isPlayerActivitiesLoading || isGamesPerformanceLoading ? (
					<WidgetSkeleton variation="total" />
				) : (
					<span title={projectType === PROJECT_TYPE.ONLINE ? onlineBetslipsTotal : retailBetslipsTotal} className="dashboard-area-content-value">
						{projectType === PROJECT_TYPE.ONLINE ? onlineBetslipsTotal : retailBetslipsTotal}
					</span>
				)}
				{hasOneOfPermissions([
					{ resource: PERMISSION_RESOURCE.PENDING_BETSLIP, action: PERMISSION_ACTION.VIEW },
					{ resource: PERMISSION_RESOURCE.SETTLED_BETSLIP, action: PERMISSION_ACTION.VIEW }
				]) && <i className="icon-right" onClick={() => navigate(`${generatePath()}${generateQueryParams()}${generateRedirectionPathHash()}`)} />}
			</div>
		</div>
	);
};

/** TotalBetslipsWidgetComponent propTypes
 * PropTypes
 */
TotalBetslipsWidgetComponent.propTypes = {
	/** Redux state property, the count of online betslips */
	onlineBetslipsTotal: PropTypes.number,
	/** Redux state property, the count of retail betslips */
	retailBetslipsTotal: PropTypes.number,
	/** Project type(Online/ Retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	/** Redux state property, dashboard pie charts*/
	dashboardFilters: PropTypes.object,
	/** Redux state property, dashboard filters*/
	retailFilters: PropTypes.object,
	// Redux state controls betslips activities loading indicator
	isBetslipActivitiesLoading: PropTypes.bool,
	// Redux state controls betslips activities loading indicator
	isPlayerActivitiesLoading: PropTypes.bool,
	// Redux state controls games performance loading indicator
	isGamesPerformanceLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		onlineBetslipsTotal: state.dashboard.online.betslipsTotal,
		retailBetslipsTotal: state.dashboard.retail.betslipsTotal,
		dashboardFilters: state.dashboard.online.filters,
		retailFilters: state.dashboard.retail.filters,
		isBetslipActivitiesLoading: state.dashboard.isBetslipActivitiesLoading,
		isPlayerActivitiesLoading: state.dashboard.online.isPlayerActivitiesLoading,
		isGamesPerformanceLoading: state.dashboard.isGamesPerformanceLoading
	};
};

export default connect(mapStateToProps, null)(TotalBetslipsWidgetComponent);
