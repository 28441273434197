import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import {
	SYSTEM_SET_CURRENCIES,
	SYSTEM_SET_AVAILABLE_CURRENCIES,
	SYSTEM_SET_SYSTEM_AVAILABLE_CURRENCIES,
	SYSTEM_ADD_CURRENCY,
	SYSTEM_UPDATE_CURRENCY,
	SYSTEM_CURRENCIES_BEFORE,
	SYSTEM_CURRENCIES_FINISH,
	SYSTEM_CURRENCY_BEFORE,
	SYSTEM_CURRENCY_FINISH,
	SYSTEM_AVAILABLE_CURRENCIES_BEFORE,
	SYSTEM_AVAILABLE_CURRENCIES_FINISH,
	SYSTEM_CURRENCIES_SET_SORTING,
	SYSTEM_CURRENCIES_SET_FILTERS
} from "store/actionTypes";

const setSystemCurrencies = (currencies) => ({
	type: SYSTEM_SET_CURRENCIES,
	payload: { currencies }
});

const setAvailableCurrencies = (currencies) => ({
	type: SYSTEM_SET_AVAILABLE_CURRENCIES,
	payload: { currencies }
});

const setSystemAvailableCurrencies = (currencies) => ({
	type: SYSTEM_SET_SYSTEM_AVAILABLE_CURRENCIES,
	payload: { currencies }
});

const setAddSystemCurrency = (currency) => ({
	type: SYSTEM_ADD_CURRENCY,
	payload: { currency }
});

const setUpdateSystemCurrency = (currency) => ({
	type: SYSTEM_UPDATE_CURRENCY,
	payload: { currency }
});

const setSystemCurrenciesBefore = () => ({
	type: SYSTEM_CURRENCIES_BEFORE
});

const setSystemCurrenciesFinished = () => ({
	type: SYSTEM_CURRENCIES_FINISH
});

const setSystemCurrencyBefore = () => ({
	type: SYSTEM_CURRENCY_BEFORE
});

const setSystemCurrencyFinished = () => ({
	type: SYSTEM_CURRENCY_FINISH
});

const setSystemAvailableCurrenciesBefore = () => ({
	type: SYSTEM_AVAILABLE_CURRENCIES_BEFORE
});

const setSystemAvailableCurrenciesFinished = () => ({
	type: SYSTEM_AVAILABLE_CURRENCIES_FINISH
});

export const setSystemCurrenciesFilters = (filters) => ({
	type: SYSTEM_CURRENCIES_SET_FILTERS,
	payload: { filters }
});

export const setSystemCurrenciesSorting = (sorting) => ({
	type: SYSTEM_CURRENCIES_SET_SORTING,
	payload: { sorting }
});

export const getSystemCurrencies = () => {
	return (dispatch, getState) => {
		dispatch(setSystemCurrenciesBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SYSTEM_CURRENCIES_ALL}`,
			method: Methods.GET,
			params: {
				...getState().systemCurrencies.sorting,
				...getState().systemCurrencies.filters
			}
		})
			.then(({ data: { value: currencies } }) => {
				dispatch(setSystemCurrencies(currencies));
				dispatch(setSystemCurrenciesFinished());
			})
			.catch((ex) => {
				dispatch(setSystemCurrenciesFinished());
			});
	};
};

export const addSystemCurrency = (currency, callback) => {
	return (dispatch) => {
		dispatch(setSystemCurrencyBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SYSTEM_CURRENCIES_CREATE}`,
			method: Methods.POST,
			data: currency
		})
			.then(({ data: { value: currency, status, message: msg } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setAddSystemCurrency(currency));
					return true;
				}
				return false;
			})
			.then(() => {
				if (typeof callback !== "function") {
					return;
				}
				callback();
			})
			.finally(() => {
				dispatch(setSystemCurrencyFinished());
			});
	};
};

export const updateSystemCurrency = (currency, callback) => {
	return (dispatch) => {
		dispatch(setSystemCurrencyBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SYSTEM_CURRENCIES_SAVE}`,
			method: Methods.POST,
			data: currency
		})
			.then(({ data: { value: currency, status, message: msg } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setUpdateSystemCurrency(currency));
					return true;
				}
				return false;
			})
			.then(() => {
				if (typeof callback !== "function") {
					return;
				}
				callback();
			})
			.finally(() => {
				dispatch(setSystemCurrencyFinished());
			});
	};
};

export const getAvailableCurrencies = () => {
	return (dispatch) => {
		dispatch(setSystemAvailableCurrenciesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SYSTEM_CURRENCIES_AVAILABLE}`,
			method: Methods.GET
		})
			.then(({ data: { value: currencies } }) => {
				dispatch(setAvailableCurrencies(currencies));
				dispatch(setSystemAvailableCurrenciesFinished());
			})
			.catch((ex) => {
				dispatch(setSystemAvailableCurrenciesFinished());
			});
	};
};

export const getSystemAvailableCurrencies = () => {
	return (dispatch) => {
		dispatch(setSystemAvailableCurrenciesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SYSTEM_CURRENCIES_SYSTEM_AVAILABLE}`,
			method: Methods.GET
		})
			.then(({ data: { value: currencies } }) => {
				dispatch(setSystemAvailableCurrencies(currencies));
				dispatch(setSystemAvailableCurrenciesFinished());
			})
			.catch((ex) => {
				dispatch(setSystemAvailableCurrenciesFinished());
			});
	};
};
