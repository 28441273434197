import { Fragment } from "react";

import ProfileComponent from "components/dashboard/profile/profile.component";

const ProfileRoute = () => {
	return (
		<Fragment>
			<ProfileComponent />
		</Fragment>
	);
};

export default ProfileRoute;
