import { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ChartGenerator from "./ChartGenerator";

const FinancialPerformance = ({ tooltipSymbol, categories, turnovers, ggrs }) => {
	const { t } = useTranslation();
	const series = useMemo(
		() => [
			{
				name: t("pages.dashboard.dashboard.partners_revenue_financial_performance_turnover"),
				data: turnovers
			},
			{
				name: t("pages.dashboard.dashboard.partners_revenue_financial_performance_ggr"),
				data: ggrs
			}
		],
		[turnovers, ggrs]
	);
	const Chart = useMemo(() => ChartGenerator({ tooltipSymbol, categories, series }), [tooltipSymbol, categories, series]);
	return <Chart />;
};

FinancialPerformance.propTypes = {
	/** React property, symbol of tooltip of chart */
	tooltipSymbol: PropTypes.string,
	/** React property, Highchart rows names */
	categories: PropTypes.arrayOf(PropTypes.string),
	/** React property, turnovers of all partners */
	turnovers: PropTypes.arrayOf(PropTypes.object),
	/** React property, ggrs of all partners */
	ggrs: PropTypes.arrayOf(PropTypes.object)
};

export default FinancialPerformance;
