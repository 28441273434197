import { useMemo, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useParams } from "react-router-dom";

import { Form, Row, Col, Select, Input, DatePicker, Radio, Tooltip } from "antd";

const { Item: FormItem } = Form;
import locale from "antd/es/date-picker/locale/en_US";

import FiltersWrapper from "components/common/filters";
import NumericInput from "components/common/numericInput";

import { setBetshopActivityFilters, getBetshopActivity } from "store/actions/dashboard/retail/betshops/activity.action";

import { FILTER_ACTIVITY_TYPE } from "constants/filter.constants";

import { monthAgo } from "utils/dateTime";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

/** Betshop Activity Page Filters Component */

const Filters = ({ setBetshopActivityFilters, getBetshopActivity, filters }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [{ name: "date", keepTime: true, roundTime: false }]
		};
	}, []);

	const filtersList = [
		{ name: "transactionId", title: t("pages.dashboard.wallet.transaction_id") },
		{ name: "userNameOrId", title: t("pages.dashboard.wallet.username_or_id") },
		{
			name: "type",
			title: t("pages.dashboard.wallet.type"),
			values: [
				{ title: t("common.all"), value: FILTER_ACTIVITY_TYPE.ALL },
				{ title: t("pages.dashboard.wallet.activityType_2"), value: FILTER_ACTIVITY_TYPE.LIMIT_ADJUSTMENT },
				{ title: t("pages.dashboard.wallet.activityType_3"), value: FILTER_ACTIVITY_TYPE.BET },
				{ title: t("pages.dashboard.wallet.activityType_4"), value: FILTER_ACTIVITY_TYPE.CANCEL },
				{ title: t("pages.dashboard.wallet.activityType_5"), value: FILTER_ACTIVITY_TYPE.PAYOUT }
			]
		}
	];

	return (
		<FiltersWrapper filtersName="betshop_activity" loadFn={() => getBetshopActivity(routeParams.id)} setFiltersFn={setBetshopActivityFilters} filters={filters} formFieldsConfigs={formFieldsConfigs} filtersList={filtersList}>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.wallet.transaction_id")} name="transactionId">
							<NumericInput placeholder={`${t("common.enter")} ${t("pages.dashboard.wallet.transaction_id")}`} isInteger={true} maxLength={12} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.wallet.username_or_id")} name="userNameOrId">
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.wallet.username_or_id")}`} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.wallet.type")} name="type">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_ACTIVITY_TYPE.ALL}>
									<span title={t("common.all")}>{t("common.all")}</span>
								</Select.Option>
								<Select.Option value={FILTER_ACTIVITY_TYPE.LIMIT_ADJUSTMENT}>
									<span title={t("pages.dashboard.wallet.activityType_2")}>{t("pages.dashboard.wallet.activityType_2")}</span>
								</Select.Option>
								<Select.Option value={FILTER_ACTIVITY_TYPE.BET}>
									<span title={t("pages.dashboard.wallet.activityType_3")}>{t("pages.dashboard.wallet.activityType_3")}</span>
								</Select.Option>
								<Select.Option value={FILTER_ACTIVITY_TYPE.CANCEL}>
									<span title={t("pages.dashboard.wallet.activityType_4")}>{t("pages.dashboard.wallet.activityType_4")}</span>
								</Select.Option>
								<Select.Option value={FILTER_ACTIVITY_TYPE.PAYOUT}>
									<span title={t("pages.dashboard.wallet.activityType_5")}>{t("pages.dashboard.wallet.activityType_5")}</span>
								</Select.Option>
							</Select>
						</FormItem>
					</Col>

					<Col xs={24} sm={12} lg={6} xl={8}>
						<FormItem
							label={
								<Fragment>
									<span>{t("pages.dashboard.wallet.date_and_time")}</span>
									<Tooltip title={t("common.for_more_data")} getPopupContainer={() => document.body}>
										<i className="icon-info table-filters-info" />
									</Tooltip>
								</Fragment>
							}
							name="date"
						>
							<DatePicker.RangePicker
								placeholder={[t("common.from"), t("common.to")]}
								format={`${DATE_FORMAT} ${TIME_FORMAT}`}
								showTime={{ format: TIME_FORMAT }}
								disabledDate={(d) => !d || d.isBefore(monthAgo(3)) || d.isAfter(moment().endOf("day"))}
								allowClear={false}
								locale={{
									...locale,
									lang: {
										...locale.lang,
										ok: t("common.apply")
									}
								}}
							/>
						</FormItem>
					</Col>

					<Col xs={24} sm={18} lg={18} xl={12}>
						<div className="table-filters-quick-filters">
							<FormItem label={t("common.quick_filters")} name="quickFilters" className="table-filters-quick-filters-buttons">
								<Radio.Group>
									<Radio.Button value="last_24_h" style={{ marginBottom: "8px" }}>
										{t("common.last_24_h")}
									</Radio.Button>
									<Radio.Button value="last_week" style={{ marginBottom: "8px" }}>
										{t("common.this_week")}
									</Radio.Button>
									<Radio.Button value="last_month" style={{ marginBottom: "8px" }}>
										{t("common.this_month")}
									</Radio.Button>
									<Radio.Button value="last_3_month" style={{ marginBottom: "8px" }}>
										{t("common.last_3_months")}
									</Radio.Button>
								</Radio.Group>
							</FormItem>
						</div>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get betshop activity */
	getBetshopActivity: PropTypes.func,
	/** Redux action to set betshop activity filters */
	setBetshopActivityFilters: PropTypes.func,
	/** Redux state property, betshop activity filters */
	filters: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	getBetshopActivity: (id) => {
		dispatch(getBetshopActivity(id));
	},
	setBetshopActivityFilters: (filters) => {
		dispatch(setBetshopActivityFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.betshops.viewingBetshop.activity.filters
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
