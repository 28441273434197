export const TRANSACTION_STATUS = {
	INVALID_SIGN: 101,
	INVALID_PPROJECT: 102,
	INVALID_LANGUAGE: 103,
	INVALID_CURRENCY: 104,
	INVALID_USER_CURRENCY: 105,
	INVALID_USER: 121,
	INVALID_SESSION: 122,
	INVALID_BET: 123,
	NOT_ENOUGH_BALANCE: 124,
	USER_LIMIT_EXCEEDED: 125,
	BET_LIMIT_EXCEEDED: 127,
	SUCCESS: 200,
	BAD_REQUEST: 400,
	TIMEOUT: 408,
	INTERNAL_SERVER_ERROR: 500,
	BAD_GATEWAY: 502,
	BAD_RESPONSE: 503
};

export const TRANSACTION_TYPE = {
	ROLLBACK: 1,
	RESULT: 2
};

export const ACTION_TYPE = {
	BET: 1,
	WON: 2,
	LOST: 3,
	ROLLBACK: 4
};
