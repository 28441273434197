import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { PARTNER_SET_SECURITY_PASSWORD_SETTINGS, PARTNER_SET_SECURITY_MULTIDEVICE_TOKEN_SETTINGS, PARTNER_SET_SECURITY_IP_RESTRICTION, PARTNER_SET_SECURITY_AUTHENTICATION_SETTINGS } from "../../../actionTypes";

import { setPartnerActionBefore, setPartnerActionFinished, setPartnerSaveActionBefore, setPartnerSaveActionFinished } from "./partner.action";

export const setPartnerSecurityPasswordSettings = (settings) => ({
	type: PARTNER_SET_SECURITY_PASSWORD_SETTINGS,
	payload: { settings }
});

export const setPartnerSecurityMultiDeviceSettings = (settings) => ({
	type: PARTNER_SET_SECURITY_MULTIDEVICE_TOKEN_SETTINGS,
	payload: { settings }
});

export const setPartnerSecurityIPRestrictionSettings = (settings) => ({
	type: PARTNER_SET_SECURITY_IP_RESTRICTION,
	payload: { settings }
});

export const setPartnerSecurityAuthenticationSettings = (settings) => ({
	type: PARTNER_SET_SECURITY_AUTHENTICATION_SETTINGS,
	payload: { settings }
});

export const getPartnerSecurityPasswordSettings = () => {
	return (dispatch) => {
		dispatch(setPartnerActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_SECURITY_PASSWORD_SETTINGS}`,
			method: Methods.GET
		})
			.then(({ data: { value: settings } }) => {
				dispatch(setPartnerSecurityPasswordSettings(settings));
				dispatch(setPartnerActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerActionFinished());
			});
	};
};

export const savePartnerSecurityPasswordSettings = (settings) => {
	return (dispatch) => {
		dispatch(setPartnerSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SET_PARTNER_SECURITY_PASSWORD_SETTINGS}`,
			method: Methods.POST,
			data: settings
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					dispatch(setPartnerSecurityPasswordSettings(data.value));
				}
				dispatch(setPartnerSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerSaveActionFinished());
			});
	};
};

export const getPartnerSecurityMultideviceTokenSettings = () => {
	return (dispatch) => {
		dispatch(setPartnerActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_SECURITY_MULTIDEVICE_TOKEN_SETTINGS}`,
			method: Methods.GET
		})
			.then(({ data: { value: settings } }) => {
				dispatch(setPartnerSecurityMultiDeviceSettings(settings));
				dispatch(setPartnerActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerActionFinished());
			});
	};
};

export const savePartnerSecurityMultideviceTokenSettings = (settings) => {
	return (dispatch) => {
		dispatch(setPartnerSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SET_PARTNER_SECURITY_MULTIDEVICE_TOKEN_SETTINGS}`,
			method: Methods.POST,
			data: settings
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					dispatch(setPartnerSecurityMultiDeviceSettings(data.value));
				}
				dispatch(setPartnerSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerSaveActionFinished());
			});
	};
};

export const getPartnerSecurityAuthenticationSettings = () => {
	return (dispatch) => {
		dispatch(setPartnerActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_SECURITY_AUTHENTICATION_SETTINGS}`,
			method: Methods.GET
		})
			.then(({ data: { value: authenticationData } }) => {
				dispatch(setPartnerSecurityAuthenticationSettings(authenticationData));
				dispatch(setPartnerActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerActionFinished());
			});
	};
};

export const savePartnerSecurityAuthenticationSettings = (settings) => {
	return (dispatch) => {
		dispatch(setPartnerSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SET_PARTNER_SECURITY_AUTHENTICATION_SETTINGS}`,
			method: Methods.POST,
			data: settings
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					dispatch(setPartnerSecurityAuthenticationSettings(data.value));
				}
				dispatch(setPartnerSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerSaveActionFinished());
			});
	};
};

export const getPartnerSecurityIPRestrictionSettings = () => {
	return (dispatch) => {
		dispatch(setPartnerActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_SECURITY_IPRESTRICTION_SETTINGS}`,
			method: Methods.GET
		})
			.then(({ data: { value: settings } }) => {
				dispatch(setPartnerSecurityIPRestrictionSettings(settings));
				dispatch(setPartnerActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerActionFinished());
			});
	};
};

export const savePartnerSecurityIPRestrictionSettings = (settings) => {
	return (dispatch) => {
		dispatch(setPartnerSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SET_PARTNER_SECURITY_IPRESTRICTION_SETTINGS}`,
			method: Methods.POST,
			data: settings
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					dispatch(setPartnerSecurityIPRestrictionSettings(data.value));
				}
				dispatch(setPartnerSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerSaveActionFinished());
			});
	};
};
