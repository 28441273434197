import { useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Modal, Form, Col, Row, Select, Checkbox, DatePicker, Input, Radio } from "antd";
const { Item: FormItem } = Form;

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import locale from "antd/es/date-picker/locale/en_US";

import Paths from "constants/path.constants";
import { POPUP_SIZE } from "constants/common.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import { SURVEY_DURATION_TYPE, SURVEY_TYPE } from "constants/survey.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import { hasPermission } from "utils/permissions";

import { getSurveys, createSurvey } from "store/actions/dashboard/usersFeedback/CSAT/surveys.action";

const AddSurveyPopup = ({ isSaving, createSurvey, getSurveys, onClose }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;

	const [selectedProjectType, setSelectedProjectType] = useState(PROJECT_TYPE.ONLINE);
	const [selectedDurationType, setSelectedDurationType] = useState(null);
	const [isTestCsat, setIsTestCsat] = useState(false);

	const handleForm = () => {
		validateFields()
			.then((data) => {
				const res = {
					name: data.csatName,
					projectType: selectedProjectType,
					type: SURVEY_TYPE.CSAT,
					isTesting: isTestCsat,
					suggestMessage: data.suggestMessage
				};

				if (selectedDurationType === SURVEY_DURATION_TYPE.TEMPORARY) {
					res.startDate = data.durationTime[0].toDate();
					res.endDate = data.durationTime[1].toDate();
				} else if (selectedDurationType === SURVEY_DURATION_TYPE.PERMANENT) {
					res.startDate = data.durationTime.toDate();
				}
				createSurvey(res, ({ id }) => {
					if (hasPermission({ resource: PERMISSION_RESOURCE.SURVEYS, action: PERMISSION_ACTION.CREATE })) {
						navigate(Paths.DASHBOARD_USERS_FEEDBACK_CSAT_EDIT.replace(DYNAMIC_PATH_ID_REGEX, id));
					} else {
						getSurveys();
						onClose();
					}
				});
			})
			.catch(Function.prototype);
	};

	return (
		<Modal open={true} title={t("pages.dashboard.usersFeedback.add_survey")} cancelText={t("common.cancel")} okText={t("common.create")} onOk={handleForm} onCancel={onClose} okButtonProps={{ loading: isSaving }} width={POPUP_SIZE.SMALL} maskClosable={false} closable={false} centered>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					name: "",
					space: selectedProjectType,
					durationType: selectedDurationType,
					durationTime: null,
					partnerIds: [],
					suggestMessage: false
				}}
			>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={24}>
						<FormItem label={`${t("pages.dashboard.usersFeedback.csat_name")} *`} name="csatName" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
							<Input maxLength={50} placeholder={`${t("common.enter")} ${t("common.name")}`} autoFocus={true} autoComplete="off" />
						</FormItem>
					</Col>
					<Col xs={24} sm={24}>
						<FormItem label={t("pages.dashboard.usersFeedback.space")} name="space">
							<Radio.Group
								style={{ display: "flex" }}
								disabled
								onChange={(projectType) => setSelectedProjectType(projectType)}
								options={[
									{ label: <span>{t("pages.dashboard.usersFeedback.backoffice_users")}</span>, value: PROJECT_TYPE.BACKOFFICE },
									{ label: <span>{t("pages.dashboard.usersFeedback.site_players")}</span>, value: PROJECT_TYPE.ONLINE }
								]}
							></Radio.Group>
						</FormItem>
					</Col>
					<Col xs={24} sm={24}>
						<FormItem label={`${t("pages.dashboard.usersFeedback.duration_type")} *`} name="durationType" rules={[{ required: true, message: t("validation.field_required") }]}>
							<Select
								suffixIcon={<i className="icon-down" />}
								placeholder={`${t("common.select")}`}
								getPopupContainer={() => document.body}
								onChange={(durationType) => {
									setSelectedDurationType(durationType);
									setFieldsValue({ durationTime: durationType === SURVEY_DURATION_TYPE.TEMPORARY ? [null, null] : null });
								}}
							>
								<Select.Option value={SURVEY_DURATION_TYPE.TEMPORARY}>{t("pages.dashboard.usersFeedback.temporary")}</Select.Option>
								<Select.Option value={SURVEY_DURATION_TYPE.PERMANENT}>{t("pages.dashboard.usersFeedback.permanent")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					{selectedDurationType !== null && (
						<Col xs={24} sm={24}>
							<FormItem
								label={`${t(`pages.dashboard.usersFeedback.${selectedDurationType === SURVEY_DURATION_TYPE.TEMPORARY ? "duration_time" : "start_date"}`)} *`}
								name="durationTime"
								rules={[
									() => ({
										validator(rule, durationTime) {
											if (selectedDurationType === SURVEY_DURATION_TYPE.TEMPORARY && Array.isArray(durationTime) && !durationTime[1]) {
												return Promise.reject(t("validation.field_required"));
											} else {
												return Promise.resolve();
											}
										}
									})
								]}
							>
								{selectedDurationType === SURVEY_DURATION_TYPE.TEMPORARY ? (
									<DatePicker.RangePicker
										placeholder={[t("common.from"), t("common.to")]}
										format={`${DATE_FORMAT} ${TIME_FORMAT}`}
										showTime={{ format: TIME_FORMAT }}
										disabledDate={(d) => !d || d.isBefore(moment())}
										allowClear={true}
										locale={{
											...locale,
											lang: {
												...locale.lang,
												ok: t("common.apply")
											}
										}}
										getPopupContainer={() => document.body}
									/>
								) : (
									<DatePicker format={`${DATE_FORMAT} ${TIME_FORMAT}`} showTime={{ format: TIME_FORMAT }} disabledDate={(d) => !d || d.isBefore(moment())} allowClear={true} placeholder={t("common.select_time")} getPopupContainer={() => document.body} />
								)}
							</FormItem>
						</Col>
					)}
					<Col span={24}>
						<div className="inline-form-checkbox">
							<label>{t("pages.dashboard.usersFeedback.suggested_message_box")}</label>
							<FormItem className="inline-form-item-control" name="suggestMessage" valuePropName="checked">
								<Checkbox />
							</FormItem>
						</div>
					</Col>
					<Col span={24}>
						<div className="inline-form-checkbox">
							<label>{t("pages.dashboard.usersFeedback.is_test_csat")}</label>
							<FormItem className="inline-form-item-control" name="isTesting" valuePropName="checked">
								<Checkbox
									onChange={(e) => {
										setIsTestCsat(e.target.checked);
										setFieldsValue({ partnerIds: [] });
									}}
								/>
							</FormItem>
						</div>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

AddSurveyPopup.propTypes = {
	/** Redux state property, is true when creating survey request is in process */
	isSaving: PropTypes.bool,
	/** Redux action to create survey */
	createSurvey: PropTypes.func,
	/** Redux action to get surveys */
	getSurveys: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	createSurvey: (survey, onSuccess) => {
		dispatch(createSurvey(survey, onSuccess));
	},
	getSurveys: () => {
		dispatch(getSurveys(true));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.surveys.isSaving
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSurveyPopup);
