import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { numberWithSpaces } from "utils/common";
import { PROJECT_TYPE } from "constants/common.constants";

import WidgetSkeleton from "components/dashboard/statistics/widgets/skeleton";

/** Dashboard Page Total GGR Amount Widget Component */
const TotalGGRAmountWidgetComponent = ({ totalGGRAmount, isGamesPerformanceLoading }) => {
	const { t } = useTranslation();
	return (
		<div className="dashboard-area" data-type="totalGGRAmount">
			<div className="dashboard-area-header">
				<i className="icon-dashboard-total-ggr-amount" />
				<span className="dashboard-area-header-title">{t("pages.dashboard.dashboard.ggrAmount")}</span>
			</div>
			<div className="dashboard-area-content">
				{isGamesPerformanceLoading ? (
					<WidgetSkeleton />
				) : (
					<span title={totalGGRAmount} className="dashboard-area-content-value">
						{totalGGRAmount}
					</span>
				)}
			</div>
		</div>
	);
};

/** TotalGGRAmountWidgetComponent propTypes
 * PropTypes
 */
TotalGGRAmountWidgetComponent.propTypes = {
	/** Redux state property, dashboard total ggr amount (Online / Retail) */
	totalGGRAmount: PropTypes.string,
	// Redux state controls games performance loading indicator
	isGamesPerformanceLoading: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
	let totalGGRAmount = 0;
	switch (ownProps.projectType) {
		case PROJECT_TYPE.ONLINE:
			totalGGRAmount = state.dashboard.online.gamesPerformance.totalGGRAmount || 0;
			break;
		case PROJECT_TYPE.RETAIL:
			totalGGRAmount = state.dashboard.retail.gamesPerformance.totalGGRAmount || 0;
			break;
		default:
			break;
	}
	const { minorUnit, symbol, code } = state.profile.userInfo.currency;
	totalGGRAmount = numberWithSpaces(totalGGRAmount.toFixed(minorUnit)) + " " + (symbol || code);

	const isGamesPerformanceLoading = state.dashboard.isGamesPerformanceLoading;
	return { totalGGRAmount, isGamesPerformanceLoading };
};

export default connect(mapStateToProps, null)(TotalGGRAmountWidgetComponent);
