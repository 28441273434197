import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { PARTNER_SET_CURRENCIES, PARTNER_ADD_CURRENCY, PARTNER_UPDATE_CURRENCY, PARTNER_DELETE_CURRENCY, PARTNER_CURRENCIES_BEFORE, PARTNER_CURRENCIES_FINISH, PARTNER_AVAILABLE_CURRENCIES_BEFORE, PARTNER_AVAILABLE_CURRENCIES_FINISH, PARTNER_SET_AVAILABLE_CURRENCIES } from "../../../actionTypes";

import { setPartnerSaveActionFinished, setPartnerSaveActionBefore } from "./partner.action";

export const setPartnerCurrencies = (currencies) => ({
	type: PARTNER_SET_CURRENCIES,
	payload: { currencies }
});

export const setAddPartnerCurrency = (currency) => ({
	type: PARTNER_ADD_CURRENCY,
	payload: { currency }
});

export const setDeletePartnerCurrency = (code) => ({
	type: PARTNER_DELETE_CURRENCY,
	payload: { code }
});

const setPartnerCurrenciesBefore = () => ({
	type: PARTNER_CURRENCIES_BEFORE
});

const setPartnerCurrenciesFinished = () => ({
	type: PARTNER_CURRENCIES_FINISH
});

const setPartnerAvailableCurrenciesBefore = () => ({
	type: PARTNER_AVAILABLE_CURRENCIES_BEFORE
});

const setPartnerAvailableCurrenciesFinished = () => ({
	type: PARTNER_AVAILABLE_CURRENCIES_FINISH
});

const setPartnerAvailableCurrencies = (currencies) => ({
	type: PARTNER_SET_AVAILABLE_CURRENCIES,
	payload: { currencies }
});

export const getPartnerCurrencies = () => {
	return (dispatch) => {
		dispatch(setPartnerCurrenciesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_CURRENCIES}`,
			method: Methods.GET
		})
			.then(({ data: { value: currencies } }) => {
				dispatch(setPartnerCurrencies(currencies));
				dispatch(setPartnerCurrenciesFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerCurrenciesFinished());
			});
	};
};

export const addPartnerCurrency = (currency) => {
	return (dispatch) => {
		dispatch(setPartnerSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.ADD_PARTNER_CURRENCY}`,
			method: Methods.POST,
			data: currency
		})
			.then(({ data: { value: currency } }) => {
				dispatch(setAddPartnerCurrency(currency));
				dispatch(setPartnerSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerSaveActionFinished());
			});
	};
};

export const updatePartnerCurrency = (currency) => {
	return (dispatch) => {
		dispatch(setPartnerSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_PARTNER_CURRENCY}`,
			method: Methods.POST,
			data: currency
		})
			.then(({ data: { message: msg, status, value: currency } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(getPartnerCurrencies());
				}
				dispatch(setPartnerSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerSaveActionFinished());
			});
	};
};

export const deletePartnerCurrency = (code) => {
	return (dispatch) => {
		dispatch(setPartnerSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.DELETE_PARTNER_CURRENCY}`,
			method: Methods.DELETE,
			data: { code }
		})
			.then(() => {
				dispatch(setDeletePartnerCurrency(code));
				dispatch(setPartnerSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerSaveActionFinished());
			});
	};
};

export const getPartnerAvailableCurrencies = () => {
	return (dispatch) => {
		dispatch(setPartnerAvailableCurrenciesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_AVAILABLE_CURRENCIES}`,
			method: Methods.GET
		})
			.then(({ data: { value: currencies } }) => {
				dispatch(setPartnerAvailableCurrencies(currencies));
				dispatch(setPartnerAvailableCurrenciesFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerAvailableCurrenciesFinished());
			});
	};
};
