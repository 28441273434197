import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import { SET_PERMISSION_REQUESTS_HISTORY, SET_PERMISSION_REQUESTS_HISTORY_SORTING, SET_PERMISSION_REQUESTS_HISTORY_FILTERS } from "store/actionTypes";

import { setPermissionRequestsActionBefore, setPermissionRequestsActionFinished } from "./requests.action";

const setPermissionRequestHistory = (history, add) => ({
	type: SET_PERMISSION_REQUESTS_HISTORY,
	payload: { history, add }
});

export const setPermissionRequestHistorySorting = (sorting) => ({
	type: SET_PERMISSION_REQUESTS_HISTORY_SORTING,
	payload: { sorting }
});

export const setPermissionRequestHistoryFilters = (filters) => ({
	type: SET_PERMISSION_REQUESTS_HISTORY_FILTERS,
	payload: { filters }
});

export const getPermissionRequestHistory = (nextPage) => {
	return (dispatch, getState) => {
		const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().permissionRequests.history.sorting.page + 1 : getState().permissionRequests.history.sorting.page;
		const params = {
			...getState().permissionRequests.history.sorting,
			...getState().permissionRequests.history.filters,
			page: page
		};

		Object.entries(params).forEach(([key, value]) => {
			if (["", null, undefined].includes(value)) {
				delete params[key];
			}
		});

		if (page > 1 && getState().permissionRequests.history.total <= (page - 1) * getState().permissionRequests.history.sorting.limit) {
			return Promise.resolve();
		}

		if (nextPage === "next" && getState().permissionRequests.history.total === 0) {
			return Promise.resolve();
		}

		dispatch(setPermissionRequestsActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PERMISSION_REQUESTS_HISTORY}`,
			method: Methods.GET,
			params: params
		})
			.then(({ data: { value: history } }) => {
				dispatch(setPermissionRequestHistory(history, isMobile() && page !== 1));
				if (nextPage) dispatch(setPermissionRequestHistorySorting({ ...getState().permissionRequests.history.sorting, page: page }));
				dispatch(setPermissionRequestsActionFinished());
			})
			.catch((ex) => {
				dispatch(setPermissionRequestsActionFinished());
			});
	};
};
