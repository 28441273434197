import axios from 'axios';
import Methods from 'constants/methods.constants';
import ApiUrls from 'constants/api.constants';

import {
	SET_JACKPOT_HISTORY_BEFORE,
	SET_JACKPOT_HISTORY_FINISH,
	SET_JACKPOT_HISTORY,
	SET_JACKPOT_HISTORY_SORTING,
	SET_JACKPOT_HISTORY_FILTERS
} from '../../../actionTypes';

export const setJackpotHistoryActionBefore = () => ({
	type: SET_JACKPOT_HISTORY_BEFORE
})

export const setJackpotHistoryActionFinished = () => ({
	type: SET_JACKPOT_HISTORY_FINISH
})

const setJackpotHistory = data => ({
	type: SET_JACKPOT_HISTORY,
	payload: { data }
})

export const setJackpotHistorySorting = sorting => ({
	type: SET_JACKPOT_HISTORY_SORTING,
	payload: { sorting }
})

export const setJackpotHistoryFilters = filters => ({
	type: SET_JACKPOT_HISTORY_FILTERS,
	payload: { filters }
})

export const getJackpotHistory = (fromFirstPage, jackpotId) => {
	return (dispatch, getState) => {
		dispatch(setJackpotHistoryActionBefore());
		const { projectIds, ...filters } = getState().bonuses.jackpots.editingJackpot.history.filters
		let additionalQueryParams = null;
		if (!Array.isArray(projectIds) || !projectIds.length) {
			additionalQueryParams = ""
		} else {
			const joinedArrayParams = projectIds.filter(Boolean).map((i) => `projectIds=${i}`);
			additionalQueryParams = ((joinedArrayParams.length > 0) ? `?${joinedArrayParams.join("&")}` : "");
		}
		
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.JACKPOT_HISTORY}${additionalQueryParams}`,
			method: Methods.GET,
			params: {
				jackpotId,
				...getState().bonuses.jackpots.editingJackpot.history.sorting,
				...filters,
				page: fromFirstPage ? 1 : getState().bonuses.jackpots.editingJackpot.history.sorting.page
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setJackpotHistory(data));
				if (fromFirstPage) {
					dispatch(setJackpotHistorySorting({ ...getState().bonuses.jackpots.editingJackpot.history.sorting, page: 1 }))
				}
				dispatch(setJackpotHistoryActionFinished());
			})
			.catch((ex) => {
				dispatch(setJackpotHistoryActionFinished());
			})
	}
}