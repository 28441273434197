import { SET_OPERATORS, OPERATORS_BEFORE, OPERATORS_FINISH, OPERATORS_SET_SORTING } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case OPERATORS_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case OPERATORS_FINISH:
			return {
				...state,
				isLoading: false
			};
		case SET_OPERATORS:
			return {
				...state,
				operators: payload.operators.item2,
				total: payload.operators.item1
			};
		case OPERATORS_SET_SORTING:
			return {
				...state,
				sorting: payload.sorting
			};
		default:
			return state;
	}
};
