import { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getBetshopActivity, setBetshopActivitySorting, setBetshopActivityFilters } from "store/actions/dashboard/retail/betshops/activity.action";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import Table from "components/common/table";
import ExportButton from "components/common/exportButton";
import Filters from "./filters.component";
import { convertCurrencies, getPartnerCurrency } from "utils/currency";
import { numberWithSpaces } from "utils/common";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import ApiUrls from "constants/api.constants";
import { WALLET_TYPE } from "constants/wallet.constants";
import activityType from "types/betshop/activity.type";
import sortingType from "types/common/sorting.type";
import currencyType from "types/common/currency.type";

/** Betshop Edit Page Transactions History tab Transactions SubTab Component */

const TransactionsComponent = ({ isLoading, activities, total, getBetshopActivity, sorting, filters, setBetshopActivitySorting, setBetshopActivityFilters, currency, globalPartnerId, currencies }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.wallet.transaction_id"),
			dataIndex: "id"
		},
		{
			title: t("pages.dashboard.wallet.date"),
			dataIndex: "changeTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.wallet.type"),
			dataIndex: "type",
			render: (value) => (
				<div className="row-with-icon">
					<i className={value === WALLET_TYPE.WALLET_ADJUSTMENT ? "icon-wallet-adjustment" : value === WALLET_TYPE.LIMIT_CHANGE ? "icon-limit-change" : "icon-bet-transaction"}></i>
					<span>{t("pages.dashboard.wallet.activityType_" + value)}</span>
				</div>
			)
		},
		{
			title: t("pages.dashboard.wallet.currency"),
			dataIndex: "currency",
			render: (value, record) => getPartnerCurrency(currency.code, currencies, globalPartnerId, record.changeTime),
			sorter: false
		},
		{
			title: t("pages.dashboard.wallet.amount"),
			dataIndex: "changeAmount",
			render: (value, record) => numberWithSpaces(convertCurrencies(value, record.currencyId, currency.code, currencies, globalPartnerId, record.changeTime))
		},
		{
			title: t("pages.dashboard.wallet.balance_before"),
			dataIndex: "balanceBefore",
			render: (value, record) => numberWithSpaces(convertCurrencies(value, record.currencyId, currency.code, currencies, globalPartnerId, record.changeTime))
		},
		{
			title: t("pages.dashboard.wallet.balance_after"),
			dataIndex: "total",
			render: (value, record) => numberWithSpaces(convertCurrencies(value, record.currencyId, currency.code, currencies, globalPartnerId, record.changeTime))
		},
		{
			title: t("pages.dashboard.wallet.username"),
			dataIndex: "userName"
		},
		{
			title: t("pages.dashboard.wallet.details"),
			dataIndex: "reason",
			render: (value, record) => (
				<span title={value} className="table-col-shorten-text">
					{value}
				</span>
			),
			sorting: false
		}
	];

	return (
		<Fragment>
			<div className="dashboard-section-content">
				<div className="table-header">
					<div className="table-buttons-dropdowns">
						{hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_ACTIVITY, action: PERMISSION_ACTION.EXPORT }) ? (
							<ExportButton columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t("pages.dashboard.betshops.activity")} url={`${ApiUrls.EXPORT_BETSHOPS_WALLET_HISTORY}?id=${routeParams.id}`} filters={filters} sorting={sorting} />
						) : null}
					</div>
					<Filters />
				</div>
				<Table
					loading={isLoading}
					columns={columns}
					data={activities}
					loadFn={() => getBetshopActivity(routeParams.id)}
					sorting={sorting}
					filters={filters}
					setSortingFn={setBetshopActivitySorting}
					setFiltersFn={setBetshopActivityFilters}
					total={total}
					updateProps={[currency]}
					actions={{}}
					isDisabled={() => false}
					enableReload={true}
				/>
			</div>
		</Fragment>
	);
};

/** TransactionsComponent propTypes
 * PropTypes
 */
TransactionsComponent.propTypes = {
	/** Redux action to get betshop activity */
	getBetshopActivity: PropTypes.func,
	/** Redux state property, represents the array of loaded activities of betshop */
	activities: PropTypes.arrayOf(activityType),
	/** Redux state property, betshop activity total count */
	total: PropTypes.number,
	/** Redux state property, is true when loading betshop activity */
	isLoading: PropTypes.bool,
	/** Redux action to set betshops activity sorting details */
	setBetshopActivitySorting: PropTypes.func,
	/** Redux action to set betshop activity filters */
	setBetshopActivityFilters: PropTypes.func,
	/** Redux state property, betshop activity sorting details */
	sorting: sortingType,
	/** Redux state property, betshops activity filters */
	filters: PropTypes.object,
	/** Redux state property, current user currency */
	currency: currencyType,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType)
};

const mapDispatchToProps = (dispatch) => ({
	getBetshopActivity: (id) => {
		dispatch(getBetshopActivity(id));
	},
	setBetshopActivitySorting: (sorting) => {
		dispatch(setBetshopActivitySorting(sorting));
	},
	setBetshopActivityFilters: (filters) => {
		dispatch(setBetshopActivityFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.betshops.viewingBetshop.activity.isLoading,
		activities: state.betshops.viewingBetshop.activity.activities,
		total: state.betshops.viewingBetshop.activity.total,
		sorting: state.betshops.viewingBetshop.activity.sorting,
		filters: state.betshops.viewingBetshop.activity.filters,
		currency: state.profile.userInfo.currency,
		globalPartnerId: state.partner.globalPartnerId,
		currencies: state.profile.userInfo.currencies
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsComponent);
