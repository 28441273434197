import i18n from "i18next";
import { message } from "antd";

/** Show error notification
 * @function
 * @param {string} msg - error message
 * @param {string} status - error status
 * @param {string} resource - error resource
 */

export const showError = (msg, status, resource) => {
	if (!status) return message.error(msg);
	let text = i18n.t(`errors.message.${status}`).replace("%resource%", resource && resource !== "None" ? i18n.t(`errors.resources.${resource}`) : "");

	if (status === "InvalidParameters") {
		text = msg;
	}

	message.error(text);
};
