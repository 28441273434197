import { useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Tabs } from "antd";
import Breadcrumbs from "components/common/breadcrumbs";
import { updateLocationHash, getHashValue } from "utils/common";
import PendingRequests from "./sections/pendingRequests";
import RequestHistory from "./sections/requestHistory";
import { PERMISSION_REQUESTS_MENU_KEYS } from "constants/permissionRequest.constants";
import { useLocation } from "react-router";

const PermissionsRequestsComponent = () => {
	const { t } = useTranslation();
	const [activeKey, setActiveKey] = useState(PERMISSION_REQUESTS_MENU_KEYS.PENDING_REQUESTS);
	const { hash, search } = useLocation();

	/** Load user partners info*/
	useEffect(() => {
		const hashValue = getHashValue("tab", hash);
		if (hashValue && Object.values(PERMISSION_REQUESTS_MENU_KEYS).includes(hashValue)) {
			setActiveKey(hashValue);
		}
	}, [hash]);

	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf PermissionsRequestsComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf PermissionsRequestsComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.permissions_requests") }]} />
				<Tabs
					animated={false}
					activeKey={activeKey}
					destroyInactiveTabPane={true}
					onChange={key => {
						updateLocationHash("tab=" + key);
						setActiveKey(key);
					}}
					items={[
						{
							key: PERMISSION_REQUESTS_MENU_KEYS.PENDING_REQUESTS,
							label: <span>{t("pages.dashboard.permissions_requests.tabs.pending_requests")}</span>,
							children: <PendingRequests hash={hash} search={search} />
						},
						{
							key: PERMISSION_REQUESTS_MENU_KEYS.HISTORY,
							label: <span>{t("pages.dashboard.permissions_requests.tabs.history")}</span>,
							children: <RequestHistory hash={hash} search={search} />
						}
					]}
				/>
			</div>
		</Fragment>
	);
};

export default PermissionsRequestsComponent;
