import { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { Tag, Tooltip } from "antd";

import HistoryFilters from "./historyFilters";
import { useTranslation } from "react-i18next";
import { getPlayerSuspiciousActivitiesHistory, setPlayerSuspiciousActivitiesHistorySorting } from "store/actions/dashboard/riskManagment/playerSuspiciousActivities/playerSuspiciousActivities.actions";

import Table from "components/common/table";
import ExportButtonImminently from "components/common/exportButton/exportButtonImminently";

import { binaryToFlags, getGamesFromConfig } from "utils/common";
import { hasPermission } from "utils/permissions";
import { GAME_TYPE_MAPPER } from "constants/game.constants";
import { EXPORT_TYPE } from "constants/common.constants";
import { DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import ApiUrls from "constants/api.constants";
import { RISK_CASE_STATUS_COLOR, RISK_CASE_STATUS_TRANSLATIONS } from "constants/riskManagement.constants";

const dashedString = "- - -";

const PlayerSuspiciousActivitiesHistory = ({
	isLoading = false,
	playerSuspiciousActivitiesHistory = [],
	total = 0,
	filters = {},
	sorting = {},
	getPlayerSuspiciousActivitiesHistory = Function.prototype,
	setPlayerSuspiciousActivitiesHistorySorting = Function.prototype,
}) => {

	const { t } = useTranslation();
	const columns = [
		{
			title: t("pages.dashboard.bets.player_id"),
			dataIndex: "playerId"
		},
		{
			title: t("pages.dashboard.bets.player_external_id"),
			dataIndex: "playerExternalId"
		},
		{
			title: t("pages.dashboard.bets.betslip_id"),
			dataIndex: "betSlipId",  
			render: (value) => {
				return value ?? dashedString
			}
		},
		{
			title: t("pages.dashboard.bets.bet_id"),
			dataIndex: "betId",
			render: (value) => {
				return value ?? dashedString
			}
		},
		{
			title: t("pages.dashboard.bets.date"),
			dataIndex: "betTime",
			render: (value) => {
				return (
					<Fragment>
						<div>{moment.utc(value).local().format(DATE_FORMAT)}</div>
						<div>{moment.utc(value).local().format(TIME_FORMAT)}</div>
					</Fragment>
				);
			}
		},
		{
			title: t("common.partner"),
			dataIndex: "partnerName",
			sorter: false
		},
		{
			title: t("pages.dashboard.bets.project"),
			dataIndex: "projectName",
			sorter: false
		},
		{
			title: t("pages.dashboard.bets.currency"),
			dataIndex: "currency",
			render: (value) => {
				return value?.toUpperCase() ?? dashedString
			}
		},
		{
			title: t("pages.dashboard.risk_management.player_suspicious_activities_configurations_betAmount"),
			dataIndex: "originalBetAmount",
			render: (value) => {
				return value ?? dashedString;
			}
		},
		{
			title: `${t("pages.dashboard.risk_management.player_suspicious_activities_configurations_betAmount")} (EUR)`,
			dataIndex: "defaultBetAmount",
			render: (value) => {
				return value ?? dashedString;
			}
		},
		{
			title: t("pages.dashboard.risk_management.player_suspicious_activities_configurations_averageBetAmount"),
			dataIndex: "originalAvgBetAmount",
			render: (value) => {
				return value ?? dashedString;
			}
		},
		{
			title: `${t("pages.dashboard.risk_management.player_suspicious_activities_configurations_averageBetAmount")} (EUR)`,
			dataIndex: "defaultAvgBetAmount",
			render: (value) => {
				return value ?? dashedString;
			}
		},
		{
			title: `${t("pages.dashboard.risk_management.player_suspicious_activities_configurations_deviation")} %`,
			dataIndex: "deviation"
		},
		{
			title: t("pages.dashboard.risk_management.bet_anomalies_history_filter_riskCaseStatus"),
			dataIndex: "riskCaseStatus",
			render: (value) => {
				return (
					<Tag color={RISK_CASE_STATUS_COLOR[value]}>
						<Fragment>
							{t(RISK_CASE_STATUS_TRANSLATIONS[value])}
						</Fragment>
					</Tag>
				)
			}
		}
	];

	const { partnerName, ...exportFilters } = filters;

	return (
		<div className="dashboard-section table-section">
			<div className="dashboard-section-content">
				<div className="table-header">
					<div className="table-buttons-dropdowns">
						{hasPermission({ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY, action: PERMISSION_ACTION.EXPORT }) && (
							<ExportButtonImminently
								url={ApiUrls.EXPORT_RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY}
								exportType={EXPORT_TYPE.CSV}
								filters={exportFilters}
								sorting={sorting}
							/>
						)}
					</div>
					<HistoryFilters />
				</div>
				<Table
					loading={isLoading}
					columns={columns}
					data={playerSuspiciousActivitiesHistory}
					loadFn={getPlayerSuspiciousActivitiesHistory}
					sorting={sorting}
					setSortingFn={setPlayerSuspiciousActivitiesHistorySorting}
					total={total}
					detailsType="table"
					uniqueKey="id"
				/>
			</div>
		</div>
	);
};

/**
 * PlayerSuspiciousActivitiesHistory PropTypes
 */
PlayerSuspiciousActivitiesHistory.propTypes = {
	filters: PropTypes.object,
	sorting: PropTypes.object,
	isLoading: PropTypes.bool,
	playerSuspiciousActivitiesHistory: PropTypes.arrayOf(PropTypes.object),
	total: PropTypes.number,
	getPlayerSuspiciousActivitiesHistory: PropTypes.func,
	setPlayerSuspiciousActivitiesHistorySorting: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getPlayerSuspiciousActivitiesHistory: (fromFirstPage) => {
		dispatch(getPlayerSuspiciousActivitiesHistory(fromFirstPage));
	},
	setPlayerSuspiciousActivitiesHistorySorting: (sorting) => {
		dispatch(setPlayerSuspiciousActivitiesHistorySorting(sorting));
	}
});

const mapStateToProps = (state) => ({
	filters: state.riskManagement.playerSuspiciousActivities.history.filters,
	sorting: state.riskManagement.playerSuspiciousActivities.history.sorting,
	isLoading: state.riskManagement.playerSuspiciousActivities.history.isLoading,
	playerSuspiciousActivitiesHistory: state.riskManagement.playerSuspiciousActivities.history.data,
	total: state.riskManagement.playerSuspiciousActivities.history.total
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerSuspiciousActivitiesHistory);
