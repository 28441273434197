import {
	PARTNERS_ACTION_BEFORE,
	PARTNERS_ACTION_FINISH,
	PARTNERS_ACTION_SET_PARTNERS,
	PARTNER_SAVE_ACTION_BEFORE,
	PARTNER_SAVE_ACTION_FINISH,
	PARTNER_SAVE_ACTION_SUCCESS,
	PARTNERS_SET_SORTING,
	PARTNERS_SET_FILTERS,
	PARTNER_WALLET_BEFORE,
	PARTNER_WALLET_FINISH,
	PARTNER_WALLET_SAVE_BEFORE,
	PARTNER_WALLET_SAVE_FINISH,
	PARTNER_SET_WALLET,
	PARTNER_UPDATE_WALLET,
	PARTNER_ACTIVITY_BEFORE,
	PARTNER_ACTIVITY_FINISH,
	PARTNER_SET_PARTNER_ACTIVITY,
	PARTNER_SET_ACTIVITY_SORTING,
	PARTNER_SET_ACTIVITY_FILTERS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case PARTNERS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case PARTNERS_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case PARTNERS_ACTION_SET_PARTNERS:
			return {
				...state,
				partners: payload.partners.item2,
				total: payload.partners.item1
			};
		case PARTNER_SAVE_ACTION_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case PARTNER_SAVE_ACTION_FINISH:
			return {
				...state,
				isSaving: false
			};
		case PARTNER_SAVE_ACTION_SUCCESS:
			return {
				...state,
				partners: [...state.partners, payload.data]
			};
		case PARTNERS_SET_FILTERS:
			return {
				...state,
				filters: payload.filters
			};
		case PARTNERS_SET_SORTING:
			return {
				...state,
				sorting: payload.sorting
			};
		case PARTNER_WALLET_BEFORE:
			return {
				...state,
				wallet: {
					...state.wallet,
					isLoading: true
				}
			};
		case PARTNER_WALLET_FINISH:
			return {
				...state,
				wallet: {
					...state.wallet,
					isLoading: false
				}
			};
		case PARTNER_WALLET_SAVE_BEFORE:
			return {
				...state,
				wallet: {
					...state.wallet,
					isSaving: true
				}
			};
		case PARTNER_WALLET_SAVE_FINISH:
			return {
				...state,
				wallet: {
					...state.wallet,
					isSaving: false
				}
			};
		case PARTNER_SET_WALLET:
			return {
				...state,
				wallet: {
					...state.wallet,
					wallet: payload.wallet
				}
			};
		case PARTNER_UPDATE_WALLET:
			return {
				...state,
				partners: state.partners.map((p) => (p.id === payload.id ? { ...p, balance: payload.balance } : p))
			};
		case PARTNER_ACTIVITY_BEFORE:
			return {
				...state,
				viewingPartner: {
					...state.viewingPartner,
					activity: {
						...state.viewingPartner.activity,
						isLoading: true
					}
				}
			};
		case PARTNER_ACTIVITY_FINISH:
			return {
				...state,
				viewingPartner: {
					...state.viewingPartner,
					activity: {
						...state.viewingPartner.activity,
						isLoading: false
					}
				}
			};
		case PARTNER_SET_PARTNER_ACTIVITY:
			return {
				...state,
				viewingPartner: {
					...state.viewingPartner,
					activity: {
						...state.viewingPartner.activity,
						activities: payload.data.item2,
						total: payload.data.item1
					}
				}
			};
		case PARTNER_SET_ACTIVITY_SORTING:
			return {
				...state,
				viewingPartner: {
					...state.viewingPartner,
					activity: {
						...state.viewingPartner.activity,
						sorting: payload.sorting
					}
				}
			};
		case PARTNER_SET_ACTIVITY_FILTERS:
			return {
				...state,
				viewingPartner: {
					...state.viewingPartner,
					activity: {
						...state.viewingPartner.activity,
						filters: payload.filters,
						sorting: {
							...state.viewingPartner.activity.sorting,
							page: 1
						}
					}
				}
			};
		default:
			return state;
	}
};
