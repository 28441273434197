import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Tag } from "antd";

import Table from 'components/common/table';
import Filters from './filters.component';
import ExportButton from 'components/common/exportButton';
import Breadcrumbs from 'components/common/breadcrumbs';
import JackpotChangeState from './changeState';
import JackpotQuickInfo from './quickInfo/quickinfo.component';
import CreateJackpot from './CreateJackpot';

import { mergeClassNames } from 'utils/common';
import { hasPermission } from 'utils/permissions';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import ApiUrls from 'constants/api.constants';
import Paths from 'constants/path.constants';
import { DYNAMIC_PATH_ID_REGEX } from 'constants/regex.constants';
import { JACKPOTS_TYPES, JACKPOT_STATE } from 'constants/bonus.constants';
import jackpotType from 'types/bonus/jackpot.type';
import sortingType from 'types/common/sorting.type';
import { getJackpots, setJackpotBonusesFilters, setJackpotBonusesSorting } from 'store/actions/dashboard/bonuses/bonuses.action';
import { DATE_TIME_FORMAT } from 'constants/date.constants';

/** Jackpots Page Component */
const JackpotComponent = ({
	isLoading,
	globalPartnerId,
	jackpots,
	total,
	getJackpots,
	sorting,
	filters,
	setJackpotBonusesSorting,
	setJackpotBonusesFilters,
}) => {

	const navigate = useNavigate();
	const { t } = useTranslation();

	const [showCreatePopup, setShowCreatePopup] = useState(false);
	const [showChangeStatePopup, setShowStateChangePopup] = useState(null);
	const [selectedJackpot, setSelectedJackpot] = useState(null);

	const hasViewPermission = hasPermission({ resource: PERMISSION_RESOURCE.BONUS_JACKPOT, action: PERMISSION_ACTION.VIEW })
	const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.BONUS_JACKPOT, action: PERMISSION_ACTION.MODIFY })

	/** Columns of table */
	const columns = [
		{
			title: t('pages.dashboard.bonuses.id'),
			dataIndex: 'id',
			render: (value, record) => {
				const isQuickViewEnabled = record.state === JACKPOT_STATE.ACTIVE || record.state === JACKPOT_STATE.PAUSED;

				return (
					(
						<span
							className={mergeClassNames(isQuickViewEnabled && "table-col-link")}
							onClick={() => isQuickViewEnabled && setSelectedJackpot(record)}
						>
							{value}
						</span>
					)
				)
			},
			sorter: false
		},
		{
			title: t('pages.dashboard.bonuses.jackpot_name'),
			dataIndex: 'name',
			sorter: true
		},
		{
			title: t('pages.dashboard.bonuses.network_type'),
			dataIndex: 'partnerName',
			render: value => (
				<div className="row-with-icon">
					<i className={value ? "icon-partner-specific" : "icon-generic"}></i>
					<span>{value ? value : t('pages.dashboard.bonuses.generic')}</span>
				</div>
			),
			sorter: true
		},
		{
			title: t('pages.dashboard.bonuses.jackpot_type'),
			dataIndex: 'type',
			render: value => {
				switch (value) {
					case JACKPOTS_TYPES.ONLINE:
						return t('pages.dashboard.bonuses.jackpot_type_online');
					case JACKPOTS_TYPES.RETAIL:
						return t('pages.dashboard.bonuses.jackpot_type_retail');
					default:
						return ""
				}
			}
		},
		{
			title: t('pages.dashboard.bonuses.status'),
			dataIndex: 'state',
			render: (value, record) => renderStateComponent(value, record),
		},
		{
			title: t('pages.dashboard.bonuses.creationDate'),
			dataIndex: 'createTime',
			render: value => value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : " - "
		},
		{
			title: t('pages.dashboard.bonuses.startDate'),
			dataIndex: 'startTime',
			render: value => value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : " - "
		},
		{
			title: t('pages.dashboard.bonuses.endDate'),
			dataIndex: 'finishTime',
			render: value => value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : " - "
		}
	];

	const generateAction = () => {
		const mainObj = {}
		const navigateFunction = record => { navigate(Paths.DASHBOARD_BONUSES_JACKPOT_EDIT.replace(DYNAMIC_PATH_ID_REGEX, record.id)) }

		switch (true) {
			case hasModifyPermission:
				mainObj.view = {
					handler: navigateFunction,
					hidden: record => record.state === JACKPOT_STATE.INACTIVE
				}
				mainObj.edit = {
					handler: navigateFunction,
					hidden: record => record.state !== JACKPOT_STATE.INACTIVE
				}
				break;
		case hasViewPermission:
				mainObj.view = {
					handler: navigateFunction
				}
				break;
			default:
				break;
		}

		return mainObj;
	}

	/** Get the state background color in table
		* @function
		* @param {strong} state - state
		* @returns {string} - color
		* @memberOf BonusesComponent
  */
	const getStateColor = state => {
		const mapping = {
			[JACKPOT_STATE.INACTIVE]: "orange",
			[JACKPOT_STATE.ACTIVE]: "green",
			[JACKPOT_STATE.PAUSED]: "cyan",
			[JACKPOT_STATE.FINISHED]: "blue",
		}
		return mapping[state];
	}

	const renderStateComponent = (value, record) => {
		const handleStateTagClick = (e, record) => {
			e.stopPropagation();
			setShowStateChangePopup(record);
		}

		const allowChangeState = (
			record.isDraft
				? false
				: hasModifyPermission && (record.state !== JACKPOT_STATE.FINISHED)
		);

		

		return (
			(
				<Tag color={getStateColor(value)}>
					<Fragment>
						{t('pages.dashboard.bonuses.jackpot_status_' + (value || JACKPOT_STATE.FINISHED))}
						{
							allowChangeState
								? (
									<i className="icon-edit" onClick={e => handleStateTagClick(e, record)} />
								)
								: null
						}
					</Fragment>
				</Tag>
			)
		)
	}

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t('pages.dashboard.menu.bonuses_jackpot'), path: "" }]} />
				<div className="dashboard-section-content">
					<div className="table-header">
						<div className="table-buttons-dropdowns">
							{
								hasPermission({ resource: PERMISSION_RESOURCE.BONUS_JACKPOT, action: PERMISSION_ACTION.EXPORT })
									? (
										<ExportButton
											columns={columns.map(c => ({ title: c.title, key: c.dataIndex }))}
											tableName={t("pages.dashboard.menu.bonuses_jackpot")}
											url={ApiUrls.EXPORT_JACKPOTS}
											sorting={sorting}
										/>
									)
									: null
							}
							{
								hasPermission({ resource: PERMISSION_RESOURCE.BONUS_JACKPOT, action: PERMISSION_ACTION.CREATE })
									? (
										<div className="table-buttons-dropdowns-button">
											<Button
												onClick={() => setShowCreatePopup(true)}
												type="primary"
											>
												{t('pages.dashboard.bonuses.create_jackpot')}
											</Button>
										</div>
									)
									: null
							}
						</div>
						<Filters />
					</div>
					<Table
						loading={isLoading}
						columns={columns}
						data={jackpots}
						loadFn={getJackpots}
						sorting={sorting}
						filters={filters}
						setSortingFn={setJackpotBonusesSorting}
						setFiltersFn={setJackpotBonusesFilters}
						total={total}
						updateProps={[globalPartnerId]}
						actions={generateAction()}
						isDisabled={() => false}
						enableReload={true}
					/>
				</div>
			</div>
			{
				showCreatePopup ? <CreateJackpot onClose={() => setShowCreatePopup(false)} /> : null
			}
			{
				showChangeStatePopup !== null && (
					<JackpotChangeState jackpot={showChangeStatePopup} onClose={() => setShowStateChangePopup(null)} />
				)
			}
			{
				selectedJackpot !== null && (
					<JackpotQuickInfo jackpot={selectedJackpot} onClose={() => setSelectedJackpot(null)} />
				)
			}
		</Fragment>
	)
}

/** JackpotComponent propTypes
	 * PropTypes
*/
JackpotComponent.propTypes = {
	/** Redux action to get bonuses */
	getJackpots: PropTypes.func,
	/** Redux state property, represents the array of bonuses  */
	jackpots: PropTypes.arrayOf(jackpotType),
	/** Redux state property, is true when loading bonuses */
	isLoading: PropTypes.bool,
	/** Redux state property, bonuses total count */
	total: PropTypes.number,
	/** Redux action to set bonuses sorting details */
	setJackpotBonusesSorting: PropTypes.func,
	/** Redux action to set bonuses filters details */
	setJackpotBonusesFilters: PropTypes.func,
	/** Redux state property, bonuses sorting details */
	sorting: sortingType,
	/** Redux state property, bonuses filters details */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
}

const mapDispatchToProps = dispatch => (
	{
		getJackpots: fromFirstPage => {
			dispatch(getJackpots(fromFirstPage))
		},
		setJackpotBonusesSorting: sorting => {
			dispatch(setJackpotBonusesSorting(sorting))
		},
		setJackpotBonusesFilters: filters => {
			dispatch(setJackpotBonusesFilters(filters))
		}
	}
)

const mapStateToProps = state => {
	return {
		isLoading: state.bonuses.jackpots.isLoading,
		jackpots: state.bonuses.jackpots.data,
		total: state.bonuses.jackpots.total,
		sorting: state.bonuses.jackpots.sorting,
		filters: state.bonuses.jackpots.filters,
		globalPartnerId: state.partner.globalPartnerId
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(JackpotComponent);
