import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Col, Row, Modal } from "antd";
const { Item: FormItem } = Form;

import SearchableSelect from "components/common/searchableSelect";

import { addSystemLanguage, getAvailableLanguages, getSystemAvailableLanguages } from "store/actions/dashboard/settings/systemLanguages/systemLanguages.action";

import { POPUP_SIZE } from "constants/common.constants";

/** System Language Adding Popup Component */
const SystemLanguageAddComponent = ({ isSaving, addSystemLanguage, getAvailableLanguages, getSystemAvailableLanguages, onClose, availableLanguages, systemAvailableLanguages, isAvailableLoading, onCreateCallback = Function.prototype }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;

	/** Load available languages */
	useEffect(() => {
		if (Object.keys(availableLanguages).length === 0) {
			getAvailableLanguages();
		}
		getSystemAvailableLanguages();
	}, []);

	/** Fires when form submitted
	 * @function
	 * @memberOf SystemLanguageAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				addSystemLanguage(data.code, onCreateCallback);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Modal open={true} title={t("pages.dashboard.language.create_language")} cancelText={t("common.cancel")} okText={t("common.save")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.SMALL} maskClosable={false} closable={false} okButtonProps={{ loading: isSaving }} centered>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					code: ""
				}}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={`${t("pages.dashboard.language.select_language")} *`} name="code" rules={[{ required: true, message: t("validation.field_required") }]}>
							<SearchableSelect
								loading={isAvailableLoading}
								placeholder={t("pages.dashboard.language.select_language_placeholder")}
								items={Object.keys(availableLanguages)}
								valueProp={(item) => item.toUpperCase()}
								textProp={(item) => availableLanguages[item]}
								renderText={(item) => item.toUpperCase() + " - " + availableLanguages[item]}
								isOptionDisabled={(item) => Object.keys(systemAvailableLanguages).includes(item)}
								getPopupContainer={() => document.body}
							/>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** SystemLanguageAddComponent propTypes
 * PropTypes
 */
SystemLanguageAddComponent.propTypes = {
	/** Redux state property, is true when language is adding */
	isSaving: PropTypes.bool,
	/** Redux action to add system language */
	addSystemLanguage: PropTypes.func,
	/** Redux action to get available languages in system */
	getAvailableLanguages: PropTypes.func,
	/** Redux action to get system available languages in system */
	getSystemAvailableLanguages: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Redux state property, represents available languages  */
	availableLanguages: PropTypes.object,
	/** Redux state property, represents system available languages  */
	systemAvailableLanguages: PropTypes.object,
	/** Redux state property, is true when available languages are loading */
	isAvailableLoading: PropTypes.bool,
	/** React property function, for calling after create new language */
	onCreateCallback: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	addSystemLanguage: (code, callback) => {
		dispatch(addSystemLanguage(code, callback));
	},
	getAvailableLanguages: () => {
		dispatch(getAvailableLanguages());
	},
	getSystemAvailableLanguages: () => {
		dispatch(getSystemAvailableLanguages());
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.systemLanguages.isSaving,
		isAvailableLoading: state.systemLanguages.isAvailableLoading,
		availableLanguages: state.systemLanguages.availableLanguages,
		systemAvailableLanguages: state.systemLanguages.systemAvailableLanguages
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemLanguageAddComponent);
