import PropTypes from "prop-types";

export default PropTypes.shape({
	id: PropTypes.string,
	partnerId: PropTypes.string,
	name: PropTypes.string,
	code: PropTypes.string,
	groupId: PropTypes.string,
	address: PropTypes.string,
	tel: PropTypes.string,
	email: PropTypes.string,
	ticketLanguageCode: PropTypes.string,
	liveMonitorLanguageCode: PropTypes.string,
	isTesting: PropTypes.bool,
	betCount: PropTypes.number,
	totalTurnover: PropTypes.number,
	totalWin: PropTypes.number,
	totalPayout: PropTypes.number,
	totalCanceled: PropTypes.number,
	ggr: PropTypes.number,
	ngr: PropTypes.number,
	created: PropTypes.string,
	lastUpdate: PropTypes.string,
	enabled: PropTypes.bool
});
