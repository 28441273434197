import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { PARTNER_ACTION_SET_RETAIL_CONFIGS, PARTNER_ACTION_SET_RETAIL_RTP, SET_PARTNER_LOGO } from "../../../actionTypes";

import { setPartnerActionBefore, setPartnerActionFinished, setPartnerSaveActionBefore, setPartnerSaveActionFinished } from "./partner.action";
import { getUserInfo } from "store/actions/dashboard/profile/userInfo.action";

const setPartnerActionSetRetailConfigs = (configs) => ({
	type: PARTNER_ACTION_SET_RETAIL_CONFIGS,
	payload: { configs }
});

const setPartnerActionSetRetailRTP = (rtp) => ({
	type: PARTNER_ACTION_SET_RETAIL_RTP,
	payload: { rtp }
});

export const getPartnerRetailConfigs = () => {
	return (dispatch) => {
		dispatch(setPartnerActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_RETAIL_CONFIGS}`,
			method: Methods.GET
		})
			.then(({ data: { value: configs } }) => {
				dispatch(setPartnerActionSetRetailConfigs(configs));
				dispatch(setPartnerActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerActionFinished());
			});
	};
};

export const savePartnerRetailConfigs = (configs) => {
	return (dispatch) => {
		dispatch(setPartnerSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SET_PARTNER_RETAIL_CONFIGS}`,
			method: Methods.POST,
			data: configs
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					dispatch(setPartnerActionSetRetailConfigs(data.value));
					dispatch(getUserInfo(true));
				}
				dispatch(setPartnerSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerSaveActionFinished());
			});
	};
};

export const getPartnerRetailRTP = () => {
	return (dispatch) => {
		dispatch(setPartnerActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_RETAIL_RTP}`,
			method: Methods.GET
		})
			.then(({ data: { value: rtp } }) => {
				dispatch(setPartnerActionSetRetailRTP(rtp));
				dispatch(setPartnerActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerActionFinished());
			});
	};
};

export const savePartnerRetailRTP = (rtp) => {
	return (dispatch) => {
		dispatch(setPartnerSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SET_PARTNER_RETAIL_RTP}`,
			method: Methods.POST,
			data: rtp
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					dispatch(setPartnerActionSetRetailRTP(data.value));
				}

				dispatch(setPartnerSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerSaveActionFinished());
			});
	};
};

export const setLogoId = (logoId) => ({
	type: SET_PARTNER_LOGO,
	payload: { logoId }
});

export const deleteLogo = (data) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.PARTNER_DELETE_LOGO}`,
			method: Methods.DELETE,
			data
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setLogoId(null));
				}
			})
			.catch((ex) => {
				console.log(ex);
			});
	};
};
