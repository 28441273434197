import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Button, Spin, Switch } from "antd";
const { Item: FormItem } = Form;

import { getApiKeyPlatforms, saveApiKeyPlatforms } from "store/actions/dashboard/online/apikeys/platforms.action";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { GAME_CATEGORY, REDUX_EDITING_API_KEY_PLATFORMS_FIELD } from "constants/game.constants";
import platformsType from "types/apiKey/platforms.type";

/** Api Key Edit Page Platforms Tab Component */
const PlatformComponent = ({ getApiKeyPlatforms, saveApiKeyPlatforms, isSaving, isLoading, platforms, gameCategory }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_PLATFORMS, action: PERMISSION_ACTION.MODIFY });

	/** Load api key platforms */
	useEffect(() => {
		if (routeParams.id) {
			getApiKeyPlatforms(routeParams.id, gameCategory);
		}
	}, [routeParams.id, gameCategory]);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			...platforms
		});
	}, [platforms]);

	/** Fires when form submitted
	 * @function
	 * @memberOf PlatformComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				saveApiKeyPlatforms(
					{
						id: routeParams.id,
						...data
					},
					gameCategory
				);
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					desktop: platforms.desktop,
					android: platforms.android,
					ios: platforms.ios
				}}
				onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, { ...platforms }))}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={8} xl={5}>
								<div className="dashboard-card">
									<div className="dashboard-card-row">
										<div className="apikey-integration-row">
											<div className="apikey-integration-row-title">
												<h5>{t("pages.dashboard.apikeys.desktop")}</h5>
											</div>
											<FormItem className="inline-form-item-control apikey-integration-row-switch" name="desktop" valuePropName="checked">
												<Switch disabled={isDisabled} />
											</FormItem>
										</div>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={8} xl={5}>
								<div className="dashboard-card">
									<div className="dashboard-card-row">
										<div className="apikey-integration-row">
											<div className="apikey-integration-row-title">
												<h5>{t("pages.dashboard.apikeys.ios")}</h5>
											</div>
											<FormItem className="inline-form-item-control apikey-integration-row-switch" name="ios" valuePropName="checked">
												<Switch disabled={isDisabled} />
											</FormItem>
										</div>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={8} xl={5}>
								<div className="dashboard-card">
									<div className="dashboard-card-row">
										<div className="apikey-integration-row">
											<div className="apikey-integration-row-title">
												<h5>{t("pages.dashboard.apikeys.android")}</h5>
											</div>
											<FormItem className="inline-form-item-control apikey-integration-row-switch" name="android" valuePropName="checked">
												<Switch disabled={isDisabled} />
											</FormItem>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>
					{!isDisabled && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** PlatformComponent propTypes
 * PropTypes
 */
PlatformComponent.propTypes = {
	/** Redux action to get apiKey platforms */
	getApiKeyPlatforms: PropTypes.func,
	/** Redux action to save apiKey platforms */
	saveApiKeyPlatforms: PropTypes.func,
	/** Redux state property, is true when platforms is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when platforms is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the platforms of current editing apiKey  */
	platforms: platformsType,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	getApiKeyPlatforms: (id, gameCategory) => {
		dispatch(getApiKeyPlatforms(id, gameCategory));
	},

	saveApiKeyPlatforms: (data, gameCategory) => {
		dispatch(saveApiKeyPlatforms(data, gameCategory));
	}
});

const mapStateToProps = (state, props) => {
	const { gameCategory } = props;
	return {
		platforms: state.apikeys.editingApikey[REDUX_EDITING_API_KEY_PLATFORMS_FIELD[gameCategory]],
		isSaving: state.apikeys.isSaving,
		isLoading: state.apikeys.isLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlatformComponent);
