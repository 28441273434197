import axios from "axios";
import { message } from "antd";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { RESPONSE_STATE } from "constants/response.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import { TRANSACTION_TYPE } from "constants/transactions.constants";

import {
	TRANSACTIONS_BEFORE,
	TRANSACTIONS_FINISH,
	SET_TRANSACTIONS,
	UPDATE_TRANSACTION,
	SET_TRANSACTIONS_SORTING,
	SET_TRANSACTIONS_FILTERS,
	TRANSACTION_HISTORY_BEFORE,
	TRANSACTION_HISTORY_FINISH,
	TRANSACTION_RESEND_BEFORE,
	TRANSACTION_RESEND_FINISH,
	SET_TRANSACTION_HISTORY,
	SET_ROLLBACKS_ACTIVE_TAB,
	SET_RESULTS_ACTIVE_TAB,
	SET_TRANSACTIONS_ACTIVE_TAB
} from "../../../actionTypes";

const setTransactionsBefore = () => ({
	type: TRANSACTIONS_BEFORE
});

const setTransactionsFinished = () => ({
	type: TRANSACTIONS_FINISH
});

const setTransactionHistoryBefore = () => ({
	type: TRANSACTION_HISTORY_BEFORE
});

const setTransactionHistoryFinished = () => ({
	type: TRANSACTION_HISTORY_FINISH
});

const setTransactionsResendBefore = () => ({
	type: TRANSACTION_RESEND_BEFORE
});

const setTransactionsResendFinished = () => ({
	type: TRANSACTION_RESEND_FINISH
});

const setTransactions = (transactions, projectType, type) => ({
	type: SET_TRANSACTIONS,
	payload: { transactions, projectType, type }
});

const setTransactionHistory = (id, history, projectType, type) => ({
	type: SET_TRANSACTION_HISTORY,
	payload: { id, history, projectType, type }
});

const updateTransaction = (transaction, projectType, type) => ({
	type: UPDATE_TRANSACTION,
	payload: { transaction, projectType, type }
});

export const setTransactionsSorting = (sorting, projectType, type) => ({
	type: SET_TRANSACTIONS_SORTING,
	payload: { sorting, projectType, type }
});

export const setTransactionsFilters = (filters, projectType, type) => ({
	type: SET_TRANSACTIONS_FILTERS,
	payload: { filters, projectType, type }
});

export const getTransactions = (projectType, type, fromFirstPage) => {
	return (dispatch, getState) => {
		dispatch(setTransactionsBefore());

		const projectT = projectType === PROJECT_TYPE.ONLINE ? "online" : "retail";
		const t = type === TRANSACTION_TYPE.ROLLBACK ? "rollbacks" : "results";

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_TRANSACTIONS}`,
			method: Methods.GET,
			params: {
				...getState().transactions[t][projectT].sorting,
				...getState().transactions[t][projectT].filters,
				projectType: projectType,
				type: type,
				page: fromFirstPage ? 1 : getState().transactions[t][projectT].sorting.page
			}
		})
			.then(({ data: { value: transactions } }) => {
				dispatch(setTransactions(transactions, projectType, type));
				if (fromFirstPage) dispatch(setTransactionsSorting({ ...getState().transactions[t][projectT].sorting, page: 1 }, projectType, type));
				dispatch(setTransactionsFinished());
			})
			.catch(() => {
				dispatch(setTransactionsFinished());
			});
	};
};

export const getTransactionHistory = (id, projectType, type) => {
	return (dispatch) => {
		dispatch(setTransactionHistoryBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_TRANSACTIONS_HISTORY}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setTransactionHistory(id, data, projectType, type));
				dispatch(setTransactionHistoryFinished());
			})
			.catch(() => {
				dispatch(setTransactionHistoryFinished());
			});
	};
};

export const setRollbacksActiveTab = (tab) => ({
	type: SET_ROLLBACKS_ACTIVE_TAB,
	payload: { tab }
});

export const setResultsActiveTab = (tab) => ({
	type: SET_RESULTS_ACTIVE_TAB,
	payload: { tab }
});

export const setTransactionsActiveTab = (tab) => ({
	type: SET_TRANSACTIONS_ACTIVE_TAB,
	payload: { tab }
});

export const resendTransaction = (id, projectType, type) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.RESEND_TRANSACTION}`,
			method: Methods.POST,
			data: { id }
		})
			.then(({ data: { message: msg, status, value: transaction } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(updateTransaction(transaction, projectType, type));
				}
			})
			.catch(Function.prototype);
	};
};

export const resendAllTransactions = (projectType, type) => {
	return (dispatch, getState) => {
		dispatch(setTransactionsResendBefore());

		const projectT = projectType === PROJECT_TYPE.ONLINE ? "online" : "retail";
		const t = type === TRANSACTION_TYPE.ROLLBACK ? "rollbacks" : "results";

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.RESEND_ALL_TRANSACTIONS}`,
			method: Methods.POST,
			data: {
				...getState().transactions[t][projectT].sorting,
				...getState().transactions[t][projectT].filters,
				projectType: projectType,
				type: type,
				page: getState().transactions[t][projectT].sorting.page
			}
		})
			.then(({ data: { message: msg, status, value: transactions } }) => {
				dispatch(setTransactionsBefore());

				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setTransactions(transactions, projectType, type));
				}
				setTimeout(() => {
					dispatch(setTransactionsFinished());
				}, 0);
				dispatch(setTransactionsResendFinished());
			})
			.catch(() => {
				dispatch(setTransactionsResendFinished());
			});
	};
};
