import PropTypes from "prop-types";

import { SCHEDULED_GAME_TYPE, INSTANT_GAME_TYPE, GAME_STATUSES } from "constants/game.constants";
import { ODD_STATE } from "constants/bet.constants";

export default PropTypes.shape({
	id: PropTypes.number,
	startTime: PropTypes.string,
	finishTime: PropTypes.string,
	gameType: PropTypes.oneOf([...Object.values(SCHEDULED_GAME_TYPE), ...Object.values(INSTANT_GAME_TYPE)].map((v) => v.value)),
	status: PropTypes.oneOf(Object.values(GAME_STATUSES)),
	markets: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			group: PropTypes.string,
			period: PropTypes.number,
			argument: PropTypes.number,
			oddList: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.string,
					outcome: PropTypes.string,
					factor: PropTypes.number,
					state: PropTypes.oneOf(Object.values(ODD_STATE))
				})
			)
		})
	),
	gameName: PropTypes.string,
	betSlipsCount: PropTypes.number,
	totalTurnover: PropTypes.number,
	totalGGR: PropTypes.number,
	totalNGR: PropTypes.number,
	totalWin: PropTypes.number,
	totalPayout: PropTypes.number,
	bettingPeriodStartTime: PropTypes.string,
	bettingPeriodEndTime: PropTypes.string,
	gameData: PropTypes.shape({
		scenes: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
				time: PropTypes.string,
				period: PropTypes.number,
				teamNumber: PropTypes.number,
				isGoalScene: PropTypes.bool
			})
		)
	})
});
