import localStorageUtils from "utils/localStorage";
import { ORDER_DIRECTION } from "constants/common.constants";

export const getDefaultStateOfMainRules = (gameType) => {
	return {
		selectedGameType: gameType,
		systemDefault: localStorageUtils.get("rulesSystemDefaultTranslation") === null ? true : localStorageUtils.get("rulesSystemDefaultTranslation"),
		data: [],
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "Date",
			orderDirection: ORDER_DIRECTION.DESC
		}
	};
};

export const getDefaultStateOfHistoryRules = () => {
	return {
		data: [],
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "Date",
			orderDirection: ORDER_DIRECTION.DESC
		}
	};
};
