import PropTypes from "prop-types";

import { Form, Col, Select, DatePicker, Tooltip } from "antd";
import locale from "antd/es/date-picker/locale/en_US";
import NumericInput from "components/common/numericInput";
import SearchableSelect from "components/common/searchableSelect";

import { fieldsValidation, disablePreviousDays, required, betTypeFieldValidation, disableTimeUntilNow } from "utils/bonuses";
import { flagsToBinary } from "utils/common";

import { BONUS_TYPE, BONUS_FIELDS_RANGE, BONUS_WIN_TYPE, FIELD_NAMES } from "constants/bonus.constants";
import { ALL_OPTION_ID } from "constants/common.constants";
import { SCHEDULED_GAME_TYPE_MAPPER } from "constants/game.constants";
import { BETSLIP_MODES } from "constants/bet.constants";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

const FreebetView = ({ handleBonusTypeFieldChange, handleBetshopFieldChange, handleGameTypeFieldChange, handleCurrencyFieldChange, availableCurrencies, betshops, games, t, isEditMode = false, isBonusEditingDisabled = false, isLoading, isAvailableLoading }) => {
	/**
	 * this component is used in create and edit modes
	 * isEditMode indicates which mode is current
	 * isBonusEditingDisabled indicates that fields can not be edited (when bonus active or finished)
	 * */

	const columnsAmountForLargeScreens = isEditMode ? 6 : 12;

	return (
		<>
			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item name={FIELD_NAMES.BONUSE_TYPE} label={t("pages.dashboard.bonuses.type")}>
					<Select
						placeholder={t("pages.dashboard.bonuses.type")}
						suffixIcon={<i className="icon-down" />}
						onChange={handleBonusTypeFieldChange}
						// this field must be disabled in edit mode
						disabled={isEditMode}
					>
						<Select.Option value={BONUS_TYPE.FREEBET}>{t("pages.dashboard.bonuses.freebet")}</Select.Option>

						<Select.Option value={BONUS_TYPE.DOUBLEDOOBLE}>{t("pages.dashboard.bonuses.doubleDooble")}</Select.Option>
					</Select>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item name={FIELD_NAMES.CURRENCY} label={`${t("pages.dashboard.bonuses.currency")} *`} rules={[required(t)]}>
					<Select
						placeholder={t("pages.dashboard.bonuses.currency")}
						suffixIcon={<i className="icon-down" />}
						disabled={isEditMode || isAvailableLoading} // this field must be disabled in edit mode
						onChange={handleCurrencyFieldChange}
					>
						{Object.keys(availableCurrencies).map((currencyCode) => {
							return (
								<Select.Option key={currencyCode} value={currencyCode}>
									{`${currencyCode.toUpperCase()} - ${availableCurrencies[currencyCode]}`}
								</Select.Option>
							);
						})}
					</Select>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item shouldUpdate={true} noStyle>
					{({ getFieldValue }) => {
						const selectedBetshopIds = getFieldValue(FIELD_NAMES.BETSHOP_IDS);
						const filteredBetshops = betshops.filter((betshop) => selectedBetshopIds.includes(betshop.key));
						const isCurrencySelcted = getFieldValue(FIELD_NAMES.CURRENCY).length > 0;

						return (
							<Tooltip placement="bottom" title={isBonusEditingDisabled && filteredBetshops.length > 1 ? filteredBetshops.map((betshop) => <div key={betshop.key}>{betshop.value}</div>) : null}>
								<Form.Item name={FIELD_NAMES.BETSHOP_IDS} label={`${t("pages.dashboard.bonuses.betshopIds")} *`} rules={[required(t)]}>
									<SearchableSelect
										mode="multiple"
										placeholder={t("pages.dashboard.bonuses.betshopIds")}
										items={betshops.length > 1 ? [{ key: ALL_OPTION_ID, value: t("common.all") }, ...betshops] : betshops}
										valueProp={(betshop) => betshop.key}
										textProp={(betshop) => betshop.value}
										renderText={(betshop) => betshop.value}
										onChange={handleBetshopFieldChange}
										disabled={isBonusEditingDisabled || !isCurrencySelcted || isLoading}
									/>
								</Form.Item>
							</Tooltip>
						);
					}}
				</Form.Item>
			</Col>
			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item shouldUpdate={true} noStyle>
					{({ getFieldValue }) => {
						const gameTypes = getFieldValue(FIELD_NAMES.GAME_TYPE);
						const isBetshopsSelected = getFieldValue(FIELD_NAMES.BETSHOP_IDS).length > 0;
						return (
							<Tooltip placement="bottom" title={isBonusEditingDisabled && gameTypes.length > 1 ? gameTypes.map((game) => <div key={game}>{t(`common.${SCHEDULED_GAME_TYPE_MAPPER[game]}`)}</div>) : null}>
								<Form.Item name={FIELD_NAMES.GAME_TYPE} label={`${t("pages.dashboard.bonuses.gameType")} *`} rules={[required(t)]}>
									<Select mode="multiple" maxTagCount={1} placeholder={`${t("common.select")} ${t("pages.dashboard.bonuses.gameType")}`} onChange={handleGameTypeFieldChange} suffixIcon={<i className="icon-down" />} disabled={isBonusEditingDisabled || !isBetshopsSelected || isLoading}>
										{games.length > 1 ? <Select.Option value={ALL_OPTION_ID}>{t("common.all")}</Select.Option> : null}
										{games.map((game) => {
											return (
												<Select.Option key={game} value={game}>
													{t(`common.${SCHEDULED_GAME_TYPE_MAPPER[game]}`)}
												</Select.Option>
											);
										})}
									</Select>
								</Form.Item>
							</Tooltip>
						);
					}}
				</Form.Item>
			</Col>

			<div className='vs--bonus-quantity-amount-label-section'>
				<Col xs={12} sm={12} xl={12}>
					<Form.Item
						name={FIELD_NAMES.QUANTITY}
						label={`${t("pages.dashboard.bonuses.quantity")} x ${t("pages.dashboard.bonuses.amount")} *`}
						rules={[
							required(t),
							() => ({
								validator(_, value) {
									return fieldsValidation({
										value,
										rangeArr: BONUS_FIELDS_RANGE.QUANTITY,
										translationFunc: t,
										fieldNameStr: FIELD_NAMES.QUANTITY
									});
								}
							})
						]}
					>
						<NumericInput placeholder={t("pages.dashboard.bonuses.quantity")} disabled={isBonusEditingDisabled} />
					</Form.Item>
				</Col>
				<Col>
					<Form.Item label={" "}>{" X "}</Form.Item>
				</Col>
				<Col xs={11} sm={11} xl={11}>
					<Form.Item
						name={FIELD_NAMES.AMOUNT}
						label={" "}
						rules={[
							required(t),
							() => ({
								validator(_, value) {
									return fieldsValidation({
										value,
										rangeArr: BONUS_FIELDS_RANGE.AMOUNT,
										translationFunc: t,
										fieldNameStr: FIELD_NAMES.AMOUNT
									});
								}
							})
						]}
					>
						<NumericInput placeholder={t("pages.dashboard.bonuses.amount")} disabled={isBonusEditingDisabled} />
					</Form.Item>
				</Col>
			</div>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.MAX_POSSIBLE_WIN_AMOUNT}
					label={t("pages.dashboard.bonuses.maxPossibleWinAmount")}
					rules={[
						() => ({
							validator(_, value) {
								return fieldsValidation({
									value,
									rangeArr: BONUS_FIELDS_RANGE.MAX_POSSIBLE_WIN,
									translationFunc: t,
									fieldNameStr: FIELD_NAMES.MAX_POSSIBLE_WIN_AMOUNT
								});
							}
						})
					]}
				>
					<NumericInput placeholder={t("pages.dashboard.bonuses.maxPossibleWinAmount")} disabled={isBonusEditingDisabled} />
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.BET_TYPE}
					label={`${t("pages.dashboard.bonuses.betType")} *`}
					dependencies={[FIELD_NAMES.GAME_TYPE]}
					rules={[
						required(t),
						({ getFieldValue }) => ({
							validator(_, value) {
								return betTypeFieldValidation({
									value,
									selectedGameTypes: getFieldValue(FIELD_NAMES.GAME_TYPE),
									translationFunc: t
								});
							}
						})
					]}
				>
					<Select placeholder={`${t("common.select")} ${t("pages.dashboard.bonuses.betType")}`} suffixIcon={<i className="icon-down" />} disabled={isBonusEditingDisabled}>
						<Select.Option value={BETSLIP_MODES.SINGLE}>{t("pages.dashboard.bets.single")}</Select.Option>

						<Select.Option value={BETSLIP_MODES.MULTI}>{t("pages.dashboard.bets.multi")}</Select.Option>

						<Select.Option value={flagsToBinary([BETSLIP_MODES.SINGLE, BETSLIP_MODES.MULTI])}>{`${t("pages.dashboard.bets.single")} or ${t("pages.dashboard.bets.multi")}`}</Select.Option>
					</Select>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item name={FIELD_NAMES.WIN_TYPE} label={t("pages.dashboard.bonuses.win_type")} rules={[required(t)]}>
					<Select placeholder={t("pages.dashboard.bonuses.win_type")} suffixIcon={<i className="icon-down" />} disabled={isBonusEditingDisabled}>
						<Select.Option value={BONUS_WIN_TYPE.PURE}>{t("pages.dashboard.bonuses.pure_win")}</Select.Option>
						<Select.Option value={BONUS_WIN_TYPE.TOTAL}>{t("pages.dashboard.bonuses.total_win")}</Select.Option>
					</Select>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.MIN_ODDS_SINGLE}
					label={t("pages.dashboard.bonuses.minOddsSingle")}
					rules={[
						() => ({
							validator(_, value) {
								return fieldsValidation({
									value,
									rangeArr: BONUS_FIELDS_RANGE.MIN_ODDS_SINGLE,
									translationFunc: t,
									fieldNameStr: FIELD_NAMES.MIN_ODDS_SINGLE
								});
							}
						})
					]}
				>
					<NumericInput placeholder={t("pages.dashboard.bonuses.minOddsSingle")} disabled={isBonusEditingDisabled} />
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.MIN_ODDS_MULTI}
					label={t("pages.dashboard.bonuses.minOddsMulti")}
					rules={[
						() => ({
							validator(_, value) {
								return fieldsValidation({
									value,
									rangeArr: BONUS_FIELDS_RANGE.MIN_ODDS_MULTI,
									translationFunc: t,
									fieldNameStr: FIELD_NAMES.MIN_ODDS_MULTI
								});
							}
						})
					]}
				>
					<NumericInput placeholder={t("pages.dashboard.bonuses.minOddsMulti")} disabled={isBonusEditingDisabled} />
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item name={FIELD_NAMES.END_DATE} label={`${t("pages.dashboard.bonuses.endDate")} *`} rules={[required(t)]}>
					<DatePicker
						placeholder={t("pages.dashboard.bonuses.endDate")}
						format={`${DATE_FORMAT} ${TIME_FORMAT}`}
						showTime={{ format: TIME_FORMAT }}
						disabledDate={disablePreviousDays}
						disabledTime={disableTimeUntilNow}
						disabled={isBonusEditingDisabled}
						allowClear={false}
						locale={{
							...locale,
							lang: {
								...locale.lang,
								ok: t("common.apply")
							}
						}}
					/>
				</Form.Item>
			</Col>
		</>
	);
};

/** FreebetView propTypes
 * PropTypes
 */
FreebetView.propTypes = {
	/** Fires on bonus type field change */
	handleBonusTypeFieldChange: PropTypes.func,
	/** Fires on currency field change */
	handleCurrencyFieldChange: PropTypes.func,
	/** Fires on betshop field change */
	handleBetshopFieldChange: PropTypes.func,
	/** Fires on game type field change */
	handleGameTypeFieldChange: PropTypes.func,
	/**  Translates texts */
	t: PropTypes.func,
	/** Redux state property, represents the object of available currencies */
	availableCurrencies: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	/** Available betshops selected by currency */
	betshops: PropTypes.array,
	/** Available games selected by betshopsIds */
	games: PropTypes.array,
	/** Specifies this type of bonus in edit mode */
	isEditMode: PropTypes.bool,
	/** Indicates whether the bonus can be edited */
	isBonusEditingDisabled: PropTypes.bool,
	/** Redux state property, is true when loading available currencies  */
	isAvailableLoading: PropTypes.bool,
	/** Redux state property, is true when data is loading */
	isLoading: PropTypes.bool
};

export default FreebetView;
