import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Col, Row, Modal, Select, AutoComplete, Divider, Tooltip } from "antd";
const { Item: FormItem } = Form;

import { getSystemAvailableCurrencies } from "store/actions/dashboard/settings/systemCurrencies/systemCurrencies.action";

import SearchableSelect from "components/common/searchableSelect";
import NumericInput from "components/common/numericInput";

import { numberTransform } from "utils/common";
import { favoriteBetsRepeatingIndexes, validateMax, validateMin, validatePosMax } from "utils/currency";
import { isFormChanged } from "utils/form";

import { POPUP_SIZE } from "constants/common.constants";
import { FAVORITE_BETS, FAVORITE_BET_SUM } from "constants/bet.constants";

import currencyType from "types/currency/currency.type";

/** Currency Editing Popup Component */
const CurrencyEditComponent = ({ isSaving, updateCurrency, onClose, editingCurrency, currencies, getSystemAvailableCurrencies, systemCurrencies }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [formInstance] = Form.useForm();
	const { validateFields, getFieldValue, setFieldsValue, getFieldError, getFieldsValue } = formInstance;

	/** Load system currencies */
	useEffect(() => {
		getSystemAvailableCurrencies();
	}, []);

	/** Get initial currency
	 * @function
	 * @returns {object} - initial values
	 * @memberOf CurrencyEditComponent
	 */
	const getDefaultValues = () => {
		const initial = {
			code: "",
			favoriteBets: ["", "", "", ""],
			decimalCount: 0,
			isDefault: false,
			betLimits: {
				singleMin: "",
				singleMax: "",
				multiMin: "",
				multiMax: "",
				singlePossibleWinMax: "",
				multiPossibleWinMax: ""
			}
		};
		return editingCurrency ? currencies.find((c) => c.code === editingCurrency) || initial : initial;
	};

	/** Get initial values for form
	 * @function
	 * @returns {object} - initial values
	 * @memberOf CurrencyEditComponent
	 */
	const getFormInitialValues = () => ({
		code: getDefaultValues().code,
		decimalCount: getDefaultValues().decimalCount,
		favoriteBets: getDefaultValues().favoriteBets,
		betLimits: getDefaultValues().betLimits
	});

	/** Fires when form submitted
	 * @function
	 * @memberOf CurrencyEditComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				const d = {
					isDefault: getDefaultValues().isDefault,
					code: getDefaultValues().code,
					id: routeParams.id,
					favoriteBets: data.favoriteBets,
					betLimits: data.betLimits
				};

				updateCurrency(d);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	/** Fires on "Apply to all" button click
	 * @function
	 * @memberOf CurrencyEditComponent
	 */
	const applyToAll = () => {
		let min, max, posWin;
		let minIsValid = true,
			maxIsValid = true,
			posWinIsValid = true;
		min = getFieldValue(["betLimits", "singleMin"]);
		max = getFieldValue(["betLimits", "singleMax"]);
		posWin = getFieldValue(["betLimits", "singlePossibleWinMax"]);
		if (getFieldError(["betLimits", "singleMin"]).length !== 0) minIsValid = false;
		if (getFieldError(["betLimits", "singleMax"]).length !== 0) maxIsValid = false;
		if (getFieldError(["betLimits", "singlePossibleWinMax"]).length !== 0) posWinIsValid = false;
		const fields = {};
		if (minIsValid) {
			fields.multiMin = min;
		}
		if (maxIsValid) {
			fields.multiMax = max;
		}

		if (posWinIsValid) {
			fields.multiPossibleWinMax = posWin;
		}

		const result = {};
		result.betLimits = { ...fields };
		setFieldsValue(result);

		setIsFormTouched(isFormChanged({ ...getFieldsValue() }, getFormInitialValues()));
	};

	return (
		<Modal
			open={true}
			title={t("pages.dashboard.currency.edit_new_currency")}
			cancelText={t("common.cancel")}
			okText={t("common.save")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.BIG}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving, disabled: !isFormTouched }}
			centered
		>
			<Form className="dashboard-form ant-hide-required-mark" form={formInstance} requiredMark={false} colon={false} layout="vertical" initialValues={getFormInitialValues()} onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, getFormInitialValues()))}>
				<Col xs={24} sm={12}>
					<div className="ant-modal-section-title ant-modal-section-title-first">
						<h4>{t(`pages.dashboard.currency.currency_info`)}</h4>
					</div>
				</Col>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12}>
						<FormItem label={t("pages.dashboard.currency.select_currency")} name="code" rules={[{ required: true, message: t("validation.field_required") }]}>
							<SearchableSelect disabled={true} placeholder={t("pages.dashboard.currency.select_currency_placeholder")} items={systemCurrencies} valueProp={(item) => item.code} textProp={(item) => item.name} renderText={(item) => item.code.toUpperCase() + " - " + item.name} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12}>
						<FormItem label={t("pages.dashboard.currency.decimal_count")} name="decimalCount">
							<Select disabled={true} suffixIcon={<i className="icon-down" />}>
								<Select.Option value={0}>0</Select.Option>
								<Select.Option value={1}>0,1</Select.Option>
								<Select.Option value={2}>0,12</Select.Option>
								<Select.Option value={3}>0,123</Select.Option>
								<Select.Option value={4}>0,1234</Select.Option>
								<Select.Option value={5}>0,12345</Select.Option>
								<Select.Option value={6}>0,123456</Select.Option>
								<Select.Option value={7}>0,1234567</Select.Option>
								<Select.Option value={8}>0,12345678</Select.Option>
							</Select>
						</FormItem>
					</Col>
				</Row>
				<Row gutter={[16, 0]}>
					{[0, 1, 2, 3].map((i) => (
						<Col xs={24} sm={6} key={i}>
							<FormItem
								label={i === 0 ? t("pages.dashboard.currency.favorite_bets") : " "}
								name={["favoriteBets", i]}
								className="form-item-without-margin"
								rules={[
									{ required: true, message: t("validation.field_required") },
									({ getFieldValue }) => ({
										validator(rule, value) {
											if (value.length > 20 || (value !== "" && (isNaN(value) || Number(value) < 0.00001) && !FAVORITE_BETS.includes(value))) {
												return Promise.reject(t("validation.field_invalid"));
											} else if (favoriteBetsRepeatingIndexes(getFieldValue("favoriteBets")).includes(i)) {
												return Promise.reject(t("validation.favorite_bets_no_duplicate"));
											} else if (!FAVORITE_BETS.includes(value) && value !== "") {
												if (getFieldValue("betLimits").multiMax !== "" && getFieldValue("betLimits").singleMax !== "" && Number(value) > Number(getFieldValue("betLimits").multiMax) && Number(value) > Number(getFieldValue("betLimits").singleMax)) {
													return Promise.reject(t("validation.favorite_bets_max"));
												} else if (getFieldValue("betLimits").multiMin !== "" && getFieldValue("betLimits").singleMin !== "" && Number(value) < Number(getFieldValue("betLimits").multiMin) && Number(value) < Number(getFieldValue("betLimits").singleMin)) {
													return Promise.reject(t("validation.favorite_bets_min"));
												}
											}
											return Promise.resolve();
										}
									})
								]}
							>
								<AutoComplete
									filterOption={() => true}
									options={FAVORITE_BETS.map((value) => ({ value }))}
									onChange={(value) => {
										if (value === FAVORITE_BET_SUM) {
											const values = formInstance.getFieldsValue();
											const updated = { ...values };
											updated["favoriteBets"] = updated["favoriteBets"].map((v) => {
												if (v === FAVORITE_BET_SUM) {
													const betLimits = values["betLimits"];
													const sumValue = Math.min(betLimits.singleMin, betLimits.multiMin);
													return `+${sumValue}`;
												}
												return v;
											});
											formInstance.setFieldsValue(updated);
										}
										setTimeout(() => {
											const nonEmpty = getFieldValue("favoriteBets")
												.map((f, i) => (f !== "" ? i : f))
												.filter((f) => f !== "");
											validateFields(nonEmpty.map((f) => ["favoriteBets", f]));
										}, 0);
									}}
									showArrow={true}
									suffixIcon={<i className="icon-down" />}
								></AutoComplete>
							</FormItem>
						</Col>
					))}
				</Row>
				<Row>
					<Col span={24}>
						<Row>
							<Col xs={24} sm={12}>
								<div className="ant-modal-section-title">
									<h4>{t(`pages.dashboard.currency.bet_limits`)}</h4>
									{
										<Tooltip title={t("pages.dashboard.currency.apply_to_all")}>
											<i className="icon-reset" style={{ cursor: "pointer" }} onClick={applyToAll} />
										</Tooltip>
									}
								</div>
							</Col>
						</Row>

						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} md={12}>
								<FormItem
									label={t("pages.dashboard.currency.min_single")}
									name={["betLimits", "singleMin"]}
									rules={[
										{ required: true, message: t("validation.field_required") },
										{ type: "number", min: 0.01, message: t("validation.field_invalid"), transform: numberTransform },
										({ getFieldValue }) => ({
											validator(rule, value) {
												return validateMin(value, getFieldValue("betLimits").singleMax);
											}
										})
									]}
								>
									<NumericInput
										placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.min_single")}`}
										onChange={() => {
											setTimeout(() => {
												if (getFieldValue(["betLimits", "singleMax"]) !== "") {
													validateFields([["betLimits", "singleMax"]]);
												}
												const nonEmpty = getFieldValue("favoriteBets")
													.map((f, i) => (f !== "" ? i : f))
													.filter((f) => f !== "");
												validateFields(nonEmpty.map((f) => ["favoriteBets", f]));
											}, 0);
										}}
									/>
								</FormItem>
							</Col>
							<Col xs={24} sm={12}>
								<FormItem
									label={t("pages.dashboard.currency.min_multi")}
									name={["betLimits", "multiMin"]}
									rules={[
										{ required: true, message: t("validation.field_required") },
										{ type: "number", min: 0.01, message: t("validation.field_invalid"), transform: numberTransform },
										({ getFieldValue }) => ({
											validator(rule, value) {
												return validateMin(value, getFieldValue("betLimits").multiMax);
											}
										})
									]}
								>
									<NumericInput
										placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.min_multi")}`}
										onChange={() => {
											setTimeout(() => {
												if (getFieldValue(["betLimits", "multiMax"]) !== "") validateFields([["betLimits", "multiMax"]]);
												const nonEmpty = getFieldValue("favoriteBets")
													.map((f, i) => (f !== "" ? i : f))
													.filter((f) => f !== "");
												validateFields(nonEmpty.map((f) => ["favoriteBets", f]));
											}, 0);
										}}
									/>
								</FormItem>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12}>
								<FormItem
									label={t("pages.dashboard.currency.max_single")}
									name={["betLimits", "singleMax"]}
									rules={[
										{ required: true, message: t("validation.field_required") },
										{ type: "number", min: 0.01, message: t("validation.field_invalid"), transform: numberTransform },
										({ getFieldValue }) => ({
											validator(rule, value) {
												return validateMax(getFieldValue("betLimits").singleMin, value, getFieldValue("betLimits").singlePossibleWinMax);
											}
										})
									]}
								>
									<NumericInput
										placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.max_single")}`}
										onChange={() => {
											setTimeout(() => {
												if (getFieldValue(["betLimits", "singleMin"]) !== "") validateFields([["betLimits", "singleMin"]]);
												if (getFieldValue(["betLimits", "singlePossibleWinMax"]) !== "") validateFields([["betLimits", "singlePossibleWinMax"]]);
												const nonEmpty = getFieldValue("favoriteBets")
													.map((f, i) => (f !== "" ? i : f))
													.filter((f) => f !== "");
												validateFields(nonEmpty.map((f) => ["favoriteBets", f]));
											}, 0);
										}}
									/>
								</FormItem>
							</Col>
							<Col xs={24} sm={12}>
								<FormItem
									label={t("pages.dashboard.currency.max_multi")}
									name={["betLimits", "multiMax"]}
									rules={[
										{ required: true, message: t("validation.field_required") },
										{ type: "number", min: 0.01, message: t("validation.field_invalid"), transform: numberTransform },
										({ getFieldValue }) => ({
											validator(rule, value) {
												return validateMax(getFieldValue("betLimits").multiMin, value, getFieldValue("betLimits").multiPossibleWinMax);
											}
										})
									]}
								>
									<NumericInput
										placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.max_multi")}`}
										onChange={() => {
											setTimeout(() => {
												if (getFieldValue(["betLimits", "multiMin"]) !== "") validateFields([["betLimits", "multiMin"]]);
												if (getFieldValue(["betLimits", "multiPossibleWinMax"]) !== "") validateFields([["betLimits", "multiPossibleWinMax"]]);
												const nonEmpty = getFieldValue("favoriteBets")
													.map((f, i) => (f !== "" ? i : f))
													.filter((f) => f !== "");
												validateFields(nonEmpty.map((f) => ["favoriteBets", f]));
											}, 0);
										}}
									/>
								</FormItem>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12}>
								<FormItem
									className="form-item-without-margin"
									label={t("pages.dashboard.currency.max_possible_win_single")}
									name={["betLimits", "singlePossibleWinMax"]}
									rules={[
										{ required: true, message: t("validation.field_required") },
										{ type: "number", min: 0.01, message: t("validation.field_invalid"), transform: numberTransform },
										({ getFieldValue }) => ({
											validator(rule, value) {
												return validatePosMax(getFieldValue("betLimits").singleMax, value);
											}
										})
									]}
								>
									<NumericInput
										placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.max_possible_win_single")}`}
										onChange={() => {
											setTimeout(() => {
												if (getFieldValue(["betLimits", "singleMax"]) !== "") validateFields([["betLimits", "singleMax"]]);
											}, 0);
										}}
									/>
								</FormItem>
							</Col>
							<Col xs={24} sm={12}>
								<FormItem
									className="form-item-without-margin"
									label={t("pages.dashboard.currency.max_possible_win_multi")}
									name={["betLimits", "multiPossibleWinMax"]}
									rules={[
										{ required: true, message: t("validation.field_required") },
										{ type: "number", min: 0.01, message: t("validation.field_invalid"), transform: numberTransform },
										({ getFieldValue }) => ({
											validator(rule, value) {
												return validatePosMax(getFieldValue("betLimits").multiMax, value);
											}
										})
									]}
								>
									<NumericInput
										placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.max_possible_win_multi")}`}
										onChange={() => {
											setTimeout(() => {
												if (getFieldValue(["betLimits", "multiMax"]) !== "") validateFields([["betLimits", "multiMax"]]);
											}, 0);
										}}
									/>
								</FormItem>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** CurrencyEditComponent propTypes
 * PropTypes
 */
CurrencyEditComponent.propTypes = {
	/** Is true when currency is saving */
	isSaving: PropTypes.bool,
	/** Function to call to update currecny */
	updateCurrency: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Current editing currecy */
	editingCurrency: PropTypes.string,
	/** Array of currencies  */
	currencies: PropTypes.arrayOf(currencyType),
	/** Redux action to get system currencies */
	getSystemAvailableCurrencies: PropTypes.func,
	/** Redux state property, represents the array of loaded system currencies */
	systemCurrencies: PropTypes.arrayOf(currencyType)
};

const mapDispatchToProps = (dispatch) => ({
	getSystemAvailableCurrencies: () => {
		dispatch(getSystemAvailableCurrencies(true));
	}
});

const mapStateToProps = (state) => {
	return {
		systemCurrencies: state.systemCurrencies.systemAvailableCurrencies
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyEditComponent);
