import PropTypes from "prop-types";

import Row from "antd/lib/row";
import Button from "antd/lib/button";

import Tooltip from "antd/lib/tooltip";

import { hasPermission } from "utils/permissions";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";
import { buildPathToStaticFolderOfCDN } from "utils/common";

const StreamServerItemHeader = ({ title = "", handleOpenAddGameModal = () => {}, handleOpenRefreshServerModal = () => {}, isAddGameDisabled = false }) => {
	return (
		<Row align={"middle"} justify={"space-between"} className="stream-servers-item-header">
			<div className="stream-servers-item-header-info">
				<Tooltip title={title}>{title}</Tooltip>
			</div>
			{hasPermission({ resource: PERMISSION_RESOURCE.DEVELOPMENT_STREAM_SERVERS, action: PERMISSION_ACTION.MODIFY }) ? (
				<div className="stream-servers-item-header-actions">
					<div className="stream-servers-item-refresh-button">
						<Button size="small" onClick={handleOpenRefreshServerModal} type="primary">
							<img src={buildPathToStaticFolderOfCDN("admin-images/dashboard/refresh-icon.png")} alt="Refresh stream server" />
						</Button>
					</div>

					<div>
						<Button size="small" type="primary" onClick={handleOpenAddGameModal} disabled={isAddGameDisabled}>
							<img src={buildPathToStaticFolderOfCDN("admin-images/dashboard/add-icon.png")} alt="Add new game for the stream server" />
						</Button>
					</div>
				</div>
			) : null}
		</Row>
	);
};

StreamServerItemHeader.propTypes = {
	// Title prop of server item header
	title: PropTypes.string,
	// Add server modal opening handler prop
	handleOpenAddGameModal: PropTypes.func,
	// Refresh server modal opening handler prop
	handleOpenRefreshServerModal: PropTypes.func,
	// Disabled indicator prop of submit button
	isAddGameDisabled: PropTypes.bool
};

export default StreamServerItemHeader;
