import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { SET_USER_BETSHOP_ACCESS } from "store/actionTypes";

import { setUsersActionBefore, setUsersActionFinished, setUserSaveActionBefore, setUserSaveActionFinished } from "./users.action";

const setUserBetShopAccess = (betShopAccess) => ({
	type: SET_USER_BETSHOP_ACCESS,
	payload: { betShopAccess }
});

export const getUserBetShopAccess = (id) => {
	return (dispatch) => {
		dispatch(setUsersActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_GET_BETSHOPS_ACCESS}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: betshopAccess } }) => {
				dispatch(setUserBetShopAccess(betshopAccess));
				dispatch(setUsersActionFinished());
			})
			.catch((ex) => {
				dispatch(setUsersActionFinished());
			});
	};
};

export const addUserBetShopAccess = (id, partnerId, projectId, type, note) => {
	return (dispatch) => {
		dispatch(setUserSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_ADD_BETSHOP_ACCESS}`,
			method: Methods.POST,
			data: { id, partnerId, projectId, type, note }
		})
			.then(({ data: { value: betShopAccess } }) => {
				dispatch(setUserBetShopAccess(betShopAccess));
				dispatch(setUserSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setUserSaveActionFinished());
			});
	};
};

export const deleteUserBetShopAccess = (id, partnerId, projectId, type, note) => {
	return (dispatch) => {
		dispatch(setUserSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_DELETE_BETSHOP_ACCESS}`,
			method: Methods.DELETE,
			data: { id, partnerId, projectId, type, note }
		})
			.then(({ data: { value: betShopAccess } }) => {
				dispatch(setUserBetShopAccess(betShopAccess));
				dispatch(setUserSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setUserSaveActionFinished());
			});
	};
};
