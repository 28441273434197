import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Button, Select, Input, Tag, Spin, Switch } from "antd";
const { Item: FormItem } = Form;

import { saveBetshopGeneralInfo } from "store/actions/dashboard/retail/betshops/generalInfo.action";
import { getPartnerAvailableLanguages } from "store/actions/dashboard/partners/languages.action";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { INTEGRATION_TYPE, WALLET_TYPE } from "constants/betshop.constants";
import { EMAIL_REGEX, TEL_REGEX, URL_REGEX } from "constants/regex.constants";
import { PAYMENT_TYPE } from "constants/partner.constants";

import betshopGeneralInfoType from "types/betshop/generalInfo.type";

/** Betshop Edit Page General Info Tab Component */
const GeneralInfoComponent = ({ saveBetshopGeneralInfo, isSaving, isLoading, generalInfo, onTabChange, isLanguagesLoading, languages, getPartnerAvailableLanguages }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.MODIFY });

	/** Load partner languages */
	useEffect(() => {
		getPartnerAvailableLanguages();
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			name: generalInfo.name,
			address: generalInfo.address,
			tel: generalInfo.tel,
			email: generalInfo.email,
			ticketLanguageCode: generalInfo.ticketLanguageCode || undefined,
			liveMonitorLanguageCode: generalInfo.liveMonitorLanguageCode || "EN",
			secret: generalInfo.secret,
			id: generalInfo.id,
			languageCode: generalInfo.languageCode || undefined,
			dst: "" + generalInfo.dst,
			hotKeyEnabled: generalInfo.hotKeyEnabled,
			lastBetReprintEnabled: generalInfo.lastBetReprintEnabled,
			paymentType: generalInfo.paymentType
		});
	}, [generalInfo]);

	/** Fires when form submitted
	 * @function
	 * @memberOf GeneralInfoComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				saveBetshopGeneralInfo({
					...data,
					id: routeParams.id,
					enabled: generalInfo.enabled,
					hotKeyEnabled: generalInfo.allowHotKeyEdit ? data.hotKeyEnabled : generalInfo.hotKeyEnabled,
					lastBetReprintEnabled: generalInfo.allowLastBetReprintEdit ? data.lastBetReprintEnabled : generalInfo.lastBetReprintEnabled
				});
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					name: generalInfo.name,
					address: generalInfo.address,
					tel: generalInfo.tel,
					email: generalInfo.email,
					secret: generalInfo.secret,
					id: generalInfo.id,
					languageCode: generalInfo.languageCode ? generalInfo.languageCode.toLowerCase() : undefined,
					ticketLanguageCode: generalInfo.ticketLanguageCode ? generalInfo.ticketLanguageCode.toLowerCase() : "EN",
					liveMonitorLanguageCode: generalInfo.liveMonitorLanguageCode ? generalInfo.liveMonitorLanguageCode.toLowerCase() : undefined,
					dst: "" + generalInfo.dst,
					hotKeyEnabled: generalInfo.hotKeyEnabled,
					lastBetReprintEnabled: generalInfo.lastBetReprintEnabled,
					paymentType: generalInfo.paymentType
				}}
				onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, { ...generalInfo }))}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} md={8} xl={6}>
								<FormItem label={`${t("pages.dashboard.betshops.name")} *`} name="name" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
									<Input maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.betshops.name")}`} disabled={isDisabled} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} md={8} xl={6}>
								<FormItem label={t("pages.dashboard.betshops.code")}>
									<Input value={generalInfo.code} disabled={true} />
								</FormItem>
							</Col>
							<Col xs={24} sm={24} md={8} xl={6}>
								<FormItem label={`${t("pages.dashboard.betshops.ticket_text_language")}`} name="ticketLanguageCode">
									<Select placeholder={t("pages.dashboard.partners.language.select_language_placeholder")} loading={isLanguagesLoading} disabled={isDisabled} suffixIcon={<i className="icon-down" />}>
										{Object.keys(languages).map((lang) => (
											<Select.Option key={lang} value={lang.toUpperCase()}>
												{languages[lang]}
											</Select.Option>
										))}
									</Select>
								</FormItem>
							</Col>
							<Col xs={24} sm={24} md={8} xl={6}>
								<FormItem label={`${t("pages.dashboard.betshops.default_language")} *`} name="languageCode" rules={[{ required: true, message: t("validation.field_required") }]}>
									<Select placeholder={t("pages.dashboard.partners.language.select_language_placeholder")} loading={isLanguagesLoading} disabled={isDisabled} suffixIcon={<i className="icon-down" />}>
										{Object.keys(languages).map((lang) => (
											<Select.Option key={lang} value={lang.toUpperCase()}>
												{languages[lang]}
											</Select.Option>
										))}
									</Select>
								</FormItem>
							</Col>
							<Col xs={24} sm={24} md={8} xl={6}>
								<FormItem label={`${t("pages.dashboard.betshops.live_monitor_language")}`} name="liveMonitorLanguageCode">
									<Select placeholder={t("pages.dashboard.partners.language.select_language_placeholder")} loading={isLanguagesLoading} disabled={isDisabled} suffixIcon={<i className="icon-down" />}>
										{Object.keys(languages).map((lang) => (
											<Select.Option key={lang} value={lang.toUpperCase()}>
												{languages[lang]}
											</Select.Option>
										))}
									</Select>
								</FormItem>
							</Col>
							<Col xs={24} sm={12} md={8} xl={6}>
								<FormItem
									label={`${t("pages.dashboard.betshops.address")} *`}
									name="address"
									rules={[
										{ required: true, whitespace: true, message: t("validation.field_required") },
										{ max: 100, message: t("validation.field_invalid") }
									]}
								>
									<Input maxLength={100} placeholder={`${t("common.enter")} ${t("pages.dashboard.betshops.address")}`} disabled={isDisabled} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} md={8} xl={6}>
								<FormItem
									label={t("pages.dashboard.betshops.email")}
									name="email"
									rules={[
										{ pattern: EMAIL_REGEX, message: t("validation.email_format") },
										{ max: 50, message: t("validation.field_invalid") }
									]}
								>
									<Input maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.betshops.email")}`} disabled={isDisabled} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} md={8} xl={6}>
								<FormItem
									label={t("pages.dashboard.betshops.tel")}
									name="tel"
									rules={[
										{ pattern: TEL_REGEX, message: t("validation.tel_format") },
										{ max: 30, message: t("validation.field_invalid") }
									]}
								>
									<Input maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.betshops.tel")}`} disabled={isDisabled} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} md={8} xl={6}>
								<FormItem label={t("pages.dashboard.betshops.daylight_saving_time")} name="dst">
									<Select disabled={isDisabled} suffixIcon={<i className="icon-down" />}>
										<Select.Option value="-1">-1</Select.Option>
										<Select.Option value="0">0</Select.Option>
										<Select.Option value="1">1</Select.Option>
									</Select>
								</FormItem>
							</Col>
							<Col xs={24} sm={12} md={8} xl={6}>
								<FormItem label={t("pages.dashboard.betshops.wallet_type")}>
									<Input value={generalInfo.walletType === WALLET_TYPE.SEAMLESS ? t("pages.dashboard.betshops.seamless") : t("pages.dashboard.betshops.basic")} disabled={true} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} md={8} xl={6}>
								<FormItem label={t("pages.dashboard.betshops.web_cashier")}>
									<Input value={generalInfo.integrationType === INTEGRATION_TYPE.STANDALONE ? t("pages.dashboard.betshops.standalone") : generalInfo.integrationType === INTEGRATION_TYPE.IFRAME ? t("pages.dashboard.betshops.iframe") : ""} disabled={true} />
								</FormItem>
							</Col>

							<Col xs={24} sm={12} md={8} xl={6}>
								<FormItem label={`${t("pages.dashboard.betshops.payment_type")} *`} name="paymentType">
									<Select suffixIcon={<i className="icon-down" />} disabled={isDisabled || generalInfo.partnerPaymentType !== PAYMENT_TYPE.BOTH}>
										<Select.Option value={PAYMENT_TYPE.BOTH}>{t("pages.dashboard.partners.retail_settings.retail_configs.both")}</Select.Option>
										<Select.Option value={PAYMENT_TYPE.CASH}>{t("pages.dashboard.partners.retail_settings.retail_configs.cash")}</Select.Option>
										<Select.Option value={PAYMENT_TYPE.CREDIT_CARD}>{t("pages.dashboard.partners.retail_settings.retail_configs.credit_card")}</Select.Option>
									</Select>
								</FormItem>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							{generalInfo.allowHotKeyEdit && (
								<Col xs={24} sm={12} xl={6}>
									<div className="inline-form-item form-switcher form-switcher-without-margin">
										<label>{t("pages.dashboard.betshops.hotkey")}</label>
										<FormItem name="hotKeyEnabled" valuePropName="checked" className="ant-row">
											<Switch disabled={isDisabled} />
										</FormItem>
									</div>
								</Col>
							)}

							{generalInfo.allowLastBetReprintEdit && (
								<Col xs={24} sm={12} xl={6}>
									<div className="inline-form-item form-switcher form-switcher-without-margin">
										<label>{t("pages.dashboard.betshops.enable_reprint")}</label>
										<FormItem name="lastBetReprintEnabled" valuePropName="checked" className="ant-row">
											<Switch disabled={isDisabled} />
										</FormItem>
									</div>
								</Col>
							)}
						</Row>
					</div>
					{!isDisabled && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** GeneralInfoComponent propTypes
 * PropTypes
 */
GeneralInfoComponent.propTypes = {
	/** Redux action to save betshop General info */
	saveBetshopGeneralInfo: PropTypes.func,
	/** Redux state property, is true when general info is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when general info is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the general info of current editing betshop  */
	generalInfo: betshopGeneralInfoType,
	/** Redux state property, is true when loading partner languages */
	isLanguagesLoading: PropTypes.bool,
	/** Redux state property, represents the object of loaded partner languages */
	languages: PropTypes.object,
	/** Redux action to get all languages for partner */
	getPartnerAvailableLanguages: PropTypes.func,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	saveBetshopGeneralInfo: (data) => {
		dispatch(saveBetshopGeneralInfo(data));
	},
	getPartnerAvailableLanguages: () => {
		dispatch(getPartnerAvailableLanguages());
	}
});

const mapStateToProps = (state) => {
	return {
		generalInfo: state.betshops.editingBetshop.generalInfo,
		isSaving: state.betshops.isSaving,
		isLoading: state.betshops.isLoading,
		isLanguagesLoading: state.partner.language.isAvailableLoading,
		languages: state.partner.language.availableLanguages
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent);
