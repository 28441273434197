import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { SET_USER_PROJECT_ACCESS } from "store/actionTypes";

import { setUsersActionBefore, setUsersActionFinished, setUserSaveActionBefore, setUserSaveActionFinished } from "./users.action";

const setUserProjectAccess = (projectAccess) => ({
	type: SET_USER_PROJECT_ACCESS,
	payload: { projectAccess }
});

export const getUserProjectAccess = (id) => {
	return (dispatch) => {
		dispatch(setUsersActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_GET_PROJECT_ACCESS}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: projectAccess } }) => {
				dispatch(setUserProjectAccess(projectAccess));
				dispatch(setUsersActionFinished());
			})
			.catch((ex) => {
				dispatch(setUsersActionFinished());
			});
	};
};

export const addUserProjectAccess = (id, partnerId, projectId, type, note) => {
	return (dispatch) => {
		dispatch(setUserSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_ADD_PROJECT_ACCESS}`,
			method: Methods.POST,
			data: { id, partnerId, projectId, type, note }
		})
			.then(({ data: { value: projectAccess } }) => {
				dispatch(setUserProjectAccess(projectAccess));
				dispatch(setUserSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setUserSaveActionFinished());
			});
	};
};

export const deleteUserProjectAccess = (id, partnerId, projectId, type, note) => {
	return (dispatch) => {
		dispatch(setUserSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_DELETE_PROJECT_ACCESS}`,
			method: Methods.DELETE,
			data: { id, partnerId, projectId, type, note }
		})
			.then(({ data: { value: projectAccess } }) => {
				dispatch(setUserProjectAccess(projectAccess));
				dispatch(setUserSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setUserSaveActionFinished());
			});
	};
};
