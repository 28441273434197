import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { setGamesActionBefore, setGamesActionFinished, setGameSaveActionBefore, setGameSaveActionFinished } from "./games.action";

import { SET_GAME_RTPS } from "store/actionTypes";
import { GAME_CATEGORY } from "constants/game.constants";

export const setGameRTPs = (rtp) => ({
	type: SET_GAME_RTPS,
	payload: { rtp }
});

export const getRTPs = (id, partnerId, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.SCHEDULED_GAME_GET_RTPS : ApiUrls.INSTANT_GAME_GET_RTPS;
	return (dispatch) => {
		dispatch(setGamesActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.GET,
			params: { id, SelectedPartnerId: partnerId }
		})
			.then(({ data: { value: rtp } }) => {
				dispatch(setGameRTPs(rtp));
				dispatch(setGamesActionFinished());
			})
			.catch((ex) => {
				dispatch(setGamesActionFinished());
			});
	};
};

export const saveRTPs = (data, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.SCHEDULED_GAME_SAVE_RTPS : ApiUrls.INSTANT_GAME_SAVE_RTPS;
	return (dispatch) => {
		dispatch(setGameSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.POST,
			data: {
				...data
			}
		})
			.then(({ data: { message: msg, status, value: rtp } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setGameRTPs(rtp));
				}
				dispatch(setGameSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setGameSaveActionFinished());
			});
	};
};

export const resetRTPs = () => {
	return (dispatch) => {
		dispatch(setGameRTPs({ rtPs: [], allowOverride: true, minPossibleValue: 0, maxPossibleValue: 0 }));
	};
};
