import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { BETSHOP_SESSIONS_BEFORE, BETSHOP_SESSIONS_FINISH, BETSHOP_SET_BETSHOP_SESSIONS, BETSHOP_SET_SESSIONS_SORTING, BETSHOP_SET_SESSIONS_FILTERS, BETSHOP_SETTLEMENT_REPORT_BEFORE, BETSHOP_SETTLEMENT_REPORT_FINISH, BETSHOP_SET_SETTLEMENT_REPORT } from "store/actionTypes";

import { PROJECT_TYPE } from "constants/common.constants";

const setBetshopSessionsBefore = () => ({
	type: BETSHOP_SESSIONS_BEFORE
});

const setBetshopSessionsFinished = () => ({
	type: BETSHOP_SESSIONS_FINISH
});

const setBetshopSessions = (data) => ({
	type: BETSHOP_SET_BETSHOP_SESSIONS,
	payload: { data }
});

export const setBetshopSessionsFilters = (filters) => ({
	type: BETSHOP_SET_SESSIONS_FILTERS,
	payload: { filters }
});

export const setBetshopSessionsSorting = (sorting) => ({
	type: BETSHOP_SET_SESSIONS_SORTING,
	payload: { sorting }
});

const setBetshopSettlementReportBefore = () => ({
	type: BETSHOP_SETTLEMENT_REPORT_BEFORE
});

const setBetshopSettlementReportFinished = () => ({
	type: BETSHOP_SETTLEMENT_REPORT_FINISH
});

const setBetshopSettlementReport = (data) => ({
	type: BETSHOP_SET_SETTLEMENT_REPORT,
	payload: { data }
});

export const getBetshopSessions = (id) => {
	return (dispatch, getState) => {
		dispatch(setBetshopSessionsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BETSHOP_GET_SESSIONS}`,
			method: Methods.GET,
			params: {
				...getState().betshops.viewingBetshop.sessions.sorting,
				...getState().betshops.viewingBetshop.sessions.filters,
				projectId: id,
				projectType: PROJECT_TYPE.RETAIL
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setBetshopSessions(data));
				dispatch(setBetshopSessionsFinished());
			})
			.catch(() => {
				dispatch(setBetshopSessionsFinished());
			});
	};
};

export const getBetshopSettlementReport = (id) => {
	return (dispatch, getState) => {
		dispatch(setBetshopSettlementReportBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BETSHOP_GET_SESSION_REPORT}`,
			method: Methods.GET,
			params: {
				id
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setBetshopSettlementReport(data));
				dispatch(setBetshopSettlementReportFinished());
			})
			.catch(() => {
				dispatch(setBetshopSettlementReportFinished());
			});
	};
};
