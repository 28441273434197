import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Row, Col } from "antd";
import PieChart from "./pieChart";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import QuickInfo from "components/common/quickInfo";

import { getBonuseQuickInfo } from "store/actions/dashboard/bonuses/bonuses.action";

import { BONUS_WIN_TYPE, BONUS_STATE, BONUS_TYPE } from "constants/bonus.constants";
import { SCHEDULED_GAME_TYPE, SCHEDULED_GAME_TYPE_MAPPER } from "constants/game.constants";
import { BETSLIP_MODES } from "constants/bet.constants";
import bonusType from "types/bonus/bonus.type";

import { binaryToFlags } from "utils/common";

/** Bonus Quick Info Component */

const BonusQuickInfo = ({ id, onClose, quickInfo, getBonuseQuickInfo, isLoading, bonuses, renderStateComponent, setPrintData }) => {
	const { t } = useTranslation();

	const [games, setGames] = useState({ title: "", value: "" });
	const [betshops, setBetshops] = useState({ title: "", value: "" });
	const currentBonus = bonuses.find((b) => b.id === id) || {};

	const bonusType = quickInfo?.bonusType;

	useEffect(() => {
		if (!Object.keys(quickInfo).length) {
			return;
		}

		const gameNames = binaryToFlags(
			Object.values(SCHEDULED_GAME_TYPE).map((v) => v.value),
			quickInfo.gameType
		).map((value) => SCHEDULED_GAME_TYPE_MAPPER[value]);
		if (gameNames.length === 1) {
			const text = t(`common.${gameNames[0]}`);
			setGames({ title: text, value: text });
		} else {
			setGames({ title: gameNames.map((value) => t(`common.${value}`)).join("\n"), value: gameNames.length + " " + t("pages.dashboard.bonuses.games") });
		}

		const betshopNames = quickInfo?.betshopNames ?? [];
		if (betshopNames.length === 1) {
			setBetshops({ title: betshopNames, value: betshopNames });
		} else {
			setBetshops({ title: betshopNames.join("\n"), value: betshopNames.length + " " + t("pages.dashboard.bonuses.betshopIds") });
		}
	}, [quickInfo]);

	return (
		<QuickInfo
			loadFn={() => getBonuseQuickInfo(id)}
			title={
				<div className="vs--flex">
					<div style={{ marginRight: "auto" }}>{t("pages.dashboard.bonuses.bonus_quick_info")}</div>
					<div className="vs--quick-info-actions vs--flex vs--justify-center ">
						{currentBonus.bonusState === BONUS_STATE.ACTIVE && bonusType !== BONUS_TYPE.DOUBLEDOOBLE ? (
							<>
								<i className="icon-print" onClick={() => setPrintData(currentBonus)} />
								<span className="vs--quick-info-actions-border" />
							</>
						) : null}
						<div className="vs--quick-info-state">{renderStateComponent(currentBonus.bonusState, currentBonus)}</div>
					</div>
				</div>
			}
			visible={id !== null}
			onClose={onClose}
			loading={isLoading}
		>
			<Fragment>
				<div className="dashboard-quick-info-section">
					<div className="dashboard-quick-info-section-header">
						<span>{t("pages.dashboard.bonuses.general_info")}</span>
					</div>
					<div className="dashboard-quick-info-section-content">
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.bonuses.type")}</span>
									{bonusType === BONUS_TYPE.FREEBET ? (
										<b title={t("pages.dashboard.bonuses.freebet")}>{t("pages.dashboard.bonuses.freebet")}</b>
									) : bonusType === BONUS_TYPE.DOUBLEDOOBLE ? (
										<b title={t("pages.dashboard.bonuses.doubleDooble")}>{t("pages.dashboard.bonuses.doubleDooble")}</b>
									) : null}
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.bonuses.gameType")}</span>
									<b title={games.title}>{games.value}</b>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.bonuses.currency")}</span>
									<b>{quickInfo?.currency}</b>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.bonuses.betshops")}</span>
									<b title={betshops.title}>{betshops.value}</b>
								</div>
							</Col>
							{bonusType === BONUS_TYPE.FREEBET ? (
								<Col xs={24} sm={12} xl={6}>
									<div className="dashboard-quick-info-section-content-info">
										<span>{t("pages.dashboard.bonuses.quantity") + " x " + t("pages.dashboard.bonuses.amount")}</span>
										<b>{(quickInfo?.quantity ?? 0) + " X " + (quickInfo?.amount ?? 0)}</b>
									</div>
								</Col>
							) : bonusType === BONUS_TYPE.DOUBLEDOOBLE ? (
								<>
									<Col xs={24} sm={12} xl={6}>
										<div className="dashboard-quick-info-section-content-info">
											<span>{t("pages.dashboard.bonuses.quantity")}</span>
											<b>{quickInfo?.quantity ?? 0}</b>
										</div>
									</Col>
									<Col xs={24} sm={12} xl={6}>
										<div className="dashboard-quick-info-section-content-info">
											<span>{t("pages.dashboard.bonuses.betProbability")}</span>
											<b>{quickInfo?.betProbability ?? 0}&nbsp;%</b>
										</div>
									</Col>
								</>
							) : null}

							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.bonuses.betType")}</span>
									<b>
										{binaryToFlags(Object.values(BETSLIP_MODES), quickInfo?.betType ?? 0)
											.map((mode) => (mode === BETSLIP_MODES.SINGLE ? t("pages.dashboard.bets.single") : t("pages.dashboard.bets.multi")))
											.join(", ")}
									</b>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.bonuses.win_type")}</span>
									<b>{quickInfo?.winType === BONUS_WIN_TYPE.PURE ? t("pages.dashboard.bonuses.pure_win") : quickInfo?.winType === BONUS_WIN_TYPE.TOTAL ? t("pages.dashboard.bonuses.total_win") : ""}</b>
								</div>
							</Col>
							{bonusType === BONUS_TYPE.FREEBET ? (
								<Col xs={24} sm={12} xl={6}>
									<div className="dashboard-quick-info-section-content-info">
										<span>{t("pages.dashboard.bonuses.maxPossibleWinAmount")}</span>
										<b>{quickInfo?.maxPossibleWinAmount || "-"}</b>
									</div>
								</Col>
							) : null}
							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.bonuses.minOddsSingle")}</span>
									<b>{quickInfo?.minOddsSingle || "-"}</b>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.bonuses.minOddsMulti")}</span>
									<b>{quickInfo?.minOddsMulti || "-"}</b>
								</div>
							</Col>
							{bonusType === BONUS_TYPE.DOUBLEDOOBLE ? (
								<>
									<Col xs={24} sm={12} xl={6}>
										<div className="dashboard-quick-info-section-content-info">
											<span>{t("pages.dashboard.bonuses.maxOddsSingle")}</span>
											<b>{quickInfo?.maxOddsSingle || "-"}</b>
										</div>
									</Col>
									<Col xs={24} sm={12} xl={6}>
										<div className="dashboard-quick-info-section-content-info">
											<span>{t("pages.dashboard.bonuses.maxOddsMulti")}</span>
											<b>{quickInfo?.maxOddsMulti || "-"}</b>
										</div>
									</Col>
									<Col xs={24} sm={12} xl={6}>
										<div className="dashboard-quick-info-section-content-info">
											<span>{t("pages.dashboard.bonuses.minAmountSingle")}</span>
											<b>{quickInfo?.minAmountSingle || "-"}</b>
										</div>
									</Col>
									<Col xs={24} sm={12} xl={6}>
										<div className="dashboard-quick-info-section-content-info">
											<span>{t("pages.dashboard.bonuses.maxAmountSingle")}</span>
											<b>{quickInfo?.maxAmountSingle || "-"}</b>
										</div>
									</Col>
									<Col xs={24} sm={12} xl={6}>
										<div className="dashboard-quick-info-section-content-info">
											<span>{t("pages.dashboard.bonuses.minAmountMulti")}</span>
											<b>{quickInfo?.minAmountMulti || "-"}</b>
										</div>
									</Col>
									<Col xs={24} sm={12} xl={6}>
										<div className="dashboard-quick-info-section-content-info">
											<span>{t("pages.dashboard.bonuses.maxAmountMulti")}</span>
											<b>{quickInfo?.maxAmountMulti || "-"}</b>
										</div>
									</Col>
									<Col xs={24} sm={12} xl={6}>
										<div className="dashboard-quick-info-section-content-info">
											<span>{t("pages.dashboard.bonuses.minPossibleWinAmount")}</span>
											<b>{quickInfo?.minPossibleWinAmount || "-"}</b>
										</div>
									</Col>
									<Col xs={24} sm={12} xl={6}>
										<div className="dashboard-quick-info-section-content-info">
											<span>{t("pages.dashboard.bonuses.maxPossibleWinAmount")}</span>
											<b>{quickInfo?.maxPossibleWinAmount || "-"}</b>
										</div>
									</Col>
									<Col xs={24} sm={12} xl={6}>
										<div className="dashboard-quick-info-section-content-info">
											<span>{t("pages.dashboard.bonuses.startDate")}</span>
											<b>
												{moment
													.utc(quickInfo?.startDate ?? "")
													.local()
													.format(DATE_TIME_FORMAT)}
											</b>
										</div>
									</Col>
								</>
							) : null}
							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.bonuses.endDate")}</span>
									<b>
										{moment
											.utc(quickInfo?.endDate ?? "")
											.local()
											.format(DATE_TIME_FORMAT)}
									</b>
								</div>
							</Col>
							{bonusType === BONUS_TYPE.FREEBET ? (
								<Col xs={24} sm={12} xl={6}>
									<div className="dashboard-quick-info-section-content-info">
										<span>{t("pages.dashboard.bonuses.vaucherStatus")}</span>
										<b>{quickInfo?.isPrinted ? t("pages.dashboard.session.printed") : t("pages.dashboard.session.not_printed")}</b>
									</div>
								</Col>
							) : null}
						</Row>
					</div>
				</div>
				<div className="dashboard-quick-info-section">
					<div className="dashboard-quick-info-section-header">
						<span>{t("pages.dashboard.bonuses.bonus_statistics")}</span>
					</div>
					<div className="dashboard-quick-info-section-content">
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h4 className="dashboard-quick-info-section-content-title">{t("pages.dashboard.bonuses.metrics")}</h4>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="bonus-info-card" data-type="bonusBetsCount">
									<div className="bonus-info-card-top">
										<i className="icon-retailBonus" />
										<span>{bonusType === BONUS_TYPE.FREEBET ? t("pages.dashboard.bonuses.bonusBetsCount") : bonusType === BONUS_TYPE.DOUBLEDOOBLE ? t("pages.dashboard.bonuses.bonusBetsCountDoubleDooble") : null}</span>
									</div>
									<div className="bonus-info-card-bottom">
										<b>{quickInfo?.bonusBetsCount}</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="bonus-info-card" data-type="remainingQuantity">
									<div className="bonus-info-card-top">
										<i className="icon-time" />
										<span>{bonusType === BONUS_TYPE.FREEBET ? t("pages.dashboard.bonuses.remainingQuantity") : bonusType === BONUS_TYPE.DOUBLEDOOBLE ? t("pages.dashboard.bonuses.remainingQuantityDoubleDooble") : null}</span>
									</div>
									<div className="bonus-info-card-bottom">
										<b>{quickInfo?.remainingQuantity}</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="bonus-info-card" data-type="bonusRedeem">
									<div className="bonus-info-card-top">
										<i className="icon-currency" />
										<span>{t("pages.dashboard.bonuses.bonusRedeem")}</span>
									</div>
									<div className="bonus-info-card-bottom">
										<b>{`${quickInfo?.bonusRedeem ?? 0} ${quickInfo?.currency ?? ""}`}</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="bonus-info-card" data-type="bonusWin">
									<div className="bonus-info-card-top">
										<i className="icon-currency" />
										<span>{t("pages.dashboard.bonuses.bonusWin")}</span>
									</div>
									<div className="bonus-info-card-bottom">
										<b>{`${quickInfo?.bonusWin ?? 0} ${quickInfo?.currency ?? ""}`}</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="bonus-info-card" data-type="bonusGGR">
									<div className="bonus-info-card-top">
										<i className="icon-currency" />
										<span>{t("pages.dashboard.bonuses.bonusGGR")}</span>
									</div>
									<div className="bonus-info-card-bottom">
										<b>{`${quickInfo?.bonusGGR ?? 0} ${quickInfo?.currency ?? ""}`}</b>
									</div>
								</div>
							</Col>
							{bonusType === BONUS_TYPE.FREEBET ? (
								<Col xs={24} sm={12} xl={6}>
									<div className="bonus-info-card" data-type="expiredQuantity">
										<div className="bonus-info-card-top">
											<i className="icon-retailBonus" />
											<span>{t("pages.dashboard.bonuses.expiredQuantity")}</span>
										</div>
										<div className="bonus-info-card-bottom">
											<b>{quickInfo?.expiredQuantity}</b>
										</div>
									</div>
								</Col>
							) : null}
						</Row>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h4 className="dashboard-quick-info-section-content-title" style={{ marginBottom: 0 }}>
									{t("pages.dashboard.bonuses.bonuseBetsPerBetShops")}
								</h4>
							</Col>
							<Col xs={24} sm={24}>
								<PieChart chartData={quickInfo?.bonusBetsPerBetshop ?? []} text={t("pages.dashboard.bonuses.usedBonuses")} usedCount={quickInfo?.bonusBetsCount ?? 0} />
							</Col>
						</Row>
					</div>
				</div>
			</Fragment>
		</QuickInfo>
	);
};

/** BonusQuickInfo propTypes
 * PropTypes
 */
BonusQuickInfo.propTypes = {
	/** The id of the player which quick info is showing */
	id: PropTypes.string,
	/** Function to call on qucik info close */
	onClose: PropTypes.func,
	/** Redux action to get player quick info */
	getBonuseQuickInfo: PropTypes.func,
	/** Redux state property, is true when loading quick info */
	isLoading: PropTypes.bool,
	/** Redux state property, is true when loading quick info */
	quickInfo: PropTypes.object,
	/** Redux state property, represents the array of bonuses  */
	bonuses: PropTypes.arrayOf(bonusType),
	/** Render component for displaying state */
	renderStateComponent: PropTypes.func,
	/** function for bonus printing */
	setPrintData: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.bonuses.standard.isLoading,
		quickInfo: state.bonuses.standard.quickInfo,
		bonuses: state.bonuses.standard.data
	}
}

const mapDispatchToProps = (dispatch) => ({
	getBonuseQuickInfo: (id) => {
		dispatch(getBonuseQuickInfo(id));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BonusQuickInfo);
