import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Col, Row, Modal, Input } from "antd";
const { Item: FormItem } = Form;

import { saveOperator } from "store/actions/dashboard/developer/operators/operators.action";

import operatorType from "types/operator/operator.type";

import { POPUP_SIZE } from "constants/common.constants";
import { URL_REGEX } from "constants/regex.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { hasPermission } from "utils/permissions";

/** Operator Editing Popup Component */
const OperatorEditComponent = ({ isLoading, onClose, editingOperator, saveOperator }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;

	/** Fires when form submitted
	 * @function
	 * @memberOf OperatorEditComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				const d = {
					id: editingOperator.id,
					secretKey: data.secretKey,
					endPointUrl: data.endPointUrl
				};
				saveOperator(d);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Modal open={true} title={t("pages.dashboard.operators.edit_operator")} cancelText={t("common.cancel")} okText={t("common.save")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.SMALL} maskClosable={false} closable={false} okButtonProps={{ loading: isLoading }} centered>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					secretKey: editingOperator.secretKey,
					endPointUrl: editingOperator.endPointUrl
				}}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem
							label={`${t("pages.dashboard.operators.endPointUrl")} *`}
							name="endPointUrl"
							rules={[
								{ required: true, whitespace: true, message: t("validation.field_required") },
								{ pattern: URL_REGEX, message: t("validation.url_format") }
							]}
						>
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.operators.endPointUrl")}`} readOnly={!hasPermission({ resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.MODIFY })} />
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem
							label={`${t("pages.dashboard.operators.secretKey")}`}
							name="secretKey"
							rules={[
								{ required: true, whitespace: true, message: t("validation.field_required") },
								{ max: 30, message: t("validation.field_invalid") },
								{ min: 2, message: t("validation.field_invalid") }
							]}
						>
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.operators.secretKey")}`} minLength={2} maxLength={32} />
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** OperatorEditComponent propTypes
 * PropTypes
 */
OperatorEditComponent.propTypes = {
	/** Redux state property, is true when loading operators */
	isLoading: PropTypes.bool,
	/** Redux action to save operator */
	saveOperator: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Current operator */
	editingOperator: operatorType
};

const mapDispatchToProps = (dispatch) => ({
	saveOperator: (operator) => {
		dispatch(saveOperator(operator));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.operators.isLoading,
		operators: state.operators.operators
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OperatorEditComponent);
