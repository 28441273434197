import { Fragment } from "react";

import StatisticsComponent from "components/dashboard/statistics/statistics.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const StatisticsRoute = () => {
	return (
		<Fragment>
			<StatisticsComponent />
		</Fragment>
	);
};

export default withPermission(
	StatisticsRoute,
	[
		{ resource: PERMISSION_RESOURCE.DASHBOARD_ONLINE, action: PERMISSION_ACTION.VIEW },
		{ resource: PERMISSION_RESOURCE.DASHBOARD_RETAIL, action: PERMISSION_ACTION.VIEW }
	],
	Paths.DASHBOARD
);
