import { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPlayerSessions, setPlayerSessionsSorting } from "store/actions/dashboard/online/players/sessions.action";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import Table from "components/common/table";
import ExportButton from "components/common/exportButton";
import Filters from "./filters.component";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import ApiUrls from "constants/api.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import countries from "systemData/countries";
import sessionType from "types/player/session.type";
import sortingType from "types/common/sorting.type";

/** Player View Page Sessions Tab Component */

const SessionsComponent = ({ isLoading, sessions, total, getPlayerSessions, sorting, filters, setPlayerSessionsSorting }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.session.session_id"),
			dataIndex: "id"
		},
		{
			title: t("pages.dashboard.session.session_start"),
			dataIndex: "createTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.session.session_end"),
			dataIndex: "endTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.session.session_duration"),
			dataIndex: "duration",
			render: (value, record) => moment.duration(moment(record.endTime).diff(moment(record.createTime)), "milliseconds").humanize(),
			sorter: false
		},
		{
			title: t("pages.dashboard.session.login_ip"),
			dataIndex: "ipAddress"
		},
		{
			title: t("pages.dashboard.session.country"),
			dataIndex: "countryCode",
			render: (value) => (value ? countries[value.toLowerCase()] : "")
		},
		{
			title: t("pages.dashboard.session.device"),
			dataIndex: "deviceType"
		}
	];

	return (
		<Fragment>
			<div className="dashboard-section-content">
				<div className="table-header">
					<div className="table-buttons-dropdowns">
						{hasPermission({ resource: PERMISSION_RESOURCE.PLAYER_SESSIONS_HISTORY, action: PERMISSION_ACTION.EXPORT }) ? (
							<ExportButton
								columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))}
								tableName={t("pages.dashboard.players.tabs.sessions_history")}
								url={`${ApiUrls.EXPORT_PLAYERS_SESSION_HISTORY}?userNameOrId=${routeParams.id}&projectType=${PROJECT_TYPE.ONLINE}`}
								filters={filters}
								sorting={sorting}
							/>
						) : null}
					</div>
					<Filters />
				</div>
				<Table loading={isLoading} columns={columns} data={sessions} loadFn={() => getPlayerSessions(routeParams.id)} sorting={sorting} setSortingFn={setPlayerSessionsSorting} total={total} isDisabled={() => false} />
			</div>
		</Fragment>
	);
};

/** SessionsComponent propTypes
 * PropTypes
 */
SessionsComponent.propTypes = {
	/** Redux action to get player sessions */
	getPlayerSessions: PropTypes.func,
	/** Redux state property, represents the array of loaded sessions of player */
	sessions: PropTypes.arrayOf(sessionType),
	/** Redux state property, player sessions total count */
	total: PropTypes.number,
	/** Redux state property, is true when loading player sessions */
	isLoading: PropTypes.bool,
	/** Redux action to set player sessions sorting details */
	setPlayerSessionsSorting: PropTypes.func,
	/** Redux state property, player sessions sorting details */
	sorting: sortingType,
	/** Redux state property, player sessions filters */
	filters: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	getPlayerSessions: (id) => {
		dispatch(getPlayerSessions(id));
	},
	setPlayerSessionsSorting: (sorting) => {
		dispatch(setPlayerSessionsSorting(sorting));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.players.isLoading,
		sessions: state.players.viewingPlayer.sessions.sessions,
		total: state.players.viewingPlayer.sessions.total,
		sorting: state.players.viewingPlayer.sessions.sorting,
		filters: state.players.viewingPlayer.sessions.filters
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionsComponent);
