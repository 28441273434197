import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { PLAYERS_QUICK_INFO_BEFORE, PLAYERS_QUICK_INFO_FINISH, SET_PLAYER_QUICK_INFO } from "store/actionTypes";

const setPlayerQuickInfoBefore = () => ({
	type: PLAYERS_QUICK_INFO_BEFORE
});

const setPlayerQuickInfoFinished = () => ({
	type: PLAYERS_QUICK_INFO_FINISH
});

const setPlayerQuickInfo = (data) => ({
	type: SET_PLAYER_QUICK_INFO,
	payload: { data }
});

export const getPlayerQuickInfo = (id) => {
	return (dispatch, getState) => {
		dispatch(setPlayerQuickInfoBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PLAYER_QUICK_VIEW}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setPlayerQuickInfo(data));
				dispatch(setPlayerQuickInfoFinished());
			})
			.catch(() => {
				dispatch(setPlayerQuickInfoFinished());
			});
	};
};
