import { Fragment } from "react";

import BetshopEditComponent from "components/dashboard/retail/betshops/edit/betshop-edit.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const BetshopEditRoute = () => {
	return (
		<Fragment>
			<BetshopEditComponent />
		</Fragment>
	);
};

export default withPermission(BetshopEditRoute, { resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
