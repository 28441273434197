import VideoPlayer from "../player";

class FlussonicVideoPlayer extends VideoPlayer {
	constructor(streamConfiguration, options) {
		super(streamConfiguration, options);
	}

	init() {
		if (this.initialized) return;
		if (typeof this.streamConfiguration !== "object" || !Array.isArray(this.streamConfiguration.streams)) return this.setError(true);
		if (!this.streamConfiguration.streams[0].url) return this.setError(true);

		const element = document.getElementById(this.options.elementID);
		if (!element) {
			return;
		}

		const iframe = document.createElement("iframe");
		iframe.src = this.streamConfiguration.streams[0].url;
		iframe.width = "100%";
		iframe.height = (element.offsetWidth * 9) / 16;
		iframe.frameBorder = "0";
		iframe.scrolling = "0";
		iframe.allowFullscreen = "allowfullscreen";
		iframe.style.border = "none";
		element.appendChild(iframe);
		this.setError(false);

		this.initialized = true;
	}

	destroy() {
		const element = document.getElementById(this.options.elementID);
		this.initialized = false;

		if (element) {
			element.innerHTML = "";
		}
	}
}

export default FlussonicVideoPlayer;
