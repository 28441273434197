import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { PROJECT_TYPE } from "constants/common.constants";

import { PARTNER_ONLINE_REPORT_BEFORE, PARTNER_ONLINE_REPORT_FINISH, SET_PARTNER_ONLINE_REPORT, SET_PARTNER_ONLINE_REPORT_FILTERS, SET_PARTNER_ONLINE_REPORT_SORTING } from "store/actionTypes";

export const setPartnerOnlineReportBefore = () => ({
	type: PARTNER_ONLINE_REPORT_BEFORE
});

export const setPartnerOnlineReportFinished = () => ({
	type: PARTNER_ONLINE_REPORT_FINISH
});

const setPartnerOnlineReport = (partnerOnlineReport) => ({
	type: SET_PARTNER_ONLINE_REPORT,
	payload: { partnerOnlineReport }
});

export const setPartnerOnlineReportFilters = (filters) => ({
	type: SET_PARTNER_ONLINE_REPORT_FILTERS,
	payload: { filters }
});

export const setPartnerOnlineReportSorting = (sorting) => ({
	type: SET_PARTNER_ONLINE_REPORT_SORTING,
	payload: { sorting }
});

export const getPartnerOnlineReport = () => {
	return (dispatch, getState) => {
		dispatch(setPartnerOnlineReportBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_ONLINE_REPORT}`,
			method: Methods.GET,
			params: {
				...getState().reports.partnerOnlineReport.filters,
				projectType: PROJECT_TYPE.ONLINE
			}
		})
			.then(({ data: { value: partnerOnlineReport } }) => {
				dispatch(setPartnerOnlineReport(partnerOnlineReport));
				dispatch(setPartnerOnlineReportFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerOnlineReportFinished());
			});
	};
};
