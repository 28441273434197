import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { POPUP_SIZE } from "constants/common.constants"
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom';

import { Modal, Form, Row, Col, Input, Select, Radio } from 'antd';
import { JACKPOTS_TYPES } from "constants/bonus.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { DYNAMIC_PATH_ID_REGEX } from 'constants/regex.constants';

import { hasPermission } from 'utils/permissions';
import partnerType from 'types/partner/partner.type';
import Paths from 'constants/path.constants';
import { createJackpot } from 'store/actions/dashboard/bonuses/bonuses.action';

const CreateJackpot = ({
	onClose,
	globalPartners,
	globalPartnerId,
	isSaving,
	createJackpot
}) => {
	const { t } = useTranslation()
	const [antdForm] = Form.useForm();
	const navigate = useNavigate();
	const { validateFields } = antdForm;

	const partnerName = (globalPartners.find(p => p.id === globalPartnerId))?.name ?? "";

	const handleForm = async () => {
		try {
			const formFields = await validateFields();
			const { partnerId, type, name } = formFields;
			const callback = ({ id }) => {
				if (hasPermission({ resource: PERMISSION_RESOURCE.BONUS_JACKPOT, action: PERMISSION_ACTION.MODIFY })) {
					navigate(Paths.DASHBOARD_BONUSES_JACKPOT_EDIT.replace(DYNAMIC_PATH_ID_REGEX, id ));
					return
				}
				if (typeof onClose === "function") {
					onClose()
				}
			}
			createJackpot({ partnerId, type, name }, callback)
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<Modal
			open={true}
			closable={false}
			maskClosable={false}
			title={t('pages.dashboard.bonuses.create_jackpot')}
			width={POPUP_SIZE.SMALL}
			centered
			onOk={handleForm}
			okText={t('common.create')}
			onCancel={onClose}
			cancelText={t('common.cancel')}
			okButtonProps={{ loading: isSaving }}
		>
			<Form
				className="dashboard-form"
				layout="vertical"
				requiredMark={false}
				form={antdForm}
				initialValues={{
					name: "",
					partnerId: globalPartnerId,
					type: JACKPOTS_TYPES.RETAIL
				}}
				colon={false}
			>
				<Row gutter={[16, 0]}>
					<Col sm={24}>
						<Form.Item
							name="name"
							label={`${t('pages.dashboard.bonuses.jackpot_name')} *`}
							rules={
								[
									{
										required: true,
										message: t("validation.field_required"),
									}
								]
							}
						>
							<Input
								placeholder={`${t('common.enter')} ${t('pages.dashboard.bonuses.jackpot_name')}`}
							/>
						</Form.Item>
					</Col>
					<Col sm={24}>
						<Form.Item
							name="type"
							label={`${t('pages.dashboard.bonuses.jackpot_type')} *`}
							rules={
								[
									{
										required: true,
										message: t("validation.field_required"),
									}
								]
							}
						>
							<Select
								placeholder={`${t('common.select')} ${t('pages.dashboard.bonuses.jackpot_type')}`}
								suffixIcon={(<i className="icon-down" />)}
								disabled={true}
							>
								<Select.Option value={JACKPOTS_TYPES.RETAIL}>{t('pages.dashboard.bonuses.jackpot_type_retail')}</Select.Option>
								<Select.Option value={JACKPOTS_TYPES.ONLINE}>{t('pages.dashboard.bonuses.jackpot_type_online')}</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col sm={24}>
						<Form.Item
							label={t('pages.dashboard.bonuses.network_type')}
							name="partnerId"
							className="vs--bonus-jackpot-network-type"
							rules={
								[
									{
										required: true,
										message: t("validation.field_required"),
									}
								]
							}
						>
							<Radio.Group
								disabled={true}
								options={[
									{
										label: (
											<div className="partners-checkbox">
												<i className="icon-partner-specific"></i>
												<span title={`${t('pages.dashboard.bonuses.partner_specific')} (${partnerName})`}>
													{t('pages.dashboard.bonuses.partner_specific')} ({partnerName})
												</span>
											</div>
										),
										value: globalPartnerId
									},
									{
										label: (
											<div className="partners-checkbox">
												<i className="icon-generic"></i>
												<span title={t('pages.dashboard.bonuses.generic')}>
													{t('pages.dashboard.bonuses.generic')}
												</span>
											</div>
										),
										value: "",
									},
								]}
								className="partner-type-radio-group"
							>
							</Radio.Group>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	)
}

CreateJackpot.propTypes = {
	/** Redux state property, is true when creating game request is in process */
	isSaving: PropTypes.bool,
	/** Redux state property, represents the array of partners  */
	globalPartners: PropTypes.arrayOf(partnerType),
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Redux action to create game */
	createJackpot: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
		createJackpot: (jackpot, onSuccess) => {
			dispatch(createJackpot(jackpot, onSuccess))
		}
})

const mapStateToProps = state => {
	return {
		isSaving: state.bonuses.jackpots.isSaving,
		globalPartners: state.partner.globalPartners,
		globalPartnerId: state.partner.globalPartnerId
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateJackpot)