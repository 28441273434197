import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { ACTIVATE_ACTION_BEFORE, ACTIVATE_ACTION_FINISH } from "../../actionTypes";

const setActivateActionBefore = () => ({
	type: ACTIVATE_ACTION_BEFORE
});

const setActivateActionFinished = () => ({
	type: ACTIVATE_ACTION_FINISH
});

export const activate = (token, newPassword, confirmPassword, onSuccess) => {
	return (dispatch) => {
		dispatch(setActivateActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_ACTIVATE}`,
			method: Methods.POST,
			data: { token, newPassword, confirmPassword }
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					onSuccess && onSuccess();
				}
				dispatch(setActivateActionFinished());
			})
			.catch((ex) => {
				dispatch(setActivateActionFinished());
			});
	};
};
