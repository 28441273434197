import { useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ChartLoader from "components/dashboard/statistics/charts/chart-loader";
import NoChartData from "../noChartData";

import { DASHBOARD_PERIOD } from "constants/dashboard.constants";

import dashboardActivityType from "types/dashboard/dashboardActivity.type";

/** Dashboard Page Unique Players Chart Component */
const UniquePlayersChartComponent = ({ playerActivities, period, isPlayerActivitiesLoading }) => {
	const { t } = useTranslation();

	/** function to make data for BarChart
	 * @function
	 * @param {string} type - categories/series
	 * @memberOf UniquePlayersChartComponent
	 */
	const getActivitiesChartData = (type) => {
		const result = {
			categories: [],
			series: []
		};

		if (period === DASHBOARD_PERIOD.TODAY || period === DASHBOARD_PERIOD.YESTERDAY) {
			playerActivities.forEach((a) => {
				result.categories.push(moment(a.time).format("HH:mm"));
			});
		} else if (period === DASHBOARD_PERIOD.LAST_MONTH || period === DASHBOARD_PERIOD.LAST_3_MONTH) {
			playerActivities.forEach((a) => {
				result.categories.push(moment(a.time).format("MM/DD"));
			});
		}

		result.series.push({
			name: t("pages.dashboard.dashboard.players"),
			data: playerActivities.map((a) => a.count),
			color: "#ABD67C"
		});

		return result[type];
	};

	const isNoData = useMemo(() => {
		const series = getActivitiesChartData("series");
		const seriesDataSum = series[0].data.reduce((ac, cur) => (ac += cur), 0);
		return seriesDataSum === 0;
	}, [getActivitiesChartData]);

	return (
		<div className="dashboard-chart">
			<div className="dashboard-chart-header">
				<span className="dashboard-chart-header-title">{t("pages.dashboard.dashboard.unique_players_chart")}</span>
			</div>
			<div className="dashboard-chart-content">
				{isPlayerActivitiesLoading ? <ChartLoader /> : null}
				{isNoData ? (
					<div className="top-players-no-chart-data-wrapper">
						<NoChartData />
					</div>
				) : (
					<HighchartsReact
						highcharts={Highcharts}
						options={{
							accessibility: {
								enabled: false
							},
							chart: {
								type: "areaspline"
							},
							xAxis: {
								crosshair: true,
								labels: {
									formatter: function () {
										return this.value;
									}
								},
								categories: getActivitiesChartData("categories")
							},
							yAxis: {
								title: {
									text: null
								},
								allowDecimals: false
							},
							series: getActivitiesChartData("series"),
							title: {
								text: null
							},
							plotOptions: {
								series: {
									fillOpacity: 0.2
								},
								area: {
									marker: {
										enabled: false,
										symbol: "circle",
										radius: 2,
										states: {
											hover: {
												enabled: true
											}
										}
									}
								}
							},
							tooltip: {
								backgroundColor: "#4D4D4D",
								style: {
									color: "#FFFFFF"
								}
							}
						}}
					/>
				)}
			</div>
		</div>
	);
};

/** UniquePlayersChartComponent propTypes
 * PropTypes
 */
UniquePlayersChartComponent.propTypes = {
	/** Redux state property, represents the array of player activities */
	playerActivities: PropTypes.arrayOf(dashboardActivityType),
	/** The selected period f chart */
	period: PropTypes.oneOf(Object.values(DASHBOARD_PERIOD)),
	// Redux state controls players activities loading
	isPlayerActivitiesLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		playerActivities: state.dashboard.online.playerActivities,
		isPlayerActivitiesLoading: state.dashboard.online.isPlayerActivitiesLoading
	};
};

export default connect(mapStateToProps, null)(UniquePlayersChartComponent);
