import PropTypes from 'prop-types';
import { GAME_CATEGORY } from 'constants/game.constants';
import { flagsToBinary } from 'utils/common';

export default PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.number,
	betShopCountLimit: PropTypes.number,
	projectCountLimit: PropTypes.number,
	gameCategory: PropTypes.oneOf([GAME_CATEGORY.SCHEDULED, GAME_CATEGORY.INSTANT, flagsToBinary(Object.values(GAME_CATEGORY))])
})