import { Fragment } from "react";

import DashboardComponent from "components/dashboard/dashboard.component";

const DashboardRoute = () => {
	return (
		<Fragment>
			<DashboardComponent />
		</Fragment>
	);
};

export default DashboardRoute;
