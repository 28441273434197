import {
	SURVEYS_ACTION_BEFORE,
	SURVEYS_ACTION_FINISH,
	SURVEY_AVAILABLE_PARTNERS_ACTION_BEFORE,
	SURVEY_AVAILABLE_PARTNERS_ACTION_FINISH,
	SET_SURVEY_AVAILABLE_PARTNERS,
	SET_SURVEY_PARTNER_FEEDBACKS_BEFORE,
	SET_SURVEY_PARTNER_FEEDBACKS_FINISH,
	SET_SURVEY_PARTNER_FEEDBACKS,
	SET_SURVEY_PARTNERS_BEFORE,
	SET_SURVEY_PARTNERS_FINISH,
	SET_SURVEY_PARTNERS,
	SURVEY_ADD_PARTNER_BEFORE,
	SURVEY_ADD_PARTNER_FINISH,
	SURVEY_SAVE_PARTNERS_BEFORE,
	SURVEY_SAVE_PARTNERS_FINISH,
	SURVEY_SAVE_PARTNERS,
	SET_SURVEY_PARTNERS_SORTING,
	SURVEY_QUICK_INFO_BEFORE,
	SURVEY_QUICK_INFO_FINISH,
	SET_SURVEY_QUICK_INFO,
	SET_SURVEY_GENERAL_INFO,
	SET_SURVEY_SAVE_BEFORE,
	SET_SURVEY_SAVE_FINISHED,
	SURVEYS_ACTION_SET_SURVEYS,
	SURVEYS_SET_SORTING,
	RESET_EDITING_SURVEY,
	CHANGE_SURVEY_STATE
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SURVEYS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case SURVEYS_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case CHANGE_SURVEY_STATE:
			return {
				...state,
				surveys: state.surveys.map((survey) => (survey.id !== payload.data.id ? { ...survey } : { ...survey, ...payload.data }))
			};
		case SURVEY_AVAILABLE_PARTNERS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case SURVEY_AVAILABLE_PARTNERS_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case SET_SURVEY_AVAILABLE_PARTNERS:
			return {
				...state,
				editingSurvey: {
					...state.editingSurvey,
					availablePartners: payload.data
				}
			};
		case SET_SURVEY_PARTNERS_BEFORE:
			return {
				...state,
				editingSurvey: {
					...state.editingSurvey,
					isLoading: true
				}
			};
		case SET_SURVEY_PARTNERS_FINISH:
			return {
				...state,
				editingSurvey: {
					...state.editingSurvey,
					isLoading: false
				}
			};
		case SET_SURVEY_PARTNERS:
			return {
				...state,
				editingSurvey: {
					...state.editingSurvey,
					partners: {
						...state.editingSurvey.partners,
						total: payload.info.item1,
						partners: payload.info.item2
					}
				}
			};
		case SURVEY_ADD_PARTNER_BEFORE:
			return {
				...state,
				quickInfo: {
					...state.quickInfo,
					isLoading: true
				}
			};
		case SURVEY_ADD_PARTNER_FINISH:
			return {
				...state,
				quickInfo: {
					...state.quickInfo,
					isLoading: false
				}
			};
		case SURVEY_SAVE_PARTNERS_BEFORE:
			return {
				...state,
				quickInfo: {
					...state.quickInfo,
					isLoading: true
				}
			};
		case SURVEY_SAVE_PARTNERS_FINISH:
			return {
				...state,
				quickInfo: {
					...state.quickInfo,
					isLoading: false
				}
			};
		case SURVEY_SAVE_PARTNERS:
			return {
				...state,
				editingSurvey: {
					...state.editingSurvey,
					partners: {
						...state.editingSurvey.partners,
						total: payload.data.item1,
						partners: payload.data.item2
					}
				}
			};
		case SET_SURVEY_PARTNERS_SORTING:
			return {
				...state,
				editingSurvey: {
					...state.editingSurvey,
					partners: {
						...state.editingSurvey.partners,
						sorting: payload.sorting
					}
				}
			};
		case SET_SURVEY_PARTNER_FEEDBACKS_BEFORE:
			return {
				...state,
				quickInfo: {
					...state.quickInfo,
					isLoading: true
				}
			};
		case SET_SURVEY_PARTNER_FEEDBACKS_FINISH:
			return {
				...state,
				quickInfo: {
					...state.quickInfo,
					isLoading: false
				}
			};
		case SET_SURVEY_PARTNER_FEEDBACKS:
			return {
				...state,
				editingSurvey: {
					...state.editingSurvey,
					partnerFeedbacks: {
						...state.editingSurvey.partnerFeedbacks,
						total: payload.data.item1,
						partnerFeedbacks: payload.data.item2
					}
				}
			};
		case SURVEY_QUICK_INFO_BEFORE:
			return {
				...state,
				quickInfo: {
					...state.quickInfo,
					isLoading: true
				}
			};
		case SURVEY_QUICK_INFO_FINISH:
			return {
				...state,
				quickInfo: {
					...state.quickInfo,
					isLoading: false
				}
			};
		case SET_SURVEY_QUICK_INFO:
			return {
				...state,
				quickInfo: {
					...state.quickInfo,
					quickInfo: payload.data
				}
			};
		case SET_SURVEY_GENERAL_INFO:
			return {
				...state,
				editingSurvey: {
					...state.editingSurvey,
					generalInfo: {
						...payload.info
					}
				}
			};
		case SET_SURVEY_SAVE_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case SET_SURVEY_SAVE_FINISHED:
			return {
				...state,
				isSaving: false
			};
		case SURVEYS_ACTION_SET_SURVEYS:
			return {
				...state,
				surveys: payload.data.item2,
				total: payload.data.item1
			};
		case SURVEYS_SET_SORTING:
			return {
				...state,
				sorting: payload.sorting
			};
		case RESET_EDITING_SURVEY:
			return {
				...state,
				editingUser: {
					generalInfo: {
						id: "",
						name: "",
						projectType: 0,
						startDate: "",
						endDate: "",
						state: 0,
						suggestedMessage: false,
						isTesting: false,
						enabled: false
					}
				}
			};
		default:
			return state;
	}
};
