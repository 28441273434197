import { Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Row, Col } from "antd";
import PieChart from "components/dashboard/usersFeedback/CSAT/pieChart";

import SurveyQuickInfoType from "types/survey/generalInfo.type";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

const General = ({ quickInfo }) => {
	const { t } = useTranslation();

	return (
		<Fragment>
			<div className="dashboard-quick-info-section">
				<div className="dashboard-quick-info-section-header">
					<span>{t("pages.dashboard.usersFeedback.general_info")}</span>
				</div>
				<div className="dashboard-quick-info-section-content">
					<Row gutter={[16, 0]}>
						<Col xs={24} sm={12} xl={6}>
							<div className="dashboard-quick-info-section-content-info">
								<span>{t("pages.dashboard.usersFeedback.csat_name")}</span>
								<b>{quickInfo?.name}</b>
							</div>
						</Col>
						<Col xs={24} sm={12} xl={6}>
							<div className="dashboard-quick-info-section-content-info">
								<span>{t("pages.dashboard.usersFeedback.csat_start_date")}</span>
								<b>
									{moment
										.utc(quickInfo?.startDate)
										.local()
										.format(DATE_TIME_FORMAT)}
								</b>
							</div>
						</Col>
						<Col xs={24} sm={12} xl={6}>
							<div className="dashboard-quick-info-section-content-info">
								<span>{t("pages.dashboard.usersFeedback.csat_end_date")}</span>
								<b>{quickInfo?.endDate ? moment.utc(quickInfo.endDate).local().format(DATE_TIME_FORMAT) : "-"}</b>
							</div>
						</Col>
						<Col xs={24} sm={12} xl={6}>
							<div className="dashboard-quick-info-section-content-info">
								<span>{t("pages.dashboard.usersFeedback.last_update_at")}</span>
								<b>
									{moment
										.utc(quickInfo?.lastUpdatedAt)
										.local()
										.format(DATE_TIME_FORMAT)}
								</b>
							</div>
						</Col>
						<Col xs={24} sm={12} xl={6}>
							<div className="dashboard-quick-info-section-content-info">
								<span>{t("pages.dashboard.usersFeedback.created_at")}</span>
								<b>
									{moment
										.utc(quickInfo?.createdAt)
										.local()
										.format(DATE_TIME_FORMAT)}
								</b>
							</div>
						</Col>
						<Col xs={24} sm={12} xl={6}>
							<div className="dashboard-quick-info-section-content-info">
								<span>{t("pages.dashboard.usersFeedback.created_by")}</span>
								<b>{quickInfo?.createdBy}</b>
							</div>
						</Col>
					</Row>
				</div>
			</div>
			<div className="dashboard-quick-info-section">
				<div className="dashboard-quick-info-section-header">
					<span>{t("pages.dashboard.usersFeedback.csat_statistics")}</span>
				</div>
				<div className="dashboard-quick-info-section-content">
					<Row gutter={[16, 0]}>
						<Col span={24}>
							<h4 className="dashboard-quick-info-section-content-title">{t("pages.dashboard.usersFeedback.metrics")}</h4>
						</Col>
						<Col xs={24} sm={12} xl={6}>
							<div className="bonus-info-card" data-type="partners">
								<div className="bonus-info-card-top">
									<i className="icon-partners" />
									<span>{t("pages.dashboard.usersFeedback.partners")}</span>
								</div>
								<div className="bonus-info-card-bottom">
									<b>{quickInfo?.partnersCount ?? 0}</b>
								</div>
							</div>
						</Col>
						<Col xs={24} sm={12} xl={6}>
							<div className="bonus-info-card" data-type="totalParticipants">
								<div className="bonus-info-card-top">
									<i className="icon-players" />
									<span>{t("pages.dashboard.usersFeedback.total_participants")}</span>
								</div>
								<div className="bonus-info-card-bottom">
									<b>{quickInfo?.playersCount ?? 0}</b>
								</div>
							</div>
						</Col>
						<Col xs={24} sm={12} xl={6}>
							<div className="bonus-info-card" data-type="responseRate">
								<div className="bonus-info-card-top">
									<i className="icon-responseRate" />
									<span>{`${t("pages.dashboard.usersFeedback.response_rate")} %`}</span>
								</div>
								<div className="bonus-info-card-bottom">
									<b>{quickInfo?.responseRate ?? 0}</b>
								</div>
							</div>
						</Col>
						<Col xs={24} sm={12} xl={6}>
							<div className="bonus-info-card" data-type="totalResponses">
								<div className="bonus-info-card-top">
									<i className="icon-totalResponses" />
									<span>{t("pages.dashboard.usersFeedback.total_responses")}</span>
								</div>
								<div className="bonus-info-card-bottom">
									<b>{quickInfo?.totalResponses ?? 0}</b>
								</div>
							</div>
						</Col>
					</Row>
					<Row gutter={[16, 0]}>
						<Col span={24}>
							<h4 className="dashboard-quick-info-section-content-title" style={{ marginBottom: 0 }}>
								{t("pages.dashboard.usersFeedback.csat_score")}
							</h4>
						</Col>
						<Col xs={24} sm={24}>
							<PieChart chartData={quickInfo?.responses ?? {}} text={t("pages.dashboard.usersFeedback.csat_score")} csatScore={quickInfo?.responseScore ?? 0} />
						</Col>
					</Row>
				</div>
			</div>
		</Fragment>
	);
};

General.propTypes = {
	/** Redux state property, quick info of survey data */
	quickInfo: SurveyQuickInfoType
};

const mapStateToProps = (state) => {
	return {
		quickInfo: state.surveys.quickInfo.quickInfo
	};
};

export default connect(mapStateToProps, null)(General);
