import { Fragment } from "react";

import CashierEditComponent from "components/dashboard/retail/cashierManagement/cashiers/edit/cashier-edit.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const CashierEditRoute = () => {
	return (
		<Fragment>
			<CashierEditComponent />
		</Fragment>
	);
};

export default withPermission(CashierEditRoute, { resource: PERMISSION_RESOURCE.CASHIER, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
