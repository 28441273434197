import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Row, Col, Form, Button } from "antd";
const { Item: FormItem } = Form;

import Table from "components/common/table";
import SearchableSelect from "components/common/searchableSelect";

import { PARTNER_TYPE } from "constants/partner.constants";
import { PROJECT_TYPE } from "constants/common.constants";

import { binaryToFlags } from "utils/common";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import userProjectAccessType from "types/user/projectAccess.type";
import userBetShopAccessType from "types/user/betShopAccess.type";
import userPartnerAccessType from "types/user/partnerAccess.type";
import { PERMISSION_REQUEST_TYPE, PERMISSION_REQUEST_OBJECT_TYPE } from "constants/permissionRequest.constants";
import PendingRequestsNote from "components/common/pendingRequestsNote";
import { doesUserHaveRoleOf } from "utils/auth";
import { USER_ROLE } from "constants/user.constants";

/** User Edit Page Project Settings Tab Table Component */
const DataTableComponent = ({ dataAccess, isLoading, deleteDataAccess, partnerAccess, onAddButtonClick, projectType, userId, permission, pendingRequestsNoteActionTypes = [] }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [selectedPartnerId, setSelectedPartnerId] = useState(null);
	const [disableDueToNumberOfRequests, setDisableDueToNumberOfRequests] = useState(false);
	const isAccessManager = doesUserHaveRoleOf(USER_ROLE.ACCESS_MANAGER);
	const accessManagerNoteActions = isAccessManager
		? {
				title: t("pages.dashboard.permissions_requests.notes"),
				isPrompt: true,
				isPromptRequired: true,
				promptLabel: t("pages.dashboard.permissions_requests.notes")
			}
		: {};

	/** Load user project access*/
	useEffect(() => {
		if (!(partnerAccess.mainPartner.id ? [partnerAccess.mainPartner.id] : []).concat(partnerAccess.partners.map((p) => p.id)).includes(selectedPartnerId)) {
			setSelectedPartnerId(null);
		}
	}, [partnerAccess]);

	/** Load project access data */
	useEffect(() => {
		if (!selectedPartnerId && dataAccess.partners[0]) {
			setSelectedPartnerId(dataAccess.partners[0].id);
		}
	}, [dataAccess]);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.users.project_name"),
			dataIndex: "name",
			render: (value) => (value === "*" ? t("common.all") : value),
			sorter: false
		},
		{
			title: t("pages.dashboard.users.project_id"),
			dataIndex: "id",
			render: (value) => (value === "*" ? "" : value),
			sorter: false
		}
	];

	/** Get selected partner
	 * @function
	 * @returns {object}
	 * @memberOf ProjectsComponent
	 */
	const getSelectedPartner = () => {
		const selected = dataAccess.partners.find((p) => p.id === selectedPartnerId);
		return (
			selected || {
				apiKeys: [],
				availableApiKeys: [],
				betShops: [],
				availableBetShops: []
			}
		);
	};

	return (
		<div className="dashboard-section-content">
			<PendingRequestsNote
				objectId={routeParams.id}
				actionTypes={pendingRequestsNoteActionTypes}
				objectType={PERMISSION_REQUEST_OBJECT_TYPE.USER}
				dependencies={[dataAccess]}
				onCountChange={(count) => {
					setDisableDueToNumberOfRequests(count > 0);
				}}
				style={{
					/* for handle scss/less style loading priority mistake by webpack */
					marginBottom: 16
				}}
			/>
			<Form colon={false} requiredMark={false} layout="vertical">
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} md={6} xl={4}>
						<FormItem label={t("pages.dashboard.users.select_partner_placeholder")}>
							<SearchableSelect
								disabled={disableDueToNumberOfRequests}
								placeholder={t("pages.dashboard.users.select_partner_placeholder")}
								items={dataAccess.partners.filter((p) => binaryToFlags(Object.values(PARTNER_TYPE), p.type).includes(projectType === PROJECT_TYPE.ONLINE ? PARTNER_TYPE.ONLINE : PARTNER_TYPE.RETAIL))}
								valueProp={(item) => item.id}
								textProp={(item) => item.name}
								renderText={(item) => item.name}
								onChange={(e) => setSelectedPartnerId(e)}
								value={selectedPartnerId}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} md={18} xl={20}>
						{!disableDueToNumberOfRequests && hasPermission({ resource: permission, action: PERMISSION_ACTION.MODIFY }) && routeParams.id !== userId ? (
							<div className="user-partners-add">
								<Button onClick={() => onAddButtonClick(selectedPartnerId)} type="primary" disabled={projectType === PROJECT_TYPE.ONLINE ? getSelectedPartner().availableApiKeys.length === 0 : getSelectedPartner().availableBetShops.length === 0}>
									{projectType === PROJECT_TYPE.ONLINE ? t("pages.dashboard.users.add_new_project") : t("pages.dashboard.users.add_new_betshop")}
								</Button>
							</div>
						) : null}
					</Col>
				</Row>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={24}>
						<Table
							loading={isLoading}
							columns={columns}
							data={projectType === PROJECT_TYPE.ONLINE ? getSelectedPartner().apiKeys : getSelectedPartner().betShops}
							total={projectType === PROJECT_TYPE.ONLINE ? getSelectedPartner().apiKeys.length : getSelectedPartner().betShops.length}
							actions={
								hasPermission({ resource: permission, action: PERMISSION_ACTION.DELETE }) && routeParams.id !== userId
									? {
											delete: {
												...accessManagerNoteActions,
												messageKey: projectType === PROJECT_TYPE.ONLINE ? "user_project" : "user_betshop",
												handler: (record, note) => {
													deleteDataAccess(routeParams.id, selectedPartnerId, record.id, projectType, note);
												},
												disabled: (record) => disableDueToNumberOfRequests || (record.id === "*" && partnerAccess.availablePartners.length === 0)
											}
										}
									: {}
							}
							isDisabled={() => false}
							noPagination={true}
							showFullID={true}
						/>
					</Col>
				</Row>
			</Form>
		</div>
	);
};

/** DataTableComponent propTypes
 * PropTypes
 */
DataTableComponent.propTypes = {
	/** Redux state property, represents the projects/betShops access info of user  */
	dataAccess: PropTypes.oneOfType([userProjectAccessType, userBetShopAccessType]),
	/** Redux state property, represents the partners access info of user  */
	partnerAccess: userPartnerAccessType,
	/** Redux state property, is true when loading user project access */
	isLoading: PropTypes.bool,
	/** Redux action to delete users project/betshop access*/
	deleteDataAccess: PropTypes.func,
	/** Function, which will file on add button click */
	onAddButtonClick: PropTypes.func,
	/** The project type */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	/** Redux state property, current user id */
	userId: PropTypes.string,
	/** React property, current permission resurce */
	permission: PropTypes.oneOf([PERMISSION_RESOURCE.USER_PROJECTS, PERMISSION_RESOURCE.USER_BETSHOPS]).isRequired,
	/** React property, permission request types */
	pendingRequestsNoteActionTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(PERMISSION_REQUEST_TYPE)))
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.users.isLoading,
		partnerAccess: state.users.editingUser.partnerAccess,
		userId: state.profile.userInfo.id
	};
};

export default connect(mapStateToProps, null)(DataTableComponent);
