import PropTypes from "prop-types";
import currencyType from 'types/common/currency.type';
import profilePartners from './profilePartners.type';

export default PropTypes.shape({
	id: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	currencyCode: PropTypes.string,
	languageCode: PropTypes.string,
	partners: PropTypes.arrayOf(profilePartners),
	currencies: PropTypes.arrayOf(currencyType),
	avatarId: PropTypes.number
})
