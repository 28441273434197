import { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Form, Row, Col, Select, DatePicker, Divider } from "antd";
import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import SearchableSelect from "components/common/searchableSelect";
import { setAccessManagersFilters, getAccessManagers } from "store/actions/dashboard/userManagement/accessManagers/accessManagers.action";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import useAutosuggestion from "hooks/useAutosuggestion";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
import { FILTER_USER_STATE } from "constants/filter.constants";
const { Item: FormItem } = Form;

/** Access Managers Page Filters Component */
const Filters = ({ setAccessManagersFilters, getAccessManagers, filters, globalPartnerId, mainPartnerId }) => {
	const { t } = useTranslation();
	const partnerId = globalPartnerId || mainPartnerId;

	const accessManagerNames = useAutosuggestion(AUTOSUGGESTION_TYPE.ACCESS_MANAGER_NAMES, [partnerId], { disableAction: () => !partnerId });
	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [
				{ name: "date", keepTime: false },
				{ name: "lastUpdate", keepTime: false }
			]
		};
	}, []);

	/** Function to get filters list
	 * @function
	 * @returns {array} - filters list
	 * @memberOf Filters
	 */
	const filtersList = () => [
		{ name: "userNameOrId", title: t("pages.dashboard.users.user_name_or_ID") },
		{
			name: "state",
			title: t("common.status"),
			values: [
				{ title: t("common.all"), value: FILTER_USER_STATE.ALL },
				{ title: t("pages.dashboard.users.status_1"), value: FILTER_USER_STATE.IN_PROGRESS },
				{ title: t("pages.dashboard.users.status_2"), value: FILTER_USER_STATE.ACTIVE },
				{ title: t("pages.dashboard.users.status_4"), value: FILTER_USER_STATE.BLOCKED },
				{ title: t("pages.dashboard.users.status_8"), value: FILTER_USER_STATE.EXPIRED }
			]
		},
		{ name: "date", title: t("common.creation_date") },
		{ name: "lastUpdate", title: t("common.last_update_date") }
	];

	return (
		<FiltersWrapper filtersName="accessManagers" loadFn={getAccessManagers} setFiltersFn={setAccessManagersFilters} filters={filters} formFieldsConfigs={formFieldsConfigs} filtersList={filtersList()}>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.users.user_name_or_ID")} name="userNameOrId">
							<AutoComplete placeholder={`${t("common.start_typing")} ${t("pages.dashboard.users.user_name_or_ID")}`} items={accessManagerNames.map((k) => k.value)} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.status")} name="state">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_USER_STATE.ALL}>{t("common.all")}</Select.Option>
								<Select.Option value={FILTER_USER_STATE.IN_PROGRESS}>{t("pages.dashboard.users.status_1")}</Select.Option>
								<Select.Option value={FILTER_USER_STATE.ACTIVE}>{t("pages.dashboard.users.status_2")}</Select.Option>
								<Select.Option value={FILTER_USER_STATE.BLOCKED}>{t("pages.dashboard.users.status_4")}</Select.Option>
								<Select.Option value={FILTER_USER_STATE.EXPIRED}>{t("pages.dashboard.users.status_8")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.creation_date")} name="date">
							<DatePicker.RangePicker placeholder={[t("common.from"), t("common.to")]} format={DATE_FORMAT} disabledDate={(d) => !d || d.isAfter(moment().endOf("day"))} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.last_update_date")} name="lastUpdate">
							<DatePicker.RangePicker placeholder={[t("common.from"), t("common.to")]} format={DATE_FORMAT} disabledDate={(d) => !d || d.isAfter(moment().endOf("day"))} />
						</FormItem>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get access managers */
	getAccessManagers: PropTypes.func,
	/** Redux action to set users filters */
	setAccessManagersFilters: PropTypes.func,
	/** Redux state property, users filters */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, represents main partner id of user*/
	mainPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getAccessManagers: () => {
		dispatch(getAccessManagers());
	},
	setAccessManagersFilters: (filters) => {
		dispatch(setAccessManagersFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.accessManagers.filters,
		globalPartnerId: state.partner.globalPartnerId,
		mainPartnerId: state.profile.userInfo.mainPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
