import { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Form, Col, Row, Modal, Select, Input, Checkbox, DatePicker, Tooltip } from "antd";
const { Item: FormItem } = Form;

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import locale from "antd/es/date-picker/locale/en_US";

import { saveMaintenanceMode } from "store/actions/dashboard/settings/maintenanceMode/maintenanceMode.action";

import { POPUP_SIZE } from "constants/common.constants";

import { MAINTENANCE_MODE_DURATION_TYPE, MAINTENANCE_MODE_CHANGE_REASON } from "constants/settings.constants";

/** Maintenance mode saving Popup Component */
const MaintenanceModeSaveComponent = ({ saveMaintenanceMode, onClose }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;

	const [selectedDuration, setSelectedDuration] = useState(MAINTENANCE_MODE_DURATION_TYPE.PERMANENT);

	/** Fires when form submitted
	 * @function
	 * @memberOf MaintenanceModeSaveComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				const res = {
					reason: data.reason,
					durationType: data.durationType,
					maintenanceMode: true
				};
				if (data.durationType === MAINTENANCE_MODE_DURATION_TYPE.TEMPORARY) {
					res.startDate = data.durationTime[0].toDate();
					res.endDate = data.durationTime[1].toDate();
				}
				saveMaintenanceMode(res);
				onClose();
			})
			.catch(Function.prototype);
	};

	return (
		<Modal open={true} title={t("pages.dashboard.menu.maintenance_mode")} cancelText={t("common.cancel")} okText={t("common.save")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.SMALL} maskClosable={false} closable={false} centered>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					durationType: selectedDuration,
					durationTime: ["", ""],
					startDate: "",
					endDate: ""
				}}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={t("pages.dashboard.maintenance_mode.duration")} name="durationType">
							<Select suffixIcon={<i className="icon-down" />} getPopupContainer={() => document.body} onChange={(e) => setSelectedDuration(e)}>
								<Select.Option value={MAINTENANCE_MODE_DURATION_TYPE.PERMANENT}>{t("pages.dashboard.maintenance_mode.permanent")}</Select.Option>
								<Select.Option value={MAINTENANCE_MODE_DURATION_TYPE.TEMPORARY}>{t("pages.dashboard.maintenance_mode.temporary")}</Select.Option>
							</Select>
						</FormItem>
						<div style={{ display: selectedDuration === MAINTENANCE_MODE_DURATION_TYPE.TEMPORARY ? "block" : "none" }}>
							<FormItem
								label={t("pages.dashboard.maintenance_mode.duration_time")}
								name="durationTime"
								rules={[
									() => ({
										validator(rule, value) {
											if (!value && selectedDuration === "temporary") {
												return Promise.reject(t("validation.field_required"));
											}
											if (moment.utc(value).isBefore(moment.utc()) && selectedDuration === "temporary") {
												return Promise.reject(t("validation.field_invalid"));
											}
											return Promise.resolve();
										}
									})
								]}
							>
								<DatePicker.RangePicker
									placeholder={[t("common.from"), t("common.to")]}
									format={`${DATE_FORMAT} ${TIME_FORMAT}`}
									showTime={{ format: TIME_FORMAT }}
									disabledDate={(d) => !d || d.isBefore(moment())}
									allowClear={true}
									locale={{
										...locale,
										lang: {
											...locale.lang,
											ok: t("common.apply")
										}
									}}
									getPopupContainer={() => document.body}
								/>
							</FormItem>
						</div>
					</Col>
					<Col span={24}>
						<FormItem label={`${t("pages.dashboard.maintenance_mode.reason")} *`} name="reason" rules={[{ required: true, message: t("validation.field_required") }]}>
							<Select suffixIcon={<i className="icon-down" />} placeholder={`${t("common.select")} ${t("pages.dashboard.maintenance_mode.reason")}`} getPopupContainer={() => document.body}>
								<Select.Option value={MAINTENANCE_MODE_CHANGE_REASON.TECHNICAL_WORK}>{t("pages.dashboard.maintenance_mode.reason_1")}</Select.Option>
								<Select.Option value={MAINTENANCE_MODE_CHANGE_REASON.PROBLEMS_WITH_GAMES}>{t("pages.dashboard.maintenance_mode.reason_2")}</Select.Option>
								<Select.Option value={MAINTENANCE_MODE_CHANGE_REASON.VERSION_OR_HOTFIX}>{t("pages.dashboard.maintenance_mode.reason_4")}</Select.Option>
								<Select.Option value={MAINTENANCE_MODE_CHANGE_REASON.OTHER}>{t("pages.dashboard.maintenance_mode.reason_8")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** MaintenanceModeSaveComponent propTypes
 * PropTypes
 */
MaintenanceModeSaveComponent.propTypes = {
	/** Redux action to save maintenance mode */
	saveMaintenanceMode: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	saveMaintenanceMode: (data) => {
		dispatch(saveMaintenanceMode(data));
	}
});

export default connect(null, mapDispatchToProps)(MaintenanceModeSaveComponent);
