import { Fragment } from "react";

import ApikeysComponent from "components/dashboard/online/apikeys/apikeys.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const ApikeysRoute = () => {
	return (
		<Fragment>
			<ApikeysComponent />
		</Fragment>
	);
};

export default withPermission(ApikeysRoute, { resource: PERMISSION_RESOURCE.APIKEY, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
