import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { PARTNER_SET_LANGUAGES, PARTNER_ADD_LANGUAGE, PARTNER_UPDATE_LANGUAGE, PARTNER_DELETE_LANGUAGE, PARTNER_LANGUAGES_BEFORE, PARTNER_LANGUAGES_FINISH, PARTNER_AVAILABLE_LANGUAGES_BEFORE, PARTNER_AVAILABLE_LANGUAGES_FINISH, PARTNER_SET_AVAILABLE_LANGUAGES } from "../../../actionTypes";

import { setPartnerSaveActionFinished, setPartnerSaveActionBefore } from "./partner.action";

export const setPartnerLanguages = (languages) => ({
	type: PARTNER_SET_LANGUAGES,
	payload: { languages }
});

export const setAddPartnerLanguage = (language) => ({
	type: PARTNER_ADD_LANGUAGE,
	payload: { language }
});

export const setUpdatePartnerLanguage = (language) => ({
	type: PARTNER_UPDATE_LANGUAGE,
	payload: { language }
});

export const setDeletePartnerLanguage = (code) => ({
	type: PARTNER_DELETE_LANGUAGE,
	payload: { code }
});

const setPartnerLanguagesBefore = () => ({
	type: PARTNER_LANGUAGES_BEFORE
});

const setPartnerLanguagesFinished = () => ({
	type: PARTNER_LANGUAGES_FINISH
});

const setPartnerAvailableLanguagesBefore = () => ({
	type: PARTNER_AVAILABLE_LANGUAGES_BEFORE
});

const setPartnerAvailableLanguagesFinished = () => ({
	type: PARTNER_AVAILABLE_LANGUAGES_FINISH
});

const setPartnerAvailableLanguages = (languages) => ({
	type: PARTNER_SET_AVAILABLE_LANGUAGES,
	payload: { languages }
});

export const getPartnerLanguages = () => {
	return (dispatch) => {
		dispatch(setPartnerLanguagesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_LANGUAGES}`,
			method: Methods.GET
		})
			.then(({ data: { value: languages } }) => {
				dispatch(setPartnerLanguages(languages));
				dispatch(setPartnerLanguagesFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerLanguagesFinished());
			});
	};
};

export const addPartnerLanguage = (codes) => {
	return (dispatch) => {
		dispatch(setPartnerSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.ADD_PARTNER_LANGUAGE}`,
			method: Methods.POST,
			data: codes
		})
			.then(({ data: { value: languages } }) => {
				dispatch(setAddPartnerLanguage(languages));
				dispatch(setPartnerSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerSaveActionFinished());
			});
	};
};

export const updatePartnerLanguage = (language) => {
	return (dispatch) => {
		dispatch(setPartnerSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_PARTNER_LANGUAGE}`,
			method: Methods.POST,
			data: language
		})
			.then(({ data: { value: language } }) => {
				dispatch(getPartnerLanguages());
				dispatch(setPartnerSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerSaveActionFinished());
			});
	};
};

export const deletePartnerLanguage = (code) => {
	return (dispatch) => {
		dispatch(setPartnerSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.DELETE_PARTNER_LANGUAGE}`,
			method: Methods.DELETE,
			data: { code }
		})
			.then(() => {
				dispatch(setDeletePartnerLanguage(code));
				dispatch(setPartnerSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerSaveActionFinished());
			});
	};
};

export const getPartnerAvailableLanguages = () => {
	return (dispatch) => {
		dispatch(setPartnerAvailableLanguagesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_AVAILABLE_LANGUAGES}`,
			method: Methods.GET
		})
			.then(({ data: { value: languages } }) => {
				dispatch(setPartnerAvailableLanguages(languages));
				dispatch(setPartnerAvailableLanguagesFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerAvailableLanguagesFinished());
			});
	};
};
