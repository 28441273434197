import { Fragment } from 'react';

import JackpotEditComponent from 'components/dashboard/bonuses/jackpot/edit/jackpot-edit.component';
import withPermission from 'hocs/withPermission';

import Paths from 'constants/path.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

const BonusJackpotEditRoute = () => {
	return (
		<Fragment>
			<JackpotEditComponent />
		</Fragment>
	)
}


export default withPermission(
	BonusJackpotEditRoute,
	{ resource: PERMISSION_RESOURCE.BONUS_JACKPOT, action: PERMISSION_ACTION.VIEW },
	Paths.DASHBOARD
);
