import { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { hasPermission, hasOneOfPermissions } from "utils/permissions";

import menuItems from "components/common/dashboardMenu/items";

import userInfoType from "types/profile/userInfo.type";

/** Wrapper Component for Dashboard, which will redirect to default page */
const DashboardComponent = ({ userInfo }) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	/** Is true when current user has no permission */
	const [noPermission, setNoPermission] = useState(false);

	/** Function to get default url to redirect
	 * @function
	 * @returns {string}
	 * @memberof DashboardComponent
	 */
	const getDefaultUrl = () => {
		const items = menuItems(userInfo.partners.map((p) => p.type));
		let defaultURL = null;
		const returnURL = searchParams.get("returnURL");
		if (returnURL) {
			return returnURL;
		}

		loop: for (let i = 0; i < items.length; i++) {
			if (items[i].url) {
				if ((!Array.isArray(items[i].permission) && hasPermission(items[i].permission)) || (Array.isArray(items[i].permission) && hasOneOfPermissions(items[i].permission))) {
					defaultURL = items[i].url;
					break loop;
				}
			} else if (items[i].items) {
				for (let j = 0; j < items[i].items.length; j++) {
					if (items[i].items[j].url) {
						if (hasPermission(items[i].items[j].permission)) {
							defaultURL = items[i].items[j].url;
							break loop;
						}
					} else if (items[i].items[j].items) {
						for (let k = 0; k < items[i].items[j].items.length; k++) {
							if (items[i].items[j].items[k].url) {
								if (hasPermission(items[i].items[j].permission)) {
									defaultURL = items[i].items[j].items[k].url;
									break loop;
								}
							}
						}
					}
				}
			}
		}
		return defaultURL;
	};

	/** Redirect to default page */
	useEffect(() => {
		if (userInfo.id) {
			const defaultURL = getDefaultUrl();
			if (defaultURL) navigate(defaultURL, { replace: true });
			else setNoPermission(true);
		}
	}, [userInfo.id]);

	return <Spin className="dashboard-loader" indicator={<LoadingOutlined spin />} spinning={!noPermission} />;
};

/** DashboardComponent propTypes
 * PropTypes
 */
DashboardComponent.propTypes = {
	/** Redux state property, the user info */
	userInfo: userInfoType
};

const mapStateToProps = (state) => {
	return {
		userInfo: state.profile.userInfo
	};
};

export default connect(mapStateToProps, null)(DashboardComponent);
