import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Select, Button, Spin } from "antd";
const { Item: FormItem } = Form;

import { getFootballHandicapProbability, saveFootballHandicapProbability } from "store/actions/dashboard/virtuals/games/probabilities.action";

import { isFormChanged } from "utils/form";
import { numberTransform } from "utils/common";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import handicapProbabilityType from "types/game/handicapProbability.type";
import { GAME_CATEGORY } from "constants/game.constants";

/** Game Edit Page Probabilities Tab HandicapProbability subTab Component */
const HandicapProbabilityComponent = ({ isSaving, isLoading, footballHandicapProbability, getFootballHandicapProbability, saveFootballHandicapProbability, onTabChange, gameCategory }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);

	/** Load game handicap Ppobability */
	useEffect(() => {
		if (routeParams.id) {
			getFootballHandicapProbability(routeParams.id, gameCategory);
		}
	}, [routeParams.id, gameCategory]);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			...footballHandicapProbability
		});
	}, [footballHandicapProbability]);

	/** Fires when form submitted
	 * @function
	 * @memberOf HandicapProbabilityComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				saveFootballHandicapProbability(
					{
						id: routeParams.id,
						...data
					},
					gameCategory
				);
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					maxDifference: footballHandicapProbability.maxDifference
				}}
				onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, { ...footballHandicapProbability }))}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.games.probabilities.range")} name="maxDifference" rules={[{ type: "number", message: t("validation.field_invalid"), transform: numberTransform }]}>
									<Select disabled={true} open={!hasPermission({ resource: PERMISSION_RESOURCE.GAME_PROBABILITIES, action: PERMISSION_ACTION.MODIFY }) ? false : undefined} suffixIcon={<i className="icon-down" />}>
										<Select.Option value={2.5}>-2.5 +2.5</Select.Option>
									</Select>
								</FormItem>
							</Col>
						</Row>
					</div>
					{/*
						{
							hasPermission({ resource: PERMISSION_RESOURCE.GAME_PROBABILITIES, action: PERMISSION_ACTION.MODIFY }) &&
								<FormItem className="button-container">
									<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
										<span>{t('common.save')}</span>
									</Button>
								</FormItem>
						}
					*/}
				</div>
			</Form>
		</Spin>
	);
};

/** HandicapProbabilityComponent propTypes
 * PropTypes
 */
HandicapProbabilityComponent.propTypes = {
	/** Redux state property, is true when HandicapProbability is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when HandicapProbability is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the handicap probability of current editing game  */
	footballHandicapProbability: handicapProbabilityType,
	/** Redux action to save football handicap probability */
	saveFootballHandicapProbability: PropTypes.func,
	/** Redux action to get football handicap probability */
	getFootballHandicapProbability: PropTypes.func,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	getFootballHandicapProbability: (id, gameCategory) => {
		dispatch(getFootballHandicapProbability(id, gameCategory));
	},
	saveFootballHandicapProbability: (data, gameCategory) => {
		dispatch(saveFootballHandicapProbability(data, gameCategory));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.games.isSaving,
		isLoading: state.games.isLoading,
		footballHandicapProbability: state.games.editingGame.probabilities.footballHandicapProbability
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HandicapProbabilityComponent);
