import { TICKETS_BEFORE, TICKETS_FINISH, TICKETS_SAVE_BEFORE, TICKETS_SAVE_FINISH, SET_CURRENT_BET_TICKET, SET_CURRENT_CANCEL_TICKET, SET_CURRENT_PAYOUT_TICKET, SET_RETAIL_BET_TICKET, SET_RETAIL_CANCEL_TICKET, SET_RETAIL_PAYOUT_TICKET, SET_TICKET_LOGO } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case TICKETS_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case TICKETS_FINISH:
			return {
				...state,
				isLoading: false
			};
		case TICKETS_SAVE_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case TICKETS_SAVE_FINISH:
			return {
				...state,
				isSaving: false
			};
		case SET_CURRENT_BET_TICKET:
			return {
				...state,
				currentBetTicket: payload.ticket
			};
		case SET_CURRENT_CANCEL_TICKET:
			return {
				...state,
				currentCancelTicket: payload.ticket
			};
		case SET_CURRENT_PAYOUT_TICKET:
			return {
				...state,
				currentPayoutTicket: payload.ticket
			};
		case SET_TICKET_LOGO:
			return {
				...state,
				ticketLogoId: payload.ticketLogoId
			};
		case SET_RETAIL_BET_TICKET:
			return {
				...state,
				betTicket: payload.ticket,
				ticketLogoId: payload.ticket.ticketLogoId
			};
		case SET_RETAIL_CANCEL_TICKET:
			return {
				...state,
				cancelTicket: payload.ticket,
				ticketLogoId: payload.ticket.ticketLogoId
			};
		case SET_RETAIL_PAYOUT_TICKET:
			return {
				...state,
				payoutTicket: payload.ticket,
				ticketLogoId: payload.ticket.ticketLogoId
			};
		default:
			return state;
	}
};
