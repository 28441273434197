import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { RESPONSE_STATE } from "constants/response.constants";

import { setApikeySaveActionBefore, setApikeySaveActionFinished } from "./apikeys.action";

import { APIKEY_BLOCKED_IPS_BEFORE, APIKEY_BLOCKED_IPS_FINISH, SET_APIKEY_BLOCKED_IPS, SET_APIKEY_ADD_BLOCKED_IP, SET_APIKEY_DELETE_BLOCKED_IP } from "store/actionTypes";

const setApiKeyBlockedIPsBefore = () => ({
	type: APIKEY_BLOCKED_IPS_BEFORE
});

const setApiKeyBlockedIPsFinished = () => ({
	type: APIKEY_BLOCKED_IPS_FINISH
});

const setApikeyBlockedIPs = (ips) => ({
	type: SET_APIKEY_BLOCKED_IPS,
	payload: { ips }
});

export const setApiKeyAddBlockedIP = (ip) => ({
	type: SET_APIKEY_ADD_BLOCKED_IP,
	payload: { ip }
});

export const setApiKeyDeleteBlockedIP = (ipId) => ({
	type: SET_APIKEY_DELETE_BLOCKED_IP,
	payload: { ipId }
});

export const getApiKeyBlockedIPs = (id) => {
	return (dispatch) => {
		dispatch(setApiKeyBlockedIPsBefore);
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APIKEY_GET_BLOCKED_IPS}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: ips } }) => {
				dispatch(setApikeyBlockedIPs(ips));
				dispatch(setApiKeyBlockedIPsFinished());
			})
			.catch((ex) => {
				dispatch(setApiKeyBlockedIPsFinished());
			});
	};
};

export const addApiKeyBlockedIP = (ip) => {
	return (dispatch) => {
		dispatch(setApikeySaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APIKEY_ADD_BLOCKED_IP}`,
			method: Methods.POST,
			data: ip
		})
			.then(({ data: { value: ip, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					dispatch(setApiKeyAddBlockedIP(ip));
				}
				dispatch(setApikeySaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setApikeySaveActionFinished());
			});
	};
};

export const deleteApiKeyBlockedIP = (id, ipId) => {
	return (dispatch) => {
		dispatch(setApikeySaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APIKEY_DELETE_BLOCKED_IP}`,
			method: Methods.DELETE,
			data: { id, ipId }
		})
			.then(({ data: { status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					dispatch(setApiKeyDeleteBlockedIP(ipId));
				}
				dispatch(setApikeySaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setApikeySaveActionFinished());
			});
	};
};
