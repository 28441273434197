import { useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { Modal, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { POPUP_SIZE } from "constants/common.constants";

import { getInactiveUsers, setInactiveUsers, resendRegistrationEmail } from "store/actions/dashboard/userManagement/users/users.action";

import Table from "components/common/table";

import usersType from "types/user/user.type";

/** Users Resend Activation Emails Popup Component */
const UsersResendingEmails = ({ isInactiveUsersLoading, inactiveUsers, onClose, getInactiveUsers, setInactiveUsers, resendRegistrationEmail }) => {
	const { t } = useTranslation();

	const [selectedUserIds, setSelectedUserIds] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	const filteredInactiveUsers = inactiveUsers.filter((user) => user.userName.includes(searchValue) || user.email.includes(searchValue));

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.users.username"),
			dataIndex: "userName",
			sorter: false
		},
		{
			title: t("pages.dashboard.users.email"),
			dataIndex: "email",
			sorter: false
		}
	];

	return (
		<Modal
			open={true}
			title={t("pages.dashboard.users.resend_activation_email")}
			cancelText={t("common.cancel")}
			className="vs--modal-resend"
			okText={t("common.resend")}
			onOk={() => {
				resendRegistrationEmail(selectedUserIds);
				onClose();
				setInactiveUsers([]);
			}}
			onCancel={onClose}
			width={POPUP_SIZE.MIDDLE}
			maskClosable={false}
			closable={false}
			centered
			okButtonProps={{
				disabled: selectedUserIds.length === 0
			}}
		>
			<div className="vs--resend-activation">
				<div className="vs--resend-activation-search">
					<Input prefix={<SearchOutlined />} placeholder={t("pages.dashboard.users.search_by_username_or_email")} onChange={(event) => setSearchValue(event.target.value)} />
				</div>
				<Table
					loading={isInactiveUsersLoading}
					columns={columns}
					data={filteredInactiveUsers}
					loadFn={getInactiveUsers}
					noPagination={true}
					rowSelection={{
						type: "checkbox",
						selectedRowKeys: selectedUserIds,
						onSelect: (user, isChecked) => {
							setSelectedUserIds((currSelectedUserIds) => (isChecked ? [...currSelectedUserIds, user.key] : currSelectedUserIds.filter((userId) => userId !== user.key)));
						},
						onSelectAll: (isChecked, selectedRows, changedRows) => {
							setSelectedUserIds((currSelectedUserIds) => {
								if (currSelectedUserIds.length < inactiveUsers.length) {
									return isChecked ? [...currSelectedUserIds, ...changedRows.map((user) => user.key)] : currSelectedUserIds.filter((userId) => !changedRows.find((user) => user.key === userId));
								} else {
									return [];
								}
							});
						}
					}}
				/>
			</div>
		</Modal>
	);
};

UsersResendingEmails.propTypes = {
	/** Fires on modal close */
	onClose: PropTypes.func,
	/** Redux state property, represents the array of inactive users */
	inactiveUsers: PropTypes.arrayOf(usersType),
	/** Redux action to get inactive users */
	getInactiveUsers: PropTypes.func,
	/** Redux action to set inactive users */
	setInactiveUsers: PropTypes.func,
	/** Redux state property, is true when loading inactive users */
	isInactiveUsersLoading: PropTypes.bool,
	/** Redux action to resend registration email */
	resendRegistrationEmail: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getInactiveUsers: () => {
		dispatch(getInactiveUsers());
	},
	setInactiveUsers: (users) => {
		dispatch(setInactiveUsers(users));
	},
	resendRegistrationEmail: (id) => {
		dispatch(resendRegistrationEmail(id));
	}
});

const mapStateToProps = (state) => ({
	isInactiveUsersLoading: state.users.isInactiveUsersLoading,
	inactiveUsers: state.users.inactiveUsers
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersResendingEmails);
