import axios from 'axios';
import Methods from 'constants/methods.constants';
import ApiUrls from 'constants/api.constants';
import { RESPONSE_STATE } from 'constants/response.constants';

import {
	NOTIFICATIONS_ACTION_BEFORE,
	NOTIFICATIONS_ACTION_FINISH,
	SET_NOTIFICATIONS,
	ADD_NOTIFICATION,
	SET_NOTIFICATION_SOUND,
	DELETE_NOTIFICATION
} from '../../../actionTypes';

const setNotificationsActionBefore = () => ({
	type: NOTIFICATIONS_ACTION_BEFORE
})

const setNotificationsActionFinished = () => ({
	type: NOTIFICATIONS_ACTION_FINISH
})

const setNotifications = notifications => ({
	type: SET_NOTIFICATIONS,
	payload: { notifications }
})

const deleteNotificatoinSuccess = id => ({
	type: DELETE_NOTIFICATION,
	payload: { id }
})

export const setNotificationSound = show => ({
	type: SET_NOTIFICATION_SOUND,
	payload: { show }
})

export const addNotification = (notification) => ({
	type: ADD_NOTIFICATION,
	payload: { notification }
});

export const getNotifications = () => {
	return dispatch => {
		dispatch(setNotificationsActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_NOTIFICATIONS}`,
			method: Methods.GET
		})
			.then(({ data: { value: notifications } }) => {
				dispatch(setNotifications(notifications));
				dispatch(setNotificationsActionFinished());
			})
			.catch(() => {
				dispatch(setNotificationsActionFinished());
			})
	}
}

export const markNotificationAsRead = id => {
	return dispatch => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.NOTIFICATIONS_MARK_AS_READ}`,
			method: Methods.POST,
			data: { id }
		})
			.then(({ data: { status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					dispatch(getNotifications());
				}
			})
			.catch((ex) => {
				console.log(ex);
			})
	}
}

export const deleteNotification = (id) => {
	return (dispatch) => {
		dispatch(deleteNotificatoinSuccess(id));
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.DELETE_NOTIFICATIONS}`,
			method: Methods.DELETE,
			data: { id }
		});
	};
};
