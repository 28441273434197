import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Spin } from "antd";

import TwoFactorComponent from "./twoFactor";
import MainLoginComponent from "./mainLogin";
import UserBlockedComponent from "./userBlocked";

import { LOGIN_VIEW_COMPONENTS } from "constants/auth.constants";

/** Login Page Component */
const LoginComponent = ({ isLoading, activeComponentName }) => {
	const renderLoginViews = (activeViewName) => {
		switch (activeViewName) {
			case LOGIN_VIEW_COMPONENTS.MAIN_LOGIN_COMPONENT:
				return <MainLoginComponent />;
			case LOGIN_VIEW_COMPONENTS.TWO_FACTOR_COMPONENT:
				return <TwoFactorComponent />;
			case LOGIN_VIEW_COMPONENTS.USER_BLOCKED_COMPONENT:
				return <UserBlockedComponent />;
		}
	};

	return <Spin spinning={isLoading}>{renderLoginViews(activeComponentName)}</Spin>;
};

/** LoginComponent propTypes
 * PropTypes
 */
LoginComponent.propTypes = {
	/** Redux state property, is true when authenticating */
	isLoading: PropTypes.bool,
	/** Redux state property, indicates which component is active */
	activeComponentName: PropTypes.string,
	/** Redux action to authenticate */
	authenticate: PropTypes.func,
	/** Redux action to change active component */
	setActiveComponentNameAction: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.auth.isLoading,
		activeComponentName: state.auth.activeComponentName
	};
};

export default connect(mapStateToProps, null)(LoginComponent);
