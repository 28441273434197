import { Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Form, Input, Button, Result } from "antd";
const { Item: FormItem } = Form;

import Paths from "constants/path.constants";

import PasswordRules from "components/common/passwordRules";

import { getPasswordSettings } from "store/actions/auth/passwordSettings.action";
import { activate } from "store/actions/auth/activate.action";

import { validatePasword } from "utils/password";

import passwordSettingsType from "types/partner/passwordSettings.type";

/** User Activation Page Component */

const ActivateComponent = ({ activate, getPasswordSettings, passwordSettings, isLoading, isActivating }) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, getFieldValue } = formInstance;

	/** Load password settings */
	useEffect(() => {
		const token = searchParams.get("t");
		if (token) {
			getPasswordSettings(token);
		}
	}, []);

	/** Fires when form submitted
	 * @function
	 * @memberOf ActivateComponent
	 */
	const handleForm = () => {
		const token = searchParams.get("t");
		validateFields()
			.then(({ newPassword, confirmPassword }) => {
				activate(token, newPassword, confirmPassword, () => {
					navigate(Paths.LOGIN, { replace: true });
				});
			})
			.catch(Function.prototype);
	};

	const renderPasswordRules = (loading, settings) => {
		if (loading) return <Fragment />;
		if (!Object.keys(settings).length) return <Fragment />;
		return (
			<FormItem>
				<PasswordRules passwordSettings={settings} />
			</FormItem>
		);
	};

	return searchParams.get("t") ? (
		<div className="vs--main-login">
			<Form
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				colon={false}
				initialValues={{
					newPassword: "",
					confirmPassword: ""
				}}
			>
				<h1 className="title">{t("pages.auth.set_your_password")}</h1>
				{renderPasswordRules(isLoading, passwordSettings)}
				<FormItem
					label={`${t("pages.auth.password")} *`}
					name="newPassword"
					validateFirst
					rules={[
						{ required: true, whitespace: true, message: t("validation.field_required") },
						() => ({
							validator(rule, value) {
								return validatePasword(value, passwordSettings);
							}
						})
					]}
				>
					<Input.Password
						placeholder={`${t("common.enter")} ${t("pages.auth.password")}`}
						onChange={() => {
							setTimeout(() => {
								if (getFieldValue("confirmPassword") !== "") validateFields(["confirmPassword"]);
							}, 0);
						}}
					/>
				</FormItem>
				<FormItem
					label={`${t("pages.auth.confirm_password")} *`}
					name="confirmPassword"
					validateFirst
					rules={[
						{ required: true, whitespace: true, message: t("validation.field_required") },
						({ getFieldValue }) => ({
							validator(rule, value) {
								if (value !== getFieldValue("newPassword")) {
									return Promise.reject(t("validation.passwords_do_not_match"));
								}
								return Promise.resolve();
							}
						})
					]}
				>
					<Input.Password placeholder={`${t("pages.auth.confirm_password")}`} onPaste={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()} />
				</FormItem>
				<FormItem>
					<Button loading={isActivating} type="primary" htmlType="submit" className="button" onClick={handleForm}>
						<span>{t("pages.auth.activate")}</span>
					</Button>
				</FormItem>
			</Form>
		</div>
	) : (
		<Result status="warning" title={t("pages.auth.no_token_found")} />
	);
};

/** ActivateComponent propTypes
 * PropTypes
 */
ActivateComponent.propTypes = {
	/** Redux state property, is true when activate request is in process */
	isActivating: PropTypes.bool,
	/** Redux state property, is true when loading password settings */
	isLoading: PropTypes.bool,
	/** Redux action to get password settings */
	getPasswordSettings: PropTypes.func,
	/** Redux action to activate user */
	activate: PropTypes.func,
	/** Redux state property, the activation password settings */
	passwordSettings: passwordSettingsType
};

const mapDispatchToProps = (dispatch) => ({
	getPasswordSettings: (token) => {
		dispatch(getPasswordSettings(token));
	},
	activate: (token, newPassword, confirmPassword, onSuccess) => {
		dispatch(activate(token, newPassword, confirmPassword, onSuccess));
	}
});

const mapStateToProps = (state) => {
	return {
		isActivating: state.auth.activation.isActivating,
		isLoading: state.auth.passwordSettings.isLoading,
		passwordSettings: state.auth.passwordSettings.passwordSettings
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateComponent);
