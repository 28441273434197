import { Fragment, useEffect, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Form, Row, Col, Button, Select, Input, message, Switch, Tooltip } from "antd";
const { Item: FormItem } = Form;

import { useTranslation } from "react-i18next";

import { TICKET_TYPE } from "constants/ticket.constants";
import { TICKET_GENERAL_DETAILS, TICKET_MARKET_DETAILS, PARTNER_TICKET_LOGO_NAME } from "constants/partner.constants";
import PATHS from "constants/api.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import Ticket from "components/common/ticket";
import BannerUpload from "components/dashboard/common/bannerUpload";
import Selecter from "components/common/selecter";

import { printElement, binaryToFlags, buildPathToStaticFolderOfCDN } from "utils/common";
import { hasPermission } from "utils/permissions";

import { setLogo, deleteTicketLogo } from "store/actions/dashboard/cms/tickets/ticket.action";

import ticketType from "types/partner/ticket.type";
import { FILE_SIZES_MEASUREMENT } from "constants/common.constants";

/** Partner Edit Page Retail Tab Bet/Cancel/Payout Ticket form common part Component */
const TicketConfigComponent = ({ ticket, currentTicket, onTicketChange, type, setLogo, ticketLogoId, deleteTicketLogo, globalPartnerId, isLoading, children }) => {
	const { t } = useTranslation();

	/** Fires when logo uploaded
	 * @function
	 * @param {object} logo - the uploaded logo
	 * @memberOf TicketConfigComponent
	 */
	const onUpload = (logo) => {
		const msg = logo?.response?.message;
		msg && message.success(msg);
		setLogo(logo);
	};

	return (
		<Fragment>
			<Row gutter={[16, 0]}>
				<Col span={24}>
					<div className="dashboard-ticket">
						<div>
							{!isLoading && (
								<Row gutter={[16, 0]}>
									<Col span={24}>
										<div className="dashboard-section-info">
											<h1>{t("pages.dashboard.tickets.form.header")}</h1>
											<Tooltip title={<span dangerouslySetInnerHTML={{ __html: t("pages.dashboard.tickets.form.ticket_upload_tooltip") }}></span>}>
												<i className="icon-info" />
											</Tooltip>
										</div>
									</Col>
									<Col xs={24} sm={24} xl={12} xxl={6}>
										<BannerUpload
											uploadUrl={`${import.meta.env.SYSTEM_API_URL}${PATHS.PARTNER_UPLOAD_TICKET_LOGO}`}
											defaultFile={
												ticketLogoId
													? {
															url: `${import.meta.env.SYSTEM_CDN_URL}/${globalPartnerId}/images/${ticketLogoId}_ticket_logo.png`,
															status: "done",
															percent: 100
														}
													: null
											}
											remove={
												hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_TICKETS, action: PERMISSION_ACTION.MODIFY })
													? {
															handler: () => deleteTicketLogo(),
															message: t("pages.dashboard.tickets.form.remove_logo_confirmation_message")
														}
													: null
											}
											data={{
												partnerId: globalPartnerId
											}}
											fileBuilder={(value) => ({
												url: `${import.meta.env.SYSTEM_CDN_URL}/${globalPartnerId}/images/${value}_ticket_logo.png`
											})}
											extensions={["image/png", "image/jpeg", "image/jpg", "image/bmp", "image/gif"]}
											size={FILE_SIZES_MEASUREMENT.MB}
											disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_TICKETS, action: PERMISSION_ACTION.MODIFY })}
											disablePreview={true}
											onSuccess={onUpload}
											updateProps={[ticketLogoId]}
										/>
									</Col>
								</Row>
							)}
							<Row gutter={[16, 0]}>
								<Col span={24}>
									<h1>{t("pages.dashboard.tickets.form.general_details")}</h1>
								</Col>
								<Col span={24}>
									<FormItem name="generalDetails">
										<Selecter
											items={[
												{ title: t("pages.dashboard.tickets.view.betshop_name"), value: TICKET_GENERAL_DETAILS.BETSHOP_NAME },
												{ title: t("pages.dashboard.tickets.view.betshop_address"), value: TICKET_GENERAL_DETAILS.BETSHOP_ADDRESS },
												{ title: t("pages.dashboard.tickets.view.cashier"), value: TICKET_GENERAL_DETAILS.CASHIER_ID },
												{ title: t("pages.dashboard.tickets.view.ticket_id"), value: TICKET_GENERAL_DETAILS.TICKET_ID },
												{ title: t("pages.dashboard.tickets.view.date_time_printed"), value: TICKET_GENERAL_DETAILS.PRINTED_DATETIME },
												{ title: t("pages.dashboard.tickets.view.validation_date"), value: TICKET_GENERAL_DETAILS.VALIDATION_DATE }
											]}
											onChange={(val) => onTicketChange("generalDetails", val)}
											disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_TICKETS, action: PERMISSION_ACTION.MODIFY })}
											resetTo={binaryToFlags(Object.values(TICKET_GENERAL_DETAILS), ticket.generalDetails)}
										/>
									</FormItem>
								</Col>
							</Row>
							<Row gutter={[16, 0]}>
								<Col span={24}>
									<h1>{t("pages.dashboard.tickets.form.market_selection")}</h1>
								</Col>
								<Col span={24}>
									<FormItem name="marketSelection">
										<Selecter
											items={[
												{ title: t("pages.dashboard.tickets.view.teams"), value: TICKET_MARKET_DETAILS.TEAM_NAMES },
												{ title: `${t("pages.dashboard.tickets.view.market_odds")}(${t("pages.dashboard.tickets.view.selection")})`, value: TICKET_MARKET_DETAILS.MARKETS },
												{ title: t("pages.dashboard.tickets.view.bet_type"), value: TICKET_MARKET_DETAILS.BET_TYPE },
												{ title: t("pages.dashboard.tickets.view.stake"), value: TICKET_MARKET_DETAILS.STAKE },
												{ title: t("pages.dashboard.tickets.view.max_possible_win"), value: TICKET_MARKET_DETAILS.MAX_POSSIBLE_WIN },
												{ title: t("pages.dashboard.tickets.view.max_win_per_ticket"), value: TICKET_MARKET_DETAILS.MAX_WIN_PER_TICKET },
												{ title: t("pages.dashboard.tickets.view.game_type"), value: TICKET_MARKET_DETAILS.GAME_TYPE },
												{ title: t("pages.dashboard.tickets.view.event_id"), value: TICKET_MARKET_DETAILS.DRAW_ID },
												{ title: t("pages.dashboard.tickets.view.start"), value: TICKET_MARKET_DETAILS.START_TIME },
												{ title: t("pages.dashboard.tickets.view.possibleWin"), value: TICKET_MARKET_DETAILS.POSSIBLE_WIN }
											].concat(type === 3 ? [{ title: t("pages.dashboard.tickets.view.status"), value: TICKET_MARKET_DETAILS.STATE }] : [])}
											onChange={(val) => onTicketChange("marketSelection", val)}
											disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_TICKETS, action: PERMISSION_ACTION.MODIFY })}
											resetTo={binaryToFlags(Object.values(TICKET_MARKET_DETAILS), ticket.marketSelection)}
										/>
									</FormItem>
								</Col>
							</Row>
							<Row gutter={[16, 0]}>
								<Col span={24}>
									<h1>{t("pages.dashboard.tickets.form.barcode")}</h1>
								</Col>
								<Col span={24}>
									<div className={"bar-code-image" + (!currentTicket.enableBarcodePrinting ? " bar-code-image-disabled" : "")}>
										<img src={buildPathToStaticFolderOfCDN("admin-images/barcode.svg")} alt="barcode" />
									</div>
								</Col>
								<Col span={24}>
									<div className="inline-form-item form-switcher">
										<FormItem name="enableBarcodePrinting" valuePropName="checked" className="ant-row">
											<Switch id="enableBarcodePrinting" onChange={(val) => onTicketChange("enableBarcodePrinting", val)} disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_TICKETS, action: PERMISSION_ACTION.MODIFY })} />
										</FormItem>
										<label htmlFor="enableBarcodePrinting" className="vs--cursor-pointer vs--ml-16">
											{t("pages.dashboard.tickets.form.enable_barcode")}
										</label>
									</div>
								</Col>

								{type === 1 && (
									<Fragment>
										<Col span={24}>
											<div className={"qr-code-image" + (!currentTicket.enableQRPrinting ? " qr-code-image-disabled" : "")}>
												<img src={buildPathToStaticFolderOfCDN("admin-images/qr.svg")} alt="qrcode" />
											</div>
										</Col>
										<Col span={24}>
											<div className="inline-form-item form-switcher">
												<FormItem name="enableQRPrinting" valuePropName="checked" className="ant-row">
													<Switch onChange={(val) => onTicketChange("enableQRPrinting", val)} disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_TICKETS, action: PERMISSION_ACTION.MODIFY })} />
												</FormItem>
												<label htmlFor="enableQRPrinting" className="vs--cursor-pointer vs--ml-16">
													{t("pages.dashboard.tickets.form.enable_qr")}
												</label>
											</div>
										</Col>
									</Fragment>
								)}
							</Row>
							<Row gutter={[16, 0]}>
								<Col span={24}>
									<h1>{t("pages.dashboard.tickets.form.custom")}</h1>
								</Col>
								<Col xs={24} sm={24} xl={12} xxl={8}>
									<FormItem name="customText" rules={[{ max: 50, message: t("validation.field_invalid") }]} className="form-item-without-label">
										<Input
											maxLength={50}
											onChange={(e) => {
												onTicketChange("customText", e.target.value);
											}}
											placeholder={`${t("common.enter")} ${t("pages.dashboard.tickets.form.custom")}`}
											disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_TICKETS, action: PERMISSION_ACTION.MODIFY })}
										/>
									</FormItem>
								</Col>
							</Row>
							{children}
						</div>
						<div>
							<Ticket id={"preview-ticket-" + type} ticket={currentTicket} type={type} logo={ticketLogoId ? `${import.meta.env.SYSTEM_API_URL}${PATHS.PARTNER_GET_TICKET_LOGO}/${globalPartnerId}/${ticketLogoId}` : null} />
							<div className="print-button-container">
								{hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_TICKETS, action: PERMISSION_ACTION.MODIFY }) ? (
									<Button type="primary" className="button" onClick={() => printElement("preview-ticket-" + type)}>
										<span>{t("common.print")}</span>
									</Button>
								) : null}
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</Fragment>
	);
};

/** TicketConfigComponent propTypes
 * PropTypes
 */
TicketConfigComponent.propTypes = {
	/** Redux state, represents the current editing ticket(ticket in preview)  */
	currentTicket: ticketType,
	/** Redux state, represents the current editing ticket */
	ticket: ticketType,
	/** Fires on any field value change in form */
	onTicketChange: PropTypes.func,
	/** Ticket type */
	type: PropTypes.oneOf(Object.values(TICKET_TYPE)),
	/** Redux action to set the partner ticket logo */
	setLogo: PropTypes.func,
	/** Current ticket logo id of editing partner */
	ticketLogoId: PropTypes.number,
	/** Redux action to delete the partner ticket logo */
	deleteTicketLogo: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, is true when loading ticket details */
	isLoading: PropTypes.bool,
	/** The JSX additional content of filters */
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.tickets.isLoading,
		ticketLogoId: state.tickets.ticketLogoId,
		globalPartnerId: state.partner.globalPartnerId
	};
};

const mapDispatchToProps = (dispatch) => ({
	setLogo: (ticketLogoId) => {
		dispatch(setLogo(ticketLogoId));
	},
	deleteTicketLogo: () => {
		dispatch(deleteTicketLogo());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketConfigComponent);
