import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardBetslipsActivities, getTopMarkets, getGamesPerformance, resetDashboardGamesPerformance } from "store/actions/dashboard/dashboard/dashboard.action";
import useGamePerformanceCalculation from "./useGamePerformanceCalculation";
import { PROJECT_TYPE } from "constants/common.constants";
import { GAME_CATEGORY } from "constants/game.constants";

const getGlobalPartnerId = (state) => state.partner.globalPartnerId;
const getFiltersProjectId = (state) => state.dashboard.retail.filters.projectId;
const getFiltersPeriod = (state) => state.dashboard.retail.filters.period;
const getFiltersGameType = (state) => state.dashboard.retail.filters.gameType;
const getGamesPerformanceSource = (state) => state.dashboard.retail.gamesPerformance.source;

const useDashboardRetailRequest = (activeKey) => {
	const dispatch = useDispatch();
	/** Redux state property, represents global partner id */
	const globalPartnerId = useSelector(getGlobalPartnerId);
	/** Redux state property, project id from dashboard filters */
	const filtersProjectId = useSelector(getFiltersProjectId);
	/** Redux state property, period from dashboard filters */
	const filtersPeriod = useSelector(getFiltersPeriod);
	/** Redux state property, game type from dashboard filters */
	const filtersGameType = useSelector(getFiltersGameType);
	/** Redux state property, game performance source data */
	const gamesPerformanceSource = useSelector(getGamesPerformanceSource);

	useEffect(() => {
		if (activeKey !== PROJECT_TYPE.RETAIL.toString()) {
			return;
		}
		const abortControllerDashboardBetslipsActivities = new AbortController();
		const abortControllerGamesPerformance = new AbortController();

		const timeoutId = setTimeout(() => {
			/** Redux action to get betslip activities */
			dispatch(
				getDashboardBetslipsActivities(
					filtersProjectId,
					filtersPeriod,
					PROJECT_TYPE.RETAIL,
					GAME_CATEGORY.SCHEDULED,
					abortControllerDashboardBetslipsActivities.signal
				)
			);
			/** Redux action to reset game performance chart data and total bonuses for retail */
			dispatch(resetDashboardGamesPerformance(PROJECT_TYPE.RETAIL));
			/** Redux action to get game performance chart data and total bonuses for retail */
			dispatch(
				getGamesPerformance(
					filtersProjectId,
					filtersPeriod,
					PROJECT_TYPE.RETAIL,
					GAME_CATEGORY.SCHEDULED,
					abortControllerGamesPerformance.signal
				)
			);
		}, 50);
		return () => {
			abortControllerDashboardBetslipsActivities.abort("Cancel");
			abortControllerGamesPerformance.abort("Cancel");
			clearTimeout(timeoutId);
		};
	}, [activeKey, globalPartnerId, filtersProjectId, filtersPeriod, dispatch]);

	useEffect(() => {
		if (activeKey !== PROJECT_TYPE.RETAIL.toString()) {
			return;
		}
		const timeoutId = setTimeout(() => {
			/** Redux action to get top markets */
			dispatch(getTopMarkets(
				filtersProjectId,
				filtersPeriod,
				filtersGameType,
				PROJECT_TYPE.RETAIL,
				GAME_CATEGORY.INSTANT
			));
		}, 20);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [activeKey, globalPartnerId, filtersProjectId, filtersPeriod, filtersGameType, dispatch]);

	useGamePerformanceCalculation(gamesPerformanceSource, PROJECT_TYPE.RETAIL, globalPartnerId);
};

export default useDashboardRetailRequest;
