import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { Tabs } from "antd";

import Breadcrumbs from "components/common/breadcrumbs";
import GeneralComponent from "../edit/sections/general";
import PartnersComponent from "../edit/sections/partners";
import ProjectsAndBetShopsComponent from "../edit/sections/projectsAndBetShops";
import PermissionsComponent from "../edit/sections/permissions";

import { getUserPartnerAccess } from "store/actions/dashboard/userManagement/users/partners.action";
import { getUserGeneralInfo } from "store/actions/dashboard/userManagement/users/generalInfo.action";
import { resetEditingUser } from "store/actions/dashboard/userManagement/users/users.action";

import Paths from "constants/path.constants";

import { PARTNER_TYPE } from "constants/partner.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { updateLocationHash, getHashValue } from "utils/common";

import { hasOneOfPermissions, hasPermission } from "utils/permissions";

/** User Edit Component */
const UserEditComponent = ({ userName, getUserGeneralInfo, resetEditingUser, partnerType }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const [activeKey, setActiveKey] = useState("1");

	/** Load user partners info*/
	useEffect(() => {
		if (getHashValue("tab")) {
			setActiveKey(getHashValue("tab"));
		}
	}, []);

	/** Reset Partner Access on unmounth */

	useEffect(() => {
		return () => {
			resetEditingUser();
		};
	}, []);

	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf UserEditComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf UserEditComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	/** Has retail type partner
	 * @function
	 * @param {number} type
	 * @returns {boolean}
	 * @memberOf UserEditComponent
	 */
	const hasRetailPartner = () => !!(partnerType & PARTNER_TYPE.RETAIL);

	/** Has online type partner
	 * @function
	 * @param {number} type
	 * @returns {boolean}
	 * @memberOf UserEditComponent
	 */
	const hasOnlinePartner = () => !!(partnerType & PARTNER_TYPE.ONLINE);

	/** Load user general info*/
	useEffect(() => {
		if (routeParams.id) getUserGeneralInfo(routeParams.id);
	}, []);

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.users"), path: Paths.DASHBOARD_USERMANAGEMENT_USERS }, { title: `${t("common.edit")} ${userName}` }]} />
				<Tabs
					animated={false}
					activeKey={activeKey}
					onChange={(key) => {
						updateLocationHash("tab=" + key);
						setActiveKey(key);
					}}
					items={[
						{
							key: "1",
							label: <span className={tabClassName("1")}>{t("pages.dashboard.users.tabs.general_info")}</span>,
							children: <GeneralComponent onTabChange={(status) => changeTabSavedStatus(status, "1")} />
						},
						hasPermission({ resource: PERMISSION_RESOURCE.USER_PARTNERS, action: PERMISSION_ACTION.VIEW })
							? {
									key: "2",
									label: <span className={tabClassName("2")}>{t("pages.dashboard.users.tabs.partners")}</span>,
									children: <PartnersComponent />
								}
							: null,
						hasOneOfPermissions([
							{ resource: PERMISSION_RESOURCE.USER_PROJECTS, action: PERMISSION_ACTION.VIEW },
							{ resource: PERMISSION_RESOURCE.USER_BETSHOPS, action: PERMISSION_ACTION.VIEW }
						])
							? {
									key: "3",
									label: <span className={tabClassName("3")}>{hasOnlinePartner() && hasRetailPartner() ? t("pages.dashboard.users.tabs.projects_and_betshops") : hasOnlinePartner() ? t("pages.dashboard.users.projects") : hasRetailPartner() ? t("pages.dashboard.users.betshops") : ""}</span>,
									children: <ProjectsAndBetShopsComponent />
								}
							: null,
						hasOneOfPermissions([
							{ resource: PERMISSION_RESOURCE.USER_PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW },
							{ resource: PERMISSION_RESOURCE.USER_PERMISSION, action: PERMISSION_ACTION.VIEW }
						])
							? {
									key: "4",
									label: <span className={tabClassName("4")}>{t("pages.dashboard.users.tabs.permissions_and_groups")}</span>,
									children: <PermissionsComponent onTabChange={(status) => changeTabSavedStatus(status, "4")} />
								}
							: null
					]}
				/>
			</div>
		</Fragment>
	);
};

/** UserEditComponent propTypes
 * PropTypes
 */
UserEditComponent.propTypes = {
	/** Redux state, represents the userName of current editing user  */
	userName: PropTypes.string,
	/** Redux action to get user General info */
	getUserGeneralInfo: PropTypes.func,
	/** Redux action to get user partner access */
	getUserPartnerAccess: PropTypes.func,
	/** Redux action to reset editing user */
	resetEditingUser: PropTypes.func,
	// Redux state property, respresents partners' types of user
	partnerType: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
		userName: state.users.editingUser.generalInfo.userName,
		partnerType: state.users.editingUser.generalInfo.partnerType
	};
};

const mapDispatchToProps = (dispatch) => ({
	getUserPartnerAccess: (id) => {
		dispatch(getUserPartnerAccess(id));
	},
	resetEditingUser: () => {
		dispatch(resetEditingUser());
	},
	getUserGeneralInfo: (id) => {
		dispatch(getUserGeneralInfo(id));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEditComponent);
