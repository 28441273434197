import { buildPathToStaticFolderOfCDN } from "utils/common";

const TwoFactorDigitalCodeView = () => {
	return (
		<div className="vs--2FA-header">
			<img src={buildPathToStaticFolderOfCDN("admin-images/digital-code.svg")} alt="Digital Code" className="vs--2FA-header-img" />
		</div>
	);
};

export default TwoFactorDigitalCodeView;
