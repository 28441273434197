import PropTypes from "prop-types";

import { PARTNER_STATE } from "constants/partner.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	state: PropTypes.oneOf(Object.values(PARTNER_STATE)),
	externalId: PropTypes.string,
	isTesting: PropTypes.bool,
	countryCode: PropTypes.string,
	type: PropTypes.number,
	created: PropTypes.string,
	lastUpdate: PropTypes.string,
	totalTurnover: PropTypes.number,
	totalGGR: PropTypes.number,
	totalWin: PropTypes.number
});
