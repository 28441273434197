import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Tabs } from "antd";
import Breadcrumbs from "components/common/breadcrumbs";
import GeneralComponent from "../edit/sections/general";
import UsersComponent from "./sections/users/index";
import Paths from "constants/path.constants";
import { updateLocationHash, getHashValue } from "utils/common";

/** Permission Group Edit Component */
const PermissionGroupEditComponent = ({ groupName }) => {
	const { t } = useTranslation();

	const [unsavedTabs, setUnsavedTabs] = useState([]);
	const [activeKey, setActiveKey] = useState("1");

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf ApiKeyEditComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf ApiKeyEditComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	const tabFromUrl = getHashValue("tab");

	/** Load user group info*/
	useEffect(() => {
		if (tabFromUrl) {
			setActiveKey(tabFromUrl);
		}
	}, [tabFromUrl]);

	return (
		<>
			<div className="dashboard-section">
				<Breadcrumbs
					items={[
						{
							title: t("pages.dashboard.permissionGroups.tabs.myGroups"),
							path: Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS
						},
						{ title: `${t("common.edit")} ${groupName}` }
					]}
				/>
				<Tabs
					animated={false}
					activeKey={activeKey}
					onChange={(key) => {
						updateLocationHash("tab=" + key);
						setActiveKey(key);
					}}
					items={[
						{
							key: "1",
							label: <span className={tabClassName("1")}>{t("pages.dashboard.permissionGroups.edit.general")}</span>,
							children: <GeneralComponent onTabChange={(status) => changeTabSavedStatus(status, "1")} />
						},
						{
							key: "2",
							label: <span className={tabClassName("2")}>{t("pages.dashboard.users.users")}</span>,
							children: <UsersComponent onTabChange={(status) => changeTabSavedStatus(status, "2")} />
						}
					]}
				/>
			</div>
		</>
	);
};

/** PermissionGroupEditComponent propTypes
 * PropTypes
 */
PermissionGroupEditComponent.propTypes = {
	/** Redux state, represents the name of current editing group  */
	groupName: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		groupName: state.permissionGroups.editingPermissionGroup.generalInfo.name
	};
};

export default connect(mapStateToProps, null)(PermissionGroupEditComponent);
