import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { PERMISSION_GROUPS_SET_MY_GROUPS, PERMISSION_GROUPS_SET_MY_GROUPS_SORTING } from "store/actionTypes";

import { setPermissionGroupsActionBefore, setPermissionGroupsActionFinished, setPermissionGroupSaveActionBefore, setPermissionGroupSaveActionFinished } from "./permissionGroups.action";

const setPermissionGroups = (data) => ({
	type: PERMISSION_GROUPS_SET_MY_GROUPS,
	payload: { data }
});

export const setPermissionGroupsSorting = (sorting) => ({
	type: PERMISSION_GROUPS_SET_MY_GROUPS_SORTING,
	payload: { sorting }
});

export const getPermissionGroups = (fromFirstPage) => {
	return (dispatch, getState) => {
		dispatch(setPermissionGroupsActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PERMISSION_GROUPS}`,
			method: Methods.GET,
			params: {
				...getState().permissionGroups.myGroups.sorting,
				page: fromFirstPage ? 1 : getState().permissionGroups.myGroups.sorting.page
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setPermissionGroups(data));
				if (fromFirstPage) dispatch(setPermissionGroupsSorting({ ...getState().permissionGroups.myGroups.sorting, page: 1 }));
				dispatch(setPermissionGroupsActionFinished());
			})
			.catch(() => {
				dispatch(setPermissionGroupsActionFinished());
			});
	};
};

export const createPermissionGroup = (group, onSuccess) => {
	return (dispatch) => {
		dispatch(setPermissionGroupSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CREATE_PERMISSION_GROUP}`,
			method: Methods.POST,
			data: group
		})
			.then(({ data: { value: group } }) => {
				dispatch(setPermissionGroupSaveActionFinished());
				onSuccess && onSuccess(group);
			})
			.catch(() => {
				dispatch(setPermissionGroupSaveActionFinished());
			});
	};
};

export const deletePermissionGroup = (id) => {
	return (dispatch) => {
		dispatch(setPermissionGroupsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.DELETE_PERMISSION_GROUP}`,
			method: Methods.DELETE,
			data: { id }
		})
			.then(({ data: { status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					dispatch(getPermissionGroups());
				}
				dispatch(setPermissionGroupsActionFinished());
			})
			.catch(() => {
				dispatch(setPermissionGroupsActionFinished());
			});
	};
};
