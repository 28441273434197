import { useMemo, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import Table from 'components/common/table';
import { Input } from "antd";

import { makeGroupTitle, filterMarkets, makeWinnerMarketName } from "utils/markets";
import { ODD_STATE } from 'constants/bet.constants';

const WinningMarkets = ({ event }) => {
	const { t } = useTranslation();

	const [searchValue, setSearchValue] = useState("");

	const winnerMarkets = useMemo(() => {
		let markets = [];
		if (event.markets) {
			const gameData = event?.gameData ?? {};
			markets = filterMarkets(event.markets);
			markets = markets.map((m, ind) => {
				const period = m.period.toString();
				const group = m.group;
				const argument = m.argument;
				const odd = m.oddList?.find(o => o.state === ODD_STATE.RETURN || o.state === ODD_STATE.WON || o.state === ODD_STATE.SEMIWON);
				const outcome = odd?.outcome ?? event.gameType;
				const gameType = event.gameType;
				return (
					{
						name: makeGroupTitle(
							period,
							group,
							gameData,
							argument,
							outcome,
							gameType
						),
						result: m.oddList?.filter(o => o.state === ODD_STATE.RETURN || o.state === ODD_STATE.WON || o.state === ODD_STATE.SEMIWON).map(o => makeWinnerMarketName(o.outcome, gameData, m.group, m.argument, event.gameType)).join(", "),
						id: ind
					}
				)
			});
		}

		return markets;
	}, [event]);

	const filteredMarkets = useMemo(() => {
		return winnerMarkets.filter(m => m.result && (!searchValue || m.result.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 || m.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1));
	}, [winnerMarkets, searchValue]);

	return (
		<div className="dashboard-result-view-section">
			<div className="dashboard-result-view-section-header">
				<span>{t('pages.dashboard.events.winning_markets')}</span>
			</div>
			<div className="dashboard-result-view-markets">
				{
					event.markets ?
						<Fragment>
							<div className="dashboard-result-view-markets-search">
								<Input
									placeholder={t('common.search')}
									prefix={<i className="icon-search" />}
									onChange={e => setSearchValue(e.target.value)}
								/>
							</div>
							<div className="dashboard-result-view-markets-list">
								<Table
									loading={false}
									columns={[
										{
											title: t('pages.dashboard.events.market'),
											dataIndex: 'name',
											sorter: false,
											width: "50%"
										},
										{
											title: t('pages.dashboard.events.result'),
											dataIndex: 'result',
											sorter: false,
											width: "50%"
										}
									]}
									data={filteredMarkets}
									total={filteredMarkets.length}
									noPagination={true}
								/>
							</div>
						</Fragment> : null
				}
			</div>
		</div>
	)
};

export default WinningMarkets;