export const TOKEN_TYPE = {
	NONE: "None",
	QR: "QR",
	TOKEN: "Token",
	PASSWORD_EXPIRED: "PasswordExpired"
};

export const LOGIN_VIEW_COMPONENTS = {
	MAIN_LOGIN_COMPONENT: "mainLoginComponent",
	TWO_FACTOR_COMPONENT: "twoFactorComponent",
	USER_BLOCKED_COMPONENT: "userBlockedComponent"
};

export const USER_STATUS = {
	TEMPORARY_LOCKED: "TemporaryLocked",
	PERMANENT_LOCKED: "PermanentLocked"
};
