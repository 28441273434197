import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Form, Col, Modal, Input, Radio } from "antd";
const { Item: FormItem } = Form;
import { createStream, getStreams } from "store/actions/dashboard/streams/streams.action";
import { BROADCAST_TYPES } from "constants/broadcast.constants";
import { POPUP_SIZE } from "constants/common.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { hasPermission } from "utils/permissions";
import { showError } from "utils/message";
import Paths from "constants/path.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";
import partnerType from "types/partner/partner.type";

/** Stream Creating Popup Component */
const StreamAddComponent = ({ isSaving, isLoading, createStream, getStreams, onClose, globalPartners, globalPartnerId }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [selectedPartnerId, setSelectedPartnerId] = useState(globalPartnerId);

	const globalPartner = globalPartners.find((p) => p.id === globalPartnerId);
	const selectedPartner = globalPartners.find((p) => p.id === selectedPartnerId);

	/** Set isTesting to true, when selected partner is test */
	useEffect(() => {
		const isTestingValue = Boolean(selectedPartner?.isTesting);
		setTimeout(() => setFieldsValue({ isTesting: isTestingValue }), 0);
	}, [selectedPartnerId]);

	const initialValues = {
		name: null,
		partnerId: "",
		streamType: BROADCAST_TYPES.SATELLITE_SCHEDULED,
		isTesting: true
	};

	/** Fires when form submitted
	 * @function
	 * @memberOf StreamAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				const stream = { ...initialValues, ...data };
				Object.keys(stream).forEach((key) => {
					if (stream[key] === "" || stream[key] === undefined) {
						stream[key] = null;
					}
				});
				createStream(stream, ({ id }) => {
					if (hasPermission({ resource: PERMISSION_RESOURCE.STREAMS, action: PERMISSION_ACTION.MODIFY })) {
						navigate(Paths.DASHBOARD_STREAMS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, id));
					} else {
						getStreams();
						onClose();
					}
				});
			})
			.catch((err) => showError(err.message));
	};

	return (
		<Modal
			open={true}
			title={t("pages.dashboard.streams.createBroadcast")}
			cancelText={t("common.cancel")}
			okText={t("common.create")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.SMALL}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving || isLoading }}
			bodyStyle={{ paddingBottom: 0 }}
			centered
		>
			<Form className="dashboard-form" form={formInstance} colon={false} layout="vertical" initialValues={initialValues} requiredMark={false}>
				<Col gutter={[16, 0]}>
					<Col xs={24}>
						<FormItem label={`${t("pages.dashboard.streams.name")} *`} name="name" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
							<Input maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.streams.name")}`} />
						</FormItem>
					</Col>
					<Col xs={24}>
						<FormItem label={t("pages.dashboard.streams.partnerType")} name="partnerId">
							<Radio.Group
								className="partner-type-radio-group"
								onChange={(e) => setSelectedPartnerId(e.target.value)}
								options={[
									{
										value: "",
										label: (
											<div className="partners-checkbox">
												<span title={t("pages.dashboard.streams.generic")}>{t("pages.dashboard.streams.generic")}</span>
											</div>
										)
									},
									{
										value: globalPartnerId,
										label: (
											<div className="partners-checkbox">
												<span title={`${t("pages.dashboard.streams.partnerSpecific")} (${globalPartner?.name ?? ""})`}>
													{t("pages.dashboard.streams.partnerSpecific")} ({globalPartner?.name ?? ""})
												</span>
											</div>
										)
									}
								]}
							></Radio.Group>
						</FormItem>
					</Col>
				</Col>
			</Form>
		</Modal>
	);
};

/** StreamAddComponent propTypes
 * PropTypes
 */
StreamAddComponent.propTypes = {
	/** Redux state property, is true when creating stream request is in process */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when data is loading */
	isLoading: PropTypes.bool,
	/** Redux action to create stream */
	createStream: PropTypes.func,
	/** Redux action to create stream */
	getStreams: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Redux state property, represents the array of partners  */
	globalPartners: PropTypes.arrayOf(partnerType),
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		isSaving: state.streams.isSaving,
		isLoading: state.streams.isLoading,
		globalPartners: state.partner.globalPartners,
		globalPartnerId: state.partner.globalPartnerId
	};
};

const mapDispatchToProps = (dispatch) => ({
	createStream: (stream, onSuccess) => {
		dispatch(createStream(stream, onSuccess));
	},
	getStreams: () => {
		dispatch(getStreams());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(StreamAddComponent);
