import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { SURVEY_QUICK_INFO_BEFORE, SURVEY_QUICK_INFO_FINISH, SET_SURVEY_QUICK_INFO } from "store/actionTypes";

const setSurveyQuickInfoBefore = () => ({
	type: SURVEY_QUICK_INFO_BEFORE
});

const setSurveyQuickInfoFinished = () => ({
	type: SURVEY_QUICK_INFO_FINISH
});

const setSurveyQuickInfo = (data) => ({
	type: SET_SURVEY_QUICK_INFO,
	payload: { data }
});

export const getSurveyQuickInfo = (id) => {
	return (dispatch) => {
		dispatch(setSurveyQuickInfoBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SURVEY_QUICK_VIEW}`,
			method: Methods.GET,
			params: {
				id
			}
		})
			.then(({ data: { value } }) => {
				dispatch(setSurveyQuickInfo(value));
			})
			.finally(() => {
				dispatch(setSurveyQuickInfoFinished());
			});
	};
};
