import { useCallback, useState } from 'react';

const createNewObject = () => ({});

const useForceUpdate = () => {
	const [value, setValue] = useState(createNewObject);
	const forceUpdate = useCallback(() => {
		setValue(createNewObject());
	}, [])
	return [forceUpdate, value];
}

export default useForceUpdate