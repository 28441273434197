import { useMemo, useRef } from "react";
import PropTypes from "prop-types";

import { Form, Col, Select, DatePicker, Tooltip } from "antd";
import locale from "antd/es/date-picker/locale/en_US";
import NumericInput from "components/common/numericInput";
import SearchableSelect from "components/common/searchableSelect";

import { flagsToBinary } from "utils/common";
import { maxFieldsValidation, minFieldsValidation, betTypeFieldValidation, betProbabilityFieldValidation, dateFieldValidation, disableTimeForDateField, addMinutesToCurrentDate, fieldsValidation, disablePreviousDays, required } from "utils/bonuses";

import { BONUS_TYPE, BONUS_FIELDS_RANGE, FIELD_NAMES, DOUBLE_DOOBLE_INITIAL_DELAY } from "constants/bonus.constants";
import { ALL_OPTION_ID } from "constants/common.constants";
import { SCHEDULED_GAME_TYPE_MAPPER } from "constants/game.constants";
import { BETSLIP_MODES } from "constants/bet.constants";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

const DoubleDoobleView = ({ t, handleBonusTypeFieldChange, handleCurrencyFieldChange, handleBetshopFieldChange, handleGameTypeFieldChange, availableCurrencies, betshops, games, isEditMode = false, isBonusEditingDisabled = false, isLoading, isAvailableLoading }) => {
	/**
	 * this component is used in create and edit modes
	 * isEditMode indicates which mode is current
	 * isBonusEditingDisabled indicates that fields can not be edited (when bonus active or finished)
	 * */
	const initialStartDate = useMemo(() => {
		return addMinutesToCurrentDate({
			minute: DOUBLE_DOOBLE_INITIAL_DELAY.MINUTE,
			second: DOUBLE_DOOBLE_INITIAL_DELAY.SECOND,
			millisecond: DOUBLE_DOOBLE_INITIAL_DELAY.MILLISECOND
		});
	}, []);

	const selectedStartDateRef = useRef(initialStartDate.clone());

	const columnsAmountForLargeScreens = isEditMode ? 6 : 12;

	return (
		<>
			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item name={FIELD_NAMES.BONUSE_TYPE} label={t("pages.dashboard.bonuses.type")}>
					<Select
						placeholder={t("pages.dashboard.bonuses.type")}
						suffixIcon={<i className="icon-down" />}
						onChange={handleBonusTypeFieldChange}
						// this field must be disabled in edit mode
						disabled={isEditMode}
					>
						<Select.Option value={BONUS_TYPE.FREEBET}>{t("pages.dashboard.bonuses.freebet")}</Select.Option>

						<Select.Option value={BONUS_TYPE.DOUBLEDOOBLE}>{t("pages.dashboard.bonuses.doubleDooble")}</Select.Option>
					</Select>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item name={FIELD_NAMES.CURRENCY} label={`${t("pages.dashboard.bonuses.currency")} *`} rules={[required(t)]}>
					<Select
						placeholder={t("pages.dashboard.bonuses.currency")}
						suffixIcon={<i className="icon-down" />}
						disabled={isEditMode || isAvailableLoading} // this field must be disabled in edit mode
						onChange={handleCurrencyFieldChange}
					>
						{Object.keys(availableCurrencies).map((currencyCode) => {
							return (
								<Select.Option key={currencyCode} value={currencyCode}>
									{`${currencyCode.toUpperCase()} - ${availableCurrencies[currencyCode]}`}
								</Select.Option>
							);
						})}
					</Select>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item shouldUpdate={true} noStyle>
					{({ getFieldValue }) => {
						const selectedBetshopIds = getFieldValue(FIELD_NAMES.BETSHOP_IDS);
						const filteredBetshops = betshops.filter((betshop) => selectedBetshopIds.includes(betshop.key));
						const isCurrencySelcted = getFieldValue(FIELD_NAMES.CURRENCY).length > 0;

						return (
							<Tooltip placement="bottom" title={isBonusEditingDisabled && filteredBetshops.length > 1 ? filteredBetshops.map((betshop) => <div key={betshop.key}>{betshop.value}</div>) : null}>
								<Form.Item name={FIELD_NAMES.BETSHOP_IDS} label={`${t("pages.dashboard.bonuses.betshopIds")} *`} rules={[required(t)]}>
									<SearchableSelect
										mode="multiple"
										placeholder={t("pages.dashboard.bonuses.betshopIds")}
										items={betshops.length > 1 ? [{ key: ALL_OPTION_ID, value: t("common.all") }, ...betshops] : betshops}
										valueProp={(betshop) => betshop.key}
										textProp={(betshop) => betshop.value}
										renderText={(betshop) => betshop.value}
										onChange={handleBetshopFieldChange}
										disabled={isBonusEditingDisabled || !isCurrencySelcted || isLoading}
									/>
								</Form.Item>
							</Tooltip>
						);
					}}
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item shouldUpdate={true} noStyle>
					{({ getFieldValue }) => {
						const gameTypes = getFieldValue(FIELD_NAMES.GAME_TYPE);
						const isBetshopsSelected = getFieldValue(FIELD_NAMES.BETSHOP_IDS).length > 0;

						return (
							<Tooltip placement="bottom" title={isBonusEditingDisabled && gameTypes.length > 1 ? gameTypes.map((game) => <div key={game}>{t(`common.${SCHEDULED_GAME_TYPE_MAPPER[game]}`)}</div>) : null}>
								<Form.Item name={FIELD_NAMES.GAME_TYPE} label={`${t("pages.dashboard.bonuses.gameType")} *`} rules={[required(t)]}>
									<Select mode="multiple" maxTagCount={1} placeholder={`${t("common.select")} ${t("pages.dashboard.bonuses.gameType")}`} onChange={handleGameTypeFieldChange} suffixIcon={<i className="icon-down" />} disabled={isBonusEditingDisabled || !isBetshopsSelected || isLoading}>
										{games.length > 1 && <Select.Option value={ALL_OPTION_ID}>{t("common.all")}</Select.Option>}
										{games.map((game) => {
											return (
												<Select.Option key={game} value={game}>
													{t(`common.${SCHEDULED_GAME_TYPE_MAPPER[game]}`)}
												</Select.Option>
											);
										})}
									</Select>
								</Form.Item>
							</Tooltip>
						);
					}}
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.QUANTITY}
					label={`${t("pages.dashboard.bonuses.quantity")} *`}
					rules={[
						required(t),
						() => ({
							validator(_, value) {
								return fieldsValidation({
									value,
									rangeArr: BONUS_FIELDS_RANGE.QUANTITY,
									translationFunc: t,
									fieldNameStr: FIELD_NAMES.QUANTITY
								});
							}
						})
					]}
				>
					<NumericInput placeholder={t("pages.dashboard.bonuses.quantity")} disabled={isBonusEditingDisabled} />
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.BET_PROBABILITY}
					label={`${t("pages.dashboard.bonuses.betProbability")} *`}
					rules={[
						required(t),
						() => ({
							validator(_, value) {
								return betProbabilityFieldValidation({
									value,
									translationFunc: t,
									rangeArr: BONUS_FIELDS_RANGE.BET_PROBABILITY
								});
							}
						})
					]}
				>
					<NumericInput placeholder={t("pages.dashboard.bonuses.betProbability")} disabled={isBonusEditingDisabled} />
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.DATE}
					label={`${t("pages.dashboard.bets.date_and_time")} *`}
					rules={[
						() => ({
							validator(_, [selectedStartDate, selectedEndDate]) {
								return dateFieldValidation({
									selectedStartDate,
									selectedEndDate,
									config: TIME_FORMAT,
									translationFunc: t,
									initialStartDate
								});
							}
						})
					]}
				>
					<DatePicker.RangePicker
						placeholder={[t("pages.dashboard.bonuses.startDate"), t("pages.dashboard.bonuses.endDate")]}
						format={`${DATE_FORMAT} ${TIME_FORMAT}`}
						showTime={{ format: TIME_FORMAT }}
						disabled={isBonusEditingDisabled}
						disabledDate={disablePreviousDays}
						disabledTime={(date, type) => {
							if (type === "start") {
								selectedStartDateRef.current = date.clone();
							}
							return disableTimeForDateField({
								date,
								type,
								selectedStartDate: selectedStartDateRef.current,
								initialStartDate
							});
						}}
						allowClear={false}
						locale={{
							...locale,
							lang: {
								...locale.lang,
								ok: t("common.apply")
							}
						}}
					/>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.BET_TYPE}
					label={`${t("pages.dashboard.bonuses.betType")} *`}
					dependencies={[FIELD_NAMES.GAME_TYPE]}
					rules={[
						required(t),
						({ getFieldValue }) => ({
							validator(_, value) {
								return betTypeFieldValidation({
									value,
									selectedGameTypes: getFieldValue(FIELD_NAMES.GAME_TYPE),
									translationFunc: t
								});
							}
						})
					]}
				>
					<Select placeholder={`${t("common.select")} ${t("pages.dashboard.bonuses.betType")}`} suffixIcon={<i className="icon-down" />} disabled={isBonusEditingDisabled}>
						<Select.Option value={BETSLIP_MODES.SINGLE}>{t("pages.dashboard.bets.single")}</Select.Option>

						<Select.Option value={BETSLIP_MODES.MULTI}>{t("pages.dashboard.bets.multi")}</Select.Option>

						<Select.Option value={flagsToBinary([BETSLIP_MODES.SINGLE, BETSLIP_MODES.MULTI])}>{`${t("pages.dashboard.bets.single")} or ${t("pages.dashboard.bets.multi")}`}</Select.Option>
					</Select>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.MIN_ODDS_SINGLE}
					label={t("pages.dashboard.bonuses.minOddsSingle")}
					dependencies={[FIELD_NAMES.MAX_ODDS_SINGLE]}
					rules={[
						({ getFieldValue }) => ({
							validator(_, value) {
								return minFieldsValidation({
									value,
									rangeArr: BONUS_FIELDS_RANGE.MIN_ODDS_SINGLE,
									translationFunc: t,
									fieldNameStr: FIELD_NAMES.MIN_ODDS_SINGLE,
									dependencyFieldValue: getFieldValue(FIELD_NAMES.MAX_ODDS_SINGLE),
									dependencyFieldName: FIELD_NAMES.MAX_ODDS_SINGLE
								});
							}
						})
					]}
				>
					<NumericInput placeholder={t("pages.dashboard.bonuses.minOddsSingle")} disabled={isBonusEditingDisabled} />
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.MAX_ODDS_SINGLE}
					label={t("pages.dashboard.bonuses.maxOddsSingle")}
					dependencies={[FIELD_NAMES.MIN_ODDS_SINGLE]}
					rules={[
						({ getFieldValue }) => ({
							validator(_, value) {
								return maxFieldsValidation({
									value,
									rangeArr: BONUS_FIELDS_RANGE.MAX_ODDS_SINGLE,
									translationFunc: t,
									fieldNameStr: FIELD_NAMES.MAX_ODDS_SINGLE,
									dependencyFieldValue: getFieldValue(FIELD_NAMES.MIN_ODDS_SINGLE),
									dependencyFieldName: FIELD_NAMES.MIN_ODDS_SINGLE
								});
							}
						})
					]}
				>
					<NumericInput placeholder={t("pages.dashboard.bonuses.maxOddsSingle")} disabled={isBonusEditingDisabled} />
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.MIN_ODDS_MULTI}
					label={t("pages.dashboard.bonuses.minOddsMulti")}
					dependencies={[FIELD_NAMES.MAX_ODDS_MULTI]}
					rules={[
						({ getFieldValue }) => ({
							validator(_, value) {
								return minFieldsValidation({
									value,
									rangeArr: BONUS_FIELDS_RANGE.MIN_ODDS_MULTI,
									translationFunc: t,
									fieldNameStr: FIELD_NAMES.MIN_ODDS_MULTI,
									dependencyFieldValue: getFieldValue(FIELD_NAMES.MAX_ODDS_MULTI),
									dependencyFieldName: FIELD_NAMES.MAX_ODDS_MULTI
								});
							}
						})
					]}
				>
					<NumericInput placeholder={t("pages.dashboard.bonuses.minOddsMulti")} disabled={isBonusEditingDisabled} />
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.MAX_ODDS_MULTI}
					label={t("pages.dashboard.bonuses.maxOddsMulti")}
					dependencies={[FIELD_NAMES.MIN_ODDS_MULTI]}
					rules={[
						({ getFieldValue }) => ({
							validator(_, value) {
								return maxFieldsValidation({
									value,
									rangeArr: BONUS_FIELDS_RANGE.MAX_ODDS_MULTI,
									translationFunc: t,
									fieldNameStr: FIELD_NAMES.MAX_ODDS_MULTI,
									dependencyFieldValue: getFieldValue(FIELD_NAMES.MIN_ODDS_MULTI),
									dependencyFieldName: FIELD_NAMES.MIN_ODDS_MULTI
								});
							}
						})
					]}
				>
					<NumericInput placeholder={t("pages.dashboard.bonuses.maxOddsMulti")} disabled={isBonusEditingDisabled} />
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.MIN_AMOUNT_SINGLE}
					label={t("pages.dashboard.bonuses.minAmountSingle")}
					dependencies={[FIELD_NAMES.MAX_AMOUNT_SINGLE]}
					rules={[
						({ getFieldValue }) => ({
							validator(_, value) {
								return minFieldsValidation({
									value,
									rangeArr: BONUS_FIELDS_RANGE.MIN_AMOUNT_SINGLE,
									translationFunc: t,
									fieldNameStr: FIELD_NAMES.MIN_AMOUNT_SINGLE,
									dependencyFieldValue: getFieldValue(FIELD_NAMES.MAX_AMOUNT_SINGLE),
									dependencyFieldName: FIELD_NAMES.MAX_AMOUNT_SINGLE
								});
							}
						})
					]}
				>
					<NumericInput placeholder={t("pages.dashboard.bonuses.minAmountSingle")} disabled={isBonusEditingDisabled} />
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.MAX_AMOUNT_SINGLE}
					label={t("pages.dashboard.bonuses.maxAmountSingle")}
					dependencies={[FIELD_NAMES.MIN_AMOUNT_SINGLE]}
					rules={[
						({ getFieldValue }) => ({
							validator(_, value) {
								return maxFieldsValidation({
									value,
									rangeArr: BONUS_FIELDS_RANGE.MAX_ODDS_MULTI,
									translationFunc: t,
									fieldNameStr: FIELD_NAMES.MIN_AMOUNT_SINGLE,
									dependencyFieldValue: getFieldValue(FIELD_NAMES.MIN_AMOUNT_SINGLE),
									dependencyFieldName: FIELD_NAMES.MIN_AMOUNT_SINGLE
								});
							}
						})
					]}
				>
					<NumericInput placeholder={t("pages.dashboard.bonuses.maxAmountSingle")} disabled={isBonusEditingDisabled} />
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.MIN_AMOUNT_MULTI}
					label={t("pages.dashboard.bonuses.minAmountMulti")}
					dependencies={[FIELD_NAMES.MAX_AMOUNT_MULTI]}
					rules={[
						({ getFieldValue }) => ({
							validator(_, value) {
								return minFieldsValidation({
									value,
									rangeArr: BONUS_FIELDS_RANGE.MIN_AMOUNT_MULTI,
									translationFunc: t,
									fieldNameStr: FIELD_NAMES.MIN_AMOUNT_MULTI,
									dependencyFieldValue: getFieldValue(FIELD_NAMES.MAX_AMOUNT_MULTI),
									dependencyFieldName: FIELD_NAMES.MAX_AMOUNT_MULTI
								});
							}
						})
					]}
				>
					<NumericInput placeholder={t("pages.dashboard.bonuses.minAmountMulti")} disabled={isBonusEditingDisabled} />
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.MAX_AMOUNT_MULTI}
					label={t("pages.dashboard.bonuses.maxAmountMulti")}
					dependencies={[FIELD_NAMES.MIN_AMOUNT_MULTI]}
					rules={[
						({ getFieldValue }) => ({
							validator(_, value) {
								return maxFieldsValidation({
									value,
									rangeArr: BONUS_FIELDS_RANGE.MAX_AMOUNT_MULTI,
									translationFunc: t,
									fieldNameStr: FIELD_NAMES.MAX_AMOUNT_MULTI,
									dependencyFieldValue: getFieldValue(FIELD_NAMES.MIN_AMOUNT_MULTI),
									dependencyFieldName: FIELD_NAMES.MIN_AMOUNT_MULTI
								});
							}
						})
					]}
				>
					<NumericInput placeholder={t("pages.dashboard.bonuses.maxAmountMulti")} disabled={isBonusEditingDisabled} />
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.MIN_POSSIBLE_WIN_AMOUNT}
					label={t("pages.dashboard.bonuses.minPossibleWinAmount")}
					dependencies={[FIELD_NAMES.MAX_POSSIBLE_WIN_AMOUNT]}
					rules={[
						({ getFieldValue }) => ({
							validator(_, value) {
								return minFieldsValidation({
									value,
									rangeArr: BONUS_FIELDS_RANGE.MIN_POSSIBLE_WIN,
									translationFunc: t,
									fieldNameStr: FIELD_NAMES.MIN_POSSIBLE_WIN_AMOUNT,
									dependencyFieldValue: getFieldValue(FIELD_NAMES.MAX_POSSIBLE_WIN_AMOUNT),
									dependencyFieldName: FIELD_NAMES.MAX_POSSIBLE_WIN_AMOUNT
								});
							}
						})
					]}
				>
					<NumericInput placeholder={t("pages.dashboard.bonuses.minPossibleWinAmount")} disabled={isBonusEditingDisabled} />
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} xl={columnsAmountForLargeScreens}>
				<Form.Item
					name={FIELD_NAMES.MAX_POSSIBLE_WIN_AMOUNT}
					label={t("pages.dashboard.bonuses.maxPossibleWinAmount")}
					dependencies={[FIELD_NAMES.MIN_POSSIBLE_WIN_AMOUNT]}
					rules={[
						({ getFieldValue }) => ({
							validator(_, value) {
								return maxFieldsValidation({
									value,
									rangeArr: BONUS_FIELDS_RANGE.MAX_POSSIBLE_WIN,
									translationFunc: t,
									fieldNameStr: FIELD_NAMES.MAX_POSSIBLE_WIN_AMOUNT,
									dependencyFieldValue: getFieldValue(FIELD_NAMES.MIN_POSSIBLE_WIN_AMOUNT),
									dependencyFieldName: FIELD_NAMES.MIN_POSSIBLE_WIN_AMOUNT
								});
							}
						})
					]}
				>
					<NumericInput placeholder={t("pages.dashboard.bonuses.maxPossibleWinAmount")} disabled={isBonusEditingDisabled} />
				</Form.Item>
			</Col>
		</>
	);
};

/** DoubleDoobleView propTypes
 * PropTypes
 */
DoubleDoobleView.propTypes = {
	/** Fires on bonus type field change */
	handleBonusTypeFieldChange: PropTypes.func,
	/** Fires on currency field change */
	handleCurrencyFieldChange: PropTypes.func,
	/** Fires on betshop field change */
	handleBetshopFieldChange: PropTypes.func,
	/** Fires on game type field change */
	handleGameTypeFieldChange: PropTypes.func,
	/**  Translates texts */
	t: PropTypes.func,
	/** Redux state property, represents the object of available currencies */
	availableCurrencies: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	/** Available betshops selected by currency */
	betshops: PropTypes.array,
	/** Available games selected by betshopsIds */
	games: PropTypes.array,
	/** Specifies the bonus in edit mode */
	isEditMode: PropTypes.bool,
	/** Indicates whether the bonus can be edited */
	isBonusEditingDisabled: PropTypes.bool,
	/** Redux state property, is true when loading available currencies  */
	isAvailableLoading: PropTypes.bool,
	/** Redux state property, is true when data is loading */
	isLoading: PropTypes.bool
};

export default DoubleDoobleView;
