import { ERRORS_ACTION_BEFORE, ERRORS_ACTION_FINISH, ERROR_ACTION_BEFORE, ERROR_ACTION_FINISH, SET_ERRORS, SET_ERRORS_SORTING, SET_ERRORS_FILTERS, SET_ERROR } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case ERRORS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case ERRORS_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case ERROR_ACTION_BEFORE:
			return {
				...state,
				isErrorLoading: true
			};
		case ERROR_ACTION_FINISH:
			return {
				...state,
				isErrorLoading: false
			};
		case SET_ERRORS:
			return {
				...state,
				errors: payload.errors.item2,
				total: payload.errors.item1
			};
		case SET_ERRORS_SORTING:
			return {
				...state,
				sorting: payload.sorting
			};
		case SET_ERRORS_FILTERS:
			return {
				...state,
				filters: payload.filters,
				sorting: {
					...state.sorting,
					page: 1
				}
			};
		case SET_ERROR:
			return {
				...state,
				errors: state.errors.map((e) => (e.id === payload.error.id ? { ...e, details: payload.error } : e)),
				error: payload.error
			};
		default:
			return state;
	}
};
