import { Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Col, Input, Row, Modal, Tooltip } from "antd";
const { Item: FormItem } = Form;

import PasswordRules from "components/common/passwordRules";

import { getPasswordSettings } from "store/actions/dashboard/profile/passwordSettings.action";
import { changePassword } from "store/actions/dashboard/profile/changePassword.action";

import { validatePasword } from "utils/password";

import passwordSettingsType from "types/partner/passwordSettings.type";

import { POPUP_SIZE } from "constants/common.constants";

/** Change Pasword Popup Component */
const ChangePasswordComponent = ({ isPasswordChanging, isLoading, passwordSettings, getPasswordSettings, changePassword, onClose }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, getFieldValue } = formInstance;

	/** Load password settings */
	useEffect(() => {
		getPasswordSettings();
	}, []);

	/** Fires when form submitted
	 * @function
	 * @memberOf ChangePasswordComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				changePassword(data, onClose);
			})
			.catch(Function.prototype);
	};

	return (
		<Modal
			open={true}
			title={
				<div className="profile-change-password">
					<span>{t("pages.dashboard.profile.change_password")}</span>
					{!isLoading && Object.keys(passwordSettings).length > 0 ? (
						<Tooltip overlayClassName="password-rules-tooltip" title={<PasswordRules passwordSettings={passwordSettings} />}>
							<i className="icon-info" />
						</Tooltip>
					) : null}
				</div>
			}
			cancelText={t("common.cancel")}
			okText={t("common.save")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.SMALL}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isPasswordChanging }}
			centered
		>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					currentPassword: "",
					newPassword: "",
					confirmPassword: ""
				}}
			>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={24}>
						<FormItem label={`${t("pages.dashboard.profile.current_password")} *`} name="currentPassword" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
							<Input.Password placeholder={`${t("common.enter")} ${t("pages.dashboard.profile.current_password")}`} />
						</FormItem>
					</Col>
				</Row>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={24}>
						<FormItem
							label={`${t("pages.dashboard.profile.new_password")} *`}
							name="newPassword"
							validateFirst
							rules={[
								{ required: true, whitespace: true, message: t("validation.field_required") },
								({ getFieldValue }) => ({
									validator(rule, value) {
										return validatePasword(value, passwordSettings);
									}
								})
							]}
						>
							<Input.Password
								placeholder={`${t("common.enter")} ${t("pages.dashboard.profile.new_password")}`}
								onChange={() => {
									setTimeout(() => {
										if (getFieldValue("confirmPassword") !== "") validateFields(["confirmPassword"]);
									}, 0);
								}}
							/>
						</FormItem>
					</Col>
				</Row>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={24}>
						<FormItem
							label={`${t("pages.dashboard.profile.confirm_password")} *`}
							name="confirmPassword"
							validateFirst
							rules={[
								{ required: true, whitespace: true, message: t("validation.field_required") },
								({ getFieldValue }) => ({
									validator(rule, value) {
										if (value !== getFieldValue("newPassword")) {
											return Promise.reject(t("validation.passwords_do_not_match"));
										}
										return Promise.resolve();
									}
								})
							]}
						>
							<Input.Password placeholder={`${t("pages.dashboard.profile.confirm_password")}`} onPaste={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()} />
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** ChangePasswordComponent propTypes
 * PropTypes
 */
ChangePasswordComponent.propTypes = {
	/** Redux state property, is true when change password request is in process */
	isPasswordChanging: PropTypes.bool,
	/** Redux state property, is true when loading password settings */
	isLoading: PropTypes.bool,
	/** Redux action to get password settings */
	getPasswordSettings: PropTypes.func,
	/** Redux action to change password */
	changePassword: PropTypes.func,
	/** Redux state property, the password settings */
	passwordSettings: passwordSettingsType,
	/** Fires on popup close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getPasswordSettings: () => {
		dispatch(getPasswordSettings());
	},
	changePassword: (data, onSuccess) => {
		dispatch(changePassword(data, onSuccess));
	}
});

const mapStateToProps = (state) => {
	return {
		isPasswordChanging: state.profile.isPasswordChanging,
		isLoading: state.profile.passwordSettings.isLoading,
		passwordSettings: state.profile.passwordSettings.passwordSettings
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordComponent);
