import { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import OutsideAlerter from "components/common/outsideAlerter";
import NotificationSound from "./notificationSound";

import { getNotifications, markNotificationAsRead, setNotificationSound, deleteNotification } from "store/actions/dashboard/notifications/notifications.action";

import notificationType from "types/notification/notification.type";

import { ACCESS_MANAGER_NOTIFICATION_CONFIGS, NOTIFICATION_STATE, NOTIFICATION_TYPE } from "constants/notification.constants";
import Paths from "constants/path.constants";
import { PERMISSION_REQUESTS_MENU_KEYS, PERMISSION_REQUEST_TYPE } from "constants/permissionRequest.constants";
import { doesUserHaveRoleOf } from "utils/auth";
import { USER_ROLE } from "constants/user.constants";

/** User Notification page Component */
const NotificationsComponent = ({ getNotifications, markNotificationAsRead, deleteNotification, notifications, setNotificationSound, sound }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [opened, setOpened] = useState(false);
	const isAccessManager = doesUserHaveRoleOf(USER_ROLE.ACCESS_MANAGER);

	/** Load user notifications */
	useEffect(() => {
		getNotifications();
	}, []);

	/** Function which checks if there is unreed notification
	 * @function
	 * @returns {boolean}
	 * @memberOf NotificationsComponent
	 */
	const hasUnreededNotification = () => notifications.some((n) => n.state === NOTIFICATION_STATE.UNREADED);

	/** Function which fires on notification click
	 * @function
	 * @param {object} notification
	 * @memberOf NotificationsComponent
	 */
	const handleNotificationClick = (notification) => {
		setOpened(false);
		markNotificationAsRead(notification.id);
		switch (notification.type) {
			case NOTIFICATION_TYPE.REPRINT_REQUEST:
				navigate({
					pathname: Paths.DASHBOARD_BETSLIPS_RETAIL,
					search: `type=${(notification?.reprintRequestData?.requestType ?? "")}&betSlipId=${notification?.reprintRequestData?.betSlipId ?? ""}&partnerId=${notification.partnerId}&f=${Date.now()}`,
					hash: `tab=3`
				})
				break;
			case NOTIFICATION_TYPE.ACCESS_MANAGER_REQUEST:
				const accessManagerRequestNavigationHash = isAccessManager ? PERMISSION_REQUESTS_MENU_KEYS.HISTORY : PERMISSION_REQUESTS_MENU_KEYS.PENDING_REQUESTS;
				let search = null;
				if (!isAccessManager && notification?.data) {
					const parsedObject = JSON.parse(notification?.data);
					const collectionArr = [];
					if (parsedObject.objectType) {
						collectionArr.push("objectType=" + parsedObject.objectType);
					}
					if (parsedObject.objectId) {
						collectionArr.push("objectId=" + parsedObject.objectId);
					}
					if (parsedObject.actionType) {
						collectionArr.push("actionTypes=" + parsedObject.actionType);
					}

					search = "?" + collectionArr.join("&");
				}
				navigate({
					pathname: Paths.PERMISSION_REQUESTS,
					search,
					hash: `tab=${accessManagerRequestNavigationHash}`
				});
				break;
			default:
				break;
		}
	};

	/** Function which fires on notification delete button click
	 * @function
	 * @param {object} e - event object
	 * @param {string} id - notification id
	 * @memberOf NotificationsComponent
	 */
	const handleNotificationDelete = (e, id) => {
		e.stopPropagation();
		deleteNotification(id);
	};

	const getNotificationData = (notification) => {
		let notificationData = null;
		try {
			notificationData = notification.type === NOTIFICATION_TYPE.REPRINT_REQUEST ? notification.reprintRequestData : JSON.parse(notification.data);
		} catch (error) {
			return {};
		}
		return notificationData ?? {};
	};

	const makeNotificationTitle = (notification) => {
		const notificationData = getNotificationData(notification);
		if (notification.type === NOTIFICATION_TYPE.REPRINT_REQUEST) {
			return t("pages.dashboard.notifications.notification_type_" + notification.type);
		}
		if (notification.type === NOTIFICATION_TYPE.ACCESS_MANAGER_REQUEST) {
			const { TITLE } = ACCESS_MANAGER_NOTIFICATION_CONFIGS;
			const titleKey = `pages.dashboard.permissions_requests.actionTypes.${TITLE[notificationData.actionType]}`;
			return t(titleKey);
		}
		return notification.name;
	};
	const makeNotificationDescription = (notification) => {
		const notificationData = getNotificationData(notification);
		if (notification.type === NOTIFICATION_TYPE.ACCESS_MANAGER_REQUEST) {
			const { ACTION_TYPES, ACTION_RESOURCES } = ACCESS_MANAGER_NOTIFICATION_CONFIGS;

			const descType = ACTION_TYPES[notificationData.actionType];
			const resource = ACTION_RESOURCES[notificationData.actionType];
			const translationKey = `pages.dashboard.permissions_requests.notification_description.${descType}`;

			return t(translationKey).replace("%%requester%%", notificationData.userName).replace("%%resource%%", resource).replace("%%objectName%%", notificationData.objectName);
		}
		return notification.message;
	};

	return (
		<Fragment>
			<OutsideAlerter callback={() => setOpened(false)}>
				<Fragment>
					<div className={"notification-button" + (opened ? " notification-button-active" : "")} onClick={() => setOpened(!opened)}>
						<i className="icon-bell" />
						{hasUnreededNotification() && <div className="notification-button-mark" />}

						{opened && (
							<div className="notification-list">
								{notifications.length > 0 ? (
									notifications.map((notification) => (
										<div className={"notification-list-item" + (notification.state === NOTIFICATION_STATE.UNREADED ? " notification-list-item-unseen" : "")} key={notification.id} onClick={() => handleNotificationClick(notification)}>
											<div className="notification-list-item-inner">
												<div className="notification-list-item-inner-head">
													<div className="notification-list-item-title">
														<h3>{makeNotificationTitle(notification)}</h3>
														{notification.state === NOTIFICATION_STATE.UNREADED && <div className="notification-list-item-mark" />}
													</div>
													<span className="notification-list-item-time">{moment.utc(notification.time).local().fromNow()}</span>
													<div className="notification-list-item-close" onClick={(e) => handleNotificationDelete(e, notification.id)}>
														<i className="icon-close" />
													</div>
												</div>
												<span className="notification-list-item-desc" title={makeNotificationDescription(notification)}>
													{makeNotificationDescription(notification)}
												</span>
											</div>
										</div>
									))
								) : (
									<div className="notification-list-empty">
										<i className="icon-speaker" />
										<span>{t("pages.dashboard.notifications.no_notifications")}</span>
									</div>
								)}
							</div>
						)}
					</div>
				</Fragment>
			</OutsideAlerter>
			{sound && <NotificationSound onEnd={() => setNotificationSound(false)} />}
		</Fragment>
	);
};

/** NotificationsComponent propTypes
 * PropTypes
 */
NotificationsComponent.propTypes = {
	/** Redux action to get user profile */
	getNotifications: PropTypes.func,
	/** Redux action to mark notifications as read */
	markNotificationAsRead: PropTypes.func,
	/** Redux action to delete notification */
	deleteNotification: PropTypes.func,
	/** Redux state, represents the array of notifications  */
	notifications: PropTypes.arrayOf(notificationType),
	/** Redux state property for notification sound */
	sound: PropTypes.bool,
	/** Redux action for notification sound */
	setNotificationSound: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getNotifications: () => {
		dispatch(getNotifications());
	},

	markNotificationAsRead: (id) => {
		dispatch(markNotificationAsRead(id));
	},

	setNotificationSound: (sound) => {
		dispatch(setNotificationSound(sound));
	},

	deleteNotification: (id) => {
		dispatch(deleteNotification(id));
	}
});

const mapStateToProps = (state) => {
	return {
		notifications: state.notifications.notifications,
		sound: state.notifications.sound
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsComponent);
