import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { refreshToken, getUser } from "utils/auth";

import { APIKEYS_ACTION_BEFORE, APIKEYS_ACTION_FINISH, APIKEYS_ACTION_SET_APIKEYS, APIKEY_SAVE_ACTION_BEFORE, APIKEY_SAVE_ACTION_FINISH, APIKEY_DELETE_ACTION_SUCCESS, APIKEYS_SET_SORTING, APIKEYS_SET_FILTERS, APIKEYS_ADD_ONS_ACTION_FINISH, APIKEYS_ADD_ONS_ACTION_BEFORE, SET_APIKEYS_ADD_ONS, APIKEYS_ADD_ONS_SAVE_BEFORE, APIKEYS_ADD_ONS_SAVE_FINISH } from "store/actionTypes";
import { message } from "antd";

export const setApikeysActionBefore = () => ({
	type: APIKEYS_ACTION_BEFORE
});

export const setApikeysActionFinished = () => ({
	type: APIKEYS_ACTION_FINISH
});

export const setApikeySaveActionBefore = () => ({
	type: APIKEY_SAVE_ACTION_BEFORE
});

export const setApikeySaveActionFinished = () => ({
	type: APIKEY_SAVE_ACTION_FINISH
});

const setApikeysActionSetApikeys = (data) => ({
	type: APIKEYS_ACTION_SET_APIKEYS,
	payload: { data }
});

const setApikeyDeleteActionSuccess = (id) => ({
	type: APIKEY_DELETE_ACTION_SUCCESS,
	payload: { id }
});

export const setApikeysSorting = (sorting) => ({
	type: APIKEYS_SET_SORTING,
	payload: { sorting }
});

export const setApikeysFilters = (filters) => ({
	type: APIKEYS_SET_FILTERS,
	payload: { filters }
});

export const getApikeys = (fromFirstPage, loadAll) => {
	return (dispatch, getState) => {
		dispatch(setApikeysActionBefore());
		let params = null;
		if (loadAll) {
			params = { page: 1, limit: 500 };
		} else {
			params = {
				...getState().apikeys.sorting,
				...getState().apikeys.filters,
				page: fromFirstPage ? 1 : getState().apikeys.sorting.page
			};
		}
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_APIKEYS}`,
			method: Methods.GET,
			params: params
		})
			.then(({ data: { value: data } }) => {
				dispatch(setApikeysActionSetApikeys(data));
				if (fromFirstPage) dispatch(setApikeysSorting({ ...getState().apikeys.sorting, page: 1 }));
				dispatch(setApikeysActionFinished());
			})
			.catch(() => {
				dispatch(setApikeysActionFinished());
			});
	};
};

export const createApikey = (apiKey, onSuccess) => {
	return (dispatch) => {
		dispatch(setApikeySaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CREATE_APIKEY}`,
			method: Methods.POST,
			data: apiKey
		})
			.then(({ data: { value: apiKey } }) => {
				dispatch(setApikeySaveActionFinished());
				const user = getUser();
				const refresh_token = user?.refreshToken ?? null;
				const userName = user?.userName ?? null;
				refreshToken(refresh_token, userName).then(() => {
					onSuccess && onSuccess(apiKey);
				});
			})
			.catch(() => {
				dispatch(setApikeySaveActionFinished());
			});
	};
};

export const deleteApikey = (id) => {
	return (dispatch) => {
		dispatch(setApikeysActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.DELETE_APIKEY}?id=${id}`,
			method: Methods.DELETE
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					dispatch(setApikeyDeleteActionSuccess(id));
				}
				dispatch(setApikeysActionFinished());
			})
			.catch(() => {
				dispatch(setApikeysActionFinished());
			});
	};
};

export const setApikeysAddOnsActionBefore = () => ({
	type: APIKEYS_ADD_ONS_ACTION_BEFORE
});

export const setApikeysAddOnsActionFinished = () => ({
	type: APIKEYS_ADD_ONS_ACTION_FINISH
});

export const setApikeysAddOnsSaveBefore = () => ({
	type: APIKEYS_ADD_ONS_SAVE_BEFORE
});

export const setApikeysAddOnsSaveFinished = () => ({
	type: APIKEYS_ADD_ONS_SAVE_FINISH
});

export const setApikeysAddOns = (data) => ({
	type: SET_APIKEYS_ADD_ONS,
	payload: { data }
});

export const getApiKeysAddOns = (id) => {
	return (dispatch) => {
		dispatch(setApikeysAddOnsActionBefore());
		
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APIKEY_GET_ADD_ONS}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setApikeysAddOns(data));
				dispatch(setApikeysAddOnsActionFinished());
			})
			.catch(() => {
				dispatch(setApikeysAddOnsActionFinished());
			});
	};
};

export const saveApiKeysAddOns = (id, data) => {
	return (dispatch) => {
		dispatch(setApikeysAddOnsSaveBefore());
		
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APIKEY_SAVE_ADD_ONS}`,
			method: Methods.POST,
			data: { id, ...data }
		})
			.then(({ data: { value, message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setApikeysAddOns(value));
				}
				dispatch(setApikeysAddOnsSaveFinished());
			})
			.catch(() => {
				dispatch(setApikeysAddOnsSaveFinished());
			});
	};
};