import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import BalanceManagement from "components/dashboard/common/balanceManagment";

import { getBetshopWallet, saveBetshopWallet } from "store/actions/dashboard/retail/betshops/wallet.action";

import betshopWalletType from "types/betshop/wallet.type";
import currencyType from "types/common/currency.type";

import { convertCurrency } from "utils/currency";

/** Betshop Limit Popup Component */
const BetshopLimitComponent = ({ wallet, isSaving, saveBetshopWallet, getBetshopWallet, betshop, onClose, currency, globalPartnerId, currencies }) => {
	const { t } = useTranslation();

	/** Load Betshop Wallet */
	useEffect(() => {
		getBetshopWallet(betshop.id);
	}, []);

	return (
		<BalanceManagement
			initialBalance={wallet.balance}
			isSaving={isSaving}
			saveWallet={saveBetshopWallet}
			additionalData={{ id: betshop.id }}
			popupTitle={t("pages.dashboard.betshops.manage_balance_for") + " " + betshop.name}
			onClose={onClose}
			isReasonRequired={true}
			maxBalance={Number(Math.min(wallet.partnerBalance, wallet.maxBalance).toFixed(2))}
			minBalance={Number((wallet.minBalance || 0).toFixed(2))}
			partnerId={globalPartnerId}
			currencyId={betshop.currencyId}
		/>
	);
};

/** BetshopLimitComponent propTypes
 * PropTypes
 */
BetshopLimitComponent.propTypes = {
	/** Redux state property, betshop current Wallet Info */
	wallet: betshopWalletType,
	/** Redux state property, is true when saving betshop balance */
	isSaving: PropTypes.bool,
	/** Redux action to save betshop balance */
	saveBetshopWallet: PropTypes.func,
	/** Redux action to get betshop wallet info */
	getBetshopWallet: PropTypes.func,
	/** Editing Bethsop */
	betshop: PropTypes.shape({
		/** Editing Bethsop Id*/
		id: PropTypes.string,
		/** Editing Bethsop name*/
		name: PropTypes.string,
		/** Editing Bethsop currency id*/
		currencyId: PropTypes.string
	}),
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Redux state property, current user currency */
	currency: currencyType,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType)
};

const mapDispatchToProps = (dispatch) => ({
	saveBetshopWallet: (wallet) => {
		dispatch(saveBetshopWallet(wallet));
	},

	getBetshopWallet: (id) => {
		dispatch(getBetshopWallet(id));
	}
});

const mapStateToProps = (state) => {
	return {
		wallet: state.betshops.wallet.wallet,
		isSaving: state.betshops.wallet.isSaving,
		currency: state.profile.userInfo.currency,
		globalPartnerId: state.partner.globalPartnerId,
		currencies: state.profile.userInfo.currencies
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BetshopLimitComponent);
