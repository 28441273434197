import { useState, Fragment } from "react";
import PropTypes from "prop-types";

import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import QuickInfo from "./quickInfo.component";

import { Tag, Button } from "antd";

import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import ResultsTicket from "components/common/resultsTicket";
import BonusStateChangeComponent from "./bonus-state-change.component";

import ExportButton from "components/common/exportButton";

import { getBonuses, setBonusesSorting, setBonusesFilters } from "store/actions/dashboard/bonuses/bonuses.action";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import bonusType from "types/bonus/bonus.type";
import sortingType from "types/common/sorting.type";

import ApiUrls from "constants/api.constants";
import Paths from "constants/path.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import { BETSLIP_MODES } from "constants/bet.constants";
import { BONUS_TYPE, BONUS_STATE } from "constants/bonus.constants";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { hasPermission } from "utils/permissions";
import { binaryToFlags } from "utils/common";
import CreateBonus from "./CreateBonus";
// import Filters from './filters.component';

/** Bonuses Page Component */
const BonusesComponent = ({ getBonuses, bonuses, isLoading, total, setBonusesSorting, setBonusesFilters, sorting, filters, globalPartnerId }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	/** The id of the player which quick info is showing */
	const [quickInfo, setQuickInfo] = useState(null);

	/** State to show/hide bonus creation popup */
	const [showCreatePopup, setShowCreatePopup] = useState(false);

	/** State to show/hide bonus state change popup */
	const [showStateChangePopup, setShowStateChangePopup] = useState(false);

	/** The data to print */
	const [printData, setPrintData] = useState(null);

	/** Get the state background color in table
	 * @function
	 * @param {strong} state - state
	 * @returns {string} - color
	 * @memberOf BonusesComponent
	 */
	const getStateColor = (state) => {
		const mapping = {
			[BONUS_STATE.ACTIVE]: "green",
			[BONUS_STATE.FINISHED]: "blue",
			[BONUS_STATE.INACTIVE]: "#4D4D4D26"
		};
		return mapping[state];
	};

	/** Fires on state tag click
	 * @function
	 * @param {object} e - click eveent object
	 * @param {object} record - the record
	 * @memberOf BonusesComponent
	 */
	const handleStateTagClick = (e, record) => {
		e.stopPropagation();
		setShowStateChangePopup(record.id);
	};

	const renderStateComponent = (value, record) => (
		<Tag color={getStateColor(value)}>
			<Fragment>
				{t('pages.dashboard.bonuses.status_' + (value || BONUS_STATE.FINISHED))}
				{
					hasPermission({ resource: PERMISSION_RESOURCE.BONUS_STANDARD, action: PERMISSION_ACTION.MODIFY })
					&& (value === BONUS_STATE.ACTIVE)
					&& (
						<i className="icon-edit" onClick={e => handleStateTagClick(e, record)} />
					)
				}
			</Fragment>
		</Tag>
	);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.bonuses.id"),
			dataIndex: "id",
			render: (value) => (
				<span className="table-col-link" onClick={() => setQuickInfo(value)}>
					{value}
				</span>
			),
			sorter: false
		},
		{
			title: t("pages.dashboard.bonuses.type"),
			dataIndex: "bonusType",
			render: (value) => {
				switch (value) {
					case BONUS_TYPE.FREEBET:
						return t("pages.dashboard.bonuses.freebet");
					case BONUS_TYPE.FREEAMOUNT:
						return t("pages.dashboard.bonuses.freeAmount");
					case BONUS_TYPE.DOUBLEDOOBLE:
						return t("pages.dashboard.bonuses.doubleDooble");
					default:
						return "";
				}
			}
		},
		{
			title: t("pages.dashboard.bonuses.betType"),
			dataIndex: "betType",
			render: (value) =>
				binaryToFlags(Object.values(BETSLIP_MODES), value)
					.map((mode) => (mode === BETSLIP_MODES.SINGLE ? t("pages.dashboard.bets.single") : t("pages.dashboard.bets.multi")))
					.join(", ")
		},
		{
			title: t("pages.dashboard.bonuses.betshopIds"),
			dataIndex: "betshopNames",
			multi: true,
			multiMapper: (value) => value,
			sorter: false
		},
		{
			title: t("pages.dashboard.bonuses.status"),
			dataIndex: "bonusState",
			render: (value, record) => renderStateComponent(value, record)
		},
		{
			title: t("pages.dashboard.bonuses.currency"),
			dataIndex: "currency"
		},
		{
			title: t("pages.dashboard.bonuses.totalQuantity"),
			dataIndex: "totalQuantity",
			render: (value, record) => (record.bonusType === BONUS_TYPE.FREEBET ? `${value} x ${record.amount}` : record.bonusType === BONUS_TYPE.DOUBLEDOOBLE ? value : "-")
		},
		{
			title: t("pages.dashboard.bonuses.totalAmount"),
			dataIndex: "amount",
			hidden: true
		},
		{
			title: t("pages.dashboard.bonuses.remainingQuantity"),
			dataIndex: "remainingQuantity",
			render: (value, record) => (record.bonusType === BONUS_TYPE.FREEBET ? `${value} x ${record.amount}` : record.bonusType === BONUS_TYPE.DOUBLEDOOBLE ? value : "-")
		},
		{
			title: t("pages.dashboard.bonuses.creationDate"),
			dataIndex: "creationDate",
			render: (value) => value && moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.bonuses.startDate"),
			dataIndex: "startDate",
			render: (value) => value && moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.bonuses.endDate"),
			dataIndex: "endDate",
			render: (value) => value && moment.utc(value).local().format(DATE_TIME_FORMAT)
		}
	];

	const generateAction = () => {
		const mainObj = {};

		if (hasPermission({ resource: PERMISSION_RESOURCE.BONUS_STANDARD, action: PERMISSION_ACTION.VIEW })) {
			mainObj.view = {
				handler: record => { navigate(Paths.DASHBOARD_BONUSES_STANDARD_EDIT.replace(DYNAMIC_PATH_ID_REGEX, record.id)) },
				hidden: record => record.bonusState === BONUS_STATE.INACTIVE
			}
		}

		if (hasPermission({ resource: PERMISSION_RESOURCE.BONUS_STANDARD, action: PERMISSION_ACTION.MODIFY })) {
			mainObj.print = {
				handler: (record) => setPrintData(record),
				hidden: (record) => record.bonusState === BONUS_STATE.FINISHED || record.bonusType === BONUS_TYPE.DOUBLEDOOBLE
			};

			mainObj.edit = {
				handler: record => { navigate(Paths.DASHBOARD_BONUSES_STANDARD_EDIT.replace(DYNAMIC_PATH_ID_REGEX, record.id)) },
				hidden: record => record.bonusState !== BONUS_STATE.INACTIVE
			}
		}

		return mainObj;
	};

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.bonuses"), path: "" }]} />
				<div className="dashboard-section-content">
					<div className="table-header">
						<div className="table-buttons-dropdowns">
							{
								hasPermission({ resource: PERMISSION_RESOURCE.BONUS_STANDARD, action: PERMISSION_ACTION.EXPORT })
									? (
										<ExportButton
											columns={columns.map(c => ({ title: c.title, key: c.dataIndex }))}
											tableName={t("pages.dashboard.menu.bonuses")}
											url={ApiUrls.EXPORT_BONUSES}
											sorting={sorting}
										/>
									)
									: null
							}
							{
								hasPermission({ resource: PERMISSION_RESOURCE.BONUS_STANDARD, action: PERMISSION_ACTION.CREATE })
									? (
										<div className="table-buttons-dropdowns-button">
											<Button
												onClick={() => setShowCreatePopup(true)}
												type="primary"
											>
												{t('pages.dashboard.bonuses.create_bonus')}
											</Button>
										</div>
									)
									: null
							}
							{/* <Filters /> */}
						</div>
					</div>
					<Table
						loading={isLoading}
						columns={columns}
						data={bonuses}
						loadFn={getBonuses}
						sorting={sorting}
						filters={filters}
						setSortingFn={setBonusesSorting}
						setFiltersFn={setBonusesFilters}
						total={total}
						updateProps={[globalPartnerId]}
						actions={generateAction()}
						isDisabled={() => false}
						enableReload={true}
					/>
					<QuickInfo id={quickInfo} onClose={() => setQuickInfo(null)} renderStateComponent={renderStateComponent} setPrintData={setPrintData} />
				</div>
			</div>
			{showCreatePopup ? <CreateBonus onClose={() => setShowCreatePopup(false)} /> : null}
			{showStateChangePopup ? <BonusStateChangeComponent onClose={() => setShowStateChangePopup(null)} id={showStateChangePopup} zIndex={1001} /> : null}
			{printData && <ResultsTicket data={printData} onPrint={() => setPrintData(null)} zIndex={1001} />}
		</Fragment>
	);
};

/** BonusesComponent propTypes
 * PropTypes
 */
BonusesComponent.propTypes = {
	/** Redux action to get bonuses */
	getBonuses: PropTypes.func,
	/** Redux state property, represents the array of bonuses  */
	bonuses: PropTypes.arrayOf(bonusType),
	/** Redux state property, is true when loading bonuses */
	isLoading: PropTypes.bool,
	/** Redux state property, bonuses total count */
	total: PropTypes.number,
	/** Redux action to set bonuses sorting details */
	setBonusesSorting: PropTypes.func,
	/** Redux action to set bonuses filters details */
	setBonusesFilters: PropTypes.func,
	/** Redux state property, bonuses sorting details */
	sorting: sortingType,
	/** Redux state property, bonuses filters details */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getBonuses: (fromFirstPage) => {
		dispatch(getBonuses(fromFirstPage));
	},
	setBonusesSorting: (sorting) => {
		dispatch(setBonusesSorting(sorting));
	},
	setBonusesFilters: (filters) => {
		dispatch(setBonusesFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.bonuses.standard.isLoading,
		bonuses: state.bonuses.standard.data,
		total: state.bonuses.standard.total,
		sorting: state.bonuses.standard.sorting,
		filters: state.bonuses.standard.filters,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BonusesComponent);
