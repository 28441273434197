import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Select } from "antd";

import RangeTimePicker from "components/common/rangeTimePicker";
import { WORKING_HOURS_TYPE } from "constants/partner.constants";

const momentToState = (value, useDefault = true) => {
	const timeValue = value || { hour: 0, minute: 0 };
	if (!value && !useDefault) {
		return "";
	}
	return moment(timeValue.hour, "H").format("HH") + ":" + moment(timeValue.minute, "m").format("mm");
};

const HoursDropdown = ({ onChange, value, day, disabled, readOnly }) => {
	const { t } = useTranslation();

	const [picker, setPicker] = useState(false);
	const [openTime, setOpenTime] = useState(momentToState(value.openTime, false));
	const [closeTime, setCloseTime] = useState(momentToState(value.closeTime, false));
	const [result, setResult] = useState(value.type !== WORKING_HOURS_TYPE.CUSTOM ? value.type : "range");

	/** Update component state when value of form item is changed, to keep them sync */
	useEffect(() => {
		setOpenTime(momentToState(value.openTime));
		setCloseTime(momentToState(value.closeTime));
		setResult(value.type !== WORKING_HOURS_TYPE.CUSTOM ? value.type : "range");
	}, [value]);

	/** Fires on dropdown change
	 * @function
	 * @param {string} v - value
	 * @memberOf HoursDropdown
	 */
	const onSelectChange = (v) => {
		setPicker(v === WORKING_HOURS_TYPE.CUSTOM);

		setResult(v);

		setOpenTime(momentToState(null));
		setCloseTime(momentToState(null));

		if (typeof onChange === "function") {
			onChange({
				type: v
			});
		}
	};

	/** Fires on timepicket change
	 * @function
	 * @param {array} values - selected ranges
	 * @memberOf HoursDropdown
	 */
	const onRangeSelect = (values) => {
		setPicker(false);
		setOpenTime(values[0]);
		setCloseTime(values[1]);
		setResult("range");
		if (typeof onChange === "function") {
			onChange({
				type: WORKING_HOURS_TYPE.CUSTOM,
				openTime: { hour: values[0].split(":")[0], minute: values[0].split(":")[1] },
				closeTime: { hour: values[1].split(":")[0], minute: values[1].split(":")[1] }
			});
		}
	};

	const onRangeSelectChange = (values) => {
		onChange({
			type: WORKING_HOURS_TYPE.CUSTOM,
			openTime: { hour: values[0].split(":")[0], minute: values[0].split(":")[1] },
			closeTime: { hour: values[1].split(":")[0], minute: values[1].split(":")[1] }
		});
	};

	return (
		<div className="hours-dropdown">
			<Select placeholder="Pick" onChange={onSelectChange} popupClassName="hours-dropdown-select" value={result} disabled={disabled} open={readOnly ? false : undefined} suffixIcon={<i className="icon-down" />}>
				<Select.Option value={WORKING_HOURS_TYPE.ALWAYS_OPEN}>{t("common.always_open")}</Select.Option>
				<Select.Option value={WORKING_HOURS_TYPE.CLOSED}>{t("common.closed")}</Select.Option>

				<Select.Option value={WORKING_HOURS_TYPE.CUSTOM}>
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<span>{t("common.select_time")}</span>
						<i className="icon-calendar"></i>
					</div>
				</Select.Option>
				<Select.Option value="range" disabled={true}>
					{openTime + " - " + closeTime}
				</Select.Option>
			</Select>
			{picker ? <RangeTimePicker id={`hours-dropdown-range-time-picker-${day}`} onSelect={onRangeSelect} defaultValue={[openTime, closeTime]} onClose={() => setPicker(false)} onSelectChange={onRangeSelectChange} /> : null}
		</div>
	);
};

/** HoursDropdown propTypes
 * PropTypes
 */
HoursDropdown.propTypes = {
	/** Handler of value change */
	onChange: PropTypes.func,
	/** Current value  */
	value: PropTypes.object,
	/** The weekday, for which component is used */
	day: PropTypes.string,
	/** Should dropdown be disabled */
	disabled: PropTypes.bool,
	/** Should dropdown be readonly */
	readOnly: PropTypes.bool
};

export default HoursDropdown;
