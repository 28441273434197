import { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { getOperators, setOperatorsSorting } from "store/actions/dashboard/developer/operators/operators.action";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import OperatorEditComponent from "./operator-edit.component";

import operatorType from "types/operator/operator.type";
import sortingType from "types/common/sorting.type";

/** Operators Page Component */
const OperatorsComponent = ({ getOperators, operators, isLoading, total, setOperatorsSorting, sorting }) => {
	const { t } = useTranslation();

	const [editingOperator, setEditingOperator] = useState(null);

	/** Close edit popup, after save */
	useEffect(() => {
		setEditingOperator(null);
	}, [operators]);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.operators.id"),
			dataIndex: "id"
		},
		{
			title: t("pages.dashboard.operators.name"),
			dataIndex: "name"
		},
		{
			title: t("pages.dashboard.operators.secretKey"),
			dataIndex: "secretKey",
			sorter: false
		},
		{
			title: t("pages.dashboard.operators.endPointUrl"),
			dataIndex: "endPointUrl",
			sorter: false
		},
		{
			title: t("pages.dashboard.operators.last_update_at"),
			dataIndex: "lastUpdatedDate",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.operators.last_update_by"),
			dataIndex: "lastUpdatedBy"
		}
	];

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.operators") }]} />
				<div className="dashboard-section-content">
					<Table
						loading={isLoading}
						columns={columns}
						data={operators}
						loadFn={getOperators}
						sorting={sorting}
						setSortingFn={setOperatorsSorting}
						total={total}
						actions={{
							edit: hasPermission({ resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.MODIFY })
								? {
										handler: (record) => {
											setEditingOperator(record);
										}
									}
								: null
						}}
						isDisabled={() => false}
					/>
				</div>
			</div>

			{editingOperator ? <OperatorEditComponent onClose={() => setEditingOperator(null)} editingOperator={editingOperator} /> : null}
		</Fragment>
	);
};

/** OperatorsComponent propTypes
 * PropTypes
 */
OperatorsComponent.propTypes = {
	/** Redux action to get operators */
	getOperators: PropTypes.func,
	/** Redux state property, represents the array of loaded operators */
	operators: PropTypes.arrayOf(operatorType),
	/** Redux state property, operators total count */
	total: PropTypes.number,
	/** Redux action to set operators sorting details */
	setOperatorsSorting: PropTypes.func,
	/** Redux state property, operators sorting details */
	sorting: sortingType,
	/** Redux state property, is true when loading operators */
	isLoading: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	getOperators: () => {
		dispatch(getOperators());
	},
	setOperatorsSorting: (sorting) => {
		dispatch(setOperatorsSorting(sorting));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.operators.isLoading,
		operators: state.operators.operators,
		total: state.operators.total,
		sorting: state.operators.sorting
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OperatorsComponent);
