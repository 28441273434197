import { Fragment } from "react";

import BoxEditComponent from "components/dashboard/retail/boxes/edit/box-edit.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const BoxEditRoute = () => {
	return (
		<Fragment>
			<BoxEditComponent />
		</Fragment>
	);
};

export default withPermission(BoxEditRoute, { resource: PERMISSION_RESOURCE.BOX, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
