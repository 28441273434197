import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { setApikeySaveActionBefore, setApikeySaveActionFinished } from "./apikeys.action";

import { APIKEY_CURRENCIES_BEFORE, APIKEY_CURRENCIES_FINISH, SET_APIKEY_CURRENCIES, SET_APIKEY_UPDATE_CURRENCY } from "store/actionTypes";

const setApiKeyCurrenciesBefore = () => ({
	type: APIKEY_CURRENCIES_BEFORE
});

const setApiKeyCurrenciesFinished = () => ({
	type: APIKEY_CURRENCIES_FINISH
});

export const setApikeyCurrencies = (currencies) => ({
	type: SET_APIKEY_CURRENCIES,
	payload: { currencies }
});

export const setApiKeyUpdateCurrency = (currency) => ({
	type: SET_APIKEY_UPDATE_CURRENCY,
	payload: { currency }
});

export const getApiKeyCurrencies = (id) => {
	return (dispatch) => {
		dispatch(setApiKeyCurrenciesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APIKEY_GET_CURRENCIES}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: currencies } }) => {
				dispatch(setApikeyCurrencies(currencies));
				dispatch(setApiKeyCurrenciesFinished());
			})
			.catch((ex) => {
				dispatch(setApiKeyCurrenciesFinished());
			});
	};
};

export const updateApiKeyCurrency = (currency) => {
	return (dispatch) => {
		dispatch(setApikeySaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APIKEY_SAVE_CURRENCY}`,
			method: Methods.POST,
			data: currency
		})
			.then(({ data: { value: currency } }) => {
				dispatch(setApiKeyUpdateCurrency(currency));
				dispatch(setApikeySaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setApikeySaveActionFinished());
			});
	};
};

export const deleteApiKeyCurrency = (id, code) => {
	return (dispatch) => {
		dispatch(setApikeySaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.APIKEY_DELETE_CURRENCY}`,
			method: Methods.DELETE,
			data: { id, code }
		})
			.then(({ data: { value: currency } }) => {
				dispatch(getApiKeyCurrencies(id));
				dispatch(setApikeySaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setApikeySaveActionFinished());
			});
	};
};
