import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Button, Select, Switch, Spin } from "antd";
const { Item: FormItem } = Form;

import { getPartnerSecurityMultideviceTokenSettings, savePartnerSecurityMultideviceTokenSettings } from "store/actions/dashboard/partners/security.action";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import tokenMultideviceType from "types/partner/tokenMultidevice.type";

const TOKEN_LIFETIME_POSSIBLE_VALUES = Array.from(Array(56).keys()).map((n) => n + 5);

/** Partner Edit Page Security Tab Token Multidevice subTab Component */
const TokenMultiDeviceComponent = ({ isSaving, isLoading, tokenMultidevice, onTabChange, getPartnerSecurityMultideviceTokenSettings, savePartnerSecurityMultideviceTokenSettings }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_SECURITY_SETTINGS, action: PERMISSION_ACTION.MODIFY });

	/** Load partner token multidevice settings */
	useEffect(() => {
		getPartnerSecurityMultideviceTokenSettings();
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			...tokenMultidevice
		});
	}, [tokenMultidevice]);

	/** Fires when form submitted
	 * @function
	 * @memberOf IPRestrictionComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				savePartnerSecurityMultideviceTokenSettings({
					...data
				});
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					lifetimeMinutes: tokenMultidevice.lifetimeMinutes,
					multideviceLogin: tokenMultidevice.multideviceLogin
				}}
				onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, { ...tokenMultidevice }))}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[30, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.partners.security_settings.token_multidevice.token_lifetime")} name="lifetimeMinutes" rules={[{ required: true, message: t("validation.field_required") }]}>
									<Select disabled={isDisabled} suffixIcon={<i className="icon-down" />}>
										{TOKEN_LIFETIME_POSSIBLE_VALUES.map((el) => (
											<Select.Option value={el} key={el}>
												{el}
											</Select.Option>
										))}
									</Select>
								</FormItem>
							</Col>
						</Row>
						<Row gutter={[30, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<div className="inline-form-item">
									<label>{t("pages.dashboard.partners.security_settings.token_multidevice.multidevice_login")}</label>
									<FormItem className="inline-form-item-control" name="multideviceLogin" valuePropName="checked">
										<Switch disabled={isDisabled} />
									</FormItem>
								</div>
							</Col>
						</Row>
					</div>
					{!isDisabled && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** TokenMultiDeviceComponent propTypes
 * PropTypes
 */
TokenMultiDeviceComponent.propTypes = {
	/** Redux state property, is true when token multidevice settings is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when token multidevice settings is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the token multidevice settings of current editing partner  */
	tokenMultidevice: tokenMultideviceType,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func,
	/** Redux action to get partner Token Multidevice settings */
	getPartnerSecurityMultideviceTokenSettings: PropTypes.func,
	/** Redux action to save partner Token Multidevice settings */
	savePartnerSecurityMultideviceTokenSettings: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getPartnerSecurityMultideviceTokenSettings: () => {
		dispatch(getPartnerSecurityMultideviceTokenSettings());
	},

	savePartnerSecurityMultideviceTokenSettings: (settings) => {
		dispatch(savePartnerSecurityMultideviceTokenSettings(settings));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.partners.isSaving,
		isLoading: state.partner.isLoading,
		tokenMultidevice: state.partner.securitySettings.tokenMultidevice
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenMultiDeviceComponent);
