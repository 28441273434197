import { Tabs } from "antd";

import { useTranslation } from "react-i18next";

import BlockedCountriesComponent from "./blockedCountries";
import BlockedIPsComponent from "./blockedIPs";

import { updateLocationHash, getHashValue } from "utils/common";

/** IP Restrictions Tab Component */
const IPRestrictionsComponent = () => {
	const { t } = useTranslation();

	return (
		<Tabs
			animated={false}
			className="sub-tabs"
			defaultActiveKey={getHashValue("subtab")}
			onChange={(key) => updateLocationHash("subtab=" + key, true)}
			items={[
				{
					key: "1",
					label: t("pages.dashboard.apikeys.tabs.blocked_countries"),
					children: <BlockedCountriesComponent />
				},
				{
					key: "2",
					label: t("pages.dashboard.apikeys.tabs.blocked_ips"),
					children: <BlockedIPsComponent />
				}
			]}
		/>
	);
};

export default IPRestrictionsComponent;
