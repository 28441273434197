import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Col, Row, Modal } from "antd";
const { Item: FormItem } = Form;

import SearchableSelect from "components/common/searchableSelect";

import { getSurveyAvailablePartners, addSurveyPartners } from "store/actions/dashboard/usersFeedback/CSAT/partners.action";

import AvailablePartnersType from "types/survey/availablePartners.type";

import { POPUP_SIZE } from "constants/common.constants";

/** Survey Partner Adding Popup Component */
const AddPartnerPopup = ({ isSaving, isLoading, getSurveyAvailablePartners, onClose, onAddClick, availablePartners, addSurveyPartners, surveyId }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;

	const modifiedPartners = useMemo(() => (availablePartners.length ? [{ item1: "*", item2: t("common.all") }, ...availablePartners] : []), [availablePartners]);

	const handleFormSubmit = () => {
		validateFields()
			.then(({ partnerIds }) => {
				addSurveyPartners(partnerIds.includes("*") ? { id: surveyId, partnerIds: availablePartners.map(({ item1: partnerId }) => partnerId), onAddClick } : { id: surveyId, partnerIds, onAddClick });
			})
			.catch(console.log);
	};

	/** Load survey available partners */
	useEffect(() => {
		if (surveyId) {
			getSurveyAvailablePartners(surveyId);
		}
	}, [surveyId]);

	return (
		<Modal
			open={true}
			zIndex={1001}
			title={t("pages.dashboard.usersFeedback.add_new_partner")}
			cancelText={t("common.cancel")}
			okText={t("common.add")}
			onOk={handleFormSubmit}
			onCancel={onClose}
			width={POPUP_SIZE.SMALL}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving, disabled: modifiedPartners.length === 0 }}
			centered
		>
			<Form className="dashboard-form" colon={false} layout="vertical" requiredMark={false} form={formInstance}>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem name="partnerIds" rules={[{ required: true, message: t("validation.field_required") }]}>
							<SearchableSelect
								mode="multiple"
								placeholder={t("common.select")}
								items={[...modifiedPartners]}
								valueProp={(partner) => partner.item1}
								textProp={(partner) => (partner.item1 === "*" ? t("common.all") : partner.item2)}
								renderText={(partner) => (partner.item1 === "*" ? <b>{t("common.all")}</b> : partner.item2)}
								loading={isLoading}
								getPopupContainer={() => document.body}
								onChange={(partnerIds) => {
									if (partnerIds.slice(-1)[0] === "*") {
										setFieldsValue({ partnerIds: ["*"] });
									} else if (partnerIds.length > 1 && partnerIds.find((partnerId) => partnerId === "*")) {
										setFieldsValue({ partnerIds: partnerIds.filter((partnerId) => partnerId !== "*") });
									} else {
										setFieldsValue({ partnerIds });
									}
								}}
							/>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** AddPartnerPopup propTypes
 * PropTypes
 */
AddPartnerPopup.propTypes = {
	/** Redux state property, is true when user partner access is adding */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when loading survey available partners */
	isLoading: PropTypes.bool,
	/** Redux state property, current survey id */
	surveyId: PropTypes.string,
	/** Redux state property, represents the available partners info of survey  */
	availablePartners: AvailablePartnersType,
	/** Redux action to add partners for survey */
	addSurveyPartners: PropTypes.func,
	/** Function to be called on modal submit */
	onAddClick: PropTypes.func,
	/** Redux action to get survey available partners */
	getSurveyAvailablePartners: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getSurveyAvailablePartners: (surveyId) => {
		dispatch(getSurveyAvailablePartners(surveyId));
	},
	addSurveyPartners: (args) => {
		dispatch(addSurveyPartners(args));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.surveys.quickInfo.isLoading,
		isSaving: state.surveys.editingSurvey.isSaving,
		availablePartners: state.surveys.editingSurvey.availablePartners
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPartnerPopup);
