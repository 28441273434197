import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getPartnerAvailableCurrencies } from "store/actions/dashboard/partners/currencies.action";
import { createBonus, getAvailableBetshops, setAvailableBetshops, getAvailableGames, setAvailableGames } from "store/actions/dashboard/bonuses/bonuses.action";

import { Modal, Form, Row } from "antd";
import DefaultView from "./DefaultView";
import FreebetView from "./FreebetView";
import DoubleDoobleView from "./DoubleDoobleView";

import { getSelectedValues, createRequestBodyForBonuses, addMinutesToCurrentDate } from "utils/bonuses";

import { POPUP_SIZE, ALL_OPTION_ID } from "constants/common.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";
import { BONUS_TYPE, FIELD_NAMES, ANTD_FORM_INITIAL_VALUES, DOUBLE_DOOBLE_INITIAL_DELAY } from "constants/bonus.constants";
import Paths from "constants/path.constants";

const CreateBonuse = ({ onClose, getPartnerAvailableCurrencies, availableCurrencies, getAvailableBetshops, betshops, getAvailableGames, games, createBonus, isSaving, setAvailableBetshops, setAvailableGames, isLoading, isAvailableLoading }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [antdForm] = Form.useForm();

	const [bonuseTypeId, setBonuseTypeId] = useState(null);
	const [currencyCode, setCurrencyCode] = useState(null);
	const [selectedBetshopIds, setSelectedBetshopIds] = useState([]);

	const handleBonusTypeFieldChange = (selectedBonuseTypeId) => {
		setBonuseTypeId(selectedBonuseTypeId);

		// reset all field values
		antdForm.setFieldsValue({
			...ANTD_FORM_INITIAL_VALUES,
			[FIELD_NAMES.DATE]: [
				addMinutesToCurrentDate({
					minute: DOUBLE_DOOBLE_INITIAL_DELAY.MINUTE,
					second: DOUBLE_DOOBLE_INITIAL_DELAY.SECOND,
					millisecond: DOUBLE_DOOBLE_INITIAL_DELAY.MILLISECOND
				}),
				null
			],
			[FIELD_NAMES.BONUSE_TYPE]: selectedBonuseTypeId
		});
	};

	const handleCurrencyFieldChange = (currencyCode) => {
		setCurrencyCode(currencyCode);

		// reset BETSHOP_IDS and GAME_TYPE field values
		antdForm.setFieldsValue({
			[FIELD_NAMES.BETSHOP_IDS]: [],
			[FIELD_NAMES.GAME_TYPE]: []
		});
	};

	const handleBetshopFieldChange = (betshopIds) => {
		const betshopIdList = getSelectedValues(betshopIds);

		// reset GAME_TYPE field value
		antdForm.setFieldsValue({
			[FIELD_NAMES.BETSHOP_IDS]: betshopIdList,
			[FIELD_NAMES.GAME_TYPE]: []
		});

		if (betshopIdList[0] === ALL_OPTION_ID) {
			setSelectedBetshopIds(betshops.map((betshop) => betshop.key));
			return;
		}

		setSelectedBetshopIds(betshopIdList);
	};

	const handleGameTypeFieldChange = (selectedGameTypeIds) => {
		antdForm.setFieldsValue({ [FIELD_NAMES.GAME_TYPE]: getSelectedValues(selectedGameTypeIds) });
	};

	const handleCreateButtonClick = () => {
		antdForm
			.validateFields()
			.then((fieldValuesObj) => {
				const requestBody = createRequestBodyForBonuses({
					fieldValuesObj,
					games,
					betshops
				});

				createBonus(
					requestBody,
					({ id }) => {
						navigate(Paths.DASHBOARD_BONUSES_STANDARD_EDIT.replace(DYNAMIC_PATH_ID_REGEX, id));
					}
				);
			})
			.catch(Function.prototype);
	};

	// Get available currencies in case of bonus type change
	useEffect(() => {
		if (bonuseTypeId === null) {
			return;
		}

		getPartnerAvailableCurrencies();
	}, [bonuseTypeId]);

	// Get available betshops for selected currency
	useEffect(() => {
		if (currencyCode === null) {
			return;
		}

		setAvailableBetshops([]);
		getAvailableBetshops(currencyCode.toUpperCase());
	}, [currencyCode]);

	// Get available game types for selected betshops
	useEffect(() => {
		if (selectedBetshopIds.length === 0) {
			return;
		}

		setAvailableGames([]);
		getAvailableGames(selectedBetshopIds);
	}, [selectedBetshopIds]);

	const propsObj = {
		handleBonusTypeFieldChange,
		handleGameTypeFieldChange,
		handleBetshopFieldChange,
		handleCurrencyFieldChange,
		availableCurrencies,
		betshops,
		games,
		isLoading,
		isAvailableLoading,
		t
	};

	const componentMapper = {
		[null]: () => <DefaultView t={propsObj.t} handleBonusTypeFieldChange={propsObj.handleBonusTypeFieldChange} />,
		[BONUS_TYPE.FREEBET]: () => <FreebetView {...propsObj} />,
		[BONUS_TYPE.DOUBLEDOOBLE]: () => <DoubleDoobleView {...propsObj} />
	};

	return (
		<Modal
			open={true}
			closable={false}
			maskClosable={false}
			title={t("pages.dashboard.bonuses.create_bonus")}
			width={POPUP_SIZE.BIG}
			centered
			onOk={handleCreateButtonClick}
			okText={t("common.create")}
			okButtonProps={{
				loading: isSaving || isLoading || isAvailableLoading,
				disabled: bonuseTypeId === null
			}}
			onCancel={onClose}
			cancelText={t("common.cancel")}
		>
			<Form className="dashboard-form" layout="vertical" requiredMark={false} form={antdForm} initialValues={ANTD_FORM_INITIAL_VALUES} colon={false}>
				<Row gutter={[16, 0]}>{componentMapper[bonuseTypeId]()}</Row>
			</Form>
		</Modal>
	);
};

/** CreateBonuse propTypes
 * PropTypes
 */
CreateBonuse.propTypes = {
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Redux state property, represents the object of available currencies  */
	availableCurrencies: PropTypes.object,
	/** Redux action to get available currencies */
	getPartnerAvailableCurrencies: PropTypes.func,
	/** Function for to get available betshops by selected currency */
	getAvailableBetshops: PropTypes.func,
	/** Available betshops selected by currency */
	betshops: PropTypes.array,
	/** Function for to get available games by selected betshopsIds */
	getAvailableGames: PropTypes.func,
	/** Available games selected by betshopsIds */
	games: PropTypes.array,
	/** Redux action to create bonus */
	createBonus: PropTypes.func,
	/** Redux state property, is true when creating bonus request is in process */
	isSaving: PropTypes.bool,
	/** Redux action to set the object of available currencies  */
	setAvailableBetshops: PropTypes.func,
	/** Redux action to set the object of available games  */
	setAvailableGames: PropTypes.func,
	/** Redux state property, is true when data is loading */
	isLoading: PropTypes.bool,
	/** Redux state property, is true when loading available currencies  */
	isAvailableLoading: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	createBonus: (bonus, onSuccess) => {
		dispatch(createBonus(bonus, onSuccess));
	},
	getPartnerAvailableCurrencies: () => {
		dispatch(getPartnerAvailableCurrencies());
	},
	getAvailableBetshops: (currency) => {
		dispatch(getAvailableBetshops(currency));
	},
	setAvailableBetshops: (arg) => {
		dispatch(setAvailableBetshops(arg));
	},
	getAvailableGames: (betshopIds) => {
		dispatch(getAvailableGames(betshopIds));
	},
	setAvailableGames: (arg) => {
		dispatch(setAvailableGames(arg));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.bonuses.standard.isSaving,
		isLoading: state.bonuses.standard.isLoading,
		availableCurrencies: state.partner.currency.availableCurrencies,
		isAvailableLoading: state.partner.currency.isAvailableLoading,
		betshops: state.bonuses.standard.betshops,
		games: state.bonuses.standard.games,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBonuse);
