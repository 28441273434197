import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { PLAYERS_BEFORE, PLAYERS_FINISH, PLAYERS_SAVE_BEFORE, PLAYERS_SAVE_FINISH, SET_PLAYERS, SET_PLAYERS_SORTING, SET_PLAYERS_FILTERS, CHANGE_PLAYER_STATE } from "store/actionTypes";

export const setPlayersActionBefore = () => ({
	type: PLAYERS_BEFORE
});

export const setPlayersActionFinished = () => ({
	type: PLAYERS_FINISH
});

const setPlayersSaveActionBefore = () => ({
	type: PLAYERS_SAVE_BEFORE
});

const setPlayersSaveActionFinished = () => ({
	type: PLAYERS_SAVE_FINISH
});

const setPlayers = (data) => ({
	type: SET_PLAYERS,
	payload: { data }
});

export const setPlayersSorting = (sorting) => ({
	type: SET_PLAYERS_SORTING,
	payload: { sorting }
});

export const setPlayersFilters = (filters) => ({
	type: SET_PLAYERS_FILTERS,
	payload: { filters }
});

export const getPlayers = (fromFirstPage) => {
	return (dispatch, getState) => {
		dispatch(setPlayersActionBefore());

		const projectIds = getState().players.filters.projectIds;
		const qsProjectIds = projectIds ? projectIds.map((id) => `projectIds=${id}`).join("&") : "";

		const filters = { ...getState().players.filters };
		delete filters["projectIds"];

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PLAYERS}?${qsProjectIds}`,
			method: Methods.GET,
			params: {
				...getState().players.sorting,
				...getState().players.filters,
				page: fromFirstPage ? 1 : getState().players.sorting.page
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setPlayers(data));
				if (fromFirstPage) dispatch(setPlayersSorting({ ...getState().players.sorting, page: 1 }));
				dispatch(setPlayersActionFinished());
			})
			.catch(() => {
				dispatch(setPlayersActionFinished());
			});
	};
};

const setChangePlayerState = (data) => ({
	type: CHANGE_PLAYER_STATE,
	payload: { data }
});

export const changePlayerState = (data) => {
	return (dispatch) => {
		dispatch(setPlayersSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CHANGE_PLAYER_STATE}`,
			method: Methods.POST,
			data: data
		})
			.then(({ data: { status, message: msg, value: data } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setChangePlayerState(data));
				}
				dispatch(setPlayersSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setPlayersSaveActionFinished());
			});
	};
};
