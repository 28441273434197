import {
	SET_PERMISSION_REQUESTS_ACTION_BEFORE,
	SET_PERMISSION_REQUESTS_ACTION_FINISH,
	SET_PERMISSION_REQUESTS_SAVE_ACTION_BEFORE,
	SET_PERMISSION_REQUESTS_SAVE_ACTION_FINISH,
	SET_PERMISSION_REQUESTS,
	SET_PERMISSION_REQUESTS_COUNT,
	SET_PERMISSION_REQUESTS_HISTORY,
	SET_PERMISSION_REQUESTS_HISTORY_SORTING,
	SET_PERMISSION_REQUESTS_HISTORY_FILTERS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_PERMISSION_REQUESTS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case SET_PERMISSION_REQUESTS_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case SET_PERMISSION_REQUESTS_SAVE_ACTION_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case SET_PERMISSION_REQUESTS_SAVE_ACTION_FINISH:
			return {
				...state,
				isSaving: false
			};
		case SET_PERMISSION_REQUESTS:
			return {
				...state,
				pending: {
					...state.pending,
					requests: payload.requests
				}
			};
		case SET_PERMISSION_REQUESTS_COUNT:
			return {
				...state,
				count: {
					...state.count,
					[payload.actionType]: payload.count
				}
			};
		case SET_PERMISSION_REQUESTS_HISTORY:
			return {
				...state,
				history: {
					...state.history,
					history: !payload.add ? payload.history.item2 : state.history.history.concat(payload.history.item2),
					total: payload.history.item1
				}
			};
		case SET_PERMISSION_REQUESTS_HISTORY_SORTING:
			return {
				...state,
				history: {
					...state.history,
					sorting: payload.sorting
				}
			};
		case SET_PERMISSION_REQUESTS_HISTORY_FILTERS:
			return {
				...state,
				history: {
					...state.history,
					filters: payload.filters,
					sorting: {
						...state.history.sorting,
						page: 1
					}
				}
			};
		default:
			return state;
	}
};
