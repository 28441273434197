import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import { SPECIFIC_ENDPOINTS } from "constants/api.constants";
import moment from "moment";

import { RESPONSE_STATE } from "constants/response.constants";

import { monthAgo } from "utils/dateTime";

import { SET_BETSLIPS_DB_CACHE_COMPARE } from "../../../actionTypes";

import { setBetslipsActionBefore, setBetslipsActionFinished, setBetslipsSaveActionBefore, setBetslipsSaveActionFinished } from "./betslips.action";
import { DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

const setBetslipsDBCacheCompare = (data) => ({
	type: SET_BETSLIPS_DB_CACHE_COMPARE,
	payload: { data }
});

export const getBetslipsDBCacheCompare = (id, projectType) => {
	const { GET_BET_DB_CACHE_COMPARE } = SPECIFIC_ENDPOINTS[projectType];
	return (dispatch, getState) => {
		dispatch(setBetslipsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${GET_BET_DB_CACHE_COMPARE}`,
			method: Methods.GET,
			params: {
				id,
				projectType,
				from: moment(monthAgo(3), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate()
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setBetslipsDBCacheCompare(data));
				dispatch(setBetslipsActionFinished());
			})
			.catch(() => {
				dispatch(setBetslipsActionFinished());
			});
	};
};

export const syncronizeDBCache = (id, projectType) => {
	const { GET_BET_DB_CACHE_SYNCHRONIZE } = SPECIFIC_ENDPOINTS[projectType];
	return (dispatch, getState) => {
		dispatch(setBetslipsSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${GET_BET_DB_CACHE_SYNCHRONIZE}`,
			method: Methods.POST,
			data: {
				id,
				projectType,
				from: moment(monthAgo(3), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate()
			}
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setBetslipsDBCacheCompare([]));
				}
				dispatch(setBetslipsSaveActionFinished());
			})
			.catch(() => {
				dispatch(setBetslipsSaveActionFinished());
			});
	};
};
