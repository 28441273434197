import { useEffect } from "react";
import PropTypes from "prop-types";

import { Drawer, Spin } from "antd";

/** Qucik info Component */
const QuickInfo = ({ title, visible, children, loadFn, loading, onClose, className }) => {
	useEffect(() => {
		if (visible) {
			loadFn && loadFn();
		}
	}, [visible]);

	return (
		<Drawer className={"dashboard-quick-info " + className} title={title} closable={false} open={visible} onClose={onClose} width="50%" maskStyle={{ backgroundColor: "transparent" }}>
			{visible && !loading ? (
				children
			) : (
				<div className="dashboard-quick-info-loading">
					<Spin size="large" />
				</div>
			)}
		</Drawer>
	);
};

QuickInfo.propTypes = {
	/** Is menu visible */
	visible: PropTypes.bool,
	/** The menu title */
	title: PropTypes.oneOfType([PropTypes.node]),
	/** The JSX content of filters */
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	/** Function to call to load info */
	loadFn: PropTypes.func,
	/** Is true when quick info is loading */
	loading: PropTypes.bool,
	/** Function to call on menu close */
	onClose: PropTypes.func,
	/** className for drawer */
	className: PropTypes.string
};

export default QuickInfo;
