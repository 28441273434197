import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Row } from "antd";

/** Apikey Game Edit Popup Footer Component */
const FooterButtons = ({ onClose, onSave, saveButtonProps, okText }) => {
	const { t } = useTranslation();

	return (
		<Row className="ant-modal-footer" justify="end" wrap={false} style={{ paddingBottom: 1 }}>
			<Button onClick={onClose} className="ant-btn">
				{t("common.cancel")}
			</Button>
			<Button onClick={onSave} {...saveButtonProps} className="ant-btn ant-btn-primary">
				{okText}
			</Button>
		</Row>
	);
};

FooterButtons.propTypes = {
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Fires on popup form save */
	onSave: PropTypes.func,
	/** save button props */
	saveButtonProps: PropTypes.shape({
		loading: PropTypes.bool,
		disabled: PropTypes.bool,
		style: PropTypes.object
	}),
	okText: PropTypes.string
};

export default FooterButtons;
