import PropTypes from "prop-types";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { Button } from "antd";

import { setActiveComponentNameAction } from "store/actions/auth/auth.action";

import { LOGIN_VIEW_COMPONENTS } from "constants/auth.constants";

import { buildPathToStaticFolderOfCDN } from "utils/common";

const UserBlockedComponent = ({ setActiveComponentNameAction }) => {
	const { t } = useTranslation();

	return (
		<div className="vs--user-blocked">
			<h4 className="vs--user-blocked-title">{t("pages.auth.user_blocked.account_locked")}</h4>
			<img src={buildPathToStaticFolderOfCDN("admin-images/block-account.svg")} alt="Block Account" className="vs--user-blocked-img" />
			<Button type="primary" htmlType="button" className="button" onClick={() => setActiveComponentNameAction(LOGIN_VIEW_COMPONENTS.MAIN_LOGIN_COMPONENT)} autoFocus>
				<span>{t("pages.auth.user_blocked.back_login_page")}</span>
			</Button>
		</div>
	);
};

/** UserBlockedComponent propTypes
 * PropTypes
 */
UserBlockedComponent.propTypes = {
	/** Redux action to change active component */
	setActiveComponentNameAction: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	setActiveComponentNameAction: (componentName) => {
		dispatch(setActiveComponentNameAction(componentName));
	}
});

export default connect(null, mapDispatchToProps)(UserBlockedComponent);
