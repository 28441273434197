import { Fragment, useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import {
	getJackpotHistory,
	setJackpotHistorySorting,
	setJackpotHistoryFilters,
} from 'store/actions/dashboard/bonuses/jackpotHistory.action';

import Table from 'components/common/table';
import Filters from './filters.component';
import ExportButton from 'components/common/exportButton';

import ApiUrls from 'constants/api.constants';
import { JACKPOT_TABLE_FILTERS, JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS, JACKPOT_BONUS_TRANSACTION_STATUS_TRANSLATION_TEXTS, JACKPOT_TRANSACTION_STATUS } from "constants/bonus.constants";
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from 'constants/date.constants';

const getGlobalPartnerId = (state) => state.partner.globalPartnerId;
const getJackpotHistoryState = (state) => state.bonuses.jackpots.editingJackpot.history.data;
const getJackpotHistorySorting = (state) => state.bonuses.jackpots.editingJackpot.history.sorting;
const getJackpotHistoryFilters = (state) => state.bonuses.jackpots.editingJackpot.history.filters;
const getJackpotHistoryTotal = (state) => state.bonuses.jackpots.editingJackpot.history.total;
const getisHistoryLoading = (state) => state.bonuses.jackpots.editingJackpot.history.isLoading;

const JackpotHistory = () => {
	const routeParams = useParams();
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();

	const betSlipId = searchParams.get(JACKPOT_TABLE_FILTERS.BETSLIP);

	const globalPartnerId = useSelector(getGlobalPartnerId);
	const jackpotHistory = useSelector(getJackpotHistoryState);
	const sorting = useSelector(getJackpotHistorySorting);
	const filters = useSelector(getJackpotHistoryFilters);
	const total = useSelector(getJackpotHistoryTotal);
	const isLoading = useSelector(getisHistoryLoading);

	const dispatch = useDispatch();

	const getHistory = useCallback((fromFirstPage) => dispatch(getJackpotHistory(fromFirstPage, routeParams.id)), [dispatch, routeParams.id]);
	const setSorting = useCallback((params) => dispatch(setJackpotHistorySorting(params)), [dispatch]);
	const setFilters = useCallback((params) => dispatch(setJackpotHistoryFilters(params)), [dispatch]);

	/** Columns of table */
	const columns = [
		{
			title: t('pages.dashboard.bonuses.betslipId'),
			dataIndex: 'betSlipId',
			sorter: false
		},
		{
			title: t("pages.dashboard.bets.date"),
			dataIndex: "betTime",
			render: (value, record) => {
				return (
					<Fragment>
						<div>{moment.utc(value).local().format(DATE_FORMAT)}</div>
						<div>{moment.utc(value).local().format(TIME_FORMAT)}</div>
					</Fragment>
				)
			}
		},
		{
			title: t('pages.dashboard.betshops.stake_amount'),
			dataIndex: 'stakeAmount'
		},
		{
			title: t('pages.dashboard.bonuses.betshopIds'),
			dataIndex: 'projectName',
			sorter: false
		},
		{
			title: t('pages.dashboard.bonuses.jackpot_contribution'),
			dataIndex: 'contribution',
			render: (value, record) => {
				return `${t(JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS[record.levelType])} - ${record.amount} (${value ?? 0}%)`
			},
			sorter: false
		},
		{
			title: t('pages.dashboard.bonuses.status'),
			dataIndex: 'status',
			render: (value) => {
				return (
					(value === JACKPOT_TRANSACTION_STATUS.WON && JACKPOT_BONUS_TRANSACTION_STATUS_TRANSLATION_TEXTS[value])
						? t(JACKPOT_BONUS_TRANSACTION_STATUS_TRANSLATION_TEXTS[value])
						: " - "
				)
			}
		},
		{
			title: t("pages.dashboard.bonuses.jackpot_end_time"),
			dataIndex: "wonTime",
			render: (value) => {
				if (!value) {
					return " - "
				}
				return (
					<Fragment>
						<div>{moment.utc(value).local().format(DATE_FORMAT)}</div>
						<div>{moment.utc(value).local().format(TIME_FORMAT)}</div>
					</Fragment>
				)
			}
		},
		{
			title: t("pages.dashboard.bonuses.jackpot_won_level"),
			dataIndex: "wonLevelType",
			render: (value) => {
				if (!value) {
					return " - "
				}
				return t(JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS[value])
			}
		},
		{
			title: t('pages.dashboard.reports.totalJackpotWonAmount'),
			dataIndex: 'wonAmount',
			render: (value) => {
				return value ?? " - "
			}
		},
		{
			title: t('pages.dashboard.bonuses.jackpot_payout'),
			dataIndex: 'isPayout',
			render: (value, record) => {
				return (
					record.status === JACKPOT_TRANSACTION_STATUS.NOT_WON
					? " - "
					: value ? t("common.yes") : t("common.no")
				)
			}
		},
		{
			title: t("pages.dashboard.bonuses.jackpot_payout_time"),
			dataIndex: "payoutTime",
			render: (value) => {
				if (!value) {
					return " - "
				}
				return (
					<Fragment>
						<div>{moment.utc(value).local().format(DATE_FORMAT)}</div>
						<div>{moment.utc(value).local().format(TIME_FORMAT)}</div>
					</Fragment>
				)
			}
		},
		{
			title: t('pages.dashboard.bonuses.jackpot_cashier'),
			dataIndex: 'userName',
			render: (value) => {
				return value ?? " - "
			}
		}
	];

	useEffect(() => {
		if(betSlipId) {
			setFilters({ ...filters, betSlipId });
		}
	}, [betSlipId]);

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<div className="dashboard-section-content">
					<div className="table-header">
						<div className="table-buttons-dropdowns">
							{
								hasPermission({ resource: PERMISSION_RESOURCE.BONUS_JACKPOT, action: PERMISSION_ACTION.EXPORT }) &&
								(
									<ExportButton
										columns={columns.map(c => ({ title: c.title, key: c.dataIndex }))}
										tableName={`${t("pages.dashboard.menu.bonuses_jackpot")} ${t("pages.dashboard.bonuses.tabs.jackpot_history")}`}
										url={`${ApiUrls.EXPORT_JACKPOT_HISTORY}?jackpotId=${routeParams.id}`}
										filters={filters}
										sorting={sorting}
									/>
								)
							}
						</div>
						<Filters jackpotId={routeParams.id} />
					</div>

					<Table
						loading={isLoading}
						columns={columns}
						data={jackpotHistory}
						loadFn={getHistory}
						sorting={sorting}
						filters={filters}
						setSortingFn={setSorting}
						setFiltersFn={setFilters}
						total={total}
						updateProps={[globalPartnerId]}
						enableReload={true}
						isDisabled={() => false}
					/>
				</div>
			</div>
		</Fragment>
	)
};

export default JackpotHistory;