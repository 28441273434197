import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { BETSHOP_ACTIVITY_BEFORE, BETSHOP_ACTIVITY_FINISH, BETSHOP_SET_BETSHOP_ACTIVITY, BETSHOP_SET_ACTIVITY_SORTING, BETSHOP_SET_ACTIVITY_FILTERS } from "store/actionTypes";

const setBetshopActivityBefore = () => ({
	type: BETSHOP_ACTIVITY_BEFORE
});

const setBetshopActivityFinished = () => ({
	type: BETSHOP_ACTIVITY_FINISH
});

const setBetshopActivity = (data) => ({
	type: BETSHOP_SET_BETSHOP_ACTIVITY,
	payload: { data }
});

export const setBetshopActivityFilters = (filters) => ({
	type: BETSHOP_SET_ACTIVITY_FILTERS,
	payload: { filters }
});

export const setBetshopActivitySorting = (sorting) => ({
	type: BETSHOP_SET_ACTIVITY_SORTING,
	payload: { sorting }
});

export const getBetshopActivity = (id) => {
	return (dispatch, getState) => {
		dispatch(setBetshopActivityBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_BETSHOP_WALLET_HISTORY}`,
			method: Methods.GET,
			params: {
				...getState().betshops.viewingBetshop.activity.sorting,
				...getState().betshops.viewingBetshop.activity.filters,
				id
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setBetshopActivity(data));
				dispatch(setBetshopActivityFinished());
			})
			.catch(() => {
				dispatch(setBetshopActivityFinished());
			});
	};
};
