import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Button, Input, Spin, Switch } from "antd";
const { Item: FormItem } = Form;

import { getApiKeyAnalyticalTools, saveApiKeyAnalyticalTools } from "store/actions/dashboard/online/apikeys/analyticalTools.action";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import apiKeyAnalyticalToolsType from "types/apiKey/analyticalTools.type";

import { ANALYTICAL_TOOL_TYPE } from "constants/apiKey.constants";

import { GAME_CATEGORY, REDUX_EDITING_API_KEY_ANALITICAL_TOOLS_FIELD } from "constants/game.constants";
import { buildPathToStaticFolderOfCDN } from "utils/common";

const ANALYTICAL_TOOLS_LOGOS_SRC = {
	[ANALYTICAL_TOOL_TYPE.HOTJAR]: buildPathToStaticFolderOfCDN("admin-images/hotjar.svg"),
	[ANALYTICAL_TOOL_TYPE.GOOGLE_ANALYTICS]: buildPathToStaticFolderOfCDN("admin-images/ga.svg"),
	[ANALYTICAL_TOOL_TYPE.VWO]: buildPathToStaticFolderOfCDN("admin-images/vwo.svg"),
	[ANALYTICAL_TOOL_TYPE.YANDEX_METRICA]: buildPathToStaticFolderOfCDN("admin-images/yandex-metrica.svg")
};

const ANALYTICAL_TOOLS_NAME_KEYS = {
	[ANALYTICAL_TOOL_TYPE.HOTJAR]: "pages.dashboard.apikeys.hotjar",
	[ANALYTICAL_TOOL_TYPE.GOOGLE_ANALYTICS]: "pages.dashboard.apikeys.ga",
	[ANALYTICAL_TOOL_TYPE.VWO]: "pages.dashboard.apikeys.vwo",
	[ANALYTICAL_TOOL_TYPE.YANDEX_METRICA]: "pages.dashboard.apikeys.yandexMetrica"
};

const ANALYTICAL_TOOLS_INPUTS_PLACEHOLDERS = {
	[ANALYTICAL_TOOL_TYPE.HOTJAR]: "pages.dashboard.apikeys.hotjarId",
	[ANALYTICAL_TOOL_TYPE.GOOGLE_ANALYTICS]: "pages.dashboard.apikeys.gaId",
	[ANALYTICAL_TOOL_TYPE.VWO]: "pages.dashboard.apikeys.vwoId",
	[ANALYTICAL_TOOL_TYPE.YANDEX_METRICA]: "pages.dashboard.apikeys.yandexMetricaId"
};

/** Api Key Edit Page Analytical Tools Tab Component */
const ToolsComponent = ({
	getApiKeyAnalyticalTools,
	saveApiKeyAnalyticalTools,
	isSaving,
	isLoading,
	analyticalTools,
	// onTabChange,
	gameCategory
}) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_ANALYTICAL_TOOLS, action: PERMISSION_ACTION.MODIFY });

	/** Fires when form submitted
	 * @function
	 * @memberOf ToolsComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				saveApiKeyAnalyticalTools(
					{
						id: routeParams.id,
						analyticalTools: Object.keys(data).map((k) => ({
							type: k,
							integrationId: data[k].integrationId,
							enabled: data[k].enabled
						}))
					},
					gameCategory
				);
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	/** Load api key analytical tools */
	useEffect(() => {
		if (routeParams.id) {
			getApiKeyAnalyticalTools(routeParams.id, gameCategory);
		}
	}, [routeParams.id, gameCategory]);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		const values = Object.fromEntries(Object.values(ANALYTICAL_TOOL_TYPE).map((t, i) => [t, { enabled: false, integrationId: "" }]));
		if (analyticalTools)
			analyticalTools.forEach((tool) => {
				values[tool.type] = {
					enabled: tool.enabled,
					integrationId: tool.integrationId
				};
			});
		setFieldsValue(values);
	}, [analyticalTools]);

	// useEffect(() => {
	// 	onTabChange(isFormTouched);
	// }, [isFormTouched])

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={Object.fromEntries(Object.values(ANALYTICAL_TOOL_TYPE).map((t, i) => [t, { enabled: false, integrationId: "" }]))}
				onValuesChange={(changed, formValues) => {
					const aTools = analyticalTools || Object.values(ANALYTICAL_TOOL_TYPE).map((t, i) => ({ type: t, enabled: false, integrationId: "" }));
					setIsFormTouched(isFormChanged({ ...formValues }, Object.fromEntries(Object.values(aTools).map((t, i) => [t.type, { enabled: t.enabled, integrationId: t.integrationId }]))));
				}}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							{Object.values(ANALYTICAL_TOOL_TYPE).map((tool) => (
								<Col xs={24} sm={6} key={tool}>
									<div className="dashboard-card">
										<div className="dashboard-card-row">
											<div className="apikey-integration-row">
												<div className="apikey-integration-row-title">
													<img src={ANALYTICAL_TOOLS_LOGOS_SRC[tool]} alt="logo" />
													<h5>{t(ANALYTICAL_TOOLS_NAME_KEYS[tool])}</h5>
												</div>
												<FormItem className="inline-form-item-control apikey-integration-row-switch" name={[tool, "enabled"]} valuePropName="checked">
													<Switch
														disabled={isDisabled}
														onChange={() => {
															setTimeout(() => {
																validateFields([[tool, "integrationId"]]);
															}, 0);
														}}
													/>
												</FormItem>
											</div>
										</div>
										<div className="dashboard-card-row">
											<FormItem
												className="apikey-integration-row-input form-item-without-margin"
												label=""
												name={[tool, "integrationId"]}
												rules={[
													({ getFieldValue }) => ({
														validator(rule, value) {
															if (!value && getFieldValue([tool, "enabled"])) {
																return Promise.reject(t("validation.field_required"));
															}
															return Promise.resolve();
														}
													})
												]}
											>
												<Input placeholder={`${t("common.enter")} ${t(ANALYTICAL_TOOLS_INPUTS_PLACEHOLDERS[tool])}`} disabled={isDisabled} />
											</FormItem>
										</div>
									</div>
								</Col>
							))}
						</Row>
					</div>
					{!isDisabled && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** ToolsComponent propTypes
 * PropTypes
 */
ToolsComponent.propTypes = {
	/** Redux action to get apiKey analytical tools */
	getApiKeyAnalyticalTools: PropTypes.func,
	/** Redux action to save apiKey analytical tools */
	saveApiKeyAnalyticalTools: PropTypes.func,
	/** Redux state property, is true when analytical tools is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when analytical tools is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the analytical tools of current editing apiKey  */
	analyticalTools: PropTypes.arrayOf(apiKeyAnalyticalToolsType),
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	getApiKeyAnalyticalTools: (id, gameCategory) => {
		dispatch(getApiKeyAnalyticalTools(id, gameCategory));
	},

	saveApiKeyAnalyticalTools: (data, gameCategory) => {
		dispatch(saveApiKeyAnalyticalTools(data, gameCategory));
	}
});

const mapStateToProps = (state, props) => {
	const { gameCategory } = props;
	return {
		analyticalTools: state.apikeys.editingApikey[REDUX_EDITING_API_KEY_ANALITICAL_TOOLS_FIELD[gameCategory]],
		isSaving: state.apikeys.isSaving,
		isLoading: state.apikeys.isLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ToolsComponent);
