import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Upload as AntUpload, Button, message } from "antd";
const { Item: FormItem } = Form;
import { UploadOutlined } from "@ant-design/icons";

import { getUser } from "utils/auth";
import { doUploadRequest } from "utils/upload";

const Upload = ({ label, btnText, action, onChange, onFileReady, onRemove, defaultFileUrl, defaultFileName, data, extensions, size, showUploadList, onBeforeUpload, globalPartnerId, disabled, children, formItemProps = {} }) => {
	const { t } = useTranslation();

	const [files, setFiles] = useState(
		defaultFileUrl
			? [
					{
						url: defaultFileUrl,
						name: defaultFileName,
						uid: Math.random(),
						status: "done"
					}
				]
			: []
	);

	useEffect(() => {
		setFiles(
			defaultFileUrl
				? [
						{
							url: defaultFileUrl,
							name: defaultFileName,
							uid: Math.random(),
							status: "done"
						}
					]
				: []
		);
	}, [defaultFileUrl]);

	const headersForUpload = {};
	const token = getUser()?.token;
	if (token) {
		headersForUpload["Authorization"] = "Bearer " + token;
	}

	if (globalPartnerId) {
		headersForUpload["SelectedPartnerId"] = globalPartnerId;
	}

	/** Fires on upload value change
	 * @function
	 * @description keep only first file, skip others
	 * @param {object} info - info about files
	 * @memberOf Upload
	 */
	const handleChange = (info) => {
		let fileList = [...info.fileList.filter((f) => f.status !== undefined)];
		fileList = fileList.slice(-1);
		setFiles(fileList);
		let logo = fileList.filter((f) => f.status === "done")[0];
		if (logo) {
			onChange && onChange(logo);
		}
	};

	/** Fires before uploading
	 * @function
	 * @description check if file format and size are allowed
	 * @param {object} file - the file to upload
	 * @memberOf Upload
	 */
	const beforeUpload = (file) => {
		if (extensions && !extensions.includes(file.type)) {
			message.info(t("errors.message.InvalidFile"));
			return Promise.reject(t("errors.message.InvalidFile"));
		} else if (size && size < file.size) {
			message.info(t("errors.message.InvalidFileSize"));
			return Promise.reject(t("errors.message.InvalidFileSize"));
		}
		if (!action) {
			return;
		}
		onBeforeUpload && onBeforeUpload();
		return true;
	};

	return (
		<FormItem {...formItemProps} label={label}>
			<AntUpload
				fileList={files}
				action={action}
				extensions={extensions}
				showUploadList={showUploadList}
				onChange={handleChange}
				multiple={false}
				onRemove={onRemove}
				headers={headersForUpload}
				data={data}
				beforeUpload={beforeUpload}
				customRequest={({ onSuccess, onError, onProgress, file }) => {
					onFileReady && onFileReady(file);
					if (action) {
						doUploadRequest({ action, onSuccess, onError, onProgress, file, data });
					}
				}}
				disabled={disabled}
			>
				{children ? (
					children
				) : (
					<Button disabled={disabled}>
						<UploadOutlined />
						{btnText}
					</Button>
				)}
			</AntUpload>
		</FormItem>
	);
};

Upload.propTypes = {
	/**The lable of form control */
	label: PropTypes.string,
	/**Upload button text */
	btnText: PropTypes.string,
	/** The url to upload */
	action: PropTypes.string,
	/** upload change handler */
	onChange: PropTypes.func,
	/** Fires on file Ready */
	onFileReady: PropTypes.func,
	/** Fires on remove button click next to file  */
	onRemove: PropTypes.func,
	/** Default file url */
	defaultFileUrl: PropTypes.string,
	/** Default file name */
	defaultFileName: PropTypes.string,
	/** Files data of upload */
	data: PropTypes.object,
	/** Files extension allowed for upload */
	extensions: PropTypes.arrayOf(PropTypes.string),
	/** Max file size allowed for upload */
	size: PropTypes.number,
	/** Whether to show default upload list */
	showUploadList: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	/** Whether the upload is disabled */
	disabled: PropTypes.bool,
	/** Function which will be executed before uploading */
	onBeforeUpload: PropTypes.func,
	/** Redux state, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** The JSX content of upload */
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

const mapStateToProps = (state) => {
	return {
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, null)(Upload);
