import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";

import { Button } from "antd";
import Table from "components/common/table";
import BoxAddComponent from "./box-add.component";
import betshopBoxType from "types/betshop/box.type";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { getBetshopBoxes, deleteBetshopBox, updateBetshopBox, getAvailableBoxes } from "store/actions/dashboard/retail/betshops/box.action";

import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import boxType from "types/box/box.type";

/** Betshop Edit Page Boxes Tab Component */
const BoxesComponent = ({ boxes, getBetshopBoxes, isLoading, getAvailableBoxes, deleteBetshopBox, updateBetshopBox, availableBoxes }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const [showCreatePopup, setShowCreatePopup] = useState(false);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.boxes.name"),
			dataIndex: "name",
			sorter: false
		},
		{
			title: t("pages.dashboard.boxes.games"),
			dataIndex: "games",
			sorter: false,
			render: (value) => value.map((v) => v.name).join(", ")
		},
		{
			title: t("pages.dashboard.boxes.last_update_at"),
			dataIndex: "lastModified",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT),
			sorter: false
		}
	];

	/** Get available Boxes */
	useEffect(() => {
		getAvailableBoxes();
	}, []);

	/** Close box editing/adding popup, after game saved */
	useEffect(() => {
		setShowCreatePopup(false);
	}, [boxes]);

	return (
		<div className="dashboard-section-content">
			{hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_BOX, action: PERMISSION_ACTION.CREATE }) && (
				<div className="dashboard-section-buttons">
					<Button
						type="primary"
						htmlType="button"
						className="button"
						onClick={() => {
							setShowCreatePopup(true);
						}}
						disabled={availableBoxes.length === 0 || boxes.length > 0}
					>
						<span>{t("pages.dashboard.betshops.add_new_box")}</span>
					</Button>
				</div>
			)}

			<Table
				loading={isLoading}
				columns={columns}
				data={boxes}
				loadFn={() => getBetshopBoxes(routeParams.id)}
				total={boxes.length}
				actions={{
					activate: hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_BOX, action: PERMISSION_ACTION.MODIFY })
						? {
								isChecked: (record) => record.enabled,
								messageKey: "box",
								handler: (isChecked, record) =>
									updateBetshopBox({
										enabled: isChecked,
										display1: record.display1,
										display2: record.display2,
										boxId: record.id,
										id: routeParams.id
									})
							}
						: null,
					delete: hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_BOX, action: PERMISSION_ACTION.DELETE })
						? {
								messageKey: "box",
								handler: (record) => {
									deleteBetshopBox(routeParams.id, record.id);
								}
							}
						: null
				}}
				isDisabled={(record) => !record.enabled}
				noPagination={true}
				uniqueKey="id"
			/>

			{showCreatePopup ? (
				<BoxAddComponent
					onClose={() => {
						setShowCreatePopup(false);
					}}
				/>
			) : null}
		</div>
	);
};

/** BoxesComponent propTypes
 * PropTypes
 */
BoxesComponent.propTypes = {
	/** Redux action to get boxes */
	getBetshopBoxes: PropTypes.func,
	/** Redux state property, represents the array of boxes of betshop */
	boxes: PropTypes.arrayOf(betshopBoxType),
	/** Redux state property, is true when loading betshop boxes */
	isLoading: PropTypes.bool,
	/** Redux action to get all available boxes */
	getAvailableBoxes: PropTypes.func,
	/** Redux action to delete betshop box */
	deleteBetshopBox: PropTypes.func,
	/** Redux action to update box to betshop*/
	updateBetshopBox: PropTypes.func,
	/** Redux state property, represents the array of available boxes  */
	availableBoxes: PropTypes.arrayOf(boxType)
};

const mapDispatchToProps = (dispatch) => ({
	getBetshopBoxes: (id) => {
		dispatch(getBetshopBoxes(id));
	},
	getAvailableBoxes: () => {
		dispatch(getAvailableBoxes());
	},
	deleteBetshopBox: (id, boxId) => {
		dispatch(deleteBetshopBox(id, boxId));
	},
	updateBetshopBox: (box) => {
		dispatch(updateBetshopBox(box));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.betshops.editingBetshop.boxes.isLoading,
		boxes: state.betshops.editingBetshop.boxes.boxes,
		availableBoxes: state.betshops.editingBetshop.boxes.availableBoxes
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BoxesComponent);
