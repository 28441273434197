import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Row, Col, Divider } from "antd";
import useDashboardOnlineRequest from "hooks/dashboard/useDashboardOnlineRequest";
import OnlinePlayersWidget from "../../widgets/onlinePlayers";
import PendingBetslipsWidget from "../../widgets/pendingBetslips";
import TotalPlayersWidget from "../../widgets/totalPlayers";
import TotalBetslipsWidget from "../../widgets/totalBetslips";
import TotalTurnoverWidget from "../../widgets/totalTurnover";
import TotalGGRAmountWidget from "../../widgets/totalGGRAmount";
import TotalGGRPercentageWidget from "../../widgets/totalGGRPercentage";
import TotalWinAmountWidget from "../../widgets/totalWinAmount";
import TopMarketsChart from "../../charts/topMarketsChart";
import GamePerformanceChart from "../../charts/gamePerformanceChart";
import UniquePlayersChart from "../../charts/uniquePlayersChart";
import BetslipsChart from "../../charts/betslipsChart";
import TopPlayers from "../../widgets/topPlayers";
import DashboardFilters from "../../filters";
import { PROJECT_TYPE } from "constants/common.constants";

/** Dashboard Page online Dashboard Tab Component */
const OnlineDashboardComponent = ({ filters, activeKey }) => {
	const { t } = useTranslation();

	useDashboardOnlineRequest(activeKey);

	return (
		<div className="dashboard-section-content">
			<Row gutter={[16, 0]}>
				<Col xs={24} sm={12} lg={6} xxl={4}>
					<OnlinePlayersWidget activeKey={activeKey} />
				</Col>
				<Col xs={24} sm={12} lg={6} xxl={4}>
					<PendingBetslipsWidget activeKey={activeKey} projectType={PROJECT_TYPE.ONLINE} />
				</Col>
			</Row>
			<Divider />
			<DashboardFilters projectType={PROJECT_TYPE.ONLINE} />
			<Row gutter={[16, 0]}>
				<Col xs={24} sm={12} lg={6} xxl={4}>
					<TotalPlayersWidget projectType={PROJECT_TYPE.ONLINE} />
				</Col>
				<Col xs={24} sm={12} lg={6} xxl={4}>
					<TotalBetslipsWidget projectType={PROJECT_TYPE.ONLINE} />
				</Col>
				<Col xs={24} sm={12} lg={6} xxl={4}>
					<TotalTurnoverWidget projectType={PROJECT_TYPE.ONLINE} />
				</Col>
				<Col xs={24} sm={12} lg={6} xxl={4}>
					<TotalGGRAmountWidget projectType={PROJECT_TYPE.ONLINE} />
				</Col>
				<Col xs={24} sm={12} lg={6} xxl={4}>
					<TotalGGRPercentageWidget projectType={PROJECT_TYPE.ONLINE} />
				</Col>
				<Col xs={24} sm={12} lg={6} xxl={4}>
					<TotalWinAmountWidget projectType={PROJECT_TYPE.ONLINE} />
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col xs={24}>
					<GamePerformanceChart projectType={PROJECT_TYPE.ONLINE} gameCategory={filters.gameCategory} />
				</Col>
				<Col xs={24}>
					<TopMarketsChart projectType={PROJECT_TYPE.ONLINE} />
				</Col>
			</Row>
			<Row gutter={[16, 0]}>
				<Col xs={24} sm={12}>
					<UniquePlayersChart period={filters.period} />
				</Col>
				<Col xs={24} sm={12}>
					<BetslipsChart period={filters.period} projectType={PROJECT_TYPE.ONLINE} />
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<span className="dashboard-widget-title">{t("pages.dashboard.dashboard.top_players")}</span>
				</Col>
				<Col xs={24}>
					<TopPlayers />
				</Col>
			</Row>
		</div>
	);
};

/** OnlineDashboardComponent propTypes
 * PropTypes
 */
OnlineDashboardComponent.propTypes = {
	/** Redux state property, dashboard pie charts*/
	filters: PropTypes.object,
	/** Active tab key */
	activeKey: PropTypes.oneOf(Object.values(PROJECT_TYPE).map((v) => v.toString()))
};

const mapStateToProps = (state) => {
	return {
		filters: state.dashboard.online.filters
	};
};

const mapDispatchToProps = null; // (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OnlineDashboardComponent);
