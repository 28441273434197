import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { SET_SURVEY_SAVE_BEFORE, SET_SURVEY_SAVE_FINISHED, SURVEYS_ACTION_BEFORE, SURVEYS_ACTION_FINISH, SURVEYS_ACTION_SET_SURVEYS, SURVEYS_SET_SORTING, RESET_EDITING_SURVEY, CHANGE_SURVEY_STATE } from "store/actionTypes";

const setSurveys = (data) => ({
	type: SURVEYS_ACTION_SET_SURVEYS,
	payload: { data }
});

export const setSurveysActionBefore = () => ({
	type: SURVEYS_ACTION_BEFORE
});

export const setSurveysActionFinished = () => ({
	type: SURVEYS_ACTION_FINISH
});

export const setSurveysSorting = (sorting) => ({
	type: SURVEYS_SET_SORTING,
	payload: { sorting }
});

export const resetEditingSurvey = () => ({
	type: RESET_EDITING_SURVEY
});

const setChangeSurveyState = (data) => ({
	type: CHANGE_SURVEY_STATE,
	payload: { data }
});

export const setSurveySaveBefore = () => ({
	type: SET_SURVEY_SAVE_BEFORE
});

export const setSurveySaveFinished = () => ({
	type: SET_SURVEY_SAVE_FINISHED
});

export const getSurveys = (fromFirstPage) => {
	return (dispatch, getState) => {
		dispatch(setSurveysActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SURVEYS}`,
			method: Methods.GET,
			params: {
				...getState().surveys.sorting,
				page: fromFirstPage ? 1 : getState().surveys.sorting.page
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setSurveys(data));
				if (fromFirstPage) dispatch(setSurveysSorting({ ...getState().surveys.sorting, page: 1 }));
				dispatch(setSurveysActionFinished());
			})
			.catch(() => {
				dispatch(setSurveysActionFinished());
			});
	};
};

export const changeSurveyState = ({ state, id, onSaveClick = null }) => {
	return (dispatch) => {
		dispatch(setSurveySaveBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SURVEY_CHANGE_STATE}`,
			method: Methods.POST,
			data: { state, id }
		})
			.then(({ data: { status, value, ...other } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(other.message);
					dispatch(setChangeSurveyState(value));

					if (onSaveClick !== null) {
						onSaveClick();
					}
				}
				dispatch(setSurveySaveFinished());
			})
			.catch((ex) => {
				dispatch(setSurveySaveFinished());
			});
	};
};

export const createSurvey = (survey, onSuccess) => {
	return (dispatch) => {
		dispatch(setSurveySaveBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CREATE_SURVEY}`,
			method: Methods.POST,
			data: survey
		})
			.then(({ data: { value } }) => {
				dispatch(setSurveySaveFinished());
				onSuccess && onSuccess(value);
			})
			.catch(() => {
				dispatch(setSurveySaveFinished());
			});
	};
};
