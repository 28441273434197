import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import useCurrentPath from "hooks/useCurrentPath";

import { Menu } from "antd";
import { WarningOutlined } from "@ant-design/icons";

import Paths from "constants/path.constants";
import { DYNAMIC_PATH_NAME_REGEX } from "constants/regex.constants";

import { hasPermission, hasOneOfPermissions } from "utils/permissions";

import { getMonitoringServices } from "store/actions/dashboard/developer/monitoring/monitoring.action";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

import menuItems from "./items";

import userInfoType from "types/profile/userInfo.type";

const canShowMenu = (item) => (!item.permission || (!Array.isArray(item.permission) && hasPermission(item.permission)) || (Array.isArray(item.permission) && hasOneOfPermissions(item.permission))) && !item.disabled;
const isArg1MoreThenArg2 = (arg1, arg2) => arg1 > arg2;
const checkForPush = (objWithMatchFn = { match: null }, argsArrayForChecking = [], valuesArrayToPush = [], arrForPush = []) => {
	if (!objWithMatchFn) return;
	if (!objWithMatchFn.match) return;
	if (!argsArrayForChecking.some((argForCheck) => objWithMatchFn.match.includes(argForCheck))) return;
	valuesArrayToPush.forEach((valueToPush) => arrForPush.push(valueToPush));
};

/** Dashboard Sidebar Menu Component */
const DashboardMenu = ({ getMonitoringServices, services, userInfo, onNavigate }) => {
	const navigate = useNavigate();
	const path = useCurrentPath();
	const { pathname } = useLocation();
	const { t } = useTranslation();

	const [items, setItems] = useState(menuItems(userInfo.partners.map((p) => p.type)));
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [openedKeys, setOpenedKeys] = useState([]);

	/** detects which menu items should be opened by default depend on route
	 * @function
	 * @memberOf DashboardMenu
	 */
	const defaultOpenedKeys = () => {
		const result = [];
		items.forEach((item) => {
			if (!item.items) {
				return;
			}
			item.items.forEach((i) => {
				if (i.items) {
					return i.items.forEach((it) => checkForPush(it, [path, pathname], [item.id, i.id], result));
				}
				checkForPush(i, [path, pathname], [item.id], result);
			});
		});
		setOpenedKeys(result);
		return result;
	};

	/** update active menu items depend on url change
	 * @function
	 * @memberOf DashboardMenu
	 */
	const defaultSelectedKeys = () => {
		const result = [];
		items.forEach((item) => {
			checkForPush(item, [path, pathname], [item.id], result);
			if (!item.items) {
				return;
			}
			item.items.forEach((i) => {
				if (i.items) {
					return i.items.forEach((it) => checkForPush(it, [path, pathname], [it.id], result));
				}
				checkForPush(i, [path, pathname], [i.id], result);
			});
		});

		setSelectedKeys(result);
	};

	/** Update active menu items, when path changes */
	useEffect(() => {
		defaultOpenedKeys();
		defaultSelectedKeys();
	}, [path, pathname, items]);

	/** Load all monitoring services to show in menu */
	useEffect(() => {
		if (hasPermission({ resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.VIEW })) {
			getMonitoringServices();
		}
	}, []);

	/** Add monitoring services to menu */
	useEffect(() => {
		setItems(
			items.map((elem) => {
				if (elem.id !== "5") {
					return elem;
				}
				const elemCopy = {
					...elem,
					hint: services.find((s) => isArg1MoreThenArg2(s.totalFailed, 1))
				};
				elemCopy.items = elem.items.map((it) => {
					if (it.id !== "53") {
						return it;
					}

					const newIt = { ...it };
					newIt.hint = services.find((s) => isArg1MoreThenArg2(s.totalFailed, 1));
					newIt.items = services.map((s, index) => {
						const jsx = (
							<div className={"dashboard-menu-item-warning " + (isArg1MoreThenArg2(s.totalFailed, 10) ? "dashboard-menu-item-warning-red" : "")}>
								<span>{s.name}</span>
								{isArg1MoreThenArg2(s.totalFailed, 1) ? <WarningOutlined /> : null}
							</div>
						);
						const path = Paths.DASHBOARD_DEVELOPER_MONITORING.replace(DYNAMIC_PATH_NAME_REGEX, s.name);

						return {
							id: `${it.id}${index}`,
							url: path,
							content: jsx,
							match: [path]
						};
					});
					return newIt;
				});
				return elemCopy;
			})
		);
	}, [services]);

	/**Navigate to the page
	 * @function
	 * @param {string} urlArg - url to navigate
	 * @memberOf DashboardMenu
	 */
	const handleNavigate = (urlArg) => {
		onNavigate();
		navigate(urlArg);
	};

	return (
		<div className="sidenav">
			<Menu
				selectedKeys={selectedKeys}
				openKeys={openedKeys}
				style={{ width: 280 }}
				mode="inline"
				onOpenChange={(keys) => {
					setOpenedKeys(keys.filter((k) => k.length === 1 || keys.includes(k[0])));
				}}
				expandIcon={() => <i className="icon-down" />}
				items={items.reduce((menuArr, item) => {
					if (!canShowMenu(item)) {
						return menuArr;
					}
					if (!item.items) {
						return menuArr.concat({
							key: item.id,
							onClick: () => {
								handleNavigate(item.url);
							},
							label: (
								<div className="dashboard-menu-item">
									<i className={item.icon}></i>
									<span>{t(item.title)}</span>
								</div>
							)
						});
					}
					menuArr.push({
						key: item.id,
						popupClassName: "sidenav-submenu",
						label: (
							<div className="dashboard-menu-item">
								<i className={item.icon}></i>
								<div className={item.hint ? "dashboard-menu-item-warning-red" : ""}>
									<span>{t(item.title)}</span>
									{item.hint ? <WarningOutlined /> : null}
								</div>
							</div>
						),
						children: item.items.reduce((submenuArr, i) => {
							if (!canShowMenu(i)) {
								return submenuArr;
							}
							if (!i.items) {
								return submenuArr.concat({
									key: i.id,
									onClick: () => handleNavigate(i.url),
									label: <span>{t(i.title)}</span>
								});
							}
							submenuArr.push({
								key: i.id,
								className: "dashboard-menu-item-submenu",
								label: (
									<div className={i.hint ? "dashboard-menu-item-warning-red" : ""}>
										<span>{t(i.title)}</span>
										{i.hint ? <WarningOutlined /> : null}
									</div>
								),
								children: i.items.map((it) => ({
									key: it.id,
									onClick: () => {
										handleNavigate(it.url);
									},
									label: it.title ? <span>{t(it.title)}</span> : it.content
								}))
							});
							return submenuArr;
						}, [])
					});

					return menuArr;
				}, [])}
			/>
		</div>
	);
};

/** DashboardMenu propTypes
 * PropTypes
 */
DashboardMenu.propTypes = {
	/**Redux action to get all monitoring services in system */
	getMonitoringServices: PropTypes.func,
	/**Redux state property, represents the array of all monitoring services */
	services: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
			totalFailed: PropTypes.number
		})
	),
	/** Redux state property, current user info */
	userInfo: userInfoType,
	/** Function , fires on navigation */
	onNavigate: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		services: state.monitoring.services,
		userInfo: state.profile.userInfo
	};
};

const mapDispatchToProps = (dispatch) => ({
	getMonitoringServices: () => {
		dispatch(getMonitoringServices());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMenu);
