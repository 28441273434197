import { Fragment } from "react";

import BonusesComponent from 'components/dashboard/bonuses/standard/bonuses.component';
import withPermission from 'hocs/withPermission';

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const BonusesJackpotRoute = () => {
	return (
		<Fragment>
			<BonusesComponent />
		</Fragment>
	);
};

export default withPermission(
	BonusesJackpotRoute,
	{ resource: PERMISSION_RESOURCE.BONUS_STANDARD, action: PERMISSION_ACTION.VIEW },
	Paths.DASHBOARD
)
