import PropTypes from "prop-types";

import { connect } from "react-redux";

import { PROJECT_TYPE } from "constants/common.constants";
import { TRANSACTION_TYPE } from "constants/transactions.constants";

import { getTransactions, getTransactionHistory, setTransactionsSorting, resendTransaction, setTransactionsFilters } from "store/actions/dashboard/transactions/transactions.action";

import TransactionsTableComponent from "../../../table.component";

import transactionType from "types/transaction/transaction.type";
import sortingType from "types/common/sorting.type";

/** Transactions Page Results Tab Online SubTabe Page Component */
const OnlineTransactionsComponent = ({ getTransactions, getTransactionHistory, setTransactionsSorting, setTransactionsFilters, resendTransaction, transactions, isLoading, sorting, filters, total }) => {
	return (
		<TransactionsTableComponent
			getTransactions={getTransactions}
			getTransactionHistory={getTransactionHistory}
			setTransactionsSorting={setTransactionsSorting}
			setTransactionsFilters={setTransactionsFilters}
			resendTransaction={resendTransaction}
			transactions={transactions}
			isLoading={isLoading}
			sorting={sorting}
			filters={filters}
			total={total}
			projectType={PROJECT_TYPE.ONLINE}
			transactionType={TRANSACTION_TYPE.RESULT}
		/>
	);
};

/** OnlineTransactionsComponent propTypes
 * PropTypes
 */
OnlineTransactionsComponent.propTypes = {
	/** Redux action to get online transactions */
	getTransactions: PropTypes.func,
	/** Redux action to get transaction history */
	getTransactionHistory: PropTypes.func,
	/** Redux action to resend transaction */
	resendTransaction: PropTypes.func,
	/** Redux state property, represents the array of online transactions */
	transactions: PropTypes.arrayOf(transactionType),
	/** Redux state property, is true when loading online transactions */
	isLoading: PropTypes.bool,
	/** Redux action to set online transactions sorting details */
	setTransactionsSorting: PropTypes.func,
	/** Redux action to set online transactions filters */
	setTransactionsFilters: PropTypes.func,
	/** Redux state property, online transactions sorting details */
	sorting: sortingType,
	/** Redux state property, online transactions filters */
	filters: PropTypes.object,
	/** Redux state property, online transactions total count */
	total: PropTypes.number
};

const mapDispatchToProps = (dispatch) => ({
	getTransactions: (fromFirstPage) => {
		dispatch(getTransactions(PROJECT_TYPE.ONLINE, TRANSACTION_TYPE.RESULT, fromFirstPage));
	},
	getTransactionHistory: (id) => {
		dispatch(getTransactionHistory(id, PROJECT_TYPE.ONLINE, TRANSACTION_TYPE.RESULT));
	},
	setTransactionsSorting: (sorting) => {
		dispatch(setTransactionsSorting(sorting, PROJECT_TYPE.ONLINE, TRANSACTION_TYPE.RESULT));
	},
	setTransactionsFilters: (filters) => {
		dispatch(setTransactionsFilters(filters, PROJECT_TYPE.ONLINE, TRANSACTION_TYPE.RESULT));
	},
	resendTransaction: (id) => {
		dispatch(resendTransaction(id, PROJECT_TYPE.ONLINE, TRANSACTION_TYPE.RESULT));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.transactions.isLoading,
		transactions: state.transactions.results.online.transactions,
		total: state.transactions.results.online.total,
		sorting: state.transactions.results.online.sorting,
		filters: state.transactions.results.online.filters
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OnlineTransactionsComponent);
