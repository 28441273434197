import { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Tag, Tooltip } from "antd";

import Paths from "constants/path.constants";
import { JOB_STATE } from "constants/job.constants";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import SignalRUtils from "utils/signalR";

import { getJobs, updateJob, unlockJob } from "store/actions/dashboard/developer/jobs/jobs.action";
import { saveJobSettings } from "store/actions/dashboard/developer/jobs/jobs.action";

import JobEditComponent from './job-edit.component';
import Breadcrumbs from 'components/common/breadcrumbs';
import Table from 'components/common/table';

import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";
import jobType from "types/job/job.type";
import { hasPermission } from "utils/permissions";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

/** Jobs Page Component */

const JobsComponent = ({ getJobs, updateJob, jobs, isLoading, unlockJob, isSaving, saveJobSettings }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	/** State to show/hide settings edit popup */
	const [showEditPopup, setShowEditPopup] = useState(false);
	const [groups, setGroups] = useState([]);

	/** Get the state background color in table
	 * @function
	 * @param {strong} state - state
	 * @returns {string} - color
	 * @memberOf JobsComponent
	 */
	const getStateColor = (state) => {
		const mapping = {
			[JOB_STATE.RUNNING]: "blue",
			[JOB_STATE.SUCCESS]: "green",
			[JOB_STATE.FAILED]: "red"
		};
		return mapping[state];
	};

	/** Load all jobs */
	useEffect(() => {
		getJobs();
	}, []);

	/** Close settings editing popup after save */
	useEffect(() => {
		if (!isSaving) setShowEditPopup(null);
	}, [isSaving]);

	/** Get all avalable groups to group jobs */
	useEffect(() => {
		if (groups.length === 0) {
			setGroups([...new Set(jobs.map((j) => j.group))]);
		}
	}, [jobs]);

	/** Subscribe to singalR events */
	useEffect(() => {
		SignalRUtils.getConnections().forEach((connection, index) => {
			if ((index === 0) || (index === 2)) {
				connection.addHandler(() => {
					connection.getConnection().invoke("Subscribe", "Jobs");
					connection.getConnection().off("Jobs");
					connection.getConnection().on("Jobs", (data) => {
						const job = JSON.parse(data);
						updateJob({
							startTime: job.startTime,
							nextFireTime: job.nextFireTime,
							elapsedMilliseconds: job.elapsedMilliseconds,
							serverName: job.serverName,
							state: job.state,
							message: job.message,
							errorId: job.errorId,
							name: job.name,
							enabled: job.enabled,
							id: job.id,
							executionHistory: job.executionHistory
						});
					});
				});
			}
		});

		return () => {
			SignalRUtils.getConnections().forEach((connection) => {
				connection.getConnection().invoke("Unsubscribe", "Jobs");
				connection.getConnection().off("Jobs");
			});
		};
	}, []);

	/** Render tooltip of title in jobs table
	 * @function
	 * @param {object} record - row item
	 * @returns {JSX}
	 * @memberOf JobsComponent
	 */
	const renderTooltip = (record) => (
		<span>
			<b>{t("pages.dashboard.jobs.cronExpression") + ": "}</b>
			<span>{record.cronExpression}</span>
			<br />
			<b>{t("pages.dashboard.jobs.cronDescription") + ": "}</b>
			<span>{record.cronDescription}</span>
		</span>
	);

	/** Columns of table */
	const columns = [
		{
			title: t("common.name"),
			dataIndex: "name",
			render: (name, record) => <Tooltip title={renderTooltip(record)}>{name}</Tooltip>,
			sorter: false,
			width: "320px"
		},
		{
			title: t("pages.dashboard.jobs.state"),
			dataIndex: "state",
			render: (state, record) => (
				<span
					onClick={() => {
						record.errorId && navigate(Paths.DASHBOARD_DEVELOPER_ERROR_DETAILS.replace(DYNAMIC_PATH_ID_REGEX, record.errorId));
					}}
				>
					{record.message ? (
						<Tooltip title={record.message}>
							<Tag color={getStateColor(state)}>{t("pages.dashboard.jobs.state_" + state)}</Tag>
						</Tooltip>
					) : (
						<Tag color={getStateColor(state)}>{t("pages.dashboard.jobs.state_" + state)}</Tag>
					)}
				</span>
			),
			sorter: false
		},
		{
			title: t("pages.dashboard.jobs.elapsedMilliseconds"),
			dataIndex: "elapsedMilliseconds",
			sorter: false
		},
		{
			title: t("pages.dashboard.jobs.startTime"),
			dataIndex: "startTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT),
			sorter: false
		},
		{
			title: t("pages.dashboard.jobs.nextFireTime"),
			dataIndex: "nextFireTime",
			render: (value) => (value ? <span className={moment.utc(value).local() < moment.utc().local() ? "info-text error-text" : ""}>{moment.utc(value).local().format(DATE_TIME_FORMAT)}</span> : ""),
			sorter: false
		},
		{
			title: t("pages.dashboard.jobs.serverName"),
			dataIndex: "serverName",
			sorter: false
		}
	];

	/* Specific case, view action busy for activiti functional */
	const generateAction = () => {
		if (!hasPermission({ resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.MODIFY })) {
			return {
				view: {
					handler: record => setShowEditPopup(record.id)
				}
			};
		}
		return {
			activate: {
				isChecked: (record) => record.enabled,
				messageKey: "job",
				handler: (isChecked, record) =>
					saveJobSettings({
						enabled: isChecked,
						cronExpression: record.cronExpression,
						allowedServerNames: record.allowedServerNames,
						id: record.id
					})
			},
			edit: {
				handler: (record) => setShowEditPopup(record.id)
			},
			unlock: {
				handler: (record) => unlockJob(record.id),
				title: t("pages.dashboard.jobs.unlock"),
				hidden: (record) => record.state !== JOB_STATE.RUNNING,
				icon: <i className="icon-lock" />
			}
		};
	};

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.jobs") }]} />
				<div className="dashboard-section-content">
					{groups.map((group) => (
						<div key={group} style={{ marginBottom: "30px" }}>
							<h1 style={{ fontSize: "20px" }}> {t("pages.dashboard.jobs.job_group_" + group)} </h1>
							<Table
								loading={isLoading}
								columns={columns}
								data={jobs.filter((job) => job.group === group).map((j) => ({ ...j, key: j.id }))}
								total={jobs.length}
								actions={generateAction()}
								noPagination={true}
								isDisabled={() => false}
								detailsType="table"
								details={(record) =>
									record.executionHistory
										? {
												columns: [
													{
														title: t("pages.dashboard.jobs.state"),
														dataIndex: "state",
														render: (value, h) => (
															<span
																onClick={() => {
																	h.errorId && navigate(Paths.DASHBOARD_DEVELOPER_ERROR_DETAILS.replace(DYNAMIC_PATH_ID_REGEX, h.errorId));
																}}
															>
																{h.message ? (
																	<Tooltip title={h.message}>
																		<Tag color={getStateColor(value)}>{t("pages.dashboard.jobs.state_" + value)}</Tag>
																	</Tooltip>
																) : (
																	<Tag color={getStateColor(value)}>{t("pages.dashboard.jobs.state_" + value)}</Tag>
																)}
															</span>
														)
													},
													{
														title: t("pages.dashboard.jobs.elapsedMilliseconds"),
														dataIndex: "elapsedMilliseconds"
													},
													{
														title: t("pages.dashboard.jobs.startTime"),
														dataIndex: "startTime",
														render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
													},
													{
														title: t("pages.dashboard.jobs.nextFireTime"),
														dataIndex: "nextFireTime",
														render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
													},
													{
														title: t("pages.dashboard.jobs.serverName"),
														dataIndex: "serverName"
													}
												],
												data: record.executionHistory ? record.executionHistory.map((h, i) => ({ ...h, index: i })) : [],
												uniqueKey: "index"
											}
										: null
								}
							/>
						</div>
					))}
				</div>
			</div>
			{
				showEditPopup ? <JobEditComponent id={showEditPopup} onClose={() => setShowEditPopup(null)} /> : null
			}
		</Fragment>
	);
};

/** JobsComponent propTypes
 * PropTypes
 */
JobsComponent.propTypes = {
	/** Redux action to get jobs */
	getJobs: PropTypes.func,
	/** Redux action to update jobs */
	updateJob: PropTypes.func,
	/** Redux state property, represents the array of jobs */
	jobs: PropTypes.arrayOf(jobType),
	/** Redux state property, is true when loading jobs */
	isLoading: PropTypes.bool,
	/** Redux action to unlock job */
	unlockJob: PropTypes.func,
	/** Redux state property, is true when saving job */
	isSaving: PropTypes.bool,
	/** Redux action to save job settings */
	saveJobSettings: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getJobs: () => {
		dispatch(getJobs());
	},
	updateJob: (job) => {
		dispatch(updateJob(job));
	},
	unlockJob: (id) => {
		dispatch(unlockJob(id));
	},
	saveJobSettings: (settings) => {
		dispatch(saveJobSettings(settings));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.jobs.isLoading,
		jobs: state.jobs.jobs,
		isSaving: state.jobs.isSaving
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsComponent);
