import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Col, Row, Modal, Select, Tooltip } from "antd";
const { Item: FormItem } = Form;
import { changeBonusState } from "store/actions/dashboard/bonuses/bonuses.action";
import { POPUP_SIZE } from "constants/common.constants";

import { BONUS_STATE } from "constants/bonus.constants";

/** Bonus State changing Popup Component */
const BonusStateChangeComponent = ({ id, isSaving, changeBonusState, onClose, ...otherProps }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;
	const [disableButton, setDisableButton] = useState(true);

	/** Fires when form submitted
	 * @function
	 * @memberOf BonusStatusChangeComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				if (data.bonusState === BONUS_STATE.FINISHED) {
					changeBonusState({ ...data, id });
				}
				onClose();
			})
			.catch(Function.prototype);
	};

	return (
		<Modal
			open={true}
			title={t("pages.dashboard.bonuses.change_bonus_state")}
			cancelText={t("common.cancel")}
			okText={t("common.save")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.SMALL}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving, disabled: disableButton }}
			centered
			{...otherProps}
		>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					bonusState: BONUS_STATE.ACTIVE
				}}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem
							label={
								<>
									<div className="vs--flex vs--align-center">
										{t("pages.dashboard.bonuses.status")}
										<Tooltip placement="bottom" title={<span>{t("pages.dashboard.bonuses.statusChangeInfo")}</span>} getPopupContainer={() => document.body}>
											<i className="icon-info vs--font-bigest" style={{ fontSize: "24px" }} />
										</Tooltip>
									</div>
								</>
							}
							name="bonusState"
							rules={[{ required: true, message: t("validation.field_required") }]}
						>
							<Select
								getPopupContainer={() => document.body}
								suffixIcon={<i className="icon-down" />}
								onChange={(value) => {
									setDisableButton(value === BONUS_STATE.ACTIVE);
								}}
							>
								<Select.Option value={BONUS_STATE.ACTIVE}>{t("pages.dashboard.bonuses.status_1")}</Select.Option>
								<Select.Option value={BONUS_STATE.FINISHED}>{t("pages.dashboard.bonuses.status_2")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** BonusStateChangeComponent propTypes
 * PropTypes
 */
BonusStateChangeComponent.propTypes = {
	/** Redux state property, is true when changing bonus status request is in process */
	isSaving: PropTypes.bool,
	/** Redux action to change bonus state */
	changeBonusState: PropTypes.func,
	/** Current editing bonus id */
	id: PropTypes.string,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** All other props */
	otherProps: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	changeBonusState: (data) => {
		dispatch(changeBonusState(data));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.bonuses.standard.isSaving
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BonusStateChangeComponent);
