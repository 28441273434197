import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getPartnerOnlineReport, setPartnerOnlineReportFilters, setPartnerOnlineReportSorting } from "store/actions/dashboard/reports/partnerOnlineReport/partnerOnlineReport.action";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import Table from "components/common/table";
import Breadcrumbs from "components/common/breadcrumbs";
import Filters from "./filters.component";
import ExportButton from "components/common/exportButton";
import { numberWithSpaces } from "utils/common";
import { convertCurrencies, canConvertCurrencies } from "utils/currency";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import ApiUrls from "constants/api.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import partnerOnlineReportType from "types/reports/partnerOnlineReport.type";
import sortingType from "types/common/sorting.type";
import currencyType from "types/common/currency.type";

/** Partner Online Report Page Component */

const PartnerOnlineReportComponent = ({ getPartnerOnlineReport, partnerOnlineReport, partnerOnlineReportTotal, setPartnerOnlineReportFilters, isLoading, filters, sorting, setPartnerOnlineReportSorting, globalPartnerId, currency, currencies }) => {
	const { t } = useTranslation();

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.reports.partner_name"),
			dataIndex: "totalCol",
			render: (value, record) => record.name,
			sorter: false
		},
		{
			title: t("pages.dashboard.reports.from"),
			dataIndex: "from",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : ""),
			sorter: false
		},
		{
			title: t("pages.dashboard.reports.to"),
			dataIndex: "to",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : ""),
			sorter: false
		},
		{
			title: t("pages.dashboard.reports.projects"),
			dataIndex: "projects",
			sorter: false,
			multi: true,
			multiMapper: (value) => value
		},
		{
			title: t("pages.dashboard.reports.currency"),
			dataIndex: "currencyCode",
			sorter: false
		},
		{
			title: t("pages.dashboard.reports.ggr") + "%",
			dataIndex: "ggrPercent",
			sorter: false,
			render: (v) => v + "%"
		},
		{
			title: t("pages.dashboard.reports.ggr"),
			dataIndex: "ggr",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.turnover"),
			dataIndex: "turnoverAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.win_amount"),
			dataIndex: "wonAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.betslips"),
			dataIndex: "betCount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.betslips_won"),
			dataIndex: "wonCount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.bonus_bet_amount"),
			dataIndex: "bonusTurnoverAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.bonus_bet_count"),
			dataIndex: "bonusBetCount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		},
		{
			title: t("pages.dashboard.reports.bonus_redeem_amount"),
			dataIndex: "bonusRedeemAmount",
			sorter: false,
			render: (v) => numberWithSpaces(v)
		}
	];

	const getTotal = () => {
		let result = {};

		if (partnerOnlineReportTotal) {
			result = {
				...partnerOnlineReportTotal
			};
			if (currency.code !== "USD" && canConvertCurrencies("USD", currency.code, currencies, globalPartnerId)) {
				["ggr", "turnoverAmount", "wonAmount", "bonusTurnoverAmount", "bonusRedeemAmount"].forEach((d) => {
					result[d] = Number(convertCurrencies(result[d], "USD", currency.code, currencies, globalPartnerId));
				});
				result.currencyCode = currency.code;
			}
		} else {
			result.from = result.to = result.currencyCode = "";
			result.projects = [];
			result.ggrPercent = result.ggr = result.turnoverAmount = result.wonAmount = result.betCount = result.wonCount = result.bonusTurnoverAmount = result.bonusBetCount = result.bonusRedeemAmount = 0;
		}
		result.name = t("pages.dashboard.reports.total");

		result.totalCol = "total";

		return result;
	};

	return (
		<div className="dashboard-section">
			<Breadcrumbs items={[{ title: t("pages.dashboard.menu.partner_online_report") }]} />
			<div className="dashboard-section-content">
				<div className="table-header">
					<div className="table-buttons-dropdowns">
						{hasPermission({ resource: PERMISSION_RESOURCE.REPORTS_PARTNER_PERFORMANCE, action: PERMISSION_ACTION.EXPORT }) ? (
							<ExportButton
								columns={columns.map((c) => ({
									title: c.title,
									key: c.dataIndex
								}))}
								tableName={t("pages.dashboard.menu.partner_online_report")}
								url={`${ApiUrls.EXPORT_REPORT_PARTNERS_REVENUES}?projectType=${PROJECT_TYPE.ONLINE}`}
								filters={filters}
								sorting={sorting}
							/>
						) : null}
					</div>
					<Filters />
				</div>
				<Table
					loading={isLoading}
					columns={columns}
					data={partnerOnlineReport.concat([getTotal()])}
					loadFn={getPartnerOnlineReport}
					filters={filters}
					setFiltersFn={setPartnerOnlineReportFilters}
					sorting={sorting}
					setSortingFn={setPartnerOnlineReportSorting}
					actions={{}}
					isDisabled={() => false}
					enableReload={true}
					noPagination={true}
				/>
			</div>
		</div>
	);
};

/** PartnerOnlineReportComponent propTypes
 * PropTypes
 */
PartnerOnlineReportComponent.propTypes = {
	/** Redux action to get partner online report */
	getPartnerOnlineReport: PropTypes.func,
	/** Redux state property, represents the partner online report */
	partnerOnlineReport: PropTypes.arrayOf(partnerOnlineReportType),
	/** Redux state property, represents the partner online report total*/
	partnerOnlineReportTotal: partnerOnlineReportType,
	/** Redux action to set partner online report filters */
	setPartnerOnlineReportFilters: PropTypes.func,
	/** Redux state property, partner online report filters */
	filters: PropTypes.object,
	/** Redux state property, is true when loading partner online report */
	isLoading: PropTypes.bool,
	/** Redux action to set partner online report sorting details */
	setPartnerOnlineReportSorting: PropTypes.func,
	/** Redux state property, partner online report sorting details */
	sorting: sortingType,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, the current user currency */
	currency: currencyType,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType)
};

const mapDispatchToProps = (dispatch) => ({
	getPartnerOnlineReport: () => {
		dispatch(getPartnerOnlineReport());
	},

	setPartnerOnlineReportFilters: (filters) => {
		dispatch(setPartnerOnlineReportFilters(filters));
	},

	setPartnerOnlineReportSorting: (sorting) => {
		dispatch(setPartnerOnlineReportSorting(sorting));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.reports.partnerOnlineReport.isLoading,
		partnerOnlineReport: state.reports.partnerOnlineReport.partnerOnlineReport,
		partnerOnlineReportTotal: state.reports.partnerOnlineReport.partnerOnlineReportTotal,
		filters: state.reports.partnerOnlineReport.filters,
		sorting: state.reports.partnerOnlineReport.sorting,
		globalPartnerId: state.partner.globalPartnerId,
		currencies: state.profile.userInfo.currencies,
		currency: state.profile.userInfo.currency
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerOnlineReportComponent);
