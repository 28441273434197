import LocalStorageUtils from "./localStorage";

//import supported translations
import translationEN from "translations/en.json";

const Languages = Object.freeze({
	ENG: 1
});

export default {
	defaultLang: "en",

	supportedLanguges: {
		en: { id: Languages.ENG, name: "English", key: "en", code: "us", translations: translationEN }
	},

	/** Get current selected language
	 * @memberof LanguageUtils
	 * @function
	 * @returns {string}
	 */
	getSelectedLanguage: function () {
		const selectedLang = LocalStorageUtils.get("selected_language");
		if (selectedLang) {
			return selectedLang;
		}
		return this.defaultLang;
	},

	/** Set current language
	 * @memberof LanguageUtils
	 * @function
	 * @param {string} langCode - language
	 */
	setSelectedLanguage: function (langCode) {
		if (this.supportedLanguges[langCode]) {
			LocalStorageUtils.set("selected_language", langCode);
		}
	},

	/** Get translation object
	 * @memberof LanguageUtils
	 * @function
	 * @param {string} langCode - language
	 * @returns {Object}
	 */
	getTranslation: function (langCode) {
		if (langCode) {
			return this.supportedLanguges[langCode].translations;
		}
		return this.supportedLanguges[this.defaultLang].translations;
	},

	/** Change app language
	 * @memberof LanguageUtils
	 * @function
	 * @param {Object} i18n - i18n config object
	 * @param {string} langCode - language
	 */
	changei18Language: function (i18n, langCode) {
		this.setSelectedLanguage(langCode);
		i18n.changeLanguage(langCode);
	}
};
