import { Fragment } from "react";

import TicketsComponent from "components/dashboard/cms/tickets/tickets.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const TicketsRoute = () => {
	return (
		<Fragment>
			<TicketsComponent />
		</Fragment>
	);
};

export default withPermission(TicketsRoute, { resource: PERMISSION_RESOURCE.BETSHOP_TICKETS, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
