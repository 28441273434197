import { Fragment, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Tabs } from "antd";

import PendingsComponent from "./pending";
import SettledComponent from "./settled";
import DBCacheCompareComponent from "./dbCacheCompare";

import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { updateLocationHash, getHashValue } from "utils/common";

/** Player View Page Bet History Tab Component */
const BetHistoryComponent = () => {
	const { hash } = useLocation();
	const { t } = useTranslation();

	const [activeKey, setActiveKey] = useState(null);

	/** Update active tab on location hash change */
	useEffect(() => {
		setActiveKey(getHashValue("subtab") || "1");
	}, [hash]);

	return (
		<Fragment>
			<Tabs
				animated={false}
				activeKey={activeKey}
				onChange={(key) => updateLocationHash("subtab=" + key, true)}
				className="sub-tabs"
				destroyInactiveTabPane={true}
				items={[
					hasPermission({ resource: PERMISSION_RESOURCE.PLAYER_BET_HISTORY, action: PERMISSION_ACTION.VIEW })
						? {
								key: "1",
								label: t("pages.dashboard.bets.tabs.pending_betslips"),
								children: <PendingsComponent />
							}
						: null,
					hasPermission({ resource: PERMISSION_RESOURCE.PLAYER_BET_HISTORY, action: PERMISSION_ACTION.VIEW })
						? {
								key: "2",
								label: t("pages.dashboard.bets.tabs.settled_betslips"),
								children: <SettledComponent />
							}
						: null,
					hasPermission({ resource: PERMISSION_RESOURCE.PLAYER_DBCACHECOMPARE, action: PERMISSION_ACTION.VIEW })
						? {
								key: "3",
								label: t("pages.dashboard.bets.cache_compare.db_cache_compare"),
								children: <DBCacheCompareComponent />
							}
						: null
				]}
			/>
		</Fragment>
	);
};

export default BetHistoryComponent;
