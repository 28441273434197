import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Button } from "antd";
import Table from "components/common/table";
import PartnerAddComponent from "./partner-add.component";
import { getUserPartnerAccess, deleteUserPartnerAccess } from "store/actions/dashboard/userManagement/users/partners.action";
import PendingRequestsNote from "components/common/pendingRequestsNote";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { PERMISSION_REQUEST_OBJECT_TYPE, PERMISSION_REQUEST_TYPE } from "constants/permissionRequest.constants";
import userPartnerAccessType from "types/user/partnerAccess.type";
import { doesUserHaveRoleOf } from "utils/auth";
import { USER_ROLE } from "constants/user.constants";

/** User Edit Page Partners Tab Component */
const PartnersComponent = ({ partnerAccess, getUserPartnerAccess, isLoading, deleteUserPartnerAccess, userId }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const isAccessManager = doesUserHaveRoleOf(USER_ROLE.ACCESS_MANAGER);
	const [disableDueToNumberOfRequests, setDisableDueToNumberOfRequests] = useState(false);
	const accessManagerNoteActions = isAccessManager
		? {
				title: t("pages.dashboard.permissions_requests.notes"),
				isPrompt: true,
				isPromptRequired: true,
				promptLabel: t("pages.dashboard.permissions_requests.notes")
			}
		: {};

	/** State to show/hide partner add popup */
	const [showAddPopup, setShowAddPopup] = useState(false);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.partners.general_info.partner_name"),
			dataIndex: "name",
			render: (value, record) => (record.id === "*" ? t("common.all") : value),
			sorter: false
		},
		{
			title: t("pages.dashboard.partners.general_info.id"),
			dataIndex: "id",
			sorter: false
		}
	];

	/** Close partner add popup, after save */
	useEffect(() => {
		setShowAddPopup(false);
	}, [partnerAccess]);

	const drowAddButton = hasPermission({ resource: PERMISSION_RESOURCE.USER_PARTNERS, action: PERMISSION_ACTION.MODIFY }) && routeParams.id !== userId;

	return (
		<div className="dashboard-section-content">
			<div className="vs--flex">
				<PendingRequestsNote
					objectId={routeParams.id}
					actionTypes={[PERMISSION_REQUEST_TYPE.ADD_PARTNER_ACCESS, PERMISSION_REQUEST_TYPE.DELETE_PARTNER_ACCESS]}
					objectType={PERMISSION_REQUEST_OBJECT_TYPE.USER}
					dependencies={[partnerAccess]}
					onCountChange={(count) => {
						setDisableDueToNumberOfRequests(count > 0);
					}}
					style={{
						/* for handle scss/less style loading priority mistake by webpack */
						marginBottom: 24,
						marginRight: "auto"
					}}
				/>
				{drowAddButton && (
					<div className="dashboard-section-buttons tb--ml-auto">
						<Button
							type="primary"
							htmlType="button"
							className="button"
							onClick={() => {
								setShowAddPopup(true);
							}}
							disabled={disableDueToNumberOfRequests || partnerAccess.partners.some((p) => p.id === "*")}
						>
							<span>{t("pages.dashboard.users.add_new_partner")}</span>
						</Button>
					</div>
				)}
			</div>
			<Table
				loading={isLoading}
				columns={columns}
				data={(partnerAccess.mainPartner.id ? [partnerAccess.mainPartner] : []).concat(partnerAccess.partners)}
				loadFn={() => getUserPartnerAccess(routeParams.id)}
				total={(partnerAccess.mainPartner.id ? [partnerAccess.mainPartner] : []).concat(partnerAccess.partners).length}
				actions={
					hasPermission({ resource: PERMISSION_RESOURCE.USER_PARTNERS, action: PERMISSION_ACTION.DELETE }) && routeParams.id !== userId
						? {
								delete: {
									...accessManagerNoteActions,
									messageKey: "user_partner",
									handler: (record, note) => {
										deleteUserPartnerAccess(routeParams.id, record.id, note);
									},
									disabled: (record) => disableDueToNumberOfRequests || record.id === partnerAccess.mainPartner.id
								}
							}
						: {}
				}
				isDisabled={() => false}
				noPagination={true}
				showFullID={true}
			/>
			{showAddPopup ? <PartnerAddComponent onClose={() => setShowAddPopup(false)} /> : null}
		</div>
	);
};

/** PartnersComponent propTypes
 * PropTypes
 */
PartnersComponent.propTypes = {
	/** Redux action to get user partner access */
	getUserPartnerAccess: PropTypes.func,
	/** Redux state property, represents the partners access info of user  */
	partnerAccess: userPartnerAccessType,
	/** Redux state property, is true when loading user partner access */
	isLoading: PropTypes.bool,
	/** Redux action to delete users partner access*/
	deleteUserPartnerAccess: PropTypes.func,
	/** Redux state property, current user id */
	userId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getUserPartnerAccess: (id) => {
		dispatch(getUserPartnerAccess(id));
	},
	deleteUserPartnerAccess: (id, partnerId, note) => {
		dispatch(deleteUserPartnerAccess(id, partnerId, note));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.users.isLoading,
		partnerAccess: state.users.editingUser.partnerAccess,
		userId: state.profile.userInfo.id
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnersComponent);
