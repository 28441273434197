import { buildPathToStaticFolderOfCDN } from "utils/common";

export const containerProps = { style: { width: "100%", height: "100%" } };

export const chartInitialOptions = {
	accessibility: {
		enabled: false
	},
	chart: {
		type: "bar",
		height: 409,
		plotBackgroundImage: [buildPathToStaticFolderOfCDN("admin-images/dashboard/chart-bg.svg")]
	},
	title: {
		text: null
	},

	xAxis: {
		type: "category",
		scrollbar: {
			enabled: true
		},
		labels: {
			style: {
				color: "#4D4D4D",
				fontWeight: 600,
				fontSize: 14
			},
			formatter: function () {
				if (typeof this.value !== "number") {
					return this.value;
				}
			}
		}
	},

	yAxis: {
		labels: {
			style: {
				color: "#4D4D4D",
				fontWeight: 600,
				fontSize: 14
			},
			formatter: function() {
				return (`
					<span title="${this.value}" class="vs--cursor-pointer">${this.value}</span>
				`);
		  },
		  useHTML: true,
		},
		min: 0,
		title: {
			text: null
		}
	},

	tooltip: {
		enabled: false
	},

	plotOptions: {
		bar: {
			dataLabels: {
				enabled: true,
				className: "chart-text",
				style: {
					fontSize: "10px",
					textShadow: false
				}
			}
		},
		series: []
	},
	legend: {
		enabled: false
	},

	credits: {
		enabled: false
	}
};

/*
	Logic for wrapping highcharts object and add wheel event,
	Need to find way to localize this functionality usage result
*/
export function AddEvents(H) {
	function stopEvent(e) {
		if (e) {
			if (e.preventDefault) {
				e.preventDefault();
			}
			if (e.stopPropagation) {
				e.stopPropagation();
			}
			e.cancelBubble = true;
		}
	}
	// class Clone extends H.Chart  {}
	//the wrap
	H.wrap(H.Chart.prototype, "render", function (proceed) {
		var chart = this,
			mapNavigation = chart.options.mapNavigation;

		proceed.call(chart);

		// Add the mousewheel event
		H.addEvent(chart.container, "wheel", function (event) {
			var delta,
				extr,
				step,
				newMin,
				newMax,
				axis = chart.xAxis[0];
			var dataMax = chart.xAxis[0].dataMax,
				dataMin = chart.xAxis[0].dataMin,
				newExtrMin,
				newExtrMax;

			var e = chart.pointer.normalize(event);
			delta = e.detail || -(e.deltaY / 120);
			delta = delta < 0 ? 1 : -1;

			if (chart.isInsidePlot(e.chartX - chart.plotLeft, e.chartY - chart.plotTop)) {
				extr = axis.getExtremes();
				step = ((extr.max - extr.min) / 5) * delta;

				if (extr.min + step <= dataMin) {
					newExtrMin = dataMin;
					newExtrMax = dataMin + (extr.max - extr.min);
				} else if (extr.max + step >= dataMax) {
					newExtrMin = dataMax - (extr.max - extr.min);
					newExtrMax = dataMax;
				} else {
					newExtrMin = extr.min + step;
					newExtrMax = extr.max + step;
				}

				axis.setExtremes(newExtrMin, newExtrMax, true, false);
			}

			stopEvent(event);
			return false;
		});
	});

	return H;
}
