import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { flushCache } from "store/actions/dashboard/developer/postDeploymentActions/postDeploymentActions.action";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Form from "antd/es/form";
import Select from "antd/es/select";
import Button from "antd/es/button";
import Space from "antd/es/space";
import Typography from "antd/es/typography";
import NumericInput from "components/common/numericInput";
import Question from "components/common/question";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { DB_TYPE } from "constants/developerSpacePostDeploymentActions.constants";

const CacheActions = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [formInstance] = Form.useForm();
	const [showFlushCacheConfirmation, setShowFlushCacheConfirmation] = useState(null);
	const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.MODIFY });

	return (
		<Col>
			<h2>{t("pages.dashboard.developerSpacePostDeploymentActions.cache")}</h2>
			{Object.values(DB_TYPE).map((db) => {
				return (
					<Space direction="horizontal" key={db}>
						<Typography.Text>
							{t("pages.dashboard.developerSpacePostDeploymentActions.db")}
							&nbsp;
							{db}
						</Typography.Text>
						<Form
							colon={false}
							form={formInstance}
							requiredMark={false}
							layout="vertical"
							initialValues={{ db }}
							onFinish={(values) => {
								setShowFlushCacheConfirmation(values);
							}}
						>
							<Form.Item style={{ display: "none" }} layout="inline" name="db">
								<NumericInput />
							</Form.Item>
							<Form.Item noStyle={true} layout="inline">
								<Button type="primary" htmlType="submit" disabled={!hasModifyPermission}>
									{t("pages.dashboard.developerSpacePostDeploymentActions.flushCache")}
								</Button>
							</Form.Item>
						</Form>
					</Space>
				);
			})}
			<Row>
				{showFlushCacheConfirmation && (
					<Question
						title={t("pages.dashboard.developerSpacePostDeploymentActions.flushCache")}
						message={t("pages.dashboard.developerSpacePostDeploymentActions.flushCacheConfirmationMessage").replace("%%X%%", showFlushCacheConfirmation.db)}
						onOk={() => {
							dispatch(flushCache({ ...showFlushCacheConfirmation }));
							setShowFlushCacheConfirmation(null);
						}}
						onCancel={() => setShowFlushCacheConfirmation(null)}
						isVisible={true}
					/>
				)}
			</Row>
		</Col>
	);
};
export default CacheActions;
