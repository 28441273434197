import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RetailEventsComponent from './events';
import Breadcrumbs from 'components/common/breadcrumbs';
import { PROJECT_TYPE } from "constants/common.constants";
import { GAME_CATEGORY } from 'constants/game.constants';

/** Events Page Component */
const EventsComponent = () => {

	const { t } = useTranslation();

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs
					items={[
						{ title: t("pages.dashboard.events.events") }
					]}
				/>
				<RetailEventsComponent activeTab={PROJECT_TYPE.RETAIL.toString()} gameCategory={GAME_CATEGORY.SCHEDULED} />
			</div>
		</Fragment>
	);
};

export default EventsComponent;
