import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { Form, Row, Col, Input, Spin } from "antd";
const { Item: FormItem } = Form;

import { numberWithSpaces } from "utils/common";
import { getPartnerCurrency, getPartnerCurrencyDecimalCount } from "utils/currency";

import playerQuickInfoType from "types/player/quickInfo.type";
import currencyType from "types/common/currency.type";

/** Game Edit Page General Info Tab Component */
const GeneralInfoComponent = ({ isLoading, generalInfo, globalPartnerId, currencies }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();

	const totals = generalInfo?.totals ?? {};
	const personalInfo = generalInfo?.personalInfo ?? {};
	const currencyCode = personalInfo.currencyCode ?? "";

	/** Function to get current currency
	 * @function
	 * @returns {string} - currency code
	 * @memberOf GeneralInfoComponent
	 */
	const getCurrentCurrency = () => getPartnerCurrency(currencyCode, currencies, globalPartnerId);

	/** Function to convert value to current currency depend on 'show in' filter
	 * @function
	 * @param {number} value - initial value
	 * @returns {number} - converted value
	 * @memberOf GeneralInfoComponent
	 */
	const getConvertedValue = (value) => numberWithSpaces(value.toFixed(getPartnerCurrencyDecimalCount(currencyCode, currencies, globalPartnerId)));

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form colon={false} form={formInstance} requiredMark={false} layout="vertical">
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h1>{t("pages.dashboard.players.personal_info")}</h1>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.players.id")}>
									<Input disabled={true} value={personalInfo.id ?? ""} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.players.external_id")}>
									<Input disabled={true} value={personalInfo.externalId ?? ""} />
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.players.userName")}>
									<Input disabled={true} value={personalInfo.userName ?? ""} />
								</FormItem>
							</Col>

							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.players.partner")}>
									<Input disabled={true} value={personalInfo.partnerName ?? ""} />
								</FormItem>
							</Col>

							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.players.project")}>
									<Input disabled={true} value={personalInfo.projectName ?? ""} />
								</FormItem>
							</Col>

							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.players.currency")}>
									<Input disabled={true} value={currencyCode} />
								</FormItem>
							</Col>

							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.players.status")}>
									<Input disabled={true} value={personalInfo.state ? t("pages.dashboard.users.status_" + personalInfo.state) : ""} />
								</FormItem>
							</Col>
						</Row>

						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h1 className="dashboard-section-name">{t("pages.dashboard.players.total_value")}</h1>
							</Col>

							<Col xs={24} sm={12} xl={4}>
								<div className="player-info-card" data-type="ggr">
									<div className="player-info-card-top">
										<i className="icon-currency" />
										<span>{t("pages.dashboard.players.ggr")}</span>
									</div>
									<div className="player-info-card-bottom">
										<b>{`${getConvertedValue(totals.ggr ?? 0)} ${getCurrentCurrency()}`}</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={4}>
								<div className="player-info-card" data-type="ggrPrecent">
									<div className="player-info-card-top">
										<i className="icon-currency" />
										<span>{t("pages.dashboard.players.ggr")} %</span>
									</div>
									<div className="player-info-card-bottom">
										<b>{totals.ggrPercent ?? 0} %</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={4}>
								<div className="player-info-card" data-type="ngr">
									<div className="player-info-card-top">
										<i className="icon-currency" />
										<span>{t("pages.dashboard.players.ngr")}</span>
									</div>
									<div className="player-info-card-bottom">
										<b>{`${getConvertedValue(totals.ngr ?? 0)} ${getCurrentCurrency()}`}</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={4}>
								<div className="player-info-card" data-type="win">
									<div className="player-info-card-top">
										<i className="icon-currency" />
										<span>{t("pages.dashboard.players.win_amount")}</span>
									</div>
									<div className="player-info-card-bottom">
										<b>{`${getConvertedValue(totals.won ?? 0)} ${getCurrentCurrency()}`}</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={4}>
								<div className="player-info-card" data-type="canceled">
									<div className="player-info-card-top">
										<i className="icon-currency" />
										<span>{t("pages.dashboard.players.canceled_amount")}</span>
									</div>
									<div className="player-info-card-bottom">
										<b>{`${getConvertedValue(totals.canceled ?? 0)} ${getCurrentCurrency()}`}</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={4}>
								<div className="player-info-card" data-type="turnover">
									<div className="player-info-card-top">
										<i className="icon-turnover" />
										<span>{t("pages.dashboard.players.turnover")}</span>
									</div>
									<div className="player-info-card-bottom">
										<b>{`${getConvertedValue(totals.turnover ?? 0)} ${getCurrentCurrency()}`}</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={4}>
								<div className="player-info-card" data-type="balance">
									<div className="player-info-card-top">
										<i className="icon-currency" />
										<span>{t("pages.dashboard.players.current_balance")}</span>
									</div>
									<div className="player-info-card-bottom">
										<b>{`${getConvertedValue(totals.currentBalance ?? 0)} ${getCurrentCurrency()}`}</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={4}>
								<div className="player-info-card" data-type="betslips">
									<div className="player-info-card-top">
										<i className="icon-betslips" />
										<span>{t("pages.dashboard.players.bets")}</span>
									</div>
									<div className="player-info-card-bottom">
										<b>{totals.betSlipsTotal ?? 0}</b>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</Form>
		</Spin>
	);
};

/** GeneralInfoComponent propTypes
 * PropTypes
 */
GeneralInfoComponent.propTypes = {
	/** Redux state property, is true when general info is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the general info of current viewing player  */
	generalInfo: playerQuickInfoType,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType)
};

const mapStateToProps = (state) => {
	return {
		generalInfo: state.players.viewingPlayer.generalInfo,
		isLoading: state.players.isLoading,
		globalPartnerId: state.partner.globalPartnerId,
		currencies: state.profile.userInfo.currencies
	};
};

export default connect(mapStateToProps, null)(GeneralInfoComponent);
