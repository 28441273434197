import axios from 'axios';
import Methods from 'constants/methods.constants';
import ApiUrls from 'constants/api.constants';

import { setJackpotSaveActionBefore, setJackpotSaveActionFinished } from './bonuses.action';

import {
	SET_JACKPOT_QUICK_INFO
} from 'store/actionTypes';

const setJackpotQuickInfo = data => ({
	type: SET_JACKPOT_QUICK_INFO,
	payload: { data }
})

export const getJackpotQuickInfo = (id) => {
	return dispatch => {
		dispatch(setJackpotSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.JACKPOT_GET_QUICK_VIEW}`,
			method: Methods.GET,
			params: {
				id
			}
		})
			.then(({ data: { value } }) => {
				dispatch(setJackpotQuickInfo(value))
			})
			.finally(() => {
				dispatch(setJackpotSaveActionFinished());
			})
	}
}