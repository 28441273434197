import { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Form, Row, Col, Select, Input, DatePicker } from "antd";
import { setBetshopFilters, getBetshops } from "store/actions/dashboard/retail/betshops/betshops.action";
import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import useAutosuggestion from "hooks/useAutosuggestion";
import { FILTER_STATUS, FILTER_TEST_STATE } from "constants/filter.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
const { Item: FormItem } = Form;
import { DATE_FORMAT } from "constants/date.constants";

/** Betshops Page Filters Component */

const Filters = ({ setBetshopFilters, getBetshops, filters, globalPartnerId }) => {
	const { t } = useTranslation();
	const betshopNames = useAutosuggestion(AUTOSUGGESTION_TYPE.BETSHOP_NAMES, [globalPartnerId], { disableAction: () => !globalPartnerId });

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [
				{ name: "date", keepTime: false },
				{ name: "lastUpdate", keepTime: false }
			]
		};
	}, []);

	const filtersList = [
		{ name: "nameOrId", title: t("pages.dashboard.betshops.betshop_name_or_id") },
		{ name: "code", title: t("pages.dashboard.betshops.code") },
		{
			name: "itemState",
			title: t("common.status"),
			values: [
				{ title: t("common.all"), value: FILTER_STATUS.ALL },
				{ title: t("common.active"), value: FILTER_STATUS.ACTIVE },
				{ title: t("common.inactive"), value: FILTER_STATUS.INACTIVE }
			]
		},
		{
			name: "testState",
			title: t("common.data_type"),
			values: [
				{ title: t("common.all"), value: FILTER_TEST_STATE.ALL },
				{ title: t("common.testing"), value: FILTER_TEST_STATE.TESTING },
				{ title: t("common.not_testing"), value: FILTER_TEST_STATE.NOTTESTING }
			]
		},
		{ name: "date", title: t("common.creation_date") },
		{ name: "lastUpdate", title: t("common.last_update_date") }
	];

	return (
		<FiltersWrapper filtersName="betshops" loadFn={getBetshops} setFiltersFn={setBetshopFilters} filters={filters} formFieldsConfigs={formFieldsConfigs} filtersList={filtersList}>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.betshops.betshop_name_or_id")} name="nameOrId">
							<AutoComplete placeholder={`${t("common.start_typing")} ${t("pages.dashboard.betshops.betshop_name_or_id")}`} items={betshopNames.map((b) => b.value)} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.betshops.code")} name="code">
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.betshops.code")}`} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.status")} name="itemState">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_STATUS.ALL}>{t("common.all")}</Select.Option>
								<Select.Option value={FILTER_STATUS.ACTIVE}>{t("common.active")}</Select.Option>
								<Select.Option value={FILTER_STATUS.INACTIVE}>{t("common.inactive")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.data_type")} name="testState">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_TEST_STATE.ALL}>{t("common.all")}</Select.Option>
								<Select.Option value={FILTER_TEST_STATE.TESTING}>{t("common.testing")}</Select.Option>
								<Select.Option value={FILTER_TEST_STATE.NOTTESTING}>{t("common.not_testing")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.creation_date")} name="date">
							<DatePicker.RangePicker placeholder={[t("common.from"), t("common.to")]} format={DATE_FORMAT} disabledDate={(d) => !d || d.isAfter(moment().endOf("day"))} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.last_update_date")} name="lastUpdate">
							<DatePicker.RangePicker placeholder={[t("common.from"), t("common.to")]} format={DATE_FORMAT} disabledDate={(d) => !d || d.isAfter(moment().endOf("day"))} />
						</FormItem>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get betshops */
	getBetshops: PropTypes.func,
	/** Redux action to set betshops filters */
	setBetshopFilters: PropTypes.func,
	/** Redux state property, betshops filters */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getBetshops: () => {
		dispatch(getBetshops());
	},
	setBetshopFilters: (filters) => {
		dispatch(setBetshopFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.betshops.filters,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
