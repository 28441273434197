import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";

import { Tabs } from "antd";

import Breadcrumbs from "components/common/breadcrumbs";

import ActivityComponent from "../view/sections/activity";
import SessionsComponent from "../view/sections/sessions";

import { getBetshopGeneralInfo } from "store/actions/dashboard/retail/betshops/generalInfo.action";

import Paths from "constants/path.constants";

import { updateLocationHash, getHashValue } from "utils/common";

/** Betshop View Component */
const BetshopViewComponent = ({ getBetshopGeneralInfo, betshopName }) => {
	const routeParams = useParams();
	const { hash } = useLocation();
	const { t } = useTranslation();

	const [activeKey, setActiveKey] = useState(null);

	/** Load betshop general info to get name */
	useEffect(() => {
		routeParams.id && getBetshopGeneralInfo(routeParams.id);
	}, []);

	/** Update active tab on location hash change */
	useEffect(() => {
		setActiveKey(getHashValue("tab") || "1");
	}, [hash]);

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.betshops"), path: Paths.DASHBOARD_RETAIL_BETSHOPS }, { title: `${betshopName} ${activeKey === "1" ? t("pages.dashboard.betshops.activity") : t("pages.dashboard.betshops.sessions")}` }]} />
				<Tabs
					animated={false}
					activeKey={activeKey}
					onChange={(key) => updateLocationHash("tab=" + key)}
					destroyInactiveTabPane={true}
					items={[
						{
							key: "1",
							label: t("pages.dashboard.betshops.viewTabs.transactions_history"),
							children: <ActivityComponent />
						},
						{
							key: "2",
							label: t("pages.dashboard.betshops.viewTabs.cashiers_sessions"),
							children: <SessionsComponent />
						}
					]}
				/>
			</div>
		</Fragment>
	);
};

/** BetshopViewComponent propTypes
 * PropTypes
 */
BetshopViewComponent.propTypes = {
	/** Redux state property, current betshop name */
	betshopName: PropTypes.string,
	/** Redux action to get betshop general info */
	getBetshopGeneralInfo: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getBetshopGeneralInfo: (id) => {
		dispatch(getBetshopGeneralInfo(id));
	}
});

const mapStateToProps = (state) => {
	return {
		betshopName: state.betshops.editingBetshop.generalInfo.name
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BetshopViewComponent);
