import PropTypes from "prop-types";

import { PRINT_REQUEST_STATE, BETSLIP_MODES, BET_STATE, PRINT_TYPE } from "constants/bet.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	requestTime: PropTypes.string,
	state: PropTypes.oneOf(Object.values(PRINT_REQUEST_STATE)),
	projectId: PropTypes.string,
	projectName: PropTypes.string,
	userId: PropTypes.string,
	userName: PropTypes.string,
	betSlipId: PropTypes.number,
	betType: PropTypes.oneOf(Object.values(BETSLIP_MODES)),
	betStatus: PropTypes.oneOf(Object.values(BET_STATE)),
	amount: PropTypes.number,
	possibleWin: PropTypes.number,
	winning: PropTypes.number,
	betTime: PropTypes.string,
	printType: PropTypes.oneOf(Object.values(PRINT_TYPE))
});
