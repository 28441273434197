import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { setPermissionGroupsActionBefore, setPermissionGroupsActionFinished, setPermissionGroupSaveActionBefore, setPermissionGroupSaveActionFinished } from "./permissionGroups.action";
import { getPermissionGroups } from "./myGroups.action";

import { PERMISSION_GROUPS_SET_GENERAL_INFO, PERMISSION_GROUPS_SET_QUICK_VIEW_USERS, PERMISSION_GROUPS_SET_QUICK_VIEW_USERS_SORTING } from "store/actionTypes";

const setPermissionGroupGeneralInfo = (info) => ({
	type: PERMISSION_GROUPS_SET_GENERAL_INFO,
	payload: { info }
});

const setPermissionGroupUsers = (data) => ({
	type: PERMISSION_GROUPS_SET_QUICK_VIEW_USERS,
	payload: { data }
});

export const setPermissionGroupQuickViewUsersSorting = (sorting) => ({
	type: PERMISSION_GROUPS_SET_QUICK_VIEW_USERS_SORTING,
	payload: { sorting }
});

export const getPermissionGroupGeneralInfo = (id) => {
	return (dispatch) => {
		dispatch(setPermissionGroupsActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.PERMISSION_GROUP_GET_PERMISSIONS}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: info } }) => {
				dispatch(setPermissionGroupGeneralInfo(info));
				dispatch(setPermissionGroupsActionFinished());
			})
			.catch((ex) => {
				dispatch(setPermissionGroupsActionFinished());
			});
	};
};

export const savePermissionGroupGeneralInfo = (info) => {
	return (dispatch) => {
		dispatch(setPermissionGroupSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.PERMISSION_GROUP_SAVE_PERMISSIONS}`,
			method: Methods.POST,
			data: {
				...info
			}
		})
			.then(({ data: { message: msg, status, value: info } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setPermissionGroupGeneralInfo(info));
				}
				dispatch(setPermissionGroupSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setPermissionGroupSaveActionFinished());
			});
	};
};

export const addPermissionGroupQuickViewUsers = (data, onClose) => {
	return (dispatch) => {
		dispatch(setPermissionGroupsActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.PERMISSION_GROUP_ADD_USERS}`,
			method: Methods.POST,
			data
		})
			.then(({ data: { value } }) => {
				dispatch(getPermissionGroupQuickViewUsers(data.groupId));
				dispatch(getPermissionGroups(false));
				onClose();
			})
			.catch((ex) => {
				dispatch(setPermissionGroupsActionFinished());
			});
	};
};

export const getPermissionGroupQuickViewUsers = (id, fromFirstPage) => {
	return (dispatch, getState) => {
		const { sorting } = getState().permissionGroups.editingPermissionGroup.usersQuickView;

		dispatch(setPermissionGroupsActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.PERMISSION_GROUP_GET_USERS}`,
			method: Methods.GET,
			params: { id, ...sorting, page: fromFirstPage ? 1 : sorting.page || 1 }
		})
			.then(({ data: { value: info } }) => {
				dispatch(setPermissionGroupUsers(info));
				if (fromFirstPage) dispatch(setPermissionGroupQuickViewUsersSorting({ ...sorting, page: 1 }));
				dispatch(setPermissionGroupsActionFinished());
			})
			.catch((ex) => {
				dispatch(setPermissionGroupsActionFinished());
			});
	};
};

export const deletePermissionGroupQuickViewUser = ({ id, groupId, note, sorting }) => {
	return (dispatch) => {
		dispatch(setPermissionGroupsActionBefore());
		dispatch(setPermissionGroupQuickViewUsersSorting(sorting));

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_DELETE_PERMISSION_GROUP}`,
			method: Methods.DELETE,
			data: { id, groupId, note }
		})
			.then(() => {
				dispatch(getPermissionGroupQuickViewUsers(groupId));
				dispatch(getPermissionGroups(false));
			})
			.catch((ex) => {
				dispatch(setPermissionGroupsActionFinished());
			});
	};
};
