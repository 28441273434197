import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import {
	BIG_MULTIPLIERS_CONFIGURATIONS_ACTION_BEFORE,
	BIG_MULTIPLIERS_CONFIGURATIONS_ACTION_FINISH,
	BIG_MULTIPLIERS_CONFIGURATIONS_SAVE_ACTION_BEFORE,
	BIG_MULTIPLIERS_CONFIGURATIONS_SAVE_ACTION_FINISH,
	BIG_MULTIPLIERS_HISTORY_BEFORE,
	BIG_MULTIPLIERS_HISTORY_FINISH,
	SET_BIG_MULTIPLIERS_CONFIGURATIONS,
	SET_BIG_MULTIPLIERS_HISTORY,
	SET_BIG_MULTIPLIERS_HISTORY_FILTERS,
	SET_BIG_MULTIPLIERS_HISTORY_SORTING
} from "store/actionTypes";

export const setBigMultipliersConfigurationsActionBefore = () => ({
	type: BIG_MULTIPLIERS_CONFIGURATIONS_ACTION_BEFORE
});

export const setBigMultipliersConfigurationsActionFinish = () => ({
	type: BIG_MULTIPLIERS_CONFIGURATIONS_ACTION_FINISH
});

export const setBigMultipliersConfigurationsSaveActionBefore = () => ({
	type: BIG_MULTIPLIERS_CONFIGURATIONS_SAVE_ACTION_BEFORE
})

export const setBigMultipliersConfigurationsSaveActionFinish = () => ({
	type: BIG_MULTIPLIERS_CONFIGURATIONS_SAVE_ACTION_FINISH
})

export const setBigMultipliersConfigurationsData = (data) => ({
	type: SET_BIG_MULTIPLIERS_CONFIGURATIONS,
	payload: { data }
})

export const getBigMultipliersConfigurationsData = () => {
	return (dispatch, getState) => {
		dispatch(setBigMultipliersConfigurationsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_RISK_MANAGEMENT_BIG_MULTIPLIERS_CONFIGURATIONS}`,
			method: Methods.GET,
		})
			.then(({ data: { value: data } }) => {
				dispatch(setBigMultipliersConfigurationsData(data));
			})
			.finally(() => {
				dispatch(setBigMultipliersConfigurationsActionFinish());
			});
	};
};

export const saveBigMultipliersConfigurationsData = (data) => {
	return (dispatch, getState) => {
		dispatch(setBigMultipliersConfigurationsSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_RISK_MANAGEMENT_BIG_MULTIPLIERS_CONFIGURATIONS}`,
			method: Methods.POST,
			data: { ...data }
		})
			.then(({ data: { value, message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
				}
				dispatch(setBigMultipliersConfigurationsData(value));
			})
			.finally(() => {
				dispatch(setBigMultipliersConfigurationsSaveActionFinish());
			});
	};
};

export const setBigMultipliersHistoryBefore = () => ({
	type: BIG_MULTIPLIERS_HISTORY_BEFORE
});

export const setBigMultipliersHistoryFinish = () => ({
	type: BIG_MULTIPLIERS_HISTORY_FINISH
});


export const setBigMultipliersHistoryFilters = (filters) => ({
	type: SET_BIG_MULTIPLIERS_HISTORY_FILTERS,
	payload: { filters }
})

export const setBigMultipliersHistorySorting = (sorting) => ({
	type: SET_BIG_MULTIPLIERS_HISTORY_SORTING,
	payload: { sorting }
});

export const setBigMultipliersHistory = (payload) => ({
	type: SET_BIG_MULTIPLIERS_HISTORY,
	payload: payload
});

export const getBigMultipliersHistory = (fromFirstPage) => {
	return (dispatch, getState) => {
		dispatch(setBigMultipliersHistoryBefore());
		const historyState = getState().riskManagement.bigMultipliers.history;
		const { partnerName, ...filters} = historyState.filters;

		const params = {
			...historyState.sorting,
			...filters,
			page: fromFirstPage ? 1 : historyState.sorting.page
		};
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_RISK_MANAGEMENT_BIG_MULTIPLIERS_HISTORY}`,
			method: Methods.GET,
			params: params
		})
			.then(({ data: { value } }) => {
				dispatch(setBigMultipliersHistory({ total: value.item1, data: value.item2 }));
				if (fromFirstPage) {
					dispatch(setBigMultipliersHistorySorting({ ...getState().riskManagement.bigMultipliers.history.sorting, page: 1 }));
				}
			})
			.finally(() => {
				dispatch(setBigMultipliersHistoryFinish());
			});
	};
}