import axios from "axios";
import moment from "moment";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { SET_PLAYER_PENDING_BETSLIPS, SET_PLAYER_PENDING_BETSLIPS_SORTING, SET_PLAYER_PENDING_BETSLIPS_FILTERS, SET_PLAYER_SETTLED_BETSLIPS, SET_PLAYER_SETTLED_BETSLIPS_SORTING, SET_PLAYER_SETTLED_BETSLIPS_FILTERS } from "store/actionTypes";

import { PROJECT_TYPE } from "constants/common.constants";
import { FILTER_BET_STATE } from "constants/filter.constants";

import { setPlayersActionBefore, setPlayersActionFinished } from "./players.action";

import { monthAgo } from "utils/dateTime";
import { flagsToBinary } from "utils/common";
import { DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

const setPlayerPendingBets = (data) => ({
	type: SET_PLAYER_PENDING_BETSLIPS,
	payload: { data }
});

export const setPlayerPendingBetsSorting = (sorting) => ({
	type: SET_PLAYER_PENDING_BETSLIPS_SORTING,
	payload: { sorting }
});

export const setPlayerPendingBetsFilters = (filters) => ({
	type: SET_PLAYER_PENDING_BETSLIPS_FILTERS,
	payload: { filters }
});

export const getPlayerPendingBets = (id) => {
	return (dispatch, getState) => {
		dispatch(setPlayersActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PLAYER_BET_HISTORY}`,
			method: Methods.GET,
			params: {
				...getState().players.viewingPlayer.betHistory.pendings.sorting,
				...getState().players.viewingPlayer.betHistory.pendings.filters,
				from: moment(monthAgo(1), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				projectType: PROJECT_TYPE.ONLINE,
				userNameOrId: id,
				isPending: true
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setPlayerPendingBets(data));
				dispatch(setPlayersActionFinished());
			})
			.catch(() => {
				dispatch(setPlayersActionFinished());
			});
	};
};

export const cancelPlayerPendingBet = (id, betId, reason, playerId) => {
	return (dispatch) => {
		dispatch(setPlayersActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.PLAYER_CANCEL_BET}`,
			method: Methods.POST,
			data: { id, betId, reason: reason }
		})
			.then(() => {
				dispatch(getPlayerPendingBets(playerId));
				dispatch(setPlayersActionFinished());
			})
			.catch(() => {
				dispatch(setPlayersActionFinished());
			});
	};
};

export const cancelPlayerPendingBetslip = (id, reason, playerId) => {
	return (dispatch) => {
		dispatch(setPlayersActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.PLAYER_CANCEL_BETSLIP}`,
			method: Methods.POST,
			data: { id, reason: reason }
		})
			.then(() => {
				dispatch(getPlayerPendingBets(playerId));
				dispatch(setPlayersActionFinished());
			})
			.catch(() => {
				dispatch(setPlayersActionFinished());
			});
	};
};

const setPlayerSettledBets = (data) => ({
	type: SET_PLAYER_SETTLED_BETSLIPS,
	payload: { data }
});

export const setPlayerSettledBetsSorting = (sorting) => ({
	type: SET_PLAYER_SETTLED_BETSLIPS_SORTING,
	payload: { sorting }
});

export const setPlayerSettledBetsFilters = (filters) => ({
	type: SET_PLAYER_SETTLED_BETSLIPS_FILTERS,
	payload: { filters }
});

export const getPlayerSettledBets = (id) => {
	return (dispatch, getState) => {
		dispatch(setPlayersActionBefore);

		const filters = getState().players.viewingPlayer.betHistory.settled.filters;
		const sorting = getState().players.viewingPlayer.betHistory.settled.sorting;

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PLAYER_BET_HISTORY}`,
			method: Methods.GET,
			params: {
				...sorting,
				...filters,
				status: filters.status.length === 1 && filters.status[0] === FILTER_BET_STATE.ALL ? null : flagsToBinary(filters.status),
				projectType: PROJECT_TYPE.ONLINE,
				userNameOrId: id,
				isPending: false
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setPlayerSettledBets(data));
				dispatch(setPlayersActionFinished());
			})
			.catch(() => {
				dispatch(setPlayersActionFinished());
			});
	};
};
