import { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Select, Input } from "antd";
import { getErrors, setErrorFilters } from "store/actions/dashboard/developer/errors/errors.action";
import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import SearchableSelect from "components/common/searchableSelect";
import useAutosuggestion from "hooks/useAutosuggestion";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
import { MONTHS, YEARS } from "constants/common.constants";
const { Item: FormItem } = Form;

/** Errors LPage Filters Component */
const Filters = ({ setErrorFilters, getErrors, filters, globalPartnerId }) => {
	const { t } = useTranslation();
	const userNames = useAutosuggestion(AUTOSUGGESTION_TYPE.USER_NAMES, [globalPartnerId], { disableAction: () => !globalPartnerId });
	const controllers = useAutosuggestion(AUTOSUGGESTION_TYPE.CONTROLLERS, [globalPartnerId], { disableAction: () => !globalPartnerId });

	const filtersList = [
		{ name: "userId", title: t("pages.dashboard.users.user_name_or_ID") },
		{ name: "controller", title: t("pages.dashboard.logs.controller") },
		{ name: "action", title: t("pages.dashboard.logs.action") },
		{
			name: "month",
			title: t("common.month"),
			values: Object.values(MONTHS).map((m) => ({ title: t("months.month_" + m), value: m }))
		},
		{ name: "year", title: t("common.year"), values: YEARS.map((y) => ({ title: y, value: y })) },
		{ name: "excludeController", title: t("pages.dashboard.logs.excludeController"), values: [] },
		{ name: "excludeAction", title: t("pages.dashboard.logs.excludeAction") }
	];

	return (
		<FiltersWrapper filtersName="errors" loadFn={() => getErrors()} setFiltersFn={setErrorFilters} filters={filters} filtersList={filtersList}>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.users.user_name_or_ID")} name="userId">
							<AutoComplete placeholder={`${t("common.start_typing")} ${t("pages.dashboard.logs.user_name_or_ID")}`} items={userNames.map((k) => k.value)} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.logs.controller")} name="controller">
							<AutoComplete placeholder={`${t("common.start_typing")} ${t("pages.dashboard.logs.controller")}`} items={controllers.map((k) => k.value)} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.logs.action")} name="action">
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.logs.action")}`} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.month")} name="month">
							<Select suffixIcon={<i className="icon-down" />}>
								{Object.values(MONTHS).map((m) => (
									<Select.Option value={m} key={m}>
										{t("months.month_" + m)}
									</Select.Option>
								))}
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.year")} name="year">
							<Select suffixIcon={<i className="icon-down" />}>
								{YEARS.map((y) => (
									<Select.Option value={y} key={y}>
										{y}
									</Select.Option>
								))}
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.logs.excludeController")} name="excludeController">
							<SearchableSelect placeholder={`${t("common.start_typing")} ${t("pages.dashboard.logs.excludeController")}`} mode="multiple" valueProp={(item) => item.key} textProp={(item) => item.value} renderText={(item) => item.value} items={controllers} maxTagCount={1} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.logs.excludeAction")} name="excludeAction">
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.logs.excludeAction")}`} />
						</FormItem>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get errors */
	getErrors: PropTypes.func,
	/** Redux action to set errors filters */
	setErrorFilters: PropTypes.func,
	/** Redux state property, errors filters */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getErrors: () => {
		dispatch(getErrors());
	},
	setErrorFilters: (filters) => {
		dispatch(setErrorFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.errors.filters,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
