import { Fragment } from "react";

import PartnersViewComponent from "components/dashboard/partners/view/partner-view.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const PartnersViewRoute = () => {
	return (
		<Fragment>
			<PartnersViewComponent />
		</Fragment>
	);
};

export default withPermission(PartnersViewRoute, { resource: PERMISSION_RESOURCE.PARTNER_TRANSACTIONS_HISTORY, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD_PARTNERS);
