import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tabs } from "antd";
import Breadcrumbs from "components/common/breadcrumbs";
import GeneralInfoComponent from "../edit/sections/general";
import Paths from "constants/path.constants";
import { getStreamsGeneralInfo } from "store/actions/dashboard/streams/generalInfo.action";
import { updateLocationHash, getHashValue } from "utils/common";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

/** Stream Edit Page Component */
const StreamsEditComponent = ({ streamsName, getStreamsGeneralInfo }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const [unsavedTabs, setUnsavedTabs] = useState([]);
	const [activeKey, setActiveKey] = useState("1");

	/** Set stream info */
	useEffect(() => {
		getStreamsGeneralInfo(routeParams.id);
	}, [routeParams.id]);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf StreamsEditComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf StreamsEditComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	/** Set default tab */
	useEffect(() => {
		getHashValue("tab") && setActiveKey(getHashValue("tab"));
	}, []);

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.streams"), path: Paths.DASHBOARD_STREAMS }, { title: streamsName }]} />
				<Tabs
					animated={false}
					activeKey={activeKey}
					onChange={(key) => {
						setActiveKey(key);
						updateLocationHash("tab=" + key);
					}}
					items={[
						hasPermission({ resource: PERMISSION_RESOURCE.STREAMS, action: PERMISSION_ACTION.VIEW })
							? {
									key: "1",
									label: <span className={tabClassName("1")}>{t("pages.dashboard.partners.tabs.general_info")}</span>,
									children: <GeneralInfoComponent onTabChange={(status) => changeTabSavedStatus(status, "1")} />
								}
							: null
					]}
				/>
			</div>
		</Fragment>
	);
};

/** StreamsEditComponent propTypes
 * PropTypes
 */
StreamsEditComponent.propTypes = {
	/** Redux state, represents the name of current editing stream  */
	streamsName: PropTypes.string,
	/** Redux action to get stream General info */
	getStreamsGeneralInfo: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		streamsName: state.streams.generalInfo.name
	};
};

const mapDispatchToProps = (dispatch) => ({
	getStreamsGeneralInfo: (id) => {
		dispatch(getStreamsGeneralInfo(id));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(StreamsEditComponent);
