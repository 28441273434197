import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { PARTNER_WALLET_BEFORE, PARTNER_WALLET_FINISH, PARTNER_WALLET_SAVE_BEFORE, PARTNER_WALLET_SAVE_FINISH, PARTNER_SET_WALLET, PARTNER_UPDATE_WALLET } from "../../../actionTypes";

const setWalletActionBefore = () => ({
	type: PARTNER_WALLET_BEFORE
});

const setWalletActionFinished = () => ({
	type: PARTNER_WALLET_FINISH
});

const setWalletSaveActionBefore = () => ({
	type: PARTNER_WALLET_SAVE_BEFORE
});

const setWalletSaveActionFinished = () => ({
	type: PARTNER_WALLET_SAVE_FINISH
});

const setPartnerWallet = (wallet) => ({
	type: PARTNER_SET_WALLET,
	payload: { wallet }
});

const updatePartnerWallet = (balance, id) => ({
	type: PARTNER_UPDATE_WALLET,
	payload: { id, balance }
});

export const getPartnerWallet = () => {
	return (dispatch) => {
		dispatch(setWalletActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_WALLET}`,
			method: Methods.GET
		})
			.then(({ data: { value: wallet } }) => {
				dispatch(setPartnerWallet(wallet));
				dispatch(setWalletActionFinished());
			})
			.catch((ex) => {
				dispatch(setWalletActionFinished());
			});
	};
};

export const savePartnerWallet = (wallet) => {
	return (dispatch) => {
		dispatch(setWalletSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_PARTNER_WALLET}`,
			method: Methods.POST,
			data: {
				...wallet
			},
			params: {
				SelectedPartnerId: wallet.SelectedPartnerId
			}
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					dispatch(updatePartnerWallet(data.value.balance, wallet.SelectedPartnerId));
				}
				dispatch(setWalletSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setWalletSaveActionFinished());
			});
	};
};
