import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Tabs } from "antd";
import Breadcrumbs from "components/common/breadcrumbs";
import GeneralComponent from "../edit/sections/general";
import GamesComponent from "../edit/sections/games";

import { getBoxGeneralInfo } from "store/actions/dashboard/retail/boxes/generalInfo.action";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { hasPermission } from "utils/permissions";
import { updateLocationHash, getHashValue } from "utils/common";

import Paths from "constants/path.constants";

/** Box Edit Component */
const BoxEditComponent = ({ boxName, getBoxGeneralInfo }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf BoxEditComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf BoxEditComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	/** Load box general info */
	useEffect(() => {
		if (routeParams.id) getBoxGeneralInfo(routeParams.id);
	}, []);

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.boxes.boxes"), path: Paths.DASHBOARD_BOXES }, { title: `${t("common.edit")} ${boxName}` }]} />
				<Tabs
					animated={false}
					defaultActiveKey={getHashValue("tab")}
					onChange={(key) => updateLocationHash("tab=" + key)}
					items={[
						{
							key: "1",
							label: <span className={tabClassName("1")}>{t("pages.dashboard.boxes.tabs.general")}</span>,
							children: <GeneralComponent onTabChange={(status) => changeTabSavedStatus(status, "1")} />
						},
						hasPermission({ resource: PERMISSION_RESOURCE.BOX_GAMES, action: PERMISSION_ACTION.VIEW })
							? {
									key: "2",
									label: <span className={tabClassName("2")}>{t("pages.dashboard.boxes.tabs.games")}</span>,
									children: <GamesComponent />
								}
							: null
					]}
				/>
			</div>
		</Fragment>
	);
};

/** BoxEditComponent propTypes
 * PropTypes
 */
BoxEditComponent.propTypes = {
	/** Redux state, represents the name of current editing box  */
	boxName: PropTypes.string,
	/** Redux action to get box General info */
	getBoxGeneralInfo: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		boxName: state.boxes.editingBox.generalInfo.name
	};
};

const mapDispatchToProps = (dispatch) => ({
	getBoxGeneralInfo: (id) => {
		dispatch(getBoxGeneralInfo(id));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BoxEditComponent);
