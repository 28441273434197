import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Form, Col, Row, Modal, Input, Checkbox, Tooltip } from "antd";
const { Item: FormItem } = Form;

import SearchableSelect from "components/common/searchableSelect";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { createAccessManager, getAccessManagers } from "store/actions/dashboard/userManagement/accessManagers/accessManagers.action";
import Paths from "constants/path.constants";
import { EMAIL_REGEX } from "constants/regex.constants";
import { POPUP_SIZE } from "constants/common.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import { hasPermission } from "utils/permissions";

import partnerType from "types/partner/partner.type";

/** Access Manager Creating Popup Component */
const AccessManagerAddComponent = ({ isSaving, createAccessManager, getAccessManagers, globalPartnerId, globalPartners, onClose }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;

	/** Fires when form submitted
	 * @function
	 * @memberOf AccessManagerAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				createAccessManager({ ...data }, ({ id }) => {
					if (hasPermission({ resource: PERMISSION_RESOURCE.ACCESS_MANAGER, action: PERMISSION_ACTION.MODIFY })) {
						navigate(Paths.ACCESS_MANAGERS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, `${id}`));
					} else {
						getAccessManagers();
						onClose();
					}
				});
			})
			.catch(Function.prototype);
	};

	/** Detect if current partner is test partner
	 * @function
	 * @returns {boolean}
	 * @memberOf AccessManagerAddComponent
	 */
	const isCurrentPartnerTest = (globalPartners.find((p) => p.id === globalPartnerId))?.isTesting;

	return (
		<Modal open={true} title={t("pages.dashboard.access_managers.create_access_manager")} cancelText={t("common.cancel")} okText={t("common.create")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.MIDDLE} maskClosable={false} closable={false} okButtonProps={{ loading: isSaving }} centered>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					firstName: "",
					lastName: "",
					userName: "",
					email: "",
					partnerIds: [],
					isTesting: isCurrentPartnerTest
				}}
			>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12}>
						<FormItem
							label={`${t("pages.dashboard.users.first_name")} *`}
							name="firstName"
							rules={[
								{ required: true, whitespace: true, message: t("validation.field_required") },
								{ max: 30, message: t("validation.field_invalid") },
								{ min: 2, message: t("validation.field_invalid") }
							]}
						>
							<Input minLength={2} maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.users.first_name")}`} autoFocus={true} autoComplete="off" />
						</FormItem>
					</Col>
					<Col xs={24} sm={12}>
						<FormItem
							label={`${t("pages.dashboard.users.last_name")} *`}
							name="lastName"
							rules={[
								{ required: true, whitespace: true, message: t("validation.field_required") },
								{ max: 30, message: t("validation.field_invalid") },
								{ min: 2, message: t("validation.field_invalid") }
							]}
						>
							<Input minLength={2} maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.users.last_name")}`} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12}>
						<FormItem
							label={`${t("pages.dashboard.users.email")} *`}
							name="email"
							rules={[
								{ pattern: EMAIL_REGEX, message: t("validation.email_format") },
								{ max: 50, message: t("validation.field_invalid") },
								{ min: 6, message: t("validation.field_invalid") }
							]}
						>
							<Input minLength={6} maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.users.email")}`} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12}>
						<FormItem
							label={`${t("pages.dashboard.users.username")} *`}
							name="userName"
							rules={[
								{ required: true, whitespace: true, message: t("validation.field_required") },
								{ max: 30, message: t("validation.field_invalid") },
								{ min: 5, message: t("validation.field_invalid") }
							]}
						>
							<Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />} minLength={6} maxLength={30} placeholder={`${t("common.enter")} ${t("pages.dashboard.users.username")}`} autoComplete="new-username" />
						</FormItem>
					</Col>
					<Col span={24}>
						<div className="inline-form-checkbox">
							<label>{t("pages.dashboard.users.is_test_access_manager")}</label>
							<FormItem className="inline-form-item-control" name="isTesting" valuePropName="checked">
								<Checkbox disabled={isCurrentPartnerTest} />
							</FormItem>
						</div>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** AccessManagerAddComponent propTypes
 * PropTypes
 */
AccessManagerAddComponent.propTypes = {
	/** Redux state property, is true when creating access manager request is in process */
	isSaving: PropTypes.bool,
	/** Redux action to create access manager */
	createAccessManager: PropTypes.func,
	/** Redux action to get access managers */
	getAccessManagers: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, represents the array of global partners  */
	globalPartners: PropTypes.arrayOf(partnerType),
	/** Fires on popup close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	createAccessManager: (user, onSuccess) => {
		dispatch(createAccessManager(user, onSuccess));
	},
	getAccessManagers: () => {
		dispatch(getAccessManagers(true));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.accessManagers.isSaving,
		globalPartnerId: state.partner.globalPartnerId,
		globalPartners: state.partner.globalPartners
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessManagerAddComponent);
