import PropTypes from "prop-types";

export default PropTypes.shape({
	code: PropTypes.string,
	name: PropTypes.string,
	symbol: PropTypes.string,
	favoriteBets: PropTypes.arrayOf(PropTypes.string),
	decimalCount: PropTypes.number,
	enabled: PropTypes.bool,
	isDefault: PropTypes.bool,
	onlineBetLimits: PropTypes.shape({
		singleMin: PropTypes.number,
		singleMax: PropTypes.number,
		multiMin: PropTypes.number,
		multiMax: PropTypes.number,
		singlePossibleWinMax: PropTypes.number,
		multiPossibleWinMax: PropTypes.number
	}),
	retailBetLimits: PropTypes.shape({
		singleMin: PropTypes.number,
		singleMax: PropTypes.number,
		multiMin: PropTypes.number,
		multiMax: PropTypes.number,
		singlePossibleWinMax: PropTypes.number,
		multiPossibleWinMax: PropTypes.number
	})
});
