import { Fragment } from "react";

import BetshopViewComponent from "components/dashboard/retail/betshops/view/betshop-view.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const BetshopViewRoute = () => {
	return (
		<Fragment>
			<BetshopViewComponent />
		</Fragment>
	);
};

export default withPermission(BetshopViewRoute, { resource: PERMISSION_RESOURCE.BETSHOP_ACTIVITY, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
