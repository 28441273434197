import PropTypes from "prop-types";
import { connect } from "react-redux";
import PendingBetslipsFilters from "../../common/pending/filters.component";
import { PROJECT_TYPE } from "constants/common.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
import { GAME_CATEGORY } from "constants/game.constants";

/** Online Pending Betlsips Page Filters Component */
const Filters = ({ filters, gameCategory, showBetsBySeparate = true }) => {
	return <PendingBetslipsFilters projectNamesAutosuggestionType={AUTOSUGGESTION_TYPE.API_KEY_NAMES} filters={filters} projectType={PROJECT_TYPE.ONLINE} gameCategory={gameCategory} showBetsBySeparate={showBetsBySeparate} />;
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux state property, online pending betslips filters */
	filters: PropTypes.object,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY)),
	showBetsBySeparate: PropTypes.bool
};

const mapDispatchToProps = null;

const mapStateToProps = (state) => {
	return {
		filters: state.betslips.pendings.online.filters
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
