import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import BalanceManagement from "components/dashboard/common/balanceManagment";

import { getPartnerWallet, savePartnerWallet } from "store/actions/dashboard/partners/wallet.action";

import { PARTNER_WALLET_TYPE } from "constants/partner.constants";

import walletType from "types/partner/wallet.type";
import currencyType from "types/common/currency.type";

import { convertCurrency } from "utils/currency";

/** Partner Wallet Adjustment Popup Component */
const PartnerWalletAdjustmentComponent = ({ isSaving, savePartnerWallet, getPartnerWallet, partner, wallet, onClose, currency, currencies }) => {
	const { t } = useTranslation();

	useEffect(() => {
		getPartnerWallet();
	}, []);

	return (
		<BalanceManagement
			initialBalance={partner.balance}
			isSaving={isSaving}
			saveWallet={savePartnerWallet}
			additionalData={{ type: PARTNER_WALLET_TYPE.REGULAR, SelectedPartnerId: partner.partnerId }}
			popupTitle={t("pages.dashboard.partners.adjust_partner_wallet")}
			onClose={onClose}
			maxBalance={Number(convertCurrency(wallet.maxBalance, currency.code, currencies, partner.partnerId))}
			minBalance={Number(convertCurrency(wallet.minBalance, currency.code, currencies, partner.partnerId))}
			partnerId={partner.partnerId}
		/>
	);
};

/** PartnerWalletAdjustmentComponent propTypes
 * PropTypes
 */
PartnerWalletAdjustmentComponent.propTypes = {
	/** Redux state property, is true when saving partner balance */
	isSaving: PropTypes.bool,
	/** Redux action to get partner balance */
	getPartnerWallet: PropTypes.func,
	/** Redux action to save partner balance */
	savePartnerWallet: PropTypes.func,
	/** Editing partner ID */
	partner: PropTypes.shape({
		partnerId: PropTypes.string,
		balance: PropTypes.number
	}),
	/** Redux action, wallet info */
	wallet: walletType,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Redux state property, current user currency */
	currency: currencyType,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType)
};

const mapDispatchToProps = (dispatch) => ({
	savePartnerWallet: (wallet) => {
		dispatch(savePartnerWallet(wallet));
	},
	getPartnerWallet: () => {
		dispatch(getPartnerWallet());
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.partners.wallet.isSaving,
		wallet: state.partners.wallet.wallet,
		currency: state.profile.userInfo.currency,
		currencies: state.profile.userInfo.currencies
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerWalletAdjustmentComponent);
