import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { Button, Col, Form, Row, Select, Space, Spin, Switch, Tooltip } from "antd";

import { getPartnerSecurityAuthenticationSettings, savePartnerSecurityAuthenticationSettings } from "store/actions/dashboard/partners/security.action";

import { AUTO_UNLOCK_COUNT_OPTIONS, AUTO_UNLOCK_TIME_OPTIONS, FAILED_LOGIN_ATTEMPTS_OPTIONS, FIELDS_NAME, FORM_DEFAULT_VALUES } from "constants/partner.constants";

import authenticationSettingsType from "types/partner/authenticationSettings.type";

// eslint-disable-next-line react/prop-types
const renderLabelWithInfoIcon = ({ labelText, tooltipText }) => {
	return (
		<>
			<span>{labelText}</span>
			<Tooltip title={<span dangerouslySetInnerHTML={{ __html: tooltipText }}></span>}>
				<i className="icon-info" style={{ fontSize: "24px" }} />
			</Tooltip>
		</>
	);
};

const AuthenticationSettingsComponent = ({ onTabChange, getPartnerSecurityAuthenticationSettings, savePartnerSecurityAuthenticationSettings, authenticationSettings, isLoading, isSaving }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();

	const formInitialValuesRef = useRef(null);
	const isFormChangedRef = useRef(false);

	const isLoginFieldsEnabled = hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_SECURITY_SETTINGS, action: PERMISSION_ACTION.MODIFY });

	/** Load partner authentication settings */
	useEffect(() => {
		getPartnerSecurityAuthenticationSettings();
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		if (isLoading) {
			return;
		}

		const { loginSettings, twoFASettings, twoFactorEnabled } = authenticationSettings;

		//Do not change order
		const constructedFormValues = {
			[FIELDS_NAME.FAILED_LOGIN_ATTEMPTS]: loginSettings.failedLoginAttempts,
			[FIELDS_NAME.AUTO_UNLOCK_TIME]: loginSettings.autoUnlockTime,
			[FIELDS_NAME.AUTO_UNLOCK_COUNT]: loginSettings.autoUnlockCount,
			[FIELDS_NAME.TWO_FA_TOGGLE]: twoFactorEnabled,
			[FIELDS_NAME.TWO_FA_FAILED_LOGIN_ATTEMPTS]: twoFASettings.failedLoginAttempts,
			[FIELDS_NAME.TWO_FA_AUTO_UNLOCK_TIME]: twoFASettings.autoUnlockTime,
			[FIELDS_NAME.TWO_FA_AUTO_UNLOCK_COUNT]: twoFASettings.autoUnlockCount
		};

		formInitialValuesRef.current = constructedFormValues;
		formInstance.setFieldsValue({ ...constructedFormValues });
	}, [authenticationSettings, isLoading, formInstance]);

	const saveAuthenticationSettings = (fieldsValueObj) => {
		const requestBody = {
			loginSettings: {
				autoUnlockCount: fieldsValueObj[FIELDS_NAME.AUTO_UNLOCK_COUNT],
				autoUnlockTime: fieldsValueObj[FIELDS_NAME.AUTO_UNLOCK_TIME],
				failedLoginAttempts: fieldsValueObj[FIELDS_NAME.FAILED_LOGIN_ATTEMPTS]
			},
			twoFASettings: {
				autoUnlockCount: fieldsValueObj[FIELDS_NAME.TWO_FA_AUTO_UNLOCK_COUNT],
				autoUnlockTime: fieldsValueObj[FIELDS_NAME.TWO_FA_AUTO_UNLOCK_TIME],
				failedLoginAttempts: fieldsValueObj[FIELDS_NAME.TWO_FA_FAILED_LOGIN_ATTEMPTS]
			},
			twoFactorEnabled: fieldsValueObj[FIELDS_NAME.TWO_FA_TOGGLE]
		};

		savePartnerSecurityAuthenticationSettings(requestBody);
		isFormChangedRef.current = false;
		onTabChange(false);
	};

	const handleFormChange = (_, fieldsValueObj) => {
		const isFormChanged = JSON.stringify(fieldsValueObj) !== JSON.stringify(formInitialValuesRef.current);
		isFormChangedRef.current = isFormChanged;

		onTabChange(isFormChanged);
	};

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form layout="vertical" initialValues={FORM_DEFAULT_VALUES} form={formInstance} requiredMark={false} onFinish={saveAuthenticationSettings} onValuesChange={handleFormChange}>
				<div className="vs--authentication">
					<div>
						<h4 className="vs--authentication-paragraph">{t("common.login")}</h4>
						<Row gutter={[16, 0]} align="bottom">
							<Col xs={24} sm={12} xl={6} xxl={4}>
								<Form.Item
									label={renderLabelWithInfoIcon({
										labelText: t("pages.dashboard.partners.security_settings.authentication_settings.failed_attempts"),
										tooltipText: t("pages.dashboard.partners.security_settings.authentication_settings.info_tooltip_text")
									})}
									name={FIELDS_NAME.FAILED_LOGIN_ATTEMPTS}
								>
									<Select suffixIcon={<i className="icon-down" />} placeholder={t("common.select_count")} disabled={!isLoginFieldsEnabled}>
										{FAILED_LOGIN_ATTEMPTS_OPTIONS.map((countOption) => {
											return (
												<Select.Option key={countOption} value={countOption}>
													{countOption}
												</Select.Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} xl={6} xxl={4}>
								<Form.Item label={t("pages.dashboard.partners.security_settings.authentication_settings.auto_unlock_time")} name={FIELDS_NAME.AUTO_UNLOCK_TIME}>
									<Select suffixIcon={<i className="icon-down" />} placeholder={t("common.select_time")} disabled={!isLoginFieldsEnabled}>
										{AUTO_UNLOCK_TIME_OPTIONS.map((timeOption) => {
											return (
												<Select.Option key={timeOption} value={timeOption}>
													{`${timeOption} ${t("common.minutes")}`}
												</Select.Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} xl={6} xxl={4}>
								<Form.Item label={t("pages.dashboard.partners.security_settings.authentication_settings.auto_unlock_count")} name={FIELDS_NAME.AUTO_UNLOCK_COUNT}>
									<Select suffixIcon={<i className="icon-down" />} placeholder={t("common.select_count")} disabled={!isLoginFieldsEnabled}>
										{AUTO_UNLOCK_COUNT_OPTIONS.map((countOption) => {
											return (
												<Select.Option key={countOption} value={countOption}>
													{countOption}
												</Select.Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<h4 className="vs--authentication-paragraph">{t("pages.dashboard.partners.security_settings.authentication_settings.2FA")}</h4>
						<Row>
							<Col xs={24} sm={12} xl={6} xxl={4}>
								<Space size={8} align={"center"} style={{ marginBottom: "16px" }}>
									<label htmlFor={FIELDS_NAME.TWO_FA_TOGGLE}>{t("pages.dashboard.partners.security_settings.authentication_settings.enable_2FA")}</label>
									<Form.Item noStyle name={FIELDS_NAME.TWO_FA_TOGGLE} valuePropName="checked">
										<Switch disabled={!isLoginFieldsEnabled} />
									</Form.Item>
								</Space>
							</Col>
						</Row>
						<Row gutter={[16, 0]} align={"bottom"}>
							<Form.Item shouldUpdate={(prevValues, curValues) => prevValues[FIELDS_NAME.TWO_FA_TOGGLE] !== curValues[FIELDS_NAME.TWO_FA_TOGGLE]} noStyle>
								{({ getFieldValue }) => {
									const isTwoFAEnabled = getFieldValue(FIELDS_NAME.TWO_FA_TOGGLE);

									return (
										<>
											<Col xs={24} sm={12} xl={6} xxl={4}>
												<Form.Item
													label={renderLabelWithInfoIcon({
														labelText: t("pages.dashboard.partners.security_settings.authentication_settings.failed_attempts"),
														tooltipText: t("pages.dashboard.partners.security_settings.authentication_settings.info_tooltip_text")
													})}
													name={FIELDS_NAME.TWO_FA_FAILED_LOGIN_ATTEMPTS}
												>
													<Select suffixIcon={<i className="icon-down" />} disabled={!isTwoFAEnabled || !isLoginFieldsEnabled} placeholder={t("common.select_count")}>
														{FAILED_LOGIN_ATTEMPTS_OPTIONS.map((countOption) => {
															return (
																<Select.Option key={countOption} value={countOption}>
																	{countOption}
																</Select.Option>
															);
														})}
													</Select>
												</Form.Item>
											</Col>
											<Col xs={24} sm={12} xl={6} xxl={4}>
												<Form.Item label={t("pages.dashboard.partners.security_settings.authentication_settings.auto_unlock_time")} name={FIELDS_NAME.TWO_FA_AUTO_UNLOCK_TIME}>
													<Select suffixIcon={<i className="icon-down" />} disabled={!isTwoFAEnabled || !isLoginFieldsEnabled} placeholder={t("common.select_time")}>
														{AUTO_UNLOCK_TIME_OPTIONS.map((timeOption) => {
															return (
																<Select.Option key={timeOption} value={timeOption}>
																	{`${timeOption} ${t("common.minutes")}`}
																</Select.Option>
															);
														})}
													</Select>
												</Form.Item>
											</Col>
											<Col xs={24} sm={12} xl={6} xxl={4}>
												<Form.Item label={t("pages.dashboard.partners.security_settings.authentication_settings.auto_unlock_count")} name={FIELDS_NAME.TWO_FA_AUTO_UNLOCK_COUNT}>
													<Select suffixIcon={<i className="icon-down" />} placeholder={t("common.select_count")} disabled={!isTwoFAEnabled || !isLoginFieldsEnabled}>
														{AUTO_UNLOCK_COUNT_OPTIONS.map((countOption) => {
															return (
																<Select.Option key={countOption} value={countOption}>
																	{countOption}
																</Select.Option>
															);
														})}
													</Select>
												</Form.Item>
											</Col>
										</>
									);
								}}
							</Form.Item>
						</Row>
					</div>
					<Row justify="end">
						<Form.Item shouldUpdate noStyle>
							{() => {
								return (
									<Button type="primary" htmlType="submit" loading={isSaving} disabled={!isFormChangedRef.current}>
										<span>{t("common.save")}</span>
									</Button>
								);
							}}
						</Form.Item>
					</Row>
				</div>
			</Form>
		</Spin>
	);
};

/** AuthenticationSettingsComponent propTypes
 * PropTypes
 */
AuthenticationSettingsComponent.propTypes = {
	/** Redux state property, is true when authentication settings is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when authentication settings is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the authentication settings of current editing partner  */
	authenticationSettings: authenticationSettingsType,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func,
	/** Redux action to get partner authentication settings */
	getPartnerSecurityAuthenticationSettings: PropTypes.func,
	/** Redux action to get partner authentication settings */
	savePartnerSecurityAuthenticationSettings: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getPartnerSecurityAuthenticationSettings: () => {
		dispatch(getPartnerSecurityAuthenticationSettings());
	},
	savePartnerSecurityAuthenticationSettings: (settings) => {
		dispatch(savePartnerSecurityAuthenticationSettings(settings));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.partners.isSaving,
		isLoading: state.partner.isLoading,
		authenticationSettings: state.partner.securitySettings.authenticationSettings
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationSettingsComponent);
