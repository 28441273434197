import i18n from "translations/config";
import moment from "moment";

/** Validate function for min limit field
 * @function
 * @param {string} minValue - value of min limit field
 * @param {string} maxValue - value of max limit field
 */
export const validateMin = (minValue, maxValue) => {
	let max = Number(maxValue);
	let min = Number(minValue);
	if (!isNaN(min) && min >= 1 && !isNaN(max) && max >= 1 && min > max) {
		return Promise.reject(i18n.t("validation.min_limit"));
	}
	return Promise.resolve();
};

/** Validate function for max limit field
 * @function
 * @param {string} minValue - value of min limit field
 * @param {string} maxValue - value of max limit field
 * @param {string} posMaxValue - value of possibleWinMax field
 */
export const validateMax = (minValue, maxValue, posMaxValue) => {
	let max = Number(maxValue);
	let min = Number(minValue);
	let posMax = Number(posMaxValue);
	if (!isNaN(min) && min >= 1 && !isNaN(max) && max >= 1 && min > max) {
		return Promise.reject(i18n.t("validation.max_limit"));
	} else if (!isNaN(max) && max >= 1 && !isNaN(posMax) && posMax >= 1 && max > posMax) {
		return Promise.reject(i18n.t("validation.max_pos_max"));
	}
	return Promise.resolve();
};

/** Validate function for singlePossibleWinMax field
 * @function
 * @param {string} maxValue - value of max limit field
 * @param {string} posMaxValue - value of possibleWinMax field
 */
export const validatePosMax = (maxValue, posMaxValue) => {
	let max = Number(maxValue);
	let posMax = Number(posMaxValue);
	if (!isNaN(max) && max >= 1 && !isNaN(posMax) && posMax >= 1 && max > posMax) {
		return Promise.reject(i18n.t("validation.max_pos_max"));
	}
	return Promise.resolve();
};

/** Get favorite bets fields, which have duplicate values
 * @function
 * @param {array} favoriteBets - value of favorite bets fields
 * @returns {array} - array of indexes of fields
 */
export const favoriteBetsRepeatingIndexes = (favoriteBets) => {
	let duplicates = [];
	const favBets = [...favoriteBets];
	favBets.forEach((el, i) => {
		if (favBets.filter((f) => f === el).length > 1 && el !== "") duplicates.push(i);
	});
	return duplicates;
};

/** Get Rate form rate history
 * @function
 * @param {object} currency - currency
 * @param {string} date - the date, to find proper rate
 * @returns {number}
 */
const getRate = (currency, date) => {
	let rate;

	if (!date || !currency.history || currency.history.length === 0) {
		rate = currency.rate;
	} else {
		const rateFromHistory = currency.history.find((h) => moment(h.from).isBefore(moment(date)) && moment(h.to).isAfter(moment(date)));
		if (rateFromHistory) {
			rate = rateFromHistory.rate;
		}
	}
	return rate;
};

/** Convert currency
 * @function
 * @param {number} value - value
 * @param {string} currencyCode - currency code
 * @param {array} currencies - array of all currencies
 * @param {string} partnerId - the partnerId, to find proper rate
 * @param {string} date - the date, to find proper rate
 * @param {boolean} returnNumber - the option for managing return value type (number/string)
 * @returns {number}
 */
export const convertCurrency = (value, currencyCode, currencies, partnerId, date, returnNumber = false) => {
	if (currencyCode === "FUN") return value;
	currencies = currencies || [];
	let rate;
	let isDefault = false;
	let currency = currencies.find((c) => c.code.toLowerCase() === currencyCode.toLowerCase() && c.partnerId === partnerId);
	if (!currency) {
		currency = currencies.find((c) => c.partnerId === partnerId && c.isDefault);
		isDefault = true;
	}

	if (!currency) return value;

	rate = getRate(currency, date);

	if (!isDefault && !rate) {
		currency = currencies.find((c) => c.partnerId === partnerId && c.isDefault);
		if (!currency) return value;
		rate = getRate(currency, date);
	}
	return returnNumber ? value / rate : (value / rate).toFixed(currency.minorUnit);
};

/** Convert one currency to another
 * @function
 * @param {number} value - value
 * @param {string} from - original currency
 * @param {string} to - convert to this currency
 * @param {array} currencies - array of all currencies
 * @param {string} partnerId - the partnerId, to find proper rate
 * @param {string} date - the date, to find proper rate
 * @param {boolean} returnNumber - the option for managing return value type (number/string)
 * @returns {number}
 */
export const convertCurrencies = (value, from, to, currencies, partnerId, date, returnNumber = false) => {
	if (from === "FUN" || to === "FUN") return value;
	let isDefault = false;
	currencies = currencies || [];
	let fromCurrency = currencies.find((c) => c.code.toLowerCase() === from.toLowerCase() && c.partnerId === partnerId);
	if (!fromCurrency) {
		fromCurrency = currencies.find((c) => c.partnerId === partnerId && c.isDefault);
		isDefault = true;
	}
	if (!fromCurrency) return value;

	let rate;
	rate = getRate(fromCurrency, date);

	if (!isDefault && !rate) {
		fromCurrency = currencies.find((c) => c.partnerId === partnerId && c.isDefault);
		if (!fromCurrency) return value;
		rate = getRate(fromCurrency, date);
	}
	return convertCurrency(value * rate, to, currencies, partnerId, date, returnNumber);
};

/** Check if partner has passed currency, and return that, otherwise return partner default currency
 * @function
 * @param {string} currencyCode - currency code
 * @param {array} currencies - array of all currencies
 * @param {string} partnerId - the partnerId, to find proper rate
 * @param {string} date - the date, to find proper rate
 * @returns {number}
 */
export const getPartnerCurrency = (currencyCode, currencies, partnerId, date) => {
	if (currencyCode === "FUN") return currencyCode;
	let rate;
	currencies = currencies || [];
	let currency = currencies.find((c) => c.code.toLowerCase() === currencyCode.toLowerCase() && c.partnerId === partnerId);
	if (currency) {
		rate = getRate(currency, date);
	}

	if (rate) return currencyCode;

	currency = currencies.find((c) => c.partnerId === partnerId && c.isDefault);
	if (currency) {
		rate = getRate(currency, date);
	}

	if (rate) return currency.code;

	return "";
};

/** Check if partner has passed currency, and return its decimalCount, otherwise return partner default currency decimalCount
 * @function
 * @param {string} currencyCode - currency code
 * @param {array} currencies - array of all currencies
 * @param {string} partnerId - the partnerId, to find proper rate
 * @param {string} date - the date, to find proper rate
 * @returns {number}
 */
export const getPartnerCurrencyDecimalCount = (currencyCode, currencies, partnerId, date) => {
	if (currencyCode === "FUN") return 2;

	let rate;
	currencies = currencies || [];
	let currency = currencies.find((c) => c.code.toLowerCase() === currencyCode.toLowerCase() && c.partnerId === partnerId);
	if (currency) {
		rate = getRate(currency, date);
	}
	if (rate) return currency.minorUnit;

	currency = currencies.find((c) => c.partnerId === partnerId && c.isDefault);
	if (currency) {
		rate = getRate(currency, date);
	}

	if (rate) return currency.minorUnit;

	return 0;
};

/** Checks if can convert one currency to another
 * @function
 * @param {string} from - original currency
 * @param {string} to - convert to this currency
 * @param {array} currencies - array of all currencies
 * @param {string} partnerId - the partnerId, to find proper rate
 * @returns {boolean}
 */
export const canConvertCurrencies = (from, to, currencies, partnerId) => {
	if (!from || !to) {
		return false;
	}
	let isDefault = false;
	currencies = currencies || [];
	let fromCurrency = currencies.find((c) => c.code.toLowerCase() === from.toLowerCase() && c.partnerId === partnerId);
	if (!fromCurrency) {
		return false;
	}
	let toCurrency = currencies.find((c) => c.code.toLowerCase() === to.toLowerCase() && c.partnerId === partnerId);
	if (!toCurrency) {
		return false;
	}

	return true;
};

/** Create callback for find currency by currencyCode from response
 * @function
 * @param {string} code - currency code
 * @returns {object}
 */
export const createCallbackByCurrencyCode = (code) => {
	return (c) => {
		return c.code.toLowerCase() === code.toLowerCase();
	};
};

/** Callback for find currency by is default param
 * @function
 * @param {string} code - currency code
 * @returns {object}
 */
export const callbackByIsDefault = (code) => {
	return code.isDefault;
};

/** Generate default currency object
 * @function
 * @param {string} code - currency code
 * @param {string} partnerId - Partner id
 * @returns {object}
 */
export const generateDefaultCurrency = (code, partnerId) => {
	return {
		code,
		partnerId,
		name: "",
		rate: 0,
		minorUnit: 0,
		isDefault: false,
		symbol: "",
		history: []
	};
};
