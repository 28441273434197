import PropTypes from "prop-types";

import { BOX_TYPE } from "constants/box.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	partnerId: PropTypes.string,
	connectionInstruction: PropTypes.string,
	isTesting: PropTypes.bool,
	created: PropTypes.string,
	lastUpdate: PropTypes.string,
	enabled: PropTypes.bool,
	games: PropTypes.arrayOf(PropTypes.string),
	type: PropTypes.oneOf(Object.values(BOX_TYPE))
});
