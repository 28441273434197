import PropTypes from "prop-types";

const CsatRateInfo = ({ rates }) => {
	return (
		<div className="pie-chart-labels">
			<div className="pie-chart-labels-container">
				{rates.map(({ name, color, y }) => (
					<div key={`${name}_${y}`} className="pie-chart-labels-item">
						<div className="pie-chart-labels-item-group">
							<div style={{ backgroundColor: color }} className="pie-chart-labels-item-group-badge" />
							<span className="pie-chart-labels-item-group-text">{name}</span>
						</div>
						{y}
					</div>
				))}
			</div>
		</div>
	);
};

CsatRateInfo.propTypes = {
	rates: PropTypes.array
};

export default CsatRateInfo;
