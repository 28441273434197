import { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Col, Row, Modal, Select, Checkbox, Tooltip } from "antd";
const { Item: FormItem } = Form;
const { Option } = Select;

import SearchableSelect from "components/common/searchableSelect";

import { addApiKeyGame } from "store/actions/dashboard/online/apikeys/games.action";
import { getGames } from "store/actions/dashboard/virtuals/games/games.action";

import { flagsToBinary, getGamesFromConfig } from "utils/common";

import apiKeyGameType from "types/apiKey/apiKeyGame.type";
import gameType from "types/game/game.type";

import { POPUP_SIZE } from "constants/common.constants";
import { GAME_CATEGORY, REDUX_EDITING_API_KEY_GAME_FIELD } from "constants/game.constants";

/** Apikey Game Adding Popup Component */
const GameAddComponent = ({ isSaving, addApiKeyGame, getGames, onClose, games, apiKeyGames, gameCategory }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;

	/** Get the game types which are available for apikeys
	 * @function
	 * @returns {array} - avalable types
	 * @memberOf GameAddComponent
	 */
	const getAvailableTypes = () => {
		const possible = getGamesFromConfig(GAME_CATEGORY.SCHEDULED).map((t) => t.value);
		const available = [];
		const types = apiKeyGames.map((g) => g.type);
		possible.forEach((p) => {
			if (!types.includes(p)) {
				available.push(p);
			}
		});
		return available;
	};

	const [gameType, setGameType] = useState(getAvailableTypes()[0]);

	/** Get all games */
	useEffect(() => {
		getGames(gameCategory);
	}, [gameCategory]);

	/** Fires when form submitted
	 * @function
	 * @memberOf GameAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ gameId, gameLabel }) => {
				const submitValues = {
					id: routeParams.id,
					gameId
				};

				if (flagsToBinary(gameLabel)) submitValues["gameLabel"] = flagsToBinary(gameLabel);

				addApiKeyGame(submitValues, gameCategory);
			})
			.catch(Function.prototype);
	};

	/** Fires on game type dorpdown change
	 * @function
	 * @param {string} value - dropdown value
	 * @memberOf GameAddComponent
	 */
	const onGameTypeChange = (value) => {
		setGameType(value);
		setFieldsValue({ gameId: "" });
	};

	return (
		<Modal
			open={true}
			title={
				<Fragment>
					<span>{t("pages.dashboard.apikeys.add_new_game")}</span>
					<Tooltip title={<span dangerouslySetInnerHTML={{ __html: t("pages.dashboard.apikeys.game_add_info") }}></span>} getPopupContainer={() => document.body}>
						<i className="icon-info" />
					</Tooltip>
				</Fragment>
			}
			cancelText={t("common.cancel")}
			okText={t("common.add")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.SMALL}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving }}
			centered
			className="apikey-add-NewGame-modal"
		>
			<Form
				className="dashboard-form apikey-edit-addNewGame"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					type: gameType,
					gameId: "",
					gameLabel: []
				}}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={t("pages.dashboard.apikeys.game_type")} name="type" rules={[{ required: true, message: t("validation.field_required") }]}>
							<Select onChange={onGameTypeChange} getPopupContainer={() => document.body} suffixIcon={<i className="icon-down" />}>
								{getGamesFromConfig(gameCategory).map((g) => (
									<Option key={g.value} value={g.value} disabled={!getAvailableTypes().includes(g.value)}>
										{t(`common.${g.type}`)}
									</Option>
								))}
							</Select>
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem label={t("pages.dashboard.apikeys.select_game")} name="gameId" rules={[{ required: true, message: t("validation.field_required") }]} style={{ marginBottom: 0 }}>
							<SearchableSelect placeholder={t("pages.dashboard.apikeys.")} items={games.filter((g) => g.type === gameType)} valueProp={(item) => item.id} textProp={(item) => item.name} renderText={(item) => item.name} getPopupContainer={() => document.body} />
						</FormItem>
						<FormItem name="gameLabel" valuePropName="checked">
							<Checkbox.Group>
								<Checkbox value={1} style={{ lineHeight: "32px" }}>
									New Game
								</Checkbox>
								<Checkbox value={2} style={{ lineHeight: "32px", marginLeft: "11px" }}>
									Is Test
								</Checkbox>
							</Checkbox.Group>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** GameAddComponent propTypes
 * PropTypes
 */
GameAddComponent.propTypes = {
	/** Redux state property, is true when adding game for api key */
	isSaving: PropTypes.bool,
	/** Redux action to add game to api key*/
	addApiKeyGame: PropTypes.func,
	/** Redux action to get games */
	getGames: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Redux state property, represents the array of games  */
	games: PropTypes.arrayOf(gameType),
	/** Redux state property, represents the array of games of api key */
	apiKeyGames: PropTypes.arrayOf(apiKeyGameType),
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	addApiKeyGame: (game, gameCategory) => {
		dispatch(addApiKeyGame(game, gameCategory));
	},
	getGames: (gameCategory) => {
		dispatch(getGames(false, true, gameCategory));
	}
});

const mapStateToProps = (state, props) => {
	const { gameCategory } = props;
	return {
		isSaving: state.apikeys.isSaving,
		apiKeyGames: state.apikeys.editingApikey.games[REDUX_EDITING_API_KEY_GAME_FIELD[gameCategory]],
		games: state.games.games
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GameAddComponent);
