import { Fragment } from "react";

import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import BetAnomalies from "components/dashboard/riskManagement/betAnomalies";

const BetAnomaliesRouteComponent = () => {
	return (
		<Fragment>
			<BetAnomalies />
		</Fragment>
	);
};


export default withPermission(
	BetAnomaliesRouteComponent,
	[
		{ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BET_ANOMALIES_HISTORY, action: PERMISSION_ACTION.VIEW },
		{ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BET_ANOMALIES_CONFIGURATIONS, action: PERMISSION_ACTION.VIEW }
	],
	Paths.DASHBOARD
)
