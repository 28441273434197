import PropTypes from "prop-types";
import { PARTNER_SECURITY_PASSWORD_PATTERN, PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS, PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE } from "constants/partner.constants";

export default PropTypes.shape({
	passwordMinLimit: PropTypes.number,
	passwordMaxLimit: PropTypes.number,
	forceChange: PropTypes.number,
	pattern: PropTypes.shape({
		type: PropTypes.oneOf(Object.values(PARTNER_SECURITY_PASSWORD_PATTERN)),
		contain: PropTypes.arrayOf(
			PropTypes.shape({
				type: PropTypes.oneOf(Object.values(PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS)),
				value: PropTypes.oneOf(Object.values(PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE))
			})
		),
		alsoIncludeCharacters: PropTypes.string,
		regularExpression: PropTypes.string
	})
});
