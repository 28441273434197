import PropTypes from "prop-types";

/** Selecter Component */
const Selecter = ({
	onChange,
	value,
	items,
	disabled
	/* resetTo */
}) => {
	/** Fires om item click
	 * @function
	 * @param {string} item - selected item
	 * @memberOf Selecter
	 */
	const handleItemClick = (item) => {
		if (disabled) return;
		if (value.includes(item)) {
			onChange(value.filter((v) => v !== item));
		} else {
			onChange([...value, item]);
		}
	};

	return (
		<div className="selecter">
			{items.map((item) => (
				<div className={"selecter-item" + (value.includes(item.value) ? " selecter-item-selected" : "") + (disabled ? " selecter-item-disabled" : "")} key={item.value} onClick={() => handleItemClick(item.value)}>
					<span className="selecter-item-title">{item.title}</span>
					{value.includes(item.value) && <i className="icon-check" />}
				</div>
			))}
		</div>
	);
};

/** Selecter propTypes
 * PropTypes
 */
Selecter.propTypes = {
	/** Handler of selecter value change */
	onChange: PropTypes.func,
	/** Current value of selecter */
	value: PropTypes.array,
	/** Should selecter be disabled */
	disabled: PropTypes.bool,
	/** Array of items */
	items: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		})
	),
	/** Reset to this value */
	resetTo: PropTypes.array
};

export default Selecter;
