import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { setBonusesActionBefore, setBonusesActionFinished, setBonusSaveActionBefore, setBonusSaveActionFinished } from "./bonuses.action";

import {
	SET_BONUS_GENERAL_INFO,
	SET_JACKPOT_GENERAL_INFO
} from '../../../actionTypes';
import { mapJackpotGeneralInfo } from 'utils/bonuses';

const setBonusGeneralInfo = (info) => ({
	type: SET_BONUS_GENERAL_INFO,
	payload: { info }
});

const setJackpotGeneralInfo = info => ({
	type: SET_JACKPOT_GENERAL_INFO,
	payload: { info }
})

export const getBonusGeneralInfo = id => {
	return dispatch => {
		dispatch(setBonusesActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BONUS_GET_GENERAL_INFO}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: info } }) => {
				dispatch(setBonusGeneralInfo(info))
				dispatch(setBonusesActionFinished());
			})
			.catch((ex) => {
				dispatch(setBonusesActionFinished());
			})
	}
}

export const getJackpotGeneralInfo = (id, forCopyData = false) => {
	return dispatch => {
		dispatch(setBonusesActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.JACKPOT_GET_GENERAL_INFO}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: info } }) => {
				const mappedinfo = mapJackpotGeneralInfo(info);

				if(!forCopyData) {
					dispatch(setJackpotGeneralInfo(mappedinfo));
				}
				dispatch(setBonusesActionFinished());

				return mappedinfo;
			})
			.catch((ex) => {
				dispatch(setBonusesActionFinished());
			})
	}
}

export const getJackpotCopyData = (id, copyFields, onCopy = Function.prototype) => {
	return dispatch => {
		dispatch(setBonusesActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.JACKPOT_GET_GENERAL_INFO}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: info } }) => {
				const mappedinfo = mapJackpotGeneralInfo(info);

				const copyData = copyFields.reduce((acc, field) => {
					if(mappedinfo[field]) {
						acc[field] = mappedinfo[field]
					}

					return acc;
				}, {});

				onCopy(copyData);
				dispatch(setBonusesActionFinished());
			})
			.catch((ex) => {
				dispatch(setBonusesActionFinished());
			})
	}
}

export const saveBonusGeneralInfo = (info) => {
	return (dispatch) => {
		dispatch(setBonusSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BONUS_SAVE_GENERAL_INFO}`,
			method: Methods.POST,
			data: {
				...info
			}
		})
			.then(({ data: { message: msg, status, value: info } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setBonusGeneralInfo(info));
				}
				dispatch(setBonusSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setBonusSaveActionFinished());
			})
	}
}

export const saveJackpotGeneralInfo = info => {
	return dispatch => {
		dispatch(setBonusSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.JACKPOT_SAVE_GENERAL_INFO}`,
			method: Methods.POST,
			data: {
				...info
			}
		})
			.then(({ data: { message: msg, status, value: info } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setJackpotGeneralInfo(mapJackpotGeneralInfo(info)));
				}
				dispatch(setBonusSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setBonusSaveActionFinished());
			})
	}
}
