import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Col, Row, Modal, Select, Input } from "antd";
const { Item: FormItem } = Form;

import { changeSettledBet, changeSettledBetslip } from "store/actions/dashboard/betslips/settled.action";

import { BET_STATE, BET_STATE_TRANSLATION_KEYS } from "constants/bet.constants";
import { POPUP_SIZE } from "constants/common.constants";

const getDefaultBetState = (item) => (item.allowOnlyCancel ? BET_STATE.CANCELLED : item.status);
const getAllowedBetStates = (item) => (item.allowOnlyCancel ? [BET_STATE.CANCELLED] : [BET_STATE.WON, BET_STATE.LOST, BET_STATE.CANCELLED].concat(item.betId ? [BET_STATE.RETURN] : []));

/** Betslip Status changing Popup Component */
const BetSlipStateChangeComponent = ({ item, isSaving, changeSettledBet, changeSettledBetslip, onClose, gameCategory }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;

	const [currentState, setCurrentState] = useState(getDefaultBetState(item));

	/** Fires when form submitted
	 * @function
	 * @memberOf BetSlipStateChangeComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				if (item.betId) {
					/// is single bet
					changeSettledBet(item.id, item.betId, data.reason, data.status, gameCategory);
					return;
				}
				changeSettledBetslip(item.id, data.reason, data.status, gameCategory);
			})
			.then(() => onClose())
			.catch(Function.prototype);
	};

	const allowedStates = getAllowedBetStates(item);

	return (
		<Modal
			open={true}
			title={t("pages.dashboard.bets.change_state")}
			cancelText={t("common.cancel")}
			okText={t("common.save")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.SMALL}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving, disabled: currentState === item.status }}
			centered
		>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					status: getDefaultBetState(item),
					reason: ""
				}}
			>
				<Row gutter={[16, 0]}>
					<Col xs={24}>
						<FormItem label={`${t("pages.dashboard.bets.status")} *`} name="status" rules={[{ required: true, message: t("validation.field_required") }]}>
							<Select getPopupContainer={() => document.body} onChange={(e) => setCurrentState(e)} suffixIcon={<i className="icon-down" />}>
								{allowedStates.map((state) => (
									<Select.Option key={state} value={state}>
										{BET_STATE_TRANSLATION_KEYS[state] ? t(BET_STATE_TRANSLATION_KEYS[state]) : ""}
									</Select.Option>
								))}
							</Select>
						</FormItem>
					</Col>
				</Row>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={`${t("pages.dashboard.bets.reason")} *`} name="reason" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
							<Input maxLength={100} placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.reason")}`} />
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** BetSlipStateChangeComponent propTypes
 * PropTypes
 */
BetSlipStateChangeComponent.propTypes = {
	/** Redux state property, is true when creating game request is in process */
	isSaving: PropTypes.bool,
	/** Redux action to change state for single bet */
	changeSettledBet: PropTypes.func,
	/** Redux action to change state for betslip */
	changeSettledBetslip: PropTypes.func,
	/** Current editing item */
	item: PropTypes.shape({
		id: PropTypes.number,
		betId: PropTypes.number,
		status: PropTypes.oneOf(Object.values(BET_STATE)),
		allowOnlyCancel: PropTypes.bool
	}),
	/** Fires on popup close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	changeSettledBet: (id, betId, reason, status, gameCategory) => {
		dispatch(changeSettledBet(id, betId, reason, status, gameCategory));
	},
	changeSettledBetslip: (id, reason, status, gameCategory) => {
		dispatch(changeSettledBetslip(id, reason, status, gameCategory));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.betslips.isSaving
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BetSlipStateChangeComponent);
