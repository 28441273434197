import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setPartnersFilters, getPartners } from "store/actions/dashboard/partners/partners.action";
import { Form, Row, Col } from "antd";
import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import useAutosuggestion from "hooks/useAutosuggestion";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
const { Item: FormItem } = Form;

/** Partner Page Filters Component */
const Filters = ({ setPartnersFilters, getPartners, filters, globalPartnerId }) => {
	const { t } = useTranslation();
	const partnersNames = useAutosuggestion(AUTOSUGGESTION_TYPE.PARTNER_NAMES, [globalPartnerId], { disableAction: () => !globalPartnerId });

	const filtersList = [
		{
			name: "nameOrId",
			title: t("pages.dashboard.partners.partner_name_or_id")
		}
	];

	return (
		<FiltersWrapper loadFn={getPartners} setFiltersFn={setPartnersFilters} filters={filters} filtersList={filtersList} filtersName="partners">
			<Row gutter={[16, 0]}>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<FormItem label={t("pages.dashboard.partners.partner_name_or_id")} name="nameOrId">
						<AutoComplete placeholder={`${t("common.start_typing")} ${t("pages.dashboard.partners.partner_name_or_id")}`} items={partnersNames.map((g) => g.value)} />
					</FormItem>
				</Col>
			</Row>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get Partners */
	getPartners: PropTypes.func,
	/** Redux action to set Partners filters */
	setPartnersFilters: PropTypes.func,
	/** Redux state property, Partners filters */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getPartners: (fromFirstPage) => {
		dispatch(getPartners(fromFirstPage));
	},
	setPartnersFilters: (filters) => {
		dispatch(setPartnersFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.partners.filters,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
