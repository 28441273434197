import VideoPlayer from "../player";

import { isMobile, isIOS, lockOrientation, unLockOrientation } from "utils/common";
import fullScreenAPI from "utils/fullscreen";

class NanoVideoPlayer extends VideoPlayer {
	constructor(streamConfiguration, options) {
		super(streamConfiguration, options);
	}

	makeConfigs() {
		const entries = [];

		if (!this.streamConfiguration) return null;

		let streams = this.streamConfiguration.streams || [];

		let languages = streams.map((s) => s.languageCode);
		let languageCode = this.options.languageCode || null;

		if (!languages.includes(languageCode)) {
			languageCode = languages[0];
		}

		streams = streams.filter((s) => s.languageCode === languageCode);

		const isSecure = streams.some((s) => s.securityEnabled);

		const SERVER_URLS = isSecure
			? {
					HLS: "https://bintu-h5live-secure.nanocosmos.de:443/h5live/authhttp/playlist.m3u8",
					PROGRESSIVE: "https://bintu-h5live-secure.nanocosmos.de:443/h5live/authhttp/stream.mp4",
					WEBSOCKET: "wss://bintu-h5live-secure.nanocosmos.de:443/h5live/authstream/stream.mp4",
					RTMP: "rtmp://bintu-splay.nanocosmos.de:1935/splay"
				}
			: {
					HLS: "https://bintu-h5live.nanocosmos.de:443/h5live/http/playlist.m3u8",
					PROGRESSIVE: "https://bintu-h5live.nanocosmos.de:443/h5live/http/stream.mp4",
					WEBSOCKET: "wss://bintu-h5live.nanocosmos.de:443/h5live/stream/stream.mp4",
					RTMP: "rtmp://bintu-play.nanocosmos.de:1935/play"
				};

		streams.forEach((s, index) => {
			const stream = {
				index: index,
				h5live: {
					server: {
						hls: SERVER_URLS.HLS,
						progressive: SERVER_URLS.PROGRESSIVE,
						websocket: SERVER_URLS.WEBSOCKET
					},
					rtmp: {
						url: SERVER_URLS.RTMP,
						streamname: s.name.trim()
					}
				}
			};
			if (s.securityToken && s.securityEnabled) {
				try {
					const security = JSON.parse(s.securityToken);
					if (security.h5live) stream.h5live.security = security.h5live.security;
				} catch (ex) {
					console.log(ex);
				}
			}
			entries.push(stream);
		});

		const nanoPlayerConfig = {
			source: {
				entries: entries,
				options: {
					adaption: {
						rule: "deviationOfMean2"
					},
					switch: {}
				},
				startIndex: "0"
			},
			playback: {
				autoplay: true,
				automute: true,
				muted: true,
				flashplayer: "//demo.nanocosmos.de/nanoplayer/nano.player.swf"
			},
			style: {
				width: "auto",
				aspectratio: "16/9",
				controls: false,
				displayMutedAutoplay: false
			},
			events: {}
		};

		return nanoPlayerConfig;
	}

	init() {
		if (this.initialized) return;
		if (!this.streamConfiguration) return this.setError(true);
		if (!this.streamConfiguration.streams || !this.streamConfiguration.streams.length) return this.setError(true);
		this.player = new window.NanoPlayer(this.options.elementID);

		const playerConfigs = {
			...this.makeConfigs()
		};

		playerConfigs.events.onPlay = this.onPlay.bind(this);
		playerConfigs.events.onPause = this.onPause.bind(this);
		playerConfigs.events.onVolumeChange = (e) => {
			this.onVolumeChange(e.data.volume);
		};
		playerConfigs.events.onReady = () => {
			if (this.player) {
				this.onPlayerReady();
				this.addEventListeners();
			}
		};

		playerConfigs.events.onError = (err) => {
			console.log("Error From Stream", err.data.code, err.data.message);
			if (err.data.code >= 2000) {
				setTimeout(() => {
					this.reload(err.data.code >= 4900 && err.data.code <= 4999 ? "EXPIRED" : "FATAL");
				}, 400);
			}
		};

		if (isIOS()) {
			playerConfigs.events.onFullscreenChange = (event) => this.onFullscreenChange(event.data.entered);
		}

		try {
			this.player.setup(playerConfigs).then(
				(config) => this.setError(false),
				(error) => this.setError(true)
			);
		} catch (ex) {
			console.log(ex);
		}

		this.initialized = true;
	}

	destroy() {
		this.player && this.player.destroy();
		this.player = null;
		this.options.onDestroy && this.options.onDestroy();

		this.initialized = false;
	}

	mute() {
		this.player.mute();
		this.options.onMute && this.options.onMute(true);
	}

	unmute() {
		this.player.unmute();
		this.options.onMute && this.options.onMute(false);
	}

	setPlayerVolume(v) {
		this.player.setVolume(v);
	}

	getPlayerVolume() {
		return this.player._view._volume;
	}

	getPlayerMuted() {
		return this.player._view._muted;
	}

	onFullscreen() {
		if (!isIOS()) {
			if (!fullScreenAPI.isFullscreen) {
				const elem = document.getElementById(this.options.elementID);
				if (!elem) {
					return;
				}
				fullScreenAPI.toggle(elem.parentElement);
				lockOrientation("landscape");
			} else {
				fullScreenAPI.toggle();
			}
		} else {
			if (!document.body.classList.contains("vs--fullscreen")) {
				this.player.requestFullscreen();
			} else {
				this.player.exitFullscreen();
			}
		}
	}

	onFullscreenChange(isFullscreen) {
		if (isIOS()) {
			if (isFullscreen) {
				document.body.classList.add("vs--fullscreen");
			} else {
				document.body.classList.remove("vs--fullscreen");
			}
		} else {
			if (!fullScreenAPI.isFullscreen) {
				unLockOrientation();
				document.body.classList.remove("vs--fullscreen");
			} else {
				document.body.classList.add("vs--fullscreen");
			}
		}
	}

	addEventListeners() {
		const element = document.getElementById(this.player._playerDivId);

		if (isMobile()) {
			const clickElem = !isIOS() ? element.getElementsByTagName("video")[0] : document.getElementById("clickWrapper-" + this.player._playerDivId);
			if (clickElem) {
				clickElem.removeEventListener("click", this.handleVideoElementClick.bind(this));
				clickElem.addEventListener("click", this.handleVideoElementClick.bind(this));
			}
		}

		if (isMobile() && !isIOS()) {
			fullScreenAPI.off("change", this.onFullscreenChange.bind(this));
			fullScreenAPI.on("change", this.onFullscreenChange.bind(this));
		}
	}

	handleVideoElementClick() {
		const element = document.getElementById(this.player._playerDivId);
		const containerElem = element.parentElement;
		this.onControlsVisiblityChange(!containerElem.classList.contains("vs--video-container-controls-visible"));
	}
}

export default NanoVideoPlayer;
