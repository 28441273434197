import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { PASSWORD_SETTINGS_ACTION_BEFORE, PASSWORD_SETTINGS_ACTION_FINISH, SET_PASSWORD_SETTINGS } from "../../../actionTypes";

const setPasswordSettingsActionBefore = () => ({
	type: PASSWORD_SETTINGS_ACTION_BEFORE
});

const setPasswordSettingsActionFinished = () => ({
	type: PASSWORD_SETTINGS_ACTION_FINISH
});

const setPasswordSettings = (settings) => ({
	type: SET_PASSWORD_SETTINGS,
	payload: { settings }
});

export const getPasswordSettings = () => {
	return (dispatch) => {
		dispatch(setPasswordSettingsActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PASSWORD_SETTINGS}`,
			method: Methods.GET
		})
			.then(({ data: { value: settings } }) => {
				dispatch(setPasswordSettings(settings));
				dispatch(setPasswordSettingsActionFinished());
			})
			.catch(() => {
				dispatch(setPasswordSettingsActionFinished());
			});
	};
};
