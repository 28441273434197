import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Tag } from "antd";

import Table from "components/common/table";

import RollbackOnlineFilters from "./sections/rollbacks/online/filters.component";
import RollbackRetailFilters from "./sections/rollbacks/retail/filters.component";
import ResultOnlineFilters from "./sections/results/online/filters.component";
import ResultRetailFilters from "./sections/results/retail/filters.component";

import ExportButton from "components/common/exportButton";
import Question from "components/common/question";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { resendAllTransactions } from "store/actions/dashboard/transactions/transactions.action";

import ApiUrls from "constants/api.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from "constants/transactions.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { BET_STATE_COLOR } from "constants/bet.constants";

import { toUpperCaseFirstLetter } from "utils/common";
import { hasPermission } from "utils/permissions";

import transactionType from "types/transaction/transaction.type";
import sortingType from "types/common/sorting.type";

/** Transactions Table Component */
const TransactionsTableComponent = ({ getTransactions, getTransactionHistory, setTransactionsSorting, setTransactionsFilters, resendTransaction, resendAllTransactions, transactions, isLoading, sorting, filters, total, globalPartnerId, isResendInProcess, projectType, transactionType }) => {
	const { t } = useTranslation();

	const [showResendAllPopup, setShowResendAllPopup] = useState(false);

	useEffect(() => {
		if (!isResendInProcess) {
			setShowResendAllPopup(false);
		}
	}, [isResendInProcess]);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.transactions.transaction_id"),
			dataIndex: "id"
		},
		{
			title: t("pages.dashboard.transactions.date"),
			dataIndex: "betTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: projectType === PROJECT_TYPE.ONLINE ? t("pages.dashboard.transactions.project") : t("pages.dashboard.transactions.betshop"),
			dataIndex: "projectId",
			copy: true
		},
		{
			title: t("pages.dashboard.transactions.action_type"),
			dataIndex: "actionType",
			render: (value) => t("pages.dashboard.transactions.action_type_" + value)
		},
		{
			title: t("pages.dashboard.transactions.bet_status"),
			dataIndex: "betStatus",
			render: (value, record) => <Tag color={BET_STATE_COLOR[value]}>{t("pages.dashboard.bets.bet_status_" + value)}</Tag>
		},
		{
			title: t("pages.dashboard.transactions.status"),
			dataIndex: "lastStatus",
			render: (value) => `${toUpperCaseFirstLetter((Object.keys(TRANSACTION_STATUS).find((key) => TRANSACTION_STATUS[key] === value) || "").toLowerCase().replace(/_/g, " "))}`
		},
		{
			title: t("pages.dashboard.transactions.last_resend_at"),
			dataIndex: "lastActionTime",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "")
		},
		{
			title: t("pages.dashboard.transactions.last_resend_by"),
			dataIndex: "lastActionBy"
		},
		{
			title: t("pages.dashboard.transactions.last_message"),
			dataIndex: "lastMessage",
			render: (value) => (
				<span title={value} className="table-col-shorten-text">
					{value}
				</span>
			)
		}
	];

	return (
		<div className="dashboard-section-content">
			<div className="table-header">
				<div className="table-buttons-dropdowns">
					{hasPermission({ resource: PERMISSION_RESOURCE.FAILED_TRANSACTION, action: PERMISSION_ACTION.EXPORT }) ? (
						<ExportButton columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t("pages.dashboard.transactions.tabs.rollbacks")} url={ApiUrls.EXPOTS_BET_TRANSACTIONS + "?projectType=" + projectType + "&type=" + transactionType} filters={filters} sorting={sorting} />
					) : null}
					{hasPermission({ resource: PERMISSION_RESOURCE.FAILED_TRANSACTION, action: PERMISSION_ACTION.MODIFY }) ? (
						<div className="table-buttons-dropdowns-button">
							<Button onClick={() => setShowResendAllPopup(true)} type="primary">
								{t("pages.dashboard.transactions.resend_all")}
							</Button>
						</div>
					) : null}
				</div>
				{transactionType === TRANSACTION_TYPE.ROLLBACK ? projectType === PROJECT_TYPE.ONLINE ? <RollbackOnlineFilters /> : <RollbackRetailFilters /> : projectType === PROJECT_TYPE.ONLINE ? <ResultOnlineFilters /> : <ResultRetailFilters />}
			</div>
			<Table
				loading={isLoading}
				columns={columns}
				data={transactions}
				loadFn={getTransactions}
				sorting={sorting}
				filters={filters}
				setSortingFn={setTransactionsSorting}
				setFiltersFn={setTransactionsFilters}
				total={total}
				updateProps={[globalPartnerId]}
				actions={
					hasPermission({ resource: PERMISSION_RESOURCE.FAILED_TRANSACTION, action: PERMISSION_ACTION.MODIFY })
						? {
								delete: {
									messageKey: "transaction",
									handler: (record) => resendTransaction(record.id),
									title: t("common.resend"),
									icon: <i className="icon-sync" />,
									hidden: (record) => record.lastStatus === TRANSACTION_STATUS.SUCCESS
								}
							}
						: {}
				}
				isDisabled={() => false}
				detailsType="table"
				details={(record) =>
					record.history
						? {
								columns: [
									{
										title: t("pages.dashboard.transactions.transaction_id"),
										render: () => record.id,
										dataIndex: "id"
									},
									{
										title: t("pages.dashboard.transactions.date"),
										dataIndex: "actionTime",
										render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
									},
									{
										title: t("pages.dashboard.transactions.status"),
										dataIndex: "status",
										render: (value) => `${toUpperCaseFirstLetter((Object.keys(TRANSACTION_STATUS).find((key) => TRANSACTION_STATUS[key] === value) || "").toLowerCase().replace(/_/g, " "))} - ${value}`
									},
									{
										title: t("pages.dashboard.transactions.resend_by"),
										dataIndex: "actionBy"
									},
									{
										title: t("pages.dashboard.transactions.message"),
										dataIndex: "message",
										render: (value) => (
											<span title={value} className="table-col-shorten-text">
												{value}
											</span>
										)
									}
								],
								data: record.history ? record.history : [],
								uniqueKey: "actionTime"
							}
						: null
				}
				detailsLoadFn={(record) => (!record.history ? getTransactionHistory(record.id) : false)}
				enableReload={true}
			/>
			<Question type="confirm" onOk={() => resendAllTransactions(projectType, transactionType)} onCancel={() => setShowResendAllPopup(false)} isVisible={showResendAllPopup} message={t("pages.dashboard.transactions.resend_all_question")} isLoading={isResendInProcess} />
		</div>
	);
};

/** TransactionsTableComponent propTypes
 * PropTypes
 */
TransactionsTableComponent.propTypes = {
	/** Function to get transactions */
	getTransactions: PropTypes.func,
	/** Function to get transaction history */
	getTransactionHistory: PropTypes.func,
	/** Function to process transaction */
	resendTransaction: PropTypes.func,
	/** The array of transactions */
	transactions: PropTypes.arrayOf(transactionType),
	/** Is true when loading transactions */
	isLoading: PropTypes.bool,
	/** Function to set transactions sorting details */
	setTransactionsSorting: PropTypes.func,
	/** Function to set transactions filters */
	setTransactionsFilters: PropTypes.func,
	/** Transactions sorting details */
	sorting: sortingType,
	/** Transactions filters */
	filters: PropTypes.object,
	/** Transactions total count */
	total: PropTypes.number,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, is true when resend all request is in process */
	isResendInProcess: PropTypes.bool,
	/** Redux action to resend all transactions */
	resendAllTransactions: PropTypes.func,
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	/** The Transaction Type (rollback/result) */
	transactionType: PropTypes.oneOf(Object.values(TRANSACTION_TYPE))
};

const mapStateToProps = (state) => {
	return {
		globalPartnerId: state.partner.globalPartnerId,
		isResendInProcess: state.transactions.isResendInProcess
	};
};

const mapDispatchToProps = (dispatch) => ({
	resendAllTransactions: (projectType, type) => {
		dispatch(resendAllTransactions(projectType, type));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsTableComponent);
