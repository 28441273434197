import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { SET_OPERATORS, OPERATORS_BEFORE, OPERATORS_FINISH, OPERATORS_SET_SORTING } from "store/actionTypes";

const setOperators = (operators) => ({
	type: SET_OPERATORS,
	payload: { operators }
});

const setOperatorsBefore = () => ({
	type: OPERATORS_BEFORE
});

const setOperatorsFinished = () => ({
	type: OPERATORS_FINISH
});

export const setOperatorsSorting = (sorting) => ({
	type: OPERATORS_SET_SORTING,
	payload: { sorting }
});

export const getOperators = () => {
	return (dispatch, getState) => {
		dispatch(setOperatorsBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_OPERATORS}`,
			method: Methods.GET,
			params: {
				...getState().operators.sorting
			}
		})
			.then(({ data: { value: operators } }) => {
				dispatch(setOperators(operators));
				dispatch(setOperatorsFinished());
			})
			.catch((ex) => {
				dispatch(setOperatorsFinished());
			});
	};
};

export const saveOperator = (operator) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_OPERATOR}`,
			method: Methods.POST,
			data: operator
		})
			.then(({ data: { status, message: msg } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(getOperators());
				}
			})
			.catch((ex) => {
				console.log(ex);
			});
	};
};
