import { useState, Fragment } from "react";
import PropTypes from "prop-types";

import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "antd";
import { hasPermission } from "utils/permissions";
import ApiUrls from "constants/api.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import ExportButton from "components/common/exportButton";
import StreamAddComponent from "./stream-add.component";
import { getStreams, setStreamsSorting } from "store/actions/dashboard/streams/streams.action";
import { createDateTimeString } from "utils/common";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import Paths from "constants/path.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";
import sortingType from "types/common/sorting.type";

/** Betslips Page Component */
const StreamsComponent = ({ getStreams, total, streams, isLoading, setStreamsSorting, sorting, globalPartnerId }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	// /** State to show/hide partner creation popup */
	const [showCreatePopup, setShowCreatePopup] = useState(false);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.streams.id"),
			dataIndex: "id",
			sorter: false
		},
		{
			title: t("pages.dashboard.streams.name"),
			dataIndex: "name",
			sorter: false
		},
		{
			title: t("pages.dashboard.streams.partnerType"),
			dataIndex: "partnerName",
			render: (value) => (
				<div className="row-with-icon">
					<i className={value ? "icon-partner-specific" : "icon-generic"}></i>
					<span>{value ? value : t("pages.dashboard.games.generic")}</span>
				</div>
			),
			sorter: false
		},
		{
			title: t("pages.dashboard.streams.games"),
			dataIndex: "games",
			multi: true,
			multiMapper: (value) => (Array.isArray(value) ? value.map((v) => `${v.name} ${createDateTimeString(v.from)} - ${createDateTimeString(v.to)}`) : []),
			sorter: false
		},

		{
			title: t("pages.dashboard.streams.created"),
			dataIndex: "created",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.streams.lastUpdate"),
			dataIndex: "lastUpdate",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		}
	];

	const generateAction = () => {
		let key = null;

		if (
			hasPermission({
				resource: PERMISSION_RESOURCE.STREAMS,
				action: PERMISSION_ACTION.VIEW
			})
		) {
			key = "view";
		}

		if (
			hasPermission({
				resource: PERMISSION_RESOURCE.STREAMS,
				action: PERMISSION_ACTION.MODIFY
			})
		) {
			key = "edit";
		}

		if (!key) {
			return {};
		}

		return {
			[key]: {
				handler: (record) => {
					navigate(Paths.DASHBOARD_STREAMS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, record.id));
				}
			}
		};
	};

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.streams") }]} />
				<div className="dashboard-section-content dashboard-section-content-stream-tooltip">
					<div className="table-header">
						{hasPermission({
							resource: PERMISSION_RESOURCE.STREAMS,
							action: PERMISSION_ACTION.EXPORT
						}) ? (
							<div className="table-buttons-dropdowns">
								<ExportButton columns={columns.filter((c) => c.dataIndex !== "games" && c).map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t("pages.dashboard.menu.streams")} url={ApiUrls.EXPORT_STREAMS} sorting={sorting} />
							</div>
						) : null}

						{hasPermission({
							resource: PERMISSION_RESOURCE.STREAMS,
							action: PERMISSION_ACTION.CREATE
						}) ? (
							<div className="table-buttons-dropdowns table-buttons-dropdowns-button-broadcast">
								<div className="table-buttons-dropdowns-button">
									<Button onClick={() => setShowCreatePopup(true)} type="primary">
										<i className="icon-broadcast"></i>
										{t("pages.dashboard.streams.createBroadcast")}
									</Button>
								</div>
							</div>
						) : null}
					</div>

					<Table loading={isLoading} columns={columns} data={streams} loadFn={getStreams} sorting={sorting} setSortingFn={setStreamsSorting} total={total} actions={generateAction()} updateProps={[globalPartnerId]} />
				</div>
			</div>

			{showCreatePopup ? <StreamAddComponent onClose={() => setShowCreatePopup(false)} /> : null}
		</Fragment>
	);
};

/** StreamsComponent propTypes
 * PropTypes
 */
StreamsComponent.propTypes = {
	/** Redux action to get streams */
	getStreams: PropTypes.func,
	/** Redux state property, partners total count */
	total: PropTypes.number,
	/** Redux state property, represents the array of partners  */
	streams: PropTypes.arrayOf(PropTypes.object),
	/** Redux state property, is true when loading partners */
	isLoading: PropTypes.bool,
	/** Redux action to set partners sorting details */
	setStreamsSorting: PropTypes.func,
	/** Redux state property, partners sorting details */
	sorting: sortingType,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getStreams: () => {
		dispatch(getStreams());
	},
	setStreamsSorting: (sorting) => {
		dispatch(setStreamsSorting(sorting));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.streams.isLoading,
		total: state.streams.total,
		streams: state.streams.streams,
		sorting: state.streams.sorting,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StreamsComponent);
