export const JOB_STATE = {
	RUNNING: 0,
	SUCCESS: 1,
	FAILED: 2
};

export const JOB_GROUP = {
	SYSTEM: 1,
	FOOTBALL_SINGLE_MATCH: 2,
	HORSE_RACE: 3,
	GREYHOUND_RACE: 4,
	ENGLISH_LEAGUE: 5,
	AFRICAN_CUP: 6,
	KENO: 7,
	WORLD_CUP: 8,
	EUROPEAN_CUP: 9,
	CHAMPIONS_LEAGUE: 10,
	COPA_LIBERTADORES: 11,
	STEEPLECHASING: 12,
	PENALTY_SHOOTOUT: 13,
	TURKISH_LEAGUE: 14
}
