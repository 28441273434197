import PropTypes from "prop-types";

import { BETSHOP_LIMIT_FREQUENCY } from "constants/betshop.constants";

export default PropTypes.shape({
	enabled: PropTypes.bool,
	fixedAmount: PropTypes.number,
	frequencySettings: PropTypes.shape({
		every: PropTypes.oneOf(Object.values(BETSHOP_LIMIT_FREQUENCY)),
		at: PropTypes.shape({
			hour: PropTypes.number,
			minute: PropTypes.number
		}),
		timeZoneId: PropTypes.string,
		dst: PropTypes.oneOf([0, -1, 1])
	})
});
