import { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Tabs } from "antd";
import ProjectAddComponent from "./data-add.component";
import DataTableComponent from "./data-table.component";
import { getUserProjectAccess, deleteUserProjectAccess, addUserProjectAccess } from "store/actions/dashboard/userManagement/users/projects.action";
import { getUserBetShopAccess, deleteUserBetShopAccess, addUserBetShopAccess } from "store/actions/dashboard/userManagement/users/betshops.action";
import { getUserPartnerAccess } from "store/actions/dashboard/userManagement/users/partners.action";
import { updateLocationHash, getHashValue } from "utils/common";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import { PARTNER_TYPE } from "constants/partner.constants";
import userProjectAccessType from "types/user/projectAccess.type";
import userBetShopAccessType from "types/user/betShopAccess.type";
import { PERMISSION_REQUEST_TYPE } from "constants/permissionRequest.constants";

/** User Edit Page Project Settings Tab Component */
const ProjectsAndBetShopsComponent = ({ getUserProjectAccess, deleteUserProjectAccess, addUserProjectAccess, getUserBetShopAccess, addUserBetShopAccess, deleteUserBetShopAccess, projectAccess, betShopAccess, partnerType }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const hasUserProjectsViewPermission = hasPermission({ resource: PERMISSION_RESOURCE.USER_PROJECTS, action: PERMISSION_ACTION.VIEW });
	const hasUserBetshopsViewPermission = hasPermission({ resource: PERMISSION_RESOURCE.USER_BETSHOPS, action: PERMISSION_ACTION.VIEW });

	/** Has retail type partner
	 * @function
	 * @param {number} type
	 * @returns {boolean}
	 * @memberOf ProjectsAndBetShopsComponent
	 */
	const hasRetailPartner = () => !!(partnerType & PARTNER_TYPE.RETAIL);

	/** Has online type partner
	 * @function
	 * @param {number} type
	 * @returns {boolean}
	 * @memberOf ProjectsAndBetShopsComponent
	 */
	const hasOnlinePartner = () => !!(partnerType & PARTNER_TYPE.ONLINE);

	/** State of tab project/betshop */
	const [tabKey, setTabKey] = useState(null);

	/** State to show/hide project/betshop add popup */
	const [showAddPopup, setShowAddPopup] = useState(null);

	// Sets initial tabkey
	useEffect(() => {
		const subTab = getHashValue("subtab");
		const boolHasOnlinePartner = hasOnlinePartner();
		const boolHasRetailPartner = hasRetailPartner();
		const tab = subTab ? subTab : boolHasOnlinePartner && hasUserProjectsViewPermission ? "1" : boolHasRetailPartner && hasUserBetshopsViewPermission ? "2" : null;
		setTabKey(tab);
	}, [hasOnlinePartner, hasRetailPartner]);

	/** Load user project access*/
	useEffect(() => {
		if (routeParams.id) {
			switch (Number(tabKey)) {
				case PROJECT_TYPE.ONLINE:
					getUserProjectAccess(routeParams.id);
					break;
				case PROJECT_TYPE.RETAIL:
					getUserBetShopAccess(routeParams.id);
					break;
				default:
					break;
			}
		}
	}, [tabKey, routeParams.id]);

	/** Close project/partner add popup, after save */
	useEffect(() => {
		setShowAddPopup(null);
	}, [projectAccess]);

	return (
		<Fragment>
			{
				<Tabs
					animated={false}
					className="sub-tabs"
					defaultActiveKey={getHashValue("subtab") ? getHashValue("subtab") : hasOnlinePartner() ? "1" : "2"}
					onChange={(key) => {
						updateLocationHash("subtab=" + key, true);
						setTabKey(key);
					}}
					items={[
						hasUserProjectsViewPermission && hasOnlinePartner()
							? {
									key: "1",
									label: t("pages.dashboard.users.projects"),
									children: (
										<DataTableComponent
											projectType={PROJECT_TYPE.ONLINE}
											onAddButtonClick={(partnerId) => setShowAddPopup({ partnerId: partnerId, projectType: PROJECT_TYPE.ONLINE })}
											permission={PERMISSION_RESOURCE.USER_PROJECTS}
											dataAccess={projectAccess}
											deleteDataAccess={deleteUserProjectAccess}
											pendingRequestsNoteActionTypes={[PERMISSION_REQUEST_TYPE.ADD_PROJECT_ACCESS, PERMISSION_REQUEST_TYPE.DELETE_PROJECT_ACCESS]}
										/>
									)
								}
							: null,
						hasUserBetshopsViewPermission && hasRetailPartner()
							? {
									key: "2",
									label: t("pages.dashboard.users.betshops"),
									children: (
										<DataTableComponent
											projectType={PROJECT_TYPE.RETAIL}
											onAddButtonClick={(partnerId) => setShowAddPopup({ partnerId: partnerId, projectType: PROJECT_TYPE.RETAIL })}
											permission={PERMISSION_RESOURCE.USER_BETSHOPS}
											dataAccess={betShopAccess}
											deleteDataAccess={deleteUserBetShopAccess}
											pendingRequestsNoteActionTypes={[PERMISSION_REQUEST_TYPE.ADD_BETSHOP_ACCESS, PERMISSION_REQUEST_TYPE.DELETE_BETSHOP_ACCESS]}
										/>
									)
								}
							: null
					]}
				/>
			}
			{showAddPopup ? (
				<ProjectAddComponent
					onClose={() => setShowAddPopup(null)}
					details={showAddPopup}
					addData={Number(tabKey) === PROJECT_TYPE.ONLINE ? addUserProjectAccess : Number(tabKey) === PROJECT_TYPE.RETAIL ? addUserBetShopAccess : Function.prototype}
					dataAccess={Number(tabKey) === PROJECT_TYPE.ONLINE ? projectAccess : Number(tabKey) === PROJECT_TYPE.RETAIL ? betShopAccess : Function.prototype}
				/>
			) : null}
		</Fragment>
	);
};

/** ProjectsAndBetShopsComponent propTypes
 * PropTypes
 */
ProjectsAndBetShopsComponent.propTypes = {
	/** Redux action to get user project access */
	getUserProjectAccess: PropTypes.func,
	/** Redux action to get delete project access */
	deleteUserProjectAccess: PropTypes.func,
	/** Redux action to get add project access */
	addUserProjectAccess: PropTypes.func,
	/** Redux action to get user betshop access */
	getUserBetShopAccess: PropTypes.func,
	/** Redux action to get delete betshop access */
	deleteUserBetShopAccess: PropTypes.func,
	/** Redux action to get add betshop access */
	addUserBetShopAccess: PropTypes.func,
	/** Redux state property, represents the projects access info of user  */
	projectAccess: userProjectAccessType,
	/** Redux state property, represents the betshop access info of user  */
	betShopAccess: userBetShopAccessType,
	// Redux state property, respresents partners' types of user
	partnerType: PropTypes.number,
	/** Redux action to get user partner access */
	getUserPartnerAccess: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getUserProjectAccess: (id) => {
		dispatch(getUserProjectAccess(id));
	},
	deleteUserProjectAccess: (id, partnerId, projectId, type, note) => {
		dispatch(deleteUserProjectAccess(id, partnerId, projectId, type, note));
	},
	addUserProjectAccess: (id, partnerId, projectId, type, note) => {
		dispatch(addUserProjectAccess(id, partnerId, projectId, type, note));
	},
	getUserBetShopAccess: (id) => {
		dispatch(getUserBetShopAccess(id));
	},
	deleteUserBetShopAccess: (id, partnerId, projectId, type, note) => {
		dispatch(deleteUserBetShopAccess(id, partnerId, projectId, type, note));
	},
	addUserBetShopAccess: (id, partnerId, projectId, type, note) => {
		dispatch(addUserBetShopAccess(id, partnerId, projectId, type, note));
	},
	getUserPartnerAccess: (id) => {
		dispatch(getUserPartnerAccess(id));
	}
});

const mapStateToProps = (state) => {
	return {
		projectAccess: state.users.editingUser.projectAccess,
		betShopAccess: state.users.editingUser.betShopAccess,
		partnerType: state.users.editingUser.generalInfo.partnerType
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsAndBetShopsComponent);
