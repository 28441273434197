import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PROJECT_TYPE } from "constants/common.constants";
import EventsComponent from "../common";
import eventType from "types/event/event.type";
import sortingType from "types/common/sorting.type";
import { GAME_CATEGORY } from "constants/game.constants";

/** Events Page retail events Tab Component */
const RetailEventsComponent = ({ events, sorting, filters, total, activeTab, gameCategory }) => {
	return <EventsComponent events={events} sorting={sorting} filters={filters} total={total} projectType={PROJECT_TYPE.RETAIL} activeTab={activeTab} gameCategory={gameCategory} />;
};

/** RetailEventsComponent propTypes
 * PropTypes
 */
RetailEventsComponent.propTypes = {
	/** Redux state property, represents the array of retail events */
	events: PropTypes.arrayOf(eventType),
	/** Redux state property, retail events sorting details */
	sorting: sortingType,
	/** Redux state property, retail events filters */
	filters: PropTypes.object,
	/** Redux state property, retail events total count */
	total: PropTypes.number,
	/* Active tab */
	activeTab: PropTypes.string,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapStateToProps = (state, props) => {
	const { gameCategory } = props;
	return {
		events: state.events.retail[gameCategory].events,
		total: state.events.retail[gameCategory].total,
		sorting: state.events.retail[gameCategory].sorting,
		filters: state.events.retail[gameCategory].filters
	};
};

export default connect(mapStateToProps, null)(RetailEventsComponent);
