import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Form, Col, Row, Modal, Input } from "antd";
const { Item: FormItem } = Form;

import { createPermissionGroup, getPermissionGroups } from "store/actions/dashboard/userManagement/permissionGroups/myGroups.action";
import Paths from "constants/path.constants";

import { POPUP_SIZE } from "constants/common.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import { hasPermission } from "utils/permissions";

/** Permission Group Creating Popup Component */
const PermissionGroupAddComponent = ({ isSaving, createPermissionGroup, getPermissionGroups, onClose, globalPartnerId }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;

	/** Fires when form submitted
	 * @function
	 * @memberOf PermissionGroupAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ name, description }) => {
				createPermissionGroup({ name, description }, ({ id }) => {
					if (hasPermission({ resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.MODIFY })) {
						navigate(Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, `${id}?PI=${globalPartnerId}`));
					} else {
						getPermissionGroups();
						onClose();
					}
				});
			})
			.catch(Function.prototype);
	};

	return (
		<Modal open={true} title={t("pages.dashboard.permissionGroups.create_group")} cancelText={t("common.cancel")} okText={t("common.create")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.SMALL} maskClosable={false} closable={false} okButtonProps={{ loading: isSaving }} centered>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					name: "",
					description: ""
				}}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={`${t("pages.dashboard.permissionGroups.name")} *`} name="name" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
							<Input maxLength={100} placeholder={`${t("common.enter")} ${t("pages.dashboard.permissionGroups.name")}`} autoFocus={true} autoComplete="off" />
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem label={`${t("pages.dashboard.permissionGroups.description")}`} name="description" rules={[{ max: 10000, message: t("validation.field_invalid") }]}>
							<Input.TextArea maxLength={10000} rows={4} placeholder={t("pages.dashboard.permissionGroups.description")} />
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** PermissionGroupAddComponent propTypes
 * PropTypes
 */
PermissionGroupAddComponent.propTypes = {
	/** Redux state property, is true when creating permission group request is in process */
	isSaving: PropTypes.bool,
	/** Redux action to create permission group */
	createPermissionGroup: PropTypes.func,
	/** Redux action to get permission groups */
	getPermissionGroups: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Fires on popup close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	createPermissionGroup: (group, onSuccess) => {
		dispatch(createPermissionGroup(group, onSuccess));
	},
	getPermissionGroups: () => {
		dispatch(getPermissionGroups(true));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.permissionGroups.isSaving,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionGroupAddComponent);
