import { Layout as AntLayout } from "antd";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ParticleCanvas from "components/common/auth/particle-canvas.component";

import { buildPathToStaticFolderOfCDN } from "utils/common";

const { Content } = AntLayout;

const Layout = ({ children }) => {
	const { t } = useTranslation();

	return (
		<div className="auth_layout">
			<ParticleCanvas />
			<Content>
				<div className="form-container">
					<div className="logo">
						<img src={buildPathToStaticFolderOfCDN("admin-images/logo-white.png")} alt="logo" />
					</div>
					<div className="form-group">
						<div className="main">{children}</div>
					</div>
					<div className="copy">
						<span>{t("common.copyright") + " " + new Date().getFullYear() + " Digitain"}</span>
					</div>
				</div>
			</Content>
		</div>
	);
};

Layout.propTypes = {
	children: PropTypes.node
};

const AuthLayoutRoute = () => {
	return (
		<Layout>
			<Outlet />
		</Layout>
	);
};

export default AuthLayoutRoute;
