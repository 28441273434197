import PropTypes from "prop-types";

import { PROJECT_TYPE } from "constants/common.constants";
import { SURVEY_STATE } from "constants/survey.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	responseRate: PropTypes.number,
	responseScore: PropTypes.number,
	totalResponses: PropTypes.number,
	playersCount: PropTypes.number,
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	state: PropTypes.oneOf(Object.values(SURVEY_STATE)),
	partners: PropTypes.arrayOf(PropTypes.string),
	lastModifiedAt: PropTypes.string,
	lastModifiedBy: PropTypes.string,
	createdAt: PropTypes.string,
	createdBy: PropTypes.string,
	enabled: PropTypes.bool,
	isTesting: PropTypes.bool
});
