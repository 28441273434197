import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Tabs } from "antd";

import Breadcrumbs from "components/common/breadcrumbs";
import RulesContent from "components/dashboard/cms/rules/rulesContent";

import { getSystemAvailableLanguages } from "store/actions/dashboard/settings/systemLanguages/systemLanguages.action";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { hasOneOfPermissions } from "utils/permissions";
import { updateLocationHash, getHashValue } from "utils/common";
import { GAME_CATEGORY } from "constants/game.constants";

const getDefaultActiveKey = () => getHashValue("tab") || GAME_CATEGORY.SCHEDULED.toString();

/** Rules Page Component */
const Rules = ({ systemLanguages, getSystemAvailableLanguages }) => {
	const { t } = useTranslation();
	const { hash } = useLocation();

	const [activeKey, setActiveKey] = useState(getDefaultActiveKey);

	/** Load system languages */
	useEffect(() => {
		if (Object.keys(systemLanguages).length === 0) {
			getSystemAvailableLanguages();
		}
	}, []);

	/** Update active tab on location hash change */
	useEffect(() => {
		setActiveKey(getDefaultActiveKey());
	}, [hash]);

	return (
		<div className="dashboard-section table-section">
			<Breadcrumbs items={[{ title: t("pages.dashboard.menu.rules") }]} />
			<Tabs
				animated={false}
				activeKey={activeKey}
				onChange={(key) => updateLocationHash("tab=" + key)}
				destroyInactiveTabPane={true}
				items={[
					hasOneOfPermissions([
						{ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW },
						{ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.VIEW }
					])
						? {
								key: GAME_CATEGORY.SCHEDULED.toString(),
								label: t(`pages.dashboard.translations.games_rules_${GAME_CATEGORY.SCHEDULED}`),
								children: <RulesContent gameCategory={GAME_CATEGORY.SCHEDULED} />
							}
						: null,
					hasOneOfPermissions([
						{ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW },
						{ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.VIEW }
					])
						? {
								key: GAME_CATEGORY.INSTANT.toString(),
								label: t(`pages.dashboard.translations.games_rules_${GAME_CATEGORY.INSTANT}`),
								children: <RulesContent gameCategory={GAME_CATEGORY.INSTANT} />
							}
						: null
				]}
			/>
		</div>
	);
};

Rules.propTypes = {
	/** Redux state property, represents the object of loaded system languages */
	systemLanguages: PropTypes.object,
	/** Redux action to get system available languages */
	getSystemAvailableLanguages: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getSystemAvailableLanguages: () => {
		dispatch(getSystemAvailableLanguages());
	}
});

const mapStateToProps = (state) => {
	return {
		systemLanguages: state.systemLanguages.systemAvailableLanguages
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Rules);
