import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { setGamesActionBefore, setGamesActionFinished, setGameSaveActionBefore, setGameSaveActionFinished } from "./games.action";

import { SET_GAME_GENERAL_INFO, RESET_GAME_GENERAL_INFO, SET_GAME_LIVE_MONITOR_LOGO_ID, SET_GAME_STREAM_URL } from "store/actionTypes";
import { GAME_CATEGORY } from "constants/game.constants";

export const setGameGeneralInfo = (info) => ({
	type: SET_GAME_GENERAL_INFO,
	payload: { info }
});

export const resetGameGeneralInfo = () => ({
	type: RESET_GAME_GENERAL_INFO
});

export const getGameGeneralInfo = (id, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.SCHEDULED_GAME_GET_GENERAL_INFO : ApiUrls.INSTANT_GAME_GET_GENERAL_INFO;
	return (dispatch) => {
		dispatch(setGamesActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: info } }) => {
				dispatch(setGameGeneralInfo(info));
				dispatch(setGamesActionFinished());
			})
			.catch((ex) => {
				dispatch(setGamesActionFinished());
			});
	};
};

export const saveGameGeneralInfo = (info, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.SCHEDULED_GAME_SAVE_GENERAL_INFO : ApiUrls.INSTANT_GAME_SAVE_GENERAL_INFO;
	return (dispatch) => {
		dispatch(setGameSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.POST,
			data: {
				...info
			}
		})
			.then(({ data: { message: msg, status, value: info } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setGameGeneralInfo(info));
				}
				dispatch(setGameSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setGameSaveActionFinished());
			});
	};
};

export const deleteLiveMonitorLogo = (gameId) => {
	return () => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_DELETE_LIVE_MONITOR_LOGO}`,
			method: Methods.DELETE,
			data: { gameId }
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
				}
			})
			.catch((ex) => {
				console.log(ex);
			});
	};
};

export const setLiveMonitorLogoId = (logoId) => ({
	type: SET_GAME_LIVE_MONITOR_LOGO_ID,
	payload: { logoId }
});
