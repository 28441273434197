import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls, { SPECIFIC_ENDPOINTS } from "constants/api.constants";
import { getTimeZone } from "utils/dateTime";

import {
	DASHBOARD_SET_APIKEY_NAMES,
	DASHBOARD_SET_APIKEY_NAMES_BEFORE,
	DASHBOARD_SET_APIKEY_NAMES_FINISH,
	DASHBOARD_SET_BETSHOP_NAMES,
	DASHBOARD_SET_BETSHOP_NAMES_BEFORE,
	DASHBOARD_SET_BETSHOP_NAMES_FINISH,
	DASHBOARD_PLAYERS_ACTIVITIES_BEFORE,
	DASHBOARD_PLAYERS_ACTIVITIES_FINISH,
	DASHBOARD_SET_PLAYERS_ACTIVITIES,
	DASHBOARD_SET_ONLINE_PLAYERS_COUNT_BEFORE,
	DASHBOARD_SET_ONLINE_PLAYERS_COUNT,
	DASHBOARD_SET_PENDING_BETSLIPS_COUNT_BEFORE,
	DASHBOARD_SET_PENDING_BETSLIPS_COUNT,
	DASHBOARD_BETSLIPS_ACTIVITIES_BEFORE,
	DASHBOARD_BETSLIPS_ACTIVITIES_FINISH,
	DASHBOARD_SET_BETSLIPS_ACTIVITIES,
	DASHBOARD_TOP_MARKETS_BEFORE,
	DASHBOARD_TOP_MARKETS_FINISH,
	DASHBOARD_SET_TOP_MARKETS,
	DASHBOARD_GAMES_PERFORMANCE_BEFORE,
	DASHBOARD_GAMES_PERFORMANCE_FINISH,
	DASHBOARD_SET_GAMES_PERFORMANCE,
	DASHBOARD_SET_FILTERS,
	DASHBOARD_TOP_PLAYERS_BEFORE,
	DASHBOARD_TOP_PLAYERS_FINISH,
	DASHBOARD_SET_TOP_PLAYERS,
	DASHBOARD_SET_TOP_PLAYERS_SORTING,
	DASHBOARD_PARTNERS_REVENUE_BEFORE,
	DASHBOARD_PARTNERS_REVENUE_FINISH,
	DASHBOARD_SET_PARTNERS_REVENUE,
	DASHBOARD_SET_PARTNERS_REVENUE_FILTER,
	DASHBOARD_CALCULATE_GAMES_PERFORMANCE
} from "../../../actionTypes";

import { PROJECT_TYPE } from "constants/common.constants";
import { GAME_CATEGORY, SCHEDULED_GAME_TYPE } from "constants/game.constants";
import { getGamesFromConfig } from "utils/common";
import { keyToGameData } from "utils/dashboard";

const setDashboardApiKeyNamesBefore = () => ({
	type: DASHBOARD_SET_APIKEY_NAMES_BEFORE
});

const setDashboardApiKeyNamesFinished = () => ({
	type: DASHBOARD_SET_APIKEY_NAMES_FINISH
});

const setDashboardApiKeyNames = (data) => ({
	type: DASHBOARD_SET_APIKEY_NAMES,
	payload: { data }
});

const setDashboardBetshopNamesBefore = () => ({
	type: DASHBOARD_SET_BETSHOP_NAMES_BEFORE
});

const setDashboardBetshopNamesFinished = () => ({
	type: DASHBOARD_SET_BETSHOP_NAMES_FINISH
});

const setDashboardBetshopNames = (data) => ({
	type: DASHBOARD_SET_BETSHOP_NAMES,
	payload: { data }
});

const setDashboardPlayersActivitiesBefore = () => ({
	type: DASHBOARD_PLAYERS_ACTIVITIES_BEFORE
});

const setDashboardPlayersActivitiesFinished = () => ({
	type: DASHBOARD_PLAYERS_ACTIVITIES_FINISH
});

const setDashboardPlayersActivities = (data) => ({
	type: DASHBOARD_SET_PLAYERS_ACTIVITIES,
	payload: { data }
});

const setDashboardOnlinePlayersCountBefore = () => ({
	type: DASHBOARD_SET_ONLINE_PLAYERS_COUNT_BEFORE
});

const setDashboardOnlinePlayersCount = (count) => ({
	type: DASHBOARD_SET_ONLINE_PLAYERS_COUNT,
	payload: { count }
});

const setDashboardBetslipsActivitiesBefore = () => ({
	type: DASHBOARD_BETSLIPS_ACTIVITIES_BEFORE
});

const setDashboardBetslipsActivitiesFinished = () => ({
	type: DASHBOARD_BETSLIPS_ACTIVITIES_FINISH
});

const setDashboardBetslipsActivities = (data, projectType) => ({
	type: DASHBOARD_SET_BETSLIPS_ACTIVITIES,
	payload: { data, projectType }
});

const setDashboardPendingBetslipsCountBefore = () => ({
	type: DASHBOARD_SET_PENDING_BETSLIPS_COUNT_BEFORE
});

const setDashboardPendingBetslipsCount = (count, projectType) => ({
	type: DASHBOARD_SET_PENDING_BETSLIPS_COUNT,
	payload: { count, projectType }
});

const setDashboardTopMarketsBefore = () => ({
	type: DASHBOARD_TOP_MARKETS_BEFORE
});

const setDashboardTopMarketsFinished = () => ({
	type: DASHBOARD_TOP_MARKETS_FINISH
});

const setDashboardTopMarkets = (data, projectType) => ({
	type: DASHBOARD_SET_TOP_MARKETS,
	payload: { data, projectType }
});

const setDashboardGamesPerformanceBefore = () => ({
	type: DASHBOARD_GAMES_PERFORMANCE_BEFORE
});

export const setDashboardGamesPerformanceFinished = () => ({
	type: DASHBOARD_GAMES_PERFORMANCE_FINISH
});

const setDashboardGamesPerformance = (data, projectType) => ({
	type: DASHBOARD_SET_GAMES_PERFORMANCE,
	payload: { data, projectType }
});

export const resetDashboardGamesPerformance = (projectType) => {
	return setDashboardGamesPerformance(
		{
			gamesPerformance: {
				source: {},
				calculated: { scheduledGamesPerformanceChart: [], instantGamesPerformanceChart: [] },
				totalTurnover: null,
				totalWinAmount: null,
				totalGGRAmount: null,
				totalGGRPercent: null
			},
			totalBonusBetsCount: null
		},
		projectType
	);
};

export const calculateDashboardGamesPerformance = (data, projectType) => ({
	type: DASHBOARD_CALCULATE_GAMES_PERFORMANCE,
	payload: { data, projectType }
});

const setTopPlayersBefore = () => ({
	type: DASHBOARD_TOP_PLAYERS_BEFORE
});

const setTopPlayersFinished = () => ({
	type: DASHBOARD_TOP_PLAYERS_FINISH
});

const setTopPlayers = (data) => ({
	type: DASHBOARD_SET_TOP_PLAYERS,
	payload: { data }
});

export const setDashboardTopPlayersSorting = (sorting) => ({
	type: DASHBOARD_SET_TOP_PLAYERS_SORTING,
	payload: { sorting }
});

export const setDashboardFilters = (filters, projectType) => ({
	type: DASHBOARD_SET_FILTERS,
	payload: { filters, projectType }
});

export const getDashboardApiKeyNames = (gameCategory) => {
	return (dispatch, getState) => {
		dispatch(setDashboardApiKeyNamesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTOSUGGESTION_GET_APIKEYS}`,
			method: Methods.GET,
			params: {
				gameCategory
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setDashboardApiKeyNames(data));
			})
			.finally(() => {
				dispatch(setDashboardApiKeyNamesFinished());
			});
	};
};

export const getDashboardBetshopNames = (projectId, period) => {
	return (dispatch, getState) => {
		dispatch(setDashboardBetshopNamesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTOSUGGESTION_GET_BETSHOPS}`,
			method: Methods.GET
		})
			.then(({ data: { value: data } }) => {
				dispatch(setDashboardBetshopNames(data));
			})
			.finally(() => {
				dispatch(setDashboardBetshopNamesFinished());
			});
	};
};

export const getDashboardPlayersActivities = (projectId, period, gameCategory, signal) => {
	return (dispatch, getState) => {
		if (getState().dashboard.online.isPlayerActivitiesLoading) return null;
		dispatch(setDashboardPlayersActivitiesBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.DASHBOARD_PLAYERS_ACTIVITIES}`,
			method: Methods.GET,
			params: {
				projectId,
				period,
				gameCategory,
				timeZone: getTimeZone(),
				projectType: PROJECT_TYPE.ONLINE
			},
			signal: signal
		})
			.then(({ data: { value: data } }) => {
				const keys = (
					gameCategory === GAME_CATEGORY.SCHEDULED
						? ["count"]
						: gameCategory === GAME_CATEGORY.INSTANT
							? ["instantCount"]
							: ["count", "instantCount"]
				);
				dispatch(setDashboardPlayersActivities(
					{
						...data,
						item2: data.item2.map(activite => {
							return ({
								time: activite.time,
								count: keys.reduce((acc, key) => acc + activite[key], 0)
							})
						})
					}
				));
				dispatch(setDashboardPlayersActivitiesFinished());
			})
			.catch(() => {
				dispatch(setDashboardPlayersActivitiesFinished());
			});
	};
};

export const getDashboardOnlinePlayersCount = () => {
	return (dispatch) => {
		dispatch(setDashboardOnlinePlayersCountBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.DASHBOARD_ONLINE_PLAYERS_COUNT}`,
			method: Methods.GET
		})
			.then(({ data: { value: count } }) => {
				dispatch(setDashboardOnlinePlayersCount(count));
			})
			.catch((ex) => {
				console.log(ex);
			});
	};
};

export const getDashboardPendingBetslipsCount = (projectType) => {
	return (dispatch) => {
		const { DASHBOARD_PENDING_BETSLIPS_COUNT } = SPECIFIC_ENDPOINTS[projectType];
		dispatch(setDashboardPendingBetslipsCountBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${DASHBOARD_PENDING_BETSLIPS_COUNT}`,
			method: Methods.GET,
			params: {
				projectType
			}
		})
			.then(({ data: { value: count } }) => {
				dispatch(setDashboardPendingBetslipsCount(count, projectType));
			})
			.catch((ex) => {
				console.log(ex);
			});
	};
};

export const getDashboardBetslipsActivities = (
	projectId,
	period,
	projectType,
	gameCategory,
	signal
) => {
	return (dispatch, getState) => {
		if (getState().dashboard.isBetslipActivitiesLoading) return null;
		dispatch(setDashboardBetslipsActivitiesBefore());
		const { DASHBOARD_BETSLIPS_ACTIVITIES } = SPECIFIC_ENDPOINTS[projectType];
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${DASHBOARD_BETSLIPS_ACTIVITIES}`,
			method: Methods.GET,
			params: {
				projectId,
				period,
				gameCategory,
				timeZone: getTimeZone(),
				projectType
			},
			signal: signal
		})
			.then(({ data: { value: data } }) => {
				const keys = (
					gameCategory === GAME_CATEGORY.SCHEDULED
						? ["count"]
						: gameCategory === GAME_CATEGORY.INSTANT
							? ["instantCount"]
							: ["count", "instantCount"]
				);
				dispatch(setDashboardBetslipsActivities(
					{
						...data,
						item2: data.item2.map(activite => {
							return ({
								time: activite.time,
								count: keys.reduce((acc, key) => acc + activite[key], 0)
							})
						})
					},
					projectType
				));
				dispatch(setDashboardBetslipsActivitiesFinished());
			})
			.catch(() => {
				dispatch(setDashboardBetslipsActivitiesFinished());
			});
	};
};

export const getTopMarkets = (projectId, period, gameCategoryGameType, projectType, gameCategoryArg) => {
	return (dispatch, getState) => {
		if (projectType === undefined || projectType === null) return null;
		if (getState().dashboard.isTopMarketsLoading) return null;

		dispatch(setDashboardTopMarketsBefore());

		const { DASHBOARD_TOP_MARKETS } = SPECIFIC_ENDPOINTS[projectType];
		const { gameCategory, gameType } = keyToGameData(gameCategoryGameType)

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${DASHBOARD_TOP_MARKETS}`,
			method: Methods.GET,
			params: {
				projectId,
				period,
				gameCategory: gameCategory || gameCategoryArg,
				timeZone: getTimeZone(),
				projectType,
				gameType
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setDashboardTopMarkets(data.markets.slice(0, 10), projectType));
				dispatch(setDashboardTopMarketsFinished());
			})
			.catch(() => {
				dispatch(setDashboardTopMarketsFinished());
			});
	};
};

export const getGamesPerformance = (projectId, period, projectType, gameCategory, signal) => {
	return (dispatch, getState) => {
		if (projectType === undefined || projectType === null) return null;
		if (getState().dashboard.isGamesPerformanceLoading) return null;
		dispatch(setDashboardGamesPerformanceBefore());
		const { DASHBOARD_GAMES_PERFORMANCE } = SPECIFIC_ENDPOINTS[projectType];
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${DASHBOARD_GAMES_PERFORMANCE}`,
			method: Methods.GET,
			params: {
				projectId,
				period,
				gameCategory,
				timeZone: getTimeZone(),
				projectType
			},
			signal: signal
		})
			.then(({ data: { value } }) => {
				const instantGamesPerformanceChart = value?.instantGamesPerformanceChart ?? {};
				const scheduledGamesPerformanceChart = value?.scheduledGamesPerformanceChart ?? {};
				const totalBonusBetsCount = value?.totalBonusBetsCount ?? 0;
				/*
					When response empty object or null need drow 0 amounts,
					this generation of empty object need for handle that case
				*/
				[instantGamesPerformanceChart, scheduledGamesPerformanceChart]
					.forEach(chartData => {
						if (Object.keys(chartData).length === 0) {
							getGamesFromConfig(gameCategory).forEach(gt => {
								chartData[gt.value] = new Object();
							})
						}
					})
				dispatch(setDashboardGamesPerformance(
					{
						gamesPerformance: {
							source: {
								instantGamesPerformanceChart,
								scheduledGamesPerformanceChart
							},
							calculated: {
								instantGamesPerformanceChart: [],
								scheduledGamesPerformanceChart: []
							},
							totalTurnover: null,
							totalWinAmount: null,
							totalGGRAmount: null,
							totalGGRPercent: null,
						},
						totalBonusBetsCount: totalBonusBetsCount
					},
					projectType
				));
				setTimeout(() => {
					dispatch(setDashboardGamesPerformanceFinished());
				}, 600);
			})
			.catch(() => {
				dispatch(setDashboardGamesPerformanceFinished());
			});
	};
};

export const getDashboardTopPlayers = (projectId, period, projectType, fromFirstPage, gameCategory, signal) => {
	return (dispatch, getState) => {
		if (getState().dashboard.isTopPlayersDataLoading) return null;
		dispatch(setTopPlayersBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.DASHBOARD_TOP_PLAYERS}`,
			method: Methods.GET,
			params: {
				projectId,
				period,
				gameCategory,
				timeZone: getTimeZone(),
				projectType,
				...getState().dashboard.online.topPlayers.sorting,
				page: fromFirstPage ? 1 : getState().dashboard.online.topPlayers.sorting.page
			},
			signal: signal
		})
			.then(({ data: { value } }) => {
				if (fromFirstPage) dispatch(setDashboardTopPlayersSorting({ ...getState().dashboard.online.topPlayers.sorting, page: 1 }));

				dispatch(setTopPlayers(value));
				dispatch(setTopPlayersFinished());
			})
			.catch(() => {
				dispatch(setTopPlayersFinished());
			});
	};
};

const setPartnersRevenueBefore = (projectType) => ({
	type: DASHBOARD_PARTNERS_REVENUE_BEFORE,
	payload: { projectType }
});

const setPartnersRevenueFinish = (projectType) => ({
	type: DASHBOARD_PARTNERS_REVENUE_FINISH,
	payload: { projectType }
});

const setPartnersRevenueData = (data, projectType) => ({
	type: DASHBOARD_SET_PARTNERS_REVENUE,
	payload: { data, projectType }
});

export const getPartnersRevenue = (filters, projectType) => {
	return (dispatch, getState) => {
		dispatch(setPartnersRevenueBefore(projectType));
		const { DASHBOARD_PARTNERS_REVENUE } = SPECIFIC_ENDPOINTS[projectType];
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${DASHBOARD_PARTNERS_REVENUE}`,
			method: Methods.GET,
			params: {
				period: filters.period,
				timeZone: getTimeZone(),
				projectType,
				allPartners: true
			}
		})
			.then(({ data: { value } }) => {
				dispatch(setPartnersRevenueData(value, projectType));
			})
			.finally(() => {
				dispatch(setPartnersRevenueFinish(projectType));
			});
	};
};

export const setPartnersRevenueFilter = (filters, projectType) => ({
	type: DASHBOARD_SET_PARTNERS_REVENUE_FILTER,
	payload: { filters, projectType }
});
