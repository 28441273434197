import { useRef, useState, memo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Input, Tooltip } from "antd";
const { Item: FormItem } = Form;

import { saveTranslation, resetTranslation } from "store/actions/dashboard/cms/translation/translation.action";

import { TRANSLATION_SOURCE_TYPE } from "constants/translation.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { hasPermission } from "utils/permissions";

/** Translation Field Component in Translations Table*/
const TranslationField = ({ lang, translationKey, groupId, subGroupId, source, isSystemDefault, saveTranslation, resetTranslation, formInstance }) => {
	const { t } = useTranslation();

	const [initialValue, setInitialValue] = useState("");
	const [inputValue, setInputValue] = useState("");
	const inputRef = useRef(null);

	const { setFieldsValue, getFieldValue } = formInstance;

	/** Fires on input focus
	 * @function
	 * @memberOf TranslationsComponent
	 */
	const handleFieldFocus = () => {
		setInitialValue(getFieldValue(["translations", translationKey, lang.toLowerCase()]));
		setInputValue(getFieldValue(["translations", translationKey, lang.toLowerCase()]));
	};

	/** Fires on input blur
	 * @function
	 * @memberOf TranslationsComponent
	 */
	const handleFieldBlur = () => {
		if (initialValue !== inputValue) {
			let text = inputValue;
			if (text) {
				text = text.trim();
			}
			if (text) {
				saveTranslation({
					key: translationKey,
					groupId: groupId,
					subGroupId: subGroupId,
					languageCode: lang,
					text: text,
					isSystemDefault: isSystemDefault
				});
			}
		}
	};

	/** Fires on filed press enter
	 * @function
	 * @memberOf TranslationsComponent
	 */
	const handleFieldPressEnter = () => {
		inputRef.current && inputRef.current.blur();
	};

	/** Checks if user have permission to edit translation
	 * @function
	 * @returns {bool}
	 * @memberOf TranslationsComponent
	 */
	const canEdit = () => (isSystemDefault && hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.MODIFY })) || (!isSystemDefault && hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_PARTNER, action: PERMISSION_ACTION.MODIFY }));

	return (
		<div className="translation-field">
			<FormItem className="inline-form-item-control table-form-control" style={{ marginLeft: "0" }} name={["translations", translationKey, lang.toLowerCase()]} rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
				<Input ref={inputRef} onPressEnter={handleFieldPressEnter} autoComplete="off" onChange={(e) => setInputValue(e.target.value)} readOnly={!canEdit()} onFocus={handleFieldFocus} onBlur={handleFieldBlur} />
			</FormItem>
			{canEdit() && source === TRANSLATION_SOURCE_TYPE.OVERRIDEN ? (
				<Tooltip title={t("pages.dashboard.translations.reset_to_default")} placement="bottomRight" getPopupContainer={() => document.body}>
					<div
						className="translation-field-icon-reset"
						onClick={() =>
							resetTranslation({
								key: translationKey,
								groupId: groupId,
								subGroupId: subGroupId,
								languageCode: lang
							})
						}
					>
						<i className="icon-sync" />
					</div>
				</Tooltip>
			) : null}
		</div>
	);
};

/** TranslationField propTypes
 * PropTypes
 */
TranslationField.propTypes = {
	/** Translation language */
	lang: PropTypes.string,
	/** Translation key */
	translationKey: PropTypes.string,
	/** Translation Group ID */
	groupId: PropTypes.string,
	/** Translation Sub Group ID */
	subGroupId: PropTypes.string,
	/** Is System Default Translation */
	isSystemDefault: PropTypes.bool,
	/** Translation Source type*/
	source: PropTypes.oneOf(Object.values(TRANSLATION_SOURCE_TYPE)),
	/** Redux action to save translation */
	saveTranslation: PropTypes.func,
	/** Redux action to save translation */
	resetTranslation: PropTypes.func,
	/**Form objcet */
	formInstance: PropTypes.object
};
const mapDispatchToProps = (dispatch) => ({
	saveTranslation: (data) => {
		dispatch(saveTranslation(data));
	},
	resetTranslation: (data) => {
		dispatch(resetTranslation(data));
	}
});

export default connect(null, mapDispatchToProps)(memo(TranslationField));
