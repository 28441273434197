import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { GET_ALL_STREAM_SERVERS, SET_ALL_STREAM_SERVERS, GET_ALL_STREAM_SERVERS_FINISH, SET_AVAILABLE_STREAM_SERVER_GAMES } from "../../../actionTypes";

export const startGetAllStreamServers = () => ({
	type: GET_ALL_STREAM_SERVERS
});

export const setAllStreamServers = (payload) => ({
	type: SET_ALL_STREAM_SERVERS,
	payload
});

export const finishGetAllStreamServers = () => ({
	type: GET_ALL_STREAM_SERVERS_FINISH
});

export const setAvailableStreamServerGames = (payload) => ({
	type: SET_AVAILABLE_STREAM_SERVER_GAMES,
	payload
});

const setStreamServers = (response, getState) => {
	const responseData = response.data || [];

	const targetServer = responseData.value;

	let allStreamServers = getState().streamServers.data || [];

	allStreamServers = allStreamServers.map((streamServer) => (streamServer.id === targetServer.id ? targetServer : streamServer));

	return {
		data: allStreamServers
	};
};

export const getAllStreamServers = () => {
	return (dispatch) => {
		dispatch(startGetAllStreamServers());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.STREAM_SERVERS_GET_ALL}`,
			method: Methods.GET
		})
			.then((response) => {
				const responseData = response.data;

				const streamServers = {
					data: responseData.value.item2
				};

				dispatch(setAllStreamServers(streamServers));
			})
			.finally(() => dispatch(finishGetAllStreamServers()));
	};
};

export const getAvailableStreamServerGames = () => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.STREAM_SERVERS_GET_AVAILABLE_GAMES}`,
			method: Methods.GET
		})
			.then((response) => {
				dispatch(setAvailableStreamServerGames(response.data.value));
			})
			.finally(() => Function.prototype);
	};
};

export const createStreamServerGame = (data, cb) => {
	return (dispatch, getState) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.STREAM_SERVERS_ADD_GAME}`,
			method: Methods.POST,
			data
		})
			.then((response) => {
				const streamServers = setStreamServers(response, getState);

				dispatch(setAllStreamServers(streamServers));
			})
			.finally(() => {
				cb();
			});
	};
};

export const restartStreamServer = (data, cb) => {
	return (dispatch, getState) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.STREAM_SERVERS_RESTART}`,
			method: Methods.POST,
			data
		})
			.then((response) => {
				const streamServers = setStreamServers(response, getState);

				dispatch(setAllStreamServers(streamServers));
			})
			.finally(() => {
				cb();
			});
	};
};

export const restartStreamServerGame = (data, cb) => {
	return (dispatch, getState) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.STREAM_SERVERS_RESTART_GAME}`,
			method: Methods.POST,
			data
		})
			.then((response) => {
				const streamServers = setStreamServers(response, getState);

				dispatch(setAllStreamServers(streamServers));
			})
			.finally(() => {
				cb();
			});
	};
};

export const removeStreamServerGame = (data, cb) => {
	return (dispatch, getState) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.STREAM_SERVERS_REMOVE_GAME}`,
			method: Methods.POST,
			data
		})
			.then((response) => {
				const streamServers = setStreamServers(response, getState);

				dispatch(setAllStreamServers(streamServers));
			})
			.finally(() => {
				cb();
			});
	};
};
