import { useState, useEffect } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Layout } from "antd";

const { Header: AntHeader } = Layout;

import GlobalPartnersFilter from "../globalPartnersFilter";
import GlobalCurrencyDropdown from "../globalCurrencyDropdown";
import ProfileDropdown from "../profileDropdown";
import Notifications from "../../dashboard/common/notifications";

import userInfoType from "types/profile/userInfo.type";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

let interval;

/** Dashboard Header Component */
const Header = ({ userInfo }) => {
	const [date, setDate] = useState(new Date());

	const { t } = useTranslation();

	/** Run the clock */
	useEffect(() => {
		interval = setInterval(() => {
			setDate(new Date());
		}, 0);
		return () => clearInterval(interval);
	}, []);

	return (
		<AntHeader className="header">
			<div className="dashboard-header">
				<div className="dashboard-header-left">
					<span className="dashboard-header-date">{moment.utc(date).local().format(DATE_FORMAT)}</span>
					<span className="dashboard-header-date">{moment.utc(date).local().format(TIME_FORMAT)}</span>
				</div>

				<div className="dashboard-header-right">
					{userInfo.maintenanceMode && (
						<div className="dashboard-header-maintenance">
							<i className="icon-warning" />
							<span>{t("pages.dashboard.maintenance_mode.virtuals_are_on_maintenance_now")}</span>
						</div>
					)}

					<GlobalPartnersFilter />
					<GlobalCurrencyDropdown />
					<Notifications />
					<ProfileDropdown />
				</div>
			</div>
		</AntHeader>
	);
};

/** Header propTypes
 * PropTypes
 */
Header.propTypes = {
	/** Redux state property, the user info */
	userInfo: userInfoType
};

const mapStateToProps = (state) => {
	return {
		userInfo: state.profile.userInfo
	};
};

export default connect(mapStateToProps, null)(Header);
