import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { message } from "antd";

import { SET_STREAMS_GENERAL_INFO, STREAMS_SAVING_BEFORE, STREAMS_SAVING_FINISH, SET_AVAILABLE_GAMES } from "../../../actionTypes";
import { setStreamsActionBefore, setStreamsActionFinished } from "./streams.action";

export const setStreamsSavingBefore = () => ({
	type: STREAMS_SAVING_BEFORE
});

export const setStreamsSavingFinished = () => ({
	type: STREAMS_SAVING_FINISH
});

export const setStreamsGeneralInfo = (generalInfo) => ({
	type: SET_STREAMS_GENERAL_INFO,
	payload: { generalInfo }
});

export const setStreamAvailableGames = (availableGames) => ({
	type: SET_AVAILABLE_GAMES,
	payload: { availableGames }
});

export const getStreamAvailableGames = () => {
	return (dispatch) => {
		dispatch(setStreamsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.STREAMS_GET_AVAILABLE_GAMES}`,
			method: Methods.GET
		})
			.then(({ data: { value: availableGamesList } }) => {
				dispatch(setStreamAvailableGames(Object.keys(availableGamesList).map((key) => ({ id: key, name: availableGamesList[key] }))));
			})
			.finally((ex) => {
				dispatch(setStreamsActionFinished());
			});
	};
};

export const getStreamsGeneralInfo = (id) => {
	return (dispatch) => {
		dispatch(setStreamsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.STREAMS_GET_GENERAL_INFO}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: info } }) => {
				dispatch(setStreamsGeneralInfo(info));
			})
			.finally((ex) => {
				dispatch(setStreamsActionFinished());
			});
	};
};

export const saveStreamsGeneralInfo = (info) => {
	return (dispatch) => {
		dispatch(setStreamsSavingBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.STREAMS_SAVE_GENERAL_INFO}`,
			method: Methods.POST,
			data: info
		})
			.then(({ data: { message: msg, value: response } }) => {
				message.success(msg);
				dispatch(setStreamsGeneralInfo(response));
			})
			.finally((ex) => {
				dispatch(setStreamsSavingFinished());
			});
	};
};
