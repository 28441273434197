import { HOTKEYS_BEFORE, HOTKEYS_FINISH, SET_HOTKEYS } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case HOTKEYS_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case HOTKEYS_FINISH:
			return {
				...state,
				isLoading: false
			};
		case SET_HOTKEYS:
			return {
				...state,
				hotkeys: payload.data
			};
		default:
			return state;
	}
};
