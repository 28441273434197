import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { setApikeysActionBefore, setApikeysActionFinished, setApikeySaveActionBefore, setApikeySaveActionFinished } from "./apikeys.action";

import { SET_APIKEY_ANALYTICAL_TOOLS } from "store/actionTypes";
import { GAME_CATEGORY } from "constants/game.constants";

export const setApikeyAnalyticalTools = (data, gameCategory) => ({
	type: SET_APIKEY_ANALYTICAL_TOOLS,
	payload: { data, gameCategory }
});

export const getApiKeyAnalyticalTools = (id, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.APIKEY_GET_SCHEDULED_GAME_ANALYTICAL_TOOLS : ApiUrls.APIKEY_GET_INSTANT_GAME_ANALYTICAL_TOOLS;
	return (dispatch) => {
		dispatch(setApikeysActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setApikeyAnalyticalTools(data, gameCategory));
				dispatch(setApikeysActionFinished());
			})
			.catch((ex) => {
				dispatch(setApikeysActionFinished());
			});
	};
};

export const saveApiKeyAnalyticalTools = (data, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.APIKEY_SAVE_SCHEDULED_GAME_ANALYTICAL_TOOLS : ApiUrls.APIKEY_SAVE_INSTANT_GAME_ANALYTICAL_TOOLS;
	return (dispatch) => {
		dispatch(setApikeySaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.POST,
			data: {
				...data
			}
		})
			.then(({ data: { message: msg, status, value: data } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setApikeyAnalyticalTools(data, gameCategory));
				}
				dispatch(setApikeySaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setApikeySaveActionFinished());
			});
	};
};
