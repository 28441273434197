import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardPlayersActivities, getDashboardBetslipsActivities, getTopMarkets, getGamesPerformance, resetDashboardGamesPerformance, getDashboardTopPlayers } from "store/actions/dashboard/dashboard/dashboard.action";
import useGamePerformanceCalculation from "./useGamePerformanceCalculation";
import { PROJECT_TYPE } from "constants/common.constants";

const getGlobalPartnerId = state => state.partner.globalPartnerId;
const getFiltersProjectId = state => state.dashboard.online.filters.projectId;
const getFiltersPeriod = state => state.dashboard.online.filters.period;
const getFiltersGameType = state => state.dashboard.online.filters.gameType;
const getFiltersGameCategory = state => state.dashboard.online.filters.gameCategory;
const getGamesPerformanceSource = state => state.dashboard.online.gamesPerformance.source;

const useDashboardOnlineRequest = (activeKey) => {
	const dispatch = useDispatch();
	/** Redux state property, represents global partner id */
	const globalPartnerId = useSelector(getGlobalPartnerId);
	/** Redux state property, project id from dashboard filters */
	const filtersProjectId = useSelector(getFiltersProjectId);
	/** Redux state property, period from dashboard filters */
	const filtersPeriod = useSelector(getFiltersPeriod);
	/** Redux state property, game type from dashboard filters */
	const filtersGameType = useSelector(getFiltersGameType);
	/** Redux state property, game category from dashboard filters */
	const filtersGameCategory = useSelector(getFiltersGameCategory);
	/** Redux state property, game performance source data */
	const gamesPerformanceSource = useSelector(getGamesPerformanceSource);

	useEffect(() => {
		if (activeKey !== PROJECT_TYPE.ONLINE.toString()) {
			return;
		}

		const abortControllerDashboardPlayersActivities = new AbortController();
		const abortControllerDashboardBetslipsActivities = new AbortController();
		const abortControllerGamesPerformance = new AbortController();
		const abortControllerDashboardTopPlayers = new AbortController();

		const timeoutId = setTimeout(() => {
			/** Redux action to get player activities */
			dispatch(getDashboardPlayersActivities(
				filtersProjectId,
				filtersPeriod,
				filtersGameCategory,
				abortControllerDashboardPlayersActivities.signal
			));
			/** Redux action to get betslip activities */
			dispatch(getDashboardBetslipsActivities(
				filtersProjectId,
				filtersPeriod,
				PROJECT_TYPE.ONLINE,
				filtersGameCategory,
				abortControllerDashboardBetslipsActivities.signal
			));
			/** Redux action to reset game performance chart data and total bonuses for online */
			dispatch(resetDashboardGamesPerformance(PROJECT_TYPE.ONLINE));
			/** Redux action to get game performance chart data and total bonuses for online */
			dispatch(getGamesPerformance(
				filtersProjectId,
				filtersPeriod,
				PROJECT_TYPE.ONLINE,
				filtersGameCategory,
				abortControllerGamesPerformance.signal
			));
			/** Redux action to get events */
			dispatch(getDashboardTopPlayers(
				filtersProjectId,
				filtersPeriod,
				PROJECT_TYPE.ONLINE,
				false,
				filtersGameCategory,
				abortControllerDashboardTopPlayers.signal
			))
		}, 50);
		return () => {
			abortControllerDashboardPlayersActivities.abort("Cancel");
			abortControllerDashboardBetslipsActivities.abort("Cancel");
			abortControllerGamesPerformance.abort("Cancel");
			abortControllerDashboardTopPlayers.abort("Cancel");
			clearTimeout(timeoutId)
		}
	}, [activeKey, globalPartnerId, filtersGameCategory, filtersProjectId, filtersPeriod, dispatch]);

	useEffect(() => {
		if (activeKey !== PROJECT_TYPE.ONLINE.toString()) {
			return;
		}
		const timeoutId = setTimeout(() => {
			/** Redux action to get top markets */
			dispatch(getTopMarkets(
				filtersProjectId,
				filtersPeriod,
				filtersGameType,
				PROJECT_TYPE.ONLINE,
				filtersGameCategory
			));
		}, 20);
		return () => {
			clearTimeout(timeoutId)
		}
	}, [activeKey, globalPartnerId, filtersProjectId, filtersPeriod, filtersGameType, dispatch, filtersGameCategory]);

	useGamePerformanceCalculation(gamesPerformanceSource, PROJECT_TYPE.ONLINE, globalPartnerId, filtersGameCategory)
}

export default useDashboardOnlineRequest;
