import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Button, Col, Row, Input } from "antd";
const { Item: FormItem } = Form;
import Table from "components/common/table";

import NumericInput from "components/common/numericInput";

import { numberTransform } from "utils/common";
import { hasPermission } from "utils/permissions";

import { SCHEDULED_GAME_TYPE } from "constants/game.constants";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { getStrengths, saveStrengths } from "store/actions/dashboard/virtuals/games/probabilities.action";

import strengthType from "types/game/strength.type";
import gameGeneralInfoType from "types/game/generalInfo.type";
import { isFormChanged } from "utils/form";

const strengthsToRecord = (strengths) => {
	return {
		participants: {
			rank: Object.fromEntries(strengths.map((p) => [p.id, p.rank])),
			name: Object.fromEntries(strengths.map((p) => [p.id, p.name]))
		}
	}
}


/** Game Edit Page Probabilities Tab strengths subTab Component */
const StrengthsComponent = ({ isSaving, isLoading, getStrengths, saveStrengths, strengths, generalInfo, onTabChange }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);

	const isHorseRaces = () => generalInfo.type === SCHEDULED_GAME_TYPE.HORSES_RACE.value || generalInfo.type === SCHEDULED_GAME_TYPE.STEEPLECHASING.value;

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue(strengthsToRecord(strengths));
	}, [strengths]);

	/** Fires when form submitted
	 * @function
	 * @memberOf StrengthsComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				const d = {
					id: routeParams.id,
					participants: Object.keys(data.participants.rank).map((k) => ({ id: k, rank: data.participants.rank[k], name: data.participants.name[k] })),
					gameType: generalInfo.type
				};
				saveStrengths(d);
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	/** Columns of table */
	const columns = [
		{
			title: isHorseRaces() ? t("pages.dashboard.games.probabilities.horse_name") : generalInfo.type === SCHEDULED_GAME_TYPE.GREYHOUNDS_RACE.value ? t("pages.dashboard.games.probabilities.greyhound_name") : "",
			dataIndex: "name",
			sorter: false,
			className: "ant-table-input-cell",
			render: (value, record) => (
				<FormItem
					className="inline-form-item-control table-form-control"
					style={{ marginLeft: 0, maxWidth: "200px" }}
					name={["participants", "name", record.id]}
					rules={[
						{ required: true, message: t("validation.field_required") },
						{ max: 20, message: t("validation.field_invalid") },
						{ min: 2, message: t("validation.field_invalid") }
					]}
				>
					<Input maxLength={20} disabled={!hasPermission({ resource: PERMISSION_RESOURCE.GAME_PROBABILITIES, action: PERMISSION_ACTION.MODIFY }) || !generalInfo.partnerId} />
				</FormItem>
			)
		},
		{
			title: isHorseRaces() ? t("pages.dashboard.games.probabilities.jockey_name") : generalInfo.type === SCHEDULED_GAME_TYPE.GREYHOUNDS_RACE.value ? t("pages.dashboard.games.probabilities.trainer") : "",
			dataIndex: isHorseRaces() ? "jockeyName" : generalInfo.type === SCHEDULED_GAME_TYPE.GREYHOUNDS_RACE.value ? "trainer" : "",
			sorter: false
		},
		...(isHorseRaces()
			? [
					{
						title: t("pages.dashboard.games.probabilities.age"),
						dataIndex: "age",
						sorter: false
					}
				]
			: []),
		{
			title: t("pages.dashboard.games.probabilities.strength"),
			dataIndex: "rank",
			sorter: false,
			className: "ant-table-input-cell",
			render: (value, record) => (
				<FormItem
					className="inline-form-item-control table-form-control"
					style={{ marginLeft: 0, maxWidth: "200px" }}
					name={["participants", "rank", record.id]}
					rules={[
						{ required: true, message: t("validation.field_required") },
						{ type: "number", min: 1, message: t("validation.must_be_more").replace("%X%", 1), transform: numberTransform },
						{ type: "number", max: 1000, message: t("validation.must_be_less").replace("%X%", 1000), transform: numberTransform }
					]}
				>
					<NumericInput disabled={!hasPermission({ resource: PERMISSION_RESOURCE.GAME_PROBABILITIES, action: PERMISSION_ACTION.MODIFY })} />
				</FormItem>
			)
		}
	];

	return (
		<Form
			colon={false}
			form={formInstance}
			requiredMark={false}
			layout="vertical"
			onValuesChange={(_, formValues) => {
				setIsFormTouched(isFormChanged(formValues, strengthsToRecord(strengths)));
			}}
		>
			<div className="dashboard-section-content">
				<Row>
					<Col span={24}>
						<Table loading={isLoading} columns={columns} data={strengths} loadFn={() => getStrengths(routeParams.id, generalInfo.type)} total={strengths.length} isDisabled={() => false} noPagination={true} uniqueKey="id" nonFixed={true} />
					</Col>
				</Row>
				{hasPermission({ resource: PERMISSION_RESOURCE.GAME_PROBABILITIES, action: PERMISSION_ACTION.MODIFY }) && (
					<FormItem className="button-container">
						<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
							<span>{t("common.save")}</span>
						</Button>
					</FormItem>
				)}
			</div>
		</Form>
	);
};

/** StrengthsComponent propTypes
 * PropTypes
 */
StrengthsComponent.propTypes = {
	/** Redux state property, is true when rtps is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when rtps are loading */
	isLoading: PropTypes.bool,
	/** Redux action to get strengths */
	getStrengths: PropTypes.func,
	/** Redux action to save strengths */
	saveStrengths: PropTypes.func,
	/** Redux state, represents the strengths data of current editing game  */
	strengths: PropTypes.arrayOf(strengthType),
	/** Redux state, represents the general info of current editing game  */
	generalInfo: gameGeneralInfoType,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getStrengths: (id, gameType) => {
		dispatch(getStrengths(id, gameType));
	},
	saveStrengths: (data) => {
		dispatch(saveStrengths(data));
	}
});

const mapStateToProps = (state) => {
	return {
		strengths: state.games.editingGame.probabilities.strengths,
		isSaving: state.games.isSaving,
		isLoading: state.games.isLoading,
		generalInfo: state.games.editingGame.generalInfo
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StrengthsComponent);
