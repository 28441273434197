import PropTypes from "prop-types";

export default PropTypes.shape({
	id: PropTypes.string,
	gameType: PropTypes.number,
	title: PropTypes.string,
	languageCode: PropTypes.string,
	username: PropTypes.string,
	version: PropTypes.string,
	date: PropTypes.string,
	isPublished: PropTypes.bool,
	content: PropTypes.string
});
