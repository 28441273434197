import { Fragment } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Modal } from "antd";

import VideoPlayer from "components/common/videoPlayer";

import { humanFileSize } from "utils/common";

/** Banner upload file preview Component */
const BannerUploadPreviewComponent = ({ file, onClose }) => {
	const { t } = useTranslation();

	return (
		<Modal
			title={file.name}
			open={true}
			centered
			footer={
				<Fragment>
					<div>
						<b>{t("common.size")}:</b>
						<span>{humanFileSize(file.total)}</span>
					</div>
					{file.dimensions ? (
						<div>
							<b>{t("common.dimensions")}:</b>
							<span>{file.dimensions}</span>
						</div>
					) : null}
				</Fragment>
			}
			onCancel={onClose}
			className="image-preview-modal"
			width={860}
		>
			{
				["mp4", "webm"].includes(file.format) ?
					<VideoPlayer
						streamConfiguration={{
							url: file.previewUrl || null
						}}
						isMinimized={false}
						defaultExtended={false}
						showMinimize={false}
						showFullscreen={true}
					/> :
					<img src={file.previewUrl} alt={file.name} />
			}

		</Modal>
	);
};

/** BannerUploadPreviewComponent propTypes
 * PropTypes
 */
BannerUploadPreviewComponent.propTypes = {
	/** The file to preview */
	file: PropTypes.shape({
		url: PropTypes.string,
		total: PropTypes.number,
		dimensions: PropTypes.string,
		name: PropTypes.string,
		format: PropTypes.string,
		previewUrl: PropTypes.string
	}),
	/** Fires on preview close */
	onClose: PropTypes.func
};

export default BannerUploadPreviewComponent;
