import { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Form, Col, Row, Modal, Select, Input, Checkbox, DatePicker, Tooltip } from "antd";
const { Item: FormItem } = Form;

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { changeGameState } from "store/actions/dashboard/virtuals/games/games.action";

import { GAME_STATE, GAME_CATEGORY } from "constants/game.constants";
import { POPUP_SIZE } from "constants/common.constants";

import gameType from "types/game/game.type";

/** Game Status changing Popup Component */
const GameStatusChangeComponent = ({ game, isSaving, changeGameState, onClose, gameCategory }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;

	const [selectedState, setSelectedState] = useState(game.state);
	const [selectedDuration, setSelectedDuration] = useState("permanent");

	/** Fires when form submitted
	 * @function
	 * @memberOf GameStatusChangeComponent
	 */
	const handleForm = () => {
		const d = {
			state: selectedState,
			id: game.id
		};
		validateFields()
			.then((data) => {
				d.notifyPartners = data.notifyPartners;
				if (d.state !== GAME_STATE.ACTIVE) {
					d.info = {
						reason: data.reason,
						to: selectedDuration === "temporary" ? data.to : ""
					};
				}
				changeGameState(d, gameCategory);
				onClose();
			})
			.catch(Function.prototype);
	};

	return (
		<Modal
			open={true}
			title={t("pages.dashboard.games.change_game_status")}
			cancelText={t("common.cancel")}
			okText={t("common.save")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.SMALL}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving, disabled: game.state === selectedState }}
			centered
		>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					state: game.state,
					duration: "permanent",
					reason: "",
					to: "",
					notifyPartners: false
				}}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem
							label={
								<Fragment>
									<span>{`${t("pages.dashboard.games.status")} *`}</span>
									<Tooltip
										overlayClassName="info-tooltip"
										title={
											<span>
												{selectedState === GAME_STATE.INACTIVE
													? t("pages.dashboard.games.deactivate_text")
													: selectedState === GAME_STATE.ACTIVE
														? t("pages.dashboard.games.activate_text")
														: selectedState === GAME_STATE.CLOSE_FOR_BETTING
															? t("pages.dashboard.games.close_for_betting_text")
															: ""}
											</span>
										}
										placement="right"
									>
										<i className="icon-info" style={{ marginLeft: "8px", fontSize: "24px" }} />
									</Tooltip>
								</Fragment>
							}
							name="state"
							rules={[{ required: true, message: t("validation.field_required") }]}
						>
							<Select getPopupContainer={() => document.body} onChange={(e) => setSelectedState(e)} suffixIcon={<i className="icon-down" />}>
								<Select.Option value={GAME_STATE.ACTIVE}>{t("common.active")}</Select.Option>
								{gameCategory === GAME_CATEGORY.SCHEDULED && <Select.Option value={GAME_STATE.CLOSE_FOR_BETTING}>{t("pages.dashboard.games.betting_inactive")}</Select.Option>}
								<Select.Option value={GAME_STATE.INACTIVE}>{t("common.inactive")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					{selectedState !== GAME_STATE.ACTIVE ? (
						<Fragment>
							<Col span={24}>
								<FormItem label={t("pages.dashboard.games.status_duration")} name="duration">
									<Select getPopupContainer={() => document.body} onChange={(e) => setSelectedDuration(e)} suffixIcon={<i className="icon-down" />}>
										<Select.Option value="permanent">{t("pages.dashboard.games.permanent")}</Select.Option>
										<Select.Option value="temporary">{t("pages.dashboard.games.temporary")}</Select.Option>
									</Select>
								</FormItem>
								<div style={{ display: selectedDuration === "temporary" ? "block" : "none" }}>
									<FormItem
										label={t("pages.dashboard.games.status_duration_time")}
										name="to"
										rules={[
											() => ({
												validator(rule, value) {
													if (!value && selectedDuration === "temporary") {
														return Promise.reject(t("validation.field_required"));
													}
													if (moment.utc(value).isBefore(moment.utc()) && selectedDuration === "temporary") {
														return Promise.reject(t("validation.field_invalid"));
													}
													return Promise.resolve();
												}
											})
										]}
									>
										<DatePicker format={`${DATE_FORMAT} ${TIME_FORMAT}`} showTime={{ format: TIME_FORMAT }} disabledDate={(d) => !d || d.isBefore(moment())} showToday={false} allowClear={true} placeholder={t("common.select_time")} getPopupContainer={() => document.body} />
									</FormItem>
								</div>
							</Col>
							<Col span={24}>
								<FormItem label={`${t("pages.dashboard.games.deactivate_reason")} *`} name="reason" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
									<Input.TextArea maxLength={100} placeholder={t("pages.dashboard.games.deactivate_reason_placeholder")} rows={4} />
								</FormItem>
							</Col>
						</Fragment>
					) : null}
					<Col span={24}>
						<div className="inline-form-checkbox">
							<label>{t("pages.dashboard.games.notifyPartners")}</label>
							<FormItem className="inline-form-item-control" name="notifyPartners" valuePropName="checked">
								<Checkbox />
							</FormItem>
						</div>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** GameStatusChangeComponent propTypes
 * PropTypes
 */
GameStatusChangeComponent.propTypes = {
	/** Redux state property, is true when creating game request is in process */
	isSaving: PropTypes.bool,
	/** Redux action to activate/deactivate game */
	changeGameState: PropTypes.func,
	/** Current editing game */
	game: gameType,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	changeGameState: (data, gameCategory) => {
		dispatch(changeGameState(data, gameCategory));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.partners.isSaving
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GameStatusChangeComponent);
