import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { HOTKEYS_BEFORE, HOTKEYS_FINISH, SET_HOTKEYS } from "store/actionTypes";

const setHotKeysActionBefore = () => ({
	type: HOTKEYS_BEFORE
});

const setHotKeysActionFinished = () => ({
	type: HOTKEYS_FINISH
});

const setHotkeys = (data) => ({
	type: SET_HOTKEYS,
	payload: { data }
});

export const getHotkeys = (gameType) => {
	return (dispatch) => {
		dispatch(setHotKeysActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_HOTKEYS}`,
			method: Methods.GET,
			params: { gameType }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setHotkeys(data));
				dispatch(setHotKeysActionFinished());
			})
			.catch(() => {
				dispatch(setHotKeysActionFinished());
			});
	};
};
