import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Switch } from "antd";
import Table from "components/common/table";
import AddPartnerPopup from "components/dashboard/usersFeedback/CSAT/edit/sections/partners/partner-add.component";
import CsatPartnersQuickInfo from "components/dashboard/usersFeedback/CSAT/edit/sections/partners/quickinfo.component";
import { getSurveyPartners, getSurveyAvailablePartners, saveSurveyPartners, setSurveyPartnersSorting } from "store/actions/dashboard/usersFeedback/CSAT/partners.action";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { SURVEY_STATE } from "constants/survey.constants";
import SortingType from "types/common/sorting.type";
import AvailablePartnersType from "types/survey/availablePartners.type";

/** Survey Edit Page Partners Tab Component */
const SurveyPartnersTab = ({ isLoading, total, sorting, setSurveyPartnersSorting, surveyState, partners, availablePartners, getSurveyPartners, getSurveyAvailablePartners, saveSurveyPartners }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	/** State to show/hide partner add popup */
	const [showAddPopup, setShowAddPopup] = useState(false);

	/** The id of the partner, for which quick info is showing */
	const [selectedPartnerId, setSelectedPartnerId] = useState(null);

	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.SURVEYS, action: PERMISSION_ACTION.MODIFY });

	/** Columns of table */
	const columns = [
		{
			dataIndex: "enabled",
			render: (enabled, { id }) => <Switch disabled={isDisabled || surveyState === SURVEY_STATE.FINISHED} checked={enabled} onChange={(checked) => saveSurveyPartners({ id: routeParams.id, partners: [{ id, enabled: checked }] })} />,
			sorter: false
		},
		{
			title: t("pages.dashboard.usersFeedback.partner"),
			dataIndex: "name",
			sorter: false
		},
		{
			title: t("pages.dashboard.usersFeedback.total_participants"),
			dataIndex: "playersCount",
			render: (totalParticipants, { id }) => (
				<span className="table-col-link" onClick={() => setSelectedPartnerId(id)}>
					{totalParticipants}
				</span>
			),
			sorter: false
		},
		{
			title: `${t("pages.dashboard.usersFeedback.response_rate")} %`,
			dataIndex: "responseRate",
			sorter: false
		},
		{
			title: `${t("pages.dashboard.usersFeedback.csat_score")} %`,
			dataIndex: "responseScore",
			sorter: false
		}
	];

	/** Load survey available partners */
	useEffect(() => {
		if (!isDisabled) {
			getSurveyAvailablePartners(routeParams.id);
		}
	}, [isDisabled, routeParams.id]);

	/** Close partner add popup, after save */
	useEffect(() => {
		setShowAddPopup(false);
	}, [partners]);

	return (
		<div className="dashboard-section-content dashboard-section-content-csat">
			{!isDisabled ? (
				<div className="dashboard-section-buttons">
					<Button
						type="primary"
						disabled={availablePartners.length === 0 || surveyState === SURVEY_STATE.FINISHED}
						htmlType="button"
						className="button"
						onClick={() => {
							setShowAddPopup(true);
						}}
					>
						<span>{t("pages.dashboard.usersFeedback.add_partner")}</span>
					</Button>
				</div>
			) : null}
			<Table loading={isLoading} columns={columns} data={partners} loadFn={(fromFirstPage) => getSurveyPartners(routeParams.id, fromFirstPage)} sorting={sorting} setSortingFn={setSurveyPartnersSorting} total={total} />

			{showAddPopup && <AddPartnerPopup surveyId={routeParams.id} onClose={() => setShowAddPopup(false)} />}
			{selectedPartnerId && <CsatPartnersQuickInfo partnerId={selectedPartnerId} surveyId={routeParams.id} onClose={() => setSelectedPartnerId(null)} />}
		</div>
	);
};

/** SurveyPartnersTab propTypes
 * PropTypes
 */
SurveyPartnersTab.propTypes = {
	/** Redux state property, is true when loading survey partners */
	isLoading: PropTypes.bool,
	/** Redux state property, survey partners total count */
	total: PropTypes.number,
	/** Redux state property, survey partners sorting details */
	sorting: SortingType,
	/** Redux state, represents current editing survey state  */
	surveyState: PropTypes.oneOf(Object.values(SURVEY_STATE)),
	/** Redux state property, represents the partners info of survey */
	partners: PropTypes.array,
	/** Redux state property, represents the available partners info of survey  */
	availablePartners: AvailablePartnersType,
	/** Redux action to get survey partners */
	getSurveyPartners: PropTypes.func,
	/** Redux action to get survey available partners */
	getSurveyAvailablePartners: PropTypes.func,
	/** Redux action to save survey partners */
	saveSurveyPartners: PropTypes.func,
	/** Redux action to set survey partners sorting details */
	setSurveyPartnersSorting: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getSurveyPartners: (surveyId, fromFirstPage) => {
		dispatch(getSurveyPartners(surveyId, fromFirstPage));
	},
	getSurveyAvailablePartners: (surveyId) => {
		dispatch(getSurveyAvailablePartners(surveyId));
	},
	setSurveyPartnersSorting: (sorting) => {
		dispatch(setSurveyPartnersSorting(sorting));
	},
	saveSurveyPartners: (args) => {
		dispatch(saveSurveyPartners(args));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.surveys.editingSurvey.isLoading,
		total: state.surveys.editingSurvey.partners.total,
		sorting: state.surveys.editingSurvey.partners.sorting,
		surveyState: state.surveys.editingSurvey.generalInfo.state,
		partners: state.surveys.editingSurvey.partners.partners,
		availablePartners: state.surveys.editingSurvey.availablePartners
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyPartnersTab);
