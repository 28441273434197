import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { REQUESTS_ACTION_BEFORE, REQUESTS_ACTION_FINISH, REQUEST_ACTION_BEFORE, REQUEST_ACTION_FINISH, SET_REQUESTS, SET_REQUESTS_SORTING, SET_REQUESTS_FILTERS, SET_REQUEST } from "store/actionTypes";

const setRequestsActionBefore = () => ({
	type: REQUESTS_ACTION_BEFORE
});

const setRequestsActionFinished = () => ({
	type: REQUESTS_ACTION_FINISH
});

const setRequestActionBefore = () => ({
	type: REQUEST_ACTION_BEFORE
});

const setRequestActionFinished = () => ({
	type: REQUEST_ACTION_FINISH
});

const setRequests = (requests) => ({
	type: SET_REQUESTS,
	payload: { requests }
});

const setRequest = (request) => ({
	type: SET_REQUEST,
	payload: { request }
});

export const setRequestsSorting = (sorting) => ({
	type: SET_REQUESTS_SORTING,
	payload: { sorting }
});

export const setRequestFilters = (filters) => ({
	type: SET_REQUESTS_FILTERS,
	payload: { filters }
});

export const getRequests = () => {
	return (dispatch, getState) => {
		dispatch(setRequestsActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_REQUEST_LOGS}`,
			method: Methods.GET,
			params: {
				...getState().requests.sorting,
				...getState().requests.filters
			}
		})
			.then(({ data: { value: requests } }) => {
				dispatch(setRequests(requests));
				dispatch(setRequestsActionFinished());
			})
			.catch((ex) => {
				dispatch(setRequestsActionFinished());
			});
	};
};

export const getRequest = (id) => {
	return (dispatch, getState) => {
		dispatch(setRequestActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_REQUEST_LOG}`,
			method: Methods.GET,
			params: { id, year: getState().requests.filters.year, month: getState().requests.filters.month }
		})
			.then(({ data: { value: request } }) => {
				dispatch(setRequest(request));
				dispatch(setRequestsActionFinished());
			})
			.catch(() => {
				dispatch(setRequestsActionFinished());
			});
	};
};
