import { PERMISSION_GROUPS_BEFORE, PERMISSION_GROUPS_FINISH, PERMISSION_GROUPS_SAVE_BEFORE, PERMISSION_GROUPS_SAVE_FINISH } from "store/actionTypes";

export const setPermissionGroupsActionBefore = () => ({
	type: PERMISSION_GROUPS_BEFORE
});

export const setPermissionGroupsActionFinished = () => ({
	type: PERMISSION_GROUPS_FINISH
});

export const setPermissionGroupSaveActionBefore = () => ({
	type: PERMISSION_GROUPS_SAVE_BEFORE
});

export const setPermissionGroupSaveActionFinished = () => ({
	type: PERMISSION_GROUPS_SAVE_FINISH
});
