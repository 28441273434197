import { useState } from "react";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import UserQuickInfoTable from "../../../components/users-quickInfo.table.component";
import UserAddComponent from "../../../components/user-add.component";
import ExportButtonImminently from "components/common/exportButton/exportButtonImminently";

import { Row, Button } from "antd";
import { hasPermission } from "utils/permissions";
import { EXPORT_TYPE } from "constants/common.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import ApiUrls from "constants/api.constants";

/** Permission Group Edit Page Users Info quick view Tab Component */
const PermissionGroupUsersComponent = ({ usersQuickView }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	/** State to show/hide user creation popup */
	const [showCreatePopup, setShowCreatePopup] = useState(false);

	const { sorting } = usersQuickView;
	const id = routeParams.id;

	return (
		<div className="dashboard-permission-group-users-section">
			<div className="table-buttons-dropdowns">
				{
					hasPermission({ resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.EXPORT })
						? (
							<ExportButtonImminently
								url={ApiUrls.EXPORT_PERMISSSION_GROUP_USERS}
								exportType={EXPORT_TYPE.CSV}
								filters={{ id }}
								sorting={sorting}
							/>
						)
						: null
				}
				{hasPermission({ resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.MODIFY }) ? (
					<div className="table-buttons-dropdowns-button">
						<Button onClick={() => setShowCreatePopup(true)} type="primary">
							{t("pages.dashboard.users.add_user")}
						</Button>
					</div>
				) : null}
			</div>
			<Row>
				<UserQuickInfoTable id={id} />
			</Row>
			<UserAddComponent
				onClose={() => {
					setShowCreatePopup(false);
				}}
				groupId={id}
				visible={showCreatePopup}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		isSaving: state.permissionGroups.isSaving,
		isLoading: state.permissionGroups.isLoading,
		globalPartnerId: state.partner.globalPartnerId,
		usersQuickView: state.permissionGroups.editingPermissionGroup.usersQuickView
	};
};

export default connect(mapStateToProps, null)(PermissionGroupUsersComponent);
