import { Fragment } from "react";

import RequestsComponent from "components/dashboard/developer/requests/requests.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

const RequestsRoute = () => {
	return (
		<Fragment>
			<RequestsComponent />
		</Fragment>
	);
};

export default withPermission(RequestsRoute, { resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
