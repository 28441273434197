import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { setBoxSaveActionBefore, setBoxSaveActionFinished } from "./boxes.action";

import { BOX_GAMES_BEFORE, BOX_GAMES_FINISH, SET_BOX_GAMES, SET_BOX_ADD_GAME, SET_BOX_UPDATE_GAME, SET_BOX_DELETE_GAME } from "store/actionTypes";

const setBoxGamesBefore = () => ({
	type: BOX_GAMES_BEFORE
});

const setBoxGamesFinished = () => ({
	type: BOX_GAMES_FINISH
});

export const setBoxGames = (games) => ({
	type: SET_BOX_GAMES,
	payload: { games }
});

export const setBoxAddGame = (game) => ({
	type: SET_BOX_ADD_GAME,
	payload: { game }
});

export const setBoxUpdateGame = (game) => ({
	type: SET_BOX_UPDATE_GAME,
	payload: { game }
});

export const setBoxDeleteGame = (id) => ({
	type: SET_BOX_DELETE_GAME,
	payload: { id }
});

export const getBoxGames = (id) => {
	return (dispatch) => {
		dispatch(setBoxGamesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BOX_GET_GAMES}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: games } }) => {
				dispatch(setBoxGames(games));
				dispatch(setBoxGamesFinished());
			})
			.catch((ex) => {
				dispatch(setBoxGamesFinished());
			});
	};
};

export const addBoxGame = (game) => {
	return (dispatch) => {
		dispatch(setBoxSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BOX_ADD_GAME}`,
			method: Methods.POST,
			data: game
		})
			.then(({ data: { value: game } }) => {
				dispatch(setBoxAddGame(game));
				dispatch(setBoxSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setBoxSaveActionFinished());
			});
	};
};

export const updateBoxGame = (game) => {
	return (dispatch) => {
		dispatch(setBoxSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BOX_SAVE_GAME}`,
			method: Methods.POST,
			data: game
		})
			.then(({ data: { value: game } }) => {
				dispatch(setBoxUpdateGame(game));
				dispatch(setBoxSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setBoxSaveActionFinished());
			});
	};
};

export const deleteBoxGame = (id, gameId) => {
	return (dispatch) => {
		dispatch(setBoxSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BOX_DELETE_GAME}`,
			method: Methods.DELETE,
			data: { id, gameId }
		})
			.then(() => {
				dispatch(setBoxDeleteGame(gameId));
				dispatch(setBoxSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setBoxSaveActionFinished());
			});
	};
};

export const saveBoxGames = (id, games) => {
	return (dispatch) => {
		dispatch(setBoxGamesBefore());
		dispatch(setBoxGames(games));
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BOX_SAVE_GAMES}`,
			method: Methods.POST,
			data: { id, gameIds: games.map((g) => g.id) }
		})
			.then(({ data: { value: games } }) => {
				dispatch(setBoxGames(games));
				dispatch(setBoxGamesFinished());
			})
			.catch((ex) => {
				dispatch(setBoxGamesFinished());
			});
	};
};

export const resetBoxGames = () => (dispatch) => {
	dispatch(setBoxGames([]));
};
