import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { calculateDashboardGamesPerformance } from "store/actions/dashboard/dashboard/dashboard.action";
import { convertCurrencies } from "utils/currency";
import { PROJECT_TYPE } from "constants/common.constants";
import { GAME_CATEGORY, SCHEDULED_GAME_TYPE } from "constants/game.constants";
import { getGamesFromConfig } from "utils/common";

const getCurrency = (state) => state.profile.userInfo.currency;
const getCurrencies = (state) => state.profile.userInfo.currencies;

const useGamePerformanceCalculation = (gamesPerformanceSource, projectType, globalPartnerId, gameCategory) => {
	const dispatch = useDispatch();
	/** Redux state property, current user currency */
	const currency = useSelector(getCurrency);
	/** Redux state property, the current user currencies */
	const currencies = useSelector(getCurrencies);

	useEffect(() => {
		/* Skipping calculation due to invalid data */
		const keys = Object.keys(gamesPerformanceSource)
		if (!keys.length || keys.every(key => Object.keys(gamesPerformanceSource[key]).length === 0)) { return; }
		if (![PROJECT_TYPE.ONLINE, PROJECT_TYPE.RETAIL].includes(projectType)) { return; }
		if (!Object.keys(currency).length) { return; }
		if (!currencies.length) { return; }
		if (!globalPartnerId) { return; }

		/* The calculation can takes a long time, so it wraps is async operation. */
		const timeoutId = setTimeout(() => {

			const allGameCategories = Object.values(GAME_CATEGORY)
			const necessaryGameCategory = (
				allGameCategories.includes(gameCategory)
					? [gameCategory]
					: allGameCategories
			)

			const getKeyByCategory = (category) => (
				category === GAME_CATEGORY.SCHEDULED
					? "scheduledGamesPerformanceChart"
					: "instantGamesPerformanceChart"
			)

			/* Declare variables for assign totals of calculated results */
			let totalTurnover = 0;
			let totalWinAmount = 0;
			let totalGGRAmount = 0;
			let totalGGRPercent = 0;

			const calculated = {
				[getKeyByCategory(GAME_CATEGORY.SCHEDULED)]: [],
				[getKeyByCategory(GAME_CATEGORY.INSTANT)]: []
			}

			/* Start main calculation */
			necessaryGameCategory.forEach((category) => {
				const sourceKey = getKeyByCategory(category)
				const performanceSource = gamesPerformanceSource[sourceKey];
				const gameTypesArray = getGamesFromConfig(category).map(gt => gt.value);
				calculated[sourceKey] = gameTypesArray.reduce((acc, gameType) => {
					/* Skipping games what doesn't exists in source data */
					if (!performanceSource[gameType]) { return acc; }
	
					/* Declare performance data with defaults  */
					const performanceData = {
						gameCategory: category,
						gameType,
						turnover: 0,
						winAmount: 0,
						ggrAmount: 0,
						ggrPercent: 0,
						betsCount: 0
					};
	
					/* Start conversion  of currency to main for all amounts */
					const currenciesAndFinances = Object.entries(performanceSource[gameType]);
					currenciesAndFinances.forEach(([currencyCode, { turnover, winAmount, betsCount }]) => {
						/* Value assignment */
						performanceData.betsCount += betsCount;
	
						/* Converting turnover */
						performanceData.turnover += parseFloat(convertCurrencies(
							turnover,
							currencyCode,
							currency.code,
							currencies,
							globalPartnerId,
							null,
							true
						));
						/* Converting win amount */
						performanceData.winAmount += parseFloat(convertCurrencies(
							winAmount,
							currencyCode,
							currency.code,
							currencies,
							globalPartnerId,
							null,
							true
						));
					});
	
					/* Calculate the ggr amount and the ggr percent from converded turnover and win amount */
					performanceData.ggrAmount = performanceData.turnover - performanceData.winAmount;
					performanceData.ggrPercent = performanceData.turnover === 0 ? 0 : (performanceData.ggrAmount * 100) / performanceData.turnover;
	
					/* Add to totals */
					totalTurnover += performanceData.turnover;
					totalWinAmount += performanceData.winAmount;
					
					acc.push(performanceData);
					return acc;
				}, []);
			})

			/* Calculate the total ggr amount and the total ggr percent from converded total turnover and total win amount */
			totalGGRAmount = totalTurnover - totalWinAmount;
			totalGGRPercent = totalTurnover === 0 ? 0 : (totalGGRAmount * 100) / totalTurnover;

			/* set store data */
			dispatch(
				calculateDashboardGamesPerformance(
					{
						calculated,
						totalTurnover,
						totalWinAmount,
						totalGGRAmount,
						totalGGRPercent
					},
					projectType
				)
			);
		}, 200);

		return () => {
			clearTimeout(timeoutId);
		};
	}, [dispatch, gameCategory, gamesPerformanceSource, projectType, currency, currencies, globalPartnerId]);
};

export default useGamePerformanceCalculation;
