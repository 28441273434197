import { SCHEDULED_GAME_TYPE } from "constants/game.constants";

// TODO: Need to handle game category
export default new Proxy(
	{
		[SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value]: {
			alg: "alg.svg",
			arg: "arg.svg",
			arm: "arm.svg",
			ars: "ars.svg",
			ast: "ast.svg",
			aut: "aut.svg",
			bel: "bel.svg",
			bha: "bha.svg",
			bra: "bra.svg",
			bre: "bre.svg",
			bur: "bur.svg",
			cgo: "cgo.svg",
			che: "che.svg",
			chi: "chi.svg",
			civ: "civ.svg",
			cmr: "cmr.svg",
			col: "col.svg",
			cro: "cro.svg",
			cry: "cry.svg",
			den: "den.svg",
			egy: "egy.svg",
			eng: "eng.svg",
			esp: "esp.svg",
			eth: "eth.svg",
			eve: "eve.svg",
			fra: "fra.svg",
			gam: "gam.svg",
			"gb-wls": "gb-wls.svg",
			ger: "ger.svg",
			gha: "gha.svg",
			gre: "gre.svg",
			gui: "gui.svg",
			ind: "ind.svg",
			irn: "irn.svg",
			isl: "isl.svg",
			ita: "ita.svg",
			jpn: "jpn.svg",
			ken: "ken.svg",
			kor: "kor.svg",
			ldu: "ldu.svg",
			lei: "lei.svg",
			liv: "liv.svg",
			mad: "mad.svg",
			mar: "mar.svg",
			mex: "mex.svg",
			mli: "mli.svg",
			mnc: "mnc.svg",
			mnu: "mnu.svg",
			mtn: "mtn.svg",
			ned: "ned.svg",
			new: "new.svg",
			nga: "nga.svg",
			nig: "nig.svg",
			nir: "nir.svg",
			nor: "nor.svg",
			par: "par.svg",
			pol: "pol.svg",
			por: "por.svg",
			rou: "rou.svg",
			rsa: "rsa.svg",
			rus: "rus.svg",
			sdn: "sdn.svg",
			sen: "sen.svg",
			sou: "sou.svg",
			srb: "srb.svg",
			sui: "sui.svg",
			svk: "svk.svg",
			swe: "swe.svg",
			tot: "tot.svg",
			tun: "tun.svg",
			tur: "tur.svg",
			uru: "uru.svg",
			uzb: "uzb.svg",
			wal: "wal.svg",
			wat: "wat.svg",
			whu: "whu.svg",
			wol: "wol.svg",
			zam: "zam.svg",
			zim: "zim.svg"
		},
		[SCHEDULED_GAME_TYPE.ENGLISH_LEAGUE.value]: {
			ars: "ars.svg",
			ast: "ast.svg",
			bou: "bou.svg",
			bha: "bha.svg",
			bre: "bre.svg",
			bur: "bur.svg",
			che: "che.svg",
			cry: "cry.svg",
			eve: "eve.svg",
			ful: "ful.svg",
			ips: "ips.svg",
			ldu: "ldu.svg",
			lei: "lei.svg",
			liv: "liv.svg",
			mnc: "mnc.svg",
			mnu: "mnu.svg",
			new: "new.svg",
			nor: "nor.svg",
			ntf: "ntf.svg",
			sou: "sou.svg",
			tot: "tot.svg",
			wat: "wat.svg",
			whu: "whu.svg",
			wol: "wol.svg"
		},
		[SCHEDULED_GAME_TYPE.AFRICAN_CUP.value]: {
			cmr: "cmr.svg",
			sen: "sen.svg",
			alg: "alg.svg",
			mli: "mli.svg",
			tun: "tun.svg",
			gui: "gui.svg",
			egy: "egy.svg",
			gha: "gha.svg",
			zim: "zim.svg",
			civ: "civ.svg",
			mar: "mar.svg",
			nga: "nga.svg",
			sdn: "sdn.svg",
			eth: "eth.svg",
			mtn: "mtn.svg",
			gam: "gam.svg"
		},
		[SCHEDULED_GAME_TYPE.WORLD_CUP.value]: {
			arg: "arg.svg",
			bel: "bel.svg",
			bra: "bra.svg",
			cmr: "cmr.svg",
			eng: "eng.svg",
			esp: "esp.svg",
			fra: "fra.svg",
			ger: "ger.svg",
			irn: "irn.svg",
			ita: "ita.svg",
			nga: "nga.svg",
			por: "por.svg",
			rus: "rus.svg",
			sen: "sen.svg",
			tur: "tur.svg",
			uru: "uru.svg"
		},
		[SCHEDULED_GAME_TYPE.EUROPEAN_CUP.value]: {
			aut: "aut.svg",
			bel: "bel.svg",
			cro: "cro.svg",
			den: "den.svg",
			eng: "eng.svg",
			esp: "esp.svg",
			fra: "fra.svg",
			ger: "ger.svg",
			ita: "ita.svg",
			ned: "ned.svg",
			por: "por.svg",
			rus: "rus.svg",
			sui: "sui.svg",
			swe: "swe.svg",
			tur: "tur.svg",
			wal: "wal.svg"
		},
		[SCHEDULED_GAME_TYPE.CHAMPIONS_LEAGUE.value]: {
			ajx: "ajx.svg",
			atm: "atm.svg",
			bar: "bar.svg",
			bay: "bay.svg",
			bnf: "bnf.svg",
			bvb: "bvb.svg",
			che: "che.svg",
			int: "int.svg",
			juv: "juv.svg",
			liv: "liv.svg",
			mnc: "mnc.svg",
			mnu: "mnu.svg",
			por: "cl_por.svg",
			psg: "psg.svg",
			rma: "rma.svg",
			tot: "tot.svg"
		},
		[SCHEDULED_GAME_TYPE.COPA_LIBERTADORES.value]: {
			aso: "aso.svg",
			boc: "boc.svg",
			cam: "cam.svg",
			ccp: "ccp.svg",
			cru: "cru.svg",
			eme: "eme.svg",
			fla: "fla.svg",
			gre: "copa_gre.svg",
			ldu: "copa_ldu.svg",
			lib: "lib.svg",
			nac: "nac.svg",
			oli: "oli.svg",
			pal: "pal.svg",
			rac: "rac.svg",
			riv: "riv.svg",
			sfc: "sfc.svg"
		},
		[SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value]: {
			arg: "arg.svg",
			bel: "bel.svg",
			bra: "bra.svg",
			col: "col.svg",
			cro: "cro.svg",
			eng: "eng.svg",
			esp: "esp.svg",
			fra: "fra.svg",
			ger: "ger.svg",
			irn: "irn.svg",
			ita: "ita.svg",
			mar: "mar.svg",
			mex: "mex.svg",
			ned: "ned.svg",
			nga: "nga.svg",
			por: "por.svg",
			sen: "sen.svg",
			tun: "tun.svg",
			tur: "tur.svg",
			uru: "uru.svg",
			den: 'den.svg',
			cmr: 'cmr.svg'
		},
		[SCHEDULED_GAME_TYPE.TURKISH_LEAGUE.value]: {
			ads: "ads.svg",
			aly: "aly.svg",
			ank: "ank.svg",
			ant: "ant.svg",
			bfk: "bfk.svg",
			bjk: "bjk.svg",
			crs: "crs.svg",
			fb: "fb.svg",
			fkg: "fkg.svg",
			gfk: "gfk.svg",
			gs: "gs.svg",
			hty: "hty.svg",
			ist: "ist.svg",
			kay: "kay.svg",
			kny: "kny.svg",
			ksp: "ksp.svg",
			pen: "pen.svg",
			sam: "sam.svg",
			svs: "svs.svg",
			ts: "ts.svg"
		},
	},
	{
		get: function (target, prop) {
			return prop in target ? target[prop] : target[SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value];
		}
	}
);
