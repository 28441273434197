import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { setBoxesActionBefore, setBoxesActionFinished, setBoxSaveActionBefore, setBoxSaveActionFinished } from "./boxes.action";

import { SET_BOX_GENERAL_INFO } from "store/actionTypes";

export const setBoxGeneralInfo = (info) => ({
	type: SET_BOX_GENERAL_INFO,
	payload: { info }
});

export const getBoxGeneralInfo = (id) => {
	return (dispatch) => {
		dispatch(setBoxesActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BOX_GET_GENERAL_INFO}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: info } }) => {
				dispatch(setBoxGeneralInfo(info));
				dispatch(setBoxesActionFinished());
			})
			.catch((ex) => {
				dispatch(setBoxesActionFinished());
			});
	};
};

export const saveBoxGeneralInfo = (info) => {
	return (dispatch) => {
		dispatch(setBoxSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BOX_SAVE_GENERAL_INFO}`,
			method: Methods.POST,
			data: {
				...info
			}
		})
			.then(({ data: { message: msg, status, value: info } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setBoxGeneralInfo(info));
				}
				dispatch(setBoxSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setBoxSaveActionFinished());
			});
	};
};
