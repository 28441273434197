export const ANALYTICAL_TOOL_TYPE = {
	HOTJAR: 1,
	GOOGLE_ANALYTICS: 2,
	VWO: 4,
	YANDEX_METRICA: 8
};

export const LOGIN_CONFIGURATION_TYPE = {
	URL: 1,
	POPUP: 2
};

export const INTEGRATION_TYPE = {
	IFRAME: 1,
	FEED: 2
};

export const ORIGIN_FOR_IFRAME_POST_MESSAGE = "*";
