import { Navigate } from "react-router-dom";

import Paths from "constants/path.constants";
import { isAuthenticated } from "utils/auth";

const ProtectedRoute = ({ children }) => {
	if (!isAuthenticated()) {
		const return_url = location.pathname + location.hash;
		return <Navigate to={`${Paths.LOGIN}${location.pathname !== Paths.DASHBOARD ? `?returnURL=${encodeURIComponent(return_url)}` : ``}`} replace />;
	}

	return children;
};

export default ProtectedRoute;
