import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Row, Col, Divider } from "antd";
import useDashboardRetailRequest from "hooks/dashboard/useDashboardRetailRequest";
import BetshopsCountWidget from "../../widgets/betshopsCount";
import PendingBetslipsWidget from "../../widgets/pendingBetslips";
import TotalBetslipsWidget from "../../widgets/totalBetslips";
import TotalBonusBetsWidget from "../../widgets/totalBonusBets";
import TotalTurnoverWidget from "../../widgets/totalTurnover";
import TotalGGRAmountWidget from "../../widgets/totalGGRAmount";
import TotalGGRPercentageWidget from "../../widgets/totalGGRPercentage";
import TotalWinAmountWidget from "../../widgets/totalWinAmount";
import BetslipsChart from "../../charts/betslipsChart";
import GamePerformanceChart from "../../charts/gamePerformanceChart";
import TopMarketsChart from "../../charts/topMarketsChart";
import DashboardFilters from "../../filters";
import { PROJECT_TYPE } from "constants/common.constants";

/** Dashboard Page retail Dashboard Tab Component */
const RetailDashboardComponent = ({ filters, activeKey }) => {
	useDashboardRetailRequest(activeKey);

	return (
		<div className="dashboard-section-content">
			<Row gutter={[16, 0]}>
				<Col xs={24} sm={12} lg={6} xxl={4}>
					<BetshopsCountWidget />
				</Col>
				<Col xs={24} sm={12} lg={6} xxl={4}>
					<PendingBetslipsWidget projectType={PROJECT_TYPE.RETAIL} activeKey={activeKey} />
				</Col>
			</Row>
			<Divider />
			<DashboardFilters projectType={PROJECT_TYPE.RETAIL} />
			<Row gutter={[16, 0]}>
				<Col xs={24} sm={12} lg={6} xxl={4}>
					<TotalBetslipsWidget projectType={PROJECT_TYPE.RETAIL} />
				</Col>
				<Col xs={24} sm={12} lg={6} xxl={4}>
					<TotalBonusBetsWidget projectType={PROJECT_TYPE.RETAIL} />
				</Col>
				<Col xs={24} sm={12} lg={6} xxl={4}>
					<TotalTurnoverWidget projectType={PROJECT_TYPE.RETAIL} />
				</Col>
				<Col xs={24} sm={12} lg={6} xxl={4}>
					<TotalGGRAmountWidget projectType={PROJECT_TYPE.RETAIL} />
				</Col>
				<Col xs={24} sm={12} lg={6} xxl={4}>
					<TotalGGRPercentageWidget projectType={PROJECT_TYPE.RETAIL} />
				</Col>
				<Col xs={24} sm={12} lg={6} xxl={4}>
					<TotalWinAmountWidget projectType={PROJECT_TYPE.RETAIL} />
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col xs={24}>
					<GamePerformanceChart projectType={PROJECT_TYPE.RETAIL} gameCategory={filters.gameCategory} />
				</Col>
				<Col xs={24}>
					<TopMarketsChart projectType={PROJECT_TYPE.RETAIL} />
				</Col>
			</Row>
			<Row gutter={[16, 0]}>
				<Col span={24}>
					<BetslipsChart period={filters.period} projectType={PROJECT_TYPE.RETAIL} />
				</Col>
			</Row>
		</div>
	);
};

/** RetailDashboardComponent propTypes
 * PropTypes
 */
RetailDashboardComponent.propTypes = {
	/** Redux state property, dashboard filters*/
	filters: PropTypes.object,
	/** Active tab key */
	activeKey: PropTypes.oneOf(Object.values(PROJECT_TYPE).map((v) => v.toString()))
};

const mapStateToProps = (state) => {
	return {
		filters: state.dashboard.retail.filters
	};
};

const mapDispatchToProps = null; // dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(RetailDashboardComponent);
