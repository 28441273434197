import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { setGamesActionBefore, setGamesActionFinished } from "./games.action";

import { SET_GAME_BANNERS } from "store/actionTypes";
import { delay } from "utils/common";

const setGameBanners = (banners) => ({
	type: SET_GAME_BANNERS,
	payload: { banners }
});

export const getBanners = (id) => {
	return (dispatch) => {
		dispatch(setGamesActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_GET_BANNERS}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: banners } }) => {
				dispatch(setGameBanners(banners));
				dispatch(setGamesActionFinished());
			})
			.catch((ex) => {
				dispatch(setGamesActionFinished());
			});
	};
};

export const deleteBanner = (id, bannerNumber, type) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_DELETE_BANNER}`,
			method: Methods.DELETE,
			data: { id, bannerNumber, type }
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
				}
				dispatch(getBanners(id));
			})
			.catch((ex) => {
				console.log(ex);
			});
	};
};

export const resetBanners = () => {
	return dispatch => {
		dispatch(setGameBanners({}))
	}
}

export const updateBanner = (name, banner, index) => {
	return async (dispatch, getState) => {
		dispatch(setGamesActionBefore());
		const banners = getState().games.editingGame.banners;
		await delay(100)
		const newBanners = {...banners, [name]: [...banners[name]]}
		newBanners[name] = newBanners[name].map((b, i) => {
			if (i === index) {
				return { ...banner }
			}
			return b;
		})
		await delay(100)
		dispatch(setGameBanners(newBanners))
		dispatch(setGamesActionFinished());
	}
}
