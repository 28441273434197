import PropTypes from "prop-types";

export default PropTypes.shape({
	loginSettings: PropTypes.shape({
		failedLoginAttempts: PropTypes.number,
		autoUnlockTime: PropTypes.number,
		autoUnlockCount: PropTypes.number
	}),
	twoFactorEnabled: PropTypes.bool,
	twoFASettings: PropTypes.shape({
		failedLoginAttempts: PropTypes.number,
		autoUnlockTime: PropTypes.number,
		autoUnlockCount: PropTypes.number
	})
});
