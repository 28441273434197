import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Col, Row, Modal, Input, Tag } from "antd";
const { Item: FormItem } = Form;

import { getJobSettings, saveJobSettings } from "store/actions/dashboard/developer/jobs/jobs.action";

import jobSettingsType from "types/job/jobSettings.type";

import { isFormChanged } from "utils/form";

import { POPUP_SIZE } from "constants/common.constants";
import { hasPermission } from "utils/permissions";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

/** Job Editing Popup Component */
const JobEditComponent = ({ isSaving, getJobSettings, saveJobSettings, jobSettings, onClose, id }) => {
	const { t } = useTranslation();
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.MODIFY });

	/** Load job settings */
	useEffect(() => {
		getJobSettings(id);
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setTimeout(() => {
			setFieldsValue({
				...jobSettings
			});
		}, 0);
	}, [jobSettings]);

	/** Fires when form submitted
	 * @function
	 * @memberOf JobEditComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ cronExpression, allowedServerNames }) => {
				saveJobSettings({
					cronExpression,
					id,
					allowedServerNames,
					enabled: jobSettings.enabled
				});
			})
			.catch(Function.prototype);
	};

	return (
		<Modal
			open={true}
			title={t("pages.dashboard.jobs.edit_job")}
			cancelText={t("common.cancel")}
			okText={t("common.save")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.SMALL}
			maskClosable={false}
			closable={false}
			okButtonProps={{
				loading: isSaving,
				disabled: !isFormTouched,
				style: isDisabled ? { display: "none" } : null
			}}
			centered
		>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					cronExpression: jobSettings.cronExpression,
					allowedServerNames: jobSettings.allowedServerNames
				}}
				onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, { ...jobSettings }))}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={t("pages.dashboard.jobs.cronDescription")}>
							<Tag className="form-control-tag">{jobSettings.cronDescription}</Tag>
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem label={`${t("pages.dashboard.jobs.cronExpression")} *`} name="cronExpression" rules={[{ required: true, message: t("validation.field_required") }]}>
							<Input disabled={isDisabled} placeholder={`${t("common.enter")} ${t("pages.dashboard.jobs.cronExpression")}`} />
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem label={`${t("pages.dashboard.jobs.allowedServerNames")} *`} name="allowedServerNames" rules={[{ required: true, message: t("validation.field_required") }]}>
							<Input disabled={isDisabled} placeholder={`${t("common.enter")} ${t("pages.dashboard.jobs.allowedServerNames")}`} />
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** JobEditComponent propTypes
 * PropTypes
 */
JobEditComponent.propTypes = {
	/** Redux state property, is true when job is saving */
	isSaving: PropTypes.bool,
	/** Redux action to save job settings */
	saveJobSettings: PropTypes.func,
	/** Redux action to get job settings */
	getJobSettings: PropTypes.func,
	/** Redux state, represents the settings of current editing job  */
	jobSettings: jobSettingsType,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Current editing job id */
	id: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getJobSettings: (id) => {
		dispatch(getJobSettings(id));
	},
	saveJobSettings: (settings) => {
		dispatch(saveJobSettings(settings));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.jobs.isSaving,
		jobSettings: state.jobs.jobSettings
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(JobEditComponent);
