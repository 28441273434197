import { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import PropTypes from "prop-types";

import { Form, Input } from "antd";

import { constructArrayForGivenRange } from "utils/common";
import { getFirstEmptyInputIndex, isPasswordInputsFilled, isPasswordEmpty } from "utils/password";

const NOT_FOUND_VALUE = -1;

const TwoFactorInputs = forwardRef((props, ref) => {
	const { charactersCount = 6, focusFirst = true, onFinish, label = "Enter Password", isDisabled } = props;

	const [passwordArr, setPasswordArr] = useState(new Array(charactersCount));

	const inputsRef = useRef([]);

	const resetInputsValue = () => {
		const resetPassword = new Array(charactersCount);

		setPasswordArr(resetPassword);
	};

	useImperativeHandle(ref, () => {
		return {
			resetInputsValue
		};
	});

	const focusFirstEmptyInput = ({ focusedInputIndex, passwordArr }) => {
		const emptyInputIndex = getFirstEmptyInputIndex(passwordArr);

		if (emptyInputIndex === NOT_FOUND_VALUE) {
			if (focusedInputIndex === null) {
				return;
			}

			inputsRef.current[focusedInputIndex].blur();
			return;
		}

		inputsRef.current[emptyInputIndex].focus();
	};

	const focusPreviousInput = ({ focusedInputIndex }) => {
		if (focusedInputIndex === 0) {
			return;
		}

		inputsRef.current[focusedInputIndex - 1].focus();
	};

	const updatePassword = ({ value, index }) => {
		if (!value.trim()) {
			setPasswordArr((prevState) => {
				prevState[index] = "";

				return [...prevState];
			});

			return;
		}

		passwordArr[index] = value;

		setPasswordArr([...passwordArr]);
		focusFirstEmptyInput({
			focusedInputIndex: index,
			passwordArr
		});
	};

	const handleUserPast = ({ e, focusedInputIndex }) => {
		e.preventDefault();
		const pastedValue = e.clipboardData.getData("Text");
		const pastedValueWithoutSpaces = pastedValue.replace(/\s/g, "");
		const pastedValueWithoutSpacesArr = pastedValueWithoutSpaces.split("");

		// If the data inserted is long or short of the required number of characters
		pastedValueWithoutSpacesArr.length = charactersCount;

		setPasswordArr(pastedValueWithoutSpacesArr);
		focusFirstEmptyInput({
			focusedInputIndex,
			passwordArr: pastedValueWithoutSpacesArr
		});
	};

	useEffect(() => {
		if (isPasswordInputsFilled(passwordArr)) {
			if (typeof onFinish === "function") {
				onFinish(passwordArr);
			}
		}

		if (focusFirst && isPasswordEmpty(passwordArr)) {
			focusFirstEmptyInput({ focusedInputIndex: null, passwordArr });
		}
	}, [passwordArr]);

	return (
		<Form requiredMark={false}>
			<div className="vs--2FA-inputs-container">
				<label className="vs--2FA-inputs-label" onClick={() => focusFirstEmptyInput({ focusedInputIndex: null, passwordArr })}>
					{label}
				</label>
				<div className={`vs--2FA ${charactersCount > 7 ? "vs--2FA-gap-8" : ""}`}>
					{constructArrayForGivenRange({ from: 1, to: charactersCount }).map((_, index) => {
						return (
							<div className="vs--2FA-input-box" key={index}>
								<Input
									disabled={isDisabled}
									maxLength={1}
									onKeyDown={(e) => {
										if (!passwordArr[index] && e.key === "Backspace") {
											focusPreviousInput({ focusedInputIndex: index });
										}
									}}
									onPaste={(e) => handleUserPast({ e, focusedInputIndex: index })}
									onChange={(e) => updatePassword({ value: e.target.value, index })}
									value={passwordArr[index]}
									ref={(elem) => {
										inputsRef.current[index] = elem;
									}}
								/>
								<div className="vs--2FA-input-box-underline" />
							</div>
						);
					})}
				</div>
			</div>
		</Form>
	);
});

/** TwoFactorInputs propTypes
 * PropTypes
 */
TwoFactorInputs.propTypes = {
	/** Indicates how mach inputs should be */
	charactersCount: PropTypes.number,
	/** If true first input will be auto focused */
	focusFirst: PropTypes.bool,
	/** Fires when all inputs will be filled */
	onFinish: PropTypes.func,
	/** Label on top of the inputs */
	label: PropTypes.string,
	/** Inputs disabled or not */
	isDisabled: PropTypes.bool
};

export default TwoFactorInputs;
