import { useState } from "react";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Row, Button } from "antd";
import QuickInfo from "components/common/quickInfo";

import UserQuickInfoTable from "../../../components/users-quickInfo.table.component";
import UserAddComponent from "../../../components/user-add.component";

import Paths from "constants/path.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

/** Players Quick Info Component */

const UserQuickInfo = ({ id, globalPartnerId, onClose }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	/** State to show/hide user creation popup */
	const [showCreatePopup, setShowCreatePopup] = useState(false);

	return (
		<QuickInfo
			title={
				<div className="dashboard-permission-group-users-quickview-component-title">
					{t("pages.dashboard.permissionGroups.groups_quick_view")}
					<Button onClick={() => navigate(Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, `${id}?PI=${globalPartnerId}#tab=2`))} type="primary">
						{t("pages.dashboard.permissionGroups.see_more")}
					</Button>
				</div>
			}
			visible={id !== null}
			onClose={onClose}
			className="dashboard-permission-group-users-drawer"
		>
			<div className="dashboard-permission-group-users-quickview-component-header">
				<span>{t("pages.dashboard.permissionGroups.group_users")}</span>
			</div>
			<div className="dashboard-permission-group-users-quickview-component">
				<div className="dashboard-permission-group-users-quickview-component-addNew">
					<Button onClick={() => setShowCreatePopup(true)} type="primary">
						{t("pages.dashboard.users.add_user")}
					</Button>
				</div>
				<Row>
					<UserQuickInfoTable id={id} />
				</Row>
			</div>
			<UserAddComponent
				onClose={() => {
					setShowCreatePopup(false);
				}}
				groupId={id}
				visible={showCreatePopup}
			/>
		</QuickInfo>
	);
};

/** UserQuickInfo propTypes
 * PropTypes
 */
UserQuickInfo.propTypes = {
	/** The id of the group which quick info is showing */
	id: PropTypes.string,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Function to call on qucik info close */
	onClose: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps)(UserQuickInfo);
