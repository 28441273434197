import { Fragment } from "react";

import PlayersComponent from "components/dashboard/online/players/players.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const PlayersRoute = () => {
	return (
		<Fragment>
			<PlayersComponent />
		</Fragment>
	);
};

export default withPermission(PlayersRoute, { resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
