import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import TwoFactorInputs from "./twoFactorInputs.component";
import TwoFactorDigitalCodeView from "./twoFactorDigitalCodeView.component";
import TwoFactorQRView from "./twoFactorQR.component";

import { TOKEN_TYPE } from "constants/auth.constants";

import { getTwoFactorQR, verifyQRCode } from "store/actions/auth/auth.action";

/** Login Page Two Factor Authentication Component */
const TwoFactorComponent = ({ tokenType, token, userName, getTwoFactorQR, verifyQRCode, QRBase64, isLoading }) => {
	const inputsResetFnRef = useRef(null);

	/** Get QQ code image, if two factor authentication state is QR code */
	useEffect(() => {
		if (tokenType === TOKEN_TYPE.QR) {
			getTwoFactorQR(token);
		}
	}, []);

	const onVerifyFail = () => {
		inputsResetFnRef.current.resetInputsValue();
	};

	return (
		<div className="vs--2FA-container">
			{tokenType === TOKEN_TYPE.QR ? <TwoFactorQRView QRsrc={QRBase64} /> : <TwoFactorDigitalCodeView />}
			<TwoFactorInputs ref={inputsResetFnRef} isDisabled={isLoading} onFinish={(passwordArr) => verifyQRCode(token, passwordArr.join(""), userName, onVerifyFail)} label="Enter Digital Code from Your Authenticator" />
		</div>
	);
};

/** TwoFactorComponent propTypes
 * PropTypes
 */
TwoFactorComponent.propTypes = {
	/** TwoFactor authentication state */
	tokenType: PropTypes.string,
	/** Token for TwoFactor authentication */
	token: PropTypes.string,
	/** UserName for TwoFactor authentication */
	userName: PropTypes.string,
	/** Base64 image urk for QR code */
	QRBase64: PropTypes.string,
	/** Redux action to get base64 image if QR code */
	getTwoFactorQR: PropTypes.func,
	/** Redux action to verify code for QR scanning */
	verifyQRCode: PropTypes.func,
	/** Redux state property, is true when authenticating */
	isLoading: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	getTwoFactorQR: (token) => {
		dispatch(getTwoFactorQR(token));
	},
	verifyQRCode: (token, code, userName, onVerifyFail) => {
		dispatch(verifyQRCode(token, code, userName, onVerifyFail));
	}
});

const mapStateToProps = (state) => {
	return {
		QRBase64: state.auth.QRBase64,
		isLoading: state.auth.isLoading,
		userName: state.auth.twoFactorData.userName,
		tokenType: state.auth.twoFactorData.tokenType,
		token: state.auth.twoFactorData.token
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorComponent);
