import { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import Filters from "./filters.component";
import ExportButton from "components/common/exportButton";
import ColumnsButton from "components/common/columnsButton";

import { getUserLogs, getUserLogDetails, setUserLogsSorting, setUserLogsFilters } from "store/actions/dashboard/userLogs/userLogs.action";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import localStorageUtils from "utils/localStorage";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import ApiUrls from "constants/api.constants";
import userLogType from "types/userLog/userLog.type";
import sortingType from "types/common/sorting.type";

/** User Logs Page Component */
const UserLogsComponent = ({ getUserLogs, getUserLogDetails, userLogs, isLoading, total, setUserLogsSorting, setUserLogsFilters, sorting, filters, globalPartnerId }) => {
	const { t } = useTranslation();

	/** Columns list, which can be included/excluded */
	const columnsToInclude = [
		{ title: t("pages.dashboard.userLogs.changes"), key: "count" },
		{ title: t("pages.dashboard.userLogs.browser"), key: "browserName" },
		{ title: t("pages.dashboard.userLogs.device_type"), key: "deviceType" },
		{ title: t("pages.dashboard.userLogs.ip"), key: "ipAddress" },
		{ title: t("pages.dashboard.userLogs.os"), key: "os" }
	];

	const [includedColumns, setIncludedColumns] = useState((localStorageUtils.get("selected_columns") || {})["userLogs"] || []);

	/** Function which will fire on columns popup apply button click
	 * @function
	 * @param {array} selectedColumns - selected columns
	 * @memberOf UserLogsComponent
	 */
	const handleColumnsApply = (selectedColumns) => {
		setIncludedColumns(selectedColumns);
	};

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.userLogs.date"),
			dataIndex: "logTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.userLogs.user"),
			dataIndex: "userName"
		},
		{
			title: t("pages.dashboard.userLogs.resource"),
			dataIndex: "resource"
		},
		{
			title: t("pages.dashboard.userLogs.resource_id"),
			dataIndex: "resourceId",
			copy: true
		},
		{
			title: t("pages.dashboard.userLogs.resource_name"),
			dataIndex: "resourceName"
		},
		{
			title: t("pages.dashboard.userLogs.action"),
			dataIndex: "action"
		},
		{
			title: t("pages.dashboard.userLogs.actionType"),
			dataIndex: "actionType",
			render: (value) => (value ? t("pages.dashboard.userLogs.actionType_" + value) : "-")
		},
		{
			title: t("pages.dashboard.userLogs.changes"),
			dataIndex: "count",
			sorter: false,
			render: (value) => value + " " + t("pages.dashboard.userLogs.changes")
		},
		{
			title: t("pages.dashboard.userLogs.browser"),
			dataIndex: "browserName",
			sorter: false,
			render: (value, record) => value + " " + record.browserVersion
		},
		{
			title: t("pages.dashboard.userLogs.device_type"),
			dataIndex: "deviceType",
			sorter: false
		},
		{
			title: t("pages.dashboard.userLogs.ip"),
			dataIndex: "ipAddress",
			sorter: false
		},
		{
			title: t("pages.dashboard.userLogs.os"),
			dataIndex: "os",
			sorter: false
		}
	];

	/** Keep included columns in local storage */
	useEffect(() => {
		const columns = localStorageUtils.get("selected_columns") || {};
		columns["userLogs"] = includedColumns;
		localStorageUtils.set("selected_columns", columns);
	}, [includedColumns]);

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.user_logs") }]} />
				<div className="dashboard-section-content">
					<div className="table-header">
						<div className="table-buttons-dropdowns">
							{hasPermission({ resource: PERMISSION_RESOURCE.USER_LOGS, action: PERMISSION_ACTION.EXPORT }) ? (
								<ExportButton columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t("pages.dashboard.menu.user_logs")} url={ApiUrls.EXPORT_USER_LOGS_GETALL} filters={filters} sorting={sorting} />
							) : null}
							<ColumnsButton columns={columnsToInclude} onApply={handleColumnsApply} defaultSelectedColumns={includedColumns} />
						</div>
						<Filters />
					</div>
					<Table
						loading={isLoading}
						columns={columns.filter((c) => !columnsToInclude.find((col) => col.key === c.dataIndex) || includedColumns.includes(c.dataIndex))}
						data={userLogs}
						loadFn={getUserLogs}
						sorting={sorting}
						filters={filters}
						setSortingFn={setUserLogsSorting}
						setFiltersFn={setUserLogsFilters}
						total={total}
						updateProps={[globalPartnerId]}
						isDisabled={() => false}
						detailsType="table"
						detailsLoadFn={(record) => (!record.details ? getUserLogDetails(record.id) : false)}
						details={(record) =>
							record.details
								? {
										columns: [
											{
												title: "#",
												dataIndex: "index",
												width: "1%"
											},
											{
												title: t("pages.dashboard.userLogs.name"),
												dataIndex: "name"
											},
											{
												title: t("pages.dashboard.userLogs.previousValue"),
												dataIndex: "previousValue",
												render: (value) => value || "-"
											},
											{
												title: t("pages.dashboard.userLogs.currentValue"),
												dataIndex: "currentValue",
												render: (value) => value || "-"
											}
										],
										data: record.details ? record.details.map((d, i) => ({ ...d, index: i + 1 })) : [],
										uniqueKey: "index"
									}
								: null
						}
						enableReload={true}
					/>
				</div>
			</div>
		</Fragment>
	);
};

/** UserLogsComponent propTypes
 * PropTypes
 */
UserLogsComponent.propTypes = {
	/** Redux action to get user logs */
	getUserLogs: PropTypes.func,
	/** Redux action to get user logs details */
	getUserLogDetails: PropTypes.func,
	/** Redux state property, represents the array of user logs  */
	userLogs: PropTypes.arrayOf(userLogType),
	/** Redux state property, is true when loading user logs */
	isLoading: PropTypes.bool,
	/** Redux state property, user logs total count */
	total: PropTypes.number,
	/** Redux action to set user logs sorting details */
	setUserLogsSorting: PropTypes.func,
	/** Redux action to set user logs filters */
	setUserLogsFilters: PropTypes.func,
	/** Redux state property, user logs sorting details */
	sorting: sortingType,
	/** Redux state property, user logs filters */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getUserLogs: (fromFirstPage) => {
		dispatch(getUserLogs(fromFirstPage));
	},
	getUserLogDetails: (id) => {
		dispatch(getUserLogDetails(id));
	},
	setUserLogsSorting: (sorting) => {
		dispatch(setUserLogsSorting(sorting));
	},
	setUserLogsFilters: (filters) => {
		dispatch(setUserLogsFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.userLogs.isLoading,
		userLogs: state.userLogs.userLogs,
		total: state.userLogs.total,
		sorting: state.userLogs.sorting,
		filters: state.userLogs.filters,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLogsComponent);
