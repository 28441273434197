import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import VideoControls from "./controls";
import VideoLayers from "./layers";

import { isMobile } from "utils/common";

import { createPlayer } from "utils/videoPlayer";

import { GAME_STREAM_CONFIGURATION_TYPE } from "constants/game.constants";

import streamConfigurationType from "types/game/streamConfiguration.type";

const idText = "vs--player";
const getDefaultMinimizedState = (value) => (isMobile() ? value : false);
const createElementId = (idArg) => (!idArg ? idText : idText + "-" + idArg);
const ifFunctionThenCallWith = (possibleFunction, ...args) => typeof possibleFunction === "function" && possibleFunction(...args);
const filterrArrayByIndexOfBoolean = (arrayWithBooleans, arrayWithValues) => {
	const retVal = [];
	arrayWithBooleans.forEach((boolean, index) => {
		boolean && retVal.push(arrayWithValues[index]);
	});
	return retVal;
};

/** Video Component */
const VideoPlayer = ({ streamConfiguration, streamProvider, streamUpdateFn, isDemo, isMinimized, defaultExtended, onExtend, onMinimize, showMinimize, showFullscreen, showExtend, id }) => {
	const playerInstance = useRef();

	const [muted, setMuted] = useState(true);
	const [paused, setPaused] = useState(false);
	const [volume, setVolume] = useState(0);
	const [loaded, setLoaded] = useState(false);
	const [error, setError] = useState(false);
	const [showControls, setShowControls] = useState(false);
	const [started, setStarted] = useState(false);

	const [minimized, setMinimized] = useState(getDefaultMinimizedState(isMinimized));
	const [extended, setExtended] = useState(defaultExtended);

	/** Initialize Player */
	useEffect(() => {
		playerInstance.current = createPlayer(streamProvider, streamConfiguration, {
			elementID: createElementId(id),
			languageCode: null,
			streamUpdateFn: streamUpdateFn,
			onError: (err) => setError(err),
			onVolumeChange: (volume) => setVolume(volume),
			onPause: () => setPaused(true),
			onPlay: () => {
				setPaused(false);
				setStarted(true);
			},
			onMute: (m) => setMuted(m),
			onReady: () => setLoaded(true),
			onDestroy: () => setLoaded(false),
			onControlsVisiblityChange: (v) => setShowControls(v)
		});

		return () => {
			playerInstance.current.destroy();
		};
	}, [streamProvider]);

	useEffect(() => {
		if (isMinimized) return;
		playerInstance.current.destroy();
		playerInstance.current.setStreamConfiguration(streamConfiguration);
		playerInstance.current.init();
	}, [streamConfiguration, streamProvider]);

	/** Update minimized state when  isMinimized property changes */
	useEffect(() => {
		if (isMinimized) {
			playerInstance.current.destroy();
		} else {
			playerInstance.current.init();
		}

		setMinimized(isMinimized);
	}, [isMinimized]);

	/** Function which will be called on minimize button click
	 * @function
	 * @memberOf VideoPlayer
	 */
	const handleMinimize = () => {
		const update = !minimized;
		setMinimized(update);
		ifFunctionThenCallWith(onMinimize, update);
	};

	/** Function which will be called on enlarge/shrink button click
	 * @function
	 * @memberOf VideoPlayer
	 */
	const handleEnlargeShrink = () => {
		const update = !extended;
		setExtended(update);
		ifFunctionThenCallWith(onExtend, update);
	};

	const renderChildren = () => {
		if (error) return <VideoLayers error={error} />;
		if (!loaded) return null;
		return (
			<VideoControls
				onMuteUnmute={() => playerInstance.current.toggleMuteUnmute()}
				onFullscreen={() => playerInstance.current.onFullscreen()}
				onEnlargeShrink={handleEnlargeShrink}
				onMinimize={handleMinimize}
				isMuted={volume === 0 || muted}
				extended={extended}
				showMinimize={showMinimize}
				showFullscreen={showFullscreen}
				showExtend={showExtend}
				volume={volume}
				onVolumeChange={(volume) => playerInstance.current.setPlayerVolume(volume)}
			/>
		);
	};

	return (
		<div
			className={[
				"vs--video-container",
				...filterrArrayByIndexOfBoolean(
					[
						!started /* "vs--video-container-untouched" */,
						isDemo && !error /* "vs--video-container-demo" */,
						error /* "vs--video-container-error" */,
						paused /* "vs--video-container-paused" */,
						showControls /* "vs--video-container-controls-visible" */,
						minimized /* "vs--video-container-minimized" */
					],
					[
						"vs--video-container-untouched" /* !started */,
						"vs--video-container-demo" /* isDemo && !error */,
						"vs--video-container-error" /* error */,
						"vs--video-container-paused" /* paused */,
						"vs--video-container-controls-visible" /* showControls */,
						"vs--video-container-minimized" /* minimized */
					]
				)
			].join(" ")}
		>
			<div id={createElementId(id)} data-mobile={isMobile()} />
			{renderChildren()}
		</div>
	);
};

/** VideoPlayer propTypes
 * PropTypes
 */
VideoPlayer.propTypes = {
	/** Stream configuration object */
	streamConfiguration: streamConfigurationType,
	/** Stream provider */
	streamProvider: PropTypes.oneOf([...Object.values(GAME_STREAM_CONFIGURATION_TYPE), 0]),
	/** Function to call to update stream */
	streamUpdateFn: PropTypes.func,
	/**Is Demo Mode */
	isDemo: PropTypes.bool,
	/** Is video enlarged By default*/
	defaultExtended: PropTypes.bool,
	/** If this property is true, the player will be minimized */
	isMinimized: PropTypes.bool,
	/** Function which will fire on player minimize */
	onMinimize: PropTypes.func,
	/** Function which will fire on player shronk, enlarge */
	onExtend: PropTypes.func,
	/** Show minimize button on mobile */
	showMinimize: PropTypes.bool,
	/** Show fullscreen button */
	showFullscreen: PropTypes.bool,
	/** Show extend button */
	showExtend: PropTypes.bool,
	/** Stream tag id */
	id: PropTypes.string
};

export default VideoPlayer;
