import { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ApiUrls from "constants/api.constants";

import { Button } from "antd";

import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { getSystemLanguages, setSystemLanguagesSorting } from "store/actions/dashboard/settings/systemLanguages/systemLanguages.action";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import ExportButton from "components/common/exportButton";
import SystemLanguageAddComponent from "./systemLanguages-add.component";

import systemLanguageType from "types/systemLanguages/systemLanguage.type";
import sortingType from "types/common/sorting.type";

/** System Languages Page Component */
const SystemLanguagesComponent = ({ getSystemLanguages, systemLanguages, isLoading, total, setSystemLanguagesSorting, sorting }) => {
	const { t } = useTranslation();

	/** State to show/hide language add popup */
	const [showCreatePopup, setShowCreatePopup] = useState(false);

	/** Close language add popup, after save */
	useEffect(() => {
		setShowCreatePopup(false);
	}, [systemLanguages]);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.language.name"),
			dataIndex: "name"
		},
		{
			title: t("pages.dashboard.language.code"),
			dataIndex: "code"
		},
		{
			title: t("pages.dashboard.language.created_at"),
			dataIndex: "created",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		}
	];

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.languages") }]} />
				<div className="dashboard-section-content">
					<div className="table-buttons-dropdowns">
						{hasPermission({
							resource: PERMISSION_RESOURCE.SETTINGS_LANGUAGES,
							action: PERMISSION_ACTION.EXPORT
						}) ? (
							<ExportButton columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t("pages.dashboard.menu.languages")} url={ApiUrls.EXPORT_SYSTEM_LANGUAGES} sorting={sorting} />
						) : null}
						<div className="table-header-text">
							<span>{`${t("pages.dashboard.language.system_default_language")}: English`}</span>
						</div>
						{hasPermission({
							resource: PERMISSION_RESOURCE.SETTINGS_LANGUAGES,
							action: PERMISSION_ACTION.CREATE
						}) ? (
							<div className="table-buttons-dropdowns-button">
								<Button onClick={() => setShowCreatePopup(true)} type="primary">
									{t("pages.dashboard.language.create_language")}
								</Button>
							</div>
						) : null}
					</div>
					<Table loading={isLoading} columns={columns} data={systemLanguages} loadFn={getSystemLanguages} sorting={sorting} setSortingFn={setSystemLanguagesSorting} total={total} actions={{}} isDisabled={() => false} uniqueKey="code" />
				</div>
			</div>

			{showCreatePopup ? <SystemLanguageAddComponent onClose={() => setShowCreatePopup(false)} onCreateCallback={getSystemLanguages} /> : null}
		</Fragment>
	);
};

/** SystemLanguagesComponent propTypes
 * PropTypes
 */
SystemLanguagesComponent.propTypes = {
	/** Redux action to get system languages */
	getSystemLanguages: PropTypes.func,
	/** Redux state property, represents the array of loaded system languages */
	systemLanguages: PropTypes.arrayOf(systemLanguageType),
	/** Redux state property, system languages total count */
	total: PropTypes.number,
	/** Redux action to set system languages sorting details */
	setSystemLanguagesSorting: PropTypes.func,
	/** Redux state property, system languages sorting details */
	sorting: sortingType,
	/** Redux state property, is true when loading system languages */
	isLoading: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	getSystemLanguages: () => {
		dispatch(getSystemLanguages());
	},
	setSystemLanguagesSorting: (sorting) => {
		dispatch(setSystemLanguagesSorting(sorting));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.systemLanguages.isLoading,
		systemLanguages: state.systemLanguages.systemLanguages,
		total: state.systemLanguages.total,
		sorting: state.systemLanguages.sorting
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemLanguagesComponent);
