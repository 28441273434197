import { Editor } from "@tinymce/tinymce-react";
import { useState } from "react";

import { Spin } from "antd";

const TINYMCE_SCRIPT_URL = `${import.meta.env.SYSTEM_CDN_URL}/static/js/tinymce.min.js`;

const DEFAULT_PROPS = {
	FONT_SIZE_FORMATS: "8px 10px 12px 14px 16px 18px 24px 36px",
	LINE_HEIGHT_FORMATS: "8px 10px 12px 14px 16px 18px 24px 36px",
	MENUBAR: "edit insert view format table tools help",
	PLUGINS: [
		'advlist',
		'autolink',
		'lists',
		'link',
		'linkchecker',
		'image',
		'editimage',
		'charmap',
		'preview',
		'anchor',
		"searchreplace",
		"visualblocks",
		"code",
		"fullscreen",
		"insertdatetime",
		"code",
		"table",
		"tableofcontents"
	],
	TOOLBAR: "undo redo | " +
		"blocks fontfamily fontsizeinput | " +
		"lineheight | " +
		"formatselect | bold italic forecolor backcolor | link image | " +
		"align | " +
		"bullist numlist outdent indent | removeformat | "
};

const TinyEditor = ({
	disabled,
	value,
	onChange,
	height = 500,
	fontSizeFormats = DEFAULT_PROPS.FONT_SIZE_FORMATS,
	lineHeightFormats = DEFAULT_PROPS.LINE_HEIGHT_FORMATS,
	menuBar = DEFAULT_PROPS.MENUBAR,
	plugins = DEFAULT_PROPS.PLUGINS,
	toolbar = DEFAULT_PROPS.TOOLBAR
}) => {
	const [isLoading, setIsLoading] = useState(true);

	return (
		<Spin spinning={isLoading}>
			<Editor
				tinymceScriptSrc={TINYMCE_SCRIPT_URL}
				disabled={disabled}
				value={value}
				onEditorChange={onChange}
				onInit={() => {
					setIsLoading(false);
				}}
				init={{
					font_size_formats: fontSizeFormats,
					line_height_formats: lineHeightFormats,
					height,
					menubar: menuBar,
					branding: false,
					target_list: false,
					default_link_target: "_blank",
					link_title: false,
					relative_urls: false,
					elementpath: false,
					directionality: "auto",
					document_base_url: "/",
					plugins,
					toolbar
				}}
			/>
		</Spin>
	)
};

export default TinyEditor;