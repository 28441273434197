import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { SET_USER_PERMISSION_GROUPS, SET_ADD_USER_PERMISSION_GROUP, SET_DELETE_USER_PERMISSION_GROUP, SET_USER_AVAILABLE_PERMISSION_GROUPS, SET_USER_AVAILABLE_PERMISSION_GROUPS_BEFORE, SET_USER_AVAILABLE_PERMISSION_GROUPS_FINISH } from "store/actionTypes";

import { setUsersActionBefore, setUsersActionFinished, setUserSaveActionBefore, setUserSaveActionFinished } from "./users.action";
import { RESPONSE_STATE } from "constants/response.constants";

const setUserPermissionGroups = (data) => ({
	type: SET_USER_PERMISSION_GROUPS,
	payload: { data }
});

const setAddUserPermissionGroup = (group) => ({
	type: SET_ADD_USER_PERMISSION_GROUP,
	payload: { group }
});

const setDeleteUserPermissionGroup = (groupId) => ({
	type: SET_DELETE_USER_PERMISSION_GROUP,
	payload: { groupId }
});

const setUserAvailablePermissionGroups = (data) => ({
	type: SET_USER_AVAILABLE_PERMISSION_GROUPS,
	payload: { data }
});

const setUserAvailablePermissionGroupsBefore = () => ({
	type: SET_USER_AVAILABLE_PERMISSION_GROUPS_BEFORE
});

const setUserAvailablePermissionGroupsFinished = () => ({
	type: SET_USER_AVAILABLE_PERMISSION_GROUPS_FINISH
});

export const getUserPermissionGroups = (id) => {
	return (dispatch) => {
		dispatch(setUsersActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_GET_PERMISSION_GROUPS}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: groups } }) => {
				dispatch(setUserPermissionGroups(groups));
				dispatch(setUsersActionFinished());
			})
			.catch((ex) => {
				dispatch(setUsersActionFinished());
			});
	};
};

export const addUserPermissionGroup = (id, groupId, note, isAccessManager = false) => {
	return (dispatch) => {
		dispatch(setUserSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_ADD_PERMISSION_GROUP}`,
			method: Methods.POST,
			data: { id, groupId, note }
		})
			.then(({ data: { status, value: group } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					dispatch(setAddUserPermissionGroup(isAccessManager ? null : group));
				}
				dispatch(setUserSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setUserSaveActionFinished());
			});
	};
};

export const deleteUserPermissionGroup = (id, groupId, note, isAccessManager = false) => {
	return (dispatch) => {
		dispatch(setUserSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_DELETE_PERMISSION_GROUP}`,
			method: Methods.DELETE,
			data: { id, groupId, note }
		})
			.then(() => {
				dispatch(setDeleteUserPermissionGroup(isAccessManager ? null : groupId));
				dispatch(setUserSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setUserSaveActionFinished());
			});
	};
};

export const getUserAvailablePermissionGroups = (userId) => {
	return (dispatch) => {
		dispatch(setUserAvailablePermissionGroupsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_GET_AVAILABLE_PERMISSION_GROUPS}`,
			method: Methods.GET,
			params: { userId }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setUserAvailablePermissionGroups(data));
				dispatch(setUserAvailablePermissionGroupsFinished());
			})
			.catch((ex) => {
				dispatch(setUserAvailablePermissionGroupsFinished());
			});
	};
};
