import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { LIVE_MONITOR_TRANSLATION_GROUP_ID, LIVE_MONITOR_TRANSLATION_SUB_GROUPS_IDS } from "constants/translation.constants";

import { RESPONSE_STATE } from "constants/response.constants";

import { SET_LIVE_MONITOR_TRANSLATIONS, UPDATE_LIVE_MONITOR_TRANSLATION } from "store/actionTypes";

import { setTranslationsBefore, setTranslationsFinished, setTranslationSaveBefore, setTranslationSaveFinished, setTranslationsPublishBefore, setTranslationsPublishFinished } from "./translation.action";

const setLiveMonitorTranslations = (translations) => ({
	type: SET_LIVE_MONITOR_TRANSLATIONS,
	payload: { translations }
});

const updateLiveMonitorTranslation = (translation, key) => ({
	type: UPDATE_LIVE_MONITOR_TRANSLATION,
	payload: { translation, key }
});

export const getLiveMonitorTranslations = (gameType, languageCodes) => {
	return (dispatch) => {
		dispatch(setTranslationsBefore());
		const languageCodesQuery = languageCodes
			.slice(0, 5)
			.map((l) => "languageCodes=" + l)
			.join("&");

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.LIVE_MONITOR_TRANSLATION_GET_ALL}?${languageCodesQuery}`,
			method: Methods.GET,
			params: {
				baseLanguageCode: "en",
				groupId: LIVE_MONITOR_TRANSLATION_GROUP_ID,
				subGroupId: LIVE_MONITOR_TRANSLATION_SUB_GROUPS_IDS[gameType],
				isSystemDefault: true
			}
		})
			.then(({ data: { value: translations } }) => {
				dispatch(setLiveMonitorTranslations(translations));
				dispatch(setTranslationsFinished());
			})
			.catch(() => {
				dispatch(setTranslationsFinished());
			});
	};
};

export const saveLiveMonitorTranslation = (gameType, key, text, languageCode) => {
	return (dispatch) => {
		dispatch(setTranslationSaveBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.LIVE_MONITOR_TRANSLATION_SAVE}`,
			method: Methods.POST,
			data: {
				key: key,
				text: text,
				languageCode: languageCode,
				groupId: LIVE_MONITOR_TRANSLATION_GROUP_ID,
				subGroupId: LIVE_MONITOR_TRANSLATION_SUB_GROUPS_IDS[gameType],
				isSystemDefault: true
			}
		})
			.then(({ data: { message: msg, status, value: translation } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					dispatch(updateLiveMonitorTranslation(translation, key));
				}

				dispatch(setTranslationSaveFinished());
			})
			.catch(() => {
				dispatch(setTranslationSaveFinished());
			});
	};
};

export const publishLiveMonitorTranslations = (languageCode) => {
	return (dispatch) => {
		dispatch(setTranslationsPublishBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.LIVE_MONITOR_TRANSLATION_PUBLISH}`,
			method: Methods.POST,
			data: { isSystemDefault: true, languageCode }
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
				}
				dispatch(setTranslationsPublishFinished());
			})
			.catch(() => {
				dispatch(setTranslationsPublishFinished());
			});
	};
};
