import { useEffect, Fragment, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { Row, Col, Select, Form } from "antd";
const { Item: FormItem } = Form;

import Breadcrumbs from "components/common/breadcrumbs";
import PermissionsList from "components/dashboard/common/permissions";

import { getPermissions, getPermissionResources } from "store/actions/dashboard/userManagement/permissions/permissions.action";

import permissionType from "types/permission/permission.type";

/** Permissions Page Component */

const PermissionsComponent = ({ getPermissions, getPermissionResources, permissions, resources, isLoading, isResourcesLoading }) => {
	const { t } = useTranslation();

	const [currentResource, setCurrentResource] = useState(null);

	/** Load permissions */
	useEffect(() => {
		if (resources && resources[0]) {
			setCurrentResource(resources[0]);
		}
	}, [resources]);

	/** Load resources */
	useEffect(() => {
		getPermissionResources();
	}, []);

	/** Load permissions */
	useEffect(() => {
		if (currentResource) {
			getPermissions(currentResource);
		}
	}, [currentResource]);

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.permissions") }]} />
				<Form colon={false} requiredMark={false} layout="vertical">
					<div className="dashboard-section-content">
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} md={6}>
								<FormItem label={t("pages.dashboard.permissions.resources")}>
									<Select onChange={(e) => setCurrentResource(e)} loading={isResourcesLoading} value={currentResource} suffixIcon={<i className="icon-down" />}>
										{resources.map((resource) => (
											<Select.Option value={resource} key={resource}>
												{resource}
											</Select.Option>
										))}
									</Select>
								</FormItem>
							</Col>
						</Row>
						<PermissionsList permissions={permissions} isLoading={isLoading} editable={false} />
					</div>
				</Form>
			</div>
		</Fragment>
	);
};

/** PermissionsComponent propTypes
 * PropTypes
 */
PermissionsComponent.propTypes = {
	/** Redux action to get permissions */
	getPermissions: PropTypes.func,
	/** Redux action to get permissin resources */
	getPermissionResources: PropTypes.func,
	/** Redux state property, represents the array of permissions */
	permissions: PropTypes.arrayOf(permissionType),
	/** Redux state property, represents the array of permission resources */
	resources: PropTypes.arrayOf(PropTypes.string),
	/** Redux state property, is true when loading permissions */
	isLoading: PropTypes.bool,
	/** Redux state property, is true when loading permission resources */
	isResourcesLoading: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	getPermissions: (resource) => {
		dispatch(getPermissions(resource));
	},
	getPermissionResources: () => {
		dispatch(getPermissionResources());
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.permissions.isLoading,
		permissions: state.permissions.permissions,
		isResourcesLoading: state.permissions.isResourcesLoading,
		resources: state.permissions.resources
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsComponent);
