import Table from "components/common/table";

const RequestsCommonTable = ({
	isLoading = false,
	columns = [],
	loadFn = Function.prototype,
	data = [],
	sorting = null,
	filters = null,
	setSortingFn = Function.prototype,
	setFiltersFn = Function.prototype,
	total = 0,
	updateProps = [],
	isDisabledFn = () => false,
	detailsLoadFn = Function.prototype,
	detailsFn = Function.prototype,
	actions = null,
	noPagination = false
}) => {
	return (
		<Table
			loading={isLoading}
			columns={columns}
			data={data}
			loadFn={loadFn}
			sorting={sorting}
			filters={filters}
			setSortingFn={setSortingFn}
			setFiltersFn={setSortingFn}
			total={total}
			updateProps={updateProps}
			isDisabled={isDisabledFn}
			detailsType="table"
			detailsLoadFn={detailsLoadFn}
			details={detailsFn}
			enableReload={true}
			actions={actions}
			noPagination={noPagination}
		/>
	);
};
export default RequestsCommonTable;
