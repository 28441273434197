import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";

import Container from "components/dashboard/common/container";
import ProtectedRoute from "routes/protectedRoute";

const Layout = ({ children }) => {
	return <Container>{children}</Container>;
};

Layout.propTypes = {
	children: PropTypes.node
};

const DashboardLayoutRoute = () => {
	return (
		<ProtectedRoute>
			<Layout>
				<Outlet />
			</Layout>
		</ProtectedRoute>
	);
};

export default DashboardLayoutRoute;
