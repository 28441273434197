export default {
	afg: "Afghanistan",
	phl: "Philippines",
	ala: "Aland Islands",
	pol: "Poland",
	dza: "Algeria",
	pcn: "Pitcairn",
	per: "Peru",
	asm: "American Samoa",
	alb: "Albania",
	prt: "Portugal",
	and: "Andorra",
	eth: "Ethiopia",
	swz: "Eswatini",
	fji: "Fiji",
	gnq: "Equatorial Guinea",
	slv: "El Salvador",
	fro: "Faroe Islands",
	est: "Estonia",
	eri: "Eritrea",
	fin: "Finland",
	flk: "Falkland Islands (Malvinas)",
	ago: "Angola",
	pri: "Puerto Rico",
	ata: "Antarctica",
	qat: "Qatar",
	rus: "Russian Federation",
	blm: "Saint Barthélemy",
	reu: "Réunion",
	arg: "Argentina",
	atg: "Antigua and Barbuda",
	rwa: "Rwanda",
	rou: "Romania",
	aia: "Anguilla",
	aut: "Austria",
	abw: "Aruba",
	shn: "Saint Helena, Ascension and Tristan da Cunha",
	bhr: "Bahrain",
	arm: "Armenia",
	lca: "Saint Lucia",
	vct: "Saint Vincent and the Grenadines",
	bhs: "Bahamas",
	bel: "Belgium",
	bgd: "Bangladesh",
	blr: "Belarus",
	aze: "Azerbaijan",
	fra: "France",
	guf: "French Guiana",
	pyf: "French Polynesia",
	gab: "Gabon",
	kna: "Saint Kitts and Nevis",
	deu: "Germany",
	aus: "Australia",
	lka: "Sri Lanka",
	wsm: "Samoa",
	atf: "French Southern Territories",
	sau: "Saudi Arabia",
	spm: "Saint Pierre and Miquelon",
	esp: "Spain",
	sen: "Senegal",
	gha: "Ghana",
	maf: "Saint Martin (French part)",
	srb: "Serbia",
	gmb: "Gambia",
	grc: "Greece",
	geo: "Georgia",
	brb: "Barbados",
	stp: "Sao Tome and Principe",
	gib: "Gibraltar",
	bmu: "Bermuda",
	ssd: "South Sudan",
	grl: "Greenland",
	gum: "Guam",
	glp: "Guadeloupe",
	grd: "Grenada",
	syc: "Seychelles",
	sle: "Sierra Leone",
	gtm: "Guatemala",
	btn: "Bhutan",
	smr: "San Marino",
	ben: "Benin",
	bol: "Bolivia (Plurinational State of)",
	ggy: "Guernsey",
	bra: "Brazil",
	bih: "Bosnia and Herzegovina",
	gin: "Guinea",
	gnb: "Guinea-Bissau",
	hti: "Haiti",
	hmd: "Heard Island and McDonald Islands",
	sxm: "Sint Maarten (Dutch part)",
	sgp: "Singapore",
	vat: "Holy See",
	hnd: "Honduras",
	slb: "Solomon Islands",
	bfa: "Burkina Faso",
	hkg: "Hong Kong",
	hun: "Hungary",
	bes: "Bonaire, Sint Eustatius and Saba",
	isl: "Iceland",
	blz: "Belize",
	guy: "Guyana",
	ind: "India",
	idn: "Indonesia",
	irn: "Iran (Islamic Republic of)",
	bdi: "Burundi",
	irq: "Iraq",
	brn: "Brunei Darussalam",
	irl: "Ireland",
	svn: "Slovenia",
	zaf: "South Africa",
	tcd: "Chad",
	cym: "Cayman Islands",
	imn: "Isle of Man",
	khm: "Cambodia",
	bwa: "Botswana",
	bgr: "Bulgaria",
	bvt: "Bouvet Island",
	isr: "Israel",
	chl: "Chile",
	svk: "Slovakia",
	cck: "Cocos (Keeling) Islands",
	can: "Canada",
	cmr: "Cameroon",
	iot: "British Indian Ocean Territory",
	col: "Colombia",
	som: "Somalia",
	cri: "Costa Rica",
	cok: "Cook Islands",
	caf: "Central African Republic",
	ita: "Italy",
	chn: "China",
	cod: "Congo  Democratic Republic of the",
	dji: "Djibouti",
	cpv: "Cabo Verde",
	hrv: "Croatia",
	jam: "Jamaica",
	cub: "Cuba",
	civ: "Côte d'Ivoire",
	cuw: "Curaçao",
	egy: "Egypt",
	jpn: "Japan",
	cyp: "Cyprus",
	cxr: "Christmas Island",
	sur: "Suriname",
	sjm: "Svalbard and Jan Mayen",
	dnk: "Denmark",
	sdn: "Sudan",
	syr: "Syrian Arab Republic",
	cze: "Czechia",
	dom: "Dominican Republic",
	ecu: "Ecuador",
	com: "Comoros",
	dma: "Dominica",
	twn: "Taiwan, Province of China",
	tjk: "Tajikistan",
	cog: "Congo",
	tza: "Tanzania, United Republic of",
	swe: "Sweden",
	tha: "Thailand",
	nor: "Norway",
	che: "Switzerland",
	pak: "Pakistan",
	tls: "Timor-Leste",
	tgo: "Togo",
	png: "Papua New Guinea",
	tkl: "Tokelau",
	tto: "Trinidad and Tobago",
	tur: "Turkey",
	tun: "Tunisia",
	ton: "Tonga",
	tkm: "Turkmenistan",
	tca: "Turks and Caicos Islands",
	tuv: "Tuvalu",
	ukr: "Ukraine",
	uga: "Uganda",
	are: "United Arab Emirates",
	gbr: "United Kingdom of Great Britain and Northern Ireland",
	usa: "United States of America",
	umi: "United States Minor Outlying Islands",
	ury: "Uruguay",
	ven: "Venezuela (Bolivarian Republic of)",
	vut: "Vanuatu",
	uzb: "Uzbekistan",
	vnm: "Viet Nam",
	vir: "Virgin Islands (U.S.)",
	wlf: "Wallis and Futuna",
	yem: "Yemen",
	esh: "Western Sahara",
	zwe: "Zimbabwe",
	vgb: "Virgin Islands (British)",
	zmb: "Zambia",
	jey: "Jersey",
	kaz: "Kazakhstan",
	jor: "Jordan",
	ken: "Kenya",
	kir: "Kiribati",
	prk: "Korea (Democratic People's Republic of)",
	kor: "Korea, Republic of",
	kgz: "Kyrgyzstan",
	kwt: "Kuwait",
	lao: "Lao People's Democratic Republic",
	lva: "Latvia",
	lbn: "Lebanon",
	lby: "Libya",
	lie: "Liechtenstein",
	ltu: "Lithuania",
	lbr: "Liberia",
	lux: "Luxembourg",
	lso: "Lesotho",
	mdg: "Madagascar",
	mys: "Malaysia",
	mwi: "Malawi",
	mac: "Macao",
	mdv: "Maldives",
	mli: "Mali",
	mlt: "Malta",
	mhl: "Marshall Islands",
	mtq: "Martinique",
	mrt: "Mauritania",
	mus: "Mauritius",
	myt: "Mayotte",
	mex: "Mexico",
	fsm: "Micronesia (Federated States of)",
	mda: "Moldova, Republic of",
	mco: "Monaco",
	mng: "Mongolia",
	mne: "Montenegro",
	msr: "Montserrat",
	mar: "Morocco",
	mmr: "Myanmar",
	moz: "Mozambique",
	nru: "Nauru",
	nld: "Netherlands",
	nam: "Namibia",
	npl: "Nepal",
	ncl: "New Caledonia",
	nzl: "New Zealand",
	ner: "Niger",
	nic: "Nicaragua",
	nga: "Nigeria",
	niu: "Niue",
	nfk: "Norfolk Island",
	mkd: "North Macedonia",
	mnp: "Northern Mariana Islands",
	omn: "Oman",
	pse: "Palestine, State of",
	plw: "Palau",
	pan: "Panama",
	pry: "Paraguay",
	sgs: "South Georgia and the South Sandwich Islands"
};
