import PropTypes from "prop-types";

import { TRANSLATION_SOURCE_TYPE } from "constants/translation.constants";

export default PropTypes.shape({
	key: PropTypes.string,
	text: PropTypes.string,
	translations: PropTypes.arrayOf(
		PropTypes.shape({
			languageCode: PropTypes.string,
			text: PropTypes.string,
			source: PropTypes.oneOf(Object.values(TRANSLATION_SOURCE_TYPE))
		})
	)
});
