import { Fragment } from "react";

import { useTranslation } from "react-i18next";

import { Tabs } from "antd";

import Breadcrumbs from "components/common/breadcrumbs";

import MyGroupsComponent from "./sections/myGroups";

/** Permission Groups Page Component */
const PermissionGroupsComponent = () => {
	const { t } = useTranslation();
	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.permissionGroups.tabs.myGroups") }]} />
				<Tabs
					animated={false}
					items={[
						{
							key: "1",
							label: t("pages.dashboard.permissionGroups.tabs.myGroups"),
							children: <MyGroupsComponent />
						}
					]}
				/>
			</div>
		</Fragment>
	);
};

export default PermissionGroupsComponent;
