import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import RTPComponent from "components/dashboard/common/RTPComponent";

import { getRTPs, saveRTPs, resetRTPs } from "store/actions/dashboard/virtuals/games/rtp.action";

import rtpType from "types/rtp/rtp.type";

import { GAME_CATEGORY, SCHEDULED_GAME_TYPE } from "constants/game.constants";

/** Game Edit Page Default RTP Tab Component */
const GameRTPComponent = ({ isSaving, isLoading, getRTPs, saveRTPs, resetRTPs, rtp, gameType, onTabChange, gameCategory }) => {
	const routeParams = useParams();
	const [searchParams] = useSearchParams();

	return (
		<RTPComponent
			isSaving={isSaving}
			isLoading={isLoading}
			getRTPs={() => getRTPs(routeParams.id, searchParams.get("PI") || "none", gameCategory)}
			saveRTPs={(d) =>
				saveRTPs(
					{
						...d,
						id: routeParams.id
					},
					gameCategory
				)
			}
			resetRTPs={resetRTPs}
			rtp={rtp}
			onTabChange={onTabChange}
			gameType={gameType}
		/>
	);
};

/** GameRTPComponent propTypes
 * PropTypes
 */
GameRTPComponent.propTypes = {
	/** Redux state property, is true when rtps is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when rtps are loading */
	isLoading: PropTypes.bool,
	/** Redux action to get game rtps */
	getRTPs: PropTypes.func,
	/** Redux action to save game rtps */
	saveRTPs: PropTypes.func,
	/** Redux action to clear game rtps */
	resetRTPs: PropTypes.func,
	/** Redux state, represents the rtp data of current editing game  */
	rtp: rtpType,
	/** Current game type */
	gameType: PropTypes.oneOf(Object.values(SCHEDULED_GAME_TYPE).map((v) => v.value)),
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	getRTPs: (id, partnerId, gameCategory) => {
		dispatch(getRTPs(id, partnerId, gameCategory));
	},
	saveRTPs: (data, gameCategory) => {
		dispatch(saveRTPs(data, gameCategory));
	},
	resetRTPs: () => {
		dispatch(resetRTPs());
	}
});

const mapStateToProps = (state) => {
	return {
		rtp: state.games.editingGame.rtp,
		isSaving: state.games.isSaving,
		isLoading: state.games.isLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GameRTPComponent);
