import { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import Tooltip from "antd/lib/tooltip";
import ChartLoader from "components/dashboard/statistics/charts/chart-loader";
import StreamServerItemActionModal from "../streamServerItemActionModal";

import { STREAM_LANGUAGES_CODES } from "constants/languages";

import { restartStreamServerGame, removeStreamServerGame } from "store/actions/dashboard/streamServers";

import { buildPathToStaticFolderOfCDN, renderWithPercentage, toFixedWithoutRound } from "utils/common";

const StreamServerGame = ({ name = "", languageCode = "", streamHealths = {}, flussonicStreamName = "", nanocosmosStreamName = "", serverId = "", streamServers, restartStreamServerGame, removeStreamServerGame }) => {
	const [showRestartGameModal, setShowRestartGameModal] = useState(false);
	const [showRemoveGameModal, setShowRemoveGameModal] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const handleToggleModal = (setState, condition = false) => setState(condition);

	const { t } = useTranslation();

	const gameStreamHealths = useMemo(() => {
		const streamServers = [];

		if (!!flussonicStreamName) {
			streamServers.push("flussonicInputBitrate", "flussonicOutputBitrate");
		}

		if (!!nanocosmosStreamName) {
			streamServers.push("nanocosmosBitrate");
		}

		const output = streamServers.map((streamServer, i) => {
			return {
				key: i + 1,
				label: t(`pages.dashboard.developerSpaceServerStreams.${streamServer}`),
				value: streamHealths[streamServer] || 0
			};
		});

		return output;
	}, [streamHealths]);

	const renderedTooltipContent = useMemo(() => {
		if (!flussonicStreamName && !nanocosmosStreamName) return null;

		const flussonicSreamNameElement = flussonicStreamName ? <p className="vs--mb-0">Flussonic : {flussonicStreamName}</p> : null;
		const nanoStreamNameElement = nanocosmosStreamName ? <p className="vs--mb-0">Nanocosmos : {nanocosmosStreamName}</p> : null;

		return (
			<div>
				<div className="vs--flex vs--flex-col vs--justify-center stream-servers-healths-block">
					{flussonicSreamNameElement}
					{nanoStreamNameElement}
				</div>
			</div>
		);
	}, [flussonicStreamName, nanocosmosStreamName]);

	useEffect(() => {
		if (isLoading) {
			handleToggleModal(setShowRestartGameModal);
			handleToggleModal(setShowRemoveGameModal);
		}
	}, [isLoading]);

	return (
		<Row className="stream-servers-item-content-games-section">
			{isLoading ? <ChartLoader size="medium" /> : null}
			<Col span={24}>
				<div className="stream-servers-item-content-games-section-header">
					<div className="stream-servers-item-content-games-section-header-info">
						{flussonicStreamName || nanocosmosStreamName ? (
							<Tooltip
								overlayInnerStyle={{
									width: "max-content"
								}}
								title={renderedTooltipContent}
							>
								<i className="icon-info stream-servers-item-content-games-section-header-icon" />
							</Tooltip>
						) : null}
						<span className="stream-servers-item-content-games-section-header-title">{name}</span>
					</div>
					<div className="stream-servers-item-content-games-section-header-actions">
						<span className="stream-servers-item-content-games-section-header-actions-languageCode">{STREAM_LANGUAGES_CODES[languageCode]}</span>

						<div className="stream-servers-item-refresh-button">
							<Button size="small" onClick={() => handleToggleModal(setShowRestartGameModal, true)} type="primary">
								<img src={buildPathToStaticFolderOfCDN("admin-images/dashboard/refresh-icon.png")} alt="Refresh stream server" />
							</Button>
						</div>

						<div>
							<Button size="small" onClick={() => handleToggleModal(setShowRemoveGameModal, true)} type="primary">
								<img src={buildPathToStaticFolderOfCDN("admin-images/dashboard/remove-icon.png")} alt="Add new game for the stream server" />
							</Button>
						</div>
					</div>
				</div>
			</Col>
			{/* Divider */}
			<div className="stream-servers-item-content-games-section-divider"></div>
			{/* Game general info section */}
			<Col span={24}>
				<Row>
					{gameStreamHealths.map((item) => (
						<Col key={item.key}>
							<div className="stream-servers-item-content-games-section-info">
								<div
									className="stream-servers-item-content-games-section-info-indicator"
									style={{
										background: item.value <= 25 ? "#FF0A00 0% 0% no-repeat padding-box" : item.value > 25 && item.value <= 75 ? "#FFA700 0% 0% no-repeat padding-box" : "#00B932 0% 0% no-repeat padding-box"
									}}
								></div>
								<div className="stream-servers-item-content-games-section-info-label">{item.label}</div>
								<span className="stream-servers-item-content-games-section-info-value">{renderWithPercentage(toFixedWithoutRound(item.value))}</span>
							</div>
						</Col>
					))}
				</Row>
			</Col>

			{/* Stream server game restart modal */}
			<StreamServerItemActionModal
				isModalOpen={showRestartGameModal}
				title={t("pages.dashboard.developerSpaceServerStreams.restartGameModalTitle")}
				contentText={t("pages.dashboard.developerSpaceServerStreams.restartGameModalContentText", {
					gameName: name
				})}
				okText={t("pages.dashboard.developerSpaceServerStreams.restartModalOkText")}
				onOk={() => {
					setIsLoading(true);

					restartStreamServerGame(
						{
							id: serverId,
							name,
							languageCode
						},
						() => {
							setIsLoading(false);
						}
					);
				}}
				onClose={() => handleToggleModal(setShowRestartGameModal)}
				saveButtonProps={{ loading: streamServers.isGameLoading }}
			/>

			{/* Stream server game remove modal */}
			<StreamServerItemActionModal
				isModalOpen={showRemoveGameModal}
				title={t("pages.dashboard.developerSpaceServerStreams.removeGameModalTitle")}
				contentText={t("pages.dashboard.developerSpaceServerStreams.removeGameModalContentText", {
					gameName: name
				})}
				okText={t("pages.dashboard.developerSpaceServerStreams.removeModalOkText")}
				onOk={() => {
					setIsLoading(true);
					removeStreamServerGame(
						{
							id: serverId,
							game: {
								name,
								languageCode,
								nanocosmosStreamName,
								flussonicStreamName
							}
						},
						() => {
							setIsLoading(false);
						}
					);
				}}
				onClose={() => handleToggleModal(setShowRemoveGameModal)}
				saveButtonProps={{ loading: streamServers.isGameLoading }}
			/>
		</Row>
	);
};

StreamServerGame.propTypes = {
	// Remove game modal handler
	handleRemoveGameModal: PropTypes.func,
	// The name of game item
	name: PropTypes.string,
	// The language code of game item
	languageCode: PropTypes.string,
	// Stream healths props
	streamHealths: PropTypes.object,
	// Game's flussonic stream name
	flussonicStreamName: PropTypes.string,
	// Game's nanocosmos stream name
	nanocosmosStreamName: PropTypes.string,
	// Current stream server id
	serverId: PropTypes.string,
	// Redux state of stream servers data
	streamServers: PropTypes.object,
	// Redux state action of restarting stream server game
	restartStreamServerGame: PropTypes.func,
	// Redux state action of removing stream server game
	removeStreamServerGame: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	restartStreamServerGame: ({ id, name, languageCode }, cb) => {
		dispatch(
			restartStreamServerGame(
				{
					id,
					name,
					languageCode
				},
				cb
			)
		);
	},
	removeStreamServerGame: ({ id, game }, cb) => {
		dispatch(
			removeStreamServerGame(
				{
					id,
					game
				},
				cb
			)
		);
	}
});

const mapStateToProps = (state) => {
	return {
		streamServers: state.streamServers
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StreamServerGame);
