import { Fragment } from "react";

import MonitoringComponent from "components/dashboard/developer/monitoring/monitoring.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

const MonitoringRoute = () => {
	return (
		<Fragment>
			<MonitoringComponent />
		</Fragment>
	);
};

export default withPermission(MonitoringRoute, { resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
