import { useEffect } from "react";
import PropTypes, { object } from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Col, Row, Modal, Input } from "antd";
import { addPermissionGroupQuickViewUsers } from "store/actions/dashboard/userManagement/permissionGroups/generalInfo.action";
import SearchableSelect from "components/common/searchableSelect";
import useAutosuggestion from "hooks/useAutosuggestion";
import { POPUP_SIZE } from "constants/common.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
import { doesUserHaveRoleOf } from "utils/auth";
import { USER_ROLE } from "constants/user.constants";
import { TEXTAREA_MAX_LENGTH } from "constants/common.constants";
const { Item: FormItem } = Form;

/** Apikey Blocked Country Adding Popup Component */
const UserAddComponent = ({ visible, groupId, globalPartnerId, isSaving, addPermissionGroupQuickViewUsers, onClose, currentUserId }) => {
	const { t } = useTranslation();
	const isAccessManager = doesUserHaveRoleOf(USER_ROLE.ACCESS_MANAGER);
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;
	const userNames = useAutosuggestion(AUTOSUGGESTION_TYPE.USER_NAMES, [globalPartnerId], { disableAction: () => !globalPartnerId });

	/** Fires when form submitted
	 * @function
	 * @memberOf UserAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ ids, note }) => {
				addPermissionGroupQuickViewUsers(Object.assign({}, { ids, groupId }, isAccessManager ? { note } : null), onClose);
			})
			.catch(Function.prototype);
	};

	/** Generate User List
	 * @function
	 * @memberOf UserAddComponent
	 */
	const generateUserNameList = () => {
		return userNames.filter((un) => un.key !== currentUserId).sort((a, b) => (a.value > b.value ? 1 : -1));
	};

	useEffect(() => {
		!visible && formInstance.resetFields();
	}, [visible]);

	return (
		<Modal open={visible} title={t("pages.dashboard.users.add_user")} cancelText={t("common.cancel")} okText={t("common.save")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.SMALL} maskClosable={false} closable={false} okButtonProps={{ loading: isSaving }} centered zIndex="1001">
			<Form className="dashboard-form" form={formInstance} colon={false} layout="vertical" requiredMark={false}>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={t("pages.dashboard.users.users")} name="ids" rules={[{ required: true, message: t("validation.field_required") }]}>
							<SearchableSelect mode="multiple" placeholder="Select" items={generateUserNameList()} valueProp={(item) => item.key} textProp={(item) => item.value} renderText={(item) => item.value} getPopupContainer={() => document.body} />
						</FormItem>

						{isAccessManager ? (
							<FormItem
								label={t("pages.dashboard.permissions_requests.notes")}
								name="note"
								rules={[
									{ required: true, message: t("validation.field_required") },
									{
										min: 5,
										max: TEXTAREA_MAX_LENGTH,
										message: t("validation.must_be_more_than").replace("%%X%%", 5).replace("%%Y%%", TEXTAREA_MAX_LENGTH)
									}
								]}
							>
								<Input.TextArea rows={4} placeholder={t("pages.dashboard.permissions_requests.notes")} maxLength={TEXTAREA_MAX_LENGTH} />
							</FormItem>
						) : null}
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** UserAddComponent propTypes
 * PropTypes
 */
UserAddComponent.propTypes = {
	/** The id of the group which quick info is showing */
	groupId: PropTypes.string,
	/** View state property, is true when modal is open */
	visible: PropTypes.bool,
	/** Redux state property, is true when adding blocked country for api key */
	isSaving: PropTypes.bool,
	/** Redux action to add blocked country for api key*/
	addPermissionGroupQuickViewUsers: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Current User id */
	currentUserId: PropTypes.string,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		isSaving: state.apikeys.isSaving,
		currentUserId: state.profile.userInfo.id,
		globalPartnerId: state.partner.globalPartnerId
	};
};

const mapDispatchToProps = (dispatch) => ({
	addPermissionGroupQuickViewUsers: (data, onClose) => {
		dispatch(addPermissionGroupQuickViewUsers(data, onClose));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAddComponent);
