import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Radio, Select } from 'antd';
import { setDashboardFilters, getDashboardApiKeyNames, getDashboardBetshopNames } from 'store/actions/dashboard/dashboard/dashboard.action';
import SearchableSelect from 'components/common/searchableSelect';
import { DASHBOARD_PERIOD, DASHBOARD_PERIOD_TRANSLATION } from "constants/dashboard.constants";
import { PROJECT_TYPE } from 'constants/common.constants';
import autoSuggestionType from 'types/autoSuggestion/autoSuggestion.type';
import { GAME_CATEGORY } from 'constants/game.constants';
import useUpdateEffect from 'hooks/useUpdateEffect';
const { Item: FormItem } = Form;

/** Dashboard Page Filters Component */
const DashboardFiltersComponent = ({ apiKeyNames, betshopNames, getDashboardApiKeyNames, getDashboardBetshopNames, isApiKeyNamesLoading, globalPartnerId, setDashboardFilters, filters, projectType, isGamesPerformanceLoading, isTopMarketsLoading }) => {
	const { t } = useTranslation();

	const [formInstance] = Form.useForm();
	const { setFieldsValue } = formInstance;

	useEffect(() => {
		if (projectType === PROJECT_TYPE.ONLINE) {
			getDashboardApiKeyNames("");
		} else {
			getDashboardBetshopNames();
		}
		setDashboardFilters({
			projectId: "",
			period: DASHBOARD_PERIOD.TODAY
		}, projectType)
		setFieldsValue({ projectId: "", period: DASHBOARD_PERIOD.TODAY })
	}, [globalPartnerId])

	useUpdateEffect(() => {
		if (projectType === PROJECT_TYPE.ONLINE) {
			getDashboardApiKeyNames(filters.gameCategory);
			setDashboardFilters({
				projectId: ""
			}, projectType)
		}
	}, [projectType, filters.gameCategory])


	/** Fires on filter change
	  * @function
	  * @param {"period" | "projectid" | "gameCategory"} filterName - filter name
	  * @param {string | GameCategory} value - filter value
	  * @memberOf DashboardFiltersComponent
	  * @returns {object} - total values
 */
	const handleFilterChange = (filterName, value) => {
		switch (filterName) {
			case "period":
				setDashboardFilters({
					period: value
				}, projectType)
				break;
			case "projectId":
				setDashboardFilters({
					projectId: value
				}, projectType)
				break;
			case "gameCategory":
				setDashboardFilters({
					gameCategory: value,
					gameType: "",
				}, projectType)
				break;
			default:
				break;
		}
	};

	return (
		<Form colon={false} requiredMark={false} layout="vertical" form={formInstance} initialValues={filters}>
			<Row gutter={[16, 0]}>
				{
					projectType === PROJECT_TYPE.ONLINE
						? (
							<Col xs={24} sm={12} lg={6} xxl={4} >
								<FormItem
									label={t("pages.dashboard.games.gameCategory")}
									name="gameCategory"
								>
									<Select
										suffixIcon={<i className="icon-down" />}
										disabled={isApiKeyNamesLoading || isGamesPerformanceLoading || isTopMarketsLoading}
										onChange={(value) => handleFilterChange("gameCategory", value)}
									>
										<Select.Option value={""}>
											{t("common.all")}
										</Select.Option>
										<Select.Option value={GAME_CATEGORY.SCHEDULED}>
											{t(`pages.dashboard.games.gameCategory_${GAME_CATEGORY.SCHEDULED}`)}
										</Select.Option>
										<Select.Option value={GAME_CATEGORY.INSTANT}>
											{t(`pages.dashboard.games.gameCategory_${GAME_CATEGORY.INSTANT}`)}
										</Select.Option>
									</Select>
								</FormItem>
							</Col>
						)
						: null
				}
				<Col xs={24} sm={12} lg={6} xxl={4} >
					<FormItem
						label={projectType === PROJECT_TYPE.ONLINE ? t('pages.dashboard.dashboard.project') : t('pages.dashboard.dashboard.betshop')}
						name="projectId"
					>
						<SearchableSelect
							items={projectType === PROJECT_TYPE.ONLINE ? apiKeyNames : betshopNames}
							valueProp={(item) => item.key}
							textProp={(item) => item.value}
							renderText={(item) => item.value}
							addNone={true}
							noneText={t("common.all")}
							onChange={(e) => handleFilterChange("projectId", e)}
							disabled={isApiKeyNamesLoading || isGamesPerformanceLoading || isTopMarketsLoading}
						/>
					</FormItem>
				</Col>
				<Col xs={24} sm={18} lg={18} xl={12}>
					<FormItem label={t("pages.dashboard.dashboard.period")} name="period" onChange={(e) => handleFilterChange("period", Number(e.target.value))}>
						<Radio.Group>
							{
								Object.values(DASHBOARD_PERIOD).map(dashPerVal => {
									return (
										<Radio.Button
											disabled={isGamesPerformanceLoading || isTopMarketsLoading}
											key={dashPerVal}
											value={dashPerVal}
										>
											{t(DASHBOARD_PERIOD_TRANSLATION[dashPerVal])}
										</Radio.Button>
									)
								})
							}
						</Radio.Group>
					</FormItem>
				</Col>
			</Row>
		</Form>
	);
};

/** DashboardFiltersComponent propTypes
 * PropTypes
 */
DashboardFiltersComponent.propTypes = {
	/** Api key names */
	apiKeyNames: PropTypes.arrayOf(autoSuggestionType),
	/** Redux action to get api key names */
	getDashboardApiKeyNames: PropTypes.func,
	/** Redux state property, represents the array of bet shops */
	betshopNames: PropTypes.arrayOf(autoSuggestionType),
	/** Redux action to get betshopNames names */
	getDashboardBetshopNames: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux action to set filters */
	setDashboardFilters: PropTypes.func,
	/** Redux state property, dashboard filters*/
	filters: PropTypes.object,
	/** Project type(Online/ Retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	// Redux state controls games performance loading indicator
	isGamesPerformanceLoading: PropTypes.bool,
	// Redux state controls topMarketsCharts data loading indicator
	isTopMarketsLoading: PropTypes.bool,
	// Redux state controls apikey names loading indicator
	isApiKeyNamesLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		apiKeyNames: state.dashboard.online.apiKeyNames,
		betshopNames: state.dashboard.retail.betshopNames,
		globalPartnerId: state.partner.globalPartnerId,
		filters: state.dashboard.online.filters,
		isApiKeyNamesLoading: state.dashboard.online.isApiKeyNamesLoading,
		isGamesPerformanceLoading: state.dashboard.isGamesPerformanceLoading,
		isTopMarketsLoading: state.dashboard.isTopMarketsLoading
	};
};

const mapDispatchToProps = dispatch => (
	{
		getDashboardApiKeyNames: (gameCategory) => {
			dispatch(getDashboardApiKeyNames(gameCategory))
		},

		getDashboardBetshopNames: () => {
			dispatch(getDashboardBetshopNames())
		},

		setDashboardFilters: (filters, projectType) => {
			dispatch(setDashboardFilters(filters, projectType))
		}
	}
)

export default connect(mapStateToProps, mapDispatchToProps)(DashboardFiltersComponent);
