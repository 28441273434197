import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Col, Row, Modal, Select, Tooltip } from "antd";
const { Item: FormItem } = Form;

import { changeSurveyState } from "store/actions/dashboard/usersFeedback/CSAT/surveys.action";
import { POPUP_SIZE } from "constants/common.constants";
import { SURVEY_STATE } from "constants/survey.constants";

/** Survey State changing Popup Component */
const SurveyStateChangeComponent = ({ id, isSaving, changeSurveyState, onSaveClick, onClose }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;
	const [disableButton, setDisableButton] = useState(true);

	/** Fires when form submitted
	 * @function
	 * @memberOf BonusStatusChangeComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				if (data.state === SURVEY_STATE.FINISHED) {
					changeSurveyState({ ...data, id, onSaveClick });
				}
				onClose();
			})
			.catch(console.log);
	};

	return (
		<Modal
			open={true}
			zIndex={1001}
			title={t("pages.dashboard.usersFeedback.change_survey_status")}
			cancelText={t("common.cancel")}
			okText={t("common.save")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.SMALL}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving, disabled: disableButton }}
			centered
		>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					state: SURVEY_STATE.ACTIVE
				}}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem
							label={
								<>
									<div className="vs--flex vs--align-center">
										{t("pages.dashboard.usersFeedback.status")}
										<Tooltip placement="bottom" title={<span>{t(`pages.dashboard.usersFeedback.status_info_${disableButton ? SURVEY_STATE.ACTIVE : SURVEY_STATE.FINISHED}`)}</span>} getPopupContainer={() => document.body}>
											<i className="icon-info vs--font-bigest" style={{ fontSize: "24px" }} />
										</Tooltip>
									</div>
								</>
							}
							name="state"
							rules={[{ required: true, message: t("validation.field_required") }]}
						>
							<Select
								getPopupContainer={() => document.body}
								suffixIcon={<i className="icon-down" />}
								onChange={(value) => {
									setDisableButton(value === SURVEY_STATE.ACTIVE);
								}}
							>
								<Select.Option value={SURVEY_STATE.ACTIVE}>{t("pages.dashboard.usersFeedback.status_1")}</Select.Option>
								<Select.Option value={SURVEY_STATE.FINISHED}>{t("pages.dashboard.usersFeedback.status_4")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** SurveyStateChangeComponent propTypes
 * PropTypes
 */
SurveyStateChangeComponent.propTypes = {
	/** Redux state property, is true when changing survey status request is in process */
	isSaving: PropTypes.bool,
	/** Redux action to change survey state */
	changeSurveyState: PropTypes.func,
	/** Function to be called when clicking on modal submit */
	onSaveClick: PropTypes.func,
	/** Current editing survey id */
	id: PropTypes.string,
	/** Fires on popup close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	changeSurveyState: (data) => {
		dispatch(changeSurveyState(data));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.surveys.isSaving
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyStateChangeComponent);
