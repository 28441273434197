import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Select, Row, Col, Form } from "antd";
const { Item: FormItem } = Form;

import { getGamesFromConfig } from "utils/common";

import { getHotkeys } from "store/actions/dashboard/cms/hotkeys/hotkeys.action";

import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";

import { GAME_CATEGORY, SCHEDULED_GAME_TYPE } from "constants/game.constants";

import hotkeyType from "types/hotkey/hotkey.type";

/** Hotkeys Page Component */

const HotkeysComponent = ({ getHotkeys, hotkeys, isLoading }) => {
	const { t } = useTranslation();

	const [gameType, setGemeType] = useState(SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value);

	/** Load hotkeys on gameType change */
	useEffect(() => {
		getHotkeys(gameType);
	}, [gameType]);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.hotkeys.market"),
			dataIndex: "market",
			sorter: false
		},
		{
			title: t("pages.dashboard.hotkeys.code"),
			dataIndex: "hotKeyCode",
			sorter: false
		},
		{
			title: t("pages.dashboard.hotkeys.usage_example"),
			dataIndex: "usageExample",
			sorter: false
		},
		{
			title: t("pages.dashboard.hotkeys.description"),
			dataIndex: "description",
			sorter: false
		}
	];

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.hotkeys") }]} />
				<div className="dashboard-section-content">
					<Form colon={false} requiredMark={false} layout="vertical">
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} md={6} xl={4}>
								<FormItem label={t("pages.dashboard.hotkeys.game_type")}>
									<Select onChange={(e) => setGemeType(e)} value={gameType} suffixIcon={<i className="icon-down" />}>
										{getGamesFromConfig(GAME_CATEGORY.SCHEDULED).map((g) => (
											<Select.Option value={g.value} key={g.value}>
												{t(`common.${g.type}`)}
											</Select.Option>
										))}
									</Select>
								</FormItem>
							</Col>
						</Row>
					</Form>
					<Table loading={isLoading} columns={columns} data={hotkeys} loadFn={Function.prototype} total={hotkeys.length} isDisabled={() => false} noPagination={true} uniqueKey="market" />
				</div>
			</div>
		</Fragment>
	);
};

/** HotkeysComponent propTypes
 * PropTypes
 */
HotkeysComponent.propTypes = {
	/** Redux action to get hotkeys */
	getHotkeys: PropTypes.func,
	/** Redux state property, represents the array of hotkeys */
	hotkeys: PropTypes.arrayOf(hotkeyType),
	/** Redux state property, is true when loading hotkeys */
	isLoading: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	getHotkeys: (gameType) => {
		dispatch(getHotkeys(gameType));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.hotkeys.isLoading,
		hotkeys: state.hotkeys.hotkeys
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HotkeysComponent);
