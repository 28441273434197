import { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Form, Row, Col, Select, DatePicker, Divider } from "antd";
import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import SearchableSelect from "components/common/searchableSelect";
import { setApikeysFilters, getApikeys } from "store/actions/dashboard/online/apikeys/apikeys.action";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import useAutosuggestion from "hooks/useAutosuggestion";
import { FILTER_STATUS, FILTER_TEST_STATE } from "constants/filter.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
const { Item: FormItem } = Form;

/** Api keys Page Filters Component */
const Filters = ({ setApikeysFilters, getApikeys, filters, globalPartnerId }) => {
	const { t } = useTranslation();
	const apiKeyNames = useAutosuggestion(AUTOSUGGESTION_TYPE.API_KEY_NAMES, [globalPartnerId], { disableAction: () => !globalPartnerId });
	const gameNames = useAutosuggestion(AUTOSUGGESTION_TYPE.GAME_NAMES, [globalPartnerId], { disableAction: () => !globalPartnerId });

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [
				{ name: "date", keepTime: false },
				{ name: "lastUpdate", keepTime: false }
			]
		};
	}, []);

	/** Function to get filters list
	 * @function
	 * @returns {array} - filters list
	 * @memberOf Filters
	 */
	const filtersList = () => [
		{ name: "nameOrId", title: t("pages.dashboard.apikeys.project_name_or_ID") },
		{
			name: "gameId",
			title: t("pages.dashboard.apikeys.games_included"),
			values: gameNames.map((g) => ({ title: g.value, value: g.key }))
		},
		{
			name: "itemState",
			title: t("common.status"),
			values: [
				{ title: t("common.all"), value: FILTER_STATUS.ALL },
				{ title: t("common.active"), value: FILTER_STATUS.ACTIVE },
				{ title: t("common.inactive"), value: FILTER_STATUS.INACTIVE }
			]
		},
		{
			name: "testState",
			title: t("common.data_type"),
			values: [
				{ title: t("common.all"), value: FILTER_TEST_STATE.ALL },
				{ title: t("common.testing"), value: FILTER_TEST_STATE.TESTING },
				{ title: t("common.not_testing"), value: FILTER_TEST_STATE.NOTTESTING }
			]
		},
		{ name: "date", title: t("common.creation_date") },
		{ name: "updateDate", title: t("common.last_update_date") }
	];

	return (
		<FiltersWrapper filtersName="apiKeys" loadFn={getApikeys} setFiltersFn={setApikeysFilters} filters={filters} formFieldsConfigs={formFieldsConfigs} filtersList={filtersList()}>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.apikeys.project_name_or_ID")} name="nameOrId">
							<AutoComplete placeholder={`${t("common.start_typing")} ${t("pages.dashboard.apikeys.project_name_or_ID")}`} items={apiKeyNames.map((k) => k.value)} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.apikeys.games_included")} name="gameId">
							<SearchableSelect items={gameNames} valueProp={(item) => item.key} textProp={(item) => item.value} renderText={(item) => item.value} addNone={true} noneText={t("common.all")} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.status")} name="itemState">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_STATUS.ALL}>{t("common.all")}</Select.Option>
								<Select.Option value={FILTER_STATUS.ACTIVE}>{t("common.active")}</Select.Option>
								<Select.Option value={FILTER_STATUS.INACTIVE}>{t("common.inactive")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.data_type")} name="testState">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_TEST_STATE.ALL}>{t("common.all")}</Select.Option>
								<Select.Option value={FILTER_TEST_STATE.TESTING}>{t("common.testing")}</Select.Option>
								<Select.Option value={FILTER_TEST_STATE.NOTTESTING}>{t("common.not_testing")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.creation_date")} name="date">
							<DatePicker.RangePicker placeholder={[t("common.from"), t("common.to")]} format={DATE_FORMAT} disabledDate={(d) => !d || d.isAfter(moment().endOf("day"))} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.last_update_date")} name="updateDate">
							<DatePicker.RangePicker placeholder={[t("common.from"), t("common.to")]} format={DATE_FORMAT} disabledDate={(d) => !d || d.isAfter(moment().endOf("day"))} />
						</FormItem>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get api keys */
	getApikeys: PropTypes.func,
	/** Redux action to set api keys filters */
	setApikeysFilters: PropTypes.func,
	/** Redux state property, api keys filters */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getApikeys: () => {
		dispatch(getApikeys());
	},
	setApikeysFilters: (filters) => {
		dispatch(setApikeysFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.apikeys.filters,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
