import { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Form, Col, Row, Modal, Input } from "antd";
const { Item: FormItem } = Form;

import SearchableSelect from "components/common/searchableSelect";

import { createCashier, getAvailableBetshops, getCashiers } from "store/actions/dashboard/retail/cashierManagement/cashiers/cashiers.action";
import Paths from "constants/path.constants";
import { EMAIL_REGEX } from "constants/regex.constants";
import { POPUP_SIZE } from "constants/common.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { INTEGRATION_TYPE } from "constants/betshop.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import { hasPermission } from "utils/permissions";

/** Cashier Creating Popup Component */
const CashierAddComponent = ({ isSaving, createCashier, getCashiers, globalPartnerId, availableBetshops, isAvailableBetshopsLoading, getAvailableBetshops, onClose }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;

	/** Load available betshops */
	useEffect(() => {
		getAvailableBetshops();
	}, []);

	/** Fires when form submitted
	 * @function
	 * @memberOf CashierAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				createCashier({ ...data }, ({ id }) => {
					if (hasPermission({ resource: PERMISSION_RESOURCE.CASHIER, action: PERMISSION_ACTION.MODIFY })) {
						navigate(Paths.DASHBOARD_RETAIL_CASHIER_MANAGEMENT_CASHIERS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, `${id}?PI=${globalPartnerId}`));
					} else {
						getCashiers();
						onClose();
					}
				});
			})
			.catch(Function.prototype);
	};

	return (
		<Modal open={true} title={t("pages.dashboard.cashiers.create_cashier")} cancelText={t("common.cancel")} okText={t("common.create")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.SMALL} maskClosable={false} closable={false} okButtonProps={{ loading: isSaving }} centered>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					firstName: "",
					lastName: "",
					userName: "",
					email: "",
					betShopId: ""
				}}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem
							label={`${t("pages.dashboard.cashiers.first_name")} *`}
							name="firstName"
							rules={[
								{ required: true, whitespace: true, message: t("validation.field_required") },
								{ max: 50, message: t("validation.field_invalid") }
							]}
						>
							<Input maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.cashiers.first_name")}`} autoFocus={true} autoComplete="off" />
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem
							label={`${t("pages.dashboard.cashiers.last_name")} *`}
							name="lastName"
							rules={[
								{ required: true, whitespace: true, message: t("validation.field_required") },
								{ max: 50, message: t("validation.field_invalid") }
							]}
						>
							<Input maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.cashiers.last_name")}`} />
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem
							label={`${t("pages.dashboard.cashiers.email")} *`}
							name="email"
							rules={[
								{ pattern: EMAIL_REGEX, message: t("validation.email_format") },
								{ max: 50, message: t("validation.field_invalid") },
								{ min: 6, message: t("validation.field_invalid") }
							]}
						>
							<Input minLength={6} maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.cashiers.email")}`} />
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem
							label={`${t("pages.dashboard.cashiers.username")} *`}
							name="userName"
							rules={[
								{ required: true, whitespace: true, message: t("validation.field_required") },
								{ max: 50, message: t("validation.field_invalid") }
							]}
						>
							<Input maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.cashiers.username")}`} />
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem label={t("pages.dashboard.cashiers.betshop")} name="betShopId" rules={[{ required: true, message: t("validation.field_required") }]}>
							<SearchableSelect
								placeholder={t("pages.dashboard.cashiers.select_betshop_placeholder")}
								loading={isAvailableBetshopsLoading}
								items={Object.keys(availableBetshops)}
								valueProp={(item) => item}
								textProp={(item) => availableBetshops[item]}
								renderText={(item) => availableBetshops[item]}
								getPopupContainer={() => document.body}
							/>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** CashierAddComponent propTypes
 * PropTypes
 */
CashierAddComponent.propTypes = {
	/** Redux state property, is true when creating user request is in process */
	isSaving: PropTypes.bool,
	/** Redux action to create cashier */
	createCashier: PropTypes.func,
	/** Redux action to get cashiers */
	getCashiers: PropTypes.func,
	/** Redux action to get available betshops */
	getAvailableBetshops: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state, represents available betshops list  */
	availableBetshops: PropTypes.object,
	/** Redux state property, is true when available betshops are loading */
	isAvailableBetshopsLoading: PropTypes.bool,
	/** Fires on popup close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	createCashier: (cashier, onSuccess) => {
		dispatch(createCashier(cashier, onSuccess));
	},
	getCashiers: () => {
		dispatch(getCashiers(true));
	},
	getAvailableBetshops: () => {
		dispatch(getAvailableBetshops({ integrationType: INTEGRATION_TYPE.STANDALONE }));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.cashiers.isSaving,
		availableBetshops: state.cashiers.availableBetshops,
		isAvailableBetshopsLoading: state.cashiers.isAvailableBetshopsLoading,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CashierAddComponent);
