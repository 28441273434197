export const MAINTENANCE_MODE_DURATION_TYPE = {
	TEMPORARY: 1,
	PERMANENT: 2,
	NONE: 4
};

export const MAINTENANCE_MODE_CHANGE_REASON = {
	TECHNICAL_WORK: 1,
	PROBLEMS_WITH_GAMES: 2,
	VERSION_OR_HOTFIX: 4,
	OTHER: 8,
	OFF: 16
};
