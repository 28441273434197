import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { setApikeySaveActionBefore, setApikeySaveActionFinished } from "./apikeys.action";

import { SET_APIKEY_GAME_RTP, APIKEY_GAMES_RTP_BEFORE, APIKEY_GAMES_RTP_FINISH } from "store/actionTypes";
import { GAME_CATEGORY } from "constants/game.constants";

const setApiKeyGameRTP = (rtp) => ({
	type: SET_APIKEY_GAME_RTP,
	payload: { rtp }
});

const setApiKeyGamesRTPBefore = () => ({
	type: APIKEY_GAMES_RTP_BEFORE
});

const setApiKeyGamesRTPFinished = () => ({
	type: APIKEY_GAMES_RTP_FINISH
});

export const getApiKeyGameRTPs = ({ id, gameId, gameCategory }) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.APIKEY_GET_SCHEDULED_GAME_RTP : ApiUrls.APIKEY_GET_INSTANT_GAME_RTP;
	return (dispatch) => {
		dispatch(setApiKeyGamesRTPBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.GET,
			params: { id, gameId }
		})
			.then(({ data: { value: rtp } }) => {
				dispatch(setApiKeyGameRTP(rtp));
				dispatch(setApiKeyGamesRTPFinished());
			})
			.catch((ex) => {
				dispatch(setApiKeyGamesRTPFinished());
			});
	};
};

export const saveApiKeyGameRTPs = ({ data, onSuccess, gameCategory }) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.APIKEY_SAVE_SCHEDULED_GAME_RTP : ApiUrls.APIKEY_SAVE_INSTANT_GAME_RTP;
	return (dispatch) => {
		dispatch(setApikeySaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.POST,
			data: data
		})
			.then(({ data: { message: msg, status, value: rtp } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setApiKeyGameRTP(rtp));
					onSuccess && onSuccess();
				}
				dispatch(setApikeySaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setApikeySaveActionFinished());
			});
	};
};

export const resetApiKeyGameRTPs = () => {
	return (dispatch) => {
		dispatch(setApiKeyGameRTP({ rtPs: [], allowOverride: true, minPossibleValue: 0, maxPossibleValue: 0 }));
	};
};
