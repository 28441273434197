import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { setUsersActionBefore, setUsersActionFinished, setUserSaveActionBefore, setUserSaveActionFinished } from "./users.action";

import { SET_USER_PERMISSIONS } from "store/actionTypes";

const setUserPermissions = (permissions) => ({
	type: SET_USER_PERMISSIONS,
	payload: { permissions }
});

export const getUserPermissions = (id) => {
	return (dispatch) => {
		dispatch(setUsersActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_USER_PERMISSIONS}`,
			method: Methods.GET,
			params: { id, resource: "All" }
		})
			.then(({ data: { value: permissions } }) => {
				dispatch(setUserPermissions(permissions));
				dispatch(setUsersActionFinished());
			})
			.catch((ex) => {
				dispatch(setUsersActionFinished());
			});
	};
};

export const saveUserPermissions = (permissions) => {
	return (dispatch) => {
		dispatch(setUserSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_USER_PERMISSIONS}`,
			method: Methods.POST,
			data: {
				...permissions
			},
			params: { resource: "All" }
		})
			.then(({ data: { message: msg, status, value: permissions } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setUserPermissions(permissions));
				}
				dispatch(setUserSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setUserSaveActionFinished());
			});
	};
};
