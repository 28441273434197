import { Fragment } from "react";

import JobsComponent from "components/dashboard/developer/jobs/jobs.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

const JobsRoute = () => {
	return (
		<Fragment>
			<JobsComponent />
		</Fragment>
	);
};

export default withPermission(JobsRoute, { resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
