import {
	USERS_ACTION_BEFORE,
	USERS_ACTION_FINISH,
	USERS_ACTION_SET_USERS,
	USERS_ACTION_SET_INACTIVE_USERS,
	USERS_INACTIVE_USERS_ACTION_BEFORE,
	USERS_INACTIVE_USERS_ACTION_FINISH,
	USER_SAVE_ACTION_BEFORE,
	USERS_SAVE_ACTION_FINISH,
	USERS_SET_SORTING,
	USERS_SET_FILTERS,
	USERS_AVAILABLE_PARTNERS_ACTION_BEFORE,
	USERS_AVAILABLE_PARTNERS_ACTION_FINISH,
	USERS_ACTION_SET_AVAILABLE_PARTNERS,
	SET_USER_GENERAL_INFO,
	SET_USER_PROJECT_ACCESS,
	SET_USER_BETSHOP_ACCESS,
	SET_USER_PARTNER_ACCESS,
	SET_USER_PERMISSIONS,
	SET_USER_PERMISSION_GROUPS,
	SET_ADD_USER_PERMISSION_GROUP,
	SET_DELETE_USER_PERMISSION_GROUP,
	SET_USER_AVAILABLE_PERMISSION_GROUPS,
	SET_USER_AVAILABLE_PERMISSION_GROUPS_BEFORE,
	SET_USER_AVAILABLE_PERMISSION_GROUPS_FINISH,
	CHANGE_USER_STATE,
	RESET_EDITING_USER
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case USERS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case USERS_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case USERS_ACTION_SET_USERS:
			return {
				...state,
				users: payload.data.item2,
				total: payload.data.item1
			};
		case USERS_ACTION_SET_INACTIVE_USERS:
			return {
				...state,
				inactiveUsers: payload.data
			};
		case USERS_INACTIVE_USERS_ACTION_BEFORE:
			return {
				...state,
				isInactiveUsersLoading: true
			};
		case USERS_INACTIVE_USERS_ACTION_FINISH:
			return {
				...state,
				isInactiveUsersLoading: false
			};
		case USER_SAVE_ACTION_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case USERS_SAVE_ACTION_FINISH:
			return {
				...state,
				isSaving: false
			};
		case USERS_AVAILABLE_PARTNERS_ACTION_BEFORE:
			return {
				...state,
				isAvailablePartnersLoading: true
			};
		case USERS_AVAILABLE_PARTNERS_ACTION_FINISH:
			return {
				...state,
				isAvailablePartnersLoading: false
			};
		case USERS_ACTION_SET_AVAILABLE_PARTNERS:
			return {
				...state,
				availablePartners: payload.data
			};
		case USERS_SET_SORTING:
			return {
				...state,
				sorting: payload.sorting
			};
		case USERS_SET_FILTERS:
			return {
				...state,
				filters: payload.filters,
				sorting: {
					...state.sorting,
					page: 1
				}
			};
		case SET_USER_GENERAL_INFO:
			return {
				...state,
				editingUser: {
					...state.editingUser,
					generalInfo: {
						...payload.info
					}
				}
			};
		case SET_USER_PROJECT_ACCESS:
			return {
				...state,
				editingUser: {
					...state.editingUser,
					projectAccess: {
						...payload.projectAccess
					}
				}
			};
		case SET_USER_BETSHOP_ACCESS:
			return {
				...state,
				editingUser: {
					...state.editingUser,
					betShopAccess: {
						...payload.betShopAccess
					}
				}
			};
		case SET_USER_PARTNER_ACCESS:
			return {
				...state,
				editingUser: {
					...state.editingUser,
					partnerAccess: {
						...payload.partnerAccess
					}
				}
			};
		case SET_USER_PERMISSIONS:
			return {
				...state,
				editingUser: {
					...state.editingUser,
					permissions: payload.permissions
				}
			};
		case SET_USER_PERMISSION_GROUPS:
			return {
				...state,
				editingUser: {
					...state.editingUser,
					permissionGroups: {
						...state.editingUser.permissionGroups,
						groups: payload.data
					}
				}
			};
		case SET_ADD_USER_PERMISSION_GROUP:
			return {
				...state,
				editingUser: {
					...state.editingUser,
					permissionGroups: {
						...state.editingUser.permissionGroups,
						groups: payload.group === null ? [...state.editingUser.permissionGroups.groups] : [payload.group, ...state.editingUser.permissionGroups.groups]
					}
				}
			};
		case SET_DELETE_USER_PERMISSION_GROUP:
			return {
				...state,
				editingUser: {
					...state.editingUser,
					permissionGroups: {
						...state.editingUser.permissionGroups,
						groups: state.editingUser.permissionGroups.groups.filter((g) => g.id !== payload.groupId)
					}
				}
			};
		case SET_USER_AVAILABLE_PERMISSION_GROUPS:
			return {
				...state,
				editingUser: {
					...state.editingUser,
					permissionGroups: {
						...state.editingUser.permissionGroups,
						availableGroups: payload.data
					}
				}
			};
		case SET_USER_AVAILABLE_PERMISSION_GROUPS_BEFORE:
			return {
				...state,
				editingUser: {
					...state.editingUser,
					permissionGroups: {
						...state.editingUser.permissionGroups,
						isAvailableGroupsLoading: true
					}
				}
			};
		case SET_USER_AVAILABLE_PERMISSION_GROUPS_FINISH:
			return {
				...state,
				editingUser: {
					...state.editingUser,
					permissionGroups: {
						...state.editingUser.permissionGroups,
						isAvailableGroupsLoading: false
					}
				}
			};
		case CHANGE_USER_STATE:
			return {
				...state,
				users: state.users.map((u) => (u.id !== payload.data.id ? { ...u } : { ...u, state: payload.data.state, lastBlocked: payload.data.lastBlocked, lastBlockedBy: payload.data.lastBlockedBy, lastUpdate: payload.data.lastUpdate }))
			};
		case RESET_EDITING_USER:
			return {
				...state,
				editingUser: {
					generalInfo: {},
					partnerAccess: {
						partners: [],
						mainPartner: {},
						availablePartners: []
					},
					projectAccess: {
						partners: []
					},
					betShopAccess: {
						partners: []
					},
					permissions: [],
					permissionGroups: {
						groups: [],
						availableGroups: {},
						isAvailableGroupsLoading: false
					}
				}
			};
		default:
			return state;
	}
};
