import { numberWithSpaces } from "utils/common";
import { ALL_OPTION_ID } from "constants/common.constants";

/** Generate string type HTML for draw searies in Game Performance Chart
 * @function
 * @param {any} content - content for draw
 * @param {string} color - color which passed in css style
 * @returns {string}
 */
export const drawLabel = (content, color) => {
	return `<span style="color: ${color};">${content}</span>`;
};

/** Formate number to specific unit and optional '%' symbol
 * @function
 * @param {number} value - value for format
 * @param {number} formatUnit - decimal count
 * @param {boolean} showNumeric - flag for managing ' %' postfix add
 * @returns {string}
 */
export const formatValue = (value, formatUnit = 2, showNumeric = false) => {
	const fractionDigits = value === 0 ? 0 : formatUnit;
	const retVal = numberWithSpaces(value.toFixed(fractionDigits));
	if (showNumeric) {
		return retVal;
	}
	return retVal + " %";
};

/** Helper function for skip game, when it's return true need to skip game
 * @function
 * @param {object} gameType - game type of game performance data with metrics
 * @param {array} selectedGameTypes - filtered array of game types which user selected
 * @param {boolean} showNumeric - flag for managing ' %' postfix add
 * @returns {boolean}
 */
export const checkGameTypeForSkip = (gameType, selectedGameTypes) => {
	if (selectedGameTypes.includes(ALL_OPTION_ID)) {
		return true;
	}
	return selectedGameTypes.includes(gameType);
};

/** Helper function to create game key for chart
	* @function
	* @param {GameCategory} gameCategory
	* @param {GameType} gameType
	* @returns {sting}
*/
export const getGameKey = ({ gameCategory, gameType }) => {
	return `${gameCategory}-${gameType}`
}

/** Helper function to create game data from key for chart
	* @function
	* @param {sting} key
	* @param {GameType} gameType
	* @returns {{ gameCategory: GameCategory, gameType: GameType }}
*/
export const keyToGameData = (key) => {
	if (!key) {
		return { gameCategory: "", gameType: "" }
	}
	const [gameCategory, gameType] = key.split("-").map(arg => Number(arg))
	return { gameCategory, gameType }
}

/** Function for select necessary value from game performance data by metric
	* @function
	* @param {object} performanceData - object of game performance data with metrics
	* @param {string} metric - field key name of game performance data
	* @param {null | number} percentCalculationValue - optional argument,
		when type of this argument is number - there will calculate the percentage from this value for the value of the metric
		when type of this null - there will returned value of the metric
	* @returns {number}
*/
export const generateMetricValueByDataCategory = (performanceData, metric, percentCalculationValue = null) => {
	const fieldValue = performanceData[metric];
	if (percentCalculationValue === null) {
		return fieldValue;
	}
	return percentCalculationValue === 0 ? percentCalculationValue : (fieldValue * 100) / percentCalculationValue;
};

/** Function for sum all value by metric and giving info about it from array of game performance data
 * @function
 * @param {array} calculatedGamePerformance - array of game performance data
 * @param {string} metric - field key name of game performance data
 * @returns {object}
 */
export const calculateAbsoluteTotalOfMetric = (calculatedGamePerformance, metric, isPercent = false) => {
	let totalOfMetricValues = 0;
	let isAllZero = true;

	calculatedGamePerformance.forEach((performanceData) => {
		isAllZero = isAllZero && performanceData[metric] === 0;
		totalOfMetricValues += Math.abs(performanceData[metric]);
	})

	return {
		isAllZero,
		totalOfMetricValues,
		valueForPercentCalculation: isPercent ? totalOfMetricValues : null
	};
};
