import { LIVE_MONITOR_TRANSLATION_GROUP_ID, LIVE_MONITOR_TRANSLATION_SUB_GROUPS_IDS } from "./translation.constants";

/**
 * Default partner id for upload translations
 * @constant
 * @type {string}
 */
export const DEFAULT_PARTNER_ID = "408600da84eb4ed19f866825ab9b9a61";

/**
 * Max count of languages
 * @constant
 * @type {number}
 */
export const LANGUAGE_CODES_MAX_COUNT = 20;

/**
 * Uploading translation types
 * @readonly
 * @enum {number}
 */
export const UPDATING_TRANSLATION_TYPES = {
	ALL: 1,
	LIVE_MONITOR: 2
};

/**
 * Translation keys for uploading Translation Types
 * @readonly
 * @type {{ [key in UPDATING_TRANSLATION_TYPES]: string }}
 */
export const UPDATING_TRANSLATION_TYPE_TRANSLATIONS_KEYS = {
	[UPDATING_TRANSLATION_TYPES.ALL]: "translationTypeAll",
	[UPDATING_TRANSLATION_TYPES.LIVE_MONITOR]: "translationTypeLiveMonitor"
};

/**
 * Flush cache db types
 * @readonly
 * @enum {number}
 */
export const DB_TYPE = {
	DB_0: 0
};

/**
 * Group ids by translation types for validate import
 * @readonly
 * @type {{ [key in UPDATING_TRANSLATION_TYPES]: array.<{ id: string, name: string, subGroups: array.<{ id: string, name: string }> }> }}
 */
export const UPDATING_TRANSLATION_TYPE_GROUP_IDS = {
	[UPDATING_TRANSLATION_TYPES.LIVE_MONITOR]: [
		{
			id: LIVE_MONITOR_TRANSLATION_GROUP_ID,
			name: "liveMonitor",
			subGroups: Object.entries(LIVE_MONITOR_TRANSLATION_SUB_GROUPS_IDS).map(([name, id]) => ({ id, name }))
		}
	],
	[UPDATING_TRANSLATION_TYPES.ALL]: [
		{
			id: "0de4d91ed5784ee5926713706c6f1330",
			name: "Common",
			subGroups: []
		},
		{
			id: "02fa365aa7fa4b7aa9566603f4a1bb7c",
			name: "Bet",
			subGroups: []
		},
		{
			id: "d5e6677a547d4443a5982f9e1b2797ef",
			name: "Statistics",
			subGroups: []
		},
		{
			id: "6b5022263cc74d92abeddce72d42ef5b",
			name: "Menu",
			subGroups: []
		},
		{
			id: "dfbccd2aa4f7408bbc58c433c561e038",
			name: "Countries",
			subGroups: []
		},
		{
			id: "8af9c2eebbc04615a96ccf3588a77bfb",
			name: "Cashier",
			subGroups: []
		},
		{
			id: "910f66d11f5c411a9123a2cd428b281e",
			name: "Errors",
			subGroups: [
				{
					id: "6f5aee3a32794dc0a0c86ed4b7ccaa82",
					name: "Resources"
				},
				{
					id: "438f4467a12041fea364952ff1e2820e",
					name: "Message"
				}
			]
		},
		{
			id: "0e90e97ec03d4295bf6197aa0623e60c",
			name: "Bonus",
			subGroups: []
		},
		{
			id: "2c582afb09fe482fa0d023d18f6a15e3",
			name: "Markets",
			subGroups: [
				{
					id: "1b2327e3a0d140ffaddb12e93173d1fb",
					name: "1"
				},
				{
					id: "4b8bf3f09d00479abdb46044f55d97ea",
					name: "4"
				},
				{
					id: "cc3176df141f4ceab201b733cd2b7bc8",
					name: "8"
				},
				{
					id: "7b058e840b5e44dfbd41b19b0cfc4955",
					name: "2"
				},
				{
					id: "3bb2a46839664b91839d96937ca370e7",
					name: "16"
				},
				{
					id: "99692957cda4404ca32c0bbe0dcfdd9e",
					name: "64"
				},
				{
					id: "c6ce7e966bc14a3eacc0dd12bf42c0a7",
					name: "128"
				},
				{
					id: "6c7134f6a52d4731956b8b3523759a29",
					name: "256"
				},
				{
					id: "e84b81e3cb424d52b5616297953f43cb",
					name: "512"
				}
			]
		}
	]
};
