import { Fragment } from "react";

import BoxesComponent from "components/dashboard/retail/boxes/boxes.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const BoxesRoute = () => {
	return (
		<Fragment>
			<BoxesComponent />
		</Fragment>
	);
};

export default withPermission(BoxesRoute, { resource: PERMISSION_RESOURCE.BOX, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
