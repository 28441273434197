import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button } from "antd";

import Colors from "./colors";
import Fonts from "./fonts";
import BorderRadius from "./borderRadius";
import MobileLogo from "./mobileLogo";

const TABS = {
	COLORS: 1,
	FONTS: 2,
	BORDER_RADIUS: 3,
	MOBILE_LOGO: 4
};

const VIEW_TYPE = {
	DESKTOP: 1,
	MOBILE: 2
};

/** Customization Tool Component */
const CustomizationToolComponent = ({ isSaving, currentView }) => {
	const { t } = useTranslation();

	const [opened, setOpened] = useState(true);
	const [tab, setTab] = useState(TABS.COLORS);

	const [isResetDisabled, setIsResetDisabled] = useState(false);

	/** On desktop not allow to be in mobile logo tab */
	useEffect(() => {
		if (currentView === VIEW_TYPE.DESKTOP && tab === TABS.MOBILE_LOGO) {
			setTab(TABS.COLORS);
		}
	}, [currentView]);

	return (
		<Fragment>
			<div className="dashboard-customization-tool">
				<div className={"dashboard-customization-tool-button" + (opened ? " dashboard-customization-tool-button-selected" : "")} onClick={() => setOpened(!opened)}>
					<i className="icon-boil" />
				</div>
				{opened && (
					<div className="dashboard-customization-tool-main">
						<div className="dashboard-customization-tool-main-leftbar">
							<ul>
								<li className={tab === TABS.COLORS ? "active" : ""} onClick={() => setTab(TABS.COLORS)}>
									<span>{t("pages.dashboard.apikeys.customization.colors")}</span>
								</li>
								<li className={tab === TABS.FONTS ? "active" : ""} onClick={() => setTab(TABS.FONTS)}>
									<span>{t("pages.dashboard.apikeys.customization.fonts")}</span>
								</li>
								<li className={tab === TABS.BORDER_RADIUS ? "active" : ""} onClick={() => setTab(TABS.BORDER_RADIUS)}>
									<span>{t("pages.dashboard.apikeys.customization.borders_radius")}</span>
								</li>
								<li className={tab === TABS.MOBILE_LOGO ? "active" : currentView === VIEW_TYPE.DESKTOP ? "disabled" : ""} onClick={() => currentView === VIEW_TYPE.MOBILE && setTab(TABS.MOBILE_LOGO)}>
									<span>{t("pages.dashboard.apikeys.customization.mobile_logo")}</span>
								</li>
							</ul>
						</div>
						<div className="dashboard-customization-tool-main-rightbar">
							<div className="dashboard-customization-tool-main-rightbar-content">
								{tab === TABS.COLORS ? <Colors onClose={() => setOpened(false)} /> : tab === TABS.FONTS ? <Fonts onClose={() => setOpened(false)} /> : tab === TABS.BORDER_RADIUS ? <BorderRadius onClose={() => setOpened(false)} /> : tab === TABS.MOBILE_LOGO ? <MobileLogo /> : null}
							</div>
						</div>
					</div>
				)}
			</div>
		</Fragment>
	);
};

/** CustomizationToolComponent propTypes
 * PropTypes
 */
CustomizationToolComponent.propTypes = {
	/** Redux state property, is true when general info is saving */
	isSaving: PropTypes.bool,
	/** Preview current view */
	currentView: PropTypes.oneOf([VIEW_TYPE.DESKTOP, VIEW_TYPE.MOBILE])
};

const mapStateToProps = (state) => {
	return {
		isSaving: state.apikeys.isSaving
	};
};

export default connect(mapStateToProps, null)(CustomizationToolComponent);
