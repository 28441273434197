import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import WidgetSkeleton from "components/dashboard/statistics/widgets/skeleton";

import { PROJECT_TYPE } from "constants/common.constants";
import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { hasPermission } from "utils/permissions";

/** Dashboard Page Total Players Widget Component */
const TotalPlayersWidgetComponent = ({ projectType, playersTotal, filters: { projectId, period }, isPlayerActivitiesLoading, isBetslipActivitiesLoading, isGamesPerformanceLoading }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const generateQueryParams = () => (projectType === PROJECT_TYPE.ONLINE ? `?projectType=${projectType}&period=${period}&projectId=${projectId ? projectId : "all"}` : "");

	return (
		<div className="dashboard-area" data-type="players">
			<div className="dashboard-area-header">
				<i className="icon-players" />
				<span className="dashboard-area-header-title">{t("pages.dashboard.dashboard.total_players")}</span>
			</div>
			<div className="dashboard-area-content">
				{isPlayerActivitiesLoading || isBetslipActivitiesLoading || isGamesPerformanceLoading ? (
					<WidgetSkeleton variation="total" />
				) : (
					<span title={playersTotal} className="dashboard-area-content-value">
						{playersTotal}
					</span>
				)}
				{hasPermission({ resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.VIEW }) && <i className="icon-right" onClick={() => navigate(`${Paths.DASHBOARD_ONLINE_PLAYERS}${generateQueryParams()}`)} />}
			</div>
		</div>
	);
};

/** UniqueDashboardComponent propTypes
 * PropTypes
 */
TotalPlayersWidgetComponent.propTypes = {
	/** Project type(Online/ Retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	/** Redux state property, the count of active players */
	playersTotal: PropTypes.number,
	/** Redux state property, dashboard pie charts*/
	filters: PropTypes.object,
	/** Redux action to set players filters */
	setPlayersFilters: PropTypes.func,
	// Redux action controls players activities loading indicator
	isPlayerActivitiesLoading: PropTypes.bool,
	// Redux state controls betslips activities loading indicator
	isBetslipActivitiesLoading: PropTypes.bool,
	// Redux state controls games performance loading indicator
	isGamesPerformanceLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		playersTotal: state.dashboard.online.playersTotal,
		filters: state.dashboard.online.filters,
		isPlayerActivitiesLoading: state.dashboard.online.isPlayerActivitiesLoading,
		isBetslipActivitiesLoading: state.dashboard.isBetslipActivitiesLoading,
		isGamesPerformanceLoading: state.dashboard.isGamesPerformanceLoading
	};
};

export default connect(mapStateToProps, null)(TotalPlayersWidgetComponent);
