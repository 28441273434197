import PropTypes from "prop-types";
import { connect } from "react-redux";

import CurrencyEditComponent from "components/dashboard/common/currencyEdit";

import { updateApiKeyCurrency } from "store/actions/dashboard/online/apikeys/currencies.action";

import currencyType from "types/currency/currency.type";

/** Api key Currency Editing Popup Component */
const ApiKeyCurrencyEditComponent = ({ isSaving, updateApiKeyCurrency, onClose, editingCurrency, currencies }) => {
	return <CurrencyEditComponent isSaving={isSaving} updateCurrency={updateApiKeyCurrency} onClose={onClose} editingCurrency={editingCurrency} currencies={currencies} />;
};

/** ApiKeyCurrencyEditComponent propTypes
 * PropTypes
 */
ApiKeyCurrencyEditComponent.propTypes = {
	/** Redux state property, is true when currency is saving */
	isSaving: PropTypes.bool,
	/** Redux action to update api key currecny */
	updateApiKeyCurrency: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Current editing currecy */
	editingCurrency: PropTypes.string,
	/** Redux state property, represents the array of api key currencies  */
	currencies: PropTypes.arrayOf(currencyType)
};

const mapDispatchToProps = (dispatch) => ({
	updateApiKeyCurrency: (currency) => {
		dispatch(updateApiKeyCurrency(currency));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.apikeys.isSaving,
		currencies: state.apikeys.editingApikey.currencies.currencies
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeyCurrencyEditComponent);
