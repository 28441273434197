import PropTypes from "prop-types";

import permissionType from "types/permission/permission.type";

export default PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	description: PropTypes.string,
	permissions: PropTypes.arrayOf(permissionType)
});
