import { Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import Table from "components/common/table";
import QuickInfo from "components/common/quickInfo";

import SortingType from "types/common/sorting.type";

import { getSurveyPartnerFeedbacks, setSurveyPartnerFeedbacksSorting } from "store/actions/dashboard/usersFeedback/CSAT/partners.action";

const CsatPartnersQuickInfo = ({ isLoading, total, sorting, setSurveyPartnerFeedbacksSorting, getSurveyPartnerFeedbacks, surveyId, partnerId, partnerFeedbacks, onClose }) => {
	const { t } = useTranslation();

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.usersFeedback.userName"),
			dataIndex: "playerName",
			sorter: false
		},
		{
			title: t("pages.dashboard.usersFeedback.date"),
			dataIndex: "date",
			render: (date) => moment.utc(date).local().format(DATE_TIME_FORMAT),
			sorter: false
		},
		{
			title: t("pages.dashboard.usersFeedback.score"),
			dataIndex: "grade",
			sorter: false
		},
		{
			title: t("pages.dashboard.usersFeedback.message"),
			dataIndex: "message",
			sorter: false
		}
	];

	return (
		<QuickInfo
			title={
				<div className="vs--flex">
					<div style={{ marginRight: "auto" }}>{t("pages.dashboard.usersFeedback.csat_partner_quick_info")}</div>
				</div>
			}
			visible={surveyId !== null}
			onClose={onClose}
			loading={isLoading}
		>
			<Fragment>
				<div className="dashboard-quick-info-section">
					<div className="dashboard-quick-info-section-header">
						<span>{t("pages.dashboard.usersFeedback.players_responses")}</span>
					</div>
				</div>
				<div className="dashboard-quick-info-section">
					<Table
						loading={isLoading}
						columns={columns}
						data={partnerFeedbacks}
						loadFn={(fromFirstPage) => getSurveyPartnerFeedbacks({ fromFirstPage, surveyId, partnerId })}
						sorting={sorting}
						setSortingFn={setSurveyPartnerFeedbacksSorting}
						total={total}
						isDisabled={() => false}
						noPagination={true}
					/>
				</div>
			</Fragment>
		</QuickInfo>
	);
};

CsatPartnersQuickInfo.propTypes = {
	/** The id of the partner which quick info is showing */
	partnerId: PropTypes.string,
	/** The id of the survey which quick info is showing */
	surveyId: PropTypes.string,
	/** Redux state property, is true when loading quick info */
	isLoading: PropTypes.bool,
	/** Redux state property, survey partner feedbacks total count */
	total: PropTypes.number,
	/** Redux state property, survey partner feedbacks sorting details */
	sorting: SortingType,
	/** Redux action to set survey partner feedbacks sorting details */
	setSurveyPartnerFeedbacksSorting: PropTypes.func,
	/** Redux state property, represents the users feedbacks for current partner */
	partnerFeedbacks: PropTypes.array,
	/** Redux action to get users feedbacks for current partner */
	getSurveyPartnerFeedbacks: PropTypes.func,
	/** Function to call on quick info close */
	onClose: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.surveys.editingSurvey.isLoading,
		partnerFeedbacks: state.surveys.editingSurvey.partnerFeedbacks.partnerFeedbacks
	};
};

const mapDispatchToProps = (dispatch) => ({
	getSurveyPartnerFeedbacks: (args) => {
		dispatch(getSurveyPartnerFeedbacks(args));
	},
	setSurveyPartnerFeedbacksSorting: (sorting) => {
		dispatch(setSurveyPartnerFeedbacksSorting(sorting));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(CsatPartnersQuickInfo);
