import { SET_POST_DEPLOYMENT_ACTIONS_ACTION_BEFORE, SET_POST_DEPLOYMENT_ACTIONS_ACTION_FINISH, SET_POST_DEPLOYMENT_TRANSLATION_LANGUAGE_CODES } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_POST_DEPLOYMENT_ACTIONS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case SET_POST_DEPLOYMENT_ACTIONS_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case SET_POST_DEPLOYMENT_TRANSLATION_LANGUAGE_CODES:
			return {
				...state,
				languageCodes: payload.languageCodes
			};
		default:
			return state;
	}
};
