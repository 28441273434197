import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./en.json";

import LanguageUtils from "utils/languages";

const currentLang = LanguageUtils.getSelectedLanguage();

// the translations
const resources = {
	en: {
		translation: translationEN
	}
};

i18n.use(initReactI18next).init({
	fallbackLng: currentLang,
	debug: true,
	resources,

	lng: currentLang,

	interpolation: {
		escapeValue: false
	}
});

export default i18n;
