import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { MAINTENANCE_MODE_BEFORE, MAINTENANCE_MODE_FINISH, SET_MAINTENANCE_MODE } from "store/actionTypes";

import { MAINTENANCE_MODE_DURATION_TYPE } from "constants/settings.constants";

import { setUserInfoMaintenanceMode } from "../../profile/userInfo.action";

const setMaintenanceModeActionBefore = () => ({
	type: MAINTENANCE_MODE_BEFORE
});

const setMaintenanceModeActionFinished = () => ({
	type: MAINTENANCE_MODE_FINISH
});

const setMaintenanceMode = (data) => ({
	type: SET_MAINTENANCE_MODE,
	payload: { data }
});

export const getMaintenanceMode = () => {
	return (dispatch) => {
		dispatch(setMaintenanceModeActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_MAINTENANCE_MODE}`,
			method: Methods.GET,
			params: {}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setMaintenanceMode(data));
				dispatch(setMaintenanceModeActionFinished());
			})
			.catch(() => {
				dispatch(setMaintenanceModeActionFinished());
			});
	};
};

export const saveMaintenanceMode = (data) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_MAINTENANCE_MODE}`,
			method: Methods.POST,
			data: data
		})
			.then(({ data: { value: maintenanceMode } }) => {
				dispatch(setMaintenanceMode(maintenanceMode));

				dispatch(setUserInfoMaintenanceMode(maintenanceMode.maintenanceMode && maintenanceMode.durationType !== MAINTENANCE_MODE_DURATION_TYPE.TEMPORARY ? true : false));
			})
			.catch((ex) => {
				console.log(ex);
			});
	};
};
