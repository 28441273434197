import {
	SYSTEM_SET_CURRENCIES,
	SYSTEM_SET_AVAILABLE_CURRENCIES,
	SYSTEM_SET_SYSTEM_AVAILABLE_CURRENCIES,
	SYSTEM_ADD_CURRENCY,
	SYSTEM_UPDATE_CURRENCY,
	SYSTEM_CURRENCIES_BEFORE,
	SYSTEM_CURRENCIES_FINISH,
	SYSTEM_CURRENCY_BEFORE,
	SYSTEM_CURRENCY_FINISH,
	SYSTEM_AVAILABLE_CURRENCIES_BEFORE,
	SYSTEM_AVAILABLE_CURRENCIES_FINISH,
	SYSTEM_CURRENCIES_SET_SORTING,
	SYSTEM_CURRENCIES_SET_FILTERS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SYSTEM_CURRENCIES_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case SYSTEM_CURRENCIES_FINISH:
			return {
				...state,
				isLoading: false
			};
		case SYSTEM_CURRENCY_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case SYSTEM_CURRENCY_FINISH:
			return {
				...state,
				isSaving: false
			};
		case SYSTEM_AVAILABLE_CURRENCIES_BEFORE:
			return {
				...state,
				isAvailableLoading: true
			};
		case SYSTEM_AVAILABLE_CURRENCIES_FINISH:
			return {
				...state,
				isAvailableLoading: false
			};
		case SYSTEM_SET_CURRENCIES:
			return {
				...state,
				systemCurrencies: payload.currencies.item2,
				total: payload.currencies.item1
			};
		case SYSTEM_SET_AVAILABLE_CURRENCIES:
			return {
				...state,
				availableCurrencies: payload.currencies
			};
		case SYSTEM_SET_SYSTEM_AVAILABLE_CURRENCIES:
			return {
				...state,
				systemAvailableCurrencies: payload.currencies
			};
		case SYSTEM_ADD_CURRENCY:
			return {
				...state,
				systemCurrencies: [...state.systemCurrencies, payload.currency],
				total: state.systemCurrencies.total + 1
			};
		case SYSTEM_UPDATE_CURRENCY:
			return {
				...state,
				systemCurrencies: state.systemCurrencies.map((c) => (c.code === payload.currency.code ? payload.currency : { ...c }))
			};
		case SYSTEM_CURRENCIES_SET_SORTING:
			return {
				...state,
				sorting: payload.sorting
			};
		case SYSTEM_CURRENCIES_SET_FILTERS:
			return {
				...state,
				filters: payload.filters,
				sorting: {
					...state.sorting,
					page: 1
				}
			};
		default:
			return state;
	}
};
