import { useMemo } from "react";
import PropTypes from "prop-types";

import { toFixedWithoutRound } from "utils/common";

import Row from "antd/lib/row";
import Col from "antd/lib/col";
import LinearGauge from "components/ui/linearGauge";

const GaugeWidget = ({ title = "", percent = "auto" }) => {
	const toFixedPercent = useMemo(() => toFixedWithoutRound(percent), [percent]);

	return (
		<Col span={24} className="stream-servers-item-content-gauge-section">
			<Row justify={"space-between"}>
				<Col span={12} className="stream-servers-item-content-gauge-section-title-label">
					<b>{title}</b>
				</Col>
				<Col span={12} className="stream-servers-item-content-gauge-section-title-value">
					<b>{toFixedPercent}%</b>
				</Col>
			</Row>
			<Row className="stream-servers-item-content-gauge-section-widget-row">
				<Col span={24} className="stream-servers-item-content-gauge-section-widget-column">
					<LinearGauge percent={toFixedPercent} />
				</Col>
			</Row>
		</Col>
	);
};

GaugeWidget.propTypes = {
	// Title prop of widget
	title: PropTypes.string,
	// Percent value prop
	percent: PropTypes.oneOfType([PropTypes.oneOf(["auto"]), PropTypes.number])
};

export default GaugeWidget;
