import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Tabs } from "antd";
import BetslipsComponent from "./betslips";
import { binaryToFlags, flagsToBinary } from "utils/common";
import { updateLocationHash, getHashValue, clearLocationHash } from "utils/common";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { PARTNER_TYPE } from "constants/partner.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import { GAME_CATEGORY } from "constants/game.constants";
import partnerType from "types/partner/partner.type";

/** Betslips Page Component */
const OnlineBetslipsComponent = ({
	globalPartnerId,
	globalPartners,
	gameCategory = flagsToBinary(Object.values(GAME_CATEGORY)),
	showBetsBySeparate = true
}) => {
	const { hash } = useLocation();
	const { t } = useTranslation();

	const [activeKey, setActiveKey] = useState(getHashValue("tab") || GAME_CATEGORY.SCHEDULED.toString());

	/** Update active tab on location hash change */
	useEffect(() => {
		if (globalPartners.length > 0) {
			setActiveKey(getHashValue("tab") || (hasScheduled() ? GAME_CATEGORY.SCHEDULED.toString() : GAME_CATEGORY.INSTANT.toString()));
		}
	}, [hash, globalPartners]);

	useEffect(() => {
		if (globalPartners.length > 0) {
			if (activeKey === GAME_CATEGORY.SCHEDULED.toString()) {
				if (!hasScheduled()) {
					setActiveKey(GAME_CATEGORY.SCHEDULED.toString());
					clearLocationHash();
				}
			} else if (activeKey === GAME_CATEGORY.INSTANT.toString()) {
				if (!hasInstant()) {
					setActiveKey(GAME_CATEGORY.INSTANT.toString());
					clearLocationHash();
				}
			}
		}
	}, [globalPartnerId, globalPartners]);

	/** Is retail type partner
	 * @function
	 * @returns {boolean}
	 * @memberOf OnlineBetslipsComponent
	 */
	const hasInstant = () => binaryToFlags(Object.values(GAME_CATEGORY), gameCategory).includes(GAME_CATEGORY.INSTANT);

	/** Is online type partner
	 * @function
	 * @returns {boolean}
	 * @memberOf OnlineBetslipsComponent
	 */
	const hasScheduled = () => binaryToFlags(Object.values(GAME_CATEGORY), gameCategory).includes(GAME_CATEGORY.SCHEDULED);

	return (
		<Fragment>
			<div className="dashboard-section">
				<Tabs
					animated={false}
					activeKey={activeKey}
					onChange={(key) => updateLocationHash("tab=" + key)}
					destroyInactiveTabPane={true}
					items={[
						hasScheduled()
							? {
									key: GAME_CATEGORY.SCHEDULED.toString(),
									label: t(`pages.dashboard.games.gameCategory_${GAME_CATEGORY.SCHEDULED}`),
									children: (
										<BetslipsComponent
											activeTab={PROJECT_TYPE.ONLINE.toString()}
											gameCategory={GAME_CATEGORY.SCHEDULED}
											showBetsBySeparate={showBetsBySeparate}
										/>
									)
								}
							: null,
						hasInstant()
							? {
									key: GAME_CATEGORY.INSTANT.toString(),
									label: t(`pages.dashboard.games.gameCategory_${GAME_CATEGORY.INSTANT}`),
									children: (
										<BetslipsComponent
											activeTab={PROJECT_TYPE.ONLINE.toString()}
											gameCategory={GAME_CATEGORY.INSTANT}
											showBetsBySeparate={showBetsBySeparate}
										/>
									)
								}
							: null
					]}
				/>
			</div>
		</Fragment>
	);
};

/** OnlineBetslipsComponent propTypes
 * PropTypes
 */
OnlineBetslipsComponent.propTypes = {
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, represents the array of global partners  */
	globalPartners: PropTypes.arrayOf(partnerType),
	showBetsBySeparate: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		globalPartnerId: state.partner.globalPartnerId,
		globalPartners: state.partner.globalPartners
	};
};

export default connect(mapStateToProps, null)(OnlineBetslipsComponent);
