import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { Tabs } from "antd";

import OnlineEventsComponent from "./events";
import Breadcrumbs from "components/common/breadcrumbs";

import { binaryToFlags, flagsToBinary } from "utils/common";
import { updateLocationHash, getHashValue, clearLocationHash } from "utils/common";

import { PARTNER_TYPE } from "constants/partner.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import { GAME_CATEGORY } from "constants/game.constants";

import partnerType from "types/partner/partner.type";

import Paths from "constants/path.constants";

/** Events Page Component */
const EventsComponent = ({ projectType, gameCategory = flagsToBinary(Object.values(GAME_CATEGORY)) }) => {
	/** Is retail type partner
	 * @function
	 * @returns {boolean}
	 * @memberOf TabWrapper
	 */
	const hasScheduled = () => binaryToFlags(Object.values(GAME_CATEGORY), gameCategory).includes(GAME_CATEGORY.SCHEDULED);

	/** Is online type partner
	 * @function
	 * @returns {boolean}
	 * @memberOf TabWrapper
	 */
	const hasInstant = () => binaryToFlags(Object.values(GAME_CATEGORY), gameCategory).includes(GAME_CATEGORY.INSTANT);
	const navigate = useNavigate();
	const { hash } = useLocation();
	const { t } = useTranslation();
	const [activeKey, setActiveKey] = useState(() => getHashValue("tab") || ( hasScheduled() ? GAME_CATEGORY.SCHEDULED.toString() : GAME_CATEGORY.INSTANT.toString() ));

	/** Update active tab on location hash change */
	useEffect(() => {
		setActiveKey(getHashValue("tab") || (hasScheduled() ? GAME_CATEGORY.SCHEDULED.toString() : GAME_CATEGORY.INSTANT.toString()));
	}, [hash]);

	useEffect(() => {
		if (activeKey === GAME_CATEGORY.SCHEDULED.toString()) {
			if (!hasScheduled()) {
				setActiveKey(GAME_CATEGORY.SCHEDULED.toString());
				clearLocationHash();
			}
		} else if (activeKey === GAME_CATEGORY.INSTANT.toString()) {
			if (!hasInstant()) {
				setActiveKey(GAME_CATEGORY.INSTANT.toString());
				clearLocationHash();
			}
		}
	}, []);

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs
					items={[
						{ title: t("pages.dashboard.events.events") }
					]}
				/>
				<Tabs
					animated={false}
					activeKey={activeKey}
					onChange={(key) => {
						navigate(`${Paths.DASHBOARD_EVENTS_ONLINE}#tab=${key}`);
					}}
					destroyInactiveTabPane={true}
					items={
						[
							(
								hasScheduled()
									? (
										{
											key: GAME_CATEGORY.SCHEDULED.toString(),
											label: t(`pages.dashboard.games.gameCategory_${GAME_CATEGORY.SCHEDULED}`),
											children: <OnlineEventsComponent activeTab={PROJECT_TYPE.ONLINE.toString()} gameCategory={GAME_CATEGORY.SCHEDULED} />
										}
									)
									: null
							),
							(
								hasInstant()
									? (
										{
											key: GAME_CATEGORY.INSTANT.toString(),
											label: t(`pages.dashboard.games.gameCategory_${GAME_CATEGORY.INSTANT}`),
											children: <OnlineEventsComponent activeTab={PROJECT_TYPE.ONLINE.toString()} gameCategory={GAME_CATEGORY.INSTANT} />
										}
									)
									: null
							)
						]
					}
				/>
			</div>
		</Fragment>
	);
};

export default EventsComponent;
