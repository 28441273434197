import PropTypes from "prop-types";

const WidgetSkeleton = ({ variation = "count" }) => {
	return (
		<div className={`vs--ui-skeleton-container-${variation}`}>
			<div className="vs--ui-skeleton"></div>
		</div>
	);
};

WidgetSkeleton.propTypes = {
	variation: PropTypes.oneOf(["count", "total"])
};

export default WidgetSkeleton;
