import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { PROJECT_TYPE } from "constants/common.constants";
import { numberWithSpaces } from "utils/common";

import WidgetSkeleton from "components/dashboard/statistics/widgets/skeleton";

/** Dashboard Page Total Win Amount Widget Component */
const TotalWinAmountWidgetComponent = ({ totalWinAmount, isGamesPerformanceLoading }) => {
	const { t } = useTranslation();
	return (
		<div className="dashboard-area" data-type="totalWinAmount">
			<div className="dashboard-area-header">
				<i className="icon-dashboard-total-win-amount" />
				<span className="dashboard-area-header-title">{t("pages.dashboard.dashboard.winAmount")}</span>
			</div>
			<div className="dashboard-area-content">
				{isGamesPerformanceLoading ? (
					<WidgetSkeleton />
				) : (
					<span title={totalWinAmount} className="dashboard-area-content-value">
						{totalWinAmount}
					</span>
				)}
			</div>
		</div>
	);
};

/** TotalWinAmountWidgetComponent propTypes
 * PropTypes
 */
TotalWinAmountWidgetComponent.propTypes = {
	/** Redux state property, dashboard total win amount (Online / Retail) */
	totalWinAmount: PropTypes.string,
	// Redux state controls games performance loading indicator
	isGamesPerformanceLoading: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
	let totalWinAmount = 0;
	switch (ownProps.projectType) {
		case PROJECT_TYPE.ONLINE:
			totalWinAmount = state.dashboard.online.gamesPerformance.totalWinAmount || 0;
			break;
		case PROJECT_TYPE.RETAIL:
			totalWinAmount = state.dashboard.retail.gamesPerformance.totalWinAmount || 0;
			break;
		default:
			break;
	}
	const { minorUnit, symbol, code } = state.profile.userInfo.currency;
	totalWinAmount = numberWithSpaces(totalWinAmount.toFixed(minorUnit)) + " " + (symbol || code);
	const isGamesPerformanceLoading = state.dashboard.isGamesPerformanceLoading;
	return { totalWinAmount, isGamesPerformanceLoading };
};

export default connect(mapStateToProps, null)(TotalWinAmountWidgetComponent);
