import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Collapse, Form, Checkbox, Spin, Tooltip } from "antd";
const { Item: FormItem } = Form;

import Table from "components/common/table";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import permissionType from "types/permission/permission.type";

import { toLowerCaseFirstLetter } from "utils/common";
import IconSecurityLevel from "./iconSecurityLevel";

const iconsMapping = {
	[PERMISSION_RESOURCE.GAME]: "icon-virtual-games",
	[PERMISSION_RESOURCE.PARTNER]: "icon-partners",
	[PERMISSION_RESOURCE.APIKEY]: "icon-online",
	[PERMISSION_RESOURCE.SETTINGS_LANGUAGES]: "icon-languages",
	[PERMISSION_RESOURCE.SETTINGS_CURRENCIES]: "icon-currencies",
	[PERMISSION_RESOURCE.BOX]: "icon-retail",
	[PERMISSION_RESOURCE.BETSHOP]: "icon-retail",
	[PERMISSION_RESOURCE.EVENTS]: "icon-events",
	[PERMISSION_RESOURCE.PLAYER]: "icon-online",
	[PERMISSION_RESOURCE.BETSLIPS]: "icon-betslips",
	[PERMISSION_RESOURCE.FAILED_TRANSACTION]: "icon-transactions",
	[PERMISSION_RESOURCE.TRANSLATIONS]: "icon-cms",
	[PERMISSION_RESOURCE.PERMISSION]: "icon-user-management",
	[PERMISSION_RESOURCE.USER]: "icon-user-management",
	[PERMISSION_RESOURCE.ACCESS_MANAGER]: "icon-user-management",
	[PERMISSION_RESOURCE.CASHIER]: "icon-user-management",
	[PERMISSION_RESOURCE.REPORTS_BETSHOP_PERFORMANCE]: "icon-reports",
	[PERMISSION_RESOURCE.REPORTS_PROJECT_PERFORMANCE]: "icon-reports",
	[PERMISSION_RESOURCE.DASHBOARD]: "icon-dashboard",
	[PERMISSION_RESOURCE.BONUS]: "icon-bonuses",
	[PERMISSION_RESOURCE.SETTINGS]: "icon-settings",
	[PERMISSION_RESOURCE.STREAMS]: "icon-stream",
	[PERMISSION_RESOURCE.DEVELOPMENT]: "icon-developer",
	[PERMISSION_RESOURCE.REPORTS]: "icon-reports",
	[PERMISSION_RESOURCE.SURVEYS]: "icon-responseRate",
	[PERMISSION_RESOURCE.RISK_MANAGEMENT_BET_ANOMALIES]: "icon-risk-management",
	[PERMISSION_RESOURCE.RISK_MANAGEMENT_BIG_MULTIPLIERS]: "icon-risk-management",
	[PERMISSION_RESOURCE.RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES]: "icon-risk-management"
};


/** Permissions List Component */

const PermissionsComponent = ({ permissions, isLoading, formInstance, initialFormValues, editable, disabled, onCheckAll, fieldName }) => {
	const { t } = useTranslation();

	const [activeKey, setActiveKey] = useState([]);
	const [formValues, setFormValues] = useState(initialFormValues);

	/** Open all by default */
	useEffect(() => {
		setFormValues(initialFormValues);
	}, [initialFormValues]);

	const assignPermission = (permission, action, value) => {
		if (permission[action] === null || permission[action] === undefined) {
			return;
		}
		if (!Object.prototype.hasOwnProperty.call(permission, action)) {
			return;
		}
		permission[action] = value;
	};

	/** Function to render table cell for permission
	 * @param {object} permission
	 * @param {string} action
	 * @param {string} parent - parent resource
	 * @function
	 * @memberOf PermissionsComponent
	 */
	const renderCell = (permission, action, parent) => {
		const actionData = permission.actions.find((a) => a.action === action);
		return !editable || (actionData && actionData.canEdit) ? (
			<div className="permissions-sub-table-content">
				{actionData && actionData.canEdit && (
					<FormItem
						name={permission.isParent ? (fieldName ? [fieldName] : []).concat([permission.resource, "parent", action]) : (fieldName ? [fieldName] : []).concat([parent?.resource ?? null, permission.resource, action])}
						valuePropName="checked"
						className="form-item-without-margin permissions-sub-table-checkbox"
					>
						<Checkbox onChange={(e) => handleChange(permission.resource, !permission.isParent ? parent?.resource : null, action, e.target.checked)} disabled={isFieldReadonly(permission.resource, !permission.isParent ? parent?.resource : null, action)} />
					</FormItem>
				)}
				<Tooltip title={actionData?.description} getPopupContainer={() => document.body}>
					<span className="table-col-shorten-text permissions-sub-table-description">{actionData?.description}</span>
				</Tooltip>
				{actionData && typeof actionData.securityLevel === "number" ? <IconSecurityLevel level={actionData.securityLevel} /> : null}
			</div>
		) : (
			""
		);
	};

	/** Checks if the permissions contains any subpermission which supports the action
	 * @param {string} resource
	 * @param {string} action
	 * @function
	 * @returns {boolean}
	 * @memberOf PermissionsComponent
	 */
	const isResourceHasAction = (resource, action) => {
		const permission = permissions.find((p) => p.resource === resource);
		if (!permission) return false;
		return permission.actions.some((a) => a.action === action && a.canEdit) || permission.subPermissions.some((p) => p.actions.some((a) => a.action === action && a.canEdit));
	};

	/** Function to render table cell for permission
	 * @param {string} resource
	 * @param {string} action
	 * @function
	 * @memberOf PermissionsComponent
	 */
	const renderHeaderCell = (resource, action) => {
		return (
			<div className="permissions-sub-table-content">
				{editable && !disabled && isResourceHasAction(resource, action) && (
					<FormItem className="form-item-without-margin permissions-sub-table-checkbox">
						<Tooltip title={isCheckedAll(resource, action) ? t("pages.dashboard.permissions.deselect_all") : t("pages.dashboard.permissions.select_all")}>
							<Checkbox
								checked={isCheckedAll(resource, action)}
								indeterminate={!isUnCheckedAll(resource, action) && !isCheckedAll(resource, action)}
								onClick={(e) => {
									!isCheckedAll(resource, action) ? checkAll(resource, action, e) : unCheckAll(resource, action, e);
								}}
							/>
						</Tooltip>
					</FormItem>
				)}
				<span>{action}</span>
			</div>
		);
	};

	/** Function to render table for permissions
	 * @param {object} permission
	 * @function
	 * @memberOf PermissionsComponent
	 */
	const renderTable = (permission) => {
		let tableData = [];
		if (!editable || permission.actions.some((a) => a.canEdit) || permission.actions.length === 0) {
			tableData.push({ ...permission, isParent: true });
		}
		tableData = tableData.concat(permission.subPermissions.filter((s) => !editable || s.actions.some((a) => a.canEdit)));

		return (
			<div className="permissions-sub-table" key={permission.resource}>
				<Table
					loading={isLoading}
					columns={[
						{
							title: "",
							dataIndex: "name",
							sorter: false,
							width: "300px"
						},
						{
							title: renderHeaderCell(permission.resource, PERMISSION_ACTION.VIEW),
							dataIndex: "view",
							render: (value, record) => renderCell(record, PERMISSION_ACTION.VIEW, tableData[0]),
							sorter: false
						},
						{
							title: renderHeaderCell(permission.resource, PERMISSION_ACTION.CREATE),
							dataIndex: "create",
							render: (value, record) => renderCell(record, PERMISSION_ACTION.CREATE, tableData[0]),
							sorter: false
						},
						{
							title: renderHeaderCell(permission.resource, PERMISSION_ACTION.MODIFY),
							dataIndex: "modify",
							render: (value, record) => renderCell(record, PERMISSION_ACTION.MODIFY, tableData[0]),
							sorter: false
						},
						{
							title: renderHeaderCell(permission.resource, PERMISSION_ACTION.DELETE),
							dataIndex: "delete",
							render: (value, record) => renderCell(record, PERMISSION_ACTION.DELETE, tableData[0]),
							sorter: false
						},
						{
							title: renderHeaderCell(permission.resource, PERMISSION_ACTION.EXPORT),
							dataIndex: "export",
							render: (value, record) => renderCell(record, PERMISSION_ACTION.EXPORT, tableData[0]),
							sorter: false
						}
					]}
					data={tableData}
					total={tableData.length}
					actions={{}}
					isDisabled={() => false}
					noPagination={true}
					uniqueKey="resource"
					nonFixed={true}
				/>
			</div>
		);
	};

	/** Fires on checkbox change
	 * @function
	 * @param {string} resource
	 * @param {string} parent
	 * @param {string} action
	 * @param {boolean} checked
	 * @memberOf PermissionsComponent
	 */
	const handleChange = (resource, parent, action, checked) => {
		const { setFieldsValue, getFieldsValue, getFieldValue } = formInstance;
		let changed = false;
		const values = fieldName ? { ...getFieldValue(fieldName) } : { ...getFieldsValue() };
		if (checked) {
			if (!parent) {
				if (action === PERMISSION_ACTION.DELETE) {
					changed = true;
					if (values[resource]["parent"]) {
						assignPermission(values[resource]["parent"], PERMISSION_ACTION.MODIFY, true);
					}
				}
				if (action === PERMISSION_ACTION.DELETE || action === PERMISSION_ACTION.MODIFY || action === PERMISSION_ACTION.CREATE || action === PERMISSION_ACTION.EXPORT) {
					changed = true;
					if (values[resource]["parent"]) {
						assignPermission(values[resource]["parent"], PERMISSION_ACTION.VIEW, true);
					}
				}
			} else {
				changed = true;
				if (values[parent]["parent"]) {
					assignPermission(values[parent]["parent"], PERMISSION_ACTION.VIEW, true);
					assignPermission(values[parent]["parent"], PERMISSION_ACTION.MODIFY, true);
				}
				if (action === PERMISSION_ACTION.DELETE) {
					if (values[parent][resource]) {
						assignPermission(values[parent][resource], PERMISSION_ACTION.MODIFY, true);
						assignPermission(values[parent][resource], PERMISSION_ACTION.VIEW, true);
					}
				} else if (action === PERMISSION_ACTION.MODIFY || action === PERMISSION_ACTION.CREATE || action === PERMISSION_ACTION.EXPORT) {
					if (values[parent][resource]) {
						assignPermission(values[parent][resource], PERMISSION_ACTION.VIEW, true);
					}
				}
			}
		}
		if (changed) {
			setFieldsValue(fieldName ? { [fieldName]: values } : values);
		}
		setFormValues(values);
	};

	/** Function, checkes should the field be readOnly
	 * @function
	 * @param {string} resource
	 * @param {string} parent
	 * @param {string} action
	 * @memberOf PermissionsComponent
	 */
	const isFieldReadonly = (resource, parent, action) => {
		if (disabled) return true;
		const values = { ...formValues };
		if ((!parent && !values[resource]) || (parent && (!values[parent] || !values[parent][resource]))) return false;
		let isReadonly = false;
		if (!parent) {
			if (action === PERMISSION_ACTION.VIEW) {
				Object.keys(values[resource]).forEach((res) => {
					if (isReadonly) return false;
					Object.keys(values[resource][res]).forEach((a) => {
						if ((a !== PERMISSION_ACTION.VIEW || res !== "parent") && values[resource][res][a] === true) {
							isReadonly = true;
							return false;
						}
					});
				});
			} else if (action === PERMISSION_ACTION.MODIFY) {
				Object.keys(values[resource]).forEach((res) => {
					if (isReadonly) return false;
					Object.keys(values[resource][res]).forEach((a) => {
						if (values[resource][res][a] === true && (a === PERMISSION_ACTION.DELETE || ((a === PERMISSION_ACTION.MODIFY || a === PERMISSION_ACTION.CREATE || a === PERMISSION_ACTION.VIEW) && res !== "parent"))) {
							isReadonly = true;
							return false;
						}
					});
				});
			}
		} else {
			if (action === PERMISSION_ACTION.MODIFY) {
				if (values[parent][resource][PERMISSION_ACTION.DELETE] === true) {
					isReadonly = true;
				}
			} else if (action === PERMISSION_ACTION.VIEW) {
				if (values[parent][resource][PERMISSION_ACTION.MODIFY] === true || values[parent][resource][PERMISSION_ACTION.DELETE] === true || values[parent][resource][PERMISSION_ACTION.CREATE] === true || values[parent][resource][PERMISSION_ACTION.EXPORT] === true) {
					isReadonly = true;
				}
			}
		}
		return isReadonly;
	};

	/** Function, checkes if all the permissions of resource are checked
	 * @function
	 * @param {string} resource
	 * @param {string} action - if null then all actions
	 * @memberOf PermissionsComponent
	 */
	const isCheckedAll = (resource, action) => {
		const values = { ...formValues };
		let foundChecked = false;
		let foundUnChecked = false;
		if (!values[resource]) return false;
		for (let i = 0; i < Object.keys(values[resource]).length; i++) {
			let actions = values[resource][Object.keys(values[resource])[i]];

			if (action) {
				if (actions[action] === false) {
					foundUnChecked = true;
				} else if (actions[action] === true) {
					foundChecked = true;
				}
			} else {
				Object.keys(actions).forEach((a) => {
					if (actions[a] === false) {
						foundUnChecked = true;
					} else if (actions[a] === true) {
						foundChecked = true;
					}
				});
			}

			if (foundUnChecked) {
				break;
			}
		}

		return !foundUnChecked && foundChecked;
	};

	/** Function, checkes if all the permissions of resource are unchecked
	 * @function
	 * @param {string} resource
	 * @param {string} action - if null then all actions
	 * @memberOf PermissionsComponent
	 */
	const isUnCheckedAll = (resource, action) => {
		const values = { ...formValues };
		let isChecked = false;
		if (!values[resource]) return false;
		for (let i = 0; i < Object.keys(values[resource]).length; i++) {
			let actions = values[resource][Object.keys(values[resource])[i]];
			if (action) {
				if (actions[action] === true) {
					isChecked = true;
				}
			} else {
				Object.keys(actions).forEach((a) => {
					if (actions[a] === true) {
						isChecked = true;
					}
				});
			}
			if (isChecked) break;
		}
		return !isChecked;
	};

	/** Fires on select all click
	 * @function
	 * @param {string} resource
	 * @param {string} action - if null then all actions
	 * @param {object} e - click event object
	 * @memberOf PermissionsComponent
	 */
	const checkAll = (resource, action, e) => {
		e.stopPropagation();
		if (isCheckedAll(resource, action)) return;
		const { setFieldsValue, getFieldsValue, getFieldValue } = formInstance;
		const values = fieldName ? { ...getFieldValue(fieldName) } : { ...getFieldsValue() };
		Object.keys(values[resource]).forEach((res) => {
			if (action && values[resource][res][action] !== undefined) {
				assignPermission(values[resource][res], action, true);
				if (action === PERMISSION_ACTION.DELETE) {
					assignPermission(values[resource][res], PERMISSION_ACTION.MODIFY, true);
					assignPermission(values[resource][res], PERMISSION_ACTION.VIEW, true);
				} else if (action === PERMISSION_ACTION.CREATE || action === PERMISSION_ACTION.MODIFY || action === PERMISSION_ACTION.EXPORT) {
					assignPermission(values[resource][res], PERMISSION_ACTION.VIEW, true);
				}
				if (res !== "parent" && values[resource]["parent"]) {
					assignPermission(values[resource]["parent"], PERMISSION_ACTION.MODIFY, true);
					assignPermission(values[resource]["parent"], PERMISSION_ACTION.VIEW, true);
				}
			} else if (!action) {
				Object.keys(values[resource][res]).forEach((a) => {
					assignPermission(values[resource][res], a, true);
				});
			}
		});
		setFieldsValue(fieldName ? { [fieldName]: values } : values);
		setFormValues(values);
		onCheckAll && onCheckAll();
	};

	/** Fires on deselect all click
	 * @function
	 * @param {string} resource
	 * @param {string} action - if null then all actions
	 * @param {object} e - click event object
	 * @memberOf PermissionsComponent
	 */
	const unCheckAll = (resource, action, e) => {
		e.stopPropagation();
		if (isUnCheckedAll(resource, action)) return;
		const { setFieldsValue, getFieldsValue, getFieldValue } = formInstance;
		const values = fieldName ? { ...getFieldValue(fieldName) } : { ...getFieldsValue() };
		Object.keys(values[resource]).forEach((res) => {
			if (action) {
				if (!isFieldReadonly(res === "parent" ? resource : res, res === "parent" ? null : resource, action)) {
					assignPermission(values[resource][res], action, false);
				}
			} else {
				Object.keys(values[resource][res]).forEach((a) => {
					assignPermission(values[resource][res], a, false);
				});
			}
		});

		setFieldsValue(fieldName ? { [fieldName]: values } : values);
		setFormValues(values);
		onCheckAll && onCheckAll();
	};

	/** CHeck if resource has any action to edit
	 * @function
	 * @param {string} resource
	 * @returns {boolean}
	 * @memberOf PermissionsComponent
	 */
	const hasEditablePermission = (resource) => {
		const permission = permissions.find((p) => p.resource === resource);
		if (!permission) return false;
		return permission.actions.some((a) => a.canEdit) || permission.subPermissions.some((p) => p.actions.some((a) => a.canEdit));
	};

	return (
		<Spin spinning={isLoading} style={{ minHeight: "300px" }}>
			<Collapse bordered={false} activeKey={activeKey} className="permissions-collapse" onChange={(e) => setActiveKey(e)} expandIcon={() => <i className="icon-down permissions-collapse-icon" />}>
				{permissions.map((perm) =>
					hasEditablePermission(perm.resource) || !editable ? (
						<Collapse.Panel
							header={
								<div className="permissions-collapse-panel-header">
									<div className="permissions-collapse-panel-header-inner">
										<i className={iconsMapping[toLowerCaseFirstLetter(perm.resource)]} />
										<span>{perm.name}</span>
									</div>
									{editable && !disabled && (
										<FormItem className="form-item-without-margin permissions-sub-table-checkbox">
											<Tooltip title={isCheckedAll(perm.resource) ? t("pages.dashboard.permissions.deselect_all") : t("pages.dashboard.permissions.select_all")}>
												<Checkbox checked={isCheckedAll(perm.resource)} indeterminate={!isUnCheckedAll(perm.resource) && !isCheckedAll(perm.resource)} onClick={(e) => (!isCheckedAll(perm.resource) ? checkAll(perm.resource, null, e) : unCheckAll(perm.resource, null, e))} />
											</Tooltip>
										</FormItem>
									)}
								</div>
							}
							key={perm.resource}
							forceRender={true}
						>
							{renderTable(perm)}
						</Collapse.Panel>
					) : null
				)}
			</Collapse>
		</Spin>
	);
};

/** PermissionsComponent propTypes
 * PropTypes
 */
PermissionsComponent.propTypes = {
	/** permissions array to show */
	permissions: PropTypes.arrayOf(permissionType),
	/** Is true when loading permissions */
	isLoading: PropTypes.bool,
	/** Form instance object */
	formInstance: PropTypes.object,
	/** Form initial values */
	initialFormValues: PropTypes.object,
	/** Are permission editable */
	editable: PropTypes.bool,
	/** Are checkboxes disabled */
	disabled: PropTypes.bool,
	/** Function, which will fire on checking all */
	onCheckAll: PropTypes.func,
	/** The fild name in form */
	fieldName: PropTypes.string
};

export default PermissionsComponent;
