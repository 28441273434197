import PropTypes from "prop-types";

export default PropTypes.shape({
	generalDetails: PropTypes.number,
	marketSelection: PropTypes.number,
	customText: PropTypes.string,
	enableBarcodePrinting: PropTypes.bool,
	enableQRPrinting: PropTypes.bool,
	ticketLogoId: PropTypes.number
});
