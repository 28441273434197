import { useEffect, useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { saveJackpotGeneralInfo } from 'store/actions/dashboard/bonuses/generalInfo.action';
import {
	getAvailableJackpotBetshops,
	setAvailableJackpotBetshops,
	getAvailableJackpotGames,
	setAvailableJackpotGames
} from 'store/actions/dashboard/bonuses/bonuses.action';
import locale from 'antd/es/date-picker/locale/en_US';

import { Form, Row, Col, Spin, Button, Input, Select, DatePicker, Tooltip } from 'antd';
import SearchableSelect from "components/common/searchableSelect";
import CopyDataPopup from './copyDataPopup';

import moment from 'moment';

import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';
import { binaryToFlags, flagsToBinary } from 'utils/common';
import { getSelectedValues } from 'utils/bonuses'

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { SCHEDULED_GAME_TYPE, SCHEDULED_GAME_TYPE_MAPPER } from 'constants/game.constants';
import { ALL_OPTION_ID } from 'constants/common.constants';
import bonusGeneralInfoType from 'types/bonus/generalInfo.type';
import { JACKPOTS_TYPES, JACKPOT_LEVEL, JACKPOT_STATE } from 'constants/bonus.constants';
import { getPartnerAvailableCurrencies } from 'store/actions/dashboard/partners/currencies.action';
import JackpotLevel from './jackpotLevel';
import { DATE_TIME_FORMAT, TIME_FORMAT } from 'constants/date.constants';

const COPY_FIELDS = {
	HIT_COUNT: "hitCount",
	LEVELS: "levels"
}

/** Bonus Edit Page General Info Tab Component */
const GeneralInfoComponent = ({
	saveJackpotGeneralInfo,
	getPartnerAvailableCurrencies,
	getAvailableJackpotBetshops,
	getAvailableJackpotGames,
	setAvailableJackpotGames,
	setAvailableJackpotBetshops,
	betshops,
	games,
	isSaving,
	isLoading,
	generalInfo,
	availableCurrencies
}) => {

	const { t } = useTranslation();
	const routeParams = useParams();
	const [antdForm] = Form.useForm();

	const [selectedCurrencyCode, setSelectedCurrencyCode] = useState("");
	const [selectedProjectIds, setSelectedProjectIds] = useState([]);
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [jackpotCopyId, setJackpotCopyId] = useState(null);

	// antdFormInitialValuesRef will be set when data(generalInfo) is received
	const antdFormInitialValuesRef = useRef({});

	const handleFormChange = (_, formValues) => {
		setIsFormTouched(isFormChanged({ ...formValues }, { ...antdFormInitialValuesRef.current }));
	}

	const handleCurrencyFieldChange = (currencyCode) => {
		setSelectedCurrencyCode(currencyCode);

		// reset GAME_TYPE field value
		antdForm.setFieldsValue({
			projectIds: [],
			gameType: []
		});
	}

	const handleBetshopFieldChange = projectIds => {
		const betshopIdList = getSelectedValues(projectIds);

		antdForm.setFieldsValue({
			projectIds: betshopIdList,
			gameType: []
		});

		if (betshopIdList[0] === ALL_OPTION_ID) {
			setSelectedProjectIds(betshops.map(betshop => betshop.key));
			return;
		}

		setSelectedProjectIds(betshopIdList);
	}

	const fillFormFromGeneralInfo = () => {
		if (Object.keys(generalInfo).length === 0) {
			return;
		}

		const allGameTypes = Object.values(SCHEDULED_GAME_TYPE).map(gameTypeObj => gameTypeObj.value)
		const gameType = binaryToFlags(allGameTypes, generalInfo.gameType);

		const startTime = generalInfo.startTime ? moment.utc(generalInfo.startTime).local() : generalInfo.startTime;

		antdFormInitialValuesRef.current = {
			...generalInfo,
			gameType,
			startTime
		}

		antdForm.setFieldsValue({ ...antdFormInitialValuesRef.current });

		setSelectedProjectIds(generalInfo.projectIds)
		setIsFormTouched(false);
	}

	const handleGameTypeFieldChange = selectedGameTypeIds => {
		antdForm.setFieldsValue({ gameType: getSelectedValues(selectedGameTypeIds) });
	}

	const handleCancelButtonClick = () => {
		antdForm.resetFields();
		fillFormFromGeneralInfo()
	}

	const handleSaveButtonClick = () => {
		antdForm.validateFields()
			.then(fieldValuesObj => {
				const requestBody = { ...fieldValuesObj }
				requestBody.levels = requestBody.levels.slice(Object.values(JACKPOT_LEVEL).length - requestBody.hitCount);
				requestBody.currency = requestBody.currency ? requestBody.currency.toUpperCase() : requestBody.currency
				requestBody.gameType = fieldValuesObj.gameType[0] === ALL_OPTION_ID
					? flagsToBinary(games)
					: flagsToBinary(fieldValuesObj.gameType);

				saveJackpotGeneralInfo({
					...requestBody,
					id: routeParams.id
				})
				setIsFormTouched(false);
			})
			.catch(Function.prototype)
	}

	const handleCopyButtonClick = (mappedInfo) => {
		Object.keys(mappedInfo).forEach((fieldKey) => {
			antdForm.setFieldsValue({
				[fieldKey]: mappedInfo[fieldKey]
			})
		})

		handleFormChange(mappedInfo, antdForm.getFieldsValue());
	}

	// Function to disable date before the next 30 minutes
	const disabledDate = (current) => {
		return current && current < moment().add(30, 'minutes').startOf('minute');
	};

	// Function to disable times before the next 30 minutes
	const disabledDateTime = () => {
		const current = moment();
		const limit = current.add(30, 'minutes');

		return {
			disabledHours: () => {
				const hours = [];
				for (let i = 0; i < limit.hour(); i++) {
					hours.push(i);
				}
				return hours;
			},
			disabledMinutes: (selectedHour) => {
				const minutes = [];
				if (selectedHour === limit.hour()) {
					for (let i = 0; i < limit.minute(); i++) {
						minutes.push(i);
					}
				}
				return minutes;
			},
			disabledSeconds: () => [],
		};
	};

	useEffect(() => {
		getPartnerAvailableCurrencies()
	}, [])

	// Set form fields values, when data is loaded
	useEffect(() => {
		if (Object.keys(generalInfo).length === 0) {
			return
		}
		fillFormFromGeneralInfo()
		setSelectedCurrencyCode(generalInfo.currency)

	}, [generalInfo])

	// Get available betshops for selected currency
	useEffect(() => {
		if (!selectedCurrencyCode) {
			return;
		}

		setAvailableJackpotBetshops([]);
		getAvailableJackpotBetshops(selectedCurrencyCode.toUpperCase(), generalInfo?.id);
	}, [selectedCurrencyCode])

	// Get available game types for selected betshops
	useEffect(() => {
		if (selectedProjectIds.length === 0) {
			return;
		}

		setAvailableJackpotGames([]);
		getAvailableJackpotGames(selectedProjectIds);
	}, [selectedProjectIds])

	const isModifyDisabled = (
		!hasPermission({ resource: PERMISSION_RESOURCE.BONUS_JACKPOT, action: PERMISSION_ACTION.MODIFY }) ||
		generalInfo.state !== JACKPOT_STATE.INACTIVE
	);

	const createGamesItems = () => {
		const mapGames = game => ({ key: game, value: t(`common.${SCHEDULED_GAME_TYPE_MAPPER[game]}`) })
		if (games.length > 1) {
			return [{ key: ALL_OPTION_ID, value: t("common.all") }].concat(games.map(mapGames))
		}
		return games.map(mapGames)
	}

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={antdForm}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					id: null,
					name: null,
					type: null,
					partnerId: null,
					partnerName: null,
					gameCategory: null,
					gameType: null,
					projectIds: [],
					currency: null,
					hitCount: null,
					state: null,
					createTime: null,
					startTime: null,
					finishTime: null,
					isDraft: false,
					levels: []
				}}
				onValuesChange={handleFormChange}
			>
				<div className="dashboard-section-content">
					<div>
						<Col span={24}>
							<div className="dashboard-section-info">
								<h1>{t('pages.dashboard.bonuses.jackpot_main')}</h1>
							</div>
						</Col>
						<Row gutter={16}>
							<Col span={6}>
								<Form.Item
									name="name"
									label={`${t('pages.dashboard.bonuses.jackpot_name')} *`}
									rules={
										[
											{
												required: true,
												message: t("validation.field_required"),
											},
											{ max: 50, message: t("validation.must_be_less_than_other").replace("%X%", t('pages.dashboard.bonuses.jackpot_name')).replace("%Y%", 50) }
										]
									}
								>
									<Input
										disabled={isModifyDisabled}
										placeholder={`${t('common.enter')} ${t('pages.dashboard.bonuses.jackpot_name')}`}
									/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									name="type"
									label={`${t('pages.dashboard.bonuses.jackpot_type')} *`}
									rules={
										[
											{
												required: true,
												message: t("validation.field_required"),
											}
										]
									}
								>
									<Select
										placeholder={`${t('common.select')} ${t('pages.dashboard.bonuses.jackpot_type')}`}
										suffixIcon={(<i className="icon-down" />)}
										disabled={true}
									>
										<Select.Option value={JACKPOTS_TYPES.RETAIL}>{t('pages.dashboard.bonuses.jackpot_type_retail')}</Select.Option>
										<Select.Option value={JACKPOTS_TYPES.ONLINE}>{t('pages.dashboard.bonuses.jackpot_type_online')}</Select.Option>
									</Select>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									name="partnerName"
									label={`${t('pages.dashboard.bonuses.network_type')} *`}
									rules={
										[
											{
												required: true,
												message: t("validation.field_required"),
											}
										]
									}
								>
									<Select
										placeholder={`${t('common.select')} ${t('pages.dashboard.bonuses.network_type')}`}
										suffixIcon={(<i className="icon-down" />)}
										disabled={true}
									>
										<Select.Option value={generalInfo.partnerName}>{generalInfo.partnerName}</Select.Option>
										<Select.Option value={""}>{t('pages.dashboard.bonuses.jackpot_type_online')}</Select.Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Col span={24}>
							<div className="dashboard-section-info">
								<h1>{t('pages.dashboard.bonuses.jackpot_general_configurations')}</h1>
							</div>
						</Col>
						<Row gutter={16}>
							<Col span={6}>
								<Form.Item
									name="currency"
									label={`${t('pages.dashboard.bonuses.currency')} *`}
									rules={
										[
											{
												required: true,
												message: t("validation.field_required"),
											}
										]
									}
								>
									<Select
										placeholder={t('pages.dashboard.bonuses.currency')}
										suffixIcon={(<i className="icon-down" />)}
										onChange={handleCurrencyFieldChange}
										disabled={isModifyDisabled}
									>
										{Object.keys(availableCurrencies).map(currencyCode => {
											return (
												<Select.Option key={currencyCode} value={currencyCode}>
													{`${currencyCode.toUpperCase()} - ${availableCurrencies[currencyCode]}`}
												</Select.Option>
											)
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item shouldUpdate={true} noStyle>
									{({ getFieldValue }) => {
										const projectIds = getFieldValue("projectIds") ?? [];
										return (
											<Tooltip
												placement='bottom'
												title={
													projectIds.length === 1
														? null
														: (
															projectIds.map((projectId, i) => {
																const betshop = betshops?.find(betshop => betshop.key === projectId)
																if (!betshop) {
																	return <Fragment key={i}/>
																}
																return (
																	<div key={i}>
																		{betshop.value}
																	</div>
																)
															})
														)
												}
											>
											<Form.Item
												name="projectIds"
												label={`${t('pages.dashboard.bonuses.betshopIds')} *`}
												rules={
													[
														{
															required: true,
															message: t("validation.field_required"),
														}
													]
												}
											>
												<SearchableSelect
													mode="multiple"
													placeholder={t('pages.dashboard.bonuses.betshopIds')}
													items={betshops.length > 1 ? [{ key: ALL_OPTION_ID, value: t("common.all") }, ...betshops] : betshops}
													valueProp={betshop => betshop.key}
													textProp={betshop => betshop.value}
													renderText={betshop => betshop.value}
													onChange={handleBetshopFieldChange}
													disabled={isModifyDisabled || !selectedCurrencyCode}
													maxTagPlaceholder={(selectedList) => <span>+{selectedList.length}</span>}
													optionPropsGenerator={(props) => ({ ...props, title: "" })}
												/>
											</Form.Item>
											</Tooltip>
										)
									}}
								</Form.Item>
							</Col>
							<Col span={6} style={{ position: "relative" }}>
								<Form.Item shouldUpdate={true} noStyle>
									{({ getFieldValue }) => {
										const gameTypes = getFieldValue("gameType") ?? [];
										return (
											<Tooltip
												placement='bottom'
												title={
													gameTypes.length === 1
														? null
														: (
															gameTypes.map(gameType => {
																return (
																	<div key={gameType}>
																		{t(`common.${SCHEDULED_GAME_TYPE_MAPPER[gameType]}`)}
																	</div>
																)
															})
														)
												}
											>
												<Form.Item
													name="gameType"
													label={`${t('pages.dashboard.bonuses.gameType')} *`}
													rules={
														[
															{
																required: true,
																message: t("validation.field_required"),
															}
														]
													}
												>
													<SearchableSelect
														mode="multiple"
														items={createGamesItems()}
														valueProp={game => game.key}
														textProp={game => game.value}
														renderText={game => <span>{game.value}</span>}
														placeholder={`${t('common.select')} ${t('pages.dashboard.bonuses.gameType')}`}
														onChange={handleGameTypeFieldChange}
														suffixIcon={(<i className="icon-down" />)}
														disabled={isModifyDisabled || !selectedProjectIds.length}
														maxTagPlaceholder={(selectedList) => <span>+{selectedList.length}</span>}
														optionPropsGenerator={(props) => ({ ...props, title: "" })}
													/>
												</Form.Item>
											</Tooltip>
										)
									}}
								</Form.Item>
							</Col>
						</Row>
						<Col span={24}>
							<div className="dashboard-section-info">
								<h1>{t('pages.dashboard.bonuses.jackpot_main_configurations')}</h1>
							</div>
						</Col>
						<Row gutter={16}>
							<Col span={6}>
								<Form.Item
									name="hitCount"
									label={`${t('pages.dashboard.bonuses.jackpot_hit_count')} *`}
									rules={
										[
											{
												required: true,
												message: t("validation.field_required"),
											}
										]
									}
								>
									<Select
										placeholder={`${t('common.select')} ${t('pages.dashboard.bonuses.jackpot_hit_count')}`}
										suffixIcon={(<i className="icon-down" />)}
										disabled={isModifyDisabled}
									>
										{
											Array.from({ length: 3 }, (_, i) => {
												const count = i + 1;
												return (
													<Select.Option key={count} value={count}>{count} {t('pages.dashboard.bonuses.level')}</Select.Option>
												)
											})
										}
									</Select>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item label=" ">
									<Button
										type="secondary"
										className="button"
										disabled={isModifyDisabled}
										onClick={() => setJackpotCopyId(generalInfo.id)}
									>
										<span>{t('pages.dashboard.bonuses.copy_data')}</span>
									</Button>
								</Form.Item>
							</Col>
						</Row>
						<Form.Item shouldUpdate={true} noStyle>
							{({ getFieldValue }) => {
								const hitCount = getFieldValue("hitCount");
								const indexesOfLevelsByHitCount = [
									[JACKPOT_LEVEL.GOLD],
									[JACKPOT_LEVEL.SILVER, JACKPOT_LEVEL.GOLD],
									[JACKPOT_LEVEL.BRONZE, JACKPOT_LEVEL.SILVER, JACKPOT_LEVEL.GOLD],
								];
								return (
									<Form.List name="levels" shouldUpdate={true}>
										{
											(fields) => {
												return (
													<Row gutter={16} wrap={false}>
														{
															fields.map((field, i, arr) => {
																const allFieldsExceptCurrent = arr.filter(f => f.name !== field.name);
																const level = antdForm.getFieldValue(["levels", field.name]);
																const existingLevelTypes = indexesOfLevelsByHitCount[hitCount - 1] ?? [];

																if (!existingLevelTypes.includes(level.levelType)) {
																	return (
																		<Fragment key={field.name} />
																	)
																}
																const levelList = antdForm.getFieldValue("levels");

																return (
																	<JackpotLevel
																		key={field.name}
																		field={field}
																		level={level}
																		levelList={levelList}
																		disabled={isModifyDisabled}
																		existingLevelTypes={existingLevelTypes}
																		allFieldsExceptCurrent={allFieldsExceptCurrent}
																	/>
																)
															})
														}
													</Row>
												)
											}
										}
									</Form.List>
								)
							}}
						</Form.Item>
					</div>
					<Row className='vs--mt-16'>
						<Form.Item
							name="startTime"
							label={`${t("pages.dashboard.bonuses.startDate")} *`}
							style={{ marginRight: "auto" }}
							rules={
								[
									{
										required: true,
										message: t("validation.field_required"),
									}
								]
							}
						>
							<DatePicker
								placeholder={t("pages.dashboard.bonuses.startDate")}
								format={DATE_TIME_FORMAT}
								showTime={{ format: TIME_FORMAT }}
								allowClear={true}
								disabled={isModifyDisabled}
								disabledDate={disabledDate}
								disabledTime={disabledDateTime}
								locale={{
									...locale,
									lang: {
										...locale.lang,
										ok: t('common.apply')
									}
								}}
							/>
						</Form.Item>
						<Form.Item className="button-container vs--mr-10">
							<Button
								type="default"
								htmlType="submit"
								className="button"
								onClick={handleCancelButtonClick}
								disabled={!isFormTouched}
							>
								<span>{t('common.cancel')}</span>
							</Button>
						</Form.Item>
						<Form.Item className="button-container vs--mr-10">
							<Button
								loading={isSaving}
								type="primary"
								htmlType="submit"
								className="button"
								onClick={handleSaveButtonClick}
								disabled={isModifyDisabled || !isFormTouched}
							>
								<span>{t('common.save')}</span>
							</Button>
						</Form.Item>
					</Row>

					{jackpotCopyId !== null && (
						<CopyDataPopup
							jackpotCopyId={jackpotCopyId}
							copyFields={Object.values(COPY_FIELDS)}
							onClose={() => setJackpotCopyId(null)}
							onCopy={handleCopyButtonClick}
						/>
					)}
				</div>
			</Form>
		</Spin>
	)
}

/** GeneralInfoComponent propTypes
 * PropTypes
*/
GeneralInfoComponent.propTypes = {
	/** Redux state property, is true when general info is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when general info is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the general info of current editing bonus  */
	generalInfo: bonusGeneralInfoType,
	/** Redux action to set the object of available currencies  */
	setAvailableJackpotBetshops: PropTypes.func,
	/** Redux action to save bonus General info */
	saveJackpotGeneralInfo: PropTypes.func,
	/** Redux action for getting available betshops */
	getAvailableJackpotBetshops: PropTypes.func,
	/** Available betshops */
	betshops: PropTypes.array,
	/** Function for to get available games by selected betshopsIds */
	getAvailableJackpotGames: PropTypes.func,
	/** Redux action to set the object of available games  */
	setAvailableJackpotGames: PropTypes.func,
	/** Available games selected by betshopsIds */
	games: PropTypes.array,
	/** Redux state property, represents the object of available currencies  */
	availableCurrencies: PropTypes.object,
	/** Redux action to get available currencies */
	getPartnerAvailableCurrencies: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
	{
		saveJackpotGeneralInfo: data => {
			dispatch(saveJackpotGeneralInfo(data));
		},
		getAvailableJackpotBetshops: (currency, jackpotId) => {
			dispatch(getAvailableJackpotBetshops(currency, jackpotId))
		},
		setAvailableJackpotBetshops: arg => {
			dispatch(setAvailableJackpotBetshops(arg))
		},
		getAvailableJackpotGames: (projectIds) => {
			dispatch(getAvailableJackpotGames(projectIds))
		},
		setAvailableJackpotGames: arg => {
			dispatch(setAvailableJackpotGames(arg))
		},
		getPartnerAvailableCurrencies: () => {
			dispatch(getPartnerAvailableCurrencies())
		}
	}
)

const mapStateToProps = state => {
	return {
		generalInfo: state.bonuses.jackpots.editingJackpot.generalInfo,
		isLoading: state.bonuses.jackpots.isLoading,
		isSaving: state.bonuses.isSaving,
		betshops: state.bonuses.jackpots.betshops,
		games: state.bonuses.jackpots.games,
		availableCurrencies: state.partner.currency.availableCurrencies
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent)
