import { useParams } from "react-router-dom";

import BetDBCacheCompare from "components/dashboard/common/betDBCacheCompare";

import { PROJECT_TYPE } from "constants/common.constants";
import { PERMISSION_RESOURCE } from "constants/permissions.constants";

/** Betshop Edit Page Activity Tab Cache Compare Component */
const DBCacheCompareComponent = () => {
	const routeParams = useParams();

	return <BetDBCacheCompare id={routeParams.id} projectType={PROJECT_TYPE.RETAIL} permission={PERMISSION_RESOURCE.BETSHOP_DBCACHECOMPARE} />;
};

export default DBCacheCompareComponent;
