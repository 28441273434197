import { Button, Col, Form, Row, Select, Spin } from "antd";
import PropTypes from "prop-types";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";
import { connect } from "react-redux";
import { RISK_MANAGEMENT_ACTION_TYPE_TRANSLATIONS, RISK_MANAGEMENT_ACTION_TYPES, RISK_MANAGEMENT_BET_ANOMALIES_TIME_PERIODS, RISK_MANAGEMENT_BET_ANOMALIES_TIME_PERIODS_TRANSLATIONS } from "constants/riskManagement.constants";
import { getBetAnomaliesConfigurationsData, saveBetAnomaliesConfigurationsData } from "store/actions/dashboard/riskManagment/betAnomalies/betAnomalies.actions";
import NumericInput from "components/common/numericInput";
import useUpdateEffect from "hooks/useUpdateEffect";
import { EMAIL_REGEX } from "constants/regex.constants";
import { MAX_INT_32_VALUE } from "constants/common.constants";
import SearchableSelect from "components/common/searchableSelect";

const { Item: FormItem } = Form;

const BetAnomaliesConfigurations = ({
	isSaving = false,
	isLoading = false,
	configurations,
	onTabChange = Function.prototype,
	getBetAnomaliesConfigurationsData = Function.prototype,
	saveBetAnomaliesConfigurationsData = Function.prototype
}) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BET_ANOMALIES_CONFIGURATIONS, action: PERMISSION_ACTION.MODIFY });

	const reset = () => {
		setFieldsValue({
			...configurations,
			emails: configurations.emails ?? []
		});
		setIsFormTouched(false);
	};


	const handleForm = () => {
		validateFields()
			.then((data) => {
				const emails = (
					data.actionType === RISK_MANAGEMENT_ACTION_TYPES.NONE
						? (data.emails?.filter((email) => EMAIL_REGEX.test(email)) ?? [])
						: data.emails
				);
				saveBetAnomaliesConfigurationsData({
					...data,
					minBetCount: Number(data.minBetCount) || 0,
					winAnomalyPercent: Number(data.winAnomalyPercent) || 0,
					emails: emails?.length > 0 ? emails : null
				})
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	/** Load partner retail configs */
	useEffect(() => {
		getBetAnomaliesConfigurationsData();
	}, []);

	useUpdateEffect(() => {
		reset()
	}, [configurations]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					minBetCount: "",
					winAnomalyPercent: "",
					actionType: "",
					timePeriod: RISK_MANAGEMENT_BET_ANOMALIES_TIME_PERIODS.LAST_HOUR,
					emails: []
				}}
				onValuesChange={(changed, formValues) => {
					if ("actionType" in changed) {
						validateFields(["emails"]);
					}
					setIsFormTouched(isFormChanged({ ...formValues }, { ...configurations }))
				}}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h4>{t("pages.dashboard.risk_management.bet_anomalies_configurations_configuration_details")}</h4>
								<Row gutter={[16, 0]}>
									<Col xs={24} sm={24} md={8} lg={8} xl={6}>
										<FormItem
											name="minBetCount"
											label={t("pages.dashboard.risk_management.bet_anomalies_configurations_configuration_detail_minBetCount")}
											rules={[
												{
													validator: (_, values) => {
														const num = Number(values) ?? 0
														if (num <= 0) {
															return Promise.reject(
																t("validation.must_be_more_and_not_equal_than_other")
																	.replace("%X%", t("pages.dashboard.risk_management.bet_anomalies_configurations_configuration_detail_minBetCount"))
																	.replace("%Y%", 0)
															)
														}
														return Promise.resolve()
													}
												},
												{
													validator: (_, values) => {
														const num = Number(values) ?? 0
														if (num > MAX_INT_32_VALUE) {
															return Promise.reject(
																t("validation.must_be_less")
																	.replace("%X%", MAX_INT_32_VALUE)
															)
														}
														return Promise.resolve()
													}
												}
											]}
										>
											<NumericInput
												disabled={isDisabled}
												placeholder={`${t("common.enter")} ${t("pages.dashboard.risk_management.bet_anomalies_configurations_configuration_detail_minBetCount")}`}
												isInteger={true}
											/>
										</FormItem>
									</Col>
									<Col xs={24} sm={24} md={8} lg={8} xl={6}>
										<FormItem
											name="winAnomalyPercent"
											label={t("pages.dashboard.risk_management.bet_anomalies_configurations_configuration_detail_winAnomalyPercent")}
											rules={[
												{
													validator: (_, values) => {
														const num = Number(values) ?? 0
														if (num < 0.1 || num > 100) {
															return Promise.reject(t("validation.must_be_between").replace("%X%", 0.1).replace("%Y%", 100));
														}
														return Promise.resolve()
													}
												}
											]}
										>
											<NumericInput
												disabled={isDisabled}
												placeholder={`${t("common.enter")} ${t("pages.dashboard.risk_management.bet_anomalies_configurations_configuration_detail_winAnomalyPercent")}`}
											/>
										</FormItem>
									</Col>
									<Col xs={24} sm={24} md={8} lg={8} xl={6}>
										<FormItem
											name="timePeriod"
											label={t("pages.dashboard.risk_management.bet_anomalies_configurations_configuration_detail_timePeriod")}
										>
											<Select
												suffixIcon={<i className="icon-down" />}
												options={Object.values(RISK_MANAGEMENT_BET_ANOMALIES_TIME_PERIODS).map(timePeriod => ({
													value: timePeriod,
													label: t(RISK_MANAGEMENT_BET_ANOMALIES_TIME_PERIODS_TRANSLATIONS[timePeriod])
												}))}
												disabled={true}
											/>
										</FormItem>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h4>{t("pages.dashboard.risk_management.bet_anomalies_configurations_actions")}</h4>
								<Row gutter={[16, 0]}>
									<Col xs={24} sm={24} md={8} lg={8} xl={6}>
										<FormItem
											name="actionType"
											label={t("pages.dashboard.risk_management.bet_anomalies_configurations_configuration_actions_actionType")}
										>
											<Select
												suffixIcon={<i className="icon-down" />}
												options={Object.values(RISK_MANAGEMENT_ACTION_TYPES).map(actionType => ({
													value: actionType,
													label: t(RISK_MANAGEMENT_ACTION_TYPE_TRANSLATIONS[actionType])
												}))}
												disabled={isDisabled}
											/>
										</FormItem>
									</Col>
									<FormItem
										dependencies={["actionType"]}
										noStyle
									>
										{
											({ getFieldValue }) => {
												const actionType = getFieldValue("actionType");
												return (
													<Col xs={24} sm={24} md={8} lg={8} xl={6}>
														<FormItem
															name="emails"
															label={t("pages.dashboard.risk_management.bet_anomalies_configurations_configuration_actions_emails")}
															rules={[
																{
																	validator: (_, values) => {
																		if (actionType === RISK_MANAGEMENT_ACTION_TYPES.NONE) {
																			return Promise.resolve();
																		}
																		if (!Array.isArray(values) || values.length === 0) {
																			return Promise.reject(t("validation.field_required"));
																		}
	
																		return Promise.resolve();
																	}
																},
																{
																	validator: (_, values) => {
																		if (Array.isArray(values) && values.length > 10) {
																			return Promise.reject(t("validation.must_be_less").replace("%X%", 10))
																		}
																		return Promise.resolve()
																	}
																},
																{
																	validator: (_, values) => {
																		if (actionType === RISK_MANAGEMENT_ACTION_TYPES.NONE) {
																			return Promise.resolve()
																		}
																		let invalidValue = null;
																		const invalidValueExists = values.some(value => {
																			if (!EMAIL_REGEX.test(value)) {
																				invalidValue = value;
																				return true
																			}
																			return false
																		})
																		if (invalidValueExists) {
																			return Promise.reject(`${invalidValue} ${t("validation.email_format")}`)
																		}
																		return Promise.resolve()
																	}
																}
															]}
														>
															<SearchableSelect
																disabled={isDisabled || actionType === RISK_MANAGEMENT_ACTION_TYPES.NONE}
																mode="tags"
																placeholder={t("pages.dashboard.risk_management.bet_anomalies_configurations_configuration_actions_emails")}
																items={configurations.emails ?? []}
																valueProp={(item) => item}
																textProp={(item) => item}
																renderText={(item) => item}
																getPopupContainer={() => document.body}
															/>
														</FormItem>
													</Col>
												)
											}
										}
									</FormItem>
								</Row>
							</Col>
						</Row>
					</div>
					<Row justify="end">
						{!isDisabled && (
							<FormItem className="button-container">
								<Button loading={isSaving} type="default" htmlType="submit" className="button" onClick={reset} disabled={!isFormTouched}>
									<span>{t("common.cancel")}</span>
								</Button>
							</FormItem>
						)}
						{!isDisabled && (
							<FormItem className="button-container">
								<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
									<span>{t("common.save")}</span>
								</Button>
							</FormItem>
						)}
					</Row>
				</div>
			</Form>
		</Spin>
	);
};

BetAnomaliesConfigurations.propTypes = {
	isSaving: PropTypes.bool,
	isLoading: PropTypes.bool,
	configurations: PropTypes.shape({
		minBetAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		minMultiplier: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		actionType: PropTypes.oneOf(Object.values(RISK_MANAGEMENT_ACTION_TYPES)),
		emails: PropTypes.arrayOf(PropTypes.string)
	}),
	onTabChange: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isSaving: state.riskManagement.betAnomalies.configurations.isSaving,
		isLoading: state.riskManagement.betAnomalies.configurations.isLoading,
		configurations: state.riskManagement.betAnomalies.configurations.data
	};
};

const mapDispatchToProps = (dispatch) => ({
	getBetAnomaliesConfigurationsData: () => {
		dispatch(getBetAnomaliesConfigurationsData());
	},
	saveBetAnomaliesConfigurationsData: (data) => {
		dispatch(saveBetAnomaliesConfigurationsData(data))
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BetAnomaliesConfigurations);
