import { Fragment } from "react";

import OperatorsComponent from "components/dashboard/developer/operators/operators.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const OperatorsRoute = () => {
	return (
		<Fragment>
			<OperatorsComponent />
		</Fragment>
	);
};

export default withPermission(OperatorsRoute, { resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
