import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import ApiUrls from "constants/api.constants";
import { PROJECT_TYPE } from "constants/common.constants";

import PendingBetsTableComponent from "components/dashboard/betslips/sections/common/pending/tableByBetSlip.component";
import Filters from "./filters.component";

import { setPlayerPendingBetsSorting, getPlayerPendingBets, cancelPlayerPendingBet, cancelPlayerPendingBetslip } from "store/actions/dashboard/online/players/betslips.action";

import betType from "types/bet/bet.type";
import sortingType from "types/common/sorting.type";

import { FILTER_SHOW_IN_CURRENCY } from 'constants/filter.constants';
import { PERMISSION_RESOURCE } from 'constants/permissions.constants';
import { GAME_CATEGORY } from 'constants/game.constants';

/** Player View Page BetHistory tab Pending betslips SubTab Page Component */
const PlayerPendingBetsComponent = ({ bets, sorting, filters, total, isLoading, getPlayerPendingBets, cancelPlayerPendingBet, cancelPlayerPendingBetslip, setPlayerPendingBetsSorting }) => {
	const routeParams = useParams();

	return (
		<PendingBetsTableComponent
			bets={bets}
			sorting={sorting}
			filters={{ ...filters, showIn: FILTER_SHOW_IN_CURRENCY.ORIGINAL }}
			total={total}
			isLoading={isLoading}
			projectType={PROJECT_TYPE.ONLINE}
			getPendingBets={() => getPlayerPendingBets(routeParams.id)}
			cancelPendingBet={(id, betId, reason) => cancelPlayerPendingBet(id, betId, reason, routeParams.id)}
			cancelPendingBetslip={(id, reason) => cancelPlayerPendingBetslip(id, reason, routeParams.id)}
			setPendingBetsSorting={setPlayerPendingBetsSorting}
			exportURL={ApiUrls.EXPORT_PLAYERS_BET_HISTORY + "?projectType=" + PROJECT_TYPE.ONLINE + "&userNameOrId=" + routeParams.id + "&isPending=" + true}
			filtersComponent={<Filters />}
			permission={PERMISSION_RESOURCE.PLAYER_BET_HISTORY}
			gameCategory={GAME_CATEGORY.SCHEDULED}
		/>
	);
};

/** PlayerPendingBetsComponent propTypes
 * PropTypes
 */
PlayerPendingBetsComponent.propTypes = {
	/** Redux state property, represents the array of player pending bets */
	bets: PropTypes.arrayOf(betType),
	/** Redux state property, player pending bets sorting details */
	sorting: sortingType,
	/** Redux state property, player pending bets filters */
	filters: PropTypes.object,
	/** Redux state property, player pending bets total count */
	total: PropTypes.number,
	/** Redux state property, is true when loading pending bets */
	isLoading: PropTypes.bool,
	/** Redux action to get player pending bets */
	getPlayerPendingBets: PropTypes.func,
	/** Redux action to cancel player pending bet */
	cancelPlayerPendingBet: PropTypes.func,
	/** Redux action to cancel player pending bet slip */
	cancelPlayerPendingBetslip: PropTypes.func,
	/** Redux action to set player pending bets sorting details */
	setPlayerPendingBetsSorting: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		bets: state.players.viewingPlayer.betHistory.pendings.bets,
		total: state.players.viewingPlayer.betHistory.pendings.total,
		sorting: state.players.viewingPlayer.betHistory.pendings.sorting,
		filters: state.players.viewingPlayer.betHistory.pendings.filters,
		isLoading: state.players.isLoading
	};
};

const mapDispatchToProps = (dispatch) => ({
	getPlayerPendingBets: (id) => {
		dispatch(getPlayerPendingBets(id));
	},
	cancelPlayerPendingBet: (id, betId, reason, playerId) => {
		dispatch(cancelPlayerPendingBet(id, betId, reason, playerId));
	},
	cancelPlayerPendingBetslip: (id, reason, playerId) => {
		dispatch(cancelPlayerPendingBetslip(id, reason, playerId));
	},
	setPlayerPendingBetsSorting: (sorting) => {
		dispatch(setPlayerPendingBetsSorting(sorting));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerPendingBetsComponent);
