import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { CHANGE_PASSWORD_ACTION_BEFORE, CHANGE_PASSWORD_ACTION_FINISH } from "../../../actionTypes";

const setChangePasswordActionBefore = () => ({
	type: CHANGE_PASSWORD_ACTION_BEFORE
});

const setChangePasswordActionFinished = () => ({
	type: CHANGE_PASSWORD_ACTION_FINISH
});

export const changePassword = (data, onSuccess) => {
	return (dispatch) => {
		dispatch(setChangePasswordActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CHANGE_PASSWORD}`,
			method: Methods.POST,
			data: {
				...data
			}
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					onSuccess && onSuccess();
				}
				dispatch(setChangePasswordActionFinished());
			})
			.catch((ex) => {
				dispatch(setChangePasswordActionFinished());
			});
	};
};
