import { useState } from "react";
import PropTypes from "prop-types";
import { ChromePicker } from "react-color";
import OutsideAlerter from "components/common/outsideAlerter";

/** Customization Tool Color Component */
const Color = ({ color, onChange, generateAll, isDisabled = false }) => {
	const [openedPicker, setOpenedPicker] = useState(false);

	return (
		<div className="color-picker">
			<div className={"color-picker-button" + (generateAll ? " color-picker-button-radial" : "") + (isDisabled ? " color-picker-button-disable" : "")} onClick={() => !isDisabled && setOpenedPicker(!openedPicker)}>
				<div className="color" style={{ background: color }}>
					{generateAll && <i className="icon-template" />}
				</div>
			</div>
			{!isDisabled && openedPicker ? (
				<OutsideAlerter callback={() => openedPicker && setOpenedPicker(false)}>
					<div className="color-picker-popover">
						<ChromePicker disableAlpha={true} color={color} onChange={(c) => onChange(c.hex)} />
					</div>
				</OutsideAlerter>
			) : null}
		</div>
	);
};

/** Color propTypes
 * PropTypes
 */
Color.propTypes = {
	/** The value */
	color: PropTypes.string,
	/** Function which fires on color change */
	onChange: PropTypes.func,
	/** If true will show radial button with arrow */
	generateAll: PropTypes.bool,
	/** React property is disable */
	isDisabled: PropTypes.bool
};

export default Color;
