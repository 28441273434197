import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

import Table from "components/common/table";

import ExportButton from "components/common/exportButton";
import Filters from "./filters.component";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { getRequests, setRequestsSorting, setRequestsFilters, approveRequest, declineRequest } from "store/actions/dashboard/betslips/requests.action";
import { setGlobalPartnerId } from "store/actions/dashboard/partners/partner.action";

import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import ApiUrls from "constants/api.constants";
import { BETSLIP_MODES, PRINT_REQUEST_STATE } from "constants/bet.constants";
import { FILTER_PRINT_TYPE } from "constants/filter.constants";

import requestType from "types/bet/request.type";
import sortingType from "types/common/sorting.type";

/** Requests Tab Component */
const RequestsComponent = ({ getRequests, setRequestsSorting, setRequestsFilters, requests, isLoading, sorting, filters, total, approveRequest, declineRequest, globalPartnerId, setGlobalPartnerId }) => {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();

	const [filtersResetActive, setFiltersResetActive] = useState(false);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.bets.request_type"),
			dataIndex: "requestType",
			render: () => t("pages.dashboard.bets.reprint"),
			sorter: false
		},
		{
			title: t("pages.dashboard.bets.ticket_type"),
			dataIndex: "printType",
			render: (value) => t(`pages.dashboard.bets.ticket_type_${value}`)
		},
		{
			title: t("pages.dashboard.bets.ticket_id"),
			dataIndex: "betSlipId"
		},
		{
			title: t("pages.dashboard.bets.date"),
			dataIndex: "requestTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.bets.bet_type"),
			dataIndex: "betType",
			render: (value) => t(`pages.dashboard.bets.${value === BETSLIP_MODES.SINGLE ? "single" : value === BETSLIP_MODES.MULTI ? "multi" : ""}`)
		},
		{
			title: t("pages.dashboard.bets.status"),
			dataIndex: "state",
			render: (value) => t(`pages.dashboard.bets.print_request_type_${value}`)
		},
		{
			title: t("pages.dashboard.bets.betshop"),
			dataIndex: "projectName"
		},
		{
			title: t("pages.dashboard.bets.cashier"),
			dataIndex: "userName"
		},
		{
			title: t("pages.dashboard.bets.currency"),
			dataIndex: "currencyId"
		},
		{
			title: t("pages.dashboard.bets.stake"),
			dataIndex: "amount"
		},
		{
			title: t("pages.dashboard.bets.possible_win"),
			dataIndex: "possibleWin"
		},
		{
			title: t("pages.dashboard.bets.win"),
			dataIndex: "winning"
		},
		{
			title: t("pages.dashboard.bets.calculated_at"),
			dataIndex: "betTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT),
			sorter: false
		}
	];

	useEffect(() => {
		const partnerId = searchParams.get("partnerId");
		if (partnerId) {
			setGlobalPartnerId(partnerId);
		}

		const betslipId = searchParams.get("betSlipId") || "";
		let ticketType = searchParams.get("type");
		ticketType = ticketType === "Bet" ? FILTER_PRINT_TYPE.BET : ticketType === "Cancel" ? FILTER_PRINT_TYPE.CANCEL : ticketType === "Payout" ? FILTER_PRINT_TYPE.PAYOUT : "";
		if (betslipId && ticketType) {
			setFiltersResetActive(false);
			setRequestsFilters({
				...filters,
				betSlipId: betslipId ? Number(betslipId) : "",
				ticketType: ticketType
			});
			setTimeout(() => {
				setFiltersResetActive(true);
			}, 0);
		}
	}, [searchParams]);

	return (
		<div className="dashboard-section-content">
			<div className="table-header">
				<div className="table-buttons-dropdowns">
					{hasPermission({ resource: PERMISSION_RESOURCE.BETSLIPS_RETAIL, action: PERMISSION_ACTION.EXPORT }) ? (
						<ExportButton columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t("pages.dashboard.bets.tabs.requests")} url={ApiUrls.EXPORT_USERS_REPRINT_REQUEST} filters={filters} sorting={sorting} />
					) : null}
				</div>
				<Filters activeReset={filtersResetActive} />
			</div>
			<Table
				loading={isLoading}
				columns={columns}
				data={requests}
				loadFn={(fromFirstPage) => getRequests(fromFirstPage, searchParams.get("betSlipId") || "", searchParams.get("type") || "")}
				sorting={sorting}
				setSortingFn={setRequestsSorting}
				total={total}
				updateProps={[globalPartnerId, searchParams]}
				isDisabled={() => false}
				actions={{
					decline: {
						messageKey: "reprint_request_cancel",
						handler: (record) => declineRequest(record.id),
						title: t("pages.dashboard.permissions_requests.reject"),
						hidden: (record) => record.state !== PRINT_REQUEST_STATE.PENDING,
						icon: <i className="icon-cancel" />,
						type: "delete"
					},
					approve: {
						messageKey: "reprint_request_approve",
						handler: (record) => approveRequest(record.id),
						title: t("pages.dashboard.permissions_requests.approve"),
						hidden: (record) => record.state !== PRINT_REQUEST_STATE.PENDING,
						icon: <i className="icon-success" />,
						type: "delete"
					}
				}}
			/>
		</div>
	);
};

/** RequestsComponent propTypes
 * PropTypes
 */
RequestsComponent.propTypes = {
	/** Redux action to get requests */
	getRequests: PropTypes.func,
	/** Redux state property , represents the array of requests */
	requests: PropTypes.arrayOf(requestType),
	/** Redux state property, is true when loading requests */
	isLoading: PropTypes.bool,
	/** Redux state property, requests total count */
	total: PropTypes.number,
	/** Redux action to set requests sorting details */
	setRequestsSorting: PropTypes.func,
	/** Redux action to set requests filters */
	setRequestsFilters: PropTypes.func,
	/** Redux state property, requests sorting details */
	sorting: sortingType,
	/** Redux state property, requests filters */
	filters: PropTypes.object,
	/** Redux action to decline request */
	declineRequest: PropTypes.func,
	/** Redux action to approve request */
	approveRequest: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux action to set global partner id */
	setGlobalPartnerId: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.betslips.isLoading,
		requests: state.betslips.requests.requests,
		total: state.betslips.requests.total,
		sorting: state.betslips.requests.sorting,
		filters: state.betslips.requests.filters,
		globalPartnerId: state.partner.globalPartnerId
	};
};

const mapDispatchToProps = (dispatch) => ({
	getRequests: (fromFirstPage, betSlipId, type) => {
		dispatch(getRequests(fromFirstPage, betSlipId, type));
	},
	setRequestsSorting: (sorting) => {
		dispatch(setRequestsSorting(sorting));
	},
	setRequestsFilters: (filters) => {
		dispatch(setRequestsFilters(filters));
	},
	declineRequest: (id) => {
		dispatch(declineRequest(id));
	},
	approveRequest: (id) => {
		dispatch(approveRequest(id));
	},
	setGlobalPartnerId: (partnerId) => {
		dispatch(setGlobalPartnerId(partnerId));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestsComponent);
