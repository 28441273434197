import PropTypes from "prop-types";
import { connect } from "react-redux";
import CurrencyEditComponent from "./currency-edit.component";
import CurrenciesComponent from "components/dashboard/common/currencies";
import currencyType from "types/currency/currency.type";

import { getApiKeyCurrencies, updateApiKeyCurrency, deleteApiKeyCurrency } from "store/actions/dashboard/online/apikeys/currencies.action";

import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

/** Api Key Edit Page Currecies Tab Component */
const ApikeyCurrenciesComponent = ({ currencies, getApiKeyCurrencies, isLoading, updateApiKeyCurrency, deleteApiKeyCurrency }) => {
	return (
		<CurrenciesComponent
			isLoading={isLoading}
			updateCurrency={updateApiKeyCurrency}
			deleteCurrency={deleteApiKeyCurrency}
			getCurrencies={getApiKeyCurrencies}
			currencies={currencies}
			CurrencyEditComponent={CurrencyEditComponent}
			entity="project"
			editable={hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_CURRENCIES, action: PERMISSION_ACTION.MODIFY })}
		/>
	);
};

/** ApikeyCurrenciesComponent propTypes
 * PropTypes
 */
ApikeyCurrenciesComponent.propTypes = {
	/** Redux action to get api key currencies */
	getApiKeyCurrencies: PropTypes.func,
	/** Redux state property, represents the array of api key currencies  */
	currencies: PropTypes.arrayOf(currencyType),
	/** Redux state property, is true when loading api key currencies */
	isLoading: PropTypes.bool,
	/** Redux action to update api key currency */
	updateApiKeyCurrency: PropTypes.func,
	/** Redux action to reset api key currency to partner currency */
	deleteApiKeyCurrency: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getApiKeyCurrencies: (id) => {
		dispatch(getApiKeyCurrencies(id));
	},
	updateApiKeyCurrency: (currency) => {
		dispatch(updateApiKeyCurrency(currency));
	},
	deleteApiKeyCurrency: (id, code) => {
		dispatch(deleteApiKeyCurrency(id, code));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.apikeys.editingApikey.currencies.isLoading,
		currencies: state.apikeys.editingApikey.currencies.currencies
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ApikeyCurrenciesComponent);
