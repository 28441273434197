import axios from "axios";
import { isAuthenticated, getUser, logout, refreshToken, startWatchingRefreshTokenExpiration } from "utils/auth";

import ApiUrls from "constants/api.constants";

import { RESPONSE_STATE } from "constants/response.constants";

import LanguageUtils from "utils/languages";
import { showError } from "utils/message";
import { setCookie, getCookie } from "utils/common";

import store from "store/configureStore";

axios.defaults.withCredentials = true;

const COOKIE_NAME = "_ga_anl_cf3q";

const checkPathAndUpdateCookies = (data, responseInfo) => {
	if (getCookie(COOKIE_NAME)) {
		return;
	}
	if (data?.status !== RESPONSE_STATE.SUCCESS) {
		return;
	}
	const responseURL = responseInfo?.request?.responseURL ?? "";
	if (!responseURL) {
		return;
	}
	const url = new URL(responseURL);
	if (![ApiUrls.AUTHENTICATE].some((path) => url.pathname.includes(path))) {
		return;
	}
	const token = data?.token;
	if (!token) {
		return;
	}
	setCookie(COOKIE_NAME, token, 365);
};

// const DOSRedirect = () => {

// 	const redirectUrl = new URL(import.meta.env.SYSTEM_API_URL);
// 	redirectUrl.pathname = Paths.CHALLENGE_PROCESSING;
// 	redirectUrl.search = "url=" + window.location.href;

// 	return window.location.replace(redirectUrl);
// }

axios.interceptors.request.use(
	(config) => {
		if (config.method === "get") {
			config.url = config.url.indexOf("?") > -1 ? config.url + "&timestump=" + Date.now() : config.url + "?timestump=" + Date.now();
		}

		// set current language
		const currentLang = LanguageUtils.getSelectedLanguage();
		if (currentLang) {
			config.headers["Accept-Language"] = currentLang;
		}

		if (isAuthenticated()) {
			if (config?.headers?.grant_type !== "refresh_token") {
				const user = getUser();
				const token = user?.token;
				if (token) {
					config.headers["Authorization"] = "Bearer " + token;
				}

				//Set default content type
				if (!config.headers["Content-Type"] || config.headers["Content-Type"] === "text/plain;charset=utf-8") {
					config.headers["Content-Type"] = "application/json;charset=utf-8";
				}

				const partnerId = config?.params?.SelectedPartnerId || store.getState().partner.globalPartnerId;

				if (config?.params?.SelectedPartnerId) delete config.params.SelectedPartnerId;

				if (partnerId) {
					if (partnerId !== "none") {
						config.headers["SelectedPartnerId"] = partnerId;
					} else if (store.getState().partner.globalPartnerId) {
						config.headers["SelectedPartnerId"] = store.getState().partner.globalPartnerId;
					}
				}
			} else {
				delete config.headers["grant_type"];
			}

			// Trim params and data

			if (config.params) {
				const newParams = {};
				Object.keys(config.params).forEach((key) => {
					if (typeof config.params[key] === "string") {
						newParams[key] = config.params[key].trim();
						if (newParams[key] === "") {
							newParams[key] = null;
						}
					} else {
						newParams[key] = config.params[key];
					}
				});
			}

			if (config.data && !(config.data instanceof FormData)) {
				try {
					const isArray = Array.isArray(config.data);
					const newData = isArray ? [] : {};
					let parsed = config.data;
					if (typeof config.data === "string") {
						try {
							parsed = JSON.parse(config.data);
						} catch (ex) {
							console.log("err");
						}
					}

					if (isArray) {
						parsed.forEach((arg, i) => {
							if (typeof arg === "string") {
								newData.push(arg.trim());
								if (newData[i] === "") {
									newData[i] = null;
								}
								return;
							}
							newData.push(arg);
						});
					} else {
						Object.keys(parsed).forEach((key) => {
							if (typeof parsed[key] === "string") {
								newData[key] = parsed[key].trim();
								if (newData[key] === "") {
									newData[key] = null;
								}
							} else {
								newData[key] = parsed[key];
							}
						});
					}
					config.data = newData;
				} catch (ex) {
					console.log(ex);
				}
			}
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	({ status, data, config, ...other }) => {
		checkPathAndUpdateCookies(data, other);

		if (data?.status !== RESPONSE_STATE.SUCCESS && !(data instanceof Blob) && !config.url.includes("/System/")) {
			showError(data?.message ?? "", data?.status ?? "", data?.resource ?? "");
		}
		startWatchingRefreshTokenExpiration();
		return { status, data };
	},
	(error) => {
		// if (!error.response) {
		// 	return DOSRedirect();
		// }
		let status = error.response.status;
		let errorData = error.response.data;

		const isBlobResponseType = error.request.responseType === "blob" && error.response.data instanceof Blob && error.response.data.type && error.response.data.type.toLowerCase().indexOf("json") != -1;

		startWatchingRefreshTokenExpiration();
		if (status >= 400) {
			if (errorData?.status === RESPONSE_STATE.UNAUTHORIZED || isBlobResponseType) {
				if (status === 401) {
					const user = getUser();
					const refresh_token = user?.refreshToken ?? null;
					const userName = user?.userName ?? null;
					if (refresh_token) {
						const config = error.response.config;
						return refreshToken(refresh_token, userName, config);
					} else {
						logout();
					}
				} else if (status === 400) {
					logout();
				}
			} else {
				showError(errorData?.message ?? "", errorData?.status ?? "", errorData?.resource ?? "");
			}
		}
		return Promise.reject(error);
	}
);
