import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Row, Col, Spin, Switch } from "antd";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { getMaintenanceMode, saveMaintenanceMode } from "store/actions/dashboard/settings/maintenanceMode/maintenanceMode.action";

import Question from "components/common/question";
import Breadcrumbs from "components/common/breadcrumbs";
import MaintenanceModeSaveComponent from "./maintenanceMode-save.component";

import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { MAINTENANCE_MODE_DURATION_TYPE, MAINTENANCE_MODE_CHANGE_REASON } from "constants/settings.constants";

import maintenanceModeType from "types/maintenanceMode/maintenanceMode.type";
import { buildPathToStaticFolderOfCDN } from "utils/common";

/** Maintenance Mode Page Component */

const MaintenanceModeComponent = ({ getMaintenanceMode, saveMaintenanceMode, maintenanceMode, isLoading }) => {
	const { t } = useTranslation();

	const [savePopup, setSavePopup] = useState(false);
	const [showDisableConfirmation, setShowDisableConfirmation] = useState(false);

	/** Load maintenance mode data */
	useEffect(() => {
		getMaintenanceMode();
	}, []);

	/** Fires on switcher click
	 * @function
	 * @memberOf MaintenanceModeComponent
	 */
	const handleSwitcherClick = () => {
		if (!maintenanceMode.maintenanceMode) {
			setSavePopup(true);
		} else {
			setShowDisableConfirmation(true);
		}
	};

	/** Fires on confirmation swithcing off
	 * @function
	 * @memberOf MaintenanceModeComponent
	 */
	const handleMaintenanceModeOff = () => {
		saveMaintenanceMode({
			maintenanceMode: false,
			reason: MAINTENANCE_MODE_CHANGE_REASON.OFF,
			durationType: MAINTENANCE_MODE_DURATION_TYPE.NONE
		});
		setShowDisableConfirmation(false);
	};

	return (
		<div className="dashboard-section">
			<Breadcrumbs items={[{ title: t("pages.dashboard.menu.maintenance_mode") }]} />
			<Spin spinning={isLoading} wrapperClassName="form-spin">
				<div className="dashboard-section-content">
					<div className="dashboard-maintenance-mode-card">
						<div className="dashboard-maintenance-mode-card-head">
							<img src={buildPathToStaticFolderOfCDN("admin-images/maintenance-mode.svg")} alt="Maintenance Mode" />
							<div className="dashboard-maintenance-mode-card-head-title">
								<h4>{t("pages.dashboard.menu.maintenance_mode")}</h4>
								<span>
									{t("pages.dashboard.maintenance_mode.last_update")}:{" "}
									<b>
										{maintenanceMode.lastUpdatedDate
											? moment
													.utc(maintenanceMode.lastUpdatedDate)
													.local()
													.format(DATE_FORMAT + " " + TIME_FORMAT)
											: "-"}
									</b>
								</span>
							</div>
							<div className="dashboard-maintenance-mode-card-head-action">
								<div className="dashboard-maintenance-mode-card-head-action-switcher">
									<Switch checked={maintenanceMode.maintenanceMode} onClick={handleSwitcherClick} disabled={!hasPermission({ resource: PERMISSION_RESOURCE.SETTINGS_MAINTENANCE_MODE, action: PERMISSION_ACTION.MODIFY })} />
								</div>
								{maintenanceMode.durationType !== MAINTENANCE_MODE_DURATION_TYPE.NONE && (
									<span>
										{t("pages.dashboard.maintenance_mode.duration")}: <b>{maintenanceMode.durationType === MAINTENANCE_MODE_DURATION_TYPE.PERMANENT ? t("pages.dashboard.maintenance_mode.permanent") : t("pages.dashboard.maintenance_mode.temporary")}</b>
									</span>
								)}
							</div>
						</div>

						<div className="dashboard-maintenance-mode-card-content">
							<div className="dashboard-maintenance-mode-card-content-info">
								<div className="dashboard-maintenance-mode-card-content-info-item">
									<b>{t("pages.dashboard.maintenance_mode.last_update_by")}</b>
									<span>{maintenanceMode.lastUpdatedBy || " - "}</span>
								</div>
								<div className="dashboard-maintenance-mode-card-content-info-item">
									<b>{t("pages.dashboard.maintenance_mode.start_date")}</b>
									<span>
										{maintenanceMode.startDate
											? moment
													.utc(maintenanceMode.startDate)
													.local()
													.format(DATE_FORMAT + " " + TIME_FORMAT)
											: " - "}
									</span>
								</div>
								<div className="dashboard-maintenance-mode-card-content-info-item">
									<b>{t("pages.dashboard.maintenance_mode.end_date")}</b>
									<span>
										{maintenanceMode.endDate
											? moment
													.utc(maintenanceMode.endDate)
													.local()
													.format(DATE_FORMAT + " " + TIME_FORMAT)
											: " - "}
									</span>
								</div>
							</div>
							{maintenanceMode.reason && maintenanceMode.reason !== MAINTENANCE_MODE_CHANGE_REASON.OFF && (
								<div className="dashboard-maintenance-mode-card-content-info">
									<div className="dashboard-maintenance-mode-card-content-info-item">
										<b>{t("pages.dashboard.maintenance_mode.reason")}</b>
										<span>{t("pages.dashboard.maintenance_mode.reason_" + maintenanceMode.reason)}</span>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</Spin>

			{savePopup && <MaintenanceModeSaveComponent onClose={() => setSavePopup(false)} />}

			{showDisableConfirmation && <Question type="confirm" onOk={handleMaintenanceModeOff} onCancel={() => setShowDisableConfirmation(false)} isVisible={true} message={t("pages.dashboard.maintenance_mode.off_confirmation_message")} />}
		</div>
	);
};

/** MaintenanceModeComponent propTypes
 * PropTypes
 */
MaintenanceModeComponent.propTypes = {
	/** Redux action to get maintenance mode data */
	getMaintenanceMode: PropTypes.func,
	/** Redux action to save maintenance mode */
	saveMaintenanceMode: PropTypes.func,
	/** Redux state property, represents the data of maintenance mode */
	maintenanceMode: maintenanceModeType,
	/** Redux state property, is true when loading maintenance mode */
	isLoading: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	getMaintenanceMode: () => {
		dispatch(getMaintenanceMode());
	},

	saveMaintenanceMode: (data) => {
		dispatch(saveMaintenanceMode(data));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.maintenanceMode.isLoading,
		maintenanceMode: state.maintenanceMode.maintenanceMode
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceModeComponent);
