import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getPermissionRequestHistory, setPermissionRequestHistorySorting, setPermissionRequestHistoryFilters } from "store/actions/dashboard/userManagement/requests/history.action";
import RequestsCommonTable from "./requestsCommonTable";
import { expandColumns, getTableColumns } from "./columns";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { PERMISSION_REQUEST_STATUS } from "constants/permissionRequest.constants";
import Filters from "./filters.component";
import permissionRequestType from "types/permissionRequest/request.type";
import sortingType from "types/common/sorting.type";

const RequestsHistory = ({ getPermissionRequestHistory, setPermissionRequestHistorySorting, setPermissionRequestHistoryFilters, globalPartnerId, isLoading, isSaving, history, total, sorting, filters, hash }) => {
	const { t } = useTranslation();

	return (
		<div className="dashboard-section-content">
			<div className="table-header">
				<div className="table-buttons-dropdowns" />
				<Filters />
			</div>
			<RequestsCommonTable
				isLoading={isLoading}
				columns={getTableColumns(true)}
				loadFn={getPermissionRequestHistory}
				data={history}
				sorting={sorting}
				filters={filters}
				setSortingFn={setPermissionRequestHistorySorting}
				setFiltersFn={setPermissionRequestHistoryFilters}
				total={total}
				updateProps={[globalPartnerId, isSaving, hash]}
				isDisabledFn={() => false}
				uniqueKey="key"
				noPagination={false}
				detailsFn={(record) => {
					return {
						columns: expandColumns,
						data: record.changes.map((change, index) => ({ index: index + 1, change })),
						uniqueKey: "index"
					};
				}}
			/>
		</div>
	);
};

RequestsHistory.propTypes = {
	/** Redux action to get permission request history */
	getPermissionRequestHistory: PropTypes.func,
	/** Redux state property, represents the array of permission request history  */
	history: PropTypes.arrayOf(permissionRequestType),
	/** Redux state property, is true when loading permission request history */
	isLoading: PropTypes.bool,
	/** Redux state property, is true when saving permission request */
	isSaving: PropTypes.bool,
	/** Redux state property, permission request history total count */
	total: PropTypes.number,
	/** Redux action to set permission request history sorting details */
	setPermissionRequestHistorySorting: PropTypes.func,
	/** Redux action to set permission request history filters */
	setPermissionRequestHistoryFilters: PropTypes.func,
	/** Redux state property, permission request history sorting */
	sorting: sortingType,
	/** Redux state property, permission request history filters */
	filters: PropTypes.object,
	/** Redux state property, represents global parner id */
	globalPartnerId: PropTypes.string,
	/** React property, url hash value  */
	hash: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		globalPartnerId: state.partner.globalPartnerId,
		isLoading: state.permissionRequests.isLoading,
		isSaving: state.permissionRequests.isSaving,
		history: state.permissionRequests.history.history,
		total: state.permissionRequests.history.total,
		sorting: state.permissionRequests.history.sorting,
		filters: state.permissionRequests.history.filters
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getPermissionRequestHistory: (userId, actionType, objectType) => {
			dispatch(getPermissionRequestHistory(userId, actionType, objectType));
		},
		setPermissionRequestHistorySorting: (sorting) => {
			dispatch(setPermissionRequestHistorySorting(sorting));
		},
		setPermissionRequestHistoryFilters: (filters) => {
			dispatch(setPermissionRequestHistoryFilters(filters));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestsHistory);
