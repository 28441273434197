import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import {
	CASHIERS_ACTION_BEFORE,
	CASHIERS_ACTION_FINISH,
	CASHIERS_ACTION_SET_CASHIERS,
	CASHIERS_SAVE_ACTION_BEFORE,
	CASHIERS_SAVE_ACTION_FINISH,
	CASHIERS_SET_SORTING,
	CASHIERS_SET_FILTERS,
	CHANGE_CASHIER_STATE,
	CASHIERS_AVAILABLE_BETSHOPS_BEFORE,
	CASHIERS_AVAILABLE_BETSHOPS_FINISH,
	CASHIERS_SET_AVAILABLE_BETSHOPS
} from "store/actionTypes";

export const setCashiersActionBefore = () => ({
	type: CASHIERS_ACTION_BEFORE
});

export const setCashiersActionFinished = () => ({
	type: CASHIERS_ACTION_FINISH
});

export const setCashierSaveActionBefore = () => ({
	type: CASHIERS_SAVE_ACTION_BEFORE
});

export const setCashierSaveActionFinished = () => ({
	type: CASHIERS_SAVE_ACTION_FINISH
});

const setCashiers = (data) => ({
	type: CASHIERS_ACTION_SET_CASHIERS,
	payload: { data }
});

const setChangeCashierState = (data) => ({
	type: CHANGE_CASHIER_STATE,
	payload: { data }
});

export const setCashiersSorting = (sorting) => ({
	type: CASHIERS_SET_SORTING,
	payload: { sorting }
});

export const setCashiersFilters = (filters) => ({
	type: CASHIERS_SET_FILTERS,
	payload: { filters }
});

const setAvailableBetshopsBefore = () => ({
	type: CASHIERS_AVAILABLE_BETSHOPS_BEFORE
});

const setAvailableBetshopsFinished = () => ({
	type: CASHIERS_AVAILABLE_BETSHOPS_FINISH
});

const setAvailableBetshops = (betshops) => ({
	type: CASHIERS_SET_AVAILABLE_BETSHOPS,
	payload: { betshops }
});

export const getCashiers = (fromFirstPage) => {
	return (dispatch, getState) => {
		dispatch(setCashiersActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_CASHIERS}`,
			method: Methods.GET,
			params: {
				...getState().cashiers.sorting,
				...getState().cashiers.filters,
				page: fromFirstPage ? 1 : getState().cashiers.sorting.page
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setCashiers(data));
				if (fromFirstPage) {
					dispatch(setCashiersSorting({ ...getState().cashiers.sorting, page: 1 }));
				}
				dispatch(setCashiersActionFinished());
			})
			.catch(() => {
				dispatch(setCashiersActionFinished());
			});
	};
};

export const createCashier = (cashier, onSuccess) => {
	return (dispatch) => {
		dispatch(setCashierSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CREATE_CASHIER}`,
			method: Methods.POST,
			data: cashier
		})
			.then(({ data: { value: cashier } }) => {
				dispatch(setCashierSaveActionFinished());
				if (typeof onSuccess === "function") {
					onSuccess(cashier);
				}
			})
			.catch(() => {
				dispatch(setCashierSaveActionFinished());
			});
	};
};

export const changeCashierState = (data) => {
	return (dispatch) => {
		dispatch(setCashierSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CHANGE_CASHIER_STATE}`,
			method: Methods.POST,
			data: data
		})
			.then(({ data: { value: data } }) => {
				setTimeout(() => {
					dispatch(setChangeCashierState(data));
				}, 200);
				dispatch(setCashierSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setCashierSaveActionFinished());
			});
	};
};

export const getAvailableBetshops = (params = {}) => {
	return (dispatch) => {
		dispatch(setAvailableBetshopsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CASHIER_GET_AVAILABLE_BETSHOPS}`,
			method: Methods.GET,
			params: { ...params }
		})
			.then(({ data: { value: betshops } }) => {
				dispatch(setAvailableBetshops(betshops));
				dispatch(setAvailableBetshopsFinished());
			})
			.catch((ex) => {
				dispatch(setAvailableBetshopsFinished());
			});
	};
};

export const forceLogout = () => {
	return (dispatch, getState) => {
		dispatch(setCashierSaveActionBefore());

		const filters = { ...getState().cashiers.filters };
		Object.keys(filters).forEach((k) => {
			if (filters[k] === "" || filters[k] === undefined) {
				delete filters[k];
			}
		});

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CASHIER_FORCE_LOGOUT}`,
			method: Methods.POST,
			data: {
				...getState().cashiers.sorting,
				...filters,
				page: getState().cashiers.sorting.page
			}
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(getCashiers());
				}
				dispatch(setCashierSaveActionFinished());
			})
			.catch(() => {
				dispatch(setCashierSaveActionFinished());
			});
	};
};

export const forceLogoutSingleCashier = (id) => {
	return (dispatch, getState) => {
		dispatch(setCashierSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CASHIER_FORCE_LOGOUT}`,
			method: Methods.POST,
			data: {
				...getState().cashiers.sorting,
				userNameOrId: id,
				page: 1
			}
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(getCashiers());
				}
				dispatch(setCashierSaveActionFinished());
			})
			.catch(() => {
				dispatch(setCashierSaveActionFinished());
			});
	};
};

export const resendRegistrationEmail = (ids) => {
	return (dispatch, getState) => {
		dispatch(setCashierSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CASHIER_RESEND_REGISTRATION_EMAIL}`,
			method: Methods.POST,
			data: { ids }
		})
			.then(({ data: { status, message: msg } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
				}
				dispatch(setCashierSaveActionFinished());
			})
			.catch(() => {
				dispatch(setCashierSaveActionFinished());
			});
	};
};
