import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Breadcrumbs from 'components/common/breadcrumbs';
import GeneralComponent from '../edit/sections/general';
import JackpotHistory from '../edit/sections/history';

import { getJackpotGeneralInfo } from 'store/actions/dashboard/bonuses/generalInfo.action';
import Paths from 'constants/path.constants';
import { JACKPOT_EDIT_HASHES, JACKPOT_HISTORY_TABS } from 'constants/bonus.constants';
import { updateLocationHash, getHashValue } from 'utils/common';

/** Bonus Edit Page Component */
const JackpotEditComponent = ({
	jackpotId,
	getJackpotGeneralInfo
}) => {
	const routeParams = useParams();
	const { hash } = useLocation();
	const { t } = useTranslation();

	const [activeKey, setActiveKey] = useState(getHashValue(JACKPOT_EDIT_HASHES.TAB) || JACKPOT_HISTORY_TABS.GENERAL);
	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Function to make tab class name
		* @function
		* @description addes class "unsaved-tab" if tab has unsaved changes
		* @param {string} key - tab key
		* @returns {string}
		* @memberOf JackpotEditComponent
  */
	const tabClassName = key => unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "";

	/** Fires when tab saved status changed
		* @function
		* @param {boolean} status - does tab have unsaved change
		* @param {string} key - tab key
		* @memberOf JackpotEditComponent
  */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter(t => t !== key));
		}
	}

	const handleTabChange = tabKey => {
		updateLocationHash(`${JACKPOT_EDIT_HASHES.TAB}=${tabKey}`);
	}

	/** Load bonus general info */
	useEffect(() => {
		if (!routeParams.id) {
			return;
		}

		getJackpotGeneralInfo(routeParams.id);
	}, [routeParams.id])

	

	/** Update active tab on location hash change */
	useEffect(() => {
		setActiveKey(getHashValue(JACKPOT_EDIT_HASHES.TAB) || JACKPOT_HISTORY_TABS.GENERAL);
	}, [hash])

	return (
		<Fragment>
			<div className="dashboard-section">
				{jackpotId && (
					<Breadcrumbs
						items={
							[
								{ title: t('pages.dashboard.menu.bonuses_jackpot'), path: Paths.DASHBOARD_BONUSES_JACKPOT },
								{ title: jackpotId, path: "" }
							]
						}
					/>
				)}
				<Tabs
					animated={false}
					onChange={handleTabChange}
					activeKey={activeKey}
					items={
						[
							{
								key: JACKPOT_HISTORY_TABS.GENERAL,
								label: (
									<span
										className={tabClassName(JACKPOT_HISTORY_TABS.GENERAL)}
									>
										{t("pages.dashboard.bonuses.tabs.general")}
									</span>
								),
								children: (
									<GeneralComponent
										onTabChange={status => changeTabSavedStatus(status, JACKPOT_HISTORY_TABS.GENERAL)}
										activeTabKey={activeKey}
									/>
								)
							},
							{
								key: JACKPOT_HISTORY_TABS.HISTORY,
								label: (
									<span
										className={tabClassName(JACKPOT_HISTORY_TABS.HISTORY)}
									>
										{t("pages.dashboard.bonuses.tabs.jackpot_history")}
									</span>
								),
								children: (
									<JackpotHistory
										onTabChange={status => changeTabSavedStatus(status, JACKPOT_HISTORY_TABS.HISTORY)}
										activeTabKey={activeKey}
									/>
								)
							}
						]
					}
				/>
			</div>
		</Fragment>
	)
}

/** JackpotEditComponent propTypes
	 * PropTypes
*/
JackpotEditComponent.propTypes = {
	/** Redux state property, represents the name of current editing bonus  */
	jackpotId: PropTypes.string,
	/** Redux action to get bonus General info */
	getJackpotGeneralInfo: PropTypes.func,
}

const mapStateToProps = state => {
	return {
		jackpotId: state.bonuses.jackpots.editingJackpot.generalInfo.id,
	}
}

const mapDispatchToProps = dispatch => (
	{
		getJackpotGeneralInfo: id => {
			dispatch(getJackpotGeneralInfo(id));
		}
	}
)

export default connect(mapStateToProps, mapDispatchToProps)(JackpotEditComponent);