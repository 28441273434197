import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spin, Form, Row, Col, Radio } from "antd";
import FinancialPerformance from "./FinancialPerformance";
import BetCount from "./BetCount";
import { PROJECT_TYPE } from "constants/common.constants";
import { DASHBOARD_PERIOD } from "constants/dashboard.constants";
import { getPartnersRevenue, setPartnersRevenueFilter } from "store/actions/dashboard/dashboard/dashboard.action";
import currencyType from "types/common/currency.type";
import usePartnersRevenueData from "hooks/usePartnersRevenueData";
const { Item: FormItem } = Form;

const periodNames = {
	this_today: "TODAY",
	this_yesterday: "YESTERDAY",
	this_month: "LAST_MONTH",
	last_3_months: "LAST_3_MONTH"
};

const PartnersRevenue = ({ projectType, data = [], isLoading, filters, getPartnersRevenue, setPartnersRevenueFilter, currency, currencies }) => {
	const { t } = useTranslation();

	useEffect(() => {
		getPartnersRevenue(filters, projectType);
	}, [filters, projectType]);

	const value = Object.keys(DASHBOARD_PERIOD).find((key) => filters.period === DASHBOARD_PERIOD[key]);

	const onChangeFilter = useCallback(
		(e) => {
			setPartnersRevenueFilter({ ...filters, ...{ period: DASHBOARD_PERIOD[e.target.value] } }, projectType);
		},
		[filters, projectType, setPartnersRevenueFilter]
	);

	const { companies, turnovers, ggrs, betCounts } = usePartnersRevenueData(data, currency, currencies);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<div className="dashboard-section-content">
				<div className="vs--flex">
					<Form requiredMark={false}>
						<Row gutter={[16, 0]}>
							<Col span={20}>
								<div className="table-filters-quick-filters">
									<FormItem label={t("common.quick_filters")} name="period" className="table-filters-quick-filters-buttons" valuePropName="checked">
										<Radio.Group onChange={onChangeFilter} value={value}>
											{Object.keys(periodNames).map((key) => {
												return (
													<Radio.Button key={key} value={periodNames[key]} style={{ marginBottom: "8px" }}>
														{t("common." + key)}
													</Radio.Button>
												);
											})}
										</Radio.Group>
									</FormItem>
								</div>
							</Col>
						</Row>
					</Form>
				</div>
				<div className="dashboard-chart" style={{ marginBottom: 24 }}>
					<div className="dashboard-chart-header">
						<span className="dashboard-chart-header-title">{t("pages.dashboard.dashboard.partners_revenue_financial_performance")}</span>
					</div>
					<div className="dashboard-chart-content all-partners" style={{ padding: 16 }}>
						{companies.length > 0 ? <FinancialPerformance tooltipSymbol={currency.code} categories={companies} turnovers={turnovers} ggrs={ggrs} /> : null}
					</div>
				</div>
				<div className="dashboard-chart">
					<div className="dashboard-chart-header">
						<span className="dashboard-chart-header-title">{t("pages.dashboard.dashboard.partners_revenue_bet_count")}</span>
					</div>
					<div className="dashboard-chart-content all-partners" style={{ padding: 16 }}>
						{companies.length > 0 ? <BetCount tooltipSymbol={""} categories={companies} betCounts={betCounts} /> : null}
					</div>
				</div>
			</div>
		</Spin>
	);
};

/** PartnersRevenue propTypes
 * PropTypes
 */
PartnersRevenue.propTypes = {
	/** Redux state property, Project type */
	projectType: PropTypes.number,
	/** Redux action, for getting Partner Revenue Data */
	getPartnersRevenue: PropTypes.func,
	/** Redux action, for setting Partner Revenue filter state */
	setPartnersRevenueFilter: PropTypes.func,
	/** Redux state property, Partners Revenue data */
	data: PropTypes.array,
	/** Redux state property, is Partners Revenue data loading */
	isLoading: PropTypes.bool,
	/** Redux state property, the filter of Partners Revenue */
	filters: PropTypes.object,
	/** Redux state property, current user currency */
	currency: currencyType,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType)
};

const mapStateToProps = (state, { projectType }) => {
	if (typeof projectType !== "number") {
		throw new Error("Invalid Project Type");
	}
	let pt = projectType === PROJECT_TYPE.ONLINE ? "online" : projectType === PROJECT_TYPE.RETAIL ? "retail" : null;
	return {
		data: state.dashboard[pt].partnersRevenue.data,
		isLoading: state.dashboard[pt].partnersRevenue.isLoading,
		filters: state.dashboard[pt].partnersRevenue.filters,
		currency: state.profile.userInfo.currency,
		currencies: state.profile.userInfo.currencies
	};
};

const mapDispatchToProps = (dispatch) => ({
	getPartnersRevenue: (filters, projectType) => {
		dispatch(getPartnersRevenue(filters, projectType));
	},
	setPartnersRevenueFilter: (filters, projectType) => {
		dispatch(setPartnersRevenueFilter(filters, projectType));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnersRevenue);
