import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Tabs } from "antd";
import PendingsComponent from "./pending";
import SettledComponent from "./settled";
import { updateLocationHash, getHashValue } from "utils/common";
import { GAME_CATEGORY } from "constants/game.constants";

/** Betslips Page Online Tab Component */
const OnlineComponent = ({ gameCategory, showBetsBySeparate = true }) => {
	const { hash } = useLocation();
	const { t } = useTranslation();

	const [activeKey, setActiveKey] = useState(null);

	/** Update active tab on location hash change */
	useEffect(() => {
		setActiveKey(getHashValue("subtab") || "1");
	}, [hash]);

	return (
		<Fragment>
			<Tabs
				animated={false}
				activeKey={activeKey}
				onChange={(key) => updateLocationHash("subtab=" + key, true)}
				className="sub-tabs"
				destroyInactiveTabPane={true}
				items={[
					{
						key: "1",
						label: t("pages.dashboard.bets.tabs.pending_betslips"),
						children: <PendingsComponent gameCategory={gameCategory} showBetsBySeparate={showBetsBySeparate} />
					},
					{
						key: "2",
						label: t("pages.dashboard.bets.tabs.settled_betslips"),
						children: <SettledComponent gameCategory={gameCategory} showBetsBySeparate={showBetsBySeparate} />
					}
				]}
			/>
		</Fragment>
	);
};

OnlineComponent.propTypes = {
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY)),
	showBetsBySeparate: PropTypes.bool
};

export default OnlineComponent;
