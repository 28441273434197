import {
	BOXES_BEFORE,
	BOXES_FINISH,
	BOX_BEFORE,
	BOX_FINISH,
	SET_BOXES,
	BOXES_ACTION_SET_BOXES_SORTING,
	SET_BOX_GENERAL_INFO,
	SET_BOX_GAMES,
	SET_BOX_ADD_GAME,
	SET_BOX_UPDATE_GAME,
	SET_BOX_DELETE_GAME,
	BOX_GAMES_BEFORE,
	BOX_GAMES_FINISH,
	SET_BOX_GAME_RTP,
	BOX_GAMES_RTP_BEFORE,
	BOX_GAMES_RTP_FINISH
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case BOXES_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case BOXES_FINISH:
			return {
				...state,
				isLoading: false
			};
		case BOX_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case BOX_FINISH:
			return {
				...state,
				isSaving: false
			};
		case SET_BOXES:
			return {
				...state,
				boxes: payload.boxes.item2,
				total: payload.boxes.item1
			};
		case BOXES_ACTION_SET_BOXES_SORTING:
			return {
				...state,
				sorting: payload.sorting
			};
		case SET_BOX_GENERAL_INFO:
			return {
				...state,
				editingBox: {
					...state.editingBox,
					generalInfo: {
						...payload.info
					}
				}
			};
		case BOX_GAMES_BEFORE:
			return {
				...state,
				editingBox: {
					...state.editingBox,
					games: {
						...state.editingBox.games,
						isLoading: true
					}
				}
			};
		case BOX_GAMES_FINISH:
			return {
				...state,
				editingBox: {
					...state.editingBox,
					games: {
						...state.editingBox.games,
						isLoading: false
					}
				}
			};
		case SET_BOX_GAMES:
			return {
				...state,
				editingBox: {
					...state.editingBox,
					games: {
						...state.editingBox.games,
						games: payload.games
					}
				}
			};
		case SET_BOX_ADD_GAME:
			return {
				...state,
				editingBox: {
					...state.editingBox,
					games: {
						...state.editingBox.games,
						games: [...state.editingBox.games.games, payload.game]
					}
				}
			};
		case SET_BOX_UPDATE_GAME:
			return {
				...state,
				editingBox: {
					...state.editingBox,
					games: {
						...state.editingBox.games,
						games: state.editingBox.games.games.map((g) => (g.id === payload.game.id ? payload.game : g))
					}
				}
			};
		case SET_BOX_DELETE_GAME:
			return {
				...state,
				editingBox: {
					...state.editingBox,
					games: {
						...state.editingBox.games,
						games: state.editingBox.games.games.filter((g) => g.id !== payload.id)
					}
				}
			};
		case BOX_GAMES_RTP_BEFORE:
			return {
				...state,
				editingBox: {
					...state.editingBox,
					rtp: {
						...state.editingBox.rtp,
						isLoading: true
					}
				}
			};
		case BOX_GAMES_RTP_FINISH:
			return {
				...state,
				editingBox: {
					...state.editingBox,
					rtp: {
						...state.editingBox.rtp,
						isLoading: false
					}
				}
			};
		case SET_BOX_GAME_RTP:
			return {
				...state,
				editingBox: {
					...state.editingBox,
					rtp: {
						...state.editingBox.rtp,
						data: payload.rtp
					}
				}
			};
		default:
			return state;
	}
};
