import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { STREAMS_ACTION_BEFORE, STREAMS_ACTION_FINISH, SET_DEVELOPER_SPACE_STREAMS } from "store/actionTypes";

export const setStreamsBefore = () => ({
	type: STREAMS_ACTION_BEFORE
});

export const setStreamsFinished = () => ({
	type: STREAMS_ACTION_FINISH
});

export const setStreams = (streams) => ({
	type: SET_DEVELOPER_SPACE_STREAMS,
	payload: { streams }
});

export const getStreams = () => {
	return (dispatch, getState) => {
		dispatch(setStreamsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SYSTEM_STREAMS}`,
			method: Methods.GET
		})
			.then(({ data }) => {
				dispatch(setStreams(data));
			})
			.catch((ex) => {
				console.error(ex.message);
			})
			.finally(() => {
				dispatch(setStreamsFinished());
			});
	};
};
