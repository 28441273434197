import { PERMISSION_REQUEST_TYPE } from "./permissionRequest.constants";

export const NOTIFICATION_STATE = {
	UNREADED: 1,
	READED: 2
};

export const NOTIFICATION_TYPE = {
	REPRINT_REQUEST: 1,
	ACCESS_MANAGER_REQUEST: 2
};

export const ACCESS_MANAGER_NOTIFICATION_CONFIGS = {
	TITLE: {
		[PERMISSION_REQUEST_TYPE.ADD_PARTNER_ACCESS]: "addPartnerAccess",
		[PERMISSION_REQUEST_TYPE.ADD_PROJECT_ACCESS]: "addProjectAccess",
		[PERMISSION_REQUEST_TYPE.ADD_BETSHOP_ACCESS]: "addBetshopAccess",
		[PERMISSION_REQUEST_TYPE.ADD_PERMISSION_GROUP]: "addPermissionGroup",
		[PERMISSION_REQUEST_TYPE.DELETE_PARTNER_ACCESS]: "deletePartnerAccess",
		[PERMISSION_REQUEST_TYPE.DELETE_PROJECT_ACCESS]: "deleteProjectAccess",
		[PERMISSION_REQUEST_TYPE.DELETE_BETSHOP_ACCESS]: "deleteBetshopAccess",
		[PERMISSION_REQUEST_TYPE.DELETE_PERMISSION_GROUP]: "deletePermissionGroup",
		[PERMISSION_REQUEST_TYPE.GROUP_MODIFY]: "permissionGroupModify"
	},
	ACTION_TYPES: {
		[PERMISSION_REQUEST_TYPE.ADD_PARTNER_ACCESS]: "added",
		[PERMISSION_REQUEST_TYPE.ADD_PROJECT_ACCESS]: "added",
		[PERMISSION_REQUEST_TYPE.ADD_BETSHOP_ACCESS]: "added",
		[PERMISSION_REQUEST_TYPE.ADD_PERMISSION_GROUP]: "added",
		[PERMISSION_REQUEST_TYPE.DELETE_PARTNER_ACCESS]: "deleted",
		[PERMISSION_REQUEST_TYPE.DELETE_PROJECT_ACCESS]: "deleted",
		[PERMISSION_REQUEST_TYPE.DELETE_BETSHOP_ACCESS]: "deleted",
		[PERMISSION_REQUEST_TYPE.DELETE_PERMISSION_GROUP]: "deleted",
		[PERMISSION_REQUEST_TYPE.GROUP_MODIFY]: "modified"
	},
	ACTION_RESOURCES: {
		[PERMISSION_REQUEST_TYPE.ADD_PARTNER_ACCESS]: "Partner Access",
		[PERMISSION_REQUEST_TYPE.ADD_PROJECT_ACCESS]: "Project Access",
		[PERMISSION_REQUEST_TYPE.ADD_BETSHOP_ACCESS]: "Betshop Access",
		[PERMISSION_REQUEST_TYPE.ADD_PERMISSION_GROUP]: "Permission Group",
		[PERMISSION_REQUEST_TYPE.DELETE_PARTNER_ACCESS]: "Partner Access",
		[PERMISSION_REQUEST_TYPE.DELETE_PROJECT_ACCESS]: "Project Access",
		[PERMISSION_REQUEST_TYPE.DELETE_BETSHOP_ACCESS]: "Betshop Access",
		[PERMISSION_REQUEST_TYPE.DELETE_PERMISSION_GROUP]: "Permission Group",
		[PERMISSION_REQUEST_TYPE.GROUP_MODIFY]: "Permission Group"
	}
};
