import PropTypes from "prop-types";

import { PROJECT_TYPE } from "constants/common.constants";
import { SURVEY_STATE } from "constants/survey.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	state: PropTypes.oneOf(Object.values(SURVEY_STATE)),
	suggestedMessage: PropTypes.bool,
	isTesting: PropTypes.bool,
	enabled: PropTypes.bool
});
