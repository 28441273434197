import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Col, Row, Modal, Select } from 'antd';
const { Item: FormItem } = Form;

import { changeJackpotState } from 'store/actions/dashboard/bonuses/bonuses.action';

import { JACKPOT_STATE, JACKPOT_BONUS_STATES_TRANSLATION_TEXTS } from 'constants/bonus.constants';
import { POPUP_SIZE } from 'constants/common.constants';

import jackpotType from 'types/bonus/jackpot.type';

const getAllowedStates = (jackpot) => {

	switch (jackpot.state) {
		case JACKPOT_STATE.INACTIVE:
			return [JACKPOT_STATE.ACTIVE];
		case JACKPOT_STATE.ACTIVE:
			return [JACKPOT_STATE.PAUSED, JACKPOT_STATE.FINISHED];
		case JACKPOT_STATE.PAUSED:
			return [JACKPOT_STATE.ACTIVE, JACKPOT_STATE.FINISHED];
		default:
			break;
	}

	return [];
}

const getIsSaving = (state) => state.bonuses.jackpots.isSaving;

const FORM_FIELDS = {
	STATE: "state"
}

/** Betslip Status changing Popup Component */
const JackpotChangeState = ({
	jackpot,
	onClose
}) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;

	const isSaving = useSelector(getIsSaving);

	const dispatch = useDispatch();

	const setJackpotState = useCallback((params) => dispatch(changeJackpotState(params)), []);

	const allowedStates = getAllowedStates(jackpot);

	/** Fires when form submitted
		* @function
		* @memberOf JackpotChangeState
  */
	const handleForm = () => {
		validateFields()
			.then(data => {
				setJackpotState({ state: data.state, id: jackpot.id });
			})
			.then(() => onClose())
			.catch(Function.prototype)
	}

	return (
		<Modal
			open={true}
			title={t('pages.dashboard.bonuses.jackpot_change_state')}
			cancelText={t('common.cancel')}
			okText={t('common.save')}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.SMALL}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving }}
			centered
		>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
			>
				<Row gutter={[16, 0]}>
					<Col xs={24} >
						<FormItem
							label={`${t('pages.dashboard.bets.status')} *`}
							name={FORM_FIELDS.STATE}
							rules={[{ required: true, message: t('validation.field_required') }]}
						>
							<Select
								placeholder={`${t('common.select')} ${t('pages.dashboard.bonuses.status')}`}
								getPopupContainer={() => document.body}
								suffixIcon={(<i className="icon-down" />)}
							>
								{
									allowedStates.map(state => (
										<Select.Option
											key={state}
											value={state}
										>
											{
												JACKPOT_BONUS_STATES_TRANSLATION_TEXTS[state]
													? t(JACKPOT_BONUS_STATES_TRANSLATION_TEXTS[state])
													: ""
											}
										</Select.Option>
									))
								}
							</Select>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	)
}

/** JackpotChangeState propTypes
	 * PropTypes
*/
JackpotChangeState.propTypes = {
	/** Current editing jackpot */
	jackpot: jackpotType,
	/** Fires on popup close */
	onClose: PropTypes.func
}

export default JackpotChangeState