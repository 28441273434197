import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";
import { GAME_CATEGORY, SCHEDULED_GAME_TYPE } from "constants/game.constants";

import { setGamesActionBefore, setGamesActionFinished, setGameSaveActionBefore, setGameSaveActionFinished } from "./games.action";

import { SET_GAME_FOOTBALL_SCENE_PROBABILITIES, SET_GAME_FOOTBALL_HANDICAP_PROBABILITY, SET_GAME_STRENGTHS, SET_GAME_COMPETITIONS_AND_RACES, SET_GAME_FOOTBALL_TEAMS, SET_GAME_PENALTY_TEAMS, SET_PENALTY_GOAL_MISS_PROBABILITIES } from "store/actionTypes";

const setFootballSceneProbabilities = (probabilities) => ({
	type: SET_GAME_FOOTBALL_SCENE_PROBABILITIES,
	payload: { probabilities }
});

const setPenaltyGoalMissProbabilities = (probabilities) => ({
	type: SET_PENALTY_GOAL_MISS_PROBABILITIES,
	payload: { probabilities }
});

const setFootballHandicapProbability = (probability) => ({
	type: SET_GAME_FOOTBALL_HANDICAP_PROBABILITY,
	payload: { probability }
});

const setStrengths = (strengths) => ({
	type: SET_GAME_STRENGTHS,
	payload: { strengths }
});

const setCompetitionsAndRaces = (data) => ({
	type: SET_GAME_COMPETITIONS_AND_RACES,
	payload: { data }
});

const setFootballTeams = (data) => ({
	type: SET_GAME_FOOTBALL_TEAMS,
	payload: { data }
});

const setPenaltyTeams = (data) => ({
	type: SET_GAME_PENALTY_TEAMS,
	payload: { data }
});

export const getFootballSceneProbabilities = (id, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.SCHEDULED_GAME_GET_FOOTBALL_SCENE_PROBABILITY : ApiUrls.INSTANT_GAME_GET_FOOTBALL_SCENE_PROBABILITY;
	return (dispatch) => {
		dispatch(setGamesActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: probabilities } }) => {
				dispatch(setFootballSceneProbabilities(probabilities));
				dispatch(setGamesActionFinished());
			})
			.catch((ex) => {
				dispatch(setGamesActionFinished());
			});
	};
};

export const getPenaltyGoalMissProbabilities = (id) => {
	return (dispatch) => {
		dispatch(setGamesActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_GET_PENALTY_GOAL_MISS_PROBABILITY}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: probabilities } }) => {
				dispatch(setPenaltyGoalMissProbabilities(probabilities));
				dispatch(setGamesActionFinished());
			})
			.catch(() => {
				dispatch(setGamesActionFinished());
			});
	};
};

export const saveFootballSceneProbabilities = (data, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.SCHEDULED_GAME_SAVE_FOOTBALL_SCENE_PROBABILITY : ApiUrls.INSTANT_GAME_SAVE_FOOTBALL_SCENE_PROBABILITY;
	return (dispatch) => {
		dispatch(setGameSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.POST,
			data: {
				...data
			}
		})
			.then(({ data: { message: msg, status, value: probabilities } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setFootballSceneProbabilities(probabilities));
				}
				dispatch(setGameSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setGameSaveActionFinished());
			});
	};
};

export const savePenaltyGoalMissProbabilities = (data) => {
	return (dispatch) => {
		dispatch(setGameSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_SAVE_PENALTY_GOAL_MISS_PROBABILITY}`,
			method: Methods.POST,
			data: {
				...data
			}
		})
			.then(({ data: { message: msg, status, value: probabilities } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setPenaltyGoalMissProbabilities(probabilities));
				}
				dispatch(setGameSaveActionFinished());
			})
			.catch(() => {
				dispatch(setGameSaveActionFinished());
			});
	};
};

export const getFootballHandicapProbability = (id) => {
	return (dispatch, getState) => {
		dispatch(setGamesActionBefore());

		const gameType = getState().games.editingGame.generalInfo.type;

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_GET_FOOTBALL_HANDICAP_PROBABILITY}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: probability } }) => {
				dispatch(setFootballHandicapProbability(probability));
				dispatch(setGamesActionFinished());
			})
			.catch((ex) => {
				dispatch(setGamesActionFinished());
			});
	};
};

export const getPenaltyHandicapProbability = (id) => {
	return (dispatch) => {
		dispatch(setGamesActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_GET_PENALTY_HANDICAP_PROBABILITY}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: probability } }) => {
				dispatch(setFootballHandicapProbability(probability));
				dispatch(setGamesActionFinished());
			})
			.catch(() => {
				dispatch(setGamesActionFinished());
			});
	};
};

export const saveFootballHandicapProbability = (data) => {
	return (dispatch) => {
		dispatch(setGameSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_SAVE_FOOTBALL_HANDICAP_PROBABILITY}`,
			method: Methods.POST,
			data: {
				...data
			}
		})
			.then(({ data: { message: msg, status, value: probability } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setFootballHandicapProbability(probability));
				}
				dispatch(setGameSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setGameSaveActionFinished());
			});
	};
};

export const getStrengths = (id, gameType) => {
	return (dispatch) => {
		dispatch(setGamesActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_GET_STRENGTHS}`,
			method: Methods.GET,
			params: { id, gameType }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setStrengths(data.participants));
				dispatch(setGamesActionFinished());
			})
			.catch((ex) => {
				dispatch(setGamesActionFinished());
			});
	};
};

export const saveStrengths = (data) => {
	return (dispatch) => {
		dispatch(setGameSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_SAVE_STRENGTHS}`,
			method: Methods.POST,
			data: {
				...data
			}
		})
			.then(({ data: { message: msg, status, value: data } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setStrengths(data.participants));
				}
				dispatch(setGameSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setGameSaveActionFinished());
			});
	};
};

export const getCompetitionsAndRaces = (id, gameType) => {
	return (dispatch) => {
		dispatch(setGamesActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_GET_COMPETITIONS_AND_RACES}`,
			method: Methods.GET,
			params: { id, gameType }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setCompetitionsAndRaces(data));
				dispatch(setGamesActionFinished());
			})
			.catch((ex) => {
				dispatch(setGamesActionFinished());
			});
	};
};

export const saveCompetitionsAndRaces = (data) => {
	return (dispatch) => {
		dispatch(setGameSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_SAVE_COMPETITIONS_AND_RACES}`,
			method: Methods.POST,
			data: {
				...data
			}
		})
			.then(({ data: { message: msg, status, value: data } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setCompetitionsAndRaces(data));
				}
				dispatch(setGameSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setGameSaveActionFinished());
			});
	};
};

export const getFootballTeams = (id, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.SCHEDULED_GAME_GET_FOOTBALL_TEAMS : ApiUrls.INSTANT_GAME_GET_FOOTBALL_TEAMS;
	return (dispatch) => {
		dispatch(setGamesActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setFootballTeams(data));
				dispatch(setGamesActionFinished());
			})
			.catch((ex) => {
				dispatch(setGamesActionFinished());
			});
	};
};

export const getPenaltyShootTeamsAndRatings = (id) => {
	return (dispatch) => {
		dispatch(setGamesActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_GET_PENALTY_TEAMS}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setPenaltyTeams(data));
			})
			.finally(() => {
				dispatch(setGamesActionFinished());
			});
	};
};

export const saveFootballTeams = (data) => {
	return (dispatch) => {
		dispatch(setGameSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_SAVE_FOOTBALL_TEAMS}`,
			method: Methods.POST,
			data: {
				...data
			}
		})
			.then(({ data: { message: msg, status, value: data } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setFootballTeams(data));
				}
				dispatch(setGameSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setGameSaveActionFinished());
			});
	};
};

export const savePenaltyShootTeamsAndRatings = (id, teams) => {
	return (dispatch) => {
		dispatch(setGameSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_SAVE_PENALTY_TEAMS}`,
			method: Methods.POST,
			data: { id, teams }
		})
			.then(({ data: { message: msg, status, value: data } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setPenaltyTeams(data));
				}
			})
			.finally((ex) => {
				dispatch(setGameSaveActionFinished());
			});
	};
};
