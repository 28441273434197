import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { refreshToken, getUser } from "utils/auth";

import { BETSHOPS_ACTION_BEFORE, BETSHOPS_ACTION_FINISH, BETSHOPS_ACTION_SET_BETSHOPS, BETSHOP_SAVE_ACTION_BEFORE, BETSHOP_SAVE_ACTION_FINISH, BETSHOPS_ACTION_SET_BETSHOP_ENABLED, BETSHOPS_SET_SORTING, BETSHOPS_SET_FILTERS } from "store/actionTypes";

export const setBetshopsActionBefore = () => ({
	type: BETSHOPS_ACTION_BEFORE
});

export const setBetshopsActionFinished = () => ({
	type: BETSHOPS_ACTION_FINISH
});

export const setBetshopSaveActionBefore = () => ({
	type: BETSHOP_SAVE_ACTION_BEFORE
});

export const setBetshopSaveActionFinished = () => ({
	type: BETSHOP_SAVE_ACTION_FINISH
});

const setBetshopsActionSetBetshops = (data) => ({
	type: BETSHOPS_ACTION_SET_BETSHOPS,
	payload: { data }
});

const setBetshopEnabled = (id, enabled) => ({
	type: BETSHOPS_ACTION_SET_BETSHOP_ENABLED,
	payload: { id, enabled }
});

export const setBetshopFilters = (filters) => ({
	type: BETSHOPS_SET_FILTERS,
	payload: { filters }
});

export const setBetshopSorting = (sorting) => ({
	type: BETSHOPS_SET_SORTING,
	payload: { sorting }
});

export const getBetshops = (fromFirstPage) => {
	return (dispatch, getState) => {
		dispatch(setBetshopsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_BETSHOPS}`,
			method: Methods.GET,
			params: {
				...getState().betshops.sorting,
				...getState().betshops.filters,
				page: fromFirstPage ? 1 : getState().betshops.sorting.page
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setBetshopsActionSetBetshops(data));
				if (fromFirstPage) dispatch(setBetshopSorting({ ...getState().betshops.sorting, page: 1 }));
				dispatch(setBetshopsActionFinished());
			})
			.catch(() => {
				dispatch(setBetshopsActionFinished());
			});
	};
};

export const createBetshop = (betshop, onSuccess) => {
	return (dispatch) => {
		dispatch(setBetshopSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CREATE_BETSHOP}`,
			method: Methods.POST,
			data: betshop
		})
			.then(({ data: { value: betshop } }) => {
				dispatch(setBetshopSaveActionFinished());
				const user = getUser();
				const refresh_token = user?.refreshToken ?? null;
				const userName = user?.userName ?? null;
				refreshToken(refresh_token, userName).then(() => {
					onSuccess && onSuccess(betshop);
				});
			})
			.catch(() => {
				dispatch(setBetshopSaveActionFinished());
			});
	};
};

export const enableDisableBetshop = (betshop) => {
	return (dispatch) => {
		dispatch(setBetshopSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BETSHOP_SAVE_GENERAL_INFO}`,
			method: Methods.POST,
			data: {
				...betshop
			}
		})
			.then(({ data: { value: info } }) => {
				dispatch(setBetshopEnabled(info.id, info.enabled));
				dispatch(setBetshopSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setBetshopSaveActionFinished());
			});
	};
};
