import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

export const deleteUserAvatar = (id, onSuccess, isProfile) => {
	return () => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${isProfile ? ApiUrls.USER_DELETE_PROFILE_AVATAR : ApiUrls.USER_DELETE_AVATAR}`,
			method: Methods.DELETE,
			data: { id }
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					onSuccess && onSuccess();
				}
			})
			.catch(Function.prototype);
	};
};
