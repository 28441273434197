import PropTypes from "prop-types";

export default PropTypes.shape({
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	userName: PropTypes.string,
	email: PropTypes.string,
	betShop: PropTypes.string,
	id: PropTypes.string
});
