import { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Form, Col, Row, Modal, Select, Input, Checkbox, DatePicker, Tooltip } from "antd";
const { Item: FormItem } = Form;

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { changePlayerState } from "store/actions/dashboard/online/players/players.action";

import { USER_STATE } from "constants/user.constants";
import { POPUP_SIZE } from "constants/common.constants";

import playerType from "types/player/player.type";

/** Player Status changing Popup Component */
const PlayerStatusChangeComponent = ({ player, isSaving, changePlayerState, onClose }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;

	const [selectedState, setSelectedState] = useState(player.state);
	const [selectedDuration, setSelectedDuration] = useState("permanent");

	/** Fires when form submitted
	 * @function
	 * @memberOf PlayerStatusChangeComponent
	 */
	const handleForm = () => {
		const d = {
			state: selectedState,
			id: player.id
		};
		validateFields()
			.then((data) => {
				if (d.state !== USER_STATE.ACTIVE) {
					d.info = {
						reason: data.reason,
						to: selectedDuration === "temporary" ? data.to : ""
					};
				}
				changePlayerState(d);
				onClose();
			})
			.catch(Function.prototype);
	};

	return (
		<Modal
			open={true}
			title={t("pages.dashboard.players.change_player_status")}
			cancelText={t("common.cancel")}
			okText={t("common.save")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.SMALL}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving, disabled: player.state === selectedState }}
			centered
		>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					state: player.state,
					duration: "permanent",
					reason: "",
					to: ""
				}}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem label={`${t("pages.dashboard.players.status")} *`} name="state" rules={[{ required: true, message: t("validation.field_required") }]}>
							<Select getPopupContainer={() => document.body} onChange={(e) => setSelectedState(e)} suffixIcon={<i className="icon-down" />}>
								<Select.Option value={USER_STATE.ACTIVE}>{t("common.active")}</Select.Option>
								<Select.Option value={USER_STATE.BLOCKED}>{t("pages.dashboard.users.status_4")}</Select.Option>
							</Select>
						</FormItem>
					</Col>

					{selectedState !== USER_STATE.ACTIVE ? (
						<Fragment>
							<Col span={24}>
								<FormItem label={t("pages.dashboard.players.status_duration")} name="duration">
									<Select getPopupContainer={() => document.body} onChange={(e) => setSelectedDuration(e)} suffixIcon={<i className="icon-down" />}>
										<Select.Option value="permanent">{t("pages.dashboard.players.permanent")}</Select.Option>
										<Select.Option value="temporary">{t("pages.dashboard.players.temporary")}</Select.Option>
									</Select>
								</FormItem>
								<div style={{ display: selectedDuration === "temporary" ? "block" : "none" }}>
									<FormItem
										label={t("pages.dashboard.players.status_duration_time")}
										name="to"
										rules={[
											() => ({
												validator(rule, value) {
													if (!value && selectedDuration === "temporary") {
														return Promise.reject(t("validation.field_required"));
													}
													if (moment.utc(value).isBefore(moment.utc()) && selectedDuration === "temporary") {
														return Promise.reject(t("validation.field_invalid"));
													}
													return Promise.resolve();
												}
											})
										]}
									>
										<DatePicker format={`${DATE_FORMAT} ${TIME_FORMAT}`} showTime={{ format: TIME_FORMAT }} disabledDate={(d) => !d || d.isBefore(moment())} showToday={false} allowClear={true} placeholder={t("common.select_time")} getPopupContainer={() => document.body} />
									</FormItem>
								</div>
							</Col>
							<Col span={24}>
								<FormItem label={`${t("pages.dashboard.players.block_reason")} *`} name="reason" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
									<Input.TextArea maxLength={100} placeholder={t("pages.dashboard.players.block_reason_placeholder")} rows={4} />
								</FormItem>
							</Col>
						</Fragment>
					) : null}
				</Row>
			</Form>
		</Modal>
	);
};

/** PlayerStatusChangeComponent propTypes
 * PropTypes
 */
PlayerStatusChangeComponent.propTypes = {
	/** Redux state property, is true when changing player status request is in process */
	isSaving: PropTypes.bool,
	/** Redux action to block/unblock player */
	changePlayerState: PropTypes.func,
	/** Current editing player */
	player: playerType,
	/** Fires on popup close */
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	changePlayerState: (data) => {
		dispatch(changePlayerState(data));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.players.isSaving
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerStatusChangeComponent);
