import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { SET_GLOBAL_PARTNER_ID, SET_GLOBAL_PARTNERS, PARTNER_ACTION_BEFORE, PARTNER_ACTION_FINISH, PARTNER_SAVE_ACTION_BEFORE, PARTNER_SAVE_ACTION_FINISH, PARTNER_RESET } from "../../../actionTypes";

export const setGlobalPartnerId = (partnerId) => ({
	type: SET_GLOBAL_PARTNER_ID,
	payload: { partnerId }
});

export const setPartnerActionBefore = () => ({
	type: PARTNER_ACTION_BEFORE
});

export const setPartnerActionFinished = () => ({
	type: PARTNER_ACTION_FINISH
});

export const setPartnerSaveActionBefore = () => ({
	type: PARTNER_SAVE_ACTION_BEFORE
});

export const setPartnerSaveActionFinished = () => ({
	type: PARTNER_SAVE_ACTION_FINISH
});

export const resetPartner = () => ({
	type: PARTNER_RESET
});

const setGlobalPartners = (partners) => ({
	type: SET_GLOBAL_PARTNERS,
	payload: { partners }
});

export const getGlobalPartners = () => {
	return (dispatch, getState) => {
		const partnersState = getState().profile.userInfo.partners;
		if (!partnersState.includes("*")) {
			return Promise.resolve().then(() => {
				dispatch(setGlobalPartners({ item2: partnersState }));
			});
		}
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNERS}`,
			method: Methods.GET,
			params: { page: 1, limit: 500 }
		})
			.then(({ data: { value: partners } }) => {
				dispatch(setGlobalPartners(partners));
			})
			.catch(Function.prototype);
	};
};
