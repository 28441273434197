import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Button, Select, Input, Spin, Tooltip, Radio, Switch, Checkbox } from "antd";
const { Item: FormItem } = Form;

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { getApiKeysAddOns, saveApiKeysAddOns } from "store/actions/dashboard/online/apikeys/apikeys.action";
import NumericInput from "components/common/numericInput";
import { COMBO_BOOST_STEP_FORM_NAMES, COMBO_BOOST_STEPS, COMBO_BOOST_STEPS_TRANSLATIONS } from "constants/bet.constants";
import { isNullish } from "utils/common";

const comboBoostStepsValues = Object.values(COMBO_BOOST_STEPS)

/** Api Key Edit Page General Info Tab Component */
const AddOnsComponent = ({ addOns, isSaving, isLoading, id, onTabChange, getApiKeysAddOns, saveApiKeysAddOns }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue, resetFields } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const isDisabled = (
		!addOns.isPartnerLevelComboBoostEnabled ||
		!hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_ADD_ONS, action: PERMISSION_ACTION.MODIFY })
		|| isSaving
	);

	const partnerLevelDisabledInfo = (
		addOns.isPartnerLevelComboBoostEnabled
			? null
			: t("pages.dashboard.bets.partner_level_disabled_info")
	)

	/** Load partner languages */
	useEffect(() => {
		getApiKeysAddOns(id);
	}, [id]);

	useEffect(() => {
		setFieldsValue({ ...addOns })
	}, [addOns])

	/** Fires when form submitted
	 * @function
	 * @memberOf AddOnsComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				const comboBoostSettings = { ...addOns, ...data }
				const boostFactors = comboBoostStepsValues.reduce((acc, comboBoostStep) => {
					acc[COMBO_BOOST_STEP_FORM_NAMES[comboBoostStep]] = Number(comboBoostSettings.boostFactors[COMBO_BOOST_STEP_FORM_NAMES[comboBoostStep]]) || null;
					const resetCondition = (
						comboBoostStepsValues
							.filter(value => value < comboBoostStep)
							.some(value => 100 === (Number(comboBoostSettings.boostFactors[value]) || 0))
					)
					if (resetCondition) {
						acc[COMBO_BOOST_STEP_FORM_NAMES[comboBoostStep]] = null;
					}
					return acc
				}, {})
				saveApiKeysAddOns(
					id,
					{
						...comboBoostSettings,
						minOddFactor: Number(comboBoostSettings.minOddFactor) || 0,
						boostFactors: boostFactors
					}
				);
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	const handleCancel = () => {
		resetFields()
		setIsFormTouched(false);
	}

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={addOns}
				onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, { ...addOns }))}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h1 className="dashboard-section-name vs--mt-32">
								<Tooltip
									title={partnerLevelDisabledInfo}
								>
									{t("pages.dashboard.bets.combo_boost")}
								</Tooltip>
								</h1>
							</Col>
							<Col span={24} className="vs--mb-18">
								<FormItem shouldUpdate noStyle>
									{({ getFieldValue }) => {
										const isComboBoostEnabled = getFieldValue(["isComboBoostEnabled"]);
										return (
											<div className="inline-form-item form-switcher form-item-without-margin">
												<label>{t(isComboBoostEnabled ? "common.enabled" : "common.disabled")}</label>
												<Tooltip
													title={partnerLevelDisabledInfo}
												>
													<FormItem name={["isComboBoostEnabled"]} valuePropName="checked" className="ant-row">
														<Switch disabled={isDisabled} />
													</FormItem>
												</Tooltip>
											</div>
										);
									}}
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={4}>
								<FormItem noStyle shouldUpdate>
									{({ getFieldValue }) => {
										const isComboBoostEnabled = getFieldValue(["isComboBoostEnabled"]);
										return (
											<FormItem
												label={`${t("pages.dashboard.bets.combo_boost_minOddFactor")} *`}
												name={["minOddFactor"]}
												rules={[
													{
														validator: (_, value) => {
															const numericValue = Number(value) || 0;
															if (!isComboBoostEnabled) {
																return Promise.resolve();
															}
															if (numericValue < 1.01 || numericValue > 1000) {
																return Promise.reject(t("validation.must_be_between").replace("%X%", 1.01).replace("%Y%", 1000));
															}

															return Promise.resolve();
														}
													}
												]}
											>
												<NumericInput
													decimalsCount={2}
													placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.combo_boost_minOddFactor")}`}
													disabled={isDisabled || !isComboBoostEnabled}
												/>
											</FormItem>
										);
									}}
								</FormItem>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h1 className="dashboard-section-name vs--mt-32">
									{`${t("pages.dashboard.bets.boost_factor")} *`}
									<Tooltip
										title={t("pages.dashboard.bets.please_set_boost_factor")}
									>
										<i className="icon-info vs--font-bigest vs--cursor-pointer" />
									</Tooltip>
								</h1>
							</Col>
							<Col span={24}>
								<FormItem shouldUpdate>
									{({ getFieldValue }) => {
										const isComboBoostEnabled = getFieldValue(["isComboBoostEnabled"]);
										const anyStepHasValue = comboBoostStepsValues.some((value) => {
											const fieldValue = getFieldValue(["boostFactors", COMBO_BOOST_STEP_FORM_NAMES[value]]);
											if (isNullish(fieldValue) || fieldValue === "") {
												return false;
											}

											return fieldValue > 0;
										});
										return (
											<Row gutter={[16, 0]}>
												{comboBoostStepsValues.map((comboBoostStep) => {
													const name = ["boostFactors", COMBO_BOOST_STEP_FORM_NAMES[comboBoostStep]];
													const previousComboBoostSteps = comboBoostStepsValues.filter((value) => value < comboBoostStep);
													const nextComboBoostSteps = comboBoostStepsValues.filter((value) => value > comboBoostStep);
													const disabledByValue = previousComboBoostSteps.some((value) => 100 === Number(getFieldValue(["boostFactors", COMBO_BOOST_STEP_FORM_NAMES[value]])) || 0);
													const disabled = isDisabled || !isComboBoostEnabled || disabledByValue;
													const rules = isComboBoostEnabled
														? [
																{
																	validator: (_, value) => {
																		const numericValue = Number(value) || 0;
																		if (!anyStepHasValue) {
																			return Promise.reject(t("validation.enter_this_or_other_field"));
																		}
																		if (isNullish(value) || value === "") {
																			return Promise.resolve();
																		}
																		let index = null;
																		const biggerExistInNextSteps = nextComboBoostSteps.some((value, i) => {
																			const nextValue = getFieldValue(["boostFactors", COMBO_BOOST_STEP_FORM_NAMES[value]]);
																			if (isNullish(nextValue) || nextValue === "") {
																				return false;
																			}
																			if (numericValue >= Number(nextValue) || 0) {
																				index = i;
																				return true;
																			}
																			return false;
																		});
																		if (biggerExistInNextSteps) {
																			return Promise.reject(
																				t("validation.must_be_less_and_not_equal_than_other")
																					.replace("%X%", "Field")
																					.replace("%Y%", getFieldValue(["boostFactors", COMBO_BOOST_STEP_FORM_NAMES[nextComboBoostSteps[index]]]))
																			);
																		}

																		if (numericValue < 1 || numericValue > 100) {
																			return Promise.reject(t("validation.must_be_between").replace("%X%", 1).replace("%Y%", 100));
																		}

																		return Promise.resolve();
																	}
																}
														]
														: [];
													return (
														<Col key={comboBoostStep}>
															<FormItem
																name={name}
																label={t(COMBO_BOOST_STEPS_TRANSLATIONS[comboBoostStep])}
																rules={rules}
																dependencies={comboBoostStepsValues.filter((value) => value !== comboBoostStep).map((value) => ["boostFactors", COMBO_BOOST_STEP_FORM_NAMES[value]])}
															>
																<NumericInput isInteger={true} placeholder={`${t("common.enter")} 1 - 100%`} disabled={disabled} />
															</FormItem>
														</Col>
													);
												})}
											</Row>
										);
									}}
								</FormItem>
							</Col>
						</Row>
					</div>
					<Row justify="end">
					{!isDisabled && (
						<FormItem className="button-container">
							<Button loading={isSaving} htmlType="submit" className="button ant-btn" onClick={handleCancel} disabled={!isFormTouched}>
								<span>{t("common.cancel")}</span>
							</Button>
						</FormItem>
					)}
					{!isDisabled && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
					</Row>
				</div>
			</Form>
		</Spin>
	);
};

/** AddOnsComponent propTypes
 * PropTypes
 */
AddOnsComponent.propTypes = {
	isSaving: PropTypes.bool,
	isLoading: PropTypes.bool,
	onTabChange: PropTypes.func,
	getApiKeysAddOns: PropTypes.func,
	saveApiKeysAddOns: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getApiKeysAddOns: (id) => {
		dispatch(getApiKeysAddOns(id));
	},
	saveApiKeysAddOns: (id, data) => {
		dispatch(saveApiKeysAddOns(id, data));
	}
});

const mapStateToProps = (state) => {
	return {
		addOns: state.apikeys.editingApikey.addOns.data,
		isSaving: state.apikeys.editingApikey.addOns.isSaving,
		isLoading: state.apikeys.editingApikey.addOns.isLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOnsComponent);
