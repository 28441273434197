import PropTypes from "prop-types";

const requestType = {
	id: PropTypes.string,
	controller: PropTypes.string,
	action: PropTypes.string,
	requestRaw: PropTypes.string,
	requestTime: PropTypes.string,
	responseRaw: PropTypes.string,
	responseTime: PropTypes.string,
	totalMilliseconds: PropTypes.number,
	ip: PropTypes.string,
	httpStatusCode: PropTypes.number
};

export default PropTypes.shape({
	...requestType,
	details: PropTypes.shape(requestType)
});
