import { PROJECT_TYPE } from "constants/common.constants";

import {
	BETSLIPS_BEFORE,
	BETSLIPS_FINISH,
	BETSLIPS_SAVE_BEFORE,
	BETSLIPS_SAVE_FINISH,
	SET_PENDING_BETSLIPS,
	SET_PENDING_BETSLIPS_SORTING,
	SET_PENDING_BETSLIPS_FILTERS,
	SET_SETTLED_BETSLIPS,
	SET_SETTLED_BETSLIPS_SORTING,
	SET_SETTLED_BETSLIPS_FILTERS,
	SET_BETSLIPS_REQUESTS,
	SET_BETSLIPS_REQUESTS_SORTING,
	SET_BETSLIPS_REQUESTS_FILTERS,
	SET_BETSLIPS_DB_CACHE_COMPARE
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	let projectType;
	switch (type) {
		case BETSLIPS_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case BETSLIPS_FINISH:
			return {
				...state,
				isLoading: false
			};
		case BETSLIPS_SAVE_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case BETSLIPS_SAVE_FINISH:
			return {
				...state,
				isSaving: false
			};
		case SET_PENDING_BETSLIPS:
			projectType = payload.projectType === PROJECT_TYPE.ONLINE ? "online" : "retail";
			return {
				...state,
				pendings: {
					...state.pendings,
					[projectType]: {
						...state.pendings[projectType],
						bets: payload.data.item2,
						total: payload.data.item1
					}
				}
			};
		case SET_PENDING_BETSLIPS_SORTING:
			projectType = payload.projectType === PROJECT_TYPE.ONLINE ? "online" : "retail";
			return {
				...state,
				pendings: {
					...state.pendings,
					[projectType]: {
						...state.pendings[projectType],
						sorting: payload.sorting
					}
				}
			};
		case SET_PENDING_BETSLIPS_FILTERS:
			projectType = payload.projectType === PROJECT_TYPE.ONLINE ? "online" : "retail";
			return {
				...state,
				pendings: {
					...state.pendings,
					[projectType]: {
						...state.pendings[projectType],
						sorting: {
							...state.pendings[projectType].sorting,
							page: 1
						},
						filters: payload.filters
					}
				}
			};
		case SET_SETTLED_BETSLIPS:
			projectType = payload.projectType === PROJECT_TYPE.ONLINE ? "online" : "retail";
			return {
				...state,
				settled: {
					...state.settled,
					[projectType]: {
						...state.settled[projectType],
						bets: payload.data.item2,
						total: payload.data.item1
					}
				}
			};
		case SET_SETTLED_BETSLIPS_SORTING:
			projectType = payload.projectType === PROJECT_TYPE.ONLINE ? "online" : "retail";
			return {
				...state,
				settled: {
					...state.settled,
					[projectType]: {
						...state.settled[projectType],
						sorting: payload.sorting
					}
				}
			};
		case SET_SETTLED_BETSLIPS_FILTERS:
			projectType = payload.projectType === PROJECT_TYPE.ONLINE ? "online" : "retail";
			return {
				...state,
				settled: {
					...state.settled,
					[projectType]: {
						...state.settled[projectType],
						sorting: {
							...state.settled[projectType].sorting,
							page: 1
						},
						filters: payload.filters
					}
				}
			};
		case SET_BETSLIPS_REQUESTS:
			return {
				...state,
				requests: {
					...state.requests,
					requests: payload.data.item2,
					total: payload.data.item1
				}
			};
		case SET_BETSLIPS_REQUESTS_SORTING:
			return {
				...state,
				requests: {
					...state.requests,
					sorting: payload.sorting
				}
			};
		case SET_BETSLIPS_REQUESTS_FILTERS:
			return {
				...state,
				requests: {
					...state.requests,
					filters: payload.filters,
					sorting: {
						...state.requests.sorting,
						page: 1
					}
				}
			};
		case SET_BETSLIPS_DB_CACHE_COMPARE:
			return {
				...state,
				dbCacheCompare: {
					...state.dbCacheCompare,
					data: payload.data
				}
			};
		default:
			return state;
	}
};
