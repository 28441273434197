import { useMemo } from "react";
import PropTypes from "prop-types";
import { convertCurrencies } from "utils/currency";
const getDefaultResult = () => ({ companies: [], turnovers: [], ggrs: [], betCounts: [] });

const usePartnersRevenueData = (data, currency, currencies) => {
	const optionCombination = useMemo(
		() =>
			data.reduce((acc, elem) => {
				if (!Object.prototype.hasOwnProperty.call(acc, elem.partnerName)) {
					acc[elem.partnerName] = { currencyCode: elem.currencyCode, data: { turnover: 0, ggr: 0, betCount: 0 } };
				}
				const convertedTurnoverString = convertCurrencies(elem.turnover, elem.currencyCode, currency.code, currencies, elem.partnerId);
				const convertedGGRString = convertCurrencies(elem.ggr, elem.currencyCode, currency.code, currencies, elem.partnerId);
				acc[elem.partnerName].data.turnover += Number(convertedTurnoverString) || 0;
				acc[elem.partnerName].data.ggr += Number(convertedGGRString) || 0;
				acc[elem.partnerName].data.betCount += elem.betCount;
				return acc;
			}, {}),
		[data, currencies, currency]
	);

	return useMemo(() => {
		const defaultRetVal = getDefaultResult();
		if (!optionCombination) {
			return defaultRetVal;
		}
		return Object.entries(optionCombination).reduce(
			(
				acc,
				[
					company,
					{
						data: { turnover, ggr, betCount }
					}
				]
			) => {
				acc.companies.push(company);
				acc.turnovers.push({ y: turnover, color: "#ABD47C" });
				acc.ggrs.push({ y: ggr, color: ggr < 0 ? "#E64E48" : "#46904A", className: "innerPoint" });
				acc.betCounts.push({ y: betCount, color: "#47B4A9" });
				return acc;
			},
			defaultRetVal
		);
	}, [optionCombination]);
};

usePartnersRevenueData.PropTypes = {
	data: PropTypes.object,
	currency: PropTypes.object,
	currencies: PropTypes.arrayOf(PropTypes.object)
};

export default usePartnersRevenueData;
