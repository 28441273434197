import PropTypes from "prop-types";

import { USER_GENDER, USER_ROLE } from "constants/user.constants";

export default PropTypes.shape({
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	userName: PropTypes.string,
	email: PropTypes.string,
	gender: PropTypes.oneOf(Object.values(USER_GENDER)),
	tel: PropTypes.string,
	address: PropTypes.string,
	allowEdit: PropTypes.bool,
	experationEnabled: PropTypes.bool,
	experationDate: PropTypes.string,
	isTesting: PropTypes.bool,
	avatarId: PropTypes.number,
	role: PropTypes.oneOf(Object.values(USER_ROLE))
});
