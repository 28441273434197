import PropTypes from "prop-types";

export default PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	from: PropTypes.string,
	to: PropTypes.string,
	ggr: PropTypes.number,
	ggrPercent: PropTypes.number,
	turnoverAmount: PropTypes.number,
	wonAmount: PropTypes.number,
	canceledAmount: PropTypes.number,
	count: PropTypes.number,
	wonCount: PropTypes.number,
	canceledCount: PropTypes.number,
	currencyCode: PropTypes.string
});
