import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Button } from "antd";
import Table from "components/common/table";
import BlockedCountryAddComponent from "./blocked-country-add.component";

import apiKeyBlockedCountryType from "types/apiKey/blockedCountry.type";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { getApiKeyBlockedCountries, deleteApiKeyBlockedCountry } from "store/actions/dashboard/online/apikeys/blockedCountries.action";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { hasPermission } from "utils/permissions";

import systemCountries from "systemData/countries";

/** Api Key Edit Page IP Restriction Tab Blocked Countries SubTab Component */
const BlockedCountriesComponent = ({ countries, getApiKeyBlockedCountries, isLoading, deleteApiKeyBlockedCountry }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [showCreatePopup, setShowCreatePopup] = useState(false);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_IPRESTRICTIONS, action: PERMISSION_ACTION.DELETE });

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.apikeys.country"),
			dataIndex: "countryName",
			sorter: false
		},
		{
			title: t("pages.dashboard.apikeys.blocked_at"),
			dataIndex: "blockedAt",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT),
			sorter: false
		},
		{
			title: t("pages.dashboard.apikeys.blocked_by"),
			dataIndex: "blockedBy",
			sorter: false
		}
	];

	/** Close country add popup, after country saved */
	useEffect(() => {
		setShowCreatePopup(false);
	}, [countries]);

	return (
		<div className="dashboard-section-content">
			{hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_IPRESTRICTIONS, action: PERMISSION_ACTION.CREATE }) ? (
				<div className="dashboard-section-buttons">
					<Button
						type="primary"
						htmlType="button"
						className="button"
						onClick={() => {
							setShowCreatePopup(true);
						}}
						disabled={countries.length === Object.keys(systemCountries).length}
					>
						<span>{t("pages.dashboard.apikeys.add_country")}</span>
					</Button>
				</div>
			) : null}
			<Table
				loading={isLoading}
				columns={columns}
				data={countries}
				loadFn={() => getApiKeyBlockedCountries(routeParams.id)}
				total={countries.length}
				actions={{
					delete: !isDisabled
						? {
								icon: <i className="icon-lock" />,
								title: t("common.unblock"),
								messageKey: "blockedCountry",
								handler: (record) => {
									deleteApiKeyBlockedCountry(routeParams.id, record.countryCode);
								}
							}
						: null
				}}
				isDisabled={(record) => isDisabled || !record.enabled}
				noPagination={true}
				uniqueKey="countryCode"
			/>

			{showCreatePopup ? (
				<BlockedCountryAddComponent
					onClose={() => {
						setShowCreatePopup(false);
					}}
				/>
			) : null}
		</div>
	);
};

/** BlockedCountriesComponent propTypes
 * PropTypes
 */
BlockedCountriesComponent.propTypes = {
	/** Redux action to get api key blocked countries */
	getApiKeyBlockedCountries: PropTypes.func,
	/** Redux state property, represents the array of blocked countries of api key */
	countries: PropTypes.arrayOf(apiKeyBlockedCountryType),
	/** Redux state property, is true when loading api key blocked countries */
	isLoading: PropTypes.bool,
	/** Redux action to delete blocked country */
	deleteApiKeyBlockedCountry: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getApiKeyBlockedCountries: (id) => {
		dispatch(getApiKeyBlockedCountries(id));
	},
	deleteApiKeyBlockedCountry: (id, countryCode) => {
		dispatch(deleteApiKeyBlockedCountry(id, countryCode));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.apikeys.editingApikey.ipRestriction.blockedCountries.isLoading,
		countries: state.apikeys.editingApikey.ipRestriction.blockedCountries.countries
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockedCountriesComponent);
