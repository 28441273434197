import { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Tag, Button } from "antd";

import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { SURVEY_STATE } from "constants/survey.constants";

import { getSurveys, setSurveysSorting } from "store/actions/dashboard/usersFeedback/CSAT/surveys.action";
import { getSurveyQuickInfo } from "store/actions/dashboard/usersFeedback/CSAT/quickInfo.action";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import ColumnsButton from "components/common/columnsButton";
import ExportButton from "components/common/exportButton";
import AddSurveyPopup from "components/dashboard/usersFeedback/CSAT/surveys-add.component";
import UserSurveyQuickInfo from "components/dashboard/usersFeedback/CSAT/quickInfo/quickinfo.component";
import SurveyStateChangeComponent from "components/dashboard/usersFeedback/CSAT/survey-state-change.component";

import sortingType from "types/common/sorting.type";
import surveyType from "types/survey/survey.type";

import Paths from "constants/path.constants";
import ApiUrls from "constants/api.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import localStorageUtils from "utils/localStorage";

/** CSAT Page Component */
const CustomerSatisfactionCoreSurvey = ({ isLoading, surveys, getSurveys, getSurveyQuickInfo, total, sorting, setSurveysSorting }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	/** Columns list, which can be included/excluded */
	const columnsToInclude = [
		{ title: t("pages.dashboard.usersFeedback.created_at"), key: "createdAt" },
		{ title: t("pages.dashboard.usersFeedback.created_by"), key: "createdBy" },
		{ title: t("pages.dashboard.usersFeedback.csat_id"), key: "id" },
		{ title: t("pages.dashboard.usersFeedback.last_modified_at"), key: "lastUpdatedAt" },
		{ title: t("pages.dashboard.usersFeedback.last_modified_by"), key: "lastUpdatedBy" }
	];

	const [includedColumns, setIncludedColumns] = useState((localStorageUtils.get("selected_columns") || {})["surveys"] || []);

	/** State to show/hide Survey create popup */
	const [showSurveyCreatePopup, setShowSurveyCreatePopup] = useState(false);

	/** The data of the selected survey, for which quick info is showing */
	const [selectedSurveyId, setSelectedSurveyId] = useState(null);

	/** State to show/hide survey state change popup */
	const [showStateChangePopup, setShowStateChangePopup] = useState(null);

	const getStateColor = (state) => {
		const mapping = {
			[SURVEY_STATE.ACTIVE]: "green",
			[SURVEY_STATE.FINISHED]: "blue",
			[SURVEY_STATE.INACTIVE]: "#4D4D4D26"
		};
		return mapping[state];
	};

	const handleStateTagClick = (e, record) => {
		e.stopPropagation();
		setShowStateChangePopup(record.id);
	};

	const renderStateComponent = (status, survey) => (
		<Tag color={getStateColor(status)}>
			<Fragment>
				{t("pages.dashboard.usersFeedback.status_" + (status || SURVEY_STATE.FINISHED))}
				{hasPermission({ resource: PERMISSION_RESOURCE.SURVEYS, action: PERMISSION_ACTION.MODIFY }) && status === SURVEY_STATE.ACTIVE && <i className="icon-edit" onClick={(e) => handleStateTagClick(e, survey)} />}
			</Fragment>
		</Tag>
	);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.usersFeedback.csat_id"),
			dataIndex: "id"
		},
		{
			title: t("pages.dashboard.usersFeedback.csat_name"),
			dataIndex: "name",
			render: (name, { id }) => (
				<span className="table-col-link" onClick={() => setSelectedSurveyId(id)}>
					{name}
				</span>
			),
			sorter: false
		},
		{
			title: t("pages.dashboard.usersFeedback.csat_start_date"),
			dataIndex: "startDate",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.usersFeedback.csat_end_date"),
			dataIndex: "endDate",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-")
		},
		{
			title: t("pages.dashboard.usersFeedback.created_at"),
			dataIndex: "createdAt",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.usersFeedback.created_by"),
			dataIndex: "createdBy"
		},
		{
			title: t("pages.dashboard.usersFeedback.last_modified_at"),
			dataIndex: "lastUpdatedAt",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.usersFeedback.last_modified_by"),
			dataIndex: "lastUpdatedBy"
		},
		{
			title: t("pages.dashboard.usersFeedback.total_participants"),
			dataIndex: "playersCount",
			sorter: false
		},
		{
			title: t("pages.dashboard.usersFeedback.response_rate"),
			dataIndex: "responseRate",
			sorter: false
		},
		{
			title: t("pages.dashboard.usersFeedback.csat_score"),
			dataIndex: "responseScore",
			sorter: false
		},
		{
			title: t("pages.dashboard.usersFeedback.space"),
			dataIndex: "projectType",
			render: (value) => <Fragment>{t(`pages.dashboard.usersFeedback.${value === PROJECT_TYPE.BACKOFFICE ? "backoffice_users" : value === PROJECT_TYPE.ONLINE ? "site_players" : "retail_users"}`)}</Fragment>,
			sorter: false
		},
		{
			title: t("pages.dashboard.usersFeedback.status"),
			dataIndex: "state",
			render: (value, record) => renderStateComponent(value, record),
			sorter: false
		},
		{
			title: t("pages.dashboard.usersFeedback.partners"),
			dataIndex: "partners",
			multi: true,
			multiMapper: (value) => value,
			sorter: false
		}
	];

	/** Keep included columns in local storage */
	useEffect(() => {
		const columns = localStorageUtils.get("selected_columns") || {};
		columns["surveys"] = includedColumns;
		localStorageUtils.set("selected_columns", columns);
	}, [includedColumns]);

	/** Load survey general info*/
	useEffect(() => {
		if (selectedSurveyId !== null) {
			getSurveyQuickInfo(selectedSurveyId);
		}
	}, [selectedSurveyId]);

	const generateAction = () => {
		let key = null;

		if (hasPermission({ resource: PERMISSION_RESOURCE.SURVEYS, action: PERMISSION_ACTION.VIEW })) {
			key = "view";
		}

		if (hasPermission({ resource: PERMISSION_RESOURCE.SURVEYS, action: PERMISSION_ACTION.MODIFY })) {
			key = "edit";
		}

		if (!key) {
			return {};
		}

		return {
			[key]: {
				handler: (survey) => {
					navigate(Paths.DASHBOARD_USERS_FEEDBACK_CSAT_EDIT.replace(DYNAMIC_PATH_ID_REGEX, survey.id));
				}
			}
		};
	};

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.csat") }]} />
				<div className="dashboard-section-content">
					<div className="table-header">
						<div className="table-buttons-dropdowns">
							{hasPermission({ resource: PERMISSION_RESOURCE.SURVEYS, action: PERMISSION_ACTION.EXPORT }) ? <ExportButton columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t("pages.dashboard.menu.csat")} url={ApiUrls.EXPORT_SURVEYS} sorting={sorting} /> : null}
							<ColumnsButton columns={columnsToInclude} onApply={(selectedColumns) => setIncludedColumns(selectedColumns)} defaultSelectedColumns={includedColumns} />
							{hasPermission({ resource: PERMISSION_RESOURCE.SURVEYS, action: PERMISSION_ACTION.CREATE }) ? (
								<div className="table-buttons-dropdowns-button table-buttons-dropdowns-button-addSurvey">
									<Button onClick={() => setShowSurveyCreatePopup(true)} type="primary">
										{t("pages.dashboard.usersFeedback.add_survey")}
									</Button>
								</div>
							) : null}
						</div>
					</div>

					<Table
						loading={isLoading}
						columns={columns.filter((c) => !columnsToInclude.find((col) => col.key === c.dataIndex) || includedColumns.includes(c.dataIndex))}
						data={surveys}
						loadFn={getSurveys}
						sorting={sorting}
						setSortingFn={setSurveysSorting}
						total={total}
						actions={generateAction()}
					/>

					{selectedSurveyId !== null ? <UserSurveyQuickInfo onClose={() => setSelectedSurveyId(null)} /> : null}
				</div>
			</div>

			{showStateChangePopup ? <SurveyStateChangeComponent onClose={() => setShowStateChangePopup(null)} id={showStateChangePopup} /> : null}
			{showSurveyCreatePopup ? <AddSurveyPopup onClose={() => setShowSurveyCreatePopup(false)} /> : null}
		</Fragment>
	);
};

CustomerSatisfactionCoreSurvey.propTypes = {
	/** Redux state property, is true when loading surveys */
	isLoading: PropTypes.bool,
	/** Redux state property, surveys total count */
	total: PropTypes.number,
	/** Redux state property, represents the array of surveys */
	surveys: PropTypes.arrayOf(surveyType),
	/** Redux action to get surveys */
	getSurveys: PropTypes.func,
	/** Redux action to get survey general info */
	getSurveyQuickInfo: PropTypes.func,
	/** Redux state property, surveys sorting details */
	sorting: sortingType,
	/** Redux action to set user surveys sorting details */
	setSurveysSorting: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getSurveys: (fromFirstPage) => {
		dispatch(getSurveys(fromFirstPage));
	},
	getSurveyQuickInfo: (surveyId) => {
		dispatch(getSurveyQuickInfo(surveyId));
	},
	setSurveysSorting: (sorting) => {
		dispatch(setSurveysSorting(sorting));
	}
});

const mapStateToProps = (state) => ({
	isLoading: state.surveys.isLoading,
	surveys: state.surveys.surveys,
	total: state.surveys.total,
	sorting: state.surveys.sorting
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSatisfactionCoreSurvey);
