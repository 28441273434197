import BannerPreviewSection from "./BannerPreviewSection";

const PreviewPenaltyBanners = ({ gameType, gameId, partnerId, banners = {} }) => {

	const buildSrc = (bannerName, bannerImg, index) => {
		const partner = partnerId ? partnerId : "system";
		const size = ["mp4", "webm"].includes(bannerImg.format) ? "large": "small";
		const path = `/games/${gameId}/${bannerName}_${index}_${bannerImg.id}_${size}.${bannerImg.format}`.toLowerCase();
		return `${import.meta.env.SYSTEM_CDN_URL}/${partner}${path}`;
	}

	const {
		pitchsideBanner1,
		pitchsideBanner2,
		pitchsideLEDBanner,
		pitchsideAdvertisingBanner1,
		pitchsideAdvertisingBanner2,
		pitchsideAdvertisingBanner3
	} = Object.keys(banners).reduce((acc, bannerName) => {
		const bannerArr = banners[bannerName];

		if (bannerArr.length !== 0) {
			
			if (bannerArr.length === 1) {
				acc[bannerName] = { src: buildSrc(bannerName, bannerArr[0], 0), file: bannerArr[0] };
			} else {
				bannerArr.forEach((bannerImg, i) => {
					const num = i + 1;
					acc[bannerName + num] = { src: buildSrc(bannerName, bannerImg, i), file: bannerImg };
				});
			}

		}

		return acc;
	}, {});

	return (
		<div className="vs--banner-adjustment-preview" data-game-type={gameType}>
			<div className="vs--banner-adjustment-preview-scene"></div>
			<div className="vs--banner-adjustment-preview-pitchsideBanner vs--banner-adjustment-preview-pitchsideBanner-1">
				<BannerPreviewSection srcObj={pitchsideBanner1} />
			</div>
			<div className="vs--banner-adjustment-preview-pitchsideBanner vs--banner-adjustment-preview-pitchsideBanner-2">
				<BannerPreviewSection srcObj={pitchsideBanner2} />
			</div>
			<div className="vs--banner-adjustment-preview-pitchsideLEDBanner">
				<BannerPreviewSection srcObj={pitchsideLEDBanner} />
			</div>
			<div className="vs--banner-adjustment-preview-pitchsideAdvertisingBanner vs--banner-adjustment-preview-pitchsideAdvertisingBanner-1">
				<BannerPreviewSection srcObj={pitchsideAdvertisingBanner1} />
			</div>
			<div className="vs--banner-adjustment-preview-pitchsideAdvertisingBanner vs--banner-adjustment-preview-pitchsideAdvertisingBanner-2">
				<BannerPreviewSection srcObj={pitchsideAdvertisingBanner2} />
			</div>
			<div className="vs--banner-adjustment-preview-pitchsideAdvertisingBanner vs--banner-adjustment-preview-pitchsideAdvertisingBanner-3">
				<BannerPreviewSection srcObj={pitchsideAdvertisingBanner3} />
			</div>
		</div>
	)
}
export default PreviewPenaltyBanners