import PropTypes from "prop-types";

export default PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	code: PropTypes.string,
	groupId: PropTypes.string,
	address: PropTypes.string,
	tel: PropTypes.string,
	email: PropTypes.string,
	ticketLanguageCode: PropTypes.string,
	liveMonitorLanguageCode: PropTypes.string,
	enabled: PropTypes.bool,
	logoId: PropTypes.number,
	dst: PropTypes.oneOf([0, -1, 1])
});
