import store from "store/configureStore";
import { getUser } from "./auth";
import { PERMISSION_RESOURCE, PERMISSION_ACTION, ACCESS_MANAGER_PERMISSION } from "constants/permissions.constants";
import { USER_ROLE, USER_ROLE_NAME } from "constants/user.constants";

/** Get current user permissions
 * @function
 * @returns {Object}
 */
export const getPermissions = () => {
	const userInfo = store.getState().profile.userInfo;
	if (getUser()?.role === USER_ROLE_NAME[USER_ROLE.ACCESS_MANAGER]) {
		return ACCESS_MANAGER_PERMISSION;
	}
	return userInfo?.permissions ?? null;
};

/** Check if has super admin permission
 * @function
 * @returns {boolean}
 */
export const hasSuperAdminPermission = () => {
	return getUser()?.role === USER_ROLE_NAME[USER_ROLE.SUPER_ADMIN];
};

/** Check if has developer permission
 * @function
 * @returns {boolean}
 */
export const hasDeveloperPermission = () => {
	return getUser()?.role === USER_ROLE_NAME[USER_ROLE.DEVELOPER];
};

/** Check if has permission
 * @function
 * @param {string} permission - permission
 * @returns {boolean}
 */
export const hasPermission = (permission) => {
	const permissions = getPermissions();
	const resource = permissions?.[permission?.resource] ?? [];
	// if (hasSuperAdminPermission()) return true;

	// if (permission.resource === PERMISSION_RESOURCE.DEVELOPER) {
	// 	return hasDeveloperPermission();
	// }
	return resource.includes(permission.action);
};

/** Check if has permissions
 * @function
 * @param {array} permissions - permissions array
 * @returns {boolean}
 */
export const hasPermissions = (permissions) => {
	if (hasSuperAdminPermission()) return true;

	return permissions.every((perm) => hasPermission(perm));
};

/** Check if has one of permissions
 * @function
 * @param {array} permissions - permissions array
 * @returns {boolean}
 */
export const hasOneOfPermissions = (permissions) => {
	if (hasSuperAdminPermission()) return true;
	return permissions.some((perm) => hasPermission(perm));
};
