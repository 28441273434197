import { useState } from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

(function (H) {
	function stopEvent(e) {
		if (e) {
			if (e.preventDefault) {
				e.preventDefault();
			}
			if (e.stopPropagation) {
				e.stopPropagation();
			}
			e.cancelBubble = true;
		}
	}
	H.wrap(H.Chart.prototype, "render", function (proceed) {
		const chart = this;
		proceed.call(chart);
		H.addEvent(chart.container, "wheel", function (event) {
			const axis = chart.xAxis[0];
			const dataMax = chart.xAxis[0].dataMax;
			const dataMin = chart.xAxis[0].dataMin;
			const e = chart.pointer.normalize(event);
			let delta;
			delta = e.detail || -(e.deltaY / 120);
			delta = delta < 0 ? 1 : -1;
			if (chart.isInsidePlot(e.chartX - chart.plotLeft, e.chartY - chart.plotTop)) {
				const extr = axis.getExtremes();
				const step = ((extr.max - extr.min) / 5) * delta;
				let newExtrMin;
				let newExtrMax;
				if (extr.min + step <= dataMin) {
					newExtrMin = dataMin;
					newExtrMax = dataMin + (extr.max - extr.min);
				} else if (extr.max + step >= dataMax) {
					newExtrMin = dataMax - (extr.max - extr.min);
					newExtrMax = dataMax;
				} else {
					newExtrMin = extr.min + step;
					newExtrMax = extr.max + step;
				}
				axis.setExtremes(newExtrMin, newExtrMax, true, false);
			}
			stopEvent(event); // Issue #5011, returning false from non-jQuery event does not prevent default
			return false;
		});
	});
})(Highcharts);

const GetInitialChartOptions = (confObj) => ({
	accessibility: {
		enabled: false
	},
	chart: {
		type: "bar",
		height: 500
	},
	xAxis: {
		type: "category",
		gridLineWidth: 1,
		gridLineDashStyle: "longdash",
		min: 0,
		max: Math.min(9, Math.max(confObj.categories.length - 1, 1)),
		scrollbar: {
			enabled: true
		}
	},
	yAxis: [
		{
			gridLineWidth: 1,
			gridLineDashStyle: "longdash",
			title: {
				text: null
			}
		}
	],
	tooltip: {
		shared: true,
		valueSuffix: " " + confObj.tooltipSymbol
	},
	plotOptions: {
		bar: {
			grouping: false,
			borderWidth: 0,
			gridLineWidth: 0,
			dataLabels: {
				enabled: true,
				color: "#000000",
				style: {
					fontSize: 10 + "px",
					textShadow: false
				}
			}
		}
	},
	title: {
		text: ""
	},
	legend: {
		enabled: false
	},
	series: [
		{
			name: null,
			color: "#E64E48",
			dataLabels: {
				align: "left"
			},
			data: [],
			pointWidth: 20,
			animation: {
				duration: 300
			}
		},
		{
			name: null,
			color: "#46904A",
			data: [],
			pointWidth: 14,
			animation: {
				duration: 300
			}
		}
	]
});

const ChartGenerator = ({ tooltipSymbol, categories, series }) => {
	const initialChartOptions = GetInitialChartOptions({ tooltipSymbol, categories });
	const Chart = () => {
		const [chartOptions, setChartOptions] = useState(initialChartOptions);
		return <HighchartsReact highcharts={Highcharts} options={chartOptions} callback={() => setChartOptions({ xAxis: { categories }, series })} />;
	};
	return Chart;
};

ChartGenerator.propTypes = {
	tooltipSymbol: PropTypes.string,
	categories: PropTypes.arrayOf(PropTypes.string),
	series: PropTypes.arrayOf(PropTypes.object)
};

export default ChartGenerator;
