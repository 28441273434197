import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Modal, Row, Col, Form } from "antd";
const { Item: FormItem } = Form;

import BarCode from "components/common/barCode";
import SearchableSelect from "components/common/searchableSelect";

import { getBonusPrintVouchers, setBonusPrintVouchers } from "store/actions/dashboard/bonuses/bonuses.action";
import { getBetTicket } from "store/actions/dashboard/cms/tickets/ticket.action";
import { getSystemAvailablePublishedLanguages } from "store/actions/dashboard/settings/systemLanguages/systemLanguages.action";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import { binaryToFlags, multiPrintElement } from "utils/common";

import PATHS from "constants/api.constants";
import { SCHEDULED_GAME_TYPE, SCHEDULED_GAME_TYPE_MAPPER } from "constants/game.constants";
import { BETSLIP_MODES } from "constants/bet.constants";
import { POPUP_SIZE } from "constants/common.constants";

import ticketType from "types/partner/ticket.type";
import betTicketType from "types/partner/betTicket.type";

/** Results print Component */
const ResultsTicket = ({ data, printVouchers, printVouchersTranslations, getBetTicket, getBonusPrintVouchers, setBonusPrintVouchers, onPrint, ticket, globalPartnerId, getSystemAvailablePublishedLanguages, systemLanguages, ...rest }) => {
	const { t } = useTranslation();

	const [showModal, setShowModal] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState("en");

	const [formInstance] = Form.useForm();

	/** Load system languages */
	useEffect(() => {
		getSystemAvailablePublishedLanguages();

		return () => {
			setBonusPrintVouchers([]);
		};
	}, []);

	/** Load partner bet ticket */
	useEffect(() => {
		globalPartnerId && getBetTicket();
	}, [globalPartnerId]);

	/** Print on component mount */
	useEffect(() => {
		if (printVouchers.length) {
			multiPrintElement(
				printVouchers.map((v) => v.id),
				(id) => {
					if (id === printVouchers[printVouchers.length - 1].id) {
						setTimeout(() => {
							onPrint && onPrint();
						}, 0);
					}
				}
			);
		}
	}, [printVouchers]);

	useEffect(() => {
		setShowModal(true);
	}, []);

	/** Function to make text of nullable fields
	 * @function
	 * @param {number || string} nullableNumber
	 * @param {object} option
	 * @returns {string}
	 * @memberOf ResultsTicket
	 */
	const renderNullableNumber = (nullableNumber, option = { valueMutation: (val) => val, defaultValue: " - " }) => {
		if (typeof nullableNumber === "number") {
			return option.valueMutation(nullableNumber);
		}
		return option.valueMutation(nullableNumber) || option.defaultValue;
	};

	return (
		<Fragment>
			{printVouchers.map((voucher, i) => {
				return (
					<div id={voucher?.id ?? i} key={voucher?.id ?? i} className="vs--print-wrapper vs--print-wrapper-hidden">
						<div className="vs--print">
							<div className="vs--print-header">
								{ticket?.ticketLogoId ? <img src={`${import.meta.env.SYSTEM_API_URL}${PATHS.PARTNER_GET_TICKET_LOGO}/${globalPartnerId}/${ticket.ticketLogoId}`} alt="logo" className="print-header-logo" /> : <span>{t("pages.dashboard.tickets.view.header_logo")}</span>}
								<div className="vs--print-header-title">
									<h1 className="print-header-title-tag">
										{(() => {
											const texts = (printVouchersTranslations?.freebetText ?? "").split("%%amount%%");
											return (
												<>
													{texts[0]}
													<br />
													{(voucher?.amount ?? 0) + (texts[1]?.replace("%%currency%%", voucher?.currency ?? "") ?? "")}
												</>
											);
										})()}
									</h1>
								</div>
							</div>
							<div className="vs--print-header-sub">
								<div className="vs--print-header-sub-title">
									<i className="icon-virtual-games" />
									<h1>{t("common.virtualSports")}</h1>
								</div>
							</div>

							<div className="vs--print-info vs--print-info-dashed vs--pb-6" data-title={printVouchersTranslations?.usageRules ?? ""}>
								<div className="vs--print-freebet-id vs--print-info-row">
									<span>{t("pages.dashboard.bonuses.vaucher.freebetId")}</span>
									<span className="vs--print-info-row-freeid">{voucher?.bonusId ?? " - "}</span>
								</div>

								<div className="vs--print-bet-on vs--print-info-row">
									<span>{printVouchersTranslations?.betOn}</span>
									<span>
										{binaryToFlags(
											Object.values(SCHEDULED_GAME_TYPE).map((v) => v.value),
											voucher?.gameType ?? 0
										)
											.map((value) => SCHEDULED_GAME_TYPE_MAPPER[value])
											.map((str) => (printVouchersTranslations?.[str] ?? ""))
											.join(",\n")}
									</span>
								</div>

								<div className="vs--print-info-row">
									<span>{printVouchersTranslations?.betType}</span>
									<span>
										{binaryToFlags(Object.values(BETSLIP_MODES), voucher?.betType ?? 0)
											.map((mode) => (mode === BETSLIP_MODES.SINGLE ? (printVouchersTranslations?.single ?? "") : (printVouchersTranslations?.multi ?? "")))
											.join(",\n")}
									</span>
								</div>
								<div className="vs--print-info-row">
									<span>{printVouchersTranslations?.validTill}</span>
									<span>{moment.utc(voucher?.endDate ?? new Date()).format(DATE_TIME_FORMAT)}</span>
								</div>
							</div>

							<div className="vs--print-info">
								<div className="vs--print-info-bet">
									<div className="vs--print-info-row">
										<span>{printVouchersTranslations?.minOddsSingle}</span>
										<span>{renderNullableNumber(voucher.minOddsSingle)}</span>
									</div>
									<div className="vs--print-info-row">
										<span>{printVouchersTranslations?.minOddsMulti}</span>
										<span>{renderNullableNumber(voucher.minOddsMulti)}</span>
									</div>
									<div className="vs--print-info-row">
										<span>{printVouchersTranslations?.maxPossibleWin}</span>
										<span>
											{renderNullableNumber(voucher.maxPossibleWinAmount, {
												valueMutation: (value) => (value ? `${value} ${voucher?.currency}` : value),
												defaultValue: " - "
											})}
										</span>
									</div>
									<div className="vs--print-info-row">
										<span>{printVouchersTranslations?.betshops}</span>
										<span className="vs--print-info-row-address vs--betshop-address">
											{Object.values((voucher?.betshopNamesAndAdresses ?? {})).map((key, i, arr) => {
												return (
													<span key={key}>
														{key}
														{arr.length !== i + 1 ? "," : null}
													</span>
												);
											})}
										</span>
									</div>
								</div>
							</div>

							<div className="vs--print-footer">
								<div className="vs--print-footer-inner">
									<div className="vs--print-footer-barcode">
										<Fragment>
											<BarCode value={voucher?.id} />
										</Fragment>
									</div>
								</div>
								<div className="vs--print-footer-inner">{voucher?.id}</div>
								<div className="vs--print-footer-inner">
									<div className="vs--print-footer-title">
										<h1 className="vs--font-bold">
											{(() => {
												const texts = (printVouchersTranslations?.voucherFooterText ?? "").split("\n");
												return (
													<>
														{texts[0]}
														<br />
														{texts[1]}
													</>
												);
											})()}
										</h1>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			})}
			<Modal
				open={showModal}
				title={t("common.print")}
				cancelText={t("common.cancel")}
				okText={t("common.print")}
				onOk={() => {
					setShowModal(false);
					getBonusPrintVouchers(data.id, selectedLanguage);
				}}
				onCancel={() => {
					setShowModal(false);
					onPrint && onPrint();
				}}
				width={POPUP_SIZE.SMALL}
				maskClosable={false}
				closable={false}
				centered
				{...rest}
			>
				<Form className="dashboard-form" form={formInstance} colon={false} layout="vertical" requiredMark={false}>
					<Row gutter={[16, 0]}>
						<Col span={24}>
							<FormItem label={`${t("pages.dashboard.bonuses.language")} *`}>
								<SearchableSelect
									placeholder={`${t("common.select")} ${t("pages.dashboard.bonuses.language")}`}
									items={Object.keys(systemLanguages)}
									valueProp={(item) => item}
									textProp={(item) => systemLanguages[item]}
									renderText={(item) => systemLanguages[item]}
									getPopupContainer={() => document.body}
									onChange={(v) => setSelectedLanguage(v)}
									value={selectedLanguage}
								/>
							</FormItem>
						</Col>
					</Row>
				</Form>
			</Modal>
		</Fragment>
	);
};

/** ResultsTicket propTypes
 * PropTypes
 */
ResultsTicket.propTypes = {
	/** bonus data */
	data: PropTypes.object,
	/** Redux state property, represents vouchers for printing */
	printVouchers: PropTypes.array,
	/** Redux state property, represents translations for print */
	printVouchersTranslations: PropTypes.object,
	/** Function to call after Print */
	onPrint: PropTypes.func,
	/** Redux action to show/hide printing modal */
	getBonusPrintVouchers: PropTypes.func,
	/** Redux action to set print voucher data */
	setBonusPrintVouchers: PropTypes.func,
	/** Redux state, represents the current loaded ticket(ticket in server)  */
	ticket: betTicketType,
	/** Redux state, represents the current editing ticket(ticket in preview)  */
	currentTicket: ticketType,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux action to get partner bet ticket */
	getBetTicket: PropTypes.func,
	/** Redux action to get system available languages */
	getSystemAvailablePublishedLanguages: PropTypes.func,
	/** Redux state property, represents the array of loaded system languages */
	systemLanguages: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		printVouchers: state.bonuses.standard.printVouchers,
		printVouchersTranslations: state.bonuses.standard.printVouchersTranslations,
		ticketLogoId: state.tickets.ticketLogoId,
		globalPartnerId: state.partner.globalPartnerId,
		ticket: state.tickets.betTicket,
		systemLanguages: state.systemLanguages.systemAvailableLanguages
	};
};
const mapDispatchToProps = (dispatch) => ({
	getBonusPrintVouchers: (id, languageCode) => {
		dispatch(getBonusPrintVouchers(id, languageCode));
	},
	setBonusPrintVouchers: (arg) => {
		dispatch(setBonusPrintVouchers(arg));
	},
	getBetTicket: () => {
		dispatch(getBetTicket());
	},
	getSystemAvailablePublishedLanguages: () => {
		dispatch(getSystemAvailablePublishedLanguages());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultsTicket);
