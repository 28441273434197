export const DASHBOARD_PERIOD = {
	TODAY: 1,
	YESTERDAY: 2,
	LAST_MONTH: 3,
	LAST_3_MONTH: 4
};

export const DASHBOARD_PERIOD_TRANSLATION = {
	[DASHBOARD_PERIOD.TODAY]: "pages.dashboard.dashboard.today",
	[DASHBOARD_PERIOD.YESTERDAY]: "pages.dashboard.dashboard.yesterday",
	[DASHBOARD_PERIOD.LAST_MONTH]: "common.this_month",
	[DASHBOARD_PERIOD.LAST_3_MONTH]: "common.last_3_months"
};

export const DASHBOARD_GAME_PERFORMANCE_METRICS = {
	GGR: "ggrAmount",
	BETS_COUNT: "betsCount",
	TURNOVER: "turnover"
};

export const DASHBOARD_GAME_PERFORMANCE_METRICS_TRANSLATION = {
	[DASHBOARD_GAME_PERFORMANCE_METRICS.GGR]: "pages.dashboard.dashboard.ggrAmount",
	[DASHBOARD_GAME_PERFORMANCE_METRICS.BETS_COUNT]: "pages.dashboard.dashboard.betCount",
	[DASHBOARD_GAME_PERFORMANCE_METRICS.TURNOVER]: "pages.dashboard.dashboard.turnover"
};

export const DASHBOARD_GAME_PERFORMANCE_DATA_CATEGORY = {
	NUMBER: 1,
	PERCENT: 2
};

export const DASHBOARD_GAME_PERFORMANCE_DATA_CATEGORY_TRANSLATION = {
	[DASHBOARD_GAME_PERFORMANCE_DATA_CATEGORY.NUMBER]: "pages.dashboard.dashboard.number",
	[DASHBOARD_GAME_PERFORMANCE_DATA_CATEGORY.PERCENT]: "pages.dashboard.dashboard.percent"
};
