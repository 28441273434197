import { Fragment } from "react";

import CustomerSatisfactionCoreSurvey from "components/dashboard/usersFeedback/CSAT/CSAT.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const CSATRoute = () => {
	return (
		<Fragment>
			<CustomerSatisfactionCoreSurvey />
		</Fragment>
	);
};

export default withPermission(CSATRoute, { resource: PERMISSION_RESOURCE.SURVEYS, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
