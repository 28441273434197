import PropTypes from "prop-types";

export default PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	games: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string
		})
	),
	display1: PropTypes.arrayOf(PropTypes.string),
	display2: PropTypes.arrayOf(PropTypes.string)
});
