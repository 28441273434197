import { Fragment } from "react";

import StreamsEditComponent from "components/dashboard/streams/edit/streams-edit.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const StreamsEditRoute = () => {
	return (
		<Fragment>
			<StreamsEditComponent />
		</Fragment>
	);
};

export default withPermission(StreamsEditRoute, { resource: PERMISSION_RESOURCE.STREAMS, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD_STREAMS);
