import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { saveBonusGeneralInfo } from "store/actions/dashboard/bonuses/generalInfo.action";
import { getAvailableBetshops, setAvailableBetshops, getAvailableGames, setAvailableGames } from "store/actions/dashboard/bonuses/bonuses.action";

import { Form, Row, Spin, Button } from "antd";
import moment from "moment";
import FreebetView from "../../../CreateBonus/FreebetView";
import DoubleDoobleView from "../../../CreateBonus/DoubleDoobleView";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";
import { binaryToFlags } from "utils/common";
import { getSelectedValues, createRequestBodyForBonuses } from "utils/bonuses";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { BONUS_TYPE, BONUS_STATE, ANTD_FORM_INITIAL_VALUES, FIELD_NAMES } from "constants/bonus.constants";
import { SCHEDULED_GAME_TYPE } from "constants/game.constants";
import { ALL_OPTION_ID } from "constants/common.constants";
import bonusGeneralInfoType from "types/bonus/generalInfo.type";

/** Bonus Edit Page General Info Tab Component */
const GeneralInfoComponent = ({ saveBonusGeneralInfo, getAvailableBetshops, getAvailableGames, setAvailableGames, setAvailableBetshops, betshops, games, isSaving, isLoading, generalInfo }) => {
	const { t } = useTranslation();
	const routeParams = useParams();
	const [antdForm] = Form.useForm();

	const [selectedBetshopIds, setSelectedBetshopIds] = useState([]);
	const [isFormTouched, setIsFormTouched] = useState(false);

	// antdFormInitialValuesRef will be set when data(generalInfo) is received
	const antdFormInitialValuesRef = useRef({});

	const handleFormChange = (_, formValues) => {
		setIsFormTouched(isFormChanged({ ...formValues }, { ...antdFormInitialValuesRef.current }));
	};

	const handleBetshopFieldChange = (betshopIds) => {
		const betshopIdList = getSelectedValues(betshopIds);

		// reset GAME_TYPE field value
		antdForm.setFieldsValue({
			[FIELD_NAMES.BETSHOP_IDS]: betshopIdList,
			[FIELD_NAMES.GAME_TYPE]: []
		});

		if (betshopIdList[0] === ALL_OPTION_ID) {
			setSelectedBetshopIds(betshops.map((betshop) => betshop.key));
			return;
		}

		setSelectedBetshopIds(betshopIdList);
	};

	const handleGameTypeFieldChange = (selectedGameTypeIds) => {
		antdForm.setFieldsValue({ [FIELD_NAMES.GAME_TYPE]: getSelectedValues(selectedGameTypeIds) });
	};

	const handleSaveButtonClick = () => {
		antdForm
			.validateFields()
			.then((fieldValuesObj) => {
				const requestBody = createRequestBodyForBonuses({
					fieldValuesObj,
					games,
					betshops
				});

				saveBonusGeneralInfo({
					...requestBody,
					id: routeParams.id
				});
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	// Set form fields values, when data is loaded
	useEffect(() => {
		if (Object.keys(generalInfo).length === 0) {
			return;
		}

		const allGameTypes = Object.values(SCHEDULED_GAME_TYPE).map((gameTypeObj) => gameTypeObj.value);
		const gameType = binaryToFlags(allGameTypes, generalInfo.gameType);

		const startDate = moment.utc(generalInfo.startDate).local();
		const endDate = moment.utc(generalInfo.endDate).local();

		antdFormInitialValuesRef.current = {
			...ANTD_FORM_INITIAL_VALUES,
			...generalInfo,
			gameType,
			endDate,
			startDate,
			date: [startDate, endDate]
		};

		antdForm.setFieldsValue({ ...antdFormInitialValuesRef.current });

		setSelectedBetshopIds(generalInfo.betshopIds);
	}, [generalInfo]);

	// Get available betshops for selected currency
	useEffect(() => {
		if (!generalInfo.currency) {
			return;
		}

		setAvailableBetshops([]);
		getAvailableBetshops(generalInfo.currency.toUpperCase());
	}, [generalInfo.currency]);

	// Get available game types for selected betshops
	useEffect(() => {
		if (selectedBetshopIds.length === 0) {
			return;
		}

		setAvailableGames([]);
		getAvailableGames(selectedBetshopIds);
	}, [selectedBetshopIds]);

	const bonusType = generalInfo?.bonusType;
	const isModifyDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.BONUS_STANDARD, action: PERMISSION_ACTION.MODIFY });
	const isBonusEditingDisabled = (
		isModifyDisabled || bonusType !== BONUS_TYPE.DOUBLEDOOBLE || generalInfo?.bonusState !== BONUS_STATE.INACTIVE
	);

	return (
		<Spin spinning={isLoading || games.length === 0} wrapperClassName="form-spin">
			<Form colon={false} form={antdForm} requiredMark={false} layout="vertical" initialValues={ANTD_FORM_INITIAL_VALUES} onValuesChange={handleFormChange}>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							{bonusType === BONUS_TYPE.FREEBET ? <FreebetView availableCurrencies={generalInfo.currency} betshops={betshops} games={games} isEditMode={true} isBonusEditingDisabled={isBonusEditingDisabled} t={t} /> : null}
							{bonusType === BONUS_TYPE.DOUBLEDOOBLE ? (
								<DoubleDoobleView handleGameTypeFieldChange={handleGameTypeFieldChange} handleBetshopFieldChange={handleBetshopFieldChange} availableCurrencies={generalInfo.currency} betshops={betshops} games={games} isEditMode={true} isBonusEditingDisabled={isBonusEditingDisabled} t={t} />
							) : null}
						</Row>
					</div>
					{!isBonusEditingDisabled ? (
						<Form.Item className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleSaveButtonClick} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</Form.Item>
					) : null}
				</div>
			</Form>
		</Spin>
	);
};

/** GeneralInfoComponent propTypes
 * PropTypes
 */
GeneralInfoComponent.propTypes = {
	/** Redux state property, is true when general info is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when general info is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the general info of current editing bonus  */
	generalInfo: bonusGeneralInfoType,
	/** Redux action to set the object of available currencies  */
	setAvailableBetshops: PropTypes.func,
	/** Redux action to save bonus General info */
	saveBonusGeneralInfo: PropTypes.func,
	/** Redux action for getting available betshops */
	getAvailableBetshops: PropTypes.func,
	/** Available betshops */
	betshops: PropTypes.array,
	/** Function for to get available games by selected betshopsIds */
	getAvailableGames: PropTypes.func,
	/** Redux action to set the object of available games  */
	setAvailableGames: PropTypes.func,
	/** Available games selected by betshopsIds */
	games: PropTypes.array
};

const mapDispatchToProps = (dispatch) => ({
	saveBonusGeneralInfo: (data) => {
		dispatch(saveBonusGeneralInfo(data));
	},
	getAvailableBetshops: (currency) => {
		dispatch(getAvailableBetshops(currency));
	},
	setAvailableBetshops: (arg) => {
		dispatch(setAvailableBetshops(arg));
	},
	getAvailableGames: (betshopIds) => {
		dispatch(getAvailableGames(betshopIds));
	},
	setAvailableGames: (arg) => {
		dispatch(setAvailableGames(arg));
	}
});

const mapStateToProps = (state) => {
	return {
		generalInfo: state.bonuses.standard.editingBonus.generalInfo,
		isLoading: state.bonuses.standard.isLoading,
		isSaving: state.bonuses.standard.isSaving,
		betshops: state.bonuses.standard.betshops,
		games: state.bonuses.standard.games,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent);
