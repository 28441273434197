import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form, Col, Input, Button, Select, TimePicker } from "antd";
import { createDateTimeString } from "utils/common";
import moment from "moment";

const ListItem = ({ field, index, fields, remove, availableGames, getFieldValue, disabled = false }) => {
	const { t } = useTranslation();

	const getGameRecordFromList = (fieldName) => {
		return {
			id: getFieldValue(["games", fieldName, "id"]),
			name: getFieldValue(["games", fieldName, "name"]),
			date: getFieldValue(["games", fieldName, "date"])
		};
	};

	const [selectedDates, setSelectedDates] = useState(getGameRecordFromList(field.name).date);

	const getDateRangeFromMomentRange = (arrRange) => {
		if (!arrRange || !arrRange[0] || !arrRange[1]) {
			return [null, null];
		}
		return [
			{ hour: arrRange[0].hour(), minute: arrRange[0].minute() },
			{ hour: arrRange[1].hour(), minute: arrRange[1].minute() }
		];
	};
	const CheckCrossedDates = (date1, date2) => {
		if (!date1 || !date2 || !date1[0] || !date1[1] || !date2[0] || !date2[1]) {
			return false;
		}

		let firstStart, firstEnd, lastStart, lastEnd;
		firstStart = date1[0].hour * 60 + date1[0].minute;
		firstEnd = date1[1].hour * 60 + date1[1].minute;
		lastStart = date2[0].hour * 60 + date2[0].minute;
		lastEnd = date2[1].hour * 60 + date2[1].minute;

		if (lastStart === lastEnd || firstStart === firstEnd) return true;

		if (firstStart < firstEnd) {
			if (lastStart < lastEnd) {
				return (lastStart > firstStart && lastStart < firstEnd) || (lastEnd > firstStart && lastEnd < firstEnd) || (firstStart > lastStart && firstStart < lastEnd) || (firstEnd > lastStart && firstEnd < lastEnd);
			} else {
				return firstStart > lastStart || firstStart < lastEnd || firstEnd > lastStart || firstEnd < lastEnd;
			}
		} else {
			if (lastStart < lastEnd) {
				return lastStart > firstStart || lastStart < firstEnd || lastEnd > firstStart || lastEnd < firstEnd;
			} else {
				return true;
			}
		}
	};

	const utcFormatteDate = useMemo(() => {
		if (!selectedDates || !selectedDates[0] || !selectedDates[1]) {
			return `UTC ${t("pages.dashboard.bets.date_and_time")}`;
		}
		let startDate, endDate;
		const datesArr = Array.prototype.map.call(selectedDates, (d) => d.toDate()).map((d) => moment.utc(d));
		[startDate, endDate] = getDateRangeFromMomentRange(datesArr);

		let minuteDiff = Math.round(endDate.minute - startDate.minute);
		let hourDiff = Math.round(endDate.hour - startDate.hour);

		if (minuteDiff < 0) {
			hourDiff--;
			minuteDiff = 60 + minuteDiff;
		}

		if (hourDiff < 0) {
			hourDiff = 24 + hourDiff;
		}

		const difference = !hourDiff && !minuteDiff ? `24 ${t("common.hours")}` : `${hourDiff ? `${hourDiff} ${t("common.hours")}` : ""} ${minuteDiff ? `${minuteDiff} ${t("common.minutes")}` : ""}`;

		return `UTC ${createDateTimeString(startDate)} - ${createDateTimeString(endDate)} (${difference})`;
	}, [selectedDates]);

	return (
		<>
			<div>
				<span className="vs--stream-general-info-title">{t("pages.dashboard.streams.gameAndSchedule")}</span>
			</div>
			<div className="vs--stream-general-info vs--flex">
				<Col xs={24} sm={12}>
					<Form.Item
						name={[index, "id"]}
						label={null}
						rules={[
							{ required: true, whitespace: true, message: t("validation.field_required") },
							({ getFieldValue }) => ({
								validator(rule, value) {
									const mutetedArr = fields.map((elem) => {
										const elemRec = getGameRecordFromList(elem.name);
										const date = getDateRangeFromMomentRange(elemRec.date)
											.map(createDateTimeString)
											.sort((next, prev) => (next < prev ? -1 : 1));
										return { id: elemRec.id, date: date };
									});
									const sortedArr = mutetedArr.sort((next, prev) => (next.date.join(" - ") < prev.date.join(" - ") ? -1 : 1));
									const isInvalid = sortedArr.some((rec, i, sortedByDateArr) => {
										if (sortedByDateArr[i - 1] && rec.id === sortedByDateArr[i - 1].id) {
											return rec.date[0] === sortedByDateArr[i - 1].date[1];
										}
										return false;
									});
									return isInvalid ? Promise.reject(t("validation.gamesCanNotBeSequentially")) : Promise.resolve();
								}
							})
						]}
					>
						<Select className="vs--stream-general-info-select" placeholder={t("pages.dashboard.streams.selectGame")} suffixIcon={<i className="icon-down" />} disabled={disabled}>
							{availableGames.map((availableGame) => {
								return (
									<Select.Option value={availableGame.id} key={availableGame.id}>
										{availableGame.name}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>
				<Col xs={24} sm={15}>
					<Form.Item
						label={null}
						name={[index, "date"]}
						rules={[
							{ required: true, message: t("validation.field_required") },
							({ getFieldValue }) => ({
								validator(rule, value) {
									const currentDateRange = getDateRangeFromMomentRange(value);
									const isInvalid = fields.some((elem) => {
										if (field.name !== elem.name) {
											const data = getGameRecordFromList(elem.name);
											const elemDate = getDateRangeFromMomentRange(data.date);
											return CheckCrossedDates(currentDateRange, elemDate);
										}
										return false;
									}, []);
									if (isInvalid) {
										return Promise.reject(t("validation.gameTimeCrossed"));
									}
									setSelectedDates(value);
									return Promise.resolve();
								}
							})
						]}
					>
						<TimePicker.RangePicker
							className="vs--stream-general-info-time"
							showTime={{
								format: "HH:mm"
							}}
							order={false}
							disabled={disabled}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} sm={20}>
					<Form.Item label={null} disabled={true}>
						<Input className="vs--stream-general-info-time-second" value={utcFormatteDate} disabled={true} />
					</Form.Item>
				</Col>
				{!disabled ? (
					<Col xs={24} sm={12}>
						<Button className="vs--dynamic-delete-button" onClick={() => remove(field.name)}>
							<i className="icon-remove" />
						</Button>
					</Col>
				) : null}
			</div>
		</>
	);
};

ListItem.propTypes = {
	field: PropTypes.object,
	index: PropTypes.number,
	fields: PropTypes.arrayOf(PropTypes.object),
	remove: PropTypes.func,
	availableGames: PropTypes.arrayOf(PropTypes.object),
	getFieldValue: PropTypes.func,
	disabled: PropTypes.bool
};

export default ListItem;
