import PropTypes from "prop-types";

import { WEEKDAYS } from "constants/common.constants";
import { WORKING_HOURS_TYPE } from "constants/partner.constants";

export default PropTypes.shape({
	betShopCountLimit: PropTypes.number,
	betShopCount: PropTypes.number,
	allowCancellationByCashier: PropTypes.bool,
	ticketRedemptionPeriod: PropTypes.number,
	secret: PropTypes.string,
	hotKeyEnabled: PropTypes.bool,
	lastBetReprintEnabled: PropTypes.bool,
	workingHours: PropTypes.shape({
		allowOverride: PropTypes.bool,
		weekDays: PropTypes.shape(
			Object.fromEntries(
				Object.entries(WEEKDAYS).map(([key, val]) => [
					val,
					PropTypes.shape({
						type: PropTypes.oneOf(Object.values(WORKING_HOURS_TYPE)),
						openTime: PropTypes.shape({
							hour: PropTypes.number,
							minute: PropTypes.number
						}),
						closeTime: PropTypes.shape({
							hour: PropTypes.number,
							minute: PropTypes.number
						})
					})
				])
			)
		)
	}),
	publicHolidays: PropTypes.shape({
		disableCashierSoftware: PropTypes.bool,
		days: PropTypes.arrayOf(
			PropTypes.shape({
				month: PropTypes.number,
				day: PropTypes.number
			})
		)
	}),
	logoId: PropTypes.number
});
