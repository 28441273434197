import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Button } from "antd";
import Table from "components/common/table";
import BlockedIPAddComponent from "./blocked-ip-add.component";

import apiKeyBlockedIPType from "types/apiKey/blockedIP.type";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { getApiKeyBlockedIPs, deleteApiKeyBlockedIP } from "store/actions/dashboard/online/apikeys/blockedIPs.action";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { hasPermission } from "utils/permissions";

/** Api Key Edit Page IP Restriction Tab Blocked IP SubTab Component */
const BlockedIPsComponent = ({ ips, getApiKeyBlockedIPs, isLoading, deleteApiKeyBlockedIP }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const [showCreatePopup, setShowCreatePopup] = useState(false);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.apikeys.ip_address"),
			dataIndex: "from",
			render: (value, record) => value + (record.to ? " - " + record.to : ""),
			sorter: false
		},
		{
			title: t("pages.dashboard.apikeys.blocked_at"),
			dataIndex: "blockedAt",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT),
			sorter: false
		},
		{
			title: t("pages.dashboard.apikeys.blocked_by"),
			dataIndex: "blockedBy",
			sorter: false
		}
	];

	/** Close country add popup, after ip saved */
	useEffect(() => {
		setShowCreatePopup(false);
	}, [ips]);

	return (
		<div className="dashboard-section-content">
			{hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_IPRESTRICTIONS, action: PERMISSION_ACTION.CREATE }) ? (
				<div className="dashboard-section-buttons">
					<Button
						type="primary"
						htmlType="button"
						className="button"
						onClick={() => {
							setShowCreatePopup(true);
						}}
					>
						<span>{t("pages.dashboard.apikeys.add_ip_address")}</span>
					</Button>
				</div>
			) : null}
			<Table
				loading={isLoading}
				columns={columns}
				data={ips}
				loadFn={() => getApiKeyBlockedIPs(routeParams.id)}
				total={ips.length}
				actions={{
					delete: hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_IPRESTRICTIONS, action: PERMISSION_ACTION.DELETE })
						? {
								icon: <i className="icon-lock" />,
								title: t("common.unblock"),
								messageKey: "blockedIP",
								handler: (record) => {
									deleteApiKeyBlockedIP(routeParams.id, record.id);
								}
							}
						: null
				}}
				isDisabled={(record) => !record.enabled}
				noPagination={true}
			/>

			{showCreatePopup ? (
				<BlockedIPAddComponent
					onClose={() => {
						setShowCreatePopup(false);
					}}
				/>
			) : null}
		</div>
	);
};

/** BlockedIPsComponent propTypes
 * PropTypes
 */
BlockedIPsComponent.propTypes = {
	/** Redux action to get api key blocked ips */
	getApiKeyBlockedIPs: PropTypes.func,
	/** Redux state property, represents the array of blocked ips of api key */
	ips: PropTypes.arrayOf(apiKeyBlockedIPType),
	/** Redux state property, is true when loading api key blocked ips */
	isLoading: PropTypes.bool,
	/** Redux action to delete blocked ip */
	deleteApiKeyBlockedIP: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getApiKeyBlockedIPs: (id) => {
		dispatch(getApiKeyBlockedIPs(id));
	},
	deleteApiKeyBlockedIP: (id, ipId) => {
		dispatch(deleteApiKeyBlockedIP(id, ipId));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.apikeys.editingApikey.ipRestriction.blockedIPs.isLoading,
		ips: state.apikeys.editingApikey.ipRestriction.blockedIPs.ips
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockedIPsComponent);
