import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { Form, Row, Col, Input, Button, Select, Switch, Radio, Spin } from "antd";
const { Item: FormItem } = Form;

import { PARTNER_SECURITY_PASSWORD_PATTERN, PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE, PARTNER_PASSWORD_FORCE_CHANGE_TYPE } from "constants/partner.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { getPartnerSecurityPasswordSettings, savePartnerSecurityPasswordSettings } from "store/actions/dashboard/partners/security.action";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import passwordSettingsType from "types/partner/passwordSettings.type";

const LIMIT_POSSIBLE_VALUES = Array.from(Array(17).keys()).map((n) => n + 8);

/** Partner Edit Page Security Tab Password Settings subTab Component */
const PasswordSettingsComponent = ({ isSaving, isLoading, passwordSettings, onTabChange, getPartnerSecurityPasswordSettings, savePartnerSecurityPasswordSettings }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);

	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_SECURITY_SETTINGS, action: PERMISSION_ACTION.MODIFY });

	/** Make form initial values
	 * @function
	 * @returns {objects} - initial values
	 * @memberOf PasswordSettingsComponent
	 */
	const initialValues = () => ({
		passwordMinLimit: passwordSettings.passwordMinLimit,
		passwordMaxLimit: passwordSettings.passwordMaxLimit,
		forceChange: passwordSettings.forceChange,
		pattern: {
			type: passwordSettings.pattern.type,
			alsoIncludeCharacters: passwordSettings.pattern.alsoIncludeCharacters,
			regularExpression: passwordSettings.pattern.regularExpression,
			contain: passwordSettings.pattern.contain ? passwordSettings.pattern.contain.map((el) => el.type + "_" + el.value) : []
		}
	});

	const [formCurrentValues, setFormCurrentValues] = useState(initialValues());

	/** Load partner security settings */
	useEffect(() => {
		getPartnerSecurityPasswordSettings();
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		const values = {
			...passwordSettings,
			pattern: {
				...passwordSettings.pattern,
				contain: passwordSettings.pattern.contain.map((c) => c.type + "_" + c.value)
			}
		};
		setFieldsValue(values);
		setFormCurrentValues(values);
	}, [passwordSettings]);

	/** Check is form changed
	 * @function
	 * @param {object} formValues - form current values
	 * @returns {boolean}
	 * @memberOf PasswordSettingsComponent
	 */
	const formChanged = (formValues) => {
		if (formValues.pattern !== undefined && formValues.pattern.contain !== undefined) formValues.pattern.contain = formValues.pattern.contain.map((c) => ({ type: Number(c.split("_")[0]), value: Number(c.split("_")[1]) }));
		return isFormChanged(formValues, { ...passwordSettings });
	};

	/** Is true when no "must value selected"
	 * @function
	 * @description Check password settings pattern section "contains" field has at least 1 "must" value selected
	 * @returns {boolean}
	 * @memberOf PasswordSettingsComponent
	 */
	const dontHaveMustValue = () => {
		const contain = formCurrentValues.pattern.contain ? [...formCurrentValues.pattern.contain] : null;
		const result = formCurrentValues.pattern.type === PARTNER_SECURITY_PASSWORD_PATTERN.CONTAINS && contain && !contain.map((c) => Number(c.split("_")[1])).includes(PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE.MUST);
		return result;
	};

	/** Fires when form submitted
	 * @function
	 * @memberOf PasswordSettingsComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				if (!dontHaveMustValue()) {
					savePartnerSecurityPasswordSettings({
						...data,
						pattern: {
							...data.pattern,
							contain: data.pattern.contain.map((c) => ({ type: Number(c.split("_")[0]), value: Number(c.split("_")[1]) }))
						}
					});
					setIsFormTouched(false);
				}
			})
			.catch(Function.prototype);
	};

	/** Fires on pattern type radio button change
	 * @function
	 * @param {object} e - the event object
	 * @memberOf PasswordSettingsComponent
	 */
	const onPatternTypeChange = (e) => {
		const value = e.target.value;
		if (value === PARTNER_SECURITY_PASSWORD_PATTERN.CONTAINS) {
			setFieldsValue({
				pattern: {
					type: value,
					regularExpression: passwordSettings.pattern.regularExpression
				}
			});
		} else if (value === PARTNER_SECURITY_PASSWORD_PATTERN.PATTERN) {
			setFieldsValue({
				pattern: {
					type: value,
					contain: passwordSettings.pattern.contain.map((c) => c.type + "_" + c.value)
				}
			});
		}
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={initialValues()}
				onValuesChange={(changed, formValues) => {
					const valuesCopy1 = { ...JSON.parse(JSON.stringify(formValues)) };
					const valuesCopy2 = { ...JSON.parse(JSON.stringify(formValues)) };
					setFormCurrentValues(valuesCopy1);
					setIsFormTouched(formChanged(valuesCopy2));
				}}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.partners.security_settings.password_settings.password_min_limit")} name="passwordMinLimit">
									<Select disabled={isDisabled} suffixIcon={<i className="icon-down" />}>
										{LIMIT_POSSIBLE_VALUES.filter((v) => !formCurrentValues.passwordMaxLimit || v <= formCurrentValues.passwordMaxLimit).map((el) => (
											<Select.Option value={el} key={el}>
												{el}
											</Select.Option>
										))}
									</Select>
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.partners.security_settings.password_settings.password_max_limit")} name="passwordMaxLimit">
									<Select disabled={isDisabled} suffixIcon={<i className="icon-down" />}>
										{LIMIT_POSSIBLE_VALUES.filter((v) => !formCurrentValues.passwordMinLimit || v >= formCurrentValues.passwordMinLimit).map((el) => (
											<Select.Option value={el} key={el}>
												{el}
											</Select.Option>
										))}
									</Select>
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem label={t("pages.dashboard.partners.security_settings.password_settings.force_change")} name="forceChange">
									<Select disabled={isDisabled} suffixIcon={<i className="icon-down" />}>
										{Object.values(PARTNER_PASSWORD_FORCE_CHANGE_TYPE).map((el, index) => (
											<Select.Option value={el} key={el}>
												{el === PARTNER_PASSWORD_FORCE_CHANGE_TYPE.NEVER ? t("common.never") : t("common.every_N_month").replace("%N%", index)}
											</Select.Option>
										))}
									</Select>
								</FormItem>
							</Col>
						</Row>

						<Row>
							<Col span={24} className="partner-security-settings-pattern">
								<FormItem name={["pattern", "type"]}>
									<Radio.Group size="large" onChange={onPatternTypeChange} disabled={isDisabled}>
										<Radio value={PARTNER_SECURITY_PASSWORD_PATTERN.CONTAINS}>{t("pages.dashboard.partners.security_settings.password_settings.may_contain")}</Radio>
										<Radio value={PARTNER_SECURITY_PASSWORD_PATTERN.PATTERN}>{t("pages.dashboard.partners.security_settings.password_settings.generate_using_pattern")}</Radio>
									</Radio.Group>
								</FormItem>

								<div style={{ display: formCurrentValues.pattern.type === PARTNER_SECURITY_PASSWORD_PATTERN.CONTAINS ? "block" : "none" }}>
									<Row gutter={[16, 0]}>
										<Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
											<div className="partner-contain-radio-row-title">
												<span>{t("pages.dashboard.partners.security_settings.password_settings.may")}</span>
												<span>{t("pages.dashboard.partners.security_settings.password_settings.must")}</span>
												<span>{t("pages.dashboard.partners.security_settings.password_settings.none")}</span>
											</div>
											{passwordSettings.pattern.contain.map((el, ind) => {
												return ind < 4 ? (
													<div className="inline-form-item partner-contain-radio-row" key={ind}>
														<label>{t(`pages.dashboard.partners.security_settings.password_settings.contain_type_${el.type}`)}</label>
														<FormItem name={["pattern", "contain", ind]}>
															<Radio.Group size="large" disabled={isDisabled}>
																<Radio value={el.type + "_" + PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE.MAY}></Radio>
																<Radio value={el.type + "_" + PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE.MUST}></Radio>
																<Radio value={el.type + "_" + PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE.NONE}></Radio>
															</Radio.Group>
														</FormItem>
													</div>
												) : null;
											})}
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
											<div className="partner-contain-radio-row-title">
												<span>{t("pages.dashboard.partners.security_settings.password_settings.may")}</span>
												<span>{t("pages.dashboard.partners.security_settings.password_settings.must")}</span>
												<span>{t("pages.dashboard.partners.security_settings.password_settings.none")}</span>
											</div>
											{passwordSettings.pattern.contain.map((el, ind) => {
												return ind > 3 ? (
													<div className="inline-form-item partner-contain-radio-row" key={ind}>
														<label>{t(`pages.dashboard.partners.security_settings.password_settings.contain_type_${el.type}`)}</label>

														<FormItem name={["pattern", "contain", ind]}>
															<Radio.Group size="large" disabled={isDisabled}>
																<Radio value={el.type + "_" + PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE.MAY}></Radio>
																<Radio value={el.type + "_" + PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE.MUST}></Radio>
																<Radio value={el.type + "_" + PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE.NONE}></Radio>
															</Radio.Group>
														</FormItem>
													</div>
												) : null;
											})}
											{dontHaveMustValue() && (
												<span className="info-text error-text" style={{ margin: "8px 0", textAlign: "right" }}>
													{t("pages.dashboard.partners.security_settings.password_settings.must_required")}
												</span>
											)}
										</Col>
									</Row>
									<Row gutter={[16, 0]}>
										<Col xs={24} sm={24} md={12} lg={12} xl={6}>
											<FormItem label={t("pages.dashboard.partners.security_settings.password_settings.also_include_characters")} name={["pattern", "alsoIncludeCharacters"]}>
												<Input disabled={isDisabled} />
											</FormItem>
										</Col>
									</Row>
								</div>
								<div style={{ display: formCurrentValues.pattern.type === PARTNER_SECURITY_PASSWORD_PATTERN.PATTERN ? "block" : "none" }}>
									<Row gutter={[16, 0]}>
										<Col xs={24} sm={24} md={12} lg={12} xl={6}>
											<FormItem
												name={["pattern", "regularExpression"]}
												rules={[
													({ getFieldValue }) => ({
														validator(rule, value) {
															if (value || formCurrentValues.pattern.type !== PARTNER_SECURITY_PASSWORD_PATTERN.PATTERN) {
																return Promise.resolve();
															}
															return Promise.reject(t("validation.field_required"));
														}
													})
												]}
											>
												<Input placeholder={t("pages.dashboard.partners.security_settings.password_settings.type_pattern")} disabled={isDisabled} />
											</FormItem>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</div>
					{!isDisabled && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** PasswordSettingsComponent propTypes
 * PropTypes
 */
PasswordSettingsComponent.propTypes = {
	/** Redux state property, is true when password settings is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when password settings is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the password settings of current editing partner  */
	passwordSettings: passwordSettingsType,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func,
	/** Redux action to get partner password settings */
	getPartnerSecurityPasswordSettings: PropTypes.func,
	/** Redux action to get partner password settings */
	savePartnerSecurityPasswordSettings: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getPartnerSecurityPasswordSettings: () => {
		dispatch(getPartnerSecurityPasswordSettings());
	},

	savePartnerSecurityPasswordSettings: (settings) => {
		dispatch(savePartnerSecurityPasswordSettings(settings));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.partners.isSaving,
		isLoading: state.partner.isLoading,
		passwordSettings: state.partner.securitySettings.passwordSettings
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordSettingsComponent);
